//#region [import]
import { useFocusEffect } from "@react-navigation/native";
import { Buttoon, ListHeader, Poppy, Skeleton, Txt } from "components";
import { CardPosst } from "components/molecules";
import { useTwoColumns } from "components/templates";
import { PosstSchema, POSST_STATUS } from "engines";
import { usePosstsCollection } from "engines/firebase/handler/posst-handler";
import * as R from "ramda";
import React from "react";
import { Linking, Platform, View } from "react-native";
import Animated from "react-native-reanimated";
import { useNavigator } from "screens";
import { SpotlightRoll } from "screens/home/SpotlightRoll";
import { TodaysAgendaSection } from "screens/home/TodaysAgendaSection";
import { IS_WEB, spacing, THEME, tr } from "utils";
import { PosstCreatorCenter } from "../posst-creator/PosstCreatorCenter";
import { ScoreboardsHomeGrid } from "./ScoreboardsHomeGrid";
import { ShortcutsHomeGrid } from "./ShortcutsHomeGrid";
//#endregion

/**
 * ### Feed of possts/announcements
 *  - Memoized feed to prevent itself from re-rendering
 *  ----
 *  @version 21.09.05
 *  -  *Memoize using useMemo()*
 *  @author  @nguyenkhooi
 *
 **/
export function PosstsFeed(props: any) {
  // return <TestResponses />
  const { scrollHandler, isAdmin, isPlaystoreTester } = props;
  // const { teamId, C } = useAppContext();

  const {
    column1ContainerStyle,
    ViewCol1BottomSpace,
    breakpointForTwoColumns,
  } = useTwoColumns();

  const posstsHandler = usePosstsCollection();
  const Navigation = useNavigator();
  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion
  const [listOffset, setListOffset] = React.useState(6);

  // const refDidopenpp = React.useRef<boolean>(false);
  // React.useEffect(function privacyPolicyGoogleSucks() {
  //   if (refDidopenpp.current) return;
  //   if (isPlaystoreTester)
  //     Poppy("Privacy Policy", "You can view our privacy policy regarding your device contacts, images, etc. from the home screen.", { text: "Got it", onPress: () => { } }, {
  //       text: "View now", onPress: () => {
  //         Linking.openURL("https://apptakeoff.com/privacy-policy")
  //       }
  //     })
  //   refDidopenpp.current = true;
  // }, []);

  return React.useMemo(() => {
    try {
      if (posstsHandler.error)
        return (
          <Txt.Indicator>{tr("Error getting scoreboard list")}</Txt.Indicator>
        );
      if (!posstsHandler.data)
        return (
          <View style={[column1ContainerStyle]}>
            <Skeleton.Screen.Possts />
          </View>
        );
      let sortedPossts = R.sortWith<PosstSchema>(
        [
          /** sorting `createdAt` in `desc` */
          R.descend(R.prop("createdAt")),
        ],
        posstsHandler.data
      )
        /** displaying posted possts */
        .filter((posst) => posst.status === POSST_STATUS.POSTED)
      // return <Skeleton.Screen.Possts />;
      return (
        <Animated.ScrollView
          scrollEventThrottle={16}
          onScroll={scrollHandler}
          contentContainerStyle={[column1ContainerStyle]}
        >
          <View>
            <SpotlightRoll />
            {!breakpointForTwoColumns && Platform.OS === "android" && isPlaystoreTester ? <Buttoon size="small" style={{ marginTop: spacing(2) }} status="info"
              onPress={() => {
                Linking.openURL("https://apptakeoff.com/privacy-policy")
              }}>
              View Privacy Policy
            </Buttoon> : null}
            {!breakpointForTwoColumns ? <TodaysAgendaSection /> : null}
            {!breakpointForTwoColumns ? <ShortcutsHomeGrid /> : null}
            <ScoreboardsHomeGrid
              isHomeContentLoaded={posstsHandler.data ? true : undefined}
            />
            <View style={{ height: spacing(2) }} />
            <ListHeader
              titleTx={tr("Announcements")}
              actions={[
                {
                  label: "New Post",
                  icon: { name: "plus", solid: true },
                  appearance: "filled",
                  status: "control",
                  onPress: () =>
                    IS_WEB ? Navigation.dialogPortal.open({
                      headerTitle: tr("New Post"),
                      render: <PosstCreatorCenter />,
                    }) : Navigation.overlayPortal.open({
                      headerTitle: tr("New Post"),
                      render: <PosstCreatorCenter />,
                    }),
                },
              ]}
            />
          </View>
          {sortedPossts.map((posst) => (
            <>
              <CardPosst posst={posst} />
            </>
          )).concat([]).slice(0, listOffset)}
          <View>
            <Buttoon
              appearance={"ghost"}
              progress
              onPress={(xong) => {
                setTimeout(() => {
                  setListOffset(listOffset + 5);
                  xong && xong();
                }, 600);
              }}
              disabled={listOffset >= sortedPossts.length}
            >
              {listOffset < sortedPossts.length ? "Load More" : ""}
            </Buttoon>
            <ViewCol1BottomSpace />
          </View>
        </Animated.ScrollView>
      );
    } catch (error) {
      throw Error(`::UG9zc3RzRmVlZA==::${error}`);
    }
  }, [posstsHandler.data, listOffset, column1ContainerStyle]);
}
