import { Timestamp } from "@google-cloud/firestore";
import { FRBS_ROLE } from "engines";
import { dListBuilderList } from "engines/firebase/firebase.props";
import firebase from "firebase";
import { THEME } from "utils";
/**
 * ###  User Doc Schema
 * -    Should be identical to such doc in FRBS.
 * ---
 * @version 1.09.29
 * @author nguyenkhooi
 * ---
 * @example
 * const MOCK_USER = {
    _id: "3X8etYGXLVWmbc0QIFXz",
    theme: "themeLight",
    personali: {
        displayName: "Soy Sauce",
        email: "soy.at.sauce.com",
        phoneNumber: "4343331234",
        photoURL: "3X8etYGXLVWmbc0QIFXz",
    },
    roles: ["ADMIN"],
    };
 */
export interface UserSchema {
  _id: string;
  _parentAppId?: string;
  _teamId: string;
  baseShopId: string;
  theme: THEME;
  personali: dPersonali;
  /** where their trainees can access their trainer's unique scheduling link */
  schedulingLink?: string;
  growth?: dGrowth;
  // activity?: dActivity[];
  /** The content of a user */
  contentViewHistory?: dContentView[];
  roles?: FRBS_ROLE[];
  createdAt?: firebase.firestore.Timestamp;
  lastGetStartedDayPress?: firebase.firestore.Timestamp;
  lastSignIn?: Date;
  /**
   * @deprecated use .stats now
   * this is a number repsenting how active this user currently is with the app (may only be relevant to new people) */
  actionPoints?: number;
  /** numbers and fields relating to the user's stats */
  stats?: {
    /** overall acquired action points in user's lifetime */
    totalActionPoints?: number;
    /** action points acquired in past rolling 3 days or x days set for that app/team */
    rollingActionPoints?: number;
    /** record how many points were acquired in a given period of time, say the month of February, or even the current month */
    actionPointsLog?: { periodStart: firebase.firestore.Timestamp, periodEnd: firebase.firestore.Timestamp, points: number }[]
  },
  notifications?: { lastRead: Date };
  /** ids of personal calendars from their device they want integrated within the app */
  personalCalendars?: string[];
  /** All the items that are completed in any of the levels */
  completedLevelItems?: string[];
  /** Pages that the user has completed so that if content gets added they are still marked 100% */
  completedLevelPages?: string[];
  /** the parent pages with level buttons that are all 100% completed */
  completedLevelParentPages?: string[];
  /** All the page ids of the pages that this user should have access to regardless of password or any other requirements */
  unlockedPages?: string[];
  /** Every page that contains a set of levels inside of it that this user has participated in (this is so we know what levels outside of New Start Here the user might be participating in) */
  parentLevelPages?: string[];
  /** @deprecated last level page where user completed an item */
  lastLevelPage?: string;
  /** The parent page where the user was last doing training on */
  lastParentLevelPage?: string;
  /** A more convenient way to know a user's streaks and display them public facing without having to read their activities collection and calculate everything */
  activityStreaks?: { activityKey: string; streak: number }[];
  /** True if the user has completed all the levels or has been given access to bypass going in order */
  allLevelsCompleted: boolean;
  /** for DEV */
  imitate: boolean;
  /** @deprecated where is this used? */
  team?: dTeam;
  /** Firebase Cloud Messaging tokens representing users devices that can receive Push Notifications */
  fcmTokens?: string[] | firebase.firestore.FieldValue;
  /** */
  mobileVersion: string;
  webVersion: string;
}

/**
 * When a user views a piece of content like a video or audio this will be tracked in their profile every time they play it and will remember how much of it they watched/listened to
 * - if used for a youtube video or document "watched" will just be 1
 */
export interface dContentView {
  /** if the team has a parent app like Unleash, this would be the id of it */
  _parentAppId?: string;
  _teamId: string;
  /** The uid of the user who watched the video (in case it's needed during large collection fetch) */
  uid: string | "anonymous";
  /** The url representing this piece of content */
  url?: string;
  /** The custom page item id it was watched with */
  itemId: string;
  /** How much of the item was watched/listened from 0 to 1 */
  watched: number;
  /** how many milliseconds of the video has been watched */
  progressMillis?: number;
  /** total duration of the video */
  progressDuration?: number;
  /** The timestamp the user played the video */
  startedAt?: firebase.firestore.Timestamp;
  /** The uid of the person who shared the video (if it was a share) */
  sharerUid?: string;
  /** if recored by addContentView, and if relevent */
  recipientName?: string;
}

export interface dGuest {
  name: string;
}
export interface dAttendance {
  /** parent app the user is using */
  // _parentAppId?: string;
  /** teamId this even belongs to */
  _teamId: string;
  /** user info for the attendee */
  attendee: {
    uid: string;
    displayName: string;
    photoURL: string;
  };
  /** if the user invited anyone that showed up */
  guests: dGuest[];
  /** Id of the meeting */
  meetingId: string;
  /** When they tapped "I'm here" */
  attendedAt: firebase.firestore.Timestamp;
  /** When the meeting started */
  meetingStartTime: firebase.firestore.Timestamp;
  /** When the meeting ended */
  meetingEndTime: firebase.firestore.Timestamp;
}

/**
 * ACTIONS
 * - A profile view (you can see and trainer/public can see)
 * - Certain items show directly in Field Trainer feed
 * - Certain items show directly in public feed
 */

/** types of actions a user can perform and be recorded */
export enum ACTION_TYPE {
  NEW_USER = "new-user", // PN!?, public feed *
  CONTENT_VIEW = "content-view", // trainer feed *
  LEVEL_COMPLETION = "level-complete", // PN!, trainer feed *
  ADD_CONTACT = "add-contact", // trainer feed unless over X contacts for same day, combine them *
  IMPORT_ALL_CONTACTS = "import-all-contacts", // PN!?, trainer feed *
  SEND_SMS = "send-message", // PN!, trainer feed unless over X messages for same day, combine them *
  CONTENT_SHARE = "content-share", // trainer feed *
  BLAST_TEXT = "blast-sms", // trainer feed *
  SEND_EMAIL = "send-email", // trainer feed *
  SCHEDULED_MEETING = "schedule-event", // PN!, trainer feed *
  JOINED_MEETING = "joined-meeting", // PN!?, trainer feed (public feed can show all team meetings) *
  ADDED_GUESTS = "add-guests", // PN!?, public feed *
  BUILD_MY_LIST = "build-my-list", // PN!, trainer feed *
  ASSIGNED_TRAINER = "assigned-trainer", // PN!, trainer feed *
  UNASSIGNED_TRAINER = "unassigned-trainer", // trainer feed *
  UPLOADED_PROFILE_IMAGE = "upload-profile-image", // public feed *
  DAILY_PROGRESS_REPORT = "submit-progress-report", // PN!!, public feed *
  SCOREBOARD_ENTRY = "scoreboard-entry", // PN!!, public feed *
  POSST_COMMENT = "posst-comment", // public feed *
  POSST_LIKE = "posst-like", // public feed *
  ADD_TASK = "add-task", // field trainer feed *
  COMPLETE_TASK = "complete-task" // field trainer feed *
}
// everyone can see it (including trainers)
export const PUBLIC_FEED_ACTIONS = [ACTION_TYPE.ADDED_GUESTS, ACTION_TYPE.UPLOADED_PROFILE_IMAGE, ACTION_TYPE.DAILY_PROGRESS_REPORT, ACTION_TYPE.SCOREBOARD_ENTRY, ACTION_TYPE.POSST_COMMENT, ACTION_TYPE.POSST_LIKE]
// only their trainers can see it
export const TRAINER_FEED_ACTIONS = [ACTION_TYPE.CONTENT_VIEW, ACTION_TYPE.LEVEL_COMPLETION, ACTION_TYPE.ADD_CONTACT, ACTION_TYPE.SEND_SMS, ACTION_TYPE.BLAST_TEXT, ACTION_TYPE.SEND_EMAIL, ACTION_TYPE.SCHEDULED_MEETING, ACTION_TYPE.JOINED_MEETING, ACTION_TYPE.BUILD_MY_LIST, ACTION_TYPE.ASSIGNED_TRAINER, ACTION_TYPE.ADD_TASK]

/** likes for things like actions */
export interface dUserLike {
  uid: string,
  emoji?: string;
  likedAt: firebase.firestore.Timestamp;
}
/** comments for actions */
export interface dUserComment {
  uid: string;
  displayName: string;
  profileImage: string;
  body: string;
  createdAt: firebase.firestore.Timestamp;
}
/** an action entry for a user */
export interface dUserAction {
  _id?: string;
  _teamId: string;
  /** who did it */
  uid: string;
  /** so we know who their trainees are when pulling a feed */
  _traineeUids: string[];
  /** user-friendly description of what happened */
  description: string;
  /** when it happened */
  occurredAt: firebase.firestore.Timestamp;
  /** what happened */
  actionType: ACTION_TYPE;
  /**
   * Where it happened (a doc id)
   * - could be page:page_id/item_id, scoreboardId, etc.
   * */
  location?: string;
  /** If relevant, the name of the recipient in an SMS, guest added, scheduled meeting name, post name, page name, etc. */
  involvedPartyName?: string;
  /** Pass an image to display */
  thumbnail?: string;
  /** easy way to get their displayName */
  displayName: string;
  /** easy way to get their profile picture */
  profileImage: string;
  /** a way for people to interact, eventually with emojis too */
  likes: dUserLike[],
  /** people who left a comment! (like their trainer) */
  comments: dUserComment[],
  /** if it was committed multiple times, good for post database combining/condensing similar actions */
  count?: number,
  /** When combining multiple actions this is the description describing all of them (combined locally in actions-handler) */
  groupedDescription?: string,
  /** Other identical actions combined locally (combined in actions-handler) */
  groupedActions?: dUserAction[]
}

/** @deprecated old Apricot thing */
enum ACTIVITY_KEY {
  POWER_BILLS,
  APPOINTMENTS_SET,
}

/**
 * An activity entry for a user (likely completed via Daily Progress Report "Report My Day")
 */
export interface dActivityEntry {
  /**
   * The key representing this activity, which can be a specific key hardcoded in the app or may be a customized self-tracking key created by an admin
   */
  activityKey: ACTIVITY_KEY | string;
  /**
   * Award the user with a set number of points for this
   * - Number of points is multiplied by the # inputted by the user
   */
  points?: number;
  /**
   * When the activity occurred
   */
  date: Timestamp;
  /**
   * This is the value of this activity
   * - for example, if it's a checkbox, it will be true/false, if it's a number input, it'll be a number, etc.
   */
  value: any;
  /**
   * Any data that goes with the activity, like details on who the appointment was scheduled with, link to the power bill, etc.
   * - optional
   */
  data?: any;
  /**
   * The id of the firestore document
   */
  _id: string;
}

interface dTeam {
  role: "Owner" | "";
  teamName: string;
}
/**
 * ###  Fields related to user's personal development
 */
interface dGrowth {
  allLevelsCompleted: false;
  levels: {};
  listBuilder: {
    lists: dListBuilderList[];
    shareTo: string[];
  };
  /** @deprecated maybe? move to growth */
  team?: string;
}

interface dPersonali extends Partial<firebase.UserInfo> { }

/**
 * ###  User data to load when user sign up
 * -  Replace `_id`, `personali` with accurate data if possible
 */
export function __DefaultUserData(): UserSchema {
  return {
    _teamId: "team_takeoff",
    _id: "4IdYkTcqjMUwZfqKTz4tCAQMtJf1", //* Nanle's
    baseShopId: "",
    personali: { displayName: "", email: "", phoneNumber: "" },
    growth: {
      team: null,
      allLevelsCompleted: false,
      levels: {},
      listBuilder: {
        lists: [],
        shareTo: [],
      },
    },
    allLevelsCompleted: false,
    completedLevelItems: [],
    roles: [FRBS_ROLE.NEWBIE],
    //@ts-ignore will convert to timestamp
    createdAt: new Date(),
    notifications: {
      lastRead: new Date(Date.now() - 24 * 60 * 60 * 1000),
    },
    theme: THEME.LIGHT,
    imitate: false,
  };
}
