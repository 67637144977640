import { DrawerActions } from "@react-navigation/native";
import { IconPrimr, ICON_NAME } from "assets";
import { dAccessory, Kitten } from "components";
import { BaseShopCreatorCenter } from "components/organisms";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { useNavigator } from "screens";
import { tr } from "utils";

/**
 * ###
 *  - This has like a detailed list of operations that the developer user type can actually achieve
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.10.19
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function DeveloperActionList() {
  const userProfile = useUserProfile();
  const MenuIcon = (name: ICON_NAME, props: dAccessory) => (
    <IconPrimr
      name={name}
      size={props.style.width}
      color={props.style.tintColor}
    />
  );

  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const Navigation = useNavigator();
  return (
    <Kitten.Menu
      onSelect={(index) => {
        Navigation.dispatch(DrawerActions.closeDrawer());
        Navigation.dialogPortal.open({
          headerTitle: tr("Create a base shop"),
          render: <BaseShopCreatorCenter />,
        });
      }}
    >
      <Kitten.MenuItem
        title={tr("Create a base shop")}
        //
        accessoryLeft={(props: dAccessory) => MenuIcon("pen", props)}
      />
    </Kitten.Menu>
  );
}
