import { HierarchyList, Kitten, sstyled, Txt } from "components";
import { iHierarchyFeedData } from "components/organisms/collaboration/HierarchyHomeFeed";
import { useTwoColumns } from "components/templates";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";
import { useAppContext } from "../../engines/providers/app-provider";

export interface dCollaboration {
  srcPageId?: string;
}

/**
 * ### Multiverse Feature for the Web App.
 *  - Multiverse feature that will allow cross-hierarchy content(a.k.a media resources) sharing across the web.
 *  ----
 *  @example
 *  <Multiverse {...props}>
 *  ----
 *  @version 21.11.05
 *  -  *Creating the Multiverse Feature*
 *  @author  Saurabh_M
 *
 **/
export function Multiverse(props: P) {
  const srcPageId = props.route?.params?.srcPageId;
  const userProfile = useUserProfile();
  const Navigation = useNavigator();
  const { C } = useAppContext();
  const { breakpointForTwoColumns } = useTwoColumns();

  return userProfile._isUserAdmin ? (
    <A.ViewBuilder
      style={{
        backgroundColor: C.background,
        flexDirection: breakpointForTwoColumns ? "row" : "column",
      }}
    >
      <A.Options
        style={{ backgroundColor: C.primaryDarker, borderColor: C.text }}
        onPress={() =>
          Navigation.overlayPortal.open({
            render: (
              <HierarchyList
                onHierarchySelect={(item) => {
                  //NOTE There is an overlay already open hence we do goBack to close that overlay to avoid navigation issues
                  Navigation.goBack();
                  Navigation.navigate<iHierarchyFeedData>("hierarchy-home", {
                    teamName: item.appTitle,
                    hierarchyTeamId: item._teamId,
                  });
                }}
                hierarchiesToBeExcluded={
                  !userProfile._isDeveloper
                    ? ["app-takeoff-team", "clone-apptakeoff", "app-takeoff"]
                    : []
                }
              />
            ),
            headerTitle: "Select Hierarchy",
          })
        }
      >
        <Txt.H1>{"🤝 \n"}</Txt.H1>
        <A.OText>{tr("Search By Hierarchy")}</A.OText>
      </A.Options>
      <A.Options
        style={{ backgroundColor: C.primaryDarker, borderColor: C.text }}
        onPress={() =>
          Navigation.navigate<dCollaboration>("collab-multiverse", {
            srcPageId,
          })
        }
      >
        <Txt.H1>{"🌎 \n"}</Txt.H1>
        <A.OText>{tr("Search the Multiverse")}</A.OText>
      </A.Options>
    </A.ViewBuilder>
  ) : (
    <A.ViewBuilder>
      <Txt.H1>{tr("Only Admins Can View this Page!")}</Txt.H1>
    </A.ViewBuilder>
  );
}

const A = {
  ViewBuilder: sstyled(View)((p) => ({
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  })),
  Options: sstyled(Kitten.Card)((p) => ({
    width: 250,
    height: 250,
    borderRadius: 20,
    margin: 30,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
  })),
  OText: sstyled(Txt.H3)((p) => ({
    alignItems: "center",
    justifyContent: "center",
  })),
};

interface P {
  route?: { params?: dCollaboration }; //* TODO remember to create dResourcesBodyScreenParam
}
