import fakerStatic from "faker";
import firebase from "firebase";
import { APIS } from "../apis";

export interface ScoreboardSchema {
  _teamId: string;
  _sbid: string;
  title: string;
  subtitle?: string;
  position: number;
  people: dScore[];
  createdAt?: firebase.firestore.FieldValue;
  /**
   * Instead of people editing their own scores, connect to an api or custom field in the system
   * examples: "activity:PAGES_READ", "api:GO_HIGH_LEVEL_POWER_BILLS"
   */
  apiTo?: string | APIS;
  /** The last time the cloud functions checked the api and updated the values, so we don't have to do it too frequently */
  lastApiUpdate?: firebase.firestore.Timestamp | Date;
}

export interface dScore {
  uid: string;
  name: string; //* @jeremyfrancis @mSaurabh @Nanle21 should we put name here? Think of the case when user change their name, this field won't be auto updated (well, we can do that in members handler validation tho)
  score: number;
  /**
   * Either a phone number or email representing this person.
   * - In the case that the uid is not provided (because it's probably just data from an api), we can use the identifier to determine what user in the app this might represent
   */
  identifier?: string;
}

export const _DEFAULT_SCOREBOARD: ScoreboardSchema = {
  _teamId: "team_takeoff",
  people: [
    {
      uid: fakerStatic.datatype.uuid(),
      name: fakerStatic.name.firstName() + " " + fakerStatic.name.lastName(),
      score: Math.round(Math.random() * 100),
    },
  ],
  _sbid: fakerStatic.datatype.uuid(),
  position: 0,
  title: fakerStatic.lorem.lines(2),
  subtitle: fakerStatic.company.catchPhrase(),
};
