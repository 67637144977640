import { Kitten, ScorerItem } from "components";
import { ListHeader, Skeleton, Txt } from "components/atoms";
import { FRBS_ROLE, useAppContext, UserSchema } from "engines";
import {
  useMembersCollection,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import { useScoreboardDocument } from "engines/firebase/handler/scoreboard-handler";
import * as R from "ramda";
import React from "react";
import { tr } from "utils";

/**
 * ### Set score(s) for member(s) that is not on the scoreboard yet
 *  ----
 *  @version 21.09.08
 *  -  *Move to Organism*
 *  @author  K
 *
 **/
export function ScoreCreatorCenter({ sbid }) {
  const { C, frbsUser, teamId } = useAppContext();
  const { data: userD, ...userProfile } = useUserProfile();
  const { data: membersD, ...handleMembers } = useMembersCollection({
    where: ["_teamId", "==", teamId],
  });
  // const Navigation = useNavigator();

  //#region [ANM]
  // useFocusEffect(function layouting() {
  //   //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  // });

  //#endregion

  //#region [section] Members section
  const [scorers, setScorers] = React.useState<UserSchema[]>([]);
  const [query, setQuery] = React.useState<string>();

  React.useEffect(
    function setupData() {
      membersD &&
        setScorers(
          membersD.filter(
            (m) =>
              m?.personali?.displayName && m.personali.displayName.length > 0
          )
        );
    },
    [membersD]
  );

  function filterHandler(value: string) {
    setQuery(value);
    if (value) {
      const newData = _members.filter((member) => {
        // console.log("member: ", member.personali);
        return member.personali?.displayName
          ?.toLowerCase()
          .includes(value.toLowerCase());
      });
      // console.log("valuee: ", newData);
      setScorers([...newData]);
    } else {
      setScorers(_members);
    }
  }
  //#endregion

  //#region [section2] Scoreboard section
  const { data: scoreboardD, ...scoreboardHandler } =
    useScoreboardDocument(sbid);
  let _existingScorerIds = scoreboardD?.people
    ? R.pluck("uid", scoreboardD?.people)
    : [];

  let _existingScorerNames =
    membersD &&
    membersD
      .filter((member) =>
        _existingScorerIds ? _existingScorerIds.includes(member._id) : null
      )
      .map((member) => {
        return member.personali?.displayName || member.personali?.email;
      })
      .join(", ");

  //#endregion

  if (handleMembers.error || scoreboardHandler.error) {
    console.warn("error in MembersMultiSelect: ", handleMembers.error);
    return (
      <Txt.Indicator>{tr("Error loading member (err_mem1)")}</Txt.Indicator>
    );
  }

  if (!membersD || !scoreboardD)
    return <Skeleton {...Skeleton.Screen.Members} />;
  let _members = membersD;

  /**
   * - If user is an ADMIN/DEV, he can add anyone's score,
   * - else, u can add your score only
   */
  if (userProfile._isRolesContain([FRBS_ROLE.ADMIN, FRBS_ROLE.DEVELOPER])) {
    return (
      <>
        <Kitten.Card
          // disabled
          style={{ borderColor: C.adminGold, borderWidth: 1 }}
        >
          <ListHeader titleTx={tr("Your score")} />
          <ScorerItem.Newbie
            key={userD?._id}
            sbid={sbid}
            member={userD}
            disabled={_existingScorerIds.includes(userD?._id)}
            style={[
              _existingScorerIds.includes(userD?._id) && { opacity: 0.4 },
            ]}
          />
          <ListHeader titleTx={tr("Members")} />
          <Txt.Helper marginV>
            {tr("Search for member's name") +
              ",\n" +
              tr("then insert the score")}
          </Txt.Helper>
          <Kitten.Input
            value={query || ""}
            placeholder={tr("Filter by member's name")}
            onChangeText={(value) => {
              filterHandler(value);
            }}
          />
          {/* <Txt.Helper marginV style={{ textAlign: "left" }}>
            {tr("Already on the scoreboard:") + " " + _existingScorerNames ||
              "None"}
          </Txt.Helper> */}

          {query?.length > 0 ? (
            <ScorerItem.Newbie
              key={"non-user-score"}
              sbid={sbid}
              nonUser={{ name: query }}
              // disabled={_existingScorerIds.includes(member?._id)}
              disabled={_existingScorerNames.includes(query)}
              style={[_existingScorerNames.includes(query) && { opacity: 0.4 }]}
            />
          ) : null}
          {scorers
            .filter((member) => member?._id !== frbsUser?.uid)
            // .slice(0, 6)
            // .filter((member) => !_existingScorerIds.includes(member._id))
            .map((member, index) => (
              <ScorerItem.Newbie
                key={member?._id ? member?._id : index}
                sbid={sbid}
                member={member}
                disabled={_existingScorerIds.includes(member?._id)}
                style={[
                  _existingScorerIds.includes(member?._id) && { opacity: 0.4 },
                ]}
              />
            ))}
          {/* <Txt>{JSON.stringify(selectedScorerIds)}</Txt> */}
        </Kitten.Card>
      </>
    );
  } else {
    return (
      <ScorerItem.Newbie
        key={userD?._id}
        sbid={sbid}
        member={userD}
        disabled={_existingScorerIds.includes(userD?._id)}
        style={[_existingScorerIds.includes(userD?._id) && { opacity: 0.4 }]}
      />
    );
  }
}
