//#region [import]

import { useBreakpointIndex, useSx } from "dripsy";
import { StyleProp, useWindowDimensions, View, ViewStyle } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { IS_WEB, scale, spacing } from "utils";
//#endregion

export function useTwoColumns() {
  const sx = useSx();
  const safe = useSafeAreaInsets();
  const window = useWindowDimensions();
  const breakpoint = useBreakpointIndex();

  let col2BottomBarHeight = scale(69);

  //#region [section] column 1 container style
  let column1ContainerStyle: StyleProp<ViewStyle> = IS_WEB
    ? sx({
        height: "100%",
        width: [
          "100%",
          "100%",
          "100%",
          window.width - 293 - (window.width / 2 - 614 * 0.8) - spacing(3), //* 100% - col2_width - col2_padding_right - gap
          window.width - 293 - (window.width / 2 - 614 * 0.8) - spacing(3),
        ],
        paddingLeft: [
          0,
          0,
          0,
          window.width / 2 - 614 * 0.8,
          window.width / 2 - 614 * 0.8,
        ], // Change this to "paddingRight" for 3-column concept
        paddingBottom: safe.bottom + spacing(3) + col2BottomBarHeight,
      })
    : sx({
        width: [
          "100%",
          "100%",
          "100%",
          window.width - 293 - (window.width / 2 - 614 * 0.8) - spacing(3), //* 100% - col2_width - col2_padding_right - gap
          window.width - 293 - (window.width / 2 - 614 * 0.8) - spacing(3),
        ],
        paddingBottom: safe.bottom + spacing(3) + col2BottomBarHeight,
      });
  //#endregion

  //#region [section2] breakpoint for layout transformation
  let breakpointForTwoColumns = breakpoint > 2;
  let column1PaddingBottom = safe.bottom + spacing(3) + col2BottomBarHeight;
  const ViewCol1BottomSpace = () =>
    !breakpointForTwoColumns && (
      <View
        style={{
          width: "100%",
          height: column1PaddingBottom,
        }}
      />
    );
  return {
    /**
     * ### A "block" to give column 1 extra bottom space to stay away from horizontal "column 2"
     */
    ViewCol1BottomSpace,
    column1PaddingBottom,
    /**
     * ### Column 1 dynamic container style
     * -  In the main Flatlist/ScrollView of column 1,
     * apply this in contentContainerStyle
     * to fit column 1 with 2-column layout
     */
    column1ContainerStyle,
    /**
       * ### Breakpoint to fully display 2 columns
       * 
       * ---
       * @example
       *  const SectionIn2ndColumn = (props) => {
          const { breakpointForTwoColumns } = useTwoColumns();
  
          React.useEffect(function triggering() {
            !breakpointForTwoColumns &&
              (whateverFunction(), Navigation.goBack());
          }, []);
  
          return breakpointForTwoColumns ? (
            <Kitten.Card disabled>
              <Kitten.MenuItem
                title={"Click here to run whateverFunction()"}
                onPress={whateverFunction}
              />
            </Kitten.Card>
          ) : (
            <View />
          );
        *
        *
        * ---
        * @description Example explanation:
        * - On the web's large screen (**breakpointForTwoColumns == true**):
        *   `SectionIn2ndColumn` displays as a section in the 2nd column
        *     - In this section, there's a `Kitten.MenuItem` in which 
        *   user will **click to trigger whateverFunction()**
        * 
        * - On native & web's smaller screen 
        *   `SectionIn2ndColumn` displays as a button
        *   - This button, instead of navigate to an overlay as normal, 
        *   will trigger whateverFunction() directly
        * 
       */
    breakpointForTwoColumns,
  };
}
