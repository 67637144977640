// import Backend from "backend/";
import { img } from "assets";
import { Buttoon, sstyled, Txt } from "components/";
import { useAppContext, useConfigCollection } from "engines";
import React from "react";
import { Image, Linking, View } from "react-native";
import { DEVICE_HEIGHT, DEVICE_WIDTH, IS_WEB, scale, spacing } from "utils";
const modalHeight = DEVICE_HEIGHT * 0.85;

/**
 * ###
 *  - This screen pops one when ever the app is outdated
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.04.02
 *  -  *Brief changelog*
 *  @author  NL
 *
 **/
/**
 * Prompts user to update their app
 */
export function SH_VersionUpdate() {
  const { teamId } = useAppContext();
  const configHandler = useConfigCollection();
  let appConfig = configHandler._getConfigFromSubDomain(
    // IS_WEB ? null : app_team_id
    IS_WEB ? null : teamId
  );

  let updateURL = appConfig.updateURL;

  return (
    <View
      style={{
        flex: 1,
        height: modalHeight,
        justifyContent: "space-around",
        paddingBottom: spacing(7),
      }}
    >
      <$_Prompt appTitle={appConfig.appTitle} />
      <$_App
        appTitle={appConfig.appTitle}
        teamId={teamId}
        logo={appConfig?.appLogo}
      />
      <$_Install appTitle={appConfig.appTitle} updateURL={updateURL} />
    </View>
    // </Modalize>
  );
}

function $_Prompt(props: _PromptP) {
  return (
    <View
      style={{
        paddingHorizontal: spacing(6),
      }}
    >
      <Txt
        style={{
          fontWeight: "bold",
          textAlign: "center",
        }}
        category="h5"
      >
        Update Available!
      </Txt>
      <Txt
        category="p1"
        style={{
          marginTop: spacing(3),
          textAlign: "center",
        }}
      >
        A more excellent version of your app is ready to be installed.
      </Txt>
    </View>
  );
}

function $_App(props: _AppP) {
  const { appTitle, teamId, logo } = props;
  //   const appTitle = appConfig.appTitle;
  const imageDimension = DEVICE_WIDTH * 0.27;
  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <A.TeamLogo
        source={
          teamId == "app-takeoff-team"
            ? img.appLogo
            : {
                uri: logo,
              }
        }
      />
      {/* <Image
        // source={APP_LOGO}
        source={{
          uri: `https://get-my.app/t/${Backend.firestoreHandler._config.variables?.appTitle?.replace(
            / /g,
            ""
          )}/logo.png`,
        }}
        style={{
          width: imageDimension,
          height: imageDimension,
          borderRadius: 17,
          marginBottom: spacing(2),
        }}
      /> */}
      <Txt category="s1" style={{ fontWeight: "bold" }}>
        {appTitle}
      </Txt>
    </View>
  );
}

function $_Install(props: _InstallP) {
  const { appTitle, updateURL } = props;
  //   const { updateURL } =
  const app_name = appTitle?.replace(/ /g, "").toLowerCase();
  const defaultUpdateURL = `https://get-my.app/t/${app_name}/?update=1`;
  return (
    <View
      style={{
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <Buttoon
        icon={{ name: "install" }}
        onPress={() => {
          const url = updateURL?.length > 0 ? updateURL : defaultUpdateURL;
          Linking.openURL(url);
        }}
      >
        Install Update
      </Buttoon>
    </View>
  );
}

const Styles = {
  modalStyle: {
    marginTop: spacing(4),
    // backgroundColor: C.surface01,
    padding: spacing(3),
    borderWidth: 1,
    // borderColor: C["color-primary-500"],
    borderBottomWidth: 0,
  },
};

const A = {
  TeamLogo: sstyled(Image)({
    width: [scale(60), scale(70), scale(80)],
    height: [scale(60), scale(70), scale(80)],
    borderRadius: 10,
    backgroundColor: "dim",
    margin: spacing(4),
  }),
};

interface _PromptP {
  appTitle: string;
}

interface _AppP {
  appTitle: string;
  teamId: string;
  logo?: string;
}

interface _InstallP {
  appTitle: string;
  updateURL?: string;
}
