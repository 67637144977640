//#region [import]
import { StackActions } from "@react-navigation/native";
import { IndexPath, Input } from "@ui-kitten/components";
import { IconPrimr, ICON_NAME } from "assets";
import {
  dAccessory,
  Inpuut,
  Kitten,
  ListHeader,
  Poppy,
  sstyled,
  Toasty,
  Txt
} from "components/atoms";
import { APIS, ScoreboardSchema, useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useScoreboardDocument } from "engines/firebase/handler/scoreboard-handler";
import React from "react";
import { ActivityIndicator, View } from "react-native";
import { NAVIGATION_ROUTE, useNavigator } from "screens/_navigation";
import { scale, tr } from "utils";
//#endregion

/**
 * ### A quick screen for a quick start
 *
 * ----
 * @example As is
 * ----
 * @version 1.1.19
 * -  *Add fool-proof try-catch*
 * @author nguyenkhooi
 */
export function ScoreboardInfo(props: P) {
  const { sbid, isEditing = false, from, onChangeFilterDays } = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  const scoreboardHandler = useScoreboardDocument(sbid);
  const { data, update } = scoreboardHandler;

  //#region [section] Edit title/subtitle
  const [editVisibility, setEditVisibility] =
    React.useState<boolean>(isEditing);
  const [editedTitle, setEditedTitle] = React.useState<string>(data?.title);
  const [editedSubtitle, setEditedSubtitle] = React.useState<string>(
    data?.subtitle
  );
  const [filterIndex, setFilterIndex] = React.useState<number>(1);
  const refTitle = React.useRef<Input>();
  React.useEffect(
    function setupValue() {
      data?.title && setEditedTitle(data?.title);
      data?.title && setEditedSubtitle(data?.subtitle);
    },
    [data]
  );
  //#endregion

  const MenuIcon = (name: ICON_NAME, props: dAccessory) => (
    <IconPrimr
      name={name}
      size={props.style.width}
      color={props.style.tintColor}
    />
  );

  try {
    if (scoreboardHandler.loading) {
      return <ActivityIndicator />;
    }
    const _indexPath = new IndexPath(filterIndex);
    return (
      <View>
        {scoreboardHandler.data?.apiTo ===
          APIS.GO_HIGH_LEVEL_APPOINTMENTS_SET ||
        scoreboardHandler.data?.apiTo === APIS.GO_HIGH_LEVEL_SALES ||
        scoreboardHandler.data?.apiTo === APIS.GO_HIGH_LEVEL_POWER_BILLS ? (
          <>
            <ListHeader titleTx={tr("Filter")} />
            <Kitten.Select
              selectedIndex={_indexPath}
              onSelect={(path) => {
                const idx = (path as IndexPath).row;

                // 7 days, 30 days, 0 days is all time
                const days = idx === 0 ? 7 : idx === 1 ? 30 : 0;
                // NOTE: ScoreboardInfo is used in mutltiple ways depending on screen size, this is likely best for mobile, but see TwoColumn setup for web
                onChangeFilterDays && onChangeFilterDays(days);

                setFilterIndex(idx);
              }}
              value={
                filterIndex === 0
                  ? "Past 7 days"
                  : filterIndex === 1
                  ? "Past 30 days"
                  : "All time (2023)"
              }
            >
              <Kitten.SelectItem title="Past 7 days" />
              <Kitten.SelectItem title="Past 30 days" />
              <Kitten.SelectItem title="All time (2023)" />
            </Kitten.Select>
          </>
        ) : null}
        {from == "sboard-scr" ? null : (
          <ListHeader
            titleTx={
              editVisibility ? tr("Editing...") : tr("About this scoreboard")
            }
            actions={[
              userProfile._isUserAdmin() && {
                label: editVisibility ? tr("Close") : tr("Edit"),
                onPress: () => {
                  setEditedTitle(data?.title);
                  setEditedSubtitle(data?.subtitle);
                  setEditVisibility(editVisibility == true ? false : true);
                },
                status: editVisibility ? "danger" : "primary",
              },
            ]}
          />
        )}

        <A.CardContainer
          style={editVisibility && { borderColor: C.adminGold, borderWidth: 1 }}
          // disabled
          footer={() =>
            userProfile._isUserAdmin() && editVisibility ? (
              <A.CtnrMenu
                selectedIndex={null}
                onSelect={(index) => {
                  // setSelectedIndex(index);
                  switch (index.row) {
                    case 0:
                      Poppy(
                        tr("Final Check"),
                        tr("Do you want to reset this scoreboard?"),
                        {
                          text: "Cancel",
                          onPress: () => {},
                        },
                        {
                          text: "Yes",
                          onPress: async () => {
                            scoreboardHandler.resetScore();
                            Toasty.show(tr("Scoreboard reset"));
                          },
                        }
                      );

                      break;
                    case 1:
                      Poppy(
                        tr("Final Check"),
                        tr("Do you want to delete this scoreboard?"),
                        {
                          text: "Cancel",
                          onPress: () => {},
                        },
                        {
                          text: "Yes",
                          onPress: async () => {
                            await scoreboardHandler.deleteDocument();

                            Navigation.dispatch(StackActions.replace("Home"));
                            Toasty.show(tr("Scoreboard deleted"));
                          },
                        }
                      );
                      break;
                  }
                }}
              >
                <Kitten.MenuItem //* Admin stuff
                  title={tr("Reset all scores")}
                  accessoryLeft={(props: dAccessory) => MenuIcon("x", props)}
                />
                <Kitten.MenuItem //* Admin stuff
                  title={tr("Delete scoreboard")}
                  accessoryLeft={(props: dAccessory) =>
                    MenuIcon("trash", props)
                  }
                />
              </A.CtnrMenu>
            ) : null
          }
        >
          {editVisibility ? (
            <>
              <Inpuut
                ref={refTitle}
                title={tr("Title") + " *"}
                value={editedTitle}
                onChangeText={setEditedTitle}
                onSavePress={() => {
                  editedTitle === ""
                    ? (Toasty.show(tr("Cannot leave title field blank"), {
                        type: "warning",
                      }),
                      refTitle.current?.focus())
                    : (update({ title: editedTitle }),
                      Toasty.show(tr("Scoreboard title updated!")));
                }}
              />
              <Inpuut
                title={tr("Description")}
                multiline
                textStyle={{ minHeight: scale(111) }}
                value={editedSubtitle}
                onChangeText={setEditedSubtitle}
                onSavePress={() => {
                  update({ subtitle: editedSubtitle });
                }}
              />
            </>
          ) : (
            <>
              {data?.subtitle ? (
                <Txt.S1>{data?.subtitle}</Txt.S1>
              ) : (
                <Txt.Helper marginV>{tr("No description")}</Txt.Helper>
              )}
            </>
          )}
        </A.CardContainer>
      </View>
    );
  } catch (error) {
    throw Error(`::U2NvcmVib2FyZEFjdGlvbnM=::${error}`);
  }
}

const A = {
  CardContainer: sstyled(Kitten.Card)((p) => ({
    width: "100%",
  })),
  CtnrMenu: sstyled(Kitten.Menu)({
    minWidth: 8,
  }),
};

/**
 * ###  Screen Props
 */
interface P {
  sbid: ScoreboardSchema["_sbid"];
  isEditing?: boolean;
  from?: NAVIGATION_ROUTE;
  /**
   * How many days ago results should be filtered to display
   */
  onChangeFilterDays?: (days: number) => void;
  // route?: { params?: dScrParams }; //* unmask if we pass params thru rnav
}
