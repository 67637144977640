import { IndexPath } from "@ui-kitten/components";
import { Avt, Kitten, sstyled, Txt } from "components/atoms";
import { useBreakpointIndex } from "dripsy";
import { MediaPageSchema } from "engines";
import { usePagesCollection } from "engines/firebase/handler/pages-handler";
import { isLevelPageV2 } from "engines/functions/level-functions/level-page-utils";
import * as R from "ramda";
import React from "react";
import { View } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import { scale, spacing, tr } from "utils";

/**
 * ### Set a link to go to specific url (webpage, custom page...)
 *  - Used in `button-item-creator`, `posst-creator`
 *
 *  ----
 *  @version 21.09.08
 *  -  *Build a Component*
 *  @nguyenkhooi
 *
 **/
export function LinkTo({
  onChange,
  flexDirection,
  linkscustom,
  defaultText,
  disableOptions,
}: dLinkTo) {
  // const { C, teamId } = useAppContext();
  // const Navigation = useNavigator();
  const breakpoints = useBreakpointIndex();

  /**
   * TODO: notes
   */
  const indexOffset = 1;

  //#region [FRBS]
  const pagesHandler = usePagesCollection();

  const [pages, setPages] = React.useState<MediaPageSchema[]>([]);
  const [autoSelectPageId, setAutoSelectPageId] = React.useState<string>(null);
  const [newSubPageTitle, setNewSubPageTitle] = React.useState<string>("");
  const autoSelectedPage =
    autoSelectPageId &&
    pagesHandler.data?.filter((_pg) => _pg._id === autoSelectPageId).pop();

  React.useEffect(
    function setupData() {
      pagesHandler.data && setPages(pagesHandler.data);
    },
    [pagesHandler.data]
  );
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    setQuery(value);
    const newData = pagesHandler.data?.filter((page) => {
      return page?.name?.toLowerCase()?.includes(value.toLowerCase());
    });
    // console.log("valuee: ", newData);
    setPages([...newData]);
  }
  //#endregion

  //#region [section2] Create new page
  // const userProfile = useUserProfile();

  // function onCreateSubpage() {
  //   userProfile._isUserAdmin() &&
  //     Navigation.dialogPortal.open({
  //       headerTitle: tr("Enter Page Name"),
  //       render: (
  //         <DocumentCreatorWithTitle
  //           onCreate={(pageName) => {
  //             const __toast = Toasty.show(tr("Creating new subpage..."), {
  //               type: "loading",
  //             });
  //             pagesHandler
  //               .add({
  //                 ...__MediaResourcesData({
  //                   teamId,
  //                   pageName,
  //                 }),
  //                 mediaItem: { visible: false },
  //                 position: pagesHandler._largestPositionIndex() + 1,
  //               })
  //               .then((id) => {
  //                 console.log("new id: ", id);
  //                 setQuery("");
  //                 setAutoSelectPageId(id);
  //                 onChange && onChange("page:" + id);
  //                 // kittenSelect.current.props.onSelect(new IndexPath(indexOffset))
  //                 Toasty.update(__toast, tr("New subpage created!"), {
  //                   type: "success",
  //                 });
  //               });
  //           }}
  //         />
  //       ),
  //     });
  // }
  //#endregion

  // let linkscustomNum = fn.js.linkPageNumber(linkscustom ? linkscustom : "");
  const [selectedTypeIndex, setSelectedTypeIndex] = React.useState(0);
  // const [_caption, setCaption] = React.useState<string>(
  //   'Add a page name, or an url link starting with "www."'
  // );

  // const kittenSelect = React.useRef<Select>(null);

  let sortedPages = R.sortWith<MediaPageSchema>(
    [
      /** sorting `name` in `asc` */
      R.ascend(R.prop("name")),
    ],
    pages
  );

  const [_selectedPageIndex, setSelectedPageIndex] =
    React.useState<IndexPath>(null);
  const selectedPageIndex = autoSelectedPage
    ? new IndexPath(sortedPages.indexOf(autoSelectedPage) + indexOffset)
    : _selectedPageIndex;
  const [url, setUrl] = React.useState<string>(linkscustom ? linkscustom : "");

  React.useEffect(
    function validateWebUrl() {
      if (url) {
        let validUrl = new RegExp(/^https?:\/\//);

        if (!validUrl.test(url)) {
          let httpsLink = "https://" + url;
          console.log("the url is NOT a valid URL");
          onChange && onChange(httpsLink);
        } else {
          console.log("the url IS a valid URL");
          onChange && onChange(url);
        }
      }
    },
    [url]
  );

  React.useEffect(
    function onDisableOptions() {
      if (disableOptions?.includes("url")) {
        setUrl("");
      } else if (disableOptions?.includes("blankPage")) {
        setNewSubPageTitle("");
      } else if (disableOptions?.includes("existingPage")) {
        setSelectedPageIndex(null);
      }
    },
    [disableOptions]
  );

  React.useEffect(
    function val() {
      if (newSubPageTitle && selectedTypeIndex === 0) {
        onChange && onChange("newpage:" + newSubPageTitle);
      }
    },
    [newSubPageTitle]
  );
  React.useEffect(
    function val() {
      if (newSubPageTitle?.length < 1 && selectedTypeIndex === 0 && defaultText)
        onChange && onChange("newpage:" + defaultText);
    },
    [defaultText]
  );

  // const [newPageName, setNewPageName] =
  //   React.useState<string>("Add a new page");

  return React.useMemo(() => {
    try {
      if (pagesHandler.error)
        return <Txt.Indicator>{"Error: " + pagesHandler.error}</Txt.Indicator>;

      if (!pagesHandler.data)
        return <Txt.Indicator>{tr("Loading...")}</Txt.Indicator>;

      /**
       * We have to subtract 2 to get the correct item, this takes into account the search for pages input and the create new page button
       */
      const displayValue = selectedPageIndex
        ? sortedPages[selectedPageIndex.row - indexOffset]?.name
        : null;

      let _radioGroupDirection = () => {
        if (flexDirection) {
          return flexDirection;
        } else {
          return breakpoints < 3 ? "column" : "row";
        }
      };
      return (
        <>
          <Kitten.RadioGroup
            style={{ flexDirection: _radioGroupDirection() }}
            selectedIndex={selectedTypeIndex}
            onChange={(index) => {
              index != 0 && setNewSubPageTitle("");
              index != 1 && setSelectedPageIndex(null);
              index != 2 && setUrl("");
              if (selectedTypeIndex !== 0 && index === 0) {
                onChange && onChange("newpage:" + defaultText);
                setNewSubPageTitle(defaultText);
              } else onChange && onChange("");
              setSelectedTypeIndex(index);
            }}
          >
            {/* <Kitten.Radio>{tr("None")}</Kitten.Radio> */}
            <Kitten.Radio disabled={disableOptions?.includes("blankPage")}>
              {tr("Create blank page")}
            </Kitten.Radio>
            <Kitten.Radio disabled={disableOptions?.includes("existingPage")}>
              {tr("Go to existing page")}
            </Kitten.Radio>
            <Kitten.Radio disabled={disableOptions?.includes("url")}>
              {tr("Go to Webpage (or document)")}
            </Kitten.Radio>
          </Kitten.RadioGroup>
          <View style={{ marginVertical: spacing(3) }}>
            {
              //#region [section] URL creator
              selectedTypeIndex == 2 && ( //* CASE 1: Enter a url
                <Kitten.Input
                  placeholder={tr("Insert url here")}
                  value={url}
                  onChangeText={setUrl}
                />
              )
              //#endregion
            }

            {
              //#region [section2] existing page creator
              selectedTypeIndex == 1 && ( //* CASE 2: Select an existing page
                <View>
                  <Txt.Helper marginV>{tr("Existing pages")}</Txt.Helper>
                  {/* <PagesFeed mode="alacarte" /> */}
                  <Kitten.ViewH>
                    <Kitten.Select
                      // ref={kittenSelect}
                      style={{ flexGrow: 1 }}
                      placeholder={tr("Select a page")}
                      value={displayValue}
                      selectedIndex={selectedPageIndex}
                      onSelect={(index: IndexPath) => {
                        console.log("[page_select] selected index: " + index);
                        setSelectedPageIndex(index);
                        // setCaption("Nice!");

                        // console.log(
                        //   "[page_select] autoselect pageid1: " + autoSelectPageId
                        // );
                        // if (autoSelectPageId) {
                        //   /**
                        //    * After a new page gets created, auto select it
                        //    */
                        //   onChange && onChange("page:" + autoSelectPageId);
                        // } else {
                        if (onChange) {
                          const __page = sortedPages[index.row - indexOffset];
                          __page &&
                            onChange(
                              (isLevelPageV2(__page?.name)
                                ? "level:"
                                : "page:") + __page?._id
                            );
                        }
                        // }

                        setAutoSelectPageId(null);
                      }}
                      // caption={_caption}
                      // style={{ maxWidth: 293 - 60 }}
                    >
                      <A.InputSearch
                        autoFocus={true}
                        value={query}
                        placeholder={tr("Search page by name")}
                        onChangeText={(value) => {
                          setQuery(query);
                          setSelectedPageIndex(null);
                          filterHandler(value);
                        }}
                      />
                      {sortedPages?.map((page, index) => (
                        <Kitten.SelectItem
                          key={page._id}
                          title={page.name}
                          accessoryLeft={() => (
                            <Avt
                              name={page.name || "K"}
                              source={{ uri: page.mediaItem?.logo || "" }}
                            />
                          )}
                        />
                      ))}
                    </Kitten.Select>
                    {/* <Buttoon
                  size={"small"}
                  appearance={"ghost"}
                  onPress={onCreateNewPage}
                  style={{ flexShrink: 1 }}
                >
                  {tr("Create a subpage")}
                </Buttoon> */}
                  </Kitten.ViewH>
                </View>
              )
              //#endregion
            }
            {
              //#region [section] subpage creator
              selectedTypeIndex == 0 && (
                <Kitten.Input
                  placeholder={tr("Type new page's name")}
                  value={
                    newSubPageTitle?.length < 1
                      ? defaultText
                        ? defaultText
                        : newSubPageTitle
                      : newSubPageTitle
                  }
                  onChangeText={setNewSubPageTitle}
                />
              )
              //#endregion
            }
            {/* <Txt>{JSON.stringify(autoSelectPageId)}</Txt> */}
          </View>
        </>
      );
    } catch (error) {
      throw Error("::TGlua1Rv::" + error);
    }
  }, [
    query,
    selectedPageIndex,
    selectedTypeIndex,
    pages,
    url,
    newSubPageTitle,
    defaultText,
    disableOptions,
  ]);
}

export enum LINKTO {
  "PAGE" = "page",
  "NEWPAGE" = "newpage",
  "LEVEL" = "level",
}

interface dLinkTo {
  /**
   * `link` will be either:
   *  - a url (start with http), or
   *  - a page id (start with page:PAGE_ID)
   * it'll be ALL store on url field of PageItemSchema,
   * since `toPage` field may contain page's name according to the old schema
   */
  onChange(link: string): void;
  linkscustom?: string;
  flexDirection: "row" | "column";
  defaultText?: string;
  disableOptions?: string[];
}

const A = {
  InputSearch: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
};
