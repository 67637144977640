import { useLinkTo } from "@react-navigation/native";
import { IndexPath } from "@ui-kitten/components";
import { Kitten, Skeleton, sstyled } from "components";
import { useAppContext } from "engines";
import { rSheets, useSheets } from "engines/hooks";
import * as R from "ramda";
import React from "react";
import { ScrollView } from "react-native";
import { useNavigator } from "screens";
import { dFaqScrParams } from "screens/_navigation/faq.navigator";
import { spacing, tr } from "utils";
import { FAQCard } from "./c.faq-card";

export function FaqHomeScreen() {
  const { C } = useAppContext();
  //#region [section] DATA
  const { data } = useSheets(0, "FAQ"); //* Upstream data
  /**
   *  Memoized og data, used when u resets filter
   */
  const ogData = React.useMemo(() => data, [data]);
  /**
   * Filtered data
   */
  const [_data, setData] = React.useState<rSheets[]>(data);
  //#endregion

  const Navigation = useNavigator();

  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const linkTo = useLinkTo();

  if (R.isEmpty(data)) return <Skeleton.Screen.Possts />;
  try {
    return (
      <SS.Sctnr>
        <SS.CtnrFilter
          horizontal={true}
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}
        >
          <Kitten.MenuItem
            onPress={() => {
              setData(ogData);
              setSelectedIndex(new IndexPath(0));
            }}
            title={tr("All")}
          />
          {[...new Set(R.pluck("cat", ogData))].map((cat, index) => (
            <Kitten.MenuItem
              key={cat + index}
              onPress={() => {
                setData([...ogData.filter((item) => item.cat === cat)]);
                setSelectedIndex(new IndexPath(index + 1));
              }}
              title={cat}
            />
          ))}
        </SS.CtnrFilter>
        <SS.CtnrGrid>
          {R.isEmpty(_data)
            ? data.map((item, index) => (
                <FAQCard
                  onPress={() => {
                    linkTo("/faq/" + item.title);
                    Navigation.navigate<dFaqScrParams>("faq-scr", {
                      faqBody: item,
                    });
                  }}
                  item={item}
                />
              ))
            : _data.map((item, index) => (
                <FAQCard
                  onPress={() => {
                    linkTo("/faq/" + item.title);
                    Navigation.navigate<dFaqScrParams>("faq-scr", {
                      faqBody: item,
                    });
                  }}
                  item={item}
                />
              ))}
        </SS.CtnrGrid>
      </SS.Sctnr>
    );
  } catch (error) {
    throw Error(`::RmFxSG9tZVNjcmVlbg==::${error}`);
  }
}

const SS = {
  Sctnr: sstyled(ScrollView)((p) => ({
    maxHeight: p.HEIGHT,
    minWidth: 300,
    flex: 1,
    backgroundColor: "background",
    // borderRightWidth: 1,
  })),
  CtnrFilter: sstyled(Kitten.Menu)((p) => ({
    flexDirection: "row",
    marginVertical: spacing(5),
    borderRadius: 10,
    borderWidth: 0,
    borderBottomWidth: 1,
    alignSelf: "flex-start",
    overflow: "hidden",
    borderColor: "dim",
    marginLeft: spacing(5),
  })),
  CtnrGrid: sstyled(Kitten.TouchableWeb)({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  }),
  CtnrCard: sstyled(Kitten.TouchableWeb)({
    // Responsive -> 100% on smaller screens, 50% on larger screens
    width: "100%",
    margin: 3,
    height: 9,
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: spacing(5),
    borderRadius: 10,
  }),
};
