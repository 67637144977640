import { Kitten, Poppy, Toasty, Txt, VideoItem } from "components";
import React from "react";
import { View } from "react-native";
import firebase from "firebase";
import { MediaPageItemSchema, typeOfItem, useAppContext } from "engines";
import { dVideoWatch } from "engines/backends/schemas/bml";

interface d { }

/**
 * ### View public content
 *  @version 23.09.14
 *  @author  jm_francis
 **/
export function PublicContent(props: d) {
  const { } = props;

  const { teamId } = useAppContext()

  //   const [uri, setUri] = React.useState<string>(null);
  const [item, setItem] = React.useState<MediaPageItemSchema>(null);
  //   const _typeOfItem = typeOfItem(item);

  //http://localhost:19006/content-item/EzpnA2B93Jxf1K75hboF/V9uD3CAb4sQ5tgrYXK8o
  //FORMAT: https://subdomain.apptakeoff.com/content-item/pageId/itemId?sid=${sharer_uid}&rid=${recipient_phone_number_or_email}
  // TODO: make sure to encode the url
  const pageId = window.location?.pathname?.split("/")[2];
  const itemId = window.location?.pathname?.split("/")[3];
  const searchParams = new URLSearchParams(window?.location?.search);
  // may be null
  const sharerUid = searchParams.get("sid")
  // may be null
  const recipientPhoneNumber = searchParams.get("rpn").replace(/ /g, "") // represented by phone # or email
  // const sharerUid = window.location?.href
  //   ?.split("?")
  //   ?.pop()
  //   ?.replace("sid=", "");
  React.useEffect(function loadContent() {
    firebase
      .functions()
      //@ts-ignore
      .httpsCallable("fetchPublicContentItem")({ pageId, itemId })
      .then((response) => {
        if (response.data.error) {
          Poppy(`Content couldn't be loaded`, response.data.error)
          console.error(response.data.error)
          return;
        }
        const pageItem = response.data.item as MediaPageItemSchema;
        // Toasty.show(response.data.item?.media || "No url found.");
        setItem(pageItem);
      })
      .catch((err) => alert(err));
  }, []);

  const startedAt = new Date();

  const refLastUpdatedMillis = React.useRef<number>(0);
  const refRecipientFrbsPaths = React.useRef<string[]>(null);

  return (
    <>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        {item ? (
          <View style={{ width: 800, height: 450 }}>
            <VideoItem
              onDurationChange={async (positionMillis, totalMillis) => {
                const percentage = positionMillis / totalMillis
                let tenSecondsInMillis = 1000 * 10
                // save progress every 10-15 seconds?
                // this function does not require all information, but does require pageId and itemId fosho
                if (refLastUpdatedMillis.current === 0 || (positionMillis - refLastUpdatedMillis.current >= tenSecondsInMillis)) {
                  console.log(`Updating video watch progress at milli marker ${positionMillis}`)
                  refLastUpdatedMillis.current = positionMillis
                  const _response = await firebase.functions().httpsCallable("updateVideoWatchProgress")({
                    pageId: pageId,
                    itemId: item._id,
                    progressDuration: totalMillis,
                    progressMillis: positionMillis,
                    title: item.title,
                    // startedAt: startedAt,
                    // uid: "anonymous",
                    // url: item.media,
                    // watched: 1,
                    sharerUid: sharerUid,
                    recipientPhoneNumber,
                    _teamId: teamId,
                    ...(refRecipientFrbsPaths.current ? { recipientFrbsPaths: refRecipientFrbsPaths.current } : {})
                  } as dVideoWatch & { recipientFrbsPaths?: string[] });
                  /** this is so we do not have to determine which contacts to update every single time */
                  if (_response.data?.recipientFrbsPaths && _response.data?.recipientFrbsPaths?.length > 0) {
                    refRecipientFrbsPaths.current = _response.data.recipientFrbsPaths
                  }
                }
              }}
              onComplete={async () => {
                try {
                  //@ts-ignore timestamp conversion
                  const response = await firebase.functions().httpsCallable("addContentView")({
                    pageId: pageId,
                    itemId: item._id,
                    title: item.title,
                    startedAt: startedAt,
                    uid: "anonymous",
                    url: item.media,
                    watched: 1,
                    sharerUid: sharerUid,
                    recipientPhoneNumber,
                    _teamId: teamId,
                  } as dVideoWatch);
                  // Toasty.show("Watch recorded.", { type: "normal" });
                } catch (err) {
                  alert(err);
                }
              }}
              playInView={true}
              item={{ ...item, disableShare: true }}
              cpid={pageId}
              serviceType={typeOfItem(item).serviceType}
            />
          </View>
        ) : (
          <>
            <Txt.H3>Video Loading</Txt.H3>
            <Kitten.Spinner />
          </>
        )}
      </View>
    </>
  );
}

// function ContentHelmet({ title, image }) {
//   return (
//     <Helmet>
//       {title ? <title>{title}</title> : null}
//       {image ? <meta property="og:image" content={image} /> : null}
//     </Helmet>
//   );
// }
