import { Kitten, ListHeader, Txt } from "components";
import {
  dateToStr,
  fn,
  getNormalDate,
  useActivityCollection,
  useAppContext,
  useConfigDocument
} from "engines";
import * as R from "ramda";
import React from "react";
import { SectionList } from "react-native";
import { spacing, tr } from "utils";

// let date = new Date();

// const getPastDate = (distanceFromToday = 0) => {
//   let d = new Date();
//   d.setDate(d.getDate() - distanceFromToday);
//   return d;
// };

export function UserStatsFeed(props: { uid?: string }) {
  const { uid } = props;
  // const { column1ContainerStyle } = useTwoColumns();
  // const Navigation = useNavigator();
  const { C, frbsUser, teamId, theme } = useAppContext();

  const userActivities = useActivityCollection(uid || frbsUser?.uid);

  const configDoc = useConfigDocument("variables-" + teamId);

  const [sectionizeData, setSectionizeData] = React.useState<
    { title: Date; data: any[] }[]
  >([]);
  function groupActivitiesByDate() {
    let _rawData = userActivities?.data;
    let _activitiesDB = configDoc?.data?.activities || [];
    let dateData = _rawData?.map((data) => ({
      ...data,
      date: fn.calendar.dateFormat(data?.date?.toDate()),
      metadata: _activitiesDB?.filter((db) => db.key === data?.activityKey)[0],
    }));
    let activitiesDates = [...new Set(R.pluck("date", dateData))];

    let _sectionizedData = activitiesDates
      .map((date) => ({
        title: date,
        data: dateData?.filter((data) => data?.date === date),
      }))
      .sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.title) - new Date(a.title);
      });
    setSectionizeData(_sectionizedData);
    // setSectionizeData(dateData);
    // setSectionizeData(activitiesDates);
  }

  React.useEffect(
    function sectionizedData() {
      userActivities?.data && configDoc?.data && groupActivitiesByDate();
    },
    [userActivities?.data, configDoc?.data]
  );

  // function onAddStatsPress() {
  //   return hasUserReported()
  //     ? null
  //     : Navigation.dialogPortal.open({
  //         headerTitle: tr("Enter Today's Report"),
  //         render: <StatsCreatorCenter />,
  //       });
  // }

  function hasUserReported() {
    return userActivities.data?.find(
      (ac) => dateToStr(getNormalDate(ac?.date)) === dateToStr(new Date())
    );
  }

  // return (
  //   <ScrollView contentContainerStyle={column1ContainerStyle}>
  //     <Txt>{frbsUser?.uid}</Txt>
  //     <Txt>{JSON.stringify(sectionizeData, null, "\t")}</Txt>
  //     {/* <Txt>{JSON.stringify(userActivities?.data, null, "\t")}</Txt> */}
  //   </ScrollView>
  // );
  // return (
  //   <ScrollView contentContainerStyle={column1ContainerStyle}>
  //     <View>
  //       {/* <TodoCenter /> */}
  //       {/* <DashboardCardGrid /> */}
  //       {/* <LevelsDashboardCenter memberId={frbsUser?.uid} type="user" /> */}
  //     </View>
  //     <MyDaySection />
  //     <MyJourneySection />
  //   </ScrollView>
  // );
  return !userActivities.data || userActivities.data?.length < 1 ? (
    <Txt.S1 style={{ textAlign: "center", marginVertical: spacing(5) }}>
      No recorded activity
    </Txt.S1>
  ) : (
    <SectionList
      // ListHeaderComponent={() => (
      //   <View>
      //     {/* <TodoCenter /> */}
      //     <DashboardCardGrid />
      //     <ListHeader titleTx={tr("Your Learning Progress")} />
      //     <LevelsDashboardCenter memberId={frbsUser?.uid} type="admin" />
      //     <ListHeader
      //       titleTx={tr("Your daily report")}
      //       actions={[
      //         {
      //           label: hasUserReported()
      //             ? tr("🎉 Hooray, you've finished your daily report!")
      //             : tr("Report My day"),
      //           icon: { name: "plus" },
      //           onPress: onAddStatsPress,
      //         },
      //       ]}
      //     />
      //     {/* <StreaksCenter uid={frbsUser?.uid} /> */}
      //   </View>
      // )}
      stickyHeaderIndices={[0]}
      // contentContainerStyle={column1ContainerStyle}
      sections={sectionizeData}
      keyExtractor={(item, index) => item + index}
      ItemSeparatorComponent={() => (
        <Kitten.Divider style={{ backgroundColor: C.grey600 }} />
      )}
      renderItem={({ item }) => (
        <Kitten.Layout level="3">
          <Kitten.ListItemv2
            title={() => (
              <Txt.S1 numberOfLines={0}>
                {item?.metadata?.title || tr("Question deleted")}
              </Txt.S1>
            )}
            description={item?.metadata?.description}
            accessoryRight={() => {
              switch (item?.metadata?.entryType) {
                case "number":
                  return (
                    <Txt.H5 style={{ maxWidth: 300 }}>{item?.value}</Txt.H5>
                  );
                  break;
                case "text":
                  return <Txt style={{ maxWidth: 300 }}>{item?.value}</Txt>;
                  break;
                case "checkbox":
                  return (
                    <Txt.H5 style={{ maxWidth: 300 }}>
                      {item?.value ? "✅" : "❌"}
                    </Txt.H5>
                  );
                  break;

                default:
                  return <Txt style={{ maxWidth: 300 }} />;
                  break;
              }
            }}
            disabled={!item?.metadata?.title} //* If question doesn't existed, it'll be grey out
            style={{ backgroundColor: "transparent" }}
          />
        </Kitten.Layout>
      )}
      renderSectionHeader={({ section: { title } }) => (
        <ListHeader title={title} disabled style={{ marginTop: spacing(3) }} />
      )}
      renderSectionFooter={() =>
        !userActivities.data || userActivities.data?.length < 1 ? (
          <Txt.S1>No recorded activity</Txt.S1>
        ) : null
      }
    />
  );
}
