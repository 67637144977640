import { IconPrimr, ICON_NAME } from "assets";
import {
  Kitten,
  Poppy,
  sstyled,
  Toasty,
  Txt,
  Videolly,
  VimeoVideolly,
  YoutubeVideolly
} from "components";
import { Avt } from "components/atoms";
import { BlastSmsComposer } from "components/molecules/HitEmUp/hitemupSms";
import {
  dVimeoToken,
  fn,
  FRBS_ROLE,
  MediaPageItemSchema,
  MediaPageSchema,
  recordUnknownUserAction,
  ServiceType,
  URL_PREFIX,
  useAppContext,
  UserSchema
} from "engines";
import { dUseDocumentReturns } from "engines/firebase/config";
import { forsureDate } from "engines/functions/calendar-functions";
import { levelItemString } from "engines/functions/level-functions/level-page-utils";
import * as Clipboard from "expo-clipboard";
import * as React from "react";
import { Platform, Share, useWindowDimensions, View } from "react-native";
import { IS_WEB, scale, spacing, tr } from "utils";
import { ACTION_TYPE, dContentView } from "../../../../engines/backends/schemas/users/user.schema";
import { useMemberDocument } from "../../../../engines/firebase/handler/members-handler";
import { useViewHistoryForUrl } from "../../../../engines/firebase/handler/view-history-handler";
import { useNavigator } from "../../../../screens/_navigation/navigation-utils";
import { Buttoon } from "../../../atoms/generals/buttoon/Buttoon";

export const RECIPIENT_PHONE_PLACEHOLDER = "PHONE_NUMBER"
export function generateTrackableUrl(subdomain: string, cpid: string, itemId: string, senderUid: string, recipientPhoneNumber?: string): string {
  return `https://${subdomain}.apptakeoff.com/content-item/${cpid}/${itemId}?sid=${senderUid}&rpn=${recipientPhoneNumber || RECIPIENT_PHONE_PLACEHOLDER}`
}

/**
 * ### Video Item of custom page
 *  - Fetch and play video from Cloudinary ✅, Vimeo 🔜, Dropbox 🔜, Gdrive 🔜
 *  ----
 *  @version 21.06.09
 *  -  *Build Component*
 *  @author  K
 *
 **/
export function VideoItem({
  pageHandler,
  item,
  cpid,
  serviceType,
  memberHandler,
  onComplete,
  editModeEnabled,
  onFoundSuccessfulVimeoToken,
  playInView,
  onDurationChange
}: // complete,
  // onComplete,
  dVideoItem) {
  const { media, title } = item;

  const { C, frbsUser } = useAppContext();
  const Navigation = useNavigator();

  //#region [FRBS]
  // const userProfile = useUserProfile();
  // const memberHandler = useMemberDocument(frbsUser?.uid);
  // const pageHandler = usePageDocument(cpid);
  // const pageContentHandler = usePageContentDocument(cpid, item._id);
  // const pageItem = pageContentHandler.data;

  const [itemString, setItemString] = React.useState<string | null>(null);
  const [isItemCompleted, setIsItemCompleted] = React.useState<boolean>(false);

  React.useEffect(
    function getItemDetails() {
      const istr = pageHandler
        ? levelItemString(pageHandler?.data, item)
        : item._id;

      istr && setItemString(istr || item._id);
      istr &&
        memberHandler &&
        setIsItemCompleted(
          memberHandler?.data?.completedLevelItems?.includes(istr)
        );
    },
    [pageHandler?.data, memberHandler?.data]
  );

  //#endregion

  const refItemComplete = React.useRef<boolean>(false);

  function completeItem(_itemString: string) {
    // NOTE: will fire onComplete once when done even if it's been watched before so we can track multiple watches
    // if (
    //   isItemCompleted ||
    //   memberHandler.data?.completedLevelItems?.includes(_itemString) ||
    //   refItemComplete.current === true
    // ) {
    //   return;
    // }
    refItemComplete.current = true;
    const istr = itemString || _itemString;
    console.log("[complete] completing " + istr);
    if (istr) {
      setIsItemCompleted(true);
      onComplete && onComplete(istr);
      // memberHandler.update({
      //   completedLevelItems: firebase.firestore.FieldValue.arrayUnion(itemString),
      // });
    } else {
      // for some reason itemString is null
      Toasty.show("Failed to mark item as complete. Id is " + istr, {
        type: "error",
      });
    }
  }

  // const refVideo = React.useRef<Video>();

  const VIDEO_ITEMS: dVideoItemsDict = {
    [ServiceType.CLOUDINARY]: {
      key: "cloudinary",
      icon: "videocam",
      VideoFrame: (
        <Videolly
          playInView={playInView}
          // ref={refVideo}
          type="component"
          source={{ uri: media }}
          // thumbnail={item?.thumbnail}
          itemString={itemString}
          onComplete={completeItem}
          editModeEnabled={editModeEnabled}
          cpid={cpid}
          pageItem={item}
          onDurationChange={(positionMillis, totalMillis) => onDurationChange && onDurationChange(positionMillis, totalMillis)}
        />
      ),
    },
    [ServiceType.YOUTUBE]: {
      key: "youtube",
      icon: "youtube",
      VideoFrame: (
        <YoutubeVideolly
          item={item}
          videoId={fn.media.getYoutubeId(item.media)}
          itemString={itemString}
          onComplete={completeItem}
        />
      ),
    },
    [ServiceType.VIMEO]: {
      key: "vimeo",
      icon: "vimeo",
      VideoFrame: (
        <VimeoVideolly
          type="component"
          cpid={pageHandler?.data?._id}
          item={item}
          itemString={itemString}
          onComplete={completeItem}
          onFoundSuccessfulToken={onFoundSuccessfulVimeoToken}
          onDurationChange={(positionMillis, totalMillis) => onDurationChange && onDurationChange(positionMillis, totalMillis)}
        />
      ),
    },
  };

  const { width } = useWindowDimensions();

  return React.useMemo(
    () => (
      <A.Ctnr>
        {itemString && serviceType ? VIDEO_ITEMS[serviceType].VideoFrame : null}
        <Kitten.ViewH
          style={{ justifyContent: "space-between", flexWrap: "wrap" }}
        >
          <Kitten.ViewH
            style={{
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxWidth: width,
            }}
          >
            <Kitten.ViewH style={{ marginTop: spacing(1) }}>
              <IconPrimr
                preset="circular"
                name={VIDEO_ITEMS[serviceType].icon}
                color={C.text}
                size={scale(12)}
              />
              <Txt numberOfLines={2} style={{ maxWidth: "95%" }}>
                {item?.title}
                {/* {serviceType} */}
              </Txt>
            </Kitten.ViewH>
            <Kitten.ViewH style={{}}>
              {memberHandler &&
                memberHandler?.data?.roles?.includes(FRBS_ROLE.ADMIN) ? (
                <Buttoon
                  appearance="ghost"
                  status="info"
                  size="small"
                  icon={{ name: "eye", size: 11 }}
                  onPress={() => {
                    Navigation.dialogPortal.open({
                      headerTitle: tr(`${item.title} Views`),
                      render: <MediaViews itemUrl={item.media} />,
                    });
                  }}
                >
                  {tr("See Views")}
                </Buttoon>
              ) : null}
              {item.disableShare ? null : (
                <Buttoon
                  appearance="ghost"
                  status={item.trackable ? "warning" : "info"}
                  size="small"
                  icon={{ name: "share", size: 11 }}
                  onPress={async () => {
                    if (item.trackable) {
                      const trackableUrl = generateTrackableUrl("unleash", cpid, item._id, frbsUser?.uid)
                      // alert(trackableUrl)
                      if (IS_WEB) {
                        await Clipboard.setString(trackableUrl)
                        Poppy("Trackable Video", "Replace " + RECIPIENT_PHONE_PLACEHOLDER + " in the url with the recipient's phone number before sending to enable tracking.")
                        Toasty.show("Copied url to clipboard", { type: "normal" })
                      } else {
                        Navigation.dialogPortal.open({
                          headerTitle: "Trackable Video",
                          render: <BlastSmsComposer defaultBlastBody={`\n${trackableUrl}`} trackableVideoItem={item} />
                        })
                      }
                      return;
                    }
                    if (IS_WEB) {
                      await Clipboard.setString(item?.media);
                      Toasty.show("Copied to clipboard", { type: "success" });
                    } else {
                      Share.share({
                        message: `${item.title || ""}${Platform.OS === "android" ? `\n${item.media}` : ""}`,
                        ...(Platform.OS === "ios" ? { url: item.media } : {}), // ios only
                      });
                      recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.CONTENT_SHARE, `${frbsUser?.uid} shared video \"${item.title}\" from \"${pageHandler.data?.name || "unknown page"}\"`, { involvedPartyName: item.title, thumbnail: item.thumbnail || fn.media.getThumbnailFromURL(item.media), location: `${URL_PREFIX}${cpid}/${item._id}` })
                    }
                  }}
                >
                  {tr("Share")}
                </Buttoon>
              )}
            </Kitten.ViewH>
          </Kitten.ViewH>
        </Kitten.ViewH>
      </A.Ctnr>
    ),
    [isItemCompleted, item, editModeEnabled, itemString, serviceType] // isItemCompleted
  );
}

function groupByUid(array: dContentView[]): dContentView[][] {
  const result = [];

  // Loop through the array
  array.forEach((item) => {
    // Check if a result array for the current uid already exists
    let group = result.find((group) => group[0].uid === item.uid);

    // If not, create a new result array
    if (!group) {
      group = [item];
      result.push(group);
    }

    // If yes, add the item to the existing result array
    else {
      group.push(item);
    }
  });

  return result;
}

export function MediaViews(p: { itemUrl: string }) {
  const { itemUrl } = p;
  const { teamId } = useAppContext();
  const Navigation = useNavigator()
  const itemViewsHandler = useViewHistoryForUrl(teamId, itemUrl);
  // const usersWhoViewed = useMembersCollection({
  //   where: ["completedLevelItems", "array-contains", itemId],
  // });
  const [groupedViews, setGroupedViews] = React.useState<dContentView[][]>([]);
  const [itemViews, setItemViews] = React.useState<dContentView[]>([]);

  // "View more"
  const [listItemMax, setListItemMax] = React.useState<number>(5);
  let maxedOut = listItemMax >= groupedViews?.length

  React.useEffect(
    function setupPackedViews() {
      if (!itemViewsHandler.data) return;
      if (itemViewsHandler.data.length < 1) {
        setGroupedViews([]);
        return;
      }
      const _itemViews = itemViewsHandler.data?.filter((a) => a._teamId === teamId || !a._teamId)
      const _gViews: dContentView[][] = groupByUid(
        _itemViews
      );
      setGroupedViews(_gViews);
      setItemViews(_itemViews);
    },
    [itemViewsHandler.data]
  );

  function UserViewItem(p: { userViews: dContentView[] }) {
    const { userViews } = p;
    const memberHandler = useMemberDocument(userViews[0]?.uid);

    const memberName =
      memberHandler?.data?.personali?.displayName ||
      memberHandler?.data?.personali?.email ||
      "Trackable Views";

    if (memberName === "Trackable Views") return null;

    return (
      <Kitten.ListItemv2
        onPress={() => {
          if (memberName === 'Trackable Views') {
            Navigation.dialogPortal.open({
              headerTitle: "Trackable Views",
              render: <TrackableViews itemViews={itemViews} />
            })
          }
        }}
        accessoryLeft={() => (
          <Avt
            style={{ marginRight: spacing(2) }}
            name={memberName}
            source={{ uri: memberHandler?.data?.personali?.photoURL || "" }}
          />
        )}
        title={memberName}
        description={
          userViews.length + " view" + (userViews.length > 1 ? "s" : "")
        }
      />
    );
  }

  return itemViewsHandler.data ? (
    <>
      <Txt.H6
        style={{ marginBottom: spacing(3) }}
      >{`${itemViews?.filter(v => v.uid !== "anonymous")?.length} in-app views`}</Txt.H6>
      <Kitten.List
        data={groupedViews.slice(0, maxedOut ? groupedViews.length : listItemMax)}
        renderItem={(data) => <UserViewItem userViews={data.item} />}
      />
      <Buttoon
        disabled={maxedOut}
        appearance="ghost"
        size="small"
        onPress={() => {
          setListItemMax(listItemMax + 30)
        }}>{tr("View more")}</Buttoon>

      {itemViews.find(cv => cv.uid === "anonymous") ? <TrackableViews itemViews={itemViews} /> : null}
    </>
  ) : (
    <Txt.H6>Loading...</Txt.H6>
  );
}

function TrackableViews(p: { itemViews: dContentView[] }) {
  const { itemViews } = p;

  const sortedViews: dContentView[] = itemViews.filter(cv => cv.uid === "anonymous" && cv.watched === 1).sort((a, b) => (forsureDate(b?.startedAt)?.getTime() || 0) - (forsureDate(a?.startedAt)?.getTime() || 0))

  // "View more"
  const [listItemMax, setListItemMax] = React.useState<number>(5);
  let maxedOut = listItemMax >= sortedViews?.length

  function ViewItem(_p: { view: dContentView }) {
    const { view } = _p
    const sharerHandler = useMemberDocument(view.sharerUid)
    const sharerName = sharerHandler.data ? sharerHandler.data?.personali?.displayName : view.sharerUid
    const _startedAt = view.startedAt ? forsureDate(view.startedAt) : null;
    return <>
      <Kitten.ListItemv2
        accessoryLeft={() => (
          <Avt
            style={{ marginRight: spacing(2) }}
            name={sharerName}
            source={{ uri: sharerHandler?.data?.personali?.photoURL || "" }}
          />
        )}
        title={`${view?.recipientName || "Recipient"} watched video sent by ${sharerName}`}
        description={`Viewed ${_startedAt ? `${fn.calendar.niceDateFormat(_startedAt)} ${fn.calendar.timeToAMPMString(_startedAt)}` : ""}`}
      />
      <Kitten.Divider />
    </>
  }

  return <>
    <Txt.H6 style={{ marginBottom: spacing(2) }}>{`${sortedViews.length} trackable views`}</Txt.H6>
    <Kitten.List data={sortedViews.slice(0, maxedOut ? sortedViews?.length : listItemMax)} renderItem={data => <ViewItem view={data.item} />} />
    <Buttoon
      disabled={maxedOut}
      appearance="ghost"
      size="small"
      onPress={() => {
        setListItemMax(listItemMax + 30)
      }}>{tr("View more")}</Buttoon>
  </>
}

const A = {
  Ctnr: sstyled(View)({
    paddingVertical: spacing(2),
    justifyContent: "center",
    alignItems: "stretch",
  }),
  CtnrVideoItem: sstyled(Kitten.Card)({ alignItems: "center" }),
  CtnrPlaybackControl: sstyled(View)({
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "center",
  }),
  PosstVideo: sstyled(Videolly)({
    width: "100%",
    height: [8, 8, 9, 12, 12],
  }),
};

interface dVideoItem {
  /** Please provide this */
  pageHandler?: dUseDocumentReturns<MediaPageSchema>;
  item: MediaPageItemSchema;
  cpid: string;
  serviceType: ServiceType;
  // accessTokens: dConfigKeys;
  /** PLEASE provide :D */
  memberHandler?: dUseDocumentReturns<UserSchema>;
  onComplete?: (itemString: string) => void;
  /**
   * Primary reason is to be able to set the thumbnail for the video
   */
  editModeEnabled?: boolean;
  /** instead of opening a modal, not recommended though */
  playInView?: boolean;
  /**
   * When a vimeo access token and user id pair is found that works to play this video, this function will pass you that data in case you want to save it for the future!
   */
  onFoundSuccessfulVimeoToken?: (
    vimeoToken: dVimeoToken,
    itemString: string
  ) => void;
  /** does not work with every video type */
  onDurationChange?: (positionMillis: number, totalMillis: number) => void
  // complete?: boolean;
  // onComplete?(): void;
}

interface dVideoItemsDict {
  [service: string]: {
    key: string;
    VideoFrame:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
    icon: ICON_NAME;
  };
}
