import { IconPrimr } from "assets";
import { Avt, Buttoon, Kitten, ListHeader, Poppy, Toasty, Txt } from "components";
import { fn, FRBS_ROLE, useAppContext, useMemberDocument, useUserProfile } from "engines"
import { LinkURL } from "engines/functions/web-functions";
import { Dimensions, ImageBackground, Linking, Pressable, View } from "react-native"
import { ScrollView } from "react-native-gesture-handler";
import { useNavigator } from "screens";
import { ActionsFeed } from "screens/team-mates/TeamMatesSceen";
import { spacing, THEME, themeDark, tr } from "utils";
import { TodoCenter } from "../personal-activity/TodoCenter";
import * as SMS from "expo-sms";
import { ContactTeamCenter } from "./ContactTeamCenter";
import { EditMember } from "./EditMember";
import { TrainingProgressOverview } from "./member-info";
import { UserStatsOverview } from "../personal-activity/UserStatsOverview";
import { UserStatsFeed } from "../personal-activity/UserStatsFeed";

export interface MemberViewNavigationParams {
    userName: string;
    uid: string;
}

export function MemberView(p: { uid: string, route: any, navigation: any }) {
    const uid = p.uid ? p.uid : p.route?.params?.uid
    const userName = p.route?.params?.userName
    const { C, theme } = useAppContext()
    const Navigation = useNavigator()
    const memberHandler = useMemberDocument(uid)
    const memberD = memberHandler.data

    const myselfHandler = useUserProfile()

    if (userName) Navigation.setOptions({ title: userName })

    const itemStyle = {
        alignItems: "center",
        justifyContent: "center",
        width: 64,
        height: 64,
        borderRadius: 10,
        opacity: 0.8,
        backgroundColor: C.surface,
        marginHorizontal: spacing(2)
    };
    const itemTitleStyle = {
        textAlign: "center",
        marginTop: 1
    }
    //   const icon_name: ICON_NAME = "dot"
    //   const iconStyle = { position: "absolute", bottom: spacing(1) }
    // const iconSize = 9.5
    const iconSize = 17

    const boxLevel = "1"

    const canAccessPrivateData = myselfHandler._isRolesContain([FRBS_ROLE.ADMIN]) || memberD?.growth?.listBuilder?.shareTo?.includes(myselfHandler.data?._id)

    return (memberD ? <ScrollView style={{ flex: 1 }} contentContainerStyle={{ backgroundColor: C.background }}>
        <ImageBackground
            imageStyle={{ opacity: 0.85 }}
            source={{ uri: fn.media.smallerSizeFromURL(memberD?.personali?.photoURL, Dimensions.get("window").width * 3) }}
            blurRadius={18}
            style={{
                backgroundColor: "orange",
                marginBottom: spacing(3), borderRadius: 10, marginHorizontal: spacing(2), paddingTop: spacing(3), paddingHorizontal: spacing(2),
                overflow: "hidden",
            }}>

            <View style={{ justifyContent: "center", alignItems: 'center' }}>
                <View>
                    <Avt
                        style={{
                            borderWidth: 1.1,
                            borderColor: C.text,
                            width: 75,
                            height: 75,
                        }}
                        source={{ uri: fn.media.smallerSizeFromURL(memberD?.personali?.photoURL, 75 * 5) }}
                        name={memberD?.personali?.displayName || "# #"}
                    />
                </View>
                <View style={{ marginLeft: spacing(4), marginTop: spacing(1), alignItems: "center" }}>
                    <Txt.H6 style={{ textShadowColor: "black", textShadowRadius: 1.25, shadowOpacity: 0.3, color: themeDark.text }}>{memberD?.personali?.displayName || "           "}</Txt.H6>
                    {memberD.roles?.includes(FRBS_ROLE.TRAINER) ? <Kitten.ViewH style={{ marginTop: -4 }}>
                        <Txt.P2 style={{ fontWeight: "bold", color: themeDark.text }}>{memberD.roles?.includes(FRBS_ROLE.TRAINER) ? "Trainer" : "Trainee"}</Txt.P2>
                        <IconPrimr name="check_circle" color={themeDark.text} size={11} />
                    </Kitten.ViewH> : null}
                    {memberD?.lastSignIn ? <Txt.Helper style={{ marginTop: -4, color: themeDark.text }}>{`Last seen ${fn.calendar.socialFriendlyTime(fn.calendar.forsureDate(memberD?.lastSignIn)).toLowerCase()}`}</Txt.Helper> : null}
                </View>
            </View>

            <Kitten.ViewH style={{ position: "absolute", top: spacing(2), right: spacing(2) }}>
                <Buttoon status="control" appearance="filled" size="tiny"
                    style={{ marginRight: spacing(1), backgroundColor: "#F29E39", borderColor: "#F29E39" }}
                    textStyle={{ color: "white" }}
                    forceTheme={THEME.DARK}
                    onPress={() => {
                        Poppy("🔥 🔥 🔥", `This represents app activity over a 3-day period.`, { text: "Ok", onPress: () => { } }, {
                            text: "View Activity",
                            onPress: () => {
                                Navigation.overlayPortal.open({
                                    headerTitle: "Member Activity",
                                    render: <ActionsFeed feedType="user" uid={uid} />
                                })
                            }
                        })
                    }}>
                    {`${memberD?.stats?.rollingActionPoints || 0} 🔥`}
                </Buttoon>
                <Buttoon status="control" size="tiny"
                    forceTheme={THEME.DARK}
                    onPress={() => {
                        Navigation.overlayPortal.open({
                            headerTitle: "Member Activity",
                            render: <ActionsFeed feedType="user" uid={uid} />
                        })
                    }}>
                    {`${memberD?.stats?.totalActionPoints || 0} points`}
                </Buttoon>
            </Kitten.ViewH>

            <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{ flex: 1 }} contentContainerStyle={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Kitten.ViewH
                    style={{
                        flex: 1,
                        maxWidth: 430,
                        justifyContent: "space-between",
                        alignItems: "center",
                        // paddingHorizontal: spacing(4),
                        paddingVertical: spacing(3),
                        // borderRadius: 10,
                        paddingHorizontal: spacing(2),
                        // minWidth: Dimensions.get("window").width * 0.9,
                        alignSelf: "center"
                    }}
                >
                    {canAccessPrivateData ? <Pressable
                        onPress={() => {
                            Navigation.overlayPortal.open({
                                headerTitle: `${memberD.personali?.displayName?.split(" ")[0]}'s Contacts` || "Member's Contacts",
                                render: <ContactTeamCenter userId={uid} />,
                            });
                        }}
                    >
                        <Kitten.Layout level={boxLevel} style={itemStyle}>
                            <IconPrimr name="contacts" color={C.text} size={iconSize} />
                            <Txt.P2 style={itemTitleStyle}>{tr("Contacts")}</Txt.P2>
                        </Kitten.Layout>
                    </Pressable> : null}

                    <Pressable onPress={() => {
                        Navigation.overlayPortal.open({
                            headerTitle: `${memberD?.personali?.displayName?.split(" ")[0]}'s Training Progress`,
                            render: <TrainingProgressOverview user={memberD} onSaveChanges={user => {
                                memberHandler.update(user)
                            }} />
                        })
                    }}>
                        <Kitten.Layout level={"1"} style={itemStyle}>
                            <IconPrimr name="trophy" color={C.text} size={iconSize} />
                            <Txt.P2 style={itemTitleStyle}>{"Training"}</Txt.P2>
                        </Kitten.Layout>
                    </Pressable>

                    {canAccessPrivateData ? <Pressable
                        onPress={() => {
                            Navigation.overlayPortal.open({
                                headerTitle: tr(`${memberD?.personali?.displayName}'s Tasks`),
                                render: <TodoCenter uid={uid} />,
                            });
                        }}
                    >
                        <Kitten.Layout level={boxLevel} style={itemStyle}>
                            <IconPrimr name="check_circle" color={C.text} size={iconSize} />
                            <Txt.P2 style={itemTitleStyle}>{tr("Tasks")}</Txt.P2>
                        </Kitten.Layout>
                    </Pressable> : null}

                    <Pressable onPress={() => {
                        Navigation.overlayPortal.open({
                            headerTitle: `${memberD?.personali?.displayName?.split(" ")[0]}'s Stats`,
                            render: <>
                                <UserStatsOverview uid={uid} showStreakShortcut />
                                <UserStatsFeed uid={uid} />
                            </>
                        })
                    }}>
                        <Kitten.Layout level={boxLevel} style={itemStyle}>
                            <IconPrimr name="star" color={theme === THEME.DARK ? C.adminGold : C.hazardYellow} size={iconSize} />
                            <Txt.P2 style={[itemTitleStyle, { color: theme === THEME.DARK ? C.adminGold : C.hazardYellow }]}>
                                {tr("Stats")}
                            </Txt.P2>
                        </Kitten.Layout>
                    </Pressable>

                    {memberD.schedulingLink ? <Pressable onPress={() => {
                        LinkURL(memberD?.schedulingLink)
                    }}>
                        <Kitten.Layout level={"1"} style={itemStyle}>
                            <IconPrimr name="calendar" color={C.text} size={iconSize} />
                            <Txt.P2 style={itemTitleStyle}>{"Schedule"}</Txt.P2>
                        </Kitten.Layout>
                    </Pressable> : null}

                </Kitten.ViewH>
            </ScrollView>

            {myselfHandler._isRolesContain([FRBS_ROLE.DEVELOPER, FRBS_ROLE.ADMIN]) ? <Kitten.ViewH style={{ position: "absolute", top: 0, left: 0 }}>
                <Buttoon icon={{ name: "cog" }} size="small" appearance="ghost" status="control" forceTheme={THEME.DARK} onPress={() => {
                    Navigation.overlayPortal.open({
                        headerTitle: "Member Settings",
                        render: <EditMember
                            user={memberD}
                            onSaveChanges={(updatedUser) => {
                                memberHandler.update(updatedUser)
                            }} />
                    })
                }}>Edit</Buttoon>
            </Kitten.ViewH> : null}

        </ImageBackground>
        {/* <UserStatsOverview uid={uid} onlyShowMonth={new Date()} showHistoryShortcut showStreakShortcut /> */}

        <Kitten.Layout level="1" style={{ marginHorizontal: spacing(3), paddingHorizontal: spacing(3) }}>
            <ListHeader titleTx={`${memberD?.personali?.displayName?.split(" ")[0]}'s Info`} />
            <Kitten.ViewH style={{ justifyContent: "space-between" }}>
                <Txt.S2>{fn.js.formatPhoneNumber(memberD?.personali?.phoneNumber)}</Txt.S2>
                <Kitten.ViewH>
                    <Buttoon size="small" appearance="ghost" status="control" icon={{ name: "message" }} onPress={async () => {
                        const result = await SMS.isAvailableAsync()
                        if (result) {
                            SMS.sendSMSAsync(memberD?.personali?.phoneNumber, "")
                        } else {
                            Poppy("Mobile only", `On your phone, you can text: ${memberD?.personali?.phoneNumber}`)
                        }
                    }}>Text</Buttoon>
                    <Buttoon size="small" appearance="ghost" status="control" icon={{ name: "phone" }} onPress={() => {
                        Linking.openURL(`tel:${memberD?.personali?.phoneNumber}`)
                    }}>Call</Buttoon>
                </Kitten.ViewH>
            </Kitten.ViewH>
            <Kitten.ViewH style={{ justifyContent: "space-between" }}>
                <Txt.S2>{memberD?.personali?.email}</Txt.S2>
                <Buttoon size="small" appearance="ghost" status="control" icon={{ name: "email" }} onPress={() => {
                    Linking.openURL(`mailto:${memberD?.personali?.email}`)
                }}>Send email</Buttoon>
            </Kitten.ViewH>
        </Kitten.Layout>

        <Kitten.Layout level="1" style={{ alignItems: "center", paddingVertical: spacing(3), marginHorizontal: spacing(3) }}>
            {memberD.createdAt ? <Txt.P2>{`Member since ${fn.calendar.forsureDate(memberD?.createdAt)?.toLocaleDateString()}`}</Txt.P2> : null}
            {myselfHandler._isRolesContain([FRBS_ROLE.ADMIN, FRBS_ROLE.DEVELOPER]) ? <Txt.P2>{`v${memberD?.mobileVersion}`}</Txt.P2> : null}
        </Kitten.Layout>

        <ActionsFeed feedType="user" uid={uid} />
    </ScrollView> : <Kitten.Spinner />)
}