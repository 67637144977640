import { IndexPath } from "@ui-kitten/components";
import { Buttoon, Kitten, Poppy, Toasty } from "components";
import { BlastSmsComposer } from "components/molecules/HitEmUp/hitemupSms";
import { TwoColumns } from "components/templates";
import { useAppContext, useUserProfile } from "engines";
import {
  getBMLContactsFromDB,
  getUserContactGroup,
  createBMLContactGroup,
  updateUserBMLListToDB,
} from "engines/backends/auth/AuthHandler";
import {
  mergeArrays,
  pointersList,
} from "engines/backends/schemas/bml/bml.schema";
import {
  CONTACTGROUPTYPE,
  ContactSchemaa,
  MACHO,
} from "engines/backends/schemas/bml/contacts/contacts.schema";
import { dListBuilderList, FPATH } from "engines/firebase/firebase.props";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import * as Contacts from "expo-contacts";
import firebase from "firebase";
import _ from "lodash";
import React, { useState } from "react";
import { ActivityIndicator, Alert } from "react-native";
import { useNavigator } from "screens/_navigation";
import { spacing, tr } from "utils";
import { BMLFeed } from "./BMLFeed";

export interface BMLListProps {
  bmlListData?: ContactSchemaa[];
  route?: { params: dBMLScrParams };
}

interface dBMLScrParams {
  bmlListData: ContactSchemaa[];
}

export interface SLData {
  _id: string;
  title: string;
  points: number;
  data: ContactSchemaa[];
}

export const BMLList = (props: BMLListProps) => {
  const { C, frbsUser, teamId } = useAppContext();
  const {
    qualificationData,
    getPointers,
    getQualificationNames,
    bmlContacts,
    setBmlContacts,
    refreshList,
    setRefreshList,
  } = useBMLMContext();
  const user = useUserProfile();

  // const bmlListData = props?.route?.params?.bmlListData;

  //TODO Testing setup
  // const bmlListData = _MOCK_BMLList_DATA;

  const [bmlContactsList, setBmlContactsList] =
    useState<ContactSchemaa[]>(bmlContacts);

  const [contactIds, setContactIds] = useState<string[]>([]);
  const [slData, setSlData] = useState<SLData[]>([]);

  const [ready, setReady] = useState(false);

  const Navigation = useNavigator();
  const [machoFilter, setMachoFilter] = useState<string[]>([]);

  const [selectedIndex, setSelectedIndex] = useState<IndexPath[]>([]);
  const [filteredSlData, setFilteredSlData] = useState<SLData[]>([]);
  const [originalData, setOriginalData] = useState<SLData[]>([]);

  const refDidAskToUploadToApi = React.useRef<boolean>(false);
  React.useEffect(function askToUploadToApiIfNeeded() {
    if (!bmlContactsList || bmlContactsList.length < 1 || refDidAskToUploadToApi.current === true) {
      return;
    }
    if (teamId === "legacy-builders-financial") {
      refDidAskToUploadToApi.current = true;
      for (let b in bmlContactsList) {
        if (bmlContactsList[b].apis?.flowtrackId) {
          // operation has been done before so do not ask about this again
          return;
        };
      }
      // made it through, never did the operation, let's prompt them
      Poppy("Send to API", `Would you like to save your contacts to the team's API?`, { text: "Later" }, {
        text: "Yes (recommended)", onPress: async () => {
          const __toast = Toasty.show("Exporting, please wait...", { type: "loading", duration: 10000 })
          try {
            const completionData = await exportContactsToFlowtrack(frbsUser.uid, bmlContactsList)
            Toasty.hide(__toast)
            if (!completionData.alreadyMadeContacts && !completionData.newContactsCreated && completionData.error) {
              Poppy("Operation incomplete", completionData.error)
              return;
            }
            Poppy("Process complete", `Saved ${completionData?.newContactsCreated} contacts to API.${completionData.alreadyMadeContacts > 0 ? `\n${completionData.alreadyMadeContacts} were already saved.` : ""}`)
          } catch (err) {
            Toasty.hide(__toast)
            console.error(err)
            Poppy("API Export Failed", err)
          }
        }
      })
    }
  }, [bmlContactsList]);

  React.useEffect(() => {
    if (refreshList) {
      if (bmlContacts.length > 0) {
        setBmlContactsList(bmlContacts);
        constructSLData();
      } else {
        Alert.prompt("Contact List is Empty", "Please start the process over");
        Navigation.goBack();
      }
      setRefreshList(false);
    }
  }, [bmlContacts, refreshList]);

  React.useEffect(() => {
    if (filteredSlData.length > 0) {
      setReady(true);
    }
  }, [filteredSlData]);

  React.useEffect(() => {
    if (selectedIndex.length > 0) {
      const values = selectedIndex.map((index) => {
        return qualificationData
          .filter((q) => q.isDisabled !== true)
          .find((q) => q._id === (index.row + 1).toString())?.name;
      });
      setMachoFilter(values);
      constructSLData();
    } else {
      setMachoFilter([]);
    }
  }, [selectedIndex]);

  const groupDisplayValue = selectedIndex.map((index) => {
    const value = qualificationData.find(
      (q) => q._id === (index.row + 1).toString()
    )?.name;
    return value;
  });

  React.useEffect(() => {
    setFilteredSlData(slData);
  }, [slData]);

  React.useEffect(() => {
    if (machoFilter.length === 0) {
      constructSLData();
    } else {
      setSlData(originalData);
    }
  }, [machoFilter]);

  React.useEffect(() => {
    const newFilteredData = [...slData];

    const filterFunction = (contact: ContactSchemaa) => {
      //NOTE change this "every" function to "some" if the filter selection
      //     is "Married" OR "Occupation" instead of "Married" AND "Occupation"
      return machoFilter.every((filterVal: string) =>
        getQualificationNames(contact.qualifications).includes(filterVal)
      )
        ? true
        : false;
    };

    if (machoFilter.length >= 1) {
      for (let index = 0; index < newFilteredData.length; index++) {
        const list = newFilteredData[index];
        list.data = list.data.filter(filterFunction);
        newFilteredData[index].data = list.data;
      }
      setFilteredSlData(newFilteredData);
    }
  }, [machoFilter]);

  //TODO Moving Logic to Migration Tool
  const createUserBMLListAndData = async () => {
    const defaultBMLList: dListBuilderList[] = [
      { title: "Build My List", id: "BML", contacts: bmlContactsList },
    ];

    //Step 5: Convert legacy data from BML to new data structure. (Priorities: A,B,C to Everyone else and points = [] (empty array))
    // Fetch Legacy Data List from user document
    // ==> Map it to ContactGroupSchema
    // ==> and remove growth.ListBuilder from user document

    const result = await createBMLContactGroup(user?.data?._id, defaultBMLList);

    return result;
  };

  //#region [section2] //TODO Don't remove this yet
  //   /**
  //    * @description Merges the data from two lists and maps it into the new schema.
  //    * @param legacyLists Data from legacy list (data previous version of the app).
  //    * @param newLists Data from the new list
  //    */
  //   const mergeOldAndNewLists = (
  //     legacyLists: ContactGroupSchema[],
  //     newLists: ContactGroupSchema[]
  //   ): ContactGroupSchema[] => {
  //     if (legacyLists.length === 0) {
  //       console.log("No Data in Legacy List");
  //       return newLists;
  //     }

  //     const mergedLists: ContactGroupSchema[] = [];
  //     newLists.forEach((newList) => {
  //       const legIndex = legacyLists.findIndex((l) => l._id === newList._id);
  //       // A similar list exists in legacy list data, let's merge it
  //       if (legIndex > 0) {
  //         const tempMergedContacts = mergeArrays(
  //           legacyLists[legIndex].contacts,
  //           newList.contacts,
  //           "_cid"
  //         );
  //         const tempMergedShareTo = [
  //           ...legacyLists[legIndex].shareTo,
  //           ...newList.shareTo,
  //         ];
  //         const mergedList: ContactGroupSchema = {
  //           _id: newList._id,
  //           name: newList.name,
  //           groupType: newList.groupType,
  //           contacts: tempMergedContacts,
  //           shareTo: tempMergedShareTo,
  //         };
  //         mergedLists.push(mergedList);
  //         delete legacyLists[legIndex];
  //       } else {
  //         mergedLists.push(newList);
  //       }
  //     });
  //     //Finally if there is anything left in legacyList, move it to mergedList
  //     if (legacyLists.length > 0) {
  //       legacyLists.forEach((legacyList) => {
  //         mergedLists.push(legacyList);
  //       });
  //     }
  //     return mergedLists;
  //   };
  //#endregion

  const getUserListData = async () => {
    //ANCHOR This will always return a value since post migration data will be there.
    let userLists = await getUserContactGroup(user?.data?._id);

    bmlContactsList.forEach((contact) => {
      contactIds.push(contact?._cid);
    });

    //Step 2: See if user has any lists available (length >0)
    if (!!userLists && userLists.length > 0) {
      //Step 3: If yes then check if there is "Build My List" list available & that it has data.
      if (!userLists.find((l) => l?._id === "BML")) {
        userLists.push({
          _id: "BML",
          name: "Build My List",
          groupType: CONTACTGROUPTYPE.LIST,
          //TODO change this to array of _cids
          contacts: _.uniq(contactIds),
          shareTo: [],
        });
      } else {
        const index = userLists.findIndex((l) => l._id === "BML");
        userLists[index].contacts = _.uniq(userLists[index].contacts);
        const existingBMLContacts = await getBMLContactsFromDB(
          user?.data?._id
          // _.uniq(userLists[index].contacts) // note sure why this was here if getBMLContactsFromDB doesn't have a field for it anyway so commented it out -jeremy
        );

        const mergedList: ContactSchemaa[] = mergeArrays(
          bmlContactsList,
          existingBMLContacts,
          "_cid"
        );
        setBmlContactsList(mergedList);
        userLists[index].contacts.push(...contactIds);
      }
    } else {
      //Step 4: If not(length===0) then create default list from config.variable-<hieararchy> (save to contact-groups)
      //        and save it with bmlList after finishing the activity.
      //TODO setUserListData() not needed since migration tool will handle it
      userLists = await createUserBMLListAndData();
    }
    userLists.forEach(async (userList) => {
      if (userList.shareTo.length > 0 || userList.contacts.length > 0)
        await updateUserBMLListToDB(user?.data?._id, userList, bmlContactsList);
    });
  };

  const constructSLData = () => {
    // console.log("bmlContactsList length = ", bmlContactsList.length);
    if (bmlContactsList) {
      const tempSLData: SLData[] = pointersList
        .map((list) => {
          return {
            ...list,
            data: bmlContactsList
              .filter((c) => {
                const _pts = getPointers(c?.qualifications);
                return _pts === list?.points || _pts > 5;
              })
              .sort((c1, c2) => {
                const c1Name = c1?.displayName || c1?.name;
                const c2Name = c2?.displayName || c2?.name;
                return c1Name?.localeCompare(c2Name);
              }),
          };
        })
        .sort((a, b) => (parseInt(a._id) < parseInt(b._id) ? 1 : -1));
      setSlData(tempSLData);
      setOriginalData(tempSLData);
    }
  };

  const handleRetake = () => {
    Poppy(
      "Good news!",
      "Your current contacts will not be removed, doing the exercise again will merge your new ones in!"
    );
    // console.log("bmlContacts = ", bmlContacts);
    const unqualifiedContacts = bmlContacts
      .filter(
        (c) =>
          !Object.keys(c).includes("qualifications") ||
          c?.qualifications?.length === 0
      )
      .filter((c) => !c?._cid.includes("uid:"));
    setBmlContacts(unqualifiedContacts);
    Navigation.goBack();
    Navigation.navigate("bml-mobile", {
      retake: true,
    });
  };

  React.useLayoutEffect(() => {
    Navigation.setOptions({
      headerRight: () => (
        <Buttoon
          //   icon={{ name: "refresh", right: true }}
          appearance="ghost"
          size={"large"}
          onPress={handleRetake}
        >
          {tr("Retake")}
        </Buttoon>
      ),
    });
  }, [Navigation]);

  React.useEffect(() => {
    //Step 1: Obtain the list from user's sub collection contact-groups
    getUserListData().then(() => {
      constructSLData();
    });
  }, []);

  try {
    return !ready ? (
      <ActivityIndicator
        style={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: C.background,
          flex: 1,
        }}
        size="large"
        color={C.primary}
      />
    ) : (
      <TwoColumns
        bottomInset
        column1Content={(scrollHandler) => (
          <>
            <Kitten.Select
              placeholder={"Filter Contacts By..."}
              style={{ margin: spacing(3) }}
              status={"primary"}
              multiSelect={true}
              selectedIndex={selectedIndex}
              //@ts-ignore
              onSelect={(index) => setSelectedIndex(index)}
              value={groupDisplayValue.join(", ")}
            >
              {qualificationData.map((qualify, index) => (
                <Kitten.SelectItem
                  key={qualify._id}
                  title={`${qualify.name}`}
                />
              ))}
            </Kitten.Select>
            <Kitten.ViewH style={{ justifyContent: "space-evenly" }}>
              <Buttoon
                status="info"
                size="small"
                style={{ margin: spacing(4) }}
                compact
                icon={{ name: "bullhorn" }}
                onPress={() => {
                  Navigation.overlayPortal.open({
                    headerTitle: "Send Blast Text",
                    render: (
                      <BlastSmsComposer
                        listData={[]}
                        shareMode={true}
                        contacts={convertToBlastSmsContacts(
                          filteredSlData,
                          frbsUser?.uid
                        )}
                      />
                    ),
                  });
                }}
              >
                {tr("Send Blast Text")}
              </Buttoon>
              {teamId === "legacy-builders-financial" ?
                <Buttoon icon={{ name: "list" }} size="small" status="control" onPress={() => {
                  Poppy("Are you sure?", `${bmlContactsList?.length} contacts will be exported.\nPlease wait for the operation to complete before going anywhere.`, { text: "Cancel" }, {
                    text: "Export", onPress: async () => {
                      const __toast = Toasty.show("Exporting, please wait...", { type: "loading", duration: 10000 })
                      try {
                        const completionData = await exportContactsToFlowtrack(frbsUser.uid, bmlContactsList)
                        Toasty.hide(__toast)
                        if (!completionData.alreadyMadeContacts && !completionData.newContactsCreated && completionData.error) {
                          Poppy("Operation incomplete", completionData.error)
                          return;
                        }
                        Poppy("Process complete", `Saved ${completionData?.newContactsCreated} contacts to API.${completionData.alreadyMadeContacts > 0 ? `\n${completionData.alreadyMadeContacts} were already saved.` : ""}`)
                      } catch (err) {
                        Toasty.hide(__toast)
                        console.error(err)
                        Poppy("API Export Failed", err)
                      }
                    }
                  })
                }}>{"API Export"}</Buttoon>
                : null}
            </Kitten.ViewH>
            <BMLFeed data={filteredSlData} />
          </>
        )}
        column2Content={[]}
      />
    );
  } catch (error) {
    throw Error(`::Qk1MTGlzdC50c3g=::${error}`);
  }
};

export async function exportContactsToFlowtrack(uid: string, contacts: ContactSchemaa[]): Promise<{
  error: any,
  newContactsCreated: number,
  alreadyMadeContacts: number
}> {
  const publicKey = "NO5DSRyDEUoNabv0eGvM"
  const privateKey = "Ec01DUR76wsmB5ux4q7Nq60mc0vleF"

  const updateContactIdsBatch = firebase.firestore().batch()
  let someFirebaseError;

  let newContactsCreated = 0;
  let alreadyMadeContacts = 0;
  for (let c in contacts) {
    const contact = contacts[c]
    if (contact?.apis?.flowtrackId) {
      ++alreadyMadeContacts
      continue;
    }
    const firstName = contact?.firstName?.length > 0 ? contact.firstName : contact?.name?.split(" ")[0]
    const lastName = contact?.lastName?.length > 0 ? contact.lastName : contact?.name?.replace(`${firstName} `, "")?.replace(firstName, "")
    const response = await fetch(`https://app.flowtrack.co/api/contact?public_key=${publicKey}&private_key=${privateKey}`, {
      method: "POST",
      body: JSON.stringify({
        country: "US",
        phone_country: "US",
        first_name: firstName,
        last_name: lastName,
        email: ((contact?.emails as Contacts.Email[])?.length > 0 ? (contact.emails as Contacts.Email[])[0]?.email : contact?.email) || "",
        phone: ((contact?.phoneNumbers as Contacts.PhoneNumber[])?.length > 0 ? (contact.phoneNumbers as Contacts.PhoneNumber[])?.find(pn => pn.isPrimary) || contact.phoneNumbers[0].number : ""),
        dial_code: "1",
        hasZip: false,
        hasState: false,
      }),
    })
    ++newContactsCreated
    const flowtrackData = await response.json()
    if (!flowtrackData?.data?.contact_id) {
      console.error(`Failed to create new flowtrack contact ${JSON.stringify(flowtrackData)}`)
      Poppy(`Operation failed (${newContactsCreated})`, JSON.stringify(flowtrackData))
      break;
    } else {
      // update firebase (in batch)
      try {
        updateContactIdsBatch.update(firebase.firestore().doc(`${FPATH.USERS}/${uid}/${FPATH.CONTACTS}/${contact._cid?.replace(/\/\//g, "")}`), {
          apis: {
            ...(contact.apis || {}),
            flowtrackId: flowtrackData?.data?.contact_id
          }
        } as Partial<ContactSchemaa>)
      } catch (err) {
        console.error(err)
        someFirebaseError = err;
        continue;
      }
    }
  }

  // firebase time
  if (someFirebaseError) {
    Poppy(`Server error`, `Everything may have worked but we noticed: ${someFirebaseError}`)
  }
  console.log("Updating firebase contact flowtrack ids...")
  await updateContactIdsBatch.commit()
  console.log("Updated for flowtrack ids complete")

  return {
    error: someFirebaseError,
    newContactsCreated,
    alreadyMadeContacts
  }
}

export const convertToBlastSmsContacts = (lists: SLData[], uid: string) => {
  const finalContacts: ContactSchemaa[] = [];
  lists.forEach((list, index) => {
    list.data.forEach((contact) => {
      const newContactData: ContactSchemaa = {
        createdAt: contact.createdAt,
        _cid: contact?._cid,
        ownerUid: uid,
        //@ts-ignore using arrayUnion to tell firestore to do a safe add to the array without adding duplicates
        groups: firebase.firestore.FieldValue.arrayUnion("BML"),
        phoneNumbers: contact?.phoneNumbers,
        _phoneNumberStrings: (
          (contact?.phoneNumbers || []) as Contacts.PhoneNumber[]
        )?.map((pn) => pn.digits || pn.number?.replace(/\D/, "g")),
        displayName: contact?.displayName,
        email: contact?.email,
        groupType: "list",
        qualifications: contact?.qualifications,
      };
      finalContacts.push(newContactData);
    });
  });
  return finalContacts;
};

export const convertBMLContactToNativeContact = (
  contact: ContactSchemaa,
  uid: string
) => {
  const finalContact: ContactSchemaa = {
    ...contact,
    displayName:
      contact?.name?.length > 0
        ? contact.name
        : `${contact.firstName} ${contact.lastName}`,
    _cid: contact?._cid,
    ownerUid: uid,
    id: contact.id || contact?._cid,
    phoneNumbers: contact.phoneNumbers ? contact.phoneNumbers : [],
    _phoneNumberStrings: (
      (contact?.phoneNumbers || []) as Contacts.PhoneNumber[]
    )?.map((pn) => pn.digits || pn.number?.replace(/\D/, "g")),
    name: contact?.displayName,
    emails: contact.emails ? contact.emails : [],
    email: contact?.emails?.length > 0 ? contact.emails[0].email : "",
    contactType: Contacts.ContactTypes.Person,
  };
  return finalContact;
};

//#region [section] Mock Data for BML List
export const _MOCK_BMLList_DATA: ContactSchemaa[] = [
  {
    _cid: "2E73EE73-C03F-4D5F-B1E8-44E85A70F170",
    displayName: "Hank M. Zakroff",
    phoneNumbers: [
      {
        countryCode: "us",
        id: "337A78CC-C90A-46AF-8D4B-6CC43251AD1A",
        number: "(555) 766-4823",
        digits: "5557664823",
        label: "work",
      },
      {
        countryCode: "us",
        id: "E998F7A3-CC3C-4CF1-BC21-A53682BC7C7A",
        number: "(707) 555-1854",
        digits: "7075551854",
        label: "other",
      },
    ],
    qualifications: [
      MACHO.MARRIED,
      MACHO.AGE,
      MACHO.CHILDREN,
      MACHO.HOMEOWNER,
      MACHO.OCCUPATION,
    ],
    listId: "BML",
    groups: ["BML"],
  },
  {
    _cid: "AFD18622-9724-4C57-98B0-D7803999D1C7:ABPerson",
    displayName: "Coretta Budget",
    phoneNumbers: [
      {
        countryCode: "us",
        id: "A039376F-9E6B-401F-A9F4-41524F0798D0",
        number: "62-(233)213-5022",
        digits: "622332135022",
        label: "mobile",
      },
    ],
    qualifications: [MACHO.MARRIED, MACHO.AGE, MACHO.OCCUPATION],
    listId: "BML",
    groups: ["BML"],
  },
  {
    _cid: "410FE041-5C4E-48DA-B4DE-04C15EA3DBAC",
    displayName: "John Appleseed",
    phoneNumbers: [
      {
        countryCode: "us",
        id: "E297F1F7-CAFC-4A9D-ABF8-F79DB4496C87",
        number: "888-555-5512",
        digits: "8885555512",
        label: "mobile",
      },
      {
        countryCode: "us",
        id: "5E423897-5B64-4129-AF55-10B1B3153697",
        number: "888-555-1212",
        digits: "8885551212",
        label: "home",
      },
    ],
    qualifications: [MACHO.MARRIED, MACHO.AGE],
    listId: "BML",
    groups: ["BML"],
  },
  {
    _cid: "E94CD15C-7964-4A9B-8AC4-10D7CFB791FD",
    displayName: "David Taylor",
    phoneNumbers: [
      {
        countryCode: "us",
        id: "FE064E55-C246-45F0-9C48-822BF65B943F",
        number: "555-610-6679",
        digits: "5556106679",
        label: "home",
      },
    ],
    qualifications: [
      MACHO.MARRIED,
      MACHO.AGE,
      MACHO.CHILDREN,
      MACHO.HOMEOWNER,
      MACHO.OCCUPATION,
    ],
    listId: "BML",
    groups: ["BML"],
  },
  {
    _cid: "E94CD15C-7964-4A9B-8AC4-10D7CFB791F3",
    displayName: "Jack Taylor",
    phoneNumbers: [
      {
        countryCode: "us",
        id: "FE064E55-C246-45F0-9C48-822BF65B943F",
        number: "333-610-6679",
        digits: "5556106679",
        label: "home",
      },
    ],
    qualifications: [MACHO.AGE],
    listId: "BML",
    groups: ["BML"],
  },
  {
    _cid: "E94CD15C-7964-4A9B-8AC4-10D7CFB791FD",
    //_teamId: "app-takeoff-team",
    displayName: "Sasha Nicole",
    phoneNumbers: [
      {
        countryCode: "us",
        id: "FE064E55-C246-45F0-9C48-822BF65B943F",
        number: "555-610-6679",
        digits: "5556106679",
        label: "home",
      },
    ],
    listId: "BML",
    groups: ["BML"],
    qualifications: [],
  },
  {
    _cid: "177C371E-701D-42F8-A03B-C61CA31627F6",
    displayName: "Kate Bell",
    phoneNumbers: [
      {
        countryCode: "us",
        id: "EF48385D-28C2-48DE-AAB3-A81BC5F16981",
        number: "(555) 564-8583",
        digits: "5555648583",
        label: "mobile",
      },
      {
        countryCode: "us",
        id: "3CD5F927-B150-4104-918B-C26DD6AC811B",
        number: "(415) 555-3695",
        digits: "4155553695",
        label: "main",
      },
    ],
    qualifications: [
      MACHO.MARRIED,
      MACHO.AGE,
      MACHO.CHILDREN,
      MACHO.HOMEOWNER,
      MACHO.OCCUPATION,
    ],
    listId: "BML",
    groups: ["BML"],
  },
];
//#endregion
