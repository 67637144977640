//#region [import]

import { useAppContext } from "engines";

//#endregion

/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.17
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function useJiraFunctions() {
  const { frbsUser } = useAppContext();

  /**
   * ### "Add" a document to Possts Collection
   *  - If no data is passed, default value will be used
   *  - This function makes sure all fields is validated before save it in frbs
   *  - All blank fields will be filled with default values
   *  ----
   *  @example
   *  handlePossts.add("mock") //* for mock data (e.g. quick testing)
   *  handlePossts.add({ body: "You're goddamn right, I'm not goddamn wrong"})
   *  ----
   *  @version 21.03.12
   *  -  *Add 4hm*
   *  @author  Nl
   *
   **/
  async function add(data) {
    let projectId = "primr-exp";
    // const _token = await frbsUser.getIdToken();
    // console.log("_token ", _token);
    try {
      return (
        // _token &&
        fetch(
          `https://us-central1-${projectId}.cloudfunctions.net/api/services/jira/create-issue`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(data),
            headers: {
              "content-type": "application/json",
              //   Authorization: "Bearer " + _token,
            },
          }
        )
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  return {
    add,
  };
}
