import { TransitionPresets } from "@react-navigation/stack";
import { IconPrimr } from "assets";
import React from "react";
import { OnboardingScreen } from "screens/onboarding/OnboardingScreen";
import { AuthScreen } from "screens/welcome/AuthScreen";
import { WelcomeScreen } from "screens/welcome/WelcomeScreen";
import { colors, spacing } from "utils";

export const WelcomeStackCollection = {
  Welcome: {
    component: WelcomeScreen,
    options: {
      title: "Welcome to App Takeoff",
    },
  },
  Auth: {
    component: AuthScreen,
    options: {
      title: "Sign in or create an account",
    },
  },
  Onboarding: {
    component: OnboardingScreen,
    options: {
      ...TransitionPresets.SlideFromRightIOS,
      headerTitle: "",
      headerTransparent: true,
      animationEnabled: true,
      // safeAreaInsets: { top: 0 },
      headerBackImage: () => (
        <IconPrimr
          preset="circular"
          name="x"
          color={colors.text01}
          containerStyle={{
            marginHorizontal: spacing(4),
            backgroundColor: colors.primary,
          }}
        />
      ),
      headerRight: null,
    },
  },
};

export type WELCOME_STK = keyof typeof WelcomeStackCollection;
