//#region [import]
import { MediaPageItemSchema } from "engines/backends";
import React, { useContext } from "react";
//#endregion

/**
 * ### Audio Player Provider,
 *
 * providing:
 * -  showPlayer
 * -  setShowPlayer
 * ---
 * @version 22.03.10
 * - *Audio Provider*
 * @author Saurabh_M
 * ---
 */
export function AudioPlayerProvider(props: dAudioProvider) {
  const { children } = props;

  return (
    <AudioPlayerContext.Provider
      value={{
        audioItem: null,
        setAudioItem: () => {},
        track: null,
        setTrack: () => {},
        showPlayer: true,
        isTrackPlayerInit: false,
        isPlaying: false,
        setIsPlaying: () => {},
        isLoading: false,
        setIsLoading: () => {},
        isFullScreen: false,
        setIsFullScreen: () => {},
        sliderValue: 0,
        position: 0,
        duration: 0,
        setShowPlayer: () => {},
        onButtonPressed: async () => {},
        slidingCompleted: () => {},
        slidingStarted: () => {},
        skipBy: () => {},
        rate: 1,
        setRate: () => {},
        stopAudioPlayer: () => {},
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
}

export const AudioPlayerContext = React.createContext<dAudioProviderContext>({
  audioItem: null,
  setAudioItem: () => {},
  track: null,
  setTrack: () => {},
  showPlayer: true,
  isTrackPlayerInit: false,
  isPlaying: false,
  setIsPlaying: () => {},
  isLoading: false,
  setIsLoading: () => {},
  isFullScreen: false,
  setIsFullScreen: () => {},
  sliderValue: 0,
  position: 0,
  duration: 0,
  setShowPlayer: () => {},
  onButtonPressed: async () => {},
  slidingCompleted: () => {},
  slidingStarted: () => {},
  skipBy: () => {},
  rate: 1,
  setRate: () => {},
  stopAudioPlayer: () => {},
});

/**
 * Audio Player context hook
 *
 * ---
 * @example
 * ```
 * const { sound,setSound,timeInfo,setTimeInfo,soundStatus,setSoundStatus } = useAudioContext()
 * ```
 *
 * @version 22.03.10
 * - *Audio Context*
 * @author Saurabh_M
 */
export const useAudioPlayerContext = () => useContext(AudioPlayerContext);

interface dAudioProvider {
  children: React.ReactNode;
}

type _Track = { url: string };
export interface dAudioProviderContext {
  audioItem: MediaPageItemSchema;
  setAudioItem: React.Dispatch<React.SetStateAction<MediaPageItemSchema>>;
  track: _Track;
  setTrack: React.Dispatch<React.SetStateAction<_Track>>;
  showPlayer: boolean;
  isTrackPlayerInit: boolean;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreen: boolean;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  sliderValue: number;
  position: number;
  duration: number;
  setShowPlayer: React.Dispatch<React.SetStateAction<boolean>>;
  onButtonPressed: () => Promise<void>;
  slidingCompleted: Function;
  slidingStarted: Function;
  skipBy: Function;
  stopAudioPlayer: Function;
  rate: number;
  setRate: React.Dispatch<React.SetStateAction<number>>;
}
