import { Kitten, sstyled } from "components";
import { UserSchema } from "engines";
import React from "react";
import { CalendarScreen, useNavigator } from "screens";
import { CalendarListSection } from "./CalendarListSection";

interface dCalendarSection {
  userData: UserSchema;
}

/**
 * ### Section of Calendar
 *  - COntacts
 *  ----
 *  @version 23.10.04
 *  -  *Brief changelog*
 *  @author  J
 *
 **/
export function CalendarSection(props: dCalendarSection) {
  const { userData } = props;
  const Navigation = useNavigator();
  const openCalendarScreen = () => {
    Navigation.overlayPortal.open({
      render: <CalendarScreen />,
      headerTitle: "Calendar",
    });
  };
  return (
    <A.ViewContainer level="3">
      <CalendarListSection userData={userData} />
      {/** moved inside CalendarListSection so that when a new event is added it updates UI instantly */}
      {/* <Kitten.ViewH style={{ justifyContent: "flex-end", padding: spacing(3) }}>
        <Buttoon
          size="tiny"
          appearance="ghost"
          onPress={() => {
            AddCalendarEvent.presentEventCreatingDialog({ title: "" })
              .then((evt) => {
                if (evt.action === "SAVED") {
                  Toasty.show("Event added", { type: "success" });
                }
              })
              .catch((error: string) => {
                Toasty.show(error, { type: "danger" });
                console.error(error);
              });
          }}
          icon={{ name: "plus" }}
        >
          {tr("Add Event")}
        </Buttoon>
        <Buttoon
          size="tiny"
          appearance="filled"
          onPress={openCalendarScreen}
          icon={{ name: "chevron_right", right: true }}
        >
          {tr("See full calendar")}
        </Buttoon>
      </Kitten.ViewH> */}
    </A.ViewContainer>
  );
}

const A = {
  ViewContainer: sstyled(Kitten.Layout)(({ hovered }) => ({
    flex: 1,
    flexGrow: 1,
    borderRadius: 9,
  })),
};
