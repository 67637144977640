import { useFocusEffect } from "@react-navigation/native";
import { Input } from "@ui-kitten/components";
import { MenuIcon } from "assets/icons/MenuIcon";
import { sstyled, Txt } from "components";
import {
  dAccessory,
  Inpuut,
  Kitten,
  ListHeader,
  Poppy,
  Toasty
} from "components/atoms";
import { ContactGroupSchema, useAppContext } from "engines";
import { useContactGroupDocument } from "engines/firebase/handler/contact-groups-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { scale, tr } from "utils";

/**
 * ### List of members of the app (aka teamId's members)
 *  - Including some quick actions as an swipeable item
 *  ----
 *  @version 21.09.07
 *  -  *Memoize it*
 *  @author  K, Nl
 *
 **/
export function ContactCreatorCenter(props: P) {
  const { C, teamId } = useAppContext();
  const { frbsUser } = useAppContext();
  const { id } = props;
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  const [editVisibility, setEditVisibility] = React.useState<boolean>(false);
  const contactGroupHandler = useContactGroupDocument(frbsUser?.uid, id);

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();
  const refTitle = React.useRef<Input>();
  const [editedName, setEditedName] = React.useState<string>(
    contactGroupHandler?.data?.name || ""
  );
  const [editedDescription, setEditedDescription] = React.useState<string>(
    contactGroupHandler?.data?.description || ""
  );
  //#endregion

  //#region [section2] contact section

  const [details, setDetails] = React.useState<ContactGroupSchema>();
  React.useEffect(
    function setupData() {
      contactGroupHandler.data && setDetails(contactGroupHandler.data);
    },
    [contactGroupHandler.data]
  );
  //#endregion

  return React.useMemo(() => {
    try {
      return (
        <>
          <ListHeader
            titleTx={editVisibility ? tr("Edit group info...") : tr("About")}
            actions={[
              {
                label: editVisibility ? tr("Close") : tr("Edit group info"),
                onPress: () => {
                  setEditedName(details?.name);
                  setEditedDescription(details?.description);
                  setEditVisibility(editVisibility == true ? false : true);
                },
                status: editVisibility ? "danger" : "primary",
              },
            ]}
          />
          <Kitten.Card>
            {editVisibility ? (
              <>
                <Inpuut
                  ref={refTitle}
                  title={tr("Group name") + " *"}
                  value={editedName}
                  onChangeText={setEditedName}
                  onSavePress={() => {
                    editedName === ""
                      ? (Toasty.show(
                          tr("Cannot leave group name field blank"),
                          {
                            type: "warning",
                          }
                        ),
                        refTitle.current?.focus())
                      : (contactGroupHandler.update({
                          name: editedName,
                        }),
                        Toasty.show(tr("Contact group name updated!"), {
                          type: "success",
                        }));
                  }}
                />
                <Inpuut
                  title={tr("Description")}
                  multiline
                  textStyle={{ minHeight: scale(111) }}
                  value={editedDescription}
                  onChangeText={setEditedDescription}
                  onSavePress={() => {
                    contactGroupHandler.update({
                      description: editedDescription,
                    });
                    Toasty.show(tr("Contact group description updated!"), {
                      type: "success",
                    });
                  }}
                />
                <Kitten.Divider_________ />
                <A.CtnrMenu
                  selectedIndex={null}
                  onSelect={(index) => {
                    if (id === "BML") {
                      Toasty.show("Build My List cannot be deleted", {
                        type: "warning",
                      });
                      return;
                    }
                    switch (index.row) {
                      case 0:
                        Poppy(
                          "Final Check",
                          "Are you sure you want to delete this contact group?",
                          {
                            text: "Cancel",
                            onPress: () => {},
                          },
                          {
                            text: "Yes",
                            onPress: () => {
                              contactGroupHandler
                                .deleteDocument()
                                .then(() => {
                                  Navigation.goBack();
                                  Toasty.show(tr("Contact grouped deleted"), {
                                    type: "success",
                                  });
                                })
                                .catch((e) => {
                                  console.log("error ", e);
                                });
                            },
                          }
                        );
                        break;
                    }
                  }}
                >
                  <Kitten.MenuItem
                    title={tr("Delete contact group")}
                    accessoryLeft={(props: dAccessory) =>
                      MenuIcon("trash", props)
                    }
                  />
                </A.CtnrMenu>
              </>
            ) : (
              <>
                {details?.description ? (
                  <Txt.S1>{details?.description}</Txt.S1>
                ) : (
                  <Txt.Helper marginV>{tr("No description")}</Txt.Helper>
                )}
              </>
            )}
          </Kitten.Card>
        </>
      );
    } catch (error) {
      throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
    }
  }, [C, contactGroupHandler]);
}

const A = {
  ViewContainer: sstyled(View)({
    marginVertical: 3,
    // minWidth: 300,
  }),
  Ctnr: sstyled(Kitten.Card)((p) => ({
    width: "100%",
  })),
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
};

interface P {
  id: string;
}
