//#region [import]
import { deleteDocument, update } from "@nandorojo/swr-firestore";
import { IconPrimr } from "assets";
import Color from "color";
import {
  Buttoon,
  Imaggie,
  Kitten,
  Poppy,
  sstyled,
  Txt
} from "components/atoms";
import {
  ACTION_TYPE,
  fn,
  FPATH,
  ItemType,
  MediaPageItemSchema,
  MediaPageSchema,
  ServiceType,
  typeOfItem,
  URL_PREFIX,
  useAppContext,
  UserSchema
} from "engines";
import {
  dUseDocumentReturns,
  FRBS_ROLE,
  recordUserAction,
  updateConfigKeys,
  useUserProfile
} from "engines/firebase";
import { calculatePageProgress } from "engines/firebase/handler/levels-handler";
import { updateItem } from "engines/firebase/handler/page-contents-handler";
import { levelItemString } from "engines/functions/level-functions/level-page-utils";
import { getThumbnailFromURL } from "engines/functions/media-functions";
import { useCollabContext } from "engines/providers/collaboration-provider";
import firebase from "firebase";
import _ from "lodash";
import React from "react";
import { Pressable, View } from "react-native";
import { useNavigator } from "screens";
import { IS_WEB, scale, spacing, tr } from "utils";
import { addViewHistoryForUser } from "../../../../engines/firebase/handler/view-history-handler";
import { MediaItemCreator } from "../items-creator/media-item-creator";
import { PickTrainerButtonCreator } from "../items-creator/pick-trainer-button-creator";
import { BlastTemplateItemEditor } from "../items-editor/blast-template-item-editor";
import { ButtonItemEditor } from "../items-editor/button-item-editor";
import { CheckboxItemEditor } from "../items-editor/checkbox-item-editor";
import { MoveItemScreen } from "../items-editor/move-item-screen";
import { ParagraphItemEditor } from "../items-editor/paragragh-item-editor";
import { TitleItemEditor } from "../items-editor/title-item-editor";
import { AudioItem } from "../items/audio-item";
import { BlastTemplateItem } from "./blast-template-item";
import { ButtonItem } from "./button-item";
import { CheckboxItem } from "./checkbox-item";
import { LevelButtonItem } from "./level-button-item";
import { PickTrainerButton } from "./pick-trainer-button";
import { VideoItem } from "./video-item";
//#endregion

export function CustomPageItem(props: dCustomPageItemProps) {
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();

  const {
    cpid,
    index,
    item,
    onReachedPageCompletion,
    onPress,
    onLevelProgress,
    lockUntilTrainerSelect
  } = props;
  //#region [section] Share ops, from parents comp
  const { isSelectShareModeOn, setIsShareContents, isShareContents } = props;
  //#endregion
  //#region [section2] Item edit ops, from parents comp
  const {
    // onCompleteLevelItem,
    // levelPageProgress,
    movingModeEnabled,
    onEditCallback,
    addEntryClick,
    shouldEditContent,
    rolesThatCanEdit,
    isLevel,
    pcid
  } = props;
  //#endregion

  const [itemString, setItemString] = React.useState<string>(null);
  const [isItemCompleted, setIsItemCompleted] = React.useState<boolean>(false);
  const [linkType, setLinkType] = React.useState<
    "page" | "URL" | "link_magnet" | "fin" | "bml"
  >(null);
  // console.log("[level] temp: "+cpid)

  //#region [section] Handle collaboration mode
  const { collabMode } = props;
  const {
    isCollabModeOn,
    isSelectModeOn,
    pageContentItems,
    setIsCollabModeOn,
    setIsSelectModeOn,
    setPageContentItems,
    setOnlyShowUncompletedContents,
    onlyShowUncompletedContents,
  } = useCollabContext();
  // React.useEffect(() => {
  //   // console.log("In CustomPageItem collab mode is ", collabMode);

  //   setIsCollabModeOn(collabMode ? collabMode : false);
  // }, [collabMode]);

  //#endregion

  /**
   * items that have initiated necessary firestore data updates that we don't want to run another update for right on top of
   * - created for vimeo token manipulation
   */
  const refUpdatingItemStrings = React.useRef<string[]>([]);

  //#region [FRBS]
  const pageItem = { _parentPageId: pcid, ...item } as MediaPageItemSchema; // _parentPageId may already be set but let's make sure :)
  let ITEM_PATH = `${FPATH.PAGES}/${cpid}/${FPATH.CUSTOM_PAGE_CONTENT}/${pageItem._id}`;
  const { pageHandler, memberHandler, pageContentsHandler } = props;
  const { data: userD, ...userProfile } = useUserProfile();
  // const memberHandler = useMemberDocument(frbsUser?.uid);
  // const pageHandler = usePageDocument(cpid);
  // const pageContentHandler = usePageContentDocument(cpid, pcid);
  // const pageItem = pageItem;

  // const hierarchyKeys = Backend.authHandler.getKeys(teamId).then((res) => {
  //   return res[0];
  // });

  React.useEffect(
    function getItemDetails() {
      // if (pageHandler.data?._teamId !== teamId && !isCollabModeOn) {
      //   //Poppy("Invalid Access", "You are not allowed to view this page");
      //   console.log("You are not allowed to view this page");
      //   Navigation.navigate("Home");
      // }
      const istr = levelItemString(pageHandler.data, pageItem);
      setItemString(istr);
      setIsItemCompleted(
        memberHandler.data?.completedLevelItems?.find(
          (i) => i && i?.includes(istr.split(":").pop())
        )
          ? true
          : false
        // memberHandler.data?.completedLevelItems?.includes(istr)
      );
    },
    [pageHandler.data, memberHandler.data]
  );

  // Gets the page the button goes to, in order to find out if it is a level page
  // const urlPageId =
  //   typeof pageItem?.url === "string" && pageItem.url?.split("page:").pop();
  // const handleUrlPage = urlPageId && usePageDocument(urlPageId);
  const _typeOfItem = pageItem && typeOfItem(pageItem);
  const itemType = pageItem && _typeOfItem?.itemType;
  // const itemType = React.useMemo(
  //   () =>
  //     pageItem &&
  //     typeOfItem(pageItem, handleUrlPage.data)?.itemType,
  //   [pageItem, handleUrlPage.data]
  // );
  //#endregion
  const [itemIndexSelected, setItemIndexSelected] =
    React.useState<number>(null);
  React.useEffect(() => {
    movingModeEnabled == false && setItemIndexSelected(null);
  }, [movingModeEnabled]);

  //NOTE If the user clicks on page when select Mode is on, we disable it to avoid issues.
  React.useEffect(() => {
    // if (!collabMode) {
    //   setIsSelectModeOn(false);
    // }
    if (teamId === pageHandler.data?._teamId) {
      setIsSelectModeOn(false);
    }
  }, []);

  // #region [ANCHOR] Avoid editing of pages if u aren't belong to page's _teamId
  /**
   * - Exception if you're DEVELOPER or ADMIN
   */
  const [isUserAllowedToEdit, setIsUserAllowedToEdit] = React.useState(false);
  React.useEffect(() => {
    if (pageHandler.data && userD) {
      if (
        userProfile._isRolesContain(
          _.concat(
            FRBS_ROLE.DEVELOPER,
            FRBS_ROLE.ADMIN,
            pageHandler.data?.rolesThatCanEdit
          )
        )
      ) {
        setIsUserAllowedToEdit(true);
      } else {
        setIsUserAllowedToEdit(false);
        // setIsCollabModeOn(true);
      }
    }
  }, [pageHandler.data, userD]);
  // #endregion

  /** ANCHOR PageContentItems
   * -  A complete dictionary of all types of page content item. Including:
   *    - `renderItem`: From user pov, it's the "item" itself, with full UI & func
   *    - `renderEditor`: From user pov, it's the item editor for updating item's value (title, link to...)
   */
  const PAGE_CONTENT_ITEMS: dPage_Content_Item = {
    //#region [section] Title item
    [ItemType.TITLE]: {
      key: "title",
      renderItem: (
        <Txt.H5>
          {pageItem?.title &&
            pageItem?.title?.replace(
              /🌀/g,
              userD?.personali?.displayName?.split(" ")[0]
            )}
        </Txt.H5>
      ),
      renderEditor: (
        <TitleItemEditor
          pageContentHandler={{
            data: pageItem,
            update: (newData) => update(ITEM_PATH, { ...newData }),
            deleteDocument: () => deleteDocument(ITEM_PATH),
          }}
          onUpdateCallback={() => {
            Navigation.goBack();
          }}
        />
      ),
      isShareable: true,
    },
    //#endregion
    //#region [section2] Paragraph item
    [ItemType.PARAGRAPH]: {
      key: "paragraph",
      renderItem: (
        <Txt.S1>
          {/* {String(pageItem?.paragraph)?.replace(
            /🌀/g,
            userD?.personali?.displayName?.split(" ")[0]
          )} */}
          {String(pageItem?.paragraph)}
        </Txt.S1>
      ),
      renderEditor: (
        <ParagraphItemEditor
          pageContentHandler={{
            data: pageItem,
            update: (newData) => update(ITEM_PATH, { ...newData }),
            deleteDocument: () => deleteDocument(ITEM_PATH),
          }}
          onUpdateCallback={() => {
            Navigation.goBack();
          }}
        />
      ),
      isShareable: true,
    },
    //#endregion
    //#region [section] Level button item
    [ItemType.LEVEL_BUTTON]: {
      key: "level",
      renderItem: (
        <LevelButtonItem
          parentPageId={cpid}
          mediaItem={pageItem}
          rolesThatCanEdit={rolesThatCanEdit}
          onLevelProgress={(_progress) =>
            onLevelProgress && onLevelProgress(item, _progress)
          }
          lockUntilTrainerSelect={lockUntilTrainerSelect}
          onPress={(item, _levelProgress) => {
            onPress && onPress(item, _levelProgress);
            completeLevelItem();
          }}
        />
      ),
      // renderEditor: (
      //   <LevelButtonItemEditor
      //     cpid={cpid}
      //     pageContentDocument={{
      //       data: pageItem,
      //       update: (newData) => update(ITEM_PATH, { ...newData }),
      //       deleteDocument: () => deleteDocument(ITEM_PATH),
      //     }}
      //     onUpdateCallback={() => Navigation.goBack()}
      //   />
      // ),
      renderEditor: (
        <ButtonItemEditor
          cpid={cpid}
          pageContentDocument={{
            data: pageItem,
            update: (newData) => update(ITEM_PATH, { ...newData }),
            deleteDocument: () => deleteDocument(ITEM_PATH),
          }}
          onUpdateCallback={() => Navigation.goBack()}
        />
      ),
      isShareable: pageItem?.url?.startsWith("http") ? true : false,
    },
    //#endregion
    //#region [section2] Button/Link item
    [ItemType.BUTTON]: {
      key: "link",
      renderItem: (
        <ButtonItem
          item={pageItem}
          parentPageId={cpid}
          onPress={() => {
            onPress && onPress(item);
            completeLevelItem();
          }}
          complete={isItemCompleted}
        />
      ),
      renderEditor: (
        <ButtonItemEditor
          cpid={cpid}
          pageContentDocument={{
            data: pageItem,
            update: (newData) => update(ITEM_PATH, { ...newData }),
            deleteDocument: () => deleteDocument(ITEM_PATH),
          }}
          onUpdateCallback={() => Navigation.goBack()}
        />
      ),
      isShareable: String(pageItem?.url).includes("http"), //remember to come back here
    },
    //#endregion
    //#region [section] Build My List Button
    [ItemType.BUILD_MY_LIST]: {
      key: "bml",
      renderItem: (
        <ButtonItem
          item={pageItem}
          parentPageId={cpid}
          onPress={() =>
            !IS_WEB
              ? Navigation.navigate("bml-mobile", {
                retake: false,
              })
              : Poppy(
                "Warning!",
                "This feature is only available on the mobile app."
              )
          }
          complete={isItemCompleted}
        />
      ),
      renderEditor: (
        <ButtonItemEditor
          cpid={cpid}
          pageContentDocument={{
            data: pageItem,
            update: (newData) => update(ITEM_PATH, { ...newData }),
            deleteDocument: () => deleteDocument(ITEM_PATH),
          }}
          onUpdateCallback={() => Navigation.goBack()}
        />
      ),
      isShareable: false,
    },
    [ItemType.PICK_TRAINER]: {
      key: "pick_trainer",
      renderItem: (<PickTrainerButton options={item.pickTrainer} onComplete={(trainer) => completeLevelItem()} selectedTrainerUids={userD?.growth?.listBuilder?.shareTo || []} />),
      renderEditor: (<PickTrainerButtonCreator editItem={item} cpid={cpid} />)
    },
    //#endregion
    //#region [section] Photo item
    [ItemType.PHOTO]: {
      key: "photo",
      renderItem: (
        <>
          {IS_WEB ? (
            <Imaggie
              source={{
                uri: pageItem.media, //* "https://res.cloudinary.com/https-apptakeoff-com/image/upload/q_auto:low/v1622731805/apptakeoff/icons/rocket_vdr9ge.gif"
              }}
            />
          ) : (
            <View style={{ width: "100%", maxHeight: 600 }}>
              <Imaggie
                source={{
                  uri: pageItem.media, //* "https://res.cloudinary.com/https-apptakeoff-com/image/upload/q_auto:low/v1622731805/apptakeoff/icons/rocket_vdr9ge.gif"
                }}
              />
            </View>
          )}
          <Kitten.ViewH>
            <IconPrimr name={"image"} color={C.text} size={12} />
            <Txt.P1>{pageItem.title}</Txt.P1>
          </Kitten.ViewH>
        </>
      ),
      /**
       * WIP (work in progress...)
       */
      // renderEditor: (
      //   <MediaItemEditor
      //     cpid={cpid}
      //     pageContentDocument={{
      //       data: pageItem,
      //       update: (newData) => update(ITEM_PATH, { ...newData }),
      //       deleteDocument: () => deleteDocument(ITEM_PATH),
      //     }}
      //     onUpdateCallback={() => {
      //       Navigation.goBack();
      //     }}
      //   />
      // ),
      renderEditor: (
        <MediaItemCreator
          cpid={cpid}
          pageContentDocument={{
            data: pageItem,
            update: (newData) => update(ITEM_PATH, { ...newData }),
            deleteDocument: () => deleteDocument(ITEM_PATH),
          }}
          onCloseCallback={() => {
            Navigation.goBack();
          }}
        />
      ),
      isShareable: true,
    },
    //#endregion
    //#region [section2] Video item
    [ItemType.VIDEO]: {
      key: "video",
      // renderItem: <Txt>Video is under maintenance</Txt>,
      renderItem: (
        <VideoItem
          editModeEnabled={shouldEditContent}
          pageHandler={pageHandler}
          item={pageItem}
          cpid={cpid}
          // serviceType={typeOfItem(pageItem).serviceType}
          serviceType={_typeOfItem.serviceType}
          memberHandler={memberHandler}
          onComplete={(itemString) => completeLevelItem()}
          onFoundSuccessfulVimeoToken={async (vimeoToken, itemString) => {
            // console.log(
            //   "[vimeo] updating vimeo token item for " +
            //     itemString +
            //     " : " +
            //     JSON.stringify(vimeoToken)
            // );
            if (
              pageHandler.data?._teamId === teamId &&
              !pageItem?.vimeoToken &&
              !refUpdatingItemStrings.current.includes(pageItem._id)
            ) {
              refUpdatingItemStrings.current.push(pageItem._id);
              // console.log(
              //   "[vimeo] updating tokens for this vimeo item and local config... " +
              //     `(${pageItem.title})`
              // );
              try {
                await updateConfigKeys(teamId, {
                  // @ts-ignore
                  vimeo: firebase.firestore.FieldValue.arrayUnion(vimeoToken),
                });
                updateItem({
                  cpid: pageHandler.data?._id,
                  pcid: pageItem?._id,
                  data: { vimeoToken },
                });
              } catch (error) {
                console.log("[vimeo] error in CustomPageItem.tsx: " + error);
              }
            }
          }}
        // editModeEnabled={editModeEnabled}
        // accessTokens={hierarchyKeys}
        // complete={isItemCompleted}
        // onComplete={() => completeLevelItem()}
        />
        // <Videolly
        //   type="component"
        //   source={{
        //     uri: "http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4",
        //   }}
        // />
      ),
      renderEditor: (
        <MediaItemCreator
          cpid={cpid}
          pageContentDocument={{
            data: pageItem,
            update: (newData) => update(ITEM_PATH, { ...newData }),
            deleteDocument: () => deleteDocument(ITEM_PATH),
          }}
          onCloseCallback={() => {
            Navigation.goBack();
          }}
        />
        // <MediaItemEditor
        //   cpid={cpid}
        //   pageContentDocument={{
        //     data: pageItem,
        //     update: (newData) => update(ITEM_PATH, { ...newData }),
        //     deleteDocument: () => deleteDocument(ITEM_PATH),
        //   }}
        //   onUpdateCallback={() => {
        //     Navigation.goBack();
        //   }}
        // />
      ),
      isShareable: true,
    },
    [ItemType.CHECKBOX]: {
      key: "checkbox",
      renderItem: (
        <CheckboxItem
          item={item}
          checked={isItemCompleted}
          onCheckItem={(item, checked) => {
            completeLevelItem(!checked);
          }}
        />
      ),
      renderEditor: (
        <CheckboxItemEditor
          cpid={cpid}
          item={item}
          onUpdateCallback={() => Navigation.goBack()}
        />
      ),
    },
    //#endregion
    //#region [section] Audio item
    [ItemType.AUDIO]: {
      key: "audio",
      renderItem: (
        <AudioItem
          item={pageItem}
          serviceType={_typeOfItem?.serviceType}
          complete={isItemCompleted}
          itemString={itemString}
          onComplete={() => completeLevelItem()}
          onFoundSuccessfulToken={async (token) => {
            // console.log(
            //   "[dropbox] saving successful token for audio to item and config: " +
            //     token
            // );
            if (
              pageHandler.data?._teamId === teamId &&
              !pageItem?.dropboxToken &&
              !refUpdatingItemStrings.current.includes(pageItem._id)
            ) {
              refUpdatingItemStrings.current.push(pageItem._id);
              try {
                await updateConfigKeys(teamId, {
                  // @ts-ignore
                  dropbox: firebase.firestore.FieldValue.arrayUnion(token),
                });
                updateItem({
                  cpid: pageHandler.data?._id,
                  pcid: pageItem?._id,
                  data: { dropboxToken: token },
                });
              } catch (error) {
                console.log("[vimeo] error in CustomPageItem.tsx: " + error);
              }
            }
          }}
        // accessTokens={hierarchyKeys}
        />
      ),
      renderEditor: (
        // <MediaItemEditor
        //   cpid={cpid}
        //   pageContentDocument={{
        //     data: pageItem,
        //     update: (newData) => update(ITEM_PATH, { ...newData }),
        //     deleteDocument: () => deleteDocument(ITEM_PATH),
        //   }}
        //   onUpdateCallback={() => {
        //     Navigation.goBack();
        //   }}
        // />
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Txt.Helper style={{ color: C["text-warning-color"] }} marginV>
            {tr("Audio Items cannot be edited at the moment.")}
          </Txt.Helper>
          <Buttoon onPress={() => Navigation.goBack()}>{tr("Close")}</Buttoon>
        </View>
      ),
      isShareable: true,
    },
    //#endregion
    //#region [section2] Text Template item
    [ItemType.BLAST_TEXT]: {
      key: "blast_text",
      renderItem: (
        <BlastTemplateItem
          item={pageItem}
          onPress={() => { }}
          onBlastInitiated={() => completeLevelItem()}
          complete={isItemCompleted}
        />
      ),
      renderEditor: (
        <BlastTemplateItemEditor
          cpid={cpid}
          pcid={pageItem._id}
          onCloseCallback={() => {
            Navigation.goBack();
          }}
        />
      ),
      isShareable: true,
    },
    //#endregion
  };

  //#region [functions]

  function onDelete() {
    Poppy(
      tr("Final Check"),
      "Are you sure you want to delete this item?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => deleteDocument(ITEM_PATH),
      }
    );
  }
  const refItemComplete = React.useRef<boolean>(false);
  /** if remove is true, instead of completing it, it will uncomplete it (great for checkbox unchecking) */
  function completeLevelItem(remove?: boolean) {
    // setIsItemCompleted(true);
    // onCompleteLevelItem(item);

    // remember the user watched this if it's a media item like a video or audio
    if (item?.media && item.media.length > 1 && !remove) {
      addViewHistoryForUser(userD?._id, {
        _teamId: teamId,
        uid: userD?._id,
        //@ts-ignore
        startedAt: new Date(),
        itemId: item?._id,
        url: item?.media,
        watched: 1,
      });
    }

    // record user action (CONTAINS FULL RETURNS BE CAREFUL)
    const recordActionWithDescription = (description: string) => recordUserAction(userD, ACTION_TYPE.CONTENT_VIEW, description, { location: `${URL_PREFIX.PAGE}${cpid}/${item?._id}`, involvedPartyName: item?.title, thumbnail: item?.thumbnail || pageHandler.data?.mediaItem?.logo })
    if (!remove && (item?.url?.startsWith(URL_PREFIX.PAGE) || item?.url?.startsWith(URL_PREFIX.LEVEL))) {
      // this is recorded on the share trainer dialog so not necessary to record something here
      if (itemType === ItemType.PICK_TRAINER) return;
      // only record buttons once
      if (userD?.completedLevelItems?.includes(item?._id)) return;
      let description = ""
      if (item.url?.startsWith(URL_PREFIX.LEVEL)) {
        description = `${userD?.personali?.displayName} started new training section: \"${pageHandler.data?.name}\"`
        recordActionWithDescription(description)
      }
      // will not record basic button navigation taps, no need
    }
    else if (!remove) {
      let description = "";
      const itemDetails = `\"${item?.title || item?.paragraph || "item"}\" in \"${pageHandler.data?.name || "a training section"}\"`
      if (itemType === ItemType.VIDEO) description = `${userD?.personali?.displayName} watched ${itemDetails}.`
      else if (itemType === ItemType.AUDIO) description = `${userD?.personali?.displayName} listened to ${itemDetails}.`
      else if (itemType === ItemType.CHECKBOX) description = `${userD?.personali?.displayName} completed ${itemDetails}.`
      else if (itemType === ItemType.BLAST_TEXT) description = `${userD?.personali?.displayName} blasted \"${item?.textTemplate?.description || "a text template"}\" from \"${pageHandler.data.name}\".`
      else description = `${userD?.personali?.displayName} viewed ${itemDetails}.`
      recordActionWithDescription(description)
    }

    if (isItemCompleted || refItemComplete.current === true) {
      if (remove) {
        memberHandler.update({
          completedLevelItems:
            firebase.firestore.FieldValue.arrayRemove(itemString),
        });
      }
      // console.log("[complete] item already complete");
      return;
    }
    refItemComplete.current = true;
    console.log("[level] completing " + itemString);
    setIsItemCompleted(true);
    /**
     * Progress of the page before completing the item
     */
    const preLevelProgress =
      // isLevelPage &&
      memberHandler.data &&
      pageContentsHandler.data &&
      calculatePageProgress(memberHandler.data, pageContentsHandler.data);
    console.log("[level] before completing progress: " + preLevelProgress);
    memberHandler.update({
      completedLevelItems: firebase.firestore.FieldValue.arrayUnion(itemString),
      ...(isLevel ? { lastLevelPage: cpid } : {}),
    });

    // if the item has not been completed before
    if (
      memberHandler.data &&
      !memberHandler.data?.completedLevelItems?.includes(itemString)
    ) {
      memberHandler.data = {
        ...memberHandler.data,
        completedLevelItems: (
          memberHandler.data.completedLevelItems || []
        ).concat([itemString]),
      };
    }

    /**
     * Progress of the page after completing the item
     */
    const postLevelProgress =
      // isLevelPage &&
      memberHandler.data &&
      pageContentsHandler.data &&
      calculatePageProgress(memberHandler.data, pageContentsHandler.data);
    if (preLevelProgress < 1 && postLevelProgress >= 1) {
      onReachedPageCompletion();
    }
    console.log("post completion [level] page progress = " + postLevelProgress);
  }
  function onRepositionItem() {
    setItemIndexSelected(index);
    addEntryClick(pageItem);

    onEditCallback && onEditCallback();

    // console.log("editModeEnabled ", editModeEnabled);
  }
  function onMoveTo() {
    addEntryClick(pageItem);
    onEditCallback && onEditCallback();
  }
  function onEdit() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Edit") + " " + PAGE_CONTENT_ITEMS[itemType].key,
      render: PAGE_CONTENT_ITEMS[itemType].renderEditor,
    });
  }
  /**
   * To move an item to a different page
   */
  function onMoveItem() {
    //@ts-ignore
    Navigation.dialogPortal.open({
      render: <MoveItemScreen item={item} parentPageId={cpid} />,
    });
  }

  function getLinkType(item: MediaPageItemSchema) {
    if (item?.url?.startsWith("page:")) {
      return null;
      // setLinkType("page");
    }
    // if (item?.url?.startsWith("http")) {
    else if (item?.url?.length > 0) {
      if (fn.js.isLinkMagnetURL(item?.url)) {
        return null;
        // setLinkType("link_magnet");
      } else if (fn.js.isFinURL(item?.url)) {
        return null;
        // setLinkType("fin");
      } else if (fn.js.isBMLButton(item?.url)) {
        // setLinkType("bml");
        return null;
      } else {
        setLinkType("URL");
      }
    }
  }

  React.useEffect(
    function () {
      getLinkType(item);
    },
    [item]
  );
  //#endregion

  //   <Txt>
  //   Con 1:{" "}
  //   {JSON.stringify(
  //     !isCollabModeOn && isUserInThisTeamId && shouldEditContent
  //   )}
  // </Txt>
  // <Txt>
  //   Con 2:{" "}
  //   {JSON.stringify(editModeEnabled && index != itemIndexSelected)}
  // </Txt>
  // <Txt>
  //   Con 3: {JSON.stringify(isShareContents && isSelectShareModeOn)}
  // </Txt>

  const CONTENT_MODE = {
    [MODE.EDIT]: !isCollabModeOn && isUserAllowedToEdit && shouldEditContent,

    [MODE.MOVE]: movingModeEnabled,

    [MODE.SHARE]: isShareContents && isSelectShareModeOn,
    [MODE.COMPLETED]: onlyShowUncompletedContents && isItemCompleted,
  };
  //* For quick debugging
  // return (
  //   <View style={{ backgroundColor: C.devPink, marginBottom: 3 }}>
  //     <Txt onPress={() => setIsSelectModeOn(!isSelectModeOn)} key={index}>
  //       {JSON.stringify(item, null, "\t")}
  //     </Txt>
  //   </View>
  // );
  return React.useMemo(() => {
    //#region [section] Page item container
    function PageItemContainer({
      children,
      pageContentData = pageItem,
    }: {
      children: any;
      pageContentData: MediaPageItemSchema;
    }) {
      let EditOptions = [
        {
          text: tr("Reposition item"),
          onSelect: onRepositionItem,
          icon: "move_vertical",
          color: C.hazardYellow,
        },
        {
          text: tr("Edit item"),
          onSelect: onEdit,
          icon: "pen",
          color: C.primary,
        },
        // { text: Menoo.Divider },
        {
          text: tr("Delete item"),
          onSelect: onDelete,
          icon: "trash",
          color: C.errorRed,
        },
        {
          text: tr("Move item"),
          onSelect: onMoveItem,
          icon: "arrow_right_top",
          color: C.accent,
        },
      ];
      //#endregion

      //#region [section2] Page share mode select
      const _shareModeEnabledStyle =
        // isSelectModeOn && customPageHandler.isItemShareable(item)

        isSelectShareModeOn ? A.activateShare : {};
      const _shareModeSelectedStyle =
        isSelectShareModeOn == true
          ? !!isShareContents?.find((p) => p._id === pageContentData?._id) &&
          A.activateShareSelected
          : !!pageContentItems?.find((p) => p._id === pageContentData?._id) &&
          {};

      const shareModeStyle = {
        ..._shareModeEnabledStyle,
        ..._shareModeSelectedStyle,
      };
      const hide = false;
      //#endregion

      return onlyShowUncompletedContents && isItemCompleted ? (
        <View />
      ) : (
        <Pressable
          style={
            !hide
              ? {
                ...shareModeStyle,
              }
              : {
                marginTop: 0,
                marginBottom: 0,
                marginVertical: 0,
              }
          }
          disabled={
            !PAGE_CONTENT_ITEMS[itemType]?.isShareable && isSelectShareModeOn
          }
          onPress={() => {
            if (isSelectShareModeOn == true) {
              if (
                !!isShareContents?.find((p) => p._id === pageContentData?._id)
              ) {
                //    : null;
                setIsShareContents([
                  ...isShareContents?.filter(
                    (p) => p._id !== pageContentData?._id
                  ),
                ]);
              } else {
                if (isShareContents?.length === 0) {
                  setIsShareContents([pageContentData]);
                } else {
                  // getLinkType(item);

                  setIsShareContents([...isShareContents, pageContentData]);
                }
              }
              // setIsShareContents((oldArray) => [...oldArray, pageContentData]);
              // setIsShareContents([...isShareContents, pageContentData]);
            } else {
              null;
            }
          }}
        >
          <A.ViewPageItemContainer
            isItemCompleted={isItemCompleted}
            shouldEditContent={shouldEditContent}
          >
            {children}
            {"debug" === "nah" && (
              <View>
                {/* {Object.keys(CONTENT_MODE).map((m) => (
                  <Txt.Debug>
                    {MODE[m]}: {JSON.stringify(CONTENT_MODE[m])}
                  </Txt.Debug>
                ))} */}
                <Txt>
                  {JSON.stringify(
                    !isCollabModeOn +
                    " " +
                    isUserAllowedToEdit +
                    " " +
                    shouldEditContent
                  )}
                </Txt>
              </View>
            )}
            {
              //#region [section] Translucent overlay to prevent U from touching the item while edit mode is on
              (CONTENT_MODE[MODE.EDIT] ||
                CONTENT_MODE[MODE.MOVE] ||
                CONTENT_MODE[MODE.SHARE]) &&
                itemType !== ItemType.VIDEO ? (
                <A.ViewItemPressPrevention
                  style={[
                    {
                      backgroundColor: Color(C.surface).alpha(0.25).toString(),
                    },
                    CONTENT_MODE[MODE.SHARE] && {
                      backgroundColor: PAGE_CONTENT_ITEMS[itemType]?.isShareable
                        ? Color(C.surface).alpha(0.1).toString()
                        : Color(C.surface).alpha(0.9).toString(),
                    },
                  ]}
                >
                  {CONTENT_MODE[MODE.SHARE] &&
                    !PAGE_CONTENT_ITEMS[itemType]?.isShareable ? (
                    <Txt.Helper marginV>
                      {tr("This item is not shareable")}
                    </Txt.Helper>
                  ) : null}
                </A.ViewItemPressPrevention>
              ) : null
              //#endregion
            }

            <Kitten.ViewH style={[{ justifyContent: "space-between" }]}>
              <ViewWithChipReadItemWidth itemType={itemType} />
              {
                //#region [section2] Edit mode row
                CONTENT_MODE[MODE.EDIT] ? (
                  EditOptions.map((option) => (
                    <IconPrimr
                      preset={"circular"}
                      name={option.icon}
                      color={C.text01}
                      onPress={option.onSelect}
                      containerStyle={{
                        bottom: -scale(12),
                        backgroundColor: option.color,
                      }}
                      disabled={CONTENT_MODE[MODE.MOVE]}
                      key={option.text}
                    />
                  ))
                ) : (
                  <View style={{ width: 10, height: spacing(3) }} />
                )
                //#endregion
              }
              {itemType === ItemType.LEVEL_BUTTON ? null : (
                <ChipReadItem
                  isItemCompleted={isItemCompleted}
                  itemType={itemType}
                />
              )}
            </Kitten.ViewH>
          </A.ViewPageItemContainer>
        </Pressable>
      );
    }
    //#endregion

    return (
      <View>
        {
          //#region [section]
          /** * Show this when:
           * -  editMode is enabled,
           * -  the item is not the selected one,
           * -  the item is not the one NEXT to the selected one,
           * //- and not the one next to the selected one cannot implement it for some reason
           */
          CONTENT_MODE[MODE.MOVE] && index != itemIndexSelected && (
            // index != itemIndexSelected + 1 &&
            <A.PressableMoveToPlaceholder
              status="basic"
              size="large"
              appearance="outline"
              onPress={onMoveTo}
            >
              {tr("Move item here")}
            </A.PressableMoveToPlaceholder>
          )
          //#endregion
        }
        <View>
          <PageItemContainer>
            {PAGE_CONTENT_ITEMS[itemType]?.renderItem}
            {/* <Txt>Position: {JSON.stringify(pageItem?.position || "Nah")}</Txt> */}
          </PageItemContainer>
          {
            //#region [section2]
            /**
             * If item is selected,
             * ViewItemPressPrevention appears above the item to prevent from pressing
             */
            CONTENT_MODE[MODE.MOVE] && index == itemIndexSelected && (
              <A.ViewItemPressPrevention
                style={{
                  backgroundColor: Color(C.infoBlue).alpha(0.2).toString(),
                }}
              />
            )
            //#endregion
          }
        </View>
      </View>
    );
  }, [
    /**
     * NOTE: Only include SPECIFIC variables that should update the UI or it will flicker and stop the video if it's playing
     */
    // pageItem,
    isItemCompleted,
    movingModeEnabled,
    itemIndexSelected,
    // isItemCompleted,
    pageItem?.title,
    pageItem?.paragraph,
    pageItem?.position,
    pageItem?.textTemplate,
    pageItem?.url,
    itemString,
    isSelectModeOn,
    pageContentItems,
    isSelectShareModeOn,
    setIsShareContents,
    isShareContents,
    isCollabModeOn,
    isUserAllowedToEdit,
    // pageItem,
    // addEntryClick,
    C,
    shouldEditContent,
    // userProfile?._isUserAdmin, //todo Disable this may affect admin check in some scenario, but good thing it stops item from flickering. Maybe take a deeper look on this in the future
    onlyShowUncompletedContents,
  ]);
}

/** A Chip to show if the item is "read" or not */
const ChipReadItem = ({
  isItemCompleted,
  itemType,
}: {
  isItemCompleted: boolean;
  itemType?: ItemType;
}) => {
  const { C } = useAppContext();
  const chipLabel = () => {
    switch (itemType) {
      case ItemType.AUDIO:
        return tr("Listened");
        break;
      case ItemType.BLAST_TEXT:
        return tr("Sent");
        break;
      case ItemType.BUTTON:
        return tr("Read");
        break;
      case ItemType.LEVEL_BUTTON:
        // return "";
        return tr("Completed");
        break;
      case ItemType.VIDEO:
        return tr("Watched");
        break;
      case ItemType.CHECKBOX:
        return "";
        break;
      default:
        return tr("Complete");
        break;
    }
  };
  return itemType === ItemType.CHECKBOX ? null : (
    <Buttoon
      appearance={"ghost"}
      size={"small"}
      icon={{
        name: "check_circle",
        color: C["color-success-500"],
        right: true,
      }}
      style={[
        {
          opacity: isItemCompleted ? 1 : 0,
          alignSelf: "flex-end",
          transform: [{ translateY: -7 }],
        },
      ]}
      disabled
    >
      {chipLabel()}
    </Buttoon>
  );
};

/** Just a view with the width of ChipReadItem so the ActionBars is centered */
const ViewWithChipReadItemWidth = ({ itemType }) => (
  <ChipReadItem isItemCompleted={false} itemType={itemType} />
);

const A = {
  ViewPageItemContainer: sstyled(View)(
    ({ isItemCompleted, shouldEditContent }) => ({
      width: "100%",
      paddingBottom: 0,
      marginVertical: shouldEditContent ? spacing(4) : 0,
      // NOTE: this needs to account for Level buttons (shouldn't do anything for those), and this is not really necessary so removing for now -jm_francis
      // backgroundColor: isItemCompleted
      //   ? "color-success-transparent-100"
      //   : "transparent",
    })
  ),
  ViewCheckbox: sstyled(View)({
    marginRight: spacing(3),
    position: "absolute",
    left: -scale(60),
    height: "100%",
    justifyContent: "center",
    transform: [{ scale: 1.166 }],
  }),
  PressableMoveToPlaceholder: sstyled(Buttoon)({
    width: "100%",
    height: scale(50),
    borderColor: "infoBlue",
    borderWidth: 2,
    borderStyle: "dotted",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: spacing(3),
  }),
  ViewItemPressPrevention: sstyled(View)({
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
    borderRadius: scale(4),
    justifyContent: "center",
    alignItems: "center",
  }),
  KittenCheckbox: sstyled(Kitten.CheckBox)({
    margin: 2,
  }),
  activateShare: {
    borderColor: "rgba(205, 205, 205, 0.6)",
    borderWidth: 1.88,
    borderRadius: 12,
    marginVertical: spacing(4),
  },
  activateShareSelected: {
    borderColor: "#40ff93",
    borderWidth: 3.8,
  },
};

enum MODE {
  EDIT,
  SHARE,
  MOVE,
  COMPLETED,
}

export interface dCustomPageItemProps {
  cpid: string;
  /** Mainly used for knowing when a level button gets tapped (includes level progress of the page it goes to if exists) */
  onPress?: (item: MediaPageItemSchema, levelProgress?: number) => void;
  onLevelProgress?: (item: MediaPageItemSchema, levelProgress?: number) => void;
  pageHandler: dUseDocumentReturns<MediaPageSchema>;
  pageContentsHandler: any;
  memberHandler: dUseDocumentReturns<UserSchema>;
  onReachedPageCompletion: () => void;
  // onCompleteLevelItem: (item: MediaPageItemSchema) => void;
  pcid: string;
  item: MediaPageItemSchema;
  index: number;
  movingModeEnabled: boolean;
  addEntryClick(item: MediaPageItemSchema): Promise<void>;
  onEditCallback(): void;
  collabMode?: boolean;
  shouldEditContent?: boolean;
  isSelectShareModeOn?: boolean;
  setIsShareContents?: (item) => void;
  isShareContents?: MediaPageItemSchema[];
  rolesThatCanEdit?: string[];
  isLevel?: boolean;
  /** FOR LEVELS ONLY: keep it locked until they select their trainer, but make sure to set this to false when they do */
  lockUntilTrainerSelect?: boolean;
}

interface dPage_Content_Item {
  [ItemType]: {
    key: string;
    renderItem: () => void;
    renderEditor: () => void;
    isShareable: boolean;
  };
}
