
/**
 * ###  User Doc Schema
 * -    Should be identical to such doc in FRBS.
 * ---
 * @version 0.12.21
 * @author Nl
 * ---
 * @example
 * const MOCK_USER = {
    _id: "3X8etYGXLVWmbc0QIFXz",
    theme: "themeLight",
    personali: {
        displayName: "Soy Sauce",
        email: "soy.at.sauce.com",
        phoneNumber: "4343331234",
        photoURL: "3X8etYGXLVWmbc0QIFXz",
    },
    roles: ["ADMIN"],
    };
    
 */
export interface ScheduleSchema {
  _id?: string;
  userId: string;
  type: string;
  email_subject: string;
  recipient:string;
  recipients?: string[];
  cc: string;
  email_html_body: string;
  appId: string;
  email_from: string;
  email_text: string;
  email_reply_to: string;
  scheduleDate: string;
  scheduleStatus:boolean;
  createdAt: firebase.firestore.FieldValue;
}

/**
 * ###  User data to load when user sign up
 * -  Replace `_id`, `personali` with accurate data if possible
 */
export const _DEFAULT_MAIL_SCHEDULE: ScheduleSchema = {
  _id: "eXLEw0upFtWkTXbokCznZzzGLKH2",
  userId: "eXLEw0upFtWkTXbokCznZzzGLKH2",
  type: "single",
  email_subject: "I am testing soomething new",
  recipient: "paulnanle611@gmail.com",
  recipients: ["paulnanle611@gmail.com"],
  cc: "",
  email_html_body:
    "<div>I am writing you this mail because I wanna test something</div>",
  appId: "primerica-app",
  email_from: "paulnanle611@gmail.com",
  email_reply_to: "paulnanle611@gmail.com",
  email_text: "hello world",
  scheduleDate: "2021-03-23T10:38:37.2837245Z",
  scheduleStatus:false,
  createdAt: new Date()
};
