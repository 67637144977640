import { TextProps } from "@ui-kitten/components";
import Color from "color";
import React, { useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
  ViewStyle
} from "react-native";
import { IS_WEB, spacing } from "utils";
import { sstyled } from "../sstyled/sstyled";
import { Txt } from "../txt/Txt";

export interface ToastOptions {
  icon?: JSX.Element;
  type?: "normal" | "success" | "danger" | "warning" | "loading";
  duration?: number;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;

  successIcon?: JSX.Element;
  dangerIcon?: JSX.Element;
  warningIcon?: JSX.Element;
  loadingIcon?: JSX.Element;

  successColor?: string;
  dangerColor?: string;
  warningColor?: string;

  onPress?(id: string): void;
}

export interface ToastProps extends ToastOptions {
  id: string;
  onClose(): void;
  message: string | JSX.Element;
  placement?: "top" | "bottom";
}

export function Toast(props: ToastProps) {
  const {
    id,
    onClose,
    icon,
    type = "normal",
    message,
    duration = 3000,
    style,
    textStyle,

    successIcon,
    dangerIcon,
    warningIcon,
    loadingIcon = <ActivityIndicator size="small" color="white" />,

    successColor,
    dangerColor,
    warningColor,

    placement,

    onPress,
  } = props;
  const refCtnr = useRef<View>(null);
  const [animation] = useState(new Animated.Value(0));

  let _icon: JSX.Element;

  useEffect(() => {
    Animated.timing(animation, {
      toValue: 1,
      useNativeDriver: true,
      duration: 250,
    }).start();

    let closeTimeout: NodeJS.Timeout | null = null;

    if (duration !== 0 && typeof duration === "number") {
      closeTimeout = global.setTimeout(() => {
        Animated.timing(animation, {
          toValue: 0,
          useNativeDriver: true,
          duration: 250,
        }).start(() => onClose());
      }, duration);
    }

    return () => {
      closeTimeout && global.clearTimeout(closeTimeout);
    };
  }, []);

  if (icon === undefined) {
    switch (type) {
      case "success": {
        !!successIcon && (_icon = successIcon);
        break;
      }

      case "danger": {
        !!dangerIcon && (_icon = dangerIcon);
        break;
      }
      case "warning": {
        !!warningIcon && (_icon = warningIcon);
        break;
      }
      case "loading": {
        !!loadingIcon && (_icon = loadingIcon);
        break;
      }
      default: {
        _icon = successIcon;
        break;
      }
    }
  } else _icon = icon;

  const animationStyle = {
    opacity: animation,
    transform: [
      {
        translateY: animation.interpolate({
          inputRange: [0, 1],
          outputRange: placement === "bottom" ? [20, 0] : [0, 20], // 0 : 150, 0.5 : 75, 1 : 0
        }),
      },
    ],
  };

  let backgroundColor = "#274BDB";
  switch (type) {
    case "success":
      backgroundColor = successColor || "#00C851";
      break;
    case "danger":
      backgroundColor = dangerColor || "#ff4444";
      break;
    case "warning":
      backgroundColor = warningColor || "#ffbb33";
      break;
    default:
      backgroundColor = "#274BDB";
      break;
  }

  let { width } = useWindowDimensions();
  let toastyTextWidth = IS_WEB ? width * 0.5 : width * 0.7;
  const renderToast = () => (
    <Animated.View
      ref={refCtnr}
      style={[styles.container, animationStyle, { backgroundColor }, style]}
    >
      {_icon ? <SS.CtnrIcon>{_icon}</SS.CtnrIcon> : null}
      {React.isValidElement(message) ? (
        message
      ) : (
        <Txt.S1
          style={[styles.message, { maxWidth: toastyTextWidth }, textStyle]}
          numberOfLines={IS_WEB ? 2 : 3}
        >
          {message}
        </Txt.S1>
      )}
      <SS.TxtAction backgroundColor={backgroundColor} onPress={onClose}>
        Ok
      </SS.TxtAction>
    </Animated.View>
  );

  return onPress ? (
    <TouchableWithoutFeedback onPress={() => onPress(id)}>
      {renderToast()}
    </TouchableWithoutFeedback>
  ) : (
    renderToast()
  );
}

const SS = {
  CtnrIcon: sstyled(View)((p) => ({
    margin: spacing(3),
  })),
  TxtAction: sstyled<TextProps, { backgroundColor: string }>(Txt.C1)((p) => ({
    color: "text01",
    backgroundColor: Color(p.backgroundColor).lighten(0.4).toString(),
    paddingVertical: 5,
    paddingHorizontal: 10,
    margin: spacing(3),
    borderRadius: 21,
    alignSelf: "flex-end",
    justifyContent: "center",
  })),
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: IS_WEB ? spacing(3) : spacing(2),
    paddingVertical: spacing(2),
    borderRadius: 5,
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 200,
  },
  message: {
    color: "#fff",
    fontWeight: Platform.OS === "android" ? "bold" : "500",
  },
  iconContainer: {
    marginRight: 10,
  },
});

// export default Toast;
