//#region [import]
import { O, T } from "components";
import React from "react";

//#endregion

/**
 * ### Brief description
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 1.04.3
 *  -  *Brief changelog*
 *  @author  NL
 *
 **/
export function MassMailHomeScreen(props: P) {
  try {
    return (
      <T.TwoColumns
        bottomInset
        column1Content={(scrollHandler) => <O.MailTemplatesCreate />}
        column2Content={[
          {
            render: () => <O.PreviousMailList />,
            headerTitle: "Your Inbox",
            icon: "email",
            showTitle: true,
          },
        ]}
      />
    );
  } catch (error) {
    throw new Error(`::TWFzc01haWxIb21lU2NyZWVu:: ${error}`);
  }
}

/**
 * ###  Screen Props
 */
interface P {
  // route?: { params?: dMassMailHomeParams }; //* unmask if we pass params thru rnav
}
