import { dAccessory } from "components";
import React from "react";
import { colors } from "utils";
import { IconPrimr, ICON_NAME } from ".";

export function MenuIcon(name: ICON_NAME, props?: dAccessory) {
  return (
    <IconPrimr
      name={name}
      size={props?.style?.width || 24}
      color={props?.style?.tintColor || colors["color-basic-600"]}
    />
  );
}
