import { useTheme } from "@react-navigation/native";
import { Input, InputProps } from "@ui-kitten/components";
import { IconPrimr } from "assets/icons";
import { Buttoon, Kitten, sstyled } from "components/atoms";
import { useAppContext } from "engines";
import React from "react";
import { ButtonProps, View, ViewProps } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import { scale, spacing } from "utils";

/**
 * ### Get and save input from the user
 * A "Save" button pops up within the input once they change something.
 * 
 * @author jm_francis
 * @version 1.1.26
 * 
 * @example
 * <CommentInput
        title="Foo"
        doneText="Save"
        value={foo}
        onChangeText={setFoo}
        onSavePress={saveFoo}
        isUrl={false}
      />
 */
export const CommentInput = React.forwardRef(
    (props: dInpuut, ref: React.MutableRefObject<Input>) => {
        const {
            title,
            style = {},
            alwaysShowSave = false,
            onChangeText,
            onSavePress = () => { },
            isUrl,
        } = props;

        const { C } = useAppContext();
        const { dark } = useTheme()

        const [_saveEnabled, enableSave] = React.useState(alwaysShowSave);

        let _onChangeText = React.useCallback(
            (text: string) => onChangeText(text),
            [props?.value]
        );

        const inputProps = { ...props };

        /**
         * ### url formatter
         * -  If the isUrl prop is set to true, the following 3 Input props will be set automatically
         * @example
         * <Inpuut url />
         */
        if (isUrl) inputProps.autoCorrect = false;
        if (isUrl) inputProps.autoCapitalize = "none";
        if (isUrl) inputProps.keyboardType = "url";
        delete inputProps.title;

        React.useEffect(
            function setupEnableSave() {
                if (!inputProps?.value && !alwaysShowSave) {
                    enableSave(false);
                } else {
                    enableSave(true)
                }
            },
            [inputProps?.value]
        );

        const borderRadius = 30

        return (
            <Kitten.ViewH style={{ justifyContent: "space-between", borderRadius, borderWidth: 0.33, borderColor: C.grey500, paddingVertical: spacing(3), paddingLeft: spacing(4), paddingRight: spacing(3), overflow: "hidden", backgroundColor: C.background }}>
                <TextInput
                    autoFocus={true}
                    keyboardAppearance={dark ? "dark" : "light"}
                    keyboardType="twitter"
                    blurOnSubmit={false}
                    multiline={true}
                    // ref={ref}
                    numberOfLines={0}
                    placeholder="Type here..."
                    placeholderTextColor={C.dim}
                    // onSubmitEditing={() => {
                    // enableSave(false);
                    // }}
                    style={{ borderWidth: 0, margin: 0, color: C.text, flex: 1 }}
                    selectionColor={C.text}
                    autoCorrect={true}
                    {...inputProps}
                    onChangeText={(text) => {
                        enableSave(true);
                        _onChangeText(text);
                    }}
                />
                <Buttoon
                    disabled={!inputProps?.value}
                    appearance="filled"
                    status="info"
                    size="small"
                    style={{ margin: 0, padding: 0, borderRadius }}
                    onPress={() => {
                        enableSave(false);
                        onSavePress && onSavePress();
                    }}
                    icon={{ name: "pen" }}
                />
            </Kitten.ViewH>
        );
    }
);

const A = {
    Ctnr: sstyled(View)((p: { style?: {} }) => ({
        flex: 1,
        alignItems: "flex-end",
        flexDirection: "column",
        ...p.style,
    })),
};

interface dInpuut extends InputProps {
    /**
     * The title displays above the input
     */
    title?: string;
    inputStyle?: InputProps["style"];
    saveButtonStyle?: ViewProps["style"];
    saveButtonAppearance?: string;
    doneText?: string;
    alwaysShowSave?: boolean;
    value: any;
    isUrl?: boolean;
    placeholder?: string;
    onChangeText?: (text: string) => void;
    onSavePress?: () => void;
    disabled?: boolean;
    containerProps?: ViewProps;
}
