import { useFocusEffect } from "@react-navigation/native";
import { sstyled } from "components";
import { Avt, Buttoon, Kitten, ListHeader, Toasty } from "components/atoms";
import { fn, useAppContext } from "engines";
import {
  useConfigDocument,
  useTrophyCollection
} from "engines/firebase/handler/config-handler";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import * as ImagePicker from "expo-image-picker";
import React from "react";
import { Platform, TextInput, View } from "react-native";
import * as Animatable from "react-native-animatable";
import { useNavigator } from "screens/_navigation";
import { scale, spacing, tr } from "utils";
/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.15
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function CreateTrophy() {
  //   const { addFunc } = props;
  const { teamId } = useAppContext();
  const Navigation = useNavigator();
  const configsHandler = useConfigDocument(`variables-${teamId}`);
  const { data, error, add } = useTrophyCollection(`variables-${teamId}`);

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section] search ops

  const [image, setImage] = React.useState("");
  const [name, setName] = React.useState<string>();
  const [description, setDescription] = React.useState<string>();
  const [loader, setLoader] = React.useState<boolean>(false);
  const [mediaProgress, setMediaProgress] = React.useState<number>(0);
  //#endregion

  //#region [functions] Media picker
  async function cameraPermission() {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        Toasty.show(
          tr("Sorry, we need camera roll permissions to make this work!"),
          { type: "danger", duration: 10000 }
        );
      }
    }
  }
  async function pickImage() {
    await cameraPermission();
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    // console.log(result); //* e.g. "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="
    if (!result.cancelled) {
      let _mediaType = fn.media.extractMediaType(result.uri);

      if (_mediaType === "video") {
        return null;
      } else {
        setImage(result.uri);
        console.log("setImage(result.uri) ", image);
      }
    }
  }

  //#region [functions] addTrophyFunc
  /**
   * This is the onPress function which is used to create a category when clicked
   */
  async function addTrophyFunc() {
    if (!name) {
      Toasty.show("Please fill in the name field", { type: "danger" });
      setLoader(false);
    } else {
      fn.media
        .uploadMedia(
          image,
          {
            folder: `${CLOUDINARY_FOLDER.CLIENTS}/${teamId}/${CLOUDINARY_FOLDER.TROPHIES}`,
            title: "user_" + name,
            type: "image",
          },
          (progress) => {
            setMediaProgress(progress);
          },
          (data) => {}
        )
        .then((response) => {
          add({
            name: name,
            description: description,
            uri: response.url,
          }).then(() => {
            Toasty.show("Trophy case has been created", { type: "success" });
            setImage(null);
            setName(null);
            setDescription(null);
            Navigation.goBack();
          });
          // setSaveVisibility(false);
        })
        .catch((error) => {
          console.warn("Error updating avt: ", error);
          setImage(null);
          // return null;
        });
    }
    //#endregion
  }

  try {
    return (
      <A.CtnrPersonali>
        <ListHeader title={tr("Create a trophy")} />
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avt name={"name"} source={{ uri: image }} size={scale(69)} />
        </View>
        <Buttoon size={"tiny"} appearance="ghost" onPress={pickImage}>
          {tr("Select Image")}
        </Buttoon>
        <A.Input
          placeholder={tr("Trophy name")}
          autoCapitalize="none"
          value={name}
          onChangeText={setName}
          autoFocus={true}
        />
        {/* <Kitten.Divider_________ /> */}
        <A.InputParagragh
          placeholder={tr("Description")}
          autoCapitalize="none"
          value={description}
          onChangeText={setDescription}
          style={{ minWidth: scale(500) }}
        />

        <Buttoon
          onPress={() => {
            addTrophyFunc();
          }}
          disabled={loader}
        >
          {tr("Create")}
        </Buttoon>
        <Kitten.Divider_________ />
      </A.CtnrPersonali>
    );
  } catch (error) {
    throw Error(`::QWRkQ29udGFjdENyZWF0b3I=::${error}`);
  }
}

const A = {
  ViewContainer: sstyled(View)({
    marginVertical: 3,
    // minWidth: 300,
  }),
  Input: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
  InputParagragh: sstyled(TextInput)({
    minHeight: scale(169),
    color: "text",
    borderWidth: 1,
    borderRadius: scale(4),
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
  }),
  CtnrPersonali: sstyled(Animatable.View)((p) => ({
    justifyContent: "flex-start",
  })),
  CtnrAvatar: sstyled(View)({
    // flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  Ctnr$: sstyled(View)({
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    // width: p.WIDTH * 0.2,
  }),
};

interface P {
  addFunc(item): Promise<any>;
}
