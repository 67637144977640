//#region [import]
import { M, Ni, sstyled, Txt } from "components";
import { Kitten, ListHeader } from "components/atoms";
import { useAppContext } from "engines";
import React, { useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { spacing } from "utils";
//#endregion

/**
 * ### A quick screen for a quick start
 *
 * ----
 * @example As is
 * ----
 * @version 1.1.19
 * -  *Add fool-proof try-catch*
 * @author nguyenkhooi
 */
export function MediaHomeActions(props: P) {
  // const { route } = props;
  const { C } = useAppContext();
  const [isReady, shouldReady] = useState(true);
  try {
    //#region [section]  FOOL-PROOF
    if (isReady == false) throw Error("NOT_READY");

    //#endregion
    return (
      <A.Ctnr>
        <Kitten.Card style={{ marginBottom: spacing(4) }}>
          <ListHeader titleTx="Admin" />
          <M.MediaHomeActionsList />
        </Kitten.Card>
      </A.Ctnr>
    );
  } catch (error) {
    switch (error.message) {
      case "NOT_READY":
        return (
          <A.CtnrIdle>
            <ActivityIndicator size="large" color={C.primary} />
          </A.CtnrIdle>
        );
    }
  }
}

const A = {
  Ctnr: sstyled(View)((p) => ({
    width: "100%",
  })),
  CtnrIdle: sstyled(View)((p) => ({
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "background",
  })),
  CtnrWelcome: sstyled(Ni.Layout)(() => ({
    paddingVertical: spacing(4),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  })),
  TxtTitle: sstyled(Txt.H6)({
    textAlign: "center",
  }),
};

/**
 * ###  Screen Props
 */
interface P {
  // sbid: ScoreboardSchema["_sbid"];
  // route?: { params?: dScrParams }; //* unmask if we pass params thru rnav
}
