//#region [import]
import { useFocusEffect } from "@react-navigation/native";
import { Kitten, LinkTo, M, sstyled, Txt } from "components";
import { DatteLatte, ListHeader } from "components/atoms";
import { BoxMediaPicker } from "components/atoms/others/BoxMediaPicker/BoxMediaPicker";
import { PosstSchema, useAppContext } from "engines";
import * as R from "ramda";
import React, { useState } from "react";
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useForm
} from "react-hook-form";
import { ScrollView, TextInput, View } from "react-native";
import { scale, spacing, tr } from "utils";
//#endregion

/**
 * ### A page to create posst
 * -  Using `react-hook-form` for ease of use.
 *  - This hook returns a `rawForm` thru `getValues(_)`
 *  - See enum FORM for the form's keys
 *  - `rawForm` is then convert to `doneForm` with `PosstSchema`
 * and ready to upload to frbs
 *
 * ---
 * @example As is
 * ---
 * @version 1.1.22
 * @author nguyenkhooi
 */
export function PosstCreatorCenter(p: { editPosst?: PosstSchema }) {
  const { editPosst } = p;
  const refTextPost = React.useRef<TextInput>();
  const creatorForm = useForm();
  const [height, setHeight] = useState(42);

  useFocusEffect(() => {
    setTimeout(() => {
      refTextPost.current && refTextPost.current.focus();
    }, 700);
  });

  return (
    <FormProvider {...creatorForm}>
      <A.ViewBuilder
      // disabled
      // header={M.PosstCreatorHeader}
      // footer={M.PosstCreatorFooter}
      >
        {/* <M.PosstCreatorHeader /> */}
        {/* <Txt.Helper marginV style={{ flex: 1 }}>
          {tr("Give your post some cool content!")}
        </Txt.Helper> */}
        <Controller
          name={POSST_CREATOR_FORM.BODY}
          control={creatorForm.control}
          render={({ onChange, onBlur, value }) => (
            <>
              <A.InputBody
                ref={refTextPost}
                onBlur={onBlur}
                onChangeText={(value) => onChange(value)}
                value={value}
                defaultValue={editPosst?.body || ""}
                placeholder={"What do you want to say?"}
                multiline={true}
                textStyle={{ minHeight: 151, fontWeight: "bold" }}
                onContentSizeChange={(e) =>
                  setHeight(e.nativeEvent.contentSize.height)
                }
              />
            </>
          )}
          rules={{ required: true }}
          defaultValue={editPosst?.body}
        />
        <Controller
          name={POSST_CREATOR_FORM.MEDIAS}
          control={creatorForm.control}
          render={(p) => <PosstMediaRow {...p} />}
          rules={{ required: false }}
          // defaultValue={editPosst?.medias || []}
          defaultValue=""
        />
        <View>
          <Controller
            name={POSST_CREATOR_FORM.GOTOPAGE}
            control={creatorForm.control}
            render={(p) => <PosstLinkTo {...p} editPosst={editPosst} />}
            rules={{ required: true }}
            defaultValue={editPosst?.goToPage}
          />
          <Controller
            name={POSST_CREATOR_FORM.SCHEDULE}
            control={creatorForm.control}
            render={PosstSchedule}
            rules={{ required: true }}
            defaultValue=""
          />
          <Kitten.Divider_________ />
          <M.PosstCreatorFooter editPosst={editPosst} />
        </View>
      </A.ViewBuilder>
    </FormProvider>
  );
}

// _handleImage(result.uri, { userID: uid, bucket: "users", contentType });

const PosstMediaRow = ({
  onChange,
  editPosst,
}: ControllerRenderProps<Record<string, any>> & { editPosst?: PosstSchema }) => {
  // const { C } = useAppContext();
  //#region [section] Posst Medias

  const [posstMedias, setPosstMedias] = React.useState<PosstSchema["medias"]>(editPosst?.medias || [
    null,
    null,
    null,
    null,
  ]);

  const [reducedPosstMedias, setReducedPosstMedias] = React.useState<
    PosstSchema["medias"]
  >([]);
  // const [valueUrl, setValueUrl] = React.useState<string>("");
  async function onSelectCallback(
    item: PosstSchema["medias"][0],
    index: number
  ) {
    /**
     * - Adjust medias[] with callback media item (either media or null)
     * - Then reduce medias[] with non-null media item, before pass it to the form (onChange())
     */
    // console.log("adjustedMedias media item " + index + ": ", item);
    let adjustedMedias = R.adjust(index, () => item, posstMedias);
    setPosstMedias(adjustedMedias);
    console.log("adjustedMedias: ", adjustedMedias);
    let reducedMedias = adjustedMedias.filter((media) => media != null);
    console.log("adjustedMedias reduced: ", reducedMedias);
    setReducedPosstMedias(reducedMedias);
    onChange(reducedMedias);
  }

  //#endregion

  // const [showMediaRow, setShowMediaRow] = React.useState<boolean>(false);
  return (
    <>
      <ListHeader titleTx={tr("Photo/Video")} />
      {/* <Txt.Helper marginV>
        {tr(
          "Note: .MOV file is not previewable, but can still display on the page normally."
        )}
      </Txt.Helper> */}
      <A.ViewMediasSection>
        <A.ViewMediaUploaderBox>
          <BoxMediaPicker
            key={0}
            onSelect={(item) => onSelectCallback(item, 0)}
            containerStyle={reducedPosstMedias.length == 0 && { minHeight: 1 }}
          />
        </A.ViewMediaUploaderBox>
        {reducedPosstMedias.length > 0 && (
          <A.ViewMediaUploaderBox>
            <BoxMediaPicker
              key={1}
              onSelect={(item) => onSelectCallback(item, 1)}
            />
          </A.ViewMediaUploaderBox>
        )}
        {reducedPosstMedias.length > 1 && (
          <A.ViewMediaUploaderBox>
            <BoxMediaPicker
              key={2}
              onSelect={(item) => onSelectCallback(item, 2)}
            />
          </A.ViewMediaUploaderBox>
        )}
      </A.ViewMediasSection>
      {/* <Txt //? For debug only. Don't remove
         onPress={() => {
           console.log("posst medias: ", posstMedias);
           // alert(R.pluck("type", posstMedias));
         }}
       >
         {posstMedias.length}
       </Txt>
       <Txt
         onPress={() => {
           let reducedMedias = posstMedias.filter(function (el) {
             return el != null;
           });
           console.log("reduced medias: ", reducedMedias);
           // alert(R.pluck("type", reducedMedias));
         }}
       >
         Filter
       </Txt> */}
    </>
  );
};

const PosstLinkTo = ({
  onChange,
  editPosst,
}: ControllerRenderProps<Record<string, any>> & { editPosst?: PosstSchema }) => {
  const [showLinkTo, setShowLinkTo] = React.useState<boolean>(editPosst?.goToPage ? true : false);
  const [linkTo, setLinkTo] = React.useState<string>(editPosst?.goToPage || "");

  React.useEffect(
    function scheduleCallback() {
      if (showLinkTo) {
        linkTo && onChange(linkTo);
      } else {
        onChange(""); //* set "form-gotopage": ""
      }
    },
    [showLinkTo, linkTo]
  );
  return (
    <View>
      <ListHeader
        titleTx={tr("Link to")}
        accessoryRight={() => (
          <Kitten.CheckBox checked={showLinkTo} onChange={setShowLinkTo} />
        )}
      />
      {showLinkTo && <LinkTo onChange={setLinkTo} />}
    </View>
  );
};

const PosstSchedule = ({
  onChange,
}: ControllerRenderProps<Record<string, any>>) => {
  const [isScheduled, shouldSchedule] = React.useState<boolean>(false);
  const [scheduleAtStr, setScheduleAtStr] = React.useState<string>("");
  const [scheduleAt, setScheduleAt] = React.useState<Date>(new Date());

  React.useEffect(
    function scheduleCallback() {
      if (isScheduled) {
        scheduleAt && onChange(scheduleAt);
      } else {
        onChange(null);
      }
    },
    [isScheduled, scheduleAt]
  );
  return (
    <View>
      <ListHeader
        titleTx={tr("Schedule")}
        accessoryRight={() => (
          <Kitten.CheckBox checked={isScheduled} onChange={shouldSchedule} />
        )}
      />
      {isScheduled ? (
        <>
          <DatteLatte
            onSelectCallback={(dateStr, date) => {
              setScheduleAt(date);
              setScheduleAtStr(dateStr);
            }}
          />
          <Txt.Indicator marginV>
            {tr("Announcement will be posted at ") + scheduleAtStr}
          </Txt.Indicator>
        </>
      ) : null}
    </View>
  );
};

const A = {
  ViewBuilder: sstyled(ScrollView)((p) => ({
    // flex: 1,
    // backgroundColor: "background",
    width: "100%",
    marginBottom: 10,
    paddingHorizontal: spacing(2),
  })),
  ViewMediasSection: sstyled(View)({
    flexDirection: "row",
    flexWrap: "wrap",
  }),
  ViewMediaUploaderBox: sstyled(View)({
    width: scale(69),
    margin: spacing(2),
    flexGrow: 1,
  }),
  InputBody: sstyled(Kitten.Input)((p) => ({
    // maxWidth: 400,
    width: "100%",
    maxHeight: 800,
    padding: spacing(3),
    // backgroundColor: "back",
  })),
};

/**
 * ###  Naming system for form hook
 */
export enum POSST_CREATOR_FORM {
  BODY = "form-body",
  MEDIAS = "form-medias",
  GOTOPAGE = "form-gotopage",
  SCHEDULE = "form-schedule",
}
