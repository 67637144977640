import { Kitten } from "components";
import { Buttoon, Poppy, sstyled, Toasty, Txt } from "components/atoms";
import { BoxMediaPicker } from "components/atoms/others/BoxMediaPicker/BoxMediaPicker";
import { fn, ShortcutSchema, useAppContext } from "engines";
import { dUseCollectionReturns } from "engines/firebase/config";
import { useMoreDocument } from "engines/firebase/handler/more-handler";
import { DefaultMoreData } from "engines/firebase/initial-values";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { IconPrimr } from "assets/icons";
import { WidgetEditor } from "../widgets/widget-editor";
import * as R from "ramda";
import { FPATH } from "../../../engines/firebase/firebase.props";

export function ShortcutEditor(props: {
  shortcutsHandler?: dUseCollectionReturns<ShortcutSchema>;
  editingDocId?: string;
  mode: "default" | "from-home";
  type?: "contact" | "link" | "inapp";
}) {
  const { shortcutsHandler, editingDocId, type, mode = "default" } = props;
  const { teamId, C } = useAppContext();
  const Navigation = useNavigator();
  const shortcutHandler = useMoreDocument(editingDocId);
  //#region [section] fields
  const [position, setPosition] = React.useState<string>(
    shortcutHandler.data?.position
  );
  const [label, setLabel] = React.useState<string>(shortcutHandler.data?.name);
  const [url, setUrl] = React.useState<string>(
    shortcutHandler.data?.link ||
      shortcutHandler.data?.iosLink ||
      shortcutHandler.data?.androidLink ||
      shortcutHandler.data?.otherLink
  );
  const [contactEmail, setContactEmail] = React.useState<string>(
    shortcutHandler.data?.contactEmail
  );
  const [contactPhone, setContactPhone] = React.useState<string>(
    shortcutHandler.data?.contactPhone
  );
  const [thumbnail, setThumbnail] = React.useState<string>(
    shortcutHandler.data?.logo
  );

  const [_isStarred, setIsStarred] = React.useState<boolean>(
    mode === "from-home" || shortcutHandler.data?.isStarred
  );
  //#endregion
  //#region [section2] handle shortcut type
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  React.useEffect(
    function onSelectHandler() {
      if (shortcutsHandler) {
        if (selectedIndex == 0) {
          setContactEmail("");
          setContactPhone("");
        }
        if (selectedIndex == 1) {
          setUrl("");
        }
      }
    },
    [selectedIndex, shortcutsHandler]
  );
  //#endregion
  //#region [section] handle shortcut updating
  React.useEffect(
    function onSetCorrectType() {
      if (editingDocId && type) {
        type == "link" && setSelectedIndex(0);
        type == "contact" && setSelectedIndex(1);
        type == "inapp" && setSelectedIndex(2);
      }
    },
    [editingDocId, type]
  );
  //#endregion
  //#region [section2] handle Inapp option
  const [selectedInappIndex, setSelectedInappIndex] = React.useState<
    Kitten.IndexPath | Kitten.IndexPath[]
  >(new Kitten.IndexPath(2));

  let InappLists = ["Admin Portal", "Contact Manager", "Your Stats"];
  const displayInappValue = InappLists[selectedInappIndex?.row];

  React.useEffect(
    function updateInappIndexonUpdate() {
      let inAppIndex = InappLists.indexOf(
        shortcutHandler.data?.link?.split("inapp://").pop()
      );

      inAppIndex >= 0 &&
        setSelectedInappIndex(new Kitten.IndexPath(inAppIndex));
    },
    [shortcutHandler.data?.link]
  );
  //#endregion
  //#region [section] handle reposition
  function onMovingStart(sourceIndex: number, targetIndex: number) {
    let _shortcuts = shortcutsHandler?.data;
    /**
     * Calculate new target position value
     * -  if targetIndex < sourceIndex, position = avg(target-1 ,target);
     * if targetPostion is 0, position is set to -1
     * -  if targetIndex > sourceIndex, position = avg(target ,target + 1);
     * if targetPostion is n, position is set to n+1
     */
    const targetPosition = () => {
      if (targetIndex < sourceIndex) {
        return _shortcuts[targetIndex - 1]
          ? R.median([
              parseInt(_shortcuts[targetIndex - 1]?.position),
              parseInt(_shortcuts[targetIndex]?.position),
            ])
          : parseInt(_shortcuts[targetIndex]?.position) - 1;
      }
      if (targetIndex > sourceIndex) {
        return _shortcuts[targetIndex + 1]
          ? R.median([
              parseInt(_shortcuts[targetIndex]?.position),
              parseInt(_shortcuts[targetIndex + 1]?.position),
            ])
          : parseInt(_shortcuts[targetIndex]?.position) + 1;
      }
    };

    console.log("[move]: ", sourceIndex + " -> " + targetIndex);
    console.log(
      "[move] update position of doc ",
      _shortcuts[sourceIndex]?._id + " to " + targetPosition()
    );
    !R.isNil(targetPosition()) &&
      shortcutHandler
        .update<ShortcutSchema>(
          FPATH.MORE + "/" + _shortcuts[sourceIndex]?._id,
          {
            position: targetPosition(),
          }
        )
        .then(() => {
          Toasty.show(tr("Update position..."), {
            type: "loading",
            duration: 1100,
          });
        });
  }

  //#endregion
  //#region [functions]
  async function onSubmit() {
    let _link = () => {
      switch (selectedIndex) {
        case 0:
          return fn.js.validateWebUrl(url);
          break;
        case 1:
          return "";
          break;
        case 2:
          return "inapp://" + displayInappValue;
          break;
        default:
          return "";
          break;
      }
    };
    const toFirebase = (cloudinaryUri: string) =>
      shortcutsHandler?.add({
        ...DefaultMoreData({ teamId }),
        name: label,
        link: _link(),
        contactEmail,
        contactPhone,
        logo: cloudinaryUri,
        position: 0,
        isStarred: _isStarred,
      });

    if (thumbnail) {
      try {
        let response = await fn.media.uploadMedia(
          thumbnail,
          {
            title: "shortcut-icon-" + label,
            folder: `${CLOUDINARY_FOLDER.CLIENTS}/${teamId}`,
            type: "image",
          },
          (progress) => {},
          (data) => {}
        );

        response &&
          (toFirebase(response?.url),
          Toasty.show(tr("Successfully create a shortcut!"), {
            type: "success",
          }),
          Navigation.goBack());
      } catch (error) {
        Toasty.show(
          tr(
            "Error uploading thumbnail. A shortcut is created without a thumbnail."
          )
        );
        toFirebase("");
        Navigation.goBack();
      }
    } else {
      toFirebase("");
      Toasty.show(tr("Successfully create a shortcut!"), {
        type: "success",
      });
      Navigation.goBack();
    }
  }

  async function onUpdate() {
    let _link = () => {
      switch (selectedIndex) {
        case 0:
          return fn.js.validateWebUrl(url);
          break;
        case 1:
          return "";
          break;
        case 2:
          return "inapp://" + displayInappValue;
          break;
        default:
          return "";
          break;
      }
    };
    const toFirebase = (cloudinaryUri: string) =>
      shortcutHandler?.update({
        name: label,
        link: _link(),
        contactEmail,
        contactPhone,
        logo: cloudinaryUri,
        position,
        isStarred: _isStarred,
      });

    if (thumbnail && thumbnail !== shortcutHandler?.data?.logo) {
      try {
        let response = await fn.media.uploadMedia(
          thumbnail,
          {
            title: "shortcut-icon-" + label,
            folder: `${CLOUDINARY_FOLDER.CLIENTS}/${teamId}`,
            type: "image",
          },
          (progress) => {},
          (data) => {}
        );

        response &&
          (toFirebase(response?.url),
          Toasty.show(tr("Successfully update a shortcut!"), {
            type: "success",
          }),
          Navigation.goBack());
      } catch (error) {
        Toasty.show(
          tr(
            "Error uploading thumbnail. A shortcut is updated without a thumbnail."
          )
        );
        toFirebase("");
        Navigation.goBack();
      }
    } else {
      toFirebase("");
      Toasty.show(tr("Successfully update a shortcut!"), {
        type: "success",
      });
      Navigation.goBack();
    }
  }

  function onRemove() {
    Poppy(
      tr("Final Check"),
      tr("Do you want to remove this shortcut?"),
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: async () => {
          shortcutHandler.deleteDocument();
          Toasty.show(tr("Shortcut removed"));
          Navigation.goBack();
        },
      }
    );
  }
  //#endregion
  return (
    <View style={{ width: "100%" }}>
      <Txt.Debug>URL: {url}</Txt.Debug>
      <Txt.Helper marginV>{tr("Select the type of shortcut")}</Txt.Helper>
      <Kitten.RadioGroup
        selectedIndex={selectedIndex}
        style={{ flexDirection: "row", justifyContent: "space-between" }}
        onChange={(index) => setSelectedIndex(index)}
      >
        <Kitten.Radio>{tr("Link")}</Kitten.Radio>
        <Kitten.Radio>{tr("Contact")}</Kitten.Radio>
        <Kitten.Radio>{tr("Widget")}</Kitten.Radio>
      </Kitten.RadioGroup>
      {/* <A.Input
        placeholder={tr("Position")}
        autoCapitalize="none"
        value={position}
        onChangeText={setPosition}
      /> */}
      {selectedIndex == 0 && (
        <View>
          <A.Input
            placeholder={tr("Name")}
            autoCapitalize="none"
            value={label}
            onChangeText={setLabel}
          />
          <A.Input
            placeholder={tr("URL")}
            autoCapitalize="none"
            value={url}
            onChangeText={setUrl}
          />
        </View>
      )}
      {selectedIndex == 1 && (
        <View>
          <A.Input
            placeholder={tr("Name")}
            autoCapitalize="none"
            value={label}
            onChangeText={setLabel}
          />
          <A.Input
            placeholder={tr("Email")}
            autoCapitalize="none"
            value={contactEmail}
            onChangeText={setContactEmail}
          />
          <A.Input
            placeholder={tr("Phone")}
            autoCapitalize="none"
            value={contactPhone}
            onChangeText={setContactPhone}
          />
        </View>
      )}
      <Kitten.Divider_________ />
      {selectedIndex !== 2 && (
        <A.ViewThumbnail>
          <Txt.Helper style={{ marginRight: spacing(3) }}>
            {tr("Add a thumbnail, if needed")}
          </Txt.Helper>
          <A.ViewMediaUploader>
            <BoxMediaPicker
              key={0}
              onSelect={(media) => setThumbnail(media?.uri)}
              type={"Images"}
              initialMedia={{ uri: shortcutHandler.data?.logo, type: "image" }}
            />
          </A.ViewMediaUploader>
        </A.ViewThumbnail>
      )}
      {selectedIndex == 2 && (
        <View>
          {/* <Txt.Helper marginV>
            {tr("We're working on this feature. Please come back later.")}
          </Txt.Helper> */}
          {/* <A.Input
            placeholder={tr("Name")}
            autoCapitalize="none"
            value={label}
            onChangeText={setLabel}
          /> */}
          <WidgetEditor
            {...props}
            onValueCallback={({ name, link, isStarred }) => {
              setLabel(name);
              setUrl(link);
              setIsStarred(isStarred);
            }}
          />
          {/* <Kitten.Select
            selectedIndex={selectedInappIndex}
            onSelect={(index) => setSelectedInappIndex(index)}
            value={displayInappValue}
          >
            {InappLists.map((item, index) => (
              <Kitten.SelectItem title={item} key={index} />
            ))}
          </Kitten.Select> */}
        </View>
      )}
      <Kitten.Divider_________ />
      <Kitten.ListItemv2
        onPress={() => setIsStarred(!_isStarred)}
        title={"Star this shortcut"}
        accessoryRight={() => (
          <IconPrimr
            onPress={() => setIsStarred(!_isStarred)}
            name={_isStarred ? "star" : "staro"}
            color={_isStarred ? C.primary : C.grey600}
          />
        )}
        style={{ backgroundColor: "transparent" }}
      />
      <Kitten.Divider_________ />
      {shortcutsHandler && <Buttoon onPress={onSubmit}>{tr("Create")}</Buttoon>}
      {editingDocId && type && (
        <View>
          <Buttoon onPress={onUpdate}>{tr("Update")}</Buttoon>
          <Buttoon appearance="ghost" status="danger" onPress={onRemove}>
            {tr("Remove shortcut")}
          </Buttoon>
        </View>
      )}
    </View>
  );
}

const A = {
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
  Input: sstyled(TextInput)({
    minWidth: 3,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
  ViewThumbnail: sstyled(View)({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: spacing(6),
  }),
  ViewMediaUploader: sstyled(View)({
    // transform: [{ scale: 0.5 }],
    width: 6,
    height: 6,
    alignSelf: "flex-start",
  }),
};
