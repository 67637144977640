import { Buttoon, Kitten } from "components";
import { MediaPageSchema, useAppContext } from "engines";
import React from "react";
import { Modalize } from "react-native-modalize";
import { spacing } from "utils";

interface P {
  onSelect: (page: MediaPageSchema) => void;
  onNone: () => void;
}

export const SheetPageSelect = React.forwardRef<Modalize, P>((props: P, ref) => {
  const { onSelect, onNone } = props;
  const { C } = useAppContext();
  const [pages, setPages] = React.useState<MediaPageSchema[]>([]);
  const [_searchValue, setSearchValue] = React.useState("");

  // React.useEffect(function fetchPages() {
  //   Backend.firebasePages.pages((pages) => setPages(pages));
  // }, []);

  const searchPages = pages.filter((pg: MediaPageSchema) =>
    pg?.name?.toLowerCase().includes(_searchValue.toLowerCase())
  );

  console.log(`PAGES: ${pages.length} vs SEARCH_PAGES: ${searchPages.length}`);

  return (
    <Modalize
      ref={ref}
      scrollViewProps={{
        keyboardShouldPersistTaps: "always",
        showsVerticalScrollIndicator: false,
      }}
      // modalHeight={DEVICE_HEIGHT * 0.72}
      modalStyle={{
        backgroundColor: C.primary,
        padding: spacing(3),
        borderRadius: 20,
        // borderWidth: 1,
        // borderColor: C["color-primary-500"],
        borderBottomWidth: 0,
      }}
      HeaderComponent={() => (
        <Kitten.Input
          // ref={refSearch}
          // autoFocus={true} //? Maybe autoFocus for mobile
          placeholder="Search for a page..."
          value={_searchValue}
          onChangeText={(text) => setSearchValue(text)}
        />
      )}
    >
      <Buttoon
        appearance="outline"
        onPress={() => {
          onNone();
          ref.current.close();
        }}
      >
        None
      </Buttoon>
      <Kitten.List
        style={{
          width: "100%",
        }}
        data={searchPages}
        renderItem={({ item }) => (
          <Kitten.ListItem
            title={item.name}
            onPress={() => {
              onSelect(item);
              ref.current.close();
            }}
          />
        )}
      />
    </Modalize>
  );
});
