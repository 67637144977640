import { useFocusEffect } from "@react-navigation/native";
import { Poppy, Txt } from "components";
import {
  Buttoon,
  Inpuut,
  Kitten,
  ListHeader,
  sstyled,
  Toasty
} from "components/atoms";
import { PagesHomeItem } from "components/molecules";
import { useTwoColumns } from "components/templates";
import { MediaPageSchema, useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { usePagesCollection } from "engines/firebase/handler/pages-handler";
import { useCollabContext } from "engines/providers/collaboration-provider";
import { median } from "ramda";
import React from "react";
import {
  ActivityIndicator,
  ScrollViewProps,
  TextInput,
  View
} from "react-native";
import Animated from "react-native-reanimated";
import { useNavigator } from "screens/_navigation";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { IS_WEB, scale, spacing, tr } from "utils";

/**
 * ### List of media resources
 *  - Including some quick actions as a dropdown menu item
 *  ----
 *  @version 21.09.08
 *  -  *Memoize it*
 *  @author  K
 *
 **/
export function PagesHomeFeed(props: P) {
  const { scrollHandler = () => { } } = props;
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();

  //#region [FRBS]
  const userProfile = useUserProfile();
  const { data: pagesD, ...pagesHandler } = usePagesCollection();
  const { isCollabModeOn, setIsCollabModeOn } = useCollabContext();
  const [pages, setPages] = React.useState<MediaPageSchema[]>([]);
  const [editModeEnabled, setEditModeEnabled] = React.useState<boolean>(false);
  const [_pageDataItem, setPageDataItem] = React.useState([]);

  React.useEffect(
    function setupData() {
      /**
       * is this if statement necessary?
       */
      // if (pagesD && pagesD.length > 0 && pagesD[0]._teamId === teamId)
      //   setIsCollabModeOn(false);
      // }
      pagesD && setPages(pagesD);
    },
    [pagesD]
  );
  const [listOffset, setListOffset] = React.useState(30);

  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [functions] MoveToLastPosition
  function moveToLastPosition() {
    console.log(
      "pageContentsHandler._largestPositionIndex() ",
      pagesHandler._largestPositionIndex()
      // pageContentsHandler._largestPositionIndex()
    );

    let datavalue = {
      cpid: _pageDataItem[0]?.id,
      position: pagesHandler._largestPositionIndex(),
    };

    //after getting the new position
    pagesHandler._updateResourcePosition(datavalue);
    setPageDataItem([]);

    Toasty.show("Move complete!", { type: "success" });
    setEditModeEnabled(false);
  }
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>("");

  function filterHandler(value: string) {
    // console.log(`🤖 Changing query to ${value}`);
    setQuery(value);
    // const newData = pagesD?.filter((member) => {
    //   // console.log("member: ", member.personali);
    //   return member.name?.toLowerCase().includes(value.toLowerCase());
    // });
    // // console.log("valuee: ", newData);
    // setPages([...newData]);
  }
  //#endregion

  function toggleMovable() {
    setEditModeEnabled(!editModeEnabled);
    console.log(`🤖 Collab Mode ? ${isCollabModeOn ? "Yes" : "No"}`);
    if (isCollabModeOn) {
      setEditModeEnabled(false);
    }
  }

  return React.useMemo(() => {
    try {
      if (pagesHandler.error) {
        console.warn("error in MembersFeed: ", pagesHandler.error);
        return (
          <Txt.Indicator>{tr("Error getting media resources")}</Txt.Indicator>
        );
      }
      // if (!pagesD) return <Rush.Bar indeterminate style={{ width: "100%" }} />;
      if (!pagesD) return <ActivityIndicator />;

      // .filter((p) => !!p.position) //* To filter all NaN position pages out. If user complains, we know where to find
      let sortedPages = pages
        .filter(
          (page) =>
            page.mediaItem?.visible == true &&
            (!page.mediaItem?.team || page.mediaItem?.team == "false") //* since the team field currently includes both boolean and string boolean
        )?.sort((p1, p2) => (p1.position || 0) - (p2.position || 0))

      //#region [functions] addEntryClick
      /**
       * ###
       *  - Function used in moving the data from one position to another
       *  ----
       *  @example
       *  addEntryClick({MediaPageSchema})
       *  returns and update the document
       *  ----
       *  @version 21.09.30
       *  -  *Brief changelog*
       *  @author  Nl
       *
       **/

      async function addEntryClick(item: MediaPageSchema) {
        setPageDataItem((oldArray) => [...oldArray, item]);

        if (_pageDataItem.length === 1) {
          let newPosition;

          for (let i = 0; i < sortedPages?.length; i++) {
            const _item = sortedPages[i];
            if (_item._id == item._id) {
              //get the previous value in the list
              const _previousItem = sortedPages[i - 1];

              let _previousPosition =
                i === 0
                  ? sortedPages[0]?.position - 1
                  : _previousItem?.position;
              if (_item.position === _previousPosition) {
                _previousPosition -= 0.001;

                //the updated values
                let datavalue = {
                  cpid: _item?._id,
                  position: _previousPosition,
                };
                pagesHandler._updateResourcePosition(datavalue);
              }
              //this gets the median of the value before and the value after
              newPosition = median([_item?.position, _previousPosition]);
              break;
            }
          }

          let datavalue = {
            cpid: _pageDataItem[0]?.id,
            position: newPosition,
          };

          //after getting the new position
          pagesHandler._updateResourcePosition(datavalue);
          setPageDataItem([]);
          Toasty.show("Page Item has been moved", { type: "success" });
        }
      }

      //#endregion

      const ViewFeedHeader = () => {
        return (
          <View>
            {editModeEnabled ? (
              <Kitten.ViewH style={{ justifyContent: "space-between" }}>
                <Txt status="info" style={{ marginBottom: spacing(3) }}>
                  {tr("Select an item to place it on the top")}
                </Txt>

                <Buttoon
                  size="small"
                  status="info"
                  appearance="ghost"
                  onPress={() => {
                    setPageDataItem([]);
                    setEditModeEnabled(false);
                  }}
                >
                  {tr("Cancel")}
                </Buttoon>
              </Kitten.ViewH>
            ) : null}
            {/* <ListHeader
              titleTx={
                query
                  ? tr("Search result")
                  : tr("Pages") + " • " + sortedPages?.length
              }
            /> */}
            <Kitten.Input
              autoFocus={IS_WEB ? true : false}
              style={{
                backgroundColor: C.surface,
                paddingHorizontal: spacing(3),
                marginVertical: spacing(3),
              }}
              value={query}
              placeholder={
                tr("Search for page title or content...") +
                " • " +
                sortedPages?.length
              }
              onChangeText={(value) => {
                filterHandler(value);
              }}
            />
          </View>
        );
      };

      return (
        <Animated.FlatList
          scrollEventThrottle={16}
          onScroll={scrollHandler}
          ListHeaderComponent={ViewFeedHeader()}
          stickyHeaderIndices={[0]}
          contentContainerStyle={[column1ContainerStyle]}
          data={sortedPages}
          renderItem={({ item: page, index }) => {
            return (
              <PagesHomeItem
                index={index}
                cpid={page._id}
                addEntryClick={addEntryClick}
                editModeEnabled={editModeEnabled}
                onEditCallback={() => {
                  toggleMovable();
                }}
                pageTitle={page.name}
                query={query}
                onPress={() => {
                  setQuery("");
                  page.password && page.password
                    ? Navigation.navigate<dPageContentsScreenParam>(
                      "Page Contents",
                      { cpid: page._id }
                    )
                    : Navigation.navigate<dPageContentsScreenParam>(
                      "Page Contents",
                      { cpid: page._id }
                    );
                }}
              />
              // </Animatable.View>
            );
          }}
          ItemSeparatorComponent={() => <View style={{ margin: spacing(3) }} />}
          ListFooterComponent={() => (
            <>
              <Buttoon
                appearance={"ghost"}
                progress
                onPress={(xong) => {
                  setTimeout(() => {
                    setListOffset(listOffset + 5);
                    xong && xong();
                  }, 600);
                }}
                disabled={listOffset >= sortedPages.length}
              >
                {listOffset < sortedPages.length ? "Load More" : ""}
              </Buttoon>
              <View>
                {
                  //#region [section]
                  /** * Show this when:
                   * -  editMode is enabled,
                   * -  the item is the LAST one on the list
                   */
                  editModeEnabled && (
                    <A.PressableMoveToPlaceholder
                      status="basic"
                      size="large"
                      appearance="outline"
                      onPress={() => {
                        moveToLastPosition();
                      }}
                    >
                      {tr("Move item here *")}
                    </A.PressableMoveToPlaceholder>
                  )
                  //#endregion
                }
              </View>
              <ViewCol1BottomSpace />
            </>
          )}
          ListEmptyComponent={() => (
            <Txt.Indicator marginV>
              {pagesHandler.loading ? tr("Loading...") : tr("No page found")}
            </Txt.Indicator>
          )}
          keyExtractor={(item) => item._id}
        />
      );
    } catch (error) {
      throw Error(`::TWVkaWFIb21lRmVlZA==::${error}`);
    }
  }, [pages, listOffset, C, editModeEnabled, userProfile, query]);
}

/**
 * ###  Create a firestore doucment by inputing its title
 * -  Used for create a new page "document", a new scoreboard "document"
 * @param props
 * @returns
 */
export const DocumentCreatorWithTitle = (props: {
  onCreate: (pageName: string) => void;
  title?: string;
}) => {
  const { onCreate, title = tr("Enter page name") } = props;
  const Navigation = useNavigator();
  const { C } = useAppContext();
  const Container = ({ children }) => (
    <View>
      <ListHeader title={title} />
      {children}
      <Kitten.Divider_________ />
    </View>
  );

  return (
    <Container>
      <TitleEditor
        onCreate={(pageName: string) => {
          onCreate && onCreate(pageName);
          Navigation.goBack();
        }}
      />
    </Container>
  );
};

export function TitleEditor(props: { onCreate: (pageName: string) => void }) {
  const { onCreate } = props;
  const { C } = useAppContext();

  const [title, setTitle] = React.useState<string>("");
  const refInput = React.useRef<TextInput>();
  React.useEffect(function inputFocus() {
    setTimeout(() => {
      refInput.current?.focus();
    }, 420);
  }, []);
  return (
    <View>
      <Inpuut
        ref={refInput}
        autoFocus={true}
        placeholder={tr("Type Here")}
        value={title}
        onChangeText={setTitle}
        doneText={tr("Create")}
        alwaysShowSave
        onSavePress={() => {
          onCreate && onCreate(title);
        }}
        textContentType="oneTimeCode"
      />
    </View>
  );
}

interface dMediaHomeFeedParams { }
interface P {
  route?: { params?: dMediaHomeFeedParams }; //* TODO remember to create dResourcesBodyScreenParam
  scrollHandler?: ScrollViewProps["onScroll"];
}

const A = {
  PressableMoveToPlaceholder: sstyled(Buttoon)({
    width: "100%",
    height: scale(50),
    borderColor: "infoBlue",
    borderWidth: 2,
    borderStyle: "dotted",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: spacing(3),
  }),
};
