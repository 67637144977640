import { IconPrimr } from "assets/icons";
import {
  Buttoon,
  Kitten,
  LINKTO,
  LinkTo,
  ListHeader,
  Poppy,
  Rush,
  sstyled,
  Toasty,
  Txt
} from "components";
import {
  fn,
  FPATH,
  MediaPageItemSchema,
  MediaPageSchema,
  URL_PREFIX,
  useAppContext,
  usePageContentsCollection,
  __MediaResourcesData,
  __ResourceItemData
} from "engines";
import { dUseDocumentReturns } from "engines/firebase/config";
import {
  usePageDocument,
  usePagesCollection
} from "engines/firebase/handler/pages-handler";
import firebase from "firebase";
import React from "react";
import { TextInput, View } from "react-native";
import { scale, spacing, tr } from "utils";
import { _appearances, _statuses } from "../items/button-item";

export function ButtonItemEditor({
  cpid,
  pageContentDocument,
  onUpdateCallback,
}: {
  cpid: string;
  pageContentDocument?: dUseDocumentReturns<MediaPageItemSchema>;
  onUpdateCallback(): void;
}) {
  const { C, teamId } = useAppContext();

  const parentPageHandler = usePageDocument(cpid);

  //#region [FRBS] page contents handler
  let content = pageContentDocument?.data;
  const _MODE = content ? "update" : "create";
  let pagesHandler = usePagesCollection();
  const contentsHandler = usePageContentsCollection(cpid);
  //#endregion

  const refInput = React.useRef<TextInput>();
  React.useEffect(function staging() {
    refInput.current?.focus();
  }, []);
  const [isLevelButton, toggleLevelButton] = React.useState<boolean>(
    content?.url?.startsWith(LINKTO.LEVEL)
  ); //* If url link to a level, set it as a level button
  const [title, setTitle] = React.useState<string>(content?.title);
  //#region [section] Get current link label from `content?.url`
  const [currentLinkLabel, setCurrentLinkLabel] = React.useState<string>("");
  const [urlId, setUrlId] = React.useState<string>("");

  const pageHandler = usePageDocument(urlId);
  React.useEffect(
    /**
     * get link type and set button label from `goToPage` value
     */
    function getLinkType() {
      if (_MODE === "update") {
        if (content?.url?.startsWith(LINKTO.PAGE)) {
          setUrlId(content?.url?.replace(LINKTO.PAGE, ""));
          setCurrentLinkLabel(pageHandler.data?.name);
          // setCurrentLinkLabel("pageHandler.data?.name");
        }
        if (content?.url?.startsWith(LINKTO.LEVEL)) {
          setUrlId(content?.url?.replace(LINKTO.LEVEL, ""));
          setCurrentLinkLabel(pageHandler.data?.name);
          // setCurrentLinkLabel("pageHandler.data?.name");
        }
        if (content?.url?.startsWith("http")) {
          setCurrentLinkLabel(content?.url);
        }
      }
    },
    [pageHandler.data, _MODE]
  );

  //#endregion

  //#region [section2] Update link using LinkTo<>
  const [isEditingLinkTo, shouldEditLinkTo] = React.useState<boolean>(
    _MODE === "create"
  ); //* allow to edit linkTo in create mode by default
  const [selectedLinkTo, setSelectedLinkTo] = React.useState<string>("");
  //#endregion

  //#region [section] color selection
  let _colorCode = fn.js.styleSpilt(content?.paragraph, 0);
  const [selectedColorIndex, setSelectedColorIndex] = React.useState<number>(
    parseInt(_colorCode)
  );

  //#endregion
  //#region [section2] appearance selection
  let _appearanceCode = fn.js.styleSpilt(content?.paragraph, 1);
  const [selectedAppearanceIndex, setSelectedAppearanceIndex] =
    React.useState<number>(parseInt(_appearanceCode));

  //#endregion
  //#region [functions]
  async function onSubmit(xong) {
    const __toast = Toasty.show(tr("Updating link"), { type: "loading" });

    let fieldsToBeAdded = {
      ...__ResourceItemData(),
      title,
      position: contentsHandler._largestPositionIndex() + 1,
      paragraph:
        "stylecode:" + selectedColorIndex + "-" + selectedAppearanceIndex,
      url: isLevelButton
        ? content?.url?.replace(LINKTO.PAGE, LINKTO.LEVEL)
        : content?.url?.replace(LINKTO.LEVEL, LINKTO.PAGE),
    };
    /**
     * Get goToPageId value
     * ---
     * 3 scenarios:
     * -  newpage:[page_name] --> create a page doc, then assign its id to goToPageId
     * -  page:[page_id] --> assign page_id to goToPageId
     * -  url --> assign null to goToPageId, handle later on
     */
    const goToPageId = selectedLinkTo.includes(LINKTO.NEWPAGE)
      ? await pagesHandler.add({
        ...__MediaResourcesData({
          teamId,
          pageName: selectedLinkTo.split(":")[1],
        }),
        rolesThatCanEdit: parentPageHandler.data?.rolesThatCanEdit || [
          `BS:${parentPageHandler.data?._id}`,
        ],
        mediaItem: {
          ...__MediaResourcesData({ teamId, pageName: "_" }).mediaItem,
          visible: false,
        }, //* Since we're creating a SUBPAGE
        position: pagesHandler._largestPositionIndex() + 1,
      })
      : selectedLinkTo.includes(LINKTO.PAGE) ||
        selectedLinkTo.includes(LINKTO.LEVEL)
        ? selectedLinkTo.split(":")[1]
        : null;

    let url = isEditingLinkTo
      ? goToPageId
        ? isLevelButton
          ? `${LINKTO.LEVEL}:${goToPageId}`
          : `${LINKTO.PAGE}:${goToPageId}`
        : selectedLinkTo //* pure http link
      : content?.url;
    url =
      url?.includes(LINKTO.PAGE) || url?.includes(LINKTO.LEVEL)
        ? isLevelButton
          ? content?.url?.replace(LINKTO.PAGE, LINKTO.LEVEL)
          : content?.url?.replace(LINKTO.LEVEL, LINKTO.PAGE)
        : url;

    switch (_MODE) {
      case "create":
        return contentsHandler
          .add({
            ...fieldsToBeAdded,
            url: goToPageId
              ? `${isLevelButton ? LINKTO.LEVEL : LINKTO.PAGE}:${goToPageId}`
              : url, //* pure http link
          })
          .then(() => {
            setTimeout(() => {
              Toasty.show("Button added to bottom!");
              onUpdateCallback && onUpdateCallback();
              xong && xong();
            }, 690);
          });
        break;
      case "update":
        /** title is being updated */
        if (
          title !== content.title &&
          (url.startsWith(URL_PREFIX.PAGE) || url.startsWith(URL_PREFIX.LEVEL))
        ) {
          Poppy(
            "Do you want to change the name of this page?",
            "This will change button label AND the page name.",
            {
              text: "NO",
              onPress: () => { },
            },
            {
              text: "YES",
              onPress: () => {
                const pageId = url
                  .replace(URL_PREFIX.PAGE, "")
                  .replace(URL_PREFIX.LEVEL, "");
                // alert("updating age id " + pageId);
                firebase
                  .firestore()
                  .doc(`${FPATH.PAGES}/${pageId}`)
                  .update({ name: title } as MediaPageSchema)
                  .then(() => {
                    Toasty.show("Destination page title updated");
                  })
                  .catch((err) => {
                    Toasty.show(err, { type: "danger" });
                    console.error(err);
                  });
              },
            }
          );
        }
        return pageContentDocument
          .update({
            title,
            url,
            paragraph:
              "stylecode:" + selectedColorIndex + "-" + selectedAppearanceIndex,
          })
          .then(() => {
            Toasty.update(
              __toast,
              tr("Link updated. Please refresh the page."),
              {
                type: "success",
              }
            );
            xong && xong();
            onUpdateCallback && onUpdateCallback();
          })
          .catch(() => {
            xong && xong();
          });
        break;

      default:
        break;
    }
  }

  function onChangeColor() {
    if (selectedColorIndex >= _statuses.length - 1) {
      setSelectedColorIndex(0);
    } else {
      setSelectedColorIndex(selectedColorIndex + 1);
    }
  }
  function onChangeAppearance() {
    if (selectedAppearanceIndex >= _appearances.length - 1) {
      setSelectedAppearanceIndex(0);
    } else {
      setSelectedAppearanceIndex(selectedAppearanceIndex + 1);
    }
  }
  //#endregion

  return (
    <View>
      {
        //#region [section] Button Preview
        isLevelButton ? (
          <Rush.Bar
            style={{ width: "100%", height: scale(64), borderRadius: scale(4) }}
            progress={0.9}
            color={C.primaryDarker}
          >
            <Kitten.ListItemv2
              style={{ flex: 1, backgroundColor: "transparent" }}
              title={() => (
                <Kitten.ViewH>
                  <Txt.H6 numberOfLines={2}>
                    {title || "Try typing button label..."}
                  </Txt.H6>
                  <IconPrimr
                    name={"chevron_right"}
                    color={C.text}
                    preset={"circular"}
                  />
                </Kitten.ViewH>
              )}
              accessoryRight={() => (
                <Buttoon status="control" size="small">
                  {tr("Start now!")}
                </Buttoon>
              )}
            />
          </Rush.Bar>
        ) : (
          <A.ButtoonPreview
            compact
            icon={
              content?.url && content?.url.length > 1 ? { name: "link" } : null
            }
            status={_statuses[selectedColorIndex]}
            appearance={_appearances[selectedAppearanceIndex]}
          >
            {title || "Try typing button label..."}
          </A.ButtoonPreview>
        )
        //#endregion
      }

      <ListHeader titleTx={"Button label"} />
      <TextInput
        ref={refInput}
        value={title}
        multiline
        onChangeText={setTitle}
        placeholder="Type button label..."
        style={{
          flex: 1,
          color: C.text,
          fontSize: 18,
          borderBottomWidth: 1,
          borderColor: C.primary,
          minHeight: scale(65),
          padding: spacing(3),
          backgroundColor: C.surface,
        }}
      />
      <Kitten.Divider_________ />
      {
        //#region [section2] LinkTo
        isEditingLinkTo ? (
          <View>
            <ListHeader titleTx={tr("Button destination")} />
            <View style={{ paddingHorizontal: spacing(4) }}>
              <LinkTo
                defaultText={title}
                onChange={(link) => {
                  setSelectedLinkTo(link);
                  console.log("link to: ", link);
                }}
                disableOptions={isLevelButton ? ["url"] : []}
                flexDirection="column"
              />
            </View>

            {_MODE === "update" && (
              <Buttoon
                appearance={"ghost"}
                size="small"
                onPress={() => shouldEditLinkTo(false)}
                style={{ alignSelf: "flex-start" }}
              >
                {tr("Cancel, use the old link instead")}
              </Buttoon>
            )}
          </View>
        ) : (
          <View>
            <ListHeader titleTx={"Currently linked to "} />
            <Kitten.ViewH
              style={{
                flexWrap: "wrap",
                width: "100%",
                paddingHorizontal: spacing(4),
              }}
            >
              <Txt.S1>{currentLinkLabel}</Txt.S1>
              {pageHandler.loading ? (
                <Kitten.Spinner />
              ) : (
                <Buttoon
                  appearance={"ghost"}
                  size="small"
                  onPress={() => shouldEditLinkTo(true)}
                >
                  {tr("Change")}
                </Buttoon>
              )}
            </Kitten.ViewH>
          </View>
        )
        //#endregion
      }
      <Kitten.Divider_________ />
      {
        <>
          <ListHeader titleTx={tr("Customization")} />
          <Kitten.ListItemv2
            title={tr("Set as level button")}
            // disabled
            style={{ backgroundColor: "transparent" }}
            accessoryRight={() => (
              <Kitten.Toggle
                checked={isLevelButton}
                onChange={(status) => {
                  if (
                    selectedLinkTo?.startsWith("https://") ||
                    selectedLinkTo?.startsWith("http://")
                  ) {
                    Toasty.show("A level button cannot go to a web url.", {
                      type: "danger",
                    });
                  } else {
                    toggleLevelButton(status);
                  }
                }}
              />
            )}
          />

          {!isLevelButton && (
            <>
              <Buttoon
                size="medium"
                style={{ alignSelf: "flex-start", marginBottom: spacing(3) }}
                icon={{ name: "chevron_right" }}
                status={_statuses[selectedColorIndex]}
                appearance={_appearances[selectedAppearanceIndex]}
                onPress={onChangeColor}
              >
                {tr("Tap to change color")}
              </Buttoon>
              <Buttoon
                size="medium"
                style={{ alignSelf: "flex-start" }}
                icon={{ name: "chevron_right" }}
                status={_statuses[selectedColorIndex]}
                appearance={_appearances[selectedAppearanceIndex]}
                onPress={onChangeAppearance}
              >
                {tr("Tap to change appearance")}
              </Buttoon>
            </>
          )}

          <Kitten.Divider_________ />
        </>
      }
      {/* <Txt>updatedLinkTo: {updatedLinkTo}</Txt> */}
      <Buttoon
        compact
        progress
        disabled={
          /** Disabled when
           * title, updatedLinkTo, colorIndex, appearanceIndex are intact
           * */
          !title ||
          (title == content?.title &&
            (isEditingLinkTo ? !selectedLinkTo : true) &&
            parseInt(fn.js.styleSpilt(content?.paragraph, 0)) ==
            selectedColorIndex &&
            parseInt(fn.js.styleSpilt(content?.paragraph, 1)) ==
            selectedAppearanceIndex &&
            content &&
            content?.url?.startsWith(LINKTO.LEVEL) === isLevelButton)
        }
        onPress={onSubmit}
      >
        {_MODE === "create" ? tr("Add") : tr("Update")}
        {/* {JSON.stringify(content?.url?.startsWith(LINKTO.LEVEL))} */}
      </Buttoon>
    </View>
  );
}

function ButtonItemEditorOG({
  cpid,
  pageContentDocument,
  onUpdateCallback,
}: {
  cpid;
  pageContentDocument: dUseDocumentReturns<MediaPageItemSchema>;
  onUpdateCallback(): void;
}) {
  const { C } = useAppContext();
  //#region [FRBS] page contents handler

  // const handlePageContents = usePageContentCollection(cpid);
  //#endregion

  let content = pageContentDocument?.data;

  const refInput = React.useRef<TextInput>();
  const [value, setValue] = React.useState<string>(content?.title);

  let _colorCode = fn.js.styleSpilt(content?.paragraph, 0);
  const [selectedColorIndex, setSelectedColorIndex] = React.useState<number>(
    parseInt(_colorCode)
  );

  // const [selectedPage, setSetSelectedPage] = React.useState<any>();

  const [selectedPage, setSelectedPage] =
    React.useState<MediaPageSchema["id"]>(null);

  const [title, setTitle] = React.useState<string>(content?.title);
  //#region [section] values for link: page or url
  const [url, setUrl] = React.useState<string>(content?.url);
  //#endregion

  //#region [section2] appearance selection
  let _appearanceCode = fn.js.styleSpilt(content?.paragraph, 1);
  const [selectedAppearanceIndex, setSelectedAppearanceIndex] =
    React.useState<number>(parseInt(_appearanceCode));

  React.useEffect(function staging() {
    refInput.current?.focus();
  }, []);

  function onSubmit(xong) {
    alert(url);
    pageContentDocument.update({
      title,
      url,
      paragraph:
        "stylecode:" + selectedColorIndex + "-" + selectedAppearanceIndex,
    });

    onUpdateCallback && onUpdateCallback();
  }

  return (
    <View>
      <A.ButtoonPreview
        compact
        icon={content?.url && content?.url.length > 1 ? { name: "link" } : null}
        status={_statuses[selectedColorIndex]}
        appearance={_appearances[selectedAppearanceIndex]}
      >
        {content?.title}
      </A.ButtoonPreview>
      <A.CtnrActionCenter disabled>
        {/* <View> */}
        <ListHeader titleTx={tr("Title")} />
        <TextInput
          value={content?.title}
          onChangeText={setTitle}
          style={{ flex: 1, color: C.text, fontSize: 18 }}
          autoFocus={true}
        />
        <ListHeader titleTx={tr("Button destination")} />
        <LinkTo
          linkscustom={content?.url}
          onChange={(link) => {
            console.log("link to: ", link);

            link.includes(LINKTO.PAGE)
              ? setSelectedPage(link.split(LINKTO.PAGE).pop())
              : setUrl(link);
            link.includes(LINKTO.LEVEL)
              ? setSelectedPage(link.split(LINKTO.LEVEL).pop())
              : setUrl(link);
          }}
          flexDirection="column"
        />

        <ListHeader titleTx={tr("Color")} />
        <Kitten.RadioGroup
          selectedIndex={selectedColorIndex}
          onChange={(index) => setSelectedColorIndex(index)}
        >
          {_statuses.map((status) => (
            <Kitten.Radio status={status}>
              {fn.js.capitalize(status)}
            </Kitten.Radio>
          ))}
        </Kitten.RadioGroup>

        <View>
          <ListHeader titleTx={tr("Appearance " + _appearanceCode)} />
          <Kitten.RadioGroup
            selectedIndex={selectedAppearanceIndex}
            onChange={(index) => setSelectedAppearanceIndex(index)}
          >
            {_appearances.map((appearance) => (
              <Kitten.Radio>
                {appearance == "ghost"
                  ? "Text link"
                  : fn.js.capitalize(appearance)}
              </Kitten.Radio>
            ))}
          </Kitten.RadioGroup>
        </View>
        <View>
          <Buttoon
            compact
            disabled={!selectedPage && (!url || content?.url.length == 0)}
            onPress={onSubmit}
          >
            {tr("Update")}
          </Buttoon>
        </View>
      </A.CtnrActionCenter>
    </View>
  );
}

const A = {
  ButtoonPreview: sstyled(Buttoon)({
    marginBottom: 10,
  }),
  CtnrMenu: sstyled(Kitten.Menu)({
    minWidth: 8,
    flex: 1,
  }),
  Input: sstyled(TextInput)({
    minWidth: 10,
    marginBottom: 3,
    color: "text",
    fontSize: scale(16),
    borderBottomWidth: 1,
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
    flex: 1,
  }),
  InputLong: sstyled(TextInput)({
    color: "text",
    minHeight: 6,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "text",
    padding: 2,
    marginBottom: 4,
  }),
  CtnrActionCenter: sstyled(Kitten.Card)({
    minWidth: 9,
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row",
  }),
};
