//@ts-check
import * as calendar from "./calendar-functions";
import * as contacts from "./contacts-functions";
import * as js from "./js-functions";
import * as media from "./media-functions";
import * as notifications from "./notifications-functions";
import * as permissions from "./permission-functions";
import * as web from "./web-functions";
import * as you from "./you";
export const fn = {
  js,
  calendar,
  media,
  web,
  notifications,
  contacts,
  permissions,
  you,
};
