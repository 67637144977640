
//@ts-check
export * from "./errors";
export * from "./helpers";
export * from "./styles";
export * from "./typings";
export const tr = (text, strictedStrings = []) => {
  let str = String(text)
    .toLowerCase()
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());


  var i, j, _lowers, _uppers;

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  _lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = _lowers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\s" + _lowers[i] + "\\s", "g"),
      function (txt) {
        return txt.toLowerCase();
      }
    );

  // Certain words such as initialisms or acronyms should be left uppercase
  _uppers = ["Id", "Tv"]
  for (i = 0, j = _uppers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\b" + _uppers[i] + "\\b", "g"),
      _uppers[i].toUpperCase()
    );

  for (i = 0, j = strictedStrings.length; i < j; i++)
    str = str.replace(String(strictedStrings[i])
      .toLowerCase()
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()), strictedStrings[i])

  return str;
  // return str.charAt(0).toUpperCase() + str.slice(1);
};
