import { Buttoon, sstyled, Toasty } from "components";
import {
  MediaPageItemSchema,
  useAppContext,
  __ResourceItemData
} from "engines";
import { usePageContentsCollection } from "engines/firebase/handler/page-contents-handler";
import * as R from "ramda";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
export function ParagraphItemCreator({
  cpid,
  updates,
  updateFunc,
  onCloseCallback = () => {},
}: P) {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  //#region [FRBS] page contents handler
  const pageContentsHandler = usePageContentsCollection(cpid);
  //#endregion

  const [mediaPerm, setMediaPerm] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<
    MediaPageItemSchema["paragraph"]
  >(updates ? (updates?.updateType == false ? "" : updates.value) : "");

  function addItem(xong) {
    pageContentsHandler.add({
      ...__ResourceItemData(),
      paragraph: message,
      position: pageContentsHandler._largestPositionIndex() + 1, //* Latest position on the ContentBody
    });
    setTimeout(() => {
      Toasty.show(tr("Paragraph added!"));
      xong && xong();
      onCloseCallback && onCloseCallback();
    }, 690);
  }
  function updateItem(xong) {
    updateFunc && updateFunc();
  }
  const refInput = React.useRef<TextInput>();
  React.useEffect(function inputFocus() {
    setTimeout(() => {
      refInput.current?.focus();
    }, 420);
  }, []);

  // // #region [functions]
  // async function checkPhotoPermission() {
  //   console.log("this function was called");
  //   const { status } = await Permissions.getAsync(
  //     Permissions.MEDIA_LIBRARY || Permissions.CAMERA
  //   );
  //   if (status !== "granted") {
  //     Platform.OS == "android"
  //       ? Navigation.navigate("photopermission")
  //       : Navigation.overlayPortal.open({
  //           headerTitle: tr("Contacts Permission"),
  //           render: <ContactsPermissionPage setContactPermOn={setMediaPerm} />,
  //         });
  //   }

  //   // Navigation.navigate("photopermission")
  // }

  // React.useEffect(function permChecker() {
  //   checkPhotoPermission();
  // }, []);
  // // #endregion

  return (
    <View>
      <A.InputParagragh
        ref={refInput}
        placeholder={tr("Write here...")}
        value={message}
        onChangeText={setMessage}
        multiline
        style={{ minWidth: scale(200) }}
      />
      <Buttoon
        size={"small"}
        progress
        compact
        disabled={R.isEmpty(message)}
        onPress={(xong) => {
          updates
            ? updates.updateType == false
              ? addItem(xong)
              : updateFunc(message)
            : addItem(xong);
        }}
      >
        {updates
          ? updates?.updateType == false
            ? tr("Add")
            : tr("Update")
          : tr("Add")}
      </Buttoon>
    </View>
  );
}

const A = {
  InputParagragh: sstyled(TextInput)({
    minHeight: scale(169),
    color: "text",
    borderWidth: 1,
    borderRadius: scale(4),
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
  }),
};

interface P {
  /**
   * the doc id of the current custom page
   */
  cpid: string;
  onCloseCallback(): void;
  updates?: {
    updateType: boolean;
    value?: string;
  };
  updateFunc?: (title: string) => void;
}
