import { dIconPrimr, IconPrimr } from "assets";
import { useAppContext } from "engines";
import React from "react";
import { moderateScale } from "utils";

interface dProps extends Partial<dIconPrimr> {
  hovering?: boolean;
  service: "youtube" | "vimeo" | "default";
}

/**
 * ### Brief description
 * Play button image that displays on top of a video (VideoBlock component)
 *  ----
 *  @example
 *  See VideoBlock.tsx
 *  ----
 *  @version 21.03.29
 *  -  made functional component
 *  @author jm_francis
 *
 **/
export function VideoPlayImage(props: dProps) {
  const { hovering = false, service = "default" } = props;
  const { C } = useAppContext();
  let serviceColor = () => {
    switch (service) {
      case "youtube":
        return "#FF000097";
        break;
      case "vimeo":
        return "#86c9ef97";
      default:
        return C["color-primary-transparent-600"];
        break;
    }
  };
  return (
    <IconPrimr
      name="play"
      color={"white"}
      preset="circular"
      containerStyle={{
        backgroundColor: serviceColor(),
        paddingLeft: 4,
      }}
      size={hovering ? moderateScale(37) : moderateScale(31)}
      // style={{ opacity: hovering ? 0.97 : 0.69 }}
      {...props}
    />
  );
}
