import { Inpuut, Kitten, ListHeader, sstyled, Txt } from "components/atoms";
import { ACTION_TYPE, dTask, recordUnknownUserAction, recordUserAction, useAppContext, useUserTasks } from "engines";
import React, { useState } from "react";
import { KeyboardAvoidingView, Platform, View } from "react-native";
import { scale, spacing, tr } from "utils";

export function TodoCenter(p: { uid?: string }) {
  const { C, teamId, frbsUser } = useAppContext();
  const uid = p.uid ? p.uid : frbsUser.uid

  const [newTaskCaption, setNewTaskCaption] = useState("");

  const [allTasks, setAllTasks] = React.useState<dTask[]>();
  // const [dueTodayTasks, setDueTodayTasks] = React.useState<dTask[]>();
  const [allIncompleteTasks, setAllIncompleteTasks] = React.useState<dTask[]>();

  const taskHandler = useUserTasks(uid);

  function sortByCreatedAt(tasks: dTask[]): dTask[] {
    return tasks.sort(
      (a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime()
    );
  }

  React.useEffect(
    function updateTasks() {
      if (!taskHandler.data) return;
      setAllTasks(sortByCreatedAt(taskHandler.data));
      // setDueTodayTasks(sortByCreatedAt(taskHandler.getTasksDueToday()));
      setAllIncompleteTasks(sortByCreatedAt(taskHandler.getIncompleteTasks()));
    },
    [taskHandler.data]
  );

  const handleAddTask = async () => {
    if (newTaskCaption?.length < 1) {
      return;
    }
    const newTask: dTask = {
      caption: newTaskCaption,
      //@ts-ignore
      dueAt: new Date(),
      //@ts-ignore
      createdAt: new Date(),
      _teamId: teamId,
      completed: false,
    };
    setAllTasks([newTask, ...allTasks]);
    setAllIncompleteTasks([newTask, ...allIncompleteTasks]);
    let taskId = await taskHandler.addTask(newTask);
    setNewTaskCaption("");

    // record the action
    // const taskCaptionCondensed = newTaskCaption.length > 30 ?
    //   newTaskCaption?.substring(0, 17 - 3) + "..." :
    //   newTaskCaption;
    recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.ADD_TASK, `${frbsUser?.uid} created a new task: \"${newTaskCaption}\"`, { location: taskId })
  };

  const completeTask = (task: dTask, complete = true) => {
    task.completed = complete;
    setAllTasks(allTasks.map((t) => (t._id === task._id ? task : t)));
    complete
      ? taskHandler.completeTask(task)
      : taskHandler.uncompleteTask(task);

    recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.COMPLETE_TASK, `${frbsUser?.uid} completed personal task: \"${newTaskCaption}\"`, { location: task._id })
  };

  return (
    <A.ViewContainer>
      {/* Add a Task */}
      <A.KAContainer
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      // style={styles.writeTaskWrapper}
      >
        <Inpuut
          placeholder={"Add a task"}
          value={newTaskCaption}
          onChangeText={(text) => setNewTaskCaption(text)}
          doneText={"Add task"}
          onEndEditing={handleAddTask}
          onSavePress={handleAddTask}
        />
      </A.KAContainer>
      <Kitten.ListItemv2
        title={tr("My Tasks")}
        disabled
        accessoryRight={() =>
          allTasks?.length ? (
            <A.ChipIndicator>{allTasks?.filter(t => t.completed)?.length || "-"}</A.ChipIndicator>
          ) : (
            <></>
          )
        }
        style={{ backgroundColor: C.surface }}
      />
      <Kitten.List
        data={allIncompleteTasks}
        keyExtractor={(item, index) => index + "__" + item?._id}
        ItemSeparatorComponent={Kitten.Divider}
        style={{ backgroundColor: "transparent" }}
        renderItem={({ item, index }) => (
          <Kitten.ListItemv2
            key={index + "__" + item?._id}
            // onPress={() => completeTask(item)}
            title={() => (
              <Txt.S2
                style={{
                  paddingRight: spacing(3),
                  ...(item?.completed
                    ? {
                      textDecorationLine: "line-through",
                      color: C.grey600,
                    }
                    : {}),
                }}
                numberOfLines={0}
              >
                {item?.caption}
              </Txt.S2>
            )}
            accessoryRight={() => (
              <Kitten.CheckBox
                checked={item.completed}
                onChange={(v) => completeTask(item, v)}
              />
            )}
          />
        )}
        ListEmptyComponent={() => (
          <View style={{ marginVertical: spacing(3) }}>
            <Txt.Helper style={{ textAlign: "center" }}>
              {"Wow, all tasks complete!"}
            </Txt.Helper>
          </View>
        )}
      />

      <Kitten.Divider_________ />
      <ListHeader titleTx="Completed Tasks" />
      <Kitten.List
        data={allTasks ? allTasks?.filter((t) => t.completed) : []}
        keyExtractor={(item, index) => index + "__" + item?._id}
        ItemSeparatorComponent={Kitten.Divider}
        style={{ backgroundColor: "transparent" }}
        renderItem={({ item, index }) => (
          <Kitten.ListItemv2
            key={index + "__" + item?._id}
            // onPress={() => completeTask(item)}
            title={() => (
              <Txt.S2
                style={{
                  paddingRight: spacing(3),
                  ...(item?.completed
                    ? {
                      textDecorationLine: "line-through",
                      color: C.grey600,
                    }
                    : {}),
                }}
                numberOfLines={0}
              >
                {item?.caption}
              </Txt.S2>
            )}
            accessoryRight={() => (
              <Kitten.CheckBox
                checked={item.completed}
                onChange={(v) => completeTask(item, v)}
              />
            )}
          />
        )}
      />
    </A.ViewContainer>
  );
}

const A = {
  ViewContainer: sstyled(View)(({ hovered }) => ({
    // height: scale(120),
    flex: 1,
    flexGrow: 1,
    backgroundColor: "surface",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "errorRed",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
  KAContainer: sstyled(KeyboardAvoidingView)({
    padding: spacing(3),
  }),
};
