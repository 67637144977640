import firebase from "firebase"
import * as Contacts from "expo-contacts";
import { IconPrimr } from "assets";
import { Avt, Kitten, Txt } from "components";
import { useAppContext } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { dQualification, FPATH } from "engines/firebase/firebase.props";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import React from "react";
import { StyleSheet } from "react-native";
import { spacing } from "utils";

interface dContactCard {
  contact: ContactSchemaa;
  handleQualification?: (cid: string, selection: string | string[]) => void;
  // handleDone?: () => void;
  // handleDeletion?: (cid: string) => void;
}

// const { height: screenHeight } = Dimensions.get("window");
/**
 * ### Contact card that can show the contact info with their MACHO pointers(if any)
 *  -
 *  ----
 *  @example
 *  <ContactCard >
 *  ----
 *  @version 22.03.24
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export const ContactCard = (props: dContactCard) => {
  const {
    contact: bmlContact,
    handleQualification,
    // handleDone,
    // handleDeletion,
  } = props;
  const { qualificationData } = useBMLMContext();
  const { C } = useAppContext();
  const [isDone, setIsDone] = React.useState(false);
  const [qualificationsWithPoints, setQualificationsWithPoints] =
    React.useState<string[]>([]);
  const [selectedQualifications, setSelectedQualifications] = React.useState<
    string[]
  >([]);

  // if (bmlContact.id) {
  //   Contacts.getContactByIdAsync(bmlContact.id).then((contact) => {
  //     if (contact) {
  //       firebase.firestore.doc(`${FPATH.CONTACTS}/${bmlContact._cid}`).update()
  //     }
  //   }
  // }

  function getPointerColor(pointer: number): string {
    const totalPossiblePoints = qualificationsWithPoints.length;
    switch (totalPossiblePoints - pointer) {
      case 2:
        return "#ffcc33";
        break;
      case 1:
        return "#ee6600";
        break;
      case 0:
        return "#ff0000";
        break;
      default:
        return C.grey500;
        break;
    }
  }

  // React.useEffect(() => {}, [bmlContact]);

  React.useEffect(() => {
    if (qualificationData) {
      const points = [];
      qualificationData.forEach((q) => {
        if (q.countTowardsPointer) {
          points.push(q._id);
        }
      });
      setQualificationsWithPoints(points);
    }
  }, [qualificationData]);

  const CardContent = () => {
    const { qualificationData } = useBMLMContext();
    return (
      <Kitten.ButtonGroup
        style={{ justifyContent: "flex-start", flexWrap: "wrap" }}
      >
        {qualificationData.map((qualification) => (
          <QualifyButton
            key={qualification._id}
            disabled={isDone}
            qualificationData={qualification}
            contact={bmlContact}
            handleQualification={(cid, selection) => {
              // const qualifedQualiciations = bmlContact?.qualifications?.filter(
              //   (q) => (qualificationsWithPoints.includes(q) ? true : false)
              // )?.length;
              if (typeof selection !== "string") {
                console.warn("string array, not sure wat to do with it");
                return;
              }
              if (
                qualificationData.find(
                  (q) => q._id === selection && !q.countTowardsPointer
                )
              ) {
                console.warn(selection + " does not countTowardsPointer");
                return;
              }
              if (selectedQualifications.includes(selection as string)) {
                setSelectedQualifications(
                  selectedQualifications.filter((q) => q !== selection)
                );
              } else {
                setSelectedQualifications(
                  selectedQualifications.concat([selection as string])
                );
              }
              handleQualification && handleQualification(cid, selection);
            }}
          />
        ))}
      </Kitten.ButtonGroup>
    );
  };

  // console.log(
  //   "TEST name ",
  //   bmlContact.displayName,
  //   " and points ",
  //   bmlContact.qualifications.filter((q) =>
  //     qualificationsWithPoints.includes(q) ? true : false
  //   ).length,
  //   " data with points ",
  //   qualificationsWithPoints.length
  // );

  // const qualifedQualiciations = bmlContact?.qualifications?.filter((q) =>
  //   qualificationsWithPoints.includes(q) ? true : false
  // )?.length;

  const HeaderView = () => {
    return (
      <Kitten.ViewH style={{ backgroundColor: "transparent" }}>
        <Avt
          name={bmlContact?.name || bmlContact?.displayName || ""}
          style={{ marginRight: spacing(3) }}
          size="small"
        />
        <Txt.C1
          selectable={false}
          numberOfLines={1}
          style={{
            flexGrow: 1,
            fontSize: 18,
            color: isDone ? C["color-primary-900"] : C.text,
          }}
        >
          {(bmlContact?.name || bmlContact?.displayName)?.replace(
            "undefined",
            ""
          )}
        </Txt.C1>
        <IconPrimr
          size={34}
          //@ts-ignore
          // name={
          //   isDone
          //     ? "verified"
          //     : bmlContact?.qualifications?.length > 0
          //     ? "num" + qualifedQualiciations.length
          //     : "num"
          // }
          name={`num${selectedQualifications?.length}`}
          color={getPointerColor(selectedQualifications?.length)}
        />
      </Kitten.ViewH>
    );
  };

  // const handleOptionSelect = (id: string, option: MACHO) => {
  //   // console.log("WOHOO");
  //   if (tempSaveContact.qualifications.includes(option)) {
  //     tempSaveContact.qualifications = tempSaveContact.qualifications.filter(
  //       (q) => q !== option
  //     );
  //   } else {
  //     tempSaveContact.qualifications.push(option);
  //   }
  //   setTempSaveContact(tempSaveContact);
  // };

  return (
    <Kitten.ListItemv2
      title={() => <HeaderView />}
      description={() => <CardContent />}
    // accessoryLeft={() => (
    //   <Avt name={bmlContact.name || bmlContact.displayName || ""} />
    // )}
    />
  );
};

export const QualifyButton = ({
  qualificationData,
  contact,
  handleQualification,
  custom,
  disabled,
}: {
  qualificationData: dQualification;
  contact: ContactSchemaa;
  handleQualification: (cid: string, selection: string | string[]) => void;
  custom?: boolean;
  disabled?: boolean;
}) => {
  const { C } = useAppContext();
  let selection = qualificationData.name;

  const [isSelected, setIsSelected] = React.useState<boolean>(false);
  React.useEffect(
    function updateSelection() {
      setIsSelected(
        (contact?.qualifications as string[])?.includes(qualificationData._id)
      );
    },
    [contact?.qualifications]
  );

  const buttonStyle = !custom
    ? {
      backgroundColor: isSelected
        ? disabled
          ? C.grey600
          : C.primary
        : C.dim,
      borderColor: isSelected ? C.grey600 : C.grey500,
      ...(isSelected ? { borderColor: "transparent" } : {}),
    }
    : {
      backgroundColor: isSelected ? C.primary : C.dim,
      borderColor: isSelected ? C.grey600 : C.grey500,
      ...(isSelected ? { borderColor: "transparent" } : {}),
    };
  // const [updatedQualifications, setUpdatedQualifications] =
  //   React.useState(qualificationData);

  return (
    <Kitten.Button
      disabled={disabled ? disabled : false}
      size={"tiny"}
      style={{ ...buttonStyle, margin: spacing(2) }}
      onPress={() => {
        setIsSelected(!isSelected);
        handleQualification(contact._cid, qualificationData._id);
      }}
      children={() => (
        <Txt.S2
          style={{
            // margin: spacing(2),
            ...(!custom
              ? {
                color: isSelected ? C.grey900 : C.text,
                fontWeight: "600",
                fontSize: 13.5,
              }
              : { fontWeight: "600", fontSize: 13.5, color: C.text }),
          }}
        >
          {selection}
        </Txt.S2>
      )}
    />
  );
};

const styles = StyleSheet.create({
  avatar: {
    alignSelf: "center",
    marginHorizontal: 10,
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  topContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: spacing(3),
    paddingRight: spacing(2),
    height: 50,
  },
  card: {
    flex: 1,
    margin: 2,
  },
  // cardContainer: {
  //   borderRadius: 10,
  //   marginVertical: 5,
  //   borderWidth: 0,
  //   borderColor: "#99999940",
  // },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  footerControl: {
    marginHorizontal: 2,
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
  },
  contactsProfile: {
    borderRadius: 17,
    width: 34,
    height: 34,
    marginHorizontal: 10,
  },
});
