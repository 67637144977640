import { ItemType, MediaPageItemSchema, typeOfItem } from "engines";
import { MediaPageSchema } from "engines/backends";

// @deprecated
export const levelKeywords = ["Level", "Class", "Lesson", "Section", "Step"];

/**
 * @deprecated getting away from naming convention and using "level:" prefix
 * ### Whether or not this is a level page
 * - (a level page has a completion percentage)
 * @example
 * const isLevel = isLevelPage(someMediaPage)
 *
 * @version 21.4.17
 * @author jm_francis
 */
export function isLevelPage(name: string): boolean {
  if (!name) return false;
  for (let k in levelKeywords) {
    const kw = levelKeywords[k]; //.toLowerCase();
    const splitStr = name?.split(" ");
    if (splitStr?.length >= 2 && splitStr[splitStr?.length - 2] === kw) {
      return true;
    }
    // if (name?.toLowerCase().includes(kw)) return true;
  }
  return false;
}

/**
 * ###
 * @deprecated getting away from naming convention and using "level:" prefix
 *  - isLevelPageV2 allows you to pass a name and it determines if the name is a level or not
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.04
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function isLevelPageV2(name: string): boolean {
  for (let index = 0; index < levelKeywords.length; index++) {
    //Filter based on the levelKeywords
    if (String(name)?.includes(levelKeywords[index])) return true;
  }
  return false;
}

/**
 * @deprecated getting away from naming convention and using "level:" prefix
 * ### Returns the valid level enabling keyword used in the given page/level name
 *
 * @example
 * keywordFromName("Some Page - Class 4") // would return "Class"
 */
export const keywordFromName = (name: string): string | null => {
  for (let k in levelKeywords) {
    if (name && name.toLowerCase().includes(levelKeywords[k].toLowerCase()))
      return levelKeywords[k];
  }
  return null;
};

/**
 * Returns true if the specified dMediaPageItem is more than just some text by itself (like a video or audio)
 * Otherwise, if returned false, it will not be an item that counts toward level progress
 */
export function isCompletableItem(item: MediaPageItemSchema): boolean {
  const { itemType } = typeOfItem(item);
  // ex: if item is just a title/text, it should not be checkable
  return (
    itemType === ItemType.VIDEO ||
    itemType === ItemType.AUDIO ||
    itemType === ItemType.BUTTON ||
    itemType === ItemType.LEVEL_BUTTON ||
    itemType === ItemType.DOCUMENT ||
    itemType === ItemType.BLAST_TEXT ||
    itemType === ItemType.CHECKBOX
  );
  // const valid = (str: string) => str && str?.length > 1;
  // if (valid(item?.media) || valid(item?.url)) return true;
  // return false;
}

/**
 * @deprecated getting away from naming convention and using "level:" prefix
 * If the page/level name is "New Person - Level 2", the function will return the number 2
 */
export const indexFromName = (name: string): number =>
  Number.parseInt(
    name.substring(name?.length - 2, name?.length)?.replace(" ", "")
  );

//* @deprecated getting away from naming convention and using "level:" prefix
export const levelItemString = (
  parentPage: MediaPageSchema,
  mediaItem: MediaPageItemSchema | any
): string =>
  `${parentPage?.name?.replace(/:/g, "")}:${
    mediaItem?._id || mediaItem?.__snapshot?.id
  }`;
