import fakerStatic from "faker";
import firebase from "firebase";
/**
 * ### Posst Doc Schema
 * 
 * ---
 * @version 21.3.7
 * -  *Add goToPage*
 * @author  nguyenkhooi 
 * ---
 * @example
 * const MOCK_POSST = {
    _pid: "3X8etYGXLVWmbc0QIFXz",
    createdAt: { seconds: 1608393286, nanoseconds: 279000000 },
    postCaption: "This is the caption",
    author: {
      avatar: "https://i.redd.it/iibrptucse951.png",
      name: "Khoi Tran",
    },
    status: "POSTED",
    goToPage: null,
    media: {
      uri: "https://i.redd.it/iibrptucse951.png",
      type: "image",
    },
    likes: [],
  };
 */
export interface PosstSchema {
  _parentAppId?: string;
  _teamId: string;
  _pid: string;
  author: {
    _id: string;
    name: string;
    avatar: string;
  };
  body: string;
  medias: {
    uri: string;
    type: "image" | "video" | "vimeo" | "youtube";
  }[];
  pinned: boolean;
  goToPage?: string;
  createdAt: firebase.firestore.FieldValue;
  updatedAt: firebase.firestore.FieldValue;
  lastEdited?: firebase.firestore.Timestamp;
  scheduleAt: string; //todo @nanle_paul suggests to use localestring instead of firestore FieldValue // firebase.firestore.FieldValue;
  likes: string[];
  commentCount: number,
  status: POSST_STATUS;
}
/**
 * ```
 * wws
 * ```
 */

export enum POSST_STATUS {
  /**
   * ###  Whether posst is posted or not
   * -  Useful for scheduled posst
   */
  POSTED = "POSTED",
  PENDING = "PENDING",
  DELETED = "DELETED",
  REPORTED = "REPORTED",
  SCHEDULED = "SCHEDULED",
}

//@ts-ignore
export const _MOCK_POSSTS: PosstSchema[] = new Array(20).fill({
  _pid: "p000",
  author: {
    _id: "id_000",
    name: "Rice Cooker",
    avatar: "https://i.redd.it/iibrptucse951.png",
  },
  body: "This is a mock post",
  medias: [{ uri: "https://i.redd.it/iibrptucse951.png", type: "image" }],
  createdAt: new Date(),
  goToPage: null,
  likes: ["000", "111"],
  commentCount: 0,
  status: "POSTED",
});

export const _DEFAULT_POSST: PosstSchema = {
  _pid: fakerStatic.datatype.uuid(),
  _teamId: "apptakeoff-team",
  author: {
    _id: fakerStatic.datatype.uuid(),
    name: fakerStatic.datatype.uuid(),
    avatar: "https://i.redd.it/iibrptucse951.png",
  },
  body: fakerStatic.lorem.paragraphs(1),
  medias: [],
  createdAt: null,
  updatedAt: null,
  scheduleAt: null,
  goToPage: null,
  likes: [],
  commentCount: 0,
  status: POSST_STATUS.POSTED,
};
