import { Kitten } from "components";
import { Inpuut, sstyled, Toasty } from "components/atoms";
import { useAppContext } from "engines";
import { useConfigDocument } from "engines/firebase/handler/config-handler";
import React from "react";
import { TextInput } from "react-native";
import { tr } from "utils";

/**
 * ###
 *  - This component would be for developer tools
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.05.02
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function DeveloperToolsCenter(props: P) {
  const { C, teamId } = useAppContext();
  // const userProfile = useUserProfile();

  const configVariablesHandler = useConfigDocument(`variables-${teamId}`);
  const refInput = React.useRef<TextInput>();
  const [releaseVersion, setReleaseVersion] = React.useState<string>(
    configVariablesHandler?.data?.releaseVersion &&
      configVariablesHandler?.data?.releaseVersion
  );
  function updateReleaseVersion() {
    configVariablesHandler
      .update({
        releaseVersion: releaseVersion,
      })
      .then((res) => {
        Toasty.show("Release version updated", { type: "success" });
      })
      .catch((e) => {
        Toasty.show("Failed to update release version", { type: "danger" });
      });
  }

  try {
    if (configVariablesHandler.error) throw Error();
    if (!configVariablesHandler.data) return null;

    return (
      <A.CtnrMenu>
        <Inpuut
          ref={refInput}
          title={tr("set release version")}
          placeholder={tr("Set release version")}
          value={releaseVersion}
          onChangeText={setReleaseVersion}
          doneText={tr("Update")}
          alwaysShowSave
          onSavePress={() => {
            updateReleaseVersion && updateReleaseVersion();
          }}
          textContentType="oneTimeCode"
        />
      </A.CtnrMenu>
    );
  } catch (e) {
    throw new Error(e);
  }
}

interface P {}

const A = {
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
};
