import { appKeys } from "../../../../cloud-functions/primr-setup/functions/src/projectApiKeys";

/**
 * ### Get API Key
 *  - This function is used in searching through the appKeys and returning the right key based on the team ID
 *  ----
 *  @example
 *  var apiVALUE = fn.js.getApiKey(data.teamId);
 *  return Cloundfunction.apiKey.keys  {name: 'app-takeoff-team', apiKey: 'AIzaSyBoae3ydBqRxAzc4dLkr5csqiiD-aLvK7o'}
 *  ----
 *  @version 21.09.22
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function getApiKey(teamId: string) {
  let value: serviceConfig = appKeys?.keys?.find(
    (el, idx) => el?.name == teamId
  );

  console.log(`APP KEYS: \n${JSON.stringify(appKeys.keys)}`);
  console.log("\nstnadup to " + teamId);

  return value;
}

export interface serviceConfig {
  name: string;
  apiKey: string;
}
