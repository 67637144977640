import { Txt } from "components";
import React from "react";
import { View } from "react-native";


export interface BMLWebProps {}

export const BMLWeb = (props: BMLWebProps) => {
  try {
    return (
      <View>
        <Txt style={{ backgroundColor: "yellow", color: "black" }}>
          {"Welcome to BML Web"}
        </Txt>
      </View>
    );
  } catch (error) {
    throw Error(`::Qk1MV2Vi::${error}`);
  }
};
