import { IndexPath } from "@ui-kitten/components";
import { Kitten, Txt } from "components/atoms";
import { useAppContext } from "engines";
import React from "react";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { tr } from "utils";
import {
  ConfigVariablesSchema,
  dActivityConfiguration
} from "../../../engines/backends/schemas/configs/configs.schema";
import { useConfigDocument } from "../../../engines/firebase/handler/config-handler";
import { spacing } from "../../../utils/styles/spacing";
import { Buttoon } from "../../atoms/generals/buttoon/Buttoon";
import Toasty from "../../atoms/generals/toasty/Toasty";

function generateUniqueKeyFromTitle(
  title: string,
  activities: dActivityConfiguration[]
): string {
  let key = title.replace(/ /g, "");
  let count = 1;
  while (activities.find((a) => a.key === key)) {
    key = `${key}${count++}`;
  }
  return key;
}

export function EditDailyStats(p: {}) {
  const { teamId } = useAppContext();
  const configHandler = useConfigDocument(`variables-${teamId}`);

  const [didMakeChanges, setDidMakeChanges] = React.useState<boolean>(false);
  const [activities, setActivities] = React.useState<dActivityConfiguration[]>(
    []
  );

  React.useEffect(
    function initActivities() {
      configHandler.data &&
        setActivities(
          (configHandler.data as ConfigVariablesSchema).activities || []
        );
    },
    [configHandler.data]
  );

  return (
    <View style={{ flex: 1 }}>
      <Txt.$Title marginV>Edit Daily Report Form</Txt.$Title>
      <FlatList
        data={activities}
        renderItem={({ item: _activity, index }) => (
          <ActivityItem
            activity={_activity}
            onUpdate={(_act) => {
              setActivities(
                activities.map((a, idx) => (idx === index ? _act : a))
              );
              !didMakeChanges && setDidMakeChanges(true);
            }}
            onDelete={(_act) => {
              setActivities(
                activities.filter((a, i) => (i === index ? false : true))
              );
              !didMakeChanges && setDidMakeChanges(true);
            }}
          />
        )}
      />
      <Kitten.ViewH style={{ justifyContent: "space-between" }}>
        <Buttoon
          status="primary"
          appearance="ghost"
          icon={{ name: "plus" }}
          onPress={() => {
            setActivities(
              activities.concat([
                // @ts-ignore not all fields required yet
                {
                  title: "",
                  description: "",
                  position: 0,
                  entryType: "number",
                },
              ])
            );
          }}
        >
          {tr("Add question")}
        </Buttoon>
        <Buttoon
          appearance="ghost"
          disabled={!didMakeChanges}
          onPress={async () => {
            const __toasty = Toasty.show("Updating activities...", {
              type: "loading",
            });
            try {
              if (activities.find((a) => a.title?.length < 1)) {
                Toasty.show("You cannot have an empty activity.", {
                  type: "normal",
                });
                return;
              }

              await configHandler.update({
                activities: activities.map((_act) =>
                  !_act.key
                    ? {
                      ..._act,
                      key: generateUniqueKeyFromTitle(_act.title, activities),
                      position:
                        activities.reduce((max, obj) =>
                          obj.position > max.position ? obj : max
                        ).position + 1,
                    }
                    : _act
                ),
              });
              Toasty.hide(__toasty);
              Toasty.show("Update complete!");
            } catch (err) {
              console.error(err);
              Toasty.hide(__toasty);
              Toasty.show(`Failed to update ${err}`, { type: "danger" });
            }
          }}
        >
          {tr("Save Changes")}
        </Buttoon>
      </Kitten.ViewH>
    </View>
  );
}

function ActivityItem(p: {
  activity: dActivityConfiguration;
  onUpdate: (activity: dActivityConfiguration) => void;
  onDelete: (activity: dActivityConfiguration) => void;
}) {
  const { activity, onUpdate, onDelete } = p;
  const [title, setTitle] = React.useState<string>(activity.title || "");
  const [description, setDescription] = React.useState<string>(
    activity.description || ""
  );
  const [minNumberForStreak, setMinNumberForStreak] =
    React.useState<string>(activity?.streakMinimum ? `${activity?.streakMinimum}` : "1");
  // const [entryType, setEntryType] = React.useState<
  //   dActivityConfiguration["entryType"]
  // >(activity.entryType || "number");
  const entryTypes = ["number", "checkbox", "text"];
  const [selectedIndex, setSelectedIndex] = React.useState(
    new IndexPath(entryTypes.indexOf(activity.entryType))
  );

  React.useEffect(
    function activityChanged() {
      // make sure to fill in missing required fields like .key and .position before sending to firestore (if this is a new activity)
      onUpdate({
        ...activity,
        title,
        description,
        streakMinimum: Number.parseInt(minNumberForStreak),
        // @ts-ignore
        entryType: entryTypes[selectedIndex.row],
      });
    },
    [title, description, selectedIndex, minNumberForStreak]
  );

  return (
    <Kitten.ListItem
      style={{
        // borderBottomWidth: 1,
        // borderColor: C.grey900,
        marginBottom: spacing(3),
      }}
    >
      <View style={{ flex: 1 }}>
        {/* <Txt.S1>{activity.title}</Txt.S1> */}
        <Kitten.Input
          label="Title"
          value={title}
          onChangeText={setTitle}
          style={styles.itemContainer}
        />
        <Kitten.Input
          label="Description"
          placeholder="(optional)"
          value={description}
          onChangeText={setDescription}
          style={styles.itemContainer}
        />
        <Kitten.Select
          style={styles.itemContainer}
          label="Entry Type"
          placeholder="Choose one!"
          onSelect={setSelectedIndex}
          selectedIndex={selectedIndex}
          value={(_idk) => (
            <Txt.S1>
              {selectedIndex.row === 0
                ? "Number"
                : selectedIndex.row === 1
                  ? "Checkbox"
                  : selectedIndex.row === 2
                    ? "Custom Entry"
                    : "Unknown entry type"}
            </Txt.S1>
          )}
        >
          <Kitten.SelectItem title="Number" />
          <Kitten.SelectItem title="Checkbox" />
          <Kitten.SelectItem title="Custom Entry" />
        </Kitten.Select>
        {entryTypes[selectedIndex.row] === "number" ? (
          <Kitten.Input
            label="Minimum Requirement for Streak"
            placeholder="1"
            value={minNumberForStreak}
            onChangeText={(val) => {
              setMinNumberForStreak(val || "1");
            }}
            style={styles.itemContainer}
          />
        ) : null}
        <Buttoon
          style={{ alignSelf: "flex-end" }}
          onPress={() => {
            onDelete(activity);
            Toasty.show(
              "Activity will not be deleted until you save. Cancel to undo.",
              {
                type: "normal",
              }
            );
          }}
          icon={{ name: "trash" }}
          status="danger"
          appearance="ghost"
          size="small"
        />
      </View>
    </Kitten.ListItem>
  );
}

const styles = {
  itemContainer: {
    width: "100%",
    marginBottom: spacing(2),
  },
};
