import { SelectProps } from "@ui-kitten/components";
import { Kitten } from "components";
import { Avt, Inpuut, Rush, Txt } from "components/atoms";
import { useAppContext, UserSchema } from "engines";
import { useMembersCollection } from "engines/firebase/handler/members-handler";
import React from "react";
import { tr } from "utils";

/**
 * ### Multi select member(s)
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.04.17
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function MembersMultiSelect({
  label = "Select",
  selectedEmailsCallback,
  selectedIdsCallback,
  rejectedList,
  ...props
}: dMembersMultiSelect) {
  const { teamId } = useAppContext();
  //#region [FRBS] members handler
  const handleMembers = useMembersCollection({
    where: ["_teamId", "==", teamId],
  });
  //#endregion

  //#region [section] UI Kitten's Grouped Selection setup.
  //* @see https://akveo.github.io/react-native-ui-kitten/docs/components/select/overview#select

  const [groupedData, setGroupedData] = React.useState<dGroupedData>({
    All: [],
  });

  React.useEffect(
    function updateGroupedMembersData() {
      if (!!handleMembers.data) {
        let _reducedData: dReducedData[] = handleMembers.data
          .filter((member) =>
            rejectedList ? !rejectedList.includes(member._id) : member
          )
          .reduce(
            (a, c) => [
              ...a,
              {
                _id: c._id,
                displayName: c.personali.displayName,
                email: c.personali.email,
              },
            ],
            []
          );
        let _groupTitle = rejectedList
          ? "Available (" + _reducedData.length + ")"
          : "All (" + _reducedData.length + ")";
        setGroupedData({
          // All: getNames(handleMembers.data),
          [_groupTitle]: _reducedData,
        });
      }
    },
    [handleMembers.data]
  );

  const [multiSelectedIndex, setMultiSelectedIndex] = React.useState([
    // new Kitten.IndexPath(0, 0),
    // new Kitten.IndexPath(1, 1), //* add this if we have another group. Kinda clumsy but will find a solution for this
  ]);

  /**
   * Display members' names (emails if names == null) from members collection
   */
  const groupDisplayValues = multiSelectedIndex.map((index) => {
    const groupTitle = Object.keys(groupedData)[index.section];
    console.log("groupTitle value: ", groupTitle);
    return ["Hye,", "dafs"];
    // return (
    //   groupedData[groupTitle][index.row]?.displayName ||
    //   groupedData[groupTitle][index.row]?.email
    // );
  });
  //#endregion

  const [foo, setFoo] = React.useState<string>("");

  // React.useEffect(
  /**
   * When u select members,
   * callback functions (selectedEmailsCallback, selectedIdsCallback)
   * will be called
   */
  //   function callback() {
  //     const _selectedEmails = multiSelectedIndex.map((index) => {
  //       const groupTitle = Object.keys(groupedData)[index.section];
  //       return groupedData[groupTitle][index.row]?.email;
  //     });
  //     const _selectedIds = multiSelectedIndex.map((index) => {
  //       const groupTitle = Object.keys(groupedData)[index.section];
  //       return groupedData[groupTitle][index.row]?._id;
  //     });
  //     selectedEmailsCallback && selectedEmailsCallback(_selectedEmails);
  //     selectedIdsCallback && selectedIdsCallback(_selectedIds);
  //   },
  //   [multiSelectedIndex]
  // );
  // return (
  //   <>
  //     {
  //       //* Map group names first...
  //       Object.keys(groupedData).map((groupName, index) => (
  //         <Kitten.Layout key={index} title={groupName}>
  //           {
  //             //* ... then in each group, map members' name into a list
  //             groupedData[groupName].map((user, index) => {
  //               return (
  //                 //* Normal SelectItem for all members
  //                 <Kitten.ListItemv2
  //                   key={index}
  //                   title={
  //                     user?.personali?.displayName ||
  //                     "Unnamed • " + user?.personali?.email
  //                   }
  //                   accessoryRight={() => (
  //                     <Avt
  //                       size="small"
  //                       name={user?.personali?.displayName}
  //                       source={{ uri: user?.personali?.photoURL }}
  //                     />
  //                   )}
  //                 />
  //               );
  //             })
  //           }
  //         </Kitten.Layout>
  //       ))
  //     }
  //   </>
  // );

  if (handleMembers.error) {
    console.warn("error in MembersMultiSelect: ", handleMembers.error);
    return (
      <Txt.Indicator marginV>Error loading member (err_mem1)</Txt.Indicator>
    );
  }

  if (!handleMembers.data) return <Rush.Bar {...Rush._config.Loading} />;
  return (
    <>
      <Kitten.Select
        label={label}
        // style={styles.select}
        multiSelect={true}
        caption={
          /** List of rejected members' names/emails which won't show up in the list */
          (rejectedList || rejectedList != []) &&
          tr(
            "Unavailable: " +
              handleMembers.data
                .filter((member) =>
                  rejectedList ? rejectedList.includes(member._id) : null
                )
                .map((member) => {
                  return (
                    member.personali?.displayName || member.personali?.email
                  );
                })
                .join(", ")
          )
        }
        placeholder="Select"
        value={groupDisplayValues.join(", ")}
        selectedIndex={multiSelectedIndex}
        onSelect={setMultiSelectedIndex}
        {...props}
      >
        <Inpuut
          title="Foo"
          doneText="Save"
          value={foo}
          onChangeText={setFoo}
          onSavePress={() => {}}
          isUrl={false}
        />
        {
          //* Map group names first...
          Object.keys(groupedData).map((groupName, index) => (
            <Kitten.SelectGroup key={index} title={groupName}>
              {
                //* ... then in each group, map members' name into a list
                groupedData[groupName]
                  .map((user, index) => {
                    return (
                      //* Normal SelectItem for all members
                      <Kitten.SelectItem
                        key={index}
                        title={user?.displayName || "Unnamed • " + user?.email}
                        accessoryRight={() => (
                          <Avt
                            size="small"
                            name={user?.displayName}
                            source={{ uri: "user?.photoURL" }}
                          />
                        )}
                      />
                    );
                  })
                  .slice(0, 6)
              }
            </Kitten.SelectGroup>
          ))
        }
      </Kitten.Select>
    </>
  );
}

interface dMembersMultiSelect extends SelectProps {
  /**
   * List of rejected members' ids which won't show up in the list
   */
  rejectedList?: UserSchema["_id"][];
  selectedEmailsCallback?: (emails: string[]) => void;
  selectedIdsCallback?: (ids: string[]) => void;
}
interface dReducedData {
  _id: string;
  displayName: string;
  email: string;
}
interface dGroupedData {
  [groupName: string]: dReducedData[];
}
