import * as React from "react";
import { StyleProp, View, ViewProps, ViewStyle } from "react-native";
import { RushBar } from "./rush-bar";

/**
 * ### A Progress Indicator for this project
 *  - Available shape: <Rush.Bar>, 🔜 <Rush.Ring>
 *  ----
 *  @example
 *  <Rush.Bar progress={.7}>
 *  ----
 *  @version 21.03.15
 *  -  *Build Rush.Bar*
 *  @author  K
 *
 **/
export const Rush: dRushComponent = RushBar;

Rush.Bar = RushBar;

Rush._config = {
  Loading: {
    style: { width: "90%", height: 5 },
    indeterminate: true,
  },
};

interface dRushComponent extends React.FC<dRushProps> {
  /**
   * ### A Progress Indicator, in bar shape
   **/
  Bar?: React.FC<dRushProps>;
  _config?: { Loading: dRushProps };
}

export interface dRushProps extends React.ComponentPropsWithRef<typeof View> {
  /**
   * Progress value (between 0 and 1).
   */
  progress?: number;
  /**
   * Color of the <Rush>. The background color will be calculated based on this but you can change it by passing `backgroundColor` to `style` prop.
   */
  color?: string;
  /**
   * If the <Rush> will show indeterminate progress.
   */
  indeterminate?: boolean;
  /**
   * Whether to show the <Rush> (true, the default) or hide it (false).
   */
  visible?: boolean;
  style?: StyleProp<ViewStyle>;
}
