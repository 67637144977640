import { Buttoon, Kitten, sstyled, Toasty, Txt } from "components/atoms";
import { RushBar } from "components/atoms/generals/rush/rush-bar";
import { BoxMediaPicker } from "components/atoms/others/BoxMediaPicker/BoxMediaPicker";
import { dPosstCreatorMedia } from "components/molecules/posst-creator/PosstCreatorMedia";
import {
  dUseDocumentReturns,
  fn,
  ItemType,
  MediaPageItemSchema,
  ServiceType,
  typeOfItem,
  useAppContext,
  __ResourceItemData
} from "engines";
import { usePageContentsCollection } from "engines/firebase/handler/page-contents-handler";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import React from "react";
import { TextInput, View } from "react-native";
import WebView from "react-native-webview";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";

/**
 * ###  Media Item Creator, with option to upload Local files, Youtube, and Vimeo
 *  - For local file, u can drag&drop, select, copy&paste
 *  - For Youtube and Vimeo, u can copy the link
 *  ----
 *  @version 21.09.28
 *  -  *Brief changelog*
 *  @nguyenkhooi
 *
 **/
export function MediaItemCreator({
  cpid,
  onCloseCallback = () => { },
  pageContentDocument,
}: P) {
  const { C, teamId } = useAppContext();
  let editorMode: "CREATE" | "UPDATE" =
    pageContentDocument && pageContentDocument?.data ? "UPDATE" : "CREATE";

  //#region [FRBS] page contents handler
  const pageContentsHandler = usePageContentsCollection(cpid);
  let pageContentD = pageContentDocument?.data;
  //#endregion
  const Navigation = useNavigator();

  const [localMedia, setLocalMedia] =
    React.useState<dPosstCreatorMedia["initialMedia"]>(null);
  const [enableTracking, setEnableTracking] = React.useState<boolean>(pageContentD?.trackable);
  const [urlFrom3rdParty, setUrlFrom3rdParty] = React.useState<string>("");
  const [youtubeId, setYoutubeId] = React.useState<string>("");
  const [caption, setCaption] = React.useState<string>("");
  const [uploadPercentage, setUploadPercentage] = React.useState<number>(0);

  // console.log(
  //   "localUri ",
  //   localMedia?.uri ? "exists but is SUPER long so chill" : "does not exist"
  // );
  // console.log("urlFrom3rdParty ", urlFrom3rdParty);
  // console.log("caption ", caption);

  //#region [section] handle input enabler
  function disableSubmission() {
    if (editorMode === "UPDATE") {
      return !(caption !== pageContentD?.title || enableTracking !== pageContentD?.trackable || localMedia?.uri || vimeoUrl || fn.js.matchYoutubeUrl(youtubeUrl))
    }

    if (selectedIndex == 0) {
      return !((localMedia && (localMedia?.uri || localMedia?.file) && caption) || (enableTracking !== pageContentD?.trackable && localMedia?.uri));
    }
    if (selectedIndex == 1) {
      let isUrlValid = fn.js.matchYoutubeUrl(youtubeUrl);
      return !(isUrlValid && caption);
    }
    if (selectedIndex == 2) {
      return !(vimeoUrl && caption);
    }
  }
  //#endregion

  //#region [functions]
  async function onSubmit(xong) {
    // alert(JSON.stringify(localMedia));
    const type = localMedia?.mimeType
      ? localMedia.mimeType
      : fn.media.extractMediaType(localMedia?.url) || localMedia?.type;
    if (
      localMedia &&
      (localMedia?.uri?.length > 0 || localMedia?.file) &&
      localMedia?.uri !== pageContentD?.media
    ) {
      fn.media.uploadMedia(
        localMedia?.file ? localMedia?.file : localMedia?.uri,
        {
          title: `${caption.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').slice(0, 15)}`,
          // folder: "pages/" + cpid,
          folder: `${CLOUDINARY_FOLDER.CLIENTS}/${teamId}`,
          type,
        },
        async (percentage) => {
          // Toasty.show(`${percentage}% uploaded...`);
          setUploadPercentage(percentage);
        },
        async (data) => {
          // console.log(`upload COMPLETE: ${JSON.stringify(data)}`)
          switch (editorMode) {
            case "CREATE":
              pageContentsHandler.add({
                ...__ResourceItemData(),
                media: data?.url,
                position: pageContentsHandler._largestPositionIndex() + 1,
                title: caption,
                _teamId: teamId,
                trackable: enableTracking,
                _parentPageId: cpid
              });
              setTimeout(() => {
                Toasty.show(tr("Media item added!"));
                xong && xong();
                onCloseCallback && onCloseCallback();
              }, 690);
              break;
            case "UPDATE":
              pageContentDocument.update({
                media: data?.url,
                title: caption?.length > 0 ? caption : pageContentD?.title,
                _teamId: teamId,
                _parentPageId: cpid,
                trackable: enableTracking,
              });
              Toasty.show(tr("Update complete. Refresh the page if needed."));
              xong && xong();
              break;
            default:
              xong && xong();
              break;
          }
        }
      );
    } else {
      switch (editorMode) {
        case "CREATE":
          pageContentsHandler.add({
            ...__ResourceItemData(),
            media: urlFrom3rdParty,
            _teamId: teamId,
            _parentPageId: cpid,
            position: pageContentsHandler._largestPositionIndex() + 1,
            title: caption,
          });
          setTimeout(() => {
            Toasty.show(tr("Media item added!"));
            xong && xong();
            onCloseCallback && onCloseCallback();
          }, 690);
          break;
        case "UPDATE":
          pageContentDocument.update({
            media:
              urlFrom3rdParty?.length > 0
                ? urlFrom3rdParty
                : pageContentD?.media,
            _parentPageId: cpid,
            title: caption?.length > 0 ? caption : pageContentD?.title,
            _teamId: teamId,
            trackable: enableTracking
          });
          Toasty.show("Update complete. Refresh to see change if needed.");
          xong && xong();
          onCloseCallback && onCloseCallback();
        default:
          xong && xong();
          break;
      }
    }
  }

  //#endregion

  //#region [section]
  const [youtubeUrl, setYoutubeUrl] = React.useState<string>("");
  const [vimeoUrl, setVimeoUrl] = React.useState<string>("");
  const [isYoutubeUrlValid, setIsYoutubeUrlValid] =
    React.useState<boolean>(false);
  React.useEffect(
    function setupValueUri() {
      if (selectedIndex == 1) {
        let ytId = fn.js.matchYoutubeUrl(youtubeUrl);

        if (ytId == false) {
          setIsYoutubeUrlValid(ytId);
          setUrlFrom3rdParty("");
        } else {
          setIsYoutubeUrlValid(true);
          setYoutubeId(ytId);
          let youtubeLink = "https://youtu.be/" + ytId;
          youtubeUrl && setUrlFrom3rdParty(youtubeLink);
        }
      }
      if (selectedIndex == 2) {
        let isUrlValid = fn.js.matchVimeoURL(vimeoUrl);
        isUrlValid ? setUrlFrom3rdParty(vimeoUrl) : setUrlFrom3rdParty("");
      }
    },
    [youtubeUrl, vimeoUrl]
  );
  const refInputCaption = React.useRef<TextInput>();
  const refInputYoutube = React.useRef<TextInput>();
  const refInputVimeo = React.useRef<TextInput>();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  //#endregion

  React.useEffect(function setupInitialData() {
    if (editorMode === "UPDATE") {
      setCaption(pageContentD?.title);

      let itemT = typeOfItem(pageContentD);
      // alert(JSON.stringify(itemT, null, "\t"));

      itemT?.itemType === ItemType.VIDEO &&
        itemT?.serviceType === ServiceType.YOUTUBE &&
        (setSelectedIndex(1), setYoutubeUrl(pageContentD?.media)); //* youtube radiobox
      itemT?.itemType === ItemType.VIDEO &&
        itemT?.serviceType === ServiceType.VIMEO &&
        (setSelectedIndex(2), setVimeoUrl(pageContentD?.media)); //* vimeo radiobox

      const initialMedia: dPosstCreatorMedia["initialMedia"] = {
        uri: pageContentD?.media,
        type:
          typeOfItem(pageContentD)?.itemType === ItemType.VIDEO
            ? "video"
            : "image",
      };
      setLocalMedia(initialMedia);
    }
  }, []);

  return (
    <View style={{ width: "100%" }}>
      {editorMode === "CREATE" ? (
        <Kitten.RadioGroup
          style={{ flexDirection: "row" }}
          selectedIndex={selectedIndex}
          onChange={(index) => {
            setSelectedIndex(index);
            //* reset the uri
            setLocalMedia(null);
            setUrlFrom3rdParty("");
            setYoutubeUrl("");
            setVimeoUrl("");
            index == 1 &&
              setTimeout(() => {
                refInputYoutube.current?.focus();
              }, 600);
            index == 2 &&
              setTimeout(() => {
                refInputVimeo.current?.focus();
              }, 600);
          }}
        >
          <Kitten.Radio>{tr("Local file")}</Kitten.Radio>
          <Kitten.Radio>{tr("Youtube")}</Kitten.Radio>
          <Kitten.Radio>{tr("Vimeo")}</Kitten.Radio>
        </Kitten.RadioGroup>
      ) : null}
      {
        //#region [section] local file input component
        selectedIndex == 0 ? (
          <View style={{ flex: 1 }}>
            <Txt.Helper marginV>
              {mediaTypeOptions[selectedIndex].description}
            </Txt.Helper>
            <BoxMediaPicker
              key={1}
              onSelect={(media) => {
                // console.log("media1 ", media);
                setLocalMedia(media);
              }}
              initialMedia={localMedia}
            />
            {/* <PageCreatorMedia
              onSelect={(media) => {
                // console.log("media1 ", media);
                setLocalUri(media?.uri ? media?.uri : media[0]?.url);
              }}
              onSetValueUrl={(url: string) => {
                setValueUrl(url);
              }}
            /> */}
          </View>
        ) : null
        //#endregion
      }
      {
        //#region [section2] youtube id input component
        selectedIndex == 1 ? (
          <View>
            <Txt.Helper marginV>
              {mediaTypeOptions[selectedIndex].description}
            </Txt.Helper>
            <Kitten.Input
              ref={refInputYoutube}
              placeholder={"e.g. https://www.youtube.com/watch?v=Zzn9-ATB9aU"}
              value={youtubeUrl}
              onChangeText={setYoutubeUrl}
              onSubmitEditing={() => {
                refInputCaption.current?.focus();
              }}
            />
            {isYoutubeUrlValid ? (
              <View style={{ marginVertical: spacing(3) }}>
                <A.CtnrWebView
                  allowsFullscreenVideo
                  scrollEnabled={false}
                  source={{
                    uri: `https://www.youtube.com/embed/${youtubeId}?rel=0&autoplay=1&showinfo=0&controls=0`,
                  }}
                />
              </View>
            ) : null}
            {youtubeUrl && !isYoutubeUrlValid ? (
              <Txt.Helper>{tr("Please provide valid youtube url")}</Txt.Helper>
            ) : null}
          </View>
        ) : null
        //#endregion
      }
      {
        //#region [section] vimeo input component
        selectedIndex == 2 ? (
          <View>
            <Txt.Helper marginV>
              {mediaTypeOptions[selectedIndex].description}
            </Txt.Helper>
            <Kitten.Input
              ref={refInputVimeo}
              placeholder={"e.g. https://vimeo.com/303643768/292f84a3fa"}
              value={vimeoUrl}
              onChangeText={setVimeoUrl}
            />
            {urlFrom3rdParty ? (
              <View style={{ marginVertical: spacing(3) }}>
                <Kitten.Card disabled>
                  <Txt.Helper>
                    {tr("Vimeo preview is not supported at the moment")}
                  </Txt.Helper>
                </Kitten.Card>
                {/* <VimeoVideolly
                  item={editorMode === "UPDATE" && pageContentD}
                  shareLink={urlFrom3rdParty}
                /> */}
                {/* <A.CtnrWebView
                  //   onError={onError}
                  allowsFullscreenVideo
                  scrollEnabled={false}
                  automaticallyAdjustContentInsets
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  scalesPageToFit
                  source={{
                    uri: `https://player.vimeo.com/video/${
                      vimeoUrl?.replace("https://vimeo.com/", "")?.split("/")[0]
                    }`,
                  }}
                /> */}
              </View>
            ) : null}
          </View>
        ) : null
        //#endregion
      }
      <A.InputCaption
        ref={refInputCaption}
        placeholder={tr("Media Title")}
        onChangeText={setCaption}
        value={caption}
        style={{ marginBottom: spacing(3) }}
      />
      {selectedIndex === 0 ? <Kitten.ViewH style={{ width: "100%", justifyContent: "space-between", marginBottom: spacing(3) }}>
        <Txt.S1>Enable tracking</Txt.S1>
        <Kitten.Toggle checked={enableTracking} onChange={checked => {
          setEnableTracking(checked)
        }} />
      </Kitten.ViewH> : null}
      {/* <Txt.C1>{`${uploadPercentage}%`}</Txt.C1> */}
      {uploadPercentage > 0 ? (
        <>
          <Txt.H5
            style={{
              color: C.primary,
              alignSelf: "center",
              marginTop: spacing(3),
            }}
          >{`${uploadPercentage}%`}</Txt.H5>
          <RushBar
            progress={uploadPercentage / 100}
            style={{ marginVertical: spacing(3) }}
            color={C.primary}
          />
        </>
      ) : (
        <Buttoon
          progress
          disabled={disableSubmission()}
          onPress={(xong) => {
            onSubmit(xong);
          }}
        >
          {editorMode === "CREATE" ? tr(`Add`) : tr("Update")}
        </Buttoon>
      )}

      <Buttoon
        appearance={"ghost"}
        onPress={() => {
          Navigation.goBack();
        }}
      >
        {tr("Cancel")}
      </Buttoon>
    </View>
  );
}

export const mediaTypeOptions = [
  {
    name: tr("Local file"),
    description: "Note: You can also upload audio files here.",
  },
  {
    name: "Youtube",
    description: "Paste a Youtube link. A preview will be shown below with a correct url.",
  },
  {
    name: "Vimeo",
    description: "Paste a Vimeo link. A preview will be shown below with a correct url.",
  },
];

interface P {
  /**
   * the doc id of the current custom page
   */
  cpid: string;
  onCloseCallback(): void;
  pageContentDocument?: dUseDocumentReturns<MediaPageItemSchema>;
}

const A = {
  CtnrWebView: sstyled(WebView)({
    width: "100%",
    height: [250, 300, 350, 420],
    backgroundColor: "black",
    borderRadius: 10,
    overflow: "hidden",
  }),
  InputCaption: sstyled(Kitten.Input)({
    marginTop: 3,
    marginBottom: 3,
  }),
};
