import { Kitten, Txt } from "components";
import { Avt, sstyled } from "components/atoms";
import { useBreakpointIndex } from "dripsy";
import { ConfigVariablesSchema, useAppContext } from "engines";
import { useConfigCollection, useConfigDocument } from "engines/firebase/handler/config-handler";
import * as React from "react";
import { ActivityIndicator, FlatList, Pressable } from "react-native";
import { scale, tr } from "utils";

/**
 * ### List of hierarchy to choose from
 * -    Use in Hierarchy Switch func for Developers
 * @returns
 */
export function HierarchyList(props: {
  onHierarchySelect(item: ConfigVariablesSchema): void;
  /** @deprecated */
  hierarchiesToBeExcluded?: ConfigVariablesSchema["_teamId"][];
  /** only use this for devs */
  showAllTeams?: boolean;
}) {
  const { onHierarchySelect, hierarchiesToBeExcluded, showAllTeams = false } =
    props;
  const { teamId } = useAppContext();
  const ourConfigHandler = useConfigDocument(`variables-${teamId}`)
  const ourConfig = ourConfigHandler.data as ConfigVariablesSchema
  const { data: _allConfigs, ...configsHandler } = showAllTeams ? useConfigCollection() : useConfigCollection({ where: [["multiverseDisabled", "!=", true]] });

  //#region [section] search ops
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [configs, setConfigs] = React.useState<ConfigVariablesSchema[]>([]);

  // search filter
  const filteredConfigs = configs?.filter(c => searchValue === "" || c.appTitle?.toLowerCase()?.includes(searchValue))

  React.useEffect(function updateConfigs() {
    if (!ourConfig || !_allConfigs) return;
    if (_allConfigs)
      setConfigs(showAllTeams ? _allConfigs : _allConfigs?.filter(c => c?._company === ourConfig?._company))

    // console.log("_allConfigs: " + JSON.stringify(_allConfigs))
    // console.log("configs: " + JSON.stringify(configs))
    // console.log("filteredConfigs: " + JSON.stringify(filteredConfigs))
  }, [_allConfigs, ourConfig]);

  const breakpoint = useBreakpointIndex();

  try {
    if (!filteredConfigs) return <ActivityIndicator />;
    if (configsHandler?.error) return <Txt.Indicator>Error</Txt.Indicator>;
    return (
      <Kitten.Card style={{ width: "100%" }}>
        <Kitten.Input
          autoFocus
          value={searchValue}
          placeholder={tr("Search hierarchy")}
          onChangeText={setSearchValue}
        />
        <Kitten.Divider_________ />
        <FlatList
          key={breakpoint <= 2 ? "single-list" : "double-list"}
          data={filteredConfigs}
          numColumns={breakpoint <= 2 ? 1 : 3}
          keyboardShouldPersistTaps={"handled"}
          renderItem={({ item }) => (
            <Pressable>
              {({ hovered }) => (
                <A.ItemHierarchy
                  title={item?.appTitle}
                  hovered={hovered}
                  accessoryLeft={() => (
                    <Avt
                      name={item?.appTitle}
                      source={{
                        uri: `https://get-my.app/t/${item?.appTitle
                          ?.toLowerCase()
                          .replace(/ /g, "")}/logo.png`,
                      }}
                    />
                  )}
                  onPress={() => onHierarchySelect && onHierarchySelect(item)}
                />
              )}
            </Pressable>
          )}
        />
      </Kitten.Card>
    );
  } catch (error) {
    throw Error("::SGllcmFyY2h5TGlzdA==::" + error);
  }
}

const A = {
  ItemHierarchy: sstyled(Kitten.ListItemv2)(({ hovered }) => ({
    width: scale(400),
    borderRadius: scale(4),
    borderWidth: 1,
    borderColor: hovered ? "primary" : "transparent",
  })),
};
