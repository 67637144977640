import { Avt, Buttoon, Kitten, Poppy, Toasty, Txt } from "components/";
import {
  dComment,
  fn,
  useAppContext,
  useNotificationDocument,
  useUserProfile,
} from "engines";
import firebase from "firebase";
// import R from "ramda";
import React, { memo } from "react";
import { Linking, View } from "react-native";
import { moderateScale } from "react-native-size-matters";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";

interface PropTypes {
  navigation: any;
}

const avatarDimension = moderateScale(24);

export function NotificationComments(props: P) {
  const Navigation = useNavigator();

  // console.log("notification details ", props.notification);

  const [notification, setNotification] = React.useState<any>();

  React.useEffect(function getNotificationComments() {
    setNotification(props?.notification && props?.notification);
  }, []);
  return (
    <View style={{ flex: 1 }}>
      {/* <KeyboardAwareScrollView
        ref={(ref) => (this.scrollRef = ref)}
        keyboardShouldPersistTaps="always"
        style={Styles.container}
        extraScrollHeight={83}
      > */}
      <Kitten.Card>
        <$_NotificationDetails notification={notification} />
      </Kitten.Card>
      <Kitten.Divider_________ />
      <$_Comments notification={notification} />
      {/* </KeyboardAwareScrollView> */}
    </View>
  );
}

function $_NotificationDetails(props) {
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  const { C, teamId } = useAppContext();

  const { notification } = props;
  const handleNotification = useNotificationDocument(
    notification?.id,
    `${teamId}`
  );

  if (!notification) return null;
  const title =
    notification.title?.length > 0 ? notification.title : notification.message;
  const message =
    notification?.message.length > 0 && title !== notification.message
      ? notification.message
      : null;

  function deletePress() {
    Poppy(
      "Are you sure you want to delete this notification from the app?",
      ``,
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () => {
          handleNotification.deleteDocument();
          Toasty.show("Notification deleted.", { type: "success" });
        },
      }
    );
  }

  let link = fn.js.extractLinkFromBody(title);
  if (!link) link = fn.js.extractLinkFromBody(message);

  return (
    <View style={{ padding: spacing(1), paddingTop: spacing(2) }}>
      {/* {userProfile._isUserAdmin() === true ? (
        <IconPrimr
          containerStyle={{ alignSelf: "flex-end" }}
          color={C.errorRed}
          name="trash"
          size={moderateScale(17)}
          onPress={deletePress}
        />
      ) : null} */}
      <Txt.H5>{title}</Txt.H5>
      {message ? <Txt.P1 category="p2">{message}</Txt.P1> : null}
      {link ? (
        <Buttoon
          onPress={() => Linking.openURL(link)}
          icon={{ name: "link" }}
          appearance="ghost"
        >
          Open Link
        </Buttoon>
      ) : null}
    </View>
  );
}

function $_Comments(props) {
  const { notification } = props;
  const { C, teamId } = useAppContext();

  if (!notification) return null;
  const handleComments = useNotificationDocument(notification?.id, `${teamId}`);

  const userProfile = useUserProfile();

  // const comments: dComment[] = notification.comments;
  const [comments, setComments] = React.useState<dComment[]>(
    notification?.comments
  );

  const [_newComment, setNewComment] = React.useState("");

  async function submitComment() {
    const comment: dComment = {
      uid: userProfile.data?._id,
      timestamp: new Date(),
      message: _newComment,
      name: userProfile.data?.personali?.displayName,
      profileImage: userProfile.data?.personali?.photoURL,
    };

    handleComments.update({
      comments: firebase.firestore.FieldValue.arrayUnion(comment),
    });

    setComments([...comments, comment]);

    // setComments(comments, ..._newComment);
    setNewComment("");
    Toasty.show("Comment posted!", { type: "success" });
  }

  function commentPress(comment: dComment) {
    if (
      comment.uid === userProfile?.data?._id ||
      userProfile?._isUserAdmin() === true
    ) {
      Poppy(
        "Would you like to delete",
        `${comment.uid === userProfile?.data?._id ? "your" : "this"} comment?`,
        {
          text: "Cancel",
          onPress: () => {},
        },
        {
          text: "Yes",
          onPress: () => {
            handleComments.update({
              comments: firebase.firestore.FieldValue.arrayRemove(comment),
            });

            setComments(
              comments.filter(
                (item) =>
                  item.message !== comment.message && item.uid == comment.uid
              )
            );
            Toasty.show("Comment removed!", { type: "success" });
          },
        }
      );
    }
  }

  return (
    <View style={{ marginTop: spacing(3) }}>
      <Txt.$Title
        style={{ marginHorizontal: spacing(1), marginBottom: spacing(2) }}
      >
        {"Comments"}
      </Txt.$Title>

      {comments.length == 0 && <Txt.Helper>{tr("No comments")}</Txt.Helper>}

      {comments?.map((comment) => (
        <CommentItem comment={comment} onPress={() => commentPress(comment)} />
      ))}
      <Kitten.Input
        autoFocus={true}
        style={{
          marginTop: spacing(3),
        }}
        textStyle={{
          minHeight: moderateScale(65),
        }}
        multiline={true}
        maxLength={200}
        placeholder="Type something..."
        status="ghost"
        value={_newComment}
        onChangeText={(value) => setNewComment(value)}
      />

      <Buttoon
        onPress={() => {
          submitComment();
        }}
        disabled={_newComment.length < 1}
        appearance="ghost"
        style={{
          alignSelf: "flex-end",
          marginTop: -10,
        }}
      >
        Send
      </Buttoon>
    </View>
  );
}

const CommentItem = memo(
  (props: { onPress: () => void; comment: dComment }) => {
    const { onPress, comment } = props;

    return (
      <Kitten.ListItem
        onPress={onPress}
        style={{
          paddingHorizontal: spacing(1),
        }}
        title={comment.name}
        description={comment.message}
        accessoryLeft={(_props) => (
          <View
            style={{
              width: "9%",
              alignItems: "center",
            }}
          >
            <Avt
              name={comment.name || ""}
              source={{ uri: comment.profileImage || "" }}
              size="small"
              // shape="square"
              style={{
                width: avatarDimension,
                height: avatarDimension,
              }}
            />
          </View>
        )}
      />
    );
  }
  // (prevProps, nextProps) => !R.equals(prevProps, nextProps)
);

const Styles = {
  container: {
    flex: 1,
    // backgroundColor: C.background,
  },
};

interface P {
  notification: object;
}
