import { auth } from "firebase";
import { AUTH_RESULT } from "./auth/AuthHandler";

export interface checkEmailandPassRES {
  code: `PW_SHORT` | `PW_GOOD` | `EMPW_GOOD`;
}

export interface c2EmailRES {
  code: AUTH_RESULT; //This includes all test cases for the auth result

  uid?: string;
}

export enum GOOGLE_AUTH {
  SUCCESS,
  FAILED,
  U_NO_ROLE_YET,
  FRBS_SETUP_NEEDED,
}

export interface kqGAuth {
  type: GOOGLE_AUTH;
  payload: {
    frbsUser: auth.UserCredential;
    roles: string[]; //* firebase.auth().IdTokenResult.claims["roles"]
  };
}

/**
 * Add all the enums for callable functions here.
 */
export enum CALLABLE_FUNCTIONS {
  ADDNEWBIEROLE = "addNewbieRole",
}
