import React from "react"
import { Avt, Buttoon, Inpuut, ListHeader, Poppy, Toasty } from "components";
import { ACTION_TYPE, dAttendance, dCalendarEvent, dGuest, recordUnknownUserAction, useAppContext } from "engines";
import { ScrollView } from "react-native-gesture-handler";
import { spacing } from "utils";
import { updateAttendnace, useUserAttendance } from "engines/firebase/handler/attendance-handler";
import { ListItemv2 } from "components/atoms/externals/ui-kitten/list-item-v2";
import { IconPrimr } from "assets";
import { useNavigator } from "screens/_navigation";

export function GuestEntryForm(p: { attendance: dAttendance, event: dCalendarEvent, onGuestsSaved: (_guests: dGuest[]) => void }) {
    const { attendance, event, onGuestsSaved } = p;
    const Navigation = useNavigator()
    const { C, frbsUser } = useAppContext()

    // const meetingId = attendance.meetingId

    // const userAttendanceHandler = useUserAttendance(frbsUser?.uid)

    const [inputValue, setInputValue] = React.useState<string>("");
    const [guests, setGuests] = React.useState<dGuest[]>(attendance?.guests || []);

    async function saveChanges(_guests: dGuest[]) {
        Toasty.show("Saving guests...", { type: "loading" })
        await updateAttendnace(frbsUser?.uid, { ...attendance, guests: _guests })
        if (_guests.length > 0)
            recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.ADDED_GUESTS, `${frbsUser?.uid} brought ${_guests.length} guests to \"${event.title}\".`, { location: event.eventId })
        // await userAttendanceHandler.updateAttendnace(frbsUser?.uid, { ...attendance, guests: _guests })
        onGuestsSaved && onGuestsSaved(_guests)
    }
    async function saveAndClose(_guests: dGuest[]) {
        await saveChanges(_guests)
        Toasty.show(`Saved ${_guests.length} guests`)
        Navigation.goBack()
    }

    return <ScrollView style={{ flex: 1 }}>

        <ListHeader titleTx={`${event?.title || "Live Meeting"}`} />

        <Inpuut style={{ marginBottom: spacing(1) }} autoFocus={true} value={inputValue} onChangeText={setInputValue} placeholder="Enter Guest Name" doneText="Add Guest" onSavePress={() => {
            const _guests = [{ name: inputValue }].concat(guests)
            setGuests(_guests)
            setInputValue("")
            saveChanges(_guests)
        }} />

        {guests.map(g => <ListItemv2 title={g.name} accessoryLeft={() => <Avt name={g.name} source={{ uri: "" }} />} accessoryRight={() => <IconPrimr name="x" color={C.errorRed} onPress={() => {
            Poppy(`Remove ${g.name}?`, "", { text: "Cancel" }, {
                text: "Remove", onPress: async () => {
                    const _guests = guests.filter(_g => _g.name !== g.name)
                    setGuests(_guests)
                    try {
                        await saveChanges(_guests)
                        Toasty.show(`Removed ${g.name}`, { type: "normal" })
                    } catch (err) {
                        console.error(err)
                        Poppy("Failed to remove", err)
                    }
                }
            })
        }} />} />)}

        <Buttoon icon={{ name: "people" }} status="info" style={{ marginTop: spacing(3) }} size="small" onPress={async () => {
            try {
                let _guests = guests;
                if (inputValue?.length > 0) {
                    Poppy("Unfinished Guest Entry", `Do you want to add ${inputValue} to the list of your guests?`, {
                        text: "No", onPress: () => {
                            saveAndClose(_guests)
                        }
                    }, {
                        text: "Yes", onPress: () => {
                            _guests = [{ name: inputValue }].concat(guests)
                            setGuests(_guests)
                            setInputValue("")
                            saveAndClose(_guests)
                        }
                    })
                } else {
                    saveAndClose(_guests)
                }
            } catch (err) {
                Poppy("Failed to save changes", err)
            }
        }}>{`Save ${guests.length} guests`}</Buttoon>
    </ScrollView>
}