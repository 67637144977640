import { KeyboardAccessoryView } from "react-native-keyboard-accessory";
//#region [import]
import {
  Avt,
  Buttoon,
  Inpuut,
  Kitten,
  Poppy,
  Rush,
  sstyled,
  Toasty,
  Txt,
} from "components/atoms";
import {
  ACTION_TYPE,
  CMT_STATUS,
  CommentSchema,
  dComment,
  fn,
  FRBS_ROLE,
  PosstSchema,
  recordUserAction,
  useAppContext,
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useCommentsCollection } from "engines/firebase/handler/posst-comments-handler";
import { usePosstDocument } from "engines/firebase/handler/posst-handler";
// import { usePosstDocument } from "engines/backends/__quick-handler/QuickHandler";
import React from "react";
import {
  FlatList,
  FlatListProps,
  Keyboard,
  useWindowDimensions,
  View
} from "react-native";
import { GiftedChatProps } from "react-native-gifted-chat";
import { dColors, IS_ANDROID, spacing, tr } from "utils";
import { CommentInput } from "./CommentInput";
import { getThumbnailFromURL } from "engines/functions/media-functions";

//#endregion

/**
 * ### CommentsCenter for posst
 *  - Show Comments as FlatList
 *  - U'll be able to write a comment, delete a comment
 *  ----
 *  @version 21.06.01
 *  -  *Remove GiftedChat*
 *  @author  K
 *
 **/
export function CommentsCenter({ pid }: { pid: PosstSchema["_pid"] }) {
  const { C } = useAppContext();
  const { height } = useWindowDimensions();

  const refCommentList = React.useRef<FlatList>();

  //#region [FRBS]
  const userProfile = useUserProfile();
  const posstHandler = usePosstDocument(pid);
  const commentsHandler = useCommentsCollection(pid, {
    orderBy: ["createdAt", "asc"],
    parseDates: ["createdAt"],
    ignoreFirestoreDocumentSnapshotField: false,
  });
  //#endregion

  React.useEffect(function CommentListLayout() {
    setTimeout(() => {
      refCommentList.current?.scrollToEnd();
    }, 1000);
  }, []);

  const [comments, setComments] = React.useState<CommentSchema[]>();

  React.useEffect(function updateComments() {
    setComments(commentsHandler.data)
  }, [commentsHandler.data]);

  const [message, setMessage] = React.useState<string>("");
  const onSend = () => {
    const totalComments = commentsHandler.data?.length + 1;
    const newComment = {
      _pid: pid,
      createdAt: new Date(),
      user: {
        _id: userProfile.data?._id,
        name: userProfile.data?.personali?.displayName,
        avatar: userProfile.data?.personali?.photoURL,
      },
      text: message,
      status: CMT_STATUS.POSTED,

    } as CommentSchema
    setComments((comments || []).concat(newComment))
    commentsHandler.add(newComment);
    posstHandler.update({ commentCount: totalComments });

    // record the action
    const maxPosstCharacters = 33;
    const maxCommentCharacters = 50
    const condensedPosstTitle = posstHandler.data?.body?.length > maxPosstCharacters ?
      posstHandler.data?.body?.substring(0, maxPosstCharacters - 3) + "..." :
      posstHandler.data?.body;
    const condensedComment = message.length > maxCommentCharacters ?
      message?.substring(0, maxCommentCharacters - 3) + "..." :
      message;
    recordUserAction(userProfile.data, ACTION_TYPE.POSST_COMMENT, `${userProfile.data?.personali?.displayName} commented on \"${condensedPosstTitle}\" saying \"${condensedComment}\"`, { location: posstHandler.data?._pid, involvedPartyName: posstHandler.data?.author?.name, thumbnail: posstHandler.data?.medias?.length > 0 ? getThumbnailFromURL(posstHandler.data?.medias[0]?.uri) : null })

    setMessage("");
    setTimeout(() => {
      refCommentList.current?.scrollToEnd();
    }, 300);
    Keyboard.dismiss();
  };

  if (posstHandler.error || commentsHandler.error)
    return <Txt>{tr("Error")}</Txt>;
  if (!posstHandler.data || !commentsHandler.data)
    return <Rush.Bar indeterminate style={{ width: "100%" }} />;

  return (
    <>
      <A.Ctnr disabled>
        <Txt.H6 style={{ textAlign: "center" }}>
          {posstHandler.data?.body
            ?.replace(/\\n/g, "")
            ?.replace(fn.js.extractLinkFromBody(posstHandler.data?.body), "")
            ?.slice(0, 100) + "..."}
        </Txt.H6>
        <Txt style={{ textAlign: "center" }}>
          {"by " + posstHandler.data?.author?.name}
        </Txt>
        {IS_ANDROID &&
          userProfile.data?.personali?.email?.startsWith("demo@") && (
            <Txt style={{ textAlign: "center", fontWeight: "bold" }}>
              To report a comment, just tap on it!
            </Txt>
          )}

        <A.CommentList
          ref={refCommentList}
          height={height}
          data={comments}
          ListEmptyComponent={() => (
            <Txt.Indicator marginV>
              {commentsHandler.loading ? tr("Loading...") : tr("No comment")}
            </Txt.Indicator>
          )}
          renderItem={({ item, index }) => {
            return (
              <A.Bubbly
                onPress={() => {
                  Poppy(
                    "Report Comment", //TODO should be save function
                    "Report this comment?",
                    {
                      text: "Cancel",
                      onPress: () => { },
                    },
                    {
                      text: "Yes",
                      onPress: () => {
                        if (userProfile._isRolesContain([FRBS_ROLE.ADMIN]))
                          commentsHandler.deleteDocument(item.__snapshot?.id);
                        Toasty.show("Comment reported.", { type: "normal" });
                      },
                    }
                  );
                }}
                style={{
                  borderRadius: 11,
                  alignSelf:
                    item.user?._id == userProfile.data?._id
                      ? "flex-end"
                      : "flex-start",
                  // marginLeft: item.user?._id == userProfile.data?._id ? 30 : 0,
                  // marginRight: item.user?._id == userProfile.data?._id ? 0 : 30,
                  backgroundColor:
                    item.user?._id == userProfile.data?._id
                      ? C.primaryDarker
                      : C.background,
                  borderWidth: item.user?._id == userProfile.data?._id ? 1 : 0,
                  borderColor:
                    item.user?._id == userProfile.data?._id ? C.dim : C.background,
                }}
                // disabled
                accessoryLeft={() => (
                  <Avt name={item.user?.name} source={{ uri: item.user?.avatar }} />
                )}
                accessoryRight={() =>
                  userProfile._isRolesContain([
                    FRBS_ROLE.ADMIN,
                    FRBS_ROLE.DEVELOPER,
                  ]) && (
                    <Buttoon
                      // status="primary"
                      appearance="ghost"
                      size="small"
                      icon={{ name: "dots_vertical", color: C.text }}
                      onPress={() => {
                        Poppy(
                          "Delete Comment", //TODO should be save function
                          "Are you sure you want to delete this comment?",
                          {
                            text: "Cancel",
                            onPress: () => { },
                          },
                          {
                            text: "Yes",
                            onPress: () =>
                              commentsHandler.deleteDocument(item.__snapshot?.id),
                          }
                        );
                      }}
                    />
                  )
                }
                // title={item.text}
                description={() => <Txt.P1>{item.text}</Txt.P1>}
                // " • " + item.createdAt?.toLocaleDateString()
                title={
                  () => <Txt.P1 style={{ fontWeight: "bold" }}>{`${item.user?.name}`}</Txt.P1>
                }
              />
            )
          }}
        />
      </A.Ctnr>
      <KeyboardAccessoryView
        alwaysVisible={true}
        androidAdjustResize
        style={{ borderRadius: 7, backgroundColor: "transparent", justifyContent: "flex-end" }}
        inSafeAreaView={true}
        heightProperty="minHeight"

      >
        {({ isKeyboardVisible }) => (
          <CommentInput
            placeholder={tr("Write your comment")}
            placeholderTextColor={C["text-hint-color"]}
            value={message}
            onChangeText={setMessage}
            onSavePress={onSend}
          />
        )}
      </KeyboardAccessoryView>
    </>
  );
}

const A = {
  Ctnr: sstyled(Kitten.Card)({ backgroundColor: "transparent" }),
  CommentList: sstyled<FlatListProps<CommentSchema>, { height: number }>(
    FlatList
  )((p) => ({
    height: p.height * 0.6,
  })),
  Bubbly: sstyled(Kitten.ListItemv2)({
    backgroundColor: "surface",
    marginBottom: spacing(2),
    borderRadius: 4,
  }),
  Config: (C: dColors) => ({
    CONVO: {
      renderAvatarOnTop: true,
      renderUsernameOnMessage: true,
      alwaysShowSend: true,
      showUserAvatar: true,
      alignTop: true,
      placeholder: "Comment",
      // messagesContainerStyle: { backgroundColor: C.background },
      textInputStyle: {
        paddingLeft: spacing(3),
        backgroundColor: C.background,
        color: C.text,
        borderTopWidth: 0,
      },
      containerStyle: {
        backgroundColor: C.background,
        paddingRight: spacing(3),
        borderRadius: 10,
      },
      imageStyle: { backgroundColor: C.dim },
    } as GiftedChatProps,
  }),
};
