import firebase from "firebase"
import { Poppy, Rush, Toasty, Txt } from "components/atoms";
import { CreatorActionsList } from "components/molecules";
import {
  BroadcastCreator,
  PosstCreatorCenter,
  PosstsFeed,
  ProfileAndActionsCenter,
  ShortcutsHomeGrid,
  WidgetCalendar
} from "components/organisms";
import { TwoColumns, useTwoColumns } from "components/templates";
import {
  ACTION_TYPE,
  fn,
  FRBS_ROLE,
  recordUserAction,
  useAppContext,
  useConfigDocument,
  useUserProfile
} from "engines";
import { useCollabContext } from "engines/providers/collaboration-provider";
import { getPermissionsAsync } from "expo-notifications";
import React from "react";
import { Linking, Platform } from "react-native";
import { useTourGuideController } from "rn-tourguide";
import { CalendarScreen } from "screens/calendar/CalendarScreen";
import { useNavigator } from "screens/_navigation";
import { IS_MOBILE_WEB, IS_PWA, IS_WEB, tr, version } from "utils";
import { getNormalDate } from "../../engines/firebase/handler/activity-handler";
import { SH_VersionUpdate } from "./sh-version-update";
import { TodaysAgendaSection } from "./TodaysAgendaSection";
import { ViewAvatarWall } from "./ViewAvatarWall";

export function HomeScreen(props) {
  // const { route } = props
  // const notificationData = route?.notificationData;

  const { frbsUser, teamId, setTeamId } = useAppContext();
  const { data: userD, ...userProfile } = useUserProfile();
  const { setIsCollabModeOn, isCollabModeOn } = useCollabContext();
  const configVariablesHandler = useConfigDocument(`variables-${teamId}`);
  const Navigation = useNavigator();

  React.useEffect(function isbanned() {
    if (userD && userD.roles?.includes(FRBS_ROLE.BANNED)) {
      firebase.auth().signOut()
      Poppy("Sensitive Material", 'This app has sensitive material. We are signing you out.')
    }
  }, [userD]);

  const refDidPromptToChangeTeams = React.useRef<boolean>(false);
  React.useEffect(
    function checkIfWrongTeam() {
      if (!userD || !teamId) return;
      if (
        userD?._teamId !== teamId &&
        !refDidChangeParentAppId.current &&
        !userD?.roles?.includes(FRBS_ROLE.DEVELOPER) &&
        !userD?.roles?.includes(FRBS_ROLE.BANNED) &&
        frbsUser?.uid
      ) {
        refDidPromptToChangeTeams.current = true;
        Poppy(
          "Wrong Team",
          "It looks like you're viewing a team that you do not belong to. Do you want to switch over to your correct team?",
          { text: "Cancel", onPress: () => {} },
          {
            text: "Switch",
            onPress: () => {
              // switch to correct team
              Toasty.show("Switching teams...", { type: "loading" });
              setTeamId(userD?._teamId);
              setTimeout(() => {
                Navigation.goBack();
                Navigation.goBack();
              }, 2000);
            },
          }
        );
      }
    },
    [userD, teamId]
  );

  function showDisabledAlert() {
    Navigation.navigate("DisabledApp");
    Poppy(
      "Inactive",
      "This app portal is no longer active because the owners are not maintaining it.  To start your own app contact us at apptakeoff.com",
      { text: "Ok" },
      {
        text: "Contact us",
        onPress: () => {
          Linking.openURL("mailto:francisj12@icloud.com");
        },
      },
      {
        onDismiss: () => {
          Navigation.navigate("DisabledApp");
        },
      }
    );
  }

  React.useEffect(
    function checkIfDisabled() {
      if (!userD) return;
      if (configVariablesHandler?.data?._appDisabled) {
        if (userD?.roles?.includes(FRBS_ROLE.DEVELOPER))
          Toasty.show("App disabled. Developer access granted.", {
            type: "danger",
          });
        else showDisabledAlert();
      }
    },
    [userD, configVariablesHandler.data]
  );

  //NOTE Disabling Collaboration Mode in case it is still on
  React.useEffect(() => {
    setIsCollabModeOn(false);
  }, []);

  function convertVersionStringToNumber(versionString: string): number {
    const numbers = versionString ? versionString?.split(".") : "";
    let finalNumbStr = "";
    for (let x = 0; x < numbers.length; x++) {
      let _numbStr = numbers[x];
      if (_numbStr.length < 2) _numbStr = `0${_numbStr}`;
      finalNumbStr += _numbStr;
    }
    return Number.parseInt(finalNumbStr);
  }

  React.useEffect(
    function didSignInToday() {
      if (!userD) return;
      const lastSignIn: Date = getNormalDate(userD.lastSignIn);
      const today = new Date();
      if (
        !lastSignIn ||
        lastSignIn.toISOString().split("T")[0] !==
        today.toISOString().split("T")[0]
      ) {
        // if the user is brand new, inform everyone else!
        if (fn.calendar.dateFormat(userD.createdAt?.toDate()) === fn.calendar.dateFormat(today) && !userD.lastSignIn) {
          recordUserAction(userD, ACTION_TYPE.NEW_USER, `Welcome new member ${userD?.personali?.displayName} to the team!`, { thumbnail: userD?.personali?.photoURL, involvedPartyName: userD?.personali?.displayName })
        }
        // if last sign in was before today or not yet filled out
        userProfile.update({
          lastSignIn: today,
          ...(IS_WEB ? { webVersion: version } : { mobileVersion: version }),
        });
      }
    },
    [userD]
  );

  //#region [functions] checkReleaseVersion
  /**
   * ###
   *  - This function is used in checking if the release
   *  version is the same with the mobile version which is set in the config variable
   *  ----
   *  @example
   *  Copy and paste function is the best...
   *  ----
   *  @version 22.05.03
   *  -  *Brief changelog*
   *  @author  Nl
   *
   **/
  function checkReleaseVersion() {
    const myVersion = convertVersionStringToNumber(version);
    const releaseVersion = convertVersionStringToNumber(
      configVariablesHandler?.data?.releaseVersion
    );
    console.log("[version] platform OS = " + Platform.OS);
    console.log(
      "[version] myVersion: " + myVersion + " compared to " + releaseVersion
    );
    IS_WEB ? null : mobileCheck();

    // console.log("releaseVersion ", myVersion);
    function mobileCheck() {
      // alert(`${myVersion} < ${releaseVersion}`);
      if (myVersion < releaseVersion) {
        Navigation.overlayPortal.open({
          render: (
            <>
              <SH_VersionUpdate />
            </>
          ),
          headerTitle: "Update Phone Version",
        });
      }
    }
  }
  //#endregion
  React.useEffect(function () {
    console.log("checking notis");
    checkNotifcation();
  }, []);
  React.useEffect(
    function () {
      checkReleaseVersion();
    },
    [configVariablesHandler.data, version]
  );
  const refDidChangeParentAppId = React.useRef<boolean>(false);
  React.useEffect(
    function updateParentAppIfNeeded() {
      if (!userD || !configVariablesHandler.data) return;
      /** if the user doesn't have their parentAppId set by default or they are switching to a new parent app, update it! */
      if (
        configVariablesHandler.data?._parentAppId &&
        userD?._parentAppId !== configVariablesHandler.data?._parentAppId &&
        !refDidChangeParentAppId.current
      ) {
        refDidChangeParentAppId.current = true;
        userProfile
          .update({
            _parentAppId: configVariablesHandler.data?._parentAppId,
          })
          .then(() => {
            if ("debug" === "nah")
              alert(
                "did update _parentAppId for user to " +
                configVariablesHandler.data?._parentAppId
              );
          });
      }
    },
    [userD, configVariablesHandler.data]
  );

  // update FCM tokens if needed (such as the user just now enabled PNs?)
  const refDidFetchFCM = React.useRef<string>("");
  React.useEffect(() => {
    if (refDidFetchFCM.current === teamId) return;
    // refDidFetchFCM.current = teamId;
    // setIsCollabModeOn(false);
    if (!userProfile || !userD || IS_WEB) return;
    fn.notifications.subscribeToGlobalTopic(teamId);
    fn.notifications.getFirebaseMessagingToken().then((token) => {
      refDidFetchFCM.current = teamId;
      if (!((userD.fcmTokens as string[]) || [])?.includes(token) && token) {
        userProfile._updateFCMTokens(token);
      }
    });
  }, [userProfile, userD]);
  const { breakpointForTwoColumns } = useTwoColumns();

  const refDidPromptForPWA = React.useRef<boolean>(false);
  React.useEffect(function addAsWebApp() {
    if (IS_MOBILE_WEB() && !IS_PWA() && !refDidPromptForPWA.current) { // is mobile web check
      refDidPromptForPWA.current = true
      Poppy("Add PWA", "To add this PWA (web app) to your phone, tap the share button below and select \"Add to Home Screen\"")
    }
  }, []);

  //#region [section] tourguide
  const { canStart: canStartTourguide, start: startTourguide } =
    useTourGuideController();
  const refDidStartTourGuide = React.useRef<boolean>(false);

  const today = new Date();
  React.useEffect(() => {
    // if the user is brand new (signed up today), we will show the tour guide for the "New? Start here!" button so they can't miss it
    if (!userD) return;
    if (canStartTourguide) {
      if (
        !IS_WEB &&
        (!userD.createdAt ||
          userD.createdAt?.toDate()?.toISOString()?.split("T")[0] ===
          today.toISOString().split("T")[0])
      ) {
        // get user profile picture uploaded first
        userD.personali?.photoURL &&
          userD.personali?.photoURL?.length > 0 &&
          refDidStartTourGuide.current === false &&
          setTimeout(() => {
            refDidStartTourGuide.current = true;
            startTourguide(breakpointForTwoColumns ? 1 : 2);
          }, 1750);
      }
    }
  }, [canStartTourguide, userD]);
  //#endregion

  //#region [functions] Functions is used in checking if notifcation persmission is enabled
  /**
   * ###
   *  - Detailed explanation (if any)
   *  ----
   *  @example
   *  Copy and paste function is the best...
   *  ----
   *  @version 22.05.03
   *  -  *Brief changelog*
   *  @author  Nl
   *
   **/
  async function checkNotifcation() {
    if (IS_WEB) {
      null;
    } else {
      let permissionResult = await getPermissionsAsync();
      console.log("[messaging] get result.status = " + permissionResult.status);
      if (permissionResult.status === "undetermined") {
        Navigation.navigate("notification-permission");
      }
    }
  } //#endregion

  if (userProfile.error) {
    console.warn("error in MembersFeed: ", userProfile.error);
    return <Txt.Indicator>{tr("Error getting members feed")}</Txt.Indicator>;
  }
  const refDidOpenAvatarWall = React.useRef<boolean>(false);
  React.useEffect(
    /** Require user to choose avatar before using the app */
    function avatarPicker() {
      if (refDidOpenAvatarWall.current) return;
      if (userD && frbsUser && !userD?.personali?.photoURL) {
        refDidOpenAvatarWall.current = true;
        Navigation.dialogPortal.open({
          headerTitle: "Pick your avatar to continue",
          render: <ViewAvatarWall />,
          // actions: [
          //   {
          //     label: "Skip for now",
          //     onPress: () => {
          //       Navigation.navigate("Home");
          //     },
          //   },
          // ],
        });
      }
    },
    [userProfile, frbsUser]
  );
  // const [page, setPage] = React.useState<string>("");
  // return <CollaborationMultiverse />;
  // return <BlastSmsComposer listData={[]} shareMode={true} contacts={[]} />;
  if (
    configVariablesHandler?.data?._appDisabled &&
    !userD?.roles?.includes(FRBS_ROLE.DEVELOPER)
  )
    return null;
  return frbsUser ? (
    <TwoColumns
      column1Content={(scrollHandler) => (
        <PosstsFeed
          scrollHandler={scrollHandler}
          isAdmin={userProfile._isRolesContain([
            FRBS_ROLE.ADMIN,
            FRBS_ROLE.DEVELOPER,
          ])}
          isPlaystoreTester={userD?.personali?.email?.startsWith("demo@") || configVariablesHandler.data?.playstoreTesting}
        />
      )}
      // column1Content={(scrollHandler) => <View />}
      column2Content={[
        {
          headerTitle: tr("Start here"),
          showTitle: false,
          render: () => (
            <ProfileAndActionsCenter />
            // <TourGuideZone
            //   zone={1}
            //   // text={"👆\nWelcome! Tap here to get started"}
            //   text={
            //     <Txt.H1 style={{ color: "black" }}>
            //       Welcome! Tap here to get started 👆
            //     </Txt.H1>
            //   }
            // >
            //   <ProfileAndActionsCenter />
            // </TourGuideZone>
          ),
          icon: "hand_peace",
        },
        //#region [section] Calendar
        {
          /** On bigger screen, it'll show Today's event on 2nd column */
          headerTitle: tr("Today's events"),
          showTitle: false,
          render: ({ badgeValueCallback }) => (
            <WidgetCalendar badgeValueCallback={badgeValueCallback} />
          ),
          icon: "calendar",
          hiddenIf: [!breakpointForTwoColumns, !frbsUser],
        },
        {
          /** On smaller screen, it'll navigate to CalendarScreen */
          headerTitle: tr("Calendar"),
          showTitle: true,
          render: ({ badgeValueCallback }) => <CalendarScreen />,
          icon: "calendar",
          hiddenIf: [breakpointForTwoColumns],
        },
        //#endregion
        {
          headerTitle: tr("Chat"),
          showTitle: false,
          // render: ({ badgeValueCallback }) => <TelegramChats />,
          // render: () => null,
          onPress: () => {
            Navigation.navigate("chat");
          },
          icon: "chat_bubble",
          hiddenIf: [breakpointForTwoColumns],
        },
        {
          headerTitle: tr("Today's Briefing"),
          showTitle: false,
          icon: "compass",
          render: () => <TodaysAgendaSection />,
          hiddenIf: [!breakpointForTwoColumns],
        },
        {
          headerTitle: tr("Shortcuts"),
          showTitle: false,
          icon: "widgets",
          render: () => <ShortcutsHomeGrid type="compact" />,
          hiddenIf: [!breakpointForTwoColumns],
        },
        {
          headerTitle: tr("Create"),
          showTitle: true,
          icon: "plus_item",
          render: () => <CreatorActionsList />,
          hiddenIf: [
            !breakpointForTwoColumns,
            !userProfile._isRolesContain([
              FRBS_ROLE.ADMIN,
              FRBS_ROLE.DEVELOPER,
            ]),
          ],
        },
        {
          headerTitle: tr("Post Announcement"),
          showTitle: true,
          icon: "plus_item",
          render: () => <PosstCreatorCenter />,
          hiddenIf: [
            breakpointForTwoColumns,
            !userProfile._isRolesContain([
              FRBS_ROLE.ADMIN,
              FRBS_ROLE.DEVELOPER,
            ]),
          ],
        },
        {
          headerTitle: tr("Send Push Notification"),
          showTitle: true,
          icon: "bullhorn",
          render: () => <BroadcastCreator />,
          hiddenIf: [
            breakpointForTwoColumns,
            !userProfile._isRolesContain([
              FRBS_ROLE.ADMIN,
              FRBS_ROLE.DEVELOPER,
            ]),
          ],
        },
      ]}
    />
  ) : (
    <Rush.Bar indeterminate style={{ width: "100%" }} />
  );
}
