/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";

// const linking: LinkingOptions<RootStackParamList> = {
const linking: LinkingOptions<{}> = {
  prefixes: [],
  config: {
    screens: {
      Drawer: {
        screens: {
          Main: {
            screens: {
              Home: "home",
              Pages: "pages",
              BaseShops: "baseshops",
              Tools: "tools",
            },
          },
        },
      },

      "content-item": {
        path: "content-item/:pageId/:itemId",
      },
      //#region [section ] home.navigator
      "sboard-scr": {
        path: "home/scoreboard/:sbid/",
        parse: {
          sbid: (sbid) => sbid,
        },
      },
      "calendar-home-scr": {
        path: "home/calendar/",
      },
      //#endregion
      //#region [section2] pages.navigator
      "Page Contents": {
        path: "pages/:cpid/:collabMode?/",
        parse: {
          cpid: (cpid) => cpid,
        },
        stringify: {
          title: () => "",
          collabMode: () => "",
        },
      },
      //#endregion
      //#region [section] bs.navigator
      "bs-body-scr": {
        path: "pages/:cpid/",
        parse: {
          cpid: (cpid) => cpid,
        },
      },
      //#endregion
      //#region [section2] tools.navigator
      "bml-web": {
        path: "tools/bml",
      },

      // "bml-mobile": {
      //   path: "tools/bml",
      // },
      // "bml-mobile-list": {
      //   path: "tools/",
      // },
      // "bml-mobile-settings": {
      //   path: "tools/",
      // },
      multiverse: { path: "tools/multiverse" },
      "mass-mail": { path: "tools/mass-mail" },
      "mass-mail-compose": { path: "tools/mass-mail/compose" },
      "use-template": { path: "tools/mass-mail/template" },

      // "collab-web": { path: "tools/collab" },
      // "collab-multiverse": { path: "tools/multiverse" },
      // "Collab Contents": { path: "tools/multiverse" },
      //#endregion
      //#region [section] fixed root stack
      //todo think of url scheme for Overlay and Dialog in the future
      // Overlay: "o/:key",
      // Overlay: "o/:headerTitle/", //* we don't set Overlay and Dialog so that if url scheme is overlay-related, it auto goes to home screen
      Gallery: {
        path: "/gallery/:images?/:imageIndex",
        stringify: {
          images: (images) => "",
        },
      },
      PosstCreator: "/post-creator",
      "faq-scr": { path: "faq/:title/" },
    },
    //#endregion
  },
};

export default linking;

const linkingOG: LinkingOptions<{}> = {
  prefixes: [],
  config: {
    screens: {
      Drawer: {
        screens: {
          Home: "home",
          "sboard-scr": {
            path: "scoreboard/:sbid/",
            parse: {
              sbid: (sbid) => sbid,
            },
          },
          "team-home-scr": "team/",
          "user-scr": "user",
        },
        path: "/",
      },
      Onboarding: { path: "/about/" },
      // Dialog: { path: "/d/" },
      // Overlay: "o/:key",
      // Overlay: "/:headerTitle/", //* since we're using sub-domain, no need to show `hierarchyName` here anymore
      //"/:hierarchyName1/:headerTitle/", //todo set "/:hierarchyName/"" (our goal) will conflict with Main. Need to find solution for this
      Gallery: {
        path: "/gallery/:images",
        stringify: {
          images: (images) => "",
        },
      },
      //"/:hierarchyName2/gallery/",  //todo set "/:hierarchyName/"" (our goal) will conflict with Main. Need to find solution for this
      PosstCreator: "/post-creator",
      "welcome-scr": "_",
      "auth-scr": "sign-in",
      "faq-scr": { path: "faq/:title/" },
    },
  },
};
