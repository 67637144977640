import { ListItemProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import {
  Avt,
  Inpuut,
  Kitten,
  Poppy,
  Rush,
  Skeleton,
  Toasty,
  Txt
} from "components/atoms";
import { useTwoColumns } from "components/templates";
import {
  ACTION_TYPE,
  dScore,
  fn,
  FRBS_ROLE,
  recordUserAction,
  ScoreboardSchema,
  useAppContext,
  UserSchema,
  __DefaultUserData
} from "engines";
import {
  useMemberDocument,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import { useScoreboardDocument } from "engines/firebase/handler/scoreboard-handler";
import React from "react";
import { Image, View } from "react-native";
import { Easing } from "react-native-reanimated";
import { useNavigator } from "screens";
import { moderateScale, scale, spacing, tr } from "utils";
import { useMembersCollection } from "../../../engines/firebase/handler/members-handler";
import { ScoreEditor } from "./ScoreEditor";
/**
 * ### Display and edit Existing score
 *  - Display scorer's name, email, ava, medal (top 3 only)
 * 
 *  ----
 *  @example 
 *  <M.ScorerItem.Existing
      index={index}
      key={scorer.uid}
      sbid={data._sbid}
      scorer={scorer}
      memberID={memberID}
      onPress={() =>
        setMemberID(memberID === scorer.uid ? null : scorer.uid)
      }
      style={{ backgroundColor: "transparent" }}
    />
 *  ----
 *  @version 21.08.17
 *  -  *Run on ios✅, web✅*
 *  @author  K
 *  
 **/
function Existing(props: dScorerItem) {
  const { sbid, scorer, onPress, index } = props;
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  //#region [FRBS]
  const [memberD, setMemberD] = React.useState<UserSchema>(null);
  // following code is also to be used on ScoreboardCard to determine top scorer info
  const memberHandler =
    scorer.uid !== "nonUser" ? useMemberDocument(scorer?.uid) : { data: null };
  const _fieldPath = scorer.identifier?.includes("@")
    ? "personali.email"
    : "personali.phoneNumber";
  const potentialMembersHandler = scorer.identifier
    ? useMembersCollection({
      where: [
        [_fieldPath, "==", scorer.identifier?.replace("+1", "")],
        ["_teamId", "==", teamId],
      ],
    })
    : { data: null };
  let scoreboardDoc = useScoreboardDocument(sbid);
  const { data: userD, ...userProfile } = useUserProfile();
  //#endregion

  React.useEffect(
    function initMember() {
      memberHandler.data && setMemberD(memberHandler.data);
    },
    [memberHandler.data]
  );
  React.useEffect(
    function initPotentialMember() {
      const potentialMember = potentialMembersHandler.data?.pop();
      potentialMember && setMemberD(potentialMember);
    },
    [potentialMembersHandler.data]
  );

  const scoreString = React.useMemo(() => {
    if (typeof scorer.score == "number") {
      return scorer.score.toString();
    }
    if (typeof scorer.score == "string") {
      return scorer.score;
    }
  }, [scorer]);
  //#region [functions]

  function onSubmit(score: string | number) {
    // console.log("score: ", score);
    scoreboardDoc.updateScore(scorer?.uid, score, scorer?.name);
    recordUserAction(memberHandler.data, ACTION_TYPE.SCOREBOARD_ENTRY, `${memberHandler.data?.personali?.displayName || ""} updated their score in \"${scoreboardDoc?.data?.title}\": ${score}`, { location: scoreboardDoc?.data?._sbid, involvedPartyName: scoreboardDoc.data?.title })
    Toasty.show(tr("Score updated!"));
  }
  function onRemove() {
    Poppy(
      tr("Final Check"),
      tr("Are you sure you want to delete this score?"),
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => {
          scoreboardDoc.xScore(
            scorer.uid,
            scorer.uid === "nonUser" ? scorer.name : null
          );
          Toasty.show(tr("Score deleted!"));
        },
      }
    );
  }

  function onEditScore({ }) {
    Navigation.dialogPortal.open({
      render: (
        <ScoreEditor
          personali={
            memberD?.personali || {
              //* When Scorer doesn't exist in members
              displayName: scorer?.name || tr("Unknown name"),
              photoURL: "",
            }
          }
          score={scoreString}
          onSubmitCallback={onSubmit}
          onRemoveCallback={onRemove}
        />
      ),
      headerTitle: tr("Edit score"),
    });
  }
  //#endregion

  function MedalIcon() {
    let MedalColor = [
      {
        highlightColor: C["color-warning-500"],
        boneColor: C["color-warning-400"],
      },
      {
        highlightColor: C["color-basic-500"],
        boneColor: C["color-basic-400"],
      },
      {
        highlightColor: C["color-warning-800"],
        boneColor: C["color-warning-700"],
      },
    ];
    if (index > 2) {
      return <View />;
    } else {
      return (
        <View
          style={{
            borderRadius: scale(16),
            marginLeft: spacing(2),
            overflow: "hidden",
            backgroundColor: "transparent",
          }}
        >
          <Skeleton
            isLoading
            duration={1200 + index * 10}
            easing={Easing.cubic}
            animationDirection={"diagonalTopRight"}
            layout={[
              {
                key: "medal-wrapper",
                width: scale(16),
                height: scale(16),
                // justifyContent: "center",
              },
            ]}
            highlightColor={MedalColor[index].highlightColor}
            boneColor={MedalColor[index].boneColor}
          />
        </View>
      );
    }
  }

  try {
    // if (memberHandler.error || scoreboardDoc.error)
    //   return <Txt.Debug marginV>Error getting member's score</Txt.Debug>;
    // if (!memberD || !scoreboardDoc.data)
    //   return <Skeleton layout={SkeletonLayout.MemberItem} />;
    return (
      <Kitten.ListItemv2
        style={[
          //#region [section2] Style for user item
          userD?._id === scorer?.uid && {
            backgroundColor: C["color-primary-transparent-100"],
          },
          //#endregion
          //#region [section] Style for podium items
          index < 3 && {
            backgroundColor: C["color-success-transparent-100"],
            borderLeftColor: C["color-success-500"],
            borderLeftWidth: 4,
          },
          //#endregion
        ]}
        // disabled
        title={() => (
          <Kitten.ViewH style={{ alignItems: "center" }}>
            <Txt.S2>
              {memberD?.personali?.displayName ||
                scorer.name ||
                tr("Unknown name")}
            </Txt.S2>
          </Kitten.ViewH>
        )} //*{fakerStatic.name.findName()}
        // description={!memberD?._id ? tr("") : memberD?.personali?.email} //*{fakerStatic.company.catchPhrase()}
        onPress={onPress}
        accessoryLeft={() =>
          !memberD?._id ? (
            <Image
              style={{
                width: moderateScale(25),
                height: moderateScale(25),
                marginRight: spacing(3),
                tintColor: fn.js.stringToColor(scorer?.name),
              }}
              source={{
                uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1664056457/admin/flag_1_w8uul0.png",
              }}
            />
          ) : (
            <Avt
              style={{
                ...(memberD?._id ? { backgroundColor: "transparent" } : {}),
              }}
              size={35}
              name={
                memberD?.personali?.displayName ||
                memberD?.personali?.email ||
                ""
              }
              source={{
                uri: memberD?.personali?.photoURL,
              }}
            />
          )
        }
        // accessoryRight={() => (
        //   <Kitten.ViewH
        //     style={{
        //       minWidth: "46%",
        //       justifyContent: "flex-end",
        //       // backgroundColor: "blue",
        //     }}
        //   >
        //     <TextInput
        //       ref={refInput}
        //       maxLength={9}
        //       placeholder={_score}
        //       editable={isEditing}
        //       placeholderTextColor={isEditing ? C.dim : C.text}
        //       defaultValue={updatedScore}
        //       onChangeText={(value) => setUpdatedScore(value)}
        //       style={{
        //         color: C.text,
        //         fontSize: scale(20),
        //         fontWeight: "bold",
        //         textAlign: "right",
        //         minWidth: "30%",
        //       }}
        //       onSubmitEditing={onSubmit}
        //     />
        //     {isEditing ? (
        //       <Kitten.ViewH>
        //         <Buttoon
        //           size="tiny"
        //           appearance="ghost"
        //           disabled={updatedScore == "" || updatedScore == null}
        //           onPress={onSubmit}
        //         >
        //           {tr("Submit")}
        //         </Buttoon>
        //         <Buttoon
        //           size="tiny"
        //           appearance="ghost"
        //           status="danger"
        //           onPress={() => {
        //             setIsEditing(false);
        //           }}
        //         >
        //           {tr("Cancel")}
        //         </Buttoon>
        //       </Kitten.ViewH>
        //     ) : (
        //       userProfile._isUserAdmin() && (
        //         <Menoo
        //           trigger={{ name: "dots_vertical", size: scale(16) }}
        //           options={[
        //             {
        //               text: tr("Edit"),
        //               onSelect: () => {
        //                 setIsEditing(true);
        //                 refInput.current?.focus();
        //               },
        //             },
        //             {
        //               text: tr("Delete"),
        //               onSelect: onRemove,
        //               color: C.errorRed,
        //             },
        //           ]}
        //         />
        //       )
        //     )}
        //   </Kitten.ViewH>
        // )}
        accessoryRight={() => (
          <Kitten.ViewH>
            <Txt.H3
              style={{
                color:
                  index == 0
                    ? C["color-warning-400"]
                    : index == 1
                      ? C["color-basic-600"]
                      : index == 2
                        ? C["color-warning-800"]
                        : C.text,
              }}
            >
              {fn.js.formatNumber(scoreString)}
            </Txt.H3>
            <MedalIcon />
            {
              //* Allow edit if user is ADMIN, DEV, or he's the scorer
              (userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]) ||
                userD?._id === scorer?.uid) && (
                <IconPrimr
                  name="pen"
                  size={scale(14)}
                  onPress={onEditScore}
                  color={
                    userProfile._isRolesContain([
                      FRBS_ROLE.ADMIN,
                      FRBS_ROLE.DEVELOPER,
                    ])
                      ? C.adminGold
                      : C.primary
                  }
                  preset={"circular"}
                />
              )
            }
          </Kitten.ViewH>
        )}
      />
    );
  } catch (error) {
    throw Error(`::U2NvcmVySXRlbQ==::${error}`);
  }
}

/**
 * ### New score ScoreItem
 *  - Used to add member's scorer which hasn't shown on list yet
 *  ----
 *  @example
 *  <ScorerItem.NewScorer />
 *  ----
 *  @version 21.06.02
 *  -  *Build component*
 *  @author  K
 *
 **/
function Newbie(props: dNewScorer) {
  const { member: _member, sbid, nonUser } = props;
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();

  // @ts-ignore
  const member: UserSchema = _member
    ? _member
    : {
      ...__DefaultUserData(),
      personali: {
        displayName: nonUser?.name,
        email: "",
        photoURL: nonUser?.image,
      },
      _id: "nonUser",
      _teamId: teamId,
    };

  //#region [FRBS]
  // const { data: memberD, ...memberHandler } = useMemberDocument(member?._id); //* temporary not use another hook; use imported "member" instead
  let scoreboardHandler = useScoreboardDocument(sbid);
  //#endregion

  const [score, setScore] = React.useState<number | string>(0);

  function onSubmit(updatedScore?: number) {
    let _score = updatedScore || score;
    if (_score === String(0) || _score == 0) {
      Toasty.show(tr("Score cannot be 0. Please try again"), {
        type: "danger",
      });
    } else {
      scoreboardHandler.addScore(
        member._id,
        _score,
        member.personali?.displayName
      );
      setScore(0);
      recordUserAction(member, ACTION_TYPE.SCOREBOARD_ENTRY, `${member?.personali?.displayName || ""} updated their score in \"${scoreboardHandler.data?.title}\": ${_score}`, { location: scoreboardHandler.data?._sbid, involvedPartyName: scoreboardHandler.data?.title })
      Toasty.show(tr("Score successfully added"));
    }
  }

  function onItemPress() {
    Navigation.dialogPortal.open({
      render: (
        <ScoreEditor
          personali={member?.personali}
          score={score}
          onSubmitCallback={onSubmit}
        />
      ),
      headerTitle: "Add score",
    });
  }

  if (scoreboardHandler.error)
    return (
      <Txt.Indicator marginV>
        {tr("Error getting member's score")}
      </Txt.Indicator>
    );
  if (!scoreboardHandler.data) return <Rush.Bar {...Rush._config.Loading} />;
  return (
    <Kitten.ListItemv2
      title={() => (
        <Txt.S2 numberOfLines={1}>
          {member?.personali?.displayName || "Unknown name"}
        </Txt.S2>
      )} //*{fakerStatic.name.findName()}
      // description={member?.personali?.email || ""} //*{fakerStatic.company.catchPhrase()}
      onPress={onItemPress}
      accessoryLeft={() => (
        <Avt
          name={member?.personali?.displayName || ""}
          source={{ uri: member.personali?.photoURL }}
        />
      )}
      accessoryRight={() =>
        props?.disabled ? (
          <Txt.Helper>{tr("Already on the scoreboard")}</Txt.Helper>
        ) : breakpointForTwoColumns ? (
          <Inpuut
            selectTextOnFocus
            onBlur={() => {
              (score == "" || score == null) && setScore(0);
            }}
            maxLength={9}
            placeholder={tr("Enter score")}
            doneText={tr("Add")}
            value={score}
            onChangeText={setScore}
            onSavePress={onSubmit}
            isUrl={false}
            textStyle={{
              width: scale(42),
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "right",
            }}
          />
        ) : (
          <IconPrimr preset="circular" name="plus" color={C.primary} />
        )
      }
      {...props}
    />
  );
}

/**
 * ### List Item for Member
 *  - Including some member's action
 *  ----
 *  @version 21.03.20
 *  -  *Move this component to ./molecules*
 *  @author  K
 *
 **/
export function ScorerItem() {
  return Existing;
}
ScorerItem.Existing = Existing;
ScorerItem.Newbie = Newbie;

export interface dScorerItemC0 extends Partial<typeof Existing> {
  Existing: typeof Existing;
  Newbie: typeof Newbie;
}

interface dScorerItem extends ListItemProps {
  index: number;
  sbid: ScoreboardSchema["_sbid"];
  scorer: dScore;
  onPress(): void;
}

interface dNewScorer extends ListItemProps {
  member?: UserSchema;
  nonUser?: { name: string; image?: string };
  sbid: ScoreboardSchema["_sbid"];
  onSubmitCallback?(): void;
}
