import { Kitten } from "components";
import { Buttoon, Poppy, sstyled, Toasty, Txt } from "components/atoms";
import { BoxMediaPicker } from "components/atoms/others/BoxMediaPicker/BoxMediaPicker";
import { fn, ShortcutSchema, useAppContext } from "engines";
import { dUseCollectionReturns } from "engines/firebase/config";
import { useMoreDocument } from "engines/firebase/handler/more-handler";
import { DefaultMoreData } from "engines/firebase/initial-values";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import { useMoreCollection } from "engines/firebase/handler/more-handler";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { IconPrimr } from "assets/icons";
import { WidgetEditor } from "./widget-editor";
import * as R from "ramda";
import { FPATH } from "../../../engines/firebase/firebase.props";
import { Avt } from "../../atoms/generals/avt/Avt";
import { ResourceSelect } from "../posst-creator/ResourceSelect";

export function ResourcelinkEditor(props: {
  shortcutsHandler?: dUseCollectionReturns<ShortcutSchema>;
  editingDocId?: string;
  onBack(): void;
  mode: "default" | "from-home";
  type?: "contact" | "link" | "inapp";
}) {
  const { editingDocId, type, mode = "default", onBack } = props;
  const { teamId, C } = useAppContext();
  const Navigation = useNavigator();
  const { _smallestPositionIndex, ...shortcutsHandler } = useMoreCollection();
  const shortcutHandler = useMoreDocument(editingDocId);
  //#region [section] fields
  const [position, setPosition] = React.useState<string>(
    shortcutHandler.data?.position
  );
  const [label, setLabel] = React.useState<string>(shortcutHandler.data?.name);
  const [url, setUrl] = React.useState<string>(
    shortcutHandler.data?.link ||
      shortcutHandler.data?.iosLink ||
      shortcutHandler.data?.androidLink ||
      shortcutHandler.data?.otherLink
  );
  const [contactEmail, setContactEmail] = React.useState<string>(
    shortcutHandler.data?.contactEmail
  );
  const [contactPhone, setContactPhone] = React.useState<string>(
    shortcutHandler.data?.contactPhone
  );
  const [thumbnail, setThumbnail] = React.useState<string>(
    shortcutHandler.data?.logo
  );

  const [_isStarred, setIsStarred] = React.useState<boolean>(
    mode === "from-home" || shortcutHandler.data?.isStarred
  );
  //#endregion
  //#region [section2] handle shortcut type
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  React.useEffect(
    function onSelectHandler() {
      if (shortcutsHandler) {
        if (selectedIndex == 0) {
          setContactEmail("");
          setContactPhone("");
        }
        if (selectedIndex == 1) {
          setUrl("");
        }
      }
    },
    [selectedIndex, shortcutsHandler]
  );
  //#endregion
  //#region [section] handle reposition
  function onMovingStart(sourceIndex: number, targetIndex: number) {
    let _shortcuts = shortcutsHandler?.data;
    /**
     * Calculate new target position value
     * -  if targetIndex < sourceIndex, position = avg(target-1 ,target);
     * if targetPostion is 0, position is set to -1
     * -  if targetIndex > sourceIndex, position = avg(target ,target + 1);
     * if targetPostion is n, position is set to n+1
     */
    const targetPosition = () => {
      if (targetIndex < sourceIndex) {
        return _shortcuts[targetIndex - 1]
          ? R.median([
              parseInt(_shortcuts[targetIndex - 1]?.position),
              parseInt(_shortcuts[targetIndex]?.position),
            ])
          : parseInt(_shortcuts[targetIndex]?.position) - 1;
      }
      if (targetIndex > sourceIndex) {
        return _shortcuts[targetIndex + 1]
          ? R.median([
              parseInt(_shortcuts[targetIndex]?.position),
              parseInt(_shortcuts[targetIndex + 1]?.position),
            ])
          : parseInt(_shortcuts[targetIndex]?.position) + 1;
      }
    };

    console.log("[move]: ", sourceIndex + " -> " + targetIndex);
    console.log(
      "[move] update position of doc ",
      _shortcuts[sourceIndex]?._id + " to " + targetPosition()
    );
    !R.isNil(targetPosition()) &&
      shortcutHandler
        .update<ShortcutSchema>(
          FPATH.MORE + "/" + _shortcuts[sourceIndex]?._id,
          {
            position: targetPosition(),
          }
        )
        .then(() => {
          Toasty.show(tr("Update position..."), {
            type: "loading",
            duration: 1100,
          });
        });
  }

  //#endregion
  //#region [functions]
  async function onSubmit() {
    shortcutsHandler?.add({
      ...DefaultMoreData({ teamId }),
      name: label,
      link: url,
      contactEmail,
      contactPhone,
      logo: thumbnail,
      position: _smallestPositionIndex() - 1,
      isStarred: _isStarred,
    });

    Toasty.show(tr("Successfully create a shortcut!"), {
      type: "success",
    });
    onBack && onBack();
  }

  async function onUpdate() {
    shortcutHandler?.update({
      name: label,
      link: url,
      contactEmail,
      contactPhone,
      logo: thumbnail,
      position,
      isStarred: _isStarred,
    });
    Toasty.show(tr("Successfully update a shortcut!"), {
      type: "success",
    }),
      onBack && onBack();
  }

  function onRemove() {
    Poppy(
      tr("Final Check"),
      tr("Do you want to remove this shortcut?"),
      {
        text: "Cancel",
        onPress: () => onBack(),
      },
      {
        text: "Yes",
        onPress: async () => {
          shortcutHandler.deleteDocument();
          Toasty.show(tr("Shortcut removed"));
          Navigation.goBack();
        },
      }
    );
  }
  //#endregion
  return (
    <Kitten.Layout style={{ width: "100%", padding: spacing(3) }}>
      <View style={{ marginVertical: spacing(3) }}>
        <ResourceSelect
          isLevel={false}
          // linkscustom={updates ? updates.url : null}
          createNewPageAllowed={false}
          onChange={(link, resource) => {
            /**
             * `link` will be either:
             *  - a url (start with http), or
             *  - a page id (start with page:PAGE_ID)
             * it'll be ALL store on url field of PageItemSchema,
             * since `toPage` field may contain page's name according to the old schema
             */
            console.log("link: ", link);
            setThumbnail(resource?.mediaItem?.logo);
            setLabel(resource?.name);
            setUrl(link);
          }}
          flexDirection="column"
        />
      </View>
      {url && (
        <View>
          <Txt.Helper marginV>{tr("Custom label, if needed")}</Txt.Helper>
          <A.Input
            disabled={!label}
            placeholder={tr("Custom label, if needed")}
            autoCapitalize="none"
            value={label}
            onChangeText={setLabel}
          />
        </View>
      )}
      <Kitten.Divider_________ />
      <Kitten.ListItemv2
        onPress={() => setIsStarred(!_isStarred)}
        title={"Star this shortcut"}
        description={`Shortcut will${
          _isStarred ? "" : " not"
        } show up on home screen`}
        accessoryRight={() => (
          <IconPrimr
            onPress={() => setIsStarred(!_isStarred)}
            name={_isStarred ? "star" : "staro"}
            color={_isStarred ? C.primary : C.grey600}
          />
        )}
        style={{ backgroundColor: "transparent" }}
      />
      <Kitten.Divider_________ />
      {/* {shortcutsHandler && <Buttoon onPress={onSubmit}>{tr("Create")}</Buttoon>} */}
      {1 == 1 && (
        <Kitten.ViewH
          style={{ justifyContent: "space-between", margin: spacing(3) }}
        >
          <Buttoon
            appearance={"ghost"}
            size="tiny"
            onPress={() => onBack && onBack()}
          >
            {tr("Cancel")}
          </Buttoon>
          <Buttoon onPress={onSubmit} disabled={!label || !url}>
            {tr("Create")}
          </Buttoon>
        </Kitten.ViewH>
      )}
      {/* {editingDocId && type && ( */}
      {1 == 2 && (
        <Kitten.ViewH
          style={{ justifyContent: "space-between", margin: spacing(3) }}
        >
          <Buttoon
            appearance={"ghost"}
            size="tiny"
            onPress={() => onBack && onBack()}
          >
            {tr("Cancel")}
          </Buttoon>
          <Buttoon appearance="ghost" status="danger" onPress={onRemove}>
            {tr("Delete")}
          </Buttoon>
          <Buttoon onPress={onUpdate}>{tr("Update")}</Buttoon>
        </Kitten.ViewH>
      )}
    </Kitten.Layout>
  );
}

const A = {
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
  Input: sstyled(Kitten.Input)({
    minWidth: 3,
    fontSize: scale(16),
    // padding: spacing(3),
  }),
  ViewThumbnail: sstyled(View)({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: spacing(6),
  }),
  ViewMediaUploader: sstyled(View)({
    // transform: [{ scale: 0.5 }],
    width: 6,
    height: 6,
    alignSelf: "flex-start",
  }),
};
