import {
  IndexPath,
  NativeDateService,
  SelectProps
} from "@ui-kitten/components";
import { Buttoon, Kitten, Txt } from "components";
import { sstyled } from "components/atoms/generals";
import { fn } from "engines";
import * as React from "react";
import { Pressable, View } from "react-native";
import { scale, spacing } from "utils";

/**
 * ### Date Time Picker for Primr Design System
 *  - Using UI Kitten instead of native date time picker
 *  - Usable on Web
 *  ----
 *  @example
 *  <DatteLatte
      onSelectCallback={(datteStr, date) => {
        console.log("datte: ", datteStr);
        // setScheduleAt(datteStr);
        // onSetSchedule(isScheduled, datteStr);
      }}
    />
 *  ----
    @see https://user-images.githubusercontent.com/14075977/127312945-a17c9f58-8d0a-49e9-9c17-9b86657e7c21.png
 *  @version 21.07.28
 *  -  *Update phone UI, need to check web*
 *  @author  K
 *
 **/
export function DatteLatte({
  onSelectCallback,
  size = "small",
  disableTimeOptions = false,
  defaultDate,
}: dDatteLatte) {
  // const {  } = props;
  const [selectedDate, setSelectedDate] = React.useState(
    defaultDate ? defaultDate : new Date()
  );
  const [ampm, setAmPm] = React.useState<"AM" | "PM">(() => {
    let _d = defaultDate ? defaultDate : new Date();
    return _d?.getHours() >= 12 ? "PM" : "AM";
  });
  // const [ampm, setAmPm] = React.useState<string>("PM");

  //#region [section] Hour setup
  // var hours = selectedDate.getHours();
  var minutes = selectedDate.getMinutes();
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes ? minutes : 59; // e.g. when it's 2:00, minutes is 'undefined', hence return 59
  const defaultHours =
    selectedDate?.getHours() > 12
      ? selectedDate?.getHours() - 12
      : selectedDate?.getHours();
  const [selectedHourIndex, setSelectedHourIndex] = React.useState(
    new Kitten.IndexPath(defaultHours - 1 < 0 ? 0 : defaultHours - 1) // was just - 1
  );
  let hourData = Array.from({ length: 12 }, (v, k) => k + 1).map(
    (number, index) => {
      let readableTime =
        number.toString().length <= 1
          ? "0" + number.toString()
          : number.toString();
      return { id: readableTime, title: readableTime };
    }
  );

  const displayHour = hourData[selectedHourIndex.row];
  //#endregion

  //#region [section] Minute setup
  const [selectedMinuteIndex, setSelectedMinuteIndex] = React.useState(
    new Kitten.IndexPath(defaultDate ? defaultDate.getMinutes() : minutes)
  );
  let minuteData = Array.from({ length: 59 }, (v, k) => k + 1).map(
    (number, index) => {
      let readableTime =
        number.toString().length <= 1
          ? "0" + number.toString()
          : number.toString();
      return { id: readableTime, title: readableTime };
    }
  );

  const displayMinute = minuteData[selectedMinuteIndex.row];
  /**
   * ###
   *  Format service is used in changing the formatting of the date to what ever we want to have it like
   *  eg so we can do MM/DD/YYYY or YYYY/MM/DD or DD/MM/YYYY
   *  formatting depends on us
   *  @author  NL
   *
   **/
  const formatDateService = new NativeDateService("en", {
    format: "MM/DD/YYYY",
  });

  //#endregion

  React.useEffect(
    function callback() {
      let _scheduleAt =
        (selectedDate.toLocaleDateString("en-US") ||
          fn.calendar.dateFormat(selectedDate)) +
        ", " +
        (displayHour?.title || "00") +
        ":" +
        (displayMinute?.title || "00") +
        // ":00 " +
        ampm;
      onSelectCallback && onSelectCallback(_scheduleAt, selectedDate);
    },
    [selectedDate, displayHour, displayMinute, ampm]
  );

  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 12);

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);

  return (
    <A.View>
      <A.ViewDate>
        <Kitten.Datepicker
          max={maxDate}
          min={minDate}
          size={size}
          date={selectedDate}
          onSelect={(nextDate) => {
            selectedDate.setMonth(nextDate.getMonth());
            selectedDate.setDate(nextDate.getDate());
            selectedDate.setFullYear(nextDate.getFullYear());
            setTimeout(() => {
              setSelectedDate(selectedDate);
            });
          }}
          dateService={formatDateService}
          style={{ paddingBottom: 0, minWidth: scale(90) }}
        />
      </A.ViewDate>

      {disableTimeOptions ? null : (
        <>
          <A.ViewHour>
            <Kitten.Select
              size={size}
              style={{ minWidth: scale(90) }}
              placeholder={"hh"}
              selectedIndex={selectedHourIndex}
              onSelect={(index) => {
                selectedDate.setHours((index as IndexPath).row + 1);
                if (ampm === "PM" && (index as IndexPath).row + 1 !== 12)
                  selectedDate.setHours(selectedDate.getHours() + 12);

                setSelectedDate(selectedDate);
                setSelectedHourIndex(index as IndexPath);
              }}
              value={displayHour?.title}
            >
              {hourData.map((hour, index) => {
                return <Kitten.SelectItem key={index} title={hour?.title} />;
              })}
            </Kitten.Select>
          </A.ViewHour>
          <A.ViewColon>
            <Txt>:</Txt>
          </A.ViewColon>
          <A.ViewMin>
            <Kitten.Select
              size={size}
              style={{ minWidth: scale(90) }}
              placeholder={"mm"}
              selectedIndex={selectedMinuteIndex}
              onSelect={(index) => {
                // if (ampm === "PM") {
                //   selectedDate?.setHours(selectedDate?.getHours() - 12);
                // } else if (ampm === "AM") {
                //   selectedDate?.setHours(selectedDate?.getHours() + 12);
                // }

                selectedDate.setMinutes((index as IndexPath).row);

                setSelectedDate(selectedDate);
                setSelectedMinuteIndex(index as IndexPath);
              }}
              value={displayMinute?.title}
            >
              {minuteData.map((minute, index) => {
                return <Kitten.SelectItem key={index} title={minute?.title} />;
              })}
            </Kitten.Select>
          </A.ViewMin>
          <A.ViewAMPM>
            <Pressable
              onPress={() => {
                if (ampm === "PM") {
                  setAmPm("AM");
                  // If the hour is 12 or greater, switch to AM
                  selectedDate?.setHours(selectedDate?.getHours() - 12);
                  setSelectedDate(selectedDate);
                } else if (ampm === "AM") {
                  setAmPm("PM");
                  // If the hour is less than 12, switch to PM
                  selectedDate?.setHours(selectedDate?.getHours() + 12);
                  setSelectedDate(selectedDate);
                }
                // if (ampm === "AM") {
                //   if (selectedDate.getHours() - 12 > -1) {
                //     selectedDate.setHours(selectedDate.getHours() - 12);
                //   }
                // } else if (ampm === "PM") {
                //   if (selectedDate.getHours() + 12 < 25) {
                //     selectedDate.setHours(selectedDate.getHours() + 12);
                //   }
                // }
                setSelectedDate(selectedDate);
                // setAmPm(ampm == "AM" ? "PM" : "AM");
              }}
            >
              <Txt>{ampm} ⇄</Txt>
            </Pressable>
          </A.ViewAMPM>
        </>
      )}
    </A.View>
  );
  return (
    <A1.Ctnr>
      <A1.CtnrDate>
        <Kitten.Datepicker
          size={size}
          date={selectedDate}
          onSelect={(nextDate) => setSelectedDate(nextDate)}
          style={{ paddingBottom: 0 }}
        />
        <Txt>@ </Txt>
      </A1.CtnrDate>
      <A1.CtnrTime>
        <Kitten.Select
          size={size}
          style={{ minWidth: scale(90) }}
          placeholder={"hh"}
          selectedIndex={selectedHourIndex}
          onSelect={(index) => setSelectedHourIndex(index)}
          value={displayHour?.title}
        >
          {hourData.map((hour, index) => {
            return <Kitten.SelectItem key={index} title={hour?.title} />;
          })}
        </Kitten.Select>
        <Txt> : </Txt>
        <Kitten.Select
          size={size}
          style={{ minWidth: scale(90) }}
          placeholder={"mm"}
          selectedIndex={selectedMinuteIndex}
          onSelect={(index) => setSelectedMinuteIndex(index)}
          value={displayMinute?.title}
        >
          {minuteData.map((minute, index) => {
            return <Kitten.SelectItem key={index} title={minute?.title} />;
          })}
        </Kitten.Select>
      </A1.CtnrTime>
      <A1.CtnrAMPM>
        <Buttoon
          appearance="ghost"
          size={size}
          icon={{ name: "refresh", right: true }}
          onPress={() => {
            setAmPm(ampm == "AM" ? "PM" : "AM");
          }}
        >
          {ampm}
        </Buttoon>
      </A1.CtnrAMPM>
    </A1.Ctnr>
  );
}

const A = {
  View: sstyled(View)({
    flex: 1,
    justifyContent: "space-around",
    flexDirection: "row",
  }),
  ViewDate: sstyled(View)({
    flexBasis: 60,
    flexGrow: 2,
    // backgroundColor: "steelblue",
    alignItems: "center",
  }),

  ViewHour: sstyled(View)({
    flexBasis: 50,
    flexGrow: 2,
    // backgroundColor: "blue",
  }),
  ViewMin: sstyled(View)({
    flexBasis: 50,
    flexGrow: 2,
    // backgroundColor: "blue",
  }),
  ViewColon: sstyled(View)({
    flexBasis: 16,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: "dodgerblue",
  }),
  ViewAMPM: sstyled(View)({
    flexBasis: 50,
    justifyContent: "center",
    // flexGrow: 1,
    alignItems: "flex-start",
    paddingLeft: spacing(3),
    // backgroundColor: "dodgerblue",
  }),
};

const A1 = {
  Ctnr: sstyled(View)({
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexDirection: "row",
  }),
  CtnrDate: sstyled(View)({
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: "powderblue",
    flexBasis: 115,
  }),
  CtnrTime: sstyled(View)({
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: "blue",
    flexBasis: 200,
  }),
  CtnrAMPM: sstyled(View)({
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    // backgroundColor: "powderblue",
    flexBasis: 50,
  }),
};

interface dDatteLatte {
  size?: SelectProps["size"];
  onSelectCallback?(datte: string, date: Date): void;
  /**
   * Whether or not to allow selecting a specific time
   */
  disableTimeOptions?: boolean;
  defaultDate?: Date;
}
