import { IconPrimr, ICON_NAME } from "assets";
import { Buttoon, dAccessory, O, Toasty } from "components";
import { FRBS_ROLE, useAppContext } from "engines";
import { useMemberDocument } from "engines/firebase/handler/members-handler";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";
import { ContactTeamCenter } from "./ContactTeamCenter";

export function MemberInfoTeam({ memberId }) {
  const { C } = useAppContext();
  const handleMember = useMemberDocument(memberId);
  const Navigation = useNavigator();
  let memberData = React.useMemo(() => handleMember.data, [handleMember.data]);
  let isMemberBanned = memberData?.roles?.includes(FRBS_ROLE.BANNED);
  let isMemberAdmin = memberData?.roles?.includes(FRBS_ROLE.ADMIN);

  //#region [functions]
  async function onLevelCompleteToggle() {
    const allLevelsCompleted = !handleMember.data?.allLevelsCompleted;
    Toasty.show(
      tr(`${allLevelsCompleted ? "Unl" : "L"}ocking user's levels...`),
      {
        type: "loading",
      }
    );
    await handleMember.update({
      allLevelsCompleted,
    });
    Toasty.show(
      tr(
        `${handleMember.data?.personali?.displayName}'s levels are now ${
          allLevelsCompleted ? "un" : ""
        }locked!`
      )
    );
  }

  function toMemberContacts() {
    Navigation.overlayPortal.open({
      headerTitle:
        memberData.personali?.displayName || memberData.personali?.email,
      render: <ContactTeamCenter userId={memberData._id} />,
    });
  }
  //#endregion

  try {
    if (handleMember.error) throw Error("firebase-error");
    return (
      <View
        style={[
          isMemberBanned && {
            backgroundColor: C.errorRed,
          },
          { width: "100%" },
        ]}
      >
        <View>
          <O.PersonaliViewCenter uid={memberData._id} />
          <Buttoon
            status={"info"}
            appearance="filled"
            icon={{ name: "contacts" }}
            onPress={toMemberContacts}
          >
            {tr("View Contact Lists")}
          </Buttoon>
        </View>
      </View>
    );
  } catch (error) {
    throw new Error(`::TWVtYmVySW5mbw==:: ${error}`);
  }
}

const MenuIcon = (name: ICON_NAME, props: dAccessory) => (
  <IconPrimr
    name={name}
    size={props.style.width}
    color={props.style.tintColor}
  />
);

interface P {
  team?: boolean;
}
