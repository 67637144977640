import { iHierarchyFeedData } from "components/organisms/collaboration/HierarchyHomeFeed";
import { CollaborationMultiverse } from "screens/collaboration/Collaboration-Multiverse";
import { CollabRsBodyScreen } from "screens/collaboration/CollabRsBodyScreen";
import { Multiverse } from "screens/collaboration/ContentMultiverse";
import { HierarchyHomeScreen } from "screens/collaboration/HierarchyHomeScreen";
import { CollaborationLoadScreen } from "../../components/organisms/collaboration/CollaborationLoadScreen";

/**
 * ###  A Navigator for Media Resources Screens related to collaboration hierarchies
 * ---
 * @version 21.11.08
 * @author Saurabh_M
 */
export const CollabStackCollection = {
  multiverse: {
    component: Multiverse,
    component: CollaborationMultiverse,
    options: ({ route }) => ({
      title: "Content Multiverse",
    }),
  },
  // "collab-web": {
  //   component: Collaboration,
  //   options: ({ route }) => ({
  //     title: "Search by Hierarchy",
  //     //
  //   }),
  // },
  "collab-multiverse": {
    component: CollaborationMultiverse,
    options: ({ route }) => ({
      title: "Search the Multiverse 🌎",
      //
    }),
  },
  "hierarchy-home": {
    component: HierarchyHomeScreen,
    options: ({ route }: { route: iHierarchyFeedData }) => ({
      title: `Collaboration Resources`,
      // ...presetNavConfig.noHeader,
    }),
  },
  "Collab Contents": {
    component: CollabRsBodyScreen,
  },
  "Collab Loading": {
    component: CollaborationLoadScreen,
  },
};

export type COLLABFEED_STK = keyof typeof CollabStackCollection;
