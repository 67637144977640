//#region [import]
import { Buttoon, Kitten, sstyled, Txt } from "components";
import { correctHTML, fn, useAppContext, useCalendar } from "engines";
import * as React from "react";
import { FlatList, Pressable, View } from "react-native";
import HTML from "react-native-render-html";
import { useNavigator } from "screens";
// import HTML from "react-native-render-html";
import { moderateScale, spacing, tr, scale } from "utils";

//#endregion

/**
 * ###  Screen of Calendar and its CRUD ops
 * -  The Screen contains specific layout.
 * -  Some screen may use `$_QuickGlance`, which is the core <_> of CalendarScreen
 * @version 1.1.23
 * @author K
 */
export function WidgetCalendar({ badgeValueCallback }) {
  const { C } = useAppContext();
  const Navigation = useNavigator();

  const { data: _events, loading, error, fetch } = useCalendar();

  const todaysEvents = _events?.filter(
    (event) =>
      event &&
      fn.calendar.dateFormat(event.date) === fn.calendar.dateFormat(new Date())
  );
  React.useEffect(
    function updateEventCount() {
      loading || !todaysEvents
        ? badgeValueCallback && badgeValueCallback("...")
        : badgeValueCallback && badgeValueCallback(todaysEvents?.length);
    },
    [_events, loading]
  );
  try {
    return (
      <A.ViewWidget>
        <Kitten.ListItemv2
          title={tr("Calendar")}
          disabled
          accessoryRight={() => (
            <Kitten.ViewH>
              <Txt.S2>
                {fn.calendar.dateFormat(new Date())}
                {"  "}
              </Txt.S2>
              {todaysEvents?.length ? (
                <A.ChipIndicator>{todaysEvents?.length || "-"}</A.ChipIndicator>
              ) : (
                <></>
              )}
            </Kitten.ViewH>
          )}
          style={{ backgroundColor: C.surface }}
        />
        <Kitten.List
          data={todaysEvents}
          keyExtractor={(item, index) =>
            index + "__" + item.description?.length
          }
          ItemSeparatorComponent={Kitten.Divider}
          style={{
            // borderWidth: 1,
            // borderColor: C.background,
            backgroundColor: "transparent",
          }}
          renderItem={({ item, index }) => (
            <Kitten.ListItemv2
              key={index + "__" + item.description?.length}
              title={`${item.title}\n${item.time}`}
              description={() => (
                <HTML
                  source={{ html: correctHTML(item.description) }}
                  // contentWidth={230}
                  baseFontStyle={{
                    color: C.text,
                    fontSize: moderateScale(14),
                  }}
                  tagsStyles={{
                    a: {
                      color: C.primaryDarker,
                      fontSize: moderateScale(14),
                      fontWeight: "bold",
                    },
                    p: {
                      fontSize: moderateScale(14),
                    },
                  }}
                />
              )}
            />
          )}
          ListEmptyComponent={() => (
            <Txt.Helper marginV onPress={fetch}>
              {loading ? tr("Getting events...") : tr("No events today")}
            </Txt.Helper>
          )}
        />
        <Buttoon
          appearance={"ghost"}
          size={"tiny"}
          status="basic"
          icon={{ name: "chevron_right", right: true }}
          onPress={() => Navigation.navigate("calendar-home-scr")}
        >
          {tr("Show full calendar")}
        </Buttoon>
      </A.ViewWidget>
    );
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}

const A = {
  CardContainer: sstyled(Pressable)({
    backgroundColor: "surface",
    margin: spacing(2),
  }),
  TxtToday: sstyled(Txt.H4)({
    paddingHorizontal: spacing(3),
    textAlign: "center",
  }),
  CardEvent: sstyled(Kitten.Card)({
    marginBottom: spacing(3),
  }),
  ViewWidget: sstyled(Pressable)(({ hovered }) => ({
    // height: scale(120),
    flex: 1,
    flexGrow: 1,
    borderWidth: 1,
    backgroundColor: "surface",
    // padding: spacing(2),
    borderColor: hovered ? "primary" : "transparent",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "primaryDarker",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};
