import { useFocusEffect } from "@react-navigation/native";
import { IndexPath } from "@ui-kitten/components";
import { sstyled } from "components";
import { Avt, Buttoon, Kitten, ListHeader, Toasty } from "components/atoms";
import { MediaPageSchema, Trophy, useAppContext } from "engines";
import { dUseDocumentReturns } from "engines/firebase/config";
import {
    useConfigDocument,
    useTrophyCollection
} from "engines/firebase/handler/config-handler";
import { isLevelPageV2 } from "engines/functions/level-functions/level-page-utils";
import React from "react";
import { TextInput, View } from "react-native";
import * as Animatable from "react-native-animatable";
import { useNavigator } from "screens/_navigation";
import { scale, spacing, tr } from "utils";
import { CreateTrophy } from "./CreateTrophy";
/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.15
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function AssignTrophytoPage(props: P) {
  const { pageHandler } = props;

  const { teamId } = useAppContext();
  const Navigation = useNavigator();
  const configsHandler = useConfigDocument(`variables-${teamId}`);
  const { data, error, add } = useTrophyCollection(`variables-${teamId}`);
  const [trophies, setTrophies] = React.useState<Trophy[]>([]);
  const [autoSelectPageId, setAutoSelectPageId] = React.useState<string>(null);
  const [selectedId, setSelectedId] = React.useState<string>();
  const indexOffset = 1;
  const autoSelectedPage =
    autoSelectPageId &&
    trophies?.filter((_pg) => _pg._id === autoSelectPageId).pop();

  const [_selectedPageIndex, setSelectedPageIndex] =
    React.useState<IndexPath>(null);
  const selectedPageIndex = autoSelectedPage
    ? new IndexPath(trophies.indexOf(autoSelectedPage) + indexOffset)
    : _selectedPageIndex;

  React.useEffect(
    function setupData() {
      data && setTrophies(data);
    },
    [data]
  );
  const isLevelPage = isLevelPageV2(pageHandler.data?.name);

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    setQuery(value);
    const newData = data?.filter((page) => {
      return page.name?.toLowerCase().includes(value.toLowerCase());
    });
    // console.log("valuee: ", newData);
    setTrophies([...newData]);
  }
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [functions] search ops
  //
  function assignTrophy() {

    // console.log("isLevelPage ", isLevelPage)

    // if (isLevelPage) {
    pageHandler.update({
      trophyId: selectedId,
    });
    Toasty.show("Trophy assigned!", {
      type: "success",
    });
    // } 
    // else {
    //   console.log("checkerLevel ", isLevelPage);
    //   Toasty.show("Sorry, this isn't a level page!", { type: "danger" });
    // }
  }
  //#endregion

  // //#region [functions] Assign Page to trophy
  // /**
  //  * ### 
  //  *  - assignTrophy() is used to assign a level page to a trophy
  //  *  ----
  //  *  @example 
  //  *  Copy and paste function is the best...
  //  *  ----
  //  *  @version 22.05.18
  //  *  -  *Brief changelog*
  //  *  @author  NL
  //  *  
  //  **/
  //  function assignTrophy() {

  //   pageHandler.update({
  //     trophyId: selectedId,
  //   });
  //   Toasty.show("Trophy assigned!", {
  //     type: "success",
  //   });

  // }
  // //#endregion
  try {
    const displayValue = selectedPageIndex
      ? trophies[selectedPageIndex.row - indexOffset]?.name
      : null;

    return (
      <A.CtnrPersonali>
        <ListHeader
          title={tr("Assign page to trophy ")}
          actions={[
            {
              label: "Create trophy",
              onPress: () => {
                Navigation.goBack();

                setTimeout(() => {
                  Navigation.dialogPortal.open({
                    headerTitle: "Add contact",
                    render: <CreateTrophy />,
                  });
                }, 1000);
              },
            },
          ]}
        />

        <Kitten.Select
          style={{ flexGrow: 1 }}
          placeholder={tr("Select trophy")}
          value={displayValue}
          selectedIndex={selectedPageIndex}
          onSelect={(index) => {
            setSelectedPageIndex(index);
            setSelectedId(trophies[index.row - indexOffset]?._id);
          }}
        >
          <A.InputSearch
            autoFocus={true}
            value={query}
            placeholder={tr("Search trophy by name")}
            onChangeText={(value) => {
              setQuery(query);
              filterHandler(value);
            }}
          />

          {trophies?.map((trophy, index) => (
            <Kitten.SelectItem
              key={trophy._id}
              title={trophy.name}
              accessoryLeft={() => (
                <Avt
                  name={trophy.name || "K"}
                  source={{ uri: trophy.uri || "" }}
                />
              )}
            />
          ))}
        </Kitten.Select>

        <Kitten.Divider_________ />
        <Buttoon
          onPress={() => {
            assignTrophy();
          }}
        //   disabled={loader}
        >
          {tr("Assign Trophy!")}
        </Buttoon>

        <Kitten.Divider_________ />
      </A.CtnrPersonali>
    );
  } catch (error) {
    throw Error(`::QWRkQ29udGFjdENyZWF0b3I=::${error}`);
  }
}

const A = {
  ViewContainer: sstyled(View)({
    marginVertical: 3,
    // minWidth: 300,
  }),
  Input: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
  InputSearch: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
  InputParagragh: sstyled(TextInput)({
    minHeight: scale(169),
    color: "text",
    borderWidth: 1,
    borderRadius: scale(4),
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
  }),
  CtnrPersonali: sstyled(Animatable.View)((p) => ({
    justifyContent: "flex-start",
  })),
  CtnrAvatar: sstyled(View)({
    // flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  Ctnr$: sstyled(View)({
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    // width: p.WIDTH * 0.2,
  }),
};

interface P {
  pageHandler?: dUseDocumentReturns<MediaPageSchema>;
}
