export interface UploadOptions {
  /** upload url to the storage service */
  url: string;
  appendToFormData?: Record<string, any>;
  /** headers to be sent with the request */
  headers?: Record<string, any>;
}

export enum CLOUDINARY_FOLDER {
  CLIENTS = "clients",
  TROPHIES = "trophies",
  PROFILE_PHOTOS = "profilePhotos"
}
