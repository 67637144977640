import * as EXContacts from "expo-contacts"
import { DrawerActions } from "@react-navigation/native";
import { MenuIcon } from "assets/icons/MenuIcon";
import { dAccessory, Kitten, Poppy } from "components/atoms";
import { fn, useAppContext, useUserProfile } from "engines";
import { ContactSchemaa, dContactNote } from "engines/backends/schemas/bml";
import { useContactsCollection } from "engines/firebase/handler/contact-handler";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import React from "react";
import { useNavigator } from "screens";
import { IS_WEB, tr } from "utils";

export function ContactGroupActionsList() {
  const { C, teamId, frbsUser } = useAppContext();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();
  const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);
  const { qualificationData } = useBMLMContext()

  const contactHandler = useContactsCollection(frbsUser?.uid);

  React.useEffect(
    function setupData() {
      contactHandler.data && setContacts(contactHandler.data);
    },
    [contactHandler.data]
  );

  const handleExport = () => {
    const exportData = contacts.map((item) => {
      const phoneNumbers = (item?.phoneNumbers as EXContacts.PhoneNumber[])?.length > 0 ? (item.phoneNumbers as EXContacts.PhoneNumber[]).map(n => n.number).join(",") : ""

      const formattedNotes = item?.notes
        ? (item?.notes as dContactNote[])
          ?.map((n) => {
            return `Note "${n.noteText}" created at ${n.createdAt
              .toDate()
              .toLocaleDateString()} by ${n.createdBy.displayName.trim()}`;
          })
          .join("\n")
        : "";

      const qualificationObj = {}
      for (let q in qualificationData) {
        const qualifier = qualificationData[q]
        qualificationObj[qualifier?.name] = (item?.qualifications as string[])?.includes(qualifier?._id) ? true : false
      }

      return {
        "Name": item.displayName,
        "Phone Number(s)": phoneNumbers,
        "Email(s)": item.email || ((item?.emails || []) as EXContacts.Email[])?.map((e: EXContacts.Email) => e.email),
        "Notes": formattedNotes,
        ...qualificationObj
      };
    });

    Poppy(
      "Export Confirmation",
      "Do you want to save your contacts as CSV?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => {
          const headers = ["Name", "Phone Number(s)", "Email(s)", "Notes"].concat(qualificationData.map(d => d.name))
          // console.log("displayName ", contacts);
          IS_WEB
            ? fn.contacts.ContactsExportWeb(
              headers,
              userProfile?.data?.personali?.displayName +
              " exported contacts",
              true,
              exportData
            )
            : fn.contacts.ContactsExport(
              headers,
              userProfile?.data?.personali?.displayName +
              " exported contacts",
              true,
              exportData
            );
        },
      }
    );
  };

  return (
    <Kitten.Menu
      onSelect={(index) => {
        Navigation.dispatch(DrawerActions.closeDrawer());
        switch (index.row) {
          case 0:
            handleExport();
            break;
        }
      }}
    >
      <Kitten.MenuItem
        title={tr("Export as csv", ["CSV"])}
        disabled={!IS_WEB || contacts.length === 0}
        accessoryLeft={(props: dAccessory) => MenuIcon("file_csv", props)}
      />
    </Kitten.Menu>
  );
}
