import React from "react";
import { View } from "react-native";
import WebView from "react-native-webview";

interface d {}

/**
 * ### Brief description
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 23.01.10
 *  -  *Brief changelog*
 *  @author  Your Name
 *
 **/
export function TelegramChats(props: d) {
  const {} = props;
  return (
    <View style={{ flex: 1 }}>
      <WebView
        style={{ flex: 1 }}
        // source={{ uri: `https://web.telegram.org/z/#-1703996064` }}
        source={{ uri: `https://web.telegram.org/` }}
        sharedCookiesEnabled={true}
        allowFileAccess
        javaScriptEnabled
        focusable
        scalesPageToFit
      />
    </View>
  );
}
