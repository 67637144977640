import { Kitten, Toasty, Txt } from "components/atoms";
import {
  ContactGroupSchema,
  FPATH,
  useAppContext,
  useContactGroupsCollection,
} from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import firebase from "firebase";
import React from "react";
import { ActivityIndicator, View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";
import { fuego } from "../../../../fuego";

interface dMoveToCGList {
  userId: string;
  contact: ContactSchemaa;
  currentListId: string;
}

/**
 * ### Renders the list of Contact Groups that the user currently has.
 *  - Contact Manager Move To function for a contact.
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.05.15
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export function MoveToCGList(props: dMoveToCGList) {
  const { currentListId, userId, contact } = props;
  const currentContactGroupHandler = useContactGroupsCollection(userId, {
    where: ["_id", "!=", currentListId],
  });
  // const contactHandler = useContactDocument(userId, contact?._cid);
  const Navigation = useNavigator();
  const [lists, setLists] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { C } = useAppContext();

  // #region [ANCHOR] Setting up contact groups data
  React.useEffect(() => {
    if (currentContactGroupHandler.data) {
      setLists(currentContactGroupHandler.data);
      //   console.log("Lists data is ", cgHandler.data);
    }
  }, [currentContactGroupHandler.data]);

  // #endregion
  const renderItemAccessory = (selectedListId: string) => (
    <Kitten.Button size="tiny" onPress={() => handleMove(selectedListId)}>
      {tr("Move")}
    </Kitten.Button>
  );

  //NOTE 1 - Open an Overlay to select existing list other than the list they are on.
  //NOTE 2 - On Selection, close the list and add that list _id to the groups array on the
  //         contacts subcollection of user and then add that contact id(_cid) to the contact-groups contacts array.
  //NOTE 3 - Show success message & close dialogue portal
  const handleMove = async (moveToListId: string) => {
    setLoading(true);
    //=============================================================================//
    //NOTE When you move a contact the contactRef in firestore remains the same we
    //     just need two references for from ContactGroup to To Contact group when
    //     moving the contact to another list.
    //=============================================================================//
    try {
      const contactRef = fuego.db.doc(
        `${FPATH.USERS}/${userId}/${FPATH.CONTACTS}/${contact?._cid}`
      );
      const contactData = await contactRef.get();

      const fromCGRef = fuego.db.doc(
        `${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}/${currentListId}`
      );
      const fromCGData = await fromCGRef.get();

      const toCGRef = fuego.db.doc(
        `${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}/${moveToListId}`
      );
      const toCGData = await toCGRef.get();

      if (contactData.exists && fromCGData.exists && toCGData.exists) {
        //NOTE Remove the contact from current contact group first and update it's listId
        contactRef
          .update({
            groups: firebase.firestore.FieldValue.arrayRemove(currentListId),
          })
          .then(() => {
            contactRef
              .update({
                groups: firebase.firestore.FieldValue.arrayUnion(moveToListId),
                listId: moveToListId,
              })
              .then(() => {
                //NOTE Then Move the contact to the moveToListId.
                fromCGRef
                  .update({
                    contacts: firebase.firestore.FieldValue.arrayRemove(
                      contact._cid
                    ),
                  })
                  .then(() => {
                    toCGRef
                      .update({
                        contacts: firebase.firestore.FieldValue.arrayUnion(
                          contact._cid
                        ),
                      })
                      .then(() => {
                        Toasty.show(tr("Moved Contact successfully"), {
                          type: "success",
                        });
                        setLoading(false);
                        Navigation.goBack();
                      });
                  });
              })
              .catch((err) => {
                Toasty.show(tr("Failed to move contact to another list."), {
                  type: "danger",
                });
                console.error("Failed to move contact to another list.", err);
                setLoading(false);
              });
          })
          .catch((err) => {
            Toasty.show(tr("Failed to move contact to another list."), {
              type: "danger",
            });
            console.error("Failed to move contact to another list.", err);
            setLoading(false);
          });
      } else {
        Toasty.show(tr("List Data is required to move the contact."), {
          type: "danger",
        });
        setLoading(false);
      }
    } catch (err) {
      Toasty.show(tr("Failed to move contact to another list."), {
        type: "danger",
      });
      console.error("Failed to move contact to another list.", err);
      setLoading(false);
    }
  };

  return (
    <View style={{ justifyContent: "space-evenly" }}>
      {loading ? (
        <View>
          <ActivityIndicator
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: C.background,
              flex: 1,
            }}
            size="large"
            color={C.primary}
          />
          <Txt.Helper>{tr("Moving contact. Please wait!!")}</Txt.Helper>
        </View>
      ) : (
        lists
          ?.filter((l) => l._id !== currentListId)
          ?.map((list: ContactGroupSchema) => (
            <Kitten.ListItemv2
              key={list?._id}
              title={list.name + "     "}
              accessoryRight={() => renderItemAccessory(list?._id)}
            />
          ))
      )}
    </View>
  );
}
