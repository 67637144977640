import * as EXContacts from "expo-contacts"
import { IconPrimr } from "assets";
import {
  Avt,
  ItemSwipeable,
  Kitten,
  Poppy,
  sstyled,
  Toasty,
  Txt,
} from "components";
import {
  ContactGroupSchema,
  dContactCSV,
  fn,
  MediaPageItemSchema,
  useAppContext,
} from "engines";
import { ContactSchemaa, dContactNote } from "engines/backends/schemas/bml";
import {
  useContactGroupDocument,
  useContactGroupsCollection,
} from "engines/firebase/handler/contact-groups-handler";
import { useContactsCollection } from "engines/firebase/handler/contact-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { FlatList, ScrollView, TextInput, View } from "react-native";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { dContactsScrParams, useNavigator } from "screens";
import { IS_WEB, scale, spacing, tr } from "utils";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";

export function ContactTeamCenter(props: P) {
  const { C, frbsUser } = useAppContext();

  const { data } = useContactGroupsCollection(props?.userId);

  const { qualificationData } = useBMLMContext()

  const [emailGroups, setEmailGroups] = React.useState<boolean>(false);
  const [list, setList] = React.useState<boolean>(false);
  const userProfile = useUserProfile();
  const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);
  const [exportData, setExportData] = React.useState<dContactCSV[]>([]);
  const [differentMember, setDifferentMember] = React.useState(false);

  const contactHandler = useContactsCollection(frbsUser?.uid);
  const nonMemberContactHandler = useContactsCollection(props?.userId);

  React.useEffect(
    function setupData() {
      // console.log(`nonMember setting is ${differentMember}`);
      if (!differentMember && contactHandler.data) {
        contactHandler.data && setContacts(contactHandler.data);
        return;
      }
      if (differentMember && nonMemberContactHandler.data) {
        nonMemberContactHandler.data &&
          setContacts(nonMemberContactHandler.data);
        return;
      }
    },
    [contactHandler.data, nonMemberContactHandler.data, differentMember]
  );

  React.useEffect(() => {
    // console.log(`props user is ${props?.userId}`);
    // console.log(`frbs user is ${frbsUser?.uid}`);
    if (props?.userId.trim() !== frbsUser?.uid.trim()) {
      setDifferentMember(true);
    }
  }, [props.userId]);

  React.useEffect(() => {
    // console.log(`Total of ${contacts?.length} contacts for ${props?.userId}`);
    const data =
      contacts && contacts.length > 0
        ? contacts.map((item) => {
          const formattedPN = item.phoneNumbers
            ? typeof item.phoneNumbers[0] === "string"
              ? item.phoneNumbers[0]
              : //@ts-expect-error
              item.phoneNumbers[0]?.number
            : "";

          const formattedNotes = item.notes
            ? item.notes
              ?.map((n) => {
                return `Note "${n.noteText}" created at ${n.createdAt
                  .toDate()
                  .toLocaleDateString()} by ${n.createdBy.displayName.trim()}`;
              })
              .join(",\n")
            : "";

          return {
            displayName: item.displayName,
            phoneNumber: formattedPN,
            email: item.email,
            notes: formattedNotes,
          };
        })
        : [];

    setExportData(data);
    // console.log("Export Data ", data?.length);
  }, [contacts]);

  const handleExport = () => {
    // console.log("Export data is ", exportData.length);
    const exportData = contacts.map((item) => {
      const phoneNumbers = (item?.phoneNumbers as EXContacts.PhoneNumber[])?.length > 0 ? (item.phoneNumbers as EXContacts.PhoneNumber[]).map(n => n.number).join(",") : ""

      const formattedNotes = item?.notes
        ? (item?.notes as dContactNote[])
          ?.map((n) => {
            return `Note "${n.noteText}" created at ${n.createdAt
              .toDate()
              .toLocaleDateString()} by ${n.createdBy.displayName.trim()}`;
          })
          .join("\n")
        : "";

      const qualificationObj = {}
      for (let q in qualificationData) {
        const qualifier = qualificationData[q]
        qualificationObj[qualifier?.name] = (item?.qualifications as string[])?.includes(qualifier?._id) ? true : false
      }

      // alert(JSON.stringify(qualificationObj))

      return {
        "Name": item.displayName,
        "Phone Number(s)": phoneNumbers,
        "Email(s)": item.email || ((item?.emails || []) as EXContacts.Email[])?.map((e: EXContacts.Email) => e.email),
        "Notes": formattedNotes,
        ...qualificationObj
      };
    });

    // if (!IS_WEB) {
    //   Toasty.show("Only available on Web Version", { type: "warning" });
    //   return;
    // }
    Poppy(
      "Final Check",
      "Do you want to save your contacts as CSV?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => {
          // console.log("displayName ", contacts);
          const headers = ["Name", "Phone Number(s)", "Email(s)", "Notes"].concat(qualificationData.map(d => d.name))
          // console.log("displayName ", contacts);
          IS_WEB
            ? fn.contacts.ContactsExportWeb(
              headers,
              userProfile?.data?.personali?.displayName +
              " exported contacts",
              true,
              exportData
            )
            : fn.contacts.ContactsExport(
              headers,
              userProfile?.data?.personali?.displayName +
              " exported contacts",
              true,
              exportData
            );
        },
      }
    );
  };

  return React.useMemo(() => {
    try {
      return (
        <View>
          <Kitten.MenuItem
            title="Save as CSV"
            disabled={data?.length === 0 ? true : false}
            accessoryRight={() => <IconPrimr name={"share"} color={C.text} />}
            onPress={handleExport}
          />
          <Kitten.Card disabled>
            <FlatList
              data={data?.filter((d) => d?.contacts?.length > 0)}
              renderItem={({ item, index }) => (
                <ContactsHomeItem
                  key={index}
                  item={item}
                  index={index}
                  c_uid={props?.userId}
                  sharedItems={props?.sharedItems}
                />
              )}
              ItemSeparatorComponent={Kitten.Divider_________}
              ListEmptyComponent={
                <Txt.Helper marginV>
                  {"No contact(s) have been added by this user"}
                </Txt.Helper>
              }
            />
          </Kitten.Card>
        </View>
      );
    } catch (error) {
      throw Error(`::ContactsHomeCenter::${error}`);
    }
  }, [data, list, emailGroups, exportData]);
}

function ContactsHomeItem(props: dContactsHomeItem) {
  const { item, c_uid, sharedItems } = props;
  const { frbsUser, C } = useAppContext();
  const Navigation = useNavigator();
  const refItemSwipeable = React.useRef<Swipeable>();
  const contactGroupHandler = useContactGroupDocument(frbsUser?.uid, item?._id);
  const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);

  const [differentMember, setDifferentMember] = React.useState(false);

  const contactHandler = useContactsCollection(frbsUser?.uid, item?._id);
  const nonMemberContactHandler = useContactsCollection(c_uid, item?._id);

  React.useEffect(
    function setupData() {
      // console.log(`nonMember setting is ${differentMember}`);
      if (!differentMember) {
        contactHandler.data &&
          setContacts(
            (contactHandler.data as ContactSchemaa[])?.filter(
              (contact) =>
                (contact?.groups as string[])?.includes(item?._id) ||
                contact?.listId === item?._id
              // c?.groups?.some((group) => group == item?._id)
            )
          );
        return;
      }
      if (differentMember) {
        nonMemberContactHandler.data &&
          setContacts(
            nonMemberContactHandler.data?.filter(
              (contact) =>
                (contact?.groups as string[])?.includes(item?._id) ||
                contact?.listId === item?._id
            )
          );
        return;
      }
    },
    [contactHandler.data, nonMemberContactHandler.data, differentMember]
  );

  React.useEffect(() => {
    if (c_uid.trim() !== frbsUser?.uid.trim()) {
      setDifferentMember(true);
    }
  }, [c_uid]);
  //#region [functions]
  function onShare() {
    Toasty.show(tr("Coming to you soon"));
  }
  function onDelete() {
    Poppy(
      "Final Check",
      "Are you sure you want to delete this group?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => contactGroupHandler.deleteDocument(),
      }
    );
  }

  //#endregion

  return (
    <ItemSwipeable
      ref={refItemSwipeable}
      rightActions={[
        {
          icon: { name: "share" },
          status: "info",
          onPress: onShare,
        },
        {
          icon: { name: "trash" },
          status: "danger",
          onPress: onDelete,
        },
        {
          label: tr("Close"),
          size: "tiny",
          appearance: "ghost",
          onPress: () => refItemSwipeable.current?.close(),
        },
      ]}
    >
      <Kitten.ListItemv2
        title={item.name}
        style={
          contactGroupHandler.data?.name == "*New Contact Group" && {
            backgroundColor: C.errorRed,
          }
        }
        onPress={() => {
          contacts && contacts.length > 0
            ? Navigation.navigate<dContactsScrParams>("Contact Group Screen", {
              id: item._id,
              team: sharedItems ? false : true,
              c_uid: c_uid,
              sharedItems: sharedItems,
            })
            : Toasty.show("Nothing to show here.", { type: "warning" });
        }}
        description={() =>
          contacts && contacts.length > 0 ? (
            <Avt.Row
              data={contacts?.map((contact) => ({
                name: contact.displayName || contact.email,
                source: {
                  uri: "",
                },
              }))}
            />
          ) : (
            <Txt.Helper style={{ textAlign: "left", color: C.errorRed }}>
              {"No Contacts"}
            </Txt.Helper>
          )
        }
      />
    </ItemSwipeable>
  );
}

interface dContactsHomeItem {
  index: number;
  item: ContactGroupSchema;
  c_uid?: string;
  sharedItems?: MediaPageItemSchema[];
}

const A = {
  ViewBuilder: sstyled(ScrollView)((p) => ({
    // flex: 1,
    // backgroundColor: "background",
    width: "100%",
    marginBottom: 10,
    paddingHorizontal: spacing(2),
  })),

  Input: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
  CheckBox: sstyled(Kitten.CheckBox)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    // borderBottomWidth: 1,
    // borderColor: "primary",
  }),
  // Thumbnail: sstyled(Imaggie)({
  //   alignSelf: "center",
  //   // width: p.mediaDimension.width,
  //   // height: p.mediaDimension.height,
  //   width: 20,
  //   height: 20,
  //   marginRight: IS_WEB ? 0 : scale(5),
  //   backgroundColor: "dim",
  // }),
};

interface P {
  userId: string;
  sharedItems?: MediaPageItemSchema[];
}
