import { Kitten, ListHeader } from "components";
import { sstyled, Txt } from "components/atoms";
import {
  dateToStr,
  getNormalDate,
  useActivityCollection,
  useAppContext,
  useConfigDocument
} from "engines";
import React from "react";
import { ImageBackground, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { useContactsCollection } from "../../../engines/firebase/handler/contact-handler";
import { TrophiesFeed } from "../Trophy/TrophiesFeed";
import { StatsCreatorCenter } from "./StatsCreatorCenter";
import { StreaksCenter } from "./StreaksCenter";
import { UserStatsOverview } from "./UserStatsOverview";

export function MyJourneySection(props) {
  const { C, frbsUser, teamId, theme } = useAppContext();

  const Navigation = useNavigator();
  const contactsHandler = useContactsCollection(frbsUser?.uid);
  const userActivities = useActivityCollection(frbsUser?.uid);
  const configDoc = useConfigDocument("variables-" + teamId);

  //#region [section] Report config

  //#endregion

  //#region [functions] Add Data functions
  function onAddStatsPress() {
    return hasUserReported()
      ? null
      : Navigation.dialogPortal.open({
          headerTitle: tr("Enter Today's Stats"),
          render: <StatsCreatorCenter />,
        });
  }

  function hasUserReported() {
    return userActivities.data?.find(
      (ac) => dateToStr(getNormalDate(ac?.date)) === dateToStr(new Date())
    );
  }

  //#endregion
  try {
    let todayDate = new Date();
    return (
      <A.ViewSection level="1">
        <ListHeader
          titleTx={tr("My Stats")}
          disabled
          style={{ backgroundColor: "transparent" }}
        />

        <View style={{ padding: spacing(3) }}>
          <A.ViewSection level={"3"}>
            <Kitten.ListItemv2
              title={`This Month (${new Date().toLocaleString("default", {
                month: "long",
              })})`}
              disabled
              style={{ backgroundColor: "transparent" }}
            />
            <View style={{ padding: spacing(3) }}>
              <UserStatsOverview
                onlyShowMonth={new Date()}
                showHistoryShortcut
              />
            </View>
          </A.ViewSection>
          <A.ViewSection level={"3"}>
            <Kitten.ListItemv2
              title={tr("Streaks")}
              disabled
              style={{ backgroundColor: "transparent" }}
            />
            <View style={{ padding: spacing(3) }}>
              <StreaksCenter />
            </View>
          </A.ViewSection>
          <A.ViewSection level={"3"}>
            <Kitten.ListItemv2
              title={tr("Trophy case")}
              disabled
              style={{ backgroundColor: "transparent" }}
            />
            <View style={{ padding: spacing(3) }}>
              <TrophiesFeed />
            </View>
          </A.ViewSection>
        </View>
      </A.ViewSection>
    );
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}

const A = {
  ViewSection: sstyled(Kitten.Layout)(({ hovered }) => ({
    // flex: 1,
    // flexGrow: 1,
    // backgroundColor: "surface",
    marginVertical: spacing(2),
    marginHorizontal: 0,
    width: "100%",
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ImageBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingVertical: spacing(2),
  }),
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "errorRed",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};
