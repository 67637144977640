import fakerStatic from "faker";
import firebase from "firebase";
import { dPersonali } from "../../../../../functions/src/schemas";

/**
 * ###  Schema for media pages
 * -  Custom page is set up by users,
 * containing their own media and contents
 * ---
 * @version 1.1.12
 * @author NL
 * @example
 *
 */
export interface ShortcutSchema {
  _teamId: string;
  _id: string;
  iosLink?: string;
  androidLink?: string;
  /**
   * If this is a team that has a zoom link or ID it can go here
   */
  link?: string;
  /**
   * If the page requires a password to get into
   */
  logo?: string;

  /**
   * The name of the page :D
   */
  name: string;
  position: number;

  otherLink: string;

  createdAt?: firebase.firestore.FieldValue;
  contactPhone?: string;
  contactEmail?: string;
}

interface MoreSchema2 {
  _teamId: string;
  _id: string;
  /** Replacing `name` */
  label: string;
  /**
   * Order in which the more item might show up on the "ShortcutList".
   * For example, a more item with position -3 will show up before one with position 1
   */
  logo?: string;
  position: number;
  link: string;
  contact: dPersonali;
  createdAt?: firebase.firestore.FieldValue;
}

export const _DEFAULT_MORE: ShortcutSchema = {
  _id: fakerStatic.datatype.uuid(),
  _teamId: "app-takeoff-team",
  iosLink: "",
  androidLink: "",
  otherLink: "",
  link: "",
  logo: "",
  name: fakerStatic.company.catchPhrase(),
  position: 0,
};
