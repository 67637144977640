//#region [import]
import { Input } from "@ui-kitten/components";
import { ICON_NAME } from "assets";
import { MenuIcon } from "assets/icons/MenuIcon";
import Color from "color";
import { BroadcastList, Ni, sstyled, Txt } from "components";
import {
  Avt,
  Buttoon,
  dAccessory,
  Kitten,
  ListHeader,
  Menoo,
  Poppy,
  Rush,
  Toasty
} from "components/atoms";
import { MembersFeed, updateButtonIfNeeded } from "components/organisms";
import { useTwoColumns } from "components/templates";
import {
  fn,
  FRBS_ROLE,
  MediaPageItemSchema,
  MediaPageSchema,
  NotificationStatus,
  useAppContext,
  useConfigDocument,
  usePageContentsCollection,
  useUserProfile
} from "engines";
import { calculatePageProgress } from "engines/firebase/handler/levels-handler";
import { usePageDocument } from "engines/firebase/handler/pages-handler";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import { useCollabContext } from "engines/providers/collaboration-provider";
import * as ImagePicker from "expo-image-picker";
import _ from "lodash";
import React from "react";
import { Image, Platform, TextInput, View } from "react-native";
import { renderers } from "react-native-popup-menu";
import { useNavigator } from "screens";
import { AssignTrophytoPage } from "screens/trophy/AssignTrophytoPage";
// import { AssignTrophytoPage } from "screens/trophy/AssignPagetoTrophy";
import { scale, spacing, tr } from "utils";
import { S_PageItemAddPassword } from "utils/helpers/PageItemAddPassword";
//#endregion

/**
 * ### Section displaying page's name, thumbnail
 * -  Show "Page settings" button
 *
 * ----
 * @example As is
 * ----
 * @version 1.1.19
 * -  *Add fool-proof try-catch*
 * @author nguyenkhooi
 */
export function PageInfo(props: P) {
  const { cpid, isUserInThisTeamId = true, previousPageId, buttonItem } = props;
  const { C, teamId, frbsUser } = useAppContext();
  const { breakpointForTwoColumns } = useTwoColumns();
  const configHandler = useConfigDocument(`variables-${teamId}`)
  const Navigation = useNavigator();

  const { setOnlyShowUncompletedContents, onlyShowUncompletedContents } =
    useCollabContext();
  const { data: pageD, ...pageHandler } = usePageDocument(cpid);
  const { data: userD, ...userProfile } = useUserProfile();
  const { data: pageContentsD, ...pageContentsHandler } =
    usePageContentsCollection(cpid);
  const variablesHandler = useConfigDocument(`variables-${teamId}`);

  //#region [section] Edit title/subtitle
  const [editVisibility, setEditVisibility] = React.useState<boolean>(false);
  const [editedName, setEditedName] = React.useState<string>(pageD?.name || "");
  const refInputPageName = React.useRef<Input>();
  //#endregion
  //#region [section2] Remove page
  function onRemove() {
    Poppy(
      "Final Check",
      "Are you sure you want to delete this page?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => {
          pageHandler.deleteDocument();
          Toasty.show(tr("Page deleted"), { type: "success" });
          Navigation.navigate("Pages");
        },
      }
    );
  }
  //#endregion
  //#region [functions] MEDIA-PICKER
  const [avatar, setAvatar] = React.useState("");
  const [mediaProgress, setMediaProgress] = React.useState<number>(0);
  async function cameraPermission() {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        Toasty.show(
          tr("Sorry, we need camera roll permissions to make this work!"),
          { type: "danger", duration: 10000 }
        );
      }
    }
  }

  async function pickImage() {
    await cameraPermission();
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.type) result.type = fn.media.extractMediaType(result.uri);
    // console.log(result); //* e.g. "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="
    if (!result.cancelled) {
      // let _mediaType = fn.media.extractMediaType(result.uri);

      if (result.type !== "image" && result.type !== "video") {
        Toasty.show("Unknown file type.");
        return null;
      } else {
        Poppy(
          "Final Check",
          "Are you sure you want to change this thumbnail?",
          {
            text: "Cancel",
            onPress: () => { },
          },
          {
            text: "Yes",
            onPress: () => {
              uploadMedia(result?.uri, result.type);
            },
          }
        );

        // setSaveVisibility(true);
      }
    }
  }

  function uploadMedia(url: string, type: string) {
    setAvatar(url);

    fn.media
      .uploadMedia(
        url,
        {
          // folder: "baseshop/" + pageD?.name,
          folder: `${CLOUDINARY_FOLDER.CLIENTS}/${teamId}`,
          title: "page-" + pageD?.id,
          type,
        },
        (progress) => {
          setMediaProgress(progress);
        },
        (data) => { }
      )
      .then((response) => {
        pageHandler.update({
          mediaItem: {
            ...pageD?.mediaItem,
            logo: response.url,
          },
        });
        if (buttonItem && previousPageId) {
          updateButtonIfNeeded(buttonItem, previousPageId, pageD, userD);
        }

        console.log("item has been updated");
        // setSaveVisibility(false);
      })
      .catch((error) => {
        console.warn("Error updating avt: ", error);
        setAvatar(null);
        // return null;
      });
  }

  function removeLogo() {
    Poppy(
      "Final Check",
      "Are you sure you want to remove this photo?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () =>
          pageHandler.update({
            mediaItem: {
              ...pageD?.mediaItem,
              logo: "",
            },
          }),
      }
    );
  }
  //#endregion

  //#region [section] Footer containing setting options
  const FooterPageSettings = () => {
    let SETTING_OPTIONS: {
      text: string;
      onSelect?(): void;
      icon?: ICON_NAME;
      color?: string;
      requiredRole?: FRBS_ROLE;
    }[] = [
        {
          text: tr("Edit page title"),
          onSelect: () => {
            setEditedName(pageD?.name);
            setEditVisibility(true);
          },
          icon: "pen",
        },
        {
          text: pageD?.mediaItem?.logo
            ? tr("Remove page logo")
            : tr("Edit page logo"),
          onSelect: pageD?.mediaItem?.logo ? removeLogo : pickImage,
          icon: "x",
        },
        pageD?.password && pageD?.password
          ? {
            text: tr("Remove password"),
            onSelect: () => {
              pageHandler.update({ password: "" });
              Toasty.show(tr("Password removed successfully"), {
                type: "success",
              });
            },
            icon: "password",
          }
          : {
            text: tr("Add password"),
            onSelect: () => {
              Navigation.dialogPortal.open({
                headerTitle: tr("Add password"),
                render: (
                  <View style={{ flex: 1, flexGrow: 1 }}>
                    <S_PageItemAddPassword pageHandler={pageHandler} />
                  </View>
                ),
              });
            },
            icon: "password",
          },
        {
          text: Menoo.Divider,
        },
        {
          text: tr("Assign trophy to page"),
          onSelect: () => {
            Navigation.dialogPortal.open({
              headerTitle: tr("Assign trophy to page"),
              render: <AssignTrophytoPage pageHandler={pageHandler} />,
            });
          },
          icon: "award",
        },
        {
          text: pageD?.collaboration
            ? tr("Disable multiverse access")
            : tr("Enable multiverse access"),
          onSelect: () => {
            Poppy(
              "Collaborating...",
              `Are you sure you want to "${pageD?.collaboration ? "Unshare" : "Share"
              }" this page with other hierarchies?`,
              {
                text: "Cancel",
                onPress: () => { },
              },
              {
                text: "Yes",
                onPress: async () => {
                  await pageHandler.update({
                    collaboration: pageD?.collaboration ? false : true,
                  });
                  //copyGoToPageDetails(pageD._id);
                  //NOTE If the page is shared then all the content is public else it's private and won't show up in Content Multiverse search
                  await pageContentsHandler._updateAllPageContentsData({
                    isContentPrivate: pageD?.collaboration ? true : false,
                  });
                },
              }
            );
          },
          icon: "share",
        },
        {
          text: Menoo.Divider,
        },
        {
          text: tr("Delete page"),
          onSelect: onRemove,
          color: C.errorRed,
          icon: "trash",
        },
      ];
    if (userProfile._isRolesContain([FRBS_ROLE.ADMIN])) {
      const startHereOption = {
        text: 'Set as default "Start Training" page',
        onSelect: () => {
          if (!userProfile._isRolesContain([FRBS_ROLE.ADMIN])) {
            Toasty.show(tr("You must be an administrator to perform this."), {
              type: "danger",
            });
            return;
          }
          if (pageHandler._isBaseShopPage()) {
            Toasty.show(
              tr("This action cannot be performed on a Base Shop page."),
              { type: "danger" }
            );
            return;
          }
          Poppy(
            "Are you sure? 👀",
            "This is a BIG change that will affect everyone!",
            {
              text: "Cancel",
              onPress: () => { },
            },
            {
              text: "Yes",
              onPress: async () => {
                try {
                  await variablesHandler.update({
                    startHerePage: pageD?._id,
                  });
                  Toasty.show(
                    'This is now the default "New? Start Here!" page!'
                  );
                } catch (err) {
                  Toasty.show("Operation failed. Something went wrong.", {
                    type: "danger",
                  });
                }
              },
            }
          );
        },
        icon: "compass",
      };
      SETTING_OPTIONS.splice(SETTING_OPTIONS?.length - 1, 0, startHereOption);
    }

    return (
      <View>
        {/* <Kitten.CheckBox
          checked={onlyShowUncompletedContents}
          onChange={() => {
            setOnlyShowUncompletedContents(!onlyShowUncompletedContents);
          }}
          style={{ alignSelf: "center", margin: spacing(3) }}
        >
          {tr(`Show "unread" items only`)}
        </Kitten.CheckBox> */}
        {userProfile._isRolesContain(
          _.concat(
            pageD?.rolesThatCanEdit,
            FRBS_ROLE.ADMIN,
            FRBS_ROLE.DEVELOPER,
            `BS:${cpid}`
          )
        ) &&
          (isUserInThisTeamId || userProfile?._isDeveloper()) &&
          (breakpointForTwoColumns ? (
            <Menoo
              renderer={renderers.SlideInMenu}
              trigger={{
                render: (
                  <Kitten.ListItemv2
                    title={tr("Page settings")}
                    accessoryLeft={(p) => MenuIcon("cog", p)}
                    disabled
                  // style={{ borderWidth: 1, borderColor: C.adminGold }}
                  />
                ),
              }}
              options={SETTING_OPTIONS}
            />
          ) : (
            <View>
              <ListHeader titleTx={tr("Settings")} />
              <A.CtnrMenu
                selectedIndex={null}
                onSelect={(index) => {
                  SETTING_OPTIONS.filter((op) => op.text !== Menoo.Divider)[
                    index.row
                  ].onSelect();
                }}
                style={{ borderWidth: 1, borderColor: C.adminGold }}
              >
                {SETTING_OPTIONS.filter(
                  (option) =>
                    !option?.requiredRole ||
                    userD?.roles?.includes(option?.requiredRole)
                ).map(
                  (option) =>
                    option.text !== Menoo.Divider && (
                      <Kitten.MenuItem //* Admin stuff
                        title={option?.text}
                        accessoryLeft={(props: dAccessory) =>
                          MenuIcon(option?.icon, props)
                        }
                        style={[
                          option?.color && {
                            backgroundColor: Color(option?.color)
                              .alpha(0.3)
                              .toString(),
                          },
                        ]}
                      />
                    )
                )}
              </A.CtnrMenu>
            </View>
          ))}
      </View>
    );
  };
  //#endregion
  const [showMembers, setShowMembers] = React.useState<boolean>(false);

  try {
    return (
      <View>
        <A.CardContainer
          style={[
            { padding: spacing(3), borderRadius: 4 },
            editVisibility && {
              borderColor: C.adminGold,
              borderWidth: 1,
            },
          ]}
        // disabled
        // footer={FooterPageSettings}
        >
          <View style={{ alignItems: "center" }}>
            {
              /** * This was implemented this way because of the issue * we had
            when ever we change a photo, the component * doesn't show us the
            photo except we refresh the screen. * we might have to look at the
            AVT component to see other ways we can solve that later */
              pageD?.mediaItem?.logo && pageD?.mediaItem?.logo ? (
                <Image
                  source={{
                    uri: pageD?.mediaItem?.logo || `https://get-my.app/t/${configHandler.data?.appTitle
                      ?.toLowerCase()
                      .replace(/ /g, "")}/logo.png`,
                  }}
                  style={{
                    width: 57,
                    height: 57,
                    borderRadius: 2,
                  }}
                />
              ) : (
                <Avt
                  name={pageD?.name || ""}
                  source={{
                    uri: pageD?.mediaItem?.logo || `https://get-my.app/t/${configHandler.data?.appTitle
                      ?.toLowerCase()
                      .replace(/ /g, "")}/logo.png`,
                  }}
                  size="giant"
                  shape="square"
                />
              )
            }
            {/* <Avt
                    name={pageD?.name || ""}
                    source={{ uri: pageD?.mediaItem?.logo || "" }}
                    size="giant"
                    shape="square"
                  /> */}
          </View>
          {editVisibility ? (
            <>
              <A.InputParagragh
                ref={refInputPageName}
                title={tr("Name") + " *"}
                selectTextOnFocus
                multiline
                textStyle={{ minHeight: scale(111) }}
                value={editedName}
                onChangeText={setEditedName}
              />
              <Buttoon
                disabled={editedName === ""}
                onPress={() => {
                  editedName === ""
                    ? (Toasty.show(tr("Cannot leave name field blank"), {
                      type: "warning",
                    }),
                      refInputPageName.current?.focus())
                    : (pageHandler.update({ name: editedName }),
                      Toasty.show(tr("Page title updated!")),
                      Navigation.setParams({
                        title: editedName,
                      }),
                      setEditVisibility(false));
                }}
              >
                {tr("Update")}
              </Buttoon>
              <Buttoon
                appearance={"ghost"}
                onPress={() => {
                  setEditedName("");
                  setEditVisibility(false);
                }}
              >
                {tr("Cancel")}
              </Buttoon>
            </>
          ) : (
            <Txt.H6 style={{ textAlign: "center" }}>
              {
                /**
                 * If no name in db --> "Untitled"
                 * If name existed, show name
                 * If name existed, but onlyShowUncompletedContents == true --> show (*) next to name
                 */
                pageD?.name
                  ? onlyShowUncompletedContents
                    ? pageD?.name + " (*)"
                    : pageD?.name
                  : tr("Untitled")
              }
            </Txt.H6>
          )}
          <Rush.Bar
            progress={calculatePageProgress(userD, pageContentsD) || 0}
            style={{
              width: "100%",
              height: scale(4),
              marginVertical: spacing(3),
            }}
          />
          {
            //#region [section] Page's Trophy
            pageD?.trophyId && (
              <Buttoon
                size={"tiny"}
                status={"basic"}
                style={{ borderRadius: 69 }}
              // onPress={()=> Navigation.dialogPortal.open({headerTitle: ""})}
              >
                {tr("One trophy is waiting for you...")}
              </Buttoon>
            )
            /* <Avt shape="square" source={pageD?.trophyId} /> */
            //#endregion
          }
          <Kitten.CheckBox
            checked={onlyShowUncompletedContents}
            onChange={() => {
              setOnlyShowUncompletedContents(!onlyShowUncompletedContents);
            }}
            style={{ alignSelf: "center", margin: spacing(3) }}
          >
            {tr(`Show "unread" items only`)}
          </Kitten.CheckBox>
          {/* <FooterPageSettings /> */}
          {/* <Txt>{JSON.stringify(pageD?.rolesThatCanEdit, null, "\t")}</Txt> */}
        </A.CardContainer>
        {
          //#region [section2] BS Notifications, only show on BS page
          pageHandler._isBaseShopPage() && (
            <Kitten.MenuItem
              title={tr("Base shop notifications")}
              accessoryLeft={(p) => MenuIcon("bell", p)}
              accessoryRight={(p) =>
                MenuIcon("dot", {
                  ...p,
                  style: {
                    ...p.style,
                    height: scale(12),
                    width: scale(12),
                    tintColor: C.errorRed,
                  },
                })
              }
              onPress={() => {
                Navigation.dialogPortal.open({
                  headerTitle: tr("Base shop notifications"),
                  render: (
                    <BroadcastList
                      bsid={`${pageD._id}`}
                      scheduled={NotificationStatus.SENT}
                    />
                  ),
                });
              }}
            />
          )
          //#endregion
        }
        {
          //#region [section] BS Member, only show on BS page
          pageHandler._isBaseShopPage() && (
            <View>
              <Kitten.MenuItem
                title={tr("Base shop members")}
                selected={showMembers}
                accessoryLeft={(p) => MenuIcon("users", p)}
                accessoryRight={(p) =>
                  MenuIcon(showMembers ? "chevron_up" : "chevron_down", p)
                }
                onPress={() => setShowMembers(!showMembers)}
              />
              {showMembers && (
                <View
                  style={{
                    backgroundColor: C["color-primary-transparent-100"],
                    padding: spacing(3),
                  }}
                >
                  <MembersFeed
                    where={["baseShopId", "==", pageD?.id]}
                    // bsid={pageD?.id}
                    mode={"alacarte"}
                  />
                </View>
              )}
            </View>
          )
          //#endregion
        }
        <Kitten.Divider />
        <FooterPageSettings />
      </View>
    );
  } catch (error) {
    throw Error(`::U2NvcmVib2FyZEFjdGlvbnM=::${error}`);
  }
}

const A = {
  CardContainer: sstyled(Kitten.Layout)((p) => ({
    width: "100%",
  })),
  CtnrIdle: sstyled(View)((p) => ({
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "background",
  })),
  CtnrWelcome: sstyled(Ni.Layout)(() => ({
    paddingVertical: spacing(4),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  })),
  TxtTitle: sstyled(Txt.H6)({
    textAlign: "center",
  }),
  CtnrMenu: sstyled(Kitten.Menu)({
    minWidth: 8,
  }),
  InputParagragh: sstyled(TextInput)({
    minHeight: scale(169),
    color: "text",
    borderWidth: 1,
    borderRadius: scale(4),
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
  }),
};

/**
 * ###  Screen Props
 */
interface P {
  cpid: MediaPageSchema["_id"];
  fullMenu?: boolean;
  isUserInThisTeamId?: boolean;
  // route?: { params?: dScrParams }; //* unmask if we pass params thru rnav
  /** If there was a previous custom page the user came from, this is the cpid of that page */
  previousPageId?: string;
  /** If this page was navigated to by a button item, this is that button */
  buttonItem?: MediaPageItemSchema;
}
