import { useDocument } from "@nandorojo/swr-firestore";
import { FPATH, MailSchema, ScheduleSchema, TemplateSchema } from "engines";
import { useCollectionPresetv2 } from "../config";

export function useEmailDocument(id: string, query?) {
  const presetDocument = useDocument<MailSchema>(`${FPATH.EMAIL}/${id}`, {
    listen: true,
    ...query,
  });

  return { ...presetDocument };
}

export function useScheduleDocument(id: string, query?) {
  const presetDocument = useDocument<ScheduleSchema>(`${FPATH.SCHEDULES}/${id}`, {
    listen: true,
    ...query,
  });

  return { ...presetDocument };
}

export function useEmailTemplateDocument(id: string, query?) {
  const presetDocument = useDocument<TemplateSchema>(
    `${FPATH.TEMPLATES}/${id}`,
    {
      listen: true,
      ...query,
    }
  );

  return { ...presetDocument };
}

export function useEmailsCollection(query?) {
  const emailsHandler = useCollectionPresetv2<MailSchema>(
    FPATH.EMAIL,
    "id",
    query
  );
  return { ...emailsHandler };
}

export function useEmailSchedulesCollection(query?) {
  const emailsHandler = useCollectionPresetv2<ScheduleSchema>(
    FPATH.SCHEDULES,
    "id",
    query
  );
  return { ...emailsHandler };
}

export function useEmailTemplateCollection(query?) {
  const templatessHandler = useCollectionPresetv2<TemplateSchema>(
    FPATH.TEMPLATES,
    "id",
    query
  );
  return { ...templatessHandler };
}
