import { Buttoon } from "components/atoms"
import { TwoColumns, useTwoColumns } from "components/templates"
import { View } from "react-native";
import { IS_WEB } from "utils";
import { MemberView } from "./MemberView"

export function MemberScreen(p: { uid: string, route: any, navigation: any }) {
    const {
        column1ContainerStyle,
        ViewCol1BottomSpace,
        // breakpointForTwoColumns,
    } = useTwoColumns();

    return <TwoColumns
        column1Content={() => <View style={[IS_WEB ? column1ContainerStyle : { flex: 1 }]}>
            <MemberView {...p} />
        </View>}
        column2Content={[]}
    />
}