import { IconPrimr } from "assets";
import { Buttoon, Kitten, Txt } from "components";
import {
  useActivityCollection,
  useAppContext,
  useConfigDocument
} from "engines";
import * as React from "react";
import { StatsScreen, useNavigator } from "screens";
import { spacing, tr } from "utils";

/**
 * ### Streaks Center
 *  - Section for displaying streaks value for each stat
 *  ----
 *  @example
 *  - See Top of 2nd column of Your Stats screen
 *  ----
 *  @version 23.02.23
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function StreaksCenter(p: { uid?: string }) {
  const { uid } = p;
  const { C, frbsUser, teamId } = useAppContext();
  const Navigation = useNavigator();
  const configHandler = useConfigDocument(`variables-${teamId}`);
  const activityHandler = useActivityCollection(uid || frbsUser?.uid);
  return (
    <>
      {uid ? null : (
        <Txt.Helper
          marginV
          // style={{
          //   color: C.grey600,
          //   marginTop: IS_WEB ? -spacing(4) + 4 : spacing(3),
          //   marginBottom: spacing(3) + 4,
          //   marginLeft: spacing(1),
          //   textAlign: IS_WEB ? "left" : "center",
          // }}
        >
          Back to back daily activity will keep your streaks up!
        </Txt.Helper>
      )}
      <Kitten.Layout level="3">
        {configHandler.data?.activities && activityHandler.data ? (
          configHandler.data?.activities?.map((ac) => (
            <Kitten.ViewH
              style={{
                justifyContent: "space-between",
                marginVertical: spacing(4),
                marginHorizontal: spacing(2),
              }}
            >
              <Txt.S1 style={{ maxWidth: "85%" }}>{`${ac.title}`}</Txt.S1>
              <Kitten.ViewH>
                <IconPrimr name="bolt" size={20} color={C.adminGold} />
                <Txt.H5 style={{ color: C.adminGold }}>
                  {`${activityHandler.calculateStreakForActivity(
                    ac.key,
                    activityHandler.data,
                    ac.streakMinimum
                  )}`}
                </Txt.H5>
                {/* <Txt.C1 style={{ alignSelf: "flex-start", color: C.primary }}>
              {" "}
              day(s)
            </Txt.C1> */}
              </Kitten.ViewH>
            </Kitten.ViewH>
          ))
        ) : (
          <>
            <Txt.Indicator marginV>{tr("Loading streaks....")}</Txt.Indicator>
            <Kitten.Spinner />
          </>
        )}
        {/* <StatsCreatorSection /> */}
        <Kitten.ViewH style={{ justifyContent: "flex-end" }}>
          <Buttoon
            size="small"
            appearance="ghost"
            icon={{ name: "chevron_right", right: true }}
            onPress={() =>
              Navigation.overlayPortal.open({
                headerTitle: `Activity Stats`,
                render: <StatsScreen uid={uid} />,
              })
            }
          >
            {tr("View all activity")}
          </Buttoon>
        </Kitten.ViewH>
      </Kitten.Layout>
    </>
  );
}
