import { Avt, Inpuut, Kitten, Menoo, Txt } from "components";
import { useAppContext, useMemberDocument, useUserProfile } from "engines";
import { dContactNote } from "engines/backends/schemas/bml";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { IS_WEB, spacing } from "utils";

interface dBMLContactNotesEdit {
  note: dContactNote;
  handleNoteDeletion: Function;
  handleNoteEdit: Function;
}

function NoteEditor(p: { defaultText: string, onSaveNote: (val: string) => void }) {
  const { defaultText, onSaveNote } = p;
  const [inputValue, setInputValue] = React.useState<string>(defaultText);
  return <View>
    <Inpuut
      textStyle={IS_WEB ? { minHeight: 45 } : { minHeight: 35 }}
      multiline
      autoFocus
      returnKeyType="done"
      doneText="Save"
      alwaysShowSave
      keyboardType="default"
      autoCorrect={false}
      autoCapitalize="none"
      onSavePress={() => {
        onSaveNote(inputValue)
        // handleNoteEdit(n._id, inputValue);
        // setIsEditable(false);
        // Navigation.goBack()
      }}
      onChangeText={(text) => setInputValue(text)}
      value={inputValue}
    />
  </View>
}

/**
 * ### Functional Component to handle BML contact notes editing
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  <BMLContactNotesEdit note={} />
 *  ----
 *  @version 22.04.15
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export function ContactNotesEdit(props: dBMLContactNotesEdit) {
  const Navigation = useNavigator()

  const { note: n, handleNoteDeletion, handleNoteEdit } = props;
  const { C } = useAppContext();
  const [isEditable, setIsEditable] = React.useState(false);
  const [currentNoteText, setCurrentNoteText] = React.useState(n.noteText);
  const { data: createdUserInfo } = useMemberDocument(n.createdBy?._id);
  const user = useUserProfile();
  const userId = user.data?._id;
  const noteCreatorId = n?.createdBy?._id;

  const Footer = () => {
    const { C } = useAppContext();
    return (
      <Kitten.ListItemv2
        style={{ backgroundColor: C.dim }}
        accessoryLeft={() => <AccessoryLeft />}
        accessoryRight={() => <AccessoryRight />}
        disabled
      />
    );
  };

  function editNote() {
    Navigation.dialogPortal.open({
      headerTitle: "Edit Note",
      render: <NoteEditor defaultText={n?.noteText || ""} onSaveNote={(val) => {
        handleNoteEdit(n._id, val);
        setIsEditable(false);
        Navigation.goBack()
      }} />
    })
  }

  const AccessoryRight = () => {
    return userId === noteCreatorId ? (
      <Menoo
        trigger={{
          name: "dots_vertical",
          color: C.text,
          preset: "circular",
          // text: "Click me",
          // style: { color: "tomato" },
        }}
        options={[
          {
            text: "Edit",
            onSelect: () => {
              editNote()
              // setIsEditable(true);
            },
          },
          { text: Menoo.Divider },
          {
            text: "Delete",
            onSelect: () => handleNoteDeletion(n),
            color: C.errorRed,
          },
          { text: Menoo.Divider },
          {
            text: "Cancel",
            onSelect: () => { },
          },
        ]}
      />
    ) : null;
  };

  const AccessoryLeft = () => {
    return (
      <Kitten.ViewH>
        <Avt
          // name={handlePosst.data.author?.name} //todo Old schema
          // source={{ uri: handlePosst.data.author?.avatar }} //todo Old schema
          name={n.createdBy?.displayName || ""}
          source={{
            uri: createdUserInfo?.personali?.photoURL || "",
          }}
          size="small"
        />
        <View style={{ marginLeft: spacing(3) }}>
          <Txt.S2>
            {n.createdBy?.displayName || ""}
            {/* {handleMember.get().personali?.displayName} */}
          </Txt.S2>
          {/* <Txt.S2>{handlePosst.data._pid}</Txt.S2> */}

          <Txt.C2>
            {n.createdAt?.toDate().toLocaleDateString() || "Unknown date"} •{" "}
            {n.createdAt?.toDate().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }) || "Unknown time"}
          </Txt.C2>
        </View>
      </Kitten.ViewH>
    );
  };

  return (
    <Kitten.Card footer={Footer} style={{ marginBottom: 10 }} disabled>
      {!isEditable ? (
        <Txt.P1 style={{ minHeight: 50 }}>{n.noteText}</Txt.P1>
      ) : (
        <Txt.P1 style={{ minHeight: 50 }}>{n.noteText}</Txt.P1>
      )}
    </Kitten.Card>
  );
}
