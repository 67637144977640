//#region [import]
import { useFocusEffect } from "@react-navigation/native";
import { MenuIcon } from "assets/icons/MenuIcon";
import { Buttoon, dAccessory, Kitten, sstyled } from "components";
import {
  AdminToolsCenter,
  DeveloperToolsCenter,
  MembersFeed
} from "components/organisms";
import { AdminToolsList } from "components/organisms/tools/ToolsList";
import { TwoColumns } from "components/templates";
import { FRBS_ROLE, useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { Pressable, View } from "react-native";
import { useNavigator } from "screens";
import { TeamActionFeedScreen } from "screens/team-action-feed/TeamActionFeedScreen";
import { AllTrophies } from "screens/trophy/AllTrophies";
import { CreateTrophy } from "screens/trophy/CreateTrophy";
import { scale, spacing, tr } from "utils";
//#endregion

/**
 * ### Tools home screen
 *  ----
 *  @version 21.03.01
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function ToolsHomeScreen(props: P) {
  const { C, teamId } = useAppContext();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();

  useFocusEffect(() => {
    Navigation.setOptions({
      title: userProfile?._isRolesContain([FRBS_ROLE.ADMIN])
        ? tr("Admin Controls")
        : tr("Members"),
    });
  });
  try {
    return (
      <TwoColumns
        bottomInset
        column1Content={(scrollHandler) => (
          <MembersFeed where={["_teamId", "==", teamId]} />
        )}
        column2Content={[
          // userProfile._isUserAdmin() && {
          //   headerTitle: tr("Collaboration"),
          //   icon: "handshake",
          //   // render: () => <O.ContactsFeed />,
          //   render: () => <SectionCollaboration />,
          // },
          {
            headerTitle: tr("Admin Controls"),
            showTitle: true,
            icon: "admin",
            render: () => <AdminToolsList />,
            hiddenIf: [
              !userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]),
            ],
          },
          {
            headerTitle: tr("Tools control center"),
            showTitle: true,
            icon: "cog",
            render: () => <AdminToolsCenter />,
            hiddenIf: [
              !userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]),
            ],
          },
          {
            headerTitle: tr("Admin Controls - Developer"),
            showTitle: true,
            icon: "cog",
            render: () => (
              <Kitten.Card
                disabled
                style={{ borderWidth: 1, borderColor: C.devPink }}
              >
                <DeveloperToolsCenter />
              </Kitten.Card>
            ),
            hiddenIf: [!userProfile._isRolesContain([FRBS_ROLE.DEVELOPER])],
          },
          {
            headerTitle: tr("Hierarchy's Trophies"),
            showTitle: true,
            icon: "award",
            render: () => (
              <A.CtnrMenu
                selectedIndex={null}
                onSelect={(index) => {
                  // setSelectedIndex(index);
                  switch (index.row) {
                    case 0:
                      Navigation.dialogPortal.open({
                        headerTitle: "Add trophy",
                        render: <AllTrophies />,
                      });

                      break;
                    case 1:
                      Navigation.dialogPortal.open({
                        headerTitle: "Add trophy",
                        render: <CreateTrophy />,
                      });
                      break;
                  }
                }}
              >
                {/* HgFqtK5m$tQ58i@n */}
                <Kitten.MenuItem //* Admin stuff
                  title={tr("All trophies")}
                  accessoryLeft={(props: dAccessory) =>
                    MenuIcon("trophy", props)
                  }
                />
                <Kitten.MenuItem //* Admin stuff
                  title={tr("Create a trophy")}
                  accessoryLeft={(props: dAccessory) => MenuIcon("plus", props)}
                />
              </A.CtnrMenu>
            ),
            hiddenIf: [
              !userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]),
            ],
          },
          {
            headerTitle: tr("Activity Feed"),
            showTitle: true,
            render: () => <TeamActionFeedScreen />,
            icon: <Buttoon size="small" status="control" icon={{ name: "people_friends" }} style={{ marginLeft: spacing(3), borderRadius: 20 }}
              onPress={() => Navigation.navigate("Team Activity")}>
              {"Activity Feed"}
            </Buttoon>,
          }
        ]}
      />
    );
  } catch (error) {
    throw new Error(`::VG9vbHNIb21lU2NyZWVu:: ${error}`);
  }
}

const A = {
  CardContainer: sstyled(Pressable)({
    borderRadius: scale(4),
    overflow: "hidden",
  }),
  ViewLabel: sstyled(View)((p) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  })),
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
};

/**
 * ###  Screen Props
 */
interface P {
  //   route?: { params?: dSboardScrParams }; //* unmask if we pass params thru rnav
}
