import { useFocusEffect } from "@react-navigation/native";
import { sstyled } from "components";
import { Buttoon, Kitten, ListHeader, Toasty } from "components/atoms";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { scale, spacing, tr } from "utils";

/**
 * ###
 *  - This component is used in adding a contact category
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.11.29
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function AddContactCategoryDialog(props: P) {
  const { addFunc } = props;
  const Navigation = useNavigator();

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section] search ops
  const [contactGroup, setContactGroup] = React.useState<string>();
  const [loader, setLoader] = React.useState<boolean>(false);
  //#endregion

  //#region [functions] addContactCategoryFunc
  /**
   * This is the onPress function which is used to create a category when clicked
   */
  function addContactCategoryFunc() {
    setLoader(true);
    if (!contactGroup) {
      Toasty.show("Please fill in the category field", { type: "danger" });
      setLoader(false);
    } else {
      addFunc({
        name: contactGroup,
        groupType: "email-groups",
        contacts: [],
      })
        .then(() => {
          Navigation.goBack();
          Toasty.show("Contact category has been added successfully", {
            type: "success",
          });
          setLoader(false);
        })
        .catch((e) => {
          Toasty.show("Failed to add contact category", { type: "danger" });
          setLoader(false);
        });
    }
    //#endregion
  }

  try {
    return (
      <A.ViewContainer>
        <ListHeader title={tr("Create a contact group")} />
        <A.Input
          placeholder={tr("Contact group name")}
          autoCapitalize="none"
          value={contactGroup}
          onChangeText={setContactGroup}
          autoFocus={true}
        />

        <Kitten.Divider_________ />
        <Buttoon
          onPress={() => {
            addContactCategoryFunc();
          }}
          disabled={loader}
        >
          {tr("Create")}
        </Buttoon>
        <Kitten.Divider_________ />
      </A.ViewContainer>
    );
  } catch (error) {
    throw Error(`::QWRkQ29udGFjdENyZWF0b3I=::${error}`);
  }
}

const A = {
  ViewContainer: sstyled(View)({
    marginVertical: 3,
    // minWidth: 300,
  }),
  Input: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
};

interface P {
  addFunc(item): Promise<any>;
}
