import { Buttoon, sstyled, Toasty } from "components";
import { Kitten, Txt } from "components/atoms";
import {
  MediaPageItemSchema,
  UrlValueForBlastText,
  useAppContext,
  __ResourceItemData
} from "engines";
import {
  usePageContentDocument,
  usePageContentsCollection
} from "engines/firebase/handler/page-contents-handler";
import * as R from "ramda";
import React from "react";
import {
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputSelectionChangeEventData,
  View
} from "react-native";
import { scale, spacing, tr } from "utils";

export function BlastTemplateItemEditor({
  cpid,
  pcid,
  onCloseCallback = () => { },
}: P) {
  const { teamId } = useAppContext()

  const _MODE = pcid ? "update" : "create"; //* if pcid exists, turn into "update" mode

  //#region [FRBS] page contents handler
  const pageContentsHandler = usePageContentsCollection(cpid);
  const pageContentHandler = usePageContentDocument(cpid, pcid);
  //#endregion

  const [message, setMessage] =
    React.useState<MediaPageItemSchema["title"]>("");
  const [defaultTemplateEnabled, setDefaultTemplateEnabled] = React.useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = React.useState<string>("");

  React.useEffect(function updateDefaultTemplateEnabled() {
    if (!pageContentHandler.data) return;
    setDefaultTemplateEnabled(pageContentHandler.data?.textTemplate?.enabledForTeamId === teamId)
    setDescriptionValue(pageContentHandler.data?.textTemplate?.description || "")
  }, [pageContentHandler.data]);

  //#region [functions] CRUD operations
  function createItem(xong) {
    pageContentsHandler.add({
      ...__ResourceItemData(),
      title: message,
      //@ts-ignore
      textTemplate: {
        enabledForTeamId: defaultTemplateEnabled ? teamId : "",
        parentPageId: cpid,
        description: descriptionValue || ""
      },
      url: UrlValueForBlastText,
      position: pageContentsHandler._largestPositionIndex() + 1, //* Latest position on the ContentBody
    });
    setTimeout(() => {
      Toasty.show(tr("Blast Text Item added!"));
      xong && xong();
      onCloseCallback && onCloseCallback();
    }, 690);
  }

  function updateItem(xong) {
    const updatedItem: Partial<MediaPageItemSchema> = {
      title: message,
      //@ts-ignore
      textTemplate: {
        enabledForTeamId: defaultTemplateEnabled ? teamId : "",
        parentPageId: cpid,
        description: descriptionValue || ""
      }
    }
    pageContentHandler.update(updatedItem);
    setTimeout(() => {
      Toasty.show(tr("Blast Text Item updated!"));
      xong && xong();
      onCloseCallback && onCloseCallback({ ...pageContentHandler.data, ...updatedItem });
    }, 690);
  }

  function deleteItem(xong) {
    pageContentHandler.deleteDocument();
    setTimeout(() => {
      Toasty.show(tr("Blast Text Item removed!"));
      xong && xong();
      onCloseCallback && onCloseCallback();
    }, 690);
  }

  function cancelEditing(xong) {
    onCloseCallback && onCloseCallback();
    xong && xong();
  }

  //#endregion

  //#region [ANCHOR] Input handler
  const refInput = React.useRef<TextInput>();
  React.useEffect(function inputFocus() {
    setTimeout(() => {
      refInput.current?.focus();
    }, 420);
  }, []);

  React.useEffect(
    function prepareValueForUpdate() {
      if (_MODE === "update") {
        pageContentHandler.data && setMessage(pageContentHandler.data?.title);
      }
    },
    [pcid]
  );

  //#endregion
  //#region [ANCHOR2] Input handler to add preset fields

  /**
   * Position of cusor in the input field
   */
  const [caret, setCaret] = React.useState({
    /**
     * Position of cusor in the input field
     */
    start: 0,
    end: 0,
  });

  function handleSelect(
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>
  ) {
    setCaret({
      start: e.nativeEvent.selection.start,
      end: e.nativeEvent.selection.end,
    });
  }

  /** Calling to this function you can insert text in the cursor position */
  function insertText(text: string) {
    /**
     * Adds text to cusor position
     */

    setMessage(
      message.substring(0, caret.start) +
      text +
      message.substring(caret.end, message.length)
    );
    refInput.current?.focus();
  }
  //#endregion
  //#region [ANCHOR] Button handler
  /**
   * ### In each mode, Button will be disabled when:
   */
  const handleButtonDisability = () => {
    if (defaultTemplateEnabled !== (pageContentHandler.data?.textTemplate?.enabledForTeamId === teamId)) return false
    switch (_MODE) {
      case "create":
        return R.isEmpty(message);
        break;
      case "update":
        return (message === pageContentHandler.data?.title && descriptionValue === (pageContentHandler.data?.textTemplate?.description || "")) || !message;
        break;

      default:
        break;
    }
  };
  //#endregion
  return (
    <View>
      <A.InputBlastText
        ref={refInput}
        placeholder={tr("Write here...")}
        value={message}
        onChangeText={setMessage}
        multiline
        onSelectionChange={(e) => handleSelect(e)}
      />
      <Txt.Helper marginV>{tr("Preset fields")}</Txt.Helper>
      <Kitten.ViewH style={{ justifyContent: "center" }}>
        <Buttoon
          icon={{ render: <Txt>🌀</Txt> }}
          compact
          size={"tiny"}
          appearance="outline"
          status={"basic"}
          onPress={() => {
            insertText("🌀");
          }}
        >
          {tr("{{recipient's name}}")}
        </Buttoon>
      </Kitten.ViewH>
      <Kitten.Divider_________ />
      <Txt.Helper marginV>{tr("Text Description")}</Txt.Helper>
      <Kitten.Input value={descriptionValue} onChangeText={setDescriptionValue} style={{ width: "100%" }} placeholder="Type description... (optional)" numberOfLines={0} />
      <Txt.Helper marginV>{tr("Settings")}</Txt.Helper>
      <Kitten.Card>
        <Kitten.ViewH style={{ justifyContent: "space-between", marginVertical: spacing(3) }}>
          <Txt.S1>{tr("Make suggested text script")}</Txt.S1>
          <Kitten.CheckBox checked={defaultTemplateEnabled} onChange={val => {
            setDefaultTemplateEnabled(val)
          }} />
        </Kitten.ViewH>
        <Txt.Helper style={{ marginTop: spacing(2) }}>{"If enabled, this text script will be accessible from anywhere in the app labeled \"Text Scripts\""}</Txt.Helper>
      </Kitten.Card>
      <Kitten.Divider_________ />
      <Buttoon
        size={"small"}
        progress
        compact
        disabled={handleButtonDisability()}
        onPress={(xong) => {
          _MODE === "create" ? createItem(xong) : updateItem(xong);
        }}
      >
        {_MODE === "create" ? tr("Add") : tr("Update")}
      </Buttoon>
      <Buttoon
        size={"small"}
        progress
        status={"danger"}
        appearance={"ghost"}
        compact
        onPress={(xong) => {
          _MODE === "update" && !message
            ? deleteItem(xong)
            : cancelEditing(xong);
        }}
      >
        {_MODE === "update" && !message
          ? tr("Remove this item?")
          : tr("Cancel Editing")}
      </Buttoon>
    </View >
  );
}

const A = {
  InputBlastText: sstyled(TextInput)({
    minHeight: scale(169),
    color: "text",
    borderWidth: 1,
    borderRadius: scale(4),
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
    minWidth: ["100%", "100%", scale(240)],
    fontFamily: Platform.OS == "ios" ? "Courier" : "monospace",
  }),
};

interface P {
  /** Page ID that this is created/updated within */
  cpid: string;
  /** Page content's ID */
  pcid?: string;
  onCloseCallback(item?: Partial<MediaPageItemSchema>): void;
}
