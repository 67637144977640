import { sstyled, Txt } from "components";
import { Avt } from "components/atoms";
import { fn, useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { View } from "react-native";
import { spacing } from "utils";

export function PosstCreatorHeader() {
  const { C } = useAppContext();
  const userProfile = useUserProfile();
  const _personali = React.useMemo(
    () => userProfile?.data?.personali,
    [userProfile]
  );
  return (
    <A.CtnrHeader>
      <A.Avatar
        name={_personali?.displayName}
        source={{ uri: _personali?.photoURL || "" }}
        size="tiny"
      />
      <View>
        <Txt.C1
          style={{
            fontWeight: "bold",
            backgroundColor: !!!_personali?.displayName && C.dim,
            borderRadius: 60,
          }}
        >
          {_personali?.displayName || "    "}
        </Txt.C1>
        <Txt.C1>{fn.calendar.dateFormat(new Date())}</Txt.C1>
      </View>
    </A.CtnrHeader>
  );
}

const A = {
  CtnrHeader: sstyled(View)((p) => ({
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: spacing(2),
  })),
  Avatar: sstyled(Avt)((p) => ({
    alignSelf: "center",
    marginRight: spacing(3),
    backgroundColor: "surface",
  })),
};
