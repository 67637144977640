//#region [import]

import { Kitten, ListHeader, Poppy, T } from "components";
import { DeveloperActionList } from "components/molecules/home/DeveloperActionList";
import { BSHomeFeed, ViewTeamLogo } from "components/organisms";
import {
  ConfigVariablesSchema,
  fn,
  FRBS_ROLE,
  useAppContext,
  useConfigDocument
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { usePageDocument } from "engines/firebase/handler/pages-handler";
import React from "react";
import { PageBodyScreen, useNavigator } from "screens";
import { dResourcesBodyScreenParam } from "screens/_navigation/media.navigator";
import { tr } from "utils";
//#endregion

/**
 * ### Scoreboard screen
 *  ----
 *  @version 21.03.01
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function BSHomeScreen(props: P) {
  const { route } = props;
  const { C, teamId } = useAppContext();
  // const pagesHandler = usePagesCollection();

  const userProfile = useUserProfile();
  const Navigation = useNavigator();

  // const [title, setTitle] = React.useState<string>();
  // const [bsItem, setBsItem] = React.useState<MediaPageSchema>();
  const handlerPage = usePageDocument(userProfile?.data?.baseShopId);

  const configHandler = useConfigDocument(`variables-${teamId}`);
  const configVariables = configHandler.data as ConfigVariablesSchema;

  const refDidInformOfNoBaseShop = React.useRef<boolean>(false);
  React.useEffect(
    function noMoreBaseShop() {
      if (!configHandler.data) return;
      if (configVariables._parentAppId && !refDidInformOfNoBaseShop.current) {
        refDidInformOfNoBaseShop.current = true;
        Poppy(
          "No longer relevant",
          "The Base Shop section is no longer relevant to your team because you have your own App Portal now.\nThis will be discontinued soon."
        );
      }
    },
    [configHandler.data]
  );

  React.useLayoutEffect(() => {
    Navigation.setOptions({
      headerTitle: () => <ViewTeamLogo />,
    });
  }, [Navigation]);

  const [pageType, setPaageType] = React.useState<"bsscreen" | "bshomescreen">(
    null
  );

  React.useEffect(
    function checkBS() {
      if (route?.params?.cpid || userProfile.data?.baseShopId) {
        fn.notifications
          .subscribeToTopic(
            teamId,
            fn.notifications.TOPICTYPES.BASESHOP,
            route.params?.cpid || handlerPage.data._id
          )
          .then((res) => {
            console.log("res ", res);
          })
          .catch((e) => {
            console.log(e);
          });
        setPaageType("bsscreen");
      } else {
        setPaageType("bshomescreen");
        Navigation.navigate<dResourcesBodyScreenParam>("BaseShops");
      }
    },
    [pageType, route.params, userProfile.data?.baseShopId, handlerPage.data]
  );

  try {
    switch (pageType) {
      case "bsscreen":
        return (
          <PageBodyScreen
            route={{
              params: {
                cpid: route?.params?.cpid
                  ? route?.params?.cpid
                  : handlerPage.data?._id,
                title: route?.params?.title
                  ? route?.params?.title
                  : handlerPage.data?.name,
                ...route?.params,
              },
            }}
          />
        );
        break;

      default:
        return (
          <T.TwoColumns
            column1Content={(scrollHandler) => (
              <BSHomeFeed scrollHandler={scrollHandler} />
            )}
            column2Content={[
              {
                key: "DeveloperActionList",
                headerTitle: tr("Create a base shop"),
                icon: "plus",
                showTitle: false,
                hiddenIf: [!userProfile._isRolesContain([FRBS_ROLE.DEVELOPER])],
                render: () => (
                  <>
                    <ListHeader titleTx={"Developer only"} />
                    <Kitten.Card
                      // disabled
                      style={{ borderWidth: 1, borderColor: C.devPink }}
                    >
                      <DeveloperActionList />
                    </Kitten.Card>
                  </>
                ),
              },
            ]}
          />
        );
        break;
    }
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}

/**
 * ###  Screen Props
 */

interface P {
  route?: { params?: dResourcesBodyScreenParam }; //* TODO remember to create dResourcesBodyScreenParam
}
