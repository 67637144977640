import firebase from "firebase"
import React from "react"
import { Kitten, ListHeader, Txt } from "components";
import { FPATH, MediaPageItemSchema, MediaPageSchema, useAppContext, useMemberDocument, usePageContentsCollection, usePageDocument, usePagesCollection, useUserProfile } from "engines";
import { useTextTemplates } from "engines/firebase/handler/page-contents-handler";
import { ScrollView } from "react-native-gesture-handler";
import { BlastTemplateItem } from "components/molecules/custom-media/items/blast-template-item";
import { ContactSchemaa, dMessageRecord } from "engines/backends/schemas/bml";
import { IndexPath } from "@ui-kitten/components";

export function TextTemplatesScreen(p: { route }) {
    const { teamId } = useAppContext()
    const { route } = p;
    //@ts-ignore when a text template gets selected to send
    const onSelectText = route?.params?.onSelectText as (item: MediaPageItemSchema) => void;
    const contact = route?.params?.contact as ContactSchemaa
    const msgHistory = contact && contact.messageHistory ? contact.messageHistory : []
    const msgedItemIds = msgHistory.map(m => m.itemId)

    const userHandler = useUserProfile()
    const textTemplateHandler = useTextTemplates(teamId)

    const [pageIds, setPageIds] = React.useState<string[]>(null);
    const [textTemplates, setTextTemplates] = React.useState<MediaPageItemSchema[]>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<IndexPath>(new IndexPath(0))
    const [pages, setPages] = React.useState<(MediaPageSchema & { index: number })[]>([]);

    React.useEffect(function loadPages() {
        if (!textTemplateHandler.data) return;
        setPageIds(textTemplateHandler.getPageIds())
        setTextTemplates(textTemplateHandler.data?.filter(t => !msgedItemIds?.includes(t?._id)).concat(textTemplateHandler.data?.filter(t => msgedItemIds?.includes(t?._id))).sort((a, b) => { return a.position - b.position }))
    }, [textTemplateHandler.data]);

    function onTextSelect(item: MediaPageItemSchema) {
        onSelectText && onSelectText(item)
        // userHandler.update({
        //     completedLevelItems: firebase.firestore.FieldValue.arrayUnion(item._id)
        // });
    }

    return <ScrollView style={{ flex: 1 }}>
        <ListHeader titleTx="Text Scripts" />
        {pageIds ? <Kitten.Select
            value={selectedIndex.row === 0 ? "All" : pages.find(p => p.index === selectedIndex.row - 1)?.name || "NA"}
            selectedIndex={selectedIndex}
            onSelect={(idx) => {
                setSelectedIndex(idx as IndexPath)
            }}
        >
            <>
                <Kitten.SelectItem title="All" />
                {pageIds.map((_pid, idx) => <PageSelectionItem pid={_pid} index={idx} onPageLoad={(page, index) => {
                    if (page._teamId !== teamId) {
                        // remove this page and its texts because it's from another team
                        setTextTemplates(textTemplates.filter(tmp => tmp.textTemplate?.parentPageId !== page._id))
                        setPageIds(pageIds.filter(pid => pid !== _pid))
                        return;
                    }
                    if (!pages.find(p => p._id === page._id)) setPages(pages.concat([{ ...page, index }]))
                }} />)}
            </>
        </Kitten.Select> : <Kitten.Spinner />}
        {textTemplates ? textTemplates.filter(tmp => pageIds?.includes(tmp.textTemplate?.parentPageId)).filter(tmp => selectedIndex.row === 0 || tmp.textTemplate?.parentPageId === pageIds[selectedIndex.row - 1]).map(item => <BlastTemplateItem allowRemoveFromSuggested={userHandler._isUserAdmin()} showGoToPageShortcut={userHandler._isUserAdmin()} item={item} onOverridePress={onTextSelect} blastText={msgedItemIds?.includes(item?._id) ? "Send again" : "Send"} iconRight={true} icon="send" complete={msgedItemIds?.includes(item?._id)} minimized={msgedItemIds?.includes(item?._id)} />) : <Kitten.Spinner />}
    </ScrollView>
}

function PageSelectionItem(p: { index: number, pid: string, onPageLoad: (page: MediaPageSchema, index: number) => void }) {
    const { index, pid, onPageLoad } = p;
    const pageHandler = usePageDocument(pid)

    React.useEffect(function gotPageName() {
        pageHandler.data && onPageLoad(pageHandler.data, index)
    }, [pageHandler.data]);

    return <Kitten.SelectItem title={pageHandler.data?.name || pid} {...p} />
}