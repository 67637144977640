import { Kitten, ListHeader, sstyled, Txt, Videolly } from "components";
import {
  Dimensions,
  KeyboardAvoidingView,
  SectionList,
  View
} from "react-native";
import { spacing } from "utils";

const { height: screenHeight, width: screenWidth } = Dimensions.get("window");

export const goldenRatio = 0.61;

export const SSBM = {
  KAV: sstyled(KeyboardAvoidingView)((p) => ({
    backgroundColor: "background",
    // paddingTop: spacing(1),
    alignItems: "center",
    flex: 1,
  })),
  Screen: sstyled(View)((p) => ({
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    maxWidth: Dimensions.get("window").width,
  })),
  MScreen: sstyled(Txt.H3)((p) => ({
    height: screenHeight * goldenRatio,
    width: screenWidth,
    padding: spacing(5),
    // fontSize: 40,
    textAlign: "center",
  })),

  QScreen: sstyled(Txt.S1)((p) => ({
    width: screenWidth,
    padding: spacing(4),
    fontSize: 18,
    textAlign: "left",
  })),
  VScreen: sstyled(View)((p) => ({
    width: screenWidth,
    padding: spacing(4),
    fontSize: 20,
    textAlign: "left",
  })),
  QualifScreen: sstyled(View)((p) => ({
    width: screenWidth,
    padding: spacing(4),
    fontSize: 20,
    textAlign: "left",
  })),
  NAVBtns: sstyled(View)((p) => ({
    flex: 1,
    flexDirection: "row",
  })),
  VideoThumbnail: sstyled(Videolly)({
    width: "95%",
    height: screenHeight * 0.3,
  }),
};

export const SSL = {
  CardHeader: sstyled(ListHeader)({
    height: 40,
  }),
  ContactList: sstyled(Kitten.ListItem)({
    marginBottom: 2,
    borderRadius: 10,
  }),
};

export const BMLL = {
  SectionList: sstyled(SectionList)({
    backgroundColor: "background",
  }),
};
