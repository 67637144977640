
import { DetailContact } from "engines";

/**
 * ###
 *  - This function receives both group data contact
 *   and normal contacts and then groups it all into one specific contact
 * to be usable by the email feature
 *  ----
 *  getRecipients(groupcontacts or recipients)
 *  returns ["drkhoi16@gmail.com", "paulnanle611@gmail.com", "mankar.saurabh@gmail.com", "nanleluke.e4e@gmail.com"]
 *  ----
 *  @version 21.08.04
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function getRecipientsEmail(newRecipients: any[]) {
  var arrayData: DetailContact[] = new Array();
  for (let index = 0; index < newRecipients.length; index++) {
    const element = newRecipients[index];
    const nanle: DetailContact = newRecipients[index];
    if (!element.groupContacts) {
      arrayData.push(nanle);
    } else {
      const groupContact = element.groupContacts;
      for (let j = 0; j < groupContact.length; j++) {
        const element = groupContact[j];
        arrayData.push(element);
      }
    }
  }

  var _reducedemail: string[] = arrayData.reduce((a, c) => [...a, c.email], []);

  return _reducedemail;
}

/**
 * ###
 *  - This function receives both group data contact
 *   and normal contacts and then groups it all into one specific contact
 * to be usable by the email feature
 *  ----
 *  getRecipients(groupcontacts or recipients)
 *  returns ["932789328932", "932789328932"]
 *  ----
 *  @version 21.08.04
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function getRecipientsPhoneNumbers(newRecipients: any[]) {
  var arrayData: DetailContact[] = new Array();
  for (let index = 0; index < newRecipients.length; index++) {
    const element = newRecipients[index];
    const nanle: DetailContact = newRecipients[index];
    if (!element.groupContacts) {
      arrayData.push(nanle);
    } else {
      const groupContact = element.groupContacts;
      for (let j = 0; j < groupContact.length; j++) {
        const element = groupContact[j];
        arrayData.push(element);
      }
    }
  }

  var _reducedemail: string[] = arrayData.reduce((a, c) => [c.displayName, c.phoneNumbers[0]], []);

  return _reducedemail;
}