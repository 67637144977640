import * as Contacts from "expo-contacts";
import { Platform } from "react-native";
import { androidPrecheckIfNeeded } from "../contacts-functions/fetchContacts";
import { isPermissionAllowed } from "./isPermissionAllowed";
export async function requestContactPermissions(): Promise<{
  isIOSContactAllowed?: boolean;
  /**
   * ### For android
   */
  isReadContactAllowed?: boolean;
  /**
   * ### For android
   */
  isWriteContactAllowed?: boolean;
}> {
  const keyword = "contacts";
  if (Platform.OS === "android") {
    await androidPrecheckIfNeeded();
    const isReadContactAllowedResult = await Contacts.requestPermissionsAsync();
    const isReadContactAllowed = isPermissionAllowed(
      isReadContactAllowedResult,
      keyword
    );

    const isWriteContactAllowedResult =
      await Contacts.requestPermissionsAsync();
    const isWriteContactAllowed = isPermissionAllowed(
      isWriteContactAllowedResult,
      keyword
    );
    return {
      isReadContactAllowed,
      isWriteContactAllowed,
    };
  } else if (Platform.OS === "ios") {
    const isIOSContactAllowed = await Contacts.requestPermissionsAsync();
    return {
      isIOSContactAllowed: isPermissionAllowed(isIOSContactAllowed, keyword),
    };
  }
  return null;
}
