import { IndexPath } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Avt, Kitten, sstyled, Txt } from "components";
import { Toasty } from "components/atoms";
import { useBreakpointIndex } from "dripsy";
import {
  fn,
  MediaPageSchema,
  URL_PREFIX,
  useAppContext,
  __MediaResourcesData,
} from "engines";
import { usePagesCollection } from "engines/firebase/handler/pages-handler";
import * as R from "ramda";
import React from "react";
import { View } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";

/**
 * ###
 *  - Set resource component
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.03
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function ResourceSelect({
  onChange,
  flexDirection,
  linkscustom,
  isLevel,
  createNewPageAllowed,
}: dLinkTo) {
  const { C, teamId } = useAppContext();
  // const Navigation = useNavigator();
  // const breakpoints = useBreakpointIndex();

  /**
   * If createNewPageAllowed:
   *  first position of item is 2
   * else:
   *  first position of item is 1
   */
  const indexOffset = createNewPageAllowed ? 2 : 1;

  //#region [FRBS]
  const pagesHandler = usePagesCollection();

  const [pages, setPages] = React.useState<MediaPageSchema[]>([]);
  const [autoSelectPageId, setAutoSelectPageId] = React.useState<string>(null);
  const autoSelectedPage =
    autoSelectPageId &&
    pagesHandler.data?.filter((_pg) => _pg._id === autoSelectPageId).pop();
  // var levelKeywords = ["Level", "Class", "Lesson", "Section", "Step", "New"];
  React.useEffect(
    function setupData() {
      pagesHandler.data &&
        setPages(
          pagesHandler.data?.filter((page) => {
            return true;
            // return isLevelPageV2(page.name);
          })
        );
    },
    [pagesHandler.data]
  );

  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    setQuery(value);
    const newData = pagesHandler.data?.filter((page) => {
      // page?.name?.toLowerCase()?.includes(value?.toLowerCase());
      return true;
      // return isLevelPageV2(page.name);
    });
    // console.log("valuee: ", newData);
    setPages([...newData]);
  }
  //#endregion

  //#region [section2] Create new page
  async function onCreateNewPage() {
    const __toast = Toasty.show(tr("Creating new subpage..."), {
      type: "loading",
    });
    console.log("creating new subpage with name: " + query);
    pagesHandler
      .add({
        ...__MediaResourcesData({
          teamId,
          pageName: query,
        }),
        mediaItem: { visible: false },
      })
      .then((id) => {
        setQuery("");
        setAutoSelectPageId(id);
        onChange &&
          onChange((isLevel ? URL_PREFIX.LEVEL : URL_PREFIX.PAGE) + id);
        // kittenSelect.current.props.onSelect(new IndexPath(indexOffset))
        Toasty.update(__toast, tr("New subpage created!"), {
          type: "success",
        });
      });
  }
  //#endregion

  let sortedPages = R.sortWith<MediaPageSchema>(
    [
      /** sorting `name` in `asc` */
      R.ascend(R.prop("name")),
    ],
    pages?.filter((page, index) => {
      /** If query is empty, show the first 5 items,
       * else filter the result based on query
       */
      return query === ""
        ? index <= 5
        : page?.name?.toLowerCase()?.includes(query?.toLowerCase());
    })
  );

  const [_selectedPageIndex, setSelectedPageIndex] =
    React.useState<IndexPath>(null);

  const selectedPageIndex = autoSelectedPage
    ? new IndexPath(sortedPages.indexOf(autoSelectedPage) + indexOffset)
    : _selectedPageIndex;

  //#region [section] on select functions
  const onSelect = React.useCallback(
    (index) => {
      console.log("[page_select] selected index: " + index);
      setQuery(sortedPages[index]?.name);
      if (createNewPageAllowed) {
        /**
         * For creating a new page
         */
        if (index?.row === 1) {
          onCreateNewPage();
        }

        index.row !== 1 && setSelectedPageIndex(index);
      } else {
        setSelectedPageIndex(index);
      }
      onChange &&
        onChange(
          (isLevel ? URL_PREFIX.LEVEL : URL_PREFIX.PAGE) +
          sortedPages[index]?._id,
          sortedPages[index]
        );
      // }

      setAutoSelectPageId(null);
    },
    [sortedPages]
  );
  //#endregion

  return React.useMemo(() => {
    try {
      if (pagesHandler.error)
        return <Txt.Indicator>{"Error: " + pagesHandler.error}</Txt.Indicator>;

      if (!pagesHandler.data)
        return <Txt.Indicator>{tr("Loading...")}</Txt.Indicator>;

      /**
       * We have to subtract 2 to get the correct item, this takes into account the search for pages input and the create new page button
       */
      const displayValue = selectedPageIndex
        ? sortedPages[selectedPageIndex.row - indexOffset]?.name
        : null;

      return (
        <Kitten.Autocomplete
          accessoryLeft={() => (
            <Avt
              name={sortedPages[selectedPageIndex]?.name || ""}
              source={{
                uri: sortedPages[selectedPageIndex]?.mediaItem?.logo || "",
              }}
              size="tiny"
            />
          )}
          placeholder="Enter Page Name"
          value={query}
          // value={displayValue}
          selectedIndex={selectedPageIndex}
          placement="bottom"
          // accessoryRight={renderCloseIcon}
          onChangeText={(value) => {
            setQuery(query);
            setSelectedPageIndex(null);
            filterHandler(value);
          }}
          onSelect={onSelect}
          style={{ flex: 1, flexGrow: 1 }}
        >
          {sortedPages?.map((page, index) => (
            <Kitten.AutocompleteItem
              key={index}
              title={page?.name}
              accessoryLeft={() => (
                <Avt
                  name={page.name || "K"}
                  source={{ uri: page.mediaItem?.logo || "" }}
                />
              )}
            />
          ))}
        </Kitten.Autocomplete>
      );

      return (
        <>
          {/* <ListHeader titleTx={tr("Link to")} /> */}
          <View>
            <Txt.Helper marginV>
              {tr("Search page's name or create a new one")}
            </Txt.Helper>
            <Kitten.ViewH>
              <Kitten.Select
                // ref={kittenSelect}
                style={{ flexGrow: 1 }}
                placeholder={tr("Select page")}
                value={displayValue}
                selectedIndex={selectedPageIndex}
                onSelect={onSelect}
              >
                <A.InputSearch
                  autoFocus={true}
                  value={query}
                  placeholder={tr("Type Page Name")}
                  onChangeText={(value) => {
                    setQuery(query);
                    setSelectedPageIndex(null);
                    filterHandler(value);
                  }}
                />
                {createNewPageAllowed && (
                  <Kitten.SelectItem
                    disabled={!query}
                    title={
                      query ? tr("Create a page name") + ' "' + query + '"' : ""
                    }
                    accessoryLeft={() =>
                      query ? (
                        <IconPrimr
                          name={"plus"}
                          preset={"circular"}
                          color={C.text}
                        />
                      ) : null
                    }
                  />
                )}

                {sortedPages?.map((page, index) => (
                  <Kitten.SelectItem
                    key={page._id}
                    title={page.name}
                    accessoryLeft={() => (
                      <Avt
                        name={page.name || "K"}
                        source={{ uri: page.mediaItem?.logo || "" }}
                      />
                    )}
                  />
                ))}
              </Kitten.Select>
            </Kitten.ViewH>
          </View>
        </>
      );
    } catch (error) {
      throw Error("::UmVzb3VyY2VTZWxlY3Q=::" + error);
    }
  }, [query, selectedPageIndex, pages]);
}

interface dLinkTo {
  /**
   * `link` will be either:
   *  - a url (start with http), or
   *  - a page id (start with page:PAGE_ID)
   * it'll be ALL store on url field of PageItemSchema,
   * since `toPage` field may contain page's name according to the old schema
   */
  onChange(link: string, resource?: MediaPageSchema): void;
  linkscustom?: string;
  flexDirection: "row" | "column";
  isLevel?: boolean;
  /** Should we allow create-new-page option? */
  createNewPageAllowed?: boolean;
}

const A = {
  InputSearch: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
};
