import { ListItemProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Avt, Kitten, Txt } from "components";
import { useAppContext } from "engines/hooks";
import React from "react";
import {
  Pressable, StyleSheet,
  TextStyle
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { moderateScale } from "utils";

export type SuggestionProps = ListItemProps & {
  label: string;
  textStyle?: TextStyle;
};

export function Suggestion({
  label,
  textStyle,
  onPress,
  ...props
}: SuggestionProps) {
  const appContext = useAppContext();
  // using touchable here as a workaround for android to pick up on taps for a view that has absolute position with a top
  return (
    <TouchableOpacity
      onPress={onPress}
      // onPressIn={onPress}
      style={{ zIndex: 100 }}
    >
      <Kitten.ListItemv2
        onPress={onPress}
        style={{ backgroundColor: "transparent" }}
        accessoryLeft={() => (
          <Avt
            // name={fn.js.extractString(["\(", "\)"])(label)[0]}
            name={String(label).split("•")[0]}
            // source={{ uri: "handlePosst.get()?.author?.avatar" }}
            size="small" //* or size={420}
          />
        )}
        {...props}
        title={String(label)}
        // title={JSON.stringify(
        //   fn.js.extractString(["\(", "\)"])(
        //     "this (is) some (text) that can be (extracted) with a (reusable) function"
        //   )
        // )}
      />
    </TouchableOpacity>
  );
  return (
    <Pressable
      style={[
        {
          ...styles.container,
          backgroundColor: appContext.C["color-info-transparent-500"],
          // borderColor: appContext.C["color-info-400"]
        },
      ]}
      {...props}
    >
      <Txt category={"s1"} style={[styles.label]}>
        {label}
      </Txt>
      <IconPrimr
        name="plus"
        size={moderateScale(14)}
        color={appContext.C.pitchWhite}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 7,
    paddingHorizontal: 7,
    backgroundColor: "white",
    borderColor: "#262626",
    // borderWidth: 0.5,
    borderRadius: 7,
    marginVertical: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    // color: "#262626",
    color: "white",
    fontWeight: "bold",
  },
});
