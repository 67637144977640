//#region [import]
import { useCollection } from "@nandorojo/swr-firestore";
import {
  Buttoon,
  CustomPageItem,
  dCustomPageItemProps,
  Kitten,
  MediaViews,
  sstyled,
  Toasty,
  Txt
} from "components";
import { useTwoColumns } from "components/templates";
import {
  Handler,
  ItemType,
  MediaPageItemSchema,
  MediaPageSchema,
  typeOfItem,
  URL_PREFIX,
  useNotificationCollection,
  UserSchema
} from "engines";
import {
  dUseDocumentReturns,
  FPATH,
  FRBS_ROLE,
  useMemberDocument,
  usePageContentsCollection,
  usePageDocument
} from "engines/firebase";
import { updateItem } from "engines/firebase/handler/page-contents-handler";
import { useAppContext } from "engines/hooks";
import firebase from "firebase";
import { median } from "ramda";
import React from "react";
import { Platform, ScrollViewProps, TextInput, View } from "react-native";
import Animated from "react-native-reanimated";
import { useNavigator } from "screens";
import { LevelCompletionTrophy } from "screens/trophy/LevelCompletionTrophy";
import { scale, spacing, tr } from "utils";
import { useCollabContext } from "../../../engines/providers/collaboration-provider";
import { Poppy } from "../../atoms/generals/poppy/Poppy";
//#endregion

/**
 * Will update the button item that took the user here if needed to get it's logo right
 */
export function updateButtonIfNeeded(
  buttonItem: MediaPageItemSchema,
  previousPageId: string,
  currentPage: MediaPageSchema,
  user?: UserSchema
) {
  if (
    buttonItem &&
    previousPageId &&
    currentPage?.mediaItem?.logo &&
    buttonItem?.thumbnail !== currentPage?.mediaItem?.logo
  ) {
    if (user?.roles?.includes(FRBS_ROLE.DEVELOPER)) {
      Toasty.show(`Updating button thumbnail with this page's image...`);
    }
    updateItem({
      cpid: previousPageId,
      data: { thumbnail: currentPage?.mediaItem?.logo },
      pcid: buttonItem._id,
    }).then(() => {
      if (user?.roles?.includes(FRBS_ROLE.DEVELOPER)) {
        Toasty.show(`Image updated.`, { type: "success" });
      }
    });
  }
}

/**
 * ### Custom page body
 *  - Containing a list of CustomPageItem
 *  ----
 *  @version 21.09.05
 *  -  *Memoize it*
 *  @author  K
 *
 **/
export function CustomPageBody(props: dCustomPageBody) {
  const {
    cpid,
    highlightItem,
    previousPageId,
    buttonItem,
    pageHandler: _pageHandler,
    collabMode,
    shouldEditContent,
    setisSelectShareModeOn,
    isSelectShareModeOn,
    isShareContents,
    setIsShareContents,
    isLevel,
    scrollHandler = () => { },
  } = props;

  const pageHandler = _pageHandler ? _pageHandler : usePageDocument(cpid);

  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();
  const refRenderCount = React.useRef(0);
  const { frbsUser, teamId } = useAppContext();
  const Navigation = useNavigator();
  const { sendLevelCompletionNotification } = useNotificationCollection(
    `${teamId}`
  );
  const [_sharedWithUids, setSharedWithUids] = React.useState<string[]>([]);
  // console.log("😎 props in CustomPageBody ", props.collabMode);
  //#region [section] handlers
  //const pageContentsHandler: dUseCollectionReturns<MediaPageItemSchema[]>
  const pageContentsHandler = usePageContentsCollection(cpid);
  let pageContentsD = pageContentsHandler.data; //* To filter all NaN position pages out. If user complains, we know where to find

  // const userProfile = useUserProfile();
  const memberHandler = useMemberDocument(frbsUser?.uid);
  //#endregion

  const {
    setIsCollabModeOn,
    isCollabModeOn,
    isSelectModeOn,
    selectAll,
    setSelectAll,
    /** for content multiverse */
    pageContentItems,
    setPageContentItems,
    setIsSelectModeOn,
  } = useCollabContext();

  //#region [ANM]
  // useFocusEffect(function layouting() {
  //   // console.log("resource cpid: ", cpid);
  //   //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  // });
  //#endregion

  React.useEffect(() => {
    pageHandler.data &&
      updateButtonIfNeeded(
        buttonItem,
        previousPageId,
        pageHandler.data,
        memberHandler.data
      );
  }, [pageHandler.data]);

  // const [isShareContents, setIsShareContents] = React.useState<
  //   MediaPageItemSchema[]
  // >([]);

  // console.log("isSelectShareModeOnNanle ", isSelectShareModeOn);
  //#region [section2] Select all ops
  React.useEffect(
    function setupSelectAll() {
      if (selectAll) {
        if (pageContentItems?.length !== pageContentsD?.length) {
          const tempPageContentIds: MediaPageItemSchema[] = [];
          pageContentsD?.forEach((item, index) => {
            if (
              item._id &&
              !tempPageContentIds.find((p) => p._id === item._id)
            ) {
              tempPageContentIds.push(item as MediaPageItemSchema);
            }
          });
          setPageContentItems(tempPageContentIds);
        }
      } else {
        if (
          !(
            pageContentItems?.length < pageContentsD?.length &&
            pageContentItems?.length > 0
          )
        )
          setPageContentItems([]);
      }
    },
    [selectAll]
  );

  React.useEffect(
    function () {
      if (pageContentItems?.length === pageContentsD?.length) {
        // setSelectAll(true);
      } else if (
        pageContentItems?.length < pageContentsD?.length &&
        pageContentItems?.length > 0
      ) {
        setSelectAll(false);
      }
    },
    [pageContentItems]
  );

  const refDidDisplayHighlightItem = React.useRef<boolean>(false);
  React.useEffect(
    function redirectToBaseShopIfNeeded() {
      // highlight item
      if (pageContentsD && highlightItem && !refDidDisplayHighlightItem.current) {
        refDidDisplayHighlightItem.current = true
        const _itemToHighlight: MediaPageItemSchema = pageContentsD.find(_item => _item?._id === highlightItem)
        if (!_itemToHighlight) {
          Toasty.show("The item you're look for could not be found. It may be removed.", { type: "warning" })
          return;
        }
        Navigation.dialogPortal.open({
          headerTitle: _itemToHighlight?.title,
          render: <CustomPageItem cpid={cpid} item={_itemToHighlight} pageContentsHandler={pageContentsHandler} pageHandler={pageHandler} memberHandler={memberHandler} pcid={cpid} />
        });
      }

      // redirect to base shop if this is a select base shop page
      if (memberHandler.data?.baseShopId) {
        for (let i in pageContentsHandler?.data) {
          const _pci = pageContentsHandler?.data[i];
          if (
            _pci?.url?.replace(`${URL_PREFIX.PAGE}`, "") ===
            memberHandler.data?.baseShopId
          ) {
            const baseShopName = _pci?.title;
            Poppy(
              "Continue Training",
              `Would you like to continue your training with ${baseShopName}?`,
              {
                text: "Not now",
                onPress: () => { },
              },
              {
                text: "Yes!",
                onPress: () => {
                  Navigation.navigate("BaseShops");
                },
              }
            );
          }
        }
      }
    },
    [memberHandler?.data, pageContentsHandler.data]
  );
  //#endregion

  //#region [section] Item Move functionalities
  const [movingModeEnabled, setMovingModeEnabled] =
    React.useState<boolean>(false);
  const [theArray, setTheArray] = React.useState([]);

  /** TODO brief description & make-sense name */
  const addEntryClick: dCustomPageItemProps["addEntryClick"] = async (item) => {
    setTheArray((oldArray) => [...oldArray, item]);
    if (theArray?.length === 1) {
      // console.log("pageContentsD ", pageContentsD);

      let newPosition = theArray[1];

      for (let i = 0; i < pageContentsD?.length; i++) {
        const _item = pageContentsD[i];
        if (_item.id == item.id) {
          const _previousItem = pageContentsD[i - 1];
          let _previousPosition =
            i === 0 ? pageContentsD[0].position - 1 : _previousItem.position;
          if (_item.position === _previousPosition) {
            _previousPosition -= 0.001;

            let datavalue = {
              cpid: cpid,
              pcid: _item.id,
              position: _previousPosition,
            };
            pageContentsHandler._updatePosition(datavalue);
          }
          newPosition = median([_item.position, _previousPosition]);
          break;
        }
      }

      let datavalue = {
        cpid: cpid,
        pcid: theArray[0].id,
        position: newPosition,
      };
      pageContentsHandler._updatePosition(datavalue);
      setTheArray([]);
      Toasty.show("Move complete!", { type: "success" });
    }
  };

  function onCancelMoveItem() {
    setTheArray([]);
    setMovingModeEnabled(false);
  }

  function toggleMovable() {
    setMovingModeEnabled(!movingModeEnabled);
    if (isCollabModeOn) {
      setMovingModeEnabled(false);
    }
  }
  //#endregion

  //#region [functions] MoveToLastPosition
  function moveToLastPosition() {
    // console.log(
    //   "pageContentsHandler._largestPositionIndex() ",
    //   pageContentsHandler._largestPositionIndex()
    // );

    // console.log("theArray lastpostion array", theArray);

    let datavalue = {
      cpid: cpid,
      pcid: theArray[0].id,
      position: pageContentsHandler._largestPositionIndex() + 1,
    };

    pageContentsHandler._updatePosition(datavalue);

    setTheArray([]);
    Toasty.show("Move complete!", { type: "success" });
    setMovingModeEnabled(false);
  }
  //#endregion
  React.useEffect(
    /**
     * In PageBodyScreen, there's `shouldEditContent` boolean.
     * -  If it's false, cancel all content editing tasks in this file
     */
    function editCotentControl() {
      !shouldEditContent && onCancelMoveItem();
    },
    [shouldEditContent]
  );
  React.useEffect(() => {
    // console.log("Collab mode is ", collabMode);
    // console.log("isCollabModeOn mode is ", isCollabModeOn);
    if (collabMode) {
      setIsCollabModeOn(collabMode);
    }
    // if (props["title"]) {
    //   Navigation.setOptions({ title: title });
    // }
  }, [collabMode]);

  const handleMembers = useCollection<UserSchema>(`${FPATH.USERS}`, {
    // This is used when ever there isn't a where clause in the props
    ...Handler.defaultSetup<UserSchema>(teamId),
  });

  let _members = handleMembers.data;

  const fieldTrainers = _members?.filter((u) =>
    memberHandler?.data?.growth?.listBuilder?.shareTo?.includes(u._id)
  );

  // console.log("fieldTrainers ", fieldTrainers);
  // React.useEffect(function setStates() {
  //   setSharedWithUids(shareData ? shareData : []);
  // }, []);

  // console.log("fieldTrainers ", fieldTrainers);

  const [completedLevelButtons, setCompletedLevelButtons] =
    React.useState<number>(0);
  const refDidMarkPageCompleted = React.useRef<boolean>(false);
  React.useEffect(
    function isAllLevelButtonsComplete() {
      if (pageContentsD?.length < 1 || refDidMarkPageCompleted.current === true)
        return;
      if (
        completedLevelButtons > 0 &&
        pageContentsD?.filter((i) => i.url?.startsWith(URL_PREFIX.LEVEL))
          .length === completedLevelButtons
      ) {
        // ALL LEVEL BUTTONS ON PAGE ARE COMPLETE
        refDidMarkPageCompleted.current = true;
        if (
          !memberHandler.data?.completedLevelParentPages ||
          !memberHandler.data?.completedLevelParentPages?.includes(cpid)
        ) {
          memberHandler.update({
            completedLevelParentPages:
              firebase.firestore.FieldValue.arrayUnion(cpid),
          });
        }
      }
    },
    [completedLevelButtons, pageContentsD]
  );

  function onReachedPageCompletion() {
    // send notification to field trainers about their progress
    sendLevelCompletionNotification(
      memberHandler?.data,
      pageHandler?.data.name,
      fieldTrainers
    );

    // present user with page's trophy if one is setup!
    pageHandler.data?.trophyId && pageHandler.data?.trophyId
      ? Navigation.dialogPortal.open({
        headerTitle: "Congratulations",
        render: <LevelCompletionTrophy pageHandler={pageHandler?.data} />,
      })
      : null;
  }

  const ViewFeedHeader = () => (
    <View>
      <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>
      {movingModeEnabled ? (
        <Kitten.ViewH style={{ justifyContent: "space-between" }}>
          <Txt status="info" style={{ marginBottom: spacing(3) }}>
            {tr("Select an item to place it on the top")}
          </Txt>

          <Buttoon
            size="small"
            status="info"
            appearance="ghost"
            onPress={onCancelMoveItem}
          >
            {tr("Cancel")}
          </Buttoon>
        </Kitten.ViewH>
      ) : (
        <View style={[{ marginBottom: spacing(3), flexDirection: "row" }]}>
          {isSelectModeOn && (
            <A.ViewCheckbox>
              <Buttoon
                appearance={"ghost"}
                size={"tiny"}
                style={{}}
                onPress={() => {
                  selectAll ? setSelectAll(false) : setSelectAll(true);
                }}
              >
                {selectAll || pageContentItems?.length === pageContentsD?.length
                  ? tr("Unselect All")
                  : tr("Select All")}
              </Buttoon>
              {/* <Kitten.CheckBox
                    checked={
                      selectAll ||
                      pageContentItems.length ===
                        pageContentsD.length
                    }
                    onChange={() => {
                      setSelectAll(!selectAll);
                    }}
                  /> */}
            </A.ViewCheckbox>
          )}
        </View>
      )}
    </View>
  );

  return React.useMemo(() => {
    try {
      // return (
      //   <View>
      //     <Txt>Is loading: {JSON.stringify(pageContentsHandler.loading)}</Txt>
      //     <Txt># of contents: {JSON.stringify(pageContentsD?.length)}</Txt>

      //   </View>
      // );
      return (
        <Animated.FlatList
          scrollEventThrottle={16}
          onScroll={scrollHandler}
          contentContainerStyle={[
            column1ContainerStyle,
            { marginVertical: spacing(2), minWidth: 300 },
          ]}
          ListHeaderComponent={ViewFeedHeader()}
          ListEmptyComponent={() => (
            <Txt.Indicator marginV>
              {pageContentsHandler.loading
                ? tr("Loading...")
                : tr("This page is empty")}
            </Txt.Indicator>
          )}
          ListFooterComponent={() => (
            <View>
              {
                //#region [section]
                /** * Show this when:
                 * -  editMode is enabled,
                 * -  the item is the LAST one on the list
                 */
                movingModeEnabled && (
                  <A.PressableMoveToPlaceholder
                    status="basic"
                    size="large"
                    appearance="outline"
                    onPress={() => {
                      moveToLastPosition();
                    }}
                  >
                    {tr("Move item here *")}
                  </A.PressableMoveToPlaceholder>
                )
                //#endregion
              }
              <ViewCol1BottomSpace />
            </View>
          )}
          data={pageContentsD}
          renderItem={({ item, index }) => (
            <>
              <A.ViewCheckbox />
              <CustomPageItem
                key={item?._id}
                onPress={(item, _levelProgress) => {
                  if (item?.url?.startsWith(URL_PREFIX.LEVEL)) {
                    let levelPageId = item?.url?.replace(URL_PREFIX.LEVEL, "");
                    /** set to next level page if this one is 100% now */
                    // if (_levelProgress >= 1) {
                    //   for (let i = 0; i < pageContentItems.length; i++) {
                    //     const _item: MediaPageItemSchema = pageContentItems[i];
                    //     if (
                    //       _item?._id !== levelPageId &&
                    //       _item?.url?.startsWith(URL_PREFIX.LEVEL)
                    //     ) {
                    //       levelPageId = _item?._id;
                    //       break;
                    //     }
                    //   }
                    // }
                    if (
                      memberHandler.data?.lastParentLevelPage !== levelPageId &&
                      _levelProgress < 1
                    ) {
                      memberHandler.update({
                        // lastLevelPage: levelPageId,
                        lastParentLevelPage: cpid,
                      });
                    }
                  }
                }}
                lockUntilTrainerSelect={memberHandler.data?.growth?.listBuilder?.shareTo?.length > 0 ? false : pageContentsD.find((_i: MediaPageItemSchema) => _i.pickTrainer?.required === true && _i.position < item.position) ? true : false}
                onLevelProgress={(_item, _levelProgress) => {
                  if (_levelProgress)
                    setCompletedLevelButtons(completedLevelButtons + 1);
                }}
                memberHandler={memberHandler}
                // configKeysHandler={configKeysHandler}
                // levelPageProgress={levelPageProgress}
                // onCompleteLevelItem={completeLevelItem}
                pageHandler={pageHandler}
                pageContentsHandler={pageContentsHandler}
                onReachedPageCompletion={onReachedPageCompletion}
                cpid={cpid}
                pcid={item?._id}
                item={item}
                isLevel={isLevel}
                index={index}
                movingModeEnabled={isCollabModeOn ? false : movingModeEnabled}
                addEntryClick={addEntryClick}
                onEditCallback={() => {
                  toggleMovable();
                }}
                // collabMode={collabMode}
                shouldEditContent={shouldEditContent}
                isSelectShareModeOn={isSelectShareModeOn}
                setIsShareContents={setIsShareContents}
                isShareContents={isShareContents}
                rolesThatCanEdit={pageHandler.data?.rolesThatCanEdit || []}
              />
            </>
          )}
          keyExtractor={(item) => item._id}
        />
      );
    } catch (error) {
      throw Error(`::Q3VzdG9tUGFnZUJvZHk=::${error}`);
    }
  }, [
    pageContentsD,
    movingModeEnabled,
    addEntryClick,
    isSelectModeOn,
    isSelectShareModeOn,
    selectAll,
    pageContentItems,
    isCollabModeOn,
    collabMode,
    column1ContainerStyle,
    onReachedPageCompletion,
  ]);
}

const A = {
  ViewContainer: sstyled(View)({ minWidth: 300 }),
  ViewCheckbox: sstyled(View)({
    marginRight: spacing(3),
    position: "absolute",
    left: -scale(90),
    height: "100%",
    justifyContent: "center",
    transform: [{ scale: 1.166 }],
  }),
  InputTitle: sstyled(TextInput)({
    color: "text",
    borderRadius: 1, //* To create a "bordered" placeholder when text is being loaded
    fontSize: scale(30),
    // fontFamily: "Inter_800ExtraBold"
    fontWeight: Platform.select({ web: "800", ios: "800", android: "bold" }),
  }),
  PressableMoveToPlaceholder: sstyled(Buttoon)({
    width: "100%",
    height: scale(50),
    borderColor: "infoBlue",
    borderWidth: 2,
    borderStyle: "dotted",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: spacing(3),
  }),
};

export interface dCustomPageBody {
  cpid: string;
  highlightItem?: string;
  /**
   * If not provided, a new page handler will be setup automatically with the cpid
   * - If you already have a page handler open for this page, it is recommended to use it again by passing it along here to avoid significantly less reads
   */
  pageHandler?: dUseDocumentReturns<MediaPageSchema>;
  selectModeOn?: boolean;
  /** @deprecated set this inside of the collab files */
  collabMode?: boolean;
  shouldEditContent?: boolean;
  isSelectShareModeOn?: boolean;
  setisSelectShareModeOn?: (value: boolean) => void;
  isShareContents?: MediaPageItemSchema[];
  setIsShareContents?: (item) => void;
  title?: string;
  scrollHandler?: ScrollViewProps["onScroll"];
  /**
   * The previous custom page the user was on (if applicable)
   */
  previousPageId?: string;
  /**
   * The button that navigated the user to this page (if exists)
   */
  buttonItem?: MediaPageItemSchema;
  isLevel?: boolean;
}
