import {
  Buttoon,
  Kitten,
  MembersMultiInput,
  Poppy,
  RECIPIENT_PHONE_PLACEHOLDER,
  sstyled,
  Toasty,
  Txt,
} from "components/";
import {
  ACTION_TYPE,
  fn,
  FPATH,
  MediaPageItemSchema,
  recordUnknownUserAction,
  URL_PREFIX,
  useAppContext,
  UserSchema,
} from "engines";
import {
  ContactGroupSchemaa,
  ContactSchemaa,
} from "engines/backends/schemas/bml";
import Contacts from "expo-contacts";
import * as SMS from "expo-sms";
import firebase from "firebase";
import React from "react";
import {
  Alert,
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputSelectionChangeEventData,
  View,
} from "react-native";
import { TextTemplatesScreen, useNavigator } from "screens";
import { IS_WEB, spacing, tr } from "utils";
// import { NavigationStackScreenProps } from "react-navigation-stack";
// import { C, moderateScale, spacing } from "utilities/";
import { completeFollowUpOnContact } from "../../../engines/firebase/handler/contact-groups-handler";
import { recordMessageSent } from "../members/contact-details-components/QuickActions";
import { FieldTrainerSelection } from "./FieldTrainerSelection";

export function BlastSmsComposer(props: P) {
  const {
    listData,
    contacts,
    skipTo,
    fieldTrainerNumber,
    defaultBlastBody,
    trackableVideoItem,
  } = props;
  const { C, frbsUser, teamId } = useAppContext();

  const refBlastInput = React.useRef<TextInput>(null);
  const [newRecipients, setNewRecipients] = React.useState<
    (ContactSchemaa | ContactGroupSchemaa)[]
  >([]);
  const [_contacts, set_contacts] = React.useState<
    (ContactSchemaa | ContactGroupSchemaa)[]
  >([]);

  // console.log("_contacts ", _contacts);
  React.useEffect(
    /**
     * Get contacts from custom contact list
     */
    function fetchContacts() {
      loadContacts();
    },
    [newRecipients]
  );

  /**
   * Value contains message body
   */
  const [_blastBody, setBlastBody] = React.useState(
    defaultBlastBody || fn.js.shareItemsToPrettyMessage(listData)
  );

  /**
   * Position of cusor in the input field
   */
  const [caret, setCaret] = React.useState({
    /**
     * Position of cusor in the input field
     */
    start: 0,
    end: 0,
  });

  const Navigation = useNavigator();
  const [includeFieldTrainer, setIncludeFieldTrainer] =
    React.useState<UserSchema>(null);

  /**
   * Use counter to get each contact details in the list
   */
  const counter = React.useRef(0);

  React.useEffect(
    function setSkipToIfNeeded() {
      if (skipTo) counter.current = contacts?.indexOf(skipTo) || 0;
    },
    [skipTo]
  );

  function handleSelect(
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>
  ) {
    setCaret({
      start: e.nativeEvent.selection.start,
      end: e.nativeEvent.selection.end,
    });
  }

  /** Calling to this function you can insert text in the cursor position */
  function insertText(text: string) {
    /**
     * Adds text to cusor position
     */

    setBlastBody(
      _blastBody.substring(0, caret.start) +
      text +
      _blastBody.substring(caret.end, _blastBody?.length)
    );
  }

  /**
   * Get contacts and set it to state
   */
  async function loadContacts() {
    console.log("🧑‍🚒🧑‍🚒🧑‍🚒🧑‍🚒 newRecipients ", newRecipients);
    set_contacts(await _fetchAllRecipients());
  }

  function getCurrentContact(): ContactSchemaa {
    return (
      _contacts?.length > 0 &&
      counter?.current <= _contacts?.length - 1 &&
      (_contacts[counter.current] as ContactSchemaa)
    );
  }
  function nameOfContact(contact: ContactSchemaa): string {
    return contact?.name || contact?.displayName || "";
  }
  function choosePhoneNumberForContact(__contact: ContactSchemaa): string {
    return __contact?.phoneNumbers &&
      (__contact.phoneNumbers as Contacts.PhoneNumber[])?.length > 0
      ? typeof __contact.phoneNumbers[0] === "string"
        ? __contact.phoneNumbers[0]
        : __contact.phoneNumbers[0]?.digits ||
        __contact.phoneNumbers[0]?.number?.replace(/\D/g, "")
      : null;
  }

  /**
   * This contains send message function
   * Skip and Cancel logic
   */
  async function sendMessage() {
    const currentContact = getCurrentContact();
    const currentContactName = nameOfContact(currentContact);
    const currentContactPhoneNumber =
      choosePhoneNumberForContact(currentContact);
    // console.log("Contacts length", _contacts);
    if (counter.current === _contacts?.length) {
      counter.current = 0;
      if (trackableVideoItem) {
        recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.BLAST_TEXT, `${frbsUser?.uid} blast texted ${trackableVideoItem.title} to ${_contacts.length} contacts.`, { location: trackableVideoItem._parentPageId ? `${URL_PREFIX.PAGE}${trackableVideoItem._parentPageId}/${trackableVideoItem._id}` : trackableVideoItem._id, involvedPartyName: _blastBody })
      } else {
        recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.BLAST_TEXT, `${frbsUser?.uid} blast texted ${_contacts.length} contacts.`, { involvedPartyName: _blastBody })
      }
      Toasty.show("All done! Great job!", { type: "success" });
      loadContacts();
      return;
    }

    // console.log("🧑‍🚒🧑‍🚒🧑‍🚒🧑‍🚒 type of type ", typeof _contacts[0]?.phoneNumbers[0]);
    console.log("🧑‍🚒🧑‍🚒🧑‍🚒🧑‍🚒  counter number ", counter.current);
    // console.log("🧑‍🚒🧑‍🚒🧑‍🚒🧑‍🚒 type of data ", _contacts[0]?.phoneNumbers[0]);
    // console.log("send message function got here 2 ", _contacts);
    /**
     * searches through message and replace this 🌀 with the contact name
     */
    const regexPhonePlaceholder = new RegExp(RECIPIENT_PHONE_PLACEHOLDER, "g");
    const messagefinal = _blastBody
      .replace(/🌀/g, currentContactName?.split(" ")[0])
      .replace(regexPhonePlaceholder, currentContactPhoneNumber); // takes out all non number characters from the phone number so it's just the phone number itself
    console.log(
      `Replacing ${RECIPIENT_PHONE_PLACEHOLDER} with ${currentContactPhoneNumber}`
    );

    async function invalidNumberAlert(): Promise<any> {
      return new Promise((resolve, reject) => {
        Alert.alert(currentContactName, `No valid phone number found.`, [
          { text: "Continue", onPress: resolve },
        ]);
      });
    }

    /** will create the group if it doesn't exist (intended for trackable video use case) */
    async function updateDefaultGroup(
      groupId: string,
      groupName: string,
      contactIds: string[]
    ) {
      //@ts-ignore arrayUnion
      return firebase
        .firestore()
        .doc(
          `${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACT_GROUPS}/${groupId}`
        )
        .set(
          {
            _id: groupId,
            contacts: firebase.firestore.FieldValue.arrayUnion(...contactIds),
            name: groupName,
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          } as ContactGroupSchemaa,
          { merge: true }
        );
    }

    function skipToNext() {
      set_contacts(
        _contacts
          .slice(0, counter.current)
          .concat(_contacts.slice(counter.current + 1, _contacts?.length))
      );

      counter.current = counter.current + 1;

      sendMessage();
    }
    // obongjyar
    if (
      // typeof _contacts[counter.current]?.phoneNumbers[0] === "object"
      //   ? _contacts[counter.current]?.phoneNumbers[0]?.digits?.length <= 0
      //   : _contacts[counter.current]?.phoneNumbers[0]?.length <= 0
      //@ts-ignore potential old contact schema data
      !currentContact.phoneNumber &&
      currentContact?.phoneNumbers?.length < 1
    ) {
      Toasty.show(
        `${currentContactName} does not have a phone number. Skipping...`,
        {
          type: "warning",
        }
      );

      return skipToNext();
    } else {
      try {
        const isAvailable = await SMS.isAvailableAsync();
        if (isAvailable) {
          try {
            //@ts-ignore potential old schemaa needed
            const phoneNumber = currentContactPhoneNumber;
            const isPhoneNumberInvalid = !phoneNumber || phoneNumber.length < 1;
            if (isPhoneNumberInvalid) {
              await invalidNumberAlert();
            }

            const { result } = !isPhoneNumberInvalid
              ? await SMS.sendSMSAsync(
                includeFieldTrainer
                  ? [phoneNumber, includeFieldTrainer?.personali?.phoneNumber]
                  : phoneNumber,
                messagefinal
              )
              : { result: "sent" };

            // TRACKABLE VIDEO BEING SENT OUT
            if (messagefinal.includes("/content-item/") || trackableVideoItem) {
              const trackableUrl = messagefinal.match(/https?:\/\/[^\s]+/)[0];
              const pageId =
                trackableVideoItem._parentPageId || trackableUrl?.split("/")[4];
              const itemId =
                trackableVideoItem._id ||
                trackableUrl?.split("/")[5].split("?")[0];
              const videoWatch = {
                _teamId: teamId,
                itemId,
                pageId,
                recipientPhoneNumber: currentContactPhoneNumber,
                sharerUid: frbsUser?.uid,
                title: trackableVideoItem.title || "Trackable video",
                uid: "anonymous",
                url: trackableVideoItem?.media || "",
                watched: 0,
                startedAt: null,
                sentAt: firebase.firestore.Timestamp.fromDate(new Date()),
              };

              // if this contact definitely exists in the app already
              if (
                currentContact.groups &&
                (currentContact.groups as string[])?.length > 0
              ) {
                console.log(
                  "Contact already has a group therefore must be in firestore already, recording videoWatch to pre-existing contact"
                );
                // fields like url and title should get updated automatically later when the video is actually watched
                recordMessageSent(
                  currentContact,
                  "sms",
                  messagefinal,
                  {
                    videoWatch,
                    ...(fieldTrainerNumber ? { groupText: { phoneNumber: fieldTrainerNumber } } : {})
                  }
                );
              }
              // unsure if the contact exists in firestore or not yet...
              else {
                const contactsPath = `${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}`;
                const _phoneNumberStrings = (
                  currentContact?.phoneNumbers as Contacts.PhoneNumber[]
                )?.map((pn) => pn.digits || pn.number?.replace(/\D/g, ""));
                // try to find a pre-existing contact for this person
                console.log(
                  "Finding potential contacts with " + currentContactPhoneNumber
                );
                firebase
                  .firestore()
                  .collection(`${contactsPath}`)
                  .where(
                    "_phoneNumberStrings",
                    "array-contains",
                    currentContactPhoneNumber
                  )
                  .get()
                  .then(async (query) => {
                    const potentialContacts = query.docs?.map(
                      (d) => d.data() as ContactSchemaa
                    );
                    console.log(
                      `Found ${potentialContacts.length} potential contacts`
                    );
                    //
                    // const finalVideoWatchesValue = _previousVideoWatchIds.includes(videoWatch.itemId) ? currentContact?.videoWatches?.map(vw => ({ ...vw, ...videoWatch, watched: vw.watched })) : firebase.firestore.FieldValue.arrayUnion(videoWatch)
                    const updatedInfoForContact: Partial<ContactSchemaa> = {
                      //@ts-ignore arrayUnion
                      _phoneNumberStrings,
                      videoWatches:
                        firebase.firestore.FieldValue.arrayUnion(videoWatch),
                      messageHistory: firebase.firestore.FieldValue.arrayUnion({
                        date: firebase.firestore.Timestamp.fromDate(new Date()),
                        itemId,
                        type: "trackable-video",
                        message: messagefinal,
                      }),
                    };

                    // get group id to add to (generate if it doesn't exist yet)
                    let contactGroupId =
                      trackableVideoItem?._id || "video-tracking-contacts"; // TODO: set to the actual video details
                    let contactGroupName = `${trackableVideoItem?.title
                      ? `New Contacts for ${trackableVideoItem.title}`
                      : "New Video Tracking Contacts"
                      }`;

                    // no contacts with phone # exist, create a contact and add to a list (or if it exists without _phoneNumberStrings, use the recordID to make it and it should be ok with merge: true)
                    if (potentialContacts?.length < 1) {
                      // @ts-ignore _id comes from MembersMultiInput and is necessary for getting a recordID
                      let _contactId =
                        currentContact._id ||
                        currentContact._cid ||
                        currentContact.id;
                      console.log("Setting up group for contact...");
                      await updateDefaultGroup(
                        contactGroupId,
                        contactGroupName,
                        [_contactId]
                      ).catch((err) => {
                        console.error(
                          `Failed to setup group ${contactGroupId} with error: ${err}`
                        );
                        Toasty.show(
                          "Failed to create group " + contactGroupId,
                          { type: "danger" }
                        );
                      });
                      // create new contact
                      console.log(
                        `Creating/updating contact for ${currentContactName}`
                      );
                      firebase
                        .firestore()
                        .doc(`${contactsPath}/${_contactId}`)
                        .set(
                          {
                            ...currentContact,
                            groups:
                              firebase.firestore.FieldValue.arrayUnion(
                                contactGroupId
                              ),
                            ...updatedInfoForContact,
                          } as ContactSchemaa,
                          { merge: true }
                        )
                        .catch((err) => {
                          console.error(
                            `Failed to setup contact for ${currentContactName} : ${err}`
                          );
                          Toasty.show(
                            `Failed to create/update contact for ${currentContactName}`,
                            { type: "danger" }
                          );
                        });
                      console.log(
                        `Created new contact for ${currentContact.name || currentContact.displayName
                        }`
                      );
                    }
                    // contacts with phone # found, therefore update pre existing contacts that were identified
                    else {
                      for (let p in potentialContacts) {
                        const _potentialContact = potentialContacts[p];
                        const _previousVideoWatchIds: string[] = (
                          _potentialContact?.videoWatches || []
                        )?.map((vw) => vw.itemId);
                        // should 100% have a _cid but if for an odd reason it doesn't we put in the ._id and .id to use the contacts recordID
                        firebase
                          .firestore()
                          .doc(
                            `${contactsPath}/${_potentialContact._cid ||
                            _potentialContact._id ||
                            _potentialContact.id
                            }`
                          )
                          .update({
                            ...updatedInfoForContact,
                            videoWatches: _previousVideoWatchIds.includes(
                              videoWatch.itemId
                            )
                              ? _potentialContact.videoWatches?.map((vw) =>
                                vw.itemId === videoWatch.itemId
                                  ? {
                                    ...vw,
                                    ...videoWatch,
                                    watched: vw.watched,
                                  }
                                  : vw
                              )
                              : firebase.firestore.FieldValue.arrayUnion(
                                videoWatch
                              ),
                            groups:
                              firebase.firestore.FieldValue.arrayUnion(
                                contactGroupId
                              ),
                          } as Partial<ContactSchemaa>);
                      }
                      console.log(
                        `updated ${potentialContacts.length} potential contacts for ${currentContactPhoneNumber} ${currentContactName}`
                      );
                    }
                  })
                  .catch((err) => {
                    Toasty.show(
                      `Failed to get potential contacts for ${currentContactName}`,
                      { type: "danger" }
                    );
                    console.error(`Error getting contact in hitemupSms ${err}`);
                  });
              }
            } else {
              // if regular message being sent
              recordMessageSent(currentContact, "sms", messagefinal, { ...(fieldTrainerNumber ? { groupText: { phoneNumber: fieldTrainerNumber } } : {}) });
            }

            if (result == "unknown" && Platform.OS === "ios") {
              // console.log(result);
              Toasty.show("Something went wrong.", {
                type: "danger",
              });
            }
            // android always gives unknown result (see expo documentation)
            if (
              result == "sent" ||
              (Platform.OS === "android" && result === "unknown")
            ) {
              // when sent successfully
              // mark contact follow up as complete if needed
              currentContact.followUpDate &&
                completeFollowUpOnContact(frbsUser?.uid, currentContact._cid);
              set_contacts(
                _contacts
                  .slice(0, counter.current)
                  .concat(
                    _contacts.slice(counter.current + 1, _contacts?.length)
                  )
              );

              counter.current = counter.current + 1;

              /**
               * ALL MESSAGES HAVE BEEN SENT
               */
              if (counter.current === _contacts?.length) {
                Toasty.show("All messages have been sent!", {
                  type: "success",
                });
                counter.current = 0;
                loadContacts();
                return;
              } else {
                sendMessage();
              }
            }
            if (
              result == "cancelled" &&
              counter.current < _contacts.length - 1
            ) {
              Alert.alert(
                "Skip or cancel?",
                "Skipping will take you to the next contact.",
                [
                  {
                    text: "Skip",
                    onPress: () => {
                      skipToNext();
                    },
                  },
                  {
                    text: "Cancel",
                    onPress: () => {
                      loadContacts();
                    },
                    style: "cancel",
                  },
                ],
                { cancelable: false }
              );

              return set_contacts(_contacts.slice(counter.current));

              // console.log("SMS Sent Cancelled");
            }
          } catch (err) {
            alert(err);
          }
        } else {
          return Toasty.show("Sorry this feature isn't available for web", {
            type: "warning",
          });
        }
      } catch (error) {
        console.log("Failed to send SMS. Are you a simulator?");
        return Toasty.show("Something went wrong. " + error, {
          type: "danger",
        });
      }
    }
  }

  /** Includes contacts and contact groups as one big contacts list (has to load contacts from groups first) */
  async function _fetchAllRecipients(): Promise<ContactSchemaa[]> {
    let __allcontacts: ContactSchemaa[] = [];
    for (let n in newRecipients) {
      const recip = newRecipients[n];
      // if this recipient is a group list
      if ((recip as ContactGroupSchemaa)?.contacts) {
        // const _groupContactIds = (recip as ContactGroupSchemaa)?.contacts;
        // (recip as ContactSchemaa).groups;
        // fetch contacts from this contact group
        const __contactsFromGroup: ContactSchemaa[] = (
          await firebase
            .firestore()
            .collection(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}`)
            .where(
              "groups",
              "array-contains",
              (recip as ContactGroupSchemaa)._id
            )
            .get()
        ).docs.map((_doc) => _doc.data() as ContactSchemaa);
        // add those contacts to the list of all contacts
        __allcontacts = __allcontacts.concat(__contactsFromGroup);
      } else {
        // add the single contact to the list
        __allcontacts.push(recip as ContactSchemaa);
      }
    }
    // add in contacts provided in the contacts prop if any (but make sure there is no duplicates from contact groups)
    const allcontact_strngs: string[] = __allcontacts.map((_c) => _c._cid);
    const nonDuplicates = (contacts || [])?.filter(
      (c) => !allcontact_strngs?.includes(c?._cid)
    );
    return __allcontacts.concat(nonDuplicates);
  }
  function _getFullRecipientCount() {
    let __count = 0;
    for (let n in newRecipients) {
      const recip = newRecipients[n];
      if ((recip as ContactGroupSchemaa)?.contacts)
        __count += (recip as ContactGroupSchemaa)?.contacts?.length;
      else __count += 1;
    }
    return __count;
  }
  let fullRecipientCount = _getFullRecipientCount();

  try {
    return (
      <A.Ctnr>
        <A.CtnrContent>
          {contacts?.length > 0 ? null : (
            <MembersMultiInput
              title={tr("To: ")}
              mode="sms"
              onRecipientsCallback={setNewRecipients}
            // onDoneInputCallback={() => refBlastInput.current?.focus()}
            />
          )}
          <Kitten.Divider_________ />
          <Kitten.Input
            ref={refBlastInput}
            multiline={true}
            value={_blastBody}
            textStyle={{ minHeight: 108 }}
            placeholder="Type your message..."
            placeholderTextColor={C.grey600}
            defaultValue={_blastBody}
            onChangeText={setBlastBody}
            onSelectionChange={(e) => handleSelect(e)}
            scrollEnabled={false}
          />
          <Txt.Indicator marginV>Preset fields</Txt.Indicator>
          <Kitten.ButtonGroup
            size="small"
            appearance="outline"
            status="basic"
            style={{ alignSelf: "center" }}
          >
            <Kitten.Button onPress={() => insertText("🌀")}>
              {"🌀 Recipient's Name"}
            </Kitten.Button>
            <Kitten.Button
              onPress={() => {
                Navigation.dialogPortal.open({
                  headerTitle: "Text Templates",
                  render: (
                    <TextTemplatesScreen
                      route={{
                        params: {
                          // contact: ,
                          onSelectText: async (
                            selectedItem: MediaPageItemSchema
                          ) => {
                            Navigation.goBack();
                            const _msg = selectedItem.title;
                            if (IS_WEB) {
                              Toasty.show("Not available on web.", {
                                type: "warning",
                              });
                            } else {
                              setBlastBody(_msg);
                            }
                          },
                        },
                      }}
                    />
                  ),
                });
              }}
            >
              {tr(`Text Scripts 💬`)}
            </Kitten.Button>
            {/* <Buttoon onPress={() => insertText("{name}")}>{`{ phone } `}</Buttoon> */}
          </Kitten.ButtonGroup>

          <Kitten.Divider_________ />
          <Buttoon
            status={C.primary}
            style={{ marginTop: spacing(4) }}
            disabled={
              newRecipients.length > 0 || contacts?.length > 0 ? false : true
            } //_contacts?.length > 0 ||
            icon={{ name: "bullhorn" }}
            onPress={() => sendMessage()}
          >
            {tr(`Send ${fullRecipientCount || contacts?.length || 0} messages`)}
          </Buttoon>
          <Buttoon
            status={includeFieldTrainer ? "info" : "basic"}
            size="small"
            appearance="outline"
            icon={{ name: "admin" }}
            style={{ marginTop: spacing(4), marginBottom: spacing(5) * 2 }}
            onPress={() => {
              if (includeFieldTrainer) {
                setIncludeFieldTrainer(null);
              } else {
                Navigation.dialogPortal.open({
                  headerTitle: "Include Field Trainer",
                  render: (
                    <FieldTrainerSelection
                      onSelect={(user) => {
                        if (
                          !user?.personali?.phoneNumber ||
                          user?.personali?.phoneNumber?.length < 1
                        ) {
                          Poppy(
                            "Field Trainer does not have a phone number",
                            ""
                          );
                        } else {
                          setIncludeFieldTrainer(user);
                        }
                      }}
                    />
                  ),
                });
              }
            }}
          >
            {includeFieldTrainer
              ? `Will include ${includeFieldTrainer.personali?.displayName} (tap to remove)`
              : "Include Field Trainer"}
          </Buttoon>
        </A.CtnrContent>
      </A.Ctnr>
    );
  } catch (error) {
    throw Error(`:: aGl0ZW11cFNtcw ==::${error} `);
  }
}
// }

const A = {
  Ctnr: sstyled(View)((p) => ({
    flex: 1,
    width: `100%`,
  })),
  CtnrContent: sstyled(View)((p) => ({
    // paddingVertical: spacing(2),
    // paddingHorizontal: spacing(4),
    // justifyContent: "flex-start",
  })),
};

interface P {
  listData?: MediaPageItemSchema[];
  shareMode?: boolean;
  contacts?: ContactSchemaa[];
  /**
   * Start a blast text from the provided contact instead of the beginning
   */
  skipTo?: ContactSchemaa;
  fieldTrainerNumber?: string;
  defaultBlastBody?: string;
  /** so we have the item details and know that a trackable video item is intended to be shared */
  trackableVideoItem?: MediaPageItemSchema;
}
