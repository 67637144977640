import firebase from "firebase"
import { Buttoon, Kitten, sstyled, Toasty, Txt } from "components";
import {
    FPATH,
    MediaPageItemSchema,
    __ResourceItemData
} from "engines";
import { usePageContentsCollection } from "engines/firebase/handler/page-contents-handler";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";

export function PickTrainerButtonCreator({
    cpid,
    editItem,
}: P) {
    const Navigation = useNavigator();
    //#region [FRBS] page contents handler
    const pageContentsHandler = usePageContentsCollection(cpid);
    //#endregion

    const [required, setRequired] = React.useState<boolean>(editItem ? editItem.pickTrainer?.required : false);

    function createItem(xong) {
        pageContentsHandler.add({
            ...__ResourceItemData(),
            position: pageContentsHandler._largestPositionIndex() + 1, //* Latest position on the ContentBody
            title: "Select Your Field Trainer",
            pickTrainer: {
                required
            }
        });
        setTimeout(() => {
            Toasty.show(tr("Select Trainer button added!"));
            xong && xong();
        }, 690);
    }
    async function updateItem() {
        try {
            const __toasty = Toasty.show("Updating...", { type: "loading" })
            await firebase.firestore().doc(`${FPATH.PAGES}/${cpid}/${FPATH.CUSTOM_PAGE_CONTENT}/${editItem._id}`).update({
                pickTrainer: {
                    ...editItem.pickTrainer,
                    required
                }
            } as Partial<MediaPageItemSchema>)
            Toasty.hide(__toasty)
            Toasty.show("Update complete!")
            Navigation.goBack()
        } catch (err) {
            Toasty.show(err, { type: "danger" })
        }
    }
    const refInput = React.useRef<TextInput>();
    React.useEffect(function inputFocus() {
        setTimeout(() => {
            refInput.current?.focus();
        }, 420);
    }, []);

    return (
        <View>
            <Txt.H6 style={{ marginBottom: spacing(2) }}>{tr("Select Trainer Button")}</Txt.H6>
            {/* <A.InputParagragh
                ref={refInput}
                placeholder={tr("Write here...")}
                value={message}
                onChangeText={setMessage}
                multiline
                style={{ minWidth: scale(200) }}
            /> */}
            <Kitten.ViewH style={{ justifyContent: "space-between", alignItems: "center" }}>
                <Txt.S1>Required to Continue</Txt.S1>
                <Kitten.Toggle checked={required} onChange={setRequired} />
            </Kitten.ViewH>
            <Txt.Helper>{"If required, the user must select their trainer before they can proceed to following sections of training."}</Txt.Helper>
            <Buttoon
                style={{ marginTop: spacing(4) }}
                progress
                compact
                // disabled={R.isEmpty(message)}
                onPress={(xong) => {
                    editItem && updateItem()
                    !editItem && createItem(xong)
                    Navigation.goBack()
                }}>
                {editItem ? "Update" : "Create"}
            </Buttoon>
        </View>
    );
}

const A = {
    InputParagragh: sstyled(TextInput)({
        minHeight: scale(169),
        color: "text",
        borderWidth: 1,
        borderRadius: scale(4),
        borderColor: "primary",
        padding: spacing(3),
        margin: spacing(3),
    }),
};

interface P {
    /**
     * the doc id of the current custom page
     */
    cpid: string;
    editItem?: MediaPageItemSchema;
}
