import { Kitten, Txt } from "components/atoms";
import { ViewUserCorner } from "components/organisms";
import {
  useAppContext,
  useMembersCollection,
  usePagesCollection,
  usePosstsCollection,
  useScoreboardCollection
} from "engines";
import * as React from "react";

export function FuegoSection() {
  const { frbsUser, teamId } = useAppContext();
  const { data: posstsD } = usePosstsCollection();
  const { data: pagesD } = usePagesCollection();
  const { data: membersD } = useMembersCollection({
    where: ["_teamId", "==", teamId],
  });
  const { data: scoreboardsD } = useScoreboardCollection();
  return (
    <Kitten.Card>
      <ViewUserCorner showInfo={true} />
      <Txt>{`Signed in as ${JSON.stringify(frbsUser?.email)}`}</Txt>
      <Txt>{`Teamid: ${JSON.stringify(teamId)}`}</Txt>
      <Kitten.Menu>
        <Kitten.MenuGroup
          title={`Fuego Collections load ${posstsD ? "🟩" : "~"} ${
            pagesD ? "🟦" : "~"
          } ${membersD ? "🟨" : "~"} ${scoreboardsD ? "🟧" : "~"}`}
        >
          <Kitten.ListItemv2
            title="Possts"
            accessoryLeft={() => <Txt>{posstsD ? `✅` : `⌛`}</Txt>}
            accessoryRight={() => <Txt>{posstsD?.length}</Txt>}
          />
          <Kitten.ListItemv2
            title="Pages"
            accessoryLeft={() => <Txt>{pagesD ? `✅` : `⌛`}</Txt>}
            accessoryRight={() => <Txt>{pagesD?.length}</Txt>}
          />
          <Kitten.ListItemv2
            title="Members"
            accessoryLeft={() => <Txt>{membersD ? `✅` : `⌛`}</Txt>}
            accessoryRight={() => <Txt>{membersD?.length}</Txt>}
          />
          <Kitten.ListItemv2
            title="Scoreboards"
            accessoryLeft={() => <Txt>{scoreboardsD ? `✅` : `⌛`}</Txt>}
            accessoryRight={() => <Txt>{scoreboardsD?.length}</Txt>}
          />
        </Kitten.MenuGroup>
      </Kitten.Menu>
    </Kitten.Card>
  );
}

// import { Txt } from "components/atoms";
// import { TwoColumns } from "components/templates";
// import { useAppContext } from "engines";
// import { Video } from "expo-av";
// import { signOut } from "firebase/auth";
// import React from "react";
// import { View } from "react-native";
// import { useNavigator } from "screens/_navigation/navigation-utils";
// import { useDocument } from "swr-firestore-v9";
// import { spacing } from "utils";
// import { fuego } from "../../../fuego";
// import { FuegoAuth } from "./FuegoAuth";
// import { FuegoPosst } from "./FuegoPosst";
// import { FuegoScoreboard } from "./FuegoScoreboard";
// // import { useNavigator } from "./_navigation/navigation-utils";

// export function FuegoScreen() {
//   const { C, frbsUser } = useAppContext();
//   const { data } = useDocument("configs/keys-app-takeoff");
//   const Navigation = useNavigator();
//   const refVideo = React.useRef<Video>(null);

//   return (
//     <TwoColumns
//       column1Content={(scrollHandler) => (
//         <View>
//           <Txt.H2 onPress={() => {}}>Fuego screen</Txt.H2>
//           {/* <Text style={styles.title}>{JSON.stringify(getFuego().db.toJSON())}</Text> */}
//           <Txt
//             onPress={() => {
//               signOut(fuego.auth);
//             }}
//           >
//             TeamId: {JSON.stringify(data?._teamId)}
//           </Txt>
//           <Txt onPress={() => {}}>
//             Email: {JSON.stringify(fuego.auth.currentUser?.email)}
//           </Txt>
//           <View style={{ padding: spacing(3) }}>
//             {/* <Videolly
//           type="component"
//           ref={refVideo}
//           source={{
//             uri: "http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4",
//           }}
//           useNativeControls
//         /> */}
//           </View>
//           <Txt onPress={() => refVideo.current?.playAsync()}>Play</Txt>
//           <FuegoAuth />
//           <FuegoScoreboard />
//           <FuegoPosst />
//         </View>
//       )}
//     />
//   );
// }
