import {
  ContactGroupSchema,
  MediaPageItemSchema,
  MediaPageSchema,
  PosstSchema,
  ScoreboardSchema,
  ShortcutSchema,
  _DEFAULT_MEDIA_ITEM,
  _DEFAULT_MEDIA_PAGE,
  _DEFAULT_POSST,
  _DEFAULT_SCOREBOARD,
  __DEFAULT_CONTACTGROUP
} from "engines/backends";
import firebase from "firebase";
import * as R from "ramda";
import { tr } from "utils";

export function __ScoreboardData({ teamId }: { teamId: string }) {
  return R.mergeDeepRight<ScoreboardSchema, Partial<ScoreboardSchema>>(
    _DEFAULT_SCOREBOARD,
    {
      _teamId: teamId,
      title: "*New scoreboard",
      subtitle: "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      people: [], //* To remove all fake data existed in a default scoreboard
      // position: safeData.length,
    }
  );
}

/**
 * ### Default media resources data, e.g. createdAt and _teamId
 * - For other field, please put it directly in the function
 * @param param0
 * @returns
 */
export function __MediaResourcesData({
  teamId = "",
  pageName = "",
}: {
  teamId: string;
  pageName: string;
  /** @deprecated, put it directly in the add() instead */
  mediaTeam?: boolean;
  /** @deprecated, put it directly in the add() instead */
  password?: string;
}) {
  return R.mergeDeepRight<MediaPageSchema, Partial<MediaPageSchema>>(
    _DEFAULT_MEDIA_PAGE,
    {
      _teamId: teamId,
      name: pageName,
      // mediaItem: {
      //   team: mediaTeam,
      // },
      // password: password,
      // name: NewMediaPageName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
  );
}

export const NewMediaPageName = tr("* New Page Page");

export function __AnnoucementData({
  teamId,
  parentAppId,
}: {
  teamId: string;
  parentAppId?: string;
}) {
  return R.mergeDeepRight<PosstSchema, Partial<PosstSchema>>(_DEFAULT_POSST, {
    _teamId: teamId,
    _parentAppId: parentAppId,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
}

export function __ResourceItemData() {
  return R.mergeDeepRight<MediaPageItemSchema, Partial<MediaPageItemSchema>>(
    _DEFAULT_MEDIA_ITEM,
    {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
  );
}

export function __ContactGroupsData() {
  return R.mergeDeepRight<ContactGroupSchema, Partial<ContactGroupSchema>>(
    __DEFAULT_CONTACTGROUP,
    {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
  );
}

export function DefaultMoreData({ teamId }: { teamId: string }) {
  return {
    _id: "",
    _teamId: teamId,
    iosLink: "",
    androidLink: "",
    otherLink: "",
    link: "",
    logo: "",
    name: "",
    position: 0,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  } as ShortcutSchema;
}
