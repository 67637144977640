/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.08.18
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function linkPageNumber(link) {
  let checker = link.includes("page");
  if (checker == true) {
    return 1;
  } else {
    return 2;
  }
}
