//#region [import]

import { BroadcastList } from "components/molecules";
import { BroadcastCreatorCenter } from "components/organisms";
import { TwoColumns } from "components/templates";
import { fn, useAppContext, useUserProfile } from "engines";
import React from "react";
import { IS_WEB, tr } from "utils";
// import { firebaseConfig } from "./config";

//#endregion

/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.03.23
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function BroadcastScreen(props: P) {
  // firebase.default.initializeApp(firebaseConfig);r
  const { C, teamId } = useAppContext();
  const userProfile = useUserProfile();

  //#region [section] notification
  React.useEffect(() => {
    IS_WEB
      ? null
      : fn.notifications.getFirebaseMessagingToken().then((token) => {
        if (token) {
          console.log("token FMC ", token);
          if (userProfile?.data?.fcmTokens?.includes(token)) {
            return null;
          } else {
            userProfile._updateFCMTokens(token);
          }
        }
      });
    IS_WEB ? null : fn.notifications.subscribeToGlobalTopic(teamId);
  }, [userProfile]);
  //#endregion
  try {
    return (
      <TwoColumns
        bottomInset
        column1Content={(scrollHandler) => (
          <BroadcastList scrollHandler={scrollHandler} />
        )}
        column2Content={[
          {
            key: "BroadcastCreatorCenter",
            headerTitle: tr("Broadcast a message"),
            showTitle: true,
            render: () => <BroadcastCreatorCenter />,
            icon: "plus",
          },
        ]}
      />
    );
  } catch (error) {
    throw new Error(`::TWFzc01haWxIb21lU2NyZWVu:: ${error}`);
  }
}

/**
 * ###  Screen Props
 */
interface P {
  // route?: { params?: dMassMailHomeParams }; //* unmask if we pass params thru rnav
}
