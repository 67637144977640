import { MenuIcon } from "assets/icons/MenuIcon";
import { Kitten, Toasty } from "components";
import { useTwoColumns } from "components/templates";
import React from "react";
import { useNavigator } from "screens/_navigation";
import { tr } from "utils";
/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.02.09
 *  -  *Brief changelog*
 *  @author  Your Name
 *
 **/

export function ShareContentsPressable(props: P) {
  // const { setIsSelectModeOn, isSelectModeOn } = useCollabContext();
  const { isSelectShareModeOn, setisSelectShareModeOn, setIsShareContents } =
    props;
  const { breakpointForTwoColumns } = useTwoColumns();
  const Navigation = useNavigator();

  React.useEffect(function closePage() {
    !breakpointForTwoColumns &&
      (setisSelectShareModeOn(!isSelectShareModeOn), Navigation.goBack());
  }, []);
  function toggleSharing() {
    setisSelectShareModeOn(!isSelectShareModeOn);
    setIsShareContents([]);
  }
  return (
    <>
      {/* <Buttoon
        onPress={() => {
          cancelContent()
        }}
        appearance={isSelectShareModeOn == false ? "outline" : "ghost"}
        status={isSelectShareModeOn == false ? "basic" : "danger"}
      >
        {isSelectShareModeOn == false ? tr("Share") : tr("Cancel")}
      </Buttoon> */}

      <Kitten.MenuItem
        onPress={() => {
          toggleSharing();
        }}
        title={isSelectShareModeOn == false ? tr("Share") : tr("Cancel")}
        accessoryLeft={(p) =>
          MenuIcon(isSelectShareModeOn == false ? "share" : "x", p)
        }
      />
    </>
  );
}

interface P {
  isSelectShareModeOn?: boolean;
  setisSelectShareModeOn?: (value: boolean) => void;
  setIsShareContents?: (item) => void;
}
