import { IndexPath } from "@ui-kitten/components";
import { Buttoon, Kitten, Txt } from "components";
import {
  useActivityCollection,
  useAppContext,
  useConfigDocument
} from "engines";
import * as React from "react";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { StatsScreen, useNavigator } from "screens";
import { spacing, tr } from "utils";
import { StreaksCenter } from "./StreaksCenter";

export function UserStatsOverview(p: {
  uid?: string;
  showStreakShortcut?: boolean;
  showHistoryShortcut?: boolean;
  onlyShowMonth?: Date;
}) {
  // make onlyShowMonth have new Date() as the default is how weget the default view to show the current month
  const { uid, showStreakShortcut, showHistoryShortcut, onlyShowMonth } = p;
  const { C, frbsUser, teamId } = useAppContext();
  const Navigation = useNavigator();
  const configHandler = useConfigDocument(`variables-${teamId}`);
  const activityHandler = useActivityCollection(uid || frbsUser?.uid);

  //   const [_endDate, setEndDate] = React.useState<Date>(null);
  const [withinMonth, setWithinMonth] = React.useState<Date>(onlyShowMonth || new Date());
  const [daysBack, setDaysBack] = React.useState<number>(30);

  return (
    <>
      <Kitten.Layout level="3">
        {onlyShowMonth ? null : (
          <FilterDropdown
            onChange={(option) => {
              setWithinMonth(option.withinMonth);
              setDaysBack(option.days);
              // alert(
              //   JSON.stringify(
              //     activityHandler.getActivitiesWithinMonth(
              //       configHandler.data.activities[0].key,
              //       activityHandler.data,
              //       option.withinMonth
              //     ).map(a => ({...a,date:a.date.toDate()}))
              //   )
              // );
            }}
          />
        )}
        {configHandler.data?.activities && activityHandler.data ? (
          configHandler.data?.activities?.map((ac) => {
            const _total =
              (withinMonth
                ? activityHandler.getTotalCountOfActivityWithinMonth(
                  ac.key,
                  activityHandler.data,
                  withinMonth
                )
                : activityHandler.getTotalCountOfActivityWithinDays(
                  ac.key,
                  activityHandler.data,
                  daysBack
                  // _endDate
                )) || 0;
            return (
              <Kitten.ViewH
                style={{
                  justifyContent: "space-between",
                  marginVertical: spacing(4),
                }}
              >
                <Txt.S1
                  style={{ marginLeft: spacing(3), maxWidth: "77%" }}
                >{`${ac.title}`}</Txt.S1>
                <Kitten.ViewH>
                  {_total < 100 ? <AnimatedCircularProgress
                    style={{ marginHorizontal: spacing(4) }}
                    size={50}
                    width={4}
                    fill={_total == 0 ? 0 : (_total / daysBack) * 100}
                    rotation={0}
                    tintColor={C.primary}
                    backgroundColor={C.primaryDarker}
                  >
                    {() => <Txt.H5 adjustsFontSizeToFit>{`${_total}`}</Txt.H5>}
                  </AnimatedCircularProgress> : <Txt.H5 style={{ marginRight: spacing(4) }} adjustsFontSizeToFit>{`${_total?.toLocaleString()}`}</Txt.H5>}
                </Kitten.ViewH>
              </Kitten.ViewH>
            );
          })
        ) : (
          <>
            <Txt.Indicator marginV>{tr("Loading stats....")}</Txt.Indicator>
            <Kitten.Spinner />
          </>
        )}
        <Kitten.ViewH style={{ justifyContent: "flex-end" }}>
          {showHistoryShortcut ? (
            <Buttoon
              size="small"
              appearance="ghost"
              icon={{ name: "chevron_right", right: true }}
              onPress={() =>
                Navigation.overlayPortal.open({
                  headerTitle: tr("Activity History"),
                  render: <StatsScreen uid={uid} />,
                })
              }
            >
              {tr("View Activity history")}
            </Buttoon>
          ) : null}
          {showStreakShortcut ? (
            <Buttoon
              size="small"
              appearance="ghost"
              status="warning"
              icon={{ name: "chevron_right", right: true }}
              onPress={() =>
                Navigation.overlayPortal.open({
                  headerTitle: tr("Activity Streaks"),
                  render: <StreaksCenter uid={uid} />,
                })
              }
            >
              {tr("View streaks")}
            </Buttoon>
          ) : null}
        </Kitten.ViewH>
      </Kitten.Layout>
    </>
  );
}

interface FilterOption {
  title: string;
  /** only show activities from this many days ago till now */
  days?: number;
  /** only show activities within month of this date */
  withinMonth?: Date;
}

function FilterDropdown(p: { onChange: (value: FilterOption) => void }) {
  const { onChange } = p;

  const today = new Date();

  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
  const daysInLastMonth = lastMonthEnd.getDate();

  const options: FilterOption[] = [
    { title: "Last 7 days", days: 7 },
    { title: "Last 30 days", days: 30 },
    {
      title: `This month (${today.toLocaleString("default", {
        month: "long",
      })})`,
      days: today.getDate(),
    },
    {
      title: `Last month (${lastMonthEnd.toLocaleString("default", {
        month: "long",
      })})`,
      days: daysInLastMonth,
      withinMonth: lastMonthEnd,
    },
  ];

  const [selectedIndex, setSelectedIndex] = React.useState<IndexPath>(
    new IndexPath(2)
  );

  return (
    <Kitten.Select
      placeholder={""}
      style={{ margin: spacing(3) }}
      status={"primary"}
      selectedIndex={selectedIndex}
      onSelect={(index) => {
        const indexNumber: number = (index as IndexPath).row;
        setSelectedIndex(index as IndexPath);
        onChange(options[indexNumber]);
      }}
      value={options[(selectedIndex as IndexPath).row].title}
    >
      {options.map((o, i) => (
        <Kitten.SelectItem key={`doption-${i}`} title={o.title} />
      ))}
    </Kitten.Select>
  );
}
