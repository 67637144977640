import { app_team_id } from "../../../package.json"
import Color from "color";
import { Kitten, sstyled, Txt } from "components";
import { ConfigVariablesSchema, useAppContext } from "engines";
import { getAppLogoFromTitle, useConfigCollection, _getValidSubdomain } from "engines/firebase/handler/config-handler";
import { LinearGradient } from "expo-linear-gradient";
import * as React from "react";
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Linking,
  StatusBar,
  View,
} from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr, version } from "utils";
import { APP_CONFIG } from "../../../apptakeoff.config";

/**
 * ### A splash screen that
 * -  Navigate to HomeStack if !!currentUser
 * -  Show Sign in button if !currentUser
 *  ----
 *  @version 21.03.15
 *  -  *Web+mobile in one*
 *  @author  K
 *
 **/
export function WelcomeScreen() {
  const { C, theme, setTheme, teamId } = useAppContext();

  // const { ...configHandler } = useConfigCollection();
  // let appConfig = configHandler._getConfigFromSubDomain();
  const _subdomain = _getValidSubdomain()
  const _teamid = teamId || APP_CONFIG?.app_id || app_team_id
  const configHandler = _subdomain ? useConfigCollection({ where: ["subdomain", "==", _subdomain] })
    : useConfigCollection({ where: ["_teamId", "==", _teamid] })
  const appConfig = configHandler.data?.find(conf => conf.appTitle) as ConfigVariablesSchema

  if (!appConfig?._teamId) return <View style={{ backgroundColor: "black" }} />;

  try {
    return (
      <ImageBackground
        source={{
          uri: getAppLogoFromTitle(appConfig?.appTitle),
        }}
        blurRadius={20}
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StatusBar animated={true} barStyle={"light-content"} />
        <LinearGradient
          colors={[
            Color(C.primary).alpha(0.7).toString(),
            Color(C.text).alpha(0.5).toString(),
            Color(C.primary).alpha(0.7).toString(),
          ]}
          start={{ x: 0, y: 0 }}
          style={{ width: "100%", height: "100%", position: "absolute" }}
        />
        {appConfig?.appTitle ? (
          <>
            <A.TeamLogo
              source={{
                uri: getAppLogoFromTitle(appConfig?.appTitle),
              }}
            />
            <Txt.H2
              style={{
                color: C.text01,
                textAlign: "center",
                marginVertical: spacing(5),
              }}
            >
              {tr("Welcome to\n") + appConfig?.appTitle}
            </Txt.H2>
          </>
        ) : (
          <Txt.H6
            style={{
              color: C.text01,
              textAlign: "center",
              marginVertical: spacing(5),
            }}
          >
            {tr("Get ready to takeoff...")}
          </Txt.H6>
        )}

        <SignInAction />
        <View style={{ width: "100%", height: spacing(5) }}></View>
        <Txt.Helper marginV style={{ color: C.text01 }}>
          v{version}
        </Txt.Helper>
        <Txt.Helper
          style={{ textDecorationLine: "underline" }}
          onPress={() =>
            Linking.openURL("https://apptakeoff.com/privacy-policy")
          }
        >
          Privacy Policy
        </Txt.Helper>
      </ImageBackground>
    );
  } catch (error) {
    throw Error(`::V2VsY29tZVNjcmVlbg==::${error}`);
  }
}

const SignInAction = () => {
  const Navigation = useNavigator();
  const { C } = useAppContext();
  //#region [FRBS]
  const configHandler = useConfigCollection();
  let appConfig = configHandler._getConfigFromSubDomain();
  //#endregion
  return (
    <View>
      {appConfig?.appTitle ? (
        <Kitten.Button
          size={"giant"}
          disabled={!appConfig?.appTitle}
          // accessoryLeft={() => (
          //   <Avt
          //     name={appConfig?.appTitle}
          //     source={{ uri: appConfig?.appLogo }}
          //     size={"small"}
          //   />
          // )}
          onPress={() => {
            appConfig?.appTitle && Navigation.navigate("Auth");
            // Navigation.dialogPortal.open({
            //   headerTitle: tr("Sign in or create an account"),
            //   render: <AuthCenter />,
            // });
            // refRocket.current
            //   ?.fadeOutUpBig(400)
            //   .then((r) => {});
          }}
        >
          {/* {tr("Continue with") + " " + appConfig?.appTitle} */}
          {tr("Let's get started")}
        </Kitten.Button>
      ) : (
        <ActivityIndicator
          color={C.primary}
          size={"large"}
          style={{
            padding: spacing(3),
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: C.surface,
            borderRadius: scale(90),
          }}
        />
      )}
    </View>
  );
};

const A = {
  TeamLogo: sstyled(Image)({
    width: [scale(100), scale(110), scale(120)],
    height: [scale(100), scale(110), scale(120)],
    borderRadius: 10,
    backgroundColor: "dim",
  }),
};
