import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import {
  DrawerDescriptorMap,
  DrawerNavigationHelpers
} from "@react-navigation/drawer/lib/typescript/src/types";
import {
  CommonActions,
  DrawerActions,
  DrawerNavigationState,
  ParamListBase,
  useLinkBuilder
} from "@react-navigation/native";
import { Kitten, ListHeader } from "components/atoms";
import * as R from "ramda";
import * as React from "react";
import { Pressable, View } from "react-native";
import { NAVIGATION_ROUTE } from "screens";
import {
  AdminDrawerCollection,
  DevDrawerCollection
} from "screens/_navigation/drawer.navigator";
import { ViewUserCorner } from "./NavigationBar";

// DrawerDescriptorMap
// DrawerNavigationHelpers,

export function PersonalDrawer(props) {
  return (
    <DrawerContentScrollView {...props}>
      <ViewUserCorner showInfo={true} />
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  );
}

type Props = {
  state: DrawerNavigationState<ParamListBase>;
  navigation: DrawerNavigationHelpers;
  descriptors: DrawerDescriptorMap;
};

/**
 * Custom DrawerItemList
 * @see https://github.com/react-navigation/react-navigation/blob/main/packages/drawer/src/views/DrawerItemList.tsx
 */
export const DrawerItemList = ({ state, navigation, descriptors }: Props) => {
  const buildLink = useLinkBuilder();

  const focusedRoute = state.routes[state.index];
  const focusedDescriptor = descriptors[focusedRoute.key];
  const focusedOptions = focusedDescriptor.options;

  const {
    drawerActiveTintColor,
    drawerInactiveTintColor,
    drawerActiveBackgroundColor,
    drawerInactiveBackgroundColor,
  } = focusedOptions;

  //#region [ANCHOR] list of route name, in order to put drawer list into sections
  const hiddenRouteNames: NAVIGATION_ROUTE[] = [
    "Main",
    // "FIN Dashboard",
    "Contact Group Screen",
  ];
  const devRouteNames = R.keys(DevDrawerCollection);
  const adminRouteNames = R.keys(AdminDrawerCollection);
  let devRoutes = state.routes.filter((r) => devRouteNames.includes(r.name));
  let adminRoutes = state.routes.filter((r) =>
    adminRouteNames.includes(r.name)
  );
  //#endregion

  /**
   * ### Render a Pressable Drawer Item, which will display in respective sections
   * @param param0
   * @returns
   */
  let PressableDrawerItem = ({ route, i }) => {
    const focused = i === state.index;
    const {
      title,
      drawerLabel,
      drawerIcon,
      drawerLabelStyle,
      drawerItemStyle,
    } = descriptors[route.key].options;

    if (hiddenRouteNames.includes(route.name)) {
      return <View key={route.key} />;
    }
    return (
      <Pressable key={route.key}>
        {(s) => (
          <DrawerItem
            key={route.key}
            label={
              drawerLabel !== undefined
                ? drawerLabel
                : title !== undefined
                ? title
                : route.name
            }
            icon={drawerIcon}
            focused={s.hovered} //* light up as hovered
            activeTintColor={drawerActiveTintColor}
            inactiveTintColor={drawerInactiveTintColor}
            activeBackgroundColor={drawerActiveBackgroundColor}
            inactiveBackgroundColor={drawerInactiveBackgroundColor}
            labelStyle={drawerLabelStyle}
            style={drawerItemStyle}
            to={buildLink(route.name, route.params)}
            onPress={() => {
              navigation.dispatch({
                ...CommonActions.navigate({ name: route.name, merge: true }),
                // ...(focused
                //   ? DrawerActions.closeDrawer()
                //   : CommonActions.navigate({ name: route.name, merge: true })),
                target: state.key,
              });
            }}
          />
        )}
      </Pressable>
    );
  };

  return (
    <View>
      {state.routes
        .filter((r) => ![...adminRouteNames, ...devRouteNames].includes(r.name))
        .map((route, i) => (
          <PressableDrawerItem route={route} i={i} />
        ))}
      {adminRoutes && adminRoutes.length > 0 && (
        <>
          <Kitten.Divider_________ />
          <ListHeader titleTx="Admin" />
          {adminRoutes.map((route, i) => (
            <PressableDrawerItem route={route} i={i} />
          ))}
        </>
      )}
      {devRoutes && devRoutes.length > 0 && (
        <>
          <Kitten.Divider_________ />
          <ListHeader titleTx="Developers" />
          {devRoutes.map((route, i) => (
            <PressableDrawerItem route={route} i={i} />
          ))}
        </>
      )}
    </View>
  );

  return state.routes.map((route, i) => {
    const focused = i === state.index;
    const {
      title,
      drawerLabel,
      drawerIcon,
      drawerLabelStyle,
      drawerItemStyle,
    } = descriptors[route.key].options;

    if (hiddenRouteNames.includes(route.name)) {
      return <View key={route.key} />;
    }
    return (
      <Pressable key={route.key}>
        {(s) => (
          <DrawerItem
            key={route.key}
            label={
              drawerLabel !== undefined
                ? drawerLabel
                : title !== undefined
                ? title
                : route.name
            }
            icon={drawerIcon}
            focused={s.hovered} //* light up as hovered
            activeTintColor={drawerActiveTintColor}
            inactiveTintColor={drawerInactiveTintColor}
            activeBackgroundColor={drawerActiveBackgroundColor}
            inactiveBackgroundColor={drawerInactiveBackgroundColor}
            labelStyle={drawerLabelStyle}
            style={drawerItemStyle}
            to={buildLink(route.name, route.params)}
            onPress={() => {
              navigation.dispatch({
                ...(focused
                  ? DrawerActions.closeDrawer()
                  : CommonActions.navigate({ name: route.name, merge: true })),
                target: state.key,
              });
            }}
          />
        )}
      </Pressable>
    );
  }) as React.ReactNode as React.ReactElement;
};
