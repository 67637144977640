import { Buttoon, Kitten, M, sstyled, Toasty, Txt } from "components/";
import {
  ConfigVariablesSchema,
  fn,
  useAppContext,
  useConfigDocument,
  useMassMailFunctions,
  useUserProfile
} from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import React from "react";
import {
  NativeSyntheticEvent,
  ScrollView,
  TextInputSelectionChangeEventData,
  View
} from "react-native";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";

// import { NavigationStackScreenProps } from "react-navigation-stack";
// import { C, moderateScale, spacing } from "utilities/";

export function HitEmUpEmail(props: P) {
  const listData = props?.listData;
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  const { frbsUser } = useAppContext();
  const handleMails = useMassMailFunctions();

  const configHandler = useConfigDocument(`variables-${teamId}`);
  const configVariables: ConfigVariablesSchema = configHandler.data;

  const [height, setHeight] = React.useState<number>(40);
  const updateSize = (height) => {
    setHeight(height);
  };
  const [newRecipients, setNewRecipients] = React.useState<ContactSchemaa[]>(
    []
  );
  const [_contacts, setContacts] = React.useState<any[]>([]);

  React.useEffect(
    /**
     * Get contacts from custom contact list
     */
    function fetchContacts() {
      getContacts();
    },
    [newRecipients]
  );

  /**
   * Value contains message body
   */
  const [_messagebody, setBody] = React.useState(
    //@ts-ignore
    fn.js.shareItemsToPrettyMessage(listData) || ""
  );

  /**
   * Position of cusor in the input field
   */
  const [caret, setCaret] = React.useState({
    /**
     * Position of cusor in the input field
     */
    start: 0,
    end: 0,
  });

  React.useEffect(
    function updateRecipientsIfContactsProvided() {
      setNewRecipients(newRecipients.concat(props.contacts));
    },
    [props.contacts]
  );

  /**
   * Use counter to get each contact details in the list
   */
  const counter = React.useRef(0);

  function handleSelect(
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>
  ) {
    setCaret({
      start: e.nativeEvent.selection.start,
      end: e.nativeEvent.selection.end,
    });
  }

  /** Calling to this function you can insert text in the cursor position */
  function insertText(text: string) {
    /**
     * Adds text to cusor position
     */

    setBody(
      _messagebody.substring(0, caret.start) +
        text +
        _messagebody.substring(caret.end, _messagebody.length)
    );
  }

  /**
   * Get contacts and set it to state
   */
  function getContacts() {
    let contactsEmail = fn.js.getRecipientsEmail(newRecipients);
    setContacts(contactsEmail);
  }

  /**
   *
   */
  // const contactName =
  //   _contacts.length > 0 &&
  //   counter.current <= _contacts.length - 1 &&
  //   (_contacts[counter.current]?.displayName
  //     ? _contacts[counter.current]?.displayName
  //     : _contacts[counter.current]?.displayName);

  /**
   * This contains send message function
   * Skip and Cancel logic
   */
  // function skipToNext() {
  //   console.log("");
  //   setContacts(
  //     _contacts
  //       .slice(0, counter.current)
  //       .concat(_contacts.slice(counter.current + 1, _contacts.length))
  //   );

  //   counter.current = counter.current + 1;

  //   sendMessage();
  // }

  async function sendMessage() {
    // if (counter.current === _contacts.length) {
    //   counter.current = 0;
    //   Toasty.show("All messages has been sent", { type: "success" });
    //   getContacts();
    //   return;
    // }
    /**
     * searches through message and replace this 🌀 with the contact name
     */
    let contacts = fn.js.getPersonaliDisplayName(newRecipients);
    let contactsEmail = fn.js.getRecipientsEmail(newRecipients);
    // const messagesfinal = _messagebody.replace(
    //   /🌀/g,
    //   contacts[counter.current]
    //     ? contacts[counter.current]
    //     : contactsEmail[counter.current]
    // );

    for (let index = 0; index < contactsEmail.length; index++) {
      const messagesfinal = _messagebody.replace(
        /🌀/g,
        contacts[index] ? contacts[index] : contactsEmail[index]
      );

      const payload = {
        recipient: contactsEmail[index],
        email_from: `${
          userProfile?.data?.personali.displayName
            ? userProfile?.data?.personali.displayName
            : userProfile?.data?.personali.email
        } <admin@apptakeoff.com>`,
        email_reply_to: "noreply@apptakeoff.com",
        email_subject: `${messagesfinal.substring(0, 60)} ...`,
        email_text_body: messagesfinal,
        _teamId: teamId,
        // Cc: _reducedccpoy ? _reducedccpoy.toString() : "",
        // bcc: _reducedbcopy ? _reducedbcopy.toString() : "",
      };

      console.log("single mail ", payload);

      if (index === _contacts.length) {
        Toasty.show("All messages has been sent", { type: "success" });
      } else {
        handleMails
          .sendErrorEmail(payload)
          .then((res) => {
            console.log("res ", res);
            console.log("res index ", index);
          })
          .catch((e) => {
            console.log("e ", e);
          });
      }
    }
    Navigation.goBack();
    Navigation.goBack();
  }

  return (
    <SS.Ctnr style={{ backgroundColor: C.background01 }}>
      <ScrollView keyboardShouldPersistTaps="always" keyboardDismissMode="none">
        <SS.CtnrContent style={{ backgroundColor: C.background }}>
          {!props.contacts || props.contacts?.length < 1 ? (
            <M.MembersMultiInput
              title={tr("To: ")}
              onRecipientsCallback={setNewRecipients}
              containerStyle={{ zIndex: 9999999 }}
            />
          ) : null}

          <Txt.$Title style={{ marginVertical: spacing(2) }}>
            Blast Email
          </Txt.$Title>
          <Kitten.Input
            autoFocus={true}
            multiline={true}
            value={_messagebody}
            onContentSizeChange={(e) =>
              updateSize(e.nativeEvent.contentSize.height)
            }
            textStyle={{ minHeight: height }}
            placeholder="Type your message..."
            defaultValue={_messagebody}
            onChangeText={setBody}
            onSelectionChange={(e) => handleSelect(e)}
            scrollEnabled={false}
          />
          <Kitten.Divider_________ />
          <Txt.Indicator>Preset fields</Txt.Indicator>
          <Kitten.ButtonGroup
            size="small"
            appearance="outline"
            status="basic"
            style={{ alignSelf: "center" }}
          >
            <Kitten.Button onPress={() => insertText("🌀")}>
              {"🌀 Recipient's Name"}
            </Kitten.Button>
            {/* <Buttoon onPress={() => insertText("{name}")}>{`{phone}`}</Buttoon> */}
          </Kitten.ButtonGroup>
          <Kitten.Divider_________ />

          <Buttoon
            status={C.primary}
            style={{ width: "92%", marginTop: spacing(4) }}
            compact
            disabled={newRecipients.length < 1 ? true : false}
            icon={{ name: "envelope" }}
            onPress={() => sendMessage()}
          >
            {`Send email to ${newRecipients.length} people`}
          </Buttoon>
        </SS.CtnrContent>
      </ScrollView>
    </SS.Ctnr>
  );
}
// }

const SS = {
  Ctnr: sstyled(View)((p) => ({
    flex: 1,
    width: `100%`,
  })),
  CtnrContent: sstyled(View)((p) => ({
    paddingVertical: spacing(2),
    paddingHorizontal: spacing(4),
    justifyContent: "flex-start",
  })),
};

interface P {
  listData: Object; //TODO need to type this
  shareMode: boolean;
  contacts?: ContactSchemaa[];
}
