import { CollectionQueryType } from "@nandorojo/swr-firestore";
import { fuego } from "../../../fuego";

export function defaultSetup<Schema>(teamId: string) {
  return {
    where: ["_teamId", "==", teamId],
    listen: true,
    // ignoreFirestoreDocumentSnapshotField: false,
  } as Partial<dSetup<Schema>>;
}

export function updateId(path: string, idKey: string, id: string) {
  fuego.db.doc(path).update({ [idKey]: id });
}

interface dSetup<Data extends Object> extends CollectionQueryType<Data> {
  /**
   * If `true`, sets up a real-time subscription to the Firestore backend.
   *
   * Default: `false`
   */
  listen?: boolean | undefined;
  /**
   * An array of key strings that indicate where there will be dates in the document.
   *
   * Example: if your dates are in the `lastUpdated` and `user.createdAt` fields, then pass `{parseDates: ["lastUpdated", "user.createdAt"]}`.
   *
   * This will automatically turn all Firestore dates into JS Date objects, removing the need to do `.toDate()` on your dates.
   */
  parseDates?: string[];
  /**
   * If `true`, docs returned in `data` will not include the firestore `__snapshot` field. If `false`, it will include a `__snapshot` field. This lets you access the document snapshot, but makes the document not JSON serializable.
   *
   * Default: `true`
   */
  ignoreFirestoreDocumentSnapshotField?: boolean | undefined;
}
