import { StackActions } from "@react-navigation/native";
import { ListItemProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Kitten } from "components";
import { Avt, Buttoon, sstyled, Txt } from "components/atoms";
import { ShortcutSchema, URL_PREFIX, useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useMoreDocument } from "engines/firebase/handler/more-handler";
import { LinkURL } from "engines/functions/web-functions";
import { type } from "ramda";
import React from "react";
import { ImageBackground, Linking, Pressable, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { FRBS_ROLE } from "../../../engines/firebase/firebase.props";
import { WidgetCalendar } from "../../organisms/calendar/WidgetCalendar";
import { ShortcutEditor } from "./ShortcutEditor";
import { WidgetContacts } from "./WidgetContacts";
import { WidgetUserStats } from "./WidgetUserStats";

export function ShortcutItem(props: {
  type: "contact" | "other" | "link" | "inapp" | "page";
  page: ShortcutSchema;
}) {
  function CoreItem() {
    switch (props.type) {
      case "contact":
        return <ContactItem {...props} />;
        break;
      case "link":
        return <LinkItem {...props} />;
        break;
      case "page":
        return <PageItem {...props} />;
        break;
      case "inapp":
        return <InappItem {...props} />;
        break;
      case "other":
        return <A.ViewShortcut {...props} />;
        return <LinkItem {...props} />;
        break;
      default:
        return (
          <Txt>{JSON.stringify(props.page?.name + " - " + props.page._id)}</Txt>
        );
        return <LinkItem {...props} />;
        break;
    }
  }

  return React.useMemo(() => {
    return <CoreItem />;
  }, [type]);
}

const LinkItem = (props: ListItemProps & { page: ShortcutSchema }) => {
  const { page } = props;
  const { C } = useAppContext();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();
  const shortcutHandler = useMoreDocument(page?._id);

  let _link = String(
    page?.link || page?.iosLink || page?.androidLink || page?.otherLink
  );

  const onPress = () => LinkURL(_link);

  return (
    <Pressable style={{ flex: 1, flexGrow: 1 }} onPress={onPress}>
      {({ hovered }) => (
        <A.ViewShortcut
          // disabled
          hovered={hovered}
          onPress={onPress}
          title={page?.name || "⚠️ Error getting title"}
          description={() =>
            hovered ? (
              <Txt
                onPress={onPress}
                style={{
                  textDecorationLine: hovered ? "underline" : "none",
                  color: C.primaryDarker,
                }}
              >
                {String(_link)?.substring(0, 50) || "⚠️ Error getting data"}
                {String(_link)?.length > 50 ? "..." : ""}
              </Txt>
            ) : (
              <Txt style={{ color: C.primaryDarker }}>{tr("Open Link")}</Txt>
            )
          }
          accessoryLeft={() => (
            <View>
              <View>
                <Avt
                  name={page?.name}
                  shape="rounded"
                  size={"tiny"}
                  source={{
                    uri: page?.logo,
                  }}
                />
              </View>
              {userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]) ? (
                <Txt.C2
                  style={{ color: C.primary, textAlign: "center" }}
                  onPress={() => {
                    Navigation.dialogPortal.open({
                      headerTitle: tr("Edit shortcut"),
                      render: (
                        <ShortcutEditor
                          editingDocId={page?._id}
                          type={"link"}
                        />
                      ),
                    });
                  }}
                >
                  {tr("Edit")}
                </Txt.C2>
              ) : null}
            </View>
          )}
          accessoryRight={() => (
            <View style={{ alignItems: "center" }}>
              {userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]) ? (
                <Pressable
                  onPress={() =>
                    shortcutHandler.update({ isStarred: !!!page?.isStarred })
                  }
                >
                  <IconPrimr
                    name={page?.isStarred ? "star" : "staro"}
                    color={page?.isStarred ? C.primary : C.grey600}
                  />
                </Pressable>
              ) : null}

              {hovered ? (
                <View />
              ) : (
                <IconPrimr
                  name={"link"}
                  color={C["color-primary-200"]}
                  size={scale(11)}
                  // containerStyle={{ backgroundColor: "black" }}
                />
              )}
            </View>
          )}
          {...props}
        />
      )}
    </Pressable>
  );
};

const InappItem = (props: ListItemProps & { page: ShortcutSchema }) => {
  const { page } = props;
  const { C } = useAppContext();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();
  let link = String(
    page?.link || page?.iosLink || page?.androidLink || page?.otherLink
  );
  let _inappLink = link.split("inapp://").pop();

  let _cpid = link.split(URL_PREFIX.PAGE).pop();

  const onPress = () => {
    if (link.startsWith("inapp://")) {
      return Navigation.navigate(_inappLink);
    } else if (link.startsWith(URL_PREFIX.PAGE)) {
      return Navigation.dispatch(
        StackActions.push("Page Contents", {
          cpid: _cpid,
          collabMode: false,
        })
      );
    }
  };
  switch (_inappLink) {
    case "Your Stats":
      // return <WidgetContacts {...props} onPress={onPress} />;
      return <WidgetUserStats {...props} onPress={onPress} isHomeScreen />;
      break;
    case "Follow-up Contacts":
      return <WidgetContacts {...props} onPress={onPress} />;
      break;
    case "Calendar":
      return <WidgetCalendar {...props} />;
      break;
    default:
      return (
        <A.ViewWidget onPress={onPress} style={{ padding: 0 }} {...props}>
          {({ hovered }) => (
            <A.LogoBackground
              source={{
                uri:
                  page?.logo ||
                  "https://images.pexels.com/photos/7135121/pexels-photo-7135121.jpeg?cs=srgb&dl=pexels-codioful-%28formerly-gradienta%29-7135121.jpg&fm=jpg",
              }}
              blurRadius={20}
            >
              {page?.logo ? (
                <Avt
                  name={page?.name}
                  shape="rounded"
                  size={"small"}
                  source={{
                    uri: page?.logo,
                  }}
                />
              ) : (
                <IconPrimr
                  name={"arrow_right"}
                  preset={"circular"}
                  color={C.text}
                  containerStyle={{ backgroundColor: C.background }}
                />
              )}

              <Txt.C1>{page?.name}</Txt.C1>
              {/* <Txt.C2>{page?.link}</Txt.C2> */}
              {hovered ? (
                <Buttoon
                  onPress={onPress}
                  appearance={"ghost"}
                  status={"basic"}
                  size={"tiny"}
                  icon={{ name: "chevron_right", right: true }}
                  compact
                >
                  {link.startsWith("inapp://")
                    ? tr("Go to") + " " + _inappLink
                    : tr("Go to Page")}
                </Buttoon>
              ) : null}
              {userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]) && (
                <Txt.C2
                  style={{
                    color: C.primary,
                    textAlign: "center",
                    position: "absolute",
                    bottom: spacing(2),
                    left: spacing(2),
                  }}
                  onPress={() => {
                    Navigation.dialogPortal.open({
                      headerTitle: tr("Edit shortcut"),
                      render: (
                        <ShortcutEditor
                          editingDocId={page?._id}
                          type={"link"}
                        />
                      ),
                    });
                  }}
                >
                  {tr("Edit")}
                </Txt.C2>
              )}
            </A.LogoBackground>
          )}
        </A.ViewWidget>
      );
      break;
  }
};

const PageItem = (props: ListItemProps & { page: ShortcutSchema }) => {
  const { page } = props;
  const { C } = useAppContext();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();
  let link = String(
    page?.link || page?.iosLink || page?.androidLink || page?.otherLink
  );
  let _inappLink = link.split("inapp://").pop();

  let _cpid = link.split(URL_PREFIX.PAGE).pop();

  const onPress = () => {
    if (link.startsWith(URL_PREFIX.PAGE)) {
      return Navigation.dispatch(
        StackActions.push("Page Contents", {
          cpid: _cpid,
          collabMode: false,
        })
      );
    }
  };
  return (
    <A.ViewWidget onPress={onPress} style={{ padding: 0 }} {...props}>
      {({ hovered }) => (
        <A.LogoBackground
          source={{
            uri:
              page?.logo ||
              "https://images.pexels.com/photos/7135121/pexels-photo-7135121.jpeg?cs=srgb&dl=pexels-codioful-%28formerly-gradienta%29-7135121.jpg&fm=jpg",
          }}
          blurRadius={20}
        >
          <Avt
            name={page?.name}
            shape="rounded"
            size={"tiny"}
            source={{
              uri: page?.logo,
            }}
          />

          <Txt.C1>{page?.name}</Txt.C1>
          {/* <Txt.C2>{page?.link}</Txt.C2> */}
          {hovered ? (
            <Buttoon
              onPress={onPress}
              appearance={"ghost"}
              status={"basic"}
              size={"tiny"}
              icon={{ name: "chevron_right", right: true }}
              compact
            >
              {link.startsWith("inapp://")
                ? tr("Go to") + " " + _inappLink
                : tr("Go to Page")}
            </Buttoon>
          ) : null}
          {userProfile._isRolesContain([
            FRBS_ROLE.ADMIN,
            FRBS_ROLE.DEVELOPER,
          ]) && (
            <Txt.C2
              style={{
                color: C.primary,
                textAlign: "center",
                position: "absolute",
                bottom: spacing(2),
                left: spacing(2),
              }}
              onPress={() => {
                Navigation.dialogPortal.open({
                  headerTitle: tr("Edit shortcut"),
                  render: (
                    <ShortcutEditor editingDocId={page?._id} type={"link"} />
                  ),
                });
              }}
            >
              {tr("Edit")}
            </Txt.C2>
          )}
        </A.LogoBackground>
      )}
    </A.ViewWidget>
  );
};

const ContactItem = (props: { page: ShortcutSchema }) => {
  const { page } = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  const shortcutHandler = useMoreDocument(page?._id);
  // return <Txt>{JSON.stringify(page)}</Txt>;

  let _email = page?.contactEmail;
  let _phone = page?.contactPhone;

  const onPress = () => {
    if (_email) {
      return Linking.openURL(`mailto:${_email}`);
      // Clipboard.setString(_email);
      // Toasty.show(`"${_email}" copied to clipboard`);
    }
    if (_phone) {
      return Linking.openURL(`tel:${_phone}`);
      // Clipboard.setString(_phone);
      // Toasty.show(`"${_phone}" copied to clipboard`);
    }
  };
  return (
    <Pressable style={{ flex: 1, flexGrow: 1 }}>
      {({ hovered }) => (
        <A.ViewShortcut
          // disabled
          onPress={onPress}
          hovered={hovered}
          title={page?.name}
          description={() => (
            <View>
              {_email ? (
                <Pressable>
                  {({ hovered }) => (
                    <View style={{ flexDirection: "row" }}>
                      <IconPrimr
                        name={"email"}
                        color={C.text}
                        size={scale(10)}
                      />
                      <Txt
                        onPress={onPress}
                        style={{
                          textDecorationLine: hovered ? "underline" : "none",
                        }}
                      >
                        {_email}
                      </Txt>
                    </View>
                  )}
                </Pressable>
              ) : (
                <View />
              )}
              {_phone ? (
                <Pressable>
                  {({ hovered }) => (
                    <View style={{ flexDirection: "row" }}>
                      <IconPrimr
                        name={"phone"}
                        color={C.text}
                        size={scale(10)}
                      />
                      <Txt
                        onPress={onPress}
                        style={{
                          textDecorationLine: hovered ? "underline" : "none",
                        }}
                      >
                        {_phone}
                      </Txt>
                    </View>
                  )}
                </Pressable>
              ) : (
                <View />
              )}
            </View>
          )}
          accessoryLeft={() => (
            <View>
              <View>
                <Avt
                  name={page?.name}
                  shape="round"
                  size={"tiny"}
                  source={{
                    uri: page?.logo,
                  }}
                />
              </View>
              {userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]) && (
                <Txt.C2
                  style={{ color: C.primary, textAlign: "center" }}
                  onPress={() => {
                    Navigation.dialogPortal.open({
                      headerTitle: tr("Edit shortcut"),
                      render: (
                        <ShortcutEditor
                          editingDocId={page?._id}
                          type={"contact"}
                        />
                      ),
                    });
                  }}
                >
                  {tr("Edit")}
                </Txt.C2>
              )}
            </View>
          )}
          accessoryRight={() => (
            <View style={{ alignItems: "center" }}>
              <Pressable
                onPress={() =>
                  shortcutHandler.update({ isStarred: !!!page?.isStarred })
                }
              >
                <IconPrimr
                  name={page?.isStarred ? "star" : "staro"}
                  color={page?.isStarred ? C.primary : C.grey600}
                />
              </Pressable>
              {hovered && (
                <IconPrimr
                  name={"contacts"}
                  color={C["color-info-200"]}
                  size={scale(11)}
                  // containerStyle={{ backgroundColor: "black" }}
                />
              )}
            </View>
          )}
        />
      )}
    </Pressable>
  );
};

const A = {
  ViewShortcut: sstyled(Kitten.ListItemv2)(({ hovered }) => ({
    backgroundColor: "surface",
    height: scale(68), //scale(190),
    borderWidth: 1,
    borderColor: hovered ? "primary" : "transparent",
    margin: spacing(2),
    borderRadius: scale(9),
  })),
  ViewWidget: sstyled(Pressable)(({ hovered }) => ({
    // height: scale(120),
    flex: 1,
    flexGrow: 1,
    borderWidth: 1,
    backgroundColor: "surface",
    padding: spacing(3),
    borderColor: hovered ? "primary" : "background",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  LogoBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: spacing(2),
  }),
  TxtItemName: sstyled(Txt.H5)(({ hovered }) => ({
    height: scale(30),
    textAlign: "center",
    color: hovered ? "primary" : "text",
  })),
};
