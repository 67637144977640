import { IndexPath } from "@ui-kitten/components";
import { Avt, Kitten, sstyled, Txt } from "components";
import { Skeleton } from "components/atoms";
import { useAppContext, useMembersCollection, UserSchema } from "engines";
import * as R from "ramda";
import React from "react";
import { View } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import { scale, spacing, tr } from "utils";

/**
 * ### this is used in selecting members
 *  - Used in `BS Creator`
 *
 *  ----
 *  @version 21.09.08
 *  -  *Build a Component*
 *  @nguyenkhooi
 *  @NL
 *
 **/
export function MemberSelect({ onChange, flexDirection }: dSelect) {
  const { C, teamId } = useAppContext();

  // const handleMembers2 = useCollection<UserSchema>(`${FPATH.USERS}`, {
  //   ...Handler.defaultSetup<UserSchema>(teamId),
  //   ignoreFirestoreDocumentSnapshotField: false, //this is a temporary solution to the BS issue
  // });
  const handleMembers = useMembersCollection({
    where: ["_teamId", "==", teamId],
  });
  /**
   * TODO: notes
   */
  const indexOffset = 1;

  //#region [FRBS]
  const [members, setMembers] = React.useState<UserSchema[]>([]);
  React.useEffect(
    function setupData() {
      handleMembers.data && setMembers(handleMembers.data);
    },
    [handleMembers.data]
  );

  // console.log("handleMembers ", handleMembers?.____snapshot);
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    setQuery(value);
    const newData = handleMembers.data?.filter((member) => {
      // console.log("member: ", member.personali);
      return member.personali?.displayName
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });
    setMembers([...newData]);
  }
  //#endregion

  const [selectedTypeIndex, setSelectedTypeIndex] = React.useState(0);

  let sortedMembers = R.sortWith<UserSchema>(
    [
      /** sorting `createdAt` in `desc` */
      R.descend(R.prop("createdAt")),
    ],
    members
  );

  const [_selectedMemberIndex, setSelectedmemberIndex] =
    React.useState<IndexPath>(null);
  const [autoSelectPageId, setAutoSelectPageId] = React.useState<string>(null);
  const autoSelectedMember =
    autoSelectPageId &&
    handleMembers.data
      ?.filter((member) => member._id === autoSelectPageId)
      .pop();
  const selectedPageIndex = autoSelectedMember
    ? new IndexPath(sortedMembers.indexOf(autoSelectedMember) + indexOffset)
    : _selectedMemberIndex;

  return React.useMemo(() => {
    try {
      if (handleMembers.error) {
        console.warn("error in MembersFeed: ", handleMembers.error);
        return (
          <Txt.Indicator>{tr("Error getting members feed")}</Txt.Indicator>
        );
      }
      if (!handleMembers.data) return <Skeleton.Screen.Members length={7} />;

      const displayValue = selectedPageIndex
        ? sortedMembers[selectedPageIndex.row - indexOffset]?.personali
            .displayName
        : null;

      return (
        <>
          <View>
            <Kitten.ViewH>
              <Kitten.Select
                // ref={kittenSelect}
                style={{ flexGrow: 1 }}
                placeholder={tr("Select member")}
                value={displayValue}
                selectedIndex={selectedPageIndex}
                onSelect={(index) => {
                  index.row !== 0 && setSelectedmemberIndex(index);
                  // setCaption("Nice!");
                  /**
                   * This was implemented to fix the ID issue when assigning BS;
                   */
                  onChange &&
                    onChange(
                      sortedMembers[index.row - indexOffset]?._id
                        ? sortedMembers[index.row - indexOffset]?._id
                        : sortedMembers[index.row - indexOffset]?.__snapshot?.id
                    );
                  // }
                }}
              >
                <A.InputSearch
                  autoFocus={true}
                  value={query}
                  placeholder={tr("Search member by name")}
                  onChangeText={(value) => {
                    setQuery(query);
                    setSelectedmemberIndex(null);
                    filterHandler(value);
                  }}
                />
                {sortedMembers?.map((member, index) => (
                  <Kitten.SelectItem
                    key={member._id}
                    title={member.personali.displayName}
                    accessoryLeft={() => (
                      <Avt
                        name={member.personali.displayName || "K"}
                        source={{ uri: member.personali?.photoURL || "" }}
                      />
                    )}
                  />
                ))}
              </Kitten.Select>
            </Kitten.ViewH>
          </View>
        </>
      );
    } catch (error) {}
  }, [query, selectedPageIndex, selectedTypeIndex, members]);
}

interface dSelect {
  onChange(userSelected: string): void; // user selected passes the user id of the user-selected
  flexDirection: "row" | "column";
  where?: WhereItem<UserSchema> /*
  used to pass query to this components
  the where clause allow us to compare multiple parameters
  we could have something like 
  where teamId, ==, "apptakeoff"
  this then returns all users under the teamId apptakeoff
 * 
 * */;
}

type WhereItem<Doc extends object = {}, key = keyof Doc> = [key, "==", unknown];

const A = {
  InputSearch: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
};
