import { Buttoon, sstyled, Toasty, Txt } from "components/atoms/generals";
import { useBreakpointIndex } from "dripsy";
import { fn, MediaPageItemSchema } from "engines";
import { updateThumbnail } from "engines/firebase/handler/page-contents-handler";
import { Audio as EXAudio, VideoProps } from "expo-av";
import { INTERRUPTION_MODE_IOS_DO_NOT_MIX } from "expo-av/build/Audio";
import React, { useRef } from "react";
import {
  ImageBackground,
  Platform,
  Pressable,
  useWindowDimensions,
  View
} from "react-native";
import Sound from "react-native-sound";
import WebView from "react-native-webview";
import { useNavigator } from "screens/_navigation/navigation-utils";
import { IS_WEB, tr } from "utils";
// import { useNavigator } from "screens/_navigation";
import { AndroidVideolly } from "./android-videolly";
// import Video from "react-native-video";
import Video from "./native-video";
import { VideoPlayImage } from "./video-play-image";
// const Video = IS_WEB ? require("expo-av").Video : require("react-native-video");

export const REQUIRED_COMPLETION_PERCENT = 0.88;

/**
 * ### An Video component for the project
 *  - With Optimized source for faster loading
 *  ----
 *  @example
 *  <Videolly
 *  type="component"  
 *  source={{ uri: 'http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4' }} 
    />
 *  ----
 *  @version 1.5.27
 *  -  *Handle internal ref and external ref*
 *  @author  Nl, K
 *
 **/
export const Videolly = React.forwardRef<VideoProps, dVideolly>((P, ref) => {
  const {
    pageItem: item,
    onComplete,
    itemString,
    source: _source,
    editModeEnabled,
    type,
    playInView,
  } = P;
  const source = _source ? _source : { uri: item?.media };

  //#region [section2]
  const getThumbnailFromURL = fn?.media?.getThumbnailFromURL;
  const [_thumbnail, setThumbnail] = React.useState<string>(item?.thumbnail);
  React.useEffect(
    function thumbnailSetup() {
      let cloudinaryThumbnail = fn?.media?.getThumbnailFromURL(source?.uri);
      /** If item has NO thumbnail, get the default cloudinaryThumbnail */
      !item?.thumbnail
        ? setThumbnail(cloudinaryThumbnail)
        : setThumbnail(item?.thumbnail);
    },
    [item?.thumbnail, getThumbnailFromURL]
  );
  //#endregion

  const Navigation = useNavigator();
  // const { C } = useAppContext();

  //#region [section] on thumbnail press:
  /**
   * - If type === "component"
   *  - Switch to video component and auto play it by default
   * - type === "thumbnail"
   *  - Open dialog and auto play video by default
   * @returns
   */
  function onThumbnailPress() {
    // if (type === "component") {
    //   setShowComponent(true);
    // } else {
    // setPlayVideoOverlay(true);
    // if (IS_WEB) {
    //   setPlayInView(true);
    //   return;
    // }
    return Navigation.dialogPortal.open({
      headerTitle: item?.title ? item?.title : "Untitled",
      render: <CoreVideolly ref={ref} {...P} />,
      staticVideo: true,
      cardType: "transparent",
    });
    // }
  }
  //#endregion

  //#region [section] on thumbnail edit, show dialog for frame selection
  function onThumbnailEdit() {
    return Navigation.overlayPortal.open({
      headerTitle: item?.title
        ? item?.title + "- Edit Thumbnail"
        : "Untitled - Edit Thumbnail",
      render: <ThumbnailEditor ref={ref} {...P} />,
    });
  }
  //#endregion

  return playInView ? (
    <CoreVideolly ref={ref} {...P} showTitle={false} />
  ) : (
    // <Video
    //   ref={ref}
    //   style={[{ borderRadius: 10 }]}
    //   shouldPlay={true}
    //   resizeMode={"contain"}
    //   useNativeControls
    //   {...P}
    // />
    <Pressable onPress={onThumbnailPress}>
      {/* <Txt>{JSON.stringify(item)}</Txt> */}
      <ImageVideollyThumbnail
        source={{ uri: item?.thumbnail || getThumbnailFromURL(source?.uri) }}
      >
        <VideoPlayImage service={"default"} />
      </ImageVideollyThumbnail>
      {source?.uri?.includes("cloudinary") && editModeEnabled && (
        <Buttoon appearance={"ghost"} onPress={onThumbnailEdit}>
          {tr("Edit Thumbnail")}
        </Buttoon>
      )}
    </Pressable>
  );
});

/**
 * ### Core videolly component
 * -  Display as a dialog if `type: "thumbnail"`
 * -  Display as a component if `type: "component"`
 * ---
 * **Few important func**
 * -  Check for completion
 *  As video duration reach to 94% (.94), `onComplete()` callback will be fired
 * -  If source is Cloudinary:
 *  - Allow user to set thumbnail image by choosing specific frame of video
 *
 */
export const CoreVideolly = React.forwardRef<VideoProps, dVideolly>(
  (P, ref) => {
    const {
      pageItem: item,
      onComplete,
      itemString,
      source,
      cpid,
      editModeEnabled,
      onDurationChange,
      showTitle,
    } = P;

    // let pcid = item?._id;

    const breakpoint = useBreakpointIndex();

    //#region [section] Video handler
    // const refVideo = React.useRef<VideoProps>(null);
    // useImperativeHandle(ref, () => refVideo.current);

    // const [status, setStatus] = React.useState<AVPlaybackStatus>(null);

    const [_thumbnail, setThumbnail] = React.useState<string>(item?.thumbnail);
    // const [playPosition, setPlayPosition] = React.useState<number>(0);
    //#endregion

    const window = useWindowDimensions();

    const [didComplete, setDidComplete] = React.useState<boolean>(false);
    const _didComplete = React.useRef<boolean>(false);

    /**
     * Enables iOS to play audio from video even when in silent mode
     */
    // React.useEffect(function enableSilentModeAudio() {
    //   EXAudio.setAudioModeAsync({
    //     allowsRecordingIOS: false,
    //     interruptionModeIOS: INTERRUPTION_MODE_IOS_DO_NOT_MIX,
    //     playsInSilentModeIOS: true,
    //     staysActiveInBackground: true,
    //   });
    // }, []);
    EXAudio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      staysActiveInBackground: true,
    });
    !IS_WEB && Sound.setCategory("Playback");

    /**
     * This is a workaround for web since we can't track the video progress
     * - not needed since progress is trackable with html <video> tag
     */
    // React.useEffect(function completeIfWEB() {
    //   // if the user is on web, complete the video immediately
    //   if (IS_WEB && didComplete === false) {
    //     setDidComplete(true);
    //     _didComplete.current = true;
    //     onComplete && onComplete(itemString);
    //   }
    // }, []);

    function completeWithProgressIfCan(progress: number) {
      if (
        !didComplete &&
        !_didComplete.current &&
        progress > REQUIRED_COMPLETION_PERCENT
      ) {
        setDidComplete(true);
        _didComplete.current = true;
        onComplete && onComplete(itemString); // && itemString
      }
    }

    function onPlaybackStatusUpdate(
      currentMillis: number,
      totalMillis: number
    ) {
      console.log(currentMillis);
      // const position = status?.positionMillis;
      // setPlayPosition(currentTime);
      // const totalDuration = status?.durationMillis;
      onDurationChange && onDurationChange(currentMillis, totalMillis);
      const progress = currentMillis / totalMillis;
      completeWithProgressIfCan(progress);
      // setStatus(status);
    }

    const responsiveStyle =
      breakpoint <= 2
        ? {
            width: window.width,
            height: (window.width * 9) / 16,
          }
        : {
            // width: IS_WEB ? "100%" : 800,
            // height: IS_WEB ? "100%" : 450,
            width: 800,
            height: 450,
          };

    // used for web
    const videoRef = useRef(null);

    return Platform.OS !== "android" ? (
      <View
        style={{
          alignItems: "center",
        }}
      >
        {showTitle ? (
          <Txt.H6 style={{ alignSelf: "flex-start" }}>
            {item?.title || ""}
          </Txt.H6>
        ) : null}
        {/** react-native-video and expo-av are not working on web currently... :( But this works great! :D */}
        {IS_WEB ? (
          <video
            ref={videoRef}
            src={source && source.uri ? source.uri : P.pageItem?.media}
            style={responsiveStyle}
            controls
            autoPlay
            onTimeUpdate={(e) => {
              // the following numbers are multiplied by 1000 in order to convert them to milliseconds
              onPlaybackStatusUpdate(
                //@ts-ignore
                videoRef.current?.currentTime * 1000,
                //@ts-ignore
                videoRef.current?.duration * 1000
              );
            }}
          />
        ) : (
          // <A.CtnrWebView
          //   // onLoad={() => {
          //   //   onComplete && itemString && onComplete(itemString);
          //   // }}
          //   allowsFullscreenVideo
          //   scrollEnabled={false}
          //   automaticallyAdjustContentInsets
          //   javaScriptEnabled={true}
          //   domStorageEnabled={true}
          //   scalesPageToFit
          //   source={P.source || { uri: P.pageItem?.media }}
          //   style={[{ borderRadius: 10 }, responsiveStyle]}
          // />
          <Video
            // ref={refVideo}
            onPlaybackStatusUpdate={(e) =>
              onPlaybackStatusUpdate(e.positionMillis, e.durationMillis)
            }
            onProgress={(e) => {
              // @ts-ignore
              onPlaybackStatusUpdate(e.currentTime, e.playableDuration);
            }}
            style={[responsiveStyle]}
            // may not apply until future expo-av version
            // videoStyle={responsiveStyle}
            // shouldPlay={true}
            resizeMode={"contain"}
            useNativeControls
            controls
            // fullscreen
            // {...P}
            // style={P.style ? P.style : {}}
            source={P.source || { uri: P.pageItem?.media }}
          />
        )}
      </View>
    ) : (
      <AndroidVideolly
        //@ts-ignore
        uri={source.uri}
        onProgress={(status) => {
          const progress = status.currentTime / status?.seekableDuration;
          completeWithProgressIfCan(progress);
        }}
      />
    );
  }
);

/**
 *
 */
const ThumbnailEditor = React.forwardRef<VideoProps, dVideolly>((P, ref) => {
  const { pageItem: item, source, cpid } = P;

  let pcid = item?._id;
  const Navigation = useNavigator();

  //#region [section] Video handler
  // const refVideo = React.useRef<VideoProps>(null);
  // useImperativeHandle(ref, () => refVideo.current);

  // const [status, setStatus] = React.useState<AVPlaybackStatus>(null);

  const [_thumbnail, setThumbnail] = React.useState<string>(item?.thumbnail);
  const [playPosition, setPlayPosition] = React.useState<number>(0);

  function onDurationChange(currentMillis: number, totalMillis: number) {
    // const position = status?.positionMillis;
    setPlayPosition(currentMillis);
    // setStatus(status);
  }
  //#endregion

  return (
    <View style={{ alignItems: "center" }}>
      <CoreVideolly
        // ref={refVideo}
        // onPlaybackStatusUpdate={onPlaybackStatusUpdate}
        shouldPlay={false}
        {...P}
        onDurationChange={onDurationChange}
      />

      <>
        <Txt.Helper marginV>{tr("Select a frame in the video.")}</Txt.Helper>
        <Buttoon
          status="basic"
          onPress={async () => {
            console.log("Setting thumbnail to frame at: " + playPosition);
            const newThumbnail = fn.media.getThumbnailFromURL(
              source?.uri,
              playPosition / 1000
            );
            if (!cpid || !pcid) {
              console.log(
                "Thumbnail could not be updated because no cpid and pcid were provided to make the change."
              );
              Toasty.show("Failed to set thumbnail. Contact a developer.", {
                type: "danger",
              });
              return;
            }
            Toasty.show("Updating thumbnail...", { type: "loading" });
            try {
              await updateThumbnail({
                cpid,
                pcid,
                thumbnail: newThumbnail,
              });
            } catch (err) {
              Toasty.show(
                "Failed to update thumbnail. Something went wrong. " + err,
                { type: "danger" }
              );
              console.log("failed to set thumbnail with error: " + err);
              return;
            }
            setThumbnail(newThumbnail);
            Toasty.show("Thumbnail update complete!", {
              type: "success",
            });
            console.log("new thumbnail set to: " + newThumbnail);
            Navigation.goBack();
          }}
        >
          {tr("Set Thumbnail to Selected Frame: ") +
            fn.media.msToTime(playPosition)}
        </Buttoon>
      </>
    </View>
  );
});

// const VideoComponent = React.forwardRef<VideoProps, dVideolly>((P, ref) => {
//   const { pageItem: item, source, cpid } = P;

//   // const Navigation = useNavigator();

//   //#region [section] Video handler
//   const refVideo = React.useRef<VideoProps>(null);
//   useImperativeHandle(ref, () => refVideo.current);
//   const window = useWindowDimensions();

//   return (
//     <Video
//       ref={refVideo}
//       shouldPlay={false}
//       resizeMode={"contain"}
//       useNativeControls
//       // {...P}
//       style={[
//         P.style ? P.style : {},
//         { borderRadius: 10 },
//         IS_WEB
//           ? { width: scale(800), height: scale(450) }
//           : {
//               width: window.width * 0.9,
//               height: (window.width * 0.9 * 9) / 16,
//             },
//       ]}
//     />
//   );
// });

/**
 * Thumbnail for videolly component,
 *  - where they click on it to open Videolly Overlay
 */
export const ImageVideollyThumbnail = sstyled(ImageBackground)({
  width: "100%",
  height: [250, 300, 350, 400],
  // height: 300,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "surface",
  borderRadius: 10,
  overflow: "hidden",
});

export interface dVideolly extends VideoProps {
  showTitle?: boolean;
  /**
   * -  Display as a dialog if `type: "thumbnail"`
   * -  Display as a component if `type: "component"`
   */
  type: "component" | "thumbnail";
  /**
   * page's item data to interact with Videolly
   */
  pageItem?: MediaPageItemSchema;
  /**
   * Will get passed with the onComplete() when called so the database knows what item to complete
   * - originally generated by levels-handler.tsx
   */
  itemString?: string;
  /**
   * If set to true, will play the native video on top of the thumbnail instead of in a modal view
   * - Modal views on iPhone disables native controls now randomly :(
   */
  playInView?: boolean;
  onComplete?: (itemString?: string) => void;
  /**
   * Primary reason is to be able to set the video thumbnail
   */
  editModeEnabled?: boolean;
  /** Used mainly for updating thumbnail  */
  cpid?: string;
  onDurationChange?: (positionMillis: number, durationMillis: number) => void;
}

const A = {
  CtnrWebView: sstyled(WebView)({
    width: "100%",
    height: [250, 300, 350, 400],
    backgroundColor: "black",
    borderRadius: 10,
    overflow: "hidden",
  }),
};
