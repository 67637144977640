/**
 * ###  Returns a URI to the thumbnail image for the video
 * - Optionally set the frame in the video you want a thumbnail image for
 *
 * @author jm_francis
 * @version 1.12.1
 *
 * @example
 * const thumbnailURI = getThumbnailFromURL("cloudinary.com/my_url.mp4")
 *
 * @example
 * const thumbnailURI = getThumbnailFromURL(url, 23)
 */
export function getThumbnailFromURL(cloudinaryUrl: string, frame?: number): string {
  let thumbnailURL = cloudinaryUrl
    .replace(".mp4", ".jpg")
    .replace(".mov", ".jpg")
    .replace("HEIC", "jpg");
  if (frame) {
    thumbnailURL = thumbnailURL.replace(
      "/upload/",
      `/upload/so_${Math.round(frame)}/`
    );
  }
  return thumbnailURL;
}

/**
 * ###  Returns a modified URI that blurs any extra space in the video or image
 *
 * @author jm_francis
 * @version 1.2.12
 *
 * @example
 * const url = blurredBorderFromURL("cloudinary.com/my_url.mp4")
 */
export function withBlurredBorderUri(url: string): string {
  return url.replace("/upload", "/upload/c_pad,h_320,w_480,b_blurred:400:15/");
}
