//#region [import]
import {
  deleteDocument
} from "@nandorojo/swr-firestore";
import { FPATH, useAppContext } from "engines";
import { MailSchema } from "../schemas/mail/mail.schema";

//#endregion

/**
 * ### Possts Collection Hooks
 *  - Standard GEAUX API
 *
 *  ----
 *  @example
 *  const handlePossts = usePosstsCollection()
 *  ----
 *  @version 21.03.07
 *  -  *Build the hook*
 *  @author  K
 *
 **/
export function useMassMailFunctions<
  Schema extends MailSchema /**👈 Rep  lace Schema here*/
>() {
  

  const { frbsUser } = useAppContext()

  

  /**
   * ### "Add" a document to Possts Collection
   *  - If no data is passed, default value will be used
   *  - This function makes sure all fields is validated before save it in frbs
   *  - All blank fields will be filled with default values
   *  ----
   *  @example
   *  handlePossts.add("mock") //* for mock data (e.g. quick testing)
   *  handlePossts.add({ body: "You're goddamn right, I'm not goddamn wrong"})
   *  ----
   *  @version 21.03.12
   *  -  *Add 4hm*
   *  @author  Nl
   *
   **/
  async function add(data) {
    let projectId = "primr-exp";
    const _token = await frbsUser.getIdToken();

    try {
      return (
        _token &&
        fetch(
          `https://us-central1-${projectId}.cloudfunctions.net/api/services/emails/send-email`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(data),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + _token,
            },
          }
        )
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * ### "Add" a document to Possts Collection
   *  - If no data is passed, default value will be used
   *  - This function makes sure all fields is validated before save it in frbs
   *  - All blank fields will be filled with default values
   *  ----
   *  @example
   *  handlePossts.add("mock") //* for mock data (e.g. quick testing)
   *  handlePossts.add({ body: "You're goddamn right, I'm not goddamn wrong"})
   *  ----
   *  @version 21.03.12
   *  -  *Add 4hm*
   *  @author  Nl
   *
   **/
  async function addbatch(data) {
    let projectId = "primr-exp";
    const _token = await frbsUser.getIdToken();
    try {
      return (
        _token &&
        fetch(
          `https://us-central1-${projectId}.cloudfunctions.net/api/services/emails/send-email-batch`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(data),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + _token,
            },
          }
        )
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * ### "Add" a document to Possts Collection
   *  - If no data is passed, default value will be used
   *  - This function makes sure all fields is validated before save it in frbs
   *  - All blank fields will be filled with default values
   *  ----
   *  @example
   *  handlePossts.add("mock") //* for mock data (e.g. quick testing)
   *  handlePossts.add({ body: "You're goddamn right, I'm not goddamn wrong"})
   *  ----
   *  @version 21.03.12
   *  -  *Add 4hm*
   *  @author  K
   *
   **/
  async function sendErrorEmail(data) {
    let projectId = "primr-exp";
    const _token = await frbsUser.getIdToken();
    try {
      return (
        _token &&
        fetch(
          `https://us-central1-${projectId}.cloudfunctions.net/api/services/error/send-email`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(data),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + _token,
            },
          }
        )
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * ### "Delete" the document out of Possts Collection
   *  - Using its _id
   *  ----
   *  @example
   *  handlePossts.x("0x29104jfasda289l")
   *  ----
   *  @version 21.03.12
   *  -  *Document*
   *  @author  K
   *
   **/
  function x(pid: Schema["MessageID"] /**👈 Replace with appropiate id */) {
    if (typeof pid !== "string")
      return Error("pid is not a string. Please correct the input");

    return deleteDocument<Schema>(`${FPATH.EMAIL}/${pid}`);
  }

  return {
    add,
    addbatch,
    x,
    sendErrorEmail,
  };
}




