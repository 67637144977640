import { Buttoon } from "components";
import { useTwoColumns } from "components/templates";
import {
  useActivityCollection,
  useAppContext
} from "engines";
import {
  dateToStr,
  getNormalDate
} from "engines/firebase/handler/activity-handler";
import * as React from "react";
import { useNavigator } from "screens/_navigation";
import { tr } from "utils";
import { StatsCreatorCenter } from "./StatsCreatorCenter";

/**
 * ### Stats Creator Section
 *  - Section where stats creator button is located
 *  - On a big screen, it's a button; on small screen, it's a plus icon that navigate directly to StatsCreatorDialog
 *  ----
 *  @example
 *  - See 2nd column of Your Stats screen
 *  ----
 *  @version 23.02.23
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function StatsCreatorSection() {
  const { C, frbsUser, teamId } = useAppContext();
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();
  const activityHandler = useActivityCollection(frbsUser?.uid);
  const today = new Date();

  function isButtonDisabled() {
    return activityHandler.data?.find(
      (ac) => dateToStr(getNormalDate(ac?.date)) === dateToStr(today)
    )
      ? true
      : false;
  }
  function onPress() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Enter Today's Stats"),
      render: <StatsCreatorCenter />,
    });
  }

  // React.useEffect(
  //   function openDialog() {
  //     if (!breakpointForTwoColumns) {
  //       onPress()
  //       // if (isButtonDisabled()) {
  //       //   Navigation.goBack();
  //       //   Toasty.show(tr("You cannot add new stats today"), { type: "danger" });
  //       // } else {
  //       //   onPress();
  //       // }
  //     }
  //   },
  //   [breakpointForTwoColumns]
  // );

  return (
    <Buttoon
      status={isButtonDisabled() ? "basic" : "primary"}
      appearance="ghost"
      // disabled={isButtonDisabled()}
      onPress={onPress}
      icon={{ name: "plus" }}
    >
      {tr("Enter Today's Stats")}
    </Buttoon>
  );
}
