import {
  getMediaTypeFromString,
  ItemType
} from "../../backends/custom-media-handler/TypeProvider";

/**
 * ### Extract Media Type (image/photo) from uri
 *  - Used with expo-media-picker
 *  ----
 *  @example
 *  let uri = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="
 *  let _mediaType = fn.medias.extractMediaType(uri)
 *  //* result: _mediaType: "image" | "video" | null
 *  ----
 *  @version 21.05.14
 *  -  *Build functions*
 *  @author  K
 *
 **/
export function extractMediaType(
  uri: string
): "video" | "image" | "audio" | "" {
  /**
   * ### How this function works
   *
   * e.g. var str = 'one:two;three';
   * str.split(':').pop().split(';')[0]; // returns 'two'
   */
  let _mediaType = uri?.split(":")?.pop()?.split("/")[0];

  if (_mediaType == "image" || _mediaType == "video" || _mediaType == "audio") {
    return _mediaType;
  } else {
    console.warn(
      "Error getting media type (not 'image' or 'video' or 'audio'). Return null value: ",
      _mediaType
    );
    const itemType = getMediaTypeFromString(uri);
    if (itemType === ItemType.PHOTO) return "image";
    if (itemType === ItemType.AUDIO) return "audio";
    if (itemType === ItemType.VIDEO) return "video";
    return "";
  }
}

/**
 * max width is 1400
 */
export function smallerSizeFromURL(
  url: string,
  width: number,
  /**
   * Quality of the image, use a multiple of 10
   * - ex: 10, 20, 30, 40 (I wouldn't go lower than 60 or maybe 50 though)
   */
  quality?: number
): string {
  if (!url) return url;
  const _width = width > 1400 ? 1400 : width
  return url?.replace(
    "/upload/",
    `/upload/${_width ? `w_${Math.round(_width)}/` : ``}${quality ? `q_${quality}/` : ``}`
  );
}

/**
 * ### Convert millisecond time to a string of hh:mm:ss format
 *  - Used with AudioItem component
 *  ----
 *  @example
 *  const milliSecondTime = 1000
 *  console.log(msToTime(milliSecondTime));
 *  //* result: Outputs time in hh:mm:ss format
 *  ----
 *  @version 21.06.12
 *  -  *Build functions*
 *  @author  Saurabh_M
 *
 **/
export function msToTime(s: number) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n: any, z: any) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  const dispHour = hrs !== NaN ? pad(hrs, 2) : "00";
  const dispMin = mins !== NaN ? pad(mins, 2) : "00";
  const dispSec = secs !== NaN ? pad(secs, 2) : "00";

  return dispHour + ":" + dispMin + ":" + dispSec;
}

// /**
//  * @description Validates if the given URL contains video.
//  * @author Saurabh_M
//  * @version 2021.08.17
//  * @param {string} url
//  */
// export async function validateVideoUrl(url: string): Promise<boolean> {
//   let results = false;
//   await fetch(url).then((response) => {
//     console.log("response :>> ", response);
//     const Headers = response.headers;
//     const content = Headers.get("content-type")
//       ? Headers.get("content-type")
//       : "";
//     console.log("content :>> ", content);
//     if (content.includes("video")) {
//       console.log("YES");
//       results = true;
//       return results;
//     } else {
//       console.log("NO");
//       return false;
//     }
//   });
//   return results;
// }

/**
 * @description Validates if the given url is valid or not
 * @author Saurabh_M
 * @version 2021.08.17
 * @param {*} url
 * @return {*}  {boolean}
 */
export function validURL(url: string): boolean {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
}
