import { Document, useDocument, useCollectionGroup, CollectionQueryType } from "@nandorojo/swr-firestore";
import { Toasty } from "components";
import { FPATH, MediaPageItemSchema, URL_PREFIX, useAppContext } from "engines";
import firebase from "firebase";
import _ from "lodash";
import * as R from "ramda";
import { dUseCollectionReturns, useCollectionPresetv2 } from "../config";

export function usePageContentDocument(
  pageId: string,
  pageContentId: string,
  query?
) {
  const swrDocument = useDocument<MediaPageItemSchema>(
    `${FPATH.PAGES}/${pageId}/${FPATH.CUSTOM_PAGE_CONTENT}/${pageContentId}`,
    {
      listen: true,
      ...query,
    }
  );

  return { ...swrDocument };
}

export type UsePageContentsCollectionType = dUseCollectionReturns<
  MediaPageItemSchema,
  Document<MediaPageItemSchema>
> & {
  _updatePosition: (data: UpdatePositionData) => Promise<any>;
  _largestPositionIndex: () => number;
  _getSubPagesId: () => string[];
  _updateAllPageContentsData: (
    updateData: Partial<MediaPageItemSchema>
  ) => Promise<void>;
};

export function usePageContentsCollection(
  pageId: string,
  query?
): UsePageContentsCollectionType {
  const swrCollection = useCollectionPresetv2<MediaPageItemSchema>(
    `${FPATH.PAGES}/${pageId}/${FPATH.CUSTOM_PAGE_CONTENT}`,
    "_id",
    { orderBy: ["position", "asc"], ...query }
  );

  function _getSubPagesIds() {
    const list = swrCollection.data;
    const subPagesIds = list
      ?.map(
        (pc) =>
          pc.url?.includes(URL_PREFIX.PAGE) && pc.url?.split(URL_PREFIX.PAGE)[1]
      )
      .filter((p) => {
        return p;
      });
    return subPagesIds;
  }

  function _largestPositionIndex() {
    let positions = swrCollection.data
      ? R.pluck("position", swrCollection.data)
        .map(Number)
        .filter((p) => !!p === true && p != Number.NEGATIVE_INFINITY) //* Temporary sol to ignore all NaN values
        ?.length == 0
        ? [0]
        : R.pluck("position", swrCollection.data)
          .map(Number)
          .filter((p) => !!p === true && p != Number.NEGATIVE_INFINITY) //* Temporary sol to ignore all NaN values
      : [0];
    // alert(JSON.stringify(positions) + "\n" + _.max(positions));
    // return Math.max(...positions) !== Number.NEGATIVE_INFINITY ? ;
    return _.max(positions);
  }

  function _updatePosition(data: UpdatePositionData) {
    return updatePosition(data);
  }
  function _updateAllPageContentsData(updateData: MediaPageItemSchema) {
    // console.log("About to unshare content with data ", updateData);
    return updateAllPageContentsData(pageId, updateData);
  }

  return {
    ...swrCollection,
    _updatePosition,
    _largestPositionIndex,
    _getSubPagesId: _getSubPagesIds,
    _updateAllPageContentsData,
  };
}

export function useTextTemplates(
  _teamId: string,
  query?
) {
  const defaultCollectionFunctions = useCollectionGroup<MediaPageItemSchema>(
    FPATH.CUSTOM_PAGE_CONTENT,
    {
      where: [["textTemplate.enabledForTeamId", "==", _teamId]],
      orderBy: ["position", "asc"],
      //@ts-ignore
      listen: true
    },
    { onError: (err) => console.error(err), refreshInterval: 10000 }
  );

  function getPageIds(): string[] {
    const pageIds = []
    for (let i in defaultCollectionFunctions.data) {
      const item = defaultCollectionFunctions.data[i]
      if (!item?.textTemplate) continue;
      if (!pageIds.includes(item.textTemplate.parentPageId)) pageIds.push(item.textTemplate.parentPageId)
    }
    return pageIds;
  }

  return { ...defaultCollectionFunctions, getPageIds };
}


export async function updateAllPageContentsData(
  pageId: string,
  updateData: Partial<MediaPageItemSchema>
) {
  const pageContentBatch = firebase.firestore().batch();

  try {
    const pageContentsRef = await firebase
      .firestore()
      .collection(FPATH.PAGES)
      .doc(pageId)
      .collection(FPATH.CUSTOM_PAGE_CONTENT)
      .get();

    if (!pageContentsRef.empty) {
      for (let i = 0; i < pageContentsRef.docs.length; i++) {
        const docRef = firebase
          .firestore()
          .collection(FPATH.PAGES)
          .doc(pageId)
          .collection(FPATH.CUSTOM_PAGE_CONTENT)
          .doc(pageContentsRef.docs[i].id);

        pageContentBatch.set(docRef, updateData, { merge: true });
      }
      await pageContentBatch.commit();
    }
    console.log("Successfully updated page contents");
  } catch (err) {
    console.log("Error while making change to pageContents data ", err);
  }
}

interface UpdatePositionData {
  /**
   * id of the page
   */
  cpid: string;
  /**
   * id of the item
   */
  pcid: string;
  position: number;
}

/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.09.02
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export const updatePosition = async (data: UpdatePositionData) => {
  try {
    const updatePosition = await firebase
      .firestore()
      .collection(FPATH.PAGES)
      .doc(data.cpid)
      .collection("pageContent")
      .doc(data.pcid)
      .update({
        position: data.position,
      });

    return updatePosition;
  } catch (error) {
    return error;
  }
};

export const addItemToPage = async (
  _pid: string,
  item: MediaPageItemSchema
) => {
  try {
    const pageContentRef = firebase
      .firestore()
      .collection(FPATH.PAGES)
      .doc(_pid)
      .collection(FPATH.CUSTOM_PAGE_CONTENT);
    const resp = await pageContentRef.add(item);
    return await pageContentRef.doc(resp.id).update({ _id: resp.id });
  } catch (error) {
    Toasty.show(error, { type: "error" });
    // alert(error);
    return error;
  }
};

interface UpdateItemData {
  /**
   * id of the page
   */
  cpid: string;
  /**
   * id of the item
   */
  pcid: string;
  /**
   * Whatever you want to update about the media item
   */
  data: Partial<MediaPageItemSchema>;
}

/**
 * # Update a field within a custom media page item
 *
 * @author jm_francis
 *
 * updateItem({ cpid, pcid, { title: "Something else" } })
 */
export const updateItem = async (options: UpdateItemData) => {
  try {
    return firebase
      .firestore()
      .collection(FPATH.PAGES)
      .doc(options.cpid)
      .collection(FPATH.CUSTOM_PAGE_CONTENT)
      .doc(options.pcid)
      .update(options.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * ###
 *  - Set a custom thumbnail image for a resource
 *  ----
 *  @example
 *  updateThumbnail({cpid: "", pcid: "", thumbnail: "https://website.com/logo.png"})
 *  ----
 *  @version 21.12.01
 *  @author  JMF
 *
 **/
export const updateThumbnail = async (data: {
  cpid: string;
  pcid: string;
  thumbnail: string;
}) => {
  try {
    const response = await firebase
      .firestore()
      .collection(FPATH.PAGES)
      .doc(data.cpid)
      .collection("pageContent")
      .doc(data.pcid)
      .update({
        thumbnail: data.thumbnail,
      });

    return response;
  } catch (error) {
    return error;
  }
};
