import * as EXContact from "expo-contacts"
import firebase from "firebase"
import { IconPrimr } from "assets";
import { Toasty } from "components";
import { ShareAccountDialog } from "components/organisms/Teammates/ShareAccountDialog";
import { ActivityIndicator } from "dripsy";
import { useAppContext, useUserProfile } from "engines";
import {
  getBMLContactsFromDB,
  getKeys,
  getUserContactGroup,
  createBMLContactGroup
} from "engines/backends/auth/AuthHandler";
import { ContactGroupSchemaa, CONTACTGROUPTYPE, ContactSchemaa } from "engines/backends/schemas/bml";
import {
  BmlQSchema,
  defaultQuestions,
  mergeArrays,
  QuestionType
} from "engines/backends/schemas/bml/bml.schema";
import {
  dConfigVariables,
  dListBuilderList,
  FPATH
} from "engines/firebase/firebase.props";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import _, { uniqueId } from "lodash";
import React, { useRef, useState } from "react";
import { Alert, Platform, ScrollView, useWindowDimensions } from "react-native";
import ConfettiCannon from "react-native-confetti-cannon";
import { useNavigator } from "screens/_navigation";
import { tr } from "utils";
import { androidPrecheckIfNeeded } from "../../../engines/functions/contacts-functions/fetchContacts";
import { BMLNavigation } from "./BMLNavigation";
import { BMLWelcomeScreen as MessageScreen } from "./MessageScreen";
import { QualificationScreen } from "./QualificationScreen";
import { QuestionScreen } from "./QuestionScreen";
import { goldenRatio, SSBM } from "./StyledComponents.BML-Mobile";

export function BMLMobile(props: BMLMobileProps) {
  const _retake = props?.route?.params?.retake;
  const [retake, setRetake] = useState(_retake ? _retake : false);
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();

  const { C, teamId, frbsUser } = useAppContext();
  const { bmlContacts, setBmlContacts, permStatus, getPermissions } =
    useBMLMContext();
  const Navigation = useNavigator();
  const user = useUserProfile();
  const admin = user._isUserAdmin();

  const scrollViewRef = useRef<ScrollView>(null);
  const confettiRef = useRef<ConfettiCannon>(null);

  const [screens, setScreens] = useState<BmlQSchema[]>([]);
  const [ready, setReady] = useState(false);
  const [contactIds, setContactIds] = useState<string[]>([]);
  const [shareDialogue, setShareDialogue] = useState(false);
  const [qualificationScreenIdx, setQualificationScreenIdx] = React.useState(0);
  const [skipToQualify, setSkipToQualify] = React.useState(false);
  const [skipped, setSkipped] = React.useState(false);
  const [bmlListExists, setBmlListExists] = useState(false);
  const [existingBMLContacts, setExistingBMLContacts] = React.useState<
    ContactSchemaa[]
  >([]);

  const getBMLData = async () => {
    const configData = await getKeys(teamId);
    const configVariables: dConfigVariables = configData[1];

    const bmlQuestionnaire =
      configVariables.bmlQuestionnaire &&
        configVariables.bmlQuestionnaire.length > 0
        ? configVariables.bmlQuestionnaire
        : defaultQuestions;

    // console.log("🔥 bmlQuestionnaire is ", bmlQuestionnaire.length);

    const qualificationScreen: BmlQSchema = {
      _qid: "10",
      _teamId: teamId,
      questionType: QuestionType.QUALIFICATION,
      question: null,
      mediaUri: null,
      points: null,
      createdAt: new Date(),
    };

    //NOTE: This is to handle the Qualification Screen and put it at the end of where the current videos are located
    //      If no videos are found then find the Cofetti Screen and place it before it.
    const qualifIndex = bmlQuestionnaire.findIndex(
      (q) => q.questionType === QuestionType.QUALIFICATION
    );
    if (qualifIndex === -1) {
      const index = bmlQuestionnaire.findIndex(
        (q) => q.questionType === QuestionType.VIDEO
      );
      if (index === -1) {
        const confetti = bmlQuestionnaire.filter(
          (q) => q.questionType === QuestionType.CONFETTI
        );
        let lastIndex = -1;
        if (confetti.length === 0) {
          const questionnaire = bmlQuestionnaire.filter(
            (q) => q.questionType === QuestionType.TEXT
          );
          // If no confetti screen found then place the qualification screen after the last question's index
          lastIndex =
            bmlQuestionnaire.lastIndexOf(
              questionnaire[questionnaire.length - 1]
            ) + 1;
        } else {
          // Place the Qualification screen before the confetti screen
          lastIndex =
            bmlQuestionnaire.lastIndexOf(confetti[confetti.length - 1]) - 1;
        }
        if (lastIndex > 0) {
          bmlQuestionnaire[lastIndex] = qualificationScreen;
        }
      } else {
        bmlQuestionnaire[index] = qualificationScreen;
      }
    }

    bmlQuestionnaire && bmlQuestionnaire.length > 0
      ? setScreens(
        bmlQuestionnaire.filter((q) => q.questionType !== QuestionType.VIDEO)
      )
      : setScreens(defaultQuestions);
  };

  // This will grab all the unqualified (qualifications array empty or doesn't exists) contacts from the BML list of contacts.
  const getBMLContacts = async () => {
    let userLists = await getUserContactGroup(user?.data._id);

    if (!!userLists && userLists.length > 0) {
      //NOTE Step 3: If yes then check if there is "Build My List" list available & that it has data.
      if (!userLists.find((l) => l?._id === "BML")) {
        userLists.push({
          _id: "BML",
          name: "Build My List",
          groupType: CONTACTGROUPTYPE.LIST,
          contacts: _.uniq(contactIds),
          shareTo: [],
        });
      } else {
        const index = userLists.findIndex((l) => l._id === "BML");
        userLists[index].contacts = _.uniq(userLists[index].contacts);

        // console.log("🚀 => allBMLContacts data gather ");

        const allBMLContacts = await getBMLContactsFromDB(user?.data._id);

        // console.log("🚀 => allBMLContacts ", allBMLContacts.length);

        setExistingBMLContacts(allBMLContacts);
        //NOTE Get contacts that are not app users (_cid doesn't include uid:) and have not been qualified
        const filteredContacts = allBMLContacts
          .filter(
            (c) =>
              !Object.keys(c).includes("qualifications") ||
              c.qualifications?.length === 0
          )
          .filter((c) => !c._cid.includes("uid:"));

        //NOTE only grab contacts from BML list with no qualifications done yet and skip app users (users with uid: in their _cid)
        if (filteredContacts?.length > 0) {
          // console.log("In BMLMobile setting bmlContacts");
          setBmlContacts(filteredContacts);
          setSkipToQualify(true);
        } else {
          setReady(true);
        }
        // console.log("allBMLContacts length = ", allBMLContacts.length);
        if (allBMLContacts.length > 0) {
          setBmlListExists(true);
        } else {
          setBmlListExists(false);
        }
      }
    } else {
      //NOTE Step 4: If not(length===0) then create default list from config.variable-<hieararchy> (save to contact-groups)
      //        and save it with bmlList after finishing the activity.
      userLists = await createUserBMLListAndData();
    }
  };

  const createUserBMLListAndData = async () => {
    //console.log("Running setUserListData");

    const defaultBMLList: dListBuilderList[] = [
      { title: "Build My List", id: "BML", contacts: [] },
    ];

    //Step 5: Convert legacy data from BML to new data structure. (Priorities: A,B,C to Everyone else and points = [] (empty array))
    // Fetch Legacy Data List from user document
    // ==> Map it to ContactGroupSchema
    // ==> and remove growth.ListBuilder from user document

    const result = await createBMLContactGroup(user?.data._id, defaultBMLList);
    // console.log("result.length :>> ", result.length);

    return result;
  };

  //#region [section] //NOTE Setting up BML List Data and fetching unqualified contacts
  React.useEffect(() => {
    getBMLData().then(() => getBMLContacts());
    // console.log("🚀 Existing unqualified BML Contacts = ", bmlContacts.length);
  }, [permStatus]);

  React.useEffect(() => {
    // console.log("existing cts len ", existingBMLContacts.length);
    if (bmlListExists && !retake) {
      setBmlContacts(existingBMLContacts);
      Navigation.goBack();
      Navigation.navigate("bml-mobile-list");
      setBmlListExists(false);
    } else {
      if (existingBMLContacts.length > 0) {
        setReady(true);
      } else if (screens.length > 0) {
        setTimeout(() => setReady(true), 500);
      }
    }
  }, [bmlListExists, screens, existingBMLContacts]);
  //#endregion

  //#region [ANCHOR2] //NOTE Identifiying when the qualification screen should appear
  React.useEffect(() => {
    if (screens.length > 0) {
      setQualificationScreenIdx(
        screens.findIndex((q) => q.questionType === QuestionType.QUALIFICATION)
      );
    }
  }, [screens]);
  //#endregion

  //#region [section2] //NOTE Get permission to read phone contacts
  React.useEffect(() => {
    androidPrecheckIfNeeded().then(() => {
      // Alert.alert("Grant permission?");
      getPermissions();
    });
  }, []);
  //#endregion

  // #region [section] //NOTE Setting the BML Settings option for Admins only
  React.useLayoutEffect(() => {
    admin
      ? Navigation.setOptions({
        headerRight: () =>
          ready ? (
            <IconPrimr
              preset="circular"
              name={"pencil"}
              color={C.primary}
              onPress={() => {
                // Navigation.navigate("Home");
                Alert.alert(
                  "⚠️ Warning !! ",
                  "You'll lose your progress if you go to settings. Ok to Proceed ? ",
                  [
                    { text: "No", onPress: () => { } },
                    {
                      text: "Yes",
                      onPress: () => {
                        Navigation.goBack();
                        Navigation.navigate("bml-mobile-settings");
                      },
                    },
                  ]
                );
              }}
            />
          ) : null,
      })
      : null;
  }, [Navigation]);
  // #endregion

  // #region [ANCHOR] //NOTE If there are unqualified contacts present then present an option to skip to qualification
  const handleSkip = () => {
    if (qualificationScreenIdx > 0) {
      scrollViewRef.current.scrollTo({
        x: screenWidth * qualificationScreenIdx,
        animated: true,
      });
      setSkipped(true);
    }
  };
  // #endregion

  const refContactLists = React.useRef<ContactSchemaa[][]>([]);
  function onContactsUpdate(screenIndex: number, contacts: ContactSchemaa[]) {
    if (refContactLists.current.length > screenIndex) {
      for (let x = 0; x < screenIndex - refContactLists.current.length; x++)
        refContactLists.current.push([]);
    }
    refContactLists.current[screenIndex] = contacts;
  }

  try {
    return !ready ? (
      <ActivityIndicator
        style={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: C.background,
          flex: 1,
        }}
        size="large"
        color={C.primary}
      />
    ) : (
      <SSBM.KAV
        style={{ backgroundColor: C.background }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -195 : 20}
      >
        <ConfettiCannon
          count={200}
          origin={{ x: -screenWidth, y: 0 }}
          fadeOut
          ref={confettiRef}
          autoStart={false}
        />
        <ScrollView
          contentInset={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
          horizontal
          snapToInterval={screenWidth}
          decelerationRate={"fast"}
          showsHorizontalScrollIndicator={false}
          bounces={true}
          ref={scrollViewRef}
          scrollEnabled={false}
          style={{ height: screenHeight * goldenRatio }}
          keyboardShouldPersistTaps={"handled"}
        >
          {screens.map((screen: BmlQSchema, index: number) => {
            if (!screen) return null;
            const qType = screen.questionType;
            switch (qType) {
              case QuestionType.WELCOME:
                return qualificationScreenIdx > 0 ? (
                  <MessageScreen
                    key={screen._qid}
                    data={screen}
                    skipToQualify={skipToQualify}
                    handleSkip={handleSkip}
                  />
                ) : (
                  <MessageScreen key={screen._qid} data={screen} />
                );
                break;
              case QuestionType.CONFETTI:
                return <MessageScreen key={screen._qid} data={screen} />;
                break;
              case QuestionType.ENCOURAGEMENT:
                return <MessageScreen key={screen._qid} data={screen} />;
                break;
              case QuestionType.TEXT:
                return (
                  <QuestionScreen
                    key={screen._qid}
                    data={screen}
                    qualificationScreenIndex={qualificationScreenIdx}
                    thisIndex={index}
                    onContactsUpdate={onContactsUpdate}
                  />
                );
                break;
              case QuestionType.VIDEO:
              case QuestionType.QUALIFICATION:
                return (
                  <QualificationScreen
                    key={screen._qid}
                    qualificationScreenIndex={qualificationScreenIdx}
                    thisIndex={index}
                  />
                );
                break;
            }
          })}
        </ScrollView>
        {shareDialogue
          ? Navigation.dialogPortal.open({
            headerTitle: tr("Share Your Account"),
            render: <ShareAccountDialog />,
          })
          : null}
        <BMLNavigation
          onScreenIndexChange={(idx) => {
            let allContactsCombined: ContactSchemaa[] = [];
            for (let b in refContactLists.current) {
              const blist = refContactLists.current[b];
              // allContactsCombined = allContactsCombined.concat(blist);
              allContactsCombined = mergeArrays(
                allContactsCombined,
                blist,
                "_cid"
              );
            }
            Toasty.show(`Saving progress...`, {
              type: "loading",
              duration: 700,
            });
            setBmlContacts(allContactsCombined);
            setQualificationScreenIdx(idx);

            // SAVE TO FIRESTORE :D
            try {
              let contactStrings: string[] = []
              const batchUpdates = firebase.firestore().batch()
              for (let a in allContactsCombined) {
                const _newContact = allContactsCombined[a]
                const contactEmail = (_newContact.emails as EXContact.Email[])?.length > 0 ? (_newContact.emails[0] as EXContact.Email).email : _newContact.email?.length > 0 ? _newContact?.email : null
                const contactPhone = (_newContact.phoneNumbers as EXContact.PhoneNumber[])?.length > 0 ? (_newContact.phoneNumbers[0] as EXContact.PhoneNumber)?.number : null
                let _contactId = _newContact._cid || _newContact.id || _newContact.email || contactEmail || contactPhone
                if (!_contactId) {
                  console.warn("No contact id for " + _newContact?.name)
                  Toasty.show(`No id for ${_newContact?.name}`, { type: "danger" })
                  continue;
                }
                contactStrings.push(_contactId)
                batchUpdates.set(firebase.firestore().doc(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}/${_contactId}`), { ..._newContact, groups: firebase.firestore.FieldValue.arrayUnion("BML") }, { merge: true })
              }
              // create/update all contacts
              batchUpdates.commit()
              // update the contact group
              firebase.firestore().doc(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACT_GROUPS}/BML`).set({ contacts: firebase.firestore.FieldValue.arrayUnion(...contactStrings) }, { merge: true })
              console.log("BML SAVE COMPLETE")

            } catch (err) {
              console.error(err)
              Toasty.show(`Failed to save: ${err}`, { type: "danger" })
            }
          }}
          data={screens}
          scrollRef={scrollViewRef}
          confettiRef={confettiRef}
          setShareDialogue={setShareDialogue}
          skipped={skipped}
          revertSkip={setSkipped}
          existingContacts={existingBMLContacts}
          user={user}
        />
      </SSBM.KAV>
    );
  } catch (error) {
    throw Error(`::Qk1MTW9iaWxl::${error}`);
  }
}

export interface dBMLMobile {
  retake?: boolean; //* TODO remember to create dResourcesBodyScreenParam
}

interface BMLMobileProps {
  route?: { params?: dBMLMobile }; //* TODO remember to create dResourcesBodyScreenParam
}
