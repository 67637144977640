import { Input, InputProps } from "@ui-kitten/components";
import { IconPrimr } from "assets/icons";
import { Buttoon } from "components/atoms";
import { useAppContext } from "engines";
import React from "react";
import { ButtonProps, View, ViewProps } from "react-native";
import { scale, spacing } from "utils";
import { Kitten } from "../../externals/ui-kitten/UiKitten";
import { sstyled } from "../sstyled/sstyled";

/**
 * ### Get and save input from the user
 * A "Save" button pops up within the input once they change something.
 * 
 * @author jm_francis
 * @version 1.1.26
 * 
 * @example
 * <Inpuut
        title="Foo"
        doneText="Save"
        value={foo}
        onChangeText={setFoo}
        onSavePress={saveFoo}
        isUrl={false}
      />
 */
export const Inpuut = React.forwardRef(
  (props: dInpuut, ref: React.MutableRefObject<Input>) => {
    const {
      title,
      style = {},
      inputStyle = {},
      saveButtonStyle = {},
      saveButtonAppearance,
      doneText = "Save",
      alwaysShowSave = false,
      onChangeText,
      onSavePress = () => { },
      isUrl,
      containerProps = {},
    } = props;

    const { C } = useAppContext();

    const [_saveEnabled, enableSave] = React.useState(alwaysShowSave);

    let _onChangeText = React.useCallback(
      (text: string) => onChangeText && onChangeText(text),
      [props?.value]
    );

    const inputProps = { ...props };

    /**
     * ### url formatter
     * -  If the isUrl prop is set to true, the following 3 Input props will be set automatically
     * @example
     * <Inpuut url />
     */
    if (isUrl) inputProps.autoCorrect = false;
    if (isUrl) inputProps.autoCapitalize = "none";
    if (isUrl) inputProps.keyboardType = "url";
    delete inputProps.title;

    React.useEffect(
      function setupEnableSave() {
        if (!inputProps?.value && !alwaysShowSave) {
          enableSave(false);
        } else {
          enableSave(true)
        }
      },
      [inputProps?.value]
    );

    return (
      <A.Ctnr style={style} {...containerProps}>
        {/* {title ? (
          <Txt.S1 style={{ fontWeight: "bold", marginBottom: spacing(1) }}>
            {title}
          </Txt.S1>
        ) : null} //NOTE @jeremyfrancis since Kitten.Input has `label`, we'll replace our own title with its `label` for UI consistency. What u think?   */}
        <Kitten.Input
          ref={ref}
          label={title || ""}
          onSubmitEditing={() => {
            // onSavePress && onSavePress();
            enableSave(false);
          }}
          style={{ width: "100%", ...inputStyle }}
          autoCorrect={false}
          {...inputProps}
          onChangeText={(text) => {
            enableSave(true);
            _onChangeText(text);
          }}
          accessoryRight={() =>
            inputProps?.value ? (
              <IconPrimr
                preset="circular"
                onPress={() => _onChangeText("")}
                name="x_circle"
                color={C.grey500}
                size={scale(12)}
              />
            ) : null
          }
        />
        {_saveEnabled ? (
          <Buttoon
            disabled={!inputProps?.value}
            appearance={saveButtonAppearance ? saveButtonAppearance : "ghost"}
            size="small"
            style={{ margin: 0, padding: 0, ...saveButtonStyle }}
            onPress={() => {
              enableSave(false);
              onSavePress && onSavePress();
            }}
            icon={{ name: "enter", right: true }}
          >
            {doneText}
          </Buttoon>
        ) : null}
      </A.Ctnr>
    );
  }
);

const A = {
  Ctnr: sstyled(View)((p: { style?: {} }) => ({
    // width: "100%",
    flex: 1,
    alignItems: "flex-end",
    flexDirection: "column",
    ...p.style,
    // marginVertical: spacing(2),
  })),
};

interface dInpuut extends InputProps {
  /**
   * The title displays above the input
   */
  title?: string;
  inputStyle?: InputProps["style"];
  saveButtonStyle?: ViewProps["style"];
  saveButtonAppearance?: string;
  doneText?: string;
  alwaysShowSave?: boolean;
  value: any;
  isUrl?: boolean;
  placeholder?: string;
  onChangeText?: (text: string) => void;
  onSavePress?: () => void;
  disabled?: boolean;
  containerProps?: ViewProps;
}
