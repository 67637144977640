import {
  SelectItem, SelectItemProps
} from "@ui-kitten/components";
import { useAppContext } from "engines/hooks";
import * as React from "react";
import { View } from "react-native";
import { sstyled, Txt } from "../../generals";
/**
 * ### Modded List Item
 *  - FIx og ListItem rendering issue
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.03.20
 *  -  *Build a component*
 *  @author  K
 *
 **/
export function SelectItemv2(props: SelectItemProps) {
  const { title, description, accessoryLeft, accessoryRight, disabled } = props;
  const { C } = useAppContext();
  return (
    <SelectItem {...props}>
      <A.Ctnr>
        <A.CtnrAccessory style={[props.disabled && { opacity: 0.6 }]}>
          {accessoryLeft && accessoryLeft()}
        </A.CtnrAccessory>
        <A.CtnrContent>
          {typeof title == "string" && (
            <Txt.S2
              numberOfLines={1}
              style={[disabled && { color: C.grey600 }]}
            >
              {title}
            </Txt.S2>
          )}
          {typeof title == "function" && title()}
          {description && typeof description == "string" && (
            <A.TxtDescription numberOfLines={1}>{description}</A.TxtDescription>
          )}
          {description && typeof description == "function" && description()}
        </A.CtnrContent>
        <A.CtnrAccessory>{accessoryRight && accessoryRight()}</A.CtnrAccessory>
      </A.Ctnr>
    </SelectItem>
  );
}

let A = {
  Ctnr: sstyled(View)({
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
  }),
  CtnrContent: sstyled(View)({
    flex: 1,
    paddingLeft: 3,
    justifyContent: "center",
  }),
  CtnrAccessory: sstyled(View)({
    justifyContent: "center",
  }),
  TxtDescription: sstyled(Txt.C2)({
    fontWeight: "normal",
  }),
};
