import { Kitten, Txt } from "components";
import { AuthCenter } from "components/organisms";
import { useAppContext } from "engines";
import { useConfigCollection } from "engines/firebase/handler/config-handler";
import * as React from "react";
import { View } from "react-native";
import { IS_WEB, version } from "utils";

/**
 * ### A splash screen that
 * -  Navigate to HomeStack if !!currentUser
 * -  Show Sign in button if !currentUser
 *  ----
 *  @version 21.03.15
 *  -  *Web+mobile in one*
 *  @author  K
 *
 **/
export function AuthScreen() {
  const { C, theme, setTheme, teamId } = useAppContext();

  const { ...configHandler } = useConfigCollection();
  let appConfig = configHandler._getConfigFromSubDomain(
    // IS_WEB ? null : app_team_id
    IS_WEB ? null : teamId
  );

  try {
    if (!IS_WEB) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: C.background,
          }}
        >
          <AuthCenter />
        </View>
      );
    } else
      return (
        <Kitten.Layout
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Kitten.Card>
            <AuthCenter />
            <Txt.Helper marginV>v{version}</Txt.Helper>
          </Kitten.Card>
        </Kitten.Layout>
      );
  } catch (error) {
    throw Error(`::V2VsY29tZVNjcmVlbg==::${error}`);
  }
}
