import { Kitten } from "components";
import { sstyled, Toasty } from "components/atoms";
import { ConfigVariablesSchema, useAppContext } from "engines";
import { useConfigDocument } from "engines/firebase/handler/config-handler";
import firebase from "firebase";
import React from "react";
import { tr } from "utils";

/**
 * ###
 *  - This component contains the toggle for Mass Mail Feature, Notifications, Smash Em Up
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.08.05
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function AdminToolsCenter(props: P) {
  const { C, teamId } = useAppContext();
  // const userProfile = useUserProfile();
  const configVariablesHandler = useConfigDocument(`variables-${teamId}`);

  /**
   * This contains a local version of the list and compares with the one on the db
   */
  const [_toolsLc, set_toolsLocal] = React.useState<string[]>([
    "Mass Mail",
    // "Panning Scoreboards",
    "Contact Manager",
    "Scoreboards",
    // "Notifications",
    // "SmsEmUp",
  ]);
  const [multiverseDisabled, setMultiverseDisabled] = React.useState(false);

  React.useEffect(() => {
    if (configVariablesHandler.data) {
      setMultiverseDisabled((configVariablesHandler.data as ConfigVariablesSchema)?.multiverseDisabled);
    }
  }, [configVariablesHandler.data]);

  /**
   *
   * @param isChecked : either true or false if the item exist
   * @param item : this is the value of the item selected in the list
   */
  const _onCheckFunc = (isChecked: boolean, item: string) => {
    console.log("isChecked ", !isChecked);
    !isChecked == true ? _addtools(item) : _removetools(item);
  };

  /**
   * This function is used to add the tools item into the database
   * @param item :this is the value of the item selected in the list
   */

  function _addtools(item: string) {
    switch (item) {
      case "Scoreboards":
        // set_toolsLocal(
        //   _toolsLc.map((t) =>
        //     t.includes("Panning Scoreboards") ? "Panning Scoreboards" : t
        //   )
        // );
        configVariablesHandler.update({
          tools: firebase.firestore.FieldValue.arrayUnion(item),
          disableScoreboard: true, // We have this because we needed to update the other field on the db so as the sync function would work
        });
        break;
      // case "Panning Scoreboards":
      //   configVariablesHandler.update({
      //     // tools: firebase.firestore.FieldValue.arrayUnion(item),
      //     disablePanningScoreboards: true, // We have this because we needed to update the other field on the db so as the sync function would work
      //   });
      //   break;
      case "Contact Manager":
        configVariablesHandler.update({
          tools: firebase.firestore.FieldValue.arrayUnion(item),
          disableContactManager: true, // We have this because we needed to update the other field on the db so as the sync function would work
        });
        break;
      case "Notifications":
        configVariablesHandler.update({
          tools: firebase.firestore.FieldValue.arrayUnion(item),
          enablePushNotifications: true, // We have this because we needed to update the other field on the db so as the sync function would work
        });
        break;

      default:
        configVariablesHandler.update({
          tools: firebase.firestore.FieldValue.arrayUnion(item),
        });
        break;
    }
  }

  function _removetools(item: string) {
    switch (item) {
      case "Scoreboards":
        // set_toolsLocal(
        //   _toolsLc.map((t) =>
        //     t.includes("Panning Scoreboards") ? "**Panning Scoreboards" : t
        //   )
        // );
        configVariablesHandler.update({
          disableScoreboard: false, // We have this because we needed to update the other field on the db so as the sync function would work
          tools: firebase.firestore.FieldValue.arrayRemove(item),
        });
        break;
      // case "Panning Scoreboards":
      //   configVariablesHandler.update({
      //     disablePanningScoreboards: false,
      //     // tools: firebase.firestore.FieldValue.arrayRemove(item),
      //   });
      //   break;
      case "Contact Manager":
        configVariablesHandler.update({
          disableContactManager: false, // We have this because we needed to update the other field on the db so as the sync function would work
          tools: firebase.firestore.FieldValue.arrayRemove(item),
        });
        break;
      case "Notifications":
        configVariablesHandler.update({
          enablePushNotifications: false, // We have this because we needed to update the other field on the db so as the sync function would work
          tools: firebase.firestore.FieldValue.arrayRemove(item),
        });
        break;

      default:
        configVariablesHandler.update({
          tools: firebase.firestore.FieldValue.arrayRemove(item),
        });
        break;
    }
  }

  // #region [ANCHOR] Code to update allPages for collaboration
  function _updateShareAllPages(enabled: boolean) {
    setMultiverseDisabled(!enabled)
    configVariablesHandler.update({ allPagesShared: enabled, multiverseDisabled: !enabled });
  }
  // #endregion

  try {
    if (configVariablesHandler.error) throw Error();
    if (!configVariablesHandler.data) return null;

    const data = configVariablesHandler?.data;
    let toolsDoc = data && Array.isArray(data?.tools) ? data?.tools : null;

    return (
      <A.CtnrMenu>
        {_toolsLc.map((item, index) => {
          return (
            <Kitten.ListItemv2
              key={index}
              title={tr(item)}
              accessoryRight={() => (
                <Kitten.Toggle
                  checked={
                    toolsDoc?.some((value) => value === item) ? true : false // We check if the local list and the list online are the same thing, for those that matches it returns true else false
                  }
                  onChange={() => {
                    _onCheckFunc(
                      toolsDoc?.some((value) => value === item) ? true : false,
                      item
                    );
                  }}
                />
              )}
            />
          );
        })}
        <Kitten.ListItemv2
          title={tr("Panning Scoreboards")}
          accessoryRight={() => (
            <Kitten.Toggle
              disabled={configVariablesHandler.data?.disableScoreboard}
              checked={!configVariablesHandler.data?.disablePanningScoreboards}
              onChange={async (status) => {
                Toasty.show("Updating...", { type: "normal" });
                await configVariablesHandler.update({
                  disablePanningScoreboards: !status,
                });
                if (!status) {
                  Toasty.show(
                    "Scoreboards will no longer be panning.\nYou may need to refresh  your app to see immediate change."
                  );
                } else {
                  Toasty.show(
                    "Scoreboards will now be panning.\nYou may need to refresh  your app to see immediate change."
                  );
                }
              }}
            />
          )}
        />
        {/* NOTE This switch works different from other tool center switch */}
        <Kitten.ListItemv2
          title={tr("Share in Multiverse")}
          accessoryRight={() => (
            <Kitten.Toggle
              checked={multiverseDisabled}
              onChange={() => {
                _updateShareAllPages(multiverseDisabled);
              }}
            />
          )}
        />
      </A.CtnrMenu>
    );
  } catch (e) {
    throw new Error(e);
  }
}

interface P { }

const A = {
  CtnrMenu: sstyled(Kitten.Menu)({
    minWidth: 8,
    borderColor: "adminGold",
    borderWidth: 1,
  }),
};
