import { Buttoon } from "components";
import { useAppContext } from "engines";
import { BmlQSchema } from "engines/backends/schemas/bml/bml.schema";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import React from "react";
import { tr } from "utils";
import { SSBM } from "./StyledComponents.BML-Mobile";

export interface WelcomeScreenProps {
  data: BmlQSchema;
  editable?: boolean;
  skipToQualify?: boolean;
  handleSkip?: Function;
}

export function BMLWelcomeScreen(props: WelcomeScreenProps) {
  const { data, skipToQualify, handleSkip } = props;
  const { question: message } = data;
  const { C } = useAppContext();
  // const { setBmlContacts } = useBMLMContext()

  try {
    return (
      <SSBM.Screen>
        <SSBM.MScreen style={{ color: C.text }} key={data._qid}>
          {message}
        </SSBM.MScreen>
        {skipToQualify && (
          <SSBM.KAV>
            <Buttoon
              onPress={() => {
                // setBmlContacts()
                handleSkip && handleSkip();
              }}
              appearance="filled"
              icon={{ name: "arrow_right" }}
              status="warning"
            >
              {tr("Skip To Qualification")}
            </Buttoon>
          </SSBM.KAV>
        )}
      </SSBM.Screen>
    );
  } catch (error) {
    throw Error(`::Qk1MV2VsY29tZVNjcmVlbg==::${error}`);
  }
}
