import { dCalendarEvent, dTask } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";

export function getDailyBriefingText(
  todaysEvents: dCalendarEvent[],
  followUps: ContactSchemaa[],
  userTasks: dTask[]
): string {
  const introMsg = "Today you have ";
  // how many clauses we have (usually seperated by commas)
  let segments = 0;
  let msg = introMsg;
  if (todaysEvents?.length > 0) {
    const firstMeeting: dCalendarEvent = todaysEvents.sort(
      (a, b) => b.date.getTime() - a.date.getTime()
    )[0];
    const startTime = firstMeeting?.time?.split(" - ")[0] || null;
    msg += `${todaysEvents.length} meeting${
      todaysEvents?.length > 1
        ? `s ${startTime ? `(next one at ${startTime})` : ""}`
        : startTime
        ? ` at ${startTime}`
        : ""
    }`;
    segments++;
  }
  if (followUps?.length > 0) {
    msg += msg.length > introMsg.length ? ", " : "";
    msg += `${followUps?.length} contacts to follow up with`;
    segments++;
  }
  if (userTasks?.length > 0) {
    msg += msg.length > introMsg.length ? " and " : "";
    msg += `${userTasks?.length} task${
      userTasks?.length > 1 ? "s" : ""
    } to complete`;
    segments++;
  }
  // to avoid "You have 1 meeting, 4 contacts to follow up with." we use " and "
  if (segments == 2) msg = msg.replace(", ", " and ");
  if (msg.length > introMsg.length) msg += ".";
  else msg = "You have nothing on your agenda today. Let's get to work.";
  return msg;
}
