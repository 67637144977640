import {
  appleAuth,
  AppleButton
} from "@invertase/react-native-apple-authentication";
import { img } from "assets";
import { Buttoon, Inpuut, Kitten, O, Poppy, sstyled, Toasty, Txt } from "components";
import {
  cloudFunction,
  CLOUD_FUNCTIONS,
  ConfigVariablesSchema,
  FPATH,
  FRBS_ROLE,
  useAppContext,
  ValidateLoginInput,
  ValidateLoginResult,
  __DefaultUserData
} from "engines";
import { errLabel } from "engines/backends/auth/AuthHandler";
import { getAppLogoFromTitle, useConfigCollection, _getValidSubdomain } from "engines/firebase/handler/config-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { LinkURL } from "engines/functions/web-functions";
import { getPermissionsAsync } from "expo-notifications";
import firebase from "firebase";
import React from "react";
import { Image, Keyboard, KeyboardAvoidingView, Platform, TextInput, View } from "react-native";
import * as Animatable from "react-native-animatable";
import { useNavigator } from "screens";
import { IS_WEB, scale, spacing, THEME, tr, version } from "utils";
import { ass, ASS_KEY } from "utils/storage";
import { APP_CONFIG } from "../../../../apptakeoff.config";
import { ERRMSG } from "../../../../cloud-functions/primr-setup/functions/src/firebase.props";
import { fuego } from "../../../../fuego";
import { app_team_id } from "../../../../package.json";
import {
  isInputNumeric,
  isInputPhoneNum
} from "../../../engines/functions/js-functions";

/**
 * ### Authentication Center
 *  - Available: Email Auth
 *  ----
 *  @version 21.05.24
 *  -  *Combining Sign in with Reset Password*
 *  -   *Disable Google sign in*
 *  @author  K
 *
 **/
export function AuthCenter() {
  const refRenderCount = React.useRef(0);
  const { setTeamId, C, teamId } = useAppContext();
  // const { setTeamId, C /*teamId*/ } = useAppContext();
  // const teamId = "unleash-apptakeoff";
  const { frbsUser, theme } = useAppContext();
  const userProfile = useUserProfile();
  const [_email, setEmail] = React.useState("");
  const [_password, setPassword] = React.useState("");
  const [_access_code, setAccessCode] = React.useState(APP_CONFIG?.login?.bypass_access_code || "");

  const [isSignInFieldsON, setSignInFieldsON] = React.useState<boolean>(false);
  const [isSignUpFieldsON, setSignUpFieldsON] = React.useState<boolean>(false);
  /** if the user is a free trial user/unleash member */
  const [isUnleashMember, setIsUnleashMember] = React.useState<boolean>(false);
  const [isPasswordResetON, setPasswordResetON] =
    React.useState<boolean>(false);
  // Show/hide resend button (currently deprecated)
  const [_resendBtnVIS] = React.useState(false);

  const [_name, setName] = React.useState<string>("");
  const [_phoneNumber, setPhoneNumber] = React.useState<number>("");

  // Check if access code is correct
  const [_isCodeCorrect, setCodeCorrect] = React.useState(true);

  // Toggle password
  const [_pwShown, setTogglePassword] = React.useState(false);
  // const [pwStatus, setPwStatus] = React.useState<string>("Weak");
  // let pwStatusLabels = ["Too Short", "Weak", "Normal", "Strong", "Secure"];

  //@ts-ignore
  const emailRef = React.useRef<TextInput>();
  const nameRef = React.useRef<TextInput>();
  const phoneRef = React.useRef<TextInput>();
  //@ts-ignore
  const pwRef = React.useRef<TextInput>();
  const pwRef2 = React.useRef<TextInput>();
  //@ts-ignore
  const codeRef = React.useRef<TextInput>();

  const toggleShowPassword = async () => {
    setTogglePassword(!_pwShown);
  };
  React.useEffect(function inputFocus() {
    emailRef.current?.focus();
  }, []);
  //#region [section]
  const _subdomain = _getValidSubdomain()
  const _teamid = teamId || APP_CONFIG?.app_id || app_team_id
  const configHandler = _subdomain ? useConfigCollection({ where: ["subdomain", "==", _subdomain] })
    : useConfigCollection({ where: ["_teamId", "==", _teamid] })
  const appConfig = configHandler.data?.find(conf => conf?.appTitle) as ConfigVariablesSchema

  // let appConfig = configHandler._getConfigFromSubDomain(
  //   // IS_WEB ? null : app_team_id
  //   IS_WEB ? null : teamId
  // );
  // const vHandler = useConfigDocument("variables-app-takeoff")
  //#endregion

  //#region [functions] registerfunctions
  const isEmailValidated = () => {
    return String(_email).match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  async function emailChecking(xong?) {
    if (!isEmailValidated() && !_appleAuthCredential) {
      emailRef.current?.focus();
    } else {
      let __toast = Toasty.show(tr("Checking email..."), { type: "loading" });
      if (_appleAuthCredential && !_email) {
        // no email returned but apple did authorize successfully therefore they are signing back in because Apple only provides email on first sign in
        setSignInFieldsON(true);
        setSignUpFieldsON(false);
        xong && xong();
        return;
      }
      const validationResult = await cloudFunction<
        ValidateLoginInput,
        ValidateLoginResult
      >(CLOUD_FUNCTIONS.ValidateLogin, {
        email: _email,
        password: _password,
        accessCode: `${APP_CONFIG?.login?.access_code_prefix || ""}${_access_code}`,
        // subdomain: "testing",
        subdomain: appConfig?.subdomain, // 👀 test this
        teamId: teamId,
        // subdomain: IS_WEB ? window.location.host : "testing.apptakeoff.com",
      }).catch(err => {
        console.error(err)
        Toasty.show(err, { type: "danger" })
      })
      if (validationResult) {
        // if they are banned
        if (validationResult.data?.errorMessage === ERRMSG.BANNED) {
          Toasty.show("Access denied.", { type: "danger" })
          return;
        }
        // for email checking the errorCode will be -1 with invalid access code because we haven't supplied an access code yet but that's ok apparently
        /** If _teamId has no value, email doesn't exist */
        if (
          !validationResult?.data?._teamId ||
          validationResult?.data?.errorCode === 0
        ) {
          setSignUpFieldsON(true);
          setSignInFieldsON(false); //* just in case
          return setTimeout(() => {
            Toasty.update(__toast, tr("No account found. Let's sign up!"), {});
            xong && xong();
            nameRef.current?.focus();
          }, 600);
        } else {
          setSignInFieldsON(true);
          setSignUpFieldsON(false); //* just in case
          setIsUnleashMember(
            validationResult?.data?._teamId === "unleash-apptakeoff" ||
            appConfig?.unleash === true
          );
          return setTimeout(() => {
            Toasty.update(__toast, tr("Email found. Let's sign in!"), {
              type: "success",
            });
            validationResult?.data?._teamId &&
              setTeamId(validationResult?.data?._teamId);
            xong && xong();
            pwRef.current?.focus();
          }, 600);
        }
      } else {
        Toasty.show("Bad attempt. Try again.", { type: "warning" })
      }
    }
  }

  async function register(xong?, _bypassAccessCode?: string) {
    let __toast = Toasty.show("Signing in...", { type: "loading" });
    try {
      const __accessCode = _bypassAccessCode || `${APP_CONFIG?.login?.access_code_prefix || ""}${_access_code}`;
      const validationResult = await cloudFunction<
        ValidateLoginInput,
        ValidateLoginResult
      >(CLOUD_FUNCTIONS.ValidateLogin, {
        email: _email,
        password: _password,
        accessCode: __accessCode,
        // subdomain: "testing",
        subdomain: appConfig?.subdomain, // 👀 test this
        teamId: teamId,
        // subdomain: IS_WEB ? window.location.host : "testing.apptakeoff.com",
      });

      // const validationResult: ValidateLoginResult = await firebase
      //   .functions()
      //   .httpsCallable("validateLogin")({
      //   email: _email,
      //   password: _password,
      //   accessCode: _access_code,
      // });

      if (validationResult) {
        if (validationResult.data?.success) {
          const __signInToasty = Toasty.show("Signing in ...", {
            type: "loading",
          });
          // console.log("[auth] validation 👍: ", validationResult);
          if (
            appConfig?._teamId == validationResult.data?._teamId ||
            __accessCode.toLowerCase().trim() ===
            "Nescaf3".toLowerCase().trim() ||
            // appConfig?._teamId === "unleash-apptakeoff" ||
            appConfig?.unleash === true
          ) {
            setTeamId(validationResult.data?._teamId || appConfig?._teamId);
            let storingTeamId = ass.saveString(
              ASS_KEY.TEAMID,
              validationResult.data?._teamId || appConfig?._teamId
            );

            if (storingTeamId) {
              try {
                if (_appleAuthCredential) {
                  const response = await fuego
                    .auth()
                    .signInWithCredential(_appleAuthCredential);
                  setEmail(response.user.email || "");
                  setName(response.user.displayName || "");
                  // alert(response.user.displayName);
                } else {
                  await fuego
                    .auth()
                    .signInWithEmailAndPassword(_email, _password);
                }
                // console.log("[auth] sign in 👍: ", r);
                // Toasty.show(tr("Signed in as " + _email), {
                //   type: "success",
                // });
                //prominent disclosure
                if (Platform.OS === "android" && _email?.includes("demo@"))
                  Poppy("Phone Contacts Disclosure", `I understand this app collects and stores my phone contacts to enable the List Builder and Contact Prospecting features, when I select contacts from my phone in those areas of the app.`
                    , { text: "Decline" }, { text: "Agree" })
                Toasty.update(__signInToasty, "Sign in complete", {});
                // Toasty.hide(__toast);
                window &&
                  window.location &&
                  window.location.reload &&
                  window?.location?.reload();
              } catch (e) {
                Toasty.show(errLabel(e, _email), { type: "danger" });
                Toasty.hide(__toast);

                switch (e.code) {
                  case "auth/weak-password":
                    return setTimeout(() => {
                      pwRef.current?.focus();
                    }, 600);
                    break;
                  case "auth/wrong-password":
                    return setTimeout(() => {
                      pwRef.current?.focus();
                    }, 600);
                    break;
                  default:
                    console.log("(auth) err: ", e.code);
                    return `Login failed. ${e.code}\nPlease try again`;
                    break;
                }
              }
            }
          } else {
            //TODO: allow changing of hierarchy if they want?
            /** This user does not belong to team of this subdomain */
            Toasty.show(tr("Sorry you can't access this team"), {
              type: "danger",
            });
            Toasty.hide(__toast);
            xong && xong();
          }
        } else {
          console.log("[auth] 👎: ", validationResult);

          switch (validationResult.data?.errorCode) {
            case -1:
              /** Invalid access code */
              Toasty.show(validationResult.data?.errorMessage, {
                type: "danger",
              });
              Toasty.hide(__toast);
              codeRef.current?.focus();
              break;
            case 0:
              /** User doesn't exist */
              if (validationResult.data?._teamId) {
                setTeamId(validationResult.data?._teamId);
                let storingTeamId = ass.saveString(
                  ASS_KEY.TEAMID,
                  validationResult.data?._teamId
                );
                if (storingTeamId) {
                  let appleEmail;
                  let appleName;
                  try {
                    let r;
                    if (_appleAuthCredential) {
                      console.log(
                        `Creating account with Apple... ${JSON.stringify(
                          _appleAuthCredential
                        )}`
                      );
                      r = await fuego
                        .auth()
                        .signInWithCredential(_appleAuthCredential);
                      appleEmail = r.user.email; // r.user.email should exist
                      appleName = r.user.displayName;
                      // alert(JSON.stringify(r.user));
                      setEmail(r.user.email || "");
                      setName(r.user.displayName || "");
                    } else {
                      r = await fuego
                        .auth()
                        .createUserWithEmailAndPassword(_email, _password);
                    }
                    //prominent disclosure
                    if (Platform.OS === "android" && _email?.includes("demo@")) {
                      Poppy("Phone Contacts Disclosure", `I understand this app collects and stores my phone contacts to enable the List Builder and Contact Prospecting features, when I select contacts from my phone in those areas of the app.`
                        , { text: "Decline" }, { text: "Agree" })
                      Poppy("Phone Image Disclosure", `I understand this app collects and stores my images to enable the Profile Picture and content organization features, when I select images from my phone in those areas of the app.`
                        , { text: "Decline" }, { text: "Agree" })
                    }
                    fuego.db.doc(FPATH.USERS + "/" + r.user?.uid).set({
                      ...__DefaultUserData(),
                      _parentAppId:
                        validationResult.data?._parentAppId ||
                        validationResult.data?._teamId,
                      _teamId: validationResult.data?._teamId,
                      _id: r.user?.uid,
                      createdAt:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      personali: {
                        email: appleEmail || _email,
                        phoneNumber: _phoneNumber || "",
                        displayName: appleName || _name,
                      },
                      roles: [
                        validationResult.data?.userType || FRBS_ROLE.NEWBIE,
                      ],
                    });
                    Toasty.hide(__toast);
                    Toasty.show(tr("Created and signed in as ") + _email, {
                      type: "success",
                    });
                  } catch (e) {
                    Toasty.show(e, { type: "danger" });
                    throw Error("Error signing up an account");
                  }
                }
              } else {
                /** If user type crazy access code */
                Toasty.hide(__toast);
                Toasty.show(tr("Invalid access code"), {
                  type: "danger",
                });
              }

              break;
            case 1:
              Toasty.hide(__toast);
              Toasty.show(validationResult.data?.errorMessage, {
                type: "danger",
              });
              break;

            default:
              Toasty.hide(__toast);
              Toasty.show(validationResult.data?.errorMessage, {
                type: "danger",
              });
              break;
              break;
          }
        }

        xong && xong();
      }
    } catch (error) {
      console.warn("[auth] error: ", error);
      xong && xong();
    }
  }
  //#endregion
  //#region [section] phone validation
  const [phoneValidation, setPhoneValidation] = React.useState<boolean>(true);

  function validatePhoneNum(text: string) {
    (isInputNumeric(text) || text == "") && setPhoneNumber(text);
    setPhoneValidation(isInputPhoneNum(text));
  }
  //#endregion
  const [termsAgreed, setTermsAgreed] = React.useState<boolean>(false);
  const [_appleAuthCredential, setAppleAuthCredential] =
    React.useState<firebase.auth.OAuthCredential>(null);

  React.useEffect(
    function versionUpdater() {
      console.log("version ", version);
      setTimeout(() => {
        IS_WEB && frbsUser && userProfile?.data
          ? userProfile
            .update({
              webVersion: version,
            })
            .then((res) => {
              console.log("response from update version");
            })
          : userProfile
            .update({
              mobileVersion: version,
            })
            .then((res) => {
              console.log("response from update version");
            });
      }, 10000);
    },
    [frbsUser]
  );

  React.useEffect(
    function appleAuthSignInOrUp() {
      if (_appleAuthCredential) {
        emailChecking();
        // if (!_email || _email.length < 1) {
        //   setSignUpFieldsON(false);
        //   setSignInFieldsON(true);
        // }
      }
    },
    [_appleAuthCredential]
  );

  async function onAppleButtonPress() {
    // Start the sign-in request
    const appleAuthRequestResponse = await appleAuth.performRequest({
      requestedOperation: appleAuth.Operation.LOGIN,
      // As per the FAQ of react-native-apple-authentication, the name should come first in the following array.
      // See: https://github.com/invertase/react-native-apple-authentication#faqs
      requestedScopes: [appleAuth.Scope.FULL_NAME, appleAuth.Scope.EMAIL],
    });

    // alert(JSON.stringify(appleAuthRequestResponse.fullName));

    // Ensure Apple returned a user identityToken
    if (!appleAuthRequestResponse.identityToken) {
      throw new Error("Apple Sign-In failed - no identify token returned");
    }

    // Create a Firebase credential from the response
    const { identityToken, nonce, fullName, email } = appleAuthRequestResponse;

    const provider = new fuego.auth.OAuthProvider("apple.com");
    const authCredential = provider.credential({
      idToken: identityToken,
      rawNonce: nonce,
    });
    setEmail(email);
    setName(fullName.givenName || "" + " " + fullName.familyName || "");
    setAppleAuthCredential(authCredential);
    // setSignInFieldsON(true);
    // emailChecking();
    // emailChecking();

    return;
  }

  if (
    !userProfile.data ||
    userProfile.data?.id == undefined ||
    !frbsUser?.uid
  ) {
    return (
      <A.ViewContainer>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={Platform.OS === "ios" ? -50 : 20}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>
          {appConfig ? (
            <A.TeamLogo
              source={{
                uri: getAppLogoFromTitle(appConfig?.appTitle),
              }}
            />
          ) : (
            <Image
              source={{
                uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/q_auto:low/v1622731805/apptakeoff/icons/rocket_vdr9ge.gif",
              }}
              style={{
                width: 200,
                height: 200,
                alignSelf: "center",
                margin: scale(4),
              }}
            />
          )}
          <A.TxtTitle>
            {isPasswordResetON
              ? tr("To reset your password,")
              : tr("Welcome to") + " " + appConfig?.appTitle}
          </A.TxtTitle>

          {isPasswordResetON ? (
            <Animatable.View animation={"fadeIn"}>
              <A.TxtSubtitle>{tr("Confirm your email")}</A.TxtSubtitle>
              <O.ResetCenter
                passwordResetVisibilityCallback={() =>
                  setPasswordResetON(!isPasswordResetON)
                }
              />
              <Kitten.Divider_________ />
              <Buttoon
                appearance="ghost"
                onPress={() => setPasswordResetON(false)}
              >
                {tr("Back")}
              </Buttoon>
            </Animatable.View>
          ) : (
            <A.CtnrEmailAuth>
              {isSignInFieldsON || isSignUpFieldsON ? (
                <Buttoon
                  compact
                  icon={{ name: "x", right: true }}
                  appearance="ghost"
                  status="basic"
                  onPress={() => {
                    setAppleAuthCredential(null);
                    setSignInFieldsON(false);
                    setSignUpFieldsON(false);
                  }}
                >
                  {_email}
                </Buttoon>
              ) : (
                <Animatable.View animation={"fadeIn"}>
                  <A.TxtSubtitle>
                    {tr("Let's start with your email")}
                  </A.TxtSubtitle>
                  <A.Input
                    ref={emailRef}
                    placeholder={tr("Email")}
                    textContentType="emailAddress"
                    placeholderTextColor={C.grey600}
                    // autoFocus={true}
                    // // disabled={_runningTSKS || !R.isNil(frbsAuthe)}
                    autoCapitalize="none"
                    keyboardType="email-address"
                    value={_email}
                    onChangeText={setEmail}
                    onBlur={() => Keyboard.dismiss()}
                    returnKeyType={"next"}
                    onSubmitEditing={() => {
                      // setTimeout(() => pwRef.current?.focus(), 50);
                      emailChecking();
                    }}
                  />
                  <Kitten.Divider_________ />
                  <Buttoon
                    disabled={!isEmailValidated()}
                    onPress={() => {
                      emailChecking();
                      // reset since they aren't going with apple sign in
                      setAppleAuthCredential(null);
                    }}
                  >
                    {tr("Next")}
                  </Buttoon>
                  {Platform.OS === "ios" && false ? (
                    <>
                      <Txt.Indicator style={{ marginVertical: spacing(3) }}>
                        or
                      </Txt.Indicator>
                      <AppleButton
                        buttonStyle={
                          theme === THEME.DARK
                            ? AppleButton.Style.WHITE
                            : AppleButton.Style.BLACK
                        }
                        buttonType={AppleButton.Type.SIGN_IN}
                        style={{
                          // marginTop: spacing(4),
                          width: "100%", // You must specify a width
                          height: 45, // You must specify a height
                          borderRadius: 45,
                        }}
                        onPress={onAppleButtonPress}
                      />
                    </>
                  ) : null}
                </Animatable.View>
              )}
              {isSignInFieldsON ? (
                <Animatable.View animation={"fadeIn"}>
                  {!_appleAuthCredential ? (
                    <A.TxtSubtitle>
                      {tr("To continue, first verify it’s you. ")}
                    </A.TxtSubtitle>
                  ) : null}
                  {!_appleAuthCredential ? (
                    <Kitten.ViewH style={{ justifyContent: "space-between" }}>
                      <A.Input
                        ref={pwRef}
                        textContentType="password"
                        placeholder={tr("Personal Password")}
                        placeholderTextColor={C.grey600}
                        // disabled={_runningTSKS}
                        secureTextEntry={!_pwShown}
                        value={_password}
                        // maxLength={15}
                        onChangeText={setPassword}
                        // onBlur={() => Keyboard.dismiss()}
                        onSubmitEditing={() => {
                          setTimeout(() => codeRef.current?.focus(), 50);
                        }}
                        style={{ marginBottom: 0 }}
                      />

                      <Buttoon
                        icon={!_pwShown ? { name: "eye" } : { name: "eye_slash" }}
                        appearance={"ghost"}
                        onPress={toggleShowPassword}
                      />
                    </Kitten.ViewH>
                  ) : null}
                  {_password?.length < 6 && !_appleAuthCredential ? (
                    <A.TxtPwStatusHelper
                      numberOfLines={4}
                      style={{ color: C.hazardYellow }}
                    >
                      {tr("Provide a password with at least 6 characters")}
                      {/* {tr(
                "Provide a password with at least 6 characters, with an upper case, a lower case, a number and a special character"
              )} */}
                    </A.TxtPwStatusHelper>
                  ) : null}
                  <View style={{ marginBottom: spacing(3) }}>
                    {/* <PassMeter
                showLabels
                password={_password}
                maxLength={15}
                minLength={6}
                labels={pwStatusLabels}
                barStyle={{ width: scale(242) }}
                onPassStatCallback={setPwStatus}
              /> */}
                  </View>
                  {APP_CONFIG?.login?.bypass_access_code ? null :
                    <A.Input
                      ref={codeRef}
                      placeholderTextColor={C.grey600}
                      placeholder={tr(
                        APP_CONFIG?.login?.access_code_placeholder || `Team's Access Code ${isUnleashMember ? "(optional)" : ""}`
                      )}
                      placeholderTextColor={C.grey600}
                      // disabled={_runningTSKS || !R.isNil(frbsAuthe)}
                      autoCapitalize="none"
                      status={_isCodeCorrect ? "basic" : "danger"}
                      caption={_isCodeCorrect ? "" : tr("Incorrect access code")}
                      value={_access_code}
                      onChangeText={setAccessCode}
                      // onBlur={() => Keyboard.dismiss()}
                      // selectTextOnFocus
                      onSubmitEditing={register}
                    />
                  }

                  <View
                    style={{ marginTop: spacing(2), marginBottom: spacing(4) }}
                  >
                    <Kitten.Divider_________ />
                    <Buttoon
                      disabled={
                        (!_access_code && !isUnleashMember) ||
                        ((!_email ||
                          !_password ||
                          // pwStatusLabels[0] == pwStatus || //* if pwStatus is either "Too Short" || "Weak"
                          // pwStatus == ""
                          _password?.length < 6) &&
                          !_appleAuthCredential)
                        //|| !termsAgreed
                      }
                      progress
                      onPress={(xong) => {
                        register(xong);
                      }}
                    >
                      {tr("Sign in")}
                    </Buttoon>
                    {/* {teamId === "unleash-apptakeoff" ? (
                    <Buttoon
                      style={{ marginTop: spacing(4) }}
                      disabled={!_email || !_password || _password?.length < 6}
                      status="info"
                      onPress={(xong) => {
                        if (!isUnleashMember) {
                          alert(
                            "This account is already associated with a team, please use their access code."
                          );
                          return;
                        }
                        register(xong, ">>UNLEASH");
                      }}
                    >
                      {tr("Sign In Free Version")}
                    </Buttoon>
                  ) : null} */}
                    {!_appleAuthCredential ? (
                      <Buttoon
                        appearance="ghost"
                        // disabled={!_email}
                        onPress={() => {
                          setPasswordResetON(!isPasswordResetON);
                        }}
                      >
                        {isPasswordResetON
                          ? tr("Cancel")
                          : tr("Forgot password?")}
                      </Buttoon>
                    ) : null}
                    {/* <Buttoon
                appearance="ghost"
                progress
                onPress={(xong) => {
                  fuego
                    .auth()
                    .signInWithEmailAndPassword("drkhoi16@gmail.com", "123456")
                    .then(() => {
                      setTeamId("app-takeoff-team");
                      xong && xong();
                    })
                    .catch((e) => {
                      console.log("login with drkhoi16 error: ", e);
                      xong && xong();
                    });
                }}
              >
                {tr("Sign in as drkhoi16@gmail.com")}
              </Buttoon> */}
                  </View>
                </Animatable.View>
              ) : null}

              {isSignUpFieldsON ? (
                <Animatable.View animation={"fadeIn"}>
                  {!_appleAuthCredential ? (
                    <A.TxtSubtitle>
                      {"Looks like you're new. Let's create an account!"}
                    </A.TxtSubtitle>
                  ) : null}
                  {!_appleAuthCredential ? (
                    <A.Input
                      ref={nameRef}
                      placeholderTextColor={C.grey600}
                      placeholder={tr("Your Name")}
                      textContentType="name"
                      value={_name}
                      onChangeText={setName}
                      onSubmitEditing={() => {
                        phoneRef.current?.focus();
                      }}
                    />
                  ) : null}
                  {!_appleAuthCredential ? (
                    <A.Input
                      ref={phoneRef}
                      textContentType="telephoneNumber"
                      placeholderTextColor={C.grey600}
                      placeholder={tr("Phone Number")}
                      value={_phoneNumber}
                      onChangeText={validatePhoneNum}
                      selectTextonFocus
                      onSubmitEditing={() => {
                        pwRef2.current?.focus();
                      }}
                    />
                  ) : null}
                  {!phoneValidation && (
                    <A.TxtPwStatusHelper
                      numberOfLines={4}
                      style={{ color: C.hazardYellow }}
                    >
                      {tr("Please enter a valid 10 digit phone number")}
                      {/* {tr(
                "Provide a password with at least 6 characters, with an upper case, a lower case, a number and a special character"
              )} */}
                    </A.TxtPwStatusHelper>
                  )}
                  {!_appleAuthCredential ? (
                    <Kitten.ViewH style={{ justifyContent: "space-between" }}>
                      <A.Input
                        ref={pwRef2}
                        placeholder={tr("Password")}
                        textContentType="password"
                        placeholderTextColor={C.grey600}
                        // disabled={_runningTSKS}
                        secureTextEntry={!_pwShown}
                        value={_password}
                        maxLength={15}
                        onChangeText={setPassword}
                        // onBlur={() => Keyboard.dismiss()}
                        onSubmitEditing={() => {
                          setTimeout(() => codeRef.current?.focus(), 50);
                        }}
                        style={{ marginBottom: 0 }}
                      />

                      <Buttoon
                        icon={!_pwShown ? { name: "eye" } : { name: "eye_slash" }}
                        appearance={"ghost"}
                        onPress={toggleShowPassword}
                      />
                    </Kitten.ViewH>
                  ) : null}
                  {_password?.length < 6 && !_appleAuthCredential ? (
                    <A.TxtPwStatusHelper
                      numberOfLines={4}
                      style={{ color: C.hazardYellow }}
                    >
                      {tr("Provide a password with at least 6 characters")}
                      {/* {tr(
                "Provide a password with at least 6 characters, with an upper case, a lower case, a number and a special character"
              )} */}
                    </A.TxtPwStatusHelper>
                  ) : null}
                  <View style={{ marginBottom: spacing(3) }}>
                    {/* <PassMeter
                showLabels
                password={_password}
                maxLength={15}
                minLength={6}
                labels={pwStatusLabels}
                barStyle={{ width: scale(242) }}
                onPassStatCallback={setPwStatus}
              /> */}
                  </View>
                  <A.Input
                    ref={codeRef}
                    placeholderTextColor={C.grey600}
                    placeholder={APP_CONFIG?.login?.access_code_placeholder || tr(`Team's Access Code`)}
                    placeholderTextColor={C.grey600}
                    // disabled={_runningTSKS || !R.isNil(frbsAuthe)}
                    autoCapitalize="none"
                    status={_isCodeCorrect ? "basic" : "danger"}
                    caption={_isCodeCorrect ? "" : tr("Incorrect access code")}
                    value={_access_code}
                    onChangeText={setAccessCode}
                    // onBlur={() => Keyboard.dismiss()}
                    // selectTextOnFocus
                    onSubmitEditing={() => {
                      // emailChecking()
                      if (isSignUpFieldsON || isSignInFieldsON) register();
                      else if (!isSignInFieldsON && !isSignUpFieldsON)
                        emailChecking();
                    }}
                  />

                  <View
                    style={{ marginTop: spacing(2), marginBottom: spacing(4) }}
                  >
                    <Kitten.CheckBox
                      checked={termsAgreed}
                      onChange={setTermsAgreed}
                      style={{ marginVertical: spacing(4) }}
                    >
                      <Txt.P2>
                        I agree to the{" "}
                        <Txt.P2
                          style={{ textDecorationLine: "underline" }}
                          onPress={() => {
                            LinkURL("https://apptakeoff.com/terms");
                          }}
                        >
                          Terms & Conditions
                        </Txt.P2>
                      </Txt.P2>
                    </Kitten.CheckBox>
                    <Buttoon
                      disabled={
                        !_access_code ||
                        ((!_email ||
                          !_password ||
                          // pwStatusLabels[0] == pwStatus || //* if pwStatus is either "Too Short" || "Weak"
                          // pwStatus == ""
                          _password?.length < 6 ||
                          !isInputPhoneNum(_phoneNumber.toString())) &&
                          !_appleAuthCredential) ||
                        !termsAgreed
                      }
                      progress
                      onPress={(xong) => {
                        register(xong);
                      }}
                    >
                      {tr("Create account")}
                    </Buttoon>
                    {app_team_id === "unleash-apptakeoff" ? (
                      <Buttoon
                        style={{ marginTop: spacing(4) }}
                        disabled={
                          ((!_email ||
                            !_password ||
                            _password?.length < 6 ||
                            !isInputPhoneNum(_phoneNumber.toString())) &&
                            !_appleAuthCredential) ||
                          !termsAgreed
                        }
                        status="info"
                        // progress
                        onPress={(xong) => {
                          register(xong, ">>UNLEASH");
                        }}
                      >
                        {"I don't have a team, try free..."}
                      </Buttoon>
                    ) : null}
                  </View>
                </Animatable.View>
              ) : null}

              {/* <GoogleSignin /> */}
            </A.CtnrEmailAuth>
          )}

          {/* <Buttoon
        onPress={() =>
          fuego
            .auth()
            .signInWithEmailAndPassword("drkhoi16@gmail.com", "123456")
            .then((e) => console.log("done", e))
            .catch((e) => console.warn(e))
        }
      >
        Quick Pass
      </Buttoon>
      <Txt>{JSON.stringify(userData)}</Txt> */}
          {/* <Buttoon appearance="ghost" onPress={() => Navigation.goBack()}>
        {tr("Close")}
      </Buttoon> */}
        </KeyboardAvoidingView>
      </A.ViewContainer>
    );
  } else {
    return <AuthWelcome appConfig={appConfig} />;
  }
}

const AuthWelcome = (props: {
  appConfig: ConfigVariablesSchema & { appLogo: string };
}) => {
  const { appConfig } = props;
  const { C, teamId, frbsUser } = useAppContext();

  const userProfile = useUserProfile();
  const Navigation = useNavigator();

  const [_name, setName] = React.useState("");
  const refName = React.useRef<TextInput>();

  //#region [functions] Version Updater
  React.useEffect(
    function versionUpdater() {
      console.log("version ", version);
      setTimeout(() => {
        IS_WEB && frbsUser && userProfile?.data
          ? userProfile
            .update({
              webVersion: version,
            })
            .then((res) => {
              console.log("response from update version");
            })
          : userProfile
            .update({
              mobileVersion: version,
            })
            .then((res) => {
              console.log("response from update version");
            });
      }, 10000);
    },
    [frbsUser]
  );

  async function checkNotifcation() {
    userProfile.update({
      lastSignIn: firebase.firestore.FieldValue.serverTimestamp(),
    });

    if (IS_WEB) {
      Navigation.navigate("Home");
    } else {
      let permissionResult = await getPermissionsAsync();
      console.log("[messaging] get result.status = " + permissionResult.status);
      if (permissionResult.status === "undetermined") {
        Navigation.navigate("notification-permission");
      } else {
        Navigation.navigate("Home");
      }
    }
  }
  //#endregion

  return (
    <A.ViewContainer>
      <A.TeamLogo
        source={
          teamId == "app-takeoff-team"
            ? img.appLogo
            : {
              uri: getAppLogoFromTitle(appConfig.appTitle),
            }
        }
      />
      <A.TxtTitle>
        {"👋  " + tr("Welcome to ") + appConfig?.appTitle}
      </A.TxtTitle>

      {!userProfile.data?.personali?.displayName ? (
        <View style={{ margin: spacing(3) }}>
          <Txt.Helper marginV>
            {tr("Finish the first task by telling us your name")}
          </Txt.Helper>
          <Inpuut
            ref={refName}
            placeholder={tr("Name")}
            textContentType="name"
            // autoFocus={true}
            // // disabled={_runningTSKS || !R.isNil(frbsAuthe)}
            autoCapitalize="words"
            value={_name}
            onChangeText={setName}
            onBlur={() => Keyboard.dismiss()}
            returnKeyType={"next"}
            doneText={tr("Save")}
            onSavePress={() => {
              userProfile.update({
                personali: {
                  ...userProfile.data?.personali,
                  displayName: _name,
                },
              });
            }}
          />
        </View>
      ) : (
        <Txt.H6 style={{ color: C.primary, margin: spacing(3) }}>
          {userProfile.data?.personali?.displayName}
        </Txt.H6>
      )}

      <Buttoon
        disabled={!userProfile.data?.personali?.displayName}
        onPress={() => {
          checkNotifcation();
        }}
      >
        {" 🎉 " + tr("Hoo-ray")}
      </Buttoon>
    </A.ViewContainer>
  );
};

const A = {
  ViewContainer: sstyled(View)({
    // flex: Platform.select({ web: 1, ios: 0, android: 0 }),
    // width: Platform.select({ web: undefined, ios: "90%", android: "90%" }),
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "background",
    // padding: 4,
    // marginHorizontal: [4, 4, 4, 8, "50%"],
    borderRadius: 10,
    borderColor: "text01",
    minWidth: ["100%", "100%", "60%", "50%", "50%"],
  }),
  CtnrEmailAuth: sstyled(View)({}),
  TxtTitle: sstyled(Txt.H6)(() => ({
    textAlign: "center",
    color: "text",
  })),
  TxtPwStatusHelper: sstyled(Txt.Helper)({
    alignSelf: "flex-start",
    textAlign: "left",
    maxWidth: scale(269),
  }),
  Input: sstyled(TextInput)({
    minWidth: 10,
    marginBottom: 3,
    color: "text",
    fontSize: scale(16),
    paddingVertical: spacing(3),
    paddingHorizontal: spacing(2),
    borderRadius: scale(4),
    borderWidth: 1,
    borderColor: "grey500",
    marginVertical: spacing(2),
  }),

  TxtSubtitle: sstyled(Txt.S2)(() => ({
    textAlign: "center",
    color: "text",
    minWidth: 10,
    marginVertical: spacing(3),
  })),
  TxtHelper: sstyled(Txt.Helper)({
    textAlign: "left",
    // width: 10,
  }),
  TeamLogo: sstyled(Image)({
    width: [scale(60), scale(70), scale(80)],
    height: [scale(60), scale(70), scale(80)],
    borderRadius: 10,
    backgroundColor: "dim",
    margin: spacing(4),
  }),
};
