import * as EXCalendar from "expo-calendar";
// import RNFetchBlob from "rn-fetch-blob";
import { dConfigKeys, fn, useAppContext } from "engines";
import { useConfigDocument } from "engines/firebase/handler/config-handler";
import firebase from "firebase";
import React from "react";
import { IS_WEB } from "utils";
import { Toasty } from "components";

// TODO fetch these variables from the firestore config.keys
// const calendarId = "cal_X34l12Ge9wBf6JWr_sibX4rmdoxldN4C-yUymmw";
// const calendarAccessToken = "DSGu18kQSYlsPAAKKpuXT-RsgLJM4zQV";

interface ProvidedData {
  fetchUrl: string;
  calendarAccessToken: string;
}

function timeTextDisplay(startDate: Date, endDate: Date): string {
  const startTime = startDate
    ? fn?.calendar.timeToAMPMString(startDate).replace(":NaN", ":00")
    : "";
  const endTime = endDate
    ? fn?.calendar.timeToAMPMString(endDate).replace(":NaN", ":00")
    : null;
  return endTime ? `${startTime} - ${endTime}` : `${startTime}`;
}

function extractEventsFromJson(json: {
  events: any[];
  pages: { current: number; total: number; next_page: string };
}): dCalendarEvent[] {
  let events: dCalendarEvent[] = json?.events?.map((event) => {
    // const event = json.events[e];
    const date = fn?.calendar.parseISOString(event.start);
    const endDate = fn?.calendar.parseISOString(event.end);

    const startTime = date
      ? fn?.calendar.timeToAMPMString(date).replace(":NaN", ":00")
      : "";
    const endTime = endDate
      ? fn?.calendar.timeToAMPMString(endDate).replace(":NaN", ":00")
      : null;
    const time = timeTextDisplay(date, endDate);

    return {
      eventId: event.event_uid || event.id,
      title: event.summary,
      description: event.description,
      date,
      endDate,
      location: {
        description: event?.location?.description,
        ...event?.location,
      },
      time,
    };
  });
  return events;
}

export function useCalendar() {
  const { teamId } = useAppContext();
  const teamKeyHandler = useConfigDocument("keys-" + teamId);
  const calendarIds = (teamKeyHandler.data &&
    (teamKeyHandler.data as dConfigKeys)?.calendarIds) || [
      (teamKeyHandler.data as dConfigKeys)?.calendarId || "",
    ];

  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<dCalendarEvent[]>([]);
  const [error, setError] = React.useState<any>("");

  const day = 86400000;
  let dateRangeStart = new Date();
  let dateRangeEnd = new Date();
  dateRangeStart.setTime(dateRangeStart.getTime() - day * 7); // see events as earlier as 7 days ago
  dateRangeEnd.setTime(dateRangeEnd.getTime() + day * 201); // get next 201 days of events (that is cronofy max for a single call)

  async function _fetchCalendar(
    calendarAccessToken: string,
    _startStr: string,
    _endStr: string,
    /**
     * When new events are fetched, get a response immediately instead of waiting for the rest
     */
    onNewEvents?: (events: dCalendarEvent[]) => void
  ) {
    try {
      if (calendarIds?.length < 1) {
        onNewEvents && onNewEvents([]);
        return [];
      }
      const fetchUrl = `https://api.cronofy.com/v1/events?tzid=Etc/UTC&${calendarIds
        .map((cid) => `calendar_ids[]=${cid}&`)
        .join("")}from=${_startStr}&to=${_endStr}`;
      // alert(fetchUrl)
      const fetchData: ProvidedData = {
        fetchUrl: fetchUrl,
        calendarAccessToken,
      };
      // first response
      const fetchCalendarResponse = await firebase
        .functions()
        .httpsCallable("fetchCalendar")(fetchData);
      let jsonResponse: {
        events: any[];
        pages: { current: number; total: number; next_page: string };
      } = fetchCalendarResponse.data;

      let allEvents: any[] = [];

      allEvents = allEvents.concat(extractEventsFromJson(jsonResponse));
      onNewEvents && onNewEvents(allEvents);

      if (jsonResponse)
        for (let p = 0; p < jsonResponse?.pages?.total || 1; p++) {
          if (!jsonResponse) continue;
          const { pages } = jsonResponse;
          if (!pages?.next_page) break;
          const _resp = await firebase
            .functions()
            .httpsCallable("fetchCalendar")({
              fetchUrl: pages.next_page,
              calendarAccessToken,
            });
          // console.log(JSON.stringify(_resp.data.pages));
          allEvents = allEvents.concat(extractEventsFromJson(_resp.data));
          onNewEvents && onNewEvents(allEvents);
          // queues next loop data
          jsonResponse = _resp.data;
        }

      return allEvents;
    } catch (err) {
      // alert(err);
      setError(err);
      throw err;
    }
  }
  async function fetchCalendar(accessToken: string) {
    // FIRST CALL (-30 days and +201 days)
    let startStr = fn?.calendar.dateFormat(dateRangeStart, "yyyy-mm-dd");
    let endStr = fn?.calendar.dateFormat(dateRangeEnd, "yyyy-mm-dd");

    await _fetchCalendar(accessToken, startStr, endStr, (events) =>
      setData(events)
    );
    // setData(_events);
    setLoading(false);

    // const __events = await _fetchCalendar(accessToken, startStr, endStr);

    // SECOND CALL (+201 days and +402 days)
    // dateRangeStart = new Date();
    // dateRangeStart.setTime(dateRangeStart.getTime() + day * 169); // start 201 days in the future
    // dateRangeEnd.setTime(dateRangeStart.getTime() + day * 169); // end 402 days in the future
    // startStr = fn?.calendar.dateFormat(dateRangeStart, "yyyy-mm-dd");
    // endStr = fn?.calendar.dateFormat(dateRangeEnd, "yyyy-mm-dd");

    // const __events = await _fetchCalendar(accessToken, startStr, endStr);
    // alert(JSON.stringify(__events.map((e) => e?.date)));
    // allEvents = allEvents.concat(__events);
  }

  function getTodaysEvents(): dCalendarEvent[] {
    if (!data) return [];
    const today = new Date();
    return data.filter(
      (e) =>
        e?.date?.getDate() === today.getDate() &&
        e?.date?.getMonth() === today.getMonth() &&
        e?.date?.getFullYear() === today.getFullYear()
    );
  }
  function getNextEvent(): dCalendarEvent {
    const todaysEvents = getUpcomingEvents();
    return todaysEvents.reduce((previousE, nextE) => {
      if (nextE?.date < previousE?.date) return nextE;
    });
  }
  function getUpcomingEvents(): dCalendarEvent[] {
    const now = new Date();
    const todaysEvents = getTodaysEvents();
    return todaysEvents.filter((e) => e?.date?.getTime() > now.getTime());
  }
  async function getAllPersonalCalendarEvents(
    calendarIds: string[],
    _dateRangeStart = dateRangeStart,
    _dateRangeEnd = dateRangeEnd
  ): Promise<dCalendarEvent[]> {
    if (IS_WEB) {
      console.warn(
        "Personal calendar integration not available on web currently, unable to fetch events."
      );
      return [];
    }
    const calPerm = await EXCalendar.getCalendarPermissionsAsync()
    if (calPerm.status !== 'granted') {
      const permResp = await EXCalendar.requestCalendarPermissionsAsync()
      if (permResp.status !== 'granted') {
        Toasty.show("Calendar permission required to fetch events.", { type: "danger" })
        return []
      }
    }

    const _personalEvents: dCalendarEvent[] = [];
    const myEvents = calendarIds
      ? await EXCalendar?.getEventsAsync(
        calendarIds,
        _dateRangeStart,
        _dateRangeEnd
      )
      : [];
    for (let e in myEvents) {
      const _evt = myEvents[e];
      const _startDate =
        typeof _evt.startDate === "string"
          ? new Date(_evt.startDate)
          : _evt.startDate;
      const _endDate =
        typeof _evt.endDate === "string"
          ? new Date(_evt.endDate)
          : _evt.endDate;
      _evt &&
        _personalEvents.push({
          personalCalendarId: _evt.calendarId,
          date: _startDate,
          endDate: _endDate,
          description: _evt.notes || "",
          title: _evt.title,
          eventId: _evt.id,
          location: { description: _evt.location },
          time: timeTextDisplay(_startDate, _endDate),
        });
    }
    return _personalEvents;
  }
  async function getTodaysPersonalCalendarEvents(
    calendarIds: string[]
  ): Promise<dCalendarEvent[]> {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
    return getAllPersonalCalendarEvents(calendarIds, startOfToday, endOfToday);
  }

  React.useEffect(
    function getCalendarData() {
      fetchCalendar((teamKeyHandler?.data as dConfigKeys)?.calendarAccessToken);
    },
    [teamKeyHandler?.data]
  );

  function combineAndSortEvents(
    events1: dCalendarEvent[],
    events2: dCalendarEvent[]
  ): dCalendarEvent[] {
    return events1
      .concat(events2)
      .sort((a, b) => a.date?.getTime() - b.date?.getTime());
  }

  return {
    loading,
    error,
    data,
    getTodaysEvents,
    getNextEvent,
    getUpcomingEvents,
    getAllPersonalCalendarEvents,
    getTodaysPersonalCalendarEvents,
    combineAndSortEvents,
    fetch: fetchCalendar,
  };
}

/**
 * ### Fixes html errors that cause display issues
 *
 * @author jm_francis
 * @version 2.9.19
 * @example
 * const newHTML = correctHTML(html)
 */
export function correctHTML(html: string): string {
  return html?.replace(/html-blob/g, "p").replace(/<u><\/u>/g, "");
}

/**
 * ###  Chronofy Event API
 */
export interface dCalendarEvent {
  personalCalendarId?: string;
  eventId: string;
  title: string;
  description: string;
  date: Date;
  endDate: Date;
  location: { description: string };
  time: string;
}
