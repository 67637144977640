const { version } = require("../../../../package.json");
import { Avt, Buttoon, Kitten, sstyled, Toasty, Txt } from "components";
import { ACTION_TYPE, FRBS_ROLE, recordUserAction, useAppContext } from "engines";
import { useMemberDocument } from "engines/firebase/handler/members-handler";
import {
  CLOUDINARY_FOLDER,
  uploadMedia
} from "engines/functions/media-functions";
import { LinkURL } from "engines/functions/web-functions";
import * as ImagePicker from "expo-image-picker";
import { MediaTypeOptions } from "expo-image-picker";
import firebase from "firebase";
import React from "react";
import { Alert, Linking, Platform, View } from "react-native";
import { scale, spacing, tr } from "utils";
import {
  isInputNumeric,
  isInputPhoneNum
} from "../../../engines/functions/js-functions";

export interface dPersonaliEditCenter {
  memberId?: string;
}

export function PersonaliEditCenter({ memberId }: dPersonaliEditCenter) {
  const { C, teamId, setFrbsUser, frbsUser } = useAppContext();
  // const refRenderCount = React.useRef(0);
  const { data: memberD, ...memberHandler } = useMemberDocument(
    memberId || frbsUser?.uid
  );
  // const userActivityHandler = useActivityCollection(memberId || frbsUser?.uid);
  // const { column1ContainerStyle } = useTwoColumns();

  const [_name, setName] = React.useState("");
  const [_email, setEmail] = React.useState("");
  const [_phone, setPhoneNumber] = React.useState("");
  // const [_address, setAddress] = React.useState("");
  const [_schedulingLink, setSchedulingLink] = React.useState("");
  const [_avatar, setAvatar] = React.useState<string>("");
  const [_isFieldTrainerChecked, setIsFieldTrainerChecked] =
    React.useState<boolean>(false);
  // const [highestStreak, setHighestStreak] = React.useState<number>(0);
  // const [_avatar, setAvatar] =
  //   React.useState<dBoxMediaPicker["initialMedia"]>(null);

  React.useEffect(
    function updatePersonali() {
      if (memberD) {
        setName(memberD?.personali?.displayName);
        setEmail(memberD?.personali?.email);
        setPhoneNumber(memberD?.personali?.phoneNumber);
        // setAddress(memberD?.personali?.address || "");
        setSchedulingLink(memberD?.schedulingLink)
        setAvatar(memberD?.personali?.photoURL);
        setIsFieldTrainerChecked(memberD?.roles?.includes(FRBS_ROLE.TRAINER));
        // setHighestStreak(
        //   memberD?.activityStreaks
        //     ? Math.max(...memberD?.activityStreaks?.map((_s) => _s.streak))
        //     : 0
        // );
      }
    },
    [memberD]
  );

  // const [showAvatar, setShowAvatar] = React.useState<boolean>(true);

  async function onSave(xong) {
    try {
      let photoURL = memberD?.personali?.photoURL;
      // if (_avatar && _avatar?.uri != memberD?.personali?.photoURL) {
      //   const uploadedMedia = await fn.media.uploadMedia(
      //     _avatar?.uri,
      //     {
      //       folder: `${CLOUDINARY_FOLDER.CLIENTS}/${teamId}`,
      //       title: "user_" + memberD?.personali?.email,
      //       type: "image",
      //     },
      //     (progress) => {},
      //     (data) => {}
      //   );

      //   photoURL = uploadedMedia
      //     ? uploadedMedia?.url
      //     : memberD?.personali?.photoURL;
      // }
      // setShowAvatar(false);

      memberHandler
        .update({
          personali: {
            ...memberD?.personali,
            phoneNumber: _phone,
            // address: _address,
            displayName: _name,
            photoURL,
          },
          schedulingLink: _schedulingLink,
          roles: _isFieldTrainerChecked
            ? firebase.firestore.FieldValue.arrayUnion(...[FRBS_ROLE.TRAINER])
            : memberD?.roles?.filter((f) =>
              f === FRBS_ROLE.TRAINER ? false : true
            ),
        })
        .then(() => {
          xong && xong();
          // setShowAvatar(true);
          Toasty.show("Saved!", { type: "success" });
        });
    } catch (error) {
      xong && xong();
      alert(error);
      // Poppy.Error(error, "s-personali", "Updating personal info");
    }
  }

  //#region [section] phone validation
  const [phoneValidation, setPhoneValidation] = React.useState<boolean>(true);

  function validatePhoneNum(text: string) {
    (isInputNumeric(text) || text == "") && setPhoneNumber(text);
    setPhoneValidation(isInputPhoneNum(text));
  }
  //#endregion

  function androidPermissionPrompt(): Promise<void> {
    return new Promise((resolve, reject) => {
      Alert.alert(
        "Photos Permission",
        "Access to your photo library is required to use this feature. Selected photos will be stored only on our secure servers.",
        [
          {
            text: "View Privacy Policy",
            onPress: async () => {
              await LinkURL("https://apptakeoff.com/privacy-policy");
            },
          },
          { text: "Decline", onPress: () => reject() },
          { text: "Agree", onPress: () => resolve() },
        ]
      );
    });
  }

  return React.useMemo(() => {
    // if (memberId !== "new_user") {
    //   if (memberHandler.error)
    //     return (
    //       <Txt.Indicator marginV>{tr("Error getting user info")}</Txt.Indicator>
    //     );

    //   if (!memberD || !(memberId || frbsUser?.uid)) {
    //     // Toasty.show(tr("Error getting your account"), { type: "danger" });
    //     return <Skeleton layout={Skeleton.Layout.ProfileItem} />;
    //   }
    // }

    return (
      <View
      // contentContainerStyle={column1ContainerStyle}
      >
        <A.CardPersonali>
          {/* <BoxMediaPicker
            initialMedia={
              memberD?.personali?.photoURL &&
              showAvatar && {
                uri: memberD?.personali?.photoURL,
                type: "image",
              }
            }
            showMedia={showAvatar}
            onSelect={setAvatar}
            pickerOptions={{
              aspect: [1, 1],
              mediaTypes: MediaTypeOptions.Images,
            }}
            containerStyle={{
              borderRadius: 100,
              minHeight: 100,
              width: 100,
            }}
          /> */}
          <View style={{ justifyContent: "center", alignSelf: "flex-start" }}>
            <Avt
              name={memberD?.personali?.displayName || ""}
              // source={{ uri: memberD?.personali?.photoURL || "" }}
              source={{ uri: _avatar || memberD?.personali?.photoURL }}
              size={scale(110)}
            />
            <Buttoon
              appearance="ghost"
              status="basic"
              // style={{ alignSelf: "flex-start" }}
              onPress={async () => {
                const { status } =
                  await ImagePicker.getMediaLibraryPermissionsAsync();
                if (Platform.OS === "android") {
                  if (status === ImagePicker.PermissionStatus.UNDETERMINED)
                    await androidPermissionPrompt();
                }
                if (status === ImagePicker.PermissionStatus.DENIED) {
                  Alert.alert(
                    "Access denied.",
                    "You need to enable access to your photo library for this app first! Do this in your device Settings."
                  );
                  return;
                }
                ImagePicker.launchImageLibraryAsync({
                  mediaTypes: MediaTypeOptions.Images,
                  allowsEditing: true,
                  quality: 1,
                }).then(async (result) => {
                  if (!result.cancelled) {
                    uploadMedia(
                      result.uri,
                      {
                        folder: `${CLOUDINARY_FOLDER.CLIENTS}/${memberD?._teamId}/${CLOUDINARY_FOLDER.PROFILE_PHOTOS}`,
                        title: `profile_${memberD?._id}`,
                        type: "image",
                      },
                      (progress) => { },
                      async (data) => {
                        try {
                          setAvatar(data.url);
                          await memberHandler.update({
                            personali: {
                              ...memberD?.personali,
                              photoURL: data.url,
                            },
                          });
                          recordUserAction(memberD, ACTION_TYPE.UPLOADED_PROFILE_IMAGE, `${memberD?.personali?.displayName} uploaded a new profile picture!`, { location: data.url, involvedPartyName: memberD?.personali?.displayName, thumbnail: data.url })
                          Toasty.show("Profile photo updated!");
                        } catch (err) {
                          Toasty.show(err, { type: "danger" });
                        }
                      }
                    );
                  }
                });
              }}
            >
              Edit
            </Buttoon>
          </View>

          <A.Ctnr$>
            <Kitten.Input
              status={!_name && "warning"}
              style={{ color: C.text, backgroundColor: C.background }}
              placeholder={tr("Full name")}
              label={tr("Full name")}
              value={_name}
              onChangeText={setName}
            />

            <Kitten.Input
              status={(!_phone || !phoneValidation) && "warning"}
              style={{ color: C.text, backgroundColor: C.background }}
              placeholder={tr("Phone number")}
              label={tr("Phone number")}
              value={_phone}
              onChangeText={validatePhoneNum}
              caption={
                _phone || _phone === memberD?.personali?.phoneNumber
                  ? phoneValidation
                    ? null
                    : tr("Please enter a valid 10 digit phone number")
                  : null
              }
            />

            <Kitten.Input
              status={!_schedulingLink && "warning"}
              style={{ color: C.text, backgroundColor: C.background }}
              placeholder={tr("Scheduling Link")}
              label={tr("Scheduling Link")}
              value={_schedulingLink}
              keyboardType="url"
              autoCapitalize="none"
              autoCorrect={false}
              onChangeText={setSchedulingLink}
            />

            <Kitten.Input
              status={!_email && "warning"}
              disabled
              style={{ color: C.text, backgroundColor: C.background }}
              placeholder={tr("Email")}
              label={tr("Email")}
              value={_email}
              onChangeText={setEmail}
            />

            {/* <Kitten.ViewH> */}
            <Kitten.CheckBox
              // style={{ marignVertical: spacing(4) }}
              style={{ marginTop: spacing(4), marginBottom: spacing(2) }}
              checked={_isFieldTrainerChecked}
              onChange={(checked) => {
                setIsFieldTrainerChecked(checked);
              }}
            >
              Field Trainer
            </Kitten.CheckBox>
            {/* </Kitten.ViewH> */}
          </A.Ctnr$>
          <A.Ctnr$>
            <Buttoon
              disabled={
                (_name === memberD?.personali?.displayName || _name == "") &&
                (_avatar === memberD?.personali?.photoURL || _avatar == "") &&
                (_phone === memberD?.personali?.phoneNumber ||
                  _phone == "" ||
                  !phoneValidation) &&
                (_schedulingLink === memberD?.schedulingLink || _schedulingLink == "") &&
                _isFieldTrainerChecked ===
                memberD?.roles?.includes(FRBS_ROLE.TRAINER)
              }
              size="small"
              progress={true}
              onPress={(xong) => {
                onSave(xong);
              }}
            >
              {tr("Save changes")}
            </Buttoon>
            {frbsUser?.email !== memberD?.personali?.email && (
              <Buttoon
                appearance={"ghost"}
                status="control"
                onPress={() => {
                  setFrbsUser({ ...frbsUser, uid: frbsUser?.normalUid });
                  Toasty.show("Enjoy being yourself again.");
                }}
              >
                Stop imitating
              </Buttoon>
            )}
          </A.Ctnr$>
        </A.CardPersonali>
        <Txt.Helper marginV>{`v${version}`}</Txt.Helper>
        <Buttoon
          appearance="ghost"
          onPress={() => {
            Linking.openURL("https://apptakeoff.com/privacy-policy");
          }}
        >
          Privacy Policy
        </Buttoon>
      </View>
    );
  }, [
    memberD,
    _email,
    _name,
    _phone,
    _avatar,
    _schedulingLink,
    C,
    _isFieldTrainerChecked,
  ]);
}

const A = {
  CardPersonali: sstyled(Kitten.Card)((p) => ({
    // justifyContent: "flex-start",
  })),
  CtnrAvatar: sstyled(View)({
    // flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  Ctnr$: sstyled(View)({
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    // width: p.WIDTH * 0.2,
  }),
};

{
  /* <Avt
          source={{
            uri:
              "https://media2.giphy.com/media/VPzxNKdSAE1Lq/giphy.gif?cid=ecf05e47pvd3a8np3w4rhvlx4vk2p7p69q7ht31xo8i1kag6&rid=giphy.gif",
          }}
          size={"small"}
        />
        <Avt
          source={{
            uri:
              "https://media0.giphy.com/media/QXCyVpOe38gCFVbN6X/giphy.gif?cid=ecf05e47bxhtzpt6turwpke0uxrkir9qgbbdb1dmfkdcf88o&rid=giphy.gif",
          }}
          size={"small"}
        />
        <Avt
          source={{
            uri:
              "https://media1.giphy.com/media/fLsxJPxbH0S9ZgSGBH/giphy.gif?cid=ecf05e47z7wnkpx2kepubvysxmyqze8iwdznpifvojii9rqp&rid=giphy.gif",
          }}
          size={"small"}
        />
        <Avt
          source={{
            uri:
              "https://media2.giphy.com/media/YjRKd3W0dmf1ZMtJky/giphy.gif?cid=ecf05e47f9fe9yovgd22lngu7g2squhphjjesnyo8ayyalla&rid=giphy.gif",
          }}
          size={"small"}
        /> */
}
