//#region [import]
import { IconPrimr } from "assets";
import { Avt, Kitten, Poppy, sstyled, Toasty, Txt } from "components";
import { ACTION_TYPE, FPATH, recordUserAction, useAppContext, UserSchema } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { Contact } from "expo-contacts";
import firebase from "firebase";
import React from "react";
import { Platform, View } from "react-native";
import { moderateScale } from "react-native-size-matters";
//#endregion

export function _newContact(
  uid: string,
  groupId: string,
  phoneContact: Contact
): ContactSchemaa {
  console.log(JSON.stringify(phoneContact));
  // alert(JSON.stringify(phoneContact));
  const contactId =
    (Platform.OS === "android"
      ? //@ts-ignore no type for it but we know on android we have this
      phoneContact.lookupKey || phoneContact.id || phoneContact._id
      //@ts-ignore _id
      : phoneContact.id || phoneContact._id)?.replace("CNSuggestedContactStore://", "")?.replace(/\/\//g, "");
  const newContact = //@ts-ignore
    {
      name: phoneContact?.name?.replace("undefined", ""),
      ownerUid: uid,
      emails:
        phoneContact.emails?.length > 0
          ? firebase.firestore.FieldValue.arrayUnion(
            ...(phoneContact?.emails || [{}])
          )
          : [],
      // @deprecated using only .name in future
      displayName: phoneContact?.name?.replace("undefined", ""),
      // @deprecated using only .emails in future
      email: phoneContact?.emails?.pop()?.email || null,
      phoneNumbers:
        phoneContact?.phoneNumbers?.length > 0
          ? firebase.firestore.FieldValue.arrayUnion(
            ...(phoneContact?.phoneNumbers || [{}])
          )
          : [],
      _phoneNumberStrings:
        phoneContact?.phoneNumbers?.length > 0
          ? firebase.firestore.FieldValue.arrayUnion(
            ...(phoneContact?.phoneNumbers || [""]).map(
              (pn) => pn.digits || pn.number?.replace(/\D/g, "") || ""
            )
          )
          : [],
      groups: firebase.firestore.FieldValue.arrayUnion(groupId || ""),
      // @deprecated using only .groups in future
      listId: groupId,
      // NOTE: what is this for?
      groupType: "email-groups",
      createdAt: new Date(),
      id: contactId,
      _cid: contactId,
    } as ContactSchemaa;
  return newContact;
}

// if contact already exists, we will update it (leave groupId a blank string if you don't want to assign a group yet)
export async function addContact(
  user: UserSchema,
  groupId: string,
  phoneContact: Contact
) {
  try {
    const newContact = _newContact(user._id, groupId, phoneContact);

    const contactsRef = firebase
      .firestore()
      .collection(FPATH.USERS)
      .doc(user._id)
      .collection(FPATH.CONTACTS);

    if (phoneContact?.id && phoneContact?.id?.length > 0) {
      // we have a id from the contact we can use, so USE it (could also be in another contact group already so let's merge)
      await contactsRef.doc(newContact._cid).set(newContact, { merge: true });
    } else {
      // we do not have a id from the contact so let's just get a random id and make it
      await contactsRef.add(newContact);
    }

    if (groupId?.length > 0) {
      // update the group to contain this contact if groupId is provided
      firebase
        .firestore()
        .doc(`${FPATH.USERS}/${user._id}/${FPATH.CONTACT_GROUPS}/${groupId}`)
        .update({
          contacts: firebase.firestore.FieldValue.arrayUnion(
            `${phoneContact?.id}`
          ),
        });
    }

    recordUserAction(user, ACTION_TYPE.ADD_CONTACT, `${user.personali?.displayName} added contact: ${newContact.name}`, { location: newContact._cid, involvedPartyName: newContact.name })
    // await contactGroupHandler.update({
    //   contacts: firebase.firestore.FieldValue.arrayUnion(`${contact?.id}`),
    // });
    Toasty.show(`${phoneContact.name} added${phoneContact?.id ? "." : ""}`, {
      type: "success",
    });
  } catch (err) {
    Poppy(`Failed to add contact`, err);
  }
}

/**
 * ### Web version of Home screen
 *  ----
 *  @version 21.03.01
 *  -  *Brief changelog*
 *  @author  @nguyenkhooi
 *
 **/
export function ContactCell(props: P) {
  const { item, contact_category_id, contactHandler, contactGroupHandler, user } =
    props;
  // console.log("const id = props.route?.params?.id; ", id);
  // const Navigation = useNavigator();
  const { C, theme, frbsUser } = useAppContext();
  // const bottomBarHeight = useBottomTabBarHeight();
  // const bottomBarHeight = 101;
  // console.log("theme ", theme);
  // const contactHandler = useContactsCollection(
  //   frbsUser?.uid,
  //   contact_category_id
  // );

  const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);
  const [checked, setChecked] = React.useState<boolean>(false);

  // const contactGroupHandler = useContactGroupDocument(
  //   frbsUser?.uid,
  //   contact_category_id
  // );

  //
  React.useEffect(
    function setupData() {
      if (contactHandler.data) {
        setContacts(
          contactHandler.data?.filter((c) =>
            c?.groups?.some((group) => group == contact_category_id)
          )
        );
        setChecked(
          contactHandler.data?.find(
            (c) =>
              (c?.name || c?.displayName) === item.name || item?.id === c?.id
          )
            ? true
            : false
        );
      }
      for (var i = 0; i < contacts.length; i++) {
        const contact = contacts[i];
        const contactId =
          contact?._cid?.length > 0
            ? contact?._cid
            : contact?.id?.length > 0
              ? contact.id
              : `${i}`;
        if (contactId === item.id) {
          setChecked(true);
          break;
        }
      }
    },
    [contactHandler.data]
  );
  try {
    return (
      <Kitten.ListItemv2
        key={`${item?.id}`}
        title={item?.name && item.name?.trim() ? item?.name?.trim() : "No Name"}
        onPress={() => {
          setChecked(true);
          addContact(user, contact_category_id, item);
        }}
        disabled={checked}
        description={() => (
          <Txt.C2 numberOfLines={1}>
            {item.phoneNumbers && item.phoneNumbers.length > 0
              ? item?.phoneNumbers[0].number
              : ""}
          </Txt.C2>
        )}
        accessoryLeft={() => (
          <Avt
            name={
              item?.name && item.name?.trim() ? item?.name?.trim() : "No Name"
            }
            source={{ uri: "" }}
          />
        )}
        accessoryRight={() => (
          <IconPrimr
            name={checked ? "check_circle" : "plus"}
            color={checked ? C["color-success-500"] : C.grey600}
            size={moderateScale(16)}
            onPress={() => {
              setChecked(true);
              addContact(user, contact_category_id, item);
            }}
          />
        )}
      />
    );
  } catch (error) {
    throw Error(`::Q29udGFjdHNTY3JlZW4=::${error}`);
  }
}

interface P {
  user: UserSchema;
  item: Contact;
  contact_category_id?: string;
  contactHandler: any;
  contactGroupHandler: any;
  //   route?: { params?: dContactsScrParams }; //* unmask if we pass params thru rnav
}

const A = {
  ViewContainer: sstyled(View)({
    height: moderateScale(38, 0.3),
    justifyContent: "center",
  }),

  ViewContent: sstyled(View)({
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 8,
    paddingRight: 35,
  }),

  content: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 8,
    paddingRight: 35,
  },
  checkImage: {
    width: moderateScale(17, 0.3),
    height: moderateScale(17, 0.3),
  },
  plusImage: {
    width: moderateScale(17, 0.3),
    height: moderateScale(17, 0.3),
    tintColor: "white",
  },
  text: {
    // color: darkMode ? "white" : Colors.black,
    fontSize: moderateScale(17, 0.3),
    // flex: 1,
    // fontFamily: Fonts.medium,
  },
  line: {
    width: "90%",
    left: 10,
    bottom: 0,
    position: "absolute",
    // backgroundColor: darkMode ? "rgb(245,245,245)" : "rgb(180,180,180)",
    height: moderateScale(0.6, 0.3),
  },
};
