interface d {}

/**
 * ### Necessary for web DO NOT DELETE!
 * - If web tries to load android-videolly.tsx it will crash
 **/
export function AndroidVideolly(props: d) {
  const {} = props;
  return null;
}
