import {
  Buttoon,
  Kitten,
  Skeleton,
  sstyled,
  Toasty,
  Txt
} from "components/atoms";
import { ACTION_TYPE, recordUserAction, useAppContext, useNotificationCollection, UserSchema } from "engines";
import {
  useMemberDocument,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import firebase from "firebase";
import React from "react";
import { TextInput, TextStyle, View, ViewStyle } from "react-native";
import { useNavigator } from "screens/_navigation";
import { scale, spacing } from "utils";

/**
 * ###
 *  - This component is used for sharing and unsharing contacts
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.01.21
 *  -  *Brief changelog*
 *  @author  NL
 *
 **/
export function ShareAccountDialog({ dialogue = "", onSelectUser }: { dialogue?: string, onSelectUser?: (user: UserSchema) => void }) {
  const { C, teamId } = useAppContext();
  const { frbsUser } = useAppContext();

  const Navigation = useNavigator();

  const memberHandler = useMemberDocument(frbsUser?.uid);
  const { sendNotificationToUser } = useNotificationCollection(`${teamId}`);
  const userProfile = useUserProfile();
  //#region [FRBS]

  /**
   * This is used to get members list that could be added as contacts
   */
  const [_sharedWithUids, setSharedWithUids] = React.useState<string[]>([]);
  const [_searchValue, setSearchValue] = React.useState("");
  const [_members, setMembers] = React.useState<UserSchema[]>();
  firebase
    .functions()
    .httpsCallable("getFieldTrainers")({ teamId })
    .then((r) => setMembers(r.data));

  const shareData = memberHandler?.data?.growth?.listBuilder?.shareTo;
  React.useEffect(function setStates() {
    setSharedWithUids(shareData ? shareData : []);
  }, []);

  //#region [FRBS]


  //#region [section] search ops

  const didAnySelect = React.useRef(false);
  const onSelect = async (userID: string) => {
    if (!_sharedWithUids) return;
    const unshare = _sharedWithUids.includes(userID);
    didAnySelect.current = true;
    const shareTo = _sharedWithUids.includes(userID)
      ? _sharedWithUids.filter((id) => id != userID)
      : [..._sharedWithUids, userID];

    setSharedWithUids(shareTo);

    memberHandler.update({
      growth: {
        listBuilder: {
          shareTo: firebase.firestore.FieldValue.arrayUnion(...shareTo),
        },
      },
    });

    const selectedUser = _members
      .filter((member) => {
        return member._id?.toLowerCase().includes(userID.toLowerCase());
      })
      .pop();

    !unshare && onSelectUser && onSelectUser(selectedUser)

    if (selectedUser && !unshare)
      recordUserAction(memberHandler.data, ACTION_TYPE.ASSIGNED_TRAINER, `${memberHandler.data?.personali?.displayName} assigned ${selectedUser.personali?.displayName} as their field trainer.`, { location: selectedUser._id, involvedPartyName: selectedUser.personali?.displayName, thumbnail: selectedUser?.personali?.photoURL })
    else if (selectedUser && unshare)
      recordUserAction(memberHandler.data, ACTION_TYPE.UNASSIGNED_TRAINER, `${memberHandler.data?.personali?.displayName} removed ${selectedUser.personali?.displayName} from being their field trainer.`, { location: selectedUser._id, involvedPartyName: selectedUser.personali?.displayName, thumbnail: selectedUser?.personali?.photoURL })

    if (!unshare) {
      sendNotificationToUser(
        selectedUser,
        `New Teammate`,
        `${userProfile?.data?.personali?.displayName} has assigned you as their trainer. You can now access their profile.`,
        userProfile?.data?.personali?.photoURL
      );
      /**
       * Used in sending notifications to users: which isn't implemented yet
       */
    }

    Toasty.show(
      `Profile ${unshare ? "unshare" : "share"
      } complete. \n Your profile has been ${unshare ? "unshared" : "shared"
      } with ${selectedUser?.personali.displayName
        ? selectedUser?.personali.displayName
        : "?"
      }`,
      {
        type: "success",
      }
    );
  };

  //#endregion

  //#region [section] search ops
  function filterHandler(value: string) {
    setSearchValue(value);

    // const newData = _members.filter((member) => {
    //   return member.personali?.displayName
    //     ?.toLowerCase()
    //     .includes(value.toLowerCase());
    // });

    // value.length == 0 || value.length < 0
    //   ? setMembers([...handleMembers.data])
    //   : setMembers([...newData]);
  }
  //#endregion

  // return React.useMemo(() => {
  try {
    // if (handleMembers.error) {
    //   console.warn("error in ContactsFeed: ", handleMembers.error);
    //   return <Txt.Indicator>{tr("Error getting contacts feed")}</Txt.Indicator>;
    // }
    // if (!handleMembers.data) return <Skeleton.Screen.Members length={7} />;
    if (!_members || _members?.length < 1)
      return (
        <>
          <Txt.Indicator>
            Trainers must assign themselves as trainers in their profile before
            showing up here.
          </Txt.Indicator>
          <Skeleton.Screen.Members length={7} />
        </>
      );

    return (
      <A.ViewContainer>
        <Kitten.Layout
          style={{
            paddingHorizontal: scale(15),
            marginBottom: 90,
            paddingBottom: scale(15),
          }}
        >
          <Txt.P2 style={A.CAPTION_TXT}>
            {
              "By selecting your trainer, the selected user(s) along with their Administrator will be given access to your profile & contact groups."
            }
          </Txt.P2>

          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Kitten.Input
              style={{ flex: 1 }}
              value={_searchValue}
              onChangeText={(value) => {
                // filterHandler(value);
                setSearchValue(value);
              }}
              placeholder="Search someone..."
            />
            {_sharedWithUids?.length > 0 ? (
              <Buttoon
                style={{ alignSelf: "flex-end", marginRight: -spacing(3) }}
                appearance="ghost"
                // status="info"
                size="medium"
                // icon={{ name: "check", right: true, size: moderateScale(14) }}
                onPress={() => Navigation.goBack()}
              >
                Done
              </Buttoon>
            ) : null}
          </View>
          {_members
            .filter(
              (m) =>
                _searchValue.length < 1 ||
                m?.personali?.displayName
                  ?.toLowerCase()
                  ?.includes(_searchValue?.toLowerCase())
            )
            .filter((u, idx, arr) => idx < 50)
            .map((user, index) => (
              <Kitten.CheckBox
                key={user?._id}
                style={A.OPTION}
                checked={_sharedWithUids && _sharedWithUids.includes(user?._id)}
                onChange={() => {
                  onSelect(user?._id);
                }}
              >
                {() => (
                  <View style={{ paddingLeft: 10 }}>
                    <Txt.P1>
                      {!user.personali.displayName
                        ? "Unknown"
                        : user.personali.displayName}
                    </Txt.P1>
                    <Kitten.Text category="c1">
                      {" "}
                      {user.personali.email}
                    </Kitten.Text>
                  </View>
                )}
              </Kitten.CheckBox>
            ))}
        </Kitten.Layout>
        <Txt.Helper>
          Trainers must assign themselves as trainers in their profile before
          showing up here.
        </Txt.Helper>
      </A.ViewContainer>
    );
  } catch (error) {
    throw Error(`::QWRkQ29udGFjdENyZWF0b3I=::${error}`);
  }
  // }, [contacts, C]);
}

const A = {
  ViewContainer: sstyled(View)({
    marginVertical: 3,
    // minWidth: 300,
  }),
  Input: sstyled(TextInput)(({ borderColor = "primary" }) => ({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: borderColor,
  })),
  OPTION: {
    marginVertical: 4,
    marginHorizontal: 12,
  } as ViewStyle,
  CAPTION_TXT: {
    textAlign: "center",
    // color: C.
    marginVertical: spacing(4),
    marginHorizontal: spacing(2),
  } as TextStyle,
};

interface P {
  id: string;
}
