import { StackActions } from "@react-navigation/native";
import { IconPrimr } from "assets";
import { Buttoon, Poppy, Txt } from "components";
import { useAppContext } from "engines";
import React from "react";
import { View } from "react-native";
// import { ScrollView } from "react-native-gesture-handler";
import ImageViewer from "react-native-image-zoom-viewer";
import { IImageInfo } from "react-native-image-zoom-viewer/built/image-viewer.type";
import { dGalleryScrParams, useNavigator } from "screens/_navigation";
import { tr } from "utils";

/**
 * ### A full screen of image gallery
 * @todo ts route.params
 * @see https://reactnavigation.org/docs/typescript/#type-checking-screens
 * ---
 * @example
 * Navigation.navigate("Gallery",{params: {images: [{url: "https://avatars2.githubusercontent.com/u/7970947?v=3&s=460"}]}})
 * @version 1.5.27
 * @author  nguyenkhooi
 */
export function GalleryScreen(props: P) {
  //#region [section] Memoized values
  let imageUrls: IImageInfo[] = React.useMemo(
    () => props.route?.params?.images,
    [props.route.params]
  );
  let imageIndex = React.useMemo(
    () => props.route?.params?.imageIndex || 0,
    [props.route?.params]
  );
  //#endregion

  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  // React.useEffect(
  //   function setHierarchyName() {
  //     !imageUrls && Navigation.navigate("Home");
  //   },
  //   [imageUrls]
  // );

  //#region [section] Navigation Arrows
  const [leftDisability, setLeftDisability] = React.useState<boolean>(false);
  const [rightDisability, setRightDisability] = React.useState<boolean>(false);
  React.useEffect(function iniLeftArrowDisability() {
    imageIndex == 0 && setLeftDisability(true);
    imageUrls?.length < 2 &&
      (setLeftDisability(true), setRightDisability(true));
  }, []);
  //#endregion

  try {
    if (!imageUrls || imageUrls?.length == 0) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Txt.Helper marginV>{tr("No image found")}</Txt.Helper>
          <Buttoon
            appearance={"outline"}
            size={"small"}
            onPress={() => Navigation.dispatch(StackActions.replace("Drawer"))}
          >
            {tr("Go home")}
          </Buttoon>
        </View>
      );
    }
    return (
      <ImageViewer
        imageUrls={imageUrls}
        index={imageIndex}
        useNativeDriver={true}
        // backgroundColor={C.background}
        backgroundColor={"#0b0e10"}
        enablePreload
        renderArrowLeft={() => (
          <IconPrimr
            disabled={leftDisability}
            preset={"circular"}
            name={"arrow_left"}
            color={C.text01}
          />
        )}
        renderArrowRight={() => (
          <IconPrimr
            disabled={rightDisability}
            preset={"circular"}
            name={"arrow_right"}
            color={C.text01}
          />
        )}
        onChange={(index) => {
          if (index == 0) {
            setLeftDisability(true);
          } else setLeftDisability(false);
          if (index == [...imageUrls, ...imageUrls].length - 1) {
            setRightDisability(true);
          } else setRightDisability(false);
        }}
        enableSwipeDown
        onSwipeDown={() => Navigation.goBack()}
        menuContext={{ saveToLocal: "Save", cancel: "Cancel" }}
        onSave={() =>
          Poppy(
            "Final Check", //TODO should be save function
            "Are you sure you want this poppy?",
            {
              text: "Cancel",
              onPress: () => {},
            },
            {
              text: "Yes",
              onPress: () => console.log("Poppy popped!"),
            }
          )
        }
      />
    );
  } catch (error) {
    throw Error(`::R2FsbGVyeVNjcmVlbg==::${error}`);
  }
}

interface P {
  route: { params: dGalleryScrParams };
}

// const images = [
//   {
//     url: "https://avatars2.githubusercontent.com/u/7970947?v=3&s=460",
//   },
//   {
//     url: "https://avatars2.githubusercontent.com/u/7970947?v=3&s=460",
//   },
//   {
//     url: "https://avatars2.githubusercontent.com/u/7970947?v=3&s=460",
//   },
//   {
//     url: "https://avatars2.githubusercontent.com/u/7970947?v=3&s=460",
//   },
// ];
