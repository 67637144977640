import { API_Contact, dExportContacts } from "engines/backends/schemas/bml";
import { ExportToCsv } from "export-to-csv";

export function ContactsExport(
  choosenFields: string[] = [
    "Name",
    "email",
    "phoneNumber",
    "notes",
  ],
  fileName: string = "Contact",
  shouldShare: boolean = true,
  altLists?: dExportContacts[]
) {
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    filename: fileName,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: choosenFields,
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(altLists);
}
