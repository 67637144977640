import { Buttoon, Kitten, sstyled } from "components";
import React from "react";
import { FlatList, Pressable, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";

interface dPickyColor {
  color: string;
  onSubmit(color: string): void;
}
export const PickyColor = React.forwardRef<any, dPickyColor>((props, ref) => {
  const { onSubmit, color } = props;
  const Navigation = useNavigator();
  React.useEffect(
    function setInitialColor() {
      setCurrentColor(color);
    },
    [color]
  );
  const [currentColor, setCurrentColor] = React.useState<string>(color);

  function _onSubmit() {
    if (currentColor === color) {
      Navigation.goBack();
    } else {
      onSubmit && onSubmit(currentColor);
    }
  }

  const _colors = [
    "#fff100",
    "#ff8c00",
    "#e81123",
    "#ec008c",
    "#68217a",
    "#00188f",
    "#00bcf2",
    "#00b294",
    "#009e49",
    "#bad80a",
  ];
  return (
    <View style={[]}>
      <FlatList
        numColumns={5}
        data={_colors}
        renderItem={({ item: hexCode, index }) => (
          <A.PressableColorCard
            key={index}
            hexCode={hexCode}
            onPress={() => setCurrentColor(hexCode)}
          />
        )}
      />
      <Kitten.Divider_________ />
      <Buttoon
        onPress={_onSubmit}
        icon={{ name: "square", color: currentColor, right: true }}
      >
        {color === currentColor
          ? tr("Use current color")
          : tr("Use this color")}
      </Buttoon>
      <Buttoon
        disabled={currentColor === color}
        onPress={() => {
          setCurrentColor(color);
        }}
        appearance={"ghost"}
      >
        {tr("Reset")}
      </Buttoon>
    </View>
  );
});

const A = {
  PressableColorCard: sstyled(Pressable)(({ hexCode }) => ({
    width: scale(42),
    height: scale(42),
    margin: spacing(3),
    backgroundColor: hexCode,
    borderRadius: scale(4),
  })),
};
