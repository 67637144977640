import { useDocument } from "@nandorojo/swr-firestore";
import { FPATH, ShortcutSchema } from "engines";
import { useCollectionPresetv2 } from "../config";
import firebase from "firebase";
import * as R from "ramda";
import _ from "lodash";

export function useMoreDocument(id: string, query?) {
  const moreDocument = useDocument<ShortcutSchema>(`${FPATH.MORE}/${id}`, {
    listen: true,
    ...query,
  });

  return { ...moreDocument };
}

export function useMoreCollection(query?) {
  const moreCollection = useCollectionPresetv2<ShortcutSchema>(
    FPATH.MORE,
    "_id",
    query
  );

  let positions = moreCollection.data
    ? R.pluck("position", moreCollection.data)
        .map(Number)
        .filter((p) => !!p === true && p != Number.NEGATIVE_INFINITY)?.length == //* Temporary sol to ignore all NaN values
      0
      ? [0]
      : R.pluck("position", moreCollection.data)
          .map(Number)
          .filter((p) => !!p === true && p != Number.NEGATIVE_INFINITY) //* Temporary sol to ignore all NaN values
    : [0];

  function _largestPositionIndex() {
    // alert(JSON.stringify(positions) + "\n" + _.max(positions));
    // return Math.max(...positions) !== Number.NEGATIVE_INFINITY ? ;
    return _.max(positions);
  }

  function _smallestPositionIndex() {
    return _.min(positions);
  }

  function _updatePosition(data: { cpid: string; position: number }) {
    return updateShortcutPosition(data);
  }

  return {
    ...moreCollection,
    _largestPositionIndex,
    _smallestPositionIndex,
    _updatePosition,
  };
}

export const updateShortcutPosition = async (data: {
  cpid: string;
  position: number;
}) => {
  // console.log("data request ", data);
  try {
    const updateResourcePosition = await firebase
      .firestore()
      .collection(FPATH.MORE)
      .doc(data.cpid)
      .update({
        position: data.position,
      });
    return updateResourcePosition;
  } catch (error) {
    return error;
  }
};
