import createMockSender from "@rpldy/mock-sender";
import NativeUploady, {
  UploadyContext,
  useItemFinalizeListener,
  useItemStartListener
} from "@rpldy/native-uploady";
import retryEnhancer from "@rpldy/retry-hooks";
import UploadButton from "@rpldy/upload-button";
import UploadDropZone from "@rpldy/upload-drop-zone";
import withPasteUpload from "@rpldy/upload-paste";
import UploadPreview, { PreviewMethods } from "@rpldy/upload-preview";
import { composeEnhancers } from "@rpldy/uploady";
import { Kitten, sstyled } from "components";
import { Buttoon, Toasty, Txt } from "components/atoms";
import { fn, PosstSchema, useAppContext } from "engines";
import * as ImagePicker from "expo-image-picker";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { dQueueItem, QueueItem } from "../../posst-creator/QueueItem";

/**
 * ### Posst Creator Media section
 *  - With abilities to select media from local directories
 *  ----
 *  @example
 *  <M.PosstMedia />
 *  ----
 *  @version 21.03.15
 *  -  *Move to Molecules*
 *  @author  K, Nl
 *
 **/

const mockSenderEnhancer = (uploader) => {
  const mockSender = createMockSender({ delay: 1500 });
  uploader.update({ send: mockSender.send });
  return uploader;
};

const PasteUploadDropZone = withPasteUpload(UploadDropZone);

export function PageCreatorMedia({
  onSelect,
  setLocalUri,
  ValueInput,
  onSetValueUrl,
  cpid,
  pcid,
}: dPosstCreatorMedia) {
  const { C } = useAppContext();

  //#region [section]  Media Callback
  const previewMethodsRef = React.useRef<PreviewMethods>();
  const [media, setMedia] = React.useState<dQueueItem>(null);
  // const [media1, setMedia1] = React.useState<FileUop>(null);
  const [toggle, setToggle] = React.useState<boolean>(false);
  const [previews, setPreviews] = React.useState<dQueueItem>(null);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [valueType, setValueType] = React.useState<string>("");

  // React.useEffect(
  //   function onSelectCallback() {
  //     if (media) {
  //       onSelect && media != null && onSelect(media);
  //       setLocalUri && media != null && setLocalUri(media);
  //     } else {
  //       onSelect && previews != null && onSelect(previews);
  //       setLocalUri && previews != null && setLocalUri(previews);
  //     }
  //   },
  //   [media, previews, ValueInput, inputValue]
  // );

  React.useEffect(
    function InsertValue() {
      setInputValue(ValueInput);
    },

    [ValueInput]
  );

  React.useEffect(
    function inputValueCheck() {
      console.log("inputValue ", inputValue);
      checker(inputValue);
      onSetValueUrl(inputValue);
    },
    [inputValue]
  );

  //#endregion
  const uploady = React.useContext(UploadyContext);
  //#region [section2] MEDIA-PICKER
  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    // console.log(result); //* e.g. "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="
    if (!result.cancelled) {
      let _mediaType = fn.media.extractMediaType(result?.uri);

      if (_mediaType !== "image" && _mediaType !== "video") {
        Toasty.show("Unknown file type.", { type: "danger" });
        setMedia(null);
      } else {
        // let uplaodResult: string = result.uri;

        // uploady.upload(media1);
        // console.log("result?.uri ", _mediaType);
        onSelect({
          type: _mediaType,
          uri: result?.uri,
        });
        // setMedia({
        //   type: _mediaType,
        //   id: "image",
        //   name: "image",
        //   url: result?.uri,
        // });
      }
    }
  };
  //#endregion
  const Navigation = useNavigator();
  const uploadRef = React.useRef(null);

  // const onPasteUpload = React.useCallback(() => {
  //   if (uploadRef && uploadRef.current) {
  //     uploadRef.current(); //initiate upload
  //   }
  // }, []);

  const UploadStatus = () => {
    const [status, setStatus] = React.useState(null);

    useItemStartListener(() => setStatus("Uploading..."));
    useItemFinalizeListener(() => setStatus("Finished!"));

    return <Txt>{"Status: " + status}</Txt>;
  };

  const onPreviewsChanged = React.useCallback((previews) => {
    setPreviews(previews);
    setInputValue(previews.id);
    // console.log("previews ", previews.id);
  }, []);

  const onClear = React.useCallback(() => {
    if (previewMethodsRef.current?.clear) {
      previewMethodsRef.current.clear();
      setInputValue("");
      setMedia(null);
    }
  }, [previewMethodsRef]);
  const refInput = React.useRef<TextInput>();

  function checker(inputValue) {
    /**
     * this function would check if the link is a youtube like or
     */
    if (!inputValue) {
      console.log("do nothing");
    } else {
      var check = fn.js.VideoLinkChecker(inputValue);
      if (check == "youtube") {
        setValueType("youtube");
      } else if (check == "vimeo") {
        setValueType("vimeo");
      } else if (check == "video") {
        setValueType("video");
      } else if (check == "dropbox") {
        setValueType("dropbox");
      } else if (check == "image") {
        setValueType("image");
      }
    }
  }
  let CASE = {
    PREVIEW_AVAILABLE: !previews || inputValue || media,
  };

  // [1,2.300,400,501]
  // 3 -2 =1
  // change + 1
  // 4-2
  // change 2 + 2 = 4
  // 5-2
  // change 3+ 2 = 5
  return (
    <>
      {/* <Txt>{valueType ? valueType : "nanle"}</Txt> */}
      {/* <Buttoon onPress={() => checker(inputValue)}>Checker</Buttoon> */}
      <NativeUploady
        debug
        destination={{ url: "my-server.com/upload" }}
        enhancer={composeEnhancers(retryEnhancer, mockSenderEnhancer)}
      >
        <PasteUploadDropZone>
          {!CASE.PREVIEW_AVAILABLE && (
            <A.ViewWithoutMedia onPress={() => refInput.current?.focus()}>
              <Kitten.ViewH>
                <A.InputMedia
                  ref={refInput}
                  value={inputValue}
                  onChangeText={setInputValue}
                  multiline={true}
                  placeholder={tr(
                    "Drag and drop a photo/video or select them manually"
                  )}
                  placeholderTextColor={C.grey500}
                />
                <Txt.S2 onPress={pickImage}> {tr("Select")}</Txt.S2>
              </Kitten.ViewH>
            </A.ViewWithoutMedia>
          )}
        </PasteUploadDropZone>
        <A.CtnrWithMedia>
          {inputValue && inputValue.includes("http") && (
            <QueueItem
              id={inputValue}
              name={inputValue}
              type={valueType}
              url={inputValue}
              cpid={cpid}
              pcid={pcid}
            />
          )}
          {media && (
            <QueueItem
              id={media.id}
              name={media.name}
              type={media.type}
              url={media.url}
            />
          )}
          <UploadPreview
            PreviewComponent={QueueItem}
            previewMethodsRef={previewMethodsRef}
            onPreviewsChanged={onPreviewsChanged}
          />
        </A.CtnrWithMedia>
        {CASE.PREVIEW_AVAILABLE && (
          <Buttoon
            appearance={"ghost"}
            status={"danger"}
            onPress={onClear}
            style={{ margin: 2 }}
          >
            {tr("Remove")}
          </Buttoon>
        )}
      </NativeUploady>
    </>
  );
}

const A = {
  InputMedia: sstyled(TextInput)({
    fontSize: 14,
    flex: 1,
    color: "text",
    minHeight: scale(42),
  }),
  CtnrWithMedia: sstyled(View)({
    // minHeight: 6,
    // flex: 1,
    // borderRadius: 10,
    // overflow: "hidden",
    // justifyContent: "center",
    // backgroundColor: "dim",
    // maxWidth: "400px",
  }),
  ViewWithoutMedia: sstyled(Kitten.Card)((p) => ({
    borderStyle: "dotted",
    borderWidth: 3,
    paddingVertical: spacing(2),
    paddingHorizontal: spacing(2),
    // minHeight: 6,
    // flex: 1,
    // borderRadius: 10,
    // maxWidth: "400px",
    // // overflow: "hidden",
    // justifyContent: "center",
    // alignItems: "center",
  })),
  CtrnUploadButton: sstyled(UploadButton)(() => ({
    width: "52px",
    height: "34px",
    fontSize: "26px",
    lineHeight: "26px",
    cursor: "pointer",
  })),
};

interface dPosstCreatorMedia {
  onSelect?(item: PosstSchema["medias"][0]): void;
  setLocalUri?(item: PosstSchema["medias"][0]): void;
  ValueInput?: string;
  onSetValueUrl?(item: string): void;
  cpid?: string;
  pcid?: string;
}

interface FileUop {
  name: string;
  size: number;
  type: string;
  lastModified: number;
}
