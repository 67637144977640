import { useDocument } from "@nandorojo/swr-firestore";
import { CommentSchema, FPATH } from "engines";
import { useCollectionPresetv2 } from "../config";

export function useCommentDocument(posstId: string, commentId: string, query?) {
  const swrDocument = useDocument<CommentSchema>(
    `${FPATH.POSSTS}/${posstId}/${FPATH.COMMENTS}/${commentId}`,
    {
      listen: true,
      ...query,
    }
  );

  return { ...swrDocument };
}

export function useCommentsCollection(posstId: string, query?) {
  const swrCollection = useCollectionPresetv2<CommentSchema>(
    `${FPATH.POSSTS}/${posstId}/${FPATH.COMMENTS}`,
    "_pid",
    query
  );
  return { ...swrCollection };
}
