import { Buttoon, Kitten, Toasty, Txt } from "components";
import {
  ACTION_TYPE,
  dActivityEntry,
  dActivityConfiguration,
  recordUnknownUserAction,
  useActivityCollection,
  useAppContext,
  useConfigDocument
} from "engines";
import React from "react";
import { FlatList, View } from "react-native";
import { StatsScreen, useNavigator } from "screens";
import { tr } from "utils";

/**
 * ### A dialog to input user stats
 *
 *  ----
 *  @version 23.02.23
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function StatsCreatorCenter(props: {
  showStatsScreenAfterSubmission?: boolean;
}) {
  const { showStatsScreenAfterSubmission } = props;
  const { teamId, frbsUser } = useAppContext();
  const Navigation = useNavigator();
  const configHandler = useConfigDocument(`variables-${teamId}`);
  const personalActivityHandler = useActivityCollection(frbsUser?.uid);

  /**
   * Any activities with values that need to get updated or added in firestore
   */
  const [updatedActivities, setUpdatedActivities] = React.useState<dActivityEntry[]>(
    []
  );

  //   React.useEffect(
  //     function initPersonalActivity() {
  //       personalActivityHandler.data &&
  //         setPersonalActivity(personalActivityHandler.data || []);
  //     },
  //     [personalActivityHandler.data]
  //   );

  async function onSubmit() {
    const __toasty = Toasty.show("Submitting your report...", {
      type: "loading",
    });
    const today = new Date();
    for (let a in updatedActivities) {
      const _act = updatedActivities[a];
      await personalActivityHandler.updateActivityByKeyAndDate(
        personalActivityHandler.data,
        _act?.activityKey as string,
        today,
        _act
      );
    }
    Toasty.hide(__toasty);
    recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.DAILY_PROGRESS_REPORT, `${frbsUser?.uid} submitted their daily progress report.`)
    Toasty.show("Report submitted! Great job!", { type: "success" });
    Navigation.goBack();
    setTimeout(() => {
      if (showStatsScreenAfterSubmission)
        Navigation.dialogPortal.open({
          headerTitle: "Your Stats",
          render: <StatsScreen />,
        });
    }, 270);
  }

  /**
   * When a value changes, we update the activity locally in the state
   */
  function valueChanged(val: any, forEntry: dActivityConfiguration) {
    // console.log(`Changing value for ${forEntry.key} to ${val}`);
    const today = new Date();
    const preExistingActivity = updatedActivities.find(
      (a) => a.activityKey === forEntry.key
    );
    if (preExistingActivity) {
      setUpdatedActivities(
        updatedActivities.map((ac) =>
          ac._id === preExistingActivity._id &&
            ac.activityKey === preExistingActivity.activityKey
            ? { ...ac, value: val }
            : ac
        )
      );
    } else {
      setUpdatedActivities(
        updatedActivities.concat([
          {
            activityKey: forEntry.key,
            // @ts-ignore firestore should convert this Date to a Timestamp for us
            date: today,
            value:
              forEntry.entryType === "number" ? Number.parseFloat(val) : val,
          },
        ])
      );
    }
  }

  return (
    <View>
      <FlatList
        style={{ minHeight: 169 }}
        data={configHandler.data?.activities}
        renderItem={(data) => (
          <ActivityEntry entry={data.item} onValueChange={valueChanged} />
        )}
        ListFooterComponent={
          !configHandler.data ? (
            <Kitten.Spinner />
          ) : !configHandler.data.activities ||
            configHandler.data.activities.length < 1 ? (
            <>
              <Txt.Indicator>
                {tr("No questions have been setup by your admin.")}
              </Txt.Indicator>
            </>
          ) : null
        }
      />
      <Kitten.Divider_________ />
      <Buttoon
        status="primary"
        onPress={onSubmit}
        icon={{ name: "check_circle" }}
      >
        {tr("Submit Report")}
      </Buttoon>
    </View>
  );
}

function ActivityEntry(props: {
  entry: dActivityConfiguration;
  onValueChange: (val: any, entry: dActivityConfiguration) => void;
}) {
  const { entry, onValueChange } = props;

  const [entryValue, setEntryValue] = React.useState<string>("");
  const [checked, setChecked] = React.useState<boolean>(false);
  return (
    <Kitten.Card
      // disabled
      header={() => (
        <Kitten.ListItemv2
          title={entry?.title}
          titleNumberOfLines={2}
          description={entry?.description}
          descriptionNumberOfLines={3}
        />
      )}
    >
      {entry.entryType === "number" || entry.entryType === "text" ? (
        <Kitten.Input
          // label={entry.title}
          value={entryValue}
          onChangeText={(_val) => {
            setEntryValue(_val);
            if (entry.entryType === "number")
              onValueChange(Number.parseFloat(_val), entry)
            else
              onValueChange(_val, entry);
          }}
          keyboardType={
            entry.entryType === "number" ? "decimal-pad" : "default"
          }
        />
      ) : entry.entryType === "checkbox" ? (
        <Kitten.CheckBox
          checked={checked}
          onChange={(_c) => {
            setChecked(_c);
            onValueChange(_c, entry);
          }}
        ></Kitten.CheckBox>
      ) : null}
    </Kitten.Card>
  );
}
