import firebase from "firebase";
//#region [import]
import { useFocusEffect } from "@react-navigation/native";
import { IconPrimr, ICON_NAME } from "assets";
import Color from "color";
import {
  Buttoon,
  dButtoon,
  Kitten,
  ListHeader,
  Rush,
  Skeleton,
  sstyled,
  Toasty,
  Txt
} from "components/atoms";
import { useBreakpointIndex, useSx } from "dripsy";
import { useAppContext } from "engines";
import * as R from "ramda";
import React from "react";
import {
  Platform,
  Pressable,
  PressableProps,
  ScrollView,
  ScrollViewProps,
  useWindowDimensions,
  View,
  ViewStyle
} from "react-native";
import Animated, {
  Easing,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { TourGuideZone, useTourGuideController } from "rn-tourguide";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";

const MotiView = View;
//#endregion

/**
 * ### Two-column layout template for responsive screen
 * -  For smaller screen, 2nd column will turn into "HollowFooter", allowing user to tap and use
 *  ----
 * @example
 * <TwoColumns
 *  column1Content={(scrollHandler)=>(<><Txt>Column 1</Txt></>)}
 *  column2Content={[
      { headerTitle: "Section One", icon: "rocket", render: () => <MediaHomeActions /> },
      { headerTitle: "Section Two", icon: "placeholder", render: () => <Txt>Section 2 of column 2</Txt>}
    ]}
 * />
 *
 *  ----
 *  @version 21.04.23
 *  -  *Turn in to Template*
 *  @author  K
 *
 **/
export function TwoColumns({
  column1Content,
  column2Delay = 230,
  column2Content,
  containerStyle,
  bottomInset,
  disableAnimatedFooter,
}: P) {
  const { C, theme } = useAppContext();
  const Navigation = useNavigator();
  const safe = useSafeAreaInsets();
  const window = useWindowDimensions();
  const breakpoint = useBreakpointIndex();

  //#region [section2] tourguide
  const { stop: stopTourguide } = useTourGuideController();

  //#endregion

  //#region [section] columns setup
  const [col1Ready, setCol1Ready] = React.useState<boolean>(false);
  const [col2Ready, setCol2Ready] = React.useState<boolean>(false);

  useFocusEffect(() => {
    setTimeout(() => {
      setCol1Ready(true);
    }, Platform.select({ ios: 100, android: 400, web: 300 }));
    setTimeout(() => {
      setCol2Ready(true);
    }, column2Delay);
  });
  let reducedCol2Contents = column2Content?.filter(
    (content) => content && !R.isNil(content)
  );
  //#endregion

  //#region [section2] 2nd-column badges setup
  const [badgeValues, setBadgeValues] = React.useState<(string | number)[]>(
    Array.from({
      length: reducedCol2Contents?.length,
    }).map(() => 0) //* setBadgesValues with array of zeros, length of `reducedCol2Contents`
  );
  const updateBadgeValue = (value: string, index: number) => {
    setBadgeValues((values) => {
      let oldValues = [...values];
      oldValues[index] = value;
      return oldValues;
    });
  };
  //#endregion

  //#region [ANM] Scrollview animation config
  const lastContentOffset = useSharedValue(0);
  const isScrolling = useSharedValue(false);
  const translateY = useSharedValue(0);

  const actionBarStyle = disableAnimatedFooter
    ? {}
    : useAnimatedStyle(() => {
      return {
        transform: [
          {
            translateY: withTiming(translateY.value, {
              duration: 620,
              easing: Easing.inOut(Easing.ease),
            }),
          },
        ],
      };
    });

  const scrollHandler = disableAnimatedFooter
    ? () => null
    : useAnimatedScrollHandler({
      onScroll: (event) => {
        if (
          lastContentOffset.value > event.contentOffset.y &&
          isScrolling.value
        ) {
          translateY.value = 0;
          // console.log("scrolling ⬆️");
        } else if (
          lastContentOffset.value < event.contentOffset.y &&
          isScrolling.value
        ) {
          translateY.value = 100;
          // console.log("scrolling ⬇️");
        }
        lastContentOffset.value = event.contentOffset.y;
      },
      onBeginDrag: (e) => {
        isScrolling.value = true;
      },
      onEndDrag: (e) => {
        isScrolling.value = false;
      },
    });
  //#endregion
  const refRenderCount = React.useRef(0);
  return (
    <View style={{ flex: 1, backgroundColor: C.background }}>
      <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>
      {column1Content && col1Ready ? (
        column1Content(scrollHandler)
      ) : (
        <Rush.Bar indeterminate style={{ width: "100%" }} />
      )}
      {
        //#region [section] full-width screen
        breakpoint > 2 && (
          <A.Column2Wrapper width={window.width}>
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                paddingBottom: safe.bottom + spacing(5),
              }}
              nestedScrollEnabled
            // stickyHeaderIndices={[0]}
            >
              {reducedCol2Contents
                ? reducedCol2Contents?.map((content, index) => {
                  if (content.hiddenIf?.some((boo) => boo === true)) {
                    // return <Txt>{JSON.stringify(content.hiddenBy)}</Txt>;
                    return null;
                  }
                  return (
                    <ContentWrapper
                      index={index}
                      key={content?.headerTitle + index}
                    >
                      {content?.showTitle ? (
                        <ListHeader titleTx={content?.headerTitle} />
                      ) : null}

                      <Kitten.Layout
                        style={{
                          backgroundColor: content?.color || "transparent",
                          borderRadius: scale(4),
                          padding: spacing(2),
                        }}
                      >
                        {content?.render({
                          badgeValueCallback: (v: string) =>
                            updateBadgeValue(String(v), index),
                        })}
                      </Kitten.Layout>
                      <Badge type={1} value={badgeValues[index] || null} />
                    </ContentWrapper>
                  );
                })
                : null}
            </ScrollView>
          </A.Column2Wrapper>
        )
        //#endregion
      }
      {
        //#region [section2] small screen
        breakpoint <= 2 && reducedCol2Contents?.length > 0 ? (
          <Animated.View
            style={[
              actionBarStyle,
              {
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                borderTopWidth: 1,
                borderTopColor: C.line,
              },
            ]}
          >
            <Kitten.Layout level={"4"}>
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={[
                  bottomInset && {
                    paddingBottom: safe.bottom + spacing(4),
                    paddingTop: spacing(4),
                  },
                  {
                    // backgroundColor: C.background,
                    paddingHorizontal: spacing(3),
                    paddingTop: spacing(4),
                    paddingBottom: spacing(4),
                  },
                ]}
                contentContainerStyle={{ alignItems: "center" }}
              >
                {reducedCol2Contents
                  ? reducedCol2Contents?.map((content, index) => {
                    if (content.hiddenIf?.some((boo) => boo === true)) {
                      // return <Txt>{JSON.stringify(content.hiddenBy)}</Txt>;
                      return null;
                    }
                    if (content.headerTitle == tr("Start here")) {
                      return (
                        <View key={content.headerTitle}>
                          <TourGuideZone
                            zone={2}
                            // tooltipBottomOffset={25}
                            text={"👇 Welcome! Tap below to get started."}
                          >
                            <NewStartHereButton
                              onPress={() => {
                                if ("debug" === "nah")
                                  // fireDailyBriefingTest, fireFollowUpTest
                                  firebase
                                    .functions()
                                    .httpsCallable("fireDailyBriefingTest")({})
                                    .then(() => {
                                      alert("called fire");
                                    })
                                    .catch((err) => alert(err));

                                // const _index = parseInt(action.name);
                                stopTourguide();
                                if (content.render)
                                  Navigation.overlayPortal.open({
                                    headerTitle: content?.headerTitle || "   ",
                                    render: content.render(),
                                    loadingTime: 0,
                                  });
                                else
                                  Toasty.show("Nothing to render.", { type: "warning" })
                              }}
                            />
                          </TourGuideZone>
                        </View>
                      );
                    }

                    /** Action icon can render an icon from content.icon string
                     * or a component from content.icon function
                     */
                    let actionIcon: () => dButtoon["icon"] = () => {
                      switch (typeof content?.icon) {
                        case "string":
                          return {
                            name: content.icon,
                            color: content?.color,
                          };
                          break;
                        case "object":
                          return {
                            name: String(content.icon),
                            render: content.icon,
                            color: content?.color,
                          };
                        default:
                          return {
                            name: String(content.icon),
                            color: content?.color,
                          };
                          break;
                      }
                    };
                    return (
                      <View key={content.headerTitle}>
                        {actionIcon()?.render ? (
                          <Pressable
                            onPress={() => {
                              if (content.onPress) {
                                content.onPress();
                                return;
                              }
                              // const _index = parseInt(action.name);
                              Navigation.overlayPortal.open({
                                headerTitle: content.headerTitle || "   ",
                                render: content?.render({
                                  badgeValueCallback: (v: string) =>
                                    updateBadgeValue(String(v), index),
                                }),
                                loadingTime: 0,
                              });
                            }}
                          >
                            {actionIcon()?.render}
                          </Pressable>
                        ) : (
                          <IconPrimr
                            {...actionIcon()}
                            size={scale(20)}
                            color={actionIcon()?.color || C.text}
                            onPress={() => {
                              if (content.onPress) {
                                content.onPress();
                                return;
                              }
                              // const _index = parseInt(action.name);
                              // Navigation.layover({
                              //   _key: content?.key,
                              // });
                              Navigation.overlayPortal.open({
                                headerTitle: content.headerTitle || "   ",
                                render: content?.render({
                                  badgeValueCallback: (v: string) =>
                                    updateBadgeValue(String(v), index),
                                }),
                                loadingTime: 0,
                              });
                            }}
                            containerStyle={{
                              marginHorizontal: spacing(3),
                              backgroundColor: actionIcon()?.color
                                ? Color(actionIcon()?.color)
                                  .alpha(0.2)
                                  .toString()
                                : "transparent",
                            }}
                            preset={"circular"}
                          />
                        )}
                      </View>
                    );
                    //* Old version
                    return (
                      <View key={content.headerTitle}>
                        <A.PressableCol2Action
                          status={
                            content.headerTitle === tr("Done")
                              ? "success"
                              : content.headerTitle ===
                                tr("Leave the Base Shop") ||
                                content.headerTitle === tr("Sign out")
                                ? "danger"
                                : "control"
                          }
                          icon={actionIcon()}
                          onPress={() => {
                            // const _index = parseInt(action.name);
                            Navigation.overlayPortal.open({
                              headerTitle: content.headerTitle || "   ",
                              render: content?.render({
                                badgeValueCallback: (v: string) =>
                                  updateBadgeValue(String(v), index),
                              }),
                            });
                          }}
                        // style={{
                        //   borderColor:
                        //     theme === THEME.DARK ? "transparent" : "black",
                        // }}
                        >
                          {/* {content.headerTitle} */}
                        </A.PressableCol2Action>
                        <Badge type={2} value={badgeValues[index] || null} />
                      </View>
                    );
                  })
                  : null}
              </ScrollView>
            </Kitten.Layout>
          </Animated.View>
        ) : null
        //#endregion
      }
    </View>
  );
}

// // /**
// //  * Gets only the valid children of a component,
// //  * and ignores any nullish or falsy child.
// //  *
// //  * @deprecated
// //  */
// // export const getValidChildren = (children: React.ReactNode) => {
// //   return React.Children.toArray(children).filter((child) =>
// //     React.isValidElement(child)
// //   ) as React.ReactElement[];
// // };

export const ContentWrapper = ({ children, index = 1 }) => {
  return (
    <MotiView
      from={{
        opacity: 0,
        // scale: 0.8,
      }}
      animate={{
        opacity: 1,
        // scale: 1,
      }}
      transition={{
        type: "timing",
        duration: 400,
        easing: Easing.ease,
        delay: 30 + index * 300,
      }}
      style={{ marginBottom: spacing(3) }}
    >
      {children}
    </MotiView>
  );
};

/**
 * ### The red badge to show on top right of 2nd-column component
 * -  To show a number or short text
 * @param param0
 * @returns
 */
const Badge = ({
  value,
  type = 1,
}: {
  value?: string | number;
  type: 1 | 2;
}) => {
  const { C } = useAppContext();
  const sx = useSx();
  return (
    value && (
      <A.ChipIndicator
        style={[
          { position: "absolute" },
          type == 1 && { top: -3, right: 0 },
          type == 2 && { top: -1, left: -1 },
        ]}
      >
        {value}
      </A.ChipIndicator>
    )
  );
  return (
    value && (
      <View
        style={[
          sx({
            paddingVertical: spacing(2),
            paddingHorizontal: spacing(3),
            position: "absolute",
            backgroundColor: "errorRed",
            justifyContent: "center",
            borderRadius: scale(3),
            alignItems: "center",
            borderBottomWidth: scale(4),
            borderBottomColor: "color-danger-700",
          }),
          type == 1 && { top: 10, right: 10 },
          type == 2 && { top: -1, right: -1 },
        ]}
      >
        <Txt style={{ fontWeight: "bold" }}>{value}</Txt>
      </View>
    )
  );
};

const NewStartHereButton = (p: PressableProps) => {
  const { C } = useAppContext();
  return (
    <NSW.PressableContainer {...p}>
      <Skeleton
        isLoading
        duration={2400}
        // easing={Easing.circle}
        layout={[
          {
            key: "medal-wrapper",
            width: scale(145),
            height: scale(32),
            // justifyContent: "center",
          },
        ]}
        highlightColor={C.primary}
        boneColor={C.infoBlue}
      />
      <NSW.ViewLabel>
        <Txt.S1>{tr("Start Training")}</Txt.S1>
      </NSW.ViewLabel>
    </NSW.PressableContainer>
  );
};

const A = {
  Column2Wrapper: sstyled(View)(({ width }) => ({
    position: "absolute",
    paddingTop: spacing(3),
    right: width / 2 - 614 * 0.8, // -614 + 293 * 0.3,
    // right: width / 2 - 614 * 1.11, // for 3-column concept
    top: 0,
    width: [0, 0, 0, 293, 293],
    maxHeight: "100%", //height - topBarHeight,
    // backgroundColor: "powderblue",
  })),
  PressableCol2Action: sstyled(Buttoon)({
    borderRadius: scale(21),
    margin: spacing(3),
    borderWidth: 1,
    borderColor: "grey600",
    elevation: 12,
  }),
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "infoBlue",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};

const NSW = {
  PressableContainer: sstyled(Pressable)({
    borderRadius: scale(21),
    margin: spacing(3),
    overflow: "hidden",
    backgroundColor: "transparent",
  }),
  ViewLabel: sstyled(View)((p) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  })),
};

interface P {
  column1Content: (
    scrollHandler?: ScrollViewProps["onScroll"]
  ) => React.ReactElement;
  // column2Content?: (props?) => React.ReactElement;
  /** Delayed time to show column2 when first render */
  column2Delay?: number;
  column2Content?: {
    key?: string;
    headerTitle?: string;
    showTitle: boolean;
    /**
     * Section Component that will:
     * -  Show up on the 2nd column on the larger screen
     * -  Show up as user clicks the PressableCol2Action on smaller screen,
     */
    render?: ({
      badgeValueCallback,
    }: {
      badgeValueCallback?: (
        value: string,
        { color }: { color?: string }
      ) => void;
    }) => React.ReactElement;
    /**
     * Do something when pressed (meant to be used instead of using render)
     */
    onPress?: () => void;
    icon: ICON_NAME | React.ReactElement;
    color?: string;
    /** If any boolean in this array is true, HIDE the section */
    hiddenIf?: boolean[];
  }[];
  containerStyle?: ViewStyle;
  bottomInset?: boolean;
  /**
   * If true, footer will not animate with scroll changes
   */
  disableAnimatedFooter?: boolean;
}
