//#region [import]
import * as eva from "@eva-design/eva";
import { PortalProvider } from "@gorhom/portal";
import { FuegoProvider } from "@nandorojo/swr-firestore";
import { ApplicationProvider } from "@ui-kitten/components";
import Color from "color";
import { Toasty } from "components/atoms";
import { DripsyProvider, makeTheme } from "dripsy";
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import React, { useContext, useState } from "react";
import { StatusBar } from "react-native";
import { MenuProvider } from "react-native-popup-menu";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { TourGuideProvider } from "rn-tourguide";
import {
  colors,
  dColors,
  evaConfig,
  scale,
  THEME,
  themeDark,
  themeLight,
  tr
} from "utils";
import { ass, ASS_KEY } from "utils/storage";
import { APP_CONFIG } from "../../../apptakeoff.config";
import { fuego } from "../../../fuego";
import { app_team_id } from "../../../package.json";
import { AudioPlayerProvider } from "./audio-provider";
import { BMLMProvider } from "./bml-mobile-provider";
import { CollaborationProvider } from "./collaboration-provider";
//#endregion

/** 
 * ### App Provider,
 * 
 * providing:
 * -  THEME: LIGHT, DARK
 * -  Kitten's provider
 * -  Safe Area Provider
 * -  KeyboardAvoidingView (test on ios✅, web✅)
 * ---
 * @version 2.1.15
 * - *Clean up AppProvider*
 * @author nguyenkhooi
 * ---
 * @example
 *  In `app.tsx`
 *    import {AppProvider} from `engines`
 *    ...
 *    const [_theme,setTheme] = React.useState<eTheme>(`themeLight`)
 *    ...
 *    return (
        <RootStoreProvider value={rootStore}>
          <AppProvider theme={_theme} setTheme={setTheme}> <--- here
            <...>
          </AppProvider> <--- and here
        </RootStoreProvider>
      )
    `To setup theme switcher`
      import {useAppContext} from "engines"
      import { AudioPlayerProvider } from './audio-provider';
...
      const ThemeSwitcherButton = (props) => {
        const {C, dark, setTheme} = useAppContext()
        return (
          <Button onPress={()=> setTheme(dark? `themeLight` : `themeDark`)} >
            {dark? `Switch to Light Theme` : `Switch to Dark Theme`}
          </Button>
        )
      }
 */
export function AppProvider(props: dAppProvider) {
  const { children } = props;

  //#region [section] THEME
  const [_theme, _setTheme] = useState<THEME>(APP_CONFIG?.styling?.default_mode || THEME.DARK);
  const [_colors, setColors] = React.useState(
    _theme == THEME.DARK ? themeDark : themeLight
  );
  React.useEffect(
    function setupTheme() {
      switch (_theme) {
        case THEME.LIGHT:
          setColors(themeLight);
          StatusBar.setBarStyle("dark-content");
          // ass.saveString(ASS_KEY.THEME, _theme);
          break;
        case THEME.DARK:
          setColors(themeDark);
          StatusBar.setBarStyle("light-content");
          // ass.saveString(ASS_KEY.THEME, _theme);
          break;
        default:
          setColors(themeLight);
          StatusBar.setBarStyle("dark-content");
          // ass.saveString(ASS_KEY.THEME, _theme);
          break;
      }
      console.log("💋 Current theme: ", _theme.toString());
    },
    [_theme]
  );

  function setTheme(targetedTheme: THEME) {
    let __toast = Toasty.show("Toggling light/dark mode", {
      type: "loading",
    });
    !!__toast &&
      setTimeout(() => {
        _setTheme(targetedTheme);
        Toasty.update(
          __toast,
          !(_theme == THEME.DARK)
            ? tr("Welcome to Dark Mode")
            : tr("Welcome to Light Mode"),
          {
            type: "success",
          }
        );
      }, 1200);
  }
  //#endregion

  //#region [FRBS]
  const [frbsUser, setFrbsUser] = React.useState<firebase.User>(null);
  React.useEffect(function authListener() {
    function authStateHandler(user: firebase.User) {
      user == null && setFrbsUser(null);
      setFrbsUser(user);
    }
    return fuego.auth().onAuthStateChanged(authStateHandler);
  }, []);

  const [teamId, _setTeamId] = React.useState<any>(APP_CONFIG?.app_id || app_team_id || ""); //* get app_team_id as initial value, or "app-takeoff"

  /**
   * Used in getting the hierachy name from the storage, and this is neccessary because of when the user refreshes the screen
   */
  ass.loadStringCustom(ASS_KEY.TEAMID).then((res) => {
    // app_team_id && !IS_WEB ? _setTeamId(app_team_id) : _setTeamId(res);
    res && _setTeamId(res);
    return res;
  });

  function setTeamId(targetedTeamId: string) {
    if (targetedTeamId) {
      // let __toast = Toasty.show(tr("Setting up team..."), {
      //   type: "loading",
      // });
      // !!__toast &&
      setTimeout(() => {
        ass
          .saveString(ASS_KEY.TEAMID, targetedTeamId)
          .then(() => {
            if (APP_CONFIG?.app_id)
              _setTeamId(APP_CONFIG?.app_id)
            else
              _setTeamId(targetedTeamId)

          });
        // Toasty.update(__toast, tr("Welcome to") + " " + targetedTeamId, {
        //   type: "success",
        // });
      }, 1200);
    } else {
      ass.saveString(ASS_KEY.TEAMID, "").then(() => _setTeamId(""));
    }
  }

  //#endregion

  //#region [section] INITIALIZE
  // React.useEffect(function initialize() {
  //   return async () => {
  //     const theme = (await ass.loadString(ASS_KEY.THEME)) as THEME;
  //     setTheme(theme);
  //     shouldReady(true);
  //   };
  // }, []);
  //#endregion

  return (
    <AppContext.Provider
      value={{
        C: _colors,
        theme: _theme,
        setTheme: setTheme,
        teamId,
        setTeamId,
        frbsUser,
        setFrbsUser,
      }}
    >
      <FuegoProvider fuego={fuego}>
        <DripsyProvider
          theme={
            _theme === THEME.LIGHT
              ? dripsyThemeLight
              : { ...dripsyThemeLight, colors: themeDark }
          }
        >
          <ApplicationProvider
            {...eva}
            theme={_theme == THEME.LIGHT ? themeLight : themeDark}
            customMapping={evaConfig}
          >
            <SafeAreaProvider>
              <MenuProvider>
                <TourGuideProvider
                  {...{
                    backdropColor: Color("black").alpha(0.9).toString(),
                    borderRadius: scale(4),
                    dismissOnPress: true,
                    labels: {
                      finish: "Skip",
                    },
                  }}
                >
                  {/** TODO: #23 Check the effectiveness of this KeyboardAvoidingView approach */}
                  <PortalProvider>
                    <CollaborationProvider>
                      <BMLMProvider>
                        <AudioPlayerProvider>{children}</AudioPlayerProvider>
                      </BMLMProvider>
                    </CollaborationProvider>
                    {/* {children} */}
                  </PortalProvider>
                </TourGuideProvider>
              </MenuProvider>
            </SafeAreaProvider>
          </ApplicationProvider>
        </DripsyProvider>
      </FuegoProvider>
    </AppContext.Provider>
  );
}

export const AppContext = React.createContext<dAppContext>({
  C: colors,
  setTheme: () => { },
  teamId: null,
  setTeamId: () => { },
  frbsUser: null,
  setFrbsUser: () => { },
});
/**
 * App context hook
 *
 * ---
 * @example
 * ```
 * const { C, dark, setTheme } = useAppContext()
 * ```
 *
 * @version 0.11.29
 * - *Add roles: string[_] to userDoc*
 * @author nguyenkhooi
 */
export const useAppContext = () => useContext(AppContext);

interface dAppProvider {
  children: React.ReactNode;
}
export interface dAppContext {
  C: dColors;
  theme?: THEME;
  setTheme(name: THEME): void;
  teamId: string;
  setTeamId(teamId: string): void;
  frbsUser: firebase.User & { normalUid?: string };
  setFrbsUser: (user: firebase.User & { normalUid?: string }) => void;
}

const dripsyThemeLight = makeTheme({
  colors: themeLight,
  fonts: {
    // root: webFont("Inter_400Regular"),
  },
  sizes: [0, 10, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330],
  space: {
    $0: 0,
    $1: 4,
    $2: 8,
    $3: 16,
    $4: 32,
    $5: 64,
    $6: 128,
    $7: 256,
    $8: 512,
  },

  // space: [0, 2, 4, 8, 16, 20, 32, 64, 128, 256], //* length = 10
  text: {
    thick: {
      fontFamily: "root",
      fontWeight: "black", // 'Circular-StdBlack'
    },
  },
  types: {
    reactNativeTypesOnly: true,
    onlyAllowThemeValues: {
      space: "always",
    },
  },
});

type MyTheme = typeof dripsyThemeLight;
declare module "dripsy" {
  interface DripsyCustomTheme extends MyTheme { }
}
