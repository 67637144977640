/**
 * ### 
 *  - This functions allow you to check if a string has a valid email or now
 *  ----
 *  @example 
 *  validateEmail(paulnanle611@gmail.com)
 *  ----
 *  @version 21.07.01
 *  -  *Brief changelog*
 *  @author  Nl
 *  
 **/
export function validateEmail(email:string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
