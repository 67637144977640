//#region [import]
import { IconPrimr } from "assets";
import { sstyled, Txt } from "components";
import { ScrollView } from "dripsy";
import { useAppContext } from "engines";
import React from "react";
import { View, ViewProps } from "react-native";
import * as Animatable from "react-native-animatable";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { dOverlayParams, useNavigator } from "screens/_navigation";
import { dColors, IS_WEB, scale, spacing, tr } from "utils";
//#endregion

/**
 * ### An overlay screen, aka pseudo modal
 * -  Since fullscreen Modal cannot be used on RNW, we'll create a transparent background screen to mimic the modal
 * -  Pass the component you want to render and it'll show up at the center of the screen
 *
 * ---
 * @example
 *
 * ---
 * @version 1.5.12
 * -  Update UI
 * @author nguyenkhooi
 */
export function OverlayScreen({ route }: P) {
  const { teamId, C, dark } = useAppContext();
  const safeInsets = useSafeAreaInsets();
  const Navigation = useNavigator();
  React.useLayoutEffect(function setHierarchyName() {
    Navigation.navigate<dOverlayParams>(
      "Overlay",
      //@ts-expect-error since we don't pass other params
      {
        hierarchyName1: teamId,
      }
    );
  }, []);
  try {
    return (
      <A.Ctnr
        style={{
          paddingTop: safeInsets.top + "5%", //todo @nguyenkhooi Check ios notch})
        }}
      >
        <A.Margin />
        <A.Content
          animation="slideInUp"
          duration={400}
          contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap" }}
          stickyHeaderIndices={[0]}
          // scrollEnabled={false}
        >
          <A.CtnrXButton>
            <IconPrimr
              name={"x"}
              size={scale(12)}
              color={C["color-basic-1100"]}
              preset="circular"
              onPress={() => {
                Navigation.goBack();
              }}
              containerStyle={{
                ...A.ButtonX(C),
              }}
            />
          </A.CtnrXButton>
          <View style={{ width: "100%" }}>
            {route.params?.render || (
              <Txt.Indicator
                onPress={() => {
                  // 👀 yo nanle can you handle for mobile?
                  if (IS_WEB) window.location.href = window.location.origin;
                }}
              >
                {tr("Go home")}
              </Txt.Indicator>
            )}
          </View>
        </A.Content>
        <A.Margin />
      </A.Ctnr>
    );
  } catch (error) {
    throw new Error(`::T3ZlcmxheVNjcmVlbg==:: ${error}`);
  }
}
const AniScrollView = Animatable.createAnimatableComponent(ScrollView);
const A = {
  Ctnr: sstyled(View)({
    flex: 1,
    // width: "100%",
    backgroundColor: "#00000090",
    flexDirection: "row",
    // flexBasis: 300,
  }),
  Margin: sstyled(View)({
    flex: 0,
    height: 50,
    width: 1,
    // backgroundColor: "powderblue",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  }),
  Content: sstyled(AniScrollView)({
    flex: 1,
    backgroundColor: "background",
    flexBasis: 576,
    maxWidth: 768,
    // flexShrink: 0,
    // minWidth: 300,
    paddingVertical: spacing(3),
    paddingHorizontal: spacing(4),
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  }),
  CtnrXButton: sstyled(View)({
    width: "100%",
    alignSelf: "flex-end",
    padding: 2,
    // position: "absolute",
    // right: 1,
    // top: 1,
    // zIndex: 9999,
  }),
  ButtonX: (C: dColors) =>
    ({
      backgroundColor: C["color-danger-500"],
      shadowColor: C["color-danger-600"],
      alignSelf: "flex-end",
      shadowOffset: {
        width: 0,
        height: 10,
      },
      shadowOpacity: 0.63,
      shadowRadius: 13.16,

      elevation: 20,
    } as ViewProps),
};

interface P {
  route: { params: dOverlayParams };
}
