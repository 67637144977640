import { StackActions } from "@react-navigation/native";
import { IconPrimr } from "assets";
import { Buttoon, Kitten, Poppy, Rush, Toasty, Txt } from "components/";
import {
  FRBS_ROLE,
  MediaPageItemSchema,
  URL_PREFIX,
  useAppContext
} from "engines";
import { useConfigDocument } from "engines/firebase/handler/config-handler";
import {
  useCalculateProgressForPage,
  useProgressForPreviousLevel
} from "engines/firebase/handler/levels-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import firebase from "firebase";
import React from "react";
import { useNavigator } from "screens";
import { scale, tr } from "utils";
import { useCollabContext } from "../../../../engines/providers/collaboration-provider";

interface dProps {
  /** The media item of this button */
  parentPageId: string;
  mediaItem: MediaPageItemSchema;
  alwaysUnlock?: boolean;
  /** keep it locked until they select their trainer, but make sure to set this to false when they do */
  lockUntilTrainerSelect?: boolean;
  //   allStaticPages?: MediaPageSchema[];
  onPress?: (item: MediaPageItemSchema, levelProgress?: number) => void;
  rolesThatCanEdit?: string[];
  /** always know what the progress of this page level is, or if it's complete (1) */
  onLevelProgress?: (progress: number) => void;
}

/**
 * ### A button that does something like open a webpage or another CustomPage
 *  ----
 *  @example
 *  <LevelButtonItem mediaItem={item} />
 *  ----
 *  @version
 *  @author  jm_francis
 *
 **/
export function LevelButtonItem(props: dProps) {
  const {
    parentPageId,
    mediaItem,
    alwaysUnlock,
    lockUntilTrainerSelect,
    onPress,
    rolesThatCanEdit,
    onLevelProgress,
  } = props;

  const { C, teamId } = useAppContext();

  /**
   * The level page this button goes to
   */
  // const levelPageId = mediaItem.url?.split("page:").pop();
  const levelPageId = mediaItem?.url
    ?.replace(URL_PREFIX.PAGE, "")
    ?.replace(URL_PREFIX.LEVEL, "");

  // const handleLevelPage = usePageDocument(levelPageId);
  const { data: variablesD } = useConfigDocument(`variables-${teamId}`);
  const { isCollabModeOn } = useCollabContext();
  const Navigation = useNavigator();

  //   const [levelProgress, setLevelProgress] = React.useState<number>(0);
  const [isLevelLocked, setIsLevelLocked] = React.useState<boolean>(false);

  const { data: userD, ...userProfile } = useUserProfile();
  const { frbsUser } = useAppContext();
  const levelProgress = useCalculateProgressForPage(levelPageId, userD);
  // console.log(
  //   "[levels] parentPage: " + parentPageId + " levelPageId: " + levelPageId
  // );
  const previousLevelProgress = useProgressForPreviousLevel(
    parentPageId,
    levelPageId,
    frbsUser?.uid
  );

  // const bypassLock = userProfile._isRolesContain([
  //   FRBS_ROLE.ADMIN,
  //   `BS:${levelPageId}`,
  // ]);

  React.useEffect(
    function handleLevelLock() {
      const allowLevelSkipping = variablesD?.levels?.allowLevelSkipping;
      if (lockUntilTrainerSelect) {
        setIsLevelLocked(true)
      }
      else if (allowLevelSkipping || userD?.allLevelsCompleted) {
        //* Unlock this level if ALL levels are allowed to skip
        setIsLevelLocked(false);
      } else {
        if (previousLevelProgress == 1) {
          //* Unlock this level if previous level is completed
          setIsLevelLocked(false);
        } else {
          setIsLevelLocked(true);
          // const rolesThatCanBypaass = [
          //   FRBS_ROLE.ADMIN,
          //   FRBS_ROLE.DEVELOPER,
          //   `BS:${levelPageId}`,
          // ];
          // userProfile._isRolesContain(
          //   rolesThatCanBypaass.concat(rolesThatCanEdit)
          // )
          //   ? //* Unlock this level if user is ADMIN, DEV, or BS owner of this page
          //     setIsLevelLocked(false)
          //   : //* Else, lock it
          //     setIsLevelLocked(true);
        }
      }
    },
    [variablesD, userD]
  );

  /**
   * If the user starts on a new set of levels in the app it will remember that in their account so that their trainers can see the progress on those levels in addition to the New Start Here ones
   */
  React.useEffect(
    function addLevelSectionIfNeeded() {
      onLevelProgress && onLevelProgress(levelProgress);

      if (
        !userD?.parentLevelPages?.includes(parentPageId) &&
        levelProgress > 0
      ) {
        userProfile.update({
          parentLevelPages:
            firebase.firestore.FieldValue.arrayUnion(parentPageId),
        });
        console.log(`Now remembering parent level page: ${parentPageId}`);
        // alert(`Now remembering parent level page: ${parentPageId}`);
      }
    },
    [levelProgress]
  );

  const refLockDeniedAttempts = React.useRef<number>(0);
  function onItemPress() {
    onPress && onPress(mediaItem, levelProgress);

    if (lockUntilTrainerSelect) {
      Toasty.show("You must select your trainer before proceeding.", { type: "warning" })
      if (!userProfile._isRolesContain([FRBS_ROLE.ADMIN, FRBS_ROLE.DEVELOPER]))
        return;
    }
    else if (
      isLevelLocked &&
      !userProfile._isRolesContain([FRBS_ROLE.ADMIN, FRBS_ROLE.DEVELOPER]) &&
      !userD.allLevelsCompleted &&
      !alwaysUnlock
    ) {
      if (refLockDeniedAttempts.current++ > 0) {
        Poppy("Bypass lock?", "If something is wrong, you can bypass the lock and proceed to the next level, but this will notify your field trainers.", { text: "Cancel", onPress: () => { } }, {
          text: "Bypass", onPress: () => {
            Navigation.dispatch(
              StackActions.push("Page Contents", {
                cpid: levelPageId,
                isLevel: true,
              })
            );
          }
        })
      } else {
        Toasty.show(
          "You must complete the previous levels before you can proceed.",
          { type: "warning" }
        );
      }
      return;
    }
    // TODO navigate to the level page here
    Navigation.dispatch(
      StackActions.push("Page Contents", {
        cpid: levelPageId,
        collabMode: isCollabModeOn,
        isLevel: true,
      })
    );
  }

  const ViewAccesoryRight = () => {
    if (isLevelLocked) {
      return (
        <Buttoon
          status="control"
          size="small"
          // disabled={!bypassLock}
          onPress={() => {
            // if (bypassLock) {
            onItemPress();
            // }
          }}
          icon={{ name: "lock" }}
        />
      );
    } else {
      if (!levelProgress || levelProgress == 0) {
        return (
          <Buttoon status="control" size="small" onPress={onItemPress}>
            {tr("Start now!")}
          </Buttoon>
        );
      }
      if (levelProgress < 1) {
        return (
          <Buttoon status="control" size="small" onPress={onItemPress}>
            {`${Math.round(levelProgress * 100)}%`}
          </Buttoon>
        );
      }
      if (levelProgress == 1) {
        return (
          <Buttoon onPress={onItemPress} icon={{ name: "check" }}>
            {tr("Completed!")}
          </Buttoon>
        );
      }
    }
  };

  let barColor = () => {
    if (isLevelLocked) return C.dim;
    return C.primaryDarker;
  };

  return (
    <Rush.Bar
      style={{ width: "100%", height: scale(64), borderRadius: scale(4) }}
      progress={levelProgress}
      color={barColor()}
    >
      <Kitten.ListItemv2
        // disabled={isLevelLocked}
        onPress={onItemPress}
        style={{ flex: 1, backgroundColor: "transparent", paddingVertical: 0 }}
        title={() => (
          <Kitten.ViewH style={{ height: "100%" }}>
            <Txt.H6 numberOfLines={0} style={{ width: "88%" }}>
              {mediaItem?.title}
            </Txt.H6>
            <IconPrimr
              name={"chevron_right"}
              color={C.text}
              preset={"circular"}
            />
          </Kitten.ViewH>
        )}
        accessoryRight={() => <ViewAccesoryRight />}
      />
    </Rush.Bar>
  );
}

export const isLinkMagnetURL = (url: string): boolean => {
  return (
    url &&
    (url.includes("followthislink.com") ||
      url.includes("freedomforfamilies.com"))
  );
};
