import { Input } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Kitten, Poppy, Txt } from "components";
import { TwoColumns } from "components/templates";
import { useAppContext } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import React from "react";
import { Dimensions, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { ContactCard } from "./ContactCard";

interface dQualifScreen {
  qualificationScreenIndex: number;
  thisIndex: number;
}

const { height: screenHeight, width: screenWidth } = Dimensions.get("window");
/**
 * ### This screen will display all the contacts that will be qualified as MACHO by user
 *  -
 *  ----
 *  @example
 *  <QualificationScreen data={} />
 *  ----
 *  @version 22.03.23
 *  -  *Creating Qualification Screen on 3/23/22*
 *  @author  Saurabh_M
 *
 **/
export function QualificationScreen(props: dQualifScreen) {
  const { thisIndex, qualificationScreenIndex } = props;
  const { C } = useAppContext();
  // const user = useUserProfile();
  const { bmlContacts, setBmlContacts, qualificationData } = useBMLMContext();
  // const Navigator = useNavigator();
  const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);
  const [query, setQuery] = React.useState("");
  const flatListRef = React.useRef<FlatList<ContactSchemaa>>(null);
  const searchBar = React.useRef<Input>(null);

  const refTempContacts = React.useRef<ContactSchemaa[]>([]);

  //NOTE Initialization of contacts for Qualification process.
  React.useEffect(() => {
    // store locally for modification before saving
    refTempContacts.current = bmlContacts;
    // display the contacts
    setContacts(bmlContacts);
  }, [bmlContacts]);

  React.useEffect(
    function shouldSaveBmlContacts() {
      if (thisIndex < qualificationScreenIndex) {
        // Toasty.show("Saving qualifications...", {
        //   type: "loading",
        //   duration: 750,
        // });
        setBmlContacts(refTempContacts.current);
      }
    },
    [qualificationScreenIndex]
  );

  //#region [section] Handle Qualification Logic
  //NOTE : Function that will tell Contact Card what to do when they press "Done"
  //       on contact card under Qualification Section.
  const handleQualification = (cid: string, selection: string | string[]) => {
    const contactIndex = bmlContacts.findIndex((c) => c._cid === cid);
    // console.log("Contact Index is ", contactIndex);
    if (contactIndex !== -1) {
      if (!bmlContacts[contactIndex].qualifications) {
        bmlContacts[contactIndex].qualifications = [];
      }
      if (!Array.isArray(selection)) {
        if (!bmlContacts[contactIndex].qualifications.includes(selection)) {
          bmlContacts[contactIndex].qualifications.push(selection);
        } else {
          const newQualifications = bmlContacts[
            contactIndex
          ]?.qualifications?.filter((q) => q !== selection);
          bmlContacts[contactIndex].qualifications = [...newQualifications];
        }
      } else {
        bmlContacts[contactIndex].qualifications = [...selection];
      }
      refTempContacts.current = bmlContacts;
      // setBmlContacts([...bmlContacts]);
    }
  };
  //#endregion

  //#region [section2] Scrolling effect when a contact is qualified
  //NOTE Handle the scrolling effect when user finishes qualification of a contact
  // const handleScroll = (currentIndex: number) => {
  //   // console.log("current Index is ", currentIndex);

  //   let scrollToIndex = Math.min(currentIndex + 1, bmlContacts?.length - 1);
  //   scrollToIndex = scrollToIndex ? scrollToIndex : 0;
  //   flatListRef?.current?.scrollToIndex({
  //     index: scrollToIndex,
  //     animated: true,
  //   });
  // };
  //#endregion

  //#region [ANCHOR] Handling the search bar on qualification screen
  React.useEffect(() => {
    //NOTE Focus on search bar when entering qualification screen
    // searchBar.current?.focus();
    if (query) {
      setContacts(
        bmlContacts.filter((c) =>
          (c.displayName || c.name)
            ?.toLowerCase()
            ?.trim()
            ?.includes(query.toLowerCase().trim())
        )
      );
    } else {
      setContacts(
        bmlContacts.sort((c1, c2) =>
          (c1?.displayName || c1?.name)?.localeCompare(c2?.displayName || c2?.name)
        )
      );
    }
  }, [query]);

  const handleSearch = (newQuery: string) => {
    //NOTE Search contact only if user types 3 or more characters.
    if (newQuery.length >= 3) {
      setQuery(newQuery);
    } else {
      setQuery("");
    }
  };
  //#endregion

  try {
    return (
      <TwoColumns
        bottomInset
        column1Content={(scrollHandler) => (
          <>
            <View style={{ flexDirection: "row" }}>
              <Kitten.Input
                ref={searchBar}
                clearButtonMode="always"
                autoCapitalize="none"
                autoCorrect={false}
                onChangeText={(value) => handleSearch(value)}
                status="info"
                placeholder="Search contact..."
                style={{
                  margin: 5,
                  borderRadius: 5,
                  borderColor: C.text,
                  backgroundColor: C.background,
                  flex: 1,
                }}
                textStyle={{ color: C.text }}
              />
              <IconPrimr
                preset={"safe"}
                name={"help_box"}
                size={30}
                color={"dodgerblue"}
                onPress={() =>
                  Poppy(
                    "How to Qualify ?",
                    "Qualify your contacts by selecting the option(s) under their name.\nPress Done to move to next contact.\nIf a contact matches all listed options, press 'Select All'"
                  )
                }
              />
            </View>
            <FlatList
              ref={flatListRef}
              scrollEventThrottle={16}
              // onScroll={scrollHandler}
              // ListFooterComponent={() =>
              //   contacts.length > 4 && (
              //     <Buttoon
              //       compact
              //       onPress={() => {
              //         flatListRef.current?.scrollToIndex({
              //           index: 0,
              //           animated: true,
              //         });
              //       }}
              //       appearance="filled"
              //       status="warning"
              //     >
              //       {"Scroll to top"}
              //     </Buttoon>
              //   )
              // }
              ItemSeparatorComponent={() => <Kitten.Divider_________ />}
              style={[
                {
                  width: screenWidth,
                  padding: 3,
                  flex: 1,
                  height: screenHeight,
                },
              ]}
              ListEmptyComponent={() => (
                <Txt.Helper
                  marginV
                >{`No Contact found for "${query}"`}</Txt.Helper>
              )}
              // contentContainerStyle={{ paddingBottom: 100 }}
              data={contacts ? contacts : bmlContacts || []}
              keyExtractor={(item) => item?._cid?.toString()}
              renderItem={({
                item,
                index,
              }: {
                item: ContactSchemaa;
                index: number;
              }) => (
                <ContactCard
                  key={item?._cid}
                  contact={item}
                  handleQualification={handleQualification}
                  // handleDone={() => handleScroll(index)}
                  // handleDeletion={handleContactDeletion}
                />
              )}
            />
          </>
        )}
        column2Content={
          [
            //TODO For future purpose
          ]
        }
      />
    );
  } catch (error) {
    throw Error(`::VmlkZW9TY3JlZW4=::${error}`);
  }
}
