// import RNFetchBlob from "rn-fetch-blob";
import { IS_WEB } from "utils";
import type { UploadOptions } from "./types";

export type CloudinaryCompletionResponse = {
  done: boolean;
  url: string;
  secure_url: string;
  folder: string;
  access_mode: string;
  asset_id: string;
  public_id: string;
  width: string;
  height: string;
  created_at: string;
  tags: string[];
};

/**
 * ## chunkedUpload
 * 
 * ### Example usage:
 * uploading to cloudinary
 * ```ts
  picker = document.getElementById("file_picker");
  picker.addEventListener("onchange", async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    await chunkedUpload(file, {
      url: CLOUDINARY_UPLOAD_URL,
      appendToFormData: {
        upload_preset: "upload_preset",
        cloud_name: "cloud_name",
        tags: ["tag1", "tag2"],
        public_id: `public_id`,
      },
      headers: {},
    });
  });
 * ```
 * @param file 
 * @param options 
 * @param chunkSize 
 */
export async function chunkedUpload(
  file: File,
  options: UploadOptions,
  onPercentUpdate?: (percent: number) => void,
  onComplete?: (data: CloudinaryCompletionResponse) => void,
  chunkSize: number = 1024 * 1024 * 5
) {
  const XUniqueUploadId = `${Date.now()}`;
  const { url, appendToFormData = {}, headers } = options;

  function noop() {}

  function slice(file: File, start: number, end: number) {
    const slice = file.slice || noop;
    return slice.bind(file)(start, end);
  }

  async function send(chunk: Blob, start: number, end: number, size: number) {
    if (IS_WEB) return await sendWEB(chunk, start, end, size);
    else return await sendWEB(chunk, start, end, size);
  }
  async function sendWEB(
    chunk: Blob,
    start: number,
    end: number,
    size: number
  ) {
    // const strChunk = await new Response(chunk).text();
    // size = new Blob([strChunk]).size;
    console.log({ start, end, size });
    const formData = new FormData();

    formData.append("file", chunk);
    for (const key in appendToFormData) {
      formData.append(key, appendToFormData[key]);
    }

    await new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          ...headers,
          "X-Unique-Upload-Id": XUniqueUploadId,
          "Content-Range": `bytes ${start}-${end}/${size}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          //   const json = res.data;
          if (json.done === true || json.url) {
            onComplete && onComplete(json);
          }
          resolve(json);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  async function sendMOBILE(
    chunk: Blob,
    start: number,
    end: number,
    size: number
  ) {
    console.log({ start, end, size });
    const formData = new FormData();

    formData.append("file", chunk);
    for (const key in appendToFormData) {
      formData.append(key, appendToFormData[key]);
    }

    const xhr = new XMLHttpRequest();
    await new Promise((resolve, reject) => {
      xhr.open("POST", url, false);
      for (const header in headers) {
        xhr.setRequestHeader(header, headers[header]);
      }
      xhr.setRequestHeader("X-Unique-Upload-Id", XUniqueUploadId);
      xhr.setRequestHeader("Content-Range", `bytes ${start}-${end}/${size}`);

      xhr.onload = function () {
        console.log(this.responseText);
        const responseData: CloudinaryCompletionResponse = JSON.parse(
          this.responseText
        );
        if (responseData.done === true) {
          onComplete && onComplete(responseData);
        }
        resolve(responseData);
      };

      xhr.onerror = function () {
        console.log(this.responseText);
        reject(this.responseText);
      };

      xhr.send(formData);
    });
  }

  function percentage(end: number, total: number) {
    return ((end / total) * 100).toFixed(2);
  }

  async function uploadFile(file: File) {
    const size = file.size;

    console.log(percentage(0, size));
    for (let start = 0; start <= size; start += chunkSize) {
      let end = start + chunkSize;
      if (end > size) {
        end = size;
      }

      const blob = slice(file, start, end);
      await send(blob, start, end - 1, size);
      const percent = percentage(end, size);
      console.log("percentage uploaded", percent);
      onPercentUpdate && onPercentUpdate(parseInt(percent));
    }
  }

  await uploadFile(file);
}

export default chunkedUpload;

// RNFetchBlob example if needed
// const arrData: any[] = [
//     {
//       name: "file",
//         filename: "myfile.jpg",
//       data: strChunk,
//     },
//   ];
//   for (let l in appendToFormData) {
//     arrData.push({ name: l, data: appendToFormData[l] });
//   }

//   RNFetchBlob.fetch(
//     "POST",
//     url,
//     {
//       ...headers,
//       "X-Unique-Upload-Id": XUniqueUploadId,
//     //   "Content-Range": `bytes ${start}-${end}/${size}`,
//       "Content-Type": "multipart/form-data",
//     },
//     arrData
//   )
//     .then((res) => res.json())
//     .then((json) => {
//       //   const json = res.data;
//       alert(JSON.stringify(json));
//       if (json.done === true || json.url) {
//         onComplete && onComplete(json);
//       }
//       resolve(json);
//     })
//     .catch((err) => {
//       alert(err);
//       console.log(err);
//       reject(err);
//     });
//   return;
