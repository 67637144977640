import { PortalHost } from "@gorhom/portal";
import { IconPrimr } from "assets";
import Color from "color";
import {
  Buttoon,
  dButtoon,
  Kitten,
  Menoo,
  Rush,
  Skeleton,
  SkeletonLayout,
  sstyled
} from "components/atoms";
import { useSx } from "dripsy";
import { useAppContext } from "engines";
import Constants from "expo-constants";
import * as R from "ramda";
import * as React from "react";
import {
  ActivityIndicator,
  Keyboard,
  Platform,
  Pressable,
  ScrollView,
  useWindowDimensions,
  View
} from "react-native";
import { MenuOptionProps } from "react-native-popup-menu";
import { getBottomSpace, IS_WEB, scale } from "utils/helpers";
import { spacing } from "utils/styles";
import { useNavigator, useNavigatorPresetConfig } from "./navigation-utils";

/**
 * ### Use for dialog overlay, positioned center of the screen
 * @returns
 */
export function DialogNavigator(props: P) {
  const { route } = props;
  const actions = route.params?.actions;
  const staticVideo = route.params?.staticVideo;
  const cardType = route.params?.cardType || "card";
  const loadingTime = route.params?.loadingTime || 600;
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const { overlayDialogConfig } = useNavigatorPresetConfig();
  const window = useWindowDimensions();
  const [ready, setReady] = React.useState<boolean>(false);
  React.useLayoutEffect(
    function layouting() {
      Navigation.setOptions(overlayDialogConfig.screenOptions);
      setTimeout(() => {
        setReady(true);
      }, loadingTime);
    },
    [Navigation]
  );

  const reducedActions = actions
    ? actions.filter((action) => action && !R.isNil(action))
    : [];

  const ControlActions = () => {
    let CloseButton = () => (
      <IconPrimr
        preset={"circular"}
        name={"x"}
        color={C.text}
        containerStyle={{
          // marginLeft: spacing(2),
          backgroundColor: Color(C.background).alpha(0.4).toString(),
        }}
        onPress={() => {
          Keyboard.dismiss();
          Navigation.goBack();
        }}
      />
    );
    if (reducedActions.length < 5) {
      if (reducedActions.length == 0) {
        //* Unless hideClose === true, Close button will always be displayed
        return route.params?.hideClose ? (
          <A.ViewControlRow />
        ) : (
          <A.ViewControlRow>
            <CloseButton />
          </A.ViewControlRow>
        );
      } else {
        return reducedActions ? (
          <A.ViewControlRow>
            {!route.params?.hideClose && <CloseButton />}
            {reducedActions.map((action, index) => (
              <Buttoon
                key={index}
                appearance="ghost"
                size="small"
                {...reducedActions[index]}
              >
                {action?.label}
              </Buttoon>
            ))}
          </A.ViewControlRow>
        ) : null;
      }
    } else {
      const _menooOptions: MenuOptionProps & { text: string }[] =
        reducedActions.map((action) => ({
          text: action?.label,
          onSelect: () => action?.onPress(),
        }));
      return reducedActions ? (
        <Menoo
          trigger={{
            name: "dots_vertical",
            color: C.grey600,
            preset: "circular",
            size: scale(14),
            // text: "Click me",
            // style: { color: "tomato" },
          }}
          options={_menooOptions}
        />
      ) : null;
    }
  };

  const sx = useSx();
  let ViewDialog = (p) => {
    switch (cardType) {
      case "card":
        return (
          <A.CardDialog
            {...p}
            style={[
              !IS_WEB && { marginBottom: getBottomSpace("safe") + spacing(5) },
            ]}
          />
        );
        break;
      case "transparent":
        return <View {...p} />;
        break;
      default:
        return (
          <A.CardDialog
            {...p}
            style={[
              !IS_WEB && { marginBottom: getBottomSpace("safe") + spacing(5) },
            ]}
          />
        );
        break;
    }
  };

  switch (Platform.OS) {
    case "web":
      /**
       * Display as a centered dialog on the web
       */
      return React.useMemo(
        () => (
          <A.PressableDialogBackgroundWeb
          // onPress={() => Navigation.goBack()} //! We can't use this since it'll overlap any pressable action of the component
          >
            <ScrollView
              stickyHeaderIndices={[0]}
              showsVerticalScrollIndicator={false}
              style={{
                marginTop: "5%",
                marginBottom: getBottomSpace("safe") + spacing(5),
              }}
              contentContainerStyle={sx({
                minWidth: [window.width, window.width, scale(501)],
                maxWidth: window.width, //* in case content is out of the window
              })} //? for centered dialog
              // style={{ backgroundColor: C.surface }} //? for sided dialog
            >
              <ControlActions />

              {/* <A.CardDialog style={{ maxWidth: window.width * 0.96 }}> */}
              <ViewDialog>
                <Skeleton isLoading={!ready} layout={SkeletonLayout.Item}>
                  <View style={{ width: "100%" }}>
                    <PortalHost name="dialog" key="dialog-portal" />
                  </View>
                </Skeleton>
              </ViewDialog>
              {/* </A.CardDialog> */}
            </ScrollView>
          </A.PressableDialogBackgroundWeb>
        ),
        [ready]
      );
      break;
    case "ios":
      return !ready ? (
        <A.PressableDialogBackground //onPress={() => Navigation.goBack()}
        >
          {/* <A.RushIndicator indeterminate color={C.surface}  /> */}
          <ActivityIndicator color={C.primary} />
        </A.PressableDialogBackground>
      ) : staticVideo ? (
        <A.PressableDialogBackground //onPress={() => Navigation.goBack()}
        >
          <ControlActions />
          <ScrollView
            keyboardShouldPersistTaps="always"
            onScrollBeginDrag={() => Keyboard.dismiss()}
          >
            <PortalHost name="dialog" key="dialog-portal" />
          </ScrollView>
        </A.PressableDialogBackground>
      ) : (
        <A.PressableDialogBackground //onPress={() => Navigation.goBack()}
        >
          <ControlActions />
          <ScrollView
            keyboardShouldPersistTaps="always"
            onScrollBeginDrag={() => Keyboard.dismiss()}
          >
            <ViewDialog>
              <PortalHost name="dialog" key="dialog-portal" />
            </ViewDialog>
          </ScrollView>
        </A.PressableDialogBackground>
      );
      break;

    default:
      /**
       * Display as a top dialog on 🍎/ 🤖
       */
      return (
        <A.PressableDialogBackground
        // onPress={() => Navigation.goBack()} //! We can't use this since it'll overlap any pressable action of the component
        >
          <Skeleton isLoading={!ready} layout={SkeletonLayout.Item}>
            <ViewDialog>
              <ScrollView stickyHeaderIndices={[0]}>
                <ControlActions />
                <PortalHost name="dialog" key="dialog-portal" />
              </ScrollView>
            </ViewDialog>
          </Skeleton>
        </A.PressableDialogBackground>
      );
      break;
  }
}

const A = {
  PressableDialogBackgroundWeb: sstyled(Pressable)({
    justifyContent: "center", //? for centered dialog
    alignItems: "center", //? for centered dialog
    // alignItems: "flex-end", //? for sided dialog
    backgroundColor: "#00000050", //or "transparent"
    flex: 1,
    width: "100%",
    height: "100%",
  }),
  PressableDialogBackground: sstyled(Pressable)({
    backgroundColor: "#00000050", //or "transparent"
    flex: 1,
    width: "100%",
    height: "100%",
    paddingTop: Constants.statusBarHeight,
  }),
  RushIndicator: sstyled(Rush.Bar)({
    marginHorizontal: spacing(3),
    marginTop: spacing(6),
    borderRadius: scale(9),
    height: 9,
  }),
  CardDialog: sstyled(View)({
    flexGrow: 1,
    // marginHorizontal: spacing(4),
    padding: spacing(4),
    borderRadius: scale(9),
    backgroundColor: "background",
    borderColor: "dim",
    borderWidth: 1,
    overflow: "hidden",
    ...(!IS_WEB ? { width: "100%" } : {}),

    //{ borderWidth: 0, backgroundColor: "transparent" } //? for sided dialog
  }),
  ViewControlRow: sstyled(Kitten.ViewH)({
    alignSelf: "flex-start",
    marginLeft: spacing(2),
    // bottom: -scale(9),
    // position: "absolute",
  }),
};
export interface dDialogParams {
  headerTitle: string;
  /** List of action buttoons, accepting **dButtoon** props.
     * - Use `label` arg for buttoon's label
     * - Try to add at most 3 buttoons for best UX
     * @example
     *  const actions = [
          { label: tr("Close"), onPress: () => Navigation.goBack() },
          { label: tr("👋"), size: "giant", onPress: () => Navigation.goBack() },
        ]; 
     */
  actions?: dAction[];
  hideClose?: boolean;
  cardType?: "card" | "transparent";
  render: React.ReactElement;
  /**
   * Time the loading dialog should be active, default is 600ms
   */
  loadingTime?: number;
  /**
   * If it's a video, make sure this is true so the video can play in fullscreen landscape
   */
  staticVideo?: boolean;
}

interface dAction extends dButtoon {
  label?: string;
}
/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: dDialogParams };
}
