import {
  AntDesign,
  Entypo as EntypoIcon,
  Feather as FeatherIcon,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialCommunityIcons as MaterialCommunityIcon
} from "@expo/vector-icons";
import Color from "color";
import * as R from "ramda";
import React from "react";
import { Platform, Pressable, View, ViewStyle } from "react-native";
import { scale } from "utils/helpers";
import { dIconOptions, dIconPrimr, ICON_PKG } from "./primr-icon.props";

// const PrimrMoon = createIconSetFromIcoMoon(
//   icoMoonConfig,
//   "IconPrimr",
//   "iconcarp.ttf"
// );

/**
 * Icon Options to use with IconPrimr<>
 */
export const iconOptions = {
  account_remove: {
    type: "material-community",
    icon: "account-remove",
  } as dIconOptions,
  account_removeo: {
    type: "material-community",
    icon: "account-remove-outline",
  } as dIconOptions,
  admin: { type: "fa5", icon: "user-tie" } as dIconOptions,
  add_to_list: { type: "entypo", icon: "add-to-list" } as dIconOptions,
  exit: { type: "fa5", icon: "sign-out-alt" } as dIconOptions,
  arrow_left: {
    type: "fa5",
    icon: Platform.select({
      android: "arrow-left",
      web: "arrow-left",
      ios: "chevron-left",
    }),
  } as dIconOptions,
  arrow_right: {
    type: "fa5",
    icon: Platform.select({
      android: "arrow-right",
      web: "arrow-right",
      ios: "chevron-right",
    }),
  } as dIconOptions,
  arrow_right_circle: {
    type: "antdesign",
    icon: "rightcircleo"
  },
  arrow_right_circle_filled: {
    type: "antdesign",
    icon: "rightcircle"
  },
  arrow_right_feather: {
    type: "feather",
    icon: "arrow-right",
  } as dIconOptions,
  arrow_right_top: {
    type: "fa5",
    icon: "arrow-right",
  } as dIconOptions,
  arrow_down: {
    type: "fa5",
    icon: Platform.select({
      android: "arrow-down",
      web: "arrow-down",
      ios: "chevron-down",
    }),
  } as dIconOptions,
  arrow_up: {
    type: "fa5",
    icon: Platform.select({
      android: "arrow-up",
      web: "arrow-up",
      ios: "chevron-up",
    }),
  } as dIconOptions,
  award: { type: "fa5", icon: "award" } as dIconOptions,
  bars: { type: "fa5", icon: "bars" } as dIconOptions,
  ban: { type: "fa5", icon: "ban" } as dIconOptions,
  bell: { type: "fa5", icon: "bell" } as dIconOptions,
  bolt: { type: "fa5", icon: "bolt" } as dIconOptions,
  bullhorn: { type: "fa5", icon: "bullhorn", scale: 0.9 } as dIconOptions,
  calendar: { type: "fa5", icon: "calendar" } as dIconOptions,
  "calendar-alt": { type: "fa5", icon: "calendar-alt" } as dIconOptions,
  chat_bubble: {
    type: "ion",
    icon: "chatbubble",
  } as dIconOptions,
  chevron_up: {
    type: "fa5",
    icon: "chevron-up",
  } as dIconOptions,
  chevron_down: {
    type: "fa5",
    icon: "chevron-down",
  } as dIconOptions,
  chevron_left: {
    type: "fa5",
    icon: "chevron-left",
  } as dIconOptions,
  chevron_right: {
    type: "fa5",
    icon: "chevron-right",
  } as dIconOptions,
  check: {
    type: "fa5",
    icon: "check",
  } as dIconOptions,
  check_circle: {
    type: "fa",
    icon: "check-circle",
    scale: 1.2,
  } as dIconOptions,
  compass: { type: "fa5", icon: "compass" } as dIconOptions,
  component: {
    type: "ion",
    icon: "logo-web-component",
    scale: 1.2,
  } as dIconOptions,
  content_share: { type: "ion", icon: "share-social-sharp" } as dIconOptions,
  cog: { type: "fa5", icon: "cog" } as dIconOptions,
  clock: { type: "feather", icon: "clock" } as dIconOptions,
  contacts: { type: "fa5", icon: "address-book" } as dIconOptions,
  copy: { type: "fa5", icon: "copy" } as dIconOptions,
  crm: {
    // type: "entypo",
    // icon: "creative-commons-attribution",
    type: "fa5",
    icon: "sitemap", // dragon, diagnoses, jedi, magic, plane-departure
  } as dIconOptions,
  crown: { type: "material-community", icon: "crown" } as dIconOptions,
  dashboard: { type: "fa", icon: "dashboard" } as dIconOptions,
  dot: { type: "fa5", icon: "circle" } as dIconOptions,
  dots_horizontal: { type: "fa5", icon: "ellipsis-h" } as dIconOptions,
  dots_vertical: {
    type: "entypo",
    icon: "dots-three-vertical",
    scale: 1.2,
  } as dIconOptions,
  email: {
    type: "material-community",
    icon: "email",
    scale: 1.1,
  } as dIconOptions,
  enter: {
    type: "antdesign",
    icon: "enter",
    scale: 1.1,
  } as dIconOptions,
  envelope: { type: "fa5", icon: "envelope" } as dIconOptions,
  eye: { type: "fa5", icon: "eye" } as dIconOptions,
  event: { type: "material-community", icon: "calendar" } as dIconOptions,
  eye_slash: { type: "fa5", icon: "eye-slash" } as dIconOptions,
  exclamation_circle: {
    type: "fa5",
    icon: "exclamation-circle",
  } as dIconOptions,
  expand: { type: "fa5", icon: "expand-alt" } as dIconOptions,
  flag: { type: "fa5", icon: "flag" } as dIconOptions,
  folder: { type: "fa5", icon: "folder" } as dIconOptions,
  file_csv: { type: "fa5", icon: "file-csv" } as dIconOptions,
  file_pdf: { type: "fa5", icon: "file-pdf" } as dIconOptions,
  filtermenu: {
    type: "material-community",
    icon: "filter-menu",
  } as dIconOptions,
  hexagon: {
    type: "material-community",
    icon: "hexagon",
    scale: 1.3,
  } as dIconOptions,
  info: {
    type: "fa5",
    icon: "info",
  } as dIconOptions,
  move_vertical: {
    type: "material-community",
    icon: "unfold-more-horizontal",
  } as dIconOptions,
  media_resource: { type: "entypo", icon: "folder-video" } as dIconOptions,
  google: { type: "fa5", icon: "google" } as dIconOptions,
  group: { type: "ion", icon: "people-circle-outline" } as dIconOptions,
  handshake: { type: "fa5", icon: "handshake" } as dIconOptions,
  hand_peace: { type: "fa5", icon: "hand-peace" } as dIconOptions,
  heart: { type: "fa5", icon: "heart" } as dIconOptions,
  home: { type: "fa5", icon: "home" } as dIconOptions,
  help_box: {
    type: "material-community",
    icon: "help-box",
  } as dIconOptions,
  image: { type: "fa5", icon: "image" } as dIconOptions,
  images: { type: "fa5", icon: "images" } as dIconOptions,
  install: { type: "entypo", icon: "install" } as dIconOptions,
  magnet_link: { type: "fa5", icon: "link" } as dIconOptions,
  menu_fold: { type: "antdesign", icon: "menu-fold" } as dIconOptions,
  level: { type: "material-community", icon: "stairs-box" } as dIconOptions,
  link: { type: "fa5", icon: "external-link-square-alt" } as dIconOptions,
  list: { type: "fa5", icon: "th-list" } as dIconOptions,
  lock: { type: "fa5", icon: "lock" } as dIconOptions,
  medal: { type: "fa5", icon: "medal" } as dIconOptions,
  message: { type: "entypo", icon: "message" } as dIconOptions,
  moon: { type: "ion", icon: "moon-sharp" } as dIconOptions,
  minus: { type: "fa5", icon: "minus" } as dIconOptions,
  menu: { type: "feather", icon: "menu" } as dIconOptions,
  notification: { type: "entypo", icon: "notification" } as dIconOptions,
  num5: {
    type: "material-community",
    icon: "numeric-5-circle",
  } as dIconOptions,
  num4: {
    type: "material-community",
    icon: "numeric-4-circle",
  } as dIconOptions,
  num3: {
    type: "material-community",
    icon: "numeric-3-circle",
  } as dIconOptions,
  num2: {
    type: "material-community",
    icon: "numeric-2-circle",
  } as dIconOptions,
  num1: {
    type: "material-community",
    icon: "numeric-1-circle",
  } as dIconOptions,
  num: {
    type: "material-community",
    icon: "numeric-0-circle",
  } as dIconOptions,
  num0: {
    type: "material-community",
    icon: "numeric-0-circle",
  } as dIconOptions,
  password: {
    type: "material-community",
    icon: "form-textbox-password",
  } as dIconOptions,
  page: {
    type: "fa5",
    icon: "file-alt",
  } as dIconOptions,
  pause: { type: "fa5", icon: "pause", scale: 0.9 } as dIconOptions,
  pen: { type: "fa5", icon: "pen" } as dIconOptions,
  pencil: { type: "fa5", icon: "pencil-alt" } as dIconOptions,
  phone: { type: "fa5", icon: "phone-alt" } as dIconOptions,
  pin: { type: "entypo", icon: "pin", scale: 1.1 } as dIconOptions,
  play: { type: "fa5", icon: "play", scale: 0.9 } as dIconOptions,
  play_outline: {
    type: "material-community",
    icon: "play-circle-outline",
  } as dIconOptions,
  plus: { type: "fa5", icon: "plus" } as dIconOptions,
  plus_item: { type: "material-community", icon: "card-plus" } as dIconOptions,
  plus_box: { type: "antdesign", icon: "plussquareo" } as dIconOptions,
  placeholder: { type: "fa5", icon: "kickstarter-k" } as dIconOptions,
  profile: { type: "ion", icon: "ios-person-circle-sharp" } as dIconOptions,
  add_person: { type: "ion", icon: "person-add" } as dIconOptions,
  rocket: { type: "fa5", icon: "rocket" } as dIconOptions,
  refresh: { type: "foundation", icon: "refresh", scale: 1.3 } as dIconOptions,
  radio_blank: {
    type: "material-community",
    icon: "radiobox-blank",
  } as dIconOptions,
  radio_marked: {
    type: "material-community",
    icon: "radiobox-marked",
  } as dIconOptions,
  retweet: { type: "fa5", icon: "retweet" } as dIconOptions,
  share: { type: "fa5", icon: "share-alt" } as dIconOptions,
  sms: { type: "fa5", icon: "sms" } as dIconOptions,
  skipforward: {
    type: "material-community",
    icon: "fast-forward-10",
  } as dIconOptions,
  skipbackward: {
    type: "material-community",
    icon: "rewind-10",
  } as dIconOptions,
  scoreboard: {
    type: "material-community",
    icon: "scoreboard",
  } as dIconOptions,
  search: { type: "fa5", icon: "search" } as dIconOptions,
  sortaz: { type: "fa5", icon: "sort-alpha-down" } as dIconOptions,
  sortza: { type: "fa5", icon: "sort-alpha-down-alt" } as dIconOptions,
  sound: { type: "fa", icon: "volume-up" } as dIconOptions,
  star: { type: "antdesign", icon: "star" } as dIconOptions,
  staro: { type: "antdesign", icon: "staro" } as dIconOptions,
  sunny: { type: "ion", icon: "sunny-sharp" } as dIconOptions,
  tap: { type: "material-community", icon: "gesture-tap-box" } as dIconOptions,
  tag: { type: "fa5", icon: "tag" } as dIconOptions,
  todolist: {
    type: "material-community",
    icon: "format-list-checks",
    scale: 1.4,
  } as dIconOptions,
  title: {
    type: "material-community",
    icon: "format-header-1",
  } as dIconOptions,
  text: { type: "ion", icon: "text" } as dIconOptions,
  text2: { type: "material-community", icon: "format-title" } as dIconOptions,
  tools: { type: "fa5", icon: "th-large" } as dIconOptions,
  to_page: {
    type: "fa5",
    icon: "file-import",
  } as dIconOptions,
  send: { type: "fa", icon: "send" } as dIconOptions,
  square: { type: "fa", icon: "square" } as dIconOptions,
  swap: {
    type: "material-community",
    icon: "swap-horizontal-bold",
  } as dIconOptions,
  trash: { type: "fa5", icon: "trash" } as dIconOptions,
  tv: { type: "fa5", icon: "tv" } as dIconOptions,
  trophy: { type: "fa5", icon: "trophy" } as dIconOptions,
  videocam: { type: "ion", icon: "videocam" } as dIconOptions,
  vimeo: { type: "fa5", icon: "vimeo-v" } as dIconOptions,
  people: { type: "ion", icon: "people" } as dIconOptions,
  people_friends: { type: "fa5", icon: "user-friends" } as dIconOptions,
  undo: { type: "fa5", icon: "undo" } as dIconOptions,
  unlock: { type: "fa5", icon: "unlock" } as dIconOptions,
  user: { type: "fa5", icon: "user-circle", scale: 1.2 } as dIconOptions,
  users: { type: "entypo", icon: "users" } as dIconOptions,
  widgets: {
    type: "material-community",
    icon: "widgets",
  } as dIconOptions,
  world: {
    type: "material-community",
    icon: "earth",
  } as dIconOptions,
  x: { type: "fa5", icon: "times", scale: 1.2 } as dIconOptions,
  x_circle: { type: "fa", icon: "times-circle", scale: 1.2 } as dIconOptions,
  youtube: { type: "fa5", icon: "youtube" } as dIconOptions,
  /**
   * Card Brand Icon. Have to fit Stripe's brand name
   * @see https://stripe.com/docs/api/cards/object#card_object-brand
   */
  "diners club": { type: "fa5", icon: "cc-diners-club" } as dIconOptions,
  discover: { type: "fa5", icon: "cc-discover" } as dIconOptions,
  jcb: { type: "fa5", icon: "cc-jcb" } as dIconOptions,
  mastercard: { type: "fa5", icon: "cc-mastercard" } as dIconOptions,
  visa: { type: "fa5", icon: "cc-visa" } as dIconOptions,
  verified: {
    type: "material-community",
    icon: "check-decagram",
  } as dIconOptions,
};

/**
 * Main and the only Icon component of the whole codebase
 *
 * @version 1.12.3
 * -  *Add disabled icon's color*
 * @example
 *  <IconPrimr preset={"safe"} name={"arrow_left"} size={30} color={"dodgerblue"} />
 */
export function IconPrimr(props: dIconPrimr) {
  const {
    preset = "safe",
    name = "placeholder",
    size = 20,
    color = "dodgerblue",
    solid = true,
    disabled,
    containerStyle = {},
    onPress,
    onLongPress,
  } = props;
  const _containerStyle: ViewStyle = R.mergeAll(
    R.flatten([
      presets(size)[preset].containerStyle ||
      presets(size).default.containerStyle,
      containerStyle,
    ])
  );
  const _iconStyle: dIconPrimr = R.mergeAll(
    R.flatten([
      presets(size)[preset].icon || presets().default.icon,
      {
        size,
        color: disabled ? "#69696940" : color,
        name,
      },
    ])
  );
  if (iconOptions[name] == undefined) {
    console.log("Icon err: icon " + name + " not existed in iconOptions!");
    return (
      <View
        style={{ ..._containerStyle, borderWidth: 1, borderColor: "tomato" }}
      />
    );
  } else {
    const { type, scale = 1, icon } = iconOptions[name];
    const { size, color } = _iconStyle;
    const BrandedIcon = getType(type);
    const Ctnr = !!onPress ? Pressable : View;
    let _style = !!onPress
      ? ({ pressed, hovered, focused }) => [
        _containerStyle,
        hovered && {
          // borderWidth: containerStyle.borderWidth === 0 ? 0 : 2,
          backgroundColor: containerStyle.backgroundColor
            ? Color(containerStyle.backgroundColor.toString()).alpha(0.1)
            : Color(color).alpha(0.1),
        },
      ]
      : _containerStyle;
    // const Ctnr = Pressable;
    return (
      //@ts-ignore rn-web special props
      <Ctnr
        disabled={disabled}
        //@ts-ignore rn-web special props
        style={_style}
        onPress={onPress}
        onLongPress={onLongPress}
      >
        <BrandedIcon
          name={icon}
          solid={solid}
          size={size * scale}
          color={color}
        />
      </Ctnr>
    );
  }
}

/** Get Icon type from `@expo/vector-icons */
const getType = (type: ICON_PKG) => {
  switch (type) {
    case "fa":
      return FontAwesome;
    case "fa5":
      return FontAwesome5;
    case "feather":
      return FeatherIcon;
    // case "material":
    //   return MaterialIcon;
    case "material-community":
      return MaterialCommunityIcon;
    case "entypo":
      return EntypoIcon;
    // case "simple-line-icon":
    //   return SimpleLineIcon;
    case "antdesign":
      return AntDesign;
    case "ion":
      return Ionicons;
    case "foundation":
      return Foundation;
    default:
      return FontAwesome5;
  }
};

export const presets = (size?: number) => ({
  default: {
    containerStyle: null,
    icon: { name: null, size: scale(11), color: "black" },
  },
  safe: {
    containerStyle: { margin: scale(5) },
    icon: { name: null, size: scale(11), color: "black" },
  },
  header: {
    containerStyle: null,
    icon: { name: null, size: scale(24), color: "black" },
  },
  circular: {
    containerStyle: {
      borderRadius: size * 2,
      width: size * 1.8,
      height: size * 1.8,
      justifyContent: "center",
      alignItems: "center",
    },
    icon: null,
  },
});
