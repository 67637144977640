import { useFocusEffect } from "@react-navigation/native";
import { IconPrimr } from "assets";
import { ContactListItem, sstyled, Txt } from "components";
import { Buttoon, Kitten, ListHeader, Skeleton } from "components/atoms";
import { ListItemv2 } from "components/atoms/externals/ui-kitten/list-item-v2";
import { HitEmUpEmail } from "components/molecules/HitEmUp/HitEmUpEmail";
import { BlastSmsComposer } from "components/molecules/HitEmUp/hitemupSms";
import { useTwoColumns } from "components/templates";
import { MediaPageItemSchema, useAppContext } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import * as R from "ramda";
import React from "react";
import { ScrollViewProps, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useNavigator } from "screens/_navigation";
import { IS_WEB, spacing, tr } from "utils";
import {
  useContactsCollection,
  useFollowUpContacts
} from "../../../engines/firebase/handler/contact-handler";
import { AddContactCreator } from "../contact-manager/AddContactCreatorDialog";

/**
 * ### List of members of the app (aka hierarchy's members)
 *  - Including some quick actions as an swipeable item
 *  ----
 *  @version 21.09.07
 *  -  *Memoize it*
 *  @author  K, Nl
 *
 **/
export function ContactsFeed(props: P) {
  const { C, frbsUser } = useAppContext();
  const userProfile = useUserProfile();
  const {
    id,
    team,
    c_uid,
    sharedItems,
    scrollHandler = () => { },
    // followUpContacts,
    // futureFollowUpContacts,
    futureFollowUpsOnly,
  } = props;
  const Navigation = useNavigator();
  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();

  //#region [FRBS]
  const contactHandler = !props.followUpContacts
    ? c_uid && c_uid !== userProfile.data?._id
      ? useContactsCollection(c_uid, id)
      : !team
        ? useContactsCollection(userProfile.data?._id, id)
        : useContactsCollection(c_uid, id)
    : useFollowUpContacts(c_uid || frbsUser?.uid);

  const [sortType, setSortType] = React.useState<"points" | "alphabetical">("alphabetical");
  const [contacts, setContacts] = React.useState<ContactSchemaa[]>(
    props.followUpContacts || []
  );

  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  const needsFollowUpContacts = contactHandler.data?.filter(
    (c) => c.followUpDate?.toDate && c.followUpDate?.toDate() <= endOfToday
  );
  const futureFollowUpContacts =
    props.futureFollowUpContacts && !contactHandler.data
      ? props.futureFollowUpContacts
      : contactHandler.data?.filter(
        (c) => c.followUpDate?.toDate && c.followUpDate?.toDate() > endOfToday
      );


  React.useEffect(
    function setupData() {
      if (!contactHandler && props.followUpContacts) {
        setContacts(props.followUpContacts || []);
      }

      if (!contactHandler.data) return;

      if (futureFollowUpsOnly) {
        // FUTURE FOLLOW UPS
        setContacts(futureFollowUpContacts);
      } else if (props.followUpContacts) {
        // FOLLOW UPS
        setContacts(needsFollowUpContacts);
      } else {
        // REGULAR CONTACTS LIST
        setContacts(contactHandler.data);
      }
    },
    [contactHandler.data]
  );
  const [listOffset, setListOffset] = React.useState(125);
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion
  //#region [section] search ops
  const [searchQuery, setSearchQuery] = React.useState<string>();

  function filterHandler(value: string) {
    setSearchQuery(value);
    setContacts(contactHandler.data?.slice()?.filter(c => value?.length === 0 || (c?.name || c?.displayName)?.toLowerCase()?.includes(value?.toLowerCase())));
  }
  //#endregion

  return React.useMemo(() => {
    try {
      if (contactHandler.error) {
        console.warn("error in ContactsFeed: ", contactHandler.error);
        return (
          <Txt.Indicator>
            {tr("Error getting contacts. " + contactHandler.error)}
          </Txt.Indicator>
        );
      }
      if (!contacts) return <Skeleton.Screen.Members length={7} />;

      let sortedContacts = sortType === "alphabetical" ? R.sortWith<ContactSchemaa>(
        [
          // @ts-ignore sorting `name` in `desc`
          R.ascend(R.prop("name")),
        ],
        contacts.slice()
      ) : contacts.slice().sort((a, b) => (b?.qualifications as string[] || [])?.length - (a?.qualifications as string[] || [])?.length)
      // if no _cid, go away you're a bad contact
      sortedContacts = sortedContacts.filter(sc => sc._cid)
      // sort((a, b) => b?.qualifications?.length - a?.qualifications?.length);

      if (props.followUpContacts)
        sortedContacts = sortedContacts?.slice()?.sort(
          (a, b) =>
            (a.followUpDate?.toDate ? a.followUpDate?.toDate()?.getTime() : 0) -
            (b.followUpDate?.toDate ? b.followUpDate?.toDate()?.getTime() : 0)
        );

      // console.log("sortedContacts ", sortedContacts);
      return (
        <>
          <View>
            <ListHeader
              titleTx={
                (searchQuery
                  ? tr("Search result")
                  : tr(
                    props.followUpContacts ? "Follow Ups" : "Contacts"
                  )) +
                " • " +
                sortedContacts?.length
              }
              actions={[
                {
                  label: "Sort",
                  icon: { name: "list" },
                  menuOptions: [
                    {
                      text: `Alphabetical ${sortType === "alphabetical" ? "(selected)" : ""}`,
                      //@ts-ignore bad type setup
                      onSelect: () => {
                        setSortType("alphabetical")
                      }
                    },
                    {
                      text: `Points ${sortType === "points" ? "(selected)" : ""}`,
                      //@ts-ignore bad type setup
                      onSelect: () => {
                        setSortType("points")
                      }
                    }
                  ]
                },
                {
                  label: tr("Blast to all"),
                  style: { marginRight: spacing(4) },
                  // disabled: sortedContacts?.length == 0,
                  icon: { name: "bullhorn" },
                  menuOptions: [
                    {
                      text: tr("Blast Email"),
                      //@ts-ignore bad type setup
                      onSelect: () => {
                        Navigation.overlayPortal.open({
                          headerTitle: tr("Blast Email"),
                          render: (
                            <HitEmUpEmail
                              listData={sharedItems}
                              shareMode={true}
                              contacts={sortedContacts}
                            />
                          ),
                        });
                      },
                    },
                    {
                      text: "__________",
                    },
                    {
                      text: tr("Blast SMS"),
                      icon: "sms",
                      onSelect: () => {
                        Navigation.overlayPortal.open({
                          headerTitle: "Blast SMS",
                          render: (
                            <BlastSmsComposer
                              listData={sharedItems}
                              shareMode={true}
                              contacts={sortedContacts}
                            />
                          ),
                        });
                      },
                    },
                    {
                      text: "__________",
                    },
                    {
                      text: tr("Cancel"),
                      onSelect: () => {
                        // console.log("checker");
                      },
                      color: C.errorRed,
                    },
                  ],
                },
              ]}
            />
            {!props.followUpContacts && !futureFollowUpsOnly ? <Kitten.Input
              value={searchQuery}
              autoFocus={searchQuery?.length > 0}
              placeholder={tr("Search contacts")}
              onChangeText={(value) => {
                filterHandler(value);
              }}
              accessoryRight={
                <IconPrimr
                  solid={true}
                  preset="circular"
                  size={18}
                  containerStyle={{ backgroundColor: C.primary }}
                  name="plus"
                  color={C.pitchWhite}
                  onPress={() => {
                    IS_WEB
                      ? Navigation.dialogPortal.open({
                        headerTitle: "Add contact",
                        render: <AddContactCreator id={id} />,
                      })
                      : Navigation.navigate("contacts-search", {
                        id: id,
                      });
                  }}
                />
              }
            /> : null}
          </View>
          <FlatList
            // scrollEventThrottle={16}
            // onScroll={scrollHandler}
            showsVerticalScrollIndicator={false}
            scrollEnabled={sortedContacts.length > 5}
            contentContainerStyle={[column1ContainerStyle]}
            data={sortedContacts
              /** pagination */
              .slice(0, listOffset)}
            renderItem={({ item: contact, index }) => (
              // <Txt>{JSON.stringify(contact)}</Txt>
              <ContactListItem
                key={index}
                contact={contact}
                groupId={id}
                onBlastTextStart={(__contact) => {
                  Navigation.dialogPortal.open({
                    headerTitle: "Blast SMS",
                    render: (
                      <BlastSmsComposer
                        listData={sharedItems}
                        shareMode={true}
                        contacts={sortedContacts}
                        skipTo={__contact}
                      />
                    ),
                  });
                }}
                listUserId={
                  c_uid && c_uid !== userProfile.data?._id
                    ? c_uid
                    : userProfile.data?._id
                }
              />
            )}
            ListFooterComponent={() => (
              <>
                {futureFollowUpContacts && !futureFollowUpsOnly ? (
                  <ListItemv2
                    title={`View ${futureFollowUpContacts.length} contacts with upcoming follow ups`}
                    accessoryRight={() => (
                      <IconPrimr name="arrow_right" color={C.text01} />
                    )}
                    onPress={() => {
                      Navigation.navigate("Future Follow Ups", {
                        id: "follow-up",
                        followUpContacts: futureFollowUpContacts,
                        futureFollowUpsOnly: true,
                      });
                    }}
                  />
                ) : null}
                {sortedContacts?.length > 10 ? (
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Buttoon
                      appearance={"ghost"}
                      progress
                      style={{ marginVertical: 20, alignSelf: "center" }}
                      onPress={(xong) => {
                        setTimeout(() => {
                          setListOffset(listOffset + 100);
                          xong && xong();
                        }, 600);
                      }}
                      disabled={listOffset >= sortedContacts.length}
                    >
                      {listOffset < sortedContacts.length
                        ? tr("Load More")
                        : ""}
                    </Buttoon>
                    <ViewCol1BottomSpace />
                  </View>
                ) : (
                  <View style={{ paddingBottom: 50 }} />
                )}
              </>
            )}
            ListEmptyComponent={() => (
              <Txt.Indicator marginV>
                {contactHandler.loading
                  ? tr("Loading...")
                  : props.followUpContacts &&
                    !props.futureFollowUpsOnly &&
                    contacts?.length < 1
                    ? tr("All done! Great job!")
                    : tr("No contacts")}
              </Txt.Indicator>
            )}
            keyExtractor={(item, index) => (item._cid || index)}
          />
        </>
      );
    } catch (error) {
      throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
    }
  }, [contactHandler, contacts, listOffset, C, searchQuery]);
}

const A = {
  CardContainer: sstyled(Kitten.Card)({
    minWidth: 300,
  }),
};

interface P {
  id: string;
  team: boolean;
  c_uid?: string;
  sharedItems?: MediaPageItemSchema[];
  scrollHandler?: ScrollViewProps["onScroll"];
  followUpContacts?: ContactSchemaa[];
  futureFollowUpContacts?: ContactSchemaa[];
  futureFollowUpsOnly?: boolean;
}
