import { ICON_NAME } from "assets";
import {
  Buttoon,
  Kitten,
  ListHeader,
  Skeleton,
  sstyled,
  Toasty
} from "components/atoms";
import { CustomPageBody } from "components/organisms";
import { FPATH, URL_PREFIX, useAppContext, _DEFAULT_MEDIA_ITEM } from "engines";
import { createNewToPage } from "engines/firebase/handler/pages-handler";
import { useCollabContext } from "engines/providers/collaboration-provider";
import firebase from "firebase";
import React from "react";
import { Dimensions, Pressable, useWindowDimensions, View } from "react-native";
import { useNavigator } from "screens";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { scale, spacing, tr } from "utils";
import { MediaPageItemSchema } from "../../../../functions/src/schemas";
import { UserSchema } from "../../../engines/backends/schemas/possts/user.schema";
import { IS_WEB } from "../../../utils/helpers/device.helpers";
import { COPY_CONTENT_TYPE } from "./CopyCollaborationContentMenu";
import { CopyTo } from "./CopyTo";

export const handleCopyContent = async (options: {
  Navigation;
  teamId: string;
  rolesThatCanEdit: string[];
  C;
  /**
   * Provide using hook to get auto adjusting style for the web
   */
  screenWidth?: number;
  /**
   * Provide using hook to get auto adjusting style for the web
   */
  screenHeight?: number;
  /**
   * New page id to paste everything into
   */
  copyToPageId: string;
  /**
   * Media page items to be copied over
   */
  pageContentItems: MediaPageItemSchema[];
  /**
   * Content handler for page being copied into
   * - You can choose not to provide this and it's fine, but you may lose advantage of lastItemIndex() function therefore omitting this is not recommended for a pre-existing page
   */
  // pageContentsHandler?: UsePageContentsCollectionType;
  navToTitle: string;
  /**
   * ### Make sure to disable/reset the collab context
   * 
   * @example
   (disableCollab) => {
      if(disableCollab) setIsCollabModeOn(false);
      setIsSelectModeOn(false);
      setSelectAll(false)
      setPageContentItems([])
      clearCollabContext();
   }
   */
  onResetCollab: (disableCollab: boolean) => void;
}) => {
  const {
    Navigation,
    teamId,
    rolesThatCanEdit,
    C,
    copyToPageId,
    pageContentItems,
    screenWidth = Dimensions.get("window").width,
    screenHeight = Dimensions.get("window").height,
    // pageContentsHandler,
    navToTitle,
    onResetCollab,
  } = options;

  const totalItems = pageContentItems.length || 0;

  console.log("[multiverse] copying " + totalItems + " items...");

  const destinationPageContentItems: MediaPageItemSchema[] = (
    await firebase
      .firestore()
      .collection(FPATH.PAGES)
      .doc(copyToPageId)
      .collection(FPATH.CUSTOM_PAGE_CONTENT)
      .get()
  )?.docs?.map((d) => d?.data());

  const pageContentsRef = firebase
    .firestore()
    .collection(FPATH.PAGES)
    .doc(copyToPageId)
    .collection(FPATH.CUSTOM_PAGE_CONTENT);

  //NOTE : Copy the content frompageID to topageID
  pageContentItems.forEach(async (item, index) => {
    try {
      // console.log(`🤖 There are ${data?.length} in destination page`);
      let newToPageId: string = "";
      let urlPagePrefix: string = "";
      if (
        item.url &&
        (item.url?.includes(URL_PREFIX.PAGE) ||
          item.url?.includes(URL_PREFIX.LEVEL))
      ) {
        urlPagePrefix = item.url?.includes(URL_PREFIX.PAGE)
          ? URL_PREFIX.PAGE
          : URL_PREFIX.LEVEL;
        newToPageId = (
          await createNewToPage(
            item.url
              ?.replace(URL_PREFIX.PAGE, "")
              ?.replace(URL_PREFIX.LEVEL, ""),
            teamId,
            rolesThatCanEdit
          )
        )
          .replace(URL_PREFIX.PAGE, "")
          .replace(URL_PREFIX.LEVEL, "");
      }
      if (newToPageId?.length > 0)
        console.log(`[multiverse] 🚀 NEW PAGE ID IS ${newToPageId} `);

      console.log(
        "[multiverse] adding in new page content item " + item?.title
      );
      const newItemDoc = await pageContentsRef.add({
        ..._DEFAULT_MEDIA_ITEM,
        // ...item,
        _id: item?._id ? item?._id : "",
        _parentPageId: copyToPageId,
        title: item?.title ? item?.title : "",
        paragraph: item?.paragraph ? item?.paragraph : "",
        media: item?.media ? item?.media : "",
        _teamId: teamId,
        checkbox: item?.checkbox || false,
        url:
          urlPagePrefix && newToPageId
            ? `${urlPagePrefix}${newToPageId}`
            : item.url,
        // topage: newToPageId ? newToPageId : item?.topage ? item?.topage : "",
        //NOTE the index +1 is to make sure the position order after copying is preserved
        // ...(pageContentsHandler
        //   ? {
        //       position: pageContentsHandler._largestPositionIndex() + index + 1,
        //     }
        //   : { position: index + 1 }),
        position:
          index +
          1 +
          (destinationPageContentItems.length > 0
            ? destinationPageContentItems.sort(
              (a, b) => b.position - a.position
            )[0].position
            : 0),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        // isContentPrivate: pageHandler.data?.collaboration ? false : true,
        isContentPrivate: false,
        trackable: false,
        ...(item.textTemplate ? { ...item.textTemplate, enableForTeamId: "" } : {})
      } as Partial<MediaPageItemSchema>);
      pageContentsRef.doc(newItemDoc.id).update({ _id: newItemDoc.id });
    } catch (err) {
      console.log("[multiverse] " + err);
      Toasty.show(err, { type: "danger" });
    }
  });

  // //NOTE: Clear all the settings
  // clearCollabContext();

  !IS_WEB && Navigation.goBack();

  // Navigation.overlayPortal.open({
  Navigation.dialogPortal.open({
    render: (
      <View
        style={{
          backgroundColor: C.background,
          width: IS_WEB ? screenWidth * 0.65 : screenWidth * 0.9,
          height: screenHeight * 0.8,
        }}
      >
        <Buttoon
          icon={{ name: "arrow_right", right: true }}
          appearance="ghost"
          onPress={() => {
            // setIsCollabModeOn(false);
            // setIsSelectModeOn(false);
            // clearCollabContext();
            onResetCollab(true);
            Navigation.navigate("Home");
            Navigation.navigate<dPageContentsScreenParam>("Page Contents", {
              cpid: copyToPageId
                .replace(URL_PREFIX.PAGE, "")
                .replace(URL_PREFIX.LEVEL, ""),
            });
          }}
        >
          Go to this page
        </Buttoon>
        <CustomPageBody
          title={navToTitle}
          // pageHandler={pageHandler}
          cpid={copyToPageId}
          selectModeOn={false}
          scrollToBottom={true}
        />
      </View>
    ),
    headerTitle: navToTitle,
  });

  // setIsSelectModeOn(false);
  // setPageContentItems([]);
  // setSelectAll(false);
  onResetCollab(false);
  //NOTE: This is not needed since what if user want to copy more contents.
  //      So it'll preservere that page
  // setCopyToPageId("");

  //NOTE : Send out push notification that new content is now available

  //NOTE : Navigate the user to the page where we copied the content to
  Toasty.show(
    `Copied ${totalItems} items${navToTitle ? " to " + navToTitle : "."}`,
    { type: "success", duration: 5000 }
  );
  Toasty.show("New items are at the bottom of the page.", {
    type: "normal",
    duration: 5000,
  });
};

export function CollaborationCopyCenter(props: P) {
  const { cpid } = props;
  const { C, teamId, frbsUser } = useAppContext();
  // const Navigation = useNavigator();
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const {
    isSelectModeOn,
    setIsSelectModeOn,
    setIsCollabModeOn,
    pageContentItems,
    copyToPageId,
    setCopyToPageId,
    clearCollabContext,
    navToTitle,
    setPageContentItems,
    setSelectAll,
    setNavToTitle,
  } = useCollabContext();
  // const [copyToPageId, setCopyToPageId] = React.useState<string>("");

  const Navigation = useNavigator();

  // const pageContentsHandler = usePageContentsCollection(copyToPageId);
  // const pageHandler = usePageDocument(copyToPageId);

  // React.useEffect(() => {
  //   setIsSelectModeOn(false);
  // }, []);

  //#region [functions]
  const onChangeCallback = React.useCallback(
    (_copyToPageId, _navToTitle) => {
      // console.log("dest id: ", _copyToPageId + " - " + _navToTitle);
      let rawCopyToPageId = String(_copyToPageId).replace(URL_PREFIX.PAGE, "");
      setCopyToPageId(rawCopyToPageId);
      setNavToTitle(_navToTitle);
      // console.log("dest id done");
    },
    [copyToPageId, navToTitle]
  );

  const totalItems = pageContentItems.length || 0;

  //#endregion

  return React.useMemo(() => {
    try {
      if (!cpid) return null;
      // return (
      //   <View>
      //     <LinkTo
      //       onChange={(l) => console.log("LinkTo: ", l)}
      //       flexDirection={"row"}
      //     />
      //   </View>
      // );
      return (
        <View>
          {pageContentItems.length != 0 && isSelectModeOn && (
            <View>
              <ListHeader titleTx={tr("Select destination page")} />
              <A.CardContainer>
                <Skeleton
                  isLoading
                  duration={4000}
                  layout={[
                    {
                      key: "medal-wrapper",
                      width: scale(940),
                      height: scale(300),
                      // justifyContent: "center",
                    },
                  ]}
                  highlightColor={C.primary}
                  boneColor={C.surface}
                />
                <A.ViewLabel>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      margin: spacing(3),
                    }}
                  >
                    <CopyTo onChange={onChangeCallback} />
                    <Kitten.Divider_________ />
                    {/* <Txt>{JSON.stringify(copyToPageId)}</Txt> */}
                    <Buttoon
                      appearance={"filled"}
                      onPress={async () => {
                        // get the current user in case they have roles that need to be added in here
                        const userD: UserSchema = (
                          await firebase
                            .firestore()
                            .doc(`${FPATH.USERS}/${frbsUser?.uid}`)
                            .get()
                        )?.data() as UserSchema;

                        handleCopyContent({
                          Navigation,
                          C,
                          rolesThatCanEdit: userD?.roles || [],
                          teamId,
                          screenWidth,
                          screenHeight,
                          copyToPageId,
                          pageContentItems: pageContentItems,
                          // pageContentsHandler,
                          navToTitle,
                          onResetCollab: (disableCollab = false) => {
                            if (disableCollab) {
                              setIsCollabModeOn(false);
                              clearCollabContext();
                            }
                            setIsSelectModeOn(false);
                            setSelectAll(false);
                            setPageContentItems([]);
                          },
                        });
                      }}
                      disabled={!copyToPageId}
                    >
                      {tr(`Copy ${totalItems} item(s)`)}
                    </Buttoon>
                  </View>
                </A.ViewLabel>
              </A.CardContainer>
            </View>
          )}
        </View>
      );
    } catch (error) {
      throw Error("::TGlua1Rv611Axa::" + error);
    }
  }, [isSelectModeOn, pageContentItems, copyToPageId]);
}

const A = {
  CardContainer: sstyled(Pressable)({
    borderRadius: scale(4),
    overflow: "hidden",
  }),
  ViewLabel: sstyled(View)((p) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  })),
};

interface dCreatorOption {
  key: COPY_CONTENT_TYPE;
  label: string;
  icon: ICON_NAME;
}
interface P {
  itemOption?: dCreatorOption;
  cpid: string;
  title?: string;
}
