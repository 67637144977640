import { useCollection } from "@nandorojo/swr-firestore";
import { useFocusEffect } from "@react-navigation/native";
import { O, Txt } from "components";
import { Buttoon, Kitten, ListHeader, Skeleton } from "components/atoms";
import { CollaborationHierarchyListItem } from "components/molecules/collaboration/CollaborationHierarchyListItem";
import { useTwoColumns } from "components/templates";
import { FPATH, MediaPageSchema, useAppContext } from "engines";
import { iHierarchyList } from "engines/backends/schemas/collaboration/hierarchyList.schema";
import { useConfigCollection } from "engines/firebase/handler/config-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useCollabContext } from "engines/providers/collaboration-provider";
import * as R from "ramda";
import React from "react";
import { FlatList, View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { spacing, tr } from "utils";
import { iHierarchyFeedData } from "./HierarchyHomeFeed";

/**
 * ### List all hierarchies which are part of collaboration
 *  ----
 *  @version 21.09.08
 *  @author  Saurabh_M
 *
 **/
export function CollaborationHomeFeed() {
  const { C, teamId } = useAppContext();
  const { setIsCollabModeOn } = useCollabContext();
  const Navigation = useNavigator();
  const { column1ContainerStyle } = useTwoColumns();
  //#region [FRBS]
  const configHandler = useConfigCollection();
  const userProfile = useUserProfile();

  const hierarchyListHandler = useCollection(FPATH.CONFIGS, {
    where: [["_teamId", "!=", teamId]],
  });

  const pagesHandler = useCollection<MediaPageSchema>(FPATH.PAGES, {
    where: [
      // NOTE Based on @Jeremy's request to show all hierarchies so copy
      //      and paste would be easy for all items on pages.
      // ["_teamId", "!=", teamId],
      ["collaboration", "==", true],
    ],
  });

  const [pages, setPages] = React.useState<MediaPageSchema[]>([]);

  React.useEffect(() => {
    pagesHandler.data && setPages(pagesHandler.data);
  }, [pagesHandler.data]);

  const [hierarchyListData, setHierarchyListData] = React.useState<
    iHierarchyList[]
  >([]);

  const [hierarchyData, setHierarchyData] = React.useState<iHierarchyList[]>(
    []
  );

  React.useEffect(() => {
    setIsCollabModeOn(true);
    const tempList: iHierarchyList[] = [];

    if (!hierarchyListHandler.error && hierarchyListHandler.data) {
      if (hierarchyListHandler.data.length > 0) {
        hierarchyListHandler.data.forEach((hierarchy) => {
          if (tempList.find((h) => h.teamId === hierarchy._teamId)) {
            return;
          }

          const _hierarchyData: iHierarchyList = {} as iHierarchyList;
          _hierarchyData.teamId = hierarchy._teamId;
          const teamInfo = configHandler._getAppInfoFromTeamId(
            hierarchy._teamId
          );

          if (!Array.isArray(teamInfo)) {
            _hierarchyData.teamName = teamInfo.appTitle;
            _hierarchyData.teamLogo = teamInfo.appLogo;
          }
          if (
            pages.length > 0 &&
            pages.find((p) => p._teamId === _hierarchyData.teamId)
          ) {
            tempList.push(_hierarchyData);
          }
        });
      }

      if (tempList.length > 0) {
        setHierarchyListData(tempList);
        setHierarchyData(tempList);
      }
    }

    // return function turnOffCollaboration() {
    //   console.log(`👥 Turning off Collaboration Mode`);
    //   setIsCollabModeOn(false);
    // };
  }, [hierarchyListHandler.data, pages]);

  const [listOffset, setListOffset] = React.useState(50);
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion
  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    let newData;
    setQuery(value);
    if (value) {
      newData = hierarchyListData.filter((hierarchy) => {
        // console.log("member: ", member.personali);
        return hierarchy.teamName?.toLowerCase().includes(value.toLowerCase());
      });
      // console.log("valuee: ", newData);
    } else {
      newData = hierarchyListData;
    }
    setHierarchyData([...newData]);
  }
  //#endregion

  let sortedHierarchyList = R.sortWith<iHierarchyList>(
    [
      /** sorting `appTitle` in `asc` */
      R.ascend(R.prop("teamName")),
    ],
    hierarchyData
  );
  if (!userProfile._isDeveloper()) {
    sortedHierarchyList = sortedHierarchyList.filter(
      (l) =>
        !l.teamId.includes("app-takeoff") &&
        !l.teamId.includes("app-takeoff-team") &&
        !l.teamId.includes("clone-apptakeoff")
    );
  }

  const ViewHeader = () => {
    return (
      <>
        <ListHeader
          titleTx={
            (query ? tr("Search result") : tr("List of Hierarchies")) +
            " • " +
            (userProfile._isDeveloper()
              ? sortedHierarchyList?.length
              : sortedHierarchyList?.filter(
                  (l) =>
                    !l.teamId.includes("app-takeoff") &&
                    !l.teamId.includes("app-takeoff-team") &&
                    !l.teamId.includes("clone-apptakeoff")
                ).length)
          }
          actions={[]}
        />
        <Kitten.Input
          value={query}
          autoFocus
          placeholder={tr("Search hierarchy name")}
          onChangeText={(value) => {
            filterHandler(value);
          }}
        />
        <Kitten.Divider_________ />
      </>
    );
  };
  return React.useMemo(() => {
    try {
      if (hierarchyListHandler.error) {
        console.warn(
          "error in Collaboration List: ",
          hierarchyListHandler.error
        );
        return (
          <Txt.Indicator>
            {tr("Error getting Collaboration List")}
          </Txt.Indicator>
        );
      }
      if (!hierarchyListHandler.data)
        return <Skeleton.Screen.Feed length={30} />;

      return sortedHierarchyList.length === 0 ? (
        <O.CollaborationLoadScreen />
      ) : (
        <FlatList
          ListHeaderComponent={() => <ViewHeader />}
          contentContainerStyle={column1ContainerStyle}
          stickyHeaderIndices={[0]}
          data={sortedHierarchyList.slice(0, listOffset)}
          renderItem={({ item: hierarchyItem, index }) => {
            return (
              <CollaborationHierarchyListItem
                index={index}
                inputData={hierarchyItem}
                editModeEnabled={false}
                onPress={() => {
                  Navigation.navigate<iHierarchyFeedData>("hierarchy-home", {
                    teamName: hierarchyItem.teamName,
                    hierarchyTeamId: hierarchyItem.teamId,
                  });
                }}
              />
            );
            // </Animatable.View>
          }}
          ItemSeparatorComponent={() => <View style={{ margin: spacing(3) }} />}
          ListFooterComponent={() => (
            <Buttoon
              appearance={"ghost"}
              progress
              onPress={(xong) => {
                setTimeout(() => {
                  setListOffset(listOffset + 5);
                  //@ts-ignore
                  xong && xong();
                }, 600);
              }}
              disabled={listOffset >= sortedHierarchyList.length}
            >
              {listOffset < sortedHierarchyList.length ? "Load More" : ""}
            </Buttoon>
          )}
          ListEmptyComponent={() => (
            <Txt.Indicator marginV>
              {hierarchyListHandler.loading
                ? tr("Loading...")
                : tr("No Hierarchy found")}
            </Txt.Indicator>
          )}
          keyExtractor={(item) => item.teamId}
        />
      );
    } catch (error) {
      throw Error(`::TXAGHcas1441Ib21lRmVlZA==::${error}`);
    }
  }, [hierarchyListData, listOffset, C, userProfile, pages, hierarchyData]);
}
