import { useCollection } from "@nandorojo/swr-firestore";
import { useFocusEffect } from "@react-navigation/native";
import { Txt } from "components";
import { Buttoon, Kitten, ListHeader, Skeleton } from "components/atoms";
import { PagesHomeItem } from "components/molecules";
import { useTwoColumns } from "components/templates";
import {
  FPATH,
  FRBS_ROLE,
  MediaPageSchema,
  useAppContext,
  useConfigDocument,
  usePagesCollection
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useCollabContext } from "engines/providers/collaboration-provider";
import React from "react";
import { FlatList, View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { spacing, tr } from "utils";

/**
 * ### List of media resources for selected hierarchy in Collaboration list
 *  ----
 *  @version 21.11.08
 *  @author  Saurabh_M
 *
 **/
export function HierarchyHomeFeed(props: iHierarchyFeedData) {
  const { teamName, hierarchyTeamId } = props;

  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  const { setIsCollabModeOn } = useCollabContext();

  //#region [section2] for collaboration mode on
  React.useEffect(() => {
    setIsCollabModeOn(true);
  }, []);
  //#endregion

  //#region [FRBS]
  const userProfile = useUserProfile();
  const configHandler = useConfigDocument("variables-" + hierarchyTeamId);
  // const ourPagesHandler = usePagesCollection({ where: ["_teamId", "==", hierarchyTeamId] });
  const pagesHandler = usePagesCollection({ where: ["_teamId", "==", hierarchyTeamId] });

  const [pages, setPages] = React.useState<MediaPageSchema[]>([]);

  React.useEffect(
    function setupData() {
      // console.log(`Data test hierarchyTeamId: [${hierarchyTeamId}]`);
      if (pagesHandler.data && configHandler.data) {
        //NOTE Logic to show all shared pages vs selective pages for collaboration
        const allPagesShared = configHandler.data.allPagesShared;
        if (userProfile._isRolesContain([FRBS_ROLE.DEVELOPER]))
          setPages(pagesHandler.data)
        else
          setPages(
            allPagesShared
              ? pagesHandler.data
              : pagesHandler.data.filter(
                (p) => p.collaboration === true || p._teamId === teamId
              )
          );
      }
    },
    [pagesHandler.data, configHandler.data]
  );
  const [listOffset, setListOffset] = React.useState(30);
  //#endregion
  const { column1ContainerStyle } = useTwoColumns();
  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    setQuery(value);
    const newData = pagesHandler.data?.filter((member) => {
      // console.log("member: ", member.personali);
      return member.name?.toLowerCase().includes(value.toLowerCase());
    });
    // console.log("valuee: ", newData);
    setPages([...newData]);
  }
  //#endregion

  const sortedPages = pages
    .sort((p1, p2) => p1.position - p2.position)
    .filter(
      (page) =>
        (page.mediaItem?.visible == true ||
          page._id === configHandler.data?.startHerePage) &&
        page?.mediaItem?.team !== true
    )
    .concat(pages.filter((pg) => pg?.mediaItem?.team === true));
  const ViewHeader = () => {
    return (
      <>
        <ListHeader
          titleTx={
            (query
              ? tr("Search result")
              : tr(`${teamName}'s Media Resources`)) +
            " • " +
            sortedPages?.length
          }
          actions={[]}
        />
        <Kitten.Input
          value={query}
          placeholder={tr("Search page's name")}
          onChangeText={(value) => {
            filterHandler(value);
          }}
        />
        <Kitten.Divider_________ />
      </>
    );
  };
  return React.useMemo(() => {
    try {
      if (pagesHandler.error) {
        console.warn("error in MembersFeed: ", pagesHandler.error);
        return (
          <Txt.Indicator>{tr("Error getting media resources")}</Txt.Indicator>
        );
      }
      if (!pagesHandler.data) return <Skeleton.Screen.Feed length={30} />;

      return (
        <FlatList
          ListHeaderComponent={() => <ViewHeader />}
          contentContainerStyle={column1ContainerStyle}
          stickyHeaderIndices={[0]}
          data={sortedPages}
          renderItem={({ item: page, index }) => {
            return (
              <PagesHomeItem
                pagesHandler={pagesHandler}
                index={index}
                cpid={page._id}
                addEntryClick={() => { }}
                editModeEnabled={false}
                onEditCallback={() => { }}
                collabMode
                pageTitle={
                  (!page.name.toLowerCase().includes("baseshop") ||
                    !page.name.toLowerCase().includes("base shop")) &&
                  page.name.concat(page?.mediaItem?.team ? "(BASE SHOP)" : "")
                }
                onPress={() => {
                  // console.log("🚀 Item method in Hierarchyhome feed pressed");
                  Navigation.navigate<dPageContentsScreenParam>(
                    "Collab Contents",
                    {
                      cpid: page?._id,
                      title: page?.name || tr("Untitled"),
                    }
                  );
                }}
              />
            );
          }}
          ItemSeparatorComponent={() => <View style={{ margin: spacing(3) }} />}
          ListFooterComponent={() => (
            <Buttoon
              appearance={"ghost"}
              progress
              onPress={(xong) => {
                setTimeout(() => {
                  setListOffset(listOffset + 5);
                  //@ts-ignore
                  xong && xong();
                }, 600);
              }}
              disabled={listOffset >= sortedPages.length}
            >
              {listOffset < sortedPages.length ? "Load More" : ""}
            </Buttoon>
          )}
          ListEmptyComponent={() => (
            <Txt.Indicator marginV>
              {pagesHandler.loading
                ? tr("Loading...")
                : tr(`${teamName} has not shared any collaboration content.`)}
            </Txt.Indicator>
          )}
          keyExtractor={(item) => item._id}
        />
      );
    } catch (error) {
      throw Error(`::SGllcmFyY2h5SG9tZUZlZWQudHN4::${error}`);
    }
  }, [pages, listOffset, C, userProfile]);
}

export interface iHierarchyFeedData {
  hierarchyTeamId: string;
  teamName: string;
}

interface P {
  route?: { params?: iHierarchyFeedData }; //* unmask if we pass params thru rnav
}
