async function sendErrorEmail(data) {
  let projectId = "primr-exp";

  return fetch(
    `https://us-central1-${projectId}.cloudfunctions.net/api/services/error/send-email`,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        // Authorization: "Bearer " + token,
      },
    }
  );
}

export default sendErrorEmail;
