import { Buttoon, Kitten, sstyled, Txt } from "components/atoms";
import { fn, useAppContext, useUserProfile } from "engines";
import React from "react";
import { Image, View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { scale, spacing, tr } from "utils";
/**
 * ###
 *  - REUSABLE COMPONENT
 *  - This component can be used when ever you want to request for user media permission
 *  ----
 *  @example
 * const { status } = await Permissions.getAsync(
      Permissions.MEDIA_LIBRARY || Permissions.CAMERA
    );
    if (status !== "granted") {
      Platform.OS == "android"
        ? Navigation.navigate("photopermission")
        : Navigation.overlayPortal.open({
            headerTitle: tr("Photos Permission"),
            render: <NotificationsPermissionPage setNotificationPermOn={setNotificationPermOn} />,
          });
    }
 *  <NotificationsPermissionPage setNotificationPermOn={setNotificationPermOn} />
 *  setNotificationPermOn returns either true|false
 *  ----
 *  @version 22.04.26
 *  -  *Brief changelog*
 *  @author  NL
 *
 **/

export function NotificationsPermissionPage(props: P) {
  //#region [section]
  const { teamId, C } = useAppContext();
  // const configHandler = useConfigCollection();
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  // let appConfig = configHandler._getConfigFromSubDomain(
  //   // IS_WEB ? null : app_team_id
  //   IS_WEB ? null : teamId
  // );
  const { setNotificationPermOn } = props;
  // const vHandler = useConfigDocument("variables-app-takeoff")
  //#endregion

  //#region [functions]
  /**
   * ###
   *  - function is used in requesting for media permission
   *  ----
   *  @example
   *  Copy and paste function is the best...
   *  ----
   *  @version 22.04.26
   *  -  *Brief changelog*
   *  @author  NL
   *
   **/
  function requestNotificationPerm() {
    fn.permissions
      .requestNotificationPermissions()
      .then((res) => {
        if (res.isNotifcationAllowed) {
          fn.notifications.getFirebaseMessagingToken().then((token) => {
            if (token) {
              console.log("token FMC ", token);
              userProfile._updateFCMTokens(token);
            }
          });
          Navigation.navigate("Home");
          fn.notifications.subscribeToGlobalTopic(teamId);
          setNotificationPermOn && setNotificationPermOn(true);
        } else {
          Navigation.navigate("Home");
          setNotificationPermOn && setNotificationPermOn(false);
        }
      })
      .catch((e) => {
        console.log("error on notifications ", e.message);
        Navigation.navigate("Home");
        // setNotificationPermOn && setNotificationPermOn(false)
      });
  }
  //#endregion

  return (
    <SS.CtnrSlide style={{ backgroundColor: C.background }}>
      <Kitten.Divider style={{ margin: spacing(5) }} />
      <SS.Title>{tr("Push Notifications")}</SS.Title>

      <Image
        source={{
          uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1650966754/NotificationsIcons/Permission_Sketches_-_Page_1_mxbh1k.png",
        }}
        style={{
          width: 145,
          height: 145,
          alignSelf: "center",
          margin: scale(4),
        }}
      />
      <Kitten.Divider style={{ margin: spacing(3) }} />

      <SS.Description>
        {tr(
          "Choose Enable in order to receive updates and stay connected with your team!"
        )}
      </SS.Description>
      <View style={{ marginVertical: spacing(5) }}>
        <Buttoon
          onPress={() => {
            requestNotificationPerm();
          }}
          status="control"
        >
          {tr("Enable")}
        </Buttoon>

        <Buttoon
          appearance={"ghost"}
          onPress={() => {
            Navigation.goBack();
            // setNotificationPermOn(false);
          }}
        >
          {tr("No thanks")}
        </Buttoon>
      </View>
    </SS.CtnrSlide>
  );
}

const SS = {
  CtnrSlide: sstyled(View)((p) => ({
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: spacing(5),
  })),
  Title: sstyled(Txt.H4)((p) => ({
    // color: C.text01,
    textAlign: "center",
  })),
  Description: sstyled(Txt.S1)((p) => ({
    // color: C.text01,
    textAlign: "center",
  })),
};

interface P {
  setNotificationPermOn?: (value: boolean) => void;
}
