import firebase from "firebase"
import { KeyboardAccessoryView } from "react-native-keyboard-accessory";
//#region [import]
import {
    Avt,
    Buttoon,
    Inpuut,
    Kitten,
    Poppy,
    Rush,
    sstyled,
    Toasty,
    Txt,
} from "components/atoms";
import {
    CommentSchema,
    dComment,
    dUserAction,
    dUserComment,
    fn,
    FRBS_ROLE,
    removeActionComment,
    useAction,
    useAppContext,
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
// import { usePosstDocument } from "engines/backends/__quick-handler/QuickHandler";
import React from "react";
import {
    FlatList,
    FlatListProps,
    Keyboard,
    useWindowDimensions,
    View
} from "react-native";
import { GiftedChatProps } from "react-native-gifted-chat";
import { dColors, IS_ANDROID, spacing, themeLight, tr } from "utils";
import { CommentInput } from "components/organisms/home/CommentInput";

//#endregion

/**
 * ### CommentsCenter for posst
 *  - Show Comments as FlatList
 *  - U'll be able to write a comment, delete a comment
 *  ----
 *  @version 21.06.01
 *  -  *Remove GiftedChat*
 *  @author  K
 *
 **/
export function ActionCommentsScreen(props: { action: dUserAction }) {
    const { C, frbsUser } = useAppContext();
    const { height } = useWindowDimensions();

    const refCommentList = React.useRef<FlatList>();

    //#region [FRBS]
    const userProfile = useUserProfile();
    const actionHandler = useAction(props?.action?.uid, props?.action?._id)
    //#endregion

    const [action, setAction] = React.useState<dUserAction>(props.action);
    const [comments, setComments] = React.useState<dUserComment[]>();

    React.useEffect(function updateAction() {
        setAction(actionHandler.data)
        setComments(actionHandler.data?.comments?.reverse())
    }, [actionHandler.data]);

    // if its a chat feature
    // React.useEffect(function CommentListLayout() {
    //     setTimeout(() => {
    //         refCommentList.current?.scrollToEnd();
    //     }, 1000);
    // }, []);

    const [message, setMessage] = React.useState<string>("");
    const onSend = () => {
        const newComment: dUserComment = {
            body: message,
            createdAt: firebase.firestore.Timestamp.now(),
            displayName: userProfile.data?.personali?.displayName,
            profileImage: userProfile.data?.personali?.photoURL,
            uid: frbsUser?.uid
        }
        actionHandler.addComment(newComment)

        setComments([newComment].concat(comments || []))
        setMessage("");
        setTimeout(() => {
            // scroll to end for chat
            // refCommentList.current?.scrollToEnd();
            refCommentList.current?.scrollToOffset({ animated: true, offset: 0 });
        }, 1000);
        Keyboard.dismiss();
    };

    if (!actionHandler.data)
        return <Rush.Bar indeterminate style={{ width: "100%" }} />;

    return (
        <>
            <A.Ctnr disabled>
                <Txt.S2 style={{ textAlign: "center", marginBottom: spacing(3) }} numberOfLines={2}>
                    {actionHandler.data?.description
                        ?.replace(/\\n/g, "")
                        .replace(fn.js.extractLinkFromBody(actionHandler.data?.description), "")}
                </Txt.S2>
                {/* <Txt style={{ textAlign: "center" }}>
                    {actionHandler.data?.displayName}
                </Txt> */}
                {IS_ANDROID &&
                    userProfile.data?.personali?.email?.startsWith("demo@") && (
                        <Txt style={{ textAlign: "center", fontWeight: "bold" }}>
                            To report a comment, just tap on it!
                        </Txt>
                    )}

                <A.CommentList
                    ref={refCommentList}
                    height={height}
                    data={comments || []}
                    ListEmptyComponent={() => (
                        <Txt.Indicator marginV>
                            {actionHandler.loading ? tr("Loading...") : tr("No comment")}
                        </Txt.Indicator>
                    )}
                    renderItem={({ item, index }) => {
                        const comment = item as dUserComment
                        return (
                            <A.BubblyCommentItem
                                onLongPress={() => {
                                    if (userProfile._isRolesContain([FRBS_ROLE.ADMIN]) || comment.uid === frbsUser?.uid) {
                                        Poppy("Delete Comment", "Delete this comment?", { text: "Cancel" }, {
                                            text: "Delete", onPress: () => {
                                                setComments(comments?.filter(c => !(c.uid === comment.uid && c.createdAt === comment.createdAt && c.body === comment.body)))
                                                actionHandler.deleteComment(comment)
                                                Toasty.show("Comment deleted")
                                            }
                                        })
                                    } else {
                                        Poppy(
                                            "Report Comment", //TODO should be save function
                                            "Report this comment?",
                                            {
                                                text: "Cancel",
                                                onPress: () => { },
                                            },
                                            {
                                                text: "Yes",
                                                onPress: () => {
                                                    if (userProfile._isRolesContain([FRBS_ROLE.ADMIN]))
                                                        removeActionComment(action, comment)
                                                    Toasty.show("Comment reported.", { type: "normal" });
                                                },
                                            }
                                        );
                                    }
                                }}
                                style={{
                                    borderRadius: 11,
                                    alignSelf:
                                        comment?.uid == userProfile.data?._id
                                            ? "flex-end"
                                            : "flex-start",
                                    backgroundColor: themeLight.accent,
                                }}
                                accessoryLeft={() => (
                                    <View style={{ justifyContent: "flex-start", flex: 1 }}>
                                        <Avt name={comment?.displayName} source={{ uri: comment?.profileImage }} size={33} />
                                    </View>
                                )}
                                title={() => <Txt.P1 style={{ fontWeight: "bold" }}>{comment.uid === frbsUser?.uid ? "You" : comment.displayName}</Txt.P1>}
                                description={() => <View>
                                    <Txt.P1>{comment?.body || "..."}</Txt.P1>
                                    <Kitten.ViewH style={{ justifyContent: "flex-end" }}>
                                        <Txt.Helper>{"\t" + fn.calendar.socialFriendlyTime(comment.createdAt.toDate())}</Txt.Helper>
                                    </Kitten.ViewH>
                                </View>}
                            />
                        )
                    }}
                />
            </A.Ctnr>
            <KeyboardAccessoryView
                alwaysVisible={true}
                androidAdjustResize
                style={{ borderRadius: 7, backgroundColor: "transparent", justifyContent: "flex-end" }}
                inSafeAreaView={true}
                heightProperty="minHeight"

            >
                {({ isKeyboardVisible }) => (
                    <CommentInput
                        placeholder={tr("Write your comment")}
                        placeholderTextColor={C["text-hint-color"]}
                        value={message}
                        onChangeText={setMessage}
                        onSavePress={onSend}
                    />
                )}
            </KeyboardAccessoryView>
        </>
    );
}

const A = {
    Ctnr: sstyled(View)({ backgroundColor: "transparent" }),
    CommentList: sstyled<FlatListProps<CommentSchema>, { height: number }>(
        FlatList
    )((p) => ({
        height: p.height * 0.6,
    })),
    BubblyCommentItem: sstyled(Kitten.ListItemv2)({
        // backgroundColor: "surface",
        marginBottom: spacing(2),
        borderRadius: 4,
    }),
    Config: (C: dColors) => ({
        CONVO: {
            renderAvatarOnTop: true,
            renderUsernameOnMessage: true,
            alwaysShowSend: true,
            showUserAvatar: true,
            alignTop: true,
            placeholder: "Comment",
            // messagesContainerStyle: { backgroundColor: C.background },
            textInputStyle: {
                paddingLeft: spacing(3),
                backgroundColor: C.background,
                color: C.text,
                borderTopWidth: 0,
            },
            containerStyle: {
                backgroundColor: C.background,
                paddingRight: spacing(3),
                borderRadius: 10,
            },
            imageStyle: { backgroundColor: C.dim },
        } as GiftedChatProps,
    }),
};
