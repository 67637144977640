import { IconPrimr } from "assets";
import { Kitten, sstyled, Txt } from "components";
import { Rush, Toasty } from "components/atoms";
import {
  dConfigKeys,
  MediaPageItemSchema,
  ServiceType,
  typeOfItem,
  useAppContext
} from "engines";
import DropboxAPI from "engines/backends/apis/DropboxAPI";
import { useConfigCollection, useConfigDocument } from "engines/firebase/handler/config-handler";
import { Audio, AVPlaybackStatus } from "expo-av";
import React from "react";
import { ImageBackground, View } from "react-native";
import { scale, spacing } from "utils";

export const AudioItem = React.memo(_AudioItem);
export function _AudioItem({
  item,
  serviceType,
  complete,
  onComplete,
  itemString,
  onFoundSuccessfulToken,
}: dAudioItem) {
  const { C, teamId } = useAppContext();
  const [sound, setSound] = React.useState<Audio.Sound>(new Audio.Sound());
  const [isSoundLoaded, setIsSoundLoaded] = React.useState(true);
  const [soundStatus, setSoundStatus] = React.useState<any>({
    isLoaded: false,
    isPlaying: false,
    currentDuration: 0,
  });
  const [timeInfo, setTimeInfo] = React.useState<any>({
    currentTime: 0,
    totalTime: 0,
    didJustFinish: false,
  });
  const configKeysHandler = useConfigDocument(`keys-${teamId}`)
  const configKeys = configKeysHandler.data as dConfigKeys
  const dropboxTokens = item?.dropboxToken ? [item.dropboxToken] : (configKeys?.dropbox || [])
  // const configHandler = useConfigCollection();
  // const dropboxTokens = item?.dropboxToken
  //   ? [item.dropboxToken]
  //   : configHandler.getEveryAppsDropboxTokens();
  /**
   * In order to not disturb the other states and components by an immediate change in the parent component,
   * causing flickering, we secretly update the completion state in here for the time being.
   */
  const [overrideComplete, setOverrideComplete] =
    React.useState<boolean>(false);
  const _complete = complete || overrideComplete;

  Audio.setAudioModeAsync({
    interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
    playsInSilentModeIOS: true,
    shouldDuckAndroid: true,
    interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
    playThroughEarpieceAndroid: true,
  });

  const _onPlaybackStatusUpdate = async (playbackStatus: AVPlaybackStatus) => {
    //console.log("Playback Status ", playbackStatus);
    const progress =
      playbackStatus.positionMillis / playbackStatus.durationMillis;
    // console.log("[complete] audio progress = " + progress);
    if (!_complete && progress >= 0.95) {
      console.log("[complete] completing audio");
      setOverrideComplete(true);
      onComplete && onComplete(itemString);
    }
    if (playbackStatus.positionMillis === playbackStatus.durationMillis) {
      setTimeInfo({
        ...timeInfo,
        currentTime: 0,
        didJustFinish:
          playbackStatus.positionMillis === playbackStatus.durationMillis
            ? true
            : false,
      });
      setSoundStatus({ isLoaded: false, isPlaying: false, currentDuration: 0 });
    } else {
      setTimeInfo({
        ...timeInfo,
        currentTime: playbackStatus.positionMillis,
        totalTime: playbackStatus.durationMillis,
      });
    }
    //console.log("In callback and status is ", timeInfo);
  };

  async function playSound() {
    console.log("Loading/Playing Sound");
    setIsSoundLoaded(false);

    const itemType = typeOfItem(item);
    let link = item?.media;

    if (link.includes(".wmv")) {
      Toasty.show(
        "This audio uses an old format, if it does not play immediately, try again in about 5-10min.",
        { type: "warning", duration: 6650 }
      );
      link = link.replace(".wmv", ".mp4");
    }

    /**
     * Dropbox
     */
    if (itemType.serviceType === ServiceType.DROPBOX) {
      const response = await DropboxAPI.getStreamingURI(
        item.media,
        dropboxTokens
      );
      link = response?.uri;
      if (!link) return;
      const usedToken = response?.usedToken;
      if (usedToken) {
        onFoundSuccessfulToken && onFoundSuccessfulToken(usedToken);
      }
    }

    //if (soundStatus.isPlaying) setSound(null);
    const { sound } = await Audio.Sound.createAsync({ uri: link });

    //NOTE: Set the Sound object and start playing from the currentDuration.
    //      This method can be used as play and unpause method.
    setSound(sound);
    await sound.playFromPositionAsync(soundStatus.currentDuration);

    const status = await sound.getStatusAsync();
    setSoundStatus({
      isLoaded: status.isLoaded,
      isPlaying: true,
      currentDuration: status?.positionMillis,
    });
    setIsSoundLoaded(true);
    //NOTE: Set a callback method to update the audio seekbar logic
    sound.setOnPlaybackStatusUpdate(_onPlaybackStatusUpdate);
    //sound.setIsLoopingAsync(true);
  }

  async function pauseSound() {
    console.log("Pausing Sound");
    const status = await sound.pauseAsync();
    //console.log("Paused status is ", status);
    setSoundStatus({
      ...soundStatus,
      isPlaying: false,
      currentDuration: status?.positionMillis,
    });
  }

  async function skipForward() {
    const status = await sound.getStatusAsync();

    const newStatus = await sound.setPositionAsync(
      status?.positionMillis + 10000
    );

    //console.log("New Status ", newStatus);

    setSoundStatus({
      ...soundStatus,
      isPlaying:
        newStatus.positionMillis === newStatus.durationMillis
          ? false
          : soundStatus.isPlaying,
      currentDuration: newStatus?.positionMillis,
    });
  }

  async function skipBackward() {
    const status = await sound.getStatusAsync();
    const newStatus = await sound.setPositionAsync(
      status?.positionMillis - 10000
    );
    setSoundStatus({
      ...soundStatus,
      isPlaying: soundStatus.isPlaying,
      currentDuration: newStatus?.positionMillis,
    });
  }

  //Rush.Bar

  React.useEffect(() => {
    return sound
      ? () => {
        if (!soundStatus.isPlaying) {
          console.log("Unloading Sound");
          sound.unloadAsync();
        }
      }
      : undefined;
  }, [sound]);

  const totalTime = timeInfo?.totalTime && new Date(timeInfo.totalTime);
  const totalTimeString = totalTime && totalTime.toISOString().substr(11, 8);

  const currentTime = timeInfo?.currentTime && new Date(timeInfo.currentTime);
  const currentTimeString =
    currentTime && currentTime.toISOString().substr(11, 8);

  const completeColor = C.primary;

  return (
    <A.Ctnr
      source={{
        uri: soundStatus.isPlaying
          ? "https://res.cloudinary.com/https-apptakeoff-com/image/upload/q_auto:low/v1622731805/apptakeoff/icons/rocket_vdr9ge.gif"
          : "",
        //uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/q_auto:low/v1625500340/apptakeoff/icons/Waveform_ssyukp.png",
      }}
      blurRadius={81}
      resizeMode="cover"
      style={_complete && { borderWidth: 2, borderColor: completeColor }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Kitten.ViewH>
          <IconPrimr
            preset={"safe"}
            name={"sound"}
            size={scale(18)}
            color={_complete ? completeColor : C.text}
          />
          <Txt.H6
            style={_complete && { color: completeColor }}
          >{`${item.title}`}</Txt.H6>
        </Kitten.ViewH>
        {/* <IconPrimr
          name={"check_circle"}
          size={scale(22)}
          color={_complete ? completeColor : C.dim}
        /> */}
      </View>
      <A.CtnrPlaybackControl>
        <IconPrimr
          preset="circular"
          // containerStyle={styles.icon}
          size={scale(18)}
          name="skipbackward"
          color={C.text}
          onPress={skipBackward}
          disabled={!soundStatus.isLoaded || !isSoundLoaded}
        />
        {isSoundLoaded ? (
          <IconPrimr
            preset="circular"
            name={!soundStatus.isPlaying ? "play" : "pause"}
            color={C.text}
            onPress={() => {
              !soundStatus.isPlaying ? playSound() : pauseSound();
              //console.log("soundStatus is ", soundStatus);
            }}
          />
        ) : (
          <Kitten.Spinner size={"large"} status={"control"} />
        )}

        <IconPrimr
          preset="circular"
          // containerStyle={styles.icon}
          size={scale(18)}
          name="skipforward"
          color={C.text}
          onPress={skipForward}
          disabled={!soundStatus.isLoaded || !isSoundLoaded}
        />
      </A.CtnrPlaybackControl>
      {totalTimeString ? (
        <Txt.Helper>{`${currentTimeString} / ${totalTimeString}`}</Txt.Helper>
      ) : (
        <Txt.Helper>{`00:00:00`}</Txt.Helper>
      )}
    </A.Ctnr>
  );
}

//todo WIP  This is for item with type == AUDIO

interface dAudioItem {
  item: MediaPageItemSchema;
  serviceType?: ServiceType;
  complete?: boolean;
  /**
   * Will get passed with the onComplete() when called so the database knows what item to complete
   * - originally generated by levels-handler.tsx
   */
  itemString?: string;
  onComplete?: (itemString: string) => void;
  onFoundSuccessfulToken?: (token: string) => void;
}

const A = {
  Ctnr: sstyled(ImageBackground)({
    width: "100%",
    flex: 1,
    // height: [8, 9, 10],
    borderRadius: 10,
    padding: spacing(3),
    overflow: "hidden",
    backgroundColor: "surface",
  }),
  CtnrVideoItem: sstyled(Kitten.Card)({ alignItems: "center" }),
  CtnrPlaybackControl: sstyled(View)({
    paddingVertical: 3,
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
  }),
  PlaybackLoader: sstyled(Rush.Bar)({
    width: "20%",
    alignSelf: "center",
  }),
};
