export const isPermissionAllowed = (
  givenStatus,
  keyword: "contact" | "notification" | "storage" | string
) => {
  switch (givenStatus) {
    case "denied":
      console.warn(
        "The permission has not been requested / is denied but requestable"
      );

      return false;
      break;
    case true:
      console.info(`Permission for ${keyword} is granted`);
      const firstLetter = keyword.substring(0, 1);

      return true;
      break;
    case "undetermined":
      console.warn(
        "This feature is not available (on this device / in this context)"
      );

      return false;
      break;
    default:
      return false;
      break;
  }
};
