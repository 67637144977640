import { Buttoon } from "components/atoms";
import { ShareAccountDialog } from "components/organisms/Teammates/ShareAccountDialog";
import { MediaPageItemSchema, UserSchema } from "engines";
import { useNavigator } from "screens";
import { tr } from "utils";

export function PickTrainerButton(p: { options: MediaPageItemSchema["pickTrainer"], onComplete: (selectedUser: UserSchema) => void, selectedTrainerUids?: string[] }) {
    const { onComplete, selectedTrainerUids } = p
    const { required } = p?.options
    const Navigation = useNavigator()

    const alreadySelected = selectedTrainerUids && selectedTrainerUids?.length > 0 ? true : false

    return <Buttoon appearance="filled" status={alreadySelected ? "primary" : "control"}
        icon={{ name: alreadySelected ? "check" : "admin" }}
        onPress={() => {
            Navigation.overlayPortal.open({
                headerTitle: "Select Trainer",
                render: <ShareAccountDialog onSelectUser={onComplete} />
            })
        }}>{tr(`Select Your Field Trainer${required ? "*" : ""}`)}</Buttoon>
}