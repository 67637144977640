import { KeyboardAccessoryView } from "react-native-keyboard-accessory";
import * as SMS from "expo-sms";
import { IconPrimr } from "assets";
import { Buttoon, Inpuut, Kitten, Poppy, Toasty, Txt } from "components";
import { Avt, ListHeader } from "components/atoms";
import {
  ACTION_TYPE,
  fn,
  FPATH,
  recordUserAction,
  useContactDocument,
  useMemberDocument,
  useNotificationCollection,
  useUserProfile,
} from "engines";
import {
  CONTACT_SECTION,
  saveBMLContact,
} from "engines/backends/auth/AuthHandler";
import {
  ContactGroupSchemaa,
  ContactSchemaa,
  dContactAppointment,
  dContactNote,
  dVideoWatch,
} from "engines/backends/schemas/bml";
import { completeFollowUpOnContact, useContactGroupsCollection } from "engines/firebase/handler/contact-groups-handler";
import { useAppContext } from "engines/providers";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import * as Clipboard from "expo-clipboard";
import * as Contacts from "expo-contacts";
import firebase from "firebase";
import { isString } from "lodash";
import React from "react";
import {
  Dimensions,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  TextStyle,
  View,
} from "react-native";
import { Typewriter, useNavigator } from "screens";
import { ContactNotesEdit } from "screens/bml/bml-mobile/ContactNotesEdit";
import { IS_WEB, spacing, tr } from "utils";
// import { FollowUpAction } from "./contact-details-components/FollowUpAction";
import { QuickActionContactRow } from "./contact-details-components/QuickActions";
import { ProgressBar } from "@ui-kitten/components";
import { forsureDate } from "engines/functions/calendar-functions";
import { ContactGroupSelector } from "components/organisms";
// import { ScheduleAppointmentAction } from "./contact-details-components/ScheduleAppointmentAction";
// import { TextScriptsAction } from "./contact-details-components/TextScriptsAction";

const headerSpacing = spacing(3);

export interface dContactListItemDetails {
  contact: ContactSchemaa;
  /** @description user Id accessing this contact */
  userId: string;
  /** @description id of the contact group list */
  // listId: string;
  /** @description name of the contact group list */
  listName?: string;
}
/**
 * ### Details of the BML Contact and a form to edit their current qualifications
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.05.15
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export const ContactListItemDetails = (props: dContactListItemDetails) => {
  const { userId, listName } = props;

  /** not necessary because we have what we need already but it's good to keep things updated especially for video tracking */
  const contactHandler = useContactDocument(props.contact?.ownerUid, props.contact?._cid)

  const [contact, setContact] = React.useState<ContactSchemaa>(props.contact);
  const [phoneContact, setPhoneContact] =
    React.useState<Contacts.Contact>(null);

  const { C, teamId, frbsUser } = useAppContext();
  const Navigation = useNavigator();
  const userHandler = useUserProfile();
  const contactGroupsHandler = useContactGroupsCollection(userId, {
    where: ["_id", "in", (contact || props.contact).groups || []],
  });
  const { sendNotificationToUser } = useNotificationCollection(`${teamId}`);

  const { qualificationData } = useBMLMContext();

  const [updatedQualifications, setUpdatedQualifications] = React.useState<
    string[]
  >(contact?.qualifications || []);

  const [updatedNotes, setUpdatedNotes] = React.useState<dContactNote[]>(
    contact?.notes || []
  );
  const [contactId, setContactId] = React.useState("");
  const [updateType, setUpdateType] = React.useState("");

  const { data: contactOwner } = useMemberDocument(userId);

  // #region [ANCHOR] Update Qualifications
  React.useEffect(() => {
    if (contact) {
      contact.qualifications = [];
      contact.qualifications = updatedQualifications;
      userId === userHandler?.data?._id &&
        saveBMLContact(
          contact,
          userHandler.data?._id,
          CONTACT_SECTION.QUALIFICATION
        );
    }
  }, [updatedQualifications]);
  // #endregion

  React.useEffect(
    function updatePhoneContact() {
      if (!contact || !contactId) return;
      Contacts.getContactByIdAsync(contact.id).then((_pContact) => {
        _pContact && setPhoneContact(_pContact);
      });
    },
    [contactId, contact]
  );

  // #region [ANCHOR2] Update Notes
  React.useEffect(() => {
    if (contact) {
      contact.notes = [];
      contact.notes = updatedNotes;
      saveBMLContact(contact, userId, CONTACT_SECTION.NOTES);
      updateType &&
        Toasty.show(`Successfully ${updateType} the note.`, {
          type: "success",
        });
      // setCurrentNoteText("");
      setUpdateType("");
    }
  }, [updatedNotes]);
  // #endregion

  React.useEffect(() => {
    const recordId = contact?._cid && contact?._cid?.split(":")[0]?.trim();
    setContactId(recordId);
    if (!IS_WEB && recordId) {
      Contacts.getContactByIdAsync(recordId).then((data) => {
        data && setContactId(data.id);
      });
    }
  }, []);

  React.useEffect(function updateContactFromFirestoreIfNeeded() {
    if (!contactHandler.data) return;
    setContact(contactHandler.data)
  }, [contactHandler.data]);

  async function resolvePhoneContact(resolveContact: ContactSchemaa, message: string) {
    const potentials = (await Contacts.getContactsAsync({ name: resolveContact.name })).data
    // no contact with this name already in your phone
    if (potentials.length < 1) {
      Poppy("Contact Not Found", `No one with the name \"${resolveContact.name}\" exists in your phone.\n\nWould you like to create this contact in your phone now?`, { text: "Cancel" }, {
        text: "Create", onPress: async () => {
          const cName = resolveContact.name || resolveContact.displayName
          const cNames: string[] = cName.includes(" ") ? (`${cName}`).split(" ") : [cName]
          const newContactId = await Contacts.addContactAsync({
            id: resolveContact._cid,
            name: cName,
            firstName: cNames.length > 2 ? `${cNames[0]} ${cNames[1]}` : cNames[0],
            lastName: cName.includes(" ") ? cNames[cNames.length - 1] : "",
            contactType: Contacts.ContactTypes.Person,
            birthday: resolveContact.birthday,
            //@ts-ignore fieldvalue type
            phoneNumbers: resolveContact.phoneNumbers,
            // image: resolveContact.image,
            // imageAvailable: resolveContact.imageAvailable,
            // rawImage: resolveContact.rawImage,
            //@ts-ignore fieldvalue type
            emails: resolveContact.emails,
            // addresses: resolveContact.addresses,
            // company: resolveContact.company,
            ...resolveContact
          })
          editAndSaveContact(newContactId)
        }
      })
    }
    // contact with this name is in your phone! Just need to edit it and save now
    else {
      //@ts-ignore lookupkey thing (lookupKey may not be necessary here by it is for safe measure)
      editAndSaveContact(potentials[0].id || potentials[0].lookupKey)
    }
  }

  function editAndSaveContact(contactId: string) {
    Contacts.presentFormAsync(contactId).then((_nothing) => {
      Contacts.getContactByIdAsync(contactId).then(
        (_updatedPhoneContact) => {
          setPhoneContact(_updatedPhoneContact);
          // alert(_updatedPhoneContact.name + " vs " + _updatedPhoneContact.firstName + " " + _updatedPhoneContact.lastName)
          const _updatedContact: ContactSchemaa = {
            ...contact,
            // displayName: _updatedPhoneContact.name,
            name: _updatedPhoneContact.name,
            emails: _updatedPhoneContact.emails,
            image: _updatedPhoneContact.image,
            imageAvailable: _updatedPhoneContact.imageAvailable,
            birthday: _updatedPhoneContact.birthday,
            phoneNumbers: _updatedPhoneContact.phoneNumbers,
            _phoneNumberStrings:
              _updatedPhoneContact.phoneNumbers?.map(
                (pn) => pn.digits || pn.number?.replace(/\D/g, "")
              ),
          };
          setContact(_updatedContact);
          // update firebase contact to refelect phone contact
          firebase
            .firestore()
            .doc(
              `${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}/${contact._cid}`
            )
            .update(_updatedContact);
        }
      );
    });
  }

  /**
   * @description Header for Contact's information containing
   * - Contact Profile Image
   * - Contact Name
   * - Contact Pointers
   * @param props optional
   * @author Saurabh_M
   * @returns HeaderView
   */
  const HeaderView = (props?: any) => {
    const imageSize = 84;
    // const badgeSize = IS_WEB ? 50 : 30;
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Avt
          style={{
            marginTop: spacing(3),
            marginBottom: Platform.OS === "ios" ? -5 : 0,
            width: imageSize,
            height: imageSize,
          }}
          name={
            `${(contact?.name || contact.displayName)?.replace(
              "undefined",
              ""
            )}` || ""
          }
          source={{
            uri:
              phoneContact && phoneContact.imageAvailable && phoneContact.image
                ? phoneContact?.image?.uri
                : "",
          }}
          // size="giant" //* or size={420}
          size={imageSize}
        />
        <Buttoon
          style={{
            marginHorizontal: 15,
            alignSelf: "center",
          }}
          appearance="ghost"
          disabled={contactId ? false : true}
          // icon={contactId ? { name: "pencil", right: true } : null}
          status={contactId ? "control" : "control"}
          size="giant"
          onPress={async () => {
            if (IS_WEB) {
              Toasty.show("Phone contacts cannot be viewed on Web.", {
                type: "warning",
              });
            } else {
              contactId && (await Contacts.presentFormAsync(contactId));
            }
          }}
        >
          {(contact.name || contact.displayName)
            ?.replace("undefined", "")
            ?.trim()}
        </Buttoon>
        <Buttoon
          style={{ marginTop: -spacing(4), marginBottom: spacing(2) }}
          size="small"
          appearance="ghost"
          icon={{ name: "arrow_right", right: true }}
          onPress={() => {
            if (IS_WEB) {
              Poppy(
                "Mobile only",
                "You must be on your mobile device to edit this contact."
              );
              return;
            }
            if (!contact.id) {
              resolvePhoneContact(contact, "Could not find contact in phone, you should add it to your device manually.")
              return;
            }
            Contacts.getContactByIdAsync(contact.id).then((_phoneContact) => {
              if (!_phoneContact) {
                resolvePhoneContact(contact, "You must be on a device that has access to this contact.")
                // Poppy(
                //   "Contact Not Found",
                //   "You must be on a device that has access to this contact."
                // );
              } else {
                editAndSaveContact(contact.id)
              }
            });
          }}
        >
          {Platform.OS === "ios"
            ? "Edit iPhone Contact"
            : Platform.OS === "android"
              ? "Edit Phone Contact"
              : "Edit contact"}
        </Buttoon>
        <QuickActionContactRow
          contact={contact}
          user={userHandler.data}
          onDidUpdatedContact={setContact}
        />
      </View>
    );
  };

  const handleNoteSaveBtn = (text: string) => {
    if (text) {
      const tempNote: dContactNote = {
        _id: (contact.notes ? contact.notes?.length + 1 : 1).toString(),
        createdAt: firebase.firestore.Timestamp.now(),
        isDeleted: false,
        noteText: text,
        createdBy: {
          _id: userHandler.data?._id,
          displayName: userHandler.data?.personali?.displayName,
        },
      };
      setUpdatedNotes([...updatedNotes, tempNote]);
      setUpdateType("Added");
      if (userId !== userHandler?.data?._id) {
        sendNotificationToUser(
          contactOwner,
          `${userHandler?.data?.personali?.displayName} added note to your contact`,
          `Check out your contact "${contact?.displayName}" ${listName ? `on your list \"${listName}.\"` : ""
          }`,
          userHandler?.data?.personali?.photoURL || ""
        );
      }
    }
  };

  const handleNoteEdit = (noteId: string, editedNote: string) => {
    // Find the index of the note and then replace with the new text and save it
    const noteIndex = updatedNotes.findIndex((n) => n._id === noteId);
    const newNote = updatedNotes[noteIndex];
    newNote.noteText = editedNote;
    newNote.createdBy._id = userHandler.data?._id;
    newNote.createdBy.displayName = userHandler.data?.personali?.displayName;

    // console.log("Saving contact ", contactUpdatedInfo.notes);
    setUpdatedNotes([
      ...updatedNotes.filter((n) => n._id !== newNote._id),
      newNote,
    ]);
    setUpdateType("Updated");

    if (userId !== userHandler?.data?._id) {
      // console.log(
      //   "Sending update notification to ",
      //   contactOwner?.personali?.displayName
      // );
      sendNotificationToUser(
        contactOwner,
        `${userHandler?.data?.personali?.displayName} updated a note on your contact`,
        `Check out your contact "${contact?.displayName}" ${listName ? `on your list \"${listName}.\"` : ""
        }`,
        userHandler?.data?.personali?.photoURL || ""
      );
    }
    // Navigator.goBack();
  };

  const handleUpdate = (qualificationId: string) => {
    let _newQualifications = updatedQualifications;
    if (updatedQualifications) {
      if (updatedQualifications.includes(qualificationId)) {
        // remove qualifcation
        _newQualifications = updatedQualifications.filter(
          (q) => q !== qualificationId
        );
        setUpdatedQualifications(_newQualifications);
      } else {
        // add qualification
        _newQualifications = [...updatedQualifications, qualificationId];
        setUpdatedQualifications(_newQualifications);
      }
    } else {
      // add qualification
      _newQualifications = [...updatedQualifications, qualificationId];
      setUpdatedQualifications(_newQualifications);
    }
    contact.qualifications = [];
    contact.qualifications = _newQualifications;

    try {
      // update qualification data in firestore
      firebase
        .firestore()
        .doc(
          `${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}/${contact._cid}`
        )
        .update({
          qualifications: _newQualifications,
        })
        .then(() => {
          console.log(
            "updated contact qualifications " +
            JSON.stringify(_newQualifications)
          );
        });
    } catch (err) {
      console.error(err);
      Toasty.show(err);
    }
  };

  const handleNoteDeletion = (note: dContactNote) => {
    Poppy(
      tr("Final Check"),
      tr(`Do you want to remove this note from the contact ?`),
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => {
          setUpdatedNotes(updatedNotes.filter((n) => n._id !== note._id));
          setUpdateType("Deleted");
          if (userId !== userHandler?.data?._id) {
            // console.log(
            //   "Sending deleted notification to ",
            //   contactOwner?.personali?.displayName
            // );
            sendNotificationToUser(
              contactOwner,
              `${userHandler?.data?.personali?.displayName} deleted a note from your contact`,
              `Check out your contact "${contact?.displayName}" ${listName ? `on your list \"${listName}.\"` : ""
              }`,
              userHandler?.data?.personali?.photoURL || ""
            );
          }
        },
      }
    );
  };

  /**
   * @description Content of the contact's detail containing
   * - Contact Phone info
   * - Contact email info
   * - Contact Pointer info (5,4,3,2,1,None)
   * - Contact Notes
   * @param props optional
   * @author Saurabh_M
   * @returns Content
   */
  const ContactInfo = () => {
    const handleNumberCopy = async (pn: string) => {
      Clipboard.setString(pn);
      Toasty.show(`Copied ${pn} to clipboard`, { type: "normal" });
    };
    return (
      <View>
        <ListHeader
          titleTx="Contact Info"
          style={{ marginTop: headerSpacing, paddingLeft: 0 }}
        />

        {isString(
          (contact?.phoneNumbers as Contacts.PhoneNumber[])?.length > 0
            ? (contact.phoneNumbers[0] as Contacts.PhoneNumber)?.number
            : ""
        ) ? (
          (contact?.phoneNumbers as Contacts.PhoneNumber[])?.map((pn) => {
            return pn?.number ? (
              <Kitten.ListItemv2
                // disabled
                key={pn.id}
                accessoryLeft={() => (
                  <Kitten.ViewH>
                    <Txt.Helper>
                      {pn.label ? pn.label.toUpperCase() : "Number"}
                    </Txt.Helper>
                    <Txt.Indicator
                      style={{ color: C.infoBlue }}
                      onPress={() =>
                        !pn.number.includes("No") && handleNumberCopy(pn.number)
                      }
                    >
                      {" " + pn.number}
                    </Txt.Indicator>
                  </Kitten.ViewH>
                )}
                accessoryRight={() => (
                  <Kitten.ViewH>
                    <IconPrimr
                      // disabled={userId !== user?.data?._id}
                      onPress={() => {
                        Linking.openURL(`sms:${pn.number}`);
                        // mark contact follow up as complete if needed
                        if (contact.followUpDate) {
                          Toasty.show("Completed follow up", {
                            type: "success",
                            duration: 4000,
                          });
                          completeFollowUpOnContact(
                            frbsUser?.uid,
                            contact._cid
                          );
                          setContact({ ...contact, followUpDate: null });
                        }
                      }}
                      preset={"safe"}
                      name={"sms"}
                      size={20}
                      color={"dodgerblue"}
                    />
                    <IconPrimr
                      // disabled={userId !== user?.data?._id}
                      onPress={() => {
                        if (contact.followUpDate) {
                          Toasty.show(
                            'If they pick up, be sure to tap "Follow Up Complete"',
                            { type: "normal", duration: 1000 * 60 * 15 }
                          );
                        }
                        Linking.openURL(`tel:${pn.number}`);
                      }}
                      preset={"safe"}
                      name={"phone"}
                      size={20}
                      color={"dodgerblue"}
                    />
                  </Kitten.ViewH>
                )}
              />
            ) : null;
          })
        ) : (
          <Kitten.ListItemv2
            accessoryLeft={() => <Txt.Indicator>{"Number"}</Txt.Indicator>}
            accessoryRight={() => <Txt.Indicator>{"Invalid"}</Txt.Indicator>}
            onPress={() => Linking.openURL(`tel:${contact.phoneNumbers[0]}`)}
          />
        )}

        {contact?.email || (contact?.emails as Contacts.Email[])?.length > 0 ? (
          <Kitten.ListItemv2
            accessoryLeft={() => <Txt.Indicator>{"EMAIL"}</Txt.Indicator>}
            accessoryRight={() => (
              <Txt.Indicator style={{ color: C.infoBlue }}>
                {(contact?.emails as Contacts.Email[])?.pop()?.email ||
                  contact?.email}
              </Txt.Indicator>
            )}
            // disabled={userId !== user?.data?._id}
            onPress={() => {
              Linking.openURL(
                `mailto:${contact?.emails?.length > 0
                  ? contact?.emails?.pop()?.email
                  : contact?.email
                }`
              );
              recordUserAction(userHandler.data, ACTION_TYPE.SEND_EMAIL, `${userHandler.data?.personali?.displayName} sent an email to ${contact?.name}.`, { location: contact._cid, involvedPartyName: contact.name })
              // mark contact follow up as complete if needed
              if (contact.followUpDate) {
                // Toasty.show(
                //   'Once you send the email, be sure to tap "Follow Up Complete"',
                //   { type: "normal", duration: 1000 * 60 * 15 }
                // );
                completeFollowUpOnContact(frbsUser?.uid, contact._cid);
                setContact({ ...contact, followUpDate: null });
              }
            }}
          />
        ) : (
          <Kitten.ListItemv2
            accessoryLeft={() => <Txt.Helper>{tr("No Email")}</Txt.Helper>}
            disabled
          />
        )}

        <ListHeader titleTx="Groups"
          style={{
            paddingLeft: 0,
            // alignSelf: "flex-start",
            // marginLeft: spacing(3),
            marginTop: spacing(4),
            // marginBottom: spacing(3),
          }}
        />
        <Kitten.ViewH style={{ flexWrap: "wrap", paddingHorizontal: spacing(2) }}>
          {contactGroupsHandler.data && contactGroupsHandler.data.length > 0 ?
            contactGroupsHandler.data.map(_group =>
              <Buttoon size="tiny" status="control" appearance="outline" style={{ marginHorizontal: spacing(1) }}
                icon={{ right: true, name: "arrow_right" }}
                onPress={() => {
                  Navigation.goBack()
                  Navigation.navigate("Contact Group Screen", { id: _group._id })
                }}>
                {`${_group.name}`}
              </Buttoon>) :
            <Txt.S2>No groups</Txt.S2>}
          <Buttoon size="tiny" status="info" appearance="filled" style={{ marginHorizontal: spacing(2), marginTop: spacing(3) }}
            icon={{ name: "plus" }}
            onPress={() => {
              Navigation.dialogPortal.open({
                headerTitle: "Select group",
                render: (
                  <ContactGroupSelector
                    userId={frbsUser?.uid}
                    addEntryClick={async (item) => {
                      try {
                        const selectedGroup = item as ContactGroupSchemaa
                        setContact({ ...contact, groups: ((contact.groups as string[]) || []).concat([selectedGroup._id]) })
                        Toasty.show("Contact added to group", { type: "success" })
                        await contactHandler.update({ groups: firebase.firestore.FieldValue.arrayUnion(selectedGroup._id) })
                        await firebase.firestore().doc(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACT_GROUPS}/${selectedGroup._id}`).update({
                          contacts: firebase.firestore.FieldValue.arrayUnion(contact._cid)
                        })
                        // update UI
                      } catch (err) {
                        Poppy("Failed to add group", err)
                        console.error(err)
                      }
                    }}
                  />
                ),
              });
            }}>Add group</Buttoon>
        </Kitten.ViewH>

        <ListHeader titleTx="Qualifications"
          style={{
            paddingLeft: 0,
            // alignSelf: "flex-start",
            // marginLeft: spacing(3),
            marginTop: spacing(4),
            // marginBottom: spacing(3),
          }}
        />
        <ScrollView
          style={{ width: "100%" }}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          <View
            style={{
              // flexWrap: "wrap",
              flexDirection: "row",
            }}
          >
            {qualificationData &&
              qualificationData?.map((qualification) => (
                <Buttoon
                  // disabled={userId !== user?.data?._id}
                  key={qualification._id}
                  size={"tiny"}
                  style={{
                    margin: 2.5,
                    backgroundColor: updatedQualifications?.includes(
                      qualification._id
                    )
                      ? C.primary
                      : C.dim,
                    borderColor: updatedQualifications?.includes(
                      qualification._id
                    )
                      ? C.grey600
                      : C.grey500,
                  }}
                  onPress={() => handleUpdate(qualification._id)}
                  children={() => (
                    <Txt.C2
                      style={{ fontWeight: "800", fontSize: 10, color: C.text }}
                    >
                      {qualification?.name}
                    </Txt.C2>
                  )}
                />
              ))}
          </View>
        </ScrollView>
      </View>
    );
  };

  const niceDateFormat = (date: Date): string =>
    date?.toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  const startOfTomorrow = new Date(startOfToday);
  startOfTomorrow.setDate(startOfToday.getDate() + 1);

  const followUpDate = contact.followUpDate?.toDate();
  const isFollowUpToday =
    followUpDate &&
    followUpDate?.getDate() == startOfToday.getDate() &&
    followUpDate?.getMonth() == startOfToday.getMonth() &&
    followUpDate?.getFullYear() == startOfToday?.getFullYear();
  const isFollowUpOverdue = followUpDate && followUpDate < startOfToday;
  let followUpText = followUpDate
    ? `Follow up due ${niceDateFormat(followUpDate)}`
    : contact?.lastFollowUp
      ? `Last followed up ${niceDateFormat(contact?.lastFollowUp?.toDate())}`
      : ""; // was "Nothing scheduled."
  if (isFollowUpToday) followUpText = "Needs follow up TODAY!";
  else if (isFollowUpOverdue)
    followUpText = `Overdue follow up! (${niceDateFormat(followUpDate)})`;

  const completedAppointments: dContactAppointment[] =
    contact.appointments?.filter(
      (a) => fn.calendar.forsureDate(a.event?.date) < startOfToday
    );
  const todaysAppointments = contact.appointments?.filter(
    (a) =>
      fn.calendar.forsureDate(a.event?.date) >= startOfToday &&
      fn.calendar.forsureDate(a.event?.date) < startOfTomorrow
  );
  const upcomingAppointments = contact.appointments?.filter(
    (a) => fn.calendar.forsureDate(a.event?.date) >= startOfTomorrow
  );
  const todaysAppointment =
    todaysAppointments?.length > 0 ? todaysAppointments[0] : null;
  const upcomingAppointment =
    upcomingAppointments?.length > 0 ? upcomingAppointments[0] : null;

  // const appointmentHeaderText = upcomingAppointment ? "Next Appointment" : "Appointments"
  const appointmentHeaderText = `Appointment/Follow Up`;
  let appointmentText = "";
  if (contact.appointments) {
    if (todaysAppointment)
      appointmentText += `Appointment today at ${fn.calendar.timeToAMPMString(
        fn.calendar.forsureDate(todaysAppointment?.event?.date)
      ) + "\n"
        }`;
    if (upcomingAppointment)
      appointmentText += `${todaysAppointment ? "\n" : ""
        }Next meeting ${niceDateFormat(
          fn.calendar.forsureDate(upcomingAppointment?.event?.date)
        )} at ${fn.calendar.timeToAMPMString(upcomingAppointment?.event?.date)} ${new Date()
          .toLocaleTimeString("en-us", { timeZoneName: "short" })
          .split(" ")[2]
        }`;
    else
      appointmentText +=
        completedAppointments?.length > 0
          ? `${todaysAppointment || upcomingAppointment ? "\n" : ""}${completedAppointments.length
          } completed appointments`
          : "";
    if (contact.appointments.length < 1) appointmentText = ""; // was "Nothing scheduled."
  } else {
    appointmentText = ""; // was "Nothing scheduled."
  }

  if (followUpText.length < 1 && appointmentText.length < 1)
    appointmentText = "No activity.";

  const VideoWatchStatus = (p: { videoWatch: dVideoWatch }) => {
    // const { unwatchedVideo, unfinishedButStartedVideo, watchedVideo } = p
    const { videoWatch } = p
    const watched = videoWatch?.watched === 1
    const unfinishedButStarted = videoWatch?.watched < 1 && videoWatch?.startedAt

    // date string of when the sent video was watched
    const watchedDateStr = (vw: dVideoWatch) => fn.calendar.niceDateFormat(vw?.watchedAt?.toDate())
    // date string of when the video was sent to the contact
    const sentAtStr = (vw: dVideoWatch) => fn.calendar.niceDateFormat(vw?.sentAt?.toDate())

    // format the progressMillis to display like MM:SS
    let progressMinutes: number, progressSeconds: number;
    let durationMinutes: number, durationSeconds: number;
    if (unfinishedButStarted && videoWatch?.progressMillis) {
      progressMinutes = Math.floor(videoWatch?.progressMillis % 3.6e6 / 6e4)
      progressSeconds = Math.floor(videoWatch?.progressMillis % 6e4 / 1e3)
      durationMinutes = Math.floor(videoWatch?.progressDuration % 3.6e6 / 6e4)
      durationSeconds = Math.floor(videoWatch?.progressDuration % 6e4 / 1e3)
    } else if (watched) {
      durationMinutes = Math.floor(videoWatch?.progressDuration % 3.6e6 / 6e4)
      durationSeconds = Math.floor(videoWatch?.progressDuration % 6e4 / 1e3)
      progressMinutes = durationMinutes
      progressSeconds = durationSeconds
    } else if (!watched) {
      durationMinutes = Math.floor(videoWatch?.progressDuration % 3.6e6 / 6e4)
      durationSeconds = Math.floor(videoWatch?.progressDuration % 6e4 / 1e3)
    }

    /** determines whether or not the video is currently being watched or not */
    let isCurrentlyWatching = false;
    if (unfinishedButStarted && videoWatch?.startedAt && videoWatch?.progressDuration > 0) {
      const latestPossibleEndVideoTime = forsureDate(videoWatch?.startedAt)
      latestPossibleEndVideoTime.setTime(latestPossibleEndVideoTime?.getTime() + videoWatch?.progressDuration * 2)
      isCurrentlyWatching = latestPossibleEndVideoTime?.getTime() > Date.now()
    }

    return <Kitten.Card style={{ marginTop: spacing(2) }}>
      <Txt.S2 style={{ marginBottom: spacing(3), color: isCurrentlyWatching ? C.primary : C.text }}>{
        !watched
          ? unfinishedButStarted ? `${isCurrentlyWatching ? "Currently" : "Started"} watching ${videoWatch?.title || "Video"} ${!isCurrentlyWatching ? `${fn.calendar.niceDateFormat(forsureDate(videoWatch?.startedAt))} at ${fn.calendar.timeToAMPMString(forsureDate(videoWatch?.startedAt))}` : ""}`
            : `${videoWatch?.title || "Video"} sent ${sentAtStr(videoWatch) || ""
            }`
          : `${videoWatch?.title || "Video"} watched ${watchedDateStr(videoWatch) || ""
          }`
      }
      </Txt.S2>
      <Kitten.ViewH style={{ justifyContent: "space-between", marginTop: spacing(1) }}>
        <Txt.P2 style={{ color: isCurrentlyWatching ? C.primary : C.grey500 }}>{`${progressMinutes || "0"}:${(progressSeconds < 10 ? `0${progressSeconds}` : progressSeconds) || "00"}`}</Txt.P2>
        <Txt.P2 style={{ color: isCurrentlyWatching ? C.primary : C.grey500 }}>{`${durationMinutes || "0"}:${(durationSeconds < 10 ? `0${durationSeconds}` : durationSeconds) || "00"}`}</Txt.P2>
      </Kitten.ViewH>
      <ProgressBar status={isCurrentlyWatching || (watched) ? "primary" : "danger"} progress={videoWatch?.progressMillis > 0 ? (videoWatch?.progressMillis / videoWatch?.progressDuration) : watched ? 1 : 0} />
    </Kitten.Card>
  }

  const ContactStatus = () => {
    // TODO: implement these:
    // contact.followUpDate
    // contact.lastFollowUp
    // contact.followUpHistory
    // contact.birthday
    // contact.appointments

    const delay = 8;
    const twStyle: TextStyle = {
      fontSize: 15.6,
      fontWeight: "500",
      textAlign: "left",
      color: C.text,
      marginLeft: spacing(4),
      marginBottom: spacing(3),
    };

    // may be null if no video watch has been sent to them but not yet watched
    // const watchedVideos = contact?.videoWatches?.filter(vw => vw.watched === 1) || []
    // const unwatchedVideos = contact?.videoWatches?.filter(vw => vw.watched < 1) || []
    // const unfinishedButStartedVideos = contact?.videoWatches?.filter(vw => vw.watched < 1 && vw.startedAt) || []

    return (
      <View>
        {contact.videoWatches?.length > 0 ? (
          <>
            <ListHeader
              titleTx="▶️ Video Tracking"
              style={{ marginTop: spacing(2) }}
            />
            {contact?.videoWatches?.sort((a, b) => (b.progressMillis || 0) - (a.progressMillis || 0))?.map(vw => <VideoWatchStatus videoWatch={vw} />)}
          </>
        ) : null}
        {/* <ListHeader titleTx="Next Follow Up" style={{ marginTop: headerSpacing, paddingLeft: 0 }} /> */}
        <ListHeader
          titleTx={appointmentHeaderText}
          style={{ marginTop: headerSpacing, paddingLeft: 0 }}
          accessoryRight={() =>
            upcomingAppointment || todaysAppointment ? (
              <Buttoon
                appearance="outline"
                icon={{ name: "link" }}
                size="tiny"
                onPress={async () => {
                  try {
                    const _appt = upcomingAppointment || todaysAppointment
                    const eventUrl = fn.calendar.generateEventInviteMessage({
                      startDate: _appt.event?.date,
                      endDate: _appt.event?.endDate,
                      notes:
                        _appt.event?.notes ||
                        _appt.event?.description,
                      title: _appt.event?.title,
                      location: _appt.event?.location || "",
                      guestsCanInviteOthers: true,
                      ..._appt,
                    });
                    if (IS_WEB) {
                      Clipboard.setString(eventUrl);
                      Toasty.show("Invite link copied to clipboard.");
                      return;
                    }
                    const canSms = await SMS.isAvailableAsync()
                    if (!canSms) {
                      Poppy("Mobile only", "Copy to clipboard?", { text: "Cancel" }, {
                        text: "Copy message", onPress: () => {
                          Clipboard.setString(eventUrl)
                          recordUserAction(userHandler.data, ACTION_TYPE.SEND_SMS, `${userHandler.data?.personali?.displayName} sent SMS to ${contact?.name}.`, { location: contact._cid, involvedPartyName: contact.name })
                        }
                      })
                      return;
                    }
                    SMS.sendSMSAsync(
                      (contact?.phoneNumbers as Contacts.PhoneNumber[])?.map(
                        (n) => n?.number
                      ),
                      eventUrl
                    );
                    recordUserAction(userHandler.data, ACTION_TYPE.SEND_SMS, `${userHandler.data?.personali?.displayName} sent SMS to ${contact?.name}.`, { location: contact._cid, involvedPartyName: contact.name })
                  } catch (err) {
                    Toasty.show(err, { type: "danger" });
                    console.error(err);
                  }
                }}
              >
                {tr("Share invite link")}
              </Buttoon>
            ) : null
          }
        />
        {followUpText && followUpText !== appointmentText ? (
          // <Typewriter text={followUpText} delay={delay} style={twStyle} />
          <Txt.S2 style={twStyle}>{followUpText}</Txt.S2>
        ) : null}
        {appointmentText ? (
          <Txt.S2 style={twStyle}>{appointmentText}</Txt.S2>
          // <Typewriter text={appointmentText} delay={delay} style={twStyle} />
        ) : null}
      </View>
    );
  };

  function NoteEditor(__p: {
    isKeyboardVisible: boolean;
    autoFocus?: boolean;
    onSavePress: (text: string) => void;
  }) {
    const { isKeyboardVisible, autoFocus, onSavePress } = __p;
    const [noteText, setNoteText] = React.useState<string>("");
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: C.dim,
        }}
      >
        <Kitten.Input
          style={{ width: isKeyboardVisible ? "88%" : "100%", height: 100 }}
          textStyle={{ fontSize: 14, height: 70 }}
          multiline
          keyboardType="default"
          autoCorrect={true}
          autoFocus={autoFocus}
          autoCapitalize="none"
          placeholder="Type notes here..."
          onChangeText={(text) => {
            setNoteText(text);
          }}
          value={noteText}
        />
        {isKeyboardVisible ? (
          <Buttoon
            style={{ paddingHorizontal: 0 }}
            size="small"
            status="primary"
            appearance="ghost"
            onPress={() => {
              setNoteText("");
              onSavePress(noteText);
            }}
          >
            {tr("Save")}
          </Buttoon>
        ) : null}
      </View>
    );
  }

  const refScrollView = React.createRef<ScrollView>();
  // const [inputBottomY, setInputBottomY] = React.useState<number>(0);

  return contact ? (
    <View style={{ flex: 1, marginBottom: spacing(4) }}>
      {React.useMemo(
        () => (
          <ScrollView
            // style={{ flex: 1 }}
            ref={refScrollView}
            scrollToOverflowEnabled={true}
            keyboardShouldPersistTaps="always"
          >
            <Kitten.Layout collapsable level={"1"}>
              {/* <Kitten.Card style={{ ...styles.card }} header={HeaderView}> */}
              <HeaderView />
              {/* <ListHeader titleTx="Actions" />
          <TextScriptsAction />
          <ScheduleAppointmentAction />
          <FollowUpAction contact={contact} didUpdateContact={setContact} /> */}
              <ContactStatus />
              <ContactInfo />
              <Kitten.Divider_________ />
              <ListHeader
                titleTx={"Notes"}
                accessoryRight={() => (
                  <Buttoon
                    icon={{ name: "pencil" }}
                    size="tiny"
                    status="control"
                    onPress={() => {
                      Navigation.dialogPortal.open({
                        headerTitle: "New Note",
                        render: (
                          <>
                            <Txt.$Title style={{ marginBottom: spacing(2) }}>
                              New Note
                            </Txt.$Title>
                            <NoteEditor
                              isKeyboardVisible={true}
                              autoFocus={true}
                              onSavePress={(text) => {
                                Navigation.goBack();
                                handleNoteSaveBtn(text);
                              }}
                            />
                          </>
                        ),
                      });
                      // refScrollView.current.scrollToEnd()
                    }}
                  >
                    New note
                  </Buttoon>
                )}
              />

              {updatedNotes?.filter((n) => n.isDeleted !== true).length > 0 ? (
                <>
                  <Kitten.Divider_________ />

                  {/* <ListHeader titleTx={"Previous Notes"} />
              <Kitten.Divider_________ /> */}
                  {/* Only display non-deleted notes */}
                  {updatedNotes
                    .filter((n) => n.isDeleted !== true)
                    .sort(
                      (n1, n2) =>
                        n2.createdAt.toMillis() - n1.createdAt.toMillis()
                    )
                    .map((n, index) => {
                      return (
                        <ContactNotesEdit
                          key={n?._id || index}
                          note={n}
                          handleNoteDeletion={handleNoteDeletion}
                          handleNoteEdit={handleNoteEdit}
                        />
                      );
                    })}
                </>
              ) : null}

              {/* </Kitten.Card> */}
            </Kitten.Layout>
            {/* <View style={{ height: Dimensions.get("window").height * 0.44 }} /> */}
          </ScrollView>
        ),
        [contact, qualificationData, updatedQualifications, updatedNotes, phoneContact]
      )}
      <KeyboardAccessoryView
        alwaysVisible={true}
        androidAdjustResize
        style={{ borderRadius: 7, backgroundColor: C.dim }}
      >
        {({ isKeyboardVisible }) => (
          <NoteEditor
            isKeyboardVisible={isKeyboardVisible}
            onSavePress={(text) => handleNoteSaveBtn(text)}
          />
        )}
      </KeyboardAccessoryView>
      {/* <KeyboardAccessoryNavigation avoidKeyboard /> */}
    </View>
  ) : null;
};

