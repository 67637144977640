import { Kitten } from "components/atoms";
import { sstyled, Txt } from "components/atoms/generals";
import { useBreakpointIndex } from "dripsy";
import {
  dConfigKeys,
  dVimeoToken,
  MediaPageItemSchema,
  useAppContext,
  usePageContentDocument
} from "engines";
import VimeoAPI, { dVimeoData } from "engines/backends/apis/VimeoAPI";
import { useConfigDocument } from "engines/firebase/handler/config-handler";
import { Audio as EXAudio, AVPlaybackStatus, Video } from "expo-av";
// import { useNavigator } from "screens/_navigation";
import { INTERRUPTION_MODE_IOS_DO_NOT_MIX } from "expo-av/build/Audio";
import { Platform } from "expo-modules-core";
import React from "react";
import { Pressable, useWindowDimensions, View } from "react-native";
import Sound from "react-native-sound";
import { WebView } from "react-native-webview";
import { useNavigator } from "screens/_navigation/navigation-utils";
import { IS_WEB, tr } from "utils";
import { AndroidVideolly } from "./android-videolly";
import { VideoPlayImage } from "./video-play-image";
import {
  dVideolly,
  ImageVideollyThumbnail,
  REQUIRED_COMPLETION_PERCENT
} from "./Videolly";

export function VimeoVideolly(P: dVimeoVideolly) {
  const {
    cpid,
    item,
    onComplete,
    itemString,
    onFoundSuccessfulToken,
    type = "thumbnail",
  } = P;
  const Navigation = useNavigator();
  // const configHandler = useConfigCollection();
  const { teamId } = useAppContext()
  const configKeysHandler = useConfigDocument(`keys-${teamId}`)
  const configKeys = configKeysHandler.data as dConfigKeys
  const window = useWindowDimensions();
  const breakpoint = useBreakpointIndex();

  //#region [section] url extract
  const [videoData, setVideoData] = React.useState<dVimeoData>(null);

  React.useEffect(
    function loadVideoData() {
      EXAudio.setAudioModeAsync({
        allowsRecordingIOS: false,
        interruptionModeIOS: INTERRUPTION_MODE_IOS_DO_NOT_MIX,
        playsInSilentModeIOS: true,
        staysActiveInBackground: true,
      });
      !IS_WEB && Sound.setCategory("Playback");
      /**
       * Optionally provide an access token you know works
       * - If you don't have one, it's ok, we'll find one for ya! ;)
       */
      let _accessTokens = item?.vimeoToken
        ? [item?.vimeoToken]
        : (configKeys?.vimeo || [])

      // console.log(
      //   "[vimeo] fetching with tokens: " +
      //     JSON.stringify(_accessTokens) +
      //     ` (${itemString}:${item?.media})`
      // );
      VimeoAPI.getVideo({ shareLink: item?.media }, _accessTokens)
        .then((data) => {
          // console.log(
          //   "[vimeo] got data for " + item?.media + " : " + JSON.stringify(data)
          // );
          onFoundSuccessfulToken &&
            onFoundSuccessfulToken(data.vimeoTokenUsed, itemString);

          setVideoData(data);
        })
        .catch((error) => {
          console.log(
            "[vimeo] [vimeo-videolly.tsx] error loading vimeo video: " +
            item?.media +
            " : " +
            error
          );
          // throw new Error(error);
        });
    },
    [configKeysHandler.data]
  );

  const _videoId = VimeoAPI.getVideoIdFromUri(item?.media);
  const videoMP4 = videoData && VimeoAPI.getMP4FromVideo(videoData);
  //#endregion

  const [didComplete, setDidCompleted] = React.useState<boolean>(false);

  const [playInView, setPlayInView] = React.useState(false);
  // const keysHandler = useConfigDocument(`keys-${teamId}`);
  const { data: contentD, ...contentHandler } = usePageContentDocument(
    cpid,
    item?._id
  );

  //#region [section2] vimeo thumbnail setup
  //* In case data doesn't have thumbnail yet.
  //* Once user refresh the screen, thumbnail will be item?.thumbnail
  const [_thumbnail, setThumbnail] = React.useState<string>(null);

  React.useEffect(
    function updateVimeoThumbnail() {
      if (!item?.thumbnail && videoData) {
        let extractedThumbnail = VimeoAPI.getThumbnailFromVideo(videoData);
        contentHandler.update({ thumbnail: extractedThumbnail });
        setThumbnail(extractedThumbnail);
      }
    },
    [videoData, item]
  );

  const videoThumbnail = item?.thumbnail || _thumbnail || "";
  //#endregion

  //#region [ANCHOR] Video player component
  /**
   * Decide whether wee use native Video component or Webview as a player
   * - Based on whether we can get mp4 link or not
   * @returns
   */
  const VideoPlayer = () => {
    let responsiveStyle =
      breakpoint <= 2
        ? {
          width: window.width,
          height: (window.width * 9) / 16,
        }
        : {
          ...(playInView
            ? {
              width: IS_WEB ? "100%" : 800,
              height: IS_WEB ? "100%" : 450,
            }
            : {
              width: 800,
              height: 450,
            }),
          // width: IS_WEB ? "100%" : 800,
          // height: IS_WEB ? "100%" : 450,
        };

    const _didComplete = React.useRef<boolean>(false);

    /**
     * For android
     */
    // const _videoRef = React.useRef<RNVideo>(null);
    // const [androidFullscreen, setAndroidFullscreen] =
    //   React.useState<boolean>(false);

    function onPlaybackStatusUpdate(
      currentMillis: number,
      durationMillis: number
    ) {
      const progress = currentMillis / durationMillis;
      // console.log("[complete] progress: " + progress);
      if (
        !didComplete &&
        !_didComplete.current &&
        progress > REQUIRED_COMPLETION_PERCENT
      ) {
        setDidCompleted(true);
        _didComplete.current = true;
        onComplete && itemString && onComplete(itemString);
      }
    }

    // for web html video component
    const videoRef = React.useRef<any>(null);

    return (
      <View>
        <Txt.H6>{item?.title}</Txt.H6>
        {videoMP4 && videoMP4.length > 0 ? (
          Platform.OS !== "android" ? (
            IS_WEB ? (
              <video
                ref={videoRef}
                src={videoMP4}
                style={responsiveStyle}
                controls
                autoPlay
                onTimeUpdate={(e) => {
                  // the following numbers are multiplied by 1000 in order to convert them to milliseconds
                  onPlaybackStatusUpdate(
                    //@ts-ignore
                    videoRef.current?.currentTime * 1000,
                    //@ts-ignore
                    videoRef.current?.duration * 1000
                  );
                }}
              />
            ) : (
              <Video
                ref={(_ref) => {
                  _ref &&
                    _ref.setOnPlaybackStatusUpdate(
                      (status: AVPlaybackStatus | any) => {
                        onPlaybackStatusUpdate(
                          status?.positionMillis,
                          status?.durationMillis
                        );
                      }
                    );
                  //     const position = status?.positionMillis;
                  //     const totalDuration = status?.durationMillis;
                  //     const progress = position / totalDuration;
                  //     // console.log("[complete] progress: " + progress);
                  //     if (
                  //       !didComplete &&
                  //       !_didComplete.current &&
                  //       progress > REQUIRED_COMPLETION_PERCENT
                  //     ) {
                  //       setDidCompleted(true);
                  //       _didComplete.current = true;
                  //       onComplete && itemString && onComplete(itemString);
                  //     }
                  //   }
                  // );
                  // ref.current = ref.current;
                }}
                style={[{ borderRadius: 10 }, responsiveStyle]}
                shouldPlay={true}
                // volume={1.0}
                resizeMode={"contain"}
                useNativeControls
                source={{ uri: videoMP4 }}
              />
            )
          ) : (
            <AndroidVideolly
              videoStyle={responsiveStyle}
              uri={videoMP4}
              onProgress={(status) => {
                onPlaybackStatusUpdate(
                  status?.currentTime,
                  status?.seekableDuration
                );
                // const progress = status.currentTime / status?.seekableDuration;
                // if (!didComplete && !_didComplete.current && progress > 0.92) {
                //   setDidCompleted(true);
                //   _didComplete.current = true;
                //   onComplete && itemString && onComplete(itemString);
                // }
              }}
            />
          )
        ) : (
          // TODO NOTE WARNING: This is a backup solution for videos videos that failed to get the videoMP4 but may not mark as complete on web! (onLoadEnd does not work on web)
          <View
            style={{ flex: 1 }}
            onLayout={() => {
              // this is so that on web when the view comes up it gets marked as completed anyway
              onComplete && itemString && onComplete(itemString);
            }}
          >
            <A.CtnrWebView
              //   onError={onError}
              onLoadEnd={() => {
                // only fires on mobile :(
                // onComplete && itemString && onComplete(itemString);
              }}
              allowsFullscreenVideo
              scrollEnabled={false}
              automaticallyAdjustContentInsets
              javaScriptEnabled={true}
              domStorageEnabled={true}
              scalesPageToFit
              source={{
                uri: `https://player.vimeo.com/video/${_videoId}`,
                // html: (`<style>.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class='embed-container'><iframe src='https://player.vimeo.com/video/${videoId}?embedparameter=value' width="640" height="400" frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>`)
              }}
              style={[{ borderRadius: 10 }, responsiveStyle]}
            />
          </View>
        )}
      </View>
    );
  };
  //#endregion

  //#region [section] on thumbnail press, show dialog of video & auto play it
  function onThumbnailPress() {
    // if (Platform.OS === "android") {
    //   setShouldPlay(true);
    //   return;
    // }
    // if (IS_WEB) {
    //   setPlayInView(true);
    //   return;
    // }
    switch (type) {
      case "component":
        return Navigation.dialogPortal.open({
          headerTitle: item?.title + "- Video",
          render: <VideoPlayer />,
          staticVideo: true,
          cardType: "transparent",
        });
        break;
      case "thumbnail":
        setPlayInView(true);
        break;
    }
  }
  //#endregion

  try {
    return React.useMemo(() => {
      return playInView ? (
        <VideoPlayer navigator={navigator} />
      ) : (
        <Pressable onPress={onThumbnailPress}>
          <ImageVideollyThumbnail source={{ uri: videoThumbnail }}>
            {videoThumbnail ? (
              <VideoPlayImage onPress={onThumbnailPress} service="vimeo" />
            ) : (
              <A.ViewIconContainer>
                <Kitten.Spinner />
                <Txt.Indicator marginV>
                  {tr("Loading thumbnail...")}
                </Txt.Indicator>
              </A.ViewIconContainer>
            )}
          </ImageVideollyThumbnail>
        </Pressable>
      );
    }, [
      // played,
      videoData,
      videoThumbnail,
      playInView,
      item,
      breakpoint,
      // window,
    ]);
  } catch (error) {
    return (
      <View>
        <Txt>{error}</Txt>
      </View>
    );
  }
}

const A = {
  CtnrWebView: sstyled(WebView)({
    width: "100%",
    height: [250, 300, 350, 400],
    backgroundColor: "black",
    borderRadius: 10,
    overflow: "hidden",
  }),
  ViewIconContainer: sstyled(View)({
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  }),
};

interface dVimeoVideolly extends dVideolly {
  item: MediaPageItemSchema;
  /**
   * Will get passed with the onComplete() when called so the database knows what item to complete
   * - originally generated by levels-handler.tsx
   */
  itemString?: string;
  onComplete?: (itemString: string) => void;
  /**
   * When a vimeo access token and user id pair is found that works to play this video, this function will pass you that data in case you want to save it for the future!
   */
  onFoundSuccessfulToken?: (
    vimeoToken: dVimeoToken,
    itemString: string
  ) => void;
}
