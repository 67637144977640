import Slider from "@react-native-community/slider";
import { Txt } from "components/atoms";
import { useAppContext } from "engines";
import * as React from "react";

const SeekBar = ({
  positionSeconds,
  durationSeconds,
  onSlidingStart,
  onSlidingComplete,
  value,
  disabled = false,
  style = [{}],
}) => {
  const { C } = useAppContext();
  const [initDuration, setInitDuration] = React.useState("00:00");
  const [totalTime, setTotalTime] = React.useState<Date>();
  const [totalTimeString, setTotalTimeString] = React.useState(initDuration);
  const [currentTime, setCurrentTime] = React.useState<Date>();
  const [currentTimeString, setCurrentTimeString] =
    React.useState(initDuration);

  React.useEffect(() => {
    setTotalTime(new Date(durationSeconds * 1000));
    setTotalTimeString(
      totalTime
        ? totalTime
            .toISOString()
            .substr(11, 8)
            .substr(initDuration.length < 6 ? 3 : 0, 8)
        : initDuration
    );
    setInitDuration(
      Math.floor(durationSeconds / 60) > 60 ? "00:00:00" : "00:00"
    );
  }, [durationSeconds]);

  React.useEffect(() => {
    setCurrentTime(new Date(positionSeconds * 1000));
    setCurrentTimeString(
      currentTime
        ? currentTime
            .toISOString()
            .substr(11, 8)
            .substr(initDuration.length < 6 ? 3 : 0, 8)
        : initDuration
    );
  }, [positionSeconds]);

  return (
    <>
      <Txt.C1
        style={{
          color: C.text,
          flex: 1,
          marginLeft: 5,
          width: 35,
        }}
      >
        {currentTimeString}
      </Txt.C1>
      <Slider
        style={[{ width: 230, height: 20, marginHorizontal: 1 }, ...style]}
        minimumValue={0}
        maximumValue={1}
        value={value}
        minimumTrackTintColor={C.text}
        maximumTrackTintColor={C.dim}
        onSlidingStart={onSlidingStart}
        onSlidingComplete={onSlidingComplete}
        disabled={disabled}
        tapToSeek
      />
      <Txt.C1 style={{ width: 35, color: C.text, flex: 1 }}>
        {totalTime
          ? totalTime
              .toISOString()
              .substr(11, 8)
              .substr(initDuration.length < 6 ? 3 : 0, 8)
          : initDuration}
      </Txt.C1>
    </>
  );
};

export default SeekBar;
