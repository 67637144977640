import { dVimeoToken } from "engines";
import { Platform } from "react-native";

/**
 * xtreme-nation's vimeo tokens
 */
let defaultVimeoTokens: dVimeoToken[] = [
  { accessToken: "1606fa3ff157a391582d2b2e67467689", userId: "3587117" },
];

export default class VimeoAPI {
  static getAlbumIdFromUri(uri: string) {
    let uriStr = String(uri);
    const albumStr = "album/";
    const start = uriStr.indexOf(albumStr) + albumStr.length;
    return uriStr.substring(start, uriStr.length);
  }
  static getVideoIdFromUri(uri: string) {
    if (!uri) return null;
    let uriStr = String(uri);
    const str = "vimeo.com/";
    const start = uriStr.indexOf(str) + str.length;
    uriStr = uriStr.substring(start, uriStr.length);
    const end = uriStr.includes("/") ? uriStr.indexOf("/") : uriStr.length;
    return uriStr.substring(0, end);
  }

  static getMP4FromVideo(videoData: dVimeoData) {
    if (!videoData || !videoData.files) {
      console.log(
        "[vimeo] WARNING: videoData or videoData.files is null in getMP4FromVideo in VimeoAPI.js"
      );
      return null;
    }
    const files = videoData.files;
    let link: string;
    // for (let f in files) {
    //   const file = files[f];
    //   if (file.quality == "hd") {
    //     link = file.link;
    //   }
    // }
    if (!link) {
      for (let f in files) {
        const file = files[f];
        if (file.type == "video/mp4" && file.quality != "hls") link = file.link;
      }
    }
    if (!link && files?.length > 0) link = files?.pop()?.link;
    // const end = String(link)?.indexOf("&oauth");
    // link = String(link)?.substring(0, end);
    return link;
  }
  static getThumbnailFromVideo(
    videoData: dVimeoData,
    size = Platform.OS === "web" ? 2 : Platform.isPad ? 2 : 1
  ) {
    if (!videoData || !videoData.pictures || !videoData.pictures.sizes) {
      console.log(
        "[vimeo] WARNING: videoData, videoData.pictures, or videoData.pictures.sizes is null in getThumbnailFromVideo(videoData) in VimeoAPI.js"
      );
      return null;
    }
    const pictures = videoData.pictures.sizes;
    let setLink = "";
    for (let p in pictures) {
      const pic = pictures[p];
      const link = pic.link;
      if (size === 1 && link.includes("200x150")) {
        setLink = link;
        break;
      } else if (size == 0 && link.includes("100x75")) {
        setLink = link;
        break;
      } else if (link.includes("200x150")) setLink = link;
      else if (link.includes("100x75")) setLink = link;
      setLink = link;
    }
    const end = setLink.indexOf("?");
    setLink = setLink.substring(0, end);
    return setLink;
  }

  /**
   * ### Fetch Vimeo's video data from server
   * - **VIP function**
   * @param uri
   * @param index
   * @param alternativeTokens
   * @returns
   */
  static fetch(
    uri: string,
    index: number,
    alternativeTokens: dVimeoToken[] = []
  ) {
    return new Promise<dVimeoData>((resolve, reject) => {
      const tokens = defaultVimeoTokens.concat(alternativeTokens);
      // console.log(
      //   "fetching token at index " +
      //     index +
      //     " (tokens.length = " +
      //     tokens.length +
      //     ")"
      // );
      const accessToken = tokens[index]?.accessToken;
      // console.log("[vimeo] attempting with token " + accessToken);
      fetch(uri, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/vnd.vimeo.*+json",
          Authorization: "Bearer " + accessToken,
        }),
      })
        .then((response) => response.json())
        .then((responseObject) => {
          // console.log(
          //   "\n\n[vimeo] response with token " +
          //     tokens[index].accessToken +
          //     " " +
          //     JSON.stringify(responseObject) +
          //     "\nfetch uri: " +
          //     uri +
          //     "\noriginal url: " +
          //     "\n\n"
          // );
          resolve(responseObject);
        })
        .catch((error) => {
          console.log("[vimeo] Vimeo fetch failed with " + error);
          resolve(false);
        });
    });
  }

  /**
   * ### Need doc
   * @param data
   * @param callback
   * @param index
   * @param alternativeTokens
   */
  static attemptVideoFetch(
    data: { shareLink?: string; path?: string },
    callback: Function,
    index = 0,
    alternativeTokens: dVimeoToken[] = []
  ) {
    // console.log(
    //   "[vimeo] attemptVideoFetch with tokens: " + alternativeTokens?.length
    // );
    const tokens = defaultVimeoTokens.concat(alternativeTokens);
    // const extractedUserId = this.getUserIdFromUri(data.shareLink);
    // const userId = extractedUserId ? extractedUserId : tokens[index].userId;
    const userId = tokens[index].userId;

    // TODO other options?
    let uri = ``;
    if (data.shareLink) {
      uri = `https://api.vimeo.com/users/${userId}/videos/${this.getVideoIdFromUri(
        data.shareLink
      )}`;
    } else if (data.path)
      uri = `https://api.vimeo.com/users/${userId}${data.path}`;
    uri = uri + "?fields=files,name,link,pictures.sizes.link";

    // console.log(
    //   "[vimeo] attempting Vimeo fetch with shareLink: " + data?.shareLink
    // );

    const nextIndex = 1 + index;
    const tryAgain = () => {
      return VimeoAPI.attemptVideoFetch(
        data,
        callback,
        nextIndex,
        alternativeTokens
      );
    };

    VimeoAPI.fetch(uri, index, alternativeTokens).then((response) => {
      if (response === false || response?.error) {
        if (nextIndex >= tokens.length) {
          callback(null);
        } else {
          return tryAgain();
        }
      } else {
        // success
        response.vimeoTokenUsed = tokens[index];
        callback(response);
      }
    });
  }

  /**
   * ### Need doc
   * @param data
   * @param callback
   * @param index
   * @param alternativeTokens
   */
  static attemptAlbumFetch(
    data: AcceptedData,
    callback: Function,
    index = 0,
    alternativeTokens: dVimeoToken[] = []
  ) {
    const tokens = defaultVimeoTokens.concat(alternativeTokens);
    const { userId } = tokens[index];

    let path: string;
    if (data.albumId) path = `/users/${userId}/albums/${data.albumId}/videos`;
    else if (data.albumObject) path = data.albumObject.uri + "/videos";
    else if (data.shareLink)
      path = `/users/${userId}/albums/${VimeoAPI.getAlbumIdFromUri(
        data.shareLink
      )}/videos`;
    const uri = "https://api.vimeo.com" + path;

    const tryAgain = () => {
      VimeoAPI.attemptAlbumFetch(data, callback, index + 1, alternativeTokens);
    };

    VimeoAPI.fetch(uri, index, alternativeTokens).then((response: any) => {
      if (response === false) {
        if (index + 1 >= tokens.length) callback(null);
        else tryAgain();
      } else callback(response.data);
    });
  }

  /**
   * ### Need doc
   * @param data
   * @param alternativeTokens
   * @returns
   */
  static getVideo(data: AcceptedData, alternativeTokens?: dVimeoToken[]) {
    return new Promise((resolve, reject) => {
      const callback = (response) => {
        response ? resolve(response) : reject();
      };
      VimeoAPI.attemptVideoFetch(data, callback, 0, alternativeTokens);
    });
  }

  /**
   * ### Need doc
   * -  get videos (options: albumId, uri, object)
   * @param data
   * @param alternativeTokens
   * @returns
   */
  static getVideos(data: AcceptedData, alternativeTokens?: dVimeoToken[]) {
    return new Promise((resolve, reject) => {
      const callback = (response) => {
        response ? resolve(response) : reject();
      };
      VimeoAPI.attemptAlbumFetch(data, callback, 0, alternativeTokens);
    });
  }
}

export interface AcceptedData {
  albumId?: string;
  albumObject?: any;
  shareLink?: string;
}

/**
 * ### Result from fetching VimeoAPI
 * @example
 * {
  "name": "Jeff Fieldstad",
  "link": "https://vimeo.com/480870077/11d56ded04",
  "pictures": {
    "sizes": [
      {
        "link": "https://i.vimeocdn.com/video/997635621-452855e200a02c1d50784877229f602adcb482ec5358fbf6b94be4927531b0ff-d_100x75?r=pad"
      },
      {
        "link": "https://i.vimeocdn.com/video/997635621-452855e200a02c1d50784877229f602adcb482ec5358fbf6b94be4927531b0ff-d_200x150?r=pad"
      },
    ]
  },
  "files": [
    {
      "quality": "hd",
      "rendition": "720p",
      "type": "video/mp4",
      "width": 1280,
      "height": 720,
      "link": "https://player.vimeo.com/progressive_redirect/playback/480870077/rendition/720p/file.mp4?loc=external&oauth2_token_id=1389886735&signature=c319a419e1a5b105a756d730bd2fa1807d49c227b996b6bdc4c4244e1349c798",
      "created_time": "2020-11-18T18:01:50+00:00",
      "fps": 29.97002997002997,
      "size": 151423162,
      "md5": "326e0d31abb66a90bb4d59b88ad14b53",
      "public_name": "720p",
      "size_short": "144.41MB"
    },
    {
      "quality": "sd",
      "rendition": "360p",
      "type": "video/mp4",
      "width": 640,
      "height": 360,
      "link": "https://player.vimeo.com/progressive_redirect/playback/480870077/rendition/360p/file.mp4?loc=external&oauth2_token_id=1389886735&signature=c7fb54c15c1d3bb58c98a6f28edcd54add5f48788b457e3cfa2e98e5fb6d715c",
      "created_time": "2020-11-18T18:02:50+00:00",
      "fps": 29.97002997002997,
      "size": 65954384,
      "md5": "74a8b45082525e345c156090844ae587",
      "public_name": "360p",
      "size_short": "62.9MB"
    },
  ],
  "vimeoTokenUsed": {
    "userId": "106185193",
    "accessToken": "24c9d48ee9aa8ddafbb3b71bf4e93a0b"
  }
}
 */
export interface dVimeoData {
  name: string;
  link: string;
  pictures: { sizes: { link: string }[] };
  files: {
    quality: "hd" | "sd" | "hls";
    rendition: string; //"720p";
    type: string; //"video/mp4";
    width: number;
    height: number;
    link: string;
    created_time: Date; //"2020-11-18T18:01:50+00:00";
    fps: number; //29.97002997002997;
    size: number; //151423162;
    md5: string; //"326e0d31abb66a90bb4d59b88ad14b53";
    public_name: string; //"720p";
    size_short: string; //"144.41MB";
  }[];
  vimeoTokenUsed: {
    userId: string; //"106185193";
    accessToken: string; //"24c9d48ee9aa8ddafbb3b71bf4e93a0b";
  };
}
