// import React from "react";
import { update } from "@nandorojo/swr-firestore";
import {
  Buttoon,
  Kitten,
  Skeleton,
  Toasty,
  Txt,
  ListHeader,
} from "components/atoms";
import { ShortcutItem } from "components/molecules";
import { FPATH, ShortcutSchema, useAppContext, URL_PREFIX } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useMoreCollection } from "engines/firebase/handler/more-handler";
import * as R from "ramda";
import React from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-drag";
import { View } from "react-native";
import { useNavigator } from "screens";
import { scale, tr } from "utils";
import { ShortcutEditor } from "../../molecules/shortcuts/ShortcutEditor";
import { FRBS_ROLE } from "../../../engines/firebase/firebase.props";

export function ShortcutList(props) {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const shortcutsHandler = useMoreCollection();
  const userProfile = useUserProfile();

  // const categorizedData = shortcutsHandler.data?.map((shortcut) => {
  //   if (shortcut?.name && (shortcut?.contactPhone || shortcut?.contactEmail)) {
  //     return { ...shortcut, _CAT: "contact" };
  //   }
  //   if (
  //     shortcut?.name &&
  //     (shortcut?.link ||
  //       shortcut?.iosLink ||
  //       shortcut?.androidLink ||
  //       shortcut?.otherLink)
  //   ) {
  //     return { ...shortcut, _CAT: "link" };
  //   }
  //   return { ...shortcut, _CAT: "other" };
  // });

  const [_shortcuts, setShortcuts] = React.useState<ShortcutSchema[]>([]); // supply your own state
  React.useEffect(
    function grabItems() {
      if (shortcutsHandler.data) {
        const categorizedData = shortcutsHandler.data?.map((shortcut) => {
          if (
            shortcut?.name &&
            (shortcut?.contactPhone || shortcut?.contactEmail)
          ) {
            return { ...shortcut, _CAT: "contact" };
          }
          if (
            shortcut?.name &&
            (shortcut?.link ||
              shortcut?.iosLink ||
              shortcut?.androidLink ||
              shortcut?.otherLink)
          ) {
            if (
              String(shortcut?.link).startsWith("inapp://") ||
              String(shortcut?.link).startsWith(URL_PREFIX.PAGE)
            ) {
              return { ...shortcut, _CAT: "inapp" };
            } else return { ...shortcut, _CAT: "link" };
          }
          return { ...shortcut, _CAT: "other" };
        });
        let sortedCatData = R.sortWith<ShortcutSchema>(
          [R.ascend(R.prop("position"))],
          categorizedData
        );
        setShortcuts(sortedCatData);
      }
    },
    [shortcutsHandler.data]
  );

  React.useEffect(
    function disableDragDropifNotAdmin() {
      setShouldDrag(userProfile._isUserAdmin() || userProfile._isDeveloper());
    },
    [userProfile]
  );

  const [shouldDrag, setShouldDrag] = React.useState<boolean>(true);

  //#region [functions] drag and drop handler
  function onMovingDone(sourceIndex: number, targetIndex: number) {
    const nextState = swap(_shortcuts, sourceIndex, targetIndex);
    setShortcuts(nextState);
    setShouldDrag(false);
    /**
     * Calculate new target position value
     * -  if targetIndex < sourceIndex, position = avg(target-1 ,target);
     * if targetPostion is 0, position is set to -1
     * -  if targetIndex > sourceIndex, position = avg(target ,target + 1);
     * if targetPostion is n, position is set to n+1
     */
    const targetPosition = () => {
      if (targetIndex < sourceIndex) {
        return _shortcuts[targetIndex - 1]
          ? R.median([
              parseInt(_shortcuts[targetIndex - 1]?.position),
              parseInt(_shortcuts[targetIndex]?.position),
            ])
          : parseInt(_shortcuts[targetIndex]?.position) - 1;
      }
      if (targetIndex > sourceIndex) {
        return _shortcuts[targetIndex + 1]
          ? R.median([
              parseInt(_shortcuts[targetIndex]?.position),
              parseInt(_shortcuts[targetIndex + 1]?.position),
            ])
          : parseInt(_shortcuts[targetIndex]?.position) + 1;
      }
    };

    console.log("[move]: ", sourceIndex + " -> " + targetIndex);
    console.log(
      "[move] update position of doc ",
      _shortcuts[sourceIndex]?._id + " to " + targetPosition()
    );
    !R.isNil(targetPosition()) &&
      update<ShortcutSchema>(FPATH.MORE + "/" + _shortcuts[sourceIndex]?._id, {
        position: targetPosition(),
      }).then(() => {
        Toasty.show(tr("Update position..."), {
          type: "loading",
          duration: 1100,
        });
        setTimeout(() => {
          setShouldDrag(true);
        }, 1000);
      });
  }

  function onCreateShortcut() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Create a shortcut"),
      render: <ShortcutEditor shortcutsHandler={shortcutsHandler} />,
    });
  }
  //#endregion

  try {
    if (shortcutsHandler.error) {
      console.warn("error in ShortcutList: ", shortcutsHandler.error);
      return <Txt.Indicator>{tr("Error getting data")}</Txt.Indicator>;
    }
    if (!shortcutsHandler.data) return <Skeleton.Screen.Feed length={2} />;
    return (
      <View>
        {/* <MoreCreator shortcutsHandler={shortcutsHandler} /> */}
        <ListHeader
          titleTx={tr("Shortcuts")}
          actions={[
            {
              label: "Create a shortcut",
              icon: { name: "plus" },
              onPress: onCreateShortcut,
            },
          ]}
        />
        {/* {userProfile._isRolesContain([
          FRBS_ROLE.ADMIN,
          FRBS_ROLE.DEVELOPER,
        ]) && (
          <Buttoon
            appearance={"ghost"}
            icon={{ name: "plus" }}
            onPress={onCreateShortcut}
          >
            {tr("Create a shortcut")}
          </Buttoon>
        )} */}
        <Txt.Helper marginV>
          {tr("Star any shortcut to put it on home screen")}
        </Txt.Helper>
        <GridContextProvider
          onChange={(_, sourceIndex, targetIndex, __) =>
            onMovingDone(sourceIndex, targetIndex)
          }
        >
          <GridDropZone
            id="items"
            boxesPerRow={1}
            rowHeight={scale(69)}
            style={{ height: scale(69) * _shortcuts?.length }}
            disableDrag={!shouldDrag}
            disableDrop={!shouldDrag}
          >
            {_shortcuts
              ?.filter((item) => item._CAT !== "inapp")
              .map((item, index) => (
                <GridItem key={item?._id}>
                  <ShortcutItem type={item._CAT} page={item} />
                  <Kitten.Divider />
                </GridItem>
              ))}
            {/* <FlatList
              data={categorizedData}
              keyExtractor={(item, index) => item._id}
              renderItem={({ item }) => (
                <ShortcutItem type={item._CAT} page={item} />
              )}
              ItemSeparatorComponent={Kitten.Divider}
            /> */}
          </GridDropZone>
        </GridContextProvider>
      </View>
    );
  } catch (error) {
    throw Error(`::TWVkaWFIb21lRmVlZA==::${error}`);
  }
}
