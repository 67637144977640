import { dUserAction } from "engines";
import { ScrollView } from "react-native-gesture-handler";
import { ActionItem } from "./TeamMatesSceen";

/**
 * Provide the action being tapped and if it has an actions array we will show all those
 */
export function ActionsGroupScreen(p: { action: dUserAction, route }) {
    const action = p?.action || p?.route?.params?.action
    return <ScrollView style={{flex: 1}}>
        {(action.groupedActions || [action]).map(_action => <ActionItem action={_action} />)}
    </ScrollView>
}