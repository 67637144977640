import messaging from "@react-native-firebase/messaging";
import { Toasty } from "components";
import { dNotification, UserSchema } from "engines/backends";
import {
  getPermissionsAsync,
  requestPermissionsAsync
} from "expo-notifications";
import { dateAsNumber } from "../calendar-functions/time";

// const refDidShowOffToasty = React.useRef<boolean>(false);

let didShowOffToasty = false;

export async function getFirebaseMessagingToken(): Promise<string> {
  // get permission
  let permissionResult = await getPermissionsAsync();
  // console.log("[messaging] get result.status = " + permissionResult.status);
  if (permissionResult.status === "undetermined")
    permissionResult = await requestPermissionsAsync();
  // console.log("[messaging] request result.status = " + permissionResult.status);

  // get token (if PN access granted)
  if (!didShowOffToasty && permissionResult.status === "denied") {
    didShowOffToasty = true;
    // refDidShowOffToasty.current = true;
    Toasty.show(
      "Push notifications are turned off. We recommend re enabling in your phone settings!",
      { type: "warning" }
    );
    return null;
  }
  if (permissionResult.granted) {
    const token = await messaging().getToken();
    // console.log("[messaging] token = " + token);
    return token;
  }
}

export async function subscribeToGlobalTopic(teamId: string) {
  try {
    const topic = `${teamId}-global`;
    // console.log("[messaging] subscribing to topic: " + topic);
    return messaging().subscribeToTopic(topic);
  } catch (err) {
    console.log("[messaging] failed to subscribe to global topic: " + err);
    return null;
  }
}

export enum TOPICTYPES {
  BASESHOP = "baseshop",
  POST = "post",
}
export async function subscribeToTopic(
  teamId: string,
  topicType: TOPICTYPES,
  topicId: string
) {
  try {
    const topic = `${teamId}-${topicType}-${topicId}`;
    // console.log("[messaging] subscribing to topic: " + topic);
    return messaging().subscribeToTopic(topic);
  } catch (err) {
    console.log("[messaging] failed to subscribe to global topic: " + err);
    return null;
  }
}

export async function unSubscribeToTopic(
  teamId: string,
  topicType: TOPICTYPES,
  topicId: string
) {
  try {
    const topic = `${teamId}-${topicType}-${topicId}`;
    // console.log("[messaging] unsubscribing from topic: " + topic);
    return messaging().unsubscribeFromTopic(topic);
  } catch (err) {
    console.log(
      `[messaging] failed to unsubscribe from ${teamId}-${topicType}-${topicId} topic: ` +
        err
    );
    return null;
  }
}

export function sortNotifications(
  notifications: dNotification[]
): dNotification[] {
  return notifications.sort(
    (n1: dNotification, n2: dNotification) =>
      dateAsNumber(n2?.createdAt) - dateAsNumber(n1?.createdAt)
  );
}

/**
 * ### Returns all notifications that the current user has not seen yet
 * - A notification is seen when the user taps the notification area and then goes back out
 * - Make sure notifications have been fetched before using this (most likely they have been)
 *
 * @author jm_francis
 * @version 1.1.30
 *
 * @example
 * const unreadNotifications = notificationFirestore.getUnreadNotifications()
 */
export function getUnreadNotifications(account: UserSchema): dNotification[] {
  // const account = Backend.firestoreHandler._account;
  let notis = this._global?.concat(this._team ? this._team : []);
  let unreadNotifications: dNotification[] = [];
  for (let n in notis) {
    const noti: dNotification = notis[n];
    if (
      account?.notifications?.lastRead &&
      dateAsNumber(noti?.createdAt) >
        dateAsNumber(account.notifications.lastRead)
    )
      unreadNotifications.push(noti);
  }
  return unreadNotifications;
}

function notify(
  title: string,
  message: string,
  /**
   * "app-takeoff-team"         : send to the whole App Takeoff Team
   * "app-takeoff-team/abcxyz"  : send to bs abcxyz of App Takeoff Team
   * "//uvwxyz"                 : send to user uvwxyz
   * ["//uvwxyz", "//sdertyu"]  : send to users uvwxyz & sdertyu
   */
  recepients: "_teamId/bsId/user"[],
  data: {
    media: string;
    badge: number;
    scheduleAt: Date;
    reoccurence: "daily" | "weekly" | "monthly" | "yearly";
  }
) {}
