import * as EXContacts from "expo-contacts"
import { MenuIcon } from "assets/icons/MenuIcon";
import { sstyled } from "components";
import { dAccessory, Kitten, Poppy, Toasty } from "components/atoms";
import { dContactCSV, fn, useAppContext, useContactsCollection } from "engines";
import { ContactSchemaa, dContactNote } from "engines/backends/schemas/bml";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { IS_WEB, tr } from "utils";
import { AddContactCreator } from "./AddContactCreatorDialog";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";

/**
 * ### List of members of the app (aka teamId's members)
 *  - Including some quick actions as an swipeable item
 *  ----
 *  @version 21.09.07
 *  -  *Memoize it*
 *  @author  K
 *
 **/
export function ContactMenu(props: P) {
  const { id } = props;
  const { C, frbsUser } = useAppContext();
  const Navigation = useNavigator();
  const contactHandler = useContactsCollection(frbsUser?.uid, id);
  const userProfile = useUserProfile();
  const { qualificationData } = useBMLMContext()

  /**
   * The contacts that belong to this group
   */
  const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);
  const [exportData, setExportData] = React.useState<dContactCSV[]>([]);

  React.useEffect(
    function setupData() {
      contactHandler.data &&
        setContacts(
          contactHandler.data?.filter((c) =>
            c?.groups?.some((group) => group == id)
          )
        );
      return;
    },
    [contactHandler.data]
  );

  React.useEffect(() => {
    // console.log(`Total of ${contacts?.length} contacts for ${frbsUser?.uid}`);
    const data =
      contacts && contacts.length > 0
        ? contacts.map((item) => {
          const formattedPN =
            item.phoneNumbers && item.phoneNumbers?.length > 0
              ? typeof item.phoneNumbers[0] === "string"
                ? item.phoneNumbers[0]
                : //@ts-expect-error
                item.phoneNumbers[0]?.number
              : "";

          const formattedNotes = item.notes
            ? item.notes
              ?.map((n) => {
                return `Note "${n.noteText}" created at ${n.createdAt
                  .toDate()
                  .toLocaleDateString()} by ${n.createdBy.displayName.trim()}`;
              })
              .join(",\n")
            : "";

          // const qualificationsAsObject = item.qualifications.reduce((obj, qualification) => {
          //   obj[qualification] = true
          //   return obj;
          // }, {});

          return {
            displayName: item.displayName || item.name,
            phoneNumber: formattedPN,
            email: item.email || (item.emails as EXContacts.Email[])?.map(e => e.email),
            qualifications: item.qualifications,
            notes: formattedNotes,
          };
        })
        : [];

    setExportData(data);
    // console.log("Export Data ", data?.length);
  }, [contacts]);

  const handleExport = () => {
    const exportData = contacts.map((item) => {
      const phoneNumbers = (item?.phoneNumbers as EXContacts.PhoneNumber[])?.length > 0 ? (item.phoneNumbers as EXContacts.PhoneNumber[]).map(n => n.number).join(",") : ""

      const formattedNotes = item?.notes
        ? (item?.notes as dContactNote[])
          ?.map((n) => {
            return `Note "${n.noteText}" created at ${n.createdAt
              .toDate()
              .toLocaleDateString()} by ${n.createdBy.displayName.trim()}`;
          })
          .join("\n")
        : "";

      const qualificationObj = {}
      for (let q in qualificationData) {
        const qualifier = qualificationData[q]
        qualificationObj[qualifier?.name] = (item?.qualifications as string[])?.includes(qualifier?._id) ? true : false
      }

      // alert(JSON.stringify(qualificationObj))

      return {
        "Name": item.displayName,
        "Phone Number(s)": phoneNumbers,
        "Email(s)": item.email || ((item?.emails || []) as EXContacts.Email[])?.map((e: EXContacts.Email) => e.email),
        "Notes": formattedNotes,
        ...qualificationObj
      };
    });

    Poppy(
      "Export Confirmation",
      "Do you want to save your contacts as CSV?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => {
          const headers = ["Name", "Phone Number(s)", "Email(s)", "Notes"].concat(qualificationData.map(d => d.name))
          // console.log("displayName ", contacts);
          IS_WEB
            ? fn.contacts.ContactsExportWeb(
              headers,
              userProfile?.data?.personali?.displayName +
              " exported contacts",
              true,
              exportData
            )
            : fn.contacts.ContactsExport(
              headers,
              userProfile?.data?.personali?.displayName +
              " exported contacts",
              true,
              exportData
            );
        },
      }
    );
  };

  return React.useMemo(() => {
    try {
      return (
        <View>
          <A.CtnrMenu
            selectedIndex={null}
            onSelect={(index) => {
              // setSelectedIndex(index);
              switch (index.row) {
                case 0:
                  IS_WEB
                    ? Navigation.dialogPortal.open({
                      headerTitle: "Add contact",
                      render: <AddContactCreator id={id} />,
                    })
                    : Navigation.navigate("contacts-search", {
                      id: id,
                    });

                  break;
                case 1:
                  handleExport();
                  break;
                case 2:
                  Toasty.show(tr("Coming soon"), { type: "warning" });
                  break;
              }
            }}
          >
            <Kitten.MenuItem
              title={tr("Add Contact")}
              accessoryLeft={(props: dAccessory) => MenuIcon("plus", props)}
            />
            <Kitten.MenuItem
              disabled={exportData.length === 0}
              title={tr("Export to CSV", ["CSV"])}
              accessoryLeft={(props: dAccessory) => MenuIcon("file_csv", props)}
            />
            <Kitten.MenuItem
              title={tr("Import from CSV", ["CSV"])}
              accessoryLeft={(props: dAccessory) => MenuIcon("file_csv", props)}
            />
          </A.CtnrMenu>
        </View>
      );
    } catch (error) {
      throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
    }
  }, [C, exportData]);
}

const A = {
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
};

interface P {
  id: string;
}
