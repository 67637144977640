import { useNavigation } from "@react-navigation/native";
import { IconPrimr } from "assets";
import { Kitten, Txt } from "components";
import { Avt, sstyled } from "components/atoms";
import { useTwoColumns } from "components/templates";
import {
  fn,
  ScoreboardSchema,
  useAppContext,
  useMemberDocument,
  useScoreboardCollection,
} from "engines";
import React from "react";
import { Image, View } from "react-native";
import Animated from "react-native-reanimated";
import { dSboardScrParams } from "screens/_navigation";
import { moderateScale, spacing, tr } from "utils";

/**
 * ### Brief description
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.09.23
 *  -  *Brief changelog*
 *  @author  Your Name
 *
 **/
export function ViewAllScoreboards(props) {
  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();

  const scoreboardsHandler = useScoreboardCollection();

  const [editModeEnabled, setEditModeEnabled] = React.useState<boolean>(false);

  return (
    <View style={{ flex: 1, marginVertical: spacing(3) }}>
      <Animated.FlatList
        // scrollEventThrottle={16}
        // onScroll={scrollHandler}
        // ListHeaderComponent={ViewFeedHeader()}
        // stickyHeaderIndices={[0]}
        contentContainerStyle={[column1ContainerStyle]}
        data={scoreboardsHandler.data}
        renderItem={({ item, index }) => {
          const scoreboard: ScoreboardSchema = item;
          return <ScoreboardItem scoreboard={scoreboard} />;
        }}
        ItemSeparatorComponent={() => <View style={{ margin: spacing(2) }} />}
        ListEmptyComponent={() => (
          <Txt.Indicator marginV>
            {scoreboardsHandler.loading
              ? tr("Loading...")
              : tr("No scoreboards found")}
          </Txt.Indicator>
        )}
        keyExtractor={(item: ScoreboardSchema) => item._sbid}
      />
    </View>
  );
}

interface dScoreboardItem {
  scoreboard: ScoreboardSchema;
}

/**
 * ### Brief description
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.09.23
 *  -  *Brief changelog*
 *  @author  Your Name
 *
 **/
export function ScoreboardItem(props: dScoreboardItem) {
  const { scoreboard } = props;

  const { C } = useAppContext();
  const Navigation = useNavigation();

  const topScore =
    scoreboard?.people?.length > 0
      ? scoreboard?.people?.sort((a, b) => b.score - a.score)[0]
      : null;

  const topScoreUser = useMemberDocument(topScore?.uid);

  const borderRadius = 7;

  return (
    <View>
      <Kitten.ListItem
        onPress={() => {
          Navigation.navigate<dSboardScrParams>("sboard-scr", {
            sbid: scoreboard._sbid,
          });
        }}
        style={{
          paddingVertical: 0,
          paddingHorizontal: 0,
          borderRadius,
          overflow: "hidden",
          paddingLeft: "6%",
          // marginVertical: spacing(3),
          marginTop: 0,
          marginBottom: 0,
          marginHorizontal: 0,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              flex: 0.7,
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <A.CtnrScoreItem>
                <IconPrimr
                  preset="safe"
                  name={"crown"}
                  color={C.hazardYellow}
                  size={crownSize}
                />
                <A.TxtTopName numberOfLines={2}>
                  {topScore?.name || tr("Unknown")}
                </A.TxtTopName>
              </A.CtnrScoreItem>
              <A.TxtTopScore>
                {fn.js.formatNumber(topScore?.score)}
              </A.TxtTopScore>
            </View>
          </View>

          <View
            style={{
              justifyContent: "center",
              alignItems: "flex-end",
              flex: 0.3,
            }}
          >
            <View
              style={{
                width: moderateScale(A.imageDimension) * 1.35,
                // height: moderateScale(A.imageDimension),
                justifyContent: "center",
                alignItems: "center",
                marginVertical: spacing(4),
                marginRight: spacing(3),
              }}
            >
              {topScore && topScoreUser.data ? (
                <Avt
                  style={A.ImageStyle}
                  source={{ uri: topScoreUser.data?.personali?.photoURL }}
                  name={topScoreUser.data?.personali?.displayName}
                />
              ) : (
                <Image
                  source={{
                    uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1663711227/admin/profilecenter_itp0la.png",
                  }}
                  style={{
                    ...A.ImageStyle,
                    backgroundColor: fn.js.stringToColor(
                      topScore?.name || ": ("
                    ),
                    opacity: 0.2,
                  }}
                />
              )}
              <Txt.S1 style={{ textAlign: "center", marginTop: spacing(3) }}>
                {scoreboard?.title}
              </Txt.S1>
            </View>
          </View>
        </View>
      </Kitten.ListItem>
      {/* <Txt.S1 style={{ alignSelf: "flex-end" }}>{scoreboard?.title}</Txt.S1> */}
    </View>
  );
}

const imageDimension = moderateScale(75);
const crownSize = moderateScale(23);

const A = {
  imageDimension,
  CtnrScoreItem: sstyled(View)({
    paddingRight: 3,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  TxtTopName: sstyled(Txt)({
    maxWidth: "95%",
    fontWeight: "bold",
    color: "hazardYellow",
    textAlign: "center",
    alignSelf: "center",
    fontSize: moderateScale(18),
  }),
  TxtTopScore: sstyled(Txt.H4)({
    marginTop: spacing(1),
    marginLeft: crownSize,
    backgroundColor: "background",
  }),
  ImageStyle: {
    width: moderateScale(imageDimension) * 0.77,
    height: moderateScale(imageDimension) * 0.77,
    borderRadius: (moderateScale(imageDimension) * 0.77) / 2,
  },
};
