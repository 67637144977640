//#region [import]
import {
  createNavigationContainerRef,
  NavigationContainer,
  StackActions
} from "@react-navigation/native";
import {
  createStackNavigator,
  TransitionPresets
} from "@react-navigation/stack";
import { IconPrimr } from "assets";
import { Buttoon, Txt } from "components/atoms";
import { DevRolesBar } from "components/organisms";
import { useAppContext } from "engines";
import { useAudioPlayerContext } from "engines/providers/audio-provider";
import React from "react";
import {
  Alert,
  ColorSchemeName,
  useWindowDimensions
} from "react-native";
import * as Animatable from "react-native-animatable";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import Draggable from "react-native-draggable";
import { ScrollView } from "react-native-gesture-handler";
import { GalleryScreen } from "screens/gallery/GalleryScreen";
import { AuthScreen } from "screens/welcome/AuthScreen";
import { colors, IS_WEB, spacing, THEME, themeDark, themeLight } from "utils";
import { AudioPlayerScreen } from "../audio-player/AudioPlayerScreen";
import { BSStackCollection } from "./bs.navigator";
import { CollabStackCollection } from "./collaboration.navigator";
import { DialogNavigator } from "./dialog.navigator";
import {
  DevDrawerCollection,
  DrawerNavigator,
  UserDrawerCollection
} from "./drawer.navigator";
import { HomeStackCollection } from "./home.navigator";
import { LayoverNavigator } from "./layover.navigator";
import LinkingConfiguration from "./LinkingConfiguration";
import {
  presetNavConfig,
  useNavigator,
  useNavigatorPresetConfig
} from "./navigation-utils";
import { OverlayNavigator } from "./overlay.navigator";
import { PagesStackCollection } from "./pages.navigator";
import { ToolsStackCollection } from "./tools.navigator";
import { WelcomeStackCollection } from "./welcome.navigator";
//#endregion

export const navigationRef = createNavigationContainerRef();
// export const navigationRef = React.createRef(undefined);

export default function Navigation({
  colorScheme,
}: {
  colorScheme?: ColorSchemeName;
}) {
  const { theme, C } = useAppContext();
  const {
    showPlayer,
    audioItem,
    setShowPlayer,
    setIsFullScreen,
    duration,
    position,
    isPlaying,
    stopAudioPlayer,
    onButtonPressed: handlePress,
  } = useAudioPlayerContext();
  const { height, width } = useWindowDimensions();
  const audioPlayerRenderSize = 90;

  const [showAudioRemoveHelper, setShowAudioRemoveHelper] = React.useState<boolean>(false);

  let navigationTheme =
    theme === THEME.DARK
      ? {
        colors: {
          ...themeDark,
          border: C.dim,
          card: C.background,
          notification: C.primaryDarker,
          primary: C.primary,
        },
        dark: true,
      }
      : {
        colors: {
          ...themeLight,
          border: C.dim,
          card: C.background,
          notification: C.primaryDarker,
          primary: C.primary,
        },
        dark: false,
      };
  return (
    <NavigationContainer
      // ref={(ref) => {
      //   refNavigation.current = ref;
      //   navigationRef.current = ref
      // }}
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={navigationTheme}
    >
      <RootStack />
      {/** draggable audio player bubble */}
      {!IS_WEB && showPlayer && (
        <Draggable
          //FIXME find out if you can close by dragging it out of screen
          x={width - 0.3 * (width - audioPlayerRenderSize / 2)}
          y={height - 0.25 * (height - audioPlayerRenderSize / 2)}
          renderSize={audioPlayerRenderSize}
          renderColor={!isPlaying ? C.grey600 : C.primaryDarker}
          renderText={`${new Date(position * 1000)
            .toISOString()
            .substr(11, 8)
            .substr(position < 60 * 60 ? 3 : 0, 8)}`}
          isCircle
          onPressIn={() => setShowAudioRemoveHelper(true)}
          onPressOut={() => setShowAudioRemoveHelper(false)}
          onShortPressRelease={() => {
            setShowPlayer(false);
            setIsFullScreen(true);
            // @ts-ignore
            navigationRef.current?.navigate("AudioPlayer");
          }}
          onLongPress={() => {
            Alert.alert(
              "Close Player ?",
              `Are you sure you want to stop playing ${audioItem.title
              }? You are ${new Date(position * 1000)
                .toISOString()
                .substr(11, 8)
                .substr(position < 60 * 60 ? 3 : 0, 8)} into it.`,
              [
                { text: "No", onPress: () => { } },
                {
                  text: "Yes",
                  onPress: () => {
                    stopAudioPlayer();
                  },
                },
              ]
            );
          }}
        >
          <>
            <AnimatedCircularProgress
              size={audioPlayerRenderSize}
              width={10}
              fill={(position / duration) * 100}
              duration={duration}
              rotation={0}
              children={() => (
                <>
                  {/* <Buttoon
                  onPress={() => {
                    setShowPlayer(false);
                    setIsFullScreen(true);
                    // @ts-ignore
                    navigationRef.current?.navigate("AudioPlayer");
                  }}
                  appearance="ghost"
                  icon={{ name: isPlaying ? "pause" : "play" }}
                  status="control"
                  size={"large"}
                /> */}
                  <IconPrimr name={isPlaying ? "pause" : "play"} color={C.pitchWhite} />
                  <Txt.P1 style={{ color: C.text01, marginBottom: 10 }}>
                    {`${new Date(position * 1000)
                      .toISOString()
                      .substr(11, 8)
                      .substr(position < 60 * 60 ? 3 : 0, 8)}`}
                  </Txt.P1>
                </>
              )}
              tintColor={C["color-success-500"]}
              backgroundColor={"#f2f2f280"}
              backgroundWidth={5}
            />
            {showAudioRemoveHelper ? <Txt.Helper style={{ position: "absolute", top: -30 }}>Hold to remove</Txt.Helper> : null}
          </>
        </Draggable>
      )}
      {"debug" === "nah" && <DevRolesBar />}
    </NavigationContainer>
  );
}

/**
 * The root navigator is used to switch between major navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow (which is contained in your PrimaryNavigator) which the user
 * will use once logged in.
 * https://reactnavigation.org/docs/modal
 */
function RootStack() {
  const Stack = createStackNavigator<
    typeof FixedStackCollection & { Welcome: any; Drawer: any }
  >();
  const { rootStackConfig } = useNavigatorPresetConfig();
  const { frbsUser, C } = useAppContext();
  const Navigation = useNavigator();
  const [welcomeWall, setWelcomeWall] = React.useState<boolean>(false);

  const timerRef = React.useRef(null);

  //#region [section] checking authentication
  React.useEffect(
    function checkingAuthentication() {
      if (IS_WEB && window.location?.href?.includes("content-item")) {
        // do not show welcome page just show the content!
        // const cid = window.location.pathname?.replace("/content-item/", "");
        // showContent(cid)
        return;
      }
      // let __toast = Toasty.show(tr("Loading..."), {
      //   type: "loading",
      //   duration: 10000,
      // });
      if (frbsUser && timerRef.current) {
        clearTimeout(timerRef.current);
        // Toasty.show(tr("Welcome back 👋"), { type: "success" });
        setWelcomeWall(false);
      } else {
        timerRef.current = setTimeout(() => {
          // Toasty.show(tr("Please log in to continue"), {
          //   type: "normal",
          // });
          setWelcomeWall(true);
        }, 3000);
      }
    },
    [frbsUser]
  );
  //#endregion

  if ("debug" === "nah") {
    //#region [ANCHOR] quick debugging
    return (
      <Stack.Navigator
        screenOptions={({ route }) => ({
          presentation: "card",
          headerShown: true,
          headerTitle: "",
        })}
      >
        <Stack.Group
          screenOptions={({ route }) => ({
            presentation: "card",
            headerShown: true,
            headerTitle: "",
          })}
        >
          {Object.entries({
            ...DevDrawerCollection,
            ...CollabStackCollection,
            ...UserDrawerCollection,
            ...HomeStackCollection,
          }).map(([name, screen]) => (
            <Stack.Screen name={name} key={name} {...screen} />
          ))}
        </Stack.Group>
        <Stack.Group
          screenOptions={({ route }) => ({
            presentation: IS_WEB ? "transparentModal" : "modal",
            ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
          })}
        >
          {Object.entries({
            ...FixedStackCollection,
          }).map(([name, screen]) => (
            <Stack.Screen name={name} key={name} {...screen} />
          ))}
        </Stack.Group>
      </Stack.Navigator>
    );
    //#endregion
  } else {
    //#region [section] "real" return
    // return (
    //   <Stack.Navigator {...rootStackConfig}>
    //     <Stack.Screen name="Auth" component={AuthScreen} />
    //   </Stack.Navigator>
    // );
    return IS_WEB ? (
      <>
        <Stack.Navigator {...rootStackConfig}>
          <Stack.Group>
            <Stack.Screen name="Drawer" component={DrawerNavigator} />
            <Stack.Group
              screenOptions={({ route, navigation }) => ({
                presentation: "card",
                cardStyle: { backgroundColor: "transparent", height: 10 }, //todo: test it on native
                headerShown: true,
                ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
                headerRight: () =>
                  route.path?.includes("content-item") ? null : (
                    <IconPrimr
                      preset="circular"
                      onPress={() =>
                        Navigation.dispatch(StackActions.replace("Drawer"))
                      }
                      name={"home"}
                      color={C.primary}
                      containerStyle={{
                        backgroundColor: C["color-primary-transparent-300"],
                        marginRight: spacing(3),
                      }}
                    />
                  ),
              })}
            >
              {Object.entries({
                ...WelcomeStackCollection,
                ...HomeStackCollection,
                ...PagesStackCollection,
                ...BSStackCollection,
                ...ToolsStackCollection,
                ...CollabStackCollection,
              }).map(([name, screen]) => (
                <Stack.Screen name={name} key={name} {...screen} />
              ))}
            </Stack.Group>
          </Stack.Group>

          <Stack.Group
            screenOptions={({ route }) => ({
              presentation: IS_WEB ? "transparentModal" : "modal",
              ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
            })}
          >
            {Object.entries({
              ...FixedStackCollection,
            }).map(([name, screen]) => (
              <Stack.Screen name={name} key={name} {...screen} />
            ))}
          </Stack.Group>
        </Stack.Navigator>

        {welcomeWall && (
          <Animatable.View
            animation={"fadeIn"}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: C.background,
              position: "absolute",
              bottom: 0,
            }}
          >
            <AuthScreen />
          </Animatable.View>
        )}
      </>
    ) : (
      <Stack.Navigator {...rootStackConfig}>
        {frbsUser ? (
          <Stack.Group>
            <Stack.Screen name="Drawer" component={DrawerNavigator} />
            <Stack.Group
              screenOptions={({ route }) => ({
                presentation: "card",
                cardStyle: { backgroundColor: "transparent" },
                headerShown: true,
                ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
              })}
            >
              {Object.entries({
                ...HomeStackCollection,
                ...PagesStackCollection,
                ...BSStackCollection,
                ...ToolsStackCollection,
                ...CollabStackCollection,
              }).map(([name, screen]) => (
                <Stack.Screen name={name} key={name} {...screen} />
              ))}
            </Stack.Group>
          </Stack.Group>
        ) : (
          <Stack.Group
            screenOptions={({ route }) => ({
              presentation: "modal",
              headerShown: false,
              headerTitle: "",
            })}
          >
            {Object.entries({
              ...WelcomeStackCollection,
            }).map(([name, screen]) => (
              <Stack.Screen name={name} key={name} {...screen} />
            ))}
          </Stack.Group>
        )}
        <Stack.Group
          screenOptions={({ route }) => ({
            presentation: IS_WEB ? "transparentModal" : "modal",
            ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
          })}
        >
          {Object.entries({
            ...FixedStackCollection,
          }).map(([name, screen]) => (
            <Stack.Screen name={name} key={name} {...screen} />
          ))}
        </Stack.Group>
      </Stack.Navigator>
    );
    //#endregion
  }
}

/**
 * ###  Those overlay screens that place on top of other navigations
 * -  Think of Overlay, Dialog, Gallery screen
 */
const FixedStackCollection = {
  Overlay: {
    component: OverlayNavigator,
    options: ({ route }) => ({
      ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
      headerShown: false,
    }),
  },
  AudioPlayer: {
    component: AudioPlayerScreen,
    options: ({ route }) => ({
      ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
      headerShown: false,
    }),
  },
  Dialog: {
    component: DialogNavigator,
    options: ({ route }) => ({
      ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
      headerShown: false,
      gestureEnabled: false,
    }),
  },
  Layover: {
    component: LayoverNavigator,
    options: ({ route }) => ({
      ...presetNavConfig.headerTitle({ route, param: "headerTitle" }),
      headerShown: false,
      gestureEnabled: false,
    }),
  },
  Gallery: {
    component: GalleryScreen,
    options: {
      ...TransitionPresets.ScaleFromCenterAndroid,
      headerTitle: "",
      headerTransparent: true,
      headerShown: true,
      // safeAreaInsets: { top: 0 },
      headerBackImage: () => (
        <IconPrimr
          preset="circular"
          name="x"
          color={colors.text01}
          containerStyle={{
            marginHorizontal: spacing(4),
            backgroundColor: colors.primary,
          }}
        />
      ),
      headerRight: null,
    },
  },
};

export interface dOverlayParams {
  /** Declare hierarchyName1 in OverlayScreen's useLayoutEffect to display teamId name in url */
  hierarchyName1?: string;
  headerTitle: string;
  render: React.ReactElement;
  /**
   * Loading dialog time to show, default is 600
   */
  loadingTime?: number;
}

export interface dGalleryScrParams {
  /** Declare hierarchyName2 in GalleryScreen's useLayoutEffect to display teamId name in url */
  images: { url: string }[];
  imageIndex: number;
}

export interface dMainStkParams {
  hierarchyName: string;
}

export interface dFaqStkParams {
  hierarchyName: string;
}

export type ROOT_STK =
  | "Drawer"
  | keyof typeof UserDrawerCollection
  | keyof typeof FixedStackCollection;
