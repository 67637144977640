//#region [import]
import firebase from "firebase"
import { Reaction } from 'react-native-reactions';
import { IconPrimr, ICON_NAME } from "assets";
import { Avt, Buttoon, CardPosst, Kitten, ListHeader, Poppy, Skeleton, Toasty, Txt } from "components";
import { ListItemv2 } from "components/atoms/externals/ui-kitten/list-item-v2";
import { ShareAccountDialog } from "components/organisms/Teammates/ShareAccountDialog";
import { TraineesTeammates } from "components/organisms/Teammates/TraineesTeammates";
import { TrainersTeammates } from "components/organisms/Teammates/TrainersTeammates";
import { ACTION_TYPE, addActionLike, dUserAction, dUserLike, fn, FPATH, FRBS_ROLE, PosstSchema, updatePreviousLike, URL_PREFIX, useAppContext, useMembersCollection, UserSchema, useTeamWideActions, useTraineesActions, useUserActions, useUserProfile } from "engines";
import React, { useState } from "react";
import { Image, Pressable, View, ViewStyle } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { dSboardScrParams, useNavigator } from "screens/_navigation";
import { spacing, themeDark, tr } from "utils";
import { EmojiItemProp } from 'react-native-reactions/lib/components/ReactionView/types';
import { Text } from 'react-native-animatable';
import { ActionCommentsScreen } from "./CommentsScreen";
import { MemberInfo, UserStatsFeed } from "components/organisms";
import { CalendarScreen } from "screens/calendar/CalendarScreen";
import { ContactListItemDetails } from "components/molecules/members/ContactListItemDetails";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { MemberViewNavigationParams } from "components/organisms/members/MemberView";
//#endregion

const TotalPointsRequired = 9

export function TeamMatesScreen(props: P) {
  const { C, frbsUser } = useAppContext()
  const Navigation = useNavigator()
  // const userHandler = useUserProfile()
  const traineesHandler = useMembersCollection({
    where: ["growth.listBuilder.shareTo", "array-contains", frbsUser?.uid],
  });
  const userHandler = useUserProfile()
  const trainees = traineesHandler.data
  const lowActivityTrainees = trainees?.filter(tr => (tr.actionPoints || 0) < TotalPointsRequired)

  return <ScrollView style={{ backgroundColor: C.background }}>
    {userHandler.data?.roles?.includes(FRBS_ROLE.TRAINER) || trainees?.length > 0 ? <ListItemv2
      onPress={() => {
        Navigation.overlayPortal.open({
          headerTitle: "Trainees",
          render: <TraineesTeammates />
        })
      }}
      title={() => <Txt.H6>{`${trainees?.length || 0} trainees`}</Txt.H6>}
      description={() => <Avt.Row
        data={traineesHandler.data?.map((member) => ({
          name: member?.personali?.displayName || member?.personali?.email,
          source: {
            uri: member.personali?.photoURL,
          },
        }))}
      />}
      accessoryRight={() => <Kitten.ViewH>
        <Txt.S2>{"View all"}</Txt.S2>
        <IconPrimr name="arrow_right" color={C.text} />
      </Kitten.ViewH>
      } /> : null}

    <ListItemv2
      onPress={() => {
        if (userHandler.data?.growth?.listBuilder?.shareTo?.length < 1) {
          Navigation.dialogPortal.open({
            headerTitle: "Assign Trainer",
            render: <ShareAccountDialog />
          })
        } else {
          Navigation.overlayPortal.open({
            headerTitle: "Trainers",
            render: <TrainersTeammates />
          })
        }
      }}
      title={() => <Txt.H6>{`${userHandler.data?.growth?.listBuilder?.shareTo?.length} trainer${userHandler.data?.growth?.listBuilder?.shareTo?.length != 1 ? "s" : ""}`}</Txt.H6>}
      // description={() => <Avt.Row
      //   data={traineesHandler.data?.map((member) => ({
      //     name: member?.personali?.displayName || member?.personali?.email,
      //     source: {
      //       uri: member.personali?.photoURL,
      //     },
      //   }))}
      // />}
      accessoryRight={() => <Kitten.ViewH>
        <Txt.S2>{userHandler.data?.growth?.listBuilder?.shareTo?.length < 1 ? "Add trainer" : "View all"}</Txt.S2>
        <IconPrimr name={"arrow_right"} color={C.text} />
      </Kitten.ViewH>
      }
    />

    <ListHeader titleTx={"Low Activity Alerts"} />
    <ScrollView horizontal style={{ width: "100%", paddingHorizontal: spacing(2) }} showsHorizontalScrollIndicator={false}>
      {lowActivityTrainees && lowActivityTrainees?.map(trainee =>
        <Pressable key={trainee._id} onPress={() => {
          // Navigation.overlayPortal.open({
          //   headerTitle: trainee.personali?.displayName || "No Name Trainee",
          //   render: <MemberView uid={trainee._id} />
          // })
          Navigation.navigate("MemberScreen", { uid: trainee._id, userName: trainee.personali?.displayName } as MemberViewNavigationParams)
        }}>
          <View style={{ marginHorizontal: spacing(2) * 1.25, alignItems: "center", maxWidth: 110 }}>
            <Avt name={trainee.personali?.displayName} source={{ uri: trainee.personali?.photoURL }} style={{ width: 60, height: 60, borderWidth: 1, borderColor: C.hazardYellow }} />
            <Txt.P2 numberOfLines={2} style={{ textAlign: "center", color: C.hazardYellow }}>{trainee.personali?.displayName || "Unknown"}</Txt.P2>
            <Txt.Helper style={{ color: C.hazardYellow }}>{`${trainee?.actionPoints || 0} points`}</Txt.Helper>
          </View>
        </Pressable>)}
    </ScrollView>

    {/* <ListHeader titleTx={trainees?.length > 0 ? "Trainees Activity" : "Your Activity"} /> */}

    {userHandler.data && trainees ? <ActionsFeed feedType={trainees.length > 0 ? "trainees" : "user"} /> : <Skeleton><Kitten.Spinner /></Skeleton>}
  </ScrollView>
}

/**
 * ### Displays all action items for the specified feedType
 * - provide alternative uid if desired to display a different user
 * - NOTE: does not include ScrollView or surrounding container, just lists all the items
 * 
 * @example
 * <ActionsFeed feedType="team" />
 */
export function ActionsFeed(p: { uid?: string, feedType: "team" | "user" | "trainees" }) {
  const { feedType = "team", uid } = p
  const { frbsUser, teamId } = useAppContext()
  const _userId = uid ? uid : frbsUser?.uid
  const [itemMax, setItemMax] = React.useState<number>(40);

  const actionsHandler = feedType === "team" ? useTeamWideActions(teamId, itemMax) :
    feedType === "trainees" ? useTraineesActions(_userId, itemMax)
      : useUserActions(_userId, {}, itemMax)

  const [actions, setActions] = React.useState<dUserAction[]>();

  const startOfToday = () => {
    const dateObj = new Date()
    dateObj.setHours(0, 0, 0, 0)
    return dateObj
  }
  const startOfYesterday = () => {
    const dateObj = startOfToday()
    dateObj.setDate(dateObj.getDate() - 1)
    return dateObj
  }

  React.useEffect(function updateUserActions() {
    if (!actionsHandler.data) return;
    const _updatedActions = actionsHandler.combineAndCondense([ACTION_TYPE.CONTENT_VIEW])
    setActions(_updatedActions || actionsHandler.data)
  }, [actionsHandler.data, itemMax]);

  const todaysActions = actions?.filter(a => a.occurredAt?.toDate() >= startOfToday())
  const yesterdaysActions = actions?.filter(a => a.occurredAt?.toDate() < startOfToday() && a.occurredAt?.toDate() > startOfYesterday())
  const laterActions = actions?.filter(a => a.occurredAt?.toDate() < startOfYesterday())

  return <ScrollView style={{ flex: 1 }}>
    {todaysActions?.length > 0 ? <ListHeader titleTx="Today" /> : null}
    {todaysActions ? todaysActions.map(action => <ActionItem action={action} key={action._id} />) :
      <View style={{ justifyContent: "center", alignItems: "center", marginTop: 150, width: "100%" }}>
        <Kitten.Spinner />
      </View>}
    {yesterdaysActions?.length > 0 ? <ListHeader titleTx={"Yesterday"} /> : null}
    {yesterdaysActions ? yesterdaysActions.map(action => <ActionItem action={action} key={action._id} />) : null}
    {laterActions?.length > 0 ? <ListHeader titleTx={"Last 30 days"} /> : null}
    {laterActions ? laterActions.map(action => <ActionItem action={action} key={action._id} />) : null}
    {actions && actions?.length < 1 ? <Txt.S1 style={{ marginTop: 150, alignSelf: "center", textAlign: "center" }}>Nothing yet.</Txt.S1> : null}
    {actionsHandler.data && itemMax <= actionsHandler.data?.length ?
      <Buttoon appearance="ghost" style={{ marginBottom: spacing(4) }} onPress={() => {
        setItemMax(itemMax + 35)
      }}>{"Load more"}</Buttoon> : null}
  </ScrollView>
}

export function ActionItem(p: { action: dUserAction }) {
  const { action } = p
  const Navigation = useNavigator()
  const { C, frbsUser } = useAppContext()

  const avtSize = 44

  async function handleActionPress() {
    if (action.count > 1) {
      Navigation.navigate("ActionsGroup", {
        action
      })
      return;
    }
    const performDefaultNavigation = () => {
      Navigation.overlayPortal.open({
        headerTitle: action.displayName || "User Details",
        render: <MemberInfo
          memberId={action.uid}
          type={"teammates"}
        />
      })
    }
    const performContactNavigation = async () => {
      const contact = (await firebase.firestore().doc(`${FPATH.USERS}/${action.uid}/${FPATH.CONTACTS}/${action.location?.replace(URL_PREFIX.CONTACT, "")}`).get()).data() as ContactSchemaa
      if (!contact) {
        Poppy("Contact not found", "Could not find this user's contact.")
        return;
      }
      Navigation.overlayPortal.open({
        headerTitle: "New Contact",
        render: <ContactListItemDetails userId={frbsUser?.uid} contact={contact} />
      })
    }
    const performCalendarNavigation = () => {
      Navigation.overlayPortal.open({
        headerTitle: "Calendar",
        render: <CalendarScreen />
      })
    }
    const performContentNavigation = () => {
      const _strs = action.location?.replace(URL_PREFIX.PAGE, "")?.split("/")
      const cpid = _strs[0]
      const itemId = _strs.length > 1 ? _strs[1] : null
      Navigation.navigate("Page Contents", {
        cpid,
        itemId
      })
    }
    const performPosstNavigation = async () => {
      const _posst: PosstSchema = (await firebase.firestore().doc(`${FPATH.POSSTS}/${action.location}`).get()).data() as PosstSchema
      if (!_posst) {
        Toasty.show("Announcement not found. It may have been deleted.", { type: "warning" })
        return;
      }
      Navigation.dialogPortal.open({
        headerTitle: "Announcement",
        render: <CardPosst posst={_posst} />
      })
    }

    switch (action.actionType) {
      case ACTION_TYPE.ADDED_GUESTS:
        performCalendarNavigation()
        break;
      case ACTION_TYPE.ADD_CONTACT:
        performContactNavigation()
        break;
      case ACTION_TYPE.BUILD_MY_LIST:
        Navigation.navigate("Contact Group Screen", { id: action.location || "BML", c_uid: action.uid })
        break;
      case ACTION_TYPE.SEND_SMS:
        performContactNavigation()
        break;
      case ACTION_TYPE.SEND_EMAIL:
        performContactNavigation()
        break;
      case ACTION_TYPE.CONTENT_SHARE:
        performContentNavigation()
        break;
      case ACTION_TYPE.BLAST_TEXT:
        Navigation.overlayPortal.open({
          headerTitle: "Blast Text",
          render: <View>
            <Txt.S1 style={{ margin: spacing(3), textAlign: "center" }}>{action.description}</Txt.S1>
            <Txt.P1 style={{ marginHorizontal: spacing(3) }}>{`\"${action.involvedPartyName}\"` || "Unknown message sent"}</Txt.P1>
          </View>
        })
        break;
      case ACTION_TYPE.SCHEDULED_MEETING:
        if (action.location?.startsWith(URL_PREFIX.CONTACT))
          performContactNavigation()
        else
          performDefaultNavigation()
        break;
      case ACTION_TYPE.CONTENT_VIEW:
        if (!action.location?.startsWith(URL_PREFIX.PAGE))
          performDefaultNavigation()
        else
          performContentNavigation()
        break;
      case ACTION_TYPE.POSST_COMMENT:
        performPosstNavigation()
        break;
      case ACTION_TYPE.POSST_LIKE:
        performPosstNavigation()
        break;
      case ACTION_TYPE.DAILY_PROGRESS_REPORT:
        Navigation.overlayPortal.open({
          headerTitle: "Reported Stats",
          render: <View>
            <UserStatsOverview uid={action.uid} />
            <UserStatsFeed uid={action.uid} />
          </View>
        })
        break;
      case ACTION_TYPE.SCOREBOARD_ENTRY:
        Navigation.navigate("sboard-scr", { sbid: action.location })
        break;
      default:
        performDefaultNavigation()
        break;
    }
  }

  /** types of actions to display a full large scale image for */
  const displayLargeImageTypes: ACTION_TYPE[] = [ACTION_TYPE.UPLOADED_PROFILE_IMAGE]
  // other .thumbnails else will be a smaller thumbnail icon
  const thumbnailDimension = 50

  const getIconForAction: (action: dUserAction) => ICON_NAME = (action) => {
    switch (action.actionType) {
      case ACTION_TYPE.ADDED_GUESTS:
        return "people"
      case ACTION_TYPE.ADD_CONTACT:
        return "add_person"
      case ACTION_TYPE.ADD_TASK:
        return "add_to_list"
      case ACTION_TYPE.ASSIGNED_TRAINER:
        return "admin"
      case ACTION_TYPE.UNASSIGNED_TRAINER:
        return "admin"
      case ACTION_TYPE.BLAST_TEXT:
        return "bullhorn"
      case ACTION_TYPE.BUILD_MY_LIST:
        return "list"
      case ACTION_TYPE.COMPLETE_TASK:
        return "check_circle"
      case ACTION_TYPE.CONTENT_VIEW:
        return "media_resource"
      case ACTION_TYPE.DAILY_PROGRESS_REPORT:
        return "pencil"
      case ACTION_TYPE.IMPORT_ALL_CONTACTS:
        return "people"
      case ACTION_TYPE.JOINED_MEETING:
        return "calendar"
      case ACTION_TYPE.LEVEL_COMPLETION:
        return "trophy"
      case ACTION_TYPE.POSST_COMMENT:
        return "message"
      case ACTION_TYPE.POSST_LIKE:
        return "heart"
      case ACTION_TYPE.SCHEDULED_MEETING:
        return "calendar"
      case ACTION_TYPE.SCOREBOARD_ENTRY:
        return "scoreboard"
      case ACTION_TYPE.SEND_EMAIL:
        return "email"
      case ACTION_TYPE.SEND_SMS:
        return "sms"
      case ACTION_TYPE.UPLOADED_PROFILE_IMAGE:
        return "profile"
      default:
        return "info"
    }
  }

  return <ListItemv2
    onPress={handleActionPress}
    // title={action.displayName}
    title={() => <Kitten.ViewH style={{ alignItems: "flex-start", justifyContent: "space-between" }}>
      <Txt.P1 style={{ ...(action.thumbnail ? { maxWidth: "84.5%" } : { maxWidth: "90%" }) }}>{action.groupedDescription || action.description}</Txt.P1>
      {action.thumbnail ? <View>
        <Image source={{ uri: fn.media.smallerSizeFromURL(action.thumbnail, thumbnailDimension * 4) }} style={{ width: thumbnailDimension, height: thumbnailDimension, borderRadius: 7 }} />
        {action.actionType === ACTION_TYPE.CONTENT_VIEW && action.description?.includes("watched") ? <IconPrimr name="play" solid containerStyle={{ position: "absolute", bottom: 0, right: 0, alignSelf: "center" }} size={13} color={C.pitchWhite} /> : null}
      </View> : <IconPrimr name={getIconForAction(action)} color={C.text} containerStyle={{ opacity: 0.8 }} size={20} />}
      {/* <Txt.Indicator>{fn.calendar.socialFriendlyTime(action.occurredAt?.toDate())}</Txt.Indicator> */}
    </Kitten.ViewH>}
    description={() => <>
      <Kitten.ViewH style={{ alignSelf: "flex-end", justifyContent: "space-between", width: "100%", marginTop: spacing(3), zIndex: 150 }}>
        <Txt.Indicator>{fn.calendar.socialFriendlyTime(action.occurredAt?.toDate())}</Txt.Indicator>
        <Buttoon icon={{ name: "chat_bubble" }} size="small" appearance="ghost" status="basic" onPress={() => {
          Navigation.overlayPortal.open({
            headerTitle: "Comments",
            render: <ActionCommentsScreen action={action} />
          })
        }}>
          {action.comments?.length > 0 ? `View all ${action.comments?.length || 0} comments` : "View comments"}
        </Buttoon>
      </Kitten.ViewH>
      <View style={{ alignItems: "flex-end" }}>
        <LikerBox
          containerStyle={{ marginRight: spacing(3) }}
          likes={action.likes || []} onLikeSelected={like => {
            if (action.likes.find(_l => _l.uid === frbsUser?.uid)) {
              updatePreviousLike(frbsUser?.uid, action, like)
            } else {
              addActionLike(action, like)
            }
          }} />
      </View>
    </>}
    // accessoryRight={() => action.thumbnail ? <Image source={{ uri: action.thumbnail }} style={{ width: 45, height: 45, borderRadius: 7 }} /> : null}
    accessoryLeft={() => <View style={{ justifyContent: "flex-start", flex: 1 }}>
      <Pressable onPress={() => {
        Navigation.overlayPortal.open({
          headerTitle: action.displayName || "User Details",
          render: <MemberInfo
            memberId={action.uid}
            type={"teammates"}
          />
        })
      }}>
        <Avt source={{ uri: fn.media.smallerSizeFromURL(action.profileImage, avtSize * 5) }}
          name={action.displayName}
          style={{ borderWidth: 0.3, borderColor: C.grey600, width: avtSize, height: avtSize }} />
      </Pressable>
    </View>}
  />
}

const ReactionItems = [
  {
    id: 0,
    emoji: '👍',
    title: 'like',
  },
  {
    id: 1,
    emoji: '❤️',
    title: 'love',
  },
  {
    id: 2,
    emoji: '🔥',
    title: 'fire',
  },
  {
    id: 3,
    emoji: '👊',
    title: 'fist',
  },
  {
    id: 4,
    emoji: '😂',
    title: 'laugh',
  },
  {
    id: 5,
    emoji: '😎',
    title: 'cool',
  },
];

function LikerBox(p: { likes: dUserLike[], onLikeSelected: (like: dUserLike) => void, containerStyle?: ViewStyle }) {
  const { onLikeSelected, containerStyle = {} } = p

  const { C, frbsUser } = useAppContext()

  const [likes, setLikes] = React.useState<dUserLike[]>(p.likes);
  const [selectedEmoji, setSelectedEmoji] = useState<EmojiItemProp>();

  React.useEffect(function updateLikes() {
    const mylike = likes.find(_l => _l.uid === frbsUser?.uid)
    if (mylike) setSelectedEmoji({ id: 0, title: "You", emoji: mylike.emoji })
    setLikes(likes)
  }, [likes]);

  const emojiTypes: string[] = []
  for (let l in likes) {
    const _like = likes[l]
    if (_like && _like.emoji && !emojiTypes.includes(_like.emoji))
      emojiTypes.push(_like.emoji)
  }

  const likeCountForEmoji = (emo: string) => {
    return likes.filter(_l => _l.emoji === emo)?.length || 0
  }

  const LikeItem = ({ value, ghost, highlight }) => <View style={{ marginRight: spacing(2), backgroundColor: highlight ? C.hazardYellow : C.infoBlue, borderRadius: 25, paddingHorizontal: spacing(3), paddingVertical: spacing(1), justifyContent: "center", alignItems: "center", borderWidth: ghost ? 0.6 : 0, borderColor: C.accent }}>
    <Text style={{ fontSize: 14, color: themeDark.text, fontFamily: "futura", fontWeight: "bold" }}>{value}</Text>
  </View>

  const updateLikes = (newLike: dUserLike) => {
    setLikes(likes.find(_l => _l.uid === frbsUser?.uid) ? likes.map(_l => _l.uid === frbsUser?.uid ? newLike : _l) : likes.concat([newLike]))
  }

  return <Kitten.ViewH style={containerStyle}>
    {emojiTypes.map(emo => <TouchableOpacity onPress={() => {
      const newLike = { uid: frbsUser?.uid, likedAt: firebase.firestore.Timestamp.now(), emoji: emo }
      updateLikes(newLike)
      onLikeSelected && onLikeSelected(newLike)
      setSelectedEmoji({ id: 0, title: "You", emoji: emo })

    }}>
      <LikeItem value={`${likeCountForEmoji(emo)} ${emo}`} highlight={emo === likes.find(_l => _l.uid === frbsUser?.uid)?.emoji} />
    </TouchableOpacity>
    )}
    <Reaction items={ReactionItems} onTap={selectValue => {
      setSelectedEmoji(selectValue)
      const newLike = { uid: frbsUser?.uid, likedAt: firebase.firestore.Timestamp.now(), emoji: selectValue.emoji.toString() }
      updateLikes(newLike)
      onLikeSelected && onLikeSelected(newLike)
    }} type="modal" showPopupType='onPress'>
      <LikeItem value={`Like ${selectedEmoji ? selectedEmoji.emoji.toString() : ""}`} ghost />
    </Reaction>
  </Kitten.ViewH>
}

/**
 * ### Scoreboard screen
 *  ----
 *  @version 21.03.01
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function TeamMatesSceenOG(props: P) {
  const { C } = useAppContext();
  // return <O.ScoreboardCenter sbid={sbid} />;
  const [doesHaveTrainees, setDoesHaveTrainees] =
    React.useState<boolean>(false);
  try {
    const Navigation = useNavigator();
    return (
      // <SafeAreaView>
      <ScrollView style={{ backgroundColor: C.background }}>
        <View style={{ width: "100%", height: spacing(3) }} />
        <ListHeader
          titleTx={tr("Your Trainers")}
          actions={[
            {
              label: tr("Assign trainer"),
              appearance: "filled",
              icon: { name: "share" },
              onPress: () => {
                Navigation.dialogPortal.open({
                  headerTitle: tr("Assign Trainer"),
                  render: <ShareAccountDialog />,
                });
              },
            },
          ]}
        />

        <TrainersTeammates />

        {/* <View style={{ width: "100%", height: spacing(5) }} /> */}
        <Kitten.Divider_________ style={{ marginVertical: spacing(4) }} />

        <ListHeader titleTx={tr("People You're Training")} />
        <TraineesTeammates
          hide={!doesHaveTrainees}
          onLoadTrainees={(trainees) => {
            if (trainees?.length > 0) setDoesHaveTrainees(true);
          }}
        />
        {!doesHaveTrainees ? (
          <Txt.Indicator style={{ marginTop: spacing(3) }}>
            If someone assigns you as their field trainer they will show up
            here!
          </Txt.Indicator>
        ) : null}
      </ScrollView>
      // </SafeAreaView>
    );
  } catch (error) {
    throw new Error(`::U2NvcmVib2FyZFNjcmVlbg==:: ${error}`);
  }
}

/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: dSboardScrParams }; //* unmask if we pass params thru rnav
}
