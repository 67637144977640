/**
 * ###
 *  - This function allow us to be able to format the number and then return a string,
 *  we could change the .toFixed value to any number in other to increase or reduce the number of values after the decimal
 *  ----
 *  @example
 *  formatNumber(2000)
 *  expected result 2,000
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.06.30
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function VideoLinkChecker(value: string) {
  var check: string;
  if (
    (value
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .replace("youtu.be/", "youtube.com?v=")
      .slice(0, 14) ===
      "youtube.com?v=") ==
    true
  ) {
    return (check = "youtube");
  } else if (
    value.includes("vimeo.com")
    // /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(
    //   inputValue
    // )
  ) {
    console.log("vimeo checkker");
    return (check = "vimeo");
  } else if (value.includes("res.cloudinary.com")) {
    if (value.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      console.log("image checkker");
      return (check = "image");
    } else {
      console.log("video checkker");
      return (check = "video");
    }
  } else if (value.includes("www.dropbox.com")) {
    console.log("dropbox checkker");
    return (check = "dropbox");
  }
}

/**
 * ### Check if youtube url is valid:
 * -  If it's valid, return *youtubeID*
 * -  Else, return false
 */
export function matchYoutubeUrl(url: string) {
  if (url.includes("youtube.com/shorts")) return true;
  var p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    return url.match(p)[1];
  }
  return false;
}

export function matchVimeoURL(url: string) {
  return fetch(`https://vimeo.com/api/oembed.json?url=${url}`)
    .then((response) => {
      if (!response.ok) {
        // Invalid URL
        // throw new Error("Invalid URL");
        return false;
      } else {
        // valid URL
        // console.log(response);
        return true;
      }
    })
    .catch((err) => {
      // Invalid URL
      console.error("match vimeo: ", err);
      return false;
    });

  // return /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)(?:\S+)?$/.test(
  //   url
  // );
}

// const videoURL = "https://vimeo.com/133693532";
