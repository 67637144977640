import { Kitten } from "components/atoms";
import { useSx } from "dripsy";
import { FPATH, ScoreboardSchema, useAppContext, UserSchema } from "engines";
import firebase from "firebase";
import { useState } from "react";
import { dScore } from "../../../engines/backends/schemas";
import {
    MediaPageRequirement,
    MediaPageSchema
} from "../../../engines/backends/schemas/media/media.schema";
import { useUserProfile } from "../../../engines/firebase/handler/members-handler";
import { spacing } from "../../../utils/styles/spacing";
import { Txt } from "../../atoms/generals/txt/Txt";

export const PageRequirmentCenter = (p: {
  page: MediaPageSchema;
  setshowContents: (shouldShow: boolean) => void;
}) => {
  const { page, setshowContents } = p;
  const { C } = useAppContext();
  const sx = useSx();

  const userHandler = useUserProfile();

  /** Scores the user does have to show them what they have accomplished so far */
  const [_userScores, setUserScores] = useState<{ [_sbid: string]: number }>(
    {}
  );

  async function testAccess() {
    const userD: UserSchema = userHandler.data;

    /** User has acess to this page for sure, so no need to check requirements */
    if ((userD?.unlockedPages || []).includes(page?._id)) {
      setshowContents(true);
      return;
    }

    const accessRequirements: MediaPageRequirement[] =
      page?.accessRequirements as MediaPageRequirement[];

    let shouldGrantAccess = true;

    const __updatedUserScores = {};

    for (let a in accessRequirements) {
      const requirement = accessRequirements[a];
      if (requirement._sbid) {
        const scoreboard: ScoreboardSchema = (
          await firebase
            .firestore()
            .doc(`${FPATH.SCOREBOARDS}/${requirement._sbid}`)
            .get()
        ).data();
        //   userD.personali?.phoneNumber
        const usersScore: dScore = scoreboard.people.find(
          (_s) =>
            _s.uid === userD._id ||
            (_s.identifier &&
              _s.identifier?.replace("+1", "") ===
                userD.personali?.phoneNumber) ||
            (_s.identifier && _s.identifier === userD.personali?.email)
        );
        if (usersScore && usersScore.score >= (requirement.minNumber || 0)) {
          // this requirment is met!
        } else {
          // requirment not met, don't let them in
          shouldGrantAccess = false;
        }
        if (usersScore) {
          // we update the user scores state to show how much they have completed
          __updatedUserScores[requirement._sbid] = usersScore.score;
        }
      }
    }

    if (shouldGrantAccess) {
      // if we get here, then all checks succeeded, allow access!
      setshowContents(true);
    } else {
      // access denied, do nothing except update current scores to show that
      setUserScores(__updatedUserScores);
    }
    // setAccessGranted(true);
  }

  testAccess();

  return (
    <Kitten.Card
      style={sx({
        width: ["100%", "100%", "30%"],
        height: ["100%", "100%", "50%"],
        justifyContent: "center",
        borderColor: C.hazardYellow,
      })}
    >
      <Txt.H6
        style={{ marginBottom: spacing(4) }}
      >{`You must meet the following requirements to access this page.`}</Txt.H6>
      {page.accessRequirements?.map((requirement) => (
        <Txt.S1>{` ${requirement.title}: ${
          _userScores[requirement._sbid || ""] || "0"
        }/${requirement.minNumber}`}</Txt.S1>
      ))}
    </Kitten.Card>
  );
};
