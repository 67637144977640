import { URL_PREFIX } from "engines/backends";

/**
 * ###
 *  - Function is used in checking if a url is a build my list button
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.02.09
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export const isBMLButton = (url: string): boolean => {
  // console.log("Coming here with url ", url);
  return (
    url &&
    url
      .toLowerCase()
      .includes((URL_PREFIX.SHORTCUT + "buildmylist").toLowerCase())
  );
};
