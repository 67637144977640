//#region [section] imports and BML settings setup
import { IconPrimr } from "assets";
import { Inpuut, Kitten, Toasty, Txt } from "components";
import ListItemDeleteAction from "components/atoms/lists/SwipeyList/ListItemDeleteAction";
import {
  dQualification,
  dQuestionCard,
  useAppContext,
  useUserProfile,
} from "engines";
import {
  getKeys,
  updateConfigVariables,
} from "engines/backends/auth/AuthHandler";
import {
  BmlQSchema,
  defaultQualification,
  defaultQuestions,
  QuestionType,
} from "engines/backends/schemas/bml";
import { dConfigVariables } from "engines/firebase/firebase.props";
import { validURL } from "engines/functions/media-functions";
import React, { useRef, useState } from "react";
import { Alert, FlatList, StyleSheet, View } from "react-native";
import { Swipeable } from "react-native-gesture-handler";
import { useNavigator } from "screens/_navigation";
import { spacing } from "utils";
import BMLSettingsMenu, { SETTINGS } from "./BMLSettingsMenu";

interface dBML {
  route: any;
}

enum ACTIONTYPE {
  "ADD" = "add",
  "DELETE" = "delete",
  "UPDATE" = "update",
}

const defaultQuestionSetting: BmlQSchema = {
  _qid: "1",
  _teamId: "",
  questionType: QuestionType.TEXT,
  question: "",
  mediaUri: null,
  points: null,
  createdAt: new Date(),
};
//#endregion

/**
 * ### This is Build My List App Setting Component
 *  - This component will allow users to modify the questionnaire for BML
 *  - Users can set their custom questions or use the default one.
 *  ----
 *  @example
 *  <BMLSettings />
 *  ----
 *  @version 21.06.16
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export function BMLSettings(props: dBML) {
  // const { navigation } = props;
  // const { bmlData }: { bmlData: BmlQSchema[] } = props.route.params;
  const { C, teamId } = useAppContext();
  const user = useUserProfile();
  const [questions, setQuestions] = useState<BmlQSchema[]>([]);
  const [isCustomQuestion, setIsCustomQuestion] = useState(false);
  const [questionnaireBML, setQuestionnaireBML] = useState<BmlQSchema[]>([]);
  const [qualificationBML, setQualificationBML] = useState<dQualification[]>(
    []
  );
  const [configVariables, setConfigVariables] = useState<dConfigVariables>({
    bmlQuestionnaire: [],
  } as dConfigVariables);
  const [filterType, setFilterType] = React.useState<QuestionType>(null);
  const FLScrollRef = useRef<FlatList>(null);
  const [dataReady, setDataReady] = useState(false);
  const admin = user._isUserAdmin();
  const Navigation = useNavigator();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const getBMLQualificationData = async () => {
    const configData = await getKeys(teamId);
    const cVariables: dConfigVariables = configData[1];

    if (cVariables.qualifications && cVariables.qualifications.length > 0) {
      setQualificationBML(cVariables.qualifications);
    } else {
      setQualificationBML(defaultQualification);
      cVariables.qualifications.push(...defaultQualification);
    }

    setConfigVariables(cVariables);
    setDataReady(true);
  };

  const getBMLQuestionnaireData = async () => {
    const configData = await getKeys(teamId);
    const cVariables: dConfigVariables = configData[1];

    if (
      cVariables.bmlQuestionnaire &&
      cVariables.bmlQuestionnaire?.length > 0 &&
      cVariables.bmlQuestionnaire.findIndex(
        (b) => b.questionType === QuestionType.QUALIFICATION
      ) !== -1
    ) {
      setQuestionnaireBML(
        cVariables.bmlQuestionnaire.filter(
          (q) => q.questionType !== QuestionType.VIDEO
        )
      );
    } else {
      setQuestionnaireBML(defaultQuestions);
      cVariables.bmlQuestionnaire = [];
      cVariables.bmlQuestionnaire.push(...defaultQuestions);
      saveConfigVariables(cVariables);
    }

    setConfigVariables(cVariables);
    setDataReady(true);
  };

  const _init = async () => {
    if (questionnaireBML.length === 0 && questions.length <= 0 && dataReady) {
      setIsCustomQuestion(false);
      const initQuestionSet: BmlQSchema[] = [];
      const initQuestionValues = defaultQuestions;

      initQuestionValues.forEach((screen) => {
        initQuestionSet.push(screen);
      });

      initQuestionSet.forEach((question) => {
        questionnaireBML.push({
          ...defaultQuestionSetting,
          _teamId: teamId,
          _qid: (questionnaireBML.length + 1).toString(),
          question: question.question,
          questionType: question.questionType,
          mediaUri:
            question.questionType === QuestionType.VIDEO
              ? question.mediaUri
              : "",
        });
      });

      if (!configVariables) {
        configVariables.bmlQuestionnaire = [];
      }
      configVariables.bmlQuestionnaire.push(...questionnaireBML);
      setQuestions(questionnaireBML);
      saveConfigVariables(configVariables);
    } else {
      setQuestions(questionnaireBML);
    }
  };

  const handleButtonPress = async (
    id: string,
    item: string,
    actionType: ACTIONTYPE
  ) => {
    let updatedQuestion: BmlQSchema = {} as BmlQSchema;
    const index = questions.findIndex((q) => q._qid === id);
    const qType = questions[index].questionType;
    if (
      qType === QuestionType.TEXT ||
      (qType === QuestionType.ENCOURAGEMENT && actionType !== ACTIONTYPE.DELETE)
    ) {
      updatedQuestion = {
        ...questions[index],
        question: item,
      };
    } else if (
      qType === QuestionType.VIDEO &&
      actionType !== ACTIONTYPE.DELETE
    ) {
      updatedQuestion = {
        ...questions[index],
        mediaUri: item,
      };
    }

    // let updatedQuestionSet = questions.filter(
    //   (x) => x._qid !== questions[index]._qid
    // );
    let updatedQuestionSet = questions.concat([])

    // let questionItem: BmlQSchema[] = [];

    switch (actionType) {
      case ACTIONTYPE.UPDATE:
        // questionItem = [...updatedQuestionSet, updatedQuestion];
        updatedQuestionSet = updatedQuestionSet.map(u => u._qid === questions[index]._qid ? updatedQuestion : u)
        break;
      case ACTIONTYPE.DELETE:
        // questionItem = [...updatedQuestionSet];
        updatedQuestionSet = updatedQuestionSet.filter(x => x._qid !== questions[index]._qid)
      default:
        break;
    }
    setQuestions(updatedQuestionSet);
    configVariables.bmlQuestionnaire = updatedQuestionSet;

    saveConfigVariables(configVariables);
  };

  const saveConfigVariables = async (configVariables: dConfigVariables) => {
    configVariables.bmlQuestionnaire = configVariables.bmlQuestionnaire.sort(
      (a, b) => parseInt(a._qid) - parseInt(b._qid)
    );
    configVariables.qualifications = configVariables.qualifications.sort(
      (a, b) => parseInt(a._id) - parseInt(b._id)
    );
    await updateConfigVariables(teamId, configVariables);
  };

  //#region [ANCHOR] Options for separate tabs
  React.useEffect(() => {
    questionnaireBML && questionnaireBML.length > 0
      ? setQuestions(questionnaireBML)
      : getBMLQuestionnaireData();
    getBMLQualificationData();
    if (!isCustomQuestion && dataReady) _init();
  }, [dataReady, questionnaireBML]);

  React.useEffect(() => {
    //NOTE selectedIndex =0 is BML Questionnaire
    //     selectedIndex =1 is BML Qualifications
    admin && selectedIndex === 0
      ? Navigation.setOptions({
        headerRight: () => (
          <View style={{ flexDirection: "row" }}>
            <BMLSettingsMenu
              filterFunction={setFilterType}
              type={SETTINGS.FILTER}
            />
            {/* <BMLSettingsMenu addFunction={handleAdd} type={"New"} /> */}
          </View>
        ),
      })
      : Navigation.setOptions({
        headerRight: () => (
          <View style={{ flexDirection: "row" }}>
            <BMLSettingsMenu
              filterFunction={setFilterType}
              type={SETTINGS.ADDQUALIFICATION}
            />
            {/* <BMLSettingsMenu addFunction={handleAdd} type={"New"} /> */}
          </View>
        ),
      });
  }, [Navigation, selectedIndex]);
  //#endregion

  //TODO For future update in this feature
  // const handleAdd = (questionType: QuestionType) => {
  //   setIsCustomQuestion(true);
  //   setFilterType(null);

  //   let newQuestion = defaultQuestionSetting;
  //   newQuestion.questionType = questionType;
  //   newQuestion._qid =
  //     questions.length > 0
  //       ? (parseInt(questions[questions.length - 1]._qid) + 1).toString()
  //       : "1";
  //   FLScrollRef.current.scrollToEnd({ animated: true });
  //   setQuestions([...questions, newQuestion]);
  // };
  const QualificationsTab = () => {
    return (
      <>
        <Txt.H6
          style={{ margin: 10, flexWrap: "wrap", color: C["color-danger-500"] }}
        >
          {
            "This feature has been disabled as it can affect your's and your trainee's contact list.\n\nPlease reach out to App Takeoff team if you have any questions."
          }
        </Txt.H6>
        <FlatList
          keyExtractor={(item) => item._id}
          data={qualificationBML}
          scrollsToTop
          style={{ width: "100%" }}
          overScrollMode="never"
          ItemSeparatorComponent={Kitten.Divider}
          renderItem={({
            item,
            index,
          }: {
            item: dQualification;
            index: number;
          }) => <QualificationCard data={item} />}
        />
      </>
    );
  };

  const QuestionnaireTab = () => {
    return (
      <FlatList
        data={
          filterType
            ? questions
              .filter((q) => q.questionType === filterType)
              .sort((a, b) => parseInt(a._qid) - parseInt(b._qid))
            : questions.sort((a, b) => parseInt(a._qid) - parseInt(b._qid))
        }
        ref={FLScrollRef}
        scrollsToTop
        // overScrollMode="auto"
        keyExtractor={(item) => item?._qid?.toString()}
        renderItem={({ item, index }: { item: BmlQSchema; index: number }) => (
          <QuestionCard
            data={{ item, index: index.toString() }}
            handleSavePress={(index: string, item: string) => {
              if (item.length > 0)
                handleButtonPress(index, item, ACTIONTYPE.UPDATE);
              else Alert.alert("Question cannot be left blank");
            }}
            handleDelete={(index: string, item: string) =>
              handleButtonPress(index, item, ACTIONTYPE.DELETE)
            }
            customQuestion={isCustomQuestion}
          />
        )}
        ItemSeparatorComponent={Kitten.Divider}
      />
    );
  };

  try {
    return (
      <View style={[styles.container, { backgroundColor: C.background }]}>
        <Kitten.TabView
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}
        >
          <Kitten.Tab title="Questionnaire">
            <Kitten.Layout style={styles.tabContainer}>
              <QuestionnaireTab />
            </Kitten.Layout>
          </Kitten.Tab>
          <Kitten.Tab title="Qualifications">
            <Kitten.Layout style={styles.tabContainer}>
              <QualificationsTab />
            </Kitten.Layout>
          </Kitten.Tab>
        </Kitten.TabView>
      </View>
    );
  } catch (error) {
    throw Error(`::Qk1MU2V0dGluZ3M=::${error}`);
  }
}

const QuestionCard = (props: dQuestionCard) => {
  const { data, handleSavePress, handleDelete, customQuestion = false } = props;
  const [screenQuestion, setScreenQuestion] = React.useState(
    data.item.question ? data.item.question : ""
  );

  const [videoLink, setVideoLink] = useState(
    data.item?.questionType === QuestionType.VIDEO ? data.item.mediaUri : ""
  );

  const { C } = useAppContext();
  const user = useUserProfile();
  const admin = user._isUserAdmin();
  const [unlockEditing, setUnlockEditing] = useState(
    customQuestion ? true : false
  );

  return (
    <Kitten.Card style={[styles.kittenCard, { maxWidth: "95%" }]} disabled>
      {/* <Inpuut
        style={{ backgroundColor: C.background }}
        placeholder="Question"
        value={
          data.item.questionType === QuestionType.TEXT
            ? `Question ${data?.item?._qid}`
            : `${data?.item?.questionType?.valueOf()?.toUpperCase()} Message`
        }
        returnKeyType="done"
        autoCapitalize="words"
        disabled
      /> */}
      <Txt.S1 style={{ marginBottom: spacing(3) }}>{data?.item?.questionType === QuestionType.TEXT
        ? `Question ${data?.item?._qid}`
        : `${data?.item?.questionType?.valueOf()?.toUpperCase()} Message`}</Txt.S1>
      <Inpuut
        style={styles.questionValue}
        placeholder="Type your question here..."
        value={screenQuestion !== "" ? screenQuestion : videoLink}
        multiline={true}
        onChangeText={(value) => {
          setScreenQuestion(value);
          if (data.item.questionType === QuestionType.VIDEO)
            setVideoLink(value);
        }}
        onSavePress={() => {
          if (data.item.questionType === QuestionType.VIDEO) {
            if (validURL(videoLink)) {
              handleSavePress(data.item._qid, videoLink);
            } else {
              Alert.alert("Invalid Video Link Entered");
              return;
            }
          } else {
            handleSavePress(data.item._qid, screenQuestion);
          }
          setUnlockEditing(!unlockEditing);
        }}
        returnKeyType="done"
        keyboardType="default"
        autoCorrect={true}
        // autoCapitalize="none"
        disabled={
          !admin ||
          (data.item.questionType !== QuestionType.TEXT && !unlockEditing)
        }
        accessoryLeft={() => {
          return (data.item.questionType === QuestionType.VIDEO ||
            data.item.questionType === QuestionType.ENCOURAGEMENT) &&
            !customQuestion ? (
            <IconPrimr
              name={unlockEditing ? "unlock" : "lock"}
              color={C["color-danger-600"]}
              onPress={() => {
                if (
                  (data.item.questionType === QuestionType.VIDEO ||
                    data.item.questionType === QuestionType.ENCOURAGEMENT) &&
                  !unlockEditing
                ) {
                  Alert.alert(
                    "⚠️ WARNING",
                    data.item.questionType === QuestionType.VIDEO
                      ? "Make sure the link you save is a valid Video Link.\n Still want to edit?"
                      : "We recommend not to edit this message.\n Still want to edit?",
                    [
                      {
                        text: "No",
                        onPress: () => {
                          setUnlockEditing(false);
                        },
                      },
                      {
                        text: "Yes",
                        onPress: () => setUnlockEditing(!unlockEditing),
                      },
                    ]
                  );
                } else setUnlockEditing(!unlockEditing);
              }}
            />
          ) : null;
        }}
      />

      {/*//TODO for future updates, keeping this section 
      <Buttoon
        style={{
          backgroundColor: C.accentDarker,
          borderColor: C.accentDarker,
          width: "100%",
        }}
        status="danger"
        icon={{ name: "trash" }}
        onPress={() => {
          handleDelete(
            data.index,
            data.item.questionType === QuestionType.TEXT
              ? data.item.question
              : data.item.mediaUri
          );
        }}
        disabled
      >
        {`Remove ${
          data.item.questionType === QuestionType.TEXT
            ? "Question"
            : data.item.questionType === QuestionType.VIDEO
            ? "Video Link"
            : "Message"
        }`}
      </Buttoon> */}
    </Kitten.Card>
  );
};

const QualificationCard = ({ data }: { data: dQualification }) => {
  const [isDisabled, setIsDisabled] = React.useState(data.isDisabled);
  const [isPointer, setIsPointer] = React.useState(data.countTowardsPointer);
  const swipyRef = React.useRef(null);
  return (
    <View style={{ width: "100%" }}>
      <Swipeable
        ref={swipyRef}
        renderRightActions={() => (
          <ListItemDeleteAction
            onPress={() => {
              Toasty.show("Cannot delete this qualification", {
                type: "warning",
              });
              swipyRef?.current?.close();
            }}
          />
        )}
      >
        <Kitten.Card
          style={[styles.kittenCard, { alignSelf: "center" }]}
          disabled
        >
          <View style={{ flexDirection: "row" }}>
            <Kitten.Input
              value={data.name}
              disabled
              style={{ width: "33%", marginRight: 10 }}
            />
            <Kitten.CheckBox checked={isDisabled} status={"danger"} disabled>
              Disabled
            </Kitten.CheckBox>
            <Kitten.CheckBox checked={isPointer} status={"warning"} disabled>
              Count as Pointer
            </Kitten.CheckBox>
          </View>
        </Kitten.Card>
      </Swipeable>
    </View>
  );
};

const styles = StyleSheet.create({
  kittenCard: { margin: 10, flex: 1 },
  container: {
    flex: 1,
    overflow: "hidden",
  },
  questionValue: {
    overflow: "hidden",
  },
  flatlistButton: {
    overflow: "hidden",
  },
  tabContainer: {
    height: "95%",
    alignItems: "center",
    justifyContent: "center",
  },
});
