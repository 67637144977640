//#region [import]

import {
  ScoreboardCenter,
  ScoreboardInfo,
  ScoreCreatorCenter
} from "components/organisms";
import { TwoColumns } from "components/templates";
import React from "react";
import { ActivityIndicator } from "react-native";
import { dSboardScrParams } from "screens/_navigation";
import { tr } from "utils";
//#endregion

/**
 * ### Scoreboard screen
 *  ----
 *  @version 21.03.01
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function ScoreboardScreen(props: P) {
  // const { C } = useAppContext();
  // const userProfile = useUserProfile();
  let sbid = props.route?.params?.sbid;

  /**
   * For custom apis (GHL)
   */
  const [filterDays, setFilterDays] = React.useState<number>(30);

  // const { breakpointForTwoColumns } = useTwoColumns();

  if (!props.route?.params?.sbid) return <ActivityIndicator />;
  // return <O.ScoreboardCenter sbid={sbid} />;
  try {
    return (
      <TwoColumns
        bottomInset
        column1Content={(scrollHandler) => (
          <ScoreboardCenter
            sbid={sbid}
            scrollHandler={scrollHandler}
            filterDays={filterDays}
          />
        )}
        column2Content={[
          {
            icon: "info",
            headerTitle: tr("Scoreboard info"),
            showTitle: false,
            render: () => (
              <ScoreboardInfo
                sbid={sbid}
                onChangeFilterDays={(days) => setFilterDays(days)}
              />
            ),
          },
          {
            icon: "plus",
            headerTitle: tr("Add score"),
            showTitle: true,
            render: () => <ScoreCreatorCenter sbid={sbid} />,
          },
        ]}
      />
    );
  } catch (error) {
    throw new Error(`::U2NvcmVib2FyZFNjcmVlbg==:: ${error}`);
  }
}

/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: dSboardScrParams }; //* unmask if we pass params thru rnav
}
