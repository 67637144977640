import * as Contacts from "expo-contacts"
import { ListItemProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Buttoon, Kitten, ListHeader } from "components";
import { sstyled, Txt } from "components/atoms";
import { ContactListItemDetails } from "components/molecules/members/ContactListItemDetails";
import { fn, useAppContext } from "engines";
import React from "react";
import { ImageBackground, View } from "react-native";
import { dContactsScrParams, useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { ContactSchemaa } from "../../../engines/backends/schemas/bml";
import {
  useContactsCollection,
  useFollowUpContacts
} from "../../../engines/firebase/handler/contact-handler";
import { Avt } from "../../atoms/generals/avt/Avt";

export function ListFollowUpContacts(
  props: ListItemProps & { followUpContacts?: ContactSchemaa[] }
) {
  // const { followUpContacts } = props;
  const { C, frbsUser, teamId, theme } = useAppContext();

  const Navigation = useNavigator();
  const contactsHandler = props.followUpContacts
    ? useFollowUpContacts(frbsUser?.uid)
    : useContactsCollection(frbsUser?.uid);

  //#region [section] Setup FU Contacts
  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  const allFollowUpContacts = props.followUpContacts
    ? contactsHandler.data
    : null;
  const needsFollowUpContacts = props.followUpContacts
    ? contactsHandler.data.filter(
      (c) => c.followUpDate?.toDate && c.followUpDate?.toDate() < endOfToday
    )
    : null;
  //#endregion

  try {
    return (
      <A.ViewSection>
        <ListHeader
          title={tr("Follow Up")}
          style={{ paddingBottom: 0, backgroundColor: "transparent" }}
          accessoryRight={() =>
            needsFollowUpContacts?.length ? (
              <A.ChipIndicator>
                {needsFollowUpContacts?.length || "-"}
              </A.ChipIndicator>
            ) : (
              <></>
            )
          }
        />

        {/* {fuContacts?.length !== 0 && (
          <Txt.Helper style={{ color: C.hazardYellow }}>
            You have {fuContacts?.length} contact(s){"\n"}that need to be
            followed up with today
          </Txt.Helper>
        )} */}
        <Kitten.List
          data={needsFollowUpContacts?.slice(0, 3)}
          keyExtractor={(item, index) =>
            index + "__" + item?.displayName?.length
          }
          ItemSeparatorComponent={Kitten.Divider}
          style={{ backgroundColor: "transparent" }}
          renderItem={({ item, index }) => {
            return (
              <_ContactItem contact={item} index={index} />
            )
          }}
          ListEmptyComponent={() => (
            <View style={{ marginVertical: spacing(3) }}>
              <Txt.Helper style={{ textAlign: "center" }}>
                {"0 follow ups due"}
              </Txt.Helper>
              <Buttoon size="small"
                appearance="ghost"
                status="basic"
                icon={{ right: true, name: "chevron_right" }} onPress={() => {
                  Navigation.navigate("Contact Manager")
                }}>{"view contacts"}</Buttoon>
            </View>
          )}
          ListFooterComponent={() =>
            needsFollowUpContacts?.length > 3 ? (
              <Buttoon
                onPress={() => {
                  Navigation.navigate<dContactsScrParams>(
                    "Contact Group Screen",
                    {
                      id: "follow-up",
                      followUpContacts: allFollowUpContacts,
                    }
                  );
                }}
                size="small"
                appearance="ghost"
                status="basic"
                icon={{ right: true, name: "chevron_right" }}
              >
                {needsFollowUpContacts?.length > 3
                  ? `see ${needsFollowUpContacts?.length - 3} more contact(s)`
                  : `see all`}
              </Buttoon>
            ) : null
          }
        />
      </A.ViewSection>
    );
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}

function _ContactItem(p: { index: number, contact: ContactSchemaa }) {
  const { index, contact } = p

  const Navigation = useNavigator()
  const { frbsUser, C } = useAppContext()

  const [phoneContact, setPhoneContact] = React.useState<Contacts.Contact>(null);

  React.useEffect(function updatePhoneContact() {
    if (!phoneContact && contact.id) {
      Contacts?.getContactByIdAsync(contact.id)?.then(phoneContact => phoneContact && setPhoneContact(phoneContact))?.catch(err => {
        console.error(err)
      })
    }
  }, [contact]);

  return <Kitten.ListItemv2
    key={index + "__" + contact._cid}
    style={{ backgroundColor: "transparent" }}
    onPress={() => {
      Navigation.overlayPortal.open({
        render: (
          <ContactListItemDetails
            contact={contact}
            userId={frbsUser?.uid}
            listName={"N/A"}
          />
        ),
        headerTitle: `Contact Details`,
      });
    }}
    accessoryLeft={() => (
      <Avt
        size="small"
        name={contact?.displayName || contact?.name}
        source={{ uri: phoneContact && phoneContact.imageAvailable && phoneContact.image ? phoneContact?.image?.uri : "" }}
      />
    )}
    title={contact?.displayName || contact?.name}
    description={() => (
      <Txt.C1 style={{ color: C.hazardYellow }}>
        {contact?.followUpDate?.toDate
          ? fn.calendar.dateFormat(contact?.followUpDate?.toDate())
          : "None"}
      </Txt.C1>
    )}
    accessoryRight={() => (
      <Kitten.ViewH>
        <IconPrimr
          preset={"circular"}
          name="message"
          size={21}
          color={C.grey500}
        // onPress={() =>
        //   Navigation.dialogPortal.open({
        //     headerTitle: "Blast SMS",
        //     render: <BlastSmsComposer contacts={[item]} />,
        //   })
        // }
        />
      </Kitten.ViewH>
    )}
  />
}

const A = {
  ViewSection: sstyled(Kitten.Layout)(({ hovered }) => ({
    flex: 1,
    flexGrow: 1,
    backgroundColor: "transparent",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ImageBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingVertical: spacing(2),
  }),
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "errorRed",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};
