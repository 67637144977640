import { Document } from "@nandorojo/swr-firestore";
import { ScoreboardSchema } from "engines";
import { dScore } from "engines/backends";
import { dUseDocumentReturns } from "./config";

export function crudScorer(
  type: "create" | "update" | "delete",
  option: {
    uid: string;
    score: dScore["score"];
    name: string;
    document: dUseDocumentReturns<ScoreboardSchema, Document<ScoreboardSchema>>;
  }
) {
  let { uid, score = "", document, name } = option;
  let currentPeople = document.data?.people || [];
  const updatedScorers = () => {
    switch (type) {
      case "create":
        return [
          ...currentPeople,
          {
            uid: uid,
            name: name, //* We don't need name, since we'll use handleMembers to do this in frontend
            score: parseInt(score),
          },
        ];
        break;
      case "update":
        return [
          ...currentPeople.filter((member) => member.uid !== uid),
          {
            uid: uid,
            name: name, //* We don't need name, since we'll use handleMembers to do this in frontend
            score: parseInt(score),
          },
        ];
        break;
      case "delete":
        console.log("crud delete");
        return uid === "nonUser"
          ? currentPeople.filter(
              (scorer) => scorer.uid !== "nonUser" || scorer.name !== name
            )
          : currentPeople.filter((member) => member.uid !== uid);
        break;
      default:
        break;
    }
  };

  document.update({
    people: updatedScorers(),
  });
}

export function editScore(
  uid: string,
  score: dScore["score"],
  name: string,
  document: dUseDocumentReturns<ScoreboardSchema, Document<ScoreboardSchema>>
) {
  const currentPeople = document.data?.people || [];

  let updatedPeople: ScoreboardSchema["people"] = [
    ...currentPeople.filter((member) =>
      uid ? member?.uid !== uid : member?.name !== name
    ),
    {
      uid: uid || "",
      name: name || "", //* We don't need name, since we'll use handleMembers to do this in frontend
      score: parseInt(score),
    },
  ];

  document.update({
    people: updatedPeople,
  });
}

export function removeScore(
  uid: string,
  document: dUseDocumentReturns<ScoreboardSchema, Document<ScoreboardSchema>>
) {
  const currentPeople = document.data?.people || [];

  let updatedPeople: ScoreboardSchema["people"] = currentPeople.filter(
    (member) => member.uid !== uid
  );
  document.update({
    people: updatedPeople,
  });
}

export function resetScoreboard(
  document: dUseDocumentReturns<ScoreboardSchema, Document<ScoreboardSchema>>
) {
  const currentPeople = document.data?.people || [];

  // let resetedScoreboard: ScoreboardSchema["people"] = currentPeople.map(
  //   (member) => ({ ...member, score: 0 })
  // );
  document.update({
    people: [],
    // people: resetedScoreboard,
  });
}
