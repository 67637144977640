import { IconPrimr } from "assets";
import {
  Imaggie,
  Kitten,
  ListHeader,
  M,
  sstyled,
  Txt
} from "components";
import { POSST_CREATOR_FORM } from "components/organisms";
import { PosstSchema, useAppContext } from "engines";
import * as R from "ramda";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { spacing, tr } from "utils";

interface dPosstCreatorMediaRow {}

/**
 * ### A Row of Posst Creator's Media Upload Card
 *  - Containing 4 Media Upload Cards
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.05.15
 *  -  *Move from PosstCreatorCenter*
 *  @author  K
 *
 **/
export function PosstCreatorMediasRow(props: dPosstCreatorMediaRow) {
  const {} = props;
  const { C } = useAppContext();
  const { control, setValue } = useFormContext();

  const [posstMedias, setPosstMedias] = React.useState<PosstSchema["medias"]>([
    null,
    null,
    null,
    null,
  ]);
  return (
    <Controller
      control={control}
      render={({ onChange, onBlur, value }) => (
        <>
          <ListHeader
            titleTx={tr("Photo/Video (optional)")}
            accessoryLeft={() => (
              <IconPrimr name={"videocam"} color={C.grey500} />
            )}
          />
          <A.CtnrMedia>
            {Array.from({ length: 4 }).map((item, index) => (
              <M.PageCreatorMedia
                key={index}
                onSelect={(item) => {
                  console.log("media item " + index + ": ", item);
                  let adjustedMedias = R.adjust(index, () => item, posstMedias);

                  setPosstMedias(adjustedMedias);
                  let reducedMedias = adjustedMedias.filter(function (el) {
                    return el != null;
                  });
                  onChange(reducedMedias);
                }}
              />
            ))}
          </A.CtnrMedia>
          <Txt
            onPress={() => {
              console.log("posst medias: ", posstMedias);
              // alert(R.pluck("type", posstMedias));
            }}
          >
            {posstMedias.length}
          </Txt>
          <Txt
            onPress={() => {
              let reducedMedias = posstMedias.filter(function (el) {
                return el != null;
              });
              console.log("reduced medias: ", reducedMedias);
              // alert(R.pluck("type", reducedMedias));
            }}
          >
            Filter
          </Txt>
        </>
      )}
      name={POSST_CREATOR_FORM.MEDIAS}
      rules={{ required: false }}
      defaultValue={[]}
    />
  );
}

const A = {
  CtnrBuilder: sstyled(Kitten.Card)((p) => ({
    // backgroundColor: "background",
    marginBottom: 10,
  })),
  CtnrMedia: sstyled(Kitten.ViewH)({
    justifyContent: "space-evenly",
    // width: "100%",
  }),
  PosstImage: sstyled(Imaggie)(
    (p: { mediaDimension: { width: number; height: number } }) => ({
      alignSelf: "center",
      width: 200,
      height: (200 * 3) / 4,
    })
  ),
  InputBody: sstyled(Kitten.Input)((p) => ({
    // maxWidth: 400,
    width: "100%",
    maxHeight: 800,
    padding: spacing(3),
    backgroundColor: "transparent",
  })),
};
