//#region [import]
import { Kitten, O, sstyled, Txt } from "components";
import { useBreakpointIndex } from "dripsy";
import { useAppContext } from "engines";
import React, { useState } from "react";
import { View } from "react-native";
import { dEditTemplateScrParams, useNavigator } from "screens/_navigation";
import { tr } from "utils";
//#endregion

/**
 * ### Brief description
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 1.04.3
 *  -  *Brief changelog*
 *  @author  NL
 *
 **/
export function MassMailUseTemplateScreen(props: P) {
  const { C } = useAppContext();
  const [isReady, shouldReady] = useState(true);
  const Navigation = useNavigator();
  const breakpoint = useBreakpointIndex();

  // function updateTemplate(payload: TemplateSchema) {
  //   console.log("got to the main function");
  //   handleTemplate.update(payload);
  // }
  // console.log(props.route.params.item)
  try {
    return breakpoint <= 2 ? (
      <A.Ctnr>
        <A.Ctnr>
          <Kitten.Card status={"warning"}>
            <Txt.Helper>
              {tr("For the best experience,")}
              {`\n`}
              {tr("The mail editor is not available on mobile.")}
              {`\n`}
              {tr(" You can still send this mail template")}
            </Txt.Helper>
          </Kitten.Card>
          <O.MailCompose docID={props.route.params.docID} />
        </A.Ctnr>
      </A.Ctnr>
    ) : (
      <A.Ctnr>
        <O.MailCompose docID={props.route.params.docID} />
      </A.Ctnr>
    );
  } catch (error) {
    throw new Error(`::TWFzc01haWxVc2VUZW1wbGF0ZVNjcmVlbg==:: ${error}`);
    // throw new Error(e);
  }
}

const A = {
  Ctnr: sstyled(View)({
    flex: 1,
    backgroundColor: "background",
  }),
};

/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: dEditTemplateScrParams }; //* unmask if we pass params thru rnav
}
