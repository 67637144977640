import { MenuIcon } from "assets/icons/MenuIcon";
import {
  Avt,
  Buttoon,
  dAccessory,
  Kitten,
  ListHeader,
  sstyled,
  Toasty,
  Txt
} from "components";
import {
  FRBS_ROLE,
  useAppContext,
  useConfigDocument,
  usePageDocument,
  UserSchema
} from "engines";
import {
  useCalculateProgressForPage,
  useGetLevelsOnParentPage
} from "engines/firebase/handler/levels-handler";
import {
  useMemberDocument,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import firebase from "firebase";
import { isNaN } from "lodash";
import React from "react";
import { Alert, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { useMassMailFunctions } from "../../../engines/backends/mass-mail-handler/MassMailHandler";
import { UserStatsOverview } from "../personal-activity/UserStatsOverview";
import { PersonaliViewCenter } from "../user/PersonaliViewCenter";
import { ContactTeamCenter } from "./ContactTeamCenter";

export interface dMemberInfo {
  memberId: string;
  type: "admin" | "teammates" | "user";
}

export function MemberInfo({ memberId, type = "admin" }: dMemberInfo) {
  const { C, teamId, frbsUser, setFrbsUser } = useAppContext();
  const Navigation = useNavigator();
  const { data: userD, ...userProfile } = useUserProfile();
  const { data: memberD, ...handleMember } = useMemberDocument(memberId);
  // const { breakpointForTwoColumns } = useTwoColumns();

  let isMemberBanned = memberD?.roles?.includes(FRBS_ROLE.BANNED);
  let isMemberAdmin = memberD?.roles?.includes(FRBS_ROLE.ADMIN);
  let isMemberTrainer = memberD?.roles?.includes(FRBS_ROLE.TRAINER);

  let displayName =
    type === "user"
      ? tr("your")
      : `${memberD?.personali?.displayName || "member"}'s`;

  const massMailHandler = useMassMailFunctions();

  //#region [section] user's growth handler
  const configHandler = useConfigDocument(`variables-${teamId}`);
  // const levelPagesHandler = useGetLevelsOnParentPage(
  //   configHandler.data?.startHerePage
  // );
  async function onToggleLevelComplete() {
    const allLevelsCompleted = !memberD?.allLevelsCompleted;
    Toasty.show(
      `${allLevelsCompleted ? "Unl" : "L"}ocking ${displayName} levels...`,
      {
        type: "loading",
      }
    );
    await handleMember.update({
      allLevelsCompleted,
    });
    Toasty.show(
      tr(
        `${displayName} levels are now ${allLevelsCompleted ? "un" : ""}locked!`
      )
    );
  }

  //#endregion

  //#region [section2] roles handler
  function onToggleAdmin() {
    isMemberAdmin
      ? handleMember.update({
        roles: firebase.firestore.FieldValue.arrayRemove(FRBS_ROLE.ADMIN),
      })
      : handleMember.update({
        roles: firebase.firestore.FieldValue.arrayUnion(FRBS_ROLE.ADMIN),
      });
  }
  function onToggleTrainer() {
    isMemberTrainer
      ? handleMember.update({
        roles: firebase.firestore.FieldValue.arrayRemove(FRBS_ROLE.TRAINER),
      })
      : handleMember.update({
        roles: firebase.firestore.FieldValue.arrayUnion(FRBS_ROLE.TRAINER),
      });
  }

  function onToggleBan() {
    isMemberAdmin
      ? // ? handleMember.update({
      handleMember.update({
        roles: firebase.firestore.FieldValue.arrayRemove(FRBS_ROLE.BANNED),
      })
      : // : handleMember.update({
      handleMember.update({
        roles: firebase.firestore.FieldValue.arrayUnion(FRBS_ROLE.BANNED),
      });
  }
  function onKickFromTeam() {
    const currentBaseShopId = memberD?.baseShopId;
    handleMember.update({
      unlockedPages:
        memberD?.unlockedPages?.filter((pid) => pid !== currentBaseShopId) ||
        [],
      baseShopId: null,
    });
  }
  //#endregion

  function toMemberContacts() {
    // console.log(
    //   `Viewing data for ${memberD?.personali?.displayName} and their shareTo is ${memberD._id} to ${userD._id}`
    // );
    //NOTE only show contact groups, if the teammate has shared their profile with you.
    if (
      // user shared with you specifically
      memberD?.growth?.listBuilder?.shareTo?.includes(userD?._id) ||
      // this is your profile
      memberD?._id == userD?._id ||
      // you are a developer
      userProfile?._isRolesContain([FRBS_ROLE.DEVELOPER]) ||
      // access permitted to ADMINs
      (memberD?.growth?.listBuilder?.shareTo?.length > 0 &&
        userProfile?._isRolesContain([FRBS_ROLE.ADMIN])) ||
      // access permitted to their RVP/base shop leader
      (memberD?.growth?.listBuilder?.shareTo?.length > 0 &&
        userProfile?._isRolesContain([`BS:${memberD?.baseShopId}`]))
    ) {
      Navigation.overlayPortal.open({
        headerTitle: memberD.personali?.displayName || memberD.personali?.email,
        render: <ContactTeamCenter userId={memberD._id} />,
      });
    } else {
      Toasty.show(
        `${memberD?.personali?.displayName} has not permitted access to their contacts. Ask them to share it!`,
        { type: "warning" }
      );
    }
  }

  try {
    if (handleMember.error) throw Error("firebase-error");
    return (
      <View
        style={[
          isMemberBanned && {
            backgroundColor: C.errorRed,
          },
          { width: "100%" },
        ]}
      >
        {isMemberBanned ? (
          <Buttoon //* Admin stuff
            status={"danger"}
            onPress={() =>
              (userProfile._isUserAdmin() || userProfile._isDeveloper()) &&
              handleMember.update({
                roles: firebase.firestore.FieldValue.arrayRemove(
                  FRBS_ROLE.BANNED
                ),
              })
            }
          >
            {userProfile._isUserAdmin() || userProfile._isDeveloper()
              ? tr("Unban") +
              " " +
              (memberD?.personali?.displayName || tr("member"))
              : tr("This member is banned")}
          </Buttoon>
        ) : (
          <View>
            {
              //#region [section] Personali
              /** Don't show if type is "user" */
              type !== "user" ? (
                <PersonaliViewCenter
                  uid={memberId}
                  showAdminTools={userProfile._isRolesContain([
                    FRBS_ROLE.ADMIN,
                    FRBS_ROLE.DEVELOPER,
                    `BS:${memberD.baseShopId || "NA"}`,
                  ])}
                />
              ) : null

              // userProfile._isUserAdmin() || userProfile._isDeveloper() ? (
              //   <O.PersonaliEditCenter uid={memberId} />
              // ) : (
              //   <PersonaliViewCenter uid={memberId} />
              // )
              //#endregion
            }
            {
              //#region [section2] Member config
              (userProfile._isUserAdmin() ||
                userProfile._isDeveloper() ||
                userProfile._isBaseShopOwner(memberD?.baseShopId) ||
                type === "user") && (
                <>
                  <ListHeader titleTx={tr("Info")} />
                  <A.Menu>
                    <Kitten.ListItemv2
                      title={tr("Joined app")}
                      accessoryRight={() => (
                        <Txt>
                          {memberD?.createdAt?.toDate().toLocaleDateString() ||
                            "⚠️ Unknown date"}
                        </Txt>
                      )}
                    />
                    <Kitten.ListItemv2
                      title={tr("Last sign in")}
                      accessoryRight={() => (
                        <Txt>
                          {memberD?.lastSignIn?.toDate().toLocaleDateString() ||
                            "⚠️ Unknown date"}{" "}
                          •{" "}
                          {memberD?.lastSignIn
                            ?.toDate()
                            .toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            }) || "⚠️ Unknown time"}
                        </Txt>
                      )}
                    />
                    <Kitten.ListItemv2
                      title={tr("App version")}
                      accessoryRight={() => (
                        <Txt>{memberD?.mobileVersion || "⚠️ Unknown"}</Txt>
                      )}
                    />
                  </A.Menu>
                </>
              )
              //#endregion
            }
            {
              //#region [section] Roles
              userProfile._isUserAdmin() || userProfile._isDeveloper() ? (
                <>
                  <ListHeader titleTx={tr("Roles")} />
                  <A.Menu>
                    <Kitten.MenuItem
                      title="Admin"
                      accessoryLeft={(p) => MenuIcon("admin", p)}
                      onPress={onToggleAdmin}
                      accessoryRight={() => (
                        <Kitten.CheckBox
                          //* Admin stuff
                          checked={isMemberAdmin || false}
                          onChange={onToggleAdmin}
                        />
                      )}
                    />
                    <Kitten.MenuItem
                      title="Field Trainer"
                      accessoryLeft={(p) => MenuIcon("compass", p)}
                      onPress={onToggleTrainer}
                      accessoryRight={() => (
                        <Kitten.CheckBox
                          //* Trainer stuff
                          checked={isMemberTrainer || false}
                          onChange={onToggleTrainer}
                        />
                      )}
                    />
                  </A.Menu>
                </>
              ) : null
              //#endregion
            }
            {
              <View>
                <ListHeader titleTx={tr("Access")} />
                <Kitten.MenuItem
                  onPress={toMemberContacts}
                  title={tr(
                    `View ${memberD?.personali?.displayName?.length > 0
                      ? memberD?.personali?.displayName?.split(" ")[0]
                      : "Users"
                    }'s Contacts`
                  )}
                  accessoryRight={(p: dAccessory) =>
                    MenuIcon("contacts", {
                      ...p,
                      style: { ...p.style, tintColor: C.infoBlue },
                    })
                  }
                  style={{ backgroundColor: C["color-info-transparent-500"] }}
                />
              </View>
            }
            {
              //#region [section2] Levels
              (userProfile._isUserAdmin() ||
                userProfile._isDeveloper() ||
                userProfile._isBaseShopOwner(memberD?.baseShopId) ||
                userProfile._isRolesContain([`BS:${memberD?.baseShopId}`]) ||
                memberD?.growth?.listBuilder?.shareTo?.includes(userD?._id) ||
                type === "user") && (
                <>
                  <ListHeader titleTx={tr("Levels")} />
                  <A.Menu>
                    {/** @ts-ignore needs to chill */}
                    {memberD?.parentLevelPages ? (
                      memberD?.parentLevelPages?.map((parentPageId) => (
                        <LevelProgressMenu
                          parentPageId={parentPageId}
                          user={memberD}
                        />
                      ))
                    ) : (
                      <LevelProgressMenu
                        parentPageId={configHandler.data?.startHerePage}
                        user={memberD}
                      />
                    )}
                    {userProfile._isUserAdmin() ||
                      userProfile._isDeveloper() ||
                      userProfile._isBaseShopOwner(memberD?.baseShopId) ? (
                      <Kitten.MenuItem
                        title={tr(`Unlock all levels`)}
                        onPress={onToggleLevelComplete}
                        accessoryLeft={() => (
                          <Kitten.CheckBox
                            checked={memberD?.allLevelsCompleted || false}
                            onChange={onToggleLevelComplete}
                          />
                        )}
                        accessoryRight={(p) => MenuIcon("unlock", p)}
                        style={{
                          backgroundColor: memberD?.allLevelsCompleted
                            ? C["color-primary-transparent-500"]
                            : C.grey500,
                        }}
                      />
                    ) : null}
                  </A.Menu>
                  {
                    //#region [section2] Streaks & Activity
                    <>
                      <ListHeader titleTx={tr("User Stats")} />
                      <UserStatsOverview
                        uid={memberId}
                        showHistoryShortcut
                        showStreakShortcut
                      />
                      {/* <Buttoon
                        appearance="ghost"
                        status="info"
                        icon={{ right: true, name: "arrow_right" }}
                        onPress={() => {
                          Navigation.navigate("stats", {
                            uid: memberId,
                            title: `${
                              memberD?.personali?.displayName ||
                              memberD?.personali?.email
                            }'s Activity History`,
                          });
                        }}
                      >
                        View Activity History
                      </Buttoon> */}
                    </>
                  }
                  <A.Menu>
                    <Kitten.MenuItem
                      onPress={onToggleBan}
                      title={tr("Ban") + " " + displayName + " account?"}
                      accessoryRight={(p) =>
                        MenuIcon("ban", {
                          ...p,
                          style: { ...p?.style, tintColor: C.errorRed },
                        })
                      }
                      style={{
                        backgroundColor: C["color-danger-transparent-500"],
                      }}
                    />
                  </A.Menu>
                  {userProfile?._isRolesContain([FRBS_ROLE.DEVELOPER]) &&
                    memberD?._id !== frbsUser?.uid ? (
                    <Buttoon
                      style={{ marginTop: spacing(3) }}
                      status={"control"}
                      appearance="ghost"
                      onPress={() => {
                        setFrbsUser({
                          ...frbsUser,
                          uid: memberD?._id,
                          normalUid: frbsUser?.uid,
                        });
                        Toasty.show("Enjoy being not you!");
                      }}
                    >
                      Imitate User
                    </Buttoon>
                  ) : null}
                  {/* {userProfile?._isRolesContain([
                    FRBS_ROLE.ADMIN,
                    FRBS_ROLE.DEVELOPER,
                    `BS:${memberD?.baseShopId}`,
                  ]) ? (
                    <Kitten.MenuItem
                      onPress={onKickFromTeam}
                      title={
                        tr("Kick") +
                        " " +
                        (memberD?.personali?.displayName || "user") +
                        " from the team?"
                      }
                      accessoryRight={(p) =>
                        MenuIcon("account_remove", {
                          ...p,
                          style: { ...p.style, tintColor: C.pitchWhite },
                        })
                      }
                      style={{
                        backgroundColor: C["color-danger-transparent-500"],
                      }}
                    />
                  ) : null} */}
                  {memberD?._id === frbsUser?.uid ? (
                    <Buttoon
                      progress
                      onPress={() => {
                        // Linking.openURL(
                        //   `mailto:\"App Takeoff Team\"<team@apptakeoff.com>?cc=\"App Takeoff\"<francisj12@icloud.com>&subject=Delete my account&body=Please delete my account. \n Email: ${frbsUser?.email} \n uid: ${frbsUser.uid}`
                        // );
                        Toasty.show("Sending request...", { type: "loading" });
                        const payload = {
                          recipient: "francisj12@icloud.com",
                          email_from: `${memberD?.personali?.email} <admin@apptakeoff.com>`,
                          email_reply_to: memberD?.personali?.email,
                          email_subject: "Delete Account Request",
                          email_html_body: `Team ID: ${memberD?._teamId}
                          Name: ${memberD?.personali?.displayName}
                          Email: ${memberD?.personali?.email}
                          Uid: ${memberD?._id}`,
                          _teamId: memberD?._teamId,
                          Cc: "francisj12@icloud.com, team@apptakeoff.com",
                          // bcc: _reducedbcopy ? _reducedbcopy.toString() : "",
                        };
                        massMailHandler
                          .add(payload)
                          .then((res) => {
                            if (res.status === 200) {
                              Alert.alert(
                                "Request to delete your account",
                                `A request to delete your account has been sent to our team and your account with all it's data will be removed within 5 business days.\nIf you change your mind at any point or have questions please contact us at team@apptakeoff.com`
                              );
                            } else {
                              Alert.alert(
                                "Something went wrong.",
                                "Something in our servers broke. Please send an email to our team immediately (team@apptakeoff.com).\nWe apologize for the inconvenience."
                              );
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            Toasty.show(err, { type: "error" });
                          });
                      }}
                      disabled={!frbsUser}
                      appearance="ghost"
                      status="danger"
                    >
                      {tr("Delete My Account")}
                    </Buttoon>
                  ) : null}
                </>
              )
              //#endregion
            }
          </View>
        )}
      </View>
    );
  } catch (error) {
    throw new Error(`::TWVtYmVySW5mbw==:: ${error}`);
  }
}

export function TrainingProgressOverview(props: { user: UserSchema, onSaveChanges: (user: Partial<UserSchema>) => void }) {
  const { user, onSaveChanges } = props;
  const { teamId, C, frbsUser } = useAppContext()
  const configHandler = useConfigDocument(`variables-${teamId}`)

  const [isLevelsUnlocked, setIsLevelsUnlocked] = React.useState<boolean>(user.allLevelsCompleted);

  const canUnlockTheirLevels = user?.growth?.listBuilder?.shareTo?.includes(frbsUser?.uid) || user?.roles?.includes(FRBS_ROLE.ADMIN) || user?.roles?.includes(FRBS_ROLE.DEVELOPER)

  async function onToggleLevelComplete() {
    const allLevelsCompleted = !isLevelsUnlocked;
    setIsLevelsUnlocked(!isLevelsUnlocked)
    Toasty.show(
      `${allLevelsCompleted ? "Unl" : "L"}ocked ${user?.personali?.displayName} levels.`,
      {
        type: "info",
      }
    );
    onSaveChanges({ allLevelsCompleted })
  }

  return <A.Menu>
    {/** @ts-ignore needs to chill */}
    {user?.parentLevelPages ? (
      user?.parentLevelPages?.map((parentPageId) => (
        <LevelProgressMenu
          parentPageId={parentPageId}
          user={user}
        />
      ))
    ) : (
      <LevelProgressMenu
        parentPageId={configHandler.data?.startHerePage}
        user={user}
      />
    )}
    {canUnlockTheirLevels ? (
      <Kitten.MenuItem
        title={tr(`Unlock all levels`)}
        onPress={onToggleLevelComplete}
        accessoryLeft={() => (
          <Kitten.CheckBox
            checked={isLevelsUnlocked}
            onChange={onToggleLevelComplete}
          />
        )}
        accessoryRight={(p) => MenuIcon("unlock", p)}
        style={{
          backgroundColor: user?.allLevelsCompleted
            ? C["color-primary-transparent-500"]
            : C.grey500,
        }}
      />
    ) : null}
  </A.Menu>
}

export function LevelProgressMenu(props: {
  parentPageId: string;
  user: UserSchema;
}) {
  const { C } = useAppContext();
  const { parentPageId, user } = props;
  const parentPage = usePageDocument(parentPageId);
  const levelPages = useGetLevelsOnParentPage(parentPageId);

  return (
    <>
      <Txt.S2 style={{ margin: spacing(4), color: C.text }}>{`${parentPage?.data?.name || "Level Section"
        }`}</Txt.S2>
      {levelPages
        ? levelPages.map((levelPageId) => (
          <LevelProgressMenuItem levelPageId={levelPageId} user={user} />
        ))
        : null}
    </>
  );
}

export function LevelProgressMenuItem(props: {
  levelPageId: string;
  user: UserSchema;
}) {
  const { C } = useAppContext();
  const Navigation = useNavigator()
  const { levelPageId, user } = props;
  const levelPageHandler = usePageDocument(levelPageId);
  const levelPageProgress = useCalculateProgressForPage(levelPageId, user, {
    levelPageHandler,
  });

  function expandLevelDetails() {
    // Navigation.overlayPortal.open({
    //   headerTitle: levelPageHandler.data?.name,
    //   render: <View>
    //     {levelPageHandler.data}
    //   </View>
    // })
  }

  return (
    <Kitten.ListItemv2
      onPress={expandLevelDetails}
      title={levelPageHandler.data?.name}
      accessoryLeft={() => (
        <Avt
          name={levelPageHandler.data?.name || ""}
          source={{ uri: levelPageHandler.data?.mediaItem?.logo }}
          shape={"square"}
        />
      )}
      accessoryRight={() => (
        <View style={{ width: scale(50) }}>
          {isNaN(levelPageProgress) ? (
            <Txt.S2 style={{ textAlign: "right", color: C.errorRed }}>
              {tr("Error")}
            </Txt.S2>
          ) : (
            <Txt.S2 style={{ textAlign: "right" }}>{`${Math.round(
              levelPageProgress * 100
            )}%`}</Txt.S2>
          )}
        </View>
      )}
    />
  );
}

interface P {
  team?: boolean;
}

const A = {
  Menu: sstyled(Kitten.Menu)({
    borderRadius: scale(4),
    borderWidth: 1,
    borderColor: "dim",
  }),
};
