import * as UIKT from "@ui-kitten/components";
import { Divider, IndexPath } from "@ui-kitten/components";
import { TouchableWeb } from "@ui-kitten/components/devsupport";
import { View } from "react-native";
import { createAnimatableComponent } from "react-native-animatable";
import { spacing } from "utils";
import { sstyled } from "../../generals";
import { Autocompletev2 } from "./autocomplete-v2";
import { ButtonGroupv2 } from "./buttongroup-v2";
import { ListItemv2 } from "./list-item-v2";
import { SelectItemv2 } from "./select-item-v2";

export interface dAccessory {
  style: {
    height: number;
    marginHorizontal: number;
    tintColor: string;
    width: number;
  };
}

const Popup = createAnimatableComponent(UIKT.Modal);
const ViewH = sstyled(View)({ flexDirection: "row", alignItems: "center" });

const Divider_________ = sstyled(Divider)({
  marginVertical: spacing(3),
  // backgroundColor: "transparent",
});

/**
 *
 * @description Customized UI Kitten components befonre export it as <Kitten.[]>
 *
 * @version 1.3.12
 * -  *Add TouchableWeb*
 */
export const Kitten = {
  ...UIKT,
  IndexPath: IndexPath,
  Divider_________: Divider_________,

  /**
 * ###  Helper component for the Touchable component rendered on the web.
 * 
 *  ---
 * @author K
 * @version 1.2.10
 * 
 * @example
 *  const [_color, setColor] = React.useState("dodgerblue")
 *  
 *  <TouchableWeb
        onMouseEnter={(e) => {
          setColor("tomato");
        }}
        onMouseLeave={(e) => {
          setColor("dodgerblue");
        }}
      >
        <Text style={{color: _color }>Hi, I'm Khoi 👋</Text>
    </TouchableWeb>
 */
  TouchableWeb: TouchableWeb,
  /**
 * ~ Kitten.Modal, w Animatable
 * 
 * ---
 * @example
 * const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Text onPress={() => setVisible(true)}>
        TOGGLE Popup
      </Text>
      <Kitten.Popup
        visible={visible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setVisible(false)}>
        <>
          <Text onPress={() => setVisible(false)}>Sup!</Text>
        </>
      </Kitten.Popup>
    </>
 * 
 */
  Popup,
  ListItemv2,
  SelectItemv2,
  /**
   * ### Modded Button Group
   *  - Allow scrolling as the group grows
   *  ----
   *  @example
   *  <View style={{ alignItems: "center" }}>
          <Kitten.ButtonGroupv2>
            {Array.from({ length: 4 }).map((_, i) => (
              <Buttoon>{i}</Buttoon>
            ))}
          </Kitten.ButtonGroupv2>
        </View>
  *  ----
  *
  **/
  ButtonGroupv2,
  Autocompletev2,
  ViewH,
};

// export default Kitten;
