import { Toasty } from "components";
import * as WebBrowser from "expo-web-browser";
import { Platform } from "react-native";
import { IS_WEB, tr } from "utils";

/**
 * Fn to open url on ios, android and web,
 * with option to `open in new tab` for web
 * ---
 * @version 0.10.30
 * @author nguyenkhooi
 */
export function LinkURL(
  url: string,
  newTab: boolean = true
): Promise<WebBrowser.WebBrowserResult> {
  const _handlePressButtonAsync = async (
    url: string
  ): Promise<WebBrowser.WebBrowserResult> => {
    const modifiedUrl = IS_WEB ? encodeURI((url).replace(/\t/, "")) : url
    let result = await WebBrowser.openBrowserAsync(modifiedUrl);
    return result;
  };

  try {
    if (Platform.OS == "web" && !!newTab) {
      window.open(url, "_blank");
    } else {
      return _handlePressButtonAsync(url);
      // RNLinking.openURL(url); // normal Linking react-native
    }
  } catch (error) {
    Toasty.show(tr("Error opening link"), { type: "danger" });
    return null;
  }
}
