import { IconPrimr, ICON_NAME } from "assets";
import { MenuIcon } from "assets/icons/MenuIcon";
import { dAccessory, Kitten, ListHeader, Toasty, Txt } from "components";
import {
  MediaItemCreator,
  ParagraphItemCreator,
  TitleItemCreator
} from "components/molecules";
import { useTwoColumns } from "components/templates";
import { ItemType, useAppContext } from "engines";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";
import { addItemToPage } from "../../../../engines/firebase/handler/page-contents-handler";
import { dCollaboration } from "../../../../screens/collaboration/Collaboration";
import { BlastTemplateItemEditor } from "../items-editor/blast-template-item-editor";
import { ButtonItemEditor } from "../items-editor/button-item-editor";
import { CheckboxItemEditor } from "../items-editor/checkbox-item-editor";
import { DocumentItemEditor } from "../items-editor/document-item-editor";
import { PickTrainerButtonCreator } from "./pick-trainer-button-creator";

const creatorOptions: dCreatorOption[] = [
  {
    key: ItemType.TITLE,
    label: tr("Add heading"),
    icon: "text2",
    description: "Big text",
  },
  {
    key: ItemType.PARAGRAPH,
    label: tr("Add paragraph"),
    icon: "text",
    description: "Small text",
  },
  {
    key: ItemType.PHOTO,
    label: tr("Add photo or video"),
    icon: "images",
    description: "Upload photos, videos, and audios.",
  },
  {
    key: ItemType.BUTTON,
    label: tr("Add page or url"),
    description: "Add a new page to the app or insert a website url.",
    icon: "page",
  },
  {
    key: ItemType.DOCUMENT,
    label: tr("Add PDF document"),
    description: "Upload a PDF document",
    icon: "file_pdf",
  },
  // {
  //   key: ItemType.LEVEL_BUTTON,
  //   label: tr("Add level"),
  //   icon: "level",
  // },
  {
    key: ItemType.BLAST_TEXT,
    label: tr("Add a Blast Text Template"),
    description: "A script that can be conveniently grabbed and sent out.",
    icon: "bullhorn",
  },
  {
    key: ItemType.CHECKBOX,
    label: tr("Add Checkbox"),
    description: "Create a checklist for the user to complete.",
    icon: "check_circle",
  },
  {
    key: ItemType.PICK_TRAINER,
    label: tr("Add Trainer Selection"),
    description: "An option for the user to select their trainer.",
    icon: "admin"
  },
  {
    key: ItemType.BUILD_MY_LIST,
    label: tr("Add List Builder"),
    description:
      "Build and qualify a list of people you know based on questions to help you think of names.",
    icon: "list",
  },
  {
    key: ItemType.SEARCH,
    label: tr("Content Multiverse"),
    icon: "world",
    description: "Explore the multiverse of content in other teams!",
  },
];

export function PageItemsCreatorMenu({ cpid }: { cpid: string }) {
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  if (!cpid) return null;
  const { breakpointForTwoColumns } = useTwoColumns();

  const [hoverButton, setHoverButton] = React.useState<ItemType>(null);

  // const [clipboardText, setClipboardText] = React.useState<string>("");

  // return !breakpointForTwoColumns ? (
  return 1 == 1 ? (
    <>
      <Kitten.Menu style={{ borderColor: C.adminGold, borderWidth: 1 }}>
        {creatorOptions.map((option) =>
          option.key !== ItemType.SEARCH ? (
            <Kitten.MenuItem
              key={option.key}
              title={() => (
                <MenuItemText
                  title={option.label}
                  description={
                    hoverButton &&
                      hoverButton === option.key &&
                      option.description
                      ? option.description
                      : null
                  }
                />
              )}
              accessoryLeft={(props: dAccessory) =>
                MenuIcon(option.icon, props)
              }
              onMouseEnter={() => setHoverButton(option.key)}
              onMouseLeave={() => setHoverButton(null)}
              onPress={async () => {
                // build my list button
                if (option.key === ItemType.BUILD_MY_LIST) {
                  await addItemToPage(cpid, {
                    title: "Build My List",
                    url: "shortcut:BuildMyList",
                    position: -15,
                    media: "",
                    _parentPageId: cpid,
                    _teamId: teamId,
                    //@ts-ignore
                    createdAt: new Date(),
                    paragraph: "",
                    isContentPrivate: true,
                  });
                  Toasty.show(
                    "List Builder Button added to bottom! (see the top)",
                    {
                      type: "success",
                    }
                  );
                  return;
                }

                // other buttons
                Navigation.dialogPortal.open({
                  headerTitle: option.label,
                  render: <S_PageItemCreator itemOption={option} cpid={cpid} />,
                });
              }}
            />
          ) : (
            <Kitten.MenuItem
              key={option.key}
              onMouseEnter={() => setHoverButton(option.key)}
              onMouseLeave={() => setHoverButton(null)}
              title={() => (
                <MenuItemText
                  title={option.label}
                  description={
                    hoverButton === ItemType.SEARCH && option.description
                      ? option.description
                      : null
                  }
                />
              )}
              accessoryLeft={(props: dAccessory) =>
                MenuIcon(option.icon, props)
              }
              onPress={
                async () => {
                  !breakpointForTwoColumns && Navigation.goBack();
                  Navigation.navigate<dCollaboration>("multiverse", {
                    srcPageId: cpid,
                  });
                }
                // : Toasty.show(tr("Content Multiverse Feature is coming soon."))
              }
            />
          )
        )}
      </Kitten.Menu>
    </>
  ) : (
    <Kitten.Menu>
      <Kitten.MenuGroup
        accessoryLeft={(p) => MenuIcon("plus", p)}
        title={tr("Add content")}
        style={{ borderColor: C.adminGold, borderWidth: 1 }}
      >
        {creatorOptions.map((option) => (
          <Kitten.MenuItem
            key={option.key}
            onMouseEnter={() => setHoverButton(option.key)}
            onMouseLeave={() => setHoverButton(null)}
            title={() => (
              <MenuItemText
                title={option.label}
                description={
                  hoverButton === ItemType.SEARCH && option.description
                    ? option.description
                    : null
                }
              />
            )}
            accessoryLeft={(props: dAccessory) => MenuIcon(option.icon, props)}
            onPress={async () => {
              option.key === ItemType.SEARCH
                ? (!breakpointForTwoColumns && Navigation.goBack(),
                  Navigation.navigate<dCollaboration>("multiverse", {
                    srcPageId: cpid,
                  }))
                : Navigation.dialogPortal.open({
                  headerTitle: option.label,
                  render: (
                    <S_PageItemCreator itemOption={option} cpid={cpid} />
                  ),
                });
            }}
          />
        ))}
      </Kitten.MenuGroup>
    </Kitten.Menu>

    // <Menoo
    //   trigger={{
    //     render: (
    //       <Kitten.ListItemv2
    // accessoryLeft={(p) => MenuIcon("plus", p)}
    // disabled
    // title={tr("Add content")}
    // style={{ borderColor: C.adminGold, borderWidth: 1 }}
    //       />
    //     ),
    //   }}
    //   options={creatorOptions.map((o) => ({
    //     text: o.label,
    //     onSelect: () =>
    //       o.key === ItemType.SEARCH
    //         ? Navigation.navigate<dCollaboration>("multiverse", {
    //             srcPageId: cpid,
    //           })
    //         : Navigation.dialogPortal.open({
    //             headerTitle: o.label,
    //             render: <S_PageItemCreator itemOption={o} cpid={cpid} />,
    //           }),
    //   }))}
    // />
  );
}

function MenuItemText(p: {
  title: string;
  description: string;
  color: string;
}) {
  const { title, description, color } = p;
  const { C } = useAppContext();
  return (
    <View
      style={{
        // alignSelf: "flex-start",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "85%",
        // paddingLeft: spacing(1),
      }}
    >
      <Txt.C2
        style={{
          ...(color ? { color } : {}),
          fontWeight: "700",
          letterSpacing: 0.24,
          // color: hoverButton === option.key ? C.primary : undefined,
        }}
      >
        {title}
      </Txt.C2>
      {description ? (
        <Txt.Indicator
          numberOfLines={3}
          appearance="hint"
          style={{
            color: color ? color : C.grey500,
            opacity: 0.88,
            fontSize: 10,
            // width: "100%",
            textAlign: "left",
          }}
        >
          {description}
        </Txt.Indicator>
      ) : null}
    </View>
  );
}

export const S_PageItemCreator = ({ itemOption, cpid }: P) => {
  const Navigation = useNavigator();
  const { C } = useAppContext();
  if (!itemOption) return null;
  const Container = ({ children }) => (
    <View style={{ width: "100%" }}>
      <ListHeader
        titleTx={itemOption.label}
        accessoryLeft={() => (
          <IconPrimr name={itemOption.icon} color={C.grey600} />
        )}
      />
      {children}
      <Kitten.Divider_________ />
      <Txt.Helper marginV style={{ textAlign: "center" }}>
        {tr("Item will be created at the bottom of the page")}
      </Txt.Helper>
    </View>
  );

  switch (itemOption.key) {
    case ItemType.TITLE:
      return (
        <Container>
          <TitleItemCreator
            cpid={cpid}
            onCloseCallback={() => Navigation.goBack()}
          />
        </Container>
      );
      break;
    case ItemType.PARAGRAPH:
      return (
        <Container>
          <ParagraphItemCreator
            cpid={cpid}
            onCloseCallback={() => Navigation.goBack()}
          />
        </Container>
      );
      break;
    case ItemType.BUTTON:
      return (
        <Container>
          <ButtonItemEditor
            cpid={cpid}
            onUpdateCallback={() => Navigation.goBack()}
          />
          {/* <ButtonItemCreator
            cpid={cpid}
            onCloseCallback={() => Navigation.goBack()}
          /> */}
        </Container>
      );
      break;
    case ItemType.DOCUMENT:
      return (
        <Container>
          <DocumentItemEditor
            cpid={cpid}
            onCloseCallback={() => Navigation.goBack()}
          />
        </Container>
      );
      break;
    case ItemType.CHECKBOX:
      return (
        <Container>
          <CheckboxItemEditor
            cpid={cpid}
            onUpdateCallback={() => Navigation.goBack()}
          />
        </Container>
      );
    // case ItemType.LEVEL_BUTTON:
    //   Toasty.show(
    //     'You can now do this from "Add Page" or by editing an existing button.',
    //     { type: "warning", duration: 5000 }
    //   );
    //   Navigation.goBack();
    //   return null;
    //   return (
    //     <Container>
    //       <LevelButtonItemCreator
    //         cpid={cpid}
    //         onCloseCallback={() => Navigation.goBack()}
    //       />
    //     </Container>
    //   );
    //   break;

    case ItemType.PHOTO:
      return (
        <Container>
          <MediaItemCreator
            cpid={cpid}
            onCloseCallback={() => Navigation.goBack()}
          />
        </Container>
      );
      break;
    case ItemType.BLAST_TEXT:
      return (
        <Container>
          <BlastTemplateItemEditor
            cpid={cpid}
            onCloseCallback={() => Navigation.goBack()}
          />
        </Container>
      );
      break;
    case ItemType.PICK_TRAINER:
      return <PickTrainerButtonCreator cpid={cpid} />
    default:
      return <Txt.Helper>{tr("Error getting component")}</Txt.Helper>;
      break;
  }
};

interface dCreatorOption {
  key: ItemType;
  label: string;
  description?: string;
  icon: ICON_NAME;
}
interface P {
  itemOption: dCreatorOption;
  cpid: string;
}
