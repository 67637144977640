//#region [import]
import {
  Buttoon,
  Kitten,
  ListHeader,
  MemberSelect,
  sstyled,
  Toasty,
  Txt
} from "components";
import { MediaPageSchema } from "engines";
import { dUseDocumentReturns } from "engines/firebase/config";
import { useMemberDocument } from "engines/firebase/handler/members-handler";
import firebase from "firebase";
import React from "react";
import { ScrollView, TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
//#endregion

/**
 * ###
 *  - This form is used in assigning a base shop leader
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.10.29
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function AssignUserBS(props: P) {
  const Navigation = useNavigator();
  const [bsOwner, setBsOwner] = React.useState<string>();

  const handleMember = useMemberDocument(bsOwner);

  function assignBSleader() {
    handleMember.update({
      _id: bsOwner,
      baseShopId: `${props.pageHandler.data.id}`,
      roles: firebase.firestore.FieldValue.arrayUnion(
        `BS:${props.pageHandler?.data?.id}`
      ),
    });

    Navigation.goBack();

    Toasty.show(
      props.pageHandler?.data?.name +
      tr(" Base shop has been assigned to a leader!"),
      {
        type: "success",
      }
    );
  }

  return (
    <A.ViewBuilder>
      <ListHeader titleTx={tr("Assign leader to base shop")} />
      <>
        <Txt.Helper marginV>{tr("Select base shop leader")}</Txt.Helper>
        <MemberSelect
          onChange={(userSelected) => {
            setBsOwner(userSelected);
          }}
          flexDirection={"row"}
        />
        <Kitten.Divider_________ />
        <Buttoon onPress={assignBSleader}>{tr("Assign")}</Buttoon>
      </>
    </A.ViewBuilder>
  );
}

const A = {
  ViewBuilder: sstyled(ScrollView)((p) => ({
    // flex: 1,
    // backgroundColor: "background",
    width: "100%",
    marginBottom: 10,
    paddingHorizontal: spacing(2),
  })),
  ViewMediasSection: sstyled(View)({
    flexDirection: "row",
    flexWrap: "wrap",
  }),
  ViewMediaUploaderBox: sstyled(View)({
    width: scale(69),
    margin: spacing(2),
    flexGrow: 1,
  }),
  InputBody: sstyled(Kitten.Input)((p) => ({
    // maxWidth: 400,
    width: "100%",
    maxHeight: 800,
    padding: spacing(3),
    // backgroundColor: "back",
  })),
  Input: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
};

interface P {
  pageHandler?: dUseDocumentReturns<MediaPageSchema>;
}
