//#region [import]
import { Buttoon, Ni, sstyled, Toasty, Txt } from "components";
import { useAppContext } from "engines";
import React from "react";
import { ScrollView } from "react-native";
import { spacing, tr } from "utils";
//#endregion

/**
 * ### A quick screen for a quick start
 *
 * ---
 * @example As is
 * ---
 * @version 0.12.18
 * @author nguyenkhooi
 */
export function SboardCreatorScreen(props) {
  const { C } = useAppContext();
  try {
    return (
      <SS.Sctnr>
        <SS.CtnrWelcome animation="fadeInUp" delay={500}>
          <SS.TxtTitle>{tr("Welcome to Board Creator Screen")}</SS.TxtTitle>
          <Buttoon
            onPress={() => Toasty.show(tr("Hey there 👋"), { type: "success" })}
          >
            {tr("Try me")}
          </Buttoon>
        </SS.CtnrWelcome>
      </SS.Sctnr>
    );
  } catch (error) {
    throw new Error(`::U2JvYXJkQ3JlYXRvclNjcmVlbg==:: ${error}`);
  }
  
}

const SS = {
  Sctnr: sstyled(ScrollView)((p) => ({
    flex: 1,
    width: "100%",
    backgroundColor: "background",
  })),
  CtnrWelcome: sstyled(Ni.Layout)(() => ({
    paddingVertical: spacing(4),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  })),
  TxtTitle: sstyled(Txt.H6)({
    textAlign: "center",
  }),
};
