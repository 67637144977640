import { CollectionQueryType, useDocument } from "@nandorojo/swr-firestore";
import { FPATH, FRBS_ROLE, useAppContext, UserSchema } from "engines";
import firebase from "firebase";
import { useCollectionPresetv2 } from "../config";

export function useMemberDocument(id: string, query?) {
  const presetDocument = useDocument<UserSchema>(`${FPATH.USERS}/${id}`, {
    listen: true,
    ...query,
  });

  function isMemberAdmin() {
    return presetDocument.data?.roles?.includes(FRBS_ROLE.ADMIN);
  }
  return { ...presetDocument, isMemberAdmin };
}

export function useUserProfile(query?) {
  const { frbsUser } = useAppContext();
  const presetDocument = useDocument<UserSchema>(
    `${FPATH.USERS}/${frbsUser?.uid}`,
    {
      listen: true,
      ...query,
    }
  );

  function _isUserAdmin() {
    return presetDocument.data?.roles?.includes(FRBS_ROLE.ADMIN);
  }

  /**
   * ### 
   *  - Used to check if any of the roles do exist
   *  ----
   *  @example 
   *  userProfile._isRolesContain([
        FRBS_ROLE.ADMIN,
        FRBS_ROLE.DEVELOPER,
        `BS:${handlerPage?.data?.id}`,
      ])
   *  ----
   *  @version 22.02.10
   *  -  *Brief changelog*
   *  @author  Nl
   *  
   **/
  function _isRolesContain(roles: string[]) {
    return presetDocument.data?.roles?.some((role) => roles?.includes(role));
  }

  function _isBaseShopOwner(value: string) {
    return presetDocument.data?.roles?.includes(
      //@ts-ignore
      `BS:${value?.replace("BS:", "")}`
    );
  }

  function _isDeveloper() {
    return presetDocument.data?.roles?.includes(FRBS_ROLE.DEVELOPER);
  }

  function _updateFCMTokens(token: string) {
    return updateFCMTokens(frbsUser.uid, token);
  }

  return {
    ...presetDocument,
    _isUserAdmin,
    _isBaseShopOwner,
    _isDeveloper,
    _isRolesContain,
    _updateFCMTokens,
  };
}

export function useMembersCollection<Schema extends object = {}>(
  query?: CollectionQueryType<Schema>
) {
  const membersHandler = useCollectionPresetv2<UserSchema>(
    FPATH.USERS,
    "_id",
    query
  );
  return { ...membersHandler };
}

export const updateFCMTokens = async (uid: string, token: string) => {
  try {
    const userData: Partial<UserSchema> = {
      fcmTokens: firebase.firestore.FieldValue.arrayUnion(token),
    };
    const updatePosition = await firebase
      .firestore()
      .collection(FPATH.USERS)
      .doc(uid)
      .update(userData);

    return updatePosition;
  } catch (error) {
    return error;
  }
};
