//#region [import]
import { IconPrimr, ICON_NAME } from "assets";
import { Buttoon, dSuggestion, Kitten, M, Txt } from "components";
import { dAccessory, DatteLatte, sstyled, Toasty } from "components/atoms";
import {
  fn,
  TemplateSchema,
  useAppContext,
  useMailTemplatesFunctions,
  useMassMailFunctions,
  UserSchema
} from "engines";
import { useEmailSchedulesCollection } from "engines/firebase/handler/emails-handler";
import {
  useMembersCollection,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import React from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";
import { useNavigator } from "screens";
// import "react-quill/dist/quill.snow.css";
import { spacing, tr } from "utils";
//#endregion

/**
 * ### Post skeleton to show when db is being loaded
 *  ----
 *  @example
 *  loading && <PosstSkeleton />
 *  ----
 *  @version 21.03.05
 *  -  *Build a component*
 *  @author  NL
 *
 **/

export function MailSend(props: P) {
  //#region [FRBS]
  const { teamId } = useAppContext();
  const userProfile = useUserProfile();
  const handleSchedulesMails = useEmailSchedulesCollection();
  const handleMails = useMassMailFunctions();
  //#endregion

  // const handleMembers = useCollection<UserSchema>(`${FPATH.USERS}`, {
  //   // This is used when ever there isn't a where clause in the props
  //   ...Handler.defaultSetup<UserSchema>(teamId),
  // });
  const handleMembers = useMembersCollection({
    where: ["_teamId", "==", teamId],
  });

  const [members, setMembers] = React.useState<UserSchema[]>([]);
  React.useEffect(
    function setupData() {
      handleMembers.data && setMembers(handleMembers.data);
    },
    [handleMembers.data]
  );

  const [_subject, setSubject] = React.useState<string>();
  const [toggle, setToggle] = React.useState<boolean>(false);
  const [toggleDate, setToggleDate] = React.useState<boolean>(false);
  const [newRecipients, setNewRecipients] = React.useState<dSuggestion[]>([]);
  const [_ccopy, setCcopy] = React.useState<dSuggestion[]>([]);
  const [_bcopy, setBcopy] = React.useState<dSuggestion[]>([]);
  const [scheduleAt, setScheduleAt] = React.useState<string>("");
  const [allHierarchies, setallHierarchies] = React.useState<boolean>();
  const {} = useAppContext();

  const Navigation = useNavigator();

  const [_recipients, setRecipients] = React.useState<string>("");

  const [_emailFrom, setemailFrom] = React.useState<string>(
    userProfile.data?.personali.displayName
  );
  const [_email_reply_to, setEmail_reply_to] = React.useState<string>(
    userProfile.data?.personali.email
  );
  const [_date, setDate] = React.useState<any>(new Date());
  const [recipientStatus, setRecipientStatus] = React.useState<string>("basic");

  const handleTemplate = useMailTemplatesFunctions();
  const creatorForm = useForm();
  const MenuIcon = (name: ICON_NAME, props: dAccessory) => (
    <IconPrimr
      name={name}
      size={props.style.width}
      color={props.style.tintColor}
    />
  );

  const toggleShow = async () => {
    setToggle(!toggle);
  };

  const toggleShowDate = async () => {
    setToggleDate(!toggleDate);
  };

  // const [templateItem, settemplateItem] = React.useState<TemplateSchema>(
  //   handleTemplateUpdate.get()
  // );

  //region

  function SendMails(xong) {
    var _emails = !allHierarchies
      ? fn.js.getRecipientsEmail(newRecipients)
      : fn.js.getPersonaliEmails(members);
    var _reducedccpoy = fn.js.getRecipientsEmail(_ccopy);
    var _reducedbcopy = fn.js.getRecipientsEmail(_bcopy);

    if (_emails.length <= 0) {
      xong && xong();
      return setRecipientStatus("danger");
    }
    if (_emails.length == 1) {
      const payload = {
        recipient: _emails[0] ? _emails[0] : "",
        email_from: `${_emailFrom} <admin@apptakeoff.com>`,
        email_reply_to: _email_reply_to,
        email_subject: _subject ? _subject : "",
        email_html_body: props.designHtml,
        _teamId: userProfile.data?._teamId,
        Cc: _reducedccpoy ? _reducedccpoy.toString() : "",
        bcc: _reducedbcopy ? _reducedbcopy.toString() : "",
      };

      // console.log("single mail ", payload);
      // console.log("templateItem ", props.docID);

      handleMails
        .add(payload)
        .then((res) => {
          if (res.status == 200) {
            Toasty.show("Mail sent", { type: "success" });

            if (props?.sendandsave == true) {
              const payload = {
                appId: "primr-exp",
                userId: userProfile.data?._id,
                template_name: _subject,
                template_subject: _subject,
                _teamId: userProfile.data?._teamId,
                template_html_body: props.designHtml,
                template_jsonBody: JSON.stringify(props.designJson),
              };

              handleTemplate.add(payload).then((res) => {
                if (res.status == 200) {
                  Toasty.show("Template Created", { type: "success" });
                  xong && xong();
                }
              });
            } else if (props?.updatedSave == true) {
              const payload = {
                appId: "primr-exp",
                userId: userProfile.data?._id,
                docID: props.docID ? props.docID : "",
                template_id: props.templateItem.TemplateId.toString(),
                template_name: props.templateItem.Name,
                template_subject: props.templateItem.Name,
                template_html_body: props.designHtml,
                template_jsonBody: JSON.stringify(props.designJson),
              };
              // console.log(payload);

              handleTemplate.update(payload).then((res) => {
                console.log(res.status);
                if (res.status == 200) {
                  Toasty.show("Template Updated", { type: "success" });
                  xong && xong();
                } else if (res.status == 422) {
                  Toasty.show("Template validation error", {
                    type: "danger",
                  });
                  xong && xong();
                } else if (res.status == 400) {
                  Toasty.show("Template not updated", { type: "danger" });
                  xong && xong();
                }
              });
            }
            xong && xong();
            setTimeout(() => {
              Navigation.navigate("mass-mail-home");
            }, 1000);
          }
        })
        .catch(() => {
          console.log("an error occured");
          xong && xong();
          Toasty.show("Mail not sent", { type: "danger" });
        });
    } else if (_emails.length > 1) {
      if (_emails.length > 49) {
        Toasty.show(`Creating email batch`, {
          type: "warning",
        });

        var emails = [];
        for (let index = 0; index < _emails.length; index += 50) {
          emails.push(_emails.slice(index, index + 50));
        }

        for (let index = 0; index < emails.length; index++) {
          const payload = {
            recipients: emails[index] ? emails[index] : "",
            email_from: `${_emailFrom} <admin@apptakeoff.com>`,
            email_reply_to: _email_reply_to,
            email_subject: _subject ? _subject : "",
            email_html_body: props.designHtml,
            _teamId: userProfile.data?._teamId,
            Cc: _reducedccpoy ? _reducedccpoy.toString() : "",
            bcc: _reducedbcopy ? _reducedbcopy.toString() : "",
          };

          console.log("payload ", payload);

          handleMails
            .addbatch(payload)
            .then((res) => {
              console.log(res);
              Toasty.show(`Batch ${index + 1} recipients email sent`, {
                type: "success",
              });
            })
            .catch((e) => {
              console.log(e);
            });
        }
        xong && xong();
        setTimeout(() => {
          Navigation.navigate("mass-mail-home");
        }, 1000);
      } else {
        const payload = {
          recipients: _emails ? _emails : "",
          email_from: `${_emailFrom} <admin@apptakeoff.com>`,
          email_reply_to: _email_reply_to,
          email_subject: _subject ? _subject : "",
          email_html_body: props.designHtml,
          _teamId: userProfile.data?._teamId,
          Cc: _reducedccpoy ? _reducedccpoy.toString() : "",
          bcc: _reducedbcopy ? _reducedbcopy.toString() : "",
        };

        console.log("batch payload ", payload);
        handleMails
          .addbatch(payload)
          .then((res) => {
            if (res.status == 200) {
              if (props?.sendandsave == true) {
                const payload = {
                  appId: "primr-exp",
                  userId: userProfile.data?._id,
                  template_name: _subject,
                  // template_alias: subject,
                  template_subject: _subject,
                  template_html_body: props.designHtml,
                  template_jsonBody: JSON.stringify(props.designJson),
                  _teamId: userProfile.data?._teamId,
                };

                handleTemplate
                  .add(payload)
                  .then((res) => {
                    if (res.status == 200) {
                      Toasty.show("Template Created", { type: "success" });
                      xong && xong();
                    }
                  })
                  .catch(() => {
                    // console.log("an error occured");
                    xong && xong();
                    Toasty.show("Template not created", { type: "danger" });
                  });
              }
              if (props?.updatedSave == true) {
                const payload = {
                  appId: "primr-exp",
                  userId: userProfile.data?._id,
                  docID: props.docID,
                  template_id: props.templateItem.TemplateId.toString(),
                  template_name: props.templateItem.Name,
                  template_subject: props.templateItem.Name,
                  template_html_body: props.designHtml,
                  template_jsonBody: JSON.stringify(props.designJson),
                };
                // console.log(payload);

                handleTemplate
                  .update(payload)
                  .then((res) => {
                    console.log(res.status);
                    if (res.status == 200) {
                      Toasty.show("Template Updated", { type: "success" });
                      xong && xong();
                    } else if (res.status == 422) {
                      Toasty.show("Template validation error", {
                        type: "danger",
                      });
                      xong && xong();
                    } else if (res.status == 400) {
                      Toasty.show("Template not updated", { type: "danger" });
                      xong && xong();
                    }
                  })
                  .catch(() => {
                    // console.log("an error occured");
                    xong && xong();
                    Toasty.show("Template not updated", { type: "danger" });
                  });
              }

              xong && xong();
              setTimeout(() => {
                Navigation.navigate("mass-mail-home");
              }, 1000);
              Toasty.show("Mail sent", { type: "success" });
            }
          })
          .catch(() => {
            xong && xong();
            // console.log("an error occured");
            Toasty.show("Mail not sent", { type: "danger" });
          });
      }
    }
  }

  function scheduleMail(xong) {
    var _emails = fn.js.getRecipientsEmail(newRecipients);
    var _reducedccpoy = fn.js.getRecipientsEmail(_ccopy);
    var _reducedbcopy = fn.js.getRecipientsEmail(_bcopy);

    let projectId = "primr-exp";
    let payload;

    console.log(_recipients);

    if (_emails.length <= 0) {
      return setRecipientStatus("danger");
    }

    if (_emails.length == 1) {
      payload = {
        email_from: `${_emailFrom} <admin@apptakeoff.com>`,
        recipient: _emails[0],
        appId: projectId,
        email_subject: _subject,
        email_html_body: props.designHtml,
        cc: _reducedccpoy.toString(),
        bcc: _reducedbcopy ? _reducedbcopy.toString() : "",
        email_reply_to: _email_reply_to,
        scheduleDate: scheduleAt,
        scheduleStatus: false,
        type: "single",
        userId: userProfile.data?._id,
        _teamId: userProfile.data?._teamId,
      };
    } else {
      payload = {
        email_from: `${_emailFrom} <admin@apptakeoff.com>`,
        recipients: _emails,
        appId: projectId,
        email_subject: _subject,
        email_html_body: props.designHtml,
        cc: _reducedccpoy ? _reducedccpoy.toString() : "",
        bcc: _reducedbcopy ? _reducedbcopy.toString() : "",
        email_reply_to: _email_reply_to,
        scheduleDate: scheduleAt,
        scheduleStatus: false,
        type: "batch",
        userId: userProfile.data?._id,
        _teamId: userProfile.data?._teamId,
      };
    }

    handleSchedulesMails
      .add(payload)
      .then(() => {
        xong && xong();
        setTimeout(() => {
          Navigation.navigate("mass-mail-home");
        }, 1000);
        Toasty.show("Mail has been scheduled", { type: "success" });
      })
      .catch(() => {
        xong && xong();
        Toasty.show("Mail failed to be scheduled", { type: "danger" });
      });
  }

  return (
    <>
      <A.CtnrBuilder>
        <Txt.$Title marginV>{tr("Send to")}</Txt.$Title>
        <Kitten.Input
          accessoryLeft={() => <Txt.Helper>{tr("Sender's name")}</Txt.Helper>}
          accessoryRight={() => (
            <Txt.Helper>{`(${_email_reply_to})`}</Txt.Helper>
          )}
          // disabled
          placeholder={tr("Email")}
          autoCapitalize="none"
          value={_emailFrom}
          onChangeText={setemailFrom}
        />

        <Kitten.ListItemv2
          title={tr("Send to all members")}
          accessoryRight={() => (
            <Kitten.CheckBox
              checked={allHierarchies}
              onChange={() => {
                setallHierarchies(!allHierarchies);
              }}
            />
          )}
        />

        {!allHierarchies ? (
          <M.MembersMultiInput
            title={tr("To: ")}
            onRecipientsCallback={setNewRecipients}
            containerStyle={{ zIndex: 9999999 }}
          />
        ) : null}

        <M.MembersMultiInput
          title={tr("Cc")}
          onRecipientsCallback={setCcopy}
          containerStyle={{ zIndex: 999999 }}
        />
        <M.MembersMultiInput
          title={tr("Bcc")}
          onRecipientsCallback={setBcopy}
          containerStyle={{ zIndex: 99999 }}
        />
        <Kitten.Input
          accessoryLeft={() => <Txt.Helper>{tr("Subject")}</Txt.Helper>}
          autoFocus={true}
          // // disabled={_runningTSKS || !R.isNil(frbsAuthe)}
          autoCapitalize="none"
          value={_subject}
          onChangeText={setSubject}
          // onBlur={() => Keyboard.dismiss()}
          selectTextOnFocus
        />

        <Kitten.Divider_________ />
        <Kitten.CheckBox
          checked={toggleDate}
          onChange={toggleShowDate}
          style={{ margin: spacing(3) }}
        >
          {
            tr("Schedule") //* `Would you like to schedule this mail?`
          }
        </Kitten.CheckBox>

        {toggleDate ? (
          <>
            <DatteLatte
              onSelectCallback={(datte) => {
                setScheduleAt(datte);
                // onSetSchedule(isScheduled, datte);
              }}
            />
            <Txt.Indicator marginV>
              {tr("Mail will be scheduled to send at ") + scheduleAt}
            </Txt.Indicator>

            {/* <Kitten.Datepicker
              // label='Label'
              // caption='Caption'
              min={new Date()}
              placeholder="Pick Date"
              date={_date}
              onSelect={(nextDate) => setDate(nextDate)}
              accessoryRight={() => (
                <>
                  <IconPrimr
                    name="calendar"
                    // size={props.style.width}
                    // color={props.style.tintColor}
                  />
                </>
              )}
            /> */}
          </>
        ) : null}

        {!toggleDate ? (
          <>
            <Buttoon progress onPress={(xong) => SendMails(xong)}>
              {tr("Send")}
            </Buttoon>
          </>
        ) : (
          <>
            <Buttoon onPress={(xong) => scheduleMail(xong)}>
              {tr("Schedule")}
            </Buttoon>
          </>
        )}
      </A.CtnrBuilder>
    </>
  );
}

/**
 * ###  Screen Props
 */
interface P {
  docID?: string;
  designJson: any;
  designHtml: any;
  sendandsave?: boolean;
  updatedSave?: boolean;
  templateItem?: TemplateSchema;
}

const A = {
  CtnrBuilder: sstyled(View)((p) => ({
    flex: 1,
    backgroundColor: "background",
    width: "100%",
    marginBottom: 10,
    paddingHorizontal: spacing(2),
  })),
  CtnrMedia: sstyled(Kitten.ViewH)({
    // justifyContent: "space-evenly",
    // flexWrap: "wrap",
    // flexDirection: "row",

    flex: 1,
    // flexDirection: "row",
    flexWrap: "wrap",
    // width: "100%",
  }),

  InputBody: sstyled(Kitten.Input)((p) => ({
    // maxWidth: 400,
    width: "100%",
    maxHeight: 800,
    padding: spacing(3),
    // backgroundColor: "back",
  })),
};
