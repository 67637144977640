import { FPATH, useAppContext, useConfigDocument } from "engines";
import firebase from "firebase";
import { Alert } from "react-native";
import { dActivityConfiguration } from "../../backends/schemas/configs/configs.schema";
import {
  dActivityEntry,
  UserSchema
} from "../../backends/schemas/users/user.schema";
import { useCollectionPresetv2 } from "../config";

/**
 * Whether given Timestamp or Date it returns Date
 */
export function getNormalDate(date: Date | firebase.firestore.Timestamp): Date {
  if ((date as firebase.firestore.Timestamp)?.toDate)
    return (date as firebase.firestore.Timestamp).toDate();
  return date as Date;
}
/**
 * A consistent string for comparing dates by their day month and year
 */
export function dateToStr(date: Date): string {
  return date ? date?.toISOString()?.split("T")[0] : "";
}

/**
 * Used in updating, deleting, getting the activity history for a user
 */
export function useActivityCollection(userId: string, query?) {
  const collectionPath = `${FPATH.USERS}/${userId}/${FPATH.USER_ACTIVITIES}`;
  const defaultCollectionFunctions = useCollectionPresetv2<dActivityEntry>(
    collectionPath,
    {
      listen: true,
      ...query,
    }
  );
  //#region [section] other hooks for sub functions
  const { frbsUser, teamId } = useAppContext();
  const configHandler = useConfigDocument(`variables-${teamId}`);

  let _configActivities = configHandler.data?.activities;
  //#endregion
  async function updateActivityByKeyAndDate(
    currentActivities: dActivityEntry[],
    activityKey: string,
    matchDate: Date,
    data: dActivityEntry
  ) {
    // const currentActivities = defaultCollectionFunctions.data;
    for (let a in currentActivities) {
      const act = currentActivities[a];
      const activityDate = act?.date?.toDate();

      if (
        act.activityKey === activityKey &&
        (activityDate && activityDate.toISOString().split("T")[0]) ===
        (matchDate && matchDate.toISOString().split("T")[0])
      ) {
        console.log(
          `[activity] Found existing activity to update (${activityKey}) : ${JSON.stringify(
            data
          )}`
        );
        return firebase
          .firestore()
          .doc(`${collectionPath}/${act._id}`)
          .update(data);
      }
    }
    console.log(
      `[activity] No pre existing activity found with provided key and date. Creating a new one...`
    );
    const _ref = await firebase
      .firestore()
      .collection(collectionPath)
      .add(data);
    return firebase
      .firestore()
      .collection(collectionPath)
      .doc(_ref.id)
      .update({ _id: _ref.id });
  }

  function calculateStreakForActivity(
    activityKey: dActivityEntry["activityKey"],
    allActivities: dActivityEntry[],
    /**
     * If provided, this is the minimum number a value must be at to count towards a streak.
     */
    streakMinimum = 1,
    /** Provide if you want to update the user's public facing streaks automatically (only if needed) */
    user?: UserSchema
  ): number {
    // filters for provided key, then sorts most recent to latest
    const sortedActivities = allActivities
      .filter((a) => a.activityKey === activityKey)
      .sort(
        (a, b) =>
          getNormalDate(b.date).getTime() - getNormalDate(a.date).getTime()
      );

    const today = new Date();
    const oneday = 86400 * 1000;

    const _getActivityWithDate: (date: Date) => dActivityEntry | undefined = (
      _date
    ) =>
      sortedActivities.find(
        (a) => dateToStr(getNormalDate(a.date)) === dateToStr(_date)
      );

    const _isActivityComplete: (activity: dActivityEntry | undefined) => boolean = (
      _activity
    ) => {
      if (_activity && typeof _activity?.value === "string")
        return _activity.value.length > 0 && _activity.value !== "0";
      if (_activity && typeof _activity?.value === "number")
        return _activity.value >= streakMinimum;
      return _activity && _activity.value ? true : false;
    };

    let streak = _getActivityWithDate(today) ? 1 : 0; // if today's streak was filled out already, start them at 1
    const previousDayDate = new Date();
    previousDayDate.setTime(previousDayDate.getTime() - oneday);
    while (_isActivityComplete(_getActivityWithDate(previousDayDate))) {
      if (streak > sortedActivities.length) {
        // just in case something crazy happens... (while loops are scarrrrry 0.o)
        Alert.alert(
          "Something went wrong.",
          "Infinite streak reached. Stopping."
        );
        break;
      }

      ++streak;
      previousDayDate.setTime(previousDayDate.getTime() - oneday);
    }

    return streak;
  }

  function getTodaysActivities(allActivities: dActivityEntry[]): dActivityEntry[] {
    const today = new Date();
    return allActivities.filter((a) => {
      const _activityDate = a.date.toDate();
      return (
        _activityDate.getDate() === today.getDate() &&
        _activityDate.getMonth() === today.getMonth() &&
        _activityDate.getFullYear() === today.getFullYear()
      );
    });
  }

  function getActivitiesWithinDays(
    activityKey: dActivityEntry["activityKey"],
    allActivities: dActivityEntry[],
    daysBack: number,
    endDate?: Date
  ): dActivityEntry[] {
    const pastDate = endDate ? endDate : new Date();
    pastDate.setDate(pastDate.getDate() - daysBack);
    return allActivities.filter((a) => {
      if (a.activityKey !== activityKey) return false;
      const entryDate = a.date?.toDate();
      if (entryDate > pastDate) {
        if (endDate && entryDate > endDate) return false;
        else return true;
      }
      return false;
    });
  }
  function getActivitiesWithinMonth(
    activityKey: dActivityEntry["activityKey"],
    allActivities: dActivityEntry[],
    month: Date
  ): dActivityEntry[] {
    return allActivities.filter((a) => {
      if (a.activityKey !== activityKey) return false;
      const entryDate = a.date?.toDate();
      if (
        entryDate.getFullYear() === month.getFullYear() &&
        entryDate.getMonth() === month.getMonth()
      )
        return true;
      return false;
    });
  }
  function getTotalCountOfActivityWithinDays(
    activityKey: dActivityEntry["activityKey"],
    allActivities: dActivityEntry[],
    daysBack: number,
    endDate?: Date
  ): number {
    const _activities = getActivitiesWithinDays(
      activityKey,
      allActivities,
      daysBack,
      endDate
    );
    let _total = 0;
    for (let a in _activities) {
      const _value = _activities[a].value;
      if (typeof _value === "string") _total += 1;
      else if (typeof _value === "number") _total += _value;
      else if (typeof _value === "boolean" && _value === true) ++_total;
    }
    return _total;
  }
  function isNumeric(str: string) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }
  function getTotalCountOfActivityWithinMonth(
    activityKey: dActivityEntry["activityKey"],
    allActivities: dActivityEntry[],
    month: Date
  ): number {
    const _activities = getActivitiesWithinMonth(
      activityKey,
      allActivities,
      month
    );
    let _total = 0;
    for (let a in _activities) {
      const _value = _activities[a].value;
      if (typeof _value === "string") {
        if (isNumeric(_value)) _total += Number.parseFloat(_value)
        else
          _total += 1;
      }
      else if (typeof _value === "number") _total += _value;
      else if (typeof _value === "boolean" && _value === true) ++_total;
    }
    return _total;
  }

  /**
   * The following will update the front facing user account with their streak for more convenient access (if anything has changed and user is provided in params)
   */
  function updateStreakInUserIfNeeded(
    activityKey: string,
    streak: number,
    user: UserSchema
  ) {
    const _preExistingActivityStreak =
      user &&
      user?.activityStreaks?.find((obj) => obj.activityKey === activityKey);
    if (
      user &&
      (!user?.activityStreaks ||
        _preExistingActivityStreak?.streak !== streak) &&
      streak > 0
    ) {
      let updatedActivityStreaks = user?.activityStreaks || [];
      if (_preExistingActivityStreak)
        // update pre existing streak
        updatedActivityStreaks = updatedActivityStreaks.map((_act) =>
          _act.activityKey === activityKey ? { ..._act, streak } : _act
        );
      // add for the first time
      else
        updatedActivityStreaks.push({
          activityKey: activityKey as string,
          streak,
        });

      firebase
        .firestore()
        .doc(`${FPATH.USERS}/${user._id}`)
        .update({
          activityStreaks: updatedActivityStreaks,
        } as Partial<UserSchema>);
    }
  }

  /**
   * Calculate the highest streak this user has, and then update user streaks in front facing profile if needed
   * - optionally provide user param to update front facing profile streaks automatically
   * 
   * @example
   * const highestStreak = activityHandler.getHighestStreak(
          activityHandler.data,
          configHandler.data?.activities,
          userD // optional but recommended (see description)
        )
   */
  function _getHighestStreak(
    userActivities?: dActivityEntry[],
    activityConfigs?: dActivityConfiguration[],
    user?: UserSchema
  ): number {
    const _userActivities = userActivities || defaultCollectionFunctions?.data;
    const _activityConfigs = activityConfigs || _configActivities;
    if (!_userActivities || !_activityConfigs) return 0;
    let _highestStreak = 0;
    for (let a in _activityConfigs) {
      const _actConf = _activityConfigs[a];
      const streak = calculateStreakForActivity(
        _actConf.key,
        _userActivities,
        _actConf.streakMinimum
      );
      if (_highestStreak < streak) _highestStreak = streak;
      /** updates front facing streak numbers if needed */
      updateStreakInUserIfNeeded(_actConf.key, streak, user);
    }
    return _highestStreak;
  }

  return {
    ...defaultCollectionFunctions,
    updateActivityByKeyAndDate,
    calculateStreakForActivity,
    getTodaysActivities,
    getActivitiesWithinDays,
    getTotalCountOfActivityWithinDays,
    getTotalCountOfActivityWithinMonth,
    getActivitiesWithinMonth,
    _getHighestStreak,
  };
}

export function getDefaultValueForEntryType(
  entryType: dActivityConfiguration["entryType"]
) {
  switch (entryType) {
    case "checkbox":
      return false;
    case "number":
      return 0;
    case "text":
      return "_";
    default:
      return null;
  }
}
