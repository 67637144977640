//#region [import]

import { Buttoon, Kitten } from "components";
import { AddContactCreator } from "components/organisms/contact-manager/AddContactCreatorDialog";
import { useUserProfile } from "engines";
import * as Contacts from "expo-contacts";
import React from "react";
import { FlatList, Keyboard } from "react-native";
// import { FlatList } from "react-native-gesture-handler";
import { useNavigator } from "screens";
import { spacing } from "utils";
import { ContactCell } from "./ContactCell";
// import { ContactCell } from "./ContactCell";

//#endregion

/**
 * ### Web version of Home screen
 *  ----
 *  @version 21.03.01
 *  -  *Brief changelog*
 *  @author  @nguyenkhooi
 *
 **/
export function ContactSearch(props: D) {
  console.log("const id = props.route?.params?.id; ", props.route.params.id);
  const { route } = props;
  const Navigation = useNavigator();
  const [_searchValue, setSearchValue] = React.useState("");
  const [phonecontacts, setPhonecontacts] = React.useState<any[]>([]);
  // const bottomBarHeight = useBottomTabBarHeight();
  const bottomBarHeight = 101;

  function filterHandler(value: string) {
    setSearchValue(value);
    if (value.length > 0) {
      const newData = phonecontacts.filter((contact) => {
        // console.log("member: ", contact);
        return contact.name?.toLowerCase().includes(value.toLowerCase());
      });
      // console.log("valuee: ", newData);
      setPhonecontacts([...newData]);
    } else {
      fetchContacts();
    }
  }

  async function fetchContacts() {
    const { status } = await Contacts.requestPermissionsAsync();
    if (status === "granted") {
      const { data } = await Contacts.getContactsAsync({
        fields: [Contacts.Fields.PhoneNumbers],
      });

      if (data.length > 0) {
        setPhonecontacts(data);
        const contact = data[0];
        //   console.log("contact ", contact);
      }
    }
  }
  React.useEffect(() => {
    fetchContacts();
  }, []);

  try {
    return (
      <>
        <Kitten.Input
          value={_searchValue}
          onChangeText={(value) => {
            filterHandler(value);
          }}
          placeholder="Search someone..."
          style={{ borderRadius: 30, width: "95%", alignSelf: "center" }}
        />
        <$_ContactList
          phonecontacts={phonecontacts}
          contact_category_id={route?.params?.id}
        />
      </>
    );
  } catch (error) {
    throw Error(`::Q29udGFjdHNTY3JlZW4=::${error}`);
  }
}

interface D {
  route?: { params?: { id: string } }; //* unmask if we pass params thru rnav
}

function $_ContactList(props: P) {
  const { phonecontacts, contact_category_id } = props;
  // console.log("wordSearch ", wordSearch);
  const userHandler = useUserProfile()
  const Navigation = useNavigator();
  // const selectedUser = _members
  //   .filter((member) => {
  //     return member._id?.toLowerCase().includes(userID.toLowerCase());
  //   })
  //   .pop();

  // console.log("phonecontacts ", phonecontacts.length);

  return (
    <>
      <FlatList
        data={phonecontacts}
        onScroll={() => Keyboard.dismiss()}
        keyboardShouldPersistTaps="always"
        extraData={true}
        keyExtractor={(item, index) => item + index}
        // scrollBarColor="white"
        // activeColor="#204ae3"
        renderItem={({ item, index }) => (
          // <Txt.P1>{tr(item.name)}</Txt.P1>
          <ContactCell item={item} contact_category_id={contact_category_id} user={userHandler.data} />
        )}
        ListHeaderComponent={() => (
          <Buttoon
            style={{
              marginHorizontal: -spacing(4),
            }}
            appearance="ghost"
            icon={{ name: "plus" }}
            onPress={() => {
              Navigation.dialogPortal.open({
                headerTitle: "Add contact",
                render: <AddContactCreator id={contact_category_id} />,
              });
            }}
          >
            Create Contact
          </Buttoon>
        )}
      />
      {/* <AlphaScrollFlatList
        onScroll={() => Keyboard.dismiss()}
        keyboardShouldPersistTaps="always"
        style={{ height: "100%" }}
        scrollBarColor="white"
        activeColor="#204ae3"
        ListHeaderComponent={() => (
          <Buttoon
            style={{
              marginHorizontal: -spacing(4),
            }}
            appearance="ghost"
            icon={{ name: "plus" }}
            onPress={() => {
              Navigation.dialogPortal.open({
                headerTitle: "Add contact",
                render: <AddContactCreator id={contact_category_id} />,
              });
            }}
          >
            Create Contact
          </Buttoon>
        )}
        scrollKey={"name"}
        data={phonecontacts}
        renderItem={({ item, index }) => (
          <ContactCell item={item} contact_category_id={contact_category_id} />
        )}
        itemHeight={moderateScale(38, 0.3)}
      /> */}
    </>
  );
}

interface P {
  phonecontacts: any[];
  contact_category_id?: string;
}
