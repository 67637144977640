import { ICON_NAME } from "assets";
import { MenuIcon } from "assets/icons/MenuIcon";
import { dAccessory, Kitten, Toasty } from "components";
import { useTwoColumns } from "components/templates";
import { useAppContext } from "engines";
import { useConfigDocument } from "engines/firebase/handler/config-handler";
import React from "react";
import { useNavigator } from "screens";
import { tr } from "utils";
import { dCollaboration } from "../../../screens/collaboration/Collaboration-Multiverse";
import { EditDailyStats } from "./EditDailyStats";

interface dToolsList {}

/**
 * ### List of admin tools
 *  - e.g. Mass Mail, Notification Center, Collaboration
 *  ----
 *  @example
 *
 *  ----
 *  @version 21.11.17
 *  -  *Build component*
 *  @author  nguyenkhooi
 *
 **/
export function AdminToolsList(props: dToolsList) {
  const {} = props;
  const { teamId, C } = useAppContext();
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();

  //#region [FRBS]
  const toolsHandler = useConfigDocument(`variables-${teamId}`);

  const data = toolsHandler?.data;

  let toolsDoc = data && Array.isArray(data?.tools) ? data?.tools : null;
  //#endregion

  //#region [section] tools array
  let _tools: { name: string; icon: ICON_NAME; onPress: () => void }[] = [
    {
      name: tr("Edit Daily Report Form"),
      icon: "pencil",
      onPress: () => {
        // !breakpointForTwoColumns && Navigation.goBack();
        Navigation.dialogPortal.open({
          render: <EditDailyStats />,
        });
      },
    },
    {
      name: tr("Content Multiverse"),
      icon: "world",
      onPress: () => {
        // IS_WEB
        //   ? Navigation.navigate<dCollaboration>("multiverse", { srcPageId: "" })
        //   : Toasty.show(tr("Content Multiverse Feature coming soon"));
        !breakpointForTwoColumns && Navigation.goBack();
        Navigation.navigate<dCollaboration>("multiverse", {
          srcPageId: "",
        });
      },
    },
    {
      name: tr("Mass Mail"),
      icon: "email",
      onPress: () => {
        toolsDoc?.some((value) => value === "Mass Mail")
          ? Navigation.navigate("mass-mail-home")
          : Toasty.show(tr("Mass Mail Feature not activated"));
      },
    },
    {
      name: tr("Push Notifications"),
      icon: "notification",
      onPress: () => {
        Navigation.navigate("broadcast");
      },
    },
    // {
    //   name: tr("Blast SMS"),
    //   icon: "bullhorn",
    //   onPress: () => {
    //     Toasty.show(tr("Blast SMS coming soon"));
    //   },
    // },
  ];
  //#endregion

  try {
    if (toolsHandler.error) throw Error();
    if (!toolsHandler.data) return null;

    return (
      <Kitten.Menu
        onSelect={(index) => {
          _tools[index.row]?.onPress();
        }}
        style={{ borderWidth: 1, borderColor: C.adminGold }}
      >
        {_tools.map((tool, index) => (
          <Kitten.MenuItem
            key={tool.name}
            title={tool.name}
            accessoryLeft={(props: dAccessory) => MenuIcon(tool.icon, props)}
          />
        ))}
      </Kitten.Menu>
    );
  } catch (error) {}
}
