import { PageBodyScreen } from "screens/page-body/PageBodyScreen";

/**
 * ###  A Navigator for Media Resources Screens
 * -  Media Resources contains custom pages of media
 * that users upload for educational purposes
 * ---
 * @version 1.6.25
 * -  *Decentralized from main.navigator*
 * @author K
 */
export const BSStackCollection = {
  "bs-body-scr": {
    component: PageBodyScreen,
  },
};

export type BS_STK = keyof typeof BSStackCollection;

export interface dBSBodyScreenParam {
  cpid: string;
}
