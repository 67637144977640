import { THEME } from "utils";
import { APP_CONFIG_PARAMS } from "./apptakeoff-config.schema";

// App Takeoff
export const APP_CONFIG: APP_CONFIG_PARAMS = {
    // app_id: "unleash-apptakeoff",
    login: {
        // bypass_access_code: ">>UNLEASH",
        // access_code_placeholder: "",
        // access_code_prefix: ""
    },
    teams: {
        enabled: false
    },
    styling: {
        default_mode: THEME.DARK
    }
}

// Patriots Club Card
export const PC_APP_CONFIG: APP_CONFIG_PARAMS = {
    app_id: "patriots-club-card",
    login: {
        bypass_access_code: "PCC:VIP",
        access_code_placeholder: "Realtor's ID Code",
        access_code_prefix: "PCC:"
    },
    teams: {
        enabled: false
    },
    styling: {
        default_mode: THEME.LIGHT
    }
}