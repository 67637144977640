//#region [import]
import { Buttoon, Kitten, M, Skeleton, Txt } from "components";
import { ListHeader, sstyled } from "components/atoms";
import { useTwoColumns } from "components/templates";
import { useBreakpointIndex } from "dripsy";
import { useAppContext, _DEFAULT_TEMPLATE } from "engines";
import { useEmailTemplateCollection } from "engines/firebase/handler/emails-handler";
import { props } from "ramda";
import React from "react";
import { useWindowDimensions, View } from "react-native";
import * as Animatable from "react-native-animatable";
import { FlatGrid } from "react-native-super-grid";
import { useNavigator } from "screens";
// import { ICustomViewStyle } from "react-native-skeleton-content/lib/Constants";
import { tr } from "utils";

//#endregion

export function MailTemplatesCreate() {
  const { C } = useAppContext();
  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();
  const handleTemplates = useEmailTemplateCollection();
  const Navigation = useNavigator();
  const [order, setOrder] = React.useState<"desc" | "asc">("desc");
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const breakpoint = useBreakpointIndex();

  // const handleTemplates = useTemplatesCollection({
  //   where: ["_teamId", "==", userProfile.data?._teamId]
  // });
  const { width } = useWindowDimensions();

  // React.useEffect(function dataManupilation () {

  // }, []);
  // const data = handleTemplates.data;
  // console.log(data);

  // settemplates(_templates);

  //#endregion

  if (handleTemplates.error) return <Txt.Indicator>Error!</Txt.Indicator>;
  if (!handleTemplates.data) return <Skeleton {...Skeleton.Screen.Feed} />;
  console.log(handleTemplates.data[0]?.id);
  return (
    <FlatGrid
      contentContainerStyle={[column1ContainerStyle]}
      ListHeaderComponent={
        <View>
          <ListHeader titleTx={tr("Your mail templates")} />
          <Txt.Helper>
            {tr(
              "Start create an email by selecting a template, or start with Blank"
            )}
          </Txt.Helper>
        </View>
      }
      itemDimension={breakpoint <= 2 ? width * 0.9 : width * 0.12}
      // data={_templates}
      data={[_DEFAULT_TEMPLATE, ...handleTemplates.data]}
      spacing={10}
      renderItem={({ item, index }) => {
        return index == 0 ? (
          <A.Ctnr
            onPress={() => {
              Navigation.navigate("mass-mail-compose");
            }}
          >
            <Txt.H5>Blank</Txt.H5>
          </A.Ctnr>
        ) : (
          <Animatable.View animation="fadeIn" delay={100 * index}>
            <M.MailTemplateListItem {...props} item={item} />
          </Animatable.View>
        );
      }}
      ListEmptyComponent={() => (
        <Buttoon appearance="ghost" status={"basic"}>
          Empty Feed
        </Buttoon>
      )}
      ListFooterComponent={ViewCol1BottomSpace}
    />
  );
}

const A = {
  Ctnr: sstyled(Kitten.Card)({
    height: 300,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "dim",
  }),
};
