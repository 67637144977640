import { ListItem, ListItemProps } from "@ui-kitten/components";
import { useAppContext } from "engines/hooks";
import * as React from "react";
import { View } from "react-native";
import { sstyled, Txt } from "../../generals";
import { spacing } from "utils";
/**
 * ### Modded List Item
 *  - FIx og ListItem rendering issue
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.03.20
 *  -  *Build a component*
 *  @author  K
 *
 **/
export function ListItemv2(props: ListItemProps) {
  const {
    title,
    description,
    accessoryLeft,
    accessoryRight,
    disabled,
    titleNumberOfLines = 1,
    descriptionNumberOfLines = 1,
  } = props;
  const { C } = useAppContext();
  return (
    <ListItem {...props}>
      <A.Ctnr>
        <A.CtnrAccessory style={[props.disabled && { opacity: 0.6 }]}>
          {accessoryLeft ? accessoryLeft() : null}
        </A.CtnrAccessory>
        <A.CtnrContent>
          {typeof title == "string" ? (
            <Txt.S2
              numberOfLines={titleNumberOfLines}
              style={[disabled && { color: C.grey600 }]}
            >
              {title}
            </Txt.S2>
          ) : null}
          {typeof title == "function" ? title() : null}
          {description && typeof description == "string" ? (
            <A.TxtDescription numberOfLines={descriptionNumberOfLines}>
              {description}
            </A.TxtDescription>
          ) : null}
          {description && typeof description == "function"
            ? description()
            : null}
        </A.CtnrContent>
        <A.CtnrAccessory>
          {accessoryRight ? accessoryRight() : null}
        </A.CtnrAccessory>
      </A.Ctnr>
    </ListItem>
  );
}

let A = {
  Ctnr: sstyled(View)({
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
  }),
  CtnrContent: sstyled(View)({
    flex: 1,
    paddingLeft: spacing(3),
    justifyContent: "center",
  }),
  CtnrAccessory: sstyled(View)({
    justifyContent: "center",
  }),
  TxtDescription: sstyled(Txt.C2)({
    fontWeight: "normal",
  }),
};
