import { Buttoon, Kitten, ListHeader, Skeleton } from "components";
import { sstyled, Txt } from "components/atoms";
import { WidgetUserStats } from "components/molecules/shortcuts/WidgetUserStats";
import {
  useActivityCollection,
  useAppContext,
  useCalendar,
  useConfigDocument,
  useMemberDocument,
  usePageDocument
} from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import {
  getTodaysCompletedFollowUpContacts,
  useContactsCollection,
  useFollowUpContacts
} from "engines/firebase/handler/contact-handler";
import {
  useCalculateProgressForPage,
  useGetLevelsOnParentPage
} from "engines/firebase/handler/levels-handler";
import React from "react";
import { ImageBackground, Pressable, View } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { useNavigator } from "screens";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { scale, spacing, THEME, themeDark, themeLight, tr } from "utils";
import { dTask } from "../../../engines/backends/schemas/tasks/tasks.schema";
import { Avt } from "../../atoms/generals/avt/Avt";
import { CalendarSection } from "./CalendarSection";
import { FollowUpSection } from "./FollowUpSection";
import { LevelsDashboardCenter } from "./LevelsDashboardCenter";
import { StatsCreatorCenter } from "./StatsCreatorCenter";
import { TaskSection } from "./TaskSection";

export function MyDaySection(props) {
  const { C, frbsUser, teamId, theme } = useAppContext();

  const Navigation = useNavigator();
  // const contactsHandler = useContactsCollection(frbsUser?.uid);
  const userActivities = useActivityCollection(frbsUser?.uid);
  const { data: configVariables, ...varHandler } = useConfigDocument(
    `variables-${teamId}`
  );
  // const configHandler = useConfigDocument(`variables-${teamId}`);
  // const configVariables = configHandler?.data as ConfigVariablesSchema;
  const memberHandler = useMemberDocument(frbsUser?.uid);
  const parentLevelPage = memberHandler.data?.lastParentLevelPage
    ? memberHandler.data?.lastParentLevelPage
    : memberHandler.data?.parentLevelPages &&
    memberHandler.data?.parentLevelPages[
    memberHandler.data?.parentLevelPages?.length - 1
    ];
  const levelsOnParentPage = useGetLevelsOnParentPage(
    memberHandler.data?.parentLevelPages &&
      memberHandler.data?.parentLevelPages?.length > 0
      ? parentLevelPage
      : ""
  );
  const incompleteLevelPages =
    levelsOnParentPage?.filter(
      (p) => !(memberHandler.data?.completedLevelPages || [])?.includes(p)
    ) || [];
  const currentLevelPageId =
    incompleteLevelPages.length > 0 ? incompleteLevelPages[0] : "";
  // memberHandler.data?.lastLevelPage
  // ? memberHandler.data?.lastLevelPage
  const currentTrainingPageHandler = usePageDocument(currentLevelPageId);
  const levelProgress = useCalculateProgressForPage(
    currentLevelPageId,
    memberHandler.data,
    { memberHandler, levelPageHandler: currentTrainingPageHandler }
  );
  // const levelProgress = 1;

  // const calendarHandler = useCalendar();

  // const contactsHandler = useContactsCollection(frbsUser?.uid);
  // const [followUpContacts, setFollowUpContacts] =
  //   React.useState<ContactSchemaa[]>(null);
  // const endOfToday = new Date();
  // endOfToday.setUTCHours(23, 59, 59, 999);
  // const followUpContacts = contactsHandler.data?.filter(
  //   (c) => c.followUpDate?.toDate && c.followUpDate?.toDate() <= endOfToday
  // );
  const followUpContactsHandler = useFollowUpContacts(frbsUser?.uid)
  const followUpContacts = followUpContactsHandler.data

  // TODO: add into my day percentage
  const [incompleteTasks, setIncompleteTasks] = React.useState<dTask[]>();
  function onIncompleteTasks(tasks: dTask[]) {
    setIncompleteTasks(tasks);
  }

  // total percentage of calendar, tasks, and follow ups
  // const [todaysProgress, setTodaysProgress] = React.useState<number>(-1);
  // const refContactsDidFollowUpToday = React.useRef<ContactSchemaa[]>(null);
  // React.useEffect(
  //   function updateTodaysProgress() {
  //     if (!calendarHandler.data || !followUpContacts) return;

  //     if (!refContactsDidFollowUpToday.current) {
  //       getTodaysCompletedFollowUpContacts(frbsUser?.uid).then((fuContacts) => {
  //         refContactsDidFollowUpToday.current = fuContacts;
  //       });
  //     }
  //     if (!refContactsDidFollowUpToday.current) return;

  //     const todaysEvents = calendarHandler?.getTodaysEvents();
  //     const eventsToGo = calendarHandler?.getUpcomingEvents();
  //     const calendarPercent =
  //       todaysEvents.length < 1 || eventsToGo?.length < 1
  //         ? 1
  //         : eventsToGo.length / todaysEvents.length;

  //     const totalContactsToday =
  //       refContactsDidFollowUpToday.current?.length + followUpContacts.length;
  //     const followUpPercent =
  //       totalContactsToday < 1
  //         ? 1
  //         : (totalContactsToday - followUpContacts?.length) /
  //         totalContactsToday;

  //     const overallPercent = (calendarPercent + followUpPercent) / 2;
  //     console.log(
  //       `Overall percent fu: ${refContactsDidFollowUpToday.current?.length}/${totalContactsToday} (${followUpPercent}) cal: ${eventsToGo?.length}/${todaysEvents?.length}`
  //     );
  //     setTodaysProgress(overallPercent);
  //   },
  //   [calendarHandler.data, followUpContacts, contactsHandler.data]
  // );

  try {
    return !userActivities?.data ? (
      <A.ViewSection level="1">
        <Txt.Indicator marginV>{tr("Loading...")}</Txt.Indicator>
      </A.ViewSection>
    ) : (
      <A.ViewSection level="1">
        <ListHeader
          titleTx={tr("My Day")}
          // titleTx={tr(
          //   `My Day ${
          //     todaysProgress > -1 ? Math.round(todaysProgress * 100) : 0
          //   }%`
          // )}
          actions={[
            {
              label: tr("Report my day"),
              icon: { name: "pencil" },
              appearance: "filled",
              status: "control",
              onPress: () => {
                Navigation.overlayPortal.open({
                  headerTitle: "Report Today",
                  render: <StatsCreatorCenter />,
                });
              },
            },
          ]}
        />
        <View style={{ padding: spacing(3) }}>
          {!memberHandler.data ? null /** SECTION  */ : !currentTrainingPageHandler.data ||
            (memberHandler.data?.completedLevelItems?.length < 1 &&
              memberHandler.data?.completedLevelPages?.length < 1) ? (
            <A.PressableStartTraining
              onPress={() =>
                Navigation.navigate<dPageContentsScreenParam>("Page Contents", {
                  cpid: configVariables?.startHerePage,
                })
              }
            >
              <Skeleton
                isLoading
                duration={2400}
                // easing={Easing.circle}
                layout={[
                  {
                    key: "medal-wrapper",
                    width: scale(940),
                    height: scale(160),
                    // justifyContent: "center",
                  },
                ]}
                highlightColor={C.primary}
                boneColor={C.infoBlue}
              />
              <A.ViewLabel>
                <Txt.H4>{tr("Start Training")}</Txt.H4>
              </A.ViewLabel>
            </A.PressableStartTraining>
          ) : levelProgress >= 1 && incompleteLevelPages.length < 1 ? (
            <A.ViewSection level={"3"}>
              <Kitten.ListItemv2
                title={() => (
                  <Txt.H4>{tr("All training completed! 🥳")}</Txt.H4>
                )}
                style={{ backgroundColor: "transparent" }}
                accessoryRight={() => (
                  <Buttoon
                    size="tiny"
                    status="basic"
                    icon={{ name: "refresh", right: true }}
                    onPress={() => { }}
                  >
                    {tr("Review training")}
                  </Buttoon>
                )}
                onPress={() => { }}
              />
              <Kitten.ViewH
                style={{ justifyContent: "flex-end", padding: spacing(3) }}
              >
                <Buttoon
                  size="tiny"
                  status="basic"
                  appearance="ghost"
                  icon={{ name: "chevron_right", right: true }}
                  onPress={() =>
                    Navigation.overlayPortal.open({
                      headerTitle: tr("All training resources"),
                      render: (
                        <LevelsDashboardCenter
                          memberId={frbsUser?.uid}
                          type="user"
                        />
                      ),
                    })
                  }
                >
                  {tr("All training resources")}
                </Buttoon>
              </Kitten.ViewH>
            </A.ViewSection>
          ) : (
            <A.ImBResumeTraining
              imageStyle={{ opacity: 0.85 }}
              source={{
                uri: currentTrainingPageHandler.data?.mediaItem?.logo || `https://get-my.app/t/${configVariables?.appTitle
                  ?.toLowerCase()
                  .replace(/ /g, "")}/logo.png`,
              }}
              blurRadius={16}
              style={{
                backgroundColor: "orange"
              }}
            >
              <ListHeader
                title={() => <Txt.S2 style={{ color: themeDark.text }}>{tr("Resume training")}</Txt.S2>}
                style={{ paddingBottom: 0, backgroundColor: "transparent", shadowColor: "black", shadowRadius: 1.5, shadowOpacity: 0.4, shadowOffset: { width: 0, height: 1 } }}
              />
              <Kitten.ListItemv2
                title={() => (
                  <Txt.H5 style={{ color: themeDark.text, maxWidth: "99%", textShadowColor: "black", textShadowRadius: 1.5, shadowOpacity: 0.4 }}>{currentTrainingPageHandler.data?.name}</Txt.H5>
                )}
                accessoryLeft={() => (
                  <Avt
                    name="A D"
                    source={{
                      uri:
                        currentTrainingPageHandler.data?.mediaItem?.logo || `https://get-my.app/t/${configVariables?.appTitle
                          ?.toLowerCase()
                          .replace(/ /g, "")}/logo.png`,
                    }}
                  />
                )}
                style={{ backgroundColor: "transparent" }}
                accessoryRight={() => (
                  <AnimatedCircularProgress
                    size={60}
                    width={5}
                    fill={levelProgress * 100}
                    rotation={0}
                    tintColor={C.primary}
                    backgroundColor={C.primaryDarker}
                  >
                    {() => (
                      <Txt.H6 style={{ color: themeDark.text, textShadowColor: "black", textShadowRadius: 0.25, shadowOpacity: 0.6 }}>{`${Math.round(levelProgress * 100)}%`}</Txt.H6>
                    )}
                  </AnimatedCircularProgress>
                )}
                onPress={() =>
                  Navigation.navigate<dPageContentsScreenParam>(
                    "Page Contents",
                    {
                      cpid: currentTrainingPageHandler.data?._id,
                    }
                  )
                }
              />
              <Kitten.ViewH
                style={{ justifyContent: "flex-end", padding: spacing(3) }}
              >
                <Buttoon
                  size="tiny"
                  status="basic"
                  icon={{ name: "play", right: true }}
                  // style={{shadowColor: "black", shadowRadius: 1.5, shadowOpacity: 0.4}}
                  onPress={() => {
                    Navigation.navigate<dPageContentsScreenParam>(
                      "Page Contents",
                      {
                        cpid: parentLevelPage,
                        thenGoTo: currentTrainingPageHandler.data?._id,
                      }
                    );
                  }}
                >
                  {tr("Resume Now")}
                </Buttoon>
                <Buttoon
                  forceTheme={THEME.DARK}
                  size="tiny"
                  status="basic"
                  appearance="ghost"
                  icon={{ name: "chevron_right", right: true }}
                  style={{ shadowColor: "black", shadowRadius: 1.5, shadowOpacity: 0.4, shadowOffset: { width: 0, height: 1 } }}
                  onPress={() =>
                    Navigation.overlayPortal.open({
                      headerTitle: tr("All training resources"),
                      render: (
                        <LevelsDashboardCenter
                          memberId={frbsUser?.uid}
                          type="user"
                        />
                      ),
                    })
                  }
                >
                  {memberHandler.data?.parentLevelPages?.length > 1
                    ? tr(
                      `View ${memberHandler.data?.parentLevelPages?.length - 1
                      } other trainings`
                    )
                    : tr("All training resources")}
                </Buttoon>
              </Kitten.ViewH>
            </A.ImBResumeTraining>
          )}
          <A.ViewSection level={"3"} style={{}}>
            <FollowUpSection followUpContacts={followUpContacts} />
          </A.ViewSection>
          <A.ViewSection level={"3"} style={{}}>
            <CalendarSection userData={memberHandler.data} />
          </A.ViewSection>
          <A.ViewSection level={"3"} style={{}}>
            <TaskSection onIncompleteTasks={onIncompleteTasks} />
          </A.ViewSection>
          <A.ViewSection
            level={"3"}
            style={{ backgroundColor: "transparent", minHeight: scale(135) }}
          >
            <ListHeader
              titleTx="Daily Progress Report"
              accessoryRight={() => (
                <Buttoon
                  appearance="outline"
                  status="basic"
                  size="tiny"
                  icon={{ name: "arrow_right", right: true }}
                  onPress={() => Navigation.navigate("stats")}
                >
                  {tr("View stats")}
                </Buttoon>
              )}
            />
            <WidgetUserStats />
          </A.ViewSection>
        </View>
      </A.ViewSection>
    );
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}

const A = {
  ViewSection: sstyled(Kitten.Layout)(({ hovered }) => ({
    // flex: 1,
    // flexGrow: 1,
    // backgroundColor: "surface",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ImBResumeTraining: sstyled(ImageBackground)(({ hovered }) => ({
    // flex: 1,
    // flexGrow: 1,
    // backgroundColor: "surface",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  PressableStartTraining: sstyled(Pressable)(({ hovered }) => ({
    // flex: 1,
    // flexGrow: 1,
    // backgroundColor: "surface",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ViewLabel: sstyled(View)((p) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  })),
  ImageBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingVertical: spacing(2),
  }),
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "errorRed",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};
