import { MediaPageItemSchema, URL_PREFIX } from "engines";
// import { isLevelPage } from "engines/functions/level-functions/level-page-utils";
import { dGoogleDriveData } from "engines/functions/media-functions";
import * as R from "ramda";
// import { MediaPageSchema } from "../schemas";

/**
 * ### Activating Keywords
 * - Words in titles that activate certain features
 */
export const buildMyListKeywords = [
  "Build My List",
  "Memory Jogger",
  "buildmylist",
  "memoryjogger",
];
// export const levelKeywords = ["level", "class", "lesson"];

/**
 * ### The service being used
 * - if not a service, NONE (example: text, url)
 */
export enum ServiceType {
  CLOUDINARY = "CLOUDINARY",
  GOOGLE_DRIVE = "GOOGLE_DRIVE",
  DROPBOX = "DROPBOX",
  VIMEO = "VIMEO",
  YOUTUBE = "YOUTUBE",
  NONE = "NONE",
}
/**
 * ### The type of media to display
 *
 * NOTE: When adding new types here, be sure to update level-page-utils isCompletableItem functions if needed!
 */
export enum ItemType {
  TITLE = "TITLE",
  BLAST_TEXT = "BLAST",
  PARAGRAPH = "PARAGRAPH",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  PHOTO = "PHOTO",
  /**
   * Although not necessary a "media" type, this can be useful to know for the future
   */
  DOCUMENT = "DOCUMENT",
  /**
   * A list of audios (in Dropbox)
   */
  AUDIO_FOLDER = "AUDIO_FOLDER",
  BUTTON = "BUTTON",
  CHECKBOX = "CHECKBOX",
  LEVEL_BUTTON = "LEVEL_BUTTON",
  BUILD_MY_LIST = "BUILD_MY_LIST",
  PICK_TRAINER = "PICK_TRAINER",
  ERROR = "ERROR",
  SEARCH = "SEARCH",
}

export interface TypeResponse {
  serviceType: ServiceType;
  itemType: ItemType;
  data?: any | dGoogleDriveData;
}

export function stringValue(value: any): string {
  return value && typeof value === "string" && value?.length > 2 ? value : null;
}

export const UrlValueForBlastText = "   ";

/**
 * ### Insert cloudinary url and get ItemType from it
 */
export function getMediaTypeFromString(media: string): ItemType {
  if (
    media?.includes(".jpg") ||
    media?.includes(".png") ||
    media?.includes(".jpeg") ||
    media?.includes(".gif")
  ) {
    return ItemType.PHOTO;
  }
  if (
    media?.includes(".m4a") ||
    // media.includes(".mp4") ||
    media?.includes(".wav") ||
    media?.includes(".wma") ||
    media?.includes(".wmv") ||
    media?.includes(".m4v") ||
    media?.includes(".mp3")
  ) {
    return ItemType.AUDIO;
  }
  if (
    media?.includes(".pdf") ||
    media?.includes(".pptx") ||
    media?.includes(".ppt")
  )
    return ItemType.DOCUMENT;
  return ItemType.VIDEO;
}

/**
 * ### Tells you the type of media item you are dealing with so you can know what to render
 *
 * @example
 * typeOfItem(someMediaItem)
 *
 * @example
 * // Provide the media page that the "url" field goes to (helps to determine if it's a level page or not)
 * typeOfItem(aButtonItem, urlPage)
 */
export function typeOfItem(
  item: MediaPageItemSchema
  // urlPage?: MediaPageSchema
): TypeResponse {
  if (!item) return null;
  const { url, title, createdAt, position, media, paragraph, checkbox, pickTrainer } = item;
  let urlPageId: string;
  if (pickTrainer)
    return { itemType: ItemType.PICK_TRAINER, serviceType: ServiceType.NONE };
  if (checkbox === true)
    return { itemType: ItemType.CHECKBOX, serviceType: ServiceType.NONE };
  if (typeof url === "string") {
    if (url?.startsWith(URL_PREFIX.PAGE))
      urlPageId = url?.replace(URL_PREFIX.PAGE, "");
    else if (url?.startsWith(URL_PREFIX.LEVEL))
      urlPageId = url?.replace(URL_PREFIX.LEVEL, "");
    else if (url === "buildmylist") urlPageId = url?.replace("buildmylist", "");
  }
  try {
    if (
      R.isNil(item)
      // ||R.isNil(url) ||
      // R.isNil(title) ||
      // R.isNil(createdAt) ||
      // R.isNil(topage) ||
      // R.isNil(position) ||
      // R.isNil(paragraph)
    )
      throw Error("Error getting this content");
    //#region [ANCHOR] Cloudinary
    if (media?.includes("cloudinary.com")) {
      const itemType = getMediaTypeFromString(media);
      return {
        serviceType: ServiceType.CLOUDINARY,
        itemType,
      };
    }
    //#endregion

    //#region [ANCHOR2] YOUTUBE
    else if (media?.includes("youtu.be") || media?.includes("youtube.com")) {
      return {
        serviceType: ServiceType.YOUTUBE,
        itemType: ItemType.VIDEO,
      };
    }
    //#endregion
    //#region [ANCHOR] VIMEO
    else if (media?.includes("vimeo.com")) {
      return {
        serviceType: ServiceType.VIMEO,
        itemType: ItemType.VIDEO,
      };
    }
    //#endregion
    //#region [ANCHOR2] DROPBOX
    else if (media?.includes("dropbox.com")) {
      let mediaType = ItemType.AUDIO;
      let dbstr = media?.substring(0, media?.indexOf("?"));
      dbstr = dbstr?.substring(dbstr?.length - 5, dbstr?.length);
      if (!dbstr.includes(".")) mediaType = ItemType.AUDIO_FOLDER;
      return {
        serviceType: ServiceType.DROPBOX,
        itemType: mediaType,
      };
    }
    //#endregion
    // // ANCHOR: Google Drive
    // else if (media?.includes("drive.google.com")) {
    //   const videoMetadata = await fn.media.getVideoMetadata(item.media);
    //   if (!videoMetadata.json.error) {
    //     return {
    //       serviceType: ServiceType.GOOGLE_DRIVE,
    //       itemType: videoMetadata.fileType.includes("video")
    //         ? ItemType.VIDEO
    //         : videoMetadata.fileType.includes("audio")
    //         ? ItemType.AUDIO
    //         : ItemType.ERROR,
    //       data: videoMetadata,
    //     };
    //   } else
    //     return {
    //       serviceType: ServiceType.GOOGLE_DRIVE,
    //       itemType: ItemType.ERROR,
    //       data: videoMetadata,
    //     };
    // }
    //#region [ANCHOR] BUILD MY LIST
    else if (urlPageId && urlPageId === "buildmylist") {
      return {
        serviceType: ServiceType.NONE,
        itemType: ItemType.BUILD_MY_LIST,
      };
    }
    //#endregion
    else if (!R.isEmpty(url)) {
      //|| urlPage?.name) {
      let mediaType = ItemType.BUTTON;
      //#region [ANCHOR2] TEXT TEMPLATE
      if (item?.url === UrlValueForBlastText && item?.title)
        //! From observation, seems like Text Template item has url == "   " & title exists (which is the text itself)
        mediaType = ItemType.BLAST_TEXT;
      //#endregion
      //#region [ANCHOR] LEVEL BUTTON
      if (urlPageId && url?.startsWith("level:"))
        mediaType = ItemType.LEVEL_BUTTON;
      // if (urlPageId && isLevelPage(urlPage?.name)) {
      //   console.log(
      //     "[section] level page = " + urlPage?.name + " for " + item.title
      //   );
      //   mediaType = ItemType.LEVEL_BUTTON;
      // }
      //#endregion
      return {
        serviceType: ServiceType.NONE,
        itemType: mediaType,
      };
    }
    //#region [ANCHOR2] TITLE ITEM
    else if (title !== "") {
      return {
        serviceType: ServiceType.NONE,
        itemType: title.includes("🌀") ? ItemType.BLAST_TEXT : ItemType.TITLE,
      };
    }
    //#endregion
    //#region [ANCHOR] PARAGRAPH ITEM
    else if (paragraph !== "") {
      return {
        serviceType: ServiceType.NONE,
        itemType: paragraph.includes("🌀")
          ? ItemType.BLAST_TEXT
          : ItemType.PARAGRAPH,
      };
    }
    //#endregion
    else return { serviceType: ServiceType.NONE, itemType: ItemType.ERROR };
  } catch (error) {
    if (
      !R.isEmpty(url) ||
      // urlPage?.name ||
      media == "0" || //! there is a weird situation where media field is == 0 | "0",
      media == 0 || //! but the item is actually a button/level button. So we have to make a hack here
      paragraph == "0" || //! same thing with `paragragh` (check out Millertime's Paperwork & Needed Forms, doc "kt5L3Ek8LHgDKy2Xv5bP")
      paragraph == 0 //todo clean this up
    ) {
      let mediaType = ItemType.BUTTON;

      if (urlPageId && url?.startsWith("level:"))
        mediaType = ItemType.LEVEL_BUTTON;
      return {
        serviceType: ServiceType.NONE,
        itemType: mediaType,
      };
    }
    return {
      serviceType: ServiceType.NONE,
      itemType: ItemType.ERROR,
    };
  }
}

/**
 * ### Determines the type of item
 * - also provides the service (ex: Cloudinary, Dropbox) and any data fetched from that service like the thumbnail
 * @example
 * const itemTypeData = await TypeOfItem(mediaItem)
 * ---
 * @deprecated
 * @auhor jm_francis
 * @version 21.3.26
 */
// export async function TypeOfItem(
//   item: MediaPageItemSchema
// ): Promise<TypeResponse> {
//   try {
//     if (R.isNil(item)) throw Error("No item found");
//     const media = stringValue(item?.media);
//     const url = stringValue(item?.url);
//     const urlPageId = item?.url?.split("page:")?.pop();
//     const title = stringValue(item?.title);
//     const paragraph = stringValue(item?.paragraph);

//     // ANCHOR: Cloudinary
//     if (media?.includes("cloudinary.com")) {
//       if (
//         media.includes(".jpg") ||
//         media.includes(".png") ||
//         media.includes(".jpeg") ||
//         media.includes(".gif")
//       )
//         return {
//           serviceType: ServiceType.CLOUDINARY,
//           itemType: ItemType.PHOTO,
//         };
//       // TODO: assumes video, but should also support audio
//       return {
//         serviceType: ServiceType.CLOUDINARY,
//         itemType: ItemType.VIDEO,
//         //   data: { thumbnail: FileNetwork.getThumbnailFromURL(media) },
//       };
//     }
//     // ANCHOR: Title
//     else if (item.title || !R.isEmpty(item.title)) {
//       return {
//         serviceType: ServiceType.NONE,
//         itemType: ItemType.TITLE,
//       };
//     } else
//       return {
//         serviceType: ServiceType.NONE,
//         itemType: ItemType.ERROR,
//       };
//   } catch (error) {
//     return {
//       serviceType: ServiceType.NONE,
//       itemType: ItemType.ERROR,
//     };
//   }

//   if (R.isNil(item))
//     return {
//       serviceType: ServiceType.NONE,
//       itemType: ItemType.ERROR,
//       data: null,
//     };

//   // const media = stringValue(item.media);
//   // const url = stringValue(item.url);
//   // const topage = stringValue(item.topage);
//   // const title = stringValue(item.title);
//   // const paragraph = stringValue(item.paragraph);

//   // // ANCHOR: Cloudinary
//   // if (media?.includes("cloudinary.com")) {
//   //   if (
//   //     media.includes(".jpg") ||
//   //     media.includes(".png") ||
//   //     media.includes(".jpeg") ||
//   //     media.includes(".gif")
//   //   )
//   //     return {
//   //       serviceType: ServiceType.CLOUDINARY,
//   //       itemType: ItemType.PHOTO,
//   //     };
//   //   // TODO: assumes video, but should also support audio
//   //   return {
//   //     serviceType: ServiceType.CLOUDINARY,
//   //     itemType: ItemType.VIDEO,
//   //     //   data: { thumbnail: FileNetwork.getThumbnailFromURL(media) },
//   //   };
//   // }
//   // // ANCHOR: YouTube
//   // else if (media?.includes("youtu.be") || media?.includes("youtube.com")) {
//   //   return {
//   //     serviceType: ServiceType.YOUTUBE,
//   //     itemType: ItemType.VIDEO,
//   //   };
//   // }
//   // // ANCHOR: Vimeo
//   // else if (media?.includes("vimeo.com")) {
//   //   return {
//   //     serviceType: ServiceType.VIMEO,
//   //     itemType: ItemType.VIDEO,
//   //   };
//   // }
//   // // ANCHOR: Dropbox
//   // else if (media?.includes("dropbox.com")) {
//   //   let mediaType = ItemType.AUDIO;
//   //   let dbstr = media.substring(0, media.indexOf("?"));
//   //   dbstr = dbstr.substring(dbstr.length - 5, dbstr.length);
//   //   if (!dbstr.includes(".")) mediaType = ItemType.AUDIO_FOLDER;
//   //   return {
//   //     serviceType: ServiceType.DROPBOX,
//   //     itemType: mediaType,
//   //   };
//   // }
//   // // ANCHOR: Google Drive
//   // else if (media?.includes("drive.google.com")) {
//   //   const videoMetadata = await fn.media.getVideoMetadata(item.media);
//   //   if (!videoMetadata.json.error) {
//   //     return {
//   //       serviceType: ServiceType.GOOGLE_DRIVE,
//   //       itemType: videoMetadata.fileType.includes("video")
//   //         ? ItemType.VIDEO
//   //         : videoMetadata.fileType.includes("audio")
//   //         ? ItemType.AUDIO
//   //         : ItemType.ERROR,
//   //       data: videoMetadata,
//   //     };
//   //   } else
//   //     return {
//   //       serviceType: ServiceType.GOOGLE_DRIVE,
//   //       itemType: ItemType.ERROR,
//   //       data: videoMetadata,
//   //     };
//   // }
//   // // ANCHOR: Build My List
//   // else if (topage && buildMyListKeywords.includes(topage)) {
//   //   return {
//   //     serviceType: ServiceType.NONE,
//   //     itemType: ItemType.BUILD_MY_LIST,
//   //   };
//   // }
//   // // ANCHOR: Button
//   // else if (url || topage) {
//   //   let mediaType = ItemType.BUTTON;
//   //   if (topage && isLevelPage(topage)) mediaType = ItemType.LEVEL_BUTTON;
//   //   return {
//   //     serviceType: ServiceType.NONE,
//   //     itemType: mediaType,
//   //   };
//   // }
//   // // ANCHOR: Title
//   // else if (title) {
//   //   return {
//   //     serviceType: ServiceType.NONE,
//   //     itemType: ItemType.TITLE,
//   //   };
//   // }
//   // // ANCHOR: Paragraph
//   // else if (paragraph) {
//   //   return {
//   //     serviceType: ServiceType.NONE,
//   //     itemType: ItemType.PARAGRAPH,
//   //   };
//   // }
//   // // ANCHOR: No data provided - item is completely empty/bank (UI should render nothing)
//   // return {
//   //   serviceType: ServiceType.NONE,
//   //   itemType: ItemType.ERROR,
//   //   data: null,
//   // };
// }

// /**
//  * ### Whether or not this is a level page
//  * - (a level page has a completion percentage)
//  * @example
//  * const isLevel = isLevelPage(someMediaPage)
//  *
//  * @version 21.4.17
//  * @author jm_francis
//  */
// export function isLevelPage(name: string) {
//   for (let k in levelKeywords) {
//     const kw = levelKeywords[k].toLowerCase();
//     if (name?.toLowerCase().includes(kw)) return true;
//   }
// }
