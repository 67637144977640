import { Buttoon, Kitten, sstyled } from "components";
import { useAppContext } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import React from "react";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { useFollowUpContacts } from "../../../engines/firebase/handler/contact-handler";
import { AddContactCreator } from "../contact-manager/AddContactCreatorDialog";
import { ListFollowUpContacts } from "../contact-manager/ListFollowUpContacts";

interface dFollowUpSection {
  followUpContacts: ContactSchemaa[];
}

/**
 * ### Section of Follow-up
 *  - COntacts
 *  ----
 *  @version 23.10.04
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function FollowUpSection(props: dFollowUpSection) {
  // const { followUpContacts } = props;
  const { frbsUser } = useAppContext();
  const followUpContactsHandler = useFollowUpContacts(frbsUser?.uid);
  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  const followUpContacts = followUpContactsHandler.data?.filter(
    (c) => c.followUpDate?.toDate && c.followUpDate?.toDate() <= endOfToday
  );
  const Navigation = useNavigator();
  const onCreateContactPress = () => {
    return Navigation.overlayPortal.open({
      headerTitle: "Add contact",
      render: <AddContactCreator id={"id"} />,
    });
  };
  const onOpenContactsScreen = () => {
    Navigation.navigate("Contact Manager");
  };
  return (
    <A.ViewContainer level="3">
      {followUpContacts ? (
        <ListFollowUpContacts followUpContacts={followUpContacts} />
      ) : null}
      <Kitten.ViewH style={{ justifyContent: "flex-end", padding: spacing(3) }}>
        <Buttoon
          size="tiny"
          appearance="ghost"
          status="info"
          onPress={onCreateContactPress}
          icon={{ name: "plus" }}
        >
          {tr("Add Contact")}
        </Buttoon>
        <Buttoon
          size="tiny"
          appearance="filled"
          status="info"
          onPress={onOpenContactsScreen}
          icon={{ name: "chevron_right", right: true }}
        >
          {tr("All Contacts")}
        </Buttoon>
      </Kitten.ViewH>
    </A.ViewContainer>
  );
  // return (
  //   <A.ViewWidget>
  //     <ListHeader
  //       title={tr("Contact Manager")}
  //       accessoryLeft={(props: dAccessory) => MenuIcon("contacts", props)}
  //       disabled
  //     />
  //     <ListFollowUpContacts />
  //   </A.ViewWidget>
  // );
}

const A = {
  ViewContainer: sstyled(Kitten.Layout)(({ hovered }) => ({
    // height: scale(120),
    flex: 1,
    flexGrow: 1,
    // borderWidth: 1,
    // backgroundColor: "surface",
    borderRadius: scale(9),
  })),
};
