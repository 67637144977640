import { Buttoon, Kitten, ListHeader, Toasty } from "components";
import {
  FPATH,
  MediaPageItemSchema,
  useAppContext,
  usePageContentsCollection,
  __ResourceItemData
} from "engines";
import firebase from "firebase";
import React from "react";
import { TextInput, View } from "react-native";
import { scale, spacing, tr } from "utils";

export function CheckboxItemEditor({
  cpid,
  item,
  onUpdateCallback,
}: {
  cpid: string;
  item?: MediaPageItemSchema;
  onUpdateCallback(): void;
}) {
  const { C, teamId } = useAppContext();

  //   const parentPageHandler = usePageDocument(cpid);

  //#region [FRBS] page contents handler
  const _MODE = item ? "update" : "create";
  //   let pagesHandler = usePagesCollection();
  const contentsHandler = usePageContentsCollection(cpid);
  //#endregion

  const refInput = React.useRef<TextInput>();
  React.useEffect(function staging() {
    refInput.current?.focus();
  }, []);
  const [title, setTitle] = React.useState<string>(item?.title);

  //#region [functions]
  async function onSubmit(xong) {
    const __toast = Toasty.show(tr("Updating checkbox"), { type: "loading" });

    let fieldsToBeAdded = {
      ...__ResourceItemData(),
      title,
      position: contentsHandler._largestPositionIndex() + 1,
      checkbox: true,
    };

    switch (_MODE) {
      case "create":
        return contentsHandler
          .add({
            ...fieldsToBeAdded,
          })
          .then(() => {
            setTimeout(() => {
              Toasty.show("Checkbox added to bottom!");
              onUpdateCallback && onUpdateCallback();
              xong && xong();
            }, 690);
          });
        break;
      case "update":
        /** title is being updated */
        try {
          await firebase
            .firestore()
            .doc(`${FPATH.PAGES}/${cpid}/${FPATH.CUSTOM_PAGE_CONTENT}/${item?._id}`)
            .update({
              title,
            })
          Toasty.update(__toast, tr("Checkbox updated."), {
            type: "success",
          });
          xong && xong();
          onUpdateCallback && onUpdateCallback();
        } catch (err) {
          Toasty.show(err, { type: "danger" })
          xong && xong();
        }
        break;
      default:
        alert("Not sure if should update or create.")
        break;
    }
  }
  //#endregion

  return (
    <View>
      <ListHeader titleTx={"Checkbox caption"} />
      <TextInput
        ref={refInput}
        value={title}
        multiline
        onChangeText={setTitle}
        placeholder="Type caption..."
        style={{
          flex: 1,
          color: C.text,
          fontSize: 18,
          borderBottomWidth: 1,
          borderColor: C.primary,
          minHeight: scale(65),
          padding: spacing(3),
          backgroundColor: C.surface,
        }}
      />
      <Kitten.Divider_________ />
      <Buttoon
        compact
        progress
        disabled={!title || title?.length < 1 || title === item?.title}
        onPress={onSubmit}
      >
        {_MODE === "create" ? tr("Add") : tr("Update")}
      </Buttoon>
    </View>
  );
}
