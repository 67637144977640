import {
  Buttoon,
  DatteLatte,
  Kitten,
  Poppy,
  Toasty,
  Txt
} from "components/atoms";
import {
  dNotification,
  MediaPageSchema,
  NotificationStatus,
  useAppContext,
  useConfigDocument,
  useNotificationCollection,
  useNotificationDocument
} from "engines";
import { TOPICTYPES } from "engines/functions/notifications-functions";
import firebase from "firebase";
import React from "react";
import { useNavigator } from "screens/_navigation";
import { spacing, tr } from "utils";
import RecurringPN, { RECTYPE } from "./recurring-pn";

/**
 * ### List of members of the app (aka teamId's members)
 *  - Including some quick actions as an swipeable item
 *  ----
 *  @version 21.09.07
 *  -  *Memoize it*
 *  @author  K, Nl
 *
 **/
export function BroadcastEditor(props: P) {
  const { C, teamId, frbsUser } = useAppContext();

  const { bsid, handlerPage, item } = props;

  console.log("bsid ", bsid);

  const Navigation = useNavigator();
  // const userProfile = useUserProfile();
  const configVariablesHandler = useConfigDocument(`variables-${teamId}`);
  const { add, sendNotificationToEveryone, sendNotificationToTeam } =
    useNotificationCollection(`${teamId}`);
  const configdata = configVariablesHandler?.data;
  const [disableAddToFirestore, setDisableAddToFirestore] =
    React.useState<boolean>();

  const handleBroadcast = useNotificationDocument(item?.id, `${teamId}`);

  //#region [ANM]
  // useFocusEffect(function layouting() {
  //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  // });
  //#endregion

  //#region [section] search ops
  // const [query, setQuery] = React.useState<string>();
  // const refTitle = React.useRef<Input>();

  const [_title, setTitle] = React.useState<string>(item?.title);
  const [_message, setMessage] = React.useState<string>(item?.message);
  const [toggleDate, setToggleDate] = React.useState<boolean>(item && true);
  const [recurring, setRecurring] = React.useState<boolean>(
    item?.recurrence && true
  );
  const [option, setOption] = React.useState<RECTYPE>(item?.recurrence);
  const selectedDate = React.useRef<Date>();

  const toggleShowDate = async () => {
    setToggleDate(!toggleDate);
  };

  const toggleShowRecurring = async () => {
    setRecurring(!recurring);
  };

  const [scheduledAt, setScheduleAt] = React.useState<Date>(
    (item.createdAt as firebase.firestore.Timestamp)?.toDate()
  );
  const [scheduleAtStr, setScheduleAtStr] = React.useState<string>();
  //#endregion

  //#region [section2] contact section

  // const [details, setDetails] = React.useState<ContactGroupSchema>();
  //#endregion

  //#region [functions] SendNotifications
  async function updateNotification(xong) {
    console.log("scheduleAt ", scheduledAt);
    if (scheduledAt) {
      handleBroadcast
        .update({
          ...handleBroadcast.data,
          title: _title || handleBroadcast.data?.title,
          _parentAppId: configdata._parentAppId,
          _teamId: teamId,
          channel: configdata._parentAppId
            ? `${configdata._parentAppId}-global`
            : `${teamId}-global`,
          message: _message || handleBroadcast.data?.message,
          recurrence: recurring ? option : RECTYPE.BLANK,
          // status: NotificationStatus.SCHEDULED,
          status: handleBroadcast.data?.status,
          // comments: [],
          //@ts-ignore
          createdAt: scheduledAt,
          //@ts-ignore converts to timestamp automatically
          timestamp: scheduledAt,
          updatedAt: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          Toasty.show("Scheduled notification updated!", {
            type: "success",
          });

          xong && xong();
        });
      Navigation.goBack();
    } else {
      // if (!configdata.enablePushNotifications) {
      //   setTimeout(() => {
      //     Toasty.show("Push notifications are disabled 😢", {
      //       type: "warning",
      //     });
      //   }, 4500);

      //   return null;
      // }

      if (!disableAddToFirestore) {
        console.log("title ", _message);

        // const d = new Date();

        handleBroadcast
          .update({
            channel: configdata._parentAppId
              ? `${configdata._parentAppId}-global`
              : `${teamId}-global`,
            _parentAppId: configdata._parentAppId,
            title: _title,
            message: _message,
            //@ts-ignore FieldValue conversion
            createdAt: scheduledAt,
            status: NotificationStatus.SENT,
            updatedAt: firebase.firestore.Timestamp.now(),
          })
          .then(() => {
            Toasty.show("Message sent to everyone!", {
              type: "success",
            });
          });
      } else {
        null;
      }

      // await sendNotificationToEveryone(_title, _message, {
      //   teamId: teamId,
      // }).then(() => {
      //   xong && xong();
      //   Navigation.goBack();
      // });
    }
  }
  //#endregion

  //#region [functions] SendNotificationBS
  async function updateNotificationBs(xong) {
    const topic = `${teamId}-${TOPICTYPES.BASESHOP}-${handlerPage?._id}`;
    // console.log("baseshop function went throught");
    // console.log("scheduleAt ", NotificationStatus.SCHEDULED);
    if (scheduledAt) {
      handleBroadcast
        .update({
          title: _title,
          _teamId: teamId,
          _parentAppId: configdata._parentAppId,
          message: _message,
          channel: `${topic}`,
          teamName: handlerPage?.name,
          icon: handlerPage?.mediaItem.logo ? handlerPage?.mediaItem.logo : "",
          //@ts-ignore auto convert
          createdAt: new Date(scheduledAt),
          //@ts-ignore auto convert
          timestamp: new Date(scheduledAt),
          recurrence: recurring ? option : RECTYPE.BLANK,
          status: NotificationStatus.SCHEDULED,
          comments: [],
          baseShopId: bsid,
          updatedAt: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          Toasty.show(
            "Your notification has been scheduled. \n Go do something else and we'll take care of it from here!",
            {
              type: "success",
            }
          );

          xong && xong();
        });
      Navigation.goBack();
    } else {
      if (!configdata.enablePushNotifications) {
        setTimeout(() => {
          Toasty.show("Push notifications are disabled 😢", {
            type: "warning",
          });
        }, 4500);

        return null;
      }

      if (!disableAddToFirestore) {
        console.log("title ", _message);

        const d = new Date();

        handleBroadcast
          .update({
            title: _title,
            _teamId: teamId,
            _cloudFunctionEndpoint: configdata._parentAppId,
            message: _message,
            channel: `${topic}`,
            teamName: handlerPage?.name,
            icon: handlerPage?.mediaItem.logo
              ? handlerPage?.mediaItem.logo
              : "",
            recurrence: recurring ? option : RECTYPE.BLANK,
            comments: [],
            baseShopId: bsid,
            status: NotificationStatus.SENT,
            updatedAt: firebase.firestore.Timestamp.now(),
          })
          .then(() => {
            Toasty.show("Message sent to team members!", {
              type: "success",
            });
          });
      } else {
        null;
      }

      await sendNotificationToTeam(
        teamId,
        TOPICTYPES.BASESHOP,
        handlerPage,
        _title,
        _message,
        configVariablesHandler.data?._parentAppId
      ).then(() => {
        xong && xong();
        Navigation.goBack();
      });
    }
  }

  async function deleteNotificationBs(xong) {
    Poppy(
      "Final Check",
      "Are you sure you want to delete this broadcast?",
      {
        text: "Cancel",
        onPress: () => {
          xong && xong();
        },
      },
      {
        text: "Yes",
        onPress: () => {
          handleBroadcast.deleteDocument().finally(() => {
            Navigation.goBack();
            xong && xong();
          });
        },
      }
    );
  }
  //#endregion
  try {
    return (
      <>
        <Kitten.Input
          status="primary"
          placeholder="Title your message (optional)"
          value={_title}
          onChangeText={setTitle}
        />
        <Kitten.Divider_________ />
        <Kitten.Input
          textStyle={{ minHeight: 60 }}
          status="primary"
          multiline
          placeholder="What would you like to say?"
          value={_message}
          onChangeText={setMessage}
        />
        <Kitten.Divider_________ />
        <Kitten.CheckBox
          checked={toggleDate}
          onChange={toggleShowDate}
          style={{ margin: spacing(3) }}
        >
          {
            tr("Schedule") //* `Would you like to schedule this mail?`
          }
        </Kitten.CheckBox>

        {toggleDate ? (
          <>
            <DatteLatte
              defaultDate={(item.timestamp || item.createdAt)?.toDate()}
              onSelectCallback={(str, date) => {
                setScheduleAtStr(str);
                setScheduleAt(date);
              }}
            />
            <Kitten.Divider_________ />
            <Txt.Indicator marginV>
              {tr("Scheduled for ") +
                (scheduledAt
                  ? scheduleAtStr
                    ? scheduleAtStr
                    : scheduledAt
                  : (item.timestamp || item.createdAt)?.toDate())}
            </Txt.Indicator>
            <Kitten.Divider_________ />
            <Kitten.CheckBox
              checked={recurring}
              onChange={toggleShowRecurring}
              style={{ margin: spacing(3) }}
            >
              {
                tr("Enable Recurring") //* `Would you like to schedule this mail?`
              }
            </Kitten.CheckBox>
          </>
        ) : null}

        {recurring ? (
          <>
            <Kitten.Divider_________ />
            <RecurringPN
              scheduledDate={selectedDate.current}
              option={option}
              setOption={(selectedItem) => {
                setOption(selectedItem);
              }}
            />
            <Kitten.Divider_________ />
          </>
        ) : null}
        {!toggleDate ? (
          <>
            <Buttoon
              icon={{ name: "bullhorn" }}
              status="basic"
              progress
              onPress={(xong) =>
                handlerPage && bsid
                  ? updateNotificationBs(xong)
                  : updateNotification(xong)
              }
            >
              {tr(
                `${handlerPage && bsid
                  ? `Send Notification to ${handlerPage?.name}`
                  : `Send Notification`
                } `
              )}
            </Buttoon>
          </>
        ) : (
          <>
            <Buttoon
              status="basic"
              progress
              onPress={(xong) =>
                handlerPage && bsid
                  ? updateNotificationBs(xong)
                  : updateNotification(xong)
              }
            >
              {tr(
                `${handlerPage && bsid
                  ? `Update Scheduled Notification for ${handlerPage?.name}`
                  : `Update Scheduled broadcast`
                }`
              )}
            </Buttoon>
          </>
        )}
        <Kitten.Divider_________ />
        <Buttoon
          status={"danger"}
          progress
          onPress={(xong) =>
            handlerPage && bsid
              ? deleteNotificationBs(xong)
              : deleteNotificationBs(xong)
          }
        >
          {tr(`Delete Scheduled broadcast`)}
        </Buttoon>
      </>
    );
  } catch (error) {
    throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
  }
}

interface P {
  bsid?: string;
  handlerPage?: MediaPageSchema;
  item?: dNotification;
}
