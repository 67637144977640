import { Buttoon } from "components/atoms";
import { MemberInfo, PersonaliEditCenter } from "components/organisms";
import { TwoColumns, useTwoColumns } from "components/templates";
import { useAppContext, useUserProfile } from "engines";
import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { tr } from "utils";
import { ass } from "utils/storage";
import { fuego } from "../../../fuego";

export function UserScreen(props) {
  const { data: userD, ...userProfile } = useUserProfile();
  const { column1ContainerStyle } = useTwoColumns();
  const { C } = useAppContext();
  try {
    if (!userD) return <ActivityIndicator />;
    if (userProfile.error) return <View />;
    return (
      <TwoColumns
        containerStyle={{ backgroundColor: C.background }}
        column1Content={(scrollHandler) => (
          // <UserCenter scrollHandler={scrollHandler} />
          <ScrollView contentContainerStyle={column1ContainerStyle}>
            <PersonaliEditCenter scrollHandler={scrollHandler} />
          </ScrollView>
        )}
        column2Content={[
          {
            icon: "user",
            headerTitle: tr("About you"),
            showTitle: false,
            render: () => <MemberInfo memberId={userD?._id} type="user" />,
          },
          {
            icon: "exit",
            headerTitle: tr("Sign out"),
            showTitle: false,
            render: () => <SignoutSection />,
          },
        ]}
        bottomInset
      />
    );
  } catch (error) {
    throw Error(`::VXNlclNjcmVlbg==::${error}`);
  }
}

const SignoutSection = () => {
  const { frbsUser, setTeamId } = useAppContext();
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();

  function onSignout(xong?) {
    // Backend.authHandler.signOut(() => setUserProfile(null));
    // Navigation.dispatch(StackActions.replace("Home"));

    fuego
      .auth()
      .signOut()
      .then((r) => {
        xong && xong();
      });
    Navigation.navigate("Home");
    ass.clear();
    setTeamId("");
  }
  React.useEffect(function triggering() {
    !breakpointForTwoColumns && (onSignout(), Navigation.goBack());
  }, []);

  return breakpointForTwoColumns ? (
    <>
      <Buttoon
        progress
        onPress={onSignout}
        disabled={!frbsUser}
        appearance="outline"
        status="danger"
      >
        {tr("Sign out")}
      </Buttoon>
    </>
  ) : (
    <View />
  );
};
