import { useJiraFunctions } from "engines";
import { useConfigCollection } from "engines/firebase/handler/config-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { checkExistError } from "engines/functions/js-functions";
import React from "react";
import { IS_WEB } from "utils";

/**
 * ###
 *  - This component is used in other to pass hooks to the ErrorBoundary and in order to remove hooks error
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.11.03
 *  -  *Brief changelog*
 *  @author  NL
 *
 **/

function withUserProfile(Component) {
  return function WrappedComponent(props) {
    const userProfile = useUserProfile();
    const handleJira = useJiraFunctions();

    /**
     * This checks if the error has been created previously
     * @param error
     * @param hierachyName
     */
    function getJiraError(error: any, hierachyName: string) {
      // const proxyUrl = "https://cors-anywhere.herokuapp.com/";
      const fetchUrl = `https://apptakeoff.atlassian.net/rest/agile/1.0/board/8/backlog?`;
      const jql = `jql=project = "ATP" AND issuetype = BUG AND status != Done ORDER BY created DESC`;
      const fields = `&fields=description,issuetype,name,customfield_10064,customfield_10063`;
      fetch(
        //   "GET",
        `${fetchUrl}${jql}${fields}`,
        {
          method: "GET",
          headers: {
            Authorization: `Basic bWFua2FyLnNhdXJhYmhAZ21haWwuY29tOjdyU3ZtSWt1RDdEYkFJM002QmVTRDkzNA==`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("jira ", data);
          const errorCode = error?.message.split("::")[1];

          // console.log("dataInfo ", data?.issues);
          const datainfo = data?.issues;
          const checkExist = checkExistError(errorCode, datainfo);

          if (checkExist) {
            console.log("checkExist ", "Error wouldn't be created");
          } else {
            console.log("checkExist ", "Error would be created");
            let errorBody = `Team name ${hierachyName} error.message`;
            const payload = {
              errorCode: errorCode,
              summary: error.message,
              description: errorBody,
            };
            createIssue(payload);
          }
        })
        .catch((err) => {
          console.log("an error occured");
          console.log(err);
        });
    }

    /**
     * this creates any error that hasn't been created previously
     * @param payload
     */
    function createIssue(payload) {
      let jiraResponse = handleJira.add(payload);
      console.log("jiraResponse ", jiraResponse);
    }

    // React.useEffect(function () {
    //   getJiraError();
    // }, []);

    //#region [section]
    const configHandler = useConfigCollection();
    let appChecker;
    if (IS_WEB && configHandler._checkLocalHostorLive(window.location.host)) {
      appChecker = "localhost";
    } else {
      appChecker = "production";
    }

    //#endregion

    // console.log("appConfig nanle", appChecker);
    return (
      <Component
        {...props}
        userProfile={userProfile}
        appChecker={appChecker}
        getJiraError={getJiraError}
        jiraCreator={createIssue}
      />
    );
  };
}

export default withUserProfile;
