import { useDocument } from "@nandorojo/swr-firestore";
import { FPATH } from "engines";
import {
  ContactGroupSchemaa,
  ContactSchemaa
} from "engines/backends/schemas/bml";
import firebase from "firebase";
import { useCollectionPresetv2 } from "../config";
/**
 * ###
 *  - This component is used in creating contacts category
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.01
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

/**
 * Used in updating, deleting, getting contacts group details from contacts groups
 * @param userId
 * @param groupId
 * @param query
 * @returns
 */
export function useContactGroupDocument(
  userId: string,
  groupId: string,
  query?
) {
  const defaultDocumentFunctions = useDocument<ContactGroupSchemaa>(
    `${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}/${groupId}`,
    {
      listen: true,
      ...query,
    }
  );

  return { ...defaultDocumentFunctions };
}

export function completeFollowUpOnContact(userId: string, contactId: string) {
  return (
    firebase
      .firestore()
      .doc(`${FPATH.USERS}/${userId}/${FPATH.CONTACTS}/${contactId}`)
      // @ts-ignore using FieldValues
      .update({
        followUpDate: firebase.firestore.FieldValue.delete(),
        followUpHistory: firebase.firestore.FieldValue.arrayUnion({
          date: new Date(),
          notes: "",
        }),
      } as Partial<ContactSchemaa>)
  );
}

/**
 * Used in getting contacts groups collections and adding a new contact
 * @param userId
 * @param query
 * @returns
 */
export function useContactGroupsCollection(userId: string, query?) {
  const defaultCollectionFunctions = useCollectionPresetv2<ContactGroupSchemaa>(
    `${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}`,
    "_id",
    query
  );

  return { ...defaultCollectionFunctions };
}
