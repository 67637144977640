import { API_CONFIGS, stripBrackets } from "engines";
import { GHLAccount, GHL_Token } from "../../schemas/configs/configs.schema";
import { ApiVersion } from "./apiSettings";
import { GHLCustomField } from "./customFields";

export interface GHLFormSubmission {
  id: string;
  contactId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  postalCode: string;
  createdAt: string; // (UTC)
  formId: string;
  calendarName: string;
  selectedSlot: string;
  selectedTimezone: string;
  pageDetails: any;
}

interface SubmissionsResponse {
  submissions: GHLFormSubmission[];
  /**
   * Likely an error message
   */
  message: string;
  statusCode: number;
  /**
   * Page info
   */
  meta?: {
    nextPage: number | null;
    /**
     * total number of pages
     */
    total: number;
    currentPage: number;
    prevPage: number;
  };
}

/**
 * Converts date object to string format of yyyy-mm-dd
 */
function dateToISOString(date: Date): string {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  return newDate.toISOString().split("T")[0];
}

/**
 * Fetch all the submissions in all the forms under the provided ghl account
 *
 * @author jm_francis
 *
 * @example
 * const submissions = getFormSubmissions(ghlAccount, token, ghlAccount.forms[APIS.POWER_BILL_SUBMISSIONS], thirtyDaysAgo)
 *
 * @example
 * const submissions = getFormSubmissions(ghlAccount, token)
 */
export async function getFormSubmissions(
  ghlAccount: GHLAccount,
  token: GHL_Token,
  /**
   * Leave null/undefined for ALL form types
   */
  formId?: string,
  /**
   * Leave null/undefined for infinite/all form submissions
   */
  startDate?: Date
): Promise<GHLFormSubmission[] | null> {
  const { locationId } = ghlAccount;

  let submissions: GHLFormSubmission[] | null = null;
  let pageNumber = 1;
  async function fetchWithPageNumber(
    page: number
  ): Promise<SubmissionsResponse> {
    const startDateStr = startDate
      ? `&startAt=${dateToISOString(startDate)}`
      : "";
    const formIdStr = formId ? `&formId=${formId}` : "";
    return fetch(
      `https://services.leadconnectorhq.com/forms/submissions/?locationId=${locationId}&limit=100&page=${page}${startDateStr}${formIdStr}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          Version: ApiVersion,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.submissions) {
          submissions = !submissions
            ? json.submissions
            : submissions.concat(json.submissions);
        } else {
          alert(JSON.stringify(json));
        }
        return json;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  try {
    while ((await fetchWithPageNumber(pageNumber++)).meta?.nextPage) {
      console.log(
        `[getFormSubmissions] Reading form submissions page ${pageNumber - 1}`
      );
    }
    return submissions;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

/**
 * If any required fields for this API type, make sure they have data before counting the point
 *
 * @author jm_francis
 *
 * @example
 * doesSubmissionMeetRequirements(submission, APIS.GO_HIGH_LEVEL_POWER_BILLS, await getCustomFields())
 */
export function doesSubmissionMeetRequirements(
  submission: GHLFormSubmission,
  formType: string,
  customFields: GHLCustomField[]
): boolean {
  const requiredFields = API_CONFIGS[formType]?.requiredFields || [];
  for (let r in requiredFields) {
    const _fieldId =
      customFields.find((f) => f.fieldKey === stripBrackets(requiredFields[r]))
        ?.id || requiredFields[r];
    if (!_fieldId) {
      console.error(
        "Failed to get field id for required field key: " + requiredFields[r]
      );
      continue;
    }
    const _fieldValue = submission[_fieldId];
    if (!_fieldValue || !(Object.keys(_fieldValue).length > 0)) {
      console.log(
        `submission with name ${submission.firstName} ${submission.lastName} does not count because ${requiredFields[r]}/${_fieldId} is empty: ${_fieldValue}`
      );
      return false;
    }
  }
  return true;
}

/**
 * If there is already a submission with the same field/value pair, it is a duplicate
 *
 * @author jm_francis
 *
 * @example
 * doesSubmissionHaveDuplicates(submission, countedSubmissions, await getCustomFields(), APIS.GO_HIGH_LEVEL_POWER_BILLS)
 */
export function doesSubmissionHaveDuplicates(
  submission: GHLFormSubmission,
  /**
   * All the submissions that have been counted for as a score
   */
  countedSubmissions: GHLFormSubmission[],
  customFields: GHLCustomField[],
  formType: string
): boolean {
  const checkFields = API_CONFIGS[formType]?.checkFieldsForDuplicates || [];

  for (let b in countedSubmissions) {
    const submissionB = countedSubmissions[b];
    for (let c in checkFields) {
      const _fieldId =
        customFields.find((f) => f.fieldKey === stripBrackets(checkFields[c]))
          ?.id || checkFields[c];
      /**
       * If there is already a submission with the same field/value pair, it is a duplicate
       */
      if (
        submission[_fieldId] &&
        submissionB[_fieldId] === submission[_fieldId]
      ) {
        console.log(
          `Found duplicate submission for ${submission.firstName} ${submission.lastName} with field ${checkFields[c]}/${_fieldId} and value ${submission[_fieldId]}`
        );
        return true;
      }
    }
  }
  return false;
}
