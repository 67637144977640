import { Buttoon, Toasty } from "components";
import { useAppContext } from "engines";
import { useMemberDocument } from "engines/firebase/handler/members-handler";
import {
  CLOUDINARY_FOLDER,
  uploadMedia
} from "engines/functions/media-functions";
import { LinkURL } from "engines/functions/web-functions";
import * as ImagePicker from "expo-image-picker";
import { MediaTypeOptions } from "expo-image-picker";
import React from "react";
import { Alert, Image, Platform, View } from "react-native";
import { useNavigator } from "screens";
import { IS_WEB, scale, tr } from "utils";

export interface dAvatarPicker {
  memberId?: string;
}

export function AvatarPicker({ memberId }: dAvatarPicker) {
  const { C, teamId, setFrbsUser, frbsUser } = useAppContext();
  // const refRenderCount = React.useRef(0);
  const { data: memberD, ...memberHandler } = useMemberDocument(
    memberId || frbsUser?.uid
  );
  const [_avatar, setAvatar] = React.useState<string>("");

  const Navigation = useNavigator();

  React.useEffect(
    function updatePersonali() {
      if (memberD) {
        setAvatar(memberD?.personali?.photoURL);
        // setHighestStreak(
        //   memberD?.activityStreaks
        //     ? Math.max(...memberD.activityStreaks?.map((_s) => _s.streak))
        //     : 0
        // );
      }
    },
    [memberD]
  );

  function androidPermissionPrompt(): Promise<void> {
    return new Promise((resolve, reject) => {
      Alert.alert(
        "Photos Permission",
        "Access to your photo library is required to use this feature. Selected photos will be stored only on our secure servers.",
        [
          {
            text: "View Privacy Policy",
            onPress: async () => {
              await LinkURL("https://apptakeoff.com/privacy-policy");
            },
          },
          { text: "Decline", onPress: () => reject() },
          { text: "Agree", onPress: () => resolve() },
        ]
      );
    });
  }

  async function pickAvatar() {
    const { status } = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (Platform.OS === "android") {
      if (status === ImagePicker.PermissionStatus.UNDETERMINED)
        await androidPermissionPrompt();
    }
    if (status === ImagePicker.PermissionStatus.DENIED) {
      Alert.alert(
        "Access denied.",
        "You need to enable access to your photo library for this app first! Do this in your device Settings."
      );
      return;
    }
    ImagePicker.launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    }).then(async (result) => {
      if (!result.cancelled) {
        uploadMedia(
          result.uri,
          {
            folder: `${CLOUDINARY_FOLDER.CLIENTS}/${memberD?._teamId}/${CLOUDINARY_FOLDER.PROFILE_PHOTOS}`,
            title: `profile_${memberD?._id}`,
            type: "image",
          },
          (progress) => {},
          async (data) => {
            try {
              setAvatar(data.url);
              // Toasty.show("Profile photo updated!");
            } catch (err) {
              Toasty.show(err, { type: "danger" });
            }
          }
        );
      }
    });
  }

  return React.useMemo(() => {
    return (
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        {_avatar ? (
          <View>
            <Image
              // name={memberD?.personali?.displayName || ""}
              // source={{ uri: memberD?.personali?.photoURL || "" }}
              source={{ uri: _avatar || memberD?.personali?.photoURL }}
              style={{ width: scale(110), height: scale(110) }}
            />
            <Buttoon appearance="ghost" status="basic" onPress={pickAvatar}>
              {tr("Change")}
            </Buttoon>
            <Buttoon
              onPress={async () => {
                await memberHandler.update({
                  personali: {
                    ...memberD?.personali,
                    photoURL: _avatar,
                  },
                });
                window &&
                  window.location &&
                  window.location.reload &&
                  window?.location?.reload();
                if (!IS_WEB || !window.location) Navigation.navigate("Home");
              }}
            >
              {tr("Save")}
            </Buttoon>
          </View>
        ) : (
          <Buttoon
            appearance="outline"
            icon={{ name: "plus" }}
            style={{ width: scale(120), height: scale(120) }}
            onPress={pickAvatar}
          ></Buttoon>
        )}
      </View>
    );
  }, [memberD, memberD?.personali?.photoURL, C, _avatar]);
}

const A = {};
