//@ts-check
// import khoi_1 from "./khoi-1.jpg";
import t321go from "./321go.gif";
import appLogo from "./app-logo.png";
import newbieMedal from "./medal.gif";
import mouseScroll from "./mouse-scroll.gif";
import shimmer from "./shimmer-placeholder.gif";
// @ts-ignore
export const img = {
  shimmer,
  mouseScroll,
  newbieMedal,
  t321go,
  appLogo,
};
