import { useDocument } from "@nandorojo/swr-firestore";
import { StackActions } from "@react-navigation/native";
import {
  Avt,
  Buttoon,
  dButtoon,
  Kitten,
  Skeleton,
  sstyled,
  Toasty,
  Txt
} from "components/atoms";
import { Image, Pressable, View } from "dripsy";
import {
  fn,
  FPATH,
  MediaPageItemSchema,
  MediaPageSchema,
  useAppContext
} from "engines";
import { LinkURL } from "engines/functions/web-functions";
import { useCollabContext } from "engines/providers/collaboration-provider";
import * as React from "react";
import { scale } from "react-native-size-matters";
// import { QuickStyle } from "react-native-shortcuts";
import { useNavigator } from "screens";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { IS_WEB, moderateScale, tr } from "utils";

interface dButtonItem {
  item: MediaPageItemSchema;
  complete?: boolean;
  /** In case you need this :D */
  parentPageId?: string;
  onPress?: () => void;
}

// export const isLinkMagnetURL = (url: string): boolean => {
//   return (
//     url &&
//     (url.includes("followthislink.com") ||
//       url.includes("freedomforfamilies.com"))
//   );
// };

// export const isFinURL = (url: string): boolean => {
//   return url && url.includes("discoverfin.io");
// };

/**
 * ### Custom Page's button item
 *  - Custom style based on `stylecode` value in "paragraph"
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.07.06
 *  -  *Separate the component*
 *  @author  K, Nl
 *
 **/
export function ButtonItem(props: dButtonItem) {
  const { item, complete, parentPageId, onPress } = props;
  // const { C } = useAppContext();
  const Navigation = useNavigator();
  const { isCollabModeOn } = useCollabContext();

  const [linkType, setLinkType] = React.useState<
    "page" | "url" | "link_magnet" | "fin" | "bml"
  >(null);

  // const linkMagnet = fn.js.isLinkMagnetURL(stringValue(item?.url));
  // const fin = fn.js.isFinURL(stringValue(item?.url));

  // const [inbrowser, setInBrowser] = React.useState<any>();
  /**
   * In order to not disturb the other states and components by an immediate change in the parent component,
   * causing flickering, we secretly update the completion state in here for the time being.
   */
  const [overrideComplete, setOverrideComplete] =
    React.useState<boolean>(false);
  // const _complete = complete || overrideComplete;

  //#region [section] handle link as a page link
  const [cpid, setCpid] = React.useState<string>(null);
  const handlePage = useDocument<MediaPageSchema>(`${FPATH.PAGES}/${cpid}`);
  //#endregion

  React.useEffect(
    /**
     * get link type
     */
    function getLinkType() {
      // not including level: here because level-button-item.tsx handles this
      if (item?.url?.startsWith("page:")) {
        setLinkType("page");
        setCpid(item?.url?.split("page:")?.pop());
      }
      // if (item?.url?.startsWith("http")) {
      else if (item?.url?.length > 0) {
        if (fn.js.isLinkMagnetURL(item?.url)) {
          setLinkType("link_magnet");
        } else if (fn.js.isFinURL(item?.url)) {
          setLinkType("fin");
        } else if (fn.js.isBMLButton(item?.url)) {
          setLinkType("bml");
        } else {
          setLinkType("url");
        }
      }
    },
    [item?.url, handlePage.data]
  );

  //#region [functions]
  const onLinkPress = async () => {
    onPress && onPress();
    setOverrideComplete(true);
    if (linkType == "url" || linkType == "link_magnet" || linkType == "fin") {
      return LinkURL(item?.url, true)
      // return fn.web.LinkURL(item?.url, true);
    } else if (isCollabModeOn) {
      Navigation.navigate<dPageContentsScreenParam>("Collab Contents", {
        cpid: cpid,
        // collabMode: isCollabModeOn,
        // title: page?.name || tr("Untitled"),
      });
    } else if (linkType == "page") {
      Navigation.dispatch(
        StackActions.push("Page Contents", {
          cpid: cpid,
          collabMode: isCollabModeOn,
          buttonItem: item,
          previousCpid: parentPageId,
        })
      );
    } else if (linkType === "bml") {
      // Navigation.dispatch(StackActions.push("bml-mobile"))
      !IS_WEB
        ? Navigation.dispatch(StackActions.push("bml-mobile"))
        : Toasty.show("This feature is only available on the mobile app.", {
          type: "warning",
        });
    }
  };
  //#endregion

  const [_faviconDidFail, setFaviconDidFail] = React.useState<boolean>(false);

  let _colorCode = fn.js.styleSpilt(item.paragraph, 0);
  let _appearanceCode = fn.js.styleSpilt(item.paragraph, 1);

  //#region [section] Setup rolesThatCanEdit
  // const userProfile = useUserProfile();
  // const [bsInput, setBsInput] = React.useState<string>("");
  //#endregion
  // console.log("linkType ", linkType);
  switch (linkType) {
    case "url":
      return (
        <Kitten.ViewH
          style={{
            ...(_appearances[_appearanceCode] == "ghost" && {
              alignItems: "flex-start",
            },
            {
              justifyContent: "space-between",
              flex: 1,
            }),
          }}
        >
          <Buttoon
            icon={{
              name: "link",
              render: _faviconDidFail ? null : (
                <Image
                  source={{
                    uri: item.url?.endsWith(".pdf") ? "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1710257842/google-docs_z412oq.svg" : `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${item.url}`,
                  }}
                  onError={(e) => setFaviconDidFail(true)}
                  style={{
                    width: moderateScale(17),
                    height: moderateScale(17),
                  }}
                />
              ),
            }}
            appearance={_appearances[_appearanceCode]}
            status={_statuses[_colorCode]}
            onPress={onLinkPress}
            style={{ flex: 6, flexGrow: 1 }}
          >
            {(p) => (
              <View style={{ flex: 1 }}>
                <Txt.C1 style={{ ...p.style, width: "90%" }} numberOfLines={10}>
                  {item?.title || "Untitled"}
                </Txt.C1>

                {/* <Txt.Helper
                  marginV
                  style={{
                    ...p.style,
                    width: "90%",
                    fontWeight: "normal",
                    textAlign: "left",
                  }}
                >
                  {String(item?.url).length > 45
                    ? item?.url.substring(0, 45) + "..."
                    : item?.url}
                </Txt.Helper> */}
              </View>
            )}
          </Buttoon>
        </Kitten.ViewH>
      );
      break;
    case "link_magnet" && "link_magnet":
      return (
        <Kitten.ViewH
          style={{
            ...(_appearances[_appearanceCode] == "ghost" && {
              alignItems: "flex-start",
            },
            {
              justifyContent: "space-between",
              flex: 1,
            }),
          }}
        >
          <Kitten.Button
            // icon={{ name: "magnet_link" }}
            accessoryLeft={() => (
              <Image
                source={{
                  uri:
                    linkType === "link_magnet"
                      ? "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1619908050/apptakeoff/icons/link_magnet_ctpo63.png"
                      : "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1635007185/apptakeoff/icons/fin-white_pj9qhi.png",
                }}
                style={{ width: moderateScale(30), height: moderateScale(30) }}
              />
            )}
            appearance={_appearances[_appearanceCode]}
            status={_statuses[_colorCode]}
            onPress={onLinkPress}
            style={{ flex: 6, flexGrow: 1 }}
          >
            {(p) => (
              <View style={{ flex: 1 }}>
                <Txt.C1 style={{ ...p.style, width: "90%" }} numberOfLines={10}>
                  {item?.title || "Untitled"}
                </Txt.C1>
              </View>
            )}
          </Kitten.Button>
        </Kitten.ViewH>
      );
      break;
    case "fin" && "fin":
      return (
        <Kitten.ViewH
          style={{
            ...(_appearances[_appearanceCode] == "ghost" && {
              alignItems: "flex-start",
            },
            {
              justifyContent: "space-between",
              flex: 1,
            }),
          }}
        >
          <Kitten.Button
            // icon={{ name: "magnet_link" }}
            accessoryLeft={() => (
              <Image
                source={{
                  uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1635007185/apptakeoff/icons/fin-white_pj9qhi.png",
                }}
                style={{ width: moderateScale(30), height: moderateScale(30) }}
              />
            )}
            appearance={_appearances[_appearanceCode]}
            status={_statuses[_colorCode]}
            onPress={onLinkPress}
            style={{ flex: 6, flexGrow: 1 }}
          >
            {(p) => (
              <View style={{ flex: 1 }}>
                <Txt.C1 style={{ ...p.style, width: "90%" }} numberOfLines={10}>
                  {item?.title || "Untitled"}
                </Txt.C1>
              </View>
            )}
          </Kitten.Button>
        </Kitten.ViewH>
      );
      break;
    case "page":
      return (
        <View
          style={
            _appearances[_appearanceCode] == "ghost" && {
              alignItems: "flex-start",
            }
          }
        >
          <Buttoon
            status={_statuses[_colorCode]}
            appearance={_appearances[_appearanceCode]}
            // icon={{ name: "page" }}
            icon={{
              name: "page",
              render: item.thumbnail ? (
                <Avt name={item.title} source={{ uri: item.thumbnail }} />
              ) : null,
            }}
            // accessoryLeft={() => (
            //   <Avt name={item.title} source={{ uri: item.thumbnail }} />
            // )}
            onPress={onLinkPress}
            style={{ justifyContent: "flex-start" }}
          >
            {item.title || "Untitled"}
          </Buttoon>
          {/* {userProfile._isRolesContain([FRBS_ROLE.DEVELOPER]) && (
            <View style={{ borderWidth: 1, borderColor: C.devPink }}>
              {!_.isEmpty(handlePage.data?.rolesThatCanEdit) ? (
                <Txt>{`✅ Editable by ${JSON.stringify(
                  handlePage.data?.rolesThatCanEdit
                )}`}</Txt>
              ) : (
                <Inpuut
                  value={bsInput}
                  onChangeText={setBsInput}
                  placeholder={"Input parent BS id"}
                  onSavePress={() =>
                    handlePage.update({
                      rolesThatCanEdit:
                        firebase.firestore.FieldValue.arrayUnion(
                          `BS:${bsInput}`
                        ),
                    })
                  }
                  style={{ maxHeight: 69 }}
                />
              )}
            </View>
          )} */}
        </View>
      );
      break;
    case "bml":
      return <PressableBuildMyList onPress={onLinkPress} item={item} />;

      return (
        <View>
          <Buttoon
            status={"danger"}
            appearance={"filled"}
            icon={{ name: "list" }}
            onPress={onLinkPress}
          >
            {item.title || "Untitled"}
          </Buttoon>
        </View>
      );
      break;
    default:
      return null;
      break;
  }
}

export const _appearances: dButtoon["appearance"][] = [
  "ghost",
  "filled",
  "outline",
];

export const _statuses: dButtoon["status"][] = [
  // "basic",
  "primary",
  "success",
  "info",
  "warning",
  "danger",
  // "control",
];

export const PressableBuildMyList = (p) => {
  const { onPress = () => { }, item } = p;
  const { C, teamId } = useAppContext();

  return (
    <A.PressableContainer onPress={onPress}>
      <Skeleton
        isLoading
        duration={10000}
        animationType={"pulse"}
        // easing={Easing.circle}
        layout={[
          {
            key: "bml-wrapper",
            width: "100%",
            height: scale(65),
          },
        ]}
        highlightColor={"#ffa500"}
        boneColor={"#ff0000"}
      />
      <A.ViewLabel
        disabled={true}
        style={{ flex: 1, backgroundColor: "transparent" }}
        title={() => (
          <Kitten.ViewH>
            <Txt.H6>{item?.title}</Txt.H6>
          </Kitten.ViewH>
        )}
        accessoryRight={() => (
          <Buttoon status="control" size="small" onPress={onPress}>
            {tr("Start now")}
          </Buttoon>
        )}
      />
      {/* <A.ViewLabel>
        <Txt.H4>{item?.title}</Txt.H4>
      </A.ViewLabel> */}
    </A.PressableContainer>
  );
};

const A = {
  PressableContainer: sstyled(Pressable)({
    borderBottomLeftRadius: scale(4),
    borderBottomRightRadius: scale(4),
    overflow: "hidden",
    backgroundColor: "transparent",
  }),
  ViewLabel: sstyled(Kitten.ListItemv2)((p) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    // justifyContent: "center",
    // alignItems: "center",
  })),
};
