import { IconPrimr } from "assets";
import { Highlighter, Kitten, Txt } from "components";
import { useAppContext } from "engines";
import React from "react";
import { View } from "react-native";
import { IS_WEB, scale } from "utils";

interface dSearchContentProps {
  data: string[];
  searchPart: string;
}

/**
 * ### Brief description
 *  - This component highlights the text that is being searched for.
 *  ----
 *  @example
 *  <SearchContent data={["hello","bye"] searchPart={"e"}}/>
 *  ----
 *  @version 21.11.30
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export function SearchContent(props: dSearchContentProps) {
  const { data, searchPart } = props;
  const dataLength = data?.length ? data.length : 0;
  const { C } = useAppContext();

  return (
    <View style={{ flexDirection: "column" }}>
      {/* <Txt.S1 numberOfLines={1}>
        {data.map((d, index) =>
          dataLength > 1 && index + 1 < dataLength ? `${d}, ` : d
        )}
      </Txt.S1> */}
      {data.map((_d, index) => {
        let d = String(_d);

        return (
          !d.includes("pageTitle^") && (
            <Kitten.ListItemv2
              disabled
              accessoryLeft={() => (
                <IconPrimr
                  name={d.split("^")[0].trim()}
                  color={C.grey600}
                  size={scale(14)}
                />
              )}
              title={() => (
                <Txt.S1
                  key={_d + index}
                  numberOfLines={2}
                  style={{ color: C.grey600 }}
                >
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: C["color-warning-transparent-500"],
                    }}
                    searchWords={[searchPart]}
                    autoEscape={true}
                    textToHighlight={
                      dataLength > 1 && index + 1 < dataLength
                        ? `${d.includes("^") ? d.split("^")[1].trim() : d}, `
                        : d.includes("^")
                        ? d.split("^")[1].trim()
                        : d
                    }
                    // textToHighlight={d}
                  />
                </Txt.S1>
              )}
              style={{ borderBottomColor: C.dim, borderBottomWidth: 1 }}
            />
          )
        );
      })}
    </View>
  );
}
