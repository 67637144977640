import { ListItemProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Kitten, ShortcutEditor } from "components";
import { Buttoon, sstyled, Txt } from "components/atoms";
import { ShortcutSchema, useAppContext } from "engines";
import React from "react";
import { ImageBackground, Pressable, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { useFollowUpContacts } from "../../../engines/firebase/handler/contact-handler";
import { dContactsScrParams } from "../../../screens/_navigation/home.navigator";
import { AddContactCreator } from "../../organisms/contact-manager/AddContactCreatorDialog";
import { ListFollowUpContacts } from "../../organisms/contact-manager/ListFollowUpContacts";

export function WidgetContacts(
  props: ListItemProps & { page: ShortcutSchema; uid?: string }
) {
  const { page, uid, onPress } = props;
  const { C, frbsUser, teamId, theme } = useAppContext();

  const Navigation = useNavigator();
  // const contactsHandler = useContactsCollection(frbsUser?.uid);
  const followUpContactsHandler = useFollowUpContacts(frbsUser?.uid);

  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  const needsFollowUpContacts = followUpContactsHandler.data?.filter(
    (c) => c?.followUpDate?.toDate && c?.followUpDate?.toDate() <= endOfToday
  );
  const allFollowUpContacts = followUpContactsHandler.data;

  async function _onPress() {
    Navigation.navigate<dContactsScrParams>("Contact Group Screen", {
      id: "follow-up",
      // sharedItems: sharedITems,
      followUpContacts: allFollowUpContacts,
    });
    return;
    return Navigation.dialogPortal.open({
      headerTitle: "Follow-Up Contacts",
      render: <ListFollowUpContacts />,
    });
  }
  const onCreateContactPress = () => {
    return Navigation.dialogPortal.open({
      headerTitle: "Add contact",
      render: <AddContactCreator id={"id"} />,
    });
  };
  return (
    <A.ViewWidget {...props}>
      {({ hovered }) => (
        <>
          <Kitten.ViewH
            style={{
              justifyContent: "space-between",
              paddingHorizontal: spacing(3),
              backgroundColor: C.text,
            }}
          >
            <Txt.C2 style={{ color: C.primaryDarker }}>
              {tr("Follow-up")}
            </Txt.C2>
            <Kitten.ViewH>
              <IconPrimr name="contacts" size={20} color={C.primaryDarker} />
              <Txt.H5 style={{ color: C.primaryDarker }}>
                {needsFollowUpContacts?.length}
              </Txt.H5>
            </Kitten.ViewH>
          </Kitten.ViewH>

          <A.ImageBackground
            source={{
              uri: "",
            }}
            // blurRadius={5}
          >
            {needsFollowUpContacts?.length == 0 ? (
              <Txt.S2 style={{ textAlign: "center" }}>
                {"No one else to follow up with today ✅"}
              </Txt.S2>
            ) : (
              <View>
                <Txt.Indicator marginV style={{ textAlign: "center" }}>
                  {needsFollowUpContacts?.length || "..."} contacts{"\n"}need
                  follow-up
                </Txt.Indicator>
                <Buttoon
                  size={"tiny"}
                  onPress={_onPress}
                  style={{ alignSelf: "center" }}
                  icon={{ name: "link" }}
                >
                  {tr("Check them out")}
                </Buttoon>
              </View>
            )}
            <Buttoon
              size={"tiny"}
              appearance={"ghost"}
              onPress={onCreateContactPress}
              style={{ alignSelf: "center" }}
              icon={{ name: "plus", right: true }}
            >
              {tr("Add new contact")}
            </Buttoon>
            <Txt.C2
              style={{
                color: C.primary,
                alignSelf: "flex-start",
                paddingRight: spacing(2),
              }}
              onPress={() => {
                Navigation.dialogPortal.open({
                  headerTitle: tr("Edit shortcut"),
                  render: (
                    <ShortcutEditor editingDocId={page?._id} type={"link"} />
                  ),
                });
              }}
            >
              {tr("Edit")}
            </Txt.C2>
          </A.ImageBackground>
        </>
      )}
    </A.ViewWidget>
  );
}

const A = {
  ViewShortcut: sstyled(Kitten.ListItemv2)(({ hovered }) => ({
    height: scale(68),
    borderWidth: 1,
    borderColor: hovered ? "primary" : "transparent",
  })),
  ViewWidget: sstyled(Pressable)(({ hovered }) => ({
    flex: 1,
    flexGrow: 1,
    borderWidth: 1,
    backgroundColor: "surface",
    borderColor: hovered ? "primary" : "background",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ImageBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingVertical: spacing(2),
  }),
};
