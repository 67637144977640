import { Poppy } from "components";
import sendErrorEmail from "engines/backends/apis/sendErrorMail";
import React, { ErrorInfo, ReactNode } from "react";
import { Dimensions, Platform } from "react-native";
import { useNavigator } from "screens";
import { PageNotFound } from "screens/error/PageNotFound";
import { version } from "utils";
import { ass, ASS_KEY } from "utils/storage";
import withUserProfile from "./withUserProfile";

interface Props {
  children: ReactNode;
  userProfile;
  appChecker;
  getJiraError?: (error: any, hierachyName: string) => void;
  jiraCreator?: () => void;
}

interface State {
  error: Error;
  errorInfo: ErrorInfo;
  userProfile: any;
  appChecker: string;
}

let lastErrorOccurance: Date = null

/**
 * ###
 *  - Error boundaries are React components that catch JavaScript errors anywhere in their
 *   child component tree, log those errors, and display a fallback UI instead of
 *  the component tree that crashed. Error boundaries catch errors during rendering,
 *   in lifecycle methods, and in constructors of the whole tree below them.
 *  ----
 *  @version 21.07.30
 *  -  *Brief changelog*
 *  @author  Nl, nguyenkhooi
 *
 **/ class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      userProfile: null,
      appChecker: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // const Navigation = useNavigator()
    // Catch errors in any components below and re-render with error message
    const minutes = 2 * 60 * 1000 // 2 minutes
    if (lastErrorOccurance && new Date().getTime() - lastErrorOccurance.getTime() < minutes) {
      // do nothing
    } else {
      Poppy("Something went wrong. Quit and reopen your app if needed.", `${error.message}`)
    }
    lastErrorOccurance = new Date()
    this.setState({
      error: error,
      errorInfo: errorInfo,
      userProfile: this.props.userProfile,
      appChecker: this.props.appChecker,
    });
  }
  render() {
    // console.log("this.state.appChecker ", this.state?.appChecker);

    if (this.state.appChecker == "localhost") {
      console.log("this is local and error mails are not meant to be sent");
    } else {
      this.state.userProfile?.data
        ? ass.loadString(ASS_KEY.TEAMID).then((res) => {
          const hierachyName = res;
          const windowWidth = Dimensions.get("window").width;
          const windowHeight = Dimensions.get("window").height;
          const errorBody = `
      - Errorcode : ${this.state.error?.message.split("::")[1]}
      - Team : ${hierachyName}
      - Version : ${version}
      - Platform : ${Platform.OS + " " + Platform.Version
              ? Platform.Version
              : "Browser (web)"
            } 
      - Screen Dimention :  Width : ${windowWidth}, Height: ${windowHeight}
      - Error infomation : ${this.state.error} 
      - Date: ${new Date()}
      - Reporter: ${this.state.userProfile?.data?._id} (${this.state.userProfile?.data?.personali?.email
            })
      ---------------------------------- 
      ${this.state.errorInfo.componentStack}
      ----------------------------------`;
          const payload = {
            recipient:
              "drkhoi16@gmail.com,paulnanle611@gmail.com,mankar.saurabh@gmail.com,gsuite@admin.apptakeoff.com",
            email_from: ` ${hierachyName}  <admin@apptakeoff.com>`,
            email_subject: `🚨 Error from ${hierachyName} - Version ${version} `,
            email_text_body: `${errorBody}`,
          };

          // console.log("payload ", payload);
          this.props.getJiraError(this.state.error, hierachyName);

          sendErrorEmail(payload)
            .then((res) => {
              console.log("res ", res);
            })
            .catch((e) => {
              console.log(e);
            });

          return console.error(
            "Uncaught error:",
            this.state.error,
            this.state.errorInfo
          );
        })
        : null;
    }

    //@ts-ignore
    if (this.state.errorInfo && "debug" === "nah") {
      // Error path

      return (
        <PageNotFound
          errorcode={
            this.state.error?.message.split("::")[1] ||
            this.state.error?.message
          }
        />
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default withUserProfile(ErrorBoundary);
