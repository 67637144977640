import { UserSchema } from "engines";

/**
 * ###
 *  - This function is used in getting all emails from the member list
 *  ----
 *  getPersonaliEmails(members array)
 *  returns ["drkhoi16@gmail.com", "paulnanle611@gmail.com", "mankar.saurabh@gmail.com", "nanleluke.e4e@gmail.com"]
 *  ----
 *  @version 21.08.04
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function getPersonaliEmails(newRecipients: UserSchema[]) {
  var arrayData: string[] = new Array();
  for (let index = 0; index < newRecipients.length; index++) {
    const element = newRecipients[index];
    const nanle = newRecipients[index].personali.email;
    arrayData.push(nanle);
  }

  return arrayData;
}
