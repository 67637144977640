import { Imaggie, sstyled, Txt, Videolly } from "components";
import { fn, PosstSchema, useAppContext } from "engines";
import * as React from "react";
import { Dimensions, View } from "react-native";
import Swiper from "react-native-web-swiper";

interface dPosstMediaRoll {
  // posstHandler: dUseDocumentReturns<PosstSchema>;
  posst: PosstSchema
}

export function PosstMediaRoll({ posst }: dPosstMediaRoll) {
  const { C } = useAppContext();
  const refRenderCount = React.useRef(0);
  // const Navigation = useNavigator();
  const refSwiper = React.useRef<Swiper>();
  // const posstHandler = useDocument<PosstSchema>(`${FPATH.POSSTS}/${posstId}`);
  //#region [section2] build media list
  //* @deprecated This section helps update the Roll when data updates in the backend, but it comes with UI compromise, so we don't use it atm
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // React.useEffect(
  //   function imageList() {
  //     console.log("medias changed!");
  //     setIsLoading(true);
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 500);
  //   },
  //   [posstHandler.data?.medias]
  // );
  //#endregion
  //#region [section] build gallary list for Gallery Screen (@deprecated for now)
  // const [galleryList, setGalleryList] = React.useState<
  //   dGalleryScrParams["images"]
  // >([]);
  // React.useEffect(
  //   function imageList() {
  //     setMediaList(posst?.medias);
  //     let _galleryList = posst?.medias
  //       /** Get image-only list from medias */
  //       ?.filter((media) => media.type.includes("image"))
  //       /** convert to correct field name for GalleryScreen */
  //       .reduce((a, c) => [...a, { url: c.uri }], []);
  //     // console.log("Gallery: ", _galleryList);
  //     setGalleryList(_galleryList);
  //   },
  //   [posst?.medias]
  // );

  //#endregion
  // return (
  //   <A.ViewContainer style={{ justifyContent: "center", alignItems: "center" }}>
  //     <Txt.Helper style={{ color: C.devPink }}>
  //       {"❗ PosstMediasRoll is having issue. Khoi is fixing it"}
  //     </Txt.Helper>
  //   </A.ViewContainer>
  // );
  return React.useMemo(() => {
    if (!posst) return null;
    let medias = posst?.medias;

    //#region [cases]
    let CASE = {
      NO_MEDIA: medias?.length < 1,
      SINGLE_MEDIA: medias?.length == 1,
      MORE_MEDIA: medias?.length > 1,
    };
    //#endregion
    if (CASE.NO_MEDIA) return <View />;
    if (CASE.SINGLE_MEDIA)
      return (
        <A.ViewContainer>
          <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>
          <MediaDisplay mediaItem={medias[0]} />
        </A.ViewContainer>
      );
    return (
      <A.ViewContainer>
        <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>
        {/* {medias.map((media) => (
          <Imaggie source={{ uri: media.uri }} />
        ))} */}
        <View style={{ flex: 1 }}>
          {!isLoading ? (
            <Swiper
              ref={refSwiper}
              // {...SpringRoll.Preset.PosstMediasRoll}
              // vertical
              // loop
              // timeout={2}
              controlsEnabled={medias?.length > 1}
              controlsProps={{
                prevPos: false,
                nextPos: false,
                dotsTouchable: true,
                dotsWrapperStyle: {
                  backgroundColor: C.background,
                  borderRadius: 20,
                },
                dotActiveStyle: { backgroundColor: C.primary },
              }}
              springConfig={{ speed: 10, bounciness: 0 }}
            >
              {medias?.map((mediaItem, index) => (
                <MediaDisplay key={index} mediaItem={mediaItem} />
              ))}
            </Swiper>
          ) : null}
        </View>
      </A.ViewContainer>
    );
  }, [posst?.medias]);
}

const MediaDisplay = ({
  mediaItem,
}: {
  mediaItem: PosstSchema["medias"][0];
}) => {
  const mediaType =
    mediaItem?.type || fn.media.extractMediaType(mediaItem?.uri);

  //#region [cases] media type
  const CASE = {
    IMAGE_MEDIA: mediaType?.includes("image"),
    VIDEO_MEDIA: mediaType?.includes("video"),
  };
  //#endregion

  return React.useMemo(() => {
    if (CASE.IMAGE_MEDIA) {
      return <A.PosstImage source={{ uri: fn.media.smallerSizeFromURL(mediaItem?.uri, Math.round(Dimensions.get("window").width * 3)) }} />;
    }
    if (CASE.VIDEO_MEDIA) {
      return (
        <Videolly
          type="component"
          source={{
            uri: mediaItem?.uri,
          }}
        />
      );
    }

    return (
      <View style={{ width: 400, height: 400, backgroundColor: "red" }}>
        <Txt>{mediaType + " " + mediaItem?.uri}</Txt>
      </View>
    );
  }, [mediaItem]);
};

const A = {
  ViewContainer: sstyled(View)({
    width: ["100%", "100%", "100%", 614 * 1.11, 614 * 1.11], //* same with CardPosst
    height: [8, 8, 9, 12, 12],
    backgroundColor: "surface",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: "hidden",
  }),
  PosstImage: sstyled(Imaggie)({
    // flex: 1,
    // flex: IS_WEB ? 1 : 0,
    width: "100%",
    height: [8, 8, 9, 12, 12],
  }),
};
