import { Buttoon, Kitten, Txt } from "components";
import { FPATH } from "engines";
import firebase from "firebase";
import React from "react";
import { View } from "react-native";
import { ContactSchemaa } from "../../../engines/backends/schemas/bml";
import { useContactDocument } from "../../../engines/firebase/handler/contact-handler";
import { useAppContext } from "../../../engines/providers/app-provider";
import { useNavigator } from "../../../screens/_navigation/navigation-utils";
import { spacing } from "../../../utils/styles/spacing";
import Toasty from "../../atoms/generals/toasty/Toasty";
import { DatteLatte } from "../../atoms/others/DatteLatte/DatteLatte";

export function FollowUpDialog(props: {
  contact: ContactSchemaa;
  didUpdateContact: (contact: ContactSchemaa) => void;
}) {
  const Navigation = useNavigator();
  const { frbsUser } = useAppContext();
  const { contact, didUpdateContact } = props;

  const contactHandler = useContactDocument(frbsUser?.uid, contact._cid);

  const [followUpDate, setFollowUpDate] = React.useState<Date | null>(null);

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  return (
    <Kitten.Card>
      <Txt.H6>Schedule Follow Up</Txt.H6>
      <Txt.P2
        style={{ marginBottom: spacing(5) }}
      >{`Choose follow up date for ${contact?.displayName || contact.email
        }`}</Txt.P2>
      <DatteLatte
        defaultDate={
          contact.followUpDate?.toDate && contact.followUpDate?.toDate() >= startOfToday
            ? contact.followUpDate?.toDate()
            : new Date()
        }
        disableTimeOptions
        onSelectCallback={(dateStr, date) => {
          setFollowUpDate(date);
        }}
      />
      <View
        style={{
          marginTop: spacing(5),
          width: "100%",
          // flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Buttoon
          // disabled={contact.followUpDate}
          // appearance="outline"
          style={{ width: "100%" }}
          icon={{ name: "clock" }}
          onPress={async () => {
            try {
              const now = new Date();
              const oneday = 86400 * 1000;
              if (
                (followUpDate?.getTime() || now.getTime()) <
                now.getTime() - oneday
              ) {
                Toasty.show("Follow up date must be set for the future!", {
                  type: "danger",
                });
                return;
              }
              const __toasty = Toasty.show("Scheduling...", {
                type: "loading",
                duration: 4500,
              });
              followUpDate &&
                // @ts-ignore trusting firestore to convert Date to Timestamp
                (await contactHandler.update({
                  followUpDate: followUpDate,
                } as Partial<ContactSchemaa>));
              didUpdateContact({
                ...contact,
                followUpDate: firebase.firestore.Timestamp.fromDate(
                  followUpDate || now
                ),
              });
              Toasty.update(__toasty, "Reminder scheduled!", {
                type: "success",
              });
              Navigation.goBack();
            } catch (err) {
              console.error(err);
              Toasty.show("Something went wrong. " + err, { type: "danger" });
            }
          }}
        >
          {"Schedule Reminder"}
        </Buttoon>
        {contact.followUpDate ? (
          <Buttoon
            style={{ marginTop: spacing(2) }}
            status="primary"
            icon={{ name: "check_circle" }}
            appearance="ghost"
            onPress={async () => {
              firebase
                .firestore()
                .doc(
                  `${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}/${contact?._cid}`
                )
                .update({
                  followUpDate: firebase.firestore.FieldValue.delete(),
                  lastFollowUp: new Date(),
                  followUpHistory: firebase.firestore.FieldValue.arrayUnion({
                    // @ts-ignore firebase will convert to Timestamp for us
                    date: new Date(),
                    // notes: "",
                  }),
                });
              didUpdateContact({
                ...contact,
                followUpDate: undefined,
                followUpHistory: (contact.followUpHistory || []).concat([
                  { date: firebase.firestore.Timestamp.fromDate(new Date()) },
                ]),
              });
              Toasty.show("Follow up complete!", { type: "success" });
              Navigation.goBack();
            }}
          >
            {"Mark as complete"}
          </Buttoon>
        ) : (
          <Buttoon
            status="danger"
            appearance="ghost"
            onPress={() => {
              Navigation.goBack();
            }}
          >
            Cancel
          </Buttoon>
        )}
      </View>
    </Kitten.Card>
  );
}

// function isSameDay(date1: Date, date2: Date): boolean {
//   if (!date1 || !date2) return false;
//   return (
//     date1.getDate() === date2.getDate() &&
//     date1.getMonth() === date2.getMonth() &&
//     date1.getFullYear() === date2.getFullYear()
//   );
// }
