import { useCollection, useCollectionGroup, useDocument } from "@nandorojo/swr-firestore";
import { Toasty } from "components";
import { fn, useCollectionPresetv2 } from "engines";
import { ACTION_TYPE, dUserAction, dUserComment, dUserLike, UserSchema } from "engines/backends";
import firebase from "firebase"
import { FPATH } from "../firebase.props";

interface dOptions {
    location?: string,
    involvedPartyName?: string,
    thumbnail?: string,
}

/**
 * ### Record an action a user did into their history
 * 
 * @author jm_francis
 * 
 * @example
 * await recordUserAction(user, ACTION_TYPE.CONTENT_VIEW, `${user.personali?.displayName} watched ${item.title}.`, `${URL_PREFIX.PAGE}${pageId}/${itemId}`)
 */
export async function recordUserAction(user: UserSchema, actionType: ACTION_TYPE, description: string, options: dOptions = {}) {
    try {
        const { location, involvedPartyName, thumbnail } = options
        if (!user || !user?.personali?.displayName) {
            throw "No user data for recordUserAction function"
        }
        const actionsRef = firebase.firestore().collection(`${FPATH.USERS}/${user?._id}/${FPATH.USER_ACTIONS}`)
        const response = await actionsRef.add({
            _teamId: user?._teamId,
            uid: user?._id,
            _traineeUids: user?.growth?.listBuilder?.shareTo || [],
            actionType,
            description,
            location,
            involvedPartyName,
            thumbnail,
            displayName: user?.personali?.displayName,
            profileImage: user?.personali?.photoURL || "",
            comments: [],
            likes: [],
            occurredAt: firebase.firestore.Timestamp.now(),
        } as dUserAction)

        const docId = response.id
        return actionsRef.doc(response.id).update({ _id: docId } as Partial<dUserAction>)
    } catch (err) {
        console.error(err)
        Toasty.show("Activity: " + err)
        return err;
    }
}

/**
 * ### Record a user action without having their user data but just their uid
 * - gets user document and forwards to recordUserAction function
 * - for the description it will automatically replace the uid with their displayName
 * 
 * @author jm_francis
 */
export async function recordUnknownUserAction(uid: string, actionType: ACTION_TYPE, description: string, options: dOptions = {}) {
    try {
        const user = (await firebase.firestore().doc(`${FPATH.USERS}/${uid}`).get()).data() as UserSchema
        const regexUid = new RegExp(
            uid,
            "g"
        );
        return recordUserAction(user, actionType, description.replace(regexUid, user.personali?.displayName), options)
    } catch (err) {
        console.error(err)
        Toasty.show("UActivity: " + err)
        return err;
    }
}

// LIKES
export async function addActionLike(action: dUserAction, like: dUserLike) {
    //@ts-ignore arrayUnion
    return await firebase.firestore().doc(`${FPATH.USERS}/${action.uid}/${FPATH.USER_ACTIONS}/${action._id}`).update({
        likes: firebase.firestore.FieldValue.arrayUnion(like)
    } as Partial<dUserAction>)
}
export async function updatePreviousLike(myUid: string, action: dUserAction, like: dUserLike) {
    return await firebase.firestore().doc(`${FPATH.USERS}/${action.uid}/${FPATH.USER_ACTIONS}/${action._id}`).update({
        likes: action.likes.map(_l => _l.uid === myUid ? like : _l)
    } as Partial<dUserAction>)
}

// COMMENTS
export async function addActionComment(action: dUserAction, comment: dUserComment) {
    //@ts-ignore arrayUnion
    return await firebase.firestore().doc(`${FPATH.USERS}/${action.uid}/${FPATH.USER_ACTIONS}/${action._id}`).update({
        comments: firebase.firestore.FieldValue.arrayUnion(comment)
    } as Partial<dUserAction>)
}
export async function removeActionComment(action: dUserAction, comment: dUserComment) {
    return await firebase.firestore().doc(`${FPATH.USERS}/${action.uid}/${FPATH.USER_ACTIONS}/${action._id}`).update({
        comments: action.comments?.filter(c => !(c.body === comment.body && c.uid == comment.uid && c.createdAt === comment.createdAt))
    } as Partial<dUserAction>)
}

/**
 * ### Listen on a single action
 */
export function useAction(uid: string, actionId: string) {
    const defaultCollectionFunctions = useDocument<dUserAction>(
        `${FPATH.USERS}/${uid}/${FPATH.USER_ACTIONS}/${actionId}`,
        {
            listen: true
        }
    );

    async function deleteComment(comment: dUserComment) {
        return removeActionComment(defaultCollectionFunctions.data, comment)
        // return defaultCollectionFunctions.update({
        //     comments: firebase.firestore.FieldValue.arrayRemove(comment)
        // })
    }
    async function addComment(comment: dUserComment) {
        return addActionComment(defaultCollectionFunctions.data, comment)
    }

    return { ...defaultCollectionFunctions, addComment, deleteComment }
}

/**
 * Hook to get all a specific user's actions
 * - sorted by most recent occurrence
 */
export function useUserActions(
    uid: string,
    query?,
    limit = 40
) {
    const defaultCollectionFunctions = useCollectionPresetv2<dUserAction>(
        `${FPATH.USERS}/${uid}/${FPATH.USER_ACTIONS}`,
        {
            listen: true,
            limit,
            ...query,
        }
    );

    function combineAndCondense(doNotCombineTypes: ACTION_TYPE[] = []) {
        return combineAndCondenseActions(defaultCollectionFunctions.data)
    }

    return { ...defaultCollectionFunctions, recordUserAction, combineAndCondense };
}

/**
 * Hook to get all actions from your trainees
 * - sorted by most recent occurrence
 */
export function useTraineesActions(
    trainerUid: string,
    limit = 40
) {
    const defaultCollectionFunctions = useCollectionGroup<dUserAction>(
        FPATH.USER_ACTIONS,
        {
            where: [["_traineeUids", "array-contains", trainerUid]],
            orderBy: ["occurredAt", "desc"],
            limit,
            listen: true,
        },
        { onError: (err) => console.error(err), refreshWhenHidden: false, refreshInterval: 10000, refreshWhenOffline: false, revalidateOnFocus: true }
    );

    function combineAndCondense() {
        return combineAndCondenseActions(defaultCollectionFunctions.data)
    }

    return { ...defaultCollectionFunctions, combineAndCondense };
}

/**
 * Hook to get all actions in your team that are relevant
 * - sorted by most recent occurrence
 */
export function useTeamWideActions(
    teamId: string,
    limit = 50
) {
    const TEAM_WIDE_ACTION_TYPES: ACTION_TYPE[] = [
        ACTION_TYPE.ADDED_GUESTS,
        ACTION_TYPE.UPLOADED_PROFILE_IMAGE,
        ACTION_TYPE.DAILY_PROGRESS_REPORT,
        ACTION_TYPE.SCOREBOARD_ENTRY,
        ACTION_TYPE.POSST_COMMENT,
        ACTION_TYPE.POSST_LIKE,
        ACTION_TYPE.NEW_USER
    ]

    const defaultCollectionFunctions = useCollectionGroup<dUserAction>(
        FPATH.USER_ACTIONS,
        {
            where: [["_teamId", "==", teamId], ["actionType", "in", TEAM_WIDE_ACTION_TYPES]],
            orderBy: ["occurredAt", "desc"],
            limit,
            listen: true,
        },
        { onError: (err) => console.error(err), refreshWhenHidden: false, refreshInterval: 13000, refreshWhenOffline: false, revalidateOnFocus: true }
    );

    function combineAndCondense(doNotCombineTypes: ACTION_TYPE[] = []) {
        return combineAndCondenseActions(defaultCollectionFunctions.data, doNotCombineTypes)
    }

    return { ...defaultCollectionFunctions, combineAndCondense };
}

/**
 * Hook to get all actions going on in your team
 * - sorted by most recent occurrence
 */
export function useTeamMembersActions(
    _teamId: string,
    query?
) {
    const defaultCollectionFunctions = useCollectionGroup<dUserAction>(
        FPATH.USER_ACTIONS,
        {
            where: [["_teamId", "==", _teamId]],
            orderBy: ["occurredAt", "desc"],
        },
        { onError: (err) => console.error(err) }
    );

    function combineAndCondense() {
        return combineAndCondenseActions(defaultCollectionFunctions.data)
    }

    return { ...defaultCollectionFunctions, combineAndCondense };
}

/**
 * If an action occurred by the same user on the same day it will make it into 1 action with the "count"/how many times it happened in that day
 * - doNotCombineTypes are types you want to leave as seperate items in your use case
 */
export function combineAndCondenseActions(actions: dUserAction[], doNotCombineTypes: ACTION_TYPE[] = [ACTION_TYPE.POSST_COMMENT, ACTION_TYPE.SCOREBOARD_ENTRY]): dUserAction[] {
    if (!actions) return null;
    const combinedActions: { [key: string]: dUserAction } = {};

    const pageNameFromDescription = (desc: string) => {
        const endIndex = desc.lastIndexOf("\"")
        const startIndex = desc.substring(0, endIndex).lastIndexOf("\"") + 1
        return desc.substring(startIndex, endIndex)
    }

    actions.forEach(action => {
        const samePageIdentity = action.actionType === ACTION_TYPE.CONTENT_VIEW ? pageNameFromDescription(action.description) : ""
        const key = `${action.uid}_${action.actionType}_${fn.calendar.dateFormat(action.occurredAt?.toDate())}_${samePageIdentity}`;

        if (combinedActions[key]) {
            combinedActions[key].count++;
            combinedActions[key].groupedActions.push(action)
        } else {
            combinedActions[key] = { ...action, count: 1, groupedActions: [action] };
        }
    });

    // update grouped description
    for (let c in combinedActions) {
        const combinedAction = combinedActions[c]
        if (combinedAction.count === 1 || doNotCombineTypes.includes(combinedAction.actionType)) continue;
        const sStr = combinedAction.count - 1 > 1 ? "s" : ""
        if (combinedAction.actionType === ACTION_TYPE.SEND_SMS) {
            combinedAction.groupedDescription = `${combinedAction.displayName} sent message to ${combinedAction.involvedPartyName || "a personal contact"} and ${combinedAction.count - 1} other${sStr}.`
        } else if (combinedAction.actionType === ACTION_TYPE.SEND_EMAIL) {
            combinedAction.groupedDescription = `${combinedAction.displayName} sent ${combinedAction.count} emails.`
        } else if (combinedAction.actionType === ACTION_TYPE.CONTENT_SHARE) {
            combinedAction.groupedDescription = `${combinedAction.displayName} shared \"${combinedAction.involvedPartyName}\" and ${combinedAction.count} other resource${sStr}.`
        } else if (combinedAction.actionType === ACTION_TYPE.ADDED_GUESTS) {
            combinedAction.groupedDescription = `${combinedAction.description?.substring(0, combinedAction.description?.length - 1)} and other guests to ${combinedActions.count} seperate meeting${sStr}.`
        } else if (combinedAction.actionType === ACTION_TYPE.ADD_CONTACT) {
            combinedAction.groupedDescription = `${combinedAction.displayName} added ${combinedAction.count} new contacts.`
        } else if (combinedAction.actionType === ACTION_TYPE.ADD_TASK) {
            combinedAction.groupedDescription = `${combinedAction.displayName} created ${combinedAction.count} new tasks.`
        } else if (combinedAction.actionType === ACTION_TYPE.COMPLETE_TASK) {
            combinedAction.groupedDescription = `${combinedAction.displayName} completed ${combinedAction.count} personal tasks.`
        } else if (combinedAction.actionType === ACTION_TYPE.ASSIGNED_TRAINER) {
            combinedAction.groupedDescription = `${combinedAction.displayName} assigned ${combinedAction.count} trainers: ${combinedAction?.groupedActions?.map((_act, idx) => _act?.involvedPartyName?.trim() || `Trainer ${idx}`)?.join(", ")}`
        } else if (combinedAction.actionType === ACTION_TYPE.UNASSIGNED_TRAINER) {
            combinedAction.groupedDescription = `${combinedAction.displayName} removed ${combinedAction.count} trainers: ${combinedAction?.groupedActions?.map((_act, idx) => _act?.involvedPartyName?.trim() || `Trainer ${idx}`)?.join(", ")}`
        } else if (combinedAction.actionType === ACTION_TYPE.DAILY_PROGRESS_REPORT) {
            combinedAction.groupedDescription = combinedAction.description
        } else if (combinedAction.actionType === ACTION_TYPE.CONTENT_VIEW) {
            const pageNames = combinedAction.groupedActions.map(_act => pageNameFromDescription(_act.description)).filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            combinedAction.groupedDescription = `${combinedAction.displayName} finished going through ${combinedAction.count} resources in: ${pageNames.join(", ")}.`
        } else if (combinedAction.actionType === ACTION_TYPE.LEVEL_COMPLETION) {
            combinedAction.groupedDescription = `${combinedAction.displayName} completed ${combinedAction.count} training sections: ${combinedAction.groupedActions?.map((_act, idx) => _act.involvedPartyName || `Section ${idx + 1}`)?.filter((str, idx, arr) => arr.indexOf(str) === idx)?.join(", ")}.`
        } else if (combinedAction.actionType === ACTION_TYPE.JOINED_MEETING) {
            combinedAction.groupedDescription = `${combinedAction.displayName} participated in ${combinedAction.count} meetings${combinedAction.involvedPartyName ? ": " + combinedAction.groupedActions.map((_act, idx) => _act.involvedPartyName || `Meeting ${idx + 1}`).join(", ") : ""}.`
        } else if (combinedAction.actionType === ACTION_TYPE.UPLOADED_PROFILE_IMAGE) {
            combinedAction.groupedDescription = `${combinedAction.displayName} uploaded a new profile picture!`
            combinedAction.location = combinedAction.groupedActions[combinedAction.groupedActions.length - 1].location
        } else if (combinedAction.actionType === ACTION_TYPE.POSST_LIKE) {
            combinedAction.groupedDescription = `${combinedAction.displayName} liked ${combinedAction.count} posts.`
        } else if (combinedAction.actionType === ACTION_TYPE.POSST_COMMENT) {
            combinedAction.groupedDescription = `${combinedAction.displayName} commented on ${combinedAction.count} posts.`
        } else if (combinedAction.actionType === ACTION_TYPE.SCOREBOARD_ENTRY) {
            combinedAction.groupedDescription = `${combinedAction.displayName} updated their score on ${combinedAction.count} leaderboards.`
        } else if (combinedAction.actionType === ACTION_TYPE.SCHEDULED_MEETING) {
            combinedAction.groupedDescription = `${combinedAction.displayName} scheduled ${combinedAction.count} meetings.`
        } else {
            if (combinedAction.description?.endsWith(".")) combinedAction.groupedDescription = combinedAction.description?.substring(0, combinedAction.description?.length - 1)
            combinedAction.groupedDescription += ` ${combinedAction.count} times.`
        }

        // potential thumbnail
        combinedAction.thumbnail = combinedAction.thumbnail || combinedAction.groupedActions?.find(ga => ga.thumbnail)?.thumbnail
    }

    const combinedActionsArray = Object.values(combinedActions);
    return combinedActionsArray
}