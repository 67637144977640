import { Button, ButtonProps, Text } from "@ui-kitten/components";
import { dIconPrimr, IconPrimr } from "assets";
import { dAccessory } from "components";
import { useAppContext } from "engines";
import * as React from "react";
import { ActivityIndicator, Keyboard, StyleProp, ViewStyle, TextStyle } from "react-native";
import { scale, THEME } from "utils";
/**
 * ### This is button component
 * ---
 * @example
 *  <Buttoon
      onPress={() => {}}
      appearance="ghost"
      icon={{ name: "chevron_right"}}
      status="basic"
      
    >
      Nine-nine!
    </Buttoon>
 *
 * @version 1.10.18
 * -  *fix _accessory to return null if !accessoryLeft/Right*
 */
export function Buttoon(props: dButtoon) {
  const {
    icon,
    compact = false,
    appearance,
    status,
    disabled,
    onPress,
    progress,
    forceTheme,
    textStyle
  } = props;
  const [_loading, setLoading] = React.useState(false);

  const { theme: _theme, C } = useAppContext()
  const theme = forceTheme ? forceTheme : _theme

  /**
   * Internal onPress(),
   * handling progress loading state
   */
  function _onPress() {
    !!progress && setLoading(progress);
    Keyboard.dismiss();
    //@ts-ignore
    onPress && onPress(() => setLoading(false));
  }
  const lightModeTintColor = appearance === "ghost" && status === "info" ? C.infoBlue
    : appearance === "ghost" && status === "control" ? C.infoBlue
      : status === "control" ? C.pitchWhite
        : appearance === "ghost" ? C.grey900
          : C.text
  const lightModeIconProps = (p: dAccessory) => ({
    color: lightModeTintColor
  })
  /**
   * Setup Button's accessory prop to be
   * either `accessoryLeft` or `accessoryRight`,
   * depending on `!!icon.right`
   */
  const _accessory = !!!icon
    ? {
      /** If there's no icon and progress: true, show ActivityIndicator on the left */
      accessoryLeft: (p: dAccessory) => {
        return _loading ? (
          <ActivityIndicator style={p.style} color={p.style.tintColor} />
        ) : null;
      },
    }
    : !!icon?.right
      ? {
        /** If there's icon, and icon.right: true, show icon on the right of the button */
        accessoryRight: (p: dAccessory) => {
          return _loading ? (
            <ActivityIndicator style={p.style} color={p.style.tintColor} />
          ) : icon?.render ? (
            icon?.render
          ) : (
            <IconPrimr
              preset={"default"}
              name={`arrow_left`}
              size={p.style.width * 0.8}
              {...(theme === THEME.LIGHT ? lightModeIconProps(p) : { color: p.style.tintColor })}
              {...icon}
            />
          );
        },
      }
      : {
        accessoryLeft: (p: dAccessory) => {
          return _loading ? (
            <ActivityIndicator style={p.style} color={p.style.tintColor} />
          ) : icon?.render ? (
            icon?.render
          ) : (
            <IconPrimr
              preset={"default"}
              name={`arrow_left`}
              size={p.style.width * 0.8}
              {...(theme === THEME.LIGHT ? lightModeIconProps(p) : { color: p.style.tintColor })}
              {...icon}
            />
          );
        },
      };

  // const lightModeModsForText = appearance === "ghost" ?
  //   { color: status === "info" ? C.infoBlue : C.text }
  //   : { color: status === "control" ? C.pitchWhite : status === "info" ? C.pitchWhite : C.grey900 }

  const controlBackgroundColor = theme === THEME.DARK ? C.pitchWhite : C.infoBlue
  const buttonStyle: StyleProp<ViewStyle>[] = [
    props.style,
    // { margin: spacing(1) },
    compact && { alignSelf: "center" },
    disabled && appearance == "ghost" && { backgroundColor: "transparent" },
    status === "control" && appearance === "filled" && { backgroundColor: controlBackgroundColor, borderColor: controlBackgroundColor },
    appearance == "icon" && {
      borderRadius: scale(100),
      borderWidth: 0,
      width: scale(20),
      height: scale(20),
      margin: scale(3),
    }
  ]

  return (
    //@ts-ignore as _accessory's expected Image, but i can use any <_>
    <Button
      {...props}
      onPress={_onPress}
      style={[buttonStyle, props.style || {}]}
      {..._accessory}
    >
      {(typeof props.children) === "string" ? evaProps => (props.children as string)?.length > 0 ?
        <Text {...evaProps} style={{
          ...(evaProps.style as object),
          ...(theme === THEME.LIGHT ? { color: lightModeTintColor } : {}),
          ...(textStyle ? textStyle : {})
        }}>{props.children as string}</Text> : null : props.children}
    </Button>
  );
}

// const Confirmation = (props: P) => {
//   const [_label, setLabel] = React.useState(props.children);
//   return <Buttoon {...props} />;
// };

export interface dButtoon extends ButtonProps {
  icon?: dIconPrimr & {
    /** Is icon on the right? */
    right?: boolean;
    render?: React.ReactElement;
  };
  disabled?: boolean;
  onPress?: Function;
  /**
   * Should button be wrapped around its children ("compact")?
   */
  compact?: boolean;

  progress?: boolean;
  /** maintain a certain button look no matter the user's set dark/light mode */
  forceTheme?: THEME;
  textStyle?: TextStyle;
}
