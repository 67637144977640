//#region [import]
import { IconPrimr, ICON_NAME } from "assets";
import { Buttoon, M, Skeleton, Txt } from "components";
import { dAccessory, ListHeader } from "components/atoms";
import { MailSchema, ScheduleSchema, useAppContext } from "engines";
import {
    useEmailSchedulesCollection,
    useEmailsCollection
} from "engines/firebase/handler/emails-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import * as R from "ramda";
import React from "react";
import { FlatList, RefreshControl } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";

export function PreviousMailList() {
  const {} = useAppContext();

  const handleMails = useEmailsCollection();

  const userProfile = useUserProfile();

  const handleSchedulesMails = useEmailSchedulesCollection();

  const Navigation = useNavigator();
  const [order, setOrder] = React.useState<"desc" | "asc">("desc");
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const [toggle, setToggle] = React.useState<boolean>(false);
  const [listOffset, setListOffset] = React.useState(10);
  const [listOffsetschedule, setListOffsetSchedule] = React.useState(10);

  const toggleShow = async () => {
    console.log(toggle);
    setToggle(!toggle);
  };

  const MenuIcon = (name: ICON_NAME, props: dAccessory) => (
    <IconPrimr
      name={name}
      size={props.style.width}
      color={props.style.tintColor}
    />
  );

  //#endregion

  try {
    if (handleMails.error) {
      // console.log("this is the error");
      console.log(handleMails.error);
      return <Txt.Indicator>Error!</Txt.Indicator>;
    }

    if (handleSchedulesMails.error) {
      // console.log("this is the error");
      console.log(handleSchedulesMails.error);
      return <Txt.Indicator>Error!</Txt.Indicator>;
    }

    if (!handleMails.data) return <Skeleton {...Skeleton.Screen.Possts} />;
    if (!handleSchedulesMails.data && userProfile.data)
      return <Skeleton {...Skeleton.Screen.Possts} />;
    let userData = userProfile.data;
    let sortedMails = R.sortWith<MailSchema>(
      [
        /** sorting `createdAt` in `desc` */
        R.descend(R.prop("createdAt")),
      ],
      handleMails.data
    ).filter((mails) => mails.userId === userData.id);

    let sorteSchedules = R.sortWith<ScheduleSchema>(
      [
        /** sorting `createdAt` in `desc` */
        R.descend(R.prop("createdAt")),
      ],
      handleSchedulesMails.data
    ).filter((schedules) => schedules.userId === userData.id);
    return (
      <>
        <ListHeader
          titleTx={tr(toggle ? "Sent" : "Scheduled")}
          actions={[
            {
              label: toggle ? "View scheduled mails" : "View sent mails",
              onPress: () => toggleShow(),
            },
          ]}
        />

        {toggle == true ? (
          <FlatList
            data={sortedMails
              /** pagination */
              .slice(0, listOffset)}
            ListFooterComponent={() => (
              <Buttoon
                appearance={"ghost"}
                progress
                onPress={(xong) => {
                  setTimeout(() => {
                    setListOffset(listOffset + 5);
                    xong && xong();
                  }, 600);
                }}
                disabled={listOffset >= sortedMails.length}
              >
                {listOffset < sortedMails.length ? "Load More" : ""}
              </Buttoon>
            )}
            ListEmptyComponent={() => (
              <Buttoon appearance="ghost" status={"basic"}>
                Empty Feed
              </Buttoon>
            )}
            keyExtractor={(item) => item.userId}
            renderItem={({ item, index }) => <M.MailListItem item={item} />}
          />
        ) : null}

        {toggle == false ? (
          <FlatList
            data={handleSchedulesMails.data}
            refreshControl={<RefreshControl refreshing={refresh} />}
            ListFooterComponent={() =>
              handleSchedulesMails.data.length > 0 && (
                <Buttoon
                  appearance={"ghost"}
                  progress
                  onPress={(xong) => {
                    setTimeout(() => {
                      setListOffsetSchedule(listOffsetschedule + 5);
                      xong && xong();
                    }, 600);
                  }}
                  disabled={listOffsetschedule >= sorteSchedules.length}
                >
                  {listOffsetschedule < sorteSchedules.length
                    ? "Load More"
                    : ""}
                </Buttoon>
              )
            }
            ListEmptyComponent={() => (
              <Buttoon appearance="ghost" status={"basic"}>
                Empty Feed
              </Buttoon>
            )}
            keyExtractor={(item) => item.id}
            renderItem={({ item, index }) => (
              <M.ScheduleListItem item={item} docID={item.id} />
            )}
          />
        ) : null}
      </>
    );
  } catch (error) {
    throw new Error(error);
  }
}
