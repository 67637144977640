import * as Media from "expo-image-picker";
import { Platform } from "react-native";
import { isPermissionAllowed } from "./isPermissionAllowed";
export async function requestMediaPermissions(): Promise<{
  isIOSMediaAllowed?: boolean;
  /**
   * ### For android
   */
   isReadMediaAllowed?: boolean;
  /**
   * ### For android
   */
   isWriteMediaAllowed?: boolean;
}> {
  const keyword = "storage";
  if (Platform.OS === "android") {
    const isReadMediaAllowedResult =
      await Media.requestMediaLibraryPermissionsAsync();
    const isReadMediaAllowed = isPermissionAllowed(
      isReadMediaAllowedResult,
      keyword
    );

    const isWriteMediaAllowedResult =
      await Media.requestMediaLibraryPermissionsAsync(true);
    const isWriteMediaAllowed = isPermissionAllowed(
      isWriteMediaAllowedResult,
      keyword
    );
    return {
       isReadMediaAllowed,
       isWriteMediaAllowed,
    };
  } else if (Platform.OS === "ios") {
    const isIOSContactAllowed = await Media.requestMediaLibraryPermissionsAsync();
    return {
      isIOSMediaAllowed: isPermissionAllowed(isIOSContactAllowed, keyword),
    };
  }
  return null;
}
