import { useAppContext } from "engines";
import React, { useEffect, useState } from "react";
import { Animated, Dimensions, View, ViewStyle } from "react-native";
import { sstyled } from "../../generals/sstyled/sstyled";

const deviceWindow = Dimensions.get("window"),
  regexArr = [/[a-z]/, /[A-Z]/, /[0-9]/, /[^A-Za-z0-9]/];

export function PassMeter(props: dPassMeter) {
  const { C } = useAppContext();
  const {
    password,
    labels,
    minLength = 4,
    maxLength = 15,
    showLabels = true,
    backgroundColor = C.surface,
    fromColor = C.errorRed,
    toColor = C["color-success-500"],
    barStyle,
    onPassStatCallback,
  } = props;
  const barLength = barStyle?.width || deviceWindow.width * 0.9,
    [passStat, setPassStat] = useState("Weak"),
    [animateVal, setAnimateVal] = useState(new Animated.Value(0)),
    [animateColor, setAnimateColor] = useState(new Animated.Value(0));
  React.useEffect(() => onPassStatCallback(passStat), [passStat]);
  useEffect(() => {
    Animated.spring(animateVal, {
      bounciness: 6,
      toValue: barLength * (password.length / maxLength),
      useNativeDriver: false,
    }).start();
    let passPoint = 0;

    if (password.length > 0 && password.length < minLength)
      setPassStat(labels[0]);
    else {
      regexArr.forEach((rgx) => (rgx.test(password) ? (passPoint += 1) : null));
      setPassStat(labels[passPoint]);
    }

    Animated.timing(animateColor, {
      toValue: passPoint,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [password]);

  const interpolateColor = animateColor.interpolate({
    inputRange: [0, 4],
    outputRange: [fromColor, toColor],
  });

  return (
    <A.ViewContainer>
      <A.BackBar style={{ backgroundColor: backgroundColor, ...barStyle }} />
      <A.MainBar
        style={{
          backgroundColor: interpolateColor,
          width: animateVal,
        }}
      />
      {showLabels ? (
        password.length != 0 ? (
          <A.TxtIndicator style={{ color: interpolateColor }}>
            {passStat}
          </A.TxtIndicator>
        ) : null
      ) : null}
    </A.ViewContainer>
  );
}
const A = {
  ViewContainer: sstyled(View)({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  }),
  BackBar: sstyled(View)({
    height: 1,
    // borderRadius: 25,
  }),
  MainBar: sstyled(Animated.View)({
    position: "absolute",
    // backgroundColor: "blue",
    height: 1,
    // borderRadius: 25,
  }),
  TxtIndicator: sstyled(Animated.Text)({
    marginLeft: 1,
    fontSize: 11,
    // marginTop: 5,
    fontWeight: "bold",
  }),
};

interface dPassMeter {
  minLength: number;
  showLabels: boolean;
  maxLength: number;
  labels: string[];
  password: string;
  backgroundColor?: string;
  fromColor?: string;
  toColor?: string;
  barStyle?: ViewStyle;
  onPassStatCallback(passStat: string): void;
}
