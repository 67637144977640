//#region [import]

import { sstyled } from "components";
import { Kitten } from "components/atoms";
import { useAppContext } from "engines";
import React from "react";
import { useWindowDimensions, View, ViewProps, ViewStyle } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigator } from "screens";
import { scale, spacing } from "utils";
//#endregion

/**
 * ### Two-column layout template for responsive screen
 * -  For smaller screen, 2nd column will turn into "HollowFooter", allowing user to tap and use
 *  ----
 * @example
 * <T.OneColumn
 *  column1Content={(scrollHandler)=>(<><Txt>Column 1</Txt></>)}
 *  column2Content={[
      { headerTitle: "Section One", icon: "rocket", render: () => <O.MediaHomeActions /> },
      { headerTitle: "Section Two", icon: "placeholder", render: () => <Txt>Section 2 of column 2</Txt>}
    ]}
 * />
 *
 *  ----
 *  @version 21.04.23
 *  -  *Turn in to Template*
 *  @author  K
 *
 **/
export function OneColumn({ column1Content, containerStyle }: P) {
  const { C, teamId } = useAppContext();
  const { height } = useWindowDimensions();
  const safeInsets = useSafeAreaInsets();
  const Navigation = useNavigator();

  const bottomBarHeight = 101;
  const [contentReady, setContentReady] = React.useState<boolean>(false);
  React.useEffect(function staging() {
    setTimeout(() => {
      setContentReady(true);
    }, 600);
  }, []);
  return (
    <>
      <A.Ctnr style={containerStyle}>
        <A.Sctnr
          // nestedScrollEnabled
          height={height}
          contentContainerStyle={{ marginBottom: bottomBarHeight }}
        >
          {contentReady && column1Content && column1Content()}
        </A.Sctnr>
      </A.Ctnr>

      {/* <A.CtnrFloatingFooter>
          <A.CtnrFooterRow>
            <IconPrimr
              key={0}
              preset={"circular"}
              name={"bars"}
              color={C.text}
              containerStyle={{
                ...A.FooterIcon(C),
                backgroundColor: C.primary,
              }}
              onPress={() => {
                Navigation.dispatch(DrawerActions.toggleDrawer());
              }}
            />
          </A.CtnrFooterRow>
        </A.CtnrFloatingFooter> */}
    </>
  );
}

const A = {
  Ctnr: sstyled(View)((p) => ({
    flex: 1,
    flexDirection: "row",
    backgroundColor: "background",
    // marginBottom: p.s(60),
  })),
  Sctnr: sstyled(ScrollView)((p: { height: number }) => ({
    maxHeight: p.height,
    minWidth: 300,
    // flex: 1,
    // paddingLeft: 4,
    // paddingTop: 6,
    // paddingRight: [4, 4, 4, "42%", "45%"],
    // backgroundColor: "yellow",
    // borderRightWidth: 1,
  })),

  CtnrFloatingFooter: sstyled(View)({
    position: "absolute",
    bottom: 0,
    paddingBottom: spacing(5),
    justifyContent: "center",
    alignItems: "center",
    width: ["100%", "100%", 0, 0, 0],
    // backgroundColor: "green",
    // flexDirection: "row",
    overflow: "hidden",
  }),
  CtnrFooterRow: sstyled(Kitten.ViewH)({
    width: "100%",
    justifyContent: "space-around",
    paddingHorizontal: spacing(3),
  }),
  FooterIcon: (C) =>
    ({
      margin: spacing(2),
      width: scale(42),
      height: scale(42),
      backgroundColor: C.background,
      shadowColor: C.primary,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.34,
      shadowRadius: 6.27,

      elevation: 10,
    } as ViewProps),
};

interface P {
  column1Content: (props?) => React.ReactElement;
  containerStyle?: ViewStyle;
}
