/**
 * ### 
 *  - Function is used to check if media link is a fin link
 *  ----
 *  @example 
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.02.09
 *  -  *Brief changelog*
 *  @author  Nl
 *  
 **/
export const isFinURL = (url: string): boolean => {
  return url && url.includes("discoverfin.io");
};
