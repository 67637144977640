import { InputProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Avt, Kitten, Toasty, Txt } from "components";
import {
  ContactGroupSchemaa,
  ContactSchemaa
} from "engines/backends/schemas/bml";
import { validateEmail } from "engines/functions/js-functions";
import { useAppContext } from "engines/hooks";
import * as Contacts from "expo-contacts";
import React, { useImperativeHandle, useRef, useState } from "react";
import {
  FlatListProps,
  Keyboard,
  Platform,
  StyleSheet,
  TextInput,
  TextInputProps,
  TextStyle,
  useWindowDimensions,
  View,
  ViewStyle
} from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { IS_WEB, spacing, tr } from "utils";
import { Suggestion, SuggestionProps } from "./suggestion";
import { Tag } from "./tag";
// export type Tag = ContactSchemaa;
// export type Suggestion = ContactSchemaa;
export interface AutocompleteTagsProps {
  mode: "sms" | "email";
  accessoryLeft(): InputProps["accessoryLeft"];
  accessoryRight(): InputProps["accessoryRight"];
  /** array of tags to render */
  tags: (ContactSchemaa | ContactGroupSchemaa)[];
  /** array of all possible suggestions that the autocomplete pulls from */
  suggestions?: (ContactSchemaa | ContactGroupSchemaa)[];
  showSuggestions?: boolean;
  contactPermissionStatus?: Contacts.PermissionStatus;
  /** function called when tags needs to be updated */
  onChangeTags: (newTags: (ContactSchemaa | ContactGroupSchemaa)[]) => void;
  /** given a tag, returns the string that should be rendered in a Tag */
  labelExtractor: (tag: ContactSchemaa | ContactGroupSchemaa) => string;
  /** called when a tag is pressed, instead of calling `onChangeTags` with the pressed tag removed */
  onTagPress?: (tag: ContactSchemaa | ContactGroupSchemaa) => void;
  /** an array of characters that should trigger a new tag and clear the TextInput
   * @default [',', ' ', ';', '\n']  */
  parseChars?: string[];
  /** called when the user types a character in parseChars and should therefore add a new tag
   * if undefined, will call `onChangeTags` with `[...tags, userInputText]`
   */
  onAddNewTag?: (userInput: string) => void;
  /** whether or not to allow the user to create a Tag that doesn't come from `suggestions`
   * @default true
   */
  allowCustomTags?: boolean;
  /** called when a suggestion is pressed
   * defaultly calls `onChangeTags` with `[...tags, pressedSuggestion]`
   */
  onSuggestionPress?: (
    suggestion: ContactSchemaa | ContactGroupSchemaa
  ) => void;
  /** given a Suggestion, returns a string that can be compared to the user's search */
  suggestionExtractor?: (
    suggestion: ContactSchemaa | ContactGroupSchemaa
  ) => string;
  /** a function for filtering suggestions based on the TextInput value */
  filterSuggestions?: (
    text: string
  ) => (ContactSchemaa | ContactGroupSchemaa)[];
  /** a function that returns a custom tag component */
  renderTag?: (
    tag: ContactSchemaa | ContactGroupSchemaa,
    onPress: (tag: ContactSchemaa | ContactGroupSchemaa) => void
  ) => JSX.Element;
  /** a function that returns a custom suggestion component */
  renderSuggestion?: (
    suggestion: ContactSchemaa | ContactGroupSchemaa,
    onPress: (tag: ContactSchemaa | ContactGroupSchemaa) => void
  ) => JSX.Element;
  /** any custom TextInputProps */
  inputProps?: Partial<TextInputProps>;
  /** any additional FlatListProps */
  flatListProps?: Partial<FlatListProps<any>>;
  /** style for the outer-most View that houses both the tagContainer and suggestion list */
  containerStyle?: ViewStyle;
  /** styles for the container View that houses the tags and the input */
  tagContainerStyle?: ViewStyle;
  /** styles for the TextInput component */
  inputStyle?: TextStyle;
  /** styles for the FlatList that renders suggestions */
  flatListStyle?: ViewStyle;
  /** Default is "To:" */
  title?: string;
  /** This will keep the text input at the top instead of listing selected tags above it */
  keepInputAtTop?: boolean;
  /** Offset where the suggestions drop down from if they need to be adjusted (for example: Build My List) */
  // suggestionsYOffset?: number;
}

export const idFromItem = (
  item: ContactSchemaa | ContactGroupSchemaa
): string => {
  return (item as ContactGroupSchemaa)?._id?.length > 0
    ? (item as ContactGroupSchemaa)._id
    : (item as ContactSchemaa)._cid;
};

/**
 * ### An autocomplete input, with tags implemented (Gmail like)
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 * const suggestions = [
        { name: "Boris Yeltsin", email: "boris.yeltsin@abc.com " },
        { name: "Tom Boboby", email: "tom.boboy@abc.com" },
    ];
 *  const [tags, setTags] = useState<Suggestion[]>([
        { name: "Fred Hendriks", email: "fred.hendricks@abc.com" },
    ]);
​
    const onAddNewTag = (input: string) => {
        setTags((tags) => [...tags, { name: input, email: input }]);
    }; 
 *  <AutocompleteTags
    tags={tags}
    suggestions={suggestions}
    labelExtractor={(item) => item.name}
    suggestionExtractor={(item) => item.email}
    onChangeTags={(tags) => setTags(tags)}
    onAddNewTag={onAddNewTag}
    inputProps={{
        autoCapitalize: "none",
        autoCorrect: false,
    }}
    containerStyle={{ backgroundColor: "yellow" }}
    />
 *  ----
 *  @version 21.06.24
 *  -  *@see https://github.com/Eldjotnar/react-native-autocomplete-tags *
 *  @author  K
 *  
 **/
export const AutocompleteTags = React.forwardRef<any, AutocompleteTagsProps>(
  (
    {
      mode,
      tags,
      suggestions,
      showSuggestions,
      labelExtractor,
      suggestionExtractor,
      onChangeTags,
      onTagPress,
      parseChars = [",", ";", "\n", "\t"],
      contactPermissionStatus,
      allowCustomTags,
      onAddNewTag,
      onSuggestionPress,
      filterSuggestions,
      renderTag,
      renderSuggestion,
      containerStyle,
      tagContainerStyle,
      inputStyle,
      flatListStyle,
      inputProps,
      flatListProps,
      accessoryLeft,
      accessoryRight,
      title = "To:",
      keepInputAtTop = false,
      // suggestionsYOffset=0
    },
    ref
  ) => {
    const [_text, setText] = useState("");
    const [inputBottomY, setInputBottomY] = React.useState<number>(0);
    const refInput = useRef<TextInput | null>(null);
    useImperativeHandle(ref, () => refInput.current);
    const window = useWindowDimensions();
    const extractor = suggestionExtractor || labelExtractor;
    const handleTagPress = (tag: ContactSchemaa | ContactGroupSchemaa) => {
      if (onTagPress) {
        onTagPress(tag);
      } else {
        onChangeTags(
          tags.filter((t) => labelExtractor(t) !== labelExtractor(tag))
        );
        // onChangeTags(tags.filter((t) => !isTagAlreadySelected(t)));
        refInput?.current?.focus();
      }
    };

    const isTagAlreadySelected = (item: ContactSchemaa | ContactGroupSchemaa) =>
      tags.find(
        (t) =>
          ((t as ContactGroupSchemaa)._id?.length > 0 &&
            (t as ContactGroupSchemaa)._id ===
              (item as ContactGroupSchemaa)._id) ||
          ((t as ContactSchemaa)._cid?.length > 0 &&
            (t as ContactSchemaa)._cid === (item as ContactSchemaa)._cid)
      )
        ? true
        : false;

    const handleSuggestionPress = (
      suggestion: ContactSchemaa | ContactGroupSchemaa
    ) => {
      if (isTagAlreadySelected(suggestion)) {
        /**
         * If suggestion is on the list, remove it? (not sure if this is doing anything currently)
         */
        let _tags = Array.from(
          tags.filter((t) => idFromItem(t) !== idFromItem(suggestion))
        );
        onChangeTags(_tags);
        // refInput.current?.focus();
      } else {
        /** If suggestion is not on the tags list yet, add it in */
        setText("");
        if (onSuggestionPress) {
          onSuggestionPress(suggestion);
        } else {
          onChangeTags(tags.concat([suggestion]));
          // refInput?.current?.focus();
        }
        // refInput.current?.focus();
      }
    };
    const handleTextChange = (input: string) => {
      setText(input);
      const lastTyped = input.charAt(input.length - 1);
      if (parseChars && parseChars.indexOf(lastTyped) > -1) {
        if (!validateEmail(input.slice(0, -1))) {
          if (IS_WEB) Toasty.show(tr("Please enter a valid email."));
          /**
           * This toasty validation is for BML mobile ContactItem component.
           */ else {
            Toasty.show(tr("Contact not found..."), { type: "warning" });
          }
          setText("");
        } else {
          setText("");
          if (allowCustomTags) {
            const label = input.slice(0, -1);
            if (onAddNewTag) {
              onAddNewTag(label);
            } else {
              // onChangeTags([...tags, label]);
              onChangeTags(tags);
              refInput?.current?.focus();
            }
          }
        }
      }
    };
    // this is where the return key adds a custom tag if enabled
    const handleBlur = () => {
      if (_text === "" || _text == null) {
        return null;
      }
      if (getSuggestions().length == 0) {
        if (allowCustomTags) {
          if (onAddNewTag) {
            onAddNewTag(_text);
            setText("");
          } else {
            // onChangeTags([...tags, text]);
            onChangeTags(tags);
            setText("");
          }
        }
      } else {
        return null;
      }
    };
    const renderTagComponent = (tag: ContactSchemaa | ContactGroupSchemaa) => {
      const onPress = () => handleTagPress(tag);
      if (renderTag) {
        return renderTag(tag, onPress);
      }
      return (
        <Tag
          label={labelExtractor(tag)}
          key={labelExtractor(tag)}
          onPress={onPress}
        />
      );
    };
    const renderSuggestionComponent = ({
      item,
    }: {
      item: ContactSchemaa | ContactGroupSchemaa;
    }) => {
      //console.log("Rendering Suggestion", item);
      const onPress = () => handleSuggestionPress(item);
      if (renderSuggestion) {
        return renderSuggestion(item, onPress);
      }
      let configSuggestion = () => {
        let config: SuggestionProps = {
          label: extractor(item),
          disabled: false,
          accessoryRight: null,
        };
        switch (mode) {
          case "sms":
            config.disabled =
              (item as ContactSchemaa)?.phoneNumbers?.length < 1 &&
              (item as ContactGroupSchemaa)?.contacts?.length < 1;
            return config;
            break;
          case "email":
            config.disabled =
              !(item as ContactSchemaa)?.email &&
              (item as ContactSchemaa)?.emails?.length < 1;
          default:
            return config;
            break;
        }
      };
      return (
        <Suggestion
          onPress={onPress}
          {...configSuggestion()}
          accessoryLeft={() =>
            isTagAlreadySelected(item) ? (
              <IconPrimr
                name="check"
                preset="circular"
                color={C.text01}
                containerStyle={{ backgroundColor: C.primary }}
              />
            ) : (
              <Avt
                // name={fn.js.extractString(["\(", "\)"])(label)[0]}
                name={String(extractor(item)).split("•")[0]}
                // source={{ uri: "handlePosst.get()?.author?.avatar" }}
                size="small" //* or size={420}
              />
            )
          }
          style={[
            isTagAlreadySelected(item) && {
              backgroundColor: C.primaryDarker,
            },
          ]}
        />
      );
    };
    const onKeyPress = ({
      nativeEvent: { key },
    }: {
      nativeEvent: { key: string };
    }) => {
      if (
        _text !== "" ||
        key !== "Backspace" ||
        // key !== "Tab" ||
        tags.length < 1
      ) {
        return;
      }
      const updatedTags = [...tags];
      updatedTags.pop();
      onChangeTags(updatedTags);
      refInput?.current?.focus();
    };
    const getSuggestions = () => {
      if (_text?.length < 1) return [];
      // return suggestions;
      if (filterSuggestions) {
        return filterSuggestions(_text);
      }
      if (!_text || _text === "") {
        return [];
        // return suggestions;
      }
      const regex = new RegExp(`${_text.trim()}`, "i");
      return suggestions?.filter((item) => extractor(item).search(regex) >= 0);
    };
    const { C } = useAppContext();

    function renderTagComponents(
      tags: (ContactSchemaa | ContactGroupSchemaa)[]
    ) {
      const arr = tags.concat([]);
      keepInputAtTop && arr.reverse();
      return arr.map((a) => renderTagComponent(a));
    }

    return (
      // <ScrollView
      //   style={{ flex: 1, width: Dimensions.get("window").width }}
      //   keyboardShouldPersistTaps="always"
      // >
      <View style={[styles.container, containerStyle]}>
        <Kitten.ViewH
          style={{
            flexWrap: "wrap",
            paddingHorizontal: spacing(3),
            flex: 1,
          }}
        >
          <Txt.S2>{title}</Txt.S2>
          {keepInputAtTop ? null : renderTagComponents(tags)}
          <TextInput
            onLayout={(e) =>
              setInputBottomY(
                e.nativeEvent.layout.y + e.nativeEvent.layout.height
              )
            }
            value={_text}
            placeholder={tr("")}
            placeholderTextColor={C.grey600}
            onKeyPress={onKeyPress}
            ref={refInput}
            onChangeText={handleTextChange}
            onBlur={handleBlur}
            returnKeyType="done"
            style={[
              {
                // color: C.text,
                color: C.pitchWhite,
                padding: spacing(2),
                flexGrow: 1,
                ...(keepInputAtTop ? { width: "100%" } : {}),
              },
              inputStyle,
            ]}
            {...inputProps}
          />
          {keepInputAtTop ? renderTagComponents(tags) : null}
          <View>{accessoryRight && accessoryRight()}</View>
        </Kitten.ViewH>
        {contactPermissionStatus &&
        contactPermissionStatus !== Contacts.PermissionStatus.GRANTED ? (
          <Txt.S1 style={{ color: C.hazardYellow }}>
            {"You must enable contact permissions in your settings."}
          </Txt.S1>
        ) : null}
        {showSuggestions && _text?.length > 0 ? (
          <FlatList
            data={getSuggestions()}
            keyExtractor={(a) => extractor(a)}
            renderItem={renderSuggestionComponent}
            ItemSeparatorComponent={Kitten.Divider}
            keyboardShouldPersistTaps="always"
            onScrollBeginDrag={() => Keyboard.dismiss()}
            // keyboardShouldPersistTaps="handled"
            style={[
              Platform.OS === "android"
                ? {
                    // ...(suggestionsYOffset ? { top: suggestionsYOffset } : {}),
                  }
                : {
                    // top: inputBottomY + spacing(3),
                    ...styles.list,
                    top: inputBottomY + spacing(3), //+ suggestionsYOffset,
                  },
              {
                maxHeight: window.height * 0.6,
                zIndex: 100,
              },
              flatListStyle,
            ]}
            {...flatListProps}
          />
        ) : (
          <View />
        )}
      </View>
      // </ScrollView>
    );
  }
);
const styles = StyleSheet.create({
  container: {
    zIndex: 100,
    width: "100%",
    flex: 1,
  },
  tagContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: "80%",
  },
  input: {
    flex: 1,
    minWidth: 100,
  },
  list: {
    position: "absolute",
    width: "100%",
    // top: 0,
    // left: 0,
    // right: 0,
  },
});
AutocompleteTags.defaultProps = {
  parseChars: [",", ";", "\n", "\t"],
  allowCustomTags: false,
  suggestions: [],
};
