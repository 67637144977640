import { useCollectionGroup } from "@nandorojo/swr-firestore";
import { FPATH } from "engines";
import firebase from "firebase";
import { dContentView } from "../../backends/schemas/users/user.schema";
import { useCollectionPresetv2 } from "../config";

/**
 * Add a watch/listen to a user's view history
 *
 * @author jm_francis
 *
 * @example
 * await addViewHistoryForUser(frbsUser?.uid, {
 *  itemId: item._id,
 *  uid: frbsUser?.uid,
 *  url: item.media,
 *  watched: progress,
 *  //@ts-ignore
 *  startedAt: new Date()
 * })
 */
export function addViewHistoryForUser(uid: string, viewContent: dContentView) {
  const collectionPath = `${FPATH.USERS}/${uid}/${FPATH.USER_VIEW_HISTORY}`;
  return firebase.firestore().collection(collectionPath).add(viewContent);
}

/**
 * Used in updating, deleting, getting the activity history for a user
 */
export function useUserViewHistory(userId: string, query?) {
  const collectionPath = `${FPATH.USERS}/${userId}/${FPATH.USER_VIEW_HISTORY}`;
  const defaultCollectionFunctions = useCollectionPresetv2<dContentView>(
    collectionPath,
    {
      listen: true,
      ...query,
    }
  );

  return {
    ...defaultCollectionFunctions,
    addViewHistoryForUser,
  };
}

/**
 * Get the view history of a specific resource by inputting the resource's url
 * (current only supports media items using the "media" field in a item)
 */
export function useViewHistoryForUrl(
  _teamId: string,
  videoUrl: string,
  query?
) {
  const defaultCollectionFunctions = useCollectionGroup<dContentView>(
    FPATH.USER_VIEW_HISTORY,
    {
      where: [["url", "==", videoUrl]],
      orderBy: ["startedAt", "asc"],
    },
    { onError: (err) => console.error(err) }
  );

  return defaultCollectionFunctions;
}
