//#region [import]
import { Buttoon, Kitten, Poppy, sstyled, Toasty } from "components/atoms";
import { MediaPageItemSchema, useAppContext } from "engines";
import { dUseDocumentReturns } from "engines/firebase/config";
import React from "react";
import { TextInput, View } from "react-native";
import { scale, spacing, tr } from "utils";

//#endregion

export function ParagraphItemEditor({
  pageContentHandler,
  onUpdateCallback,
}: {
  pageContentHandler: dUseDocumentReturns<MediaPageItemSchema>;
  onUpdateCallback(): void;
}) {
  const { C } = useAppContext();
  let content = pageContentHandler?.data;
  const refInput = React.useRef<TextInput>();
  const [value, setValue] = React.useState<string>(content?.paragraph);

  React.useEffect(function staging() {
    refInput.current?.focus();
  }, []);

  //#region [functions]
  function onDelete() {
    Poppy(
      "The field is empty",
      "Are you sure you want to delete this item?",
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () => {
          pageContentHandler.deleteDocument();
          Toasty.show(tr("Paragraph deleted"), { type: "success" });
        },
      }
    );
  }

  function onSubmit() {
    if (value?.length == 0) {
      onDelete();
    } else {
      pageContentHandler.update({ paragraph: value });
      Toasty.show(tr("Paragraph updated"), { type: "success" });
      onUpdateCallback && onUpdateCallback();
    }
    //#endregion
  }
  return (
    <View>
      <A.InputParagragh
        ref={refInput}
        multiline
        value={value}
        onChangeText={setValue}
        selectTextOnFocus
        onSubmitEditing={onSubmit}
      />
      <Kitten.Divider_________ />
      <Buttoon
        compact
        disabled={value === content.paragraph}
        onPress={onSubmit}
      >
        {tr("Save")}
      </Buttoon>
    </View>
  );
}

const A = {
  InputParagragh: sstyled(TextInput)({
    minHeight: scale(169),
    color: "text",
    borderWidth: 1,
    borderRadius: scale(4),
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
  }),
};
