import { MenuIcon } from "assets/icons/MenuIcon";
import { dAccessory, Kitten } from "components";
import {
  DocumentCreatorWithTitle,
  MailTemplatesCreate,
  PosstCreatorCenter
} from "components/organisms";
import { BroadcastCreator } from "components/organisms/broadcast/BroadcastCreator";
import {
  useAppContext,
  useScoreboardCollection,
  __ScoreboardData
} from "engines";
import React from "react";
import { useNavigator } from "screens";
import { tr } from "utils";

export function CreatorActionsList() {
  const { teamId, C } = useAppContext();
  const scoreboardsHandler = useScoreboardCollection();
  const Navigation = useNavigator();
  return (
    <Kitten.Menu style={{ borderWidth: 1, borderColor: C.adminGold }}>
      <Kitten.MenuItem
        title={tr("Post Announcement")}
        onPress={() => {
          Navigation.dialogPortal.open({
            headerTitle: tr("Create Announcement"),
            render: <PosstCreatorCenter />,
          });
        }}
        accessoryLeft={(props: dAccessory) => MenuIcon("pen", props)}
      />
      <Kitten.MenuItem
        title={tr("Send a Push Notification")}
        onPress={() => {
          Navigation.dialogPortal.open({
            headerTitle: tr("Send a Push Notification"),
            render: <BroadcastCreator />,
          });
        }}
        accessoryLeft={(props: dAccessory) => MenuIcon("notification", props)}
      />
      <Kitten.MenuItem
        title={tr("Send a Mass Email")}
        onPress={() => {
          Navigation.dialogPortal.open({
            headerTitle: tr("Send a Mass Email"),
            render: <MailTemplatesCreate />,
          });
        }}
        accessoryLeft={(props: dAccessory) => MenuIcon("email", props)}
      />
      <Kitten.MenuItem
        title={tr("Create a scoreboard")}
        onPress={() => {
          Navigation.dialogPortal.open({
            headerTitle: tr("Enter scoreboard title"),
            render: (
              <DocumentCreatorWithTitle
                title={tr("Enter scoreboard title")}
                onCreate={(pageName) => {
                  scoreboardsHandler.add({
                    ...__ScoreboardData({ teamId }),
                    title: pageName,
                  });
                }}
              />
            ),
          });
        }}
        accessoryLeft={(props: dAccessory) => MenuIcon("scoreboard", props)}
      />
      {/* <Kitten.MenuItem
        title={tr("Blast SMS")}
        accessoryLeft={(props: dAccessory) => MenuIcon("sms", props)}
      /> */}
    </Kitten.Menu>
  );
}
