import { useCollection } from "@nandorojo/swr-firestore";
import { useFocusEffect } from "@react-navigation/native";
import { ICON_NAME } from "assets";
import { ListHeader, M, Poppy, sstyled, Toasty, Txt } from "components";
import { Buttoon, Kitten, Skeleton } from "components/atoms";
import { ShareAccountDialog } from "components/organisms/Teammates/ShareAccountDialog";
import {
    FPATH,
    Handler,
    useAppContext,
    useMemberDocument,
    UserSchema
} from "engines";
import * as R from "ramda";
import React from "react";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";

/**
 * ###
 *  -
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.01.21
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function FieldTrainerSelection(p: P) {
    const { onSelect, customIcon } = p;
    const { C, teamId, frbsUser } = useAppContext();
    const Navigation = useNavigator()

    const [members, setMembers] = React.useState<UserSchema[]>([]);
    const memberHandler = useMemberDocument(frbsUser?.uid);
    //#region [FRBS]
    const handleMembers = useCollection<UserSchema>(`${FPATH.USERS}`, {
        ...Handler.defaultSetup<UserSchema>(teamId),
    });
    const [_sharedWithUids, setSharedWithUids] = React.useState<string[]>([]);
    const shareData = memberHandler?.data?.growth?.listBuilder?.shareTo;
    React.useEffect(
        function setStates() {
            setSharedWithUids(shareData ? shareData : []);
        },
        [shareData]
    );

    React.useEffect(
        function setupData() {
            let __members = handleMembers.data;
            handleMembers.data &&
                setMembers(
                    __members.filter((m) =>
                        memberHandler.data?.growth?.listBuilder?.shareTo?.includes(m._id)
                    )
                );
        },
        [handleMembers.data]
    );
    const [listOffset, setListOffset] = React.useState(30);
    //#endregion

    //#endregion
    return React.useMemo(() => {
        try {
            if (handleMembers.error) {
                return (
                    <Txt.Indicator>{tr("Error getting members feed")}</Txt.Indicator>
                );
            }
            if (!handleMembers.data) return <Skeleton.Screen.Members length={7} />;

            let sortedMembers = R.sortWith<UserSchema>(
                [
                    /** sorting `createdAt` in `desc` */
                    R.descend(R.prop("createdAt")),
                ],
                members
            );
            return (
                <View>
                    <A.CardContainer disabled>
                        <FlatList
                            data={sortedMembers}
                            renderItem={({ item: member, index }) => (
                                <>
                                    {_sharedWithUids && _sharedWithUids.includes(member?._id) ? (
                                        <M.MemberListItem
                                            key={member._id}
                                            member={member}
                                            team={true}
                                            selectMode
                                            customIcon={customIcon}
                                            overridePress={() => {
                                                onSelect(member);
                                                Navigation.goBack();
                                            }}
                                        />
                                    ) : null}
                                </>

                                /* Type was added in other to reuse the memberListItem component and 
                              to know if it was a BS or a member info detail, ask Nanle if you have questions */
                            )}
                            ListFooterComponent={() =>
                                <>{
                                    sortedMembers?.length > 10 ? (
                                        <Buttoon
                                            appearance={"ghost"}
                                            progress
                                            onPress={(xong) => {
                                                setTimeout(() => {
                                                    setListOffset(listOffset + 5);
                                                    xong && xong();
                                                }, 600);
                                            }}
                                            disabled={listOffset >= sortedMembers.length}
                                        >
                                            {listOffset < sortedMembers.length &&
                                                sortedMembers.length !== 0
                                                ? tr("Load More")
                                                : ""}
                                        </Buttoon>
                                    ) : null}
                                    <Buttoon onPress={() => {
                                        Navigation.goBack()
                                        setTimeout(() => {
                                            Navigation.navigate("Teammates")
                                        }, 100)
                                    }} status="control" appearance="outline" style={{ marginTop: spacing(4) }} size="small" icon={{ name: "share" }}>Assign Trainer</Buttoon></>
                            }
                            ListEmptyComponent={() => (
                                <>
                                    <Txt.Indicator marginV>
                                        {handleMembers.loading
                                            ? tr("Loading...")
                                            : "You need a trainer, assign one now!"}
                                    </Txt.Indicator>
                                </>
                            )}
                            keyExtractor={(item) => item._id}
                        />
                    </A.CardContainer>
                </View>
            );
        } catch (error) {
            throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
        }
    }, [members, listOffset, C, shareData]);
}

const A = {
    CardContainer: sstyled(Kitten.Card)({
        minWidth: 300,
    }),
};

interface P {
    onSelect: (user: UserSchema) => void;
    customIcon?: ICON_NAME;
}
