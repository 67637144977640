import * as Contacts from "expo-contacts";
import { ListItemProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Kitten, Txt } from "components";
import { Avt, Buttoon, Menoo, Poppy, Toasty } from "components/atoms";
import { fn, FPATH, useAppContext } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { useContactGroupDocument } from "engines/firebase/handler/contact-groups-handler";
import { useContactDocument } from "engines/firebase/handler/contact-handler";
import { dateFormat } from "engines/functions/calendar-functions";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import { PhoneNumber } from "expo-contacts";
import firebase from "firebase";
import { uniqueId } from "lodash";
import React from "react";
import { useNavigator } from "screens";
import { scale, tr } from "utils";
import { fuego } from "../../../../fuego";
import { spacing } from "../../../utils/styles/spacing";
import { ContactListItemDetails } from "./ContactListItemDetails";
import { MoveToCGList } from "./MoveToCGList";

/**
 * ### List Item for Member
 *  - Including some member's action
 *  ----
 *  @version 21.03.20
 *  -  *Move this component to ./molecules*
 *  @author  K, Nl
 *
 **/
export function ContactListItem(props: dContactListItem) {
  const {
    contact,
    type = "existing",
    // contactAddHandler,
    groupId,
    listUserId,
    onBlastTextStart,
  } = props;
  const { frbsUser } = useAppContext();
  const { C } = useAppContext();
  const Navigation = useNavigator();

  // const [priorityLevel, setPriorityLevel] = React.useState<number>(1);
  // const refItemSwipeable = React.useRef<Swipeable>();
  const { getPointers } = useBMLMContext();

  // console.log(`conastct is ${JSON.stringify(contact)} in CLI.tsx`);

  const contactHandler = useContactDocument(listUserId, contact?._cid);
  const contactGroupHandler = useContactGroupDocument(listUserId, groupId);
  const [formattedContact, setFormattedContact] =
    React.useState<ContactSchemaa>(null);

  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  const endOfYesterday = new Date();
  endOfYesterday.setTime(endOfToday.getTime() - 86400 * 1000);
  const needsFollowUp =
    contact.followUpDate?.toDate &&
    contact?.followUpDate?.toDate()?.getTime() <= endOfToday.getTime();
  const isLateFollowUp =
    contact?.followUpDate?.toDate()?.getTime() < endOfYesterday.getTime();
  const upcomingFollowUp = contact?.followUpDate && !needsFollowUp;

  const [phoneContact, setPhoneContact] =
    React.useState<Contacts.Contact>(null);

  React.useEffect(() => {
    // console.log(`contactHandler.data?._cid is ${contactHandler.data?._cid}`);
    if (contactHandler.data?._cid) {
      const contact = contactHandler.data;

      // set phone contact if found on device (great for contact image)
      if (contact.id) {
        Contacts.getContactByIdAsync(contact.id)
          .then((phoneContact) => phoneContact && setPhoneContact(phoneContact))
          .catch((err) => {
            console.error(err);
          });
      }

      if (
        contact.phoneNumbers?.length > 0 &&
        typeof contact.phoneNumbers[0] === "string"
      ) {
        const newContact: ContactSchemaa = {
          _cid: contact._cid,
          displayName: contact?.name?.replace("undefined", "") || "",
          name: contact?.name || "",
          phoneNumbers: [],
          _phoneNumberStrings: [],
          emails: [],
          listId: groupId,
          groups: contact?.groups || [groupId],
          notes: contact?.notes || [],
          qualifications: contact?.qualifications || [],
          ownerUid: contact.ownerUid || frbsUser.uid,
        };
        if (contact?.phoneNumbers?.length > 0) {
          const newNumbers = contact.phoneNumbers.map((pn) => {
            if (typeof pn === "string") {
              const newPhoneNumber: PhoneNumber = {
                digits: pn ? pn.replace("-", "") : "",
                id: pn ? pn : uniqueId(),
                label: "mobile",
                number: pn ? pn : "",
              };
              return newPhoneNumber;
            } else {
              return pn;
            }
          });
          newContact.phoneNumbers = newNumbers;
          newContact._phoneNumberStrings = newNumbers.map((nn) => nn.number);
        }
        setFormattedContact(newContact);
      } else {
        setFormattedContact(contact);
      }
    }
  }, [contactHandler.data]);
  // function onSetPriority() { Khoi please is this function still useful
  //   if (priorityLevel >= 5) {
  //     setPriorityLevel(1);
  //   } else {
  //     setPriorityLevel(priorityLevel + 1);
  //   }
  // }

  //#region [functions] delete member from group
  function onRemove() {
    Poppy(
      "Are you sure you want to remove this contact?",
      "This contact will only be removed from this list.",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: async () => {
          const __toasty = Toasty.show("Removing contact...", {
            type: "loading",
          });
          try {
            const _cid = `${contact._cid?.length > 0 ? contact._cid : contact.id}`
            const contactDocToDelete = (await firebase.firestore().collection(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}`).where("_cid", "==", _cid).get())?.docs?.pop()
            if (!contactDocToDelete) {
              Poppy("Remove Failed", `Contact with id ${contactDocToDelete.id} could not be located in the system.`)
              return;
            }
            await contactGroupHandler.update({
              contacts: firebase.firestore.FieldValue.arrayRemove(
                ...[contactDocToDelete.id, contactDocToDelete.data()?._cid]
              ),
            });
            await fuego.db
              .doc(
                `${FPATH.USERS}/${frbsUser.uid}/${FPATH.CONTACTS}/${contactDocToDelete.id}`
              )
              .update({
                groups: firebase.firestore.FieldValue.arrayRemove(
                  contactGroupHandler.data?._id || contactGroupHandler.data?.id
                ),
                listId: "",
              });

            // }
            Toasty.update(__toasty, "Contact removed", {
              type: "normal",
            });
          } catch (err) {
            Poppy("Failed to remove contact", err)
          }
        },
      }
    );
  }
  function onDelete() {
    Poppy(
      "Are you sure you want to delete this contact?",
      "This contact will be deleted forever.",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: async () => {
          const __toasty = Toasty.show("Deleting contact...", {
            type: "loading",
          });
          try {
            const _cid = `${contact._cid?.length > 0 ? contact._cid : contact.id}`
            const contactDocToDelete = (await firebase.firestore().collection(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}`).where("_cid", "==", _cid).get())?.docs?.pop()
            if (!contactDocToDelete || !contactDocToDelete.data) {
              Poppy("Delete Failed", `Contact with id ${contactDocToDelete.id} could not be located in the system.`)
              return;
            }
            const contactBeingDeleted = contactDocToDelete.data() as ContactSchemaa
            // remove contact id from every group that has it somewhere
            for (let g in contactBeingDeleted?.groups) {
              const _group = contactBeingDeleted[g]
              fuego.db.doc(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACT_GROUPS}/${_group}`).update({
                contacts: firebase.firestore.FieldValue.arrayRemove(
                  ...[contactDocToDelete.id, contactBeingDeleted?._cid]
                )
              })
            }
            // delete the actual contact document
            await fuego.db
              .doc(
                `${FPATH.USERS}/${frbsUser.uid}/${FPATH.CONTACTS}/${contactDocToDelete.id}`
              )
              .delete()

            Toasty.update(__toasty, "Contact deleted", {
              type: "normal",
            });
          } catch (err) {
            Poppy("Failed to delete contact", err)
          }
        },
      }
    );
  }
  //#endregion

  //#region [functions] add contact
  /**
   * this adds what ever user that is clicked
   */
  function addContact() {
    /**
     * This checks if the member has been added previously to any other group
     * if(true)
     * it checks if the emails align then updates the groups array with the current screen group
     */
    fuego.db
      .doc(`${FPATH.USERS}/${frbsUser.uid}/${FPATH.CONTACTS}/${contact._cid}`)
      .get()
      .then((res) => {
        if (res?.data()) {
          if (res?.data()?.email === contact?.email) {
            fuego.db
              .doc(
                `${FPATH.USERS}/${frbsUser.uid}/${FPATH.CONTACTS}/${contact._cid}`
              )
              .update({
                groups: firebase.firestore.FieldValue.arrayUnion(groupId),
              })
              .then((res) => {
                contactGroupHandler.update({
                  contacts: firebase.firestore.FieldValue.arrayUnion(
                    `${contact._cid}`
                  ),
                });
                Toasty.show(tr("Contact has been added"), {
                  type: "success",
                });
              })
              .catch(() => {
                Toasty.show(tr("Failed to add contact"), {
                  type: "danger",
                });
              });
          }
        } else {
          fuego.db
            .doc(
              `${FPATH.USERS}/${frbsUser.uid}/${FPATH.CONTACTS}/${contact._cid}`
            )
            .set({
              _cid: `${contact._cid}`,
              ownerUid: frbsUser?.uid,
              displayName: contact.displayName?.replace("undefined", ""),
              email: contact.email,
              emails: [{}],
              phoneNumbers: contact.phoneNumbers
                ? firebase.firestore.FieldValue.arrayUnion(contact.phoneNumbers)
                : [],
              _phoneNumberStrings: firebase.firestore.FieldValue.arrayUnion(
                ((contact.phoneNumbers || []) as Contacts.PhoneNumber[])?.map(
                  (pn) => pn.digits || pn.number?.replace(/\D/g, "")
                )
              ),
              // pointer: contact.pointer ? contact.pointer : 5,
              groups: firebase.firestore.FieldValue.arrayUnion(groupId),
              groupType: "email-groups",
              qualifications: [],
              notes: [],
              image: contact.image,
              imageAvailable: contact.imageAvailable,
            })
            .then(() => {
              contactGroupHandler.update({
                contacts: firebase.firestore.FieldValue.arrayUnion(
                  `${contact._cid}`
                ),
              });
              Toasty.show(tr("Contact has been added"), {
                type: "success",
              });
            })
            .catch(() => {
              Toasty.show(tr("Failed to add contact"), {
                type: "danger",
              });
            });
        }
      });
  }
  //#endregion

  // #region [ANCHOR2] Moving Contact to another list
  const onContactItemMove = () => {
    Navigation.overlayPortal.open({
      headerTitle: tr("Move to list"),
      render: (
        <MoveToCGList
          userId={frbsUser?.uid}
          currentListId={groupId}
          contact={contact}
        />
      ),
    });
  };
  // #endregion

  function ActionSection() {
    switch (type) {
      case "existing":
        return (
          <Kitten.ViewH
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <IconPrimr
              //@ts-ignore
              name={`num${getPointers(contact.qualifications) > 0
                ? getPointers(contact.qualifications)
                : ""
                }`}
              size={40}
              color={
                contact.qualifications
                  ? contact.qualifications.length > 3
                    ? C["color-danger-600"]
                    : contact.qualifications?.length === 3
                      ? C["color-warning-600"]
                      : C["color-basic-600"]
                  : C["color-basic-600"]
              }
            />
            {listUserId === frbsUser?.uid && (
              <Menoo
                trigger={{
                  name: "dots_vertical",
                  size: scale(16),
                  preset: "circular",
                  color: C.text,
                }}
                options={[
                  {
                    text: tr("Move to another group"),
                    onSelect: () => {
                      onContactItemMove();
                    },
                  },
                  {
                    text: tr("Start Blast Text"),
                    onSelect: () => {
                      onBlastTextStart && onBlastTextStart(contact);
                    },
                  },
                  // { text: "__________" },
                  {
                    text: tr("Remove from list"),
                    onSelect: () => {
                      onRemove();
                    },
                    color: C.infoBlue,
                  },
                  {
                    text: tr("Delete Everywhere"),
                    onSelect: () => {
                      onDelete();
                    },
                    color: C.errorRed
                  }
                ]}
              />
            )}
          </Kitten.ViewH>
        );
        return;
        break;
      case "newbie":
        return (
          <Kitten.ViewH
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Buttoon
              size="tiny"
              appearance="ghost"
              onPress={() => {
                addContact();
              }}
            >
              {"Add"}
            </Buttoon>
          </Kitten.ViewH>
        );
        return;
        break;

      default:
        break;
    }
  }

  let color = isLateFollowUp ? C.errorRed : C.adminGold;
  if (upcomingFollowUp) color = C.text;

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  // may be null if no video watch has been sent to them but not yet watched
  const unwatchedVideo = contact?.videoWatches?.find((vw) => vw.watched < 1);
  const unfinishedButStartedVideo = contact?.videoWatches?.find(vw => vw.watched < 1 && vw.startedAt)
  // format the progressMillis to display like MM:SS
  let progressMinutes: number, progressSeconds: number;
  if (unfinishedButStartedVideo && unfinishedButStartedVideo.progressMillis) {
    progressMinutes = Math.floor(unfinishedButStartedVideo?.progressMillis % 3.6e6 / 6e4)
    progressSeconds = Math.floor(unfinishedButStartedVideo?.progressMillis % 6e4 / 1e3);
  }
  // may be null if no video watch exists that has been watched
  const lastWatchedVideo = contact?.videoWatches
    ?.reverse()
    ?.find((vw) => vw.watched === 1);
  // date string of when the sent video was watched
  const watchedDateStr = fn.calendar.niceDateFormat(
    lastWatchedVideo?.watchedAt?.toDate()
  );
  // date string of when the video was sent to the contact
  const sentAtStr = fn.calendar.niceDateFormat(
    unwatchedVideo?.sentAt?.toDate()
  );

  return (
    <>
      <Kitten.ListItemv2
        title={() => (
          <Kitten.ViewH>
            <Txt.S2
              style={
                !contact?.displayName &&
                !contact?.name && {
                  borderWidth: 2,
                  borderRadius: scale(4),
                  borderColor: C.hazardYellow,
                }
              }
            >
              {contact?.name ||
                contact?.displayName?.replace("undefined", "") ||
                "                     "}
            </Txt.S2>
          </Kitten.ViewH>
        )} //*{fakerStatic.name.findName()}
        description={
          () => (
            <>
              {unwatchedVideo || lastWatchedVideo ? (
                <Txt.P2
                  style={{
                    ...(unwatchedVideo
                      ? {
                        color: C.text,
                        textDecorationLine: "underline",
                      }
                      : {
                        color: C.primary,
                        textDecorationLine: "underline",
                      }),
                  }}
                >
                  {unwatchedVideo
                    ? unfinishedButStartedVideo ? `🎥 Started watching ${unfinishedButStartedVideo.title || "Video"} (${progressMinutes}m ${progressSeconds}s)`
                      : `🎥 ${unwatchedVideo.title || "Video"} sent ${sentAtStr ? sentAtStr : ""
                      }`
                    : `🎥 ${lastWatchedVideo.title || "Video"} watched ${watchedDateStr ? watchedDateStr : ""
                    }`}
                </Txt.P2>
              ) : null}

              {needsFollowUp || upcomingFollowUp ? (
                <Txt.P2 style={{ color }}>
                  {`${needsFollowUp && isLateFollowUp
                    ? `Overdue follow up ${fn.calendar
                      .forsureDate(contact.followUpDate)
                      ?.toLocaleDateString()}`
                    : needsFollowUp
                      ? "Needs follow up today "
                      : ""
                    }`}
                </Txt.P2>
              ) : null}

              {contact?.appointments &&
                contact.appointments?.filter(
                  (apt) =>
                    fn.calendar.forsureDate(apt.event?.date) >= startOfToday
                )?.length > 0 ? (
                <Txt.P2 style={{ color: C.infoBlue }}>
                  {`Upcoming appointment ${fn.calendar
                    .forsureDate(
                      contact.appointments[contact.appointments?.length - 1]
                        ?.event?.date
                    )
                    ?.toLocaleDateString()}`}
                </Txt.P2>
              ) : (!contact?.messageHistory ||
                contact?.messageHistory?.length < 1) &&
                !contact?.videoWatches?.find((vw) => vw.watched === 1) ? (
                <Txt.P2 style={{ color: C.hazardYellow }}>{"Unreached"}</Txt.P2>
              ) : contact?.messageHistory?.filter(
                (mh) => mh.type !== "trackable-video"
              )?.length > 0 ? (
                <Txt.P2>
                  {`Last messaged ${contact.messageHistory[
                    contact.messageHistory?.length - 1
                  ]?.date
                    ?.toDate()
                    ?.toLocaleDateString()}`}
                </Txt.P2>
              ) : null}
            </>
          )
          // : `${contact?.email || contact?.emails?.length > 0
          //   ? contact?.email || contact?.emails?.pop()?.email + " • "
          //   : ""
          // } ${contact?.phoneNumbers?.length > 0
          //   ? contact?.phoneNumbers[0]?.number || "Invalid number"
          //   : "No number"
          // }` || ""
        }
        onPress={() => {
          !Object.keys(contact).includes("uid") &&
            Navigation.overlayPortal.open({
              render: (
                <ContactListItemDetails
                  contact={formattedContact || contact}
                  userId={listUserId}
                  listId={groupId}
                  listName={contactGroupHandler.data?.name}
                />
              ),
              headerTitle: `Contact Details`,
            });
        }}
        accessoryLeft={() => (
          <Avt
            name={
              contact?.name ||
              contact?.displayName?.replace("undefined", "") ||
              contact?.email
            }
            source={{
              uri:
                phoneContact &&
                  phoneContact.imageAvailable &&
                  phoneContact.image
                  ? phoneContact?.image?.uri
                  : "",
            }}
          />
        )}
        accessoryRight={ActionSection}
        {...props}
      />
    </>
  );
}

export interface dContactListItem extends ListItemProps {
  contact: ContactSchemaa;
  groupId: string;
  /**@description userId of the user who's contact list contacts are about to be viewed*/
  listUserId: string;
  /**
   * "existing": display value of contact existed in the contact group + some editing functionalities
   * "newbie": display value of member in ContactCreatorCenter + a button to add to the contact group
   */
  type?: "existing" | "newbie";
  contactAddHandler?: any;
  onBlastTextStart?: (contact: ContactSchemaa) => void;
}
