import { useFocusEffect } from "@react-navigation/native";
import { ContactListItem, Poppy, sstyled, Txt } from "components";
import {
  Buttoon,
  Kitten,
  ListHeader,
  Skeleton,
  Toasty
} from "components/atoms";
import { ContactListItemDetails } from "components/molecules/members/ContactListItemDetails";
import { ACTION_TYPE, DetailContact, FPATH, recordUnknownUserAction, recordUserAction, useAppContext, UserSchema } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { useContactGroupDocument } from "engines/firebase/handler/contact-groups-handler";
import { useContactsCollection } from "engines/firebase/handler/contact-handler";
import { useMembersCollection } from "engines/firebase/handler/members-handler";
import { requestContactPermissions } from "engines/functions/permission-functions";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import * as Contacts from "expo-contacts";
import firebase from "firebase";
import { uniqueId } from "lodash";
import * as R from "ramda";
import React from "react";
import { Alert, Platform, TextInput, View } from "react-native";
import * as RNContacts from "react-native-contacts";
import { useNavigator } from "screens/_navigation";
import { IS_WEB, scale, spacing, tr } from "utils";
import { useContactGroupsCollection } from "../../../engines/firebase/handler/contact-groups-handler";
/**
 * ###
 *  - This component is used in creating a contact
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.11.29
 *  -  *Brief changelog*
 *  @author  Your Name
 *
 **/
export function AddContactCreator(props: P) {
  const { C, teamId } = useAppContext();
  const { frbsUser } = useAppContext();
  const { id } = props;
  const Navigation = useNavigator();
  const contactGroupHandler = useContactGroupDocument(frbsUser?.uid, id);
  const allContactGroupsHandler = useContactGroupsCollection(frbsUser?.uid);
  const [selectedContactGroupIndex, setSelectedContactGroupIndex] =
    React.useState<number>(0);
  const { qualificationData } = useBMLMContext();

  //#region [FRBS]

  const handleMembers = useMembersCollection({
    where: ["_teamId", "==", teamId],
  });
  const [contacts, setContacts] = React.useState<UserSchema[]>([]);
  /**
   * This is used to get members list that could be added as contacts
   */
  React.useEffect(
    function setupData() {
      handleMembers.data && setContacts(handleMembers.data);
    },
    [handleMembers.data]
  );
  // const [listOffset, setListOffset] = React.useState(30);
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone_number, setPhone_number] = React.useState<string>("");
  const [showMember, setShowMember] = React.useState<boolean>(false);
  const [contactQualifications, setContactQualifications] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function addContact(xong) {
    setLoading(true);
    const contactDispName = firstName + (lastName ? " " + lastName : "");
    const _groups =
      id && id !== "id" && id.length > 0
        ? [id]
        : allContactGroupsHandler.data?.length > 0
          ? [allContactGroupsHandler.data[selectedContactGroupIndex]._id]
          : [];
    if (_groups?.length < 1) {
      Alert.alert(
        "Uh oh",
        "We need to know what contact group to add this contact to first."
      );
      return;
    }
    const newContact: ContactSchemaa = {
      //@ts-ignore
      createdAt: new Date(),
      displayName: contactDispName,
      name: contactDispName,
      ownerUid: frbsUser?.uid,
      // deprecated
      email: email,
      emails: [{ email, id: email, label: "Primary" }],
      //@ts-ignore
      phoneNumbers: firebase.firestore.FieldValue.arrayUnion({
        number: phone_number,
        id: uniqueId(),
        label: "Mobile",
        digits: phone_number,
        isPrimary: true,
      } as Contacts.PhoneNumber),
      _phoneNumberStrings: firebase.firestore.FieldValue.arrayUnion(phone_number),
      // pointer: 5,
      //@ts-ignore
      groups: _groups,
      // pretty sure this is irrelevant?
      groupType: contactGroupHandler.data?.groupType
        ? contactGroupHandler.data?.groupType
        : "email-groups",
      //NOTE added this for BML List to work
      qualifications: contactQualifications,
      //NOTE added this for BML List to work
      listId: id,
      // followUpDate: new Date(), //TODO Need accurate value
    };

    function openContactView(contactId: string, recordID?: string) {
      firebase
        .firestore()
        .doc(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}/${contactId}`)
        .get()
        .then((doc) => {
          const contact = doc.data() as ContactSchemaa;
          Navigation.overlayPortal.open({
            headerTitle: "Contact Details",
            render: (
              <ContactListItemDetails
                userId={frbsUser?.uid}
                contact={recordID ? { ...contact, id: recordID } : contact}
              />
            ),
          });
        })
        .catch((err) => {
          console.error(err)
          Toasty.show("**" + err, { type: "danger" })
        });
    }

    contactHandler
      .add(newContact)
      .then((res) => {
        contactGroupHandler.update({
          contacts: firebase.firestore.FieldValue.arrayUnion(`${res}`),
        });
        IS_WEB ? clearState() : null;

        recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.ADD_CONTACT, `${frbsUser?.uid} created a new contact: ${newContact.name}`, { location: res, involvedPartyName: newContact.name })

        IS_WEB
          ? openContactView(res)
          : addContactMobile(xong, (recordID) => {
            openContactView(res, recordID);

            firebase
              .firestore()
              .doc(
                `${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}/${res || recordID}`
              )
              .set({ id: recordID || res }, { merge: true }).then(() => {
                console.log("updated contact id successfully")
              }).catch(err => {
                Toasty.show("*" + err, { type: "danger" })
              })
          });

        Toasty.show(tr("Contact added."), {
          type: "success",
        });
      })
      .catch((e) => {
        clearState();
        Poppy(`Failed to add contact`, e)
        // Toasty.show(tr("Failed to add contact"), {
        //   type: "danger",
        // });
        console.log("err for contact ", e);
        setLoading(false);
        xong && xong();
      });
  }

  const getUniqueId = () => {
    return Math.random().toString(36).slice(2);
  };

  async function addContactMobile(
    xong,
    onAddMobileContact?: (recordId: string) => void
  ) {
    let emails: Contacts.Email = {
      id: getUniqueId(),
      email: email,
      label: "email",
    };
    let phoneNos: Contacts.PhoneNumber = {
      id: getUniqueId(),
      label: "contact",
      number: phone_number,
    };
    let contact: Contacts.Contact = {
      id: getUniqueId(),
      name: firstName,
      firstName: firstName,
      lastName: lastName ? lastName : "",
      emails: [emails],
      phoneNumbers: [phoneNos],
      contactType: Contacts.ContactTypes.Person,
    };

    if (Platform.OS === "android") {
      requestContactPermissions();
      RNContacts.addContact({
        displayName: firstName + (lastName ? " " + lastName : ""),
        givenName: firstName,
        familyName: lastName || "",
        emailAddresses: [emails],
        phoneNumbers: [phoneNos],
      })
        .then((res) => {
          console.log("contactId ", res);

          Toasty.show(tr("Contact has been added to phone contacts"), {
            type: "success",
          });
          onAddMobileContact && onAddMobileContact(res?.recordID);
          setLoading(false);
          xong && xong();
          clearState();
        })
        .catch((err) => {
          // alert(e)
          Toasty.show(tr("Failed to add contact to phone contacts"), {
            type: "danger",
          });
          console.log("error ", err);
          setLoading(false);
          xong && xong();
        });
    } else {
      Contacts.addContactAsync(contact)
        .then((res) => {
          console.log("contactId ", res);
          Toasty.show(tr("Contact added to phone contacts"), {
            type: "success",
          });
          setLoading(false);
          onAddMobileContact && onAddMobileContact(res);
          xong && xong();
          clearState();
        })
        .catch((e) => {
          // alert(e)
          Toasty.show(tr("Failed to add contact to phone contacts"), {
            type: "danger",
          });
          console.log("error ", e);
          setLoading(false);
          xong && xong();
        });
    }
  }

  function clearState() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone_number("");
    setContactQualifications([]);
    Navigation.goBack();
  }

  //#region [section2] contact section
  const contactHandler = useContactsCollection(frbsUser?.uid, id);
  let _existingMemberIds = contactHandler.data
    ? R.pluck("id", contactHandler.data)
    : [];

  //#endregion
  const handleQualifiyBtnPress = (id: string) => {
    let newValue = [];
    if (!contactQualifications.includes(id)) {
      newValue = [...contactQualifications, id];
      setContactQualifications(newValue);
    } else {
      newValue = contactQualifications.filter((cq) => cq !== id);
      setContactQualifications(newValue);
    }
  };

  // return React.useMemo(() => {
  try {
    if (handleMembers.error) {
      console.warn("error in ContactsFeed: ", handleMembers.error);
      return <Txt.Indicator>{tr("Error getting contacts feed")}</Txt.Indicator>;
    }
    if (!handleMembers.data) return <Skeleton.Screen.Members length={7} />;

    let _members = handleMembers.data;

    //#region [section] search ops
    function filterHandler(value: string) {
      setQuery(value);
      const newData = _members.filter((member) => {
        // console.log("member: ", member.personali);
        return member.personali?.displayName
          ?.toLowerCase()
          .includes(value.toLowerCase());
      });
      // console.log("valuee: ", newData);
      setContacts([...newData]);
    }
    //#endregion
    let sortedContacts = R.sortWith<UserSchema>(
      [
        /** sorting `displayName` in `desc` */
        R.descend(R.prop("_id")),
      ],
      contacts
    );
    return (
      <A.ViewContainer>
        {showMember ? (
          <>
            <ListHeader
              titleTx={
                (query ? tr("Search result") : tr("Contacts")) +
                " • " +
                sortedContacts?.length
              }
            />
            <Kitten.Input
              value={query}
              placeholder={tr("Filter by contact's name")}
              onChangeText={(value) => {
                filterHandler(value);
              }}
            />

            {sortedContacts
              .slice(0, 6)
              .filter((member) => !_existingMemberIds.includes(member._id))
              .map((member, index) => {
                let contact: DetailContact = {
                  displayName: member.personali?.displayName,
                  email: member.personali?.email,
                  phoneNumber: member.personali?.phoneNumber,
                  uid: member._id,
                  qualifications: [],
                };
                return (
                  <ContactListItem
                    type={"newbie"}
                    key={index}
                    //@ts-expect-error
                    contact={contact}
                    contactAddHandler={contactHandler}
                    groupId={id}
                  />
                );
              })}
          </>
        ) : (
          <>
            <ListHeader titleTx={tr("Add a contact")} />
            <A.Input
              placeholder={tr("First Name *")}
              value={firstName}
              onChangeText={setFirstName}
              borderColor={"primary"}
              placeholderTextColor={C["text-hint-color"]}
            />
            <A.Input
              placeholder={tr("Last Name")}
              value={lastName}
              onChangeText={setLastName}
              borderColor={"primary"}
              placeholderTextColor={C["text-hint-color"]}
            />
            <A.Input
              placeholder={tr("Email")}
              value={email}
              onChangeText={setEmail}
              borderColor={"primary"}
              keyboardType={"email-address"}
              placeholderTextColor={C["text-hint-color"]}
            />
            <A.Input
              placeholder={tr("Phone number")}
              value={phone_number}
              keyboardType={"number-pad"}
              onChangeText={setPhone_number}
              borderColor={"primary"}
              placeholderTextColor={C["text-hint-color"]}
            />

            <View
              style={{
                justifyContent: "space-evenly",
                flexDirection: "row",
                marginVertical: 5,
              }}
            >
              {qualificationData.map((qualification) => (
                <Kitten.Button
                  key={qualification._id}
                  size={"tiny"}
                  appearance={
                    contactQualifications.includes(qualification._id)
                      ? "filled"
                      : "outline"
                  }
                  onPress={() => handleQualifiyBtnPress(qualification._id)}
                >
                  {qualification.name}
                </Kitten.Button>
              ))}
            </View>

            <Kitten.Divider_________ />

            {/* <Kitten.Accordion
              data={[{ title: "Item 1" },{title: "item 2"}]}
              renderItem={(item) => <Kitten.ListItemv2 title={item.item.title} />}
            /> */}
            {allContactGroupsHandler.data &&
              (!id || id.length < 1 || id === "id") ? (
              <>
                <Txt.Helper style={{ marginBottom: spacing(1) }}>
                  What group to add this contact to.
                </Txt.Helper>
                <Kitten.Select
                  style={{ marginBottom: spacing(3) }}
                  value={
                    allContactGroupsHandler.data.length > 0
                      ? allContactGroupsHandler.data[selectedContactGroupIndex]
                        ?.name
                      : "New Contact Group"
                  }
                >
                  {allContactGroupsHandler.data?.map((cgroup, idx) => (
                    <Kitten.ListItemv2
                      title={cgroup.name}
                      onPress={() => setSelectedContactGroupIndex(idx)}
                    />
                  ))}
                </Kitten.Select>
              </>
            ) : null}
            <Buttoon
              appearance={"filled"}
              onPress={(xong) => {
                addContact(xong);
              }}
              progress
              // disabled={!firstName || !lastName || phone_number?.length !== 10}
              disabled={!firstName}
            >
              {tr("Add contact")}
            </Buttoon>
            {!IS_WEB ? (
              <Txt.Helper style={{ marginTop: spacing(3) }}>
                This contact will be added to your phone's contacts and the
                mobile app.
              </Txt.Helper>
            ) : null}
          </>
        )}

        {/* <Kitten.Divider_________ />
        <Buttoon
          onPress={() => setShowMember(!showMember)}
          appearance={"ghost"}
          icon={{ name: showMember ? "plus" : "arrow_right" }}
        >
          {showMember
            ? tr("Or add a new contact")
            : tr("Or select a member from hierarchy")}
        </Buttoon> */}
        {/* <Kitten.Divider_________ /> */}
        {/* <Buttoon
          onPress={() => {
            Navigation.navigate("contacts-search", {
              id: id,
            });
          }}
          disabled={IS_WEB ? true : false}
          appearance={"ghost"}
          icon={{ name: showMember ? "plus" : "arrow_right" }}
        >
          {tr("Or select a member from phone contacts")}
        </Buttoon> */}
      </A.ViewContainer>
    );
  } catch (error) {
    throw Error(`::QWRkQ29udGFjdENyZWF0b3I=::${error}`);
  }
  // }, [contacts, C]);
}

const A = {
  ViewContainer: sstyled(View)({
    marginVertical: 3,
    // minWidth: 300,
  }),
  Input: sstyled(TextInput)(({ borderColor = "primary" }) => ({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: borderColor,
  })),
};

interface P {
  id: string;
}
