import { dTask, FPATH } from "engines";
import firebase from "firebase";
import { useCollectionPresetv2 } from "../config";

/**
 * Tasks handler for user sub collection
 */
export function useUserTasks(userId: string, query?) {
  const collectionPath = `${FPATH.USERS}/${userId}/${FPATH.TASKS}`;
  const defaultCollectionFunctions = useCollectionPresetv2<dTask>(
    collectionPath,
    "_id",
    {
      listen: true,
      orderBy: [["completed", "asc"]],
      ...query,
    }
  );

  /** returns the id of the task after it's created */
  async function addTask(task: dTask): Promise<string> {
    const res = await firebase.firestore().collection(collectionPath).add(task);
    await firebase
      .firestore()
      .doc(`${collectionPath}/${res.id}`)
      .update({ _id: res.id });
    return res.id
  }

  function completeTask(task: dTask) {
    return firebase
      .firestore()
      .doc(`${collectionPath}/${task._id}`)
      .update({ completed: true });
  }
  function uncompleteTask(task: dTask) {
    return firebase
      .firestore()
      .doc(`${collectionPath}/${task._id}`)
      .update({ completed: false });
  }
  function updateTask(task: dTask) {
    return firebase
      .firestore()
      .doc(`${collectionPath}/${task._id}`)
      .update(task);
  }

  function getIncompleteTasks() {
    return defaultCollectionFunctions.data?.filter((t) => !t.completed);
  }
  function getCompleteTasks() {
    return defaultCollectionFunctions.data?.filter((t) => t.completed);
  }
  /**
   * Also includes previously missed tasks
   */
  function getTasksDueToday() {
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
    return defaultCollectionFunctions.data?.filter(
      (t) => !t.dueAt?.toDate().getTime() >= endOfToday.getTime()
    );
  }

  return {
    ...defaultCollectionFunctions,
    addTask,
    completeTask,
    uncompleteTask,
    updateTask,
    getIncompleteTasks,
    getCompleteTasks,
    getTasksDueToday,
  };
}
