import { dVimeoToken } from "engines";
import firebase from "firebase";
export interface MediaPageItemSchema {
  _id?: string;
  /** the last known parent page id for this item (may not exist) */
  _parentPageId?: string;
  _teamId?: string;
  /**
   * The title of this item!
   */
  title?: string;
  /**
   * Show some longer paragraph text on the screen
   */
  paragraph?: string;
  /**
   * A link to either a video or audio file on a supported service like an integrated Vimeo account, Cloudinary, or Youtube
   */
  media?: string;
  /** If this item is a checkbox item */
  checkbox?: boolean;
  /** Hide the share button so it's more secure */
  disableShare?: boolean;
  /**
   * To open a web page in the app (or outside of the app)
   * - use either a web url, or this format: `${URL_PREFIX}${URL_DESTINATION}"
   */
  url?: string;
  /**
   * Optionally set a specific thumbnail image for the resource
   * - probably pertaining to a video resource
   */
  thumbnail?: string;
  /**
   * @deprecated use url field now
   * If value is provided, this item will be a button that opens another MediaPage that exists in the app
   */
  topage?: string;
  /**
   * Order in which this item is displayed on the page
   * For example, an item with position -3 will show up before one with position 1
   */
  position: number;
  /**
   * Not yet being used (not in primerica-template currently)
   */
  createdAt: firebase.firestore.FieldValue;
  /**
   * Vimeo token that we know works for the Vimeo video
   */
  vimeoToken?: dVimeoToken;
  /**
   * Dropbox token that we know works for the Dropbox audio
   */
  dropboxToken?: string;
  /**
   * This indicates if the item is shared on Content Multiverse (default is false)
   */
  isContentPrivate?: boolean;
  /**
   * A default text template for quick grab on contacts
   */
  textTemplate?: {
    /** if enabled, this will match the current teamId */
    enabledForTeamId: string;
    parentPageId: string;
    parentPageName: string;
    description?: string;
  },
  /** when shared, will provide a trackable url, if enabled this video will be accessible by anyone not as secure */
  trackable?: boolean,
  pickTrainer?: {
    required: boolean
  }
}

/**
 * A non web url destination for a button
 *
 * @example
 * url: `${URL_PREFIX.PAGE}${pageId}`
 */
export enum URL_PREFIX {
  /**
   * Goes to a specific page id
   */
  PAGE = "page:",
  /**
   * Goes to a specific page id but causes the button to show the level progress of the page
   */
  LEVEL = "level:",
  /**
   * Opens a specific feature in the app like Build My List
   * - Use URL_DESTINATION
   * @example
   * `${URL_PREFIX.PAGE}${pageId}`
   */
  SHORTCUT = "shortcut:",
  /**
   * To specific new page in LinkTo <>
   */
  NEWPAGE = "newpage",
  /**
   * A user's uid
   */
  USER = "uid:",
  /**
   * A contacts _cid
   */
  CONTACT = "contact:"
}

/**
 * The destination of a url for a button
 * - Be sure to use URL_PREFIX.SHORTCUT first
 */
export enum URL_DESTINATION {
  BUILD_MY_LIST = "BuildMyList",
  SELECT_BASE_SHOP = "SelectBaseShop",
}

export const _DEFAULT_MEDIA_ITEM: MediaPageItemSchema = {
  _id: "",
  // _parentPageId: "",
  _teamId: "",
  title: "",
  paragraph: "",
  media: "",
  url: "",
  topage: "",
  position: 0,
  createdAt: null,
  checkbox: false,
  isContentPrivate: true,
};
