import { dCalendarEvent, dContentView, dQualification, UserSchema } from "engines";
import * as Contacts from "expo-contacts";
import firebase from "firebase";

export type MSG_TYPE = "sms" | "call" | "email" | "clipboard" | "trackable-video"

/** a text message that was sent out */
export interface dMessageRecord {
  type: MSG_TYPE,
  date: firebase.firestore.Timestamp;
  notes?: string,
  message?: string,
  itemId?: string,
  groupText?: { uid?: string, phoneNumber: string }
}

/**
 * @ts-ignore allows FieldValue as entry
 * TODO: Use for all contacts
 */
//@ts-ignore
export interface ContactSchemaa extends Partial<Contacts.Contact> {
  /** not all contacts have this but this is when the contact got added for the first time */
  createdAt?: firebase.firestore.Timestamp;
  /**
   * Used with firestore where as .id is related to the id in their personal phone
   */
  _cid: string;
  /**
   * The uid of the user who created this contact/has ownership over it
   * - Great for use with collectionGroup
   * - Because this is a newer field (2/21/23), this may not exist in all contacts so be careful, another refactor may need to be done
   */
  ownerUid: string;
  /**
   * TODO: deprecate in the future, but currently this is the only name in firestore so it's necessary
   */
  displayName?: string;

  /**
   * Name of the contact (old contacts may not have this until refactor is complete so include .displayName too for now)
   */
  name?: string;

  /**
   * TODO: deprecate in the future and use .emails instead
   */
  email?: string;

  phoneNumbers: Partial<Contacts.PhoneNumber[]> | firebase.firestore.FieldValue;
  /** for query searching */
  _phoneNumberStrings: string[] | firebase.firestore.FieldValue,
  emails: Partial<Contacts.Email[]> | firebase.firestore.FieldValue;
  /**
   * When to be reminded to follow up with this person
   */
  followUpDate?: firebase.firestore.Timestamp;
  /**
   * When the laste follow up occurred
   */
  lastFollowUp?: firebase.firestore.Timestamp;
  /**
   * Keeps track of all the follow ups that have completed/occurred in the past
   * @deprecated use messageHistory now
   */
  followUpHistory?: { date: firebase.firestore.Timestamp; notes?: string }[];
  /**
   * Any indication of a message being sent to this contact, whether we know what was sent or not, or if it was just indication of them being followed up with
   */
  messageHistory?: dMessageRecord[];
  /**
   * 
   */
  appointments?: dContactAppointment[]
  /**
   * History of videos that have been sent to the contact and or watched by them for video tracking
   */
  videoWatches?: dVideoWatch[],
  apis?: {
    flowtrackId?: string
  },
  // email?: string;
  /**
   * @description This array holds value of Qualifications ids from qualifications field on config/variables document
   */
  qualifications?: string[] | firebase.firestore.FieldValue;
  /**
   * @deprecated use groups instead
   * @description The list Id will indicate what list this contact belongs to.
   *             This is now moved to groups
   */
  listId?: string;
  /**
   * @description The groups will indicate what contact-group this contact belongs to.
   *              A contact can belong to multiple contact-groups at a time.
   */
  groups?: string[] | firebase.firestore.FieldValue;
  /**
   * @deprecated use contact.image.uri
   */
  profileImage?: string;
  notes?: dContactNote[] | firebase.firestore.FieldValue;

  /**
   * @deprecated This has been replaced by qualifications field
   */
  points?: number[] | firebase.firestore.FieldValue;
}

export interface dVideoWatch extends dContentView {
  /** the page the item sent is from */
  pageId: string;
  /** the title of the video */
  title?: string;
  /** the phone number of the person this was sent to */
  recipientPhoneNumber: string;
  /** when the video was sent */
  sentAt?: firebase.firestore.Timestamp;
  /** when the video was finished being watched */
  watchedAt?: firebase.firestore.Timestamp;
}

export interface dContactAppointment {
  event: dCalendarEvent;
  completed?: boolean;
  cancelled?: boolean;
}

export interface dExportContacts {
  displayName: string;
  phoneNumbers: string;
  email: string;
  qualifications: dQualification[],
  notes: string;
}

export interface dContactNote {
  _id: string;
  createdAt?: firebase.firestore.Timestamp;
  isDeleted?: boolean;
  noteText?: string;
  createdBy?: dContactNoteCreator;
}

export interface dContactNoteCreator {
  _id: string;
  displayName: string;
}

/**
 * @description Qualification enum for MACHO
 * - M = Married
 * - A = Age (20-55)
 * - C = Children
 * - H = HomeOwner
 * - O = Occupation(Job)
 */
export enum MACHO {
  "MARRIED" = "M",
  "AGE" = "A",
  "CHILDREN" = "C",
  "HOMEOWNER" = "H",
  "OCCUPATION" = "O",
}

export interface ContactGroupSchemaa {
  _id: string;
  name: string;
  description?: string;
  /**
   * What is this for? - jeremy
   */
  groupType?: "email-groups" | "list";
  /**
   * ids of all contacts in this group
   */
  contacts: string[];
  /**
   * If you know that the contact groups have already been fetched for this contact group you can quickly access them here
   */
  contactObjects?: ContactSchemaa[];
  /**
   * @deprecated now using user.growth.listBuilder.shareTo
   */
  shareTo?: UserSchema["_id"][];
  createdAt?: firebase.firestore.FieldValue;
}

/**
 * @deprecated using string type now inside ContactGroupSchemaa
 */
export enum CONTACTGROUPTYPE {
  "EMAILGROUP" = "email-group",
  "LIST" = "list",
}

/**
 * @deprecated do not use, use dBMLSuggestion
 */
export interface dSuggestionContact extends Partial<UserSchema["personali"]> {
  _id: string;
  groupName?: string;
  groupContacts?: dSuggestionContact[];
}

export interface dContact extends Contacts.Contact { }

export type API_Contact = keyof dContact;

export type RES_LoadContacts = {
  code: "CONTACTS_X_LOADED" | "CONTACTS_LOADED" | "X_PERMISSIONS";
  contacts?: dContact[] | null[];
};

export type PROPS_ExportableContactInfo = {
  notes: dContactNote[];
  emailAddresses: [{ label: string; email: string }];
  familyName: string;
  givenName: string;
  phoneNumbers: [{ label: string; number: string }];
  recordID: string;
};

export type RES_JSONtoCSV = {
  code: "EXPORT_DONE" | "EXPORT_ERROR" | "X_PERMISSIONS";
  csvFile?: any;
};

const _DEFAULT_SUGGESTIONS = [
  { _id: "000", displayName: "Boris Yeltsin", email: "boris.yeltsin@abc.com " },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
  { _id: "000", displayName: "Tom Boboby", email: "tom.boboy@abc.com" },
];
