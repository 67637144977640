//#region [import]
import {
  Avt,
  Buttoon,
  Kitten,
  O,
  Poppy,
  sstyled,
  Toasty,
  Txt
} from "components";
import { PosstCreatorCenter } from "components/organisms";
import { ACTION_TYPE, FPATH, PosstSchema, recordUserAction } from "engines";
import {
  useMemberDocument,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import { getThumbnailFromURL } from "engines/functions/media-functions";
import firebase from "firebase";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";

//#endregion

/**
 * ### Footer of PosstCard
 *  - Include Author info, like, comment, delete func
 *  ----
 *  @example
 *
 *  ----
 *  @version 21.10.16
 *  -  *Brief changelog*
 *  @author  @nguyenkhooi
 *
 **/
export function Footer(props: dFooter) {
  const { posst } = props;
  const userProfile = useUserProfile();
  // const handlePosst = usePosstDocument(posstId);

  const handleMember = useMemberDocument(posst?.author?._id);
  const Navigation = useNavigator();
  const refRenderCount = React.useRef(0);

  //#region [functions]
  function toCommentsCenter() {
    Navigation.overlayPortal.open({
      headerTitle: tr("Comments"),
      render: <O.CommentsCenter pid={posst?._pid} />,
    });
  }
  function onPinToggle() {
    firebase
      .firestore()
      .doc(`${FPATH.POSSTS}/${posst?._pid}`)
      .update({ pinned: !posst?.pinned });
  }

  async function onLikeToggle() {
    if (Array.isArray(posst?.likes) && posst?.likes?.includes(userProfile.data?._id)) {
      // remove like
      await firebase
        .firestore()
        .doc(`${FPATH.POSSTS}/${posst?._pid}`)
        .update({
          likes: firebase.firestore.FieldValue.arrayRemove(
            userProfile.data?._id
          ),
        })
    } else {
      // add like
      await firebase
        .firestore()
        .doc(`${FPATH.POSSTS}/${posst?._pid}`)
        .update({
          likes: firebase.firestore.FieldValue.arrayUnion(
            userProfile.data?._id
          ),
        });
      const condensedTitle = posst?.body?.length > 80 ?
        posst?.body?.substring(0, 80 - 3) + "..." :
        posst?.body;
      recordUserAction(userProfile.data, ACTION_TYPE.POSST_LIKE, `${userProfile.data?.personali?.displayName} liked ${posst.author?.name}'s post ${condensedTitle}.`, { location: posst?._pid, involvedPartyName: posst?.author?.name, thumbnail: posst?.medias?.length > 0 ? getThumbnailFromURL(posst?.medias[0]?.uri) : null })
    }
  }
  function onEdit() {
    Navigation.dialogPortal.open({
      headerTitle: "Edit Announcement",
      render: <PosstCreatorCenter editPosst={posst} />
    })
  }
  function onDelete() {
    Poppy(
      tr("Final Check"),
      tr("Do you want to delete this announcement?"),
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: async () => {
          // await handlePosst.deleteDocument();
          await firebase
            .firestore()
            .doc(`${FPATH.POSSTS}/${posst?._pid}`)
            .delete();
          Toasty.show(tr("Announcement deleted"));
        },
      }
    );
  }
  //#endregion
  return React.useMemo(() => {
    return (
      <A.ViewContainer
        // disabled
        accessoryLeft={() => (
          <Kitten.ViewH>
            <Avt
              // name={handlePosst.data.author?.name} //todo Old schema
              // source={{ uri: handlePosst.data.author?.avatar }} //todo Old schema
              name={
                handleMember.data?.personali?.displayName ||
                handleMember.data?.personali?.email
              }
              source={{
                uri: handleMember.data?.personali?.photoURL,
              }}
              size="small"
            />
            <View style={{ marginLeft: spacing(3) }}>
              <Txt.S2 numberOfLines={2}>
                {handleMember.data?.personali?.displayName ||
                  `${handleMember.data?.personali?.email}`}
                {/* {handleMember.get().personali?.displayName} */}
              </Txt.S2>
              {/* <Txt.S2>{handlePosst.data._pid}</Txt.S2> */}

              <Txt.C2>
                {posst?.createdAt?.toDate().toLocaleDateString() ||
                  "Unknown date"}
                {" "}
                •{" "}
                {posst?.createdAt?.toDate().toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }) || "Unknown time"}
              </Txt.C2>
            </View>
          </Kitten.ViewH>
        )}
        accessoryRight={() => (
          <Kitten.ViewH style={{ flexWrap: "wrap" }}>
            <Buttoon
              size="small"
              appearance="ghost"
              status={
                Array.isArray(posst?.likes) &&
                  posst?.likes?.includes(userProfile?.data?._id)
                  ? "danger"
                  : "basic"
              }
              // progress
              onPress={onLikeToggle}
              icon={{ name: "heart" }}
            >
              {Array.isArray(posst?.likes) ? posst?.likes.length : 0}
            </Buttoon>
            <Buttoon
              size="small"
              appearance="ghost"
              status="basic"
              onPress={toCommentsCenter}
              icon={{ name: "chat_bubble" }}
            >
              {`${posst?.commentCount || 0}`}
            </Buttoon>
            {userProfile._isUserAdmin() && (
              <Buttoon
                size="small"
                appearance="ghost"
                status={posst?.pinned ? "warning" : "basic"}
                // progress
                onPress={onPinToggle}
                icon={{ name: "pin" }}
              />
            )}
            {(userProfile._isUserAdmin() ||
              userProfile.data?._id === posst?.author?._id) && (
                <Buttoon /** Admin stuff */
                  size="small"
                  appearance="ghost"
                  status="basic"
                  onPress={onDelete}
                  icon={{ name: "trash" }}
                />
              )}
            {(userProfile._isUserAdmin() ||
              userProfile.data?._id === posst?.author?._id) && (
                <Buttoon /** Admin stuff */
                  size="small"
                  appearance="ghost"
                  status="basic"
                  onPress={onEdit}
                  icon={{ name: "pen" }}
                />
              )}
          </Kitten.ViewH>
        )}
      // accessoryRight={() => <Txt onPress={() => alert("TEST")}>Test</Txt>}
      >
        <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>
      </A.ViewContainer>
    );
  }, [handleMember.data, posst, userProfile]);
}

const A = {
  ViewContainer: sstyled(Kitten.ListItemv2)({}),
};

export interface dFooter {
  // posstId: PosstSchema["_pid"];
  posst: PosstSchema;
}
