import { AvatarProps } from "@ui-kitten/components";
import { EvaSize } from "@ui-kitten/components/devsupport";
import { Kitten, Txt } from "components";
import { fn, useAppContext } from "engines";
import React from "react";
import { View } from "react-native";
import { evaConfig, spacing } from "utils";
import { isValidUrl } from "../../../../engines/functions/js-functions/core";

function Avatar(props: dAvatar) {
  const { C } = useAppContext();
  const { size, source, name = "", shape } = props;
  const [isError, setIsError] = React.useState<boolean>(false);

  React.useEffect(
    /**
     * This function is for android 🤖, since it doesn't take null or empty value as "Error"
     */
    function checkSource() {
      setIsError(
        source?.uri == "" || source?.uri == null || !isValidUrl(source?.uri)
      );
    },
    [source]
  );
  function _size() {
    if (typeof size === "number") {
      return size;
    } else if (typeof size === "string") {
      return evaConfig.strict[`size-${size}`];
    } else return 32;
  }

  function _borderRadius() {
    switch (shape) {
      case "square":
        return 0;
        break;
      case "round":
        return _size() * 2 * 0.6;
        break;
      case "rounded":
        return _size() * 0.3;
        break;

      default:
        return _size() * 2 * 0.6;
        break;
    }
  }

  let optimizedSource: any = source?.uri?.includes("cloudinary")
    ? {
        uri: source?.uri?.replace(
          "/upload/",
          "/upload/q_auto:low/c_thumb,w_200/"
        ),
      }
    : source;

  let avtColor = React.useMemo(() => fn.js.stringToColor(name), [name]);
  //   console.log("sizeee: ", _size());
  return React.useMemo(
    () =>
      isError ? (
        <View
          style={{
            width: _size(),
            height: _size(),
            borderRadius: _borderRadius(),
            backgroundColor: avtColor,
            justifyContent: "center",
            alignItems: "center",
            ...props.style,
          }}
        >
          <Txt.S2 style={{ color: "white", fontSize: _size() * 0.4 }}>
            {fn.js.initialFromName(name)}
          </Txt.S2>
        </View>
      ) : (
        <Kitten.Avatar
          size="small"
          style={{
            backgroundColor: C.dim,
            width: _size(),
            height: _size(),
            borderRadius: _borderRadius(),
            ...props.style,
          }}
          source={optimizedSource}
          onError={() => setIsError(true)}
          {...props}
        />
      ),
    [optimizedSource, isError]
  );
}

function Row(props: dAvtRow) {
  const { C } = useAppContext();
  const { data } = props;

  const moreNumber = data?.length - 4 <= 0 ? 0 : data?.length - 4;
  return (
    <Kitten.ViewH style={{ alignItems: "center" }}>
      {data?.slice(0, 4).map((member, index) => (
        <Avatar
          key={index + member.name}
          name={member.name}
          source={member.source}
          style={{
            borderWidth: 2,
            borderColor: C.background,
            transform: [{ translateX: -index * 10 }],
          }}
        />
      ))}
      {moreNumber > 0 ? (
        <Txt.P1
          style={{
            paddingHorizontal: spacing(2),
            paddingVertical: spacing(1),
            borderRadius: 10,
            backgroundColor: C.surface,
            transform: [{ translateX: -4 * 10 }],
          }}
        >
          {`+ ${data ? moreNumber : "..."} more`}
        </Txt.P1>
      ) : null}
    </Kitten.ViewH>
  );
}

/**
 * ### An avatar component for the project
 *  - Based on `Kitten.Avatar`
 *  ----
 *  @example
 *  <Avt  
 *  name={"handlePosst.get()?.author?.name" || ""}
    source={{ uri: "handlePosst.get()?.author?.avatar"}}
    size="small" //* or size={420} 
    />
 *  ----
 *  @version 21.09.08
 *  -  *Memoized Avt*
 *  @author  @nguyenkhooi
 *
 **/
export const Avt: dAvt = Avatar;

Avt.Row = Row;

interface dAvt extends React.FC<dAvatar> {
  /**
   * ### Show the first 4 avatars of the list, and number of members left (if there's any)
   * ---
   * @example
   *  <Avt.Row
        data={item.contacts?.map((contact) => ({
          name: contact.displayName || contact.email,
          source: {
            uri: "",
          },
        }))}
      />
  */
  Row?: typeof Row;
}

//@ts-expect-error ts doesn't like `size: EvaSize | number`, but in reality we have _size() to convert number to EvaSize so no problem here
interface dAvatar extends AvatarProps {
  /**
   * Name used for generating initials
   */
  name: string;
  size?: EvaSize | number;
  shape?: "square" | "round" | "rounded";
}
interface dAvtRow {
  data: dAvatar[];
}
