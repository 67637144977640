import { Kitten, ListHeader } from "components";
import { useTwoColumns } from "components/templates";
import { useAppContext, useUserProfile } from "engines";
import * as EXCalendar from "expo-calendar";
import firebase from "firebase";
import React from "react";
import { FlatList } from "react-native-gesture-handler";

/**
 * source: {name,id},
 * color
 * id
 * title
 */

export function PersonalCalendar(p: { myCalendars: EXCalendar.Calendar[] }) {
  const { myCalendars } = p;

  const { frbsUser } = useAppContext();

  const userHandler = useUserProfile(frbsUser?.uid);

  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();

  const [selectedCalendars, setSelectedCalendars] = React.useState<string[]>(
    []
  );

  React.useEffect(
    function userUpdated() {
      if (!userHandler.data) return;
      setSelectedCalendars(userHandler?.data?.personalCalendars);
    },
    [userHandler.data]
  );

  return (
    <>
      <ListHeader title="Select Calendars to Sync" />
      <FlatList
        style={[column1ContainerStyle]}
        data={myCalendars}
        renderItem={({ item, index }) => (
          <Kitten.ListItemv2
            title={item.title}
            accessoryRight={() => (
              <Kitten.CheckBox
                checked={selectedCalendars?.includes(item.id)}
                onChange={(v) => {
                  if (v && !selectedCalendars?.includes(item.id)) {
                    setSelectedCalendars(selectedCalendars?.concat([item.id]));
                    userHandler.update({
                      personalCalendars:
                        firebase.firestore.FieldValue.arrayUnion(item.id),
                    });
                  } else {
                    setSelectedCalendars(
                      selectedCalendars?.filter((c) => c !== item.id)
                    );
                    userHandler.update({
                      personalCalendars:
                        firebase.firestore.FieldValue.arrayRemove(item.id),
                    });
                  }
                }}
              />
            )}
          />
        )}
      />
    </>
  );
}
