import { useFocusEffect } from "@react-navigation/native";
import { Buttoon, DatteLatte, Kitten, Toasty, Txt } from "components/atoms";
import {
  MediaPageSchema,
  NotificationStatus,
  useAppContext,
  useConfigDocument,
  useNotificationCollection
} from "engines";
import { TOPICTYPES } from "engines/functions/notifications-functions";
import firebase from "firebase";
import React from "react";
import { useNavigator } from "screens/_navigation";
import { spacing, tr } from "utils";
import RecurringPN, { RECTYPE } from "./recurring-pn";
/**
 * ### List of members of the app (aka teamId's members)
 *  - Including some quick actions as an swipeable item
 *  ----
 *  @version 21.09.07
 *  -  *Memoize it*
 *  @author  K, Nl
 *
 **/
export function BroadcastCreator(props: P) {
  const { C, teamId, frbsUser } = useAppContext();

  const { bsid, handlerPage } = props;

  console.log("bsid ", bsid);

  const Navigation = useNavigator();
  // const userProfile = useUserProfile();
  const configVariablesHandler = useConfigDocument(`variables-${teamId}`);
  const { add, sendNotificationToEveryone, sendNotificationToTeam } =
    useNotificationCollection(`${teamId}`);
  // const configdata = configVariablesHandler?.data;
  // const [disableAddToFirestore, setDisableAddToFirestore] =
  //   React.useState<boolean>();

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section] search ops
  // const [query, setQuery] = React.useState<string>();
  // const refTitle = React.useRef<Input>();

  const [_title, setTitle] = React.useState<string>("");
  const [_message, setMessage] = React.useState<string>("");
  const [toggleDate, setToggleDate] = React.useState<boolean>(false);
  const [recurring, setRecurring] = React.useState<boolean>(false);
  const [option, setOption] = React.useState<RECTYPE>(RECTYPE.BLANK);
  const selectedDate = React.useRef<Date>();
  const [_datePickerVisible, setDatePickerVisible] = React.useState(true);
  const [_timePickerVisible, setTimePickerVisible] = React.useState(true);

  const toggleShowDate = async () => {
    setToggleDate(!toggleDate);
    // setDatePickerVisible(!_datePickerVisible);
  };

  const toggleShowRecurring = async () => {
    setRecurring(!recurring);
  };

  const [scheduleAtStr, setScheduleAtStr] = React.useState<string>();
  const [scheduleAt, setScheduleAt] = React.useState<Date>(new Date());
  //#endregion

  //#region [section2] contact section

  // const [details, setDetails] = React.useState<ContactGroupSchema>();
  //#endregion

  //#region [functions] SendNotifications
  async function SendNotifications(xong) {
    console.log("scheduleAt ", scheduleAt);
    if (scheduleAt && toggleDate) {
      add({
        title:
          _title?.length > 0 ? _title : configVariablesHandler.data?.appTitle,
        _cloudFunctionEndpoint: configVariablesHandler.data?._parentAppId,
        _teamId: teamId,
        channel: `${teamId}-global`,
        message: _message,
        //@ts-ignore auto convert
        createdAt: scheduleAt,
        //@ts-ignore auto convert
        timestamp: scheduleAt,
        recurrence: recurring ? option : RECTYPE.BLANK,
        status: NotificationStatus.SCHEDULED,
        comments: [],
      })
        .then(() => {
          Toasty.show(
            "Your notification has been scheduled. \n Go do something else and we'll take care of it from here!",
            {
              type: "success",
            }
          );

          xong && xong();
          Navigation.goBack();
        })
        .catch((err) => {
          Toasty.show("Failed to schedule " + err, { type: "danger" });
        });
    } else {
      // no longer disable PNs silly
      // if (!configdata.enablePushNotifications) {
      //   setTimeout(() => {
      //     Toasty.show("Push notifications are disabled 😢", {
      //       type: "warning",
      //     });
      //   }, 4500);

      //   return null;
      // }

      // if (!disableAddToFirestore) {
      // console.log("title ", _message);

      const d = new Date();

      add({
        title:
          _title?.length > 0 ? _title : configVariablesHandler.data?.appTitle,
        _teamId: teamId,
        channel: `${teamId}-global`,
        message: _message,
        status: NotificationStatus.SENT,
        createdAt: firebase.firestore.Timestamp.now(),
        comments: [],
      }).then(() => {
        Toasty.show("Message sent to everyone!", {
          type: "success",
        });
      });
      // } else {
      //   null;
      // }

      // const _token = await frbsUser.getIdToken();

      await sendNotificationToEveryone(
        _title?.length > 0 ? _title : configVariablesHandler.data?.appTitle,
        _message,
        {
          // teamId: userProfile?.data?._teamId,
          cloudFunctionEndpoint: configVariablesHandler.data?._parentAppId,
          teamId,
        }
      ).then(() => {
        xong && xong();
        Navigation.goBack();
      });
    }
  }
  //#endregion

  //#region [functions] SendNotificationBS
  async function SendNotificationBs(xong) {
    const topic = `${teamId}-${TOPICTYPES.BASESHOP}-${handlerPage?._id}`;
    if (scheduleAtStr) {
      add({
        title:
          _title?.length > 0 ? _title : configVariablesHandler.data?.appTitle,
        _teamId: teamId,
        message: _message,
        channel: `${topic}`,
        teamName: handlerPage?.name,
        icon: handlerPage?.mediaItem.logo ? handlerPage?.mediaItem.logo : "",
        createdAt: new Date(scheduleAtStr),
        recurrence: recurring ? option : RECTYPE.BLANK,
        status: NotificationStatus.SCHEDULED,
        comments: [],
        baseShopId: bsid,
      }).then(() => {
        Toasty.show(
          "Your notification has been scheduled. \n Go do something else and we'll take care of it from here!",
          {
            type: "success",
          }
        );

        xong && xong();
      });
      Navigation.goBack();
    } else {
      // if (!configdata.enablePushNotifications) {
      //   setTimeout(() => {
      //     Toasty.show("Push notifications are disabled 😢", {
      //       type: "warning",
      //     });
      //   }, 4500);

      //   return null;
      // }

      // if (!disableAddToFirestore) {
      // const d = new Date();

      add({
        _teamId: teamId,
        title: _title,
        channel: `${topic}`,
        teamName: handlerPage?.name,
        icon: handlerPage?.mediaItem.logo ? handlerPage?.mediaItem.logo : "",
        message: _message,
        createdAt: firebase.firestore.Timestamp.now(),
        status: NotificationStatus.SENT,
        baseShopId: bsid,
        comments: [],
      }).then(() => {
        Toasty.show("Message sent to team members!", {
          type: "success",
        });
      });
      // } else {
      //   null;
      // }

      await sendNotificationToTeam(
        teamId,
        TOPICTYPES.BASESHOP,
        handlerPage,
        _title,
        _message,
        configVariablesHandler.data?._parentAppId
      ).then(() => {
        xong && xong();
        Navigation.goBack();
      });
    }
  }
  //#endregion
  try {
    return (
      <>
        <Kitten.Input
          status="primary"
          placeholder="Title your message (optional)"
          value={_title}
          onChangeText={setTitle}
        />
        <Kitten.Divider_________ />
        <Kitten.Input
          textStyle={{ minHeight: 60 }}
          status="primary"
          multiline
          placeholder="What would you like to say?"
          value={_message}
          onChangeText={setMessage}
        />
        <Kitten.Divider_________ />
        <Kitten.CheckBox
          checked={toggleDate}
          onChange={toggleShowDate}
          style={{ margin: spacing(3) }}
        >
          {
            tr("Schedule") //* `Would you like to schedule this mail?`
          }
        </Kitten.CheckBox>

        {toggleDate ? (
          <>
            <DatteLatte
              onSelectCallback={(dateStr, date) => {
                setScheduleAtStr(dateStr);
                setScheduleAt(date);
              }}
            />
            {() => setDatePickerVisible(false)}
            <Kitten.Divider_________ />
            <Txt.Indicator marginV>
              {tr("Scheduled for ") + scheduleAtStr}
            </Txt.Indicator>
            <Kitten.Divider_________ />
            <Kitten.CheckBox
              checked={recurring}
              onChange={toggleShowRecurring}
              style={{ margin: spacing(3) }}
            >
              {
                tr("Enable Recurring") //* `Would you like to schedule this mail?`
              }
            </Kitten.CheckBox>
          </>
        ) : null}

        {recurring ? (
          <>
            <Kitten.Divider_________ />
            <RecurringPN
              scheduledDate={selectedDate.current}
              option={option}
              setOption={(selectedItem) => {
                setOption(selectedItem);
              }}
            />
            <Kitten.Divider_________ />
          </>
        ) : null}
        {!toggleDate ? (
          <>
            <Buttoon
              style={{ marginTop: spacing(4) }}
              status="basic"
              icon={{ name: "bullhorn" }}
              progress
              onPress={(xong) =>
                handlerPage && bsid
                  ? SendNotificationBs(xong)
                  : SendNotifications(xong)
              }
            >
              {tr(
                `${handlerPage && bsid
                  ? `Send Notification to ${handlerPage?.name}`
                  : `Send Notification`
                } `
              )}
            </Buttoon>
          </>
        ) : (
          <>
            <Buttoon
              progress
              onPress={(xong) =>
                handlerPage && bsid
                  ? SendNotificationBs(xong)
                  : SendNotifications(xong)
              }
            >
              {tr(
                `${handlerPage && bsid
                  ? `Schedule Notification for ${handlerPage?.name}`
                  : `Schedule Notification`
                }`
              )}
            </Buttoon>
          </>
        )}
        <Buttoon
          style={{ marginVertical: spacing(3) }}
          icon={{ name: "arrow_right", right: true }}
          appearance="ghost"
          status="control"
          size="small"
          onPress={() => {
            Navigation.navigate("Push Notifications");
          }}
        >
          {tr("View all notifications")}
        </Buttoon>
      </>
    );
  } catch (error) {
    throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
  }
}

interface P {
  bsid?: string;
  handlerPage?: MediaPageSchema;
}
