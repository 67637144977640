import * as Clipboard from "expo-clipboard";
import firebase from "firebase";
import * as EXCalendar from "expo-calendar";
import * as AddCalendarEvent from "react-native-add-calendar-event";
import { IconPrimr, ICON_NAME } from "assets";
import { Poppy, Toasty, Txt } from "components/atoms";
import {
  ACTION_TYPE,
  fn,
  FPATH,
  MediaPageItemSchema,
  recordUserAction,
  sendNotificationToUser,
  URL_PREFIX,
  useAppContext,
  UserSchema,
  useUserProfile,
} from "engines";
import {
  ContactSchemaa,
  dMessageRecord,
  dVideoWatch,
  MSG_TYPE,
} from "engines/backends/schemas/bml";
import React from "react";
import { Alert, Linking, Platform, View } from "react-native";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import { IS_WEB, spacing, tr } from "utils";
import * as Contacts from "expo-contacts";
import * as SMS from "expo-sms";
import { TextTemplatesScreen, useNavigator } from "screens";
import { generateEmailInvite } from "./email-generator";
import {
  FollowUpDialog,
  generateTrackableUrl,
  RECIPIENT_PHONE_PLACEHOLDER,
} from "components";
import { TrackableVideosScreen } from "screens/trackable-videos/TrackableVideosScreen";
import { FieldTrainerSelection } from "components/molecules/HitEmUp/FieldTrainerSelection";
import { LinkURL } from "engines/functions/web-functions";
import { forsureDate } from "engines/functions/calendar-functions";

/** Attemps to save sent message history to contact but will do nothing if contact deesn't yet exist in firestore */
export async function recordMessageSent(
  contact: ContactSchemaa,
  type: MSG_TYPE,
  _msg?: string,
  options: {
    item?: MediaPageItemSchema,
    videoWatch?: dVideoWatch,
    groupText?: dMessageRecord["groupText"]
  } = {},
) {
  const { item, videoWatch, groupText } = options
  try {
    //@ts-ignore
    await firebase
      .firestore()
      .doc(
        `${FPATH.USERS}/${contact?.ownerUid}/${FPATH.CONTACTS}/${contact._cid || contact._id || contact.id
        }`
      )
      //@ts-ignore FieldValue
      .update({
        ...contact,
        messageHistory: firebase.firestore.FieldValue.arrayUnion(
          //@ts-ignore Date to Timestamp conversion
          {
            date: new Date(),
            message: _msg,
            itemId: item?._id,
            type,
            groupText
          } as dMessageRecord),
        ...(videoWatch
          ? {
            videoWatches:
              firebase.firestore.FieldValue.arrayUnion(videoWatch),
          }
          : {}),
      } as Partial<ContactSchemaa>);
    console.log(`message sent to ${contact._cid}`);
  } catch (err) {
    // if the contact doesn't exist in the app yet, that is expected anyway so nothing to worry about when getting an error
    console.warn(`${err}`);
  }
}

export function QuickActionContactRow({
  contact,
  user,
  onDidUpdatedContact,
}: {
  contact?: ContactSchemaa;
  user?: UserSchema;
  onDidUpdatedContact?: (updatedContact: ContactSchemaa) => void;
}) {
  const Navigation = useNavigator();
  const { frbsUser, teamId } = useAppContext();
  const userHandler = useUserProfile();

  const [includeTrainerInText, setIncludeTrainerInText] = React.useState<UserSchema>();

  const phoneNumbers = contact?.phoneNumbers
    ? (contact.phoneNumbers as Contacts.PhoneNumber[])
    : [];
  const emailAddresses =
    contact.emails && (contact?.emails as Contacts.Email[])?.length > 0
      ? (contact.emails as Contacts.Email[])
      : contact?.email
        ? [contact?.email]
        : [];

  const messageOptions =
    phoneNumbers.length > 0
      ? []
        .concat(
          phoneNumbers.map((_pn) => ({
            label: `${_pn.label ? _pn.label + "\n" : ""}${formatPhoneNumber(
              _pn.number
            )}`,
            onPress: async () => {
              const smsAvailable = await SMS.isAvailableAsync();
              if (smsAvailable) {
                SMS.sendSMSAsync(includeTrainerInText ? [_pn.number, includeTrainerInText.personali?.phoneNumber] : _pn.number, "");
                // record that a message was sent at this time
                recordMessageSent(contact, "sms", null, {
                  ...(includeTrainerInText ? { groupText: { phoneNumber: includeTrainerInText.personali?.phoneNumber, uid: includeTrainerInText?._id } } : {}),
                });
                recordUserAction(userHandler.data, ACTION_TYPE.SEND_SMS, `${userHandler.data?.personali?.displayName} sent SMS to ${contact?.name}.`, { location: contact._cid, involvedPartyName: contact.name })
              } else
                Toasty.show("SMS not available on web.", { type: "warning" });
            },
          }))
        )
        .concat([
          {
            label: "💬 Text scripts",
            onPress: () => {
              Navigation.dialogPortal.open({
                headerTitle: "Text Templates",
                render: (
                  <TextTemplatesScreen
                    route={{
                      params: {
                        contact: contact,
                        onSelectText: async (
                          selectedItem: MediaPageItemSchema
                        ) => {
                          Navigation.goBack();
                          const _msg = selectedItem.title?.replace(
                            /🌀/g,
                            (
                              contact?.displayName ||
                              contact?.name ||
                              ""
                            ).split(" ")[0]
                          );
                          if (IS_WEB) {
                            Clipboard.setString(_msg);
                            Poppy(
                              "Message copied to clipboard.",
                              "Please send the message manually by pasting it or using your mobile device with this feature."
                            );
                            recordMessageSent(
                              contact,
                              "clipboard",
                              _msg,
                              {
                                item: selectedItem
                              }
                            );
                          } else {
                            const canSendSMS = await SMS.isAvailableAsync();
                            if (canSendSMS) {
                              SMS.sendSMSAsync(
                                includeTrainerInText ? phoneNumbers.map((p) => p.number).concat([includeTrainerInText.personali?.phoneNumber]) : phoneNumbers.map((p) => p.number),
                                _msg
                              );
                              recordMessageSent(
                                contact,
                                "sms",
                                _msg,
                                {
                                  item: selectedItem,
                                  ...(includeTrainerInText ? { groupText: { phoneNumber: includeTrainerInText.personali?.phoneNumber, uid: includeTrainerInText?._id } } : {}),
                                }
                              );
                              recordUserAction(userHandler.data, ACTION_TYPE.SEND_SMS, `${userHandler.data?.personali?.displayName} sent SMS to ${contact?.name}.`, { location: contact._cid, involvedPartyName: contact.name })
                            } else
                              Toasty.show(
                                "SMS not available on this device.",
                                { type: "warning" }
                              );
                          }
                        },
                      },
                    }}
                  />
                ),
              });
            },
          },
          {
            label: "🎥 Trackable Videos",
            onPress: () => {
              Navigation.dialogPortal.open({
                headerTitle: "Trackable Videos",
                render: (
                  <TrackableVideosScreen
                    route={{
                      params: {
                        contact: contact,
                        onVideoSelect: async (item: MediaPageItemSchema) => {
                          Navigation.goBack();
                          if (
                            (contact.phoneNumbers as Contacts.PhoneNumber[])
                              .length < 1
                          ) {
                            Toasty.show("Contact has 0 phone numbers.", {
                              type: "warning",
                            });
                            return;
                          }
                          if (!item._parentPageId) {
                            Poppy(
                              "Old Video Alert",
                              "This video needs to be updated before it can be tracked.\n\n(missing _parentPageId)"
                            );
                            return;
                          }

                          //@ts-ignore too lazy to do what i already know...
                          if (
                            !contact._phoneNumberStrings ||
                            contact._phoneNumberStrings?.length !==
                            contact.phoneNumbers?.length
                          ) {
                            // if this contact does not yet have _phoneNumberStrings, create it
                            firebase
                              .firestore()
                              .doc(
                                `${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}/${contact._cid}`
                              )
                              .update({
                                _phoneNumberStrings: (
                                  contact.phoneNumbers as Contacts.PhoneNumber[]
                                ).map(
                                  (pn) => (pn as Contacts.PhoneNumber).digits || (pn as Contacts.PhoneNumber).number?.replace(/\D/g, "")
                                ),
                              } as Partial<ContactSchemaa>);
                          }

                          // prepare the message/url to be sent out
                          let _msg = generateTrackableUrl(
                            "unleash",
                            item._parentPageId,
                            item._id,
                            frbsUser?.uid,
                            (
                              contact.phoneNumbers as Contacts.PhoneNumber[]
                            )[0].digits ||
                            (
                              contact.phoneNumbers as Contacts.PhoneNumber[]
                            )[0].number?.replace(/\D/g, "")
                          );
                          const contactName =
                            contact.name || contact.displayName;
                          const contactPhoneNumber =
                            (
                              contact?.phoneNumbers as Contacts.PhoneNumber[]
                            )[0]?.digits ||
                            (
                              contact?.phoneNumbers as Contacts.PhoneNumber[]
                            )[0]?.number?.replace(/\D/g, "");
                          const regexPhonePlaceholder = new RegExp(
                            RECIPIENT_PHONE_PLACEHOLDER,
                            "g"
                          );
                          // replace the PHONE_NUMBER with the contacts phone number
                          _msg = _msg
                            .replace(/🌀/g, contactName?.split(" ")[0])
                            .replace(
                              regexPhonePlaceholder,
                              contactPhoneNumber
                            );

                          const canSendSMS = await SMS.isAvailableAsync();
                          if (canSendSMS) {
                            SMS.sendSMSAsync(
                              includeTrainerInText ? phoneNumbers.map((p) => p.number).concat([includeTrainerInText.personali?.phoneNumber]) : phoneNumbers.map((p) => p.number),
                              _msg
                            );
                            const videoWatch: dVideoWatch = {
                              _teamId: teamId,
                              itemId: item._id,
                              pageId: item._parentPageId,
                              uid: "anonymous",
                              recipientPhoneNumber: contactPhoneNumber,
                              watched: 0,
                              sentAt: firebase.firestore.Timestamp.fromDate(
                                new Date()
                              ),
                              sharerUid: frbsUser?.uid,
                              title: item.title,
                              url: item.media,
                            };
                            recordMessageSent(
                              contact,
                              "trackable-video",
                              _msg,
                              {
                                item,
                                videoWatch,
                                ...(includeTrainerInText ? { groupText: { phoneNumber: includeTrainerInText.personali?.phoneNumber, uid: includeTrainerInText?._id } } : {}),
                              }
                            );
                            recordUserAction(userHandler.data, ACTION_TYPE.SEND_SMS, `${userHandler.data?.personali?.displayName} sent SMS to ${contact?.name}.`, { location: contact._cid, involvedPartyName: contact.name })
                          } else {
                            Toasty.show("SMS not available on this device.", {
                              type: "warning",
                            });
                            // alert(_msg)
                          }
                        },
                      },
                    }}
                  />
                ),
              });
            },
          },
          {
            label: includeTrainerInText ? `⬆️ Including ${includeTrainerInText.personali?.displayName}` : `👨‍💼 Include Trainer`,
            onPress: async () => {
              // select field trainer to include
              if (!includeTrainerInText) {
                // if they have 1 trainer selected, choose it for them automatically
                if (userHandler.data?.growth?.listBuilder?.shareTo?.length === 1) {
                  const defaultTrainer = (await firebase.firestore().doc(`${FPATH.USERS}/${userHandler.data?.growth?.listBuilder?.shareTo[0]}`).get()).data() as UserSchema
                  setIncludeTrainerInText(defaultTrainer)
                  Toasty.show(`Will include ${defaultTrainer.personali?.displayName} in your next text.`)
                } else {
                  Navigation.dialogPortal.open({
                    headerTitle: "Include Trainer",
                    render: <FieldTrainerSelection customIcon="message" onSelect={(trainer) => {
                      setIncludeTrainerInText(trainer)
                      Toasty.show(`Will include ${trainer.personali?.displayName} in your next text.`)
                    }} />
                  })
                }
              }
              // already selected, unselect
              else {
                setIncludeTrainerInText(null)
              }
            }
          }
        ])
      : [{ label: "No phone numbers", onPress: () => { } }];

  const phoneOptions =
    phoneNumbers.length > 0
      ? phoneNumbers.map((_pn) => ({
        label: `${_pn.label}\n${formatPhoneNumber(_pn.number)}`,
        onPress: () => {
          const _url = `tel:${_pn.number}`;
          Linking.canOpenURL(_url).then((supported) => {
            if (supported) {
              Linking.openURL(_url);
              recordMessageSent(contact, "call");
            } else
              Toasty.show(
                "You must be on a mobile device to dial this number.",
                { type: "warning" }
              );
          });
        },
      }))
      : [
        {
          label: "No phone numbers",
          onPress: () => {
            Alert.alert("Contact does not have a phone number");
          },
        },
      ];

  const mailOptions =
    emailAddresses.length > 0
      ? emailAddresses.map((_ea) => ({
        label: `${_ea.label}\n${_ea.email}`,
        onPress: () => {
          const _url = `mailto:${_ea.email}`;
          Linking.canOpenURL(_url).then((supported) => {
            if (supported) {
              Linking.openURL(_url);
              recordMessageSent(contact, "email");
            } else {
              Toasty.show("Your device is not capable of sending email.", {
                type: "warning",
              });
            }
          });
        },
      }))
      : [
        {
          label: "No email addresses",
          onPress: () => {
            Alert.alert("No emails for contact");
          },
        },
      ];

  async function sendEmailInvite(evtId: string, emailStrings: string[]) {
    try {
      await generateEmailInvite(evtId, emailStrings);
      Toasty.show("Invite email sent");
    } catch (err) {
      console.error(err);
      Alert.alert("Failed to send invite", err);
    }
  }

  const niceDateFormat = (date: Date): string =>
    date?.toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

  async function sendNotificationToAllFieldTrainers(meetingDate: Date) {
    // notify field trainer about new event!
    const fieldTrainers = userHandler.data?.growth?.listBuilder?.shareTo || [];
    console.log(
      `sending notification to ${fieldTrainers.length} field tainers for user.`
    );
    for (let f in fieldTrainers) {
      try {
        const ft_uid = fieldTrainers[f];
        const fieldTrainer = (
          await firebase.firestore().collection(FPATH.USERS).doc(ft_uid).get()
        ).data() as UserSchema;
        if (!fieldTrainer) continue;
        const timeZoneAbbreviation = new Date()
          .toLocaleTimeString("en-us", { timeZoneName: "short" })
          .split(" ")[2];
        sendNotificationToUser(
          fieldTrainer,
          `${userHandler.data?.personali?.displayName} scheduled a meeting!`,
          `${userHandler.data?.personali?.displayName
          } just scheduled a meeting with ${contact.name || contact.displayName || contact.email || "a prospect"
          } for ${niceDateFormat(
            meetingDate
          )} for ${fn.calendar.timeToAMPMString(
            meetingDate
          )} ${timeZoneAbbreviation}`
        );
      } catch (err) {
        console.log(
          `Failed to send notification to field trainer with error...`
        );
        console.error(err);
        continue;
      }
    }
  }

  function createMeeting(trainer?: UserSchema) {
    const _startDate = new Date();
    _startDate.setHours(12, 0, 0, 0);
    const _endDate = new Date();
    _endDate.setHours(_startDate.getHours() + 1, 0, 0, 0);
    AddCalendarEvent.presentEventCreatingDialog({
      title: `Meeting with ${contact?.name || contact?.displayName || "NPC"
        } ${user?.personali?.displayName
          ? ` and ${user?.personali?.displayName}`
          : ""
        }`,
      startDate: _startDate.toISOString(),
      endDate: _endDate.toISOString(),
    })
      .then(async (evt) => {
        try {
          if (evt.action === "SAVED") {
            sendNotificationToAllFieldTrainers(_startDate);

            const _calPerm =
              await EXCalendar.requestCalendarPermissionsAsync();
            if (_calPerm.granted) {
              let calEvent;
              try {
                calEvent = {
                  ...((await EXCalendar.getEventAsync(
                    evt.calendarItemIdentifier
                  )) || {})
                };
                calEvent.date = calEvent.date
                  ? calEvent.date
                  : calEvent.startDate;
              } catch (err) {
                console.error(err)
                Poppy(`Save failed`, "The event could not be recorded in the app.\n" + err)
                return;
              }
              user && calEvent &&
                firebase
                  .firestore()
                  .doc(
                    `${FPATH.USERS}/${user._id}/${FPATH.CONTACTS}/${contact._cid}`
                  )
                  .update({
                    // @ts-ignore
                    appointments: firebase.firestore.FieldValue.arrayUnion({
                      event: calEvent,
                    }),
                  });
              onDidUpdatedContact && calEvent &&
                onDidUpdatedContact({
                  ...contact,
                  appointments: (contact.appointments || []).concat([
                    { event: calEvent },
                  ]),
                });

              const evtDate = forsureDate(calEvent.startDate)
              const timezoneAbbr = new Date()
                .toLocaleTimeString("en-us", { timeZoneName: "short" })
                .split(" ")[2];
              if (trainer) {
                recordUserAction(user, ACTION_TYPE.SCHEDULED_MEETING, `${user?.personali?.displayName} scheduled a meeting (with their trainer ${trainer.personali?.displayName}) \"${calEvent.title}\" for ${fn.calendar.niceDateFormat(evtDate)} at ${fn.calendar.timeToAMPMString(evtDate)} ${timezoneAbbr}`, { involvedPartyName: calEvent.title, location: `${URL_PREFIX.CONTACT}${contact._cid}` })
              } else {
                recordUserAction(user, ACTION_TYPE.SCHEDULED_MEETING, `${user?.personali?.displayName} scheduled a meeting \"${calEvent.title}\" for ${fn.calendar.niceDateFormat(evtDate)} at ${fn.calendar.timeToAMPMString(evtDate)} ${timezoneAbbr}`, { involvedPartyName: calEvent.title, location: `${URL_PREFIX.CONTACT}${contact._cid}` })
              }
            }
            Toasty.show("Meeting scheduled", { type: "success" });
            Poppy(
              `Send ${contact?.name || contact?.displayName
              } an invite reminder?`,
              `This will send to ${emailAddresses?.length} email address${emailAddresses?.length > 1 ? "es" : ""
              }`,
              { text: "No thanks" },
              {
                text: "Send invite",
                onPress: () => {
                  sendEmailInvite(
                    evt.calendarItemIdentifier,
                    emailAddresses.map((e) =>
                      typeof e === "string" || !e.email ? e : e.email
                    )
                  );
                },
              }
            );
          }
        } catch (err) {
          console.error(err)
          Poppy("Aw, snap", err)
        }
      })
      .catch((error: string) => {
        Toasty.show(error, { type: "danger" });
        console.error(error);
      });
  }

  const scheduleOptions = [
    {
      label: "🗓 New Meeting",
      onPress: () => {
        if (IS_WEB) {
          Toasty.show("Not currently available on web.", {
            type: "warning",
          });
          return;
        }
        createMeeting()
      },
    },
    {
      label: "👨‍💼 Schedule with Trainer",
      onPress: () => {
        // const trainers = userHandler.data?.growth?.listBuilder?.shareTo || []
        Navigation.dialogPortal.open({
          headerTitle: "Choose Field Trainer",
          render: <FieldTrainerSelection customIcon="calendar" onSelect={(async (selectedTrainer) => {
            if (!selectedTrainer?.schedulingLink) {
              Poppy("No scheduling link", "Your trainer does not have a scheduling link in their profile. Ask them to create one first.")
              return;
            }
            await LinkURL(selectedTrainer.schedulingLink)
            Poppy("Create Meeting", "Would you like to save this meeting to your personal calendar and app?\n\nYou will need to add the details manually.", {
              text: "Yes (recommended)", onPress: () => {
                createMeeting(selectedTrainer)
              }
            }, {
              text: "No, thanks", onPress: () => {
                recordUserAction(user, ACTION_TYPE.SCHEDULED_MEETING, `${user.personali?.displayName} scheduled a meeting on their trainer's calendar (${selectedTrainer.personali?.displayName}).`, { location: `${URL_PREFIX.USER}${selectedTrainer?._id}`, involvedPartyName: `${selectedTrainer?.personali?.displayName}` })
              }
            })
          })
          } />
        })
      }
    }
  ];

  const followUpOptions = [
    {
      label: "Follow up",
      onPress: () => {
        Navigation.dialogPortal.open({
          headerTitle: "Follow Up Reminder",
          render: (
            <FollowUpDialog
              contact={contact}
              didUpdateContact={(updatedContact) => {
                onDidUpdatedContact && onDidUpdatedContact(updatedContact);
              }}
            />
          ),
        });
      },
    },
  ];

  return (
    // <ScrollView
    //     horizontal={true}
    //     style={rowStyles.scrollContainer}
    //     showsHorizontalScrollIndicator={false}
    // >
    <View style={rowStyles.container}>
      <QuickActionButton
        iconName="message"
        label="message"
        keepOpen={includeTrainerInText ? true : false}
        options={messageOptions}
      />
      <QuickActionButton iconName="phone" label="call" options={phoneOptions} />
      <QuickActionButton iconName="email" label="mail" options={mailOptions} />
      <QuickActionButton
        iconName="clock"
        label="follow up"
        options={followUpOptions}
      />
      <QuickActionButton
        iconName="calendar"
        label="schedule"
        options={scheduleOptions}
      />
    </View>
    // </ScrollView>
  );
}

export function QuickActionButton({
  iconName,
  label,
  keepOpen,
  options,
}: {
  iconName: ICON_NAME;
  label: string;
  keepOpen?: boolean;
  options?: { label: string; onPress: () => void }[];
}) {
  const { C } = useAppContext();

  const refMenu = React.useRef<Menu>(null);

  React.useEffect(function shouldOpen() {
    if (keepOpen === true)
      refMenu.current.open()
  }, [keepOpen]);

  const handleOptionSelect = (option) => {
    // onOptionSelect(option);
  };

  const renderOptions = () => {
    return options
      ? options.map((option, index) => (
        <>
          {index !== 0 ? (
            <View
              style={{
                backgroundColor: C.background,
                width: "100%",
                height: 0.5,
                opacity: 0.27,
              }}
            />
          ) : null}
          <MenuOption
            customStyles={{
              optionText: {
                color: C.background01,
                fontWeight: "500",
                lineHeight: 21,
                fontSize: 15,
              },
              optionWrapper: {
                padding: spacing(4),
                backgroundColor: C.text,
              },
            }}
            key={index}
            value={option.label}
            text={option.label}
            onSelect={option.onPress}
          />
        </>
      ))
      : [];
  };

  return (
    <>
      <Menu
        ref={refMenu}
        // {...({ opened: _forceOpen })}
        // opened={_opened}
        onSelect={handleOptionSelect}
        renderer={renderers.Popover}
        rendererProps={{ placement: "bottom" }}
        onOpen={() => {
          if (options?.length === 1) {
            refMenu.current.close();
            options[0].onPress && options[0]?.onPress();
          }
        }}
      >
        <MenuTrigger>
          <View style={styles.container}>
            <IconPrimr name={iconName} size={18} color="lightgray" />
            <Txt.Indicator style={[styles.label, { color: "lightgray" }]}>
              {label}
            </Txt.Indicator>
          </View>
        </MenuTrigger>
        <MenuOptions
          customStyles={{
            optionsContainer: {
              borderRadius: 10,
              overflow: "hidden",
              backgroundColor: C.text,
            },
          }}
        >
          {renderOptions()}
        </MenuOptions>
      </Menu>
    </>
  );
}

function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) return null;
  // Remove all non-digit characters from the phone number
  const cleaned = phoneNumber?.replace("+1", "")?.replace(/\D/g, "");

  // Check if the cleaned phone number has a valid length
  if (cleaned.length === 10 || phoneNumber.startsWith("+1")) {
    // Format the phone number as (XXX) XXX-XXXX
    return `${phoneNumber.startsWith("+1") ? "+1 " : ""}(${cleaned.slice(
      0,
      3
    )}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  } else {
    // Return the original phone number if it doesn't have a valid length
    return phoneNumber;
  }
}

const styles = {
  container: {
    minHeight: 60,
    minWidth: 60,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(200, 200, 200, 0.2)",
    borderRadius: 5,
    marginHorizontal: spacing(2),
    padding: 8,
  },
  label: {},
};

const rowStyles = {
  scrollContainer: {
    alignSelf: "center",
    width: "100%",
    marginTop: spacing(1),
    marginBottom: spacing(3),
  },
  container: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
  },
};
