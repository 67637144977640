import { IndexPath } from "@ui-kitten/components";
import { Avt, Kitten, sstyled, Txt } from "components/atoms";
import { MediaPageSchema } from "engines";
import React from "react";
import { TextInput, View } from "react-native";
import { scale, spacing, tr } from "utils";

/**
 * ### Choose a page, any page!
 *
 * @example
 * <PagePicker pages={pagesHandler?.data} onPageSelected={(seletedPage) => console.log(selectedPage? selectedPage?.name : "No page selected ;(")} />
 *
 * @author jm_francis
 */
export function PagePicker(p: {
  pages: MediaPageSchema[];
  onPageSelected: (page: MediaPageSchema) => void;
}) {
  const { pages, onPageSelected } = p;

  const indexOffset = 2;

  const [selectedPageIndex, setSelectedPageIndex] = React.useState<number>(0);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [sortedPages, setSortedPages] = React.useState<MediaPageSchema[]>([]);

  React.useEffect(
    function getPages() {
      setSortedPages(
        pages
          ?.filter((p) =>
            p?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
          )
          .sort((a, b) => {
            const textA = a?.name?.replace(/ /g, "")?.toUpperCase();
            const textB = b?.name?.replace(/ /g, "")?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
      );
    },
    [pages, searchValue]
  );

  return (
    <Kitten.Select
      style={{ flexGrow: 1 }}
      placeholder={"Select a page"}
      value={() => {
        const selectedPage =
          sortedPages && sortedPages[selectedPageIndex - indexOffset];
        onPageSelected(selectedPage);
        return selectedPageIndex > 1 ? (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Avt
              style={{ marginRight: spacing(3) }}
              name={selectedPage?.name || ""}
              source={{ uri: selectedPage?.mediaItem?.logo || "" }}
              size="small" //* or size={420}
            />
            <Txt.S1>{selectedPage?.name}</Txt.S1>
          </View>
        ) : (
          <Txt.S1>Select a page</Txt.S1>
        );
      }}
      selectedIndex={new IndexPath(selectedPageIndex)}
      onSelect={(index: IndexPath) => {
        setSelectedPageIndex(index.row);
        // const selectedPage = pagesHandler.data[index.row - 1];
      }}
    >
      {[
        <A.InputSearch
          style={{ padding: spacing(3) }}
          autoFocus={true}
          value={searchValue}
          placeholder={tr("Search page by name")}
          onChangeText={setSearchValue}
        />,
        <Kitten.SelectItemv2 title={"No selection"} />,
      ].concat(
        sortedPages?.map((p) => (
          <Kitten.SelectItemv2
            title={p?.name}
            accessoryLeft={() => (
              <Avt
                name={p?.name || ""}
                source={{ uri: p?.mediaItem?.logo || "" }}
                size="small" //* or size={420}
              />
            )}
          />
        ))
      )}
    </Kitten.Select>
  );
}

const A = {
  InputSearch: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
};
