//#region [import]
const Papa = require("papaparse");
const XLSX = require("xlsx");
import { Buttoon, Kitten, Poppy, sstyled, Toasty, Txt } from "components/atoms";
import { fn, PosstSchema, POSST_STATUS, useAppContext, usePosstDocument } from "engines";
import React from "react";
import { View } from "react-native";
import { IS_ANDROID, moderateScale, spacing } from "utils";
import { Footer } from "./card-footer";
import { GoToPage } from "./go-to-page";
import { PosstMediaRoll } from "./PosstMediasRoll";

//#endregion

/**
 * ### Card displaying posst body+content
 *  - Dynamic based on content type
 *  ----
 *  @version 21.09.09
 *  -  *Build a component*
 *  @author  K
 *
 **/
export function CardPosst(props: dCardPosst) {
  const { posstId } = props;
  const { frbsUser } = useAppContext();
  //#region [FRBS]
  const posstHandler = posstId ? usePosstDocument(posstId) : props.posst;
  // const userProfile = useUserProfile();
  //#endregion

  const [posst, setPosst] = React.useState<any>(props.posst);

  React.useEffect(function updatePosstIfNeeded() {
    //@ts-ignore
    if (posstId && posstHandler?.data) setPosst(posstHandler.data)
    else if (props.posst) setPosst(props.posst)
  }, [posstId, props.posst, posstHandler.data]);

  // const Navigation = useNavigator();
  const refRenderCount = React.useRef(0);
  const cardStatus = () => {
    switch (posst?.status) {
      case POSST_STATUS.DELETED:
        return "danger";
        break;
      case POSST_STATUS.SCHEDULED:
        return "warning";
        break;
      default:
        return null;
        break;
    }
  };

  //#region [functions]
  // function toCommentsCenter() {
  //   Navigation.overlayPortal.open({
  //     headerTitle: "Comments",
  //     render: <O.CommentsCenter pid={posstId} />,
  //   });
  // }
  //#endregion

  const CASE = {
    MEDIA_EXISTED: posst?.medias?.length > 0,
  };
  return React.useMemo(
    () => (
      <View>
        <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>
        <PosstMediaRoll {...props} posst={posst} />
        <A.CardContent
          status={cardStatus()}
        // disabled //* started disabling the child components, so we had to remove this, but now the whole card is clickable :(
        >
          <A.ViewBody>
            {CASE.MEDIA_EXISTED ? (
              <>
                <Txt.S1>
                  {posst?.body
                    ?.replace(/\\n/g, "\n")
                    .replace(fn.js.extractLinkFromBody(posst?.body), "")}
                </Txt.S1>
              </>
            ) : (
              <>
                <Txt.S1>
                  {posst?.body
                    ?.replace(/\\n/g, "\n")
                    .replace(fn.js.extractLinkFromBody(posst?.body), "")}
                </Txt.S1>
              </>
            )}
          </A.ViewBody>
          <GoToPage posst={posst} />
          <Footer {...props}  posst={posst}/>
          {/* {userProfile._isDeveloper() && (
            <Txt.Debug>{posst?._pid}</Txt.Debug>
          )} */}
          {/** Google requires a report button for all user generated content so this shows the report button to only them */}
          {frbsUser?.email?.startsWith("demo@") && IS_ANDROID ? (
            <Buttoon
              style={{ width: moderateScale(100), alignSelf: "center" }}
              onPress={() => {
                Poppy(
                  "Are you sure you want to report/flag this post?",
                  "It will be taken down immediately if containing inappropriate or abusive content.",
                  {
                    text: "Cancel",
                  },
                  {
                    text: "Yes, report!",
                    onPress: () =>
                      Toasty.show("Post reported and being reviewed!", {
                        type: "normal",
                      }),
                  }
                );
              }}
              status="danger"
              appearance="ghost"
              icon={{ name: "flag" }}
            >
              Report
            </Buttoon>
          ) : null}
        </A.CardContent>
      </View>
    ),
    [posst]
  );
}

const A = {
  CardContent: sstyled(Kitten.Card)({
    marginBottom: 10,
    width: ["100%", "100%", "100%", 614 * 1.11, 614 * 1.11],
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
  ViewBody: sstyled(View)({
    marginBottom: spacing(3),
  }),
};

export interface dCardPosst {
  posst: PosstSchema;
  /** optionally use a listener for this posst to get live updates */
  posstId?: PosstSchema["_pid"];
  /** optionally keep track of index in array */
  index?: number;
}
