/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.08.30
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function getYoutubeId(value: string) {
  if (value.includes("youtube.com/shorts"))
    return value.replace("https://www.youtube.com/shorts/", "");
  let id = value.includes("youtube.com")
    ? value.replace("https://www.youtube.com/watch?v=", "")
    : value.replace("https://youtu.be/", "");
  id = id.substring(0, id.includes("?") ? id.indexOf("?") : id.length);

  return id;
}
