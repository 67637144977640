import { Buttoon, Kitten, Txt } from "components";
import { useTwoColumns } from "components/templates";
import { useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { useNavigator } from "screens";
import { tr } from "utils";
import { ass } from "utils/storage";
import { fuego } from "../../../../fuego";
import { PersonaliEditCenter } from "./PersonaliEditCenter";

export function ProfileCenter() {
  const refRenderCount = React.useRef(0);
  const { C, theme, setTheme, setTeamId, frbsUser } = useAppContext();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();
  const { column1ContainerStyle } = useTwoColumns();
  return (
    <Kitten.Card disabled>
      <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>

      <PersonaliEditCenter uid={userProfile?.data?._id} />
      <Buttoon
        progress
        onPress={(xong) => {
          // Backend.authHandler.signOut(() => setUserProfile(null));
          // Navigation.dispatch(StackActions.replace("Home"));

          fuego
            .auth()
            .signOut()
            .then((r) => {
              xong && xong();
            });
          Navigation.navigate("Home");
          ass.clear();
          setTeamId("");
        }}
        disabled={!frbsUser}
        appearance="ghost"
        status="danger"
      >
        {tr("Sign out")}
      </Buttoon>
      {/* <Buttoon
        disabled={!!auth.currentUser}
        onPress={() =>
          fuego
            .auth()
            .signInWithEmailAndPassword("drkhoi16@gmail.com", "123456")
            .then((e) => console.log("done", e))
            .catch((e) => console.warn(e))
        }
      >
        Sign in
      </Buttoon> */}
    </Kitten.Card>
  );
}
