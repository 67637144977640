import { Kitten } from "components";
import { Buttoon, Poppy, Toasty } from "components/atoms";
import { RescheduleMail } from "components/molecules";
import { ScheduleSchema, useAppContext } from "engines";
import { useScheduleDocument } from "engines/firebase/handler/emails-handler";
import React from "react";
import { StyleSheet } from "react-native";
import { WebView } from "react-native-webview";
import { useNavigator } from "screens";
import { tr } from "utils";
/**
 * ### List Item for Member
 *  - Including some member's action
 *  ----
 *  @version 21.03.20
 *  -  *Move this component to ./molecules*
 *  @author  K
 *
 **/
export function ScheduleListItem(props: dScheduleListItem) {
  const { item, docID } = props;
  const { C } = useAppContext();
  const handleSchedule = useScheduleDocument(docID);
  const Navigation = useNavigator();
  const [toggleDate, setToggleDate] = React.useState<boolean>(false);
  const [scheduleAt, setScheduleAt] = React.useState<string>("");

  const toggleShowDate = async () => {
    setToggleDate(!toggleDate);
  };

  const openRescheduleMail = async () => {
    //Navigation.navigate<dOverlayParams>("Overlay", {
    Navigation.dialogPortal.open({
      headerTitle: tr("Reschedule Mail"),
      render: <RescheduleMail docID={docID} />,
    });
  };

  const openScheduleMail = async () => {
    //Navigation.navigate<dOverlayParams>("Overlay", {
    Navigation.dialogPortal.open({
      headerTitle: tr("Reschedule Mail Preview"),
      render: (
        <>
          <WebView
            source={{ html: item.email_html_body }}
            style={{ flex: -1, width: "100%", height: 700 }}
          />
        </>
      ),
    });
  };

  const deleteSchedule = async () => {
    handleSchedule
      .deleteDocument()
      .then((res) => {
        Toasty.show("Delete Successful", { type: "success" });
        // getEmailTemplate();
      })
      .catch(() => {
        Toasty.show("Failed to delete", { type: "danger" });
      });
  };
  return (
    <>
      <Kitten.ListItem
        title={item.email_subject}
        onPress={() => openScheduleMail()}
        description={`Email scheduled at ${item.scheduleDate}`}
        // accessoryLeft={() => <UserAvatar size={40} name={item.email_subject} />}
        accessoryRight={() => (
          <>
            <Buttoon
              icon={{ name: "pencil" }}
              status={"primary"}
              appearance={"ghost"}
              style={{ alignSelf: "flex-end" }}
              onPress={() => {
                openRescheduleMail();
              }}
            />
            <Buttoon
              icon={{ name: "trash" }}
              status={"danger"}
              appearance={"ghost"}
              style={{ alignSelf: "flex-end" }}
              onPress={() => {
                Poppy(
                  tr("Final Check"),
                  tr("Do you want to delete this email schedule?"),
                  {
                    text: "Cancel",
                    onPress: () => {},
                  },
                  {
                    text: "Yes",
                    onPress: async () => {
                      await deleteSchedule();
                    },
                  }
                );
              }}
            />
          </>
        )}
      ></Kitten.ListItem>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  contentContainer: {
    flex: 1,
  },
  title: {
    textAlign: "left",
  },
  description: {
    textAlign: "left",
  },
});

export interface dScheduleListItem {
  item: ScheduleSchema;
  docID: string;
}
