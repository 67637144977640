import { useDocument } from "@nandorojo/swr-firestore";
import { FPATH } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import firebase from "firebase";
import { useCollectionPresetv2 } from "../config";


/**
 * ###
 *  - Contact manager
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.11.25
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

/**
 * Used in updating contacts, getting contacts details, deleting of contacts
 * @param userId
 * @param contactId
 * @param query
 * @returns
 */
export function useContactDocument(userId: string, contactId: string, query?) {
  const defaultDocumentFunctions = useDocument<ContactSchemaa>(
    `${FPATH.USERS}/${userId}/${FPATH.CONTACTS}/${contactId}`,
    {
      listen: true,
      ...query,
    }
  );

  return {
    ...defaultDocumentFunctions,
  };
}

/**
 * Used in getting contact collection data, and adding a new contact
 * @param userId
 * @param groupId
 * @returns
 */
export function useContactsCollection(userId: string, groupId?: string) {
  const defaultCollectionFunctions = useCollectionPresetv2<ContactSchemaa>(
    `${FPATH.USERS}/${userId}/${FPATH.CONTACTS}`,
    "_cid",
    groupId ? { where: ["groups", "array-contains", groupId] } : {} // may need to run refactor so all groups arrays are updated
  );

  return { ...defaultCollectionFunctions };
}
export function useContactsCollectionWithAnyGroups(userId: string, groups: string[]) {
  const defaultCollectionFunctions = useCollectionPresetv2<ContactSchemaa>(
    `${FPATH.USERS}/${userId}/${FPATH.CONTACTS}`,
    "_cid",
    { where: ["groups", "array-contains-any", groups] }
  );

  return { ...defaultCollectionFunctions };
}

// must be outside the hook to avoid infinite loop since date object is always changing (deprecated now though)
const endOfToday = new Date();
endOfToday.setUTCHours(23, 59, 59, 999);
/**
 * @deprecated use the hook now cuz it's chill
 * Returns all the contacts that need to be followed up with
 * (followUpDate is less than end of today)
 * (we can't use a swr hook cuz date queries are broken with them)
 *
 * @author jm_francis
 *
 * @example
 * const _contacts = await getFollowUpContacts(frbsUser?.uid)
 */
export async function getFollowUpContacts(
  userId: string
): Promise<ContactSchemaa[]> {
  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(endOfToday.getDate() - 30);

  const query = await firebase
    .firestore()
    .collection(`${FPATH.USERS}/${userId}/${FPATH.CONTACTS}`)
    .where("followUpDate", ">=", thirtyDaysAgo)
    // .where("followUpDate", "<=", endOfToday)
    .get();
  const _contacts: ContactSchemaa[] = query.docs
    .map((d) => d.data() as ContactSchemaa)
    .filter((c) => c._cid);

  return _contacts;
}

export async function getTodaysCompletedFollowUpContacts(
  userId: string
): Promise<ContactSchemaa[]> {
  const now = new Date();
  const startOfDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0
  );
  const endOfDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59
  );
  const query = await firebase
    .firestore()
    .collection(`${FPATH.USERS}/${userId}/${FPATH.CONTACTS}`)
    .where("lastFollowUp", ">=", startOfDay)
    .where("lastFollowUp", "<=", endOfDay)
    .get();
  const _contacts: ContactSchemaa[] = query.docs
    .map((d) => d.data() as ContactSchemaa)
    .filter((c) => c._cid);
  return _contacts;
}

/**
 * Used in getting all contacts that need to be followed up with today or have not been followed up with from a previous date
 */
export function useFollowUpContacts(userId: string) {
  // endOfToday.setHours(23, 59, 59, 999);
  // endOfToday.setUTCHours(23, 59, 59, 999);
  const defaultCollectionFunctions = useCollectionPresetv2<ContactSchemaa>(
    `${FPATH.USERS}/${userId}/${FPATH.CONTACTS}`,
    "_cid",
    {
      where: ["followUpDate", "!=", null],
    }
  );

  return { ...defaultCollectionFunctions };
}
