//#region [import]
import { Buttoon, Kitten, sstyled, Txt } from "components";
import { correctHTML, fn, useAppContext, useCalendar } from "engines";
import * as React from "react";
import { FlatList, Pressable, View } from "react-native";
import HTML from "react-native-render-html";
import { useNavigator } from "screens";
// import HTML from "react-native-render-html";
import { moderateScale, spacing, tr } from "utils";

//#endregion

/**
 * ###  Screen of Calendar and its CRUD ops
 * -  The Screen contains specific layout.
 * -  Some screen may use `$_QuickGlance`, which is the core <_> of CalendarScreen
 * @version 1.1.23
 * @author K
 */
export function CalendarGlance({ badgeValueCallback }) {
  const { C } = useAppContext();
  const Navigation = useNavigator();

  const { data: _events, loading, error, fetch } = useCalendar();

  const todaysEvents = _events?.filter(
    (event) =>
      event &&
      fn.calendar.dateFormat(event.date) === fn.calendar.dateFormat(new Date())
  );
  React.useEffect(
    function updateEventCount() {
      loading || !todaysEvents
        ? badgeValueCallback && badgeValueCallback("...")
        : badgeValueCallback && badgeValueCallback(todaysEvents?.length);
    },
    [_events, loading]
  );
  try {
    return (
      <View>
        {/* <ListHeader
          titleTx="Today's events"
          actions={[
            {
              label: tr("Full Calendar"),
              onPress: () => Navigation.navigate("calendar-home-scr"),
            },
          ]}
        /> */}

        <A.CardContainer>
          <Kitten.ListItemv2
            title={tr("Calendar")}
            disabled
            accessoryRight={() => (
              <Txt.H5>{fn.calendar.dateFormat(new Date())}</Txt.H5>
            )}
            style={{ backgroundColor: C.surface }}
          />
          <FlatList
            data={todaysEvents}
            keyExtractor={(item, index) =>
              index + "__" + item.description?.length
            }
            renderItem={({ item, index }) => (
              <A.CardEvent
                key={index + "__" + item.description?.length}
                delay={100 * index}
                duration={400}
              >
                <View>
                  <Txt.$Title>{`${item.title}`}</Txt.$Title>
                  <Txt.$Title>{`${item.time}`}</Txt.$Title>
                  <Kitten.Divider_________ />
                  <HTML
                    source={{ html: correctHTML(item.description) }}
                    // contentWidth={230}
                    baseFontStyle={{
                      color: C.text,
                      fontSize: moderateScale(14),
                    }}
                    tagsStyles={{
                      a: {
                        color: C.primaryDarker,
                        fontSize: moderateScale(14),
                        fontWeight: "bold",
                      },
                      p: {
                        fontSize: moderateScale(14),
                      },
                    }}
                  />
                </View>
              </A.CardEvent>
            )}
            // renderItem={({ item, index }) => (
            //   <A.CardEvent
            //     key={index + "__" + item.description?.length}
            //     delay={100 * index}
            //     duration={400}
            //   >
            //     <View>
            //       <Txt.$Title>{`${item.title}\n${item.time}`}</Txt.$Title>
            //       <Kitten.Divider_________ />
            //       <HTML
            //         source={{ html: correctHTML(item.description) }}
            //         // contentWidth={230}
            //         baseFontStyle={{
            //           color: C.text,
            //           fontSize: moderateScale(14),
            //         }}
            //         tagsStyles={{
            //           a: {
            //             color: C.primaryDarker,
            //             fontSize: moderateScale(14),
            //             fontWeight: "bold",
            //           },
            //           p: {
            //             fontSize: moderateScale(14),
            //           },
            //         }}
            //       />
            //     </View>
            //   </A.CardEvent>
            // )}
            ListEmptyComponent={() => (
              <Txt.Helper marginV onPress={fetch}>
                {loading ? tr("Getting events...") : tr("No events today")}
              </Txt.Helper>
            )}
          />
          <Buttoon
            appearance={"ghost"}
            size={"small"}
            status="basic"
            icon={{ name: "chevron_right", right: true }}
            onPress={() => Navigation.navigate("calendar-home-scr")}
          >
            {tr("Show full calendar")}
          </Buttoon>
        </A.CardContainer>
      </View>
    );
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}

const A = {
  CardContainer: sstyled(Pressable)({
    backgroundColor: "surface",
    margin: spacing(2),
  }),
  TxtToday: sstyled(Txt.H4)({
    paddingHorizontal: spacing(3),
    textAlign: "center",
  }),
  CardEvent: sstyled(Kitten.Card)({
    marginBottom: spacing(3),
  }),
  // CardEvent: sstyled(createAnimatableComponent(Kitten.Card))({
  //   marginBottom: spacing(3),
  // }),
};
