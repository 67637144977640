import firebase from "firebase"
import { IconPrimr } from "assets";
import { Avt, Kitten, ListHeader, Poppy, sstyled, Toasty } from "components";
import { ListItemv2 } from "components/atoms/externals/ui-kitten/list-item-v2";
import { FRBS_ROLE, useAppContext, UserSchema, useUserProfile } from "engines";
import { Linking, View } from "react-native";
import React from "react";
import { smallerSizeFromURL } from "engines/functions/media-functions";
import { MenuIcon } from "assets/icons/MenuIcon";
import { useNavigator } from "screens";

export function EditMember(p: { user: UserSchema, onSaveChanges: (user: Partial<UserSchema>) => void }) {
    const { user, onSaveChanges } = p

    const { C, frbsUser, setFrbsUser } = useAppContext()
    const myselfHandler = useUserProfile()

    const Navigation = useNavigator()

    const [isAdmin, setIsAdmin] = React.useState<boolean>(user.roles?.includes(FRBS_ROLE.ADMIN));
    const [isTrainer, setIsTrainer] = React.useState<boolean>(user.roles?.includes(FRBS_ROLE.TRAINER));
    const [isBanned, setIsBanned] = React.useState<boolean>(user.roles?.includes(FRBS_ROLE.BANNED));

    const toggleBan = () => {
        if (!isBanned) {
            Poppy("Member Banned", `${user.personali?.displayName} will no longer be granted access to the app unless you unban them in the future.`)
        } else {
            Toasty.show("Unbanned member", { type: "normal" })
        }
        setIsBanned(!isBanned)
        onSaveChanges({
            // @ts-ignore FieldValue
            roles: !user.roles?.includes(FRBS_ROLE.BANNED) ? firebase.firestore.FieldValue.arrayUnion(FRBS_ROLE.BANNED) : firebase.firestore.FieldValue.arrayRemove(FRBS_ROLE.BANNED),
        })
    }

    function toggleAdmin() {
        if (!isAdmin) {
            Toasty.show(`${user.personali?.displayName} is now an admin`)
        } else {
            Toasty.show(`${user.personali?.displayName} is no longer an admin`)
        }
        setIsAdmin(!isAdmin)
        onSaveChanges({
            // @ts-ignore FieldValue
            roles: !user.roles?.includes(FRBS_ROLE.ADMIN) ? firebase.firestore.FieldValue.arrayUnion(FRBS_ROLE.ADMIN) : firebase.firestore.FieldValue.arrayRemove(FRBS_ROLE.ADMIN),
        })
    }
    function toggleTrainer() {
        if (!isTrainer) {
            Toasty.show(`${user.personali?.displayName} is now a trainer`)
        } else {
            Toasty.show(`${user.personali?.displayName} is no longer a trainer`)
        }
        setIsTrainer(!isTrainer)
        onSaveChanges({
            // @ts-ignore FieldValue
            roles: !user.roles?.includes(FRBS_ROLE.TRAINER) ? firebase.firestore.FieldValue.arrayUnion(FRBS_ROLE.TRAINER) : firebase.firestore.FieldValue.arrayRemove(FRBS_ROLE.TRAINER),
        })
    }

    return <View style={{ flex: 1 }}>

        {
            //#region [section] Roles
            myselfHandler._isRolesContain([FRBS_ROLE.ADMIN, FRBS_ROLE.DEVELOPER]) ? (
                <>
                    <ListHeader titleTx={"Roles"} />
                    <A.Menu>
                        <Kitten.MenuItem
                            title="Admin"
                            accessoryLeft={(p) => MenuIcon("admin", p)}
                            onPress={toggleAdmin}
                            accessoryRight={() => (
                                <Kitten.CheckBox
                                    //* Admin stuff
                                    checked={isAdmin || false}
                                    onChange={toggleAdmin}
                                />
                            )}
                        />
                        <Kitten.MenuItem
                            title="Field Trainer"
                            accessoryLeft={(p) => MenuIcon("compass", p)}
                            onPress={toggleTrainer}
                            accessoryRight={() => (
                                <Kitten.CheckBox
                                    //* Trainer stuff
                                    checked={isTrainer || false}
                                    onChange={toggleTrainer}
                                />
                            )}
                        />
                    </A.Menu>
                </>
            ) : null
            //#endregion
        }

        <ListHeader titleTx="Actions" />

        {user.growth?.listBuilder?.shareTo?.includes(frbsUser?.uid) ?
            <ListItemv2 title="Remove as trainee"
                style={{ backgroundColor: C.hazardYellow }}
                accessoryRight={() => <IconPrimr name="profile" color={C.text} />}
                onPress={() => {
                    Poppy("Are you sure?", `Removing ${user.personali?.displayName} as your trainee means you will no longer see: their contacts, training progress, and instant notifications relating to their activity.`, {
                        text: "Cancel",
                        onPress: () => { }
                    }, {
                        text: "Confirm",
                        onPress: () => {
                            //@ts-ignore
                            onSaveChanges({ growth: { listBuilder: { shareTo: firebase.firestore.FieldValue.arrayRemove(frbsUser?.uid) } } })
                            Toasty.show("Removed trainee")
                            Navigation.goBack()
                        }
                    })
                }} />
            : null}

        <ListItemv2 title="Download Profile Image"
            style={{ backgroundColor: C.accentDarker }}
            onPress={() => {
                Linking.openURL(user?.personali?.photoURL)
            }}
            accessoryRight={() => <Avt name={user?.personali?.displayName} source={{ uri: smallerSizeFromURL(user?.personali?.photoURL, 80) }} />} />

        {myselfHandler._isRolesContain([FRBS_ROLE.ADMIN, FRBS_ROLE.DEVELOPER]) ?
            <ListItemv2
                title={isBanned ? "Unban Member" : "Ban Member from App"}
                onPress={toggleBan}
                style={{ backgroundColor: C.errorRed }}
                accessoryRight={() => <IconPrimr name="ban" color={C.text} />} />
            : null}

        {myselfHandler._isRolesContain([FRBS_ROLE.DEVELOPER]) ? <ListItemv2 onPress={() => {
            setFrbsUser({
                ...frbsUser,
                uid: user?._id,
                normalUid: frbsUser?.uid,
            });
            Toasty.show("Enjoy being not you!");
        }} title="Imitate User" style={{ backgroundColor: C.dim }} accessoryRight={() => <IconPrimr name="admin" color={C.text} />} /> : null}
    </View>
}

const A = {
    Menu: sstyled(Kitten.Menu)({
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "dim",
    }),
};
