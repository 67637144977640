//#region [import]
import { O, sstyled, Txt } from "components";
import { useBreakpointIndex } from "dripsy";
import React from "react";
import { ImageBackground } from "react-native";
import { tr } from "utils";

//#endregion

/**
 * ### Brief description
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 1.04.3
 *  -  *Brief changelog*
 *  @author  NL
 *
 **/
export function MassMailComposeScreen(props: P) {
  const breakpoint = useBreakpointIndex();

  try {
    return breakpoint <= 2 ? (
      <A.Ctnr
        source={{
          uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/q_auto:low/v1622731805/apptakeoff/icons/rocket_vdr9ge.gif",
        }}
        resizeMode="cover"
        blurRadius={80}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Txt.Indicator>
          {tr("Sorry, this component only works with larger screen size.")}
        </Txt.Indicator>
      </A.Ctnr>
    ) : (
      <A.Ctnr source={{ uri: "" }}>
        <O.MailCompose docID={"_"} />
      </A.Ctnr>
    );
  } catch (error) {
    throw Error(`::TWFzc01haWxDb21wb3NlU2NyZWVu::${error}`);
    
  }
}

const A = {
  Ctnr: sstyled(ImageBackground)({
    flex: 1,
    backgroundColor: "background",
  }),
};
interface P {
  // route?: { params?: dMassMailHomeParams }; //* unmask if we pass params thru rnav
}
