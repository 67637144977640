import { Toasty } from "components";
import {
  ContactSchemaa,
  defaultQualification,
} from "engines/backends/schemas/bml";
import { useConfigDocument } from "engines/firebase";
/**
 * ### BML Mobile Provider,
 *
 * providing:
 * -    [contacts]
 * ---
 * @version 0.12.23.PR
 * - *What's changed? [...]*
 * @author nguyenkhooi
 * ---
 * @example
 *    import {BMLMProvider} from `engines`
 */ import { useAppContext } from "engines/hooks";
import * as Contacts from "expo-contacts";
import React, { useContext, useState } from "react";
import { Alert, Linking } from "react-native";
import { dQualification } from "../backends/schemas/configs/configs.schema";
import { androidPrecheckIfNeeded } from "../functions/contacts-functions/fetchContacts";

export function BMLMProvider(props: dBMLMProvider) {
  const { children } = props;
  const { teamId } = useAppContext();
  const [permStatus, setPermStatus] = React.useState<Contacts.PermissionStatus>(
    Contacts.PermissionStatus.UNDETERMINED
  );

  /** All Contacts from the phone */
  const [contacts, setContacts] = useState<Contacts.Contact[]>([]);

  const [refreshList, setRefreshList] = useState(true);
  // NOTE Qualification Data from the Config/variables document for that hierarchy. If not present then use the default one
  const [qualificationData, setQualificationData] = useState<dQualification[]>(
    []
  );
  const [bmlContacts, setBmlContacts] = useState<ContactSchemaa[]>([]);
  const configHandler = useConfigDocument("variables-" + teamId);

  const getPermissions = async () => {
    await androidPrecheckIfNeeded();
    const { status } = await Contacts.requestPermissionsAsync();
    // const { granted } = await Contacts.getPermissionsAsync();
    //console.log("Contacts Permission status :>> ", status);
    setPermStatus(status);
    if (status !== Contacts.PermissionStatus.GRANTED) {
      //TODO Check with Khoi on this, Check line 12-16 app.config.ts
      Alert.alert(
        "Need Contacts Permission",
        "Please Manually Allow Permission to access your phone contacts before using this feature.",
        [
          {
            text: "Ok",
            onPress: () => {
              Linking.canOpenURL("app-settings:")
                .then((supported) => {
                  if (!supported) {
                    console.log("Can't handle settings url");
                  } else {
                    return Linking.openURL("app-settings:");
                  }
                })
                .catch((err) => console.error("An error occurred", err));
            },
          },
        ]
      );

      // setTimeout(() => Navigation.navigate("Home"), 1000);
    }
  };

  React.useEffect(() => {
    Contacts.getPermissionsAsync().then((resp) => {
      if (resp.granted) {
        setPermStatus(Contacts.PermissionStatus.GRANTED);
      }
    });
  }, []);

  // #region [ANCHOR] NOTE Obtaining the default qualification from config/variables-teamId document
  React.useEffect(() => {
    if (configHandler.data) {
      if (configHandler.data.qualifications) {
        setQualificationData(
          Array.isArray(configHandler.data.qualifications) &&
            configHandler.data.qualifications?.filter((q) => !q.isDisabled)
        );
      } else {
        // console.log("🔥 Saving default qualifications");
        setQualificationData(defaultQualification);
        configHandler.set(
          { qualifications: defaultQualification },
          { merge: true }
        );
      }
    }
  }, [configHandler.data]);
  // #endregion

  // #region [ANCHOR2] NOTE Obtain all the phone contacts
  const getContacts = async (props?: any) => {
    try {
      const { granted } = await Contacts.getPermissionsAsync();

      if (granted) {
        const { data: contacts } = await Contacts.getContactsAsync({
          fields: [
            Contacts.Fields.Emails,
            Contacts.Fields.Name,
            Contacts.Fields.PhoneNumbers,
            Contacts.Fields.Image,
          ],
        });
        setContacts(contacts);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      Toasty.show(
        "Error while getting your phone contacts. Please Grant Permission.",
        { type: "danger" }
      );
      return false;
    }
  };
  // #endregion

  // #region [section2] NOTE Obtain the permission from user to access contacts
  React.useEffect(() => {
    if (permStatus === Contacts.PermissionStatus.GRANTED) {
      if (!getContacts())
        Toasty.show("You'll need to grant permission to use this feature.", {
          type: "warning",
        });
    }
  }, [permStatus]);
  // #endregion;

  /**
   *
   * @param ids Array of Qualification Ids (check dQualification interface)
   * @returns Array of Names corresponding to those Ids
   */
  const getQualificationNames = (ids: string[]) => {
    if (ids) {
      const names = ids
        .map((id) => {
          // return qualificationData?.find((q) => q._id === id && !q.isDisabled)
          const name = qualificationData?.find((q) => q._id === id)?.name;
          return name;
        })
        .filter((c) => c !== undefined)
        .sort((c1, c2) => c1?.localeCompare(c2));
      return names;
    } else {
      return [];
    }
  };

  /**
   *
   * @param ids Array of Qualification Ids (check dQualification interface)
   * @returns Total points from qualifications that count towards the point system.
   */
  const getPointers = (ids: string[]) => {
    if (ids) {
      const points = qualificationData.filter(
        // (q) => q.countTowardsPointer && !q.isDisabled && ids.includes(q._id)
        (q) => q.countTowardsPointer && ids.includes(q._id)
      ).length;
      return points;
    } else {
      return 0;
    }
  };

  return (
    <BMLMContext.Provider
      value={{
        bmlContacts,
        setBmlContacts,
        contacts,
        setContacts,
        getPermissions,
        permStatus,
        qualificationData,
        getQualificationNames,
        getPointers,
        refreshList,
        setRefreshList,
      }}
    >
      {children}
    </BMLMContext.Provider>
  );
}

export const BMLMContext = React.createContext<dBMLMContext>(null);

/**
 * BMLM context hook
 *
 * ---
 * @example
 * ```
 * const { [...] } = useBMLMContext()
 * ```
 *
 * @version 0.12.23
 * - *What's changed? [...]*
 * @author nguyenkhooi
 */
export const useBMLMContext = () => useContext(BMLMContext);

interface dBMLMProvider {
  children: React.ReactNode;
}
interface dBMLMContext {
  bmlContacts: ContactSchemaa[];
  contacts: Contacts.Contact[];
  setBmlContacts: React.Dispatch<React.SetStateAction<ContactSchemaa[]>>;
  setContacts: React.Dispatch<React.SetStateAction<Contacts.Contact[]>>;
  getPermissions: () => Promise<void>;
  permStatus: Contacts.PermissionStatus;
  qualificationData: dQualification[];
  getQualificationNames: (ids: string[]) => string[];
  getPointers: (ids: string[]) => number;
  refreshList: boolean;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}
