import { useFocusEffect } from "@react-navigation/native";
import { MenuIcon } from "assets/icons/MenuIcon";
import { sstyled } from "components";
import { dAccessory, Kitten } from "components/atoms";
import { BroadcastList } from "components/molecules";
import { useTwoColumns } from "components/templates";
import { MediaPageSchema, NotificationStatus, useAppContext } from "engines";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { tr } from "utils";
import { BroadcastCreator } from "./BroadcastCreator";

/**
 * ### List of members of the app (aka teamId's members)
 *  - Including some quick actions as an swipeable item
 *  ----
 *  @version 21.09.07
 *  -  *Memoize it*
 *  @author  K
 *
 **/
export function BroadcastCreatorCenter(props: P) {
  const { C } = useAppContext();
  const { bsid, handlerPage, scheduled } = props;
  const Navigation = useNavigator();

  const { breakpointForTwoColumns } = useTwoColumns();
  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  const handleBroadcastCreator = () =>
    Navigation.dialogPortal.open({
      headerTitle: tr("Create contact category"),
      render: <BroadcastCreator bsid={`${bsid}`} handlerPage={handlerPage} />,
    });

  React.useEffect(function triggering() {
    !breakpointForTwoColumns && (Navigation.goBack(), handleBroadcastCreator());
  }, []);

  return React.useMemo(() => {
    try {
      return (
        <A.ViewContainer
          style={
            handlerPage && bsid
              ? { borderColor: C.infoBlue, borderWidth: 1 }
              : null
          }
        >
          <A.CtnrMenu
            selectedIndex={null}
            onSelect={(index) => {
              // setSelectedIndex(index);
              switch (index.row) {
                case 0:
                  Navigation.overlayPortal.open({
                    headerTitle: `${
                      handlerPage && bsid
                        ? `Send Notification to ${handlerPage?.name}`
                        : `Broadcast Notification`
                    }`,
                    render: (
                      <BroadcastCreator
                        bsid={`${bsid}`}
                        handlerPage={handlerPage}
                      />
                    ),
                  });
                  break;
                case 1:
                  Navigation.dialogPortal.open({
                    headerTitle: `${
                      handlerPage && bsid
                        ? `Send Notification to ${handlerPage?.name}`
                        : `Broadcast Notification`
                    }`,
                    render: (
                      <BroadcastList bsid={`${bsid}`} scheduled={scheduled} />
                    ),
                  });
                  break;
              }
            }}
          >
            <Kitten.MenuItem //* Admin stuff
              title={tr("Broadcast a message")}
              accessoryLeft={(props: dAccessory) => MenuIcon("bullhorn", props)}
            />
            {handlerPage && bsid ? (
              <Kitten.MenuItem //* Admin stuff
                title={tr("Scheduled Notifications")}
                accessoryLeft={(props: dAccessory) =>
                  MenuIcon("calendar", props)
                }
              />
            ) : null}
          </A.CtnrMenu>
        </A.ViewContainer>
      );
    } catch (error) {
      throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
    }
  }, [C]);
}

const A = {
  ViewContainer: sstyled(View)({
    marginVertical: 3,
    // minWidth: 300,
  }),
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
};

interface P {
  bsid?: string;
  handlerPage?: MediaPageSchema;
  scheduled?: NotificationStatus;
}
