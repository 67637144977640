import { IconPrimr } from "assets";
import { Buttoon, Kitten, Poppy, sstyled, Toasty, Txt } from "components";
import {
  correctHTML,
  dAttendance,
  dCalendarEvent,
  fn,
  useAppContext,
} from "engines";
import * as Calendar from "expo-calendar";
import * as Linking from "expo-linking";
import React from "react";
import { Alert, Platform, Share, useWindowDimensions } from "react-native";
import RenderHtml from "react-native-render-html";
import { useNavigator } from "screens/_navigation";
import { IS_WEB, moderateScale, spacing, tr } from "utils";
import { AttendnaceOverview } from "./AttendanceOverview";
import { GuestEntryForm } from "./GuestEntryForm";

export function EventCard(p: {
  event: dCalendarEvent;
  userAttendance: dAttendance[];
  onAttended: (event: dCalendarEvent) => void;
  hideViewAttendees?: boolean;
}) {
  const { event, userAttendance, onAttended, hideViewAttendees } = p;

  const { C } = useAppContext();
  const Navigation = useNavigator();

  const [didAttend, setDidAttend] = React.useState<boolean>(false);
  const [attendance, setAttendance] = React.useState<dAttendance>(null);

  React.useEffect(
    function updateAttended() {
      if (!userAttendance) return;
      setDidAttend(
        userAttendance.find((e) => e.meetingId === event.eventId) ? true : false
      );
      setAttendance(userAttendance.find((e) => e.meetingId === event.eventId));
    },
    [userAttendance]
  );

  let itemLink =
    (event.location?.description?.length > 0 &&
      event.location.description?.includes("https://")) ||
      event.location.description?.includes("http://")
      ? event.location.description
      : null;
  const startKeyword =
    event.description && event.description?.includes("https://")
      ? "https://"
      : event.description?.includes("http://")
        ? "http://"
        : null;
  if (startKeyword) {
    const words = event.description.split(" ");
    for (let w in words) {
      const word = words[w];
      if (word?.includes(startKeyword)) {
        itemLink = word.substring(word.indexOf("http"), word.length);
        // if (itemLink.includes("<"))
        //   itemLink = itemLink.substring(itemLink.indexOf("<"));
        // if (itemLink.includes('"'))
        //   itemLink = itemLink.substring(itemLink.indexOf('"'));
        // item.description = item.description.replace(`${itemLink}`, "");
        break;
      }
    }
  }
  const meetingStartDate = event.date;
  const meetingEndDate = event.endDate;
  const sevenMinutesMillis = 420000;
  const currentDate = new Date();
  const isMeetingLive =
    currentDate?.getTime() > meetingStartDate?.getTime() &&
    currentDate?.getTime() < meetingEndDate?.getTime();
  const meetingAboutToStart =
    currentDate?.getTime() > meetingStartDate?.getTime() - sevenMinutesMillis &&
    currentDate?.getTime() < meetingStartDate?.getTime();
  const isMeetingOver = currentDate.getTime() > meetingEndDate.getTime();

  const { width } = useWindowDimensions();

  // console.log("LINK: " + itemLink);
  return (
    <A.CardEvent
      key={event.eventId}
      // animation={"fadeInRight"}
      // delay={100 * (index + 1)}
      // duration={400}
      onPress={() => {
        if (itemLink) {
          // Toasty.show("Opening " + itemLink);
          Linking.openURL(itemLink);
        } else {
          Toasty.show("No url found.", { type: "normal" });
        }
      }}
      style={{ marginHorizontal: spacing(1), flex: 1 }}
    >
      <Kitten.ViewH style={{ justifyContent: "space-between" }}>
        <Kitten.ViewH>
          <IconPrimr
            preset="circular"
            name="dot"
            color={
              event?.personalCalendarId
                ? C.infoBlue
                : C["color-warning-600"]
            }
          />
          <Txt.$Title numberOfLines={0} style={{ width: "90%", color: C.text }}>{`${event.title}\n${event.time}`}</Txt.$Title>
        </Kitten.ViewH>
      </Kitten.ViewH>

      <Kitten.Divider_________ />
      {/* <Txt>
      {JSON.stringify(
        item.description
          .replace(/html-blob/g, "p")
          .replace(/<u><\/u>/g, "")
      )}
    </Txt> */}
      <RenderHtml
        onLinkPress={(e, href) => Linking.openURL(href)}
        baseFontStyle={{ color: C.text, fontSize: moderateScale(14) }}
        source={{
          html: `<html>${correctHTML(event.description)}
          ${itemLink && !event.description.includes(itemLink)
              ? `<br /><b><a href="${itemLink}">${itemLink}</a></b>`
              : event.location.description
                ? `<br /><b>${event.location.description}</b>`
                : ""
            }
          </html>`,
        }}
        // source={{html: `<html>${item.description}</html>`}}
        // contentWidth={"100%"}
        // containerStyle={{ backgroundColor: "blue" }}
        contentWidth={width}
        // baseStyle={{ color: C.text, fontSize: moderateScale(14) }}
        tagsStyles={{
          a: {
            color: C.primaryDarker,
            fontSize: moderateScale(14),
            fontWeight: "bold",
          },
          p: {
            fontSize: moderateScale(14),
          },
        }}
      />
      <Txt style={{ color: "transparent" }}>
        {event.description?.replace(/html-blob/g, "html")?.substring(0, 30)}
      </Txt>
      <Kitten.Divider_________ />
      <Kitten.ViewH
        style={{
          justifyContent: "space-between",
          marginVertical: spacing(3),
        }}
      >
        {meetingAboutToStart ? (
          <Txt.Indicator style={{ color: C.hazardYellow }}>
            Meeting about to start
          </Txt.Indicator>
        ) : isMeetingLive ? (
          <Txt.Indicator style={{ color: C.primary }}>
            This meeting is live!
          </Txt.Indicator>
        ) : isMeetingOver ? (
          <Txt.Indicator>This meeting has ended.</Txt.Indicator>
        ) : (
          null
        )}
        {!didAttend && (isMeetingLive || meetingAboutToStart) ? (
          <Buttoon
            onPress={async () => {
              setDidAttend(true);
              onAttended && onAttended(event);
            }}
            size="small"
            status="basic"
            appearance="filled"
          >
            I'm here ✋
          </Buttoon>
        ) : didAttend ? (
          <Kitten.ViewH>
            <IconPrimr name="check" color={C.primary} size={15} />
            <Txt.S1 style={{ color: C.primary }}>Attended</Txt.S1>
          </Kitten.ViewH>
        ) : null}
      </Kitten.ViewH>
      <Kitten.ViewH
        style={{ marginTop: spacing(3), justifyContent: "space-evenly", flexWrap: "wrap" }}
      >
        {(isMeetingOver || isMeetingLive) && !hideViewAttendees ? (
          <>
            <Buttoon
              icon={{ name: "people" }}
              onPress={() => {
                Navigation.dialogPortal.open({
                  headerTitle: tr(`${event.title} Attendance`),
                  render: <AttendnaceOverview event={event} />,
                });
              }}
              // appearance="outline"
              status="info"
              style={{ width: "49%" }}
              size="small"
            // style={{ marginTop: spacing(2) }}
            >
              View attendance
            </Buttoon>
            {didAttend ? (
              <Buttoon
                onPress={() => {
                  Navigation.dialogPortal.open({
                    headerTitle: "Add Guests",
                    render: (
                      <GuestEntryForm
                        attendance={attendance}
                        event={event}
                        onGuestsSaved={(_guests) => {
                          setAttendance({ ...attendance, guests: _guests });
                        }}
                      />
                    ),
                  });
                }}
                size="small"
                status={"control"}
                icon={
                  isMeetingOver
                    ? null
                    : { name: attendance?.guests?.length > 0 ? "pencil" : "plus" }
                }
                style={{ width: "49%" }}
              >
                {isMeetingOver
                  ? `View my guests (${attendance?.guests.length || 0})`
                  : attendance?.guests?.length > 0
                    ? `Edit ${attendance?.guests?.length} guests`
                    : "Add Guests"}
              </Buttoon>
            ) : null}
          </>
        ) :
          (<>
            {meetingAboutToStart ? null : <Buttoon
              // appearance="outline"
              status="basic"
              icon={{ name: "calendar" }}
              size="small"
              onPress={async () => {
                if (IS_WEB) {
                  Toasty.show(
                    "You must use this feature from your mobile device.",
                    { type: "warning" }
                  );
                  return;
                }
                await Calendar.requestCalendarPermissionsAsync();

                const eventDetails = {
                  title: event.title,
                  startDate: event.date,
                  endDate: event.endDate,
                };

                const androidCalendars =
                  Platform.OS === "android"
                    ? await Calendar.getCalendarsAsync()
                    : null;
                if (androidCalendars && androidCalendars.length < 1) {
                  Poppy(
                    "No calendars",
                    "Make sure you have your calendar setup on your phone and have given the app permission to access it."
                  );
                  return;
                }

                const defaultCalendar =
                  Platform.OS === "android"
                    ? androidCalendars[0]
                    : await Calendar.getDefaultCalendarAsync();
                const duplicateEvents = await Calendar.getEventsAsync(
                  defaultCalendar ? [defaultCalendar?.id] : [],
                  event.date,
                  event.endDate
                );
                if (duplicateEvents.find((e) => e.title === event.title)) {
                  Alert.alert(
                    "Event already exists",
                    "Looks like you already have this event added to your calendar."
                  );
                  return;
                }
                const eventId = await Calendar.createEventAsync(
                  defaultCalendar.id,
                  eventDetails
                );
                !IS_WEB &&
                  Poppy(
                    `Event added to your device's calendar ${defaultCalendar.name || ""
                    }`,
                    "Would you like to view this in your calendar now?",
                    { text: "No" },
                    {
                      text: "View event",
                      onPress: () => {
                        if (Platform.OS === "ios") Linking.openURL(`calshow://`);
                        else if (Platform.OS === "android")
                          Calendar.openEventInCalendar(eventId);
                      },
                    }
                  );
                Toasty.show(
                  `Event added to your device's calendar ${defaultCalendar.name || ""
                  }`,
                  { type: "success" }
                );
              }}
            >
              Save to my calendar
            </Buttoon>}
            <Buttoon icon={{ name: "share" }} status="basic" size="small" onPress={() => {
              const timezoneAbbr = new Date()
                .toLocaleTimeString("en-us", { timeZoneName: "short" })
                .split(" ")[2];
              const eventInviteMessage = fn.calendar.generateEventInviteMessage({
                startDate: event?.date,
                endDate: event?.endDate,
                notes: event.description || "",
                title: event.title || "",
                location: event.location?.description || "",
                guestsCanInviteOthers: true,
                alarms: [],
                id: event.eventId,
              });
              // const message = `${event.title}\n${event.description.replace("<br>", "\n").replace("<br />", "\n").replace(event.location?.description, "").replace(/(<([^>]+)>)/ig, "")}\n\n${fn.calendar.niceDateFormat(event.date)}\n${fn.calendar.timeToAMPMString(event.date)}${event.endDate ? ` - ${fn.calendar.timeToAMPMString(event.endDate)}` : ""} ${timezoneAbbr || ""}${event.location ? `\n\n${event.location.description || ""}` : ""}`
              Share.share({ message: eventInviteMessage })
            }}>Share event</Buttoon>
          </>)}
      </Kitten.ViewH>
    </A.CardEvent >
  );
}

const A = {
  // CardEvent: sstyled(createAnimatableComponent(Kitten.Card))((p) => ({
  //   // marginVertical: spacing(3),
  // })),
  CardEvent: sstyled(Kitten.Card)((p) => ({
    // marginVertical: spacing(3),
  })),
};
