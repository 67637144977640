//#region [import]
import { createDrawerNavigator } from "@react-navigation/drawer";
import { IconPrimr } from "assets";
import { Txt } from "components";
import { PersonalDrawer } from "components/organisms/drawer/PersonalDrawer";
import { ContactsHomeCenter } from "components/organisms/members/ContactsHomeCenter";
import {
  MediaPageItemSchema,
  useAppContext,
  useConfigDocument,
  useNotificationCollection,
  useUserProfile
} from "engines";
import * as React from "react";
import { View } from "react-native";
import { BroadcastScreen } from "screens/broadcast/BroadcastScreen";
import { DevsHomeScreen } from "screens/devs/DevsHomeScreen";
import { MassMailHomeScreen } from "screens/mass-mail/mass-mail-home/MassMailHomeScreen";
import { NotificationHomeCenter } from "screens/notifications/NotificationHomeCenter";
import { PersonalDashboardScreen } from "screens/personal-activity/PersonalDashboardScreen";
import { ToolsHomeScreen } from "screens/tools-home-scr/ToolsHomeScreen";
import { spacing } from "utils";
import { useActivityCollection } from "../../engines/firebase/handler/activity-handler";
import { MainNavigator } from "./main.navigator";
import { useNavigator } from "./navigation-utils";

//#endregion

/**
 * ###  Drawer Navigator wraps outside of the Main Navigator (bottom tabs)
 * -  Drawer is a place for all user profile features
 * -  Ref: Twitter's "personal drawer"
 * @returns
 */
export function DrawerNavigator() {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  const Drawer = createDrawerNavigator<
    typeof UserDrawerCollection &
      typeof DevDrawerCollection &
      typeof AdminDrawerCollection
  >();
  // const { drawerConfig } = useNavigatorPresetConfig();

  let userDrawerScreenOptions = {
    headerShown: true,
    headerLeft: () => (
      <IconPrimr
        preset="circular"
        name="arrow_left"
        color={C.text}
        onPress={() => {
          Navigation.canGoBack()
            ? Navigation.goBack()
            : Navigation.navigate("Main");
        }}
        containerStyle={{ marginLeft: spacing(3) }}
      />
    ),
  };
  return (
    <Drawer.Navigator
      screenOptions={{
        headerTintColor: C.text,
        headerStyle: { backgroundColor: C.background },
        headerShown: true,
        // drawerType: "permanent" // for 3-column concept
      }}
      drawerContent={PersonalDrawer}
    >
      <Drawer.Screen
        name="Main"
        key="Main"
        component={MainNavigator}
        options={{
          headerStyle: {
            borderBottomColor: C.dim,
            backgroundColor: C.background,
          },
          headerShown: false,
        }}
      />
      <Drawer.Group screenOptions={userDrawerScreenOptions}>
        {Object.entries({ ...UserDrawerCollection }).map(([name, screen]) => (
          <Drawer.Screen
            name={name}
            key={name}
            {...screen}
            options={{ ...screen.options }}
          />
        ))}
        {/* {userProfile?._isDeveloper() && (
          <Drawer.Screen
            name={"Dev Hub"}
            key={"Dev Hub"}
            component={FuegoScreen}
          />
        )} */}
      </Drawer.Group>
      <Drawer.Group screenOptions={userDrawerScreenOptions}>
        {userProfile?._isDeveloper() &&
          Object.entries({ ...DevDrawerCollection }).map(([name, screen]) => (
            <Drawer.Screen name={name} key={name} {...screen} />
          ))}
      </Drawer.Group>
      <Drawer.Group screenOptions={userDrawerScreenOptions}>
        {userProfile?._isUserAdmin() &&
          Object.entries({ ...AdminDrawerCollection }).map(([name, screen]) => (
            <Drawer.Screen name={name} key={name} {...screen} />
          ))}
      </Drawer.Group>
    </Drawer.Navigator>
  );
}

/**
 * ###  Contains all user's related screens
 * -  Think of this as "personal drawer"
 * @property drawerIcon:  {
      focused: boolean;
      size: number;
      color: string;
    }
 */

// NOTE: IT IS NOT RECOMMENDED TO CHANGE THE TITLES HERE, FOR EXAMPLE "Your Stats" is used to identify where to send push notification taps to
export const UserDrawerCollection = {
  // Profile: {
  //   component: UserScreen,
  //   options: {
  //     title: "Profile Setting",
  //     drawerIcon: ({ size, color }) => (
  //       <IconPrimr name="user" color={color} size={size} />
  //     ),
  //   },
  // },
  "My Stats": {
    component: PersonalDashboardScreen,
    options: {
      drawerIcon: ({ size, color }) => {
        const { frbsUser, teamId } = useAppContext();
        const activityHandler = useActivityCollection(frbsUser?.uid);
        const configHandler = useConfigDocument(`variables-${teamId}`);

        const [highestStreak, setHighestStreak] = React.useState<number>(0);

        React.useEffect(
          function getHighestStreak() {
            if (!configHandler.data?.activities || !activityHandler.data)
              return;
            setHighestStreak(
              activityHandler._getHighestStreak(
                activityHandler.data,
                configHandler.data?.activities
              )
            );
          },
          [activityHandler.data, configHandler.data]
        );

        return (
          <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
            <IconPrimr name="award" color={color} size={size} />
            <Txt.C2 style={{ color, fontSize: size * 0.5 }}>
              {highestStreak}
            </Txt.C2>
          </View>
        );
      },
    },
  },
  // "Contact Group Screen": {
  //   component: ContactsScreen,
  // },
  // "FIN Dashboard": {
  //   component: FinHomeScreen,
  // },
  // Teammates: {
  //   component: TeamMatesSceen,
  //   options: {
  //     drawerIcon: ({ size, color }) => {
  //       const { frbsUser } = useAppContext();
  //       const trainees = useMembersCollection({
  //         where: [
  //           "growth.listBuilder.shareTo",
  //           "array-contains",
  //           frbsUser?.uid,
  //         ],
  //       });
  //       return (
  //         <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
  //           <IconPrimr name="users" color={color} size={size} />
  //           <Txt.C2 style={{ color, fontSize: size * 0.5 }}>
  //             {trainees.data?.length}
  //           </Txt.C2>
  //         </View>
  //       );
  //     },
  //   },
  // },
  Notifications: {
    component: NotificationHomeCenter,
    options: {
      drawerIcon: ({ size, color }) => {
        const { frbsUser, teamId } = useAppContext();
        const notis = useNotificationCollection(teamId);
        return (
          <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
            <IconPrimr name="bell" color={color} size={size} />
            <Txt.C2 style={{ color, fontSize: size * 0.5 }}>
              {
                notis.data?.filter(
                  //@ts-ignore
                  (p) =>
                    p.createdAt?.seconds >
                    new Date((Date.now() - 86400000) / 1000)
                ).length
              }
            </Txt.C2>
          </View>
        );
      },
    },
  },
};

export const AdminDrawerCollection = {
  "Push Notifications": {
    component: BroadcastScreen,
    options: {
      drawerIcon: ({ size, color }) => (
        <IconPrimr name="notification" color={color} size={size} />
      ),
    },
  },
  "Mass Mail": {
    component: MassMailHomeScreen,
    options: {
      drawerIcon: ({ size, color }) => (
        <IconPrimr name="email" color={color} size={size} />
      ),
    },
  },
  // "Blast SMS": {
  //   component: HitEmUpSms,
  //   options: {
  //     drawerIcon: ({ size, color }) => (
  //       <IconPrimr name="bullhorn" color={color} size={size} />
  //     ),
  //   },
  // },
};

export const DevDrawerCollection = {
  // ...AdminDrawerCollection,
  // Main: {
  //   component: MainNavigator,
  //   options: {
  //     drawerIcon: ({ size, color }) => (
  //       <IconPrimr name="home" color={color} size={size} />
  //     ),
  //   },
  // },
  "Dev Hub": {
    component: DevsHomeScreen,
    options: {
      drawerIcon: ({ size, color }) => (
        <IconPrimr name="cog" color={color} size={size} />
      ),
    },
  },
};

function ContactManagerScreen(props: P) {
  const { params } = props.route;
  // console.log("props?.route?.params ", params);
  return (
    <ContactsHomeCenter
      sharedITems={params?.listData}
      goDirectlyToFollowUps={params?.notification}
    />
  );
}

export type DRAWER_STK =
  | "Main"
  | keyof typeof UserDrawerCollection
  | keyof typeof DevDrawerCollection
  | keyof typeof AdminDrawerCollection;

interface P {
  route?: { params?: MediaPageItemSchema[] };
}
