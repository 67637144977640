import { dIconPrimr, IconPrimr, ICON_NAME } from "assets/icons";
import { Kitten, Txt } from "components/atoms";
import { useAppContext } from "engines";
import * as React from "react";
import { TextStyle, View } from "react-native";
import {
  Menu,
  MenuOption,
  MenuOptionProps,
  MenuOptions,
  MenuProps,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { IS_WEB, scale } from "utils/helpers";
import { spacing } from "utils/styles";

/**
 * ### Popup menu component for this project
 *  - Usually used for top right menu button
 *  - Based on react-native-popup-menu
 *  ----
 *  @example 
 *   <Menoo
        trigger={{
        name: "dots_vertical",
        color: "white",
        preset: "circular",
        // text: "Click me",
        // style: { color: "tomato" },
        }}
        options={[
        {
            text: "Option 1",
            onSelect: () => console.log("option 1 selected"),
        },
        { text: Menoo.Divider },
        {
            text: "Option 2",
            onSelect: () => console.log("option 2 selected"),
        },
        ]}
    />
 *  ----
 *  @version 21.08.17
 *  -  *Build component*
 *  @author  K
 *  
 **/
export function Menoo({
  trigger,
  options = [
    { text: "Option 1", onSelect: () => console.log("option 1 selected") },
    { text: MenooDivider },
    { text: "Option 2", onSelect: () => console.log("option 2 selected") },
  ],
  renderer = IS_WEB ? renderers.Popover : renderers.SlideInMenu,
  ...rest
}: dMenoo) {
  const { C } = useAppContext();
  const { bottom } = useSafeAreaInsets();
  return (
    <View>
      <Menu renderer={renderer} {...rest}>
        <MenuTrigger>
          {trigger?.render ? (
            trigger?.render
          ) : trigger?.text ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                margin: spacing(3),
              }}
            >
              {trigger?.icon && !trigger?.iconRight ? (
                <IconPrimr
                  name={trigger?.icon}
                  size={scale(18)}
                  color={trigger?.style?.color}
                />
              ) : null}
              <Txt.S1 style={trigger?.style}>{trigger?.text}</Txt.S1>
              {trigger?.icon && trigger?.iconRight ? (
                <IconPrimr
                  name={trigger?.icon}
                  size={scale(18)}
                  color={trigger?.style?.color}
                />
              ) : null}
            </View>
          ) : (
            <IconPrimr
              name="dots_vertical"
              preset="circular"
              color={C.grey500}
              {...(trigger && trigger)}
            />
          )}
        </MenuTrigger>

        <MenuOptions
          customStyles={{
            optionText: {
              color: C.text,
              fontSize: scale(16),
              textAlign: "center",
            },
            optionsContainer:
              rest.renderer === renderers.SlideInMenu ||
                renderer === renderers.SlideInMenu
                ? {
                  backgroundColor: C.background,
                  borderRadius: scale(10),
                  margin: spacing(4),
                  paddingTop: spacing(3),
                  paddingBottom: bottom + spacing(3),
                  shadowColor: C["color-success-500"],
                  shadowOffset: {
                    width: 0,
                    height: 5,
                  },
                  shadowOpacity: 0.76,
                  shadowRadius: 60,

                  elevation: 11,
                }
                : {
                  backgroundColor: C.background,
                  borderRadius: scale(10),
                  paddingHorizontal: spacing(3),
                  borderWidth: 1,
                  borderColor: C.dim,
                },
          }}
        >
          {(rest.renderer == renderers.SlideInMenu ||
            renderer === renderers.SlideInMenu) && (
              <View
                style={{
                  position: "absolute",
                  left: spacing(3),
                  top: -(scale(16) + spacing(5)),
                }}
              >
                <Txt.H6 style={[{ textAlign: "left" }]}>{trigger?.text}</Txt.H6>
              </View>
            )}
          {options.map((op, index) => {
            if (op?.text === "__________") {
              return <Kitten.Divider_________ key={index} />;
            } else {
              return (
                <MenuOption
                  key={index}
                  {...op}
                  customStyles={{
                    optionWrapper: [{ marginVertical: spacing(3) }],
                    optionText: [
                      {
                        color: op?.color || C.text,
                        fontSize: scale(16),
                        textAlign: "center",
                        // fontWeight: "bold",
                      },
                    ],
                  }}
                />
              );
            }
          })}
        </MenuOptions>
      </Menu>
    </View>
  );
}
let MenooDivider = "__________";
Menoo.Divider = MenooDivider;

export interface dMenoo extends Partial<MenuProps> {
  trigger:
  | { render }
  | dIconPrimr
  | { text: string; style?: TextStyle; icon?: ICON_NAME, iconRight?: boolean };
  options: MenuOptionProps &
  {
    /** Either menu label, or Menoo.Divider for divider */
    text: string | "__________";
    /** optional text color */
    color?: string;
  }[];
}
