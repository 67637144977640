// NOTE: You may need to use direct path/full path imports to avoid white/blank screen

//@ts-check
import PushNotificationIOS from "@react-native-community/push-notification-ios";
import { Poppy, Toasty } from "components";
import { extractLinkFromBody } from "engines/functions/js-functions/extract-link";
import { LinkURL } from "engines/functions/web-functions";
// import { LinkURL } from "engines/functions/web-functions";
import { registerRootComponent } from "expo";
import "expo-asset";
import { Alert, LogBox } from "react-native";
import "react-native-gesture-handler";
import { IS_WEB } from "utils";
import App, { navigateFromNotification } from "./App";
// import PushNotification from "react-native-push-notification";
const PushNotification = IS_WEB
  ? null
  : require("react-native-push-notification");
LogBox.ignoreAllLogs(true);
LogBox.ignoreLogs([
  "[use-collection] warning: Your document,",
  "[use-document] warning: Your document,",
  "Setting a timer",
  "source.uri should not be an empty string",
  "Require cycles are allowed",
]);

//* Enable it in production to hide all console logs
!("debug" === "nah") &&
  ((console.log = function () { }),
    (console.warn = function () { }),
    (console.error = function () { }));

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
// if (IS_WEB) registerRootComponent(App);
// else AppRegistry.registerComponent("PrimericaTemplate", () => App);
// if (Platform.OS == "ios") { AppRegistry.registerComponent("PrimericaTemplate", () => App) } else { registerRootComponent(App) };
registerRootComponent(App);
// AppRegistry.runApplication('App', {
//   rootTag: document.getElementById('root'),
// });
// TrackPlayer.registerPlaybackService(() => require("./service"));

let receivedNotifications = [];

// PushNotification.localNotification({
//   // title: "Stat Report",
//   title: "New Announcement",
//   // message: "Go to stats",
//   message: "Some new & announcement push notification test",
//   // data: {
//   //   destination: "stats",
//   // },
//   // destination: "stats",
//   userInfo: {
//     // destination: "stats",
//     // data: { destination: "stats" },
//   },
// });
// PushNotification.localNotification({
//   title: "Christian Francis just watched the video you sent them!",
//   message: "Christian Francis just watched Trackable Video, come in the app to follow up with them.",
// });

//#region [section] debug test (COMMENT OUT IN PRODUCTION)

// let navigationAttempts = 0;
// const tryToNavigate = () => {
//   Toasty.show(`Attempting navigation... ${navigationAttempts}`);
//   const navigationRef =
//     require("./src/screens/_navigation/root.navigator").navigationRef;
//   if (!navigationRef.current) {
//     // navigationRef not ready, try again...
//     setTimeout(tryToNavigate, 500);
//   } else {
//     // if (notification.data?.destination) {
//     // @ts-ignore
//     navigationRef.current.navigate("stats", {
//       notification: true,
//     });
//     if (
//       navigationRef.current.getCurrentRoute().name !== "stats" &&
//       ++navigationAttempts < 10
//     ) {
//       setTimeout(tryToNavigate, 500);
//     }
//     // }
//   }
// };

// // if this notification needs to navigate the user somewhere, keep attempting until the navigationRef is generated
// setTimeout(() => {
//   tryToNavigate();
// }, 24);
//#endregion

// Must be outside of any component LifeCycle (such as `componentDidMount`).
!IS_WEB &&
  PushNotification.configure({

    // (optional) Called when Token is generated (iOS and Android)
    onRegister: function (token) {
      console.log("TOKEN:", token);
    },

    // (required) Called when a remote is received or opened, or local notification is opened
    /**
     * {
      foreground: Boolean,
      message: string,
      title?: string,
      subtitle?: string,
      data: {
        userInteraction: number | boolean, // 0 or 1
        accountIdentifier: string,
      },
    }
     */
    onNotification: async function (notification) {
      // alert("need to go somewhere?");
      try {
        if (!notification) return;
        console.log("NOTIFICATION:", notification);
        const now = new Date()
        const notificationId = `${notification?.id || ""}_${notification?.title}_${notification?.description}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`
        if (
          notification.foreground &&
          !receivedNotifications.includes(notificationId)
        ) {
          receivedNotifications.push(notificationId);
          PushNotification.localNotification({
            ...notification,
            // bigPictureUrl
            // notification.userInfo.image (ios only)
          });
        }

        if (notification?.userInteraction) {
          // if notification contains a url, provide option to open it
          if (
            notification?.message?.toLowerCase()?.includes("https://") ||
            notification?.message?.toLowerCase()?.includes("http://")
          ) {
            const url = extractLinkFromBody(notification.message);
            // const url = ""
            !notification.data?.destination &&
              Poppy(
                notification?.title || "",
                notification?.message || "",
                { text: "OK", onPress: () => { } },
                {
                  text: "Open URL",
                  onPress: () => {
                    LinkURL(url);
                  },
                }
              );
            // Alert.alert(notification.title, notification.message, [
            //   {
            //     text: "Open URL",
            //     onPress: () => {
            //       // Linking.openURL(url);
            //       LinkURL(url);
            //     },
            //   },
            //   {
            //     text: "OK",
            //     onPress: () => {},
            //   },
            // ]);
          }
          // regular notification
          else {
            // Alert.alert(notification.title, notification.message);
            // Alert.alert(JSON.stringify(notification.data));

            // if this notification needs to navigate the user somewhere, keep attempting until the navigationRef is generated
            const dailyActivityReminderPossibles = ["Daily Activity Reminder"];
            const morningReportPossibles = ["Today's Agenda", "Daily Briefing"];
            const trackableVideoPossibles = "just watched"

            let goToShortcut = dailyActivityReminderPossibles.includes(
              notification?.title
            )
              ? "stats"
              : morningReportPossibles.includes(notification?.title)
                ? "UserDashboard"
                : notification?.title?.includes(trackableVideoPossibles) ? "UserDashboard" : null;

            if (notification?.title?.startsWith("Follow Up")) {
              goToShortcut = "Contact Manager"
            }
            if(notification?.title?.includes("Low Activity")) {
              goToShortcut = "Teammates"
            }

            if (notification?.data?.destination || goToShortcut) {
              Toasty.show(`Going to ${notification?.data?.destination}`);
              Poppy(
                notification?.title,
                notification?.message,
                { text: "Cancel", onPress: () => { } },
                {
                  text: "View",
                  onPress: () => {
                    navigateFromNotification(notification, 0, goToShortcut);
                  },
                }
              );
            } else {
              // Alert.alert(notification?.title, notification?.message);
              if (notification?.title?.length > 0 && notification?.message?.length > 0) {
                Poppy(notification?.title, notification?.message);
              }
            }
          }
        }

        // process the notification

        // (required) Called when a remote is received or opened, or local notification is opened
        notification?.finish(PushNotificationIOS.FetchResult.NoData);
      } catch (err) {
        console.error(err);
        Toasty.show(err, { type: "danger" });
        Alert.alert(
          `Something went wrong.`,
          `Failed to go to destination ${notification.data?.destination}.\nTry to go there yourself.\n${err}`
        );
      }
    },

    // (optional) Called when Registered Action is pressed and invokeApp is false, if true onNotification will be called (Android)
    onAction: function (notification) {
      console.log("ACTION:", notification.action);
      console.log("NOTIFICATION:", notification);

      // process the action
    },

    // (optional) Called when the user fails to register for remote notifications. Typically occurs when APNS is having issues, or the device is a simulator. (iOS)
    onRegistrationError: function (err) {
      console.error(err.message, err);
    },

    // IOS ONLY (optional): default: all - Permissions to register.
    permissions: {
      alert: true,
      badge: true,
      sound: true,
    },

    // Should the initial notification be popped automatically
    // default: true
    popInitialNotification: true,

    /**
     * (optional) default: true
     * - Specified if permissions (ios) and token (android and ios) will requested or not,
     * - if not, you must call PushNotificationsHandler.requestPermissions() later
     * - if you are not using remote notification or do not have Firebase installed, use this:
     *     requestPermissions: Platform.OS === 'ios'
     */
    requestPermissions: false,
  });
