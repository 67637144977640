import { useDocument } from "@nandorojo/swr-firestore";
import { FPATH, ScoreboardSchema } from "engines";
import firebase from "firebase";
import { useCollectionPresetv2 } from "../config";
import {
  crudScorer,
  editScore,
  resetScoreboard
} from "../scoreboard-functions";

/**
 * ### Scoreboard Document Hook
 **/
export function useScoreboardDocument(id: ScoreboardSchema["_sbid"], query?) {
  const presetDocument = useDocument<ScoreboardSchema>(
    `${FPATH.SCOREBOARDS}/${id}`,
    {
      listen: true,
      ...query,
    }
  );

  /**
   * ###  Add a member's score
   * @param uid member id
   * @param score member score
   * @returns score
   */
  let addScore = (uid: string, score: string | number, name: string) =>
    crudScorer("create", { uid, score, name, document: presetDocument });

  /**
   * ###  Delete member's score
   * @param uid member id
   * @returns null
   */
  let xScore = (uid: string, _scorerName?: string) =>
    crudScorer("delete", {
      uid,
      document: presetDocument,
      score: 0,
      name: _scorerName ? _scorerName : "-",
    });
  /**
   * ### Reset the current scoreboard
   * @returns
   */
  let resetScore = () => resetScoreboard(presetDocument);
  let updateScore = (
    uid: string | null,
    score: string | number,
    name?: string
  ) => editScore(uid, Number(score), name, presetDocument);
  return { ...presetDocument, addScore, updateScore, xScore, resetScore };
}

export function useScoreboardCollection(query?) {
  const scoreboardsHandler = useCollectionPresetv2<ScoreboardSchema>(
    FPATH.SCOREBOARDS,
    "_sbid",
    query
  );
  const resetAllScores = async () => {
    const batch = firebase.firestore().batch();
    for (let d in scoreboardsHandler.data) {
      const scoreboard = scoreboardsHandler.data[d];
      firebase
        .firestore()
        .collection(FPATH.SCOREBOARDS)
        .doc(scoreboard._sbid)
        .update({
          people: [],
        });
    }
    return batch.commit();
  };
  return { ...scoreboardsHandler, resetAllScores };
}
