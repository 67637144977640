import { useFocusEffect } from "@react-navigation/native";
import { Buttoon, Kitten, Skeleton, sstyled, Txt } from "components/atoms";
import { MemberListItem } from "components/molecules";
import { useTwoColumns } from "components/templates";
import {
  FRBS_ROLE,
  useAppContext,
  useMembersCollection,
  UserSchema
} from "engines";
import * as R from "ramda";
import React from "react";
import { FlatList, View } from "react-native";
import { tr } from "utils";

/**
 * ###
 *  -
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.01.21
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function MembersFeed({
  where,
  mode = "combo",
}: {
  where?: any;
  mode?: "alacarte" | "combo";
}) {
  const { C, teamId } = useAppContext();
  const { column1ContainerStyle } = useTwoColumns();
  const [members, setMembers] = React.useState<UserSchema[]>([]);
  const [allMembers, setAllMembers] = React.useState<UserSchema[]>([]);
  const [_viewActiveUsers, toggleViewActiveUsers] =
    React.useState<boolean>(false);
  const [_viewNewMembers, toggleViewNewUsers] = React.useState<boolean>(false);

  //#region [FRBS]
  const { data: membersData, ...handleMembers } = useMembersCollection({
    where: where ? where : ["_teamId", "==", teamId],
  });

  React.useEffect(
    function setupData() {
      // let members = membersD?.filter((member) => {
      //   // return member.growth?.listBuilder?.shareTo?.includes(frbsUser.uid);
      // });
      membersData &&
        setAllMembers([
          ...membersData
            .filter((m) => {
              return m.personali.displayName;
            })
            .sort((m1, m2) =>
              m1.personali?.displayName
                ?.trim()
                ?.localeCompare(m2.personali?.displayName?.trim())
            ),
          ...membersData.filter((m) => {
            return !m.personali?.displayName;
          }),
        ]);
    },
    [membersData]
  );

  const [listOffset, setListOffset] = React.useState(30);

  React.useEffect(() => {
    setMembers(allMembers.slice(0, listOffset));
  }, [listOffset, allMembers]);
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    // console.log("Query is ", value);
    setQuery(value);
    if (value.length >= 3) {
      const newData = allMembers?.filter((member) => {
        const memberIdentifier =
          member.personali?.displayName?.length > 0
            ? member.personali.displayName
            : member.personali.email;
        return memberIdentifier?.toLowerCase().includes(value.toLowerCase());
      });
      setMembers(newData);
    } else {
      setMembers(allMembers);
    }
  }
  //#endregion

  return React.useMemo(() => {
    try {
      if (handleMembers.error) {
        return (
          <Txt.Indicator>{tr("Error getting members feed")}</Txt.Indicator>
        );
      }
      if (!membersData) return <Skeleton.Screen.Members length={7} />;

      let sortedMembers = R.sortWith<UserSchema>(
        [
          /** sorting `createdAt` in `desc` */
          R.descend(R.prop("createdAt")),
        ],
        members
      ).filter((m) => !m?.roles?.includes(FRBS_ROLE.BANNED)); // filters out banned members

      // signed in in last 30 days
      const oneday = 86400 * 1000;
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setTime(thirtyDaysAgo.getTime() - oneday * 30);
      const activeMembers = R.sortWith<UserSchema>(
        [
          /** sorting `lastSignIn` in `desc` */
          R.descend(R.prop("lastSignIn")),
        ],
        membersData
      ).filter(
        (m) =>
          m?.lastSignIn?.toDate()?.getTime() > thirtyDaysAgo.getTime() &&
          m?.createdAt?.toDate() < thirtyDaysAgo
      );
      const newMembers = R.sortWith<UserSchema>(
        [R.descend(R.prop("createdAt"))],
        membersData
      )?.filter((m) => m?.createdAt?.toDate() > thirtyDaysAgo);

      return (
        <FlatList
          contentContainerStyle={[mode === "combo" && column1ContainerStyle]}
          stickyHeaderIndices={[0]}
          ListHeaderComponent={
            <View>
              <Kitten.Input
                value={query}
                // placeholder={tr("Search member's name")}
                placeholder={
                  _viewActiveUsers
                    ? tr(`Search ${activeMembers.length} active users...`)
                    : _viewNewMembers
                      ? tr(`Search ${newMembers.length} new users`)
                      : tr(`Search ${allMembers.length} users...`)
                }
                onChangeText={(value) => {
                  filterHandler(value);
                }}
              />
              <Kitten.ViewH
                style={{ flexWrap: "wrap", justifyContent: "center" }}
              >
                {!_viewActiveUsers && !_viewNewMembers ? (
                  <>
                    <Buttoon
                      onPress={() => toggleViewNewUsers(true)}
                      appearance="ghost"
                      size="small"
                    >
                      {`View ${newMembers.length} new users`}
                    </Buttoon>
                    <Buttoon
                      onPress={() => toggleViewActiveUsers(true)}
                      appearance="ghost"
                      size="small"
                    >
                      {`View ${activeMembers.length} active users`}
                    </Buttoon>
                  </>
                ) : null}
                {_viewActiveUsers || _viewNewMembers ? (
                  <Buttoon
                    onPress={() => {
                      toggleViewActiveUsers(false);
                      toggleViewNewUsers(false);
                    }}
                    appearance="ghost"
                    size="small"
                  >
                    {"View all users"}
                  </Buttoon>
                ) : null}
              </Kitten.ViewH>
              {_viewActiveUsers ? (
                <Txt.Helper>
                  {
                    "Active users have been in your app for longer than 30 days and signed in within the past 30 days."
                  }
                </Txt.Helper>
              ) : null}
              {_viewNewMembers ? (
                <Txt.Helper>
                  {
                    "New users have been in your app for longer than 30 days and signed in within the past 30 days."
                  }
                </Txt.Helper>
              ) : null}
              <Kitten.Divider_________ />
            </View>
          }
          data={
            _viewActiveUsers
              ? activeMembers
              : _viewNewMembers
                ? newMembers
                : sortedMembers
          }
          renderItem={({ item: member, index }) => (
            <MemberListItem key={member._id} member={member} team={true} />
            /* Type was added in other to reuse the memberListItem component and 
              to know if it was a BS or a member info detail, ask Nanle if you have questions */
          )}
          ListFooterComponent={() =>
            !query && listOffset <= allMembers?.length ? (
              <Buttoon
                appearance={"ghost"}
                status={"primary"}
                progress
                onPress={(xong) => {
                  setTimeout(() => {
                    setListOffset(listOffset + 50);
                    xong && xong();
                  }, 600);
                }}
                disabled={listOffset >= allMembers.length}
              >
                {listOffset < allMembers.length ? tr("Load More") : ""}
              </Buttoon>
            ) : (
              <View style={[mode === "combo" && { paddingVertical: 150 }]} />
            )
          }
          ListEmptyComponent={() => (
            <Txt.Indicator marginV>
              {handleMembers.loading ? tr("Loading...") : tr("No member found")}
            </Txt.Indicator>
          )}
          keyExtractor={(item) => item._id}
        />
      );
    } catch (error) {
      throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
    }
  }, [
    allMembers,
    members,
    query,
    listOffset,
    C,
    _viewActiveUsers,
    _viewNewMembers,
  ]);
}

const A = {
  CardContainer: sstyled(Kitten.Card)({
    minWidth: 300,
  }),
};

interface P {
  /*
  used to pass query to this components
  the where clause allow us to compare multiple parameters
  we could have something like 
  where teamId, ==, "apptakeoff"
  this then returns all users under the teamId apptakeoff
 * 
 * */
  where?: WhereItem<UserSchema>;
  bsid?: string; //I added this because i was having a type error on the teams page
}

type WhereItem<Doc extends object = {}, key = keyof Doc> = [key, "==", unknown];
