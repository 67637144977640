//#region [import]

import { Avt, Kitten, Skeleton, sstyled, Txt } from "components";
import { ContactsFeed } from "components/organisms";
import { ContactMenu } from "components/organisms/contact-manager/ContactMenu";
import { ContactCreatorCenter } from "components/organisms/members/ContactCreatorCenter";
import { TwoColumns } from "components/templates";
import { Pressable, View } from "dripsy";
import {
  useAppContext,
  useContactGroupDocument,
  useUserProfile
} from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import React from "react";
import { scale } from "react-native-size-matters";
import { WebView } from "react-native-webview";
import { dContactsScrParams, useNavigator } from "screens";
import { tr } from "utils";
//#endregion

/**
 * ### Web version of Home screen
 *  ----
 *  @version 21.03.01
 *  -  *Brief changelog*
 *  @author  @nguyenkhooi
 *
 **/
export function ContactsScreen(props: P) {
  const id = props?.route?.params?.id;
  const team = props?.route?.params?.team;
  const uid = props?.route?.params?.c_uid;
  const sharedItems = props?.route?.params?.sharedItems;
  const followUpContacts = props?.route?.params?.followUpContacts;
  const futureFollowUpsOnly = props?.route?.params?.futureFollowUpsOnly;

  const user = useUserProfile();
  const Navigation = useNavigator();
  const { data: cgData } = useContactGroupDocument(user.data?._id, id);
  const { data: nonUserCGData } = useContactGroupDocument(uid, id);
  const [differentMember, setDifferentMember] = React.useState(false);

  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  const needsFollowUpContacts: ContactSchemaa[] = followUpContacts?.filter(
    (c) =>
      c.followUpDate?.toDate &&
      c.followUpDate?.toDate()?.getTime() <= endOfToday.getTime()
  );
  const futureFollowUpContacts: ContactSchemaa[] = followUpContacts?.filter(
    (c) =>
      c.followUpDate?.toDate &&
      c.followUpDate?.toDate()?.getTime() > endOfToday.getTime()
  );

  React.useEffect(() => {
    if (uid && uid?.trim() !== user.data?._id) {
      setDifferentMember(true);
    }
  }, [props.route, user.data]);

  React.useEffect(() => {
    if (cgData && !differentMember) {
      cgData.name && Navigation.setOptions({ title: cgData.name });
      return;
    }
    if (nonUserCGData && differentMember) {
      nonUserCGData.name &&
        Navigation.setOptions({
          title: nonUserCGData.name + ` contacts`,
        });
      return;
    }
  }, [cgData, props.route, differentMember]);

  try {
    return (
      <TwoColumns
        disableAnimatedFooter
        bottomInset
        column1Content={(scrollHandler) => (
          <>
            <ContactsFeed
              scrollHandler={scrollHandler}
              id={id}
              team={team}
              c_uid={differentMember ? uid : user.data?._id}
              sharedItems={sharedItems}
              followUpContacts={
                futureFollowUpsOnly ? followUpContacts : needsFollowUpContacts
              }
              futureFollowUpContacts={futureFollowUpContacts}
              futureFollowUpsOnly={futureFollowUpsOnly}
            />
          </>
        )}
        column2Content={[
          {
            headerTitle: tr("Contact group details"),
            showTitle: false,
            hiddenIf: [team],
            render: () => <ContactCreatorCenter id={id} />,
            icon: "cog",
          },
          {
            headerTitle: tr("Options"),
            showTitle: true,
            hiddenIf: [team],
            render: () => <ContactMenu id={id} />,
            icon: "plus",
          },
        ]}
      />
    );
  } catch (error) {
    throw Error(`::Q29udGFjdHNTY3JlZW4=::${error}`);
  }
}

export function FinHomeScreen() {
  return <WebView source={{ uri: "https://fin.linkmagnet.com/" }} />;
}

export const FinCenter = (p) => {
  const { type = "pressable" } = p;
  const { C } = useAppContext();
  const Navigation = useNavigator();

  switch (type) {
    case "pressable":
      return (
        <A.PressableContainer
          onPress={() => {
            Navigation.navigate("FIN Dashboard");
          }}
        >
          <Skeleton
            isLoading
            duration={2400}
            // easing={Easing.circle}
            layout={[
              {
                key: "medal-wrapper",
                width: scale(1200),
                height: scale(24),
                // justifyContent: "center",
              },
            ]}
            highlightColor={"#0000ff"}
            boneColor={"#360ccc"}
          />
          <A.ViewLabel>
            <Kitten.ListItemv2
              style={{ backgroundColor: "transparent" }}
              title={() => (
                <Kitten.ViewH>
                  <Avt
                    name="fin"
                    resizeMode="contain"
                    source={{
                      uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1646928784/admin/Whtie-Green_yemaxy.png",
                    }}
                    size={24}
                    shape={"square"}
                    style={{ backgroundColor: "transparent" }}
                  />
                  <Txt.S2 style={{ color: "white" }}> Prospects</Txt.S2>
                </Kitten.ViewH>
              )}
              description={"  "}
              disabled
            />
            {/* <Txt.H4>{"Open FIN dashboard"}</Txt.H4> */}
          </A.ViewLabel>
        </A.PressableContainer>
      );
      break;
    case "screen":
      return <FinHomeScreen />;
    default:
      return (
        <A.PressableContainer
          onPress={() => {
            Navigation.navigate("FIN Dashboard");
          }}
        >
          <Skeleton
            isLoading
            duration={2400}
            // easing={Easing.circle}
            layout={[
              {
                key: "medal-wrapper",
                width: scale(940),
                height: scale(96),
                // justifyContent: "center",
              },
            ]}
            highlightColor={"#0000ff"}
            boneColor={"#360ccc"}
          />
          <A.ViewLabel>
            <Txt.H4>{"Open FIN dashboard"}</Txt.H4>
          </A.ViewLabel>
        </A.PressableContainer>
      );
      break;
  }
};

const A = {
  PressableContainer: sstyled(Pressable)({
    // borderRadius: scale(4),
    overflow: "hidden",
    backgroundColor: "transparent",
  }),
  ViewLabel: sstyled(View)((p) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  })),
};

interface P {
  route?: { params?: dContactsScrParams }; //* unmask if we pass params thru rnav
}
