import { Kitten, Skeleton } from "components";
import { useSx } from "dripsy";
import * as React from "react";
import { View } from "react-native";
import { scale, spacing, tr } from "utils";
import { sstyled } from "../../sstyled/sstyled";
import { Txt } from "../../txt/Txt";
import { ICustomViewStyle } from "../constants";

const _FeedSkeleton = ({ length = 5 }) => {
  return (
    <>
      {Array.from({ length }).map((_, index) => (
        <Skeleton key={index} layout={SkeletonLayout.Item} />
      ))}
    </>
  );
};
const _PosstsSkeleton = ({ length = 5 }) => {
  return (
    <View style={{ width: "100%" }}>
      <Txt.$Title marginV>{tr("Loading...")}</Txt.$Title>
      {Array.from({ length }).map((_, index) => (
        <Kitten.Card
          key={index}
          style={{
            marginBottom: spacing(4),
            height: scale(108),
            justifyContent: "center",
          }}
        >
          <Skeleton layout={SkeletonLayout.Footer}></Skeleton>
        </Kitten.Card>
      ))}
    </View>
  );
};

const _MembersSkeleton = ({ length = 5 }) => {
  return (
    <>
      <Txt.$Title marginV>{tr("Loading...")}</Txt.$Title>
      {Array.from({ length }).map((_, index) => (
        <Kitten.Card key={index} style={{ marginBottom: spacing(4) }}>
          <Skeleton layout={SkeletonLayout.MemberItem}></Skeleton>
        </Kitten.Card>
      ))}
    </>
  );
};

const _ProfileItemSkeleton = ({ length = 1 }) => {
  return (
    <>
      {Array.from({ length }).map((_, index) => (
        <Kitten.Card key={index} style={{ marginBottom: spacing(4) }}>
          <Skeleton layout={SkeletonLayout.ProfileItem}></Skeleton>
        </Kitten.Card>
      ))}
    </>
  );
};

const _ScoreboardsSkeleton = ({ length = 2 }) => {
  const sx = useSx();
  return (
    <View style={{ width: "100%" }}>
      {/* <Skeleton layout={SkeletonLayout.Title} /> */}
      <Txt.$Title marginV>{tr("Loading...")}</Txt.$Title>
      <Kitten.ViewH>
        {Array.from({ length }).map((_, index) => (
          <View
            key={index}
            style={sx({
              width: scale(120),
              height: 10,
              margin: spacing(4),
            })}
          >
            <Skeleton layout={SkeletonLayout.ScoreHomeItem}></Skeleton>
          </View>
        ))}
      </Kitten.ViewH>
    </View>
  );
};

export const SkeletonLayout = {
  Title: [
    {
      width: scale(100),
      height: scale(15),
      alignSelf: "flex-start",
      marginVertical: spacing(2),
    },
  ] as ICustomViewStyle[],
  Item: [
    {
      width: "100%",
      height: scale(92),
      marginBottom: spacing(3),
    },
  ] as ICustomViewStyle[],
  ItemSmall: [
    {
      width: "100%",
      height: scale(50),
      marginBottom: spacing(3),
    },
  ] as ICustomViewStyle[],
  Media: [
    {
      key: "media-skltn",
      width: "100%",
      height: scale(220),
      marginBottom: spacing(3),
    },
  ] as ICustomViewStyle[],
  Footer: [
    {
      flexDirection: "row",
      alignSelf: "flex-start",
      children: [
        { width: 30, height: scale(30), borderRadius: 30 },
        {
          width: 100,
          height: scale(20),
          marginLeft: 10,
        },
      ],
    },
  ] as ICustomViewStyle[],
  MemberItem: [
    {
      flexDirection: "row",
      alignSelf: "flex-start",
      width: "100%",
      children: [
        { width: 30, height: scale(30), borderRadius: 30 },
        {
          width: "70%",
          height: scale(20),
          marginLeft: 10,
        },
      ],
    },
  ] as ICustomViewStyle[],
  ProfileItem: [
    {
      width: "100%",
      alignItems: "flex-start",
      justifyContent: "flex-start",

      children: [
        { width: scale(51), height: scale(51), borderRadius: 51, margin: 8 },
        {
          width: "90%",
          height: scale(41),
          margin: 8,
        },
        {
          width: "90%",
          height: scale(41),
          margin: 8,
        },
        {
          width: "90%",
          height: scale(41),
          margin: 8,
        },
      ],
    },
  ] as ICustomViewStyle[],
  ScoreHomeItem: [
    {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      children: [
        {
          width: "40%",
          height: scale(20),
          margin: 5,
        },
        { width: scale(60), height: scale(60), borderRadius: 60 },
        {
          width: "70%",
          height: scale(20),
          margin: 5,
        },
        {
          width: "50%",
          height: scale(20),
          margin: 5,
        },
      ],
    },
  ] as ICustomViewStyle[],
};

const A = {
  ViewTitleIdle: sstyled(View)({
    backgroundColor: "dim",
  }),
};

/**
 * ### Post skeleton to show when db is being loaded
 *  ----
 *  @example
 *  loading && <PosstSkeleton />
 *  ----
 *  @version 21.03.05
 *  -  *Build a component*
 *  @author  K
 *
 **/
export function ScreenSkeleton() {
  return _FeedSkeleton;
}
ScreenSkeleton.Feed = _FeedSkeleton;
ScreenSkeleton.Possts = _PosstsSkeleton;
ScreenSkeleton.Members = _MembersSkeleton;
ScreenSkeleton.Scoreboards = _ScoreboardsSkeleton;
ScreenSkeleton.ProfileItem = _ProfileItemSkeleton;
