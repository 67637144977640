import { ICON_NAME } from "assets";
import { Buttoon } from "components";
import { spacing } from "utils";

export function ActionButton(p: {
  title: string;
  iconName: ICON_NAME;
  onPress: () => void;
}) {
  const { title, iconName, onPress } = p;

  return (
    <Buttoon
      onPress={onPress}
      appearance="filled"
      size="small"
      status="control"
      style={{ borderRadius: 23, marginRight: spacing(3) }}
      icon={{ name: iconName }}
    >
      {title}
    </Buttoon>
  );
}
