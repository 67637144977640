import { CollectionQueryType, useCollection, useDocument } from "@nandorojo/swr-firestore";
import { Toasty } from "components";
import {
  ConfigVariablesSchema,
  dConfigKeys,
  dVimeoToken,
  FPATH,
  Trophy,
  useAppContext
} from "engines";
import firebase from "firebase";
import { IS_WEB } from "utils";
import { APP_CONFIG } from "../../../../apptakeoff.config";
import { app_team_id } from "../../../../package.json";
import { useCollectionPresetv2 } from "../config";

export function useConfigDocument(id: string, query?) {
  // console.log("Coming here with id ", id);
  const presetDocument = useDocument<ConfigVariablesSchema | dConfigKeys>(
    `${FPATH.CONFIGS}/${id}`,
    {
      listen: true,
      ...query,
    }
  );

  // function updateGHLAccount(locationId: string, ghlAccount: GHLAccount) {
  //   return firebase
  //     .firestore()
  //     .collection(FPATH.CONFIGS)
  //     .doc(id)
  //     .update({

  //     });
  //     updateToken
  // }

  return { ...presetDocument };
}

export function useTrophyCollection(id: string, query?) {
  const defaultCollectionFunctions = useCollectionPresetv2<Trophy>(
    `${FPATH.CONFIGS}/${id}/${FPATH.TROPHIES}`,
    "_id",
    query
  );
  return { ...defaultCollectionFunctions };
}

export function useTrophyDocument(varTeam: string, _id: string, query?) {
  const presetDocument = useDocument<Trophy>(
    `${FPATH.CONFIGS}/${varTeam}/${FPATH.TROPHIES}/${_id}`,
    {
      listen: true,
      ...query,
    }
  );
  return { ...presetDocument };
}

export const testingSubdomains: string[] = ["localhost", "nxt", "testing", "shtest", "khtest", "jrtest", "nlnxt", "nltest"]
/**
   * Returns the subdomain if IS_WEB and there is a production client subdomain, otherwies returns null on purpose
   * - localhost, nxt, etc. return null
   * - Returning null means you should look at package.json for APP_CONFIG for the team id to use instead
   */
export function _getValidSubdomain() {
  if (!IS_WEB) return null;
  if (window.location.host === "app.patriotclubcard.com") return "patriotclubcard"
  let _subdomain = String(window.location.host).split(".")[0];
  if (testingSubdomains?.includes(_subdomain) || _subdomain.includes("localhost")) return null;
  return _subdomain
}
export function getAppLogoFromTitle(title: string): string {
  return `https://get-my.app/t/${title?.toLowerCase().replace(/ /g, "")}/logo.png`
}

export function useConfigCollection(query?: CollectionQueryType) {
  const { teamId } = useAppContext();
  const configHandler = useCollection<ConfigVariablesSchema>(FPATH.CONFIGS, {
    listen: true,
    ...query,
  });

  /**
   * ### Get config doc from subdomain
   * - In **dev environment** or one of **testing subdomains**,
   * it'll return app-takeoff-team, or specific `customTeamId`
   * (see _chosenTeamId)
   * - In other subdomains, it returns respective config docs
   */
  function _getConfigFromSubDomain(
    /** specific team id to get Config data from */
    customTeamId?: string
  ): ConfigVariablesSchema & { appLogo: string } {
    //#region [section] subdomain setup
    /**
     * Get subdomain from address bar on WEB,
     * ---
     * - For **localhost** or one of the **testing subdomains** --> return *"crew"*
     * - or set it as "mobile" for native app
     * - else return the specific subdomain
     * @returns
     */
    let subdomain = () => {
      if (IS_WEB) {
        let _subdomain = String(window.location.host).split(".")[0];
        // _subdomain = "gladiators"
        // if (_subdomain.includes("localhost")) {
        //   return "localhost";
        // } else

        if (
          testingSubdomains?.includes(_subdomain)
        ) {
          return "crew";
        } else {
          return _subdomain;
        }
      } else {
        return "mobile";
      }
    };
    //#endregion

    //#region [section2] chosen team id setup
    /**
     * Setup config data
     * ---
     * - If `customTeamId` is provided, get config from such teamId
     * - If subdomain is **"mobile"** --> return "app_team_id"
     *    - or return *"app-takeoff"* if such value is falsy (as a safety method)
     * - else return app's **"teamId"** or return **"app-takeoff"** if such value is falsy (as a safety method)
     * @returns
     */
    //#endregion

    //#region [section] config data setup
    const _data = configHandler.data?.filter((config) => {
      if (customTeamId) {
        return config._teamId === customTeamId && config.subdomain;
      } else if (subdomain() === "mobile") {
        const app_id = APP_CONFIG?.app_id || app_team_id
        return app_id
          ? config._teamId === app_id && config.subdomain
          : config._teamId === "app-takeoff" && config.subdomain;
      } else {
        return config?.subdomain && config?.subdomain == subdomain();
      }
    });
    //#endregion

    // console.log("***final match: "+JSON.stringify(data.pop()))
    let configDoc = _data && Array.isArray(_data) ? _data[0] : null; //* We are getting the first one because we want to get an object not an array

    if (configDoc) {
      const appLogo = getAppLogoFromTitle(configDoc.appTitle);

      return { ...configDoc, appLogo };
    }
    return null;
  }

  /**
   * ###
   *  - This function is used to check if the link is either a production link or localhost
   *  ----
   *  @example
   *  Copy and paste function is the best...
   *  ----
   *  @version 21.11.10
   *  -  *Brief changelog*
   *  @author  Nl
   *
   **/
  function _checkLocalHostorLive(url: string) {
    let subdomain = url.split(".")[0];
    let _devLocalHostChecker = subdomain.includes("localhost") ? true : false;

    return _devLocalHostChecker;
  }

  function getEveryAppsVimeoTokens(): dVimeoToken[] {
    if (!configHandler.data) {
      console.log(
        "[vimeo] config data not yet loaded or something went wrong! ;("
      );
      return [];
    }

    const vimeoTokenArrays = configHandler.data
      .filter((obj) => (obj?.vimeo ? true : false))
      .map((obj) => obj.vimeo);
    const allVimeoTokens: dVimeoToken[] = [];
    for (let v in vimeoTokenArrays) {
      const arr = vimeoTokenArrays[v];
      for (let t in arr) allVimeoTokens.push(arr[t]);
    }

    let addedTokens = "";
    const filteredTokens = [];

    /**
     * Add local app's tokens to the top first to speed up video loading times :D
     */
    for (let a in configHandler.data) {
      const config = configHandler.data[a];
      if (config?._teamId === teamId && config?.id.includes("keys")) {
        // @ts-ignore
        for (let t in config?.vimeo) filteredTokens.push(config?.vimeo[t]);
      }
    }

    // console.log(
    //   "[vimeo] local tokens added first: " + JSON.stringify(filteredTokens)
    // );

    for (let t in allVimeoTokens) {
      const vimeoToken = allVimeoTokens[t];
      if (!addedTokens.includes(vimeoToken.accessToken)) {
        filteredTokens.push(vimeoToken);
        addedTokens += vimeoToken.accessToken + ", ";
      }
    }
    // console.log("[vimeo] filtered tokens: " + JSON.stringify(filteredTokens));
    console.log(
      "[vimeo] returning " + filteredTokens.length + " tokens to try..."
    );

    return filteredTokens;
  }
  function getEveryAppsDropboxTokens(): string[] {
    if (!configHandler.data) {
      console.log(
        "[dropbox] config data not yet loaded or something went wrong! ;("
      );
      return [];
    }

    const dropboxTokensArray = configHandler.data
      .filter((obj) => (obj?.dropbox ? true : false))
      .map((obj) => obj.dropbox);
    const allDropboxTokens: string[] = [];
    for (let v in dropboxTokensArray) {
      const arr = dropboxTokensArray[v];
      for (let t in arr) allDropboxTokens.push(arr[t]);
    }

    // let addedTokens = "";
    const filteredTokens = [];

    /**
     * Add local app's tokens to the top first to speed up video loading times :D
     */
    for (let a in configHandler.data) {
      const config = configHandler.data[a];
      if (config?._teamId === teamId && config?.id.includes("keys")) {
        // @ts-ignore
        for (let t in config?.dropbox) filteredTokens.push(config?.dropbox[t]);
      }
    }

    // console.log(
    //   "[dropbox] local tokens added first: " + JSON.stringify(filteredTokens)
    // );

    for (let t in allDropboxTokens) {
      const dropboxToken = allDropboxTokens[t];
      if (!filteredTokens.includes(dropboxToken)) {
        filteredTokens.push(dropboxToken);
      }
    }
    // console.log("[dropbox] filtered tokens: " + JSON.stringify(filteredTokens));
    // console.log(
    //   "[dropbox] returning " + filteredTokens.length + " tokens to try..."
    // );

    return filteredTokens;
  }
  /**
   * ### Get config doc from subdomain
   * - In dev environment or `testing` subdomain,
   * it'll return app-takeoff-team, or any teamId of choice
   * (see _devSubdomain)
   * - In other subdomain, it returns appropiate config doc
   */
  function _getAppInfoFromTeamId(
    _teamId: string,
    option = "NONE"
  ): dAppConfigData | dAppConfigData[] {
    if (option && option === "ALL") {
      const allData = configHandler.data?.filter((c) =>
        c.id.includes("variables")
      );
      const tempData: dAppConfigData[] = [];
      allData?.forEach((cd) => {
        const appLogo = `https://get-my.app/t/${cd.appTitle
          ?.toLowerCase()
          .replace(/ /g, "")}/logo.png`;

        const tempCD: dAppConfigData = {
          teamId: cd._teamId,
          appTitle: cd.appTitle,
          appLogo: appLogo,
        };
        tempData.push(tempCD);
      });

      return tempData;
    } else {
      const data = configHandler.data?.filter(
        (configs) =>
          configs._teamId === _teamId && configs.id === `variables-${_teamId}`
      );
      // console.log("subdomain: ", configHandler.data);
      let configDoc = data && Array.isArray(data) ? data[0] : null;

      if (configDoc) {
        const appLogo = `https://get-my.app/t/${configDoc.appTitle
          ?.toLowerCase()
          .replace(/ /g, "")}/logo.png`;

        const returnData = {
          teamId: _teamId,
          appTitle: data.find((d) => d._teamId === _teamId).appTitle,
          appLogo: appLogo,
        };
        return returnData;
      }
      return null;
    }
  }

  /**
   * ### Get config doc from subdomain
   * - In dev environment or `testing` subdomain,
   * it'll return app-takeoff-team, or any teamId of choice
   * (see _devSubdomain)
   * - In other subdomain, it returns appropiate config doc
   */
  // function _getKeysFromSubDomain(url: string): dConfigKeys {
  //   let subdomain = url.split(".")[0];

  //   let _devSubdomain =
  //     subdomain.includes("localhost") || subdomain.includes("testing")
  //       ? "leaders" //"app-takeoff" //* suitable domain name
  //       : subdomain;

  //   const data = configHandler.data?.filter(
  //     (configs) => configs.subdomain === _devSubdomain
  //   );
  //   let configDoc = data && Array.isArray(data) ? data[1] : null;

  //   console.log("keys: " + JSON.stringify(configDoc));

  //   if (configDoc) {
  //     const appLogo = `https://get-my.app/t/${configDoc.appTitle
  //       ?.toLowerCase()
  //       .replace(/ /g, "")}/logo.png`;

  //     return configDoc;
  //   }
  //   return null;
  //   // return data && Array.isArray(data) ? data[0] : null; //We are getting the first one because we want to get an object not an array
  // }

  return {
    ...configHandler,
    _getConfigFromSubDomain,
    _getValidSubdomain,
    _checkLocalHostorLive,
    _getAppInfoFromTeamId,
    getEveryAppsVimeoTokens,
    getEveryAppsDropboxTokens,
  };
}

export async function updateConfigKeys(teamId: string, data: dConfigKeys) {
  return firebase
    .firestore()
    .collection(FPATH.CONFIGS)
    .doc(`keys-${teamId}`)
    .update(data);
}

export interface dAppConfigData {
  teamId: string;
  appTitle: string;
  appLogo: string;
}
