import { IconPrimr } from "assets";
import Color from "color";
import { Txt } from "components";
import { useAppContext } from "engines";
import { smallerSizeFromURL } from "engines/functions/media-functions";
import * as R from "ramda";
import React from "react";
import {
  Dimensions,
  Image,
  ImageBackground,
  ImageProps,
  Pressable,
  useWindowDimensions,
  View
} from "react-native";
import { dGalleryScrParams } from "screens";
import { useNavigator } from "screens/_navigation/navigation-utils";
import { IS_WEB } from "utils";
import { sstyled } from "../sstyled/sstyled";

export interface dImaggie extends ImageProps {
  fixedWidth?: number;
  backgroundBlurRadius?: number;
  expandable?: boolean;
}

/**
 * ### Image component of the project
 *  - Support connecting to <GalleryScreen> for fullscreen view
 *  ----
 *  @example
 *  <Imaggie source={{}}  />
 *  ----
 *  @version 21.09.16
 *  -  *Update `expandable` props*
 *  @nguyenkhooi
 *
 **/
export function Imaggie(props: dImaggie) {
  const {
    source,
    fixedWidth = 250, //* reference from CardPosst
    backgroundBlurRadius = 80,
    expandable = true,
  } = props;
  const { C } = useAppContext();
  const dimension = useWindowDimensions();
  const windowWidth = Dimensions.get("window").width
  const Navigation = useNavigator();

  const optimizedSource = source?.uri && source?.uri?.includes("cloudinary") ? { uri: smallerSizeFromURL(source?.uri, windowWidth * 5) } : source?.uri

  const [mediaDimension, setMediaDimension] = React.useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const [error, setError] = React.useState<string>("");
  React.useLayoutEffect(function getMediaDimension() {
    !!source?.uri &&
      Image.getSize(source?.uri, (w, h) => {
        let _fixedWidth = fixedWidth || IS_WEB ? 450 : dimension.width * 0.95;
        const _ratio = _fixedWidth / w;
        const height = h * _ratio;
        setMediaDimension({ width: _fixedWidth, height });
      });
  }, []);

  if (!R.isEmpty(error)) {
    return (
      <A.CtnrError>
        <Txt.Indicator>{error}</Txt.Indicator>
      </A.CtnrError>
    );
  }

  return (
    <Pressable
      accessibilityRole="button"
      disabled={!expandable}
      onPress={() => {
        expandable &&
          Navigation.navigate<dGalleryScrParams>("Gallery", {
            imageIndex: 0,
            images: [{ url: props.source?.uri }],
          });
      }}
    >
      {({ hovered }) => (
        <ImageBackground
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
          source={optimizedSource}
          resizeMode="cover"
          blurRadius={backgroundBlurRadius}
        >
          <A.ImgResponsive
            // source={props.source?.uri}
            resizeMode="contain"
            {...props}
            source={optimizedSource}
            style={[props?.style && props.style]}
            mediaDimension={mediaDimension}
            onError={(err) => {
              console.error(err)
              setError(`${err}`)
            }}
          />

          {(!IS_WEB || hovered) && expandable && R.isEmpty(error) && (
            <IconPrimr
              preset={"circular"}
              name="expand"
              color={C.text01}
              size={11}
              containerStyle={{
                backgroundColor: Color(C.primary).alpha(0.8).toString(),
                position: "absolute",
                right: 10,
                bottom: 10,
              }}
            />
          )}
        </ImageBackground>
      )}
    </Pressable>
  );
}

const A = {
  ImgResponsive: sstyled(Image)(
    (p: {
      mediaDimension: {
        width: number;
        height: number;
      };
    }) => ({
      alignSelf: "center",
      width: p.mediaDimension.width,
      height: p.mediaDimension.height,
    })
  ),
  CtnrError: sstyled(View)({
    width: 5,
    height: 5,
    justifyContent: "center",
    alignItems: "center",
  }),
};
