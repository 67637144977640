
/**
 * ###  User Doc Schema
 * -    Should be identical to such doc in FRBS.
 * ---
 * @version 0.12.21
 * @author Nl
 * ---
 * @example
 * const MOCK_USER = {
    _id: "3X8etYGXLVWmbc0QIFXz",
    theme: "themeLight",
    personali: {
        displayName: "Soy Sauce",
        email: "soy.at.sauce.com",
        phoneNumber: "4343331234",
        photoURL: "3X8etYGXLVWmbc0QIFXz",
    },
    roles: ["ADMIN"],
    };
    
 */
export interface MailSchema {
  id?: string;
  userId: string;
  type: string;
  subject: string;
  recipient: string;
  cc: string;
  body: string;
  appId: string;
  To: string;
  SubmittedAt: string;
  textbody: string;
  /**
   * This message Id is gotten from postmark
   */
  MessageID: string;
  Message: string;
  ErrorCode?: number;
  createdAt: firebase.firestore.FieldValue;
}

/**
 * ###  User data to load when user sign up
 * -  Replace `_id`, `personali` with accurate data if possible
 */
export const _DEFAULT_EMAIL: MailSchema = {
  userId: "eXLEw0upFtWkTXbokCznZzzGLKH2",
  type: "single",
  subject: "I am testing soomething new",
  recipient: "paulnanle611@gmail.com",
  cc: "",
  body: "<div>I am writing you this mail because I wanna test something</div>",
  appId: "primerica-template",
  To: "paulnanle611@gmail.com",
  SubmittedAt: "2021-03-23T10:38:37.2837245Z",
  MessageID: "2593050a-7f86-43ef-8c31-3bb81c553c35",
  Message: "OK",
  ErrorCode: 0,
  textbody: "Hello world",
  createdAt: new Date()
  // createdAt: new Date()
};
