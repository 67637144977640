import type { FieldValue, SetOptions } from "@firebase/firestore-types";
import {
  AllowType,
  CollectionQueryType,
  deleteDocument,
  Document,
  useCollection
} from "@nandorojo/swr-firestore";
import {
  CommentSchema,
  dScore,
  Handler,
  MailSchema,
  MediaPageItemSchema,
  MediaPageSchema,
  PosstSchema,
  ScheduleSchema,
  ScoreboardSchema,
  TemplateSchema,
  UserSchema
} from "engines/backends";
import { useAppContext } from "engines/providers";
import "firebase/auth";
import "firebase/firestore";
import * as React from "react";
import { FPATH } from "./firebase.props";

/** @deprecated */
export function useCollectionPreset<Schema extends object = {}>({
  path = FPATH.SCOREBOARDS,
  keyName,
  query,
}: {
  path: FPATH;
  keyName: string;
  query?: CollectionQueryType<Schema>;
}) {
  const { teamId } = useAppContext();
  const { frbsUser } = useAppContext();

  const [_path, setPath] = React.useState<string>(null);
  //#region [section] swr-firestore hook
  let hook: any;
  const hookThatAlreadyExists = activeHooks
    .filter((h) => h.path === path)
    .pop();
  if (hookThatAlreadyExists) {
    hook = hookThatAlreadyExists;
  } else {
    hook = useCollection<Schema>(`${_path}`, {
      where: ["_teamId", "==", teamId],
      listen: true,
      ...query,
    });
    activeHooks.push({ path, hook });
  }
  const { data, error, ...rest } = hook;
  // console.log("collection: " + _path);
  React.useEffect(
    function validateData() {
      if (data && keyName) {
        data?.forEach((doc) => {
          let docData = doc?.__snapshot?.data() as Schema;

          docData &&
            (typeof docData[keyName] === "undefined" ||
              docData[keyName] !== doc.__snapshot.id) &&
            doc.__snapshot.ref.update({
              [keyName]: doc.__snapshot.id,
            } as Schema);
        });
      }
    },
    [data]
  );
  //#endregion
  //#region [section2] internal collection data (to persist data & reduce number of reads in db)
  const [collectionData, setCollectionData] =
    React.useState<dUseCollectionReturns<Schema>["data"]>(null);

  React.useEffect(
    function buildData() {
      if (frbsUser && frbsUser.uid) {
        setPath(path);
        error && (setCollectionData(null), console.warn(error));
        data &&
          (setCollectionData(data),
          console.log("collection preset: ", collectionData?.length));
      } else {
        setPath(null);
        setCollectionData(null);
        console.log(`collection ${path} reset!`);
      }
    },
    [error, data, frbsUser]
  );
  //#endregion
  //#region [section] handle function that will be used externally

  let handleCollection: dUseCollectionReturns<Schema> = {
    ...rest,
    error,
    data,
    deleteDocument(id: string) {
      console.log("collection clicked!: ", `${path}/${id}`);
      return deleteDocument(`${path}/${id}`).catch((e) =>
        console.log("collection err: ", e)
      );
    },
  };
  //#endregion
  return handleCollection;
}

const activeHooks: { path: string; hook: any }[] = [];

export function useCollectionPresetv2<Schema extends object = {}>(
  path: string = FPATH.SCOREBOARDS,
  idKey: string = "_id",
  query?: CollectionQueryType<Schema>
) {
  const { teamId } = useAppContext();
  // const { frbsUser } = useAppContext();
  /**
   * Check the depth of path by counting "/"
   * - check if path is subcollection or not
   */
  let pathDepth = (path?.match(/\//g) || []).length;
  //#region [section] swr-firestore hook
  const defaultCollection = useCollection<Schema>(path, {
    where: pathDepth < 1 ? ["_teamId", "==", teamId] : null,
    // where: pathDepth < 1 ? ["_teamId", "==", "landrumlegends"] : null, //* Use when you want to display a specific _teamId's contents
    listen: true,
    ...query,
  });

  const { data, add: swrAdd, error, ...rest } = defaultCollection;
  // console.log("collection: " + _path);
  // React.useEffect(
  //   function validateData() {
  //     if (data && keyName) {
  //       data?.forEach((doc) => {
  //         let docData = doc?.__snapshot?.data() as Schema;

  //         docData &&
  //           (typeof docData[keyName] === "undefined" ||
  //             docData[keyName] !== doc.__snapshot.id) &&
  //           doc.__snapshot.ref.update({
  //             [keyName]: doc.__snapshot.id,
  //           } as Schema);
  //       });
  //     }
  //   },
  //   [data]
  // );
  //#endregion

  async function add(data: Schema | Schema[]) {
    // let id = await swrAdd(data);
    return new Promise((resolve, reject) => {
      swrAdd(data)
        /**
         * Disable this to prevent freezing when adding contents to either resource pages or bs
         */
        .then((id) => {
          // console.log("id: ", id);
          Handler.updateId(`${path}/${id}`, idKey, id);
          resolve(id);
        })
        .catch((e) => reject(e));
      // id && (Handler.updateId(`${path}/${id}`, idKey, id), resolve(id)); //todo take care of id == string[] case
    });
  }

  let handleCollection: dUseCollectionReturns<Schema> = {
    ...rest,
    add,
    error,
    data,
    deleteDocument(id: string) {
      return deleteDocument(`${path}/${id}`)
        .then((r) => console.log("doc " + `${path}/${id}` + " is deleted"))
        .catch((e) => console.log("collection err: ", e));
    },
  };

  React.useEffect(function cleanup() {
    return () => {
      rest.unsubscribe &&
        (rest.unsubscribe(), console.log("unsubscribing " + path));
    };
  }, []);
  return handleCollection;
}

export interface dFirestoreContext {
  userProfile: dUseDocumentReturns<UserSchema>;
  handleScoreboards: dUseCollectionReturns<ScoreboardSchema>;
  handleScoreboard({ _id }): dUseDocumentReturns<ScoreboardSchema> & {
    xScore(uid: string): void;
    updateScore(uid: string, score: dScore["score"]): void;
    resetScore(): void;
  };
  handleAnnouncements: dUseCollectionReturns<PosstSchema>;
  handleAnnouncement({ _id }): dUseDocumentReturns<PosstSchema>;
  handleComments(pid): dUseCollectionReturns<CommentSchema>;
  handleComment(pid: string, cid: string): dUseDocumentReturns<CommentSchema>;
  handleMembers: dUseCollectionReturns<UserSchema>;
  handleMember({ _id }): dUseDocumentReturns<UserSchema>;
  handlePages: dUseCollectionReturns<MediaPageSchema>;
  handlePage({ _id }): dUseDocumentReturns<MediaPageSchema>;
  handlePageContents(
    pageId: string
  ): dUseCollectionReturns<MediaPageItemSchema>;
  handlePageItem(
    pageId: string,
    pageContentId: string
  ): dUseDocumentReturns<MediaPageItemSchema>;
  teamId: string;
  handleMails: dUseCollectionReturns<MailSchema>;
  handleTemplates: dUseCollectionReturns<TemplateSchema>;
  handleTemplateItem({ _id }): dUseDocumentReturns<TemplateSchema>;
  handleSchedulesMails: dUseCollectionReturns<ScheduleSchema>;
  handleSchedulesItem({ _id }): dUseDocumentReturns<ScheduleSchema>;
}

export interface dFirestoreDocumentSnapshot {
  id: string;
  exists?: boolean | undefined;
  hasPendingWrites?: boolean | undefined;
  __snapshot?:
    | import("@firebase/firestore-types").QueryDocumentSnapshot<
        import("@firebase/firestore-types").DocumentData
      >
    | undefined;
}

export interface dUseCollectionReturns<
  Data extends object = {},
  Doc extends dFirestoreDocumentSnapshot = Document<Data>
> {
  /**
   * Delete specific document from the collection, by its id
   * @param id
   */
  deleteDocument(id: string): void;
  data: Doc[] | null | undefined;
  isValidating: boolean;
  revalidate: () => Promise<boolean>;
  mutate: (
    data?:
      | Doc[]
      | Promise<Doc[] | null>
      | ((currentValue: Doc[] | null) => Doc[] | null)
      | null
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<Doc[] | null | undefined>;
  error: any;
  /**
   * ### Create a new page
   * - Returns the page id
   */
  add: (data: Data | Data[]) => Promise<string> | null;
  loading: boolean;
  /**
   * A function that, when called, unsubscribes the Firestore listener.
   *
   * The function can be null, so make sure to check that it exists before calling it.
   *
   * Note: This is not necessary to use. `useCollection` already unmounts the listener for you. This is only intended if you want to unsubscribe on your own.
   */
  unsubscribe: (() => void) | null;
}

export interface dUseDocumentReturns<
  Data extends object = {},
  Doc extends dFirestoreDocumentSnapshot = Document<Data>
> {
  data: Doc | null | undefined;
  isValidating: boolean;
  revalidate: () => Promise<boolean>;
  mutate: (
    data?:
      | Doc
      | Promise<Doc | null>
      | ((currentValue: Doc | null) => Doc | null)
      | null
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<Doc | null | undefined>;
  error: any;
  set: (
    data: Partial<AllowType<Data, FieldValue>>,
    options?: SetOptions | undefined
  ) => Promise<void> | null;
  update: (data: Partial<AllowType<Data, FieldValue>>) => Promise<void> | null;
  loading: boolean;
  deleteDocument: () => Promise<void> | null;
  /**
   * A function that, when called, unsubscribes the Firestore listener.
   *
   * The function can be null, so make sure to check that it exists before calling it.
   *
   * **Note**: This is not necessary to use. `useDocument` already unmounts the listener for you. This is only intended if you want to unsubscribe on your own.
   */
  unsubscribe: (() => void) | null;
}
