import { CheckBox, TextProps } from "@ui-kitten/components";
import {
  AudioItem,
  Buttoon,
  dButtoon,
  Highlighter,
  Imaggie,
  Kitten,
  sstyled,
  Toasty,
  Txt,
  Videolly,
  VimeoVideolly,
  YoutubeVideolly
} from "components";
import {
  fn,
  ItemType,
  MediaPageItemSchema,
  ServiceType,
  stringValue,
  typeOfItem,
  useAppContext
} from "engines";
import { LinkURL } from "engines/functions/web-functions";
import { Video } from "expo-av";
import React from "react";
import { Dimensions, View } from "react-native";
import { useNavigator } from "screens";
import { dSearchResultCollab } from "screens/collaboration/Collaboration";
import { spacing, tr } from "utils";
import { useCollabContext } from "../../../engines/providers/collaboration-provider";
/**
 * ### List Hierarchy ListItem participating in Collaboration
 *  ----
 *  @version 21.11.07
 *  @author  Saurabh_M
 *
 **/
export function CollaborationListItem(props: dCollabListItem) {
  const {
    index,
    inputData,
    onPress,
    keyword,
    editModeEnabled,
    selectedFilter,
  } = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const { width: screenWidth, height: screenHeight } = Dimensions.get("screen");

  const {
    isSelectModeOn,
    pageContentItems,
    setPageContentItems,
    setIsCollabModeOn,
    setIsSelectModeOn,
  } = useCollabContext();
  const [parsePCItem, setParsePCItem] = React.useState<MediaPageItemSchema>();
  const [itemIndexSelected, setItemIndexSelected] =
    React.useState<number>(null);

  React.useEffect(() => {
    editModeEnabled == false && setItemIndexSelected(null);
  }, [editModeEnabled]);

  const getItem = () => {
    const item: MediaPageItemSchema = {
      ...inputData,
    };
    // removing fields that are not required in pageContent
    Object.keys(item).includes("pageId") && delete item["pageId"];
    Object.keys(item).includes("mediaType") && delete item["mediaType"];
    return item;
  };

  React.useEffect(() => {
    const item = getItem();

    // console.log("item is ", item);
    setParsePCItem(item);
  }, [inputData]);

  const input = inputData;
  let { itemType, serviceType } = typeOfItem(input);

  function onPhotoPreview() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Content Viewer"),
      render: (
        <>
          <Imaggie source={{ uri: inputData.media }} />
          <Txt>{inputData.title}</Txt>
        </>
      ),
    });
  }

  /**
   * @description Handles the video logic for content multiverse search result items
   * @author Saurabh_M
   * @date 2022.07.22
   * @param {dSearchResultCollab} input
   * @param {ServiceType} serviceType
   */
  const onVideoPreview = () => {
    Navigation.dialogPortal.open({
      headerTitle: tr("Content Viewer"),
      render: (
        <>
          <Txt.H4 style={{ color: C.text, marginBottom: 10 }}>
            {input.title}
          </Txt.H4>
          {serviceType === ServiceType.CLOUDINARY && (
            <Videolly type="thumbnail" source={{ uri: input?.media }} />
          )}
          {serviceType === ServiceType.YOUTUBE && (
            <YoutubeVideolly
              item={input}
              videoId={fn.media.getYoutubeId(input?.media)}
            />
          )}
          {serviceType === ServiceType.VIMEO && (
            <VimeoVideolly type="thumbnail" item={input} />
          )}
        </>
      ),
    });
  };
  function onAudioPreview() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Content Viewer"),
      render: <AudioItem item={inputData} serviceType={ServiceType.DROPBOX} />,
    });
  }

  function onAudioFolderPreview() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Content Viewer"),
      render: (
        <>
          <Txt.H4 style={{ color: C.text, marginBottom: 10 }}>
            {input.title}
          </Txt.H4>
          <Buttoon onPress={() => LinkURL(input.media)} appearance="ghost">
            {tr("Click Here To View")}
          </Buttoon>
        </>
      ),
    });
  }

  function onParagraphPreview() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Content Viewer"),
      render: (
        <>
          <Txt
            style={{
              color: C.text,
              marginVertical: 5,
              maxWidth: screenWidth * 0.9,
            }}
          >
            {input?.paragraph}
          </Txt>
        </>
      ),
    });
  }

  function onBlastPreview() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Blast Text"),
      render: <Txt>{input?.title}</Txt>,
    });
  }

  function onButtonPreview() {
    input.mediaType === "link"
      ? LinkURL(input?.url)
      : Toasty.show("No preview available", { type: "warning" });
  }

  const handleChange = () => {
    if (!parsePCItem) {
      const item = getItem();
      setPageContentItems([...pageContentItems, item]);
      return;
    }

    if (!!pageContentItems.find((p) => p?._id === inputData?._id)) {
      setPageContentItems([
        ...pageContentItems.filter((p) => p._id !== inputData._id),
      ]);
    } else {
      if (pageContentItems.length === 0) {
        setPageContentItems([parsePCItem]);
      } else {
        setPageContentItems([...pageContentItems, parsePCItem]);
      }
    }
  };

  // #region [ANCHOR2] Enforcing Collab Mode to stay true since this is collaboration file
  React.useEffect(() => {
    setIsCollabModeOn(true);
  }, []);
  // #endregion

  const ITEM_CONFIG: {
    [type: string]: {
      // iconName: ICON_NAME;
      titleProps?: TextProps;
      previewButtonProps?: dButtoon;
    };
  } = {
    [ItemType.TITLE]: {
      titleProps: { category: "h5", style: { fontWeight: "bold" } },
      previewButtonProps: {
        disabled: true,
        children: tr("Heading"),
        icon: { name: "title" },
      },
    },
    [ItemType.PARAGRAPH]: {
      previewButtonProps: {
        children: tr("Preview paragraph"),
        onPress: onParagraphPreview,
        icon: { name: "text" },
      },
    },

    [ItemType.PHOTO]: {
      previewButtonProps: {
        children: tr("Preview photo"),
        onPress: onPhotoPreview,
        icon: { name: "image" },
      },
    },
    [ItemType.VIDEO]: {
      previewButtonProps: {
        children: tr("Preview Video"),
        onPress: onVideoPreview,
        icon: { name: "videocam" },
      },
    },

    [ItemType.AUDIO]: {
      previewButtonProps: {
        icon: { name: "play" },
        children: tr("Preview audio"),
        onPress: onAudioPreview,
      },
    },

    [ItemType.AUDIO_FOLDER]: {
      previewButtonProps: {
        icon: { name: "play" },
        children: tr("Preview audio"),
        onPress: onAudioFolderPreview,
      },
    },

    [ItemType.BLAST_TEXT]: {
      previewButtonProps: {
        icon: { name: "sms" },
        children: tr("Preview blast text"),
        onPress: onBlastPreview,
      },
    },

    [ItemType.BUTTON]: {
      titleProps: { category: "s1", style: { fontWeight: "bold" } },
      previewButtonProps: {
        onPress: onButtonPreview,
        disabled: input.mediaType === "link" ? false : true,
        icon: {
          name:
            input?.mediaType === "chevron_right" ? "page" : input?.mediaType,
        },
        children:
          input?.mediaType === "chevron_right"
            ? tr("Visit page")
            : tr("Open Link"),
      },
    },
  };

  return React.useMemo(() => {
    try {
      return (
        <View>
          <Kitten.ListItemv2
            // style={_isResourceNew && { backgroundColor: C["color-danger-400"] }}
            key={index}
            onPress={() => {
              setIsSelectModeOn(true);
              handleChange();
            }}
            title={() => (
              <Kitten.Text
                {...ITEM_CONFIG[itemType]?.titleProps}
                numberOfLines={2}
              >
                <Highlighter
                  highlightStyle={{
                    backgroundColor: C["color-warning-transparent-500"],
                  }}
                  searchWords={[keyword]}
                  autoEscape={true}
                  textToHighlight={
                    inputData &&
                    stringValue(inputData?.title)?.replaceAll("-", "").length >
                      0
                      ? inputData.title
                      : inputData.paragraph
                      ? "..." +
                        keyword +
                        stringValue(inputData.paragraph)
                          ?.trim()
                          ?.replaceAll("\n", "")
                          ?.toLowerCase()
                          ?.split(keyword)
                      : // ?.substring(0, 50)
                        `Content containing "${keyword}"`
                  }
                  textToHighlight={inputData.title || inputData.paragraph}
                />
              </Kitten.Text>
            )}
            description={() =>
              inputData.mediaType === "link" && (
                <Txt.S2
                  style={{ color: C.primary, textDecorationLine: "underline" }}
                >
                  {String(inputData?.url).length > 45
                    ? inputData?.url.substring(0, 45) + "..."
                    : inputData?.url}
                </Txt.S2>
              )
            }
            accessoryRight={() =>
              isSelectModeOn && (
                <Kitten.CheckBox
                  checked={
                    !!pageContentItems.find((p) => p._id === inputData._id)
                  }
                  onChange={handleChange}
                />
              )
            }
          />
          <Kitten.Divider />
          <Kitten.Layout
            level="1"
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Buttoon
              icon={{ name: "expand" }}
              children={tr("Preview")}
              {...ITEM_CONFIG[itemType]?.previewButtonProps}
              appearance="ghost"
              size="small"
            />
            <Buttoon
              onPress={onPress}
              appearance="ghost"
              icon={{ name: "to_page", right: true }}
              status="info"
              size="small"
            >
              {tr("Visit item location")}
            </Buttoon>
          </Kitten.Layout>
        </View>
      );
    } catch (error) {
      //
      throw Error(`::TWVkaWFIbA67s21672a1lSXRlbQ==::${error}`);
    }
  }, [
    editModeEnabled,
    itemIndexSelected,
    isSelectModeOn,
    C,
    pageContentItems,
    inputData,
    selectedFilter,
  ]);
}

interface dCollabListItem {
  index: number;
  inputData: dSearchResultCollab;
  keyword: string;
  onPress(): void;
  editModeEnabled: boolean;
  selectedFilter: string;
}

// interface dInputData {
//   pageContentData: Partial<MediaPageItemSchema>;
//   teamName: string;
//   teamLogo: string;
// }

const A = {
  ViewCheckbox: sstyled(View)({
    marginRight: spacing(3),
    position: "absolute",
    left: 15,
    bottom: 25,
    height: "100%",
    justifyContent: "center",
    //@ts-ignore
    transform: [{ scale: 1.166 }],
  }),
  KittenCheckbox: sstyled(CheckBox)({
    margin: 2,
  }),
  Video: sstyled(Video)((p) => ({
    alignSelf: "center",
    width: 320,
    height: 200,
  })),
};
