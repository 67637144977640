import { Avt, Buttoon, Kitten, Txt } from "components/atoms";
import { useSx } from "dripsy";
import { useAppContext, UserSchema } from "engines";
import * as React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, tr } from "utils";

interface dScoreEditor {
  personali: UserSchema["personali"];
  score: string | number;
  onSubmitCallback: (score: number) => void;
  onRemoveCallback?: () => void;
  shouldNavigateBack?: boolean;
}

/**
 * ### Component for create/edit score
 *  - Used for both user and member
 *  ----
 *  @example
 *
 *  ----
 *  @version 22.05.27
 *  -  *Brief changelog*
 *  @author  NK
 *
 **/
export function ScoreEditor({
  personali,
  score,
  onSubmitCallback,
  onRemoveCallback,
  shouldNavigateBack = true,
}: dScoreEditor) {
  const { C } = useAppContext();
  const [updatedScore, setUpdatedScore] = React.useState<string>(String(score));
  const Navigation = useNavigator();

  function onRemove() {
    shouldNavigateBack && Navigation.goBack();
    onRemoveCallback && onRemoveCallback();
  }

  function onSubmit() {
    if (!updatedScore) {
      //* if input is blank, fire onRemove()
      onRemove();
    } else {
      shouldNavigateBack && Navigation.goBack();
      onSubmitCallback && onSubmitCallback(Number(updatedScore));
    }
  }

  const sx = useSx();
  return (
    <Kitten.Card>
      <View style={{ alignItems: "center" }}>
        <Avt
          name={personali?.displayName}
          source={{ uri: personali?.photoURL }}
          size="medium"
        />
        <Txt.H6>{personali?.displayName}</Txt.H6>

        <Txt.Helper marginV>{tr("New score")}</Txt.Helper>
        <View
          style={sx({
            // width: [scale(300), scale(290), scale(280)],
            width: [scale(500), scale(490), scale(480)],
          })}
        >
          <TextInput
            style={{
              fontSize: 60,
              fontWeight: "bold",
              textAlign: "center",
              color: C.text,
            }}
            maxLength={10}
            placeholder={String(score)}
            onChangeText={setUpdatedScore}
            value={updatedScore}
            selectTextOnFocus={true}
            autoFocus
            keyboardType="numeric"
            onSubmitEditing={onSubmit}
          />
        </View>
        <Kitten.Divider_________ />
        <Kitten.ViewH>
          <Buttoon
            onPress={onSubmit}
            icon={{ name: "enter", right: true }}
            disabled={
              !updatedScore || updatedScore == String(0) || updatedScore == 0
            }
          >
            {tr("Submit")}
          </Buttoon>
          {onRemoveCallback && (
            <Buttoon appearance={"ghost"} status="danger" onPress={onRemove}>
              {tr("Remove score?")}
            </Buttoon>
          )}
        </Kitten.ViewH>
      </View>
    </Kitten.Card>
  );
}
