import { Txt } from "components";
import { useAppContext } from "engines";
import * as React from "react";
import {
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
  View
} from "react-native";
import { SwipeListView } from "react-native-swipe-list-view";

interface dSwupeyList {}

/**
 * ### A swipe to action list
 *  - Based on SwipeListView
 *  ----
 *  @example
 *  <SwipeyList />
 *  ----
 *  @version 21.07.20
 *  -  *Build a themed component*
 *  @author  K
 *
 **/
export function SwipeyList(props: dSwupeyList) {
  const { C, teamId } = useAppContext();

  // React.useEffect(
  //   function setupData() {
  //     handleMembers.data && setListData(handleMembers.data);
  //   },
  //   [handleMembers.data]
  // );
  const [listData, setListData] = React.useState(
    Array(20)
      .fill("")
      .map((_, i) => ({ key: `${i}`, Txt: `item #${i}` }))
  );

  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };

  const deleteRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    const newData = [...listData];
    const prevIndex = listData.findIndex((item) => item.key === rowKey);
    newData.splice(prevIndex, 1);
    setListData(newData);
  };

  const onRowDidOpen = (rowKey) => {
    console.log("This row opened", rowKey);
  };

  const renderItem = (data, rowMap) => (
    <TouchableHighlight
      onPress={() => {
        if (rowMap[data.item?.key]) {
          rowMap[data.item?.key].manuallySwipeRow(-150);
        }
      }}
      style={styles.rowFront}
      underlayColor={"#AAA"}
    >
      <View>
        <Txt>I am {data.item.key} in a SwipeListView</Txt>
      </View>
    </TouchableHighlight>
  );

  const renderHiddenItem = (data, rowMap) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => closeRow(rowMap, data.item.key)}
      >
        <Txt style={styles.backTxtWhite}>Close</Txt>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={() => deleteRow(rowMap, data.item.key)}
      >
        <Txt style={styles.backTxtWhite}>Delete</Txt>
      </TouchableOpacity>
    </View>
  );

  return (
    <View style={styles.container}>
      <SwipeListView
        data={listData}
        renderItem={renderItem}
        renderHiddenItem={renderHiddenItem}
        rightOpenValue={-150}
        previewRowKey={"0"}
        previewOpenValue={-40}
        previewOpenDelay={3000}
        onRowDidOpen={onRowDidOpen}
        {...props}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  backTxtWhite: {
    color: "#FFF",
  },
  rowFront: {
    alignItems: "center",
    backgroundColor: "#CCC",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    justifyContent: "center",
    height: 50,
  },
  rowBack: {
    alignItems: "center",
    backgroundColor: "#DDD",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: "blue",
    right: 75,
  },
  backRightBtnRight: {
    backgroundColor: "red",
    right: 0,
  },
});
