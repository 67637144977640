//#region [import]
import "firebase/auth";
import "firebase/firestore";
import React, { useContext } from "react";
// import { useNavigator } from "screens";
import { ass, ASS_KEY } from "utils/storage";
import { MediaPageItemSchema } from "../../../functions/src/schemas";
//#endregion

/**
 * ### Collaboration Provider,
 *
 * providing:
 * -  collaborationModeOn
 * -  setCollaborationMode
 * ---
 * @version 21.11.15
 * - *Add KeyboardAvoidingView*
 * @author Saurabh_M
 * ---
 * @example
 *  <CollaborationProvider />
 */
export function CollaborationProvider(props: dCollabProvider) {
  const { children } = props;

  const [isCollabModeOn, _setIsCollabModeOn] = React.useState<boolean>(false);
  const [isSelectModeOn, setIsSelectModeOn] = React.useState<boolean>(false);
  const [pageContentItems, setPageContentItems] = React.useState<
    MediaPageItemSchema[]
  >([]);
  const [copyToPageId, setCopyToPageId] = React.useState<string>("");
  const [copyFromPageId, setCopyFromPageId] = React.useState<string>("");
  const [selectAll, setSelectAll] = React.useState<boolean>(false);
  const [navToTitle, setNavToTitle] = React.useState<string>("");
  // const setIsSelectModeOn = React.useCallback((value: boolean) => {
  //   _setIsCollabModeOn(value);
  // }, []);

  const clearCollabContext = () => {
    setIsSelectModeOn(false);
    setPageContentItems([]);
    setSelectAll(false);
    setCopyToPageId("");
    setIsCollabModeOn(false);
  };

  //#region [FRBS]

  /**
   * Used in getting the collaboration mode on or off from the storage, and this is neccessary because of when the user refreshes the screen
   */
  ass.loadStringCustom(ASS_KEY.ISCOLLABMODEON).then((res) => {
    _setIsCollabModeOn(JSON.parse(res));
    return res;
  });

  function setIsCollabModeOn(collabOn: boolean) {
    if (collabOn) {
      ass
        .saveString(ASS_KEY.ISCOLLABMODEON, JSON.stringify(collabOn))
        .then(() => _setIsCollabModeOn(collabOn));
    } else {
      ass
        .saveString(ASS_KEY.ISCOLLABMODEON, JSON.stringify(false))
        .then(() => _setIsCollabModeOn(false));
    }
  }

  //#endregion

  //#region [section] setup display uncompleted items only
  const [onlyShowUncompletedContents, setOnlyShowUncompletedContents] =
    React.useState<boolean>(false);
  //#endregion

  return (
    <CollaborationContext.Provider
      value={{
        isCollabModeOn,
        setIsCollabModeOn,
        isSelectModeOn,
        setIsSelectModeOn,
        pageContentItems,
        setPageContentItems,
        copyToPageId,
        setCopyToPageId,
        selectAll,
        setSelectAll,
        copyFromPageId,
        setCopyFromPageId,
        clearCollabContext,
        navToTitle,
        setNavToTitle,
        onlyShowUncompletedContents,
        setOnlyShowUncompletedContents,
      }}
    >
      {children}
    </CollaborationContext.Provider>
  );
}

export const CollaborationContext = React.createContext<dCollabContext>({
  isCollabModeOn: false,
  setIsCollabModeOn: () => {},
  isSelectModeOn: false,
  setIsSelectModeOn: () => {},
  pageContentItems: [],
  setPageContentItems: () => {},
  copyToPageId: "",
  setCopyToPageId: () => {},
  copyFromPageId: "",
  setCopyFromPageId: () => {},
  selectAll: false,
  setSelectAll: () => {},
  clearCollabContext: () => {},
  navToTitle: "",
  setNavToTitle: () => {},
  onlyShowUncompletedContents: false,
  setOnlyShowUncompletedContents: () => {},
});

/**
 * Collaboration context hook
 *
 * ---
 * @example
 * ```
 * const { isCollabModeOn } = useCollabContext()
 * ```
 *
 * @version 21.11.15
 * - *Collaboration Context*
 * @author Saurabh_M
 */
export const useCollabContext = () => useContext(CollaborationContext);

interface dCollabProvider {
  children: React.ReactNode;
}
export interface dCollabContext {
  isCollabModeOn?: boolean;
  setIsCollabModeOn?: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectModeOn?: boolean;
  setIsSelectModeOn?: React.Dispatch<React.SetStateAction<boolean>>;
  pageContentItems?: MediaPageItemSchema[];
  setPageContentItems?: React.Dispatch<
    React.SetStateAction<MediaPageItemSchema[]>
  >;
  copyToPageId: string;
  setCopyToPageId?: React.Dispatch<React.SetStateAction<string>>;
  copyFromPageId: string;
  setCopyFromPageId?: React.Dispatch<React.SetStateAction<string>>;
  navToTitle: string;
  setNavToTitle?: React.Dispatch<React.SetStateAction<string>>;
  selectAll?: boolean;
  setSelectAll?: React.Dispatch<React.SetStateAction<boolean>>;
  clearCollabContext?: Function;
  onlyShowUncompletedContents: boolean;
  setOnlyShowUncompletedContents: React.Dispatch<React.SetStateAction<boolean>>;
}
