import { CollectionQueryType, useDocument } from "@nandorojo/swr-firestore";
import { FPATH, PosstSchema } from "engines";
import { useCollectionPresetv2 } from "../config";

export function usePosstDocument(id: string, query?) {
  const presetDocument = useDocument<PosstSchema>(`${FPATH.POSSTS}/${id}`, {
    listen: true,
    ...query,
  });

  return { ...presetDocument };
}

export function usePosstsCollection(query?: CollectionQueryType<PosstSchema>) {
  const posstsHandler = useCollectionPresetv2<PosstSchema>(
    FPATH.POSSTS,
    "_pid",
    query
  );
  return { ...posstsHandler };
}
