import { Avt, Buttoon, Inpuut, Kitten, ListHeader, Toasty } from "components";
import { useSx } from "dripsy";
import { MediaPageSchema, useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import firebase from "firebase";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { dResourcesBodyScreenParam } from "screens/_navigation/media.navigator";
import { spacing, tr } from "utils";
import { ass, ASS_KEY } from "utils/storage";

export const PagePasswordCenter = ({ password, item, setshowContents }: P) => {
  const Navigation = useNavigator();
  const { C } = useAppContext();
  const userProfile = useUserProfile();
  const refInput = React.useRef<TextInput>();
  const [passwordInput, setPassword] = React.useState<string>();

  // console.log("password", password);
  //#region [FRBS] page contents handler
  function getaccess() {
    // userProfile._isDeveloper() ? null : setshowContents(true); //This checks the role of the user if the user is a developer if yes it allows the user access pages with out password
    if (passwordInput != password) {
      setshowContents(false);
      refInput.current?.focus();
      Toasty.show(tr("Wrong Page Password"), { type: "danger" });
      // Navigation.goBack();
      // Navigation.goBack(); // this other goback takes the user back to the content page or baseshop page
    } else {
      userProfile.update({
        unlockedPages: firebase.firestore.FieldValue.arrayUnion(item._id),
        // baseShopId: item._id,
      });
      if (item.mediaItem.team == true || item.mediaItem.team == "true") {
        //this ensures that the user is joining a baseshop not a custom page
        let storingBSID = ass.save(ASS_KEY.BSIDINFO, item);

        // storingBSID &&
        // userProfile.update({
        //   baseShopId: item._id,
        // });

        Navigation.navigate<dResourcesBodyScreenParam>("BaseShops", {
          cpid: item?._id,
          title: item?.name || tr("Untitled"),
        });
        setshowContents(true);
      } else {
        setshowContents(true);
      }

      // Navigation.goBack();
      Toasty.show(tr("Welcome to") + " " + item.name, { type: "success" });
    }
  }
  //#endregion
  React.useEffect(function inputFocus() {
    refInput.current?.focus();
  }, []);
  const sx = useSx();
  return (
    <>
      <Kitten.Card
        style={sx({
          width: ["100%", "100%", "30%"],
          height: ["100%", "100%", "50%"],
          justifyContent: "center",
          borderColor: C.hazardYellow,
        })}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Avt
            size="giant"
            shape="rounded"
            source={{
              uri: item?.mediaItem?.logo && item?.mediaItem?.logo,
            }}
            name={item?.name && item?.name}
          />
          <ListHeader
            titleTx={item?.name || "Enter Password"}
            // accessoryLeft={() => <IconPrimr name={"lock"} color={C.text} />}
          />
        </View>

        {/* <ListHeader
          titleTx={"Input password"}
          accessoryLeft={() => <IconPrimr name={"lock"} color={C.text} />}
        /> */}
        <Inpuut
          ref={refInput}
          status={"warning"}
          style={{ marginTop: spacing(3) }}
          placeholder={tr("Enter Password")}
          value={passwordInput}
          onChangeText={(text) => {
            setPassword(text);
          }}
          doneText={tr("Enter")}
          // secureTextEntry={true}
          onSavePress={() => {
            getaccess();
          }}
        />
        {/* <Kitten.Divider_________ />
      <Buttoon
        style={{ marginTop: spacing(6) }}
        appearance={"ghost"}
        status={"warning"}
        onPress={() => {
          item?.mediaItem?.team == true || item?.mediaItem?.team == "true"
            ? (userProfile.update({ baseShopId: "" }),
              ass.remove(ASS_KEY.BSIDINFO),
              Navigation.navigate("BaseShops"))
            : Navigation.navigate("Pages");
        }}
      >
        {tr("Cancel")}
      </Buttoon> */}
        <Buttoon
          style={{ marginTop: spacing(6), alignSelf: "flex-start" }}
          size="small"
          appearance={"ghost"}
          status={"warning"}
          onPress={() => {
            if (
              item?.mediaItem?.team == true ||
              // @ts-ignore
              item?.mediaItem?.team == "true"
            ) {
              ass.remove(ASS_KEY.BSIDINFO);
              // Navigation.goBack();
              // Navigation.navigate("BaseShops"))
            }
            Navigation.goBack();
            // item?.mediaItem?.team == true || item?.mediaItem?.team == "true"
            //   ? ass.remove(ASS_KEY.BSIDINFO), //(userProfile.update({ baseShopId: "" }),
            //     Navigation.navigate("BaseShops"))
            //   : Navigation.navigate("Pages");
          }}
        >
          {tr("Cancel")}
        </Buttoon>
      </Kitten.Card>
    </>
  );
};

interface P {
  password?: string | number;
  item?: MediaPageSchema;
  setshowContents?(value): void;
  // pageType?: string;
}
