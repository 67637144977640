import { useFocusEffect } from "@react-navigation/native";
import { Txt } from "components";
import { Buttoon, Kitten, Rush, Toasty } from "components/atoms";
import { BSHomeItem } from "components/molecules";
import { useTwoColumns } from "components/templates";
import { useBreakpointIndex } from "dripsy";
import { MediaPageSchema, useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { usePagesCollection } from "engines/firebase/handler/pages-handler";
import { median } from "ramda";
import React from "react";
import { ScrollViewProps, View } from "react-native";
import Animated from "react-native-reanimated";
import { useNavigator } from "screens/_navigation";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { spacing, tr } from "utils";
/**
 * ### List of media resources
 *  - Including some quick actions as a dropdown menu item
 *  ----
 *  @version 21.09.08
 *  -  *Memoize it*
 *  @author  K
 *
 **/
export function BSHomeFeed({ scrollHandler = () => {} }: P) {
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();
  //#region [FRBS]
  const userProfile = useUserProfile();
  const pagesHandler = usePagesCollection();
  const [pages, setPages] = React.useState<MediaPageSchema[]>([]);
  const [editModeEnabled, setEditModeEnabled] = React.useState<boolean>(false);
  const [_pageDataItem, setPageDataItem] = React.useState([]);
  React.useEffect(
    function setupData() {
      pagesHandler.data && setPages(pagesHandler.data);
    },
    [pagesHandler.data]
  );
  const [listOffset, setListOffset] = React.useState(30);
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  const breakpoint = useBreakpointIndex();
  //#endregion
  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    setQuery(value);
    const newData = pagesHandler.data?.filter((member) => {
      // console.log("member: ", member.personali);
      return String(member.name)?.toLowerCase().includes(value.toLowerCase());
    });
    // console.log("valuee: ", newData);
    setPages([...newData]);
  }
  //#endregion

  return React.useMemo(() => {
    try {
      if (pagesHandler.error) {
        console.warn("error in MembersFeed: ", pagesHandler.error);
        return (
          <Txt.Indicator>{tr("Error getting media resources")}</Txt.Indicator>
        );
      }
      if (!pagesHandler.data)
        return <Rush.Bar indeterminate style={{ width: "100%" }} />;

      // let sortedPages = R.sortWith<MediaPageSchema>(
      //   [
      //     /** sorting `position` in `asc` */
      //     R.ascend(R.prop("position")),
      //   ],
      //   pages
      // ).filter(
      //   (page) =>
      //     (page.mediaItem?.team === true || page.mediaItem?.team == "true") && //* since the team field currently includes both boolean and string boolean
      //     page.mediaItem.visible === true
      // );

      let sortedPages = pages
        .filter(
          (page) =>
            (page.mediaItem?.team === true || page.mediaItem?.team == "true") && //* since the team field currently includes both boolean and string boolean
            page.mediaItem.visible === true
        )
        ?.sort((p1, p2) => p1.position - p2.position);

      //#region [functions] addEntryClick
      /**
       * ###
       *  - Function used in moving the data from one position to another
       *  ----
       *  @example
       *  addEntryClick({MediaPageSchema})
       *  returns and update the document
       *  ----
       *  @version 21.09.30
       *  -  *Brief changelog*
       *  @author  Nl
       *
       **/

      async function addEntryClick(item: MediaPageSchema) {
        setPageDataItem((oldArray) => [...oldArray, item]);

        if (_pageDataItem.length === 1) {
          let newPosition;

          for (let i = 0; i < sortedPages?.length; i++) {
            const _item = sortedPages[i];
            if (_item._id == item._id) {
              //get the previous value in the list
              const _previousItem = sortedPages[i - 1];

              let _previousPosition =
                i === 0
                  ? sortedPages[0]?.position - 1
                  : _previousItem?.position;
              if (_item.position === _previousPosition) {
                _previousPosition -= 0.001;

                //the updated values
                let datavalue = {
                  cpid: _item?._id,
                  position: _previousPosition,
                };
                pagesHandler._updateResourcePosition(datavalue);
              }
              //this gets the median of the value before and the value after
              newPosition = median([_item?.position, _previousPosition]);
              break;
            }
          }

          let datavalue = {
            cpid: _pageDataItem[0]?.id,
            position: newPosition,
          };

          //after getting the new position
          pagesHandler._updateResourcePosition(datavalue);
          setPageDataItem([]);
          Toasty.show("Page Item has been moved", { type: "success" });
        }
      }
      //#endregion

      function toggleMovable() {
        setEditModeEnabled(!editModeEnabled);
      }

      const ViewFeedHeader = () => {
        return (
          <View>
            {editModeEnabled ? (
              <Kitten.ViewH style={{ justifyContent: "space-between" }}>
                <Txt status="info" style={{ marginBottom: spacing(3) }}>
                  {tr("Select an item to place it on the top")}
                </Txt>

                <Buttoon
                  size="small"
                  status="info"
                  appearance="ghost"
                  onPress={() => {
                    setPageDataItem([]);
                    setEditModeEnabled(false);
                  }}
                >
                  {tr("Cancel")}
                </Buttoon>
              </Kitten.ViewH>
            ) : null}
            <Kitten.Input
              style={{
                backgroundColor: C.surface,
                paddingHorizontal: spacing(1),
                marginVertical: spacing(1),
              }}
              value={query}
              placeholder={tr("Search base shop") + " • " + sortedPages?.length}
              onChangeText={(value) => {
                filterHandler(value);
              }}
            />
          </View>
        );
      };

      return (
        <Animated.FlatList
          key={breakpoint >= 2 ? "tri-col" : "uno-col"}
          scrollEventThrottle={16}
          onScroll={scrollHandler}
          ListHeaderComponent={ViewFeedHeader()}
          contentContainerStyle={[column1ContainerStyle]}
          stickyHeaderIndices={[0]}
          data={sortedPages
            /** pagination */
            .slice(0, listOffset)}
          numColumns={breakpoint >= 2 ? 3 : 1}
          renderItem={({ item: page, index }) => {
            return (
              <BSHomeItem
                index={index}
                cpid={page?._id}
                addEntryClick={addEntryClick}
                editModeEnabled={editModeEnabled}
                onEditCallback={() => {
                  toggleMovable();
                }}
                onPress={() => {
                  Navigation.navigate<dPageContentsScreenParam>("BaseShops", {
                    cpid: page._id,
                    diableBaseShopPrompt: true,
                    title: page.name || tr("Untitled"),
                  });
                }}
              />
            );
          }}
          ListFooterComponent={() => (
            <View>
              <Buttoon
                appearance={"ghost"}
                progress
                onPress={(xong) => {
                  setTimeout(() => {
                    setListOffset(listOffset + 5);
                    xong && xong();
                  }, 600);
                }}
                disabled={listOffset >= sortedPages.length}
              >
                {listOffset < sortedPages.length ? "Load More" : ""}
              </Buttoon>
              <ViewCol1BottomSpace />
            </View>
          )}
          ListEmptyComponent={() => (
            <Txt.Indicator marginV>
              {pagesHandler.loading
                ? tr("Loading...")
                : tr("No Base Shop found")}
            </Txt.Indicator>
          )}
          keyExtractor={(item) => item._id}
        />
      );
    } catch (error) {
      throw Error(`::TWVkaWFIb21lRmVlZA==::${error}`);
    }
  }, [pages, listOffset, C, userProfile]);
}

interface P {
  scrollHandler: ScrollViewProps["onScroll"];
}
