import { ListHeader, Skeleton, sstyled, Toasty, Txt } from "components/atoms";
import { ScoreboardCard } from "components/molecules";
import { useAppContext, __ScoreboardData } from "engines";
import { FRBS_ROLE } from "engines/firebase";
import { useConfigDocument } from "engines/firebase/handler/config-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useScoreboardCollection } from "engines/firebase/handler/scoreboard-handler";
import React from "react";
import { Alert, Dimensions, FlatList, ScrollView, View } from "react-native";
import AutoScrolling from "react-native-auto-scrolling";
import { dSboardScrParams, useNavigator } from "screens/_navigation";
import { dColors, IS_WEB, scale, tr } from "utils";
import { DocumentCreatorWithTitle } from "../pages/PagesHomeFeed";

/**
 * ### Grid of Scoreboards, usually shown on HomeScreen
 *  - Show grid of ScoreboardItems, which displays top scorer of each scoreboard
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.06.03
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function ScoreboardsHomeGrid({ isHomeContentLoaded }) {
  const { teamId } = useAppContext();
  const userProfile = useUserProfile();
  const configVariables = useConfigDocument(`variables-${teamId}`);
  const { data: scoreboardsD, ...scoreboardsHandler } =
    useScoreboardCollection();
  // const configVariables = useConfigDocument(`variables-${teamId}`);
  const Navigation = useNavigator();
  // useFocusEffect(function layouting() {
  //   //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  // });

  const refFlatListContainer = React.useRef<any>(null);
  const refScrollView = React.useRef<ScrollView>(null);
  // const [didLayout, setDidLayout] = React.useState<boolean>(false);

  const [stopPanning, setStopPanning] = React.useState<boolean>(true);
  const [autoScrollWidth, setAutoScrollWidth] = React.useState<number>(
    Dimensions.get("window").width * 2
  );

  const refDidSetInitialPanning = React.useRef<boolean>(false);
  // React.useEffect(
  //   function setupPanning() {
  //     /**
  //      * Current disabled on web
  //      */
  //     if (refDidSetInitialPanning.current == false && !IS_WEB) {
  //       setTimeout(
  //         async () => {
  //           setStopPanning(
  //             configVariables.data?.disablePanningScoreboards ? true : false
  //           );
  //         },
  //         IS_WEB ? 3000 : 725
  //       );
  //       refDidSetInitialPanning.current = true;
  //     }
  //   },
  //   [
  //     configVariables.data,
  //     scoreboardsHandler,
  //     scoreboardsD,
  //     isHomeContentLoaded,
  //     userProfile.data,
  //   ]
  // );

  // const disablePanningScoreboards = stopPanning;
  const PanningView: AutoScrolling = stopPanning ? View : AutoScrolling;
  const autoScrollingProps = {
    // duration: scoreboardsD && scoreboardsD?.length < 3 ? 999999 : undefined,
    duration: undefined,
    endPaddingWidth: 0,
    style: {
      width: autoScrollWidth,
      // height: scale(180),
    },
  };

  try {
    if (scoreboardsHandler.error || configVariables.error)
      return (
        <Txt.Indicator>{tr("Error getting scoreboard list")}</Txt.Indicator>
      );
    if (!scoreboardsD || !configVariables.data)
      return <Skeleton.Screen.Scoreboards length={4} />;

    const data = configVariables?.data;

    let toolsDoc = data && Array.isArray(data?.tools) ? data?.tools : null;
    if (!toolsDoc?.some((value) => value === "Scoreboards")) return null;

    return (
      <A.Ctnr style={{ overflow: "hidden" }}>
        <ListHeader
          titleTx={tr("Scoreboards")}
          actions={[
            userProfile._isRolesContain([
              FRBS_ROLE.ADMIN,
              FRBS_ROLE.DEVELOPER,
            ]) && {
              label: tr("New Scoreboard"),
              icon: { name: "plus" },
              onPress: () => {
                Navigation.dialogPortal.open({
                  headerTitle: tr("Enter scoreboard title"),
                  render: (
                    <DocumentCreatorWithTitle
                      title={tr("Enter scoreboard title")}
                      onCreate={(pageName) => {
                        scoreboardsHandler.add({
                          ...__ScoreboardData({ teamId }),
                          title: pageName,
                        });
                      }}
                    />
                  ),
                });
              },
            },
            userProfile._isRolesContain([
              FRBS_ROLE.ADMIN,
              FRBS_ROLE.DEVELOPER,
            ]) && {
              label: tr("Reset all scores"),
              icon: { name: "num0" },
              onPress: async () => {
                async function resetAllDaScores() {
                  try {
                    await scoreboardsHandler.resetAllScores();
                    Toasty.show("All scores have been reset.");
                  } catch (err) {
                    console.log("[scoreboards] ", err);
                    Toasty.show("Something went wrong resetting the scores.", {
                      type: "danger",
                    });
                  }
                }

                if (IS_WEB) {
                  const result = await window.confirm(
                    [
                      "Are you sure you want to reset all the scores?",
                      "If not, please hit Cancel.",
                    ]
                      .filter(Boolean)
                      .join("\n")
                  );
                  if (result) {
                    await resetAllDaScores();
                  } else {
                  }
                } else {
                  Alert.alert(
                    "Are you sure you want to reset all the scores?",
                    "",
                    [
                      {
                        text: "Yes",
                        onPress: async () => {
                          await resetAllDaScores();
                        },
                      },
                      { text: "No", style: "cancel" },
                    ],
                    { cancelable: false }
                  );
                }
              },
            },
            {
              label: stopPanning ? tr("Resume panning") : tr("Stop panning"),
              icon: { name: stopPanning ? "play" : "pause" },
              onPress: () => {
                setStopPanning(!stopPanning);
                if (userProfile._isUserAdmin())
                  Toasty.show(
                    `Scoreboard panning ${
                      stopPanning ? "resumed" : "stopped"
                    }. This affects only you.`,
                    { type: "normal" }
                  );
              },
            },
            {
              label: "View all",
              icon: { name: "expand" },
              onPress: () => {
                Navigation.navigate("ViewAllScoreboards");
              },
            },
          ]}
        />
        <ScrollView
          ref={refScrollView}
          horizontal
          // style={{ flex: 1 }}
          style={{
            width: IS_WEB ? undefined : Dimensions.get("window").width,
            // flex: 1,
            minHeight: scale(180),
          }}
          overScrollMode="auto"
          showsHorizontalScrollIndicator={true}
        >
          <PanningView
            // delay={2}
            {...(stopPanning ? { flex: 1 } : autoScrollingProps)}
          >
            <View
              ref={refFlatListContainer}
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                // opacity: didLayout ? 1 : 0,
                // width: Dimensions.get("window").width * 2
              }}
              onLayout={(event) => {
                refFlatListContainer.current &&
                  refFlatListContainer.current.measure(
                    (x, y, width, height, px, py) => {
                      setAutoScrollWidth(width);
                    }
                  );
              }}
            >
              <FlatList
                style={{
                  marginRight: stopPanning ? 0 : 10,
                }}
                horizontal
                // numColumns={1}
                scrollEnabled={false}
                data={scoreboardsD}
                showsHorizontalScrollIndicator={IS_WEB}
                // ListHeaderComponent={() => <C_ActionSticky />}
                renderItem={({ item, index }) => (
                  <ScoreboardCard
                    onLayout={() => {
                      if (
                        scoreboardsD.length - 1 === index &&
                        refDidSetInitialPanning.current == false
                      ) {
                        refDidSetInitialPanning.current = true;
                        setTimeout(
                          () =>
                            setStopPanning(
                              configVariables.data?.disablePanningScoreboards
                                ? true
                                : false
                            ),
                          500
                        );
                      }
                    }}
                    index={index}
                    key={item.id}
                    scoreboardId={item.id}
                    // key={item._sbid} //* `item-${index}` for mock data
                    // scoreboardId={item._sbid}
                    onPress={() => {
                      Navigation.navigate<dSboardScrParams>("sboard-scr", {
                        sbid: item.id,
                        // sbid: item._sbid,
                      });
                    }}
                  />
                )}
                keyExtractor={(item) => item._sbid}
                showsVerticalScrollIndicator={false}
                ItemSeparatorComponent={() => (
                  <View style={{ width: 10, height: 10 }} />
                )}
              />
            </View>
          </PanningView>
        </ScrollView>
      </A.Ctnr>
    );
  } catch (error) {
    return null;
  }
}

const A = {
  Ctnr: sstyled(View)((p) => ({
    // flex: 1,
    // padding: spacing(3),
  })),
  Config: (C: dColors) => ({
    List: {
      itemWidth: 160,
      itemHeight: 140,
      separatorSize: 8,
      borderRadius: 10,
      stickyItemWidth: 50,
      stickyItemHeight: 50,
      stickyItemBackgroundColors: [
        C["color-primary-transparent-200"],
        C.primary,
      ],
    },
  }),
};
