import { PortalHost } from "@gorhom/portal";
import { createStackNavigator } from "@react-navigation/stack";
import { Rush, Txt } from "components/atoms";
import { useSx } from "dripsy";
// import { useAppContext } from "engines";
import * as React from "react";
import { Keyboard, View, ViewProps } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { dColors, spacing, tr } from "utils";
import { dOverlayParams } from ".";
import { useNavigator, useNavigatorPresetConfig } from "./navigation-utils";

export function OverlayNavigator(p: P) {
  const { route } = p;
  // const { C } = useAppContext();
  const loadingTime = route.params?.loadingTime || 0;
  const Navigation = useNavigator();
  const { overlayStackConfig } = useNavigatorPresetConfig();
  const inset = useSafeAreaInsets();
  const [ready, setReady] = React.useState<boolean>(false);
  React.useLayoutEffect(
    function layouting() {
      setTimeout(() => {
        setReady(true);
        Navigation.setOptions(overlayStackConfig.screenOptions);
      }, loadingTime);
    },
    [Navigation, ready]
  );
  return !ready ? (
    <View
      style={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Rush.Bar indeterminate style={{ width: 200, height: 20 }} />
      <Txt style={{ textAlign: "center" }}>{tr("Loading...")}</Txt>
    </View>
  ) : (
    <ScrollView
      keyboardShouldPersistTaps="always"
      onScrollBeginDrag={() => Keyboard.dismiss()}
      style={{
        width: "100%",
        height: "100%",
        borderWidth: 0,
        borderRadius: 0,
        paddingBottom: inset.bottom + spacing(4),
      }}
      contentContainerStyle={{ width: "100%" }}
    >
      <PortalHost name="overlay" key="overlay-portal" />
    </ScrollView>
  );
}

const OverlayStack = createStackNavigator();

export const OverlayWizardsStack = () => {
  const Navigation = useNavigator();
  const sx = useSx();
  //   const options: StackNavigationOptions ={
  // style
  //   }
  React.useLayoutEffect(
    function layouting() {
      Navigation.setOptions({
        cardStyle: sx({
          flex: 1,
          marginTop: 30,
          alignContent: "center",
          marginLeft: ["0%", "3%", "15%"],
          marginRight: ["0%", "3%", "15%"],
          //   backgroundColor: "steelblue",
        }),
      });
    },
    [Navigation]
  );
  return (
    <OverlayStack.Navigator initialRouteName="Wizards Home">
      <OverlayStack.Screen
        name="Wizards Home"
        component={OverlayHomeScreen}
        options={{ headerLeft: (props) => <Txt {...props}>Close</Txt> }}
      />
      <OverlayStack.Screen name="User" component={OverlayHomeScreen} />
    </OverlayStack.Navigator>
  );
};

function OverlayHomeScreen() {
  const Navigation = useNavigator();
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "steelblue",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Txt>Ovo</Txt>
      <Txt onPress={() => Navigation.navigate("User")}>Next</Txt>
    </View>
  );
}

/**
 * List of keys for screens used in OverlayScreen2
 * ---
 * - URL friendly
 */
export enum OVERLAY2 {
  "WIZARDS" = "WIZARDS",
  "SCOREBOARDS_HOME" = "SCOREBOARDS_HOME",
  "MEMBERS_HOME" = "MEMBERS_HOME",
  "COMMENTS_1P" = "COMMENTS",
  "POSST_CREATOR" = "POSST_CREATOR",
  "MAIL_TEMPLATE" = "MASS_MAIL",
  "MEMBER_INFO_1P" = "MEMBER_INFO",
  "MAIL_PREVIEW_2P" = "MAIL_PREVIEW",
  "MAIL_RESCHEDULE_PREVIEW_1P" = "MAIL_RESCHEDULE_PREVIEW",
  "MAIL_RESCHEDULE_EDIT_1P" = "MAIL_RESCHEDULE_EDIT",
  "SCOREBOARDS_CREATOR_1P" = "SCOREBOARDS_CREATOR",
  "TEAM_INFO" = "TEAM_INFO",
  "MAIL_SEND_FORM_2P" = "MAIL_SEND_FORM",
  "MAIL_SAVE_SEND_FORM_4P" = "MAIL_SAVE_SEND_FORM",
  "MAIL_TEMPLATE_SAVE_FORM_2P" = "MAIL_TEMPLATE_SAVE_FORM",
  "MAIL_SENT_LIST" = "MAIL_SENT_LIST",
  "CONTACT_GROUPS_HOME" = "CONTACT_GROUPS_HOME",
  "CONTACT_GROUP" = "CONTACT_GROUP",
  "SCOREBOARD_ADD_SCORER_1P" = "SCOREBOARD_ADD_SCORER",
  "MORE" = "MORE",
  "RESOURCES_ITEM_CREATOR_1P" = "RESOURCES_ITEM_CREATOR_1P",
}

const A = {
  ButtonX: (C: dColors) =>
    ({
      backgroundColor: C["color-danger-500"],
      shadowColor: C["color-danger-600"],
      alignSelf: "flex-end",
      shadowOffset: {
        width: 0,
        height: 10,
      },
      shadowOpacity: 0.63,
      shadowRadius: 13.16,

      elevation: 20,
    } as ViewProps),
};

/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: dOverlayParams };
}
