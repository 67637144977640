import firebase from "firebase"
import { Buttoon, Poppy, sstyled, Toasty } from "components/atoms";
import { POSST_CREATOR_FORM } from "components/organisms";
import {
  FPATH,
  PosstSchema,
  POSST_STATUS,
  useAppContext,
  usePagesCollection,
  __AnnoucementData,
  __MediaResourcesData
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { usePosstsCollection } from "engines/firebase/handler/posst-handler";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { View } from "react-native";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";
import { LINKTO } from "./LinkTo";
import { uploadMultipleMedias } from "./medias-upload";

export function PosstCreatorFooter(p: { editPosst?: PosstSchema }) {
  const { editPosst } = p;
  const { teamId } = useAppContext();
  const Navigation = useNavigator();
  // const { C } = useAppContext();

  const { control } = useFormContext();
  /**
   * Get posst form value in real time
   */
  const _rawForm = useWatch({ control });
  //#region [FRBS]
  const posstsHandler = usePosstsCollection();
  const pagesHandler = usePagesCollection();
  const userProfile = useUserProfile();
  //#endregion
  const [_goToPage, setGoToPage] = React.useState<string>(
    _rawForm[POSST_CREATOR_FORM.GOTOPAGE]
  );

  /**
   * A fairly complicated function, so read this before changing anything
   *  ---
   * - When onSubmit() runs, we get the **draft form** with default value + data from react-hook-forms
   * - Then there're few cases of this posst:
   *  * A media(s) post & scheduled post
   *  * A media(s) post & NOT scheduled post
   *  * NOT a media(s) post & scheduled post
   *  * NOT a media(s) post & NOT scheduled post
   * @param xong
   */
  async function onSubmit(xong) {
    let rawGoToPage = _rawForm[POSST_CREATOR_FORM.GOTOPAGE];
    //#region [section] handle gotoPageId
    /**
     * Get goToPageId value
     * ---
     * 3 scenarios:
     * -  newpage:[page_name] --> create a page doc, then assign its id to goToPageId
     * -  page:[page_id] --> assign page_id to goToPageId
     * -  url --> assign null to goToPageId, handle later on
     */
    const goToPageId = rawGoToPage.includes(LINKTO.NEWPAGE)
      ? await pagesHandler.add({
        ...__MediaResourcesData({
          teamId,
          pageName: rawGoToPage.split(":")[1],
        }),
        position: pagesHandler._largestPositionIndex() + 1,
      })
      : rawGoToPage.includes(LINKTO.PAGE)
        ? rawGoToPage.split(":")[1]
        : null;

    //#endregion
    if ("debug" === "nah") {
      alert(
        "[posst] posst creator form :\n" +
        JSON.stringify(_rawForm) +
        "\ngoToPageId: " +
        goToPageId
      ); //* for debug, don't remove
    } else {
      try {
        const draftForm: PosstSchema = {
          ...(editPosst || {}),
          ...(editPosst ? {} : __AnnoucementData({
            teamId,
            parentAppId: userProfile?.data?._parentAppId,
          })),
          ...(editPosst ? { createdAt: editPosst.createdAt } : {}),
          medias: editPosst?.medias || [],
          body: _rawForm[POSST_CREATOR_FORM.BODY],
          goToPage: goToPageId
            ? `${LINKTO.PAGE}:${goToPageId}`
            : _rawForm[POSST_CREATOR_FORM.GOTOPAGE], //* if goToPageId exists, save it as page:[pageId], else pass value from the form
          ...(editPosst ? {} : {
            author: {
              _id: userProfile.data?._id,
              name: userProfile.data?.personali?.displayName, //TODO suggestion: should remove this when moving to 1 codebase
              avatar: userProfile.data?.personali?.phoneNumber, //TODO suggestion: should remove this when moving to 1 codebase
            }
          }),
        };

        const CASE = {
          MEDIAS_POST: _rawForm[POSST_CREATOR_FORM.MEDIAS].length > 0,
          SCHEDULED_POST: _rawForm[POSST_CREATOR_FORM.SCHEDULE],
        };

        const finishAndClose = () => {
          //* Toasty at the end of the submission
          setTimeout(() => {
            xong && xong();
            if (CASE.SCHEDULED_POST) {
              Toasty.show(
                tr(
                  "Announcement will be posted at " +
                  _rawForm[POSST_CREATOR_FORM.SCHEDULE]
                ),
                {
                  duration: 10000,
                  type: "success",
                }
              );
            } else {
              Toasty.show(tr(editPosst ? "Announcement updated" : "Announcement will be posted shortly!"), {
                duration: 10000,
                type: "success",
              });
            }
            Navigation.navigate("Home");
          }, 1000);
        }

        if (CASE.MEDIAS_POST && CASE.SCHEDULED_POST) {
          if (editPosst) {
            Poppy("Edit disabled", "You cannot edit scheduled announcements just yet!")
            return;
          }
          uploadMultipleMedias(_rawForm[POSST_CREATOR_FORM.MEDIAS])
            .then((result) => {
              if (result) {
                let finalForm: PosstSchema = {
                  ...draftForm,
                  medias: result,
                  status: POSST_STATUS.SCHEDULED,
                  createdAt: editPosst ? editPosst.createdAt : _rawForm[POSST_CREATOR_FORM.SCHEDULE],
                  _parentAppId: userProfile.data?._parentAppId,
                  // scheduleAt: scheduleAt,
                };
                console.log("[posst] final Form: ", finalForm);
                posstsHandler.add(finalForm);
              }
            })
            .catch((e) => console.log("error upload multiple medias: ", e));
          finishAndClose()
        }

        if (CASE.MEDIAS_POST && !CASE.SCHEDULED_POST) {
          const uploadProceed = () => {
            uploadMultipleMedias(_rawForm[POSST_CREATOR_FORM.MEDIAS]).then(
              (result) => {
                if (result) {
                  let finalForm: PosstSchema = {
                    ...draftForm,
                    medias: result ? result : editPosst?.medias,
                    status: editPosst ? editPosst.status : POSST_STATUS.POSTED,
                    _parentAppId: editPosst ? editPosst._parentAppId : userProfile.data?._parentAppId,
                  };
                  console.log("[posst] final Form: ", finalForm);
                  if (editPosst) {
                    //@ts-ignore
                    finalForm.lastEdited = new Date()
                    firebase.firestore().doc(`${FPATH.POSSTS}/${editPosst?._pid}`).set(finalForm, { merge: true })
                  } else {
                    posstsHandler.add(finalForm);
                  }
                }
              }
            );
          }

          if (editPosst?.medias?.length > 0) {
            Poppy("Replacing original", "Original photo/video will be replaced. Is that ok?", { text: "No, cancel" }, {
              text: "Yes, proceed", onPress: () => {
                uploadProceed()
                finishAndClose()
              }
            })
          } else {
            uploadProceed()
            finishAndClose()
          }
        }

        if (!CASE.MEDIAS_POST && CASE.SCHEDULED_POST) {
          if (editPosst) {
            Poppy("Edit disabled", "Editing scheduled announcements is not yet supported.")
            return;
          }
          const finalForm: PosstSchema = {
            ...draftForm,
            status: POSST_STATUS.SCHEDULED,
            createdAt: _rawForm[POSST_CREATOR_FORM.SCHEDULE],
            _parentAppId: userProfile.data?._parentAppId,
            // scheduleAt: _scheduleAt,
          };
          console.log("[posst] final Form: ", finalForm);
          if (editPosst) {
            //@ts-ignore
            finalForm.lastEdited = new Date()
            await firebase.firestore().doc(`${FPATH.POSSTS}/${editPosst?._pid}`).set(finalForm, { merge: true })
          } else {
            posstsHandler.add(finalForm);
          }
          finishAndClose()
        }

        if (!CASE.MEDIAS_POST && !CASE.SCHEDULED_POST) {
          const finalForm: PosstSchema = {
            ...draftForm,
            status: POSST_STATUS.POSTED,
            _parentAppId: userProfile.data?._parentAppId,
          };
          console.log("[posst] final Form: ", finalForm);
          if (editPosst) {
            //@ts-ignore
            finalForm.lastEdited = new Date()
            await firebase.firestore().doc(`${FPATH.POSSTS}/${editPosst?._pid}`).set(finalForm, { merge: true })
          } else {
            posstsHandler.add(finalForm);
          }
          finishAndClose()
        }
      } catch (error) {
        console.log(error);
        Poppy("Failed to upload", error)
        xong && xong();
      }
    }
  }

  //#endregion

  return (
    <A.CtnrFooter>
      <Buttoon
        status="basic"
        icon={{ name: "bullhorn" }}
        // size="small"
        progress={true}
        disabled={!_rawForm[POSST_CREATOR_FORM.BODY]} //TODO add more conditions
        onPress={(xong) => onSubmit(xong)}
      >
        {editPosst ? "Update" : _rawForm[POSST_CREATOR_FORM.SCHEDULE]
          ? tr("Schedule Announcement")
          : tr("Post now")}
      </Buttoon>
      <Buttoon
        style={{ marginVertical: spacing(3) }}
        icon={{ name: "arrow_right", right: true }}
        appearance="ghost"
        status="control"
        size="small"
        onPress={() => {
          Navigation.navigate("scheduled-possts");
        }}
      >
        {tr("View scheduled announcements")}
      </Buttoon>
    </A.CtnrFooter>
  );
}

const A = {
  CtnrFooter: sstyled(View)({
    paddingTop: spacing(3),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
  }),
};
