import * as MailComposer from 'expo-mail-composer';
import { Alert, Platform } from 'react-native';
import * as EXCalendar from 'expo-calendar';
import { Toasty } from 'components/atoms';
import { fn } from 'engines';

export async function generateEmailInvite(evtId: string, emailStrings: string[]) {
    for (let e in emailStrings) {
        try {
            let permResult = await EXCalendar.getCalendarPermissionsAsync();
            if (!permResult.granted) {
                permResult = await EXCalendar.requestCalendarPermissionsAsync();
            }
            if (Platform.OS === 'ios') {
                const permResultIos = await EXCalendar.requestRemindersPermissionsAsync();
                if (!permResultIos.granted) {
                    Alert.alert('Calendar & Reminder permissions must be enabled in your device settings.');
                    return;
                }
            }
            if (!permResult.granted) {
                Alert.alert('Calendar permissions must be enabled in your device settings.');
                return;
            }

            // Create the .ics file
            const event = await EXCalendar.getEventAsync(evtId);
            const _startDate: Date = (event.startDate as Date)?.getFullYear ? (event.startDate as Date) : new Date(event.startDate)
            // const _endDate: Date = (event.endDate as Date)?.getFullYear ? (event.endDate as Date) : new Date(event.endDate)
            const inviteUrl = fn.calendar.generateEventUrl(event)
            const emailSubject = `Invitation - ${event.title}`;
            const emailBody = `<html><body>You've been invited to a meeting ${_startDate.toLocaleDateString()} at ${fn.calendar.timeToAMPMString(_startDate)} (${event.timeZone})
            <br><br><a href="${inviteUrl}">Click here to add this event to your calendar</a>
            <br><p>${event.notes || event.description || ""}</p><br><br><p>${event.location || event.url || ""}</p></body></html>`;
            // const emailUrl = `mailto:${emailStrings[e]}?subject=${emailSubject}&body=${encodeURIComponent(emailBody)}}&itHtml=true`;
            // console.log(`\n${emailUrl}\n`)
            MailComposer.composeAsync({ subject: emailSubject, body: emailBody, isHtml: true, recipients: emailStrings })
            // Linking.openURL(emailUrl).catch(err => {
            //     console.error(err)
            //     Alert.alert(`Failed to send invite.`, err)
            // })

        } catch (err) {
            console.error(err);
            Toasty.show(`${err}`, { type: 'danger' });
            continue;
        }
    }
}

// function createICS(event: EXCalendar.Event): string {
//     const { title, location, notes } = event;

//     const startDate: Date = typeof event.startDate === 'string' ? new Date(event.startDate) : event.startDate;
//     const endDate: Date = typeof event.endDate === 'string' ? new Date(event.endDate) : event.endDate;

//     const formattedStartDate = startDate.toISOString().replace(/-|:|\.\d+/g, '');
//     const formattedEndDate = endDate.toISOString().replace(/-|:|\.\d+/g, '');

//     const icsContent = `BEGIN:VCALENDAR
//   VERSION:2.0
//   PRODID:-//Your Company//NONSGML Event//EN
//   BEGIN:VEVENT
//   UID:${formattedStartDate}-${formattedEndDate}@yourcompany.com
//   DTSTAMP:${formattedStartDate}Z
//   DTSTART:${formattedStartDate}Z
//   DTEND:${formattedEndDate}Z
//   SUMMARY:${title}
//   LOCATION:${location}
//   DESCRIPTION:${notes}
//   END:VEVENT
//   END:VCALENDAR`;

//     return icsContent;
// }
// function generateCalendarURL(event) {
//     const calendarURL = `data:text/calendar;charset=utf-8,${encodeURIComponent(event)}`;
//     return calendarURL;
// }