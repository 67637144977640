import { Avt, Kitten, Txt } from "components";
import { dAttendance, dCalendarEvent, fn, useAppContext } from "engines";
import React from "react";
import { View } from "react-native";
import { spacing } from "utils";
import { useAttendanceForMeeting } from "../../engines/firebase/handler/attendance-handler";

export function AttendnaceOverview(p: { event: dCalendarEvent }) {
  const { event } = p;
  const { teamId } = useAppContext();
  const attendanceHandler = useAttendanceForMeeting(teamId, event.eventId);

  React.useEffect(
    function setupPackedViews() {
      if (!attendanceHandler.data) return;
    },
    [attendanceHandler.data]
  );

  function UserAttendanceItem(p: { attendance: dAttendance }) {
    const { attendance } = p;
    // const memberHandler = useMemberDocument(userViews[0]?.uid);

    // const memberName =
    //   memberHandler.data?.personali?.displayName ||
    //   memberHandler.data?.personali?.email ||
    //   "Unknown Viewer";

    const joinTimePretty = fn?.calendar
      .timeToAMPMString(attendance.attendedAt.toDate())
      .replace(":NaN", ":00");

    return (
      <Kitten.ListItemv2
        accessoryLeft={() => (
          <Avt
            style={{ marginRight: spacing(2) }}
            name={attendance.attendee.displayName}
            source={{ uri: attendance.attendee.photoURL }}
          />
        )}
        title={`${attendance.attendee.displayName}`}
        description={`Joined at ${joinTimePretty}`}
        accessoryRight={() => <View style={{ alignItems: "flex-end" }}><Txt.P1 style={{fontWeight: "bold"}}>{`Guests (${attendance?.guests?.length || 0})`}</Txt.P1><Txt.P2>{attendance.guests.map(_g => _g.name).join("\n")}</Txt.P2></View>}
      />
    );
  }

  function getTotalGuestCount() {
    if (!attendanceHandler.data) return 0
    let total = 0
    for (let a in attendanceHandler.data) {
      total += (attendanceHandler.data[a]?.guests || [])?.length
    }
    return total
  }

  const guestCount = getTotalGuestCount()

  return attendanceHandler.data ? (
    <>
      <Txt.H6>{event.title || ""}</Txt.H6>
      {/* <Txt.P1>{JSON.stringify(attendanceHandler.data)}</Txt.P1> */}
      <Txt.H6
        style={{
          marginTop: spacing(3),
          fontWeight: "300",
          // textAlign: "center",
        }}
      >{`${attendanceHandler.data.length + guestCount} attendees`}</Txt.H6>
      {guestCount > 0 ? <Txt.P1 style={{ marginBottom: spacing(3) }}>{`${attendanceHandler.data?.length} members, ${guestCount} guests`}</Txt.P1> : null}
      <Kitten.List
        data={attendanceHandler.data}
        renderItem={(data) => <UserAttendanceItem attendance={data.item} />}
      />
    </>
  ) : (
    <Txt.H6>Loading...</Txt.H6>
  );
}
