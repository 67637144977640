import React from "react";
import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  SectionList,
  StatusBar,
} from "react-native";
import * as R from "ramda";
import { Buttoon } from "../../atoms/generals/buttoon/Buttoon";
import { Kitten, Txt, sstyled } from "components/atoms";
import { WidgetUserStats } from "../shortcuts/WidgetUserStats";
import { IconPrimr } from "../../../assets/icons/primr-icon";
import { spacing, scale, tr } from "utils";
import { useAppContext } from "engines";
import { ExlinkEditor2 } from "./exlink-editor-2";
import { ResourcelinkEditor } from "./resource-link-editor";
import { WidgetContacts } from "../shortcuts/WidgetContacts";
import { DefaultWidgetEditor } from "./default-widget-editor";

export const WidgetEditor = ({ onValueCallback }): React.ReactElement => {
  const [selectedIndex, setSelectedIndex] = React.useState<
    Kitten.IndexPath | Kitten.IndexPath[]
  >(null);
  function onCancel() {
    setSelectedIndex(null);
  }

  function onBackPress() {
    return onValueCallback({
      name: "",
      link: "",
      contactEmail: "",
      contactPhone: "",
      logo: "",
      position: 0,
      isStarred: true,
    });
  }

  const DATA = [
    {
      title: "Shortcuts",
      data: [
        {
          title: "External link",
          item: <ExlinkEditor2 onBack={onCancel} mode={"from-home"} />,
          link: "https://",
        },
        {
          title: "Link to Resource",
          item: <ResourcelinkEditor onBack={onCancel} mode={"from-home"} />,
          link: "page://",
        },
      ],
    },
    {
      title: "Contact Manager",
      data: [
        {
          title: "Follow-up Contacts",
          item: (
            <DefaultWidgetEditor
              widget={<WidgetContacts />}
              link={"inapp://Follow-up Contacts"}
              mode={"from-home"}
              onBack={onCancel}
            />
          ),
        },
      ],
    },
    {
      title: "Your Stats",
      data: [
        {
          title: "Daily Report Stats",
          item: (
            <DefaultWidgetEditor
              widget={<WidgetUserStats />}
              link={"inapp://Your Stats"}
              mode={"from-home"}
              onBack={onCancel}
            />
          ),
        },
      ],
    },
    // {
    //   title: "Annoucements",
    //   data: [
    //     {
    //       title: "Create Announcement Button",
    //       item: <Buttoon>Create Announcement</Buttoon>,
    //     },
    //   ],
    // },
  ];

  const selectedWidgetData = () => {
    return DATA.filter(
      (section) => section.title === DATA[selectedIndex?.section]?.title
    )[0]?.data[selectedIndex?.row];
  };

  return (
    <View>
      <Txt.Debug>Data: {JSON.stringify(selectedIndex)}</Txt.Debug>
      <Txt.Helper marginV>{tr("Create widget")}</Txt.Helper>
      <Kitten.Select
        placeholder={tr("Select Widget")}
        selectedIndex={selectedIndex}
        onSelect={(index) => setSelectedIndex(index)}
        value={selectedWidgetData()?.title}
      >
        {DATA.map((section, index) => (
          <Kitten.SelectGroup
            title={section?.title}
            key={section?.title + index}
          >
            {section.data?.map((item, index) => (
              <Kitten.SelectItem
                title={item?.title}
                key={item?.title + index}
              />
            ))}
          </Kitten.SelectGroup>
        ))}
      </Kitten.Select>
      {selectedWidgetData()?.item}
    </View>
  );
};
