import { Buttoon } from "components";
import { dButtoon, Kitten, Txt } from "components/atoms";
import * as React from "react";
import { View } from "react-native";
import Swipeable, {
  SwipeableProperties
} from "react-native-gesture-handler/Swipeable";

/**
 * ### Swipeable Item for quick actions
 *  - Swipeable on mobile
 *  - On web, we can have a button to `refItemSwipeable.current?.openRight()`
 *  ----
 *  @example 
 *  <ItemSwipeable
      ref={refItemSwipeable}
      rightActions={[
        {
          icon: { name: "trash" },
          status: "danger",
          onPress: onDelete,
        },
        {
          label: tr("Close"),
          size: "tiny",
          appearance: "ghost",
          onPress: () => refItemSwipeable.current?.close(),
        },
      ]}
    >
      <Txt>This is content</Txt>
    </ItemSwipeable>
 *  ----
 *  @version 21.09.07
 *  -  *Document*
 *  @author  @nguyenkhooi
 *  
 **/
export const ItemSwipeable = React.forwardRef<Swipeable, dItemSwipeable>(
  (props, ref) => {
    const MediaItemActions = () => (
      <View>
        <Kitten.ViewH style={{ flexGrow: 1, alignItems: null }}>
          {props.rightActions?.map((action, index) => (
            <Buttoon
              key={index}
              {...action}
              style={{ borderRadius: 0, ...action.style }}
            >
              {action.label}
            </Buttoon>
          ))}
        </Kitten.ViewH>
        <Txt.Helper></Txt.Helper>
      </View>
    );
    return (
      <Swipeable ref={ref} {...props} renderRightActions={MediaItemActions} />
    );
  }
);

interface dItemSwipeable extends React.Component<SwipeableProperties> {
  rightActions: dActions[];
}

interface dActions extends dButtoon {
  label?: string;
}
