import { Buttoon, Toasty } from "components";
import { Backend, GOOGLE_AUTH } from "engines";
import React from "react";
import { View } from "react-native";
import { tr } from "utils";
// import { auth } from "../../firebase";

/**
 * ### Sign in with google
 *  - Basically a button, but the file includes all the functions+error handling blah blah
 *  ----
 *  @version 21.03.15
 *  -  *ATOMize*
 *  @author  K
 *
 **/
export function GoogleSignin(props) {
  return (
    <View
      style={{
        // flex: 1,
        marginTop: 10,
        // width: Platform.select({ web: undefined, ios: "80%", android: "80%" }),
      }}
    >
      <Buttoon
        progress
        onPress={(xong) => {
          Backend.authHandler.connectWithGoogleAuth().then((r) => {
            if (r.type == GOOGLE_AUTH.SUCCESS) {
              Toasty.show(
                tr("Welcome back, ") + r.payload.frbsUser.user.email,
                {
                  type: "success",
                }
              );
            }
          });
          xong && xong();
        }}
        icon={{ name: "google" }}
        status="info"
      >
        {tr("Sign in with Google")}
      </Buttoon>
    </View>
  );
}
