import { Txt } from "components";
import { NotificationFeed } from "components/molecules/notifications/NotificationFeed";
import { useTwoColumns } from "components/templates";
import { useAppContext } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { ScrollView, View } from "react-native";
import { useNavigator } from "screens";
import { spacing } from "utils";

export function NotificationHomeCenter() {
  const refRenderCount = React.useRef(0);
  const { C, theme, setTheme, setTeamId, frbsUser } = useAppContext();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();
  const { column1ContainerStyle } = useTwoColumns();
  return (
    <ScrollView contentContainerStyle={[column1ContainerStyle]}>
      <View style={{ margin: spacing(2) }}>
        <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>

        <NotificationFeed />
      </View>
    </ScrollView>
  );
}
