import { Radio } from "@ui-kitten/components";
import {
  CollaborationCopyCenter,
  CopyCollaborationContentMenu,
  sstyled
} from "components";
import { CollaborationSearchFeed } from "components/organisms/collaboration/CollaborationSearchFeed";
import { TwoColumns, useTwoColumns } from "components/templates";
import { FRBS_ROLE, MediaPageItemSchema } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useCollabContext } from "engines/providers/collaboration-provider";
import React, { useState } from "react";
import { View } from "react-native";
import { scale, spacing, tr } from "utils";
import { useNavigator } from "../_navigation/navigation-utils";

export interface dCollaboration {
  srcPageId?: string;
}

/**
 * ### Collaboration Multiverse Feature for the Web App.
 *  - Collaboration Multiverse feature that will allow cross-hierarchy content(a.k.a media resources) sharing across the web.
 *  ----
 *  @example
 *  <CollaborationMultiverse {...props}>
 *  ----
 *  @version 21.11.05
 *  -  *Creating the Collaboration Multiverse Feature*
 *  @author  Saurabh_M
 *
 **/
export function CollaborationMultiverse(props: P) {
  const srcPageId = props?.route?.params?.srcPageId;

  const {
    setIsCollabModeOn,
    isCollabModeOn,
    setCopyToPageId,
    copyToPageId,
    pageContentItems,
  } = useCollabContext();
  const userProfile = useUserProfile();
  const [filterTypes, setFilterTypes] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FILTERTYPES | string>(
    FILTERTYPES.ALL
  );
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();

  React.useEffect(() => {
    setIsCollabModeOn(true);
    if (srcPageId) {
      setCopyToPageId(srcPageId);
    }
    // return () => setIsCollabModeOn(false);
  }, []);

  //* On smaller screen, it'll close out the filter screen
  // React.useEffect(function triggering() {
  //   !breakpointForTwoColumns && Navigation.canGoBack() && Navigation.goBack();
  // }, []);

  return React.useMemo(() => {
    try {
      return (
        <TwoColumns
          column1Content={(scrollHandler) => (
            <CollaborationSearchFeed
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              setFilterTypes={setFilterTypes}
            />
          )}
          bottomInset
          column2Content={[
            {
              hiddenIf: [filterTypes?.length == 0],
              headerTitle: tr("Filter By"),
              icon: "filtermenu",
              showTitle: true,
              render: () => (
                //FIXME Check the file PageBodyScreen "Edit Contents" shouldedit section component
                <>
                  <A.ViewBuilder>
                    {filterTypes
                      .sort((a, b) => a.localeCompare(b))
                      .map((f) => (
                        <A.RadioContentType
                          checked={f === selectedFilter}
                          key={f}
                          onChange={() => {
                            setSelectedFilter(f);
                            !!!srcPageId && Navigation.goBack();
                            !breakpointForTwoColumns &&
                              Navigation.canGoBack() &&
                              Navigation.goBack();
                          }}
                        >
                          {f === "BUTTON"
                            ? "Website/Page"
                            : f === "FOLDER"
                            ? "Link to page"
                            : tr(f)}
                        </A.RadioContentType>
                      ))}
                  </A.ViewBuilder>
                </>
              ),
            },
            {
              hiddenIf: [filterTypes?.length === 0],
              headerTitle: tr("Select Content"),
              showTitle: true,
              render: () => <CopyCollaborationContentMenu cpid={"abc"} />,
              icon: "check",
            },
            {
              headerTitle: tr("Copy page contents"),
              showTitle: true,
              render: () => <CollaborationCopyCenter cpid={"abc"} />,
              icon: "copy",
              hiddenIf: [
                !isCollabModeOn,
                pageContentItems?.length == 0,
                userProfile._isRolesContain[
                  (FRBS_ROLE.ADMIN, `BS:${copyToPageId}`)
                ],
              ],
            },
          ]}
        />
      );
    } catch (error) {
      throw Error(`::Q29sbGFib3JhdGlvbi1NdWx0aXZlcnNlLnRzeA==::${error}`);
    }
  }, [filterTypes, pageContentItems, selectedFilter]);
}

export interface dSearchResultCollab extends MediaPageItemSchema {
  pageId: string;
  mediaType: string;
  _teamId: string;
  hierarchyLogo: string;
}

export enum FILTERTYPES {
  "ALL" = "ALL",
  "VIDEO" = "VIDEO",
  "AUDIO" = "AUDIO",
  "WEBSITE" = "WEBSITE",
  "FOLDER" = "FOLDER",
  "IMAGE" = "IMAGE",
  "TEXT" = "TEXT2",
}
const A = {
  ViewBuilder: sstyled(View)((p) => ({
    width: "100%",
    marginBottom: 10,
  })),
  RadioContentType: sstyled(Radio)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(1),
    borderBottomWidth: 0,
    borderColor: "primary",
  }),
};

interface P {
  route?: { params?: dCollaboration }; //* TODO remember to create dResourcesBodyScreenParam
}
