import { useAsyncStorage } from "@react-native-async-storage/async-storage";
import { IconPrimr, ICON_NAME } from "assets";
import { Avt, Buttoon, Kitten, Poppy, Skeleton, sstyled, Txt } from "components";
import {
  dCalendarEvent,
  fn,
  FRBS_ROLE,
  useActivityCollection,
  useAppContext,
  useCalendar,
  useConfigDocument,
  UserSchema,
  useTraineesActions,
  useUserActions,
  useUserProfile,
} from "engines";
import { useFollowUpContacts } from "engines/firebase/handler/contact-handler";
import React from "react";
import { Dimensions, ImageBackground, Pressable, View } from "react-native";
import { CalendarScreen, useNavigator } from "screens";
import { ActionsFeed } from "screens/team-mates/TeamMatesSceen";
import { scale, spacing, THEME, themeDark, tr } from "utils";
import { TodoCenter } from "./TodoCenter";

interface dProfileSummarySection {
  userD: UserSchema
}

/**
 * ### Menu in UserStatsFeed about Profile
 *  ----
 *  @version 23.10.04
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function ProfileSummarySection(props: dProfileSummarySection) {
  const { userD } = props;
  const { C, frbsUser, teamId } = useAppContext();
  const Navigation = useNavigator();
  // const userProfile = useUserProfile();
  // const taskHandler = useUserTasks(frbsUser?.uid);

  // const trainees = useMembersCollection({
  //   where: ["growth.listBuilder.shareTo", "array-contains", frbsUser?.uid],
  // });
  const [isTrainer, setIsTrainer] = React.useState<boolean | string>(userD?.roles?.includes(FRBS_ROLE.TRAINER));
  // red dot for trainer: new activity from trainees since last tap
  // red dot for non trainer: new comments or likes on your activity (or no trainer selected?)
  const actionHandler = isTrainer ? useTraineesActions(frbsUser?.uid) : useUserActions(frbsUser?.uid)
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  const [lastTapOnTrainees, setLastTapOnTrainees] = React.useState<Date>(startOfToday);
  const showRedDotForTrainees = userD?.roles?.includes((FRBS_ROLE.TRAINER)) || isTrainer === true ?
    // for the trainer
    actionHandler.data?.find(a => a.occurredAt?.toDate() > lastTapOnTrainees)
    // for the trainee
    : actionHandler.data?.find(a => a.comments?.find(com => com.createdAt?.toDate() > lastTapOnTrainees) || a.likes?.find(lik => lik.likedAt?.toDate() > lastTapOnTrainees))

  const calendarHandler = useCalendar();
  const [todaysEvents, setTodaysEvents] =
    React.useState<dCalendarEvent[]>(null);

  // const contactGroupHandler = useContactGroupsCollection(frbsUser?.uid)
  // const contactsHandler = useContactsCollectionWithAnyGroups(frbsUser?.uid, contactGroupHandler.data?.map(g => g._id || "unknown") || []);
  const followUpContactsHandler = useFollowUpContacts(frbsUser?.uid)

  const lastTapOnTraineesHandler = useAsyncStorage("lastTapOnTrainees")
  React.useEffect(function getLastTapOnTrainees() {
    lastTapOnTraineesHandler.getItem((err, result) => {
      if (err || !result) {
        console.error(err || "Failed to get lastTapOnTrainees from async storage, it may not exist yet?")
      } else {
        const dateStr = JSON.parse(result)?.value
        dateStr && setLastTapOnTrainees(new Date(dateStr))
      }
    })
  }, []);

  React.useEffect(
    function initEvents() {
      if (!calendarHandler.data) return;

      if (
        userD?.personalCalendars &&
        userD.personalCalendars?.length > 0
      ) {
        calendarHandler
          .getTodaysPersonalCalendarEvents(userD?.personalCalendars)
          .then((pes) =>
            setTodaysEvents(
              calendarHandler.combineAndSortEvents(
                calendarHandler.getTodaysEvents(),
                pes
              )
            )
          )
          .catch((err) => {
            console.error(err);
            setTodaysEvents(calendarHandler.getTodaysEvents() || []);
          });
      } else {
        setTodaysEvents(calendarHandler.getTodaysEvents());
      }
    },
    [calendarHandler.data, userD]
  );

  const itemStyle = {
    alignItems: "center",
    justifyContent: "center",
    // width: 80,
    // height: 77,
    width: 72,
    height: 72,
    // paddingBottom: spacing(3),
    borderRadius: 10,
    opacity: 0.8,
    backgroundColor: C.surface
  };
  const itemTitleStyle = {
    textAlign: "center",
    marginTop: 1
  }
  // const icon_name: ICON_NAME = "arrow_right_circle_filled"
  const icon_name: ICON_NAME = "dot"
  const iconStyle = { position: "absolute", bottom: spacing(1) }
  // const iconSize = 9.5
  const iconSize = 19.7

  const boxLevel = "1"

  const RedDotAlert = () => <IconPrimr name="dot" color={C.errorRed} size={4.5} containerStyle={{ position: "absolute", top: spacing(3) * 1.62, right: spacing(3) * 1.62 }} />

  return (
    <ImageBackground
      imageStyle={{ opacity: 0.85 }}
      source={{ uri: fn.media.smallerSizeFromURL(userD?.personali?.photoURL, Dimensions.get("window").width * 3) }}
      blurRadius={18}
      style={{
        backgroundColor: "orange",
        marginBottom: spacing(3), borderRadius: 10, marginHorizontal: spacing(2), paddingTop: spacing(3), paddingHorizontal: spacing(2),
        overflow: "hidden",
      }}
    >
      {/* <Kitten.Layout level="1" style={{ marginBottom: spacing(3), borderRadius: 10, marginHorizontal: spacing(2), paddingTop: spacing(3), paddingHorizontal: spacing(2) }}> */}
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            paddingHorizontal: spacing(3),
          }}
        >
          <UserInfoCenter showInfo={true} />
          <Kitten.ViewH style={{ position: "absolute", top: 0, left: 0 }}>
            <IconPrimr
              size={scale(21)}
              name="cog"
              preset="circular"
              color={C.text01}
              onPress={() => Navigation.navigate("profile-settings")}
            />
            {userD.roles?.includes(FRBS_ROLE.ADMIN) || userD.roles?.includes(FRBS_ROLE.DEVELOPER) ? (
              <IconPrimr
                size={scale(20)}
                name="admin"
                preset="circular"
                solid={true}
                color={C.adminGold}
                onPress={() => Navigation.navigate("Admin Portal")}
              />
            ) : null}
          </Kitten.ViewH>

          <Kitten.ViewH style={{ position: "absolute", top: 0, right: 0 }}>
            <Buttoon status="control" appearance="filled" size="tiny"
              style={{ marginRight: spacing(1), backgroundColor: "#F29E39", borderColor: "#F29E39" }}
              textStyle={{ color: "white" }}
              forceTheme={THEME.DARK}
              onPress={() => {
                Poppy("🔥 🔥 🔥", `This represents your app activity over a 3-day period.`, { text: "Ok", onPress: () => { } }, {
                  text: "View Activity",
                  onPress: () => {
                    Navigation.overlayPortal.open({
                      headerTitle: "Your Activity",
                      render: <ActionsFeed feedType="user" uid={frbsUser?.uid} />
                    })
                  }
                })
              }}>
              {`${userD?.stats?.rollingActionPoints || 0} 🔥`}
            </Buttoon>
            <Buttoon status="control" size="tiny"
              forceTheme={THEME.DARK}
              onPress={() => {
                Navigation.overlayPortal.open({
                  headerTitle: "Your Activity",
                  render: <ActionsFeed feedType="user" />
                })
              }}>
              {`${userD?.stats?.totalActionPoints || 0} points`}
            </Buttoon>
          </Kitten.ViewH>

        </View>
      </View>
      <Kitten.ViewH
        style={{
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          paddingHorizontal: spacing(4),
          paddingVertical: spacing(3),
          // marginTop: spacing(2),
          borderRadius: 10,
          marginHorizontal: spacing(2)
        }}
      >
        <Pressable
          key="contact-manager"
          onPress={() => {
            Navigation.navigate("Contact Manager");
            // Navigation.navigate<dContactsScrParams>("Contact Group Screen", {});
            //   Navigation.navigate<dContactsScrParams>("Contact Group Screen", {
            //     id: "follow-up",
            //     followUpContacts: followUpContacts,
            //   });
          }}
        >
          <Kitten.Layout level={boxLevel} style={itemStyle}>
            {/* {contactsHandler.data ? (
              <Txt.H4>{contactsHandler.data?.length}</Txt.H4>
            ) : (
              <Kitten.Spinner />
            )} */}
            <IconPrimr name="contacts" color={C.text} size={iconSize} />
            <Txt.P2 style={itemTitleStyle}>{tr("Contacts")}</Txt.P2>
            {followUpContactsHandler.data?.length > 0 ? <RedDotAlert /> : null}
            {/* <Txt.P2 style={{ fontSize: 9, position: "absolute", bottom: spacing(3) }}>{"View all"}</Txt.P2> */}
            {/* <IconPrimr containerStyle={iconStyle} size={iconSize} name={icon_name} color={C.text} /> */}
          </Kitten.Layout>
        </Pressable>
        <Pressable key="teammates"
          onPress={() => {
            Navigation.navigate("Teammates", { initialUser: userD })
            lastTapOnTraineesHandler.setItem(JSON.stringify({ value: new Date() }))

          }}>
          <Kitten.Layout level={boxLevel} style={itemStyle}>
            {/* {trainees.data ? (
              <Txt.H4>
                {trainees.data?.length ||
                  userProfile.data?.growth?.listBuilder?.shareTo?.length ||
                  0}
              </Txt.H4>
            ) : (
              <Kitten.Spinner />
            )} */}
            <IconPrimr name="people" color={C.text} size={iconSize * 1.18} />
            <Txt.P2 style={itemTitleStyle}>{userD.roles?.includes(FRBS_ROLE.TRAINER) ? tr("Trainees") : tr("Trainers")}</Txt.P2>
            {showRedDotForTrainees ? <RedDotAlert /> : null}
            {/* <IconPrimr containerStyle={iconStyle} size={iconSize} name={icon_name} color={C.text} /> */}
          </Kitten.Layout>
        </Pressable>
        <Pressable key="tasks"
          onPress={() => {
            Navigation.overlayPortal.open({
              headerTitle: tr("Tasks"),
              render: <TodoCenter />,
            });
          }}
        >
          <Kitten.Layout level={boxLevel} style={itemStyle}>
            {/* {taskHandler.data ? (
              <Txt.H4>{taskHandler.getIncompleteTasks().length}</Txt.H4>
            ) : (
              <Kitten.Spinner />
            )} */}
            <IconPrimr name="check_circle" color={C.text} size={iconSize} />
            <Txt.P2 style={itemTitleStyle}>{tr("Tasks")}</Txt.P2>
            {/* <IconPrimr containerStyle={iconStyle} size={iconSize} name={icon_name} color={C.text} /> */}
          </Kitten.Layout>
        </Pressable>

        <Pressable
          key="calendar"
          onPress={() =>
            Navigation.overlayPortal.open({
              render: <CalendarScreen />,
              headerTitle: "Calendar",
            })
          }
        >
          <Kitten.Layout level={boxLevel} style={itemStyle}>
            {/* {todaysEvents ? (
              <Txt.H4>{todaysEvents.length}</Txt.H4>
            ) : (
              <Kitten.Spinner />
            )} */}
            <IconPrimr name="calendar" color={C.text} size={iconSize} />
            <Txt.P2 style={itemTitleStyle}>
              {tr("Meetings")}
            </Txt.P2>
            {todaysEvents?.length > 0 ? <RedDotAlert /> : null}
            {/* <IconPrimr containerStyle={iconStyle} size={iconSize} name={icon_name} color={C.text} /> */}
          </Kitten.Layout>
        </Pressable>
      </Kitten.ViewH>
      {/* </Kitten.Layout> */}
    </ImageBackground>
  );
}

export function UserInfoCenter(props: { showInfo: boolean }) {
  const { showInfo = true } = props;
  const { C, frbsUser, teamId } = useAppContext();
  const { data: userD, ...userProfile } = useUserProfile();
  const configHandler = useConfigDocument(`variables-${teamId}`);
  const activityHandler = useActivityCollection(frbsUser?.uid);
  const [highestStreak, setHighestStreak] = React.useState<number>(0);

  React.useEffect(
    function getHighestStreak() {
      if (!configHandler.data?.activities || !activityHandler.data || !userD)
        return;
      setHighestStreak(
        activityHandler._getHighestStreak(
          activityHandler.data,
          configHandler.data?.activities,
          userD
        )
      );
    },
    [activityHandler.data, configHandler.data, userD]
  );

  return (
    <Skeleton
      isLoading={userProfile.loading}
      boneColor={C.grey500}
      highlightColor={C.grey600}
      layout={[
        {
          key: "text-skltn",
          width: scale(39),
          height: scale(39),
          borderRadius: 40,
        },
      ]}
    >
      <View style={{ justifyContent: "center", alignItems: 'center' }}>
        <View>
          <Avt
            style={{
              borderWidth: 1.1,
              borderColor: themeDark.text,
              width: 75,
              height: 75,
            }}
            source={{ uri: fn.media.smallerSizeFromURL(userD?.personali?.photoURL, 75 * 5) }}
            name={userD?.personali?.displayName || "# #"}
          />

          {highestStreak > 0 ? (
            <View
              style={{
                position: "absolute",
                bottom: -11,
                right: -10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconPrimr name="bolt" size={18} color={C.adminGold} />
              <Txt.P2
                style={{
                  color: C.adminGold,
                  fontWeight: "bold",
                  marginLeft: -3,
                }}
              >
                {highestStreak}
              </Txt.P2>
            </View>
          ) : null}
        </View>
        {showInfo ? (
          <View style={{ marginLeft: spacing(4), marginTop: spacing(1) }}>
            <Txt.H6 style={{ color: themeDark.text, textShadowColor: "black", textShadowRadius: 1.25, shadowOpacity: 0.3 }}>{userD?.personali?.displayName || "           "}</Txt.H6>
            {/* <Txt style={{ color: C.grey500 }}>{userD?.personali?.email}</Txt> */}
          </View>
        ) : null}
      </View>
    </Skeleton>
  );
}

const A = {
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "infoBlue",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};
