import { IconPrimr } from "assets";
import { Kitten, Txt } from "components/atoms";
import { useAppContext } from "engines";
import React from "react";
import { FlatList, StyleSheet } from "react-native";

export interface RecurringPNProps {
  scheduledDate: Date;
  option: string;
  setOption: any;
}

const RecurringPN = (props: RecurringPNProps) => {
  const { C } = useAppContext();
  const { scheduledDate, option, setOption } = props;

  return (
    // <View style={styles.container}>
    <FlatList
      contentContainerStyle={
        {
          // flexDirection: "row",
          // justifyContent: "center",
          // alignItems: "center",
          // flexWrap: "wrap",
        }
      }
      data={buttonData}
      keyExtractor={(item) => item._id}
      renderItem={({ item, index }) => (
        <Kitten.ListItem
          onPress={() => setOption(item.title)}
          // style={{ backgroundColor: C.background01 }}
          accessoryLeft={() => (
            <IconPrimr
              name={option !== item.title ? "radio_blank" : "radio_marked"}
              onPress={() => {
                setOption(item.title);
              }}
              color={C.primary}
            />
          )}
          accessoryRight={() => <Txt.S1>{item.title}</Txt.S1>}
        />
      )}
    />
    // </View>
  );
};

export default RecurringPN;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

// //#region [section2]
// const d = new Date();

// const weekday = [
//   "Sunday",
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
// ];

// let day = weekday[d.getUTCDay()];
// const month = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];

// let notificationsOption = [
//   {
//     index: 1,
//     title: tr("Doesn't repeat"),
//   },
//   {
//     index: 2,
//     title: tr("Daily"),
//   },
//   {
//     index: 3,
//     title: tr("Weekly on " + day),
//   },
//   // {
//   //   title: tr("Monthly on the fourth thursday"),
//   // },
//   {
//     index: 4,
//     title: tr("Annually on " + monthName + " " + d.getDate()),
//   },
//   {
//     index: 5,
//     title: tr("Every week day monday to friday"),
//   },
//   // {
//   //   title: tr("Custom"),
//   // },
// ];

// let monthName = month[d.getUTCMonth()];
// //#endregion

export enum RECTYPE {
  "DAILY" = "Daily",
  "WEEKLY" = "Weekly",
  "MONTHLY" = "Monthly",
  "YEARLY" = "Yearly",
  "BLANK" = "",
}

const buttonData = [
  {
    _id: "1",
    title: RECTYPE.DAILY,
  },
  {
    _id: "2",
    title: RECTYPE.WEEKLY,
  },
  {
    _id: "3",
    title: RECTYPE.MONTHLY,
  },
  {
    _id: "4",
    title: RECTYPE.YEARLY,
  },
];

export enum DAYS {
  "DAILY" = 1,
  /**
   * Added to the month parameter and hence = 1
   */
  "MONTHLY" = 1,
  /**
   * Added to year parameter and hence = 1
   */
  "YEARLY" = 1,
  "WEEKLY" = 7,
}
