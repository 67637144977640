import { CheckBox, ListItemProps } from "@ui-kitten/components";
import { Buttoon, Kitten, ListHeader } from "components";
import { sstyled, Txt } from "components/atoms";
import { dTask, useAppContext, useUserTasks } from "engines";
import React from "react";
import { ImageBackground, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { TodoCenter } from "./TodoCenter";

export function TaskListSection(
  props: ListItemProps & { onIncompleteTasks: (tasks: dTask[]) => void }
) {
  const { onIncompleteTasks } = props;
  const { C, frbsUser, teamId, theme } = useAppContext();

  const Navigation = useNavigator();
  const taskHandler = useUserTasks(frbsUser?.uid);

  React.useEffect(
    function fetchedTasks() {
      if (!taskHandler.data) return;
      onIncompleteTasks(taskHandler.getIncompleteTasks());
    },
    [taskHandler.data]
  );

  try {
    return (
      <A.ViewSection>
        <ListHeader
          title={tr("Tasks")}
          style={{ paddingBottom: 0, backgroundColor: "transparent" }}
          accessoryRight={() =>
            taskHandler.data?.length ? (
              <A.ChipIndicator>
                {taskHandler.getIncompleteTasks()?.length || "-"}
              </A.ChipIndicator>
            ) : (
              <></>
            )
          }
        />
        <Kitten.List
          data={taskHandler.getIncompleteTasks()?.slice(0, 3)}
          keyExtractor={(item, index) => index + "__" + item?._id}
          ItemSeparatorComponent={Kitten.Divider}
          style={{ backgroundColor: "transparent" }}
          renderItem={({ item, index }) => (
            <Kitten.ListItemv2
              key={index + "__" + item._id}
              style={{ backgroundColor: "transparent" }}
              onPress={() => {
                Navigation.overlayPortal.open({
                  render: <TodoCenter />,
                  headerTitle: "Tasks",
                });
              }}
              // accessoryLeft={() => (
              //   <CheckBox
              //     checked={item?.completed}
              //     onChange={(val) => {
              //       val && taskHandler.completeTask(item);
              //       !val && taskHandler.uncompleteTask(item);
              //     }}
              //   />
              // )}

              title={() => (
                <Txt.S2
                  style={{
                    paddingRight: spacing(3),
                    ...(item?.completed
                      ? {
                        textDecorationLine: "line-through",
                        color: C.grey600,
                      }
                      : {}),
                  }}
                  numberOfLines={0}
                >
                  {item?.caption}
                </Txt.S2>
              )}
              description={() => null}
              accessoryRight={() => (
                <Kitten.ViewH>
                  <CheckBox
                    checked={item?.completed}
                    onChange={(val) => {
                      val && taskHandler.completeTask(item);
                      !val && taskHandler.uncompleteTask(item);
                    }}
                  />
                </Kitten.ViewH>
              )}
            />
          )}
          ListEmptyComponent={() => (
            <View style={{ marginVertical: spacing(3) }}>
              <Txt.Helper style={{ textAlign: "center" }}>
                {"No tasks to complete."}
              </Txt.Helper>
              <Buttoon
                onPress={() => {
                  Navigation.overlayPortal.open({
                    render: <TodoCenter />,
                    headerTitle: "Tasks",
                  });
                }}
                size="small"
                appearance="ghost"
                status="basic"
                icon={{ right: true, name: "chevron_right" }}
              >
                {"view tasks"}
              </Buttoon>
            </View>
          )}
          ListFooterComponent={() =>
            taskHandler.data && taskHandler.getIncompleteTasks()?.length > 3 ? (
              <Buttoon
                onPress={() => {
                  Navigation.overlayPortal.open({
                    render: <TodoCenter />,
                    headerTitle: "Tasks",
                  });
                }}
                size="small"
                appearance="ghost"
                status="basic"
                icon={{ right: true, name: "chevron_right" }}
              >
                {taskHandler.getIncompleteTasks()?.length > 3
                  ? `see ${taskHandler.getIncompleteTasks()?.length - 3
                  } more task(s)`
                  : `see all tasks`}
              </Buttoon>
            ) : null
          }
        />
      </A.ViewSection>
    );
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}

const A = {
  ViewSection: sstyled(Kitten.Layout)(({ hovered }) => ({
    flex: 1,
    flexGrow: 1,
    backgroundColor: "transparent",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ImageBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingVertical: spacing(2),
  }),
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "errorRed",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};
