export function extractLinkFromBody(body: string) {
  if (!body) return null;
  const lowercaseBody = body.toLowerCase();
  let _http = "https://";
  if (lowercaseBody.includes("http://")) {
    _http = "http://";
  } else if (lowercaseBody.includes("https://")) {
    _http = "https://";
  } else return null;
  let b2 = body.substring(lowercaseBody.indexOf(_http), body.length);
  let link = b2.substring(0, b2.indexOf(" ") > 0 ? b2.indexOf(" ") : b2.length);
  return link;
}
