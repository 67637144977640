import { ListItemProps } from "@ui-kitten/components";
import { dIconPrimr, IconPrimr } from "assets";
import { dButtoon, Txt } from "components";
import { Kitten } from "components/atoms/externals";
import { Buttoon, dMenoo, Menoo, sstyled } from "components/atoms/generals";
import { useAppContext } from "engines";
import * as R from "ramda";
import * as React from "react";
import { MenuOptionProps } from "react-native-popup-menu";
import { scale, spacing } from "utils";

export interface dListHeader extends ListItemProps {
  /**
   * Title text, using preset designed (Recommended)
   */
  titleTx?: string;

  /** List of action buttoons, accepting **dButtoon** props.
   * - Use `label` arg for buttoon's label
   * - Try to add at most 3 buttoons for best UX
   */
  actions?: dAction[];
}

interface dAction extends dButtoon {
  label?: string;
  icon?: dIconPrimr;
  menuOptions?: dMenoo["options"];
  right?: boolean;
}

/**
 * ### List Header with ActionsGroup as an accessoryRight
 *  - Use as the header of e.g. PosstsFeed
 *  ----
 *  @example
 *   <ListHeader
        titleTx={tr("Head of the table")}
        actions={[
          { label: "Save", onPress: () => Toasty.show("Save") },
          { icon: { name: "trash" }, onPress: () => Toasty.show("Delete") },
        ]}
      />
 *  ----
 *  @version 21.03.24
 *  -  *build component*
 *  @author  K
 *
 **/
export function ListHeader(props: dListHeader) {
  const { titleTx = "", actions } = props;
  const { C } = useAppContext();
  // const breakpoint = useBreakpointIndex();

  /** Reduced actions to filter out those empty value */
  const reducedActions = actions
    ? actions.filter((action) => action && !R.isNil(action))
    : [];

  /** Technically those "buttons" or "menu items" that will render inside ControlActions row */
  let ActionItem = (actionProps: dAction & { type: "icon-only" }) => {
    if (actionProps.menuOptions) {
      let ViewTrigger = ({ disabled = false }: { disabled?: boolean }) => (
        <Kitten.ViewH
          style={{
            /** Technically Buttoon's outline tiny config */
            borderColor: disabled ? C.dim : C.primary,
            backgroundColor: disabled
              ? C["color-basic-transparent-disabled"]
              : C["color-primary-transparent-100"],
            borderWidth: 1,
            paddingHorizontal: scale(6),
            paddingVertical: scale(6),
            borderRadius: 10,
            marginHorizontal: spacing(2),
          }}
        >
          {actionProps?.icon && !actionProps?.right ? (
            <IconPrimr
              preset="default"
              size={12}
              color={disabled ? C.dim : C.primary}
              {...actionProps?.icon}
            />
          ) : null}
          <Txt
            style={{
              color: disabled ? C.dim : C.primary,
              marginHorizontal: scale(6),
              fontSize: scale(10),
              fontWeight: "bold",
            }}
          >
            {actionProps?.label}
          </Txt>
          {actionProps?.icon && actionProps?.right ? (
            <IconPrimr
              preset="default"
              size={12}
              color={disabled ? C.dim : C.primary}
              {...actionProps?.icon}
            />
          ) : null}
        </Kitten.ViewH>
      );
      if (actionProps?.disabled) {
        return <ViewTrigger disabled />;
      }
      return (
        <Menoo
          trigger={{
            disabled: actionProps?.disabled,
            render: <ViewTrigger />,
          }}
          options={actionProps.menuOptions}
        />
      );
    } else {
      return (
        <Buttoon
          appearance="ghost"
          size="tiny"
          status={"basic"}
          {...actionProps}
        >
          {actionProps.type !== "icon-only" && actionProps.label}
        </Buttoon>
      );
    }
  };

  /** Rows of action items (button || menu item) on the right of ListHeader<> */
  const ControlActions = () => {
    if (reducedActions.length < 4) {
      /** If no action, show blank View */
      if (reducedActions.length == 0) {
        return <A.ViewControlRow />;
      } else if (reducedActions?.length == 1) {
        /** If 1 action --> show text+icon button */
        return reducedActions ? (
          <A.ViewControlRow>
            <ActionItem {...reducedActions[0]} />
          </A.ViewControlRow>
        ) : null;
      } else {
        /** If >1 & <4 actions --> show icon button group */
        return reducedActions ? (
          <A.ViewControlRow>
            <Kitten.ButtonGroup
              appearance="outline"
              size="tiny"
              status="basic"
              style={{ borderWidth: 0 }}
            >
              {reducedActions.map((action, index) => (
                <ActionItem
                  key={index}
                  {...reducedActions[index]}
                  type={"icon-only"}
                />
              ))}
            </Kitten.ButtonGroup>
          </A.ViewControlRow>
        ) : null;
      }
    } else {
      /** If >=4 actions, show them as a dropdown menoo */
      const _menooOptions: MenuOptionProps & { text: string }[] =
        reducedActions.map((action) => ({
          text: action?.label,
          onSelect: () => action?.onPress(),
        }));
      return reducedActions ? (
        <Menoo
          trigger={{
            name: "dots_vertical",
            color: C.grey600,
            preset: "circular",
            size: scale(14),
            // text: "Click me",
            // style: { color: "tomato" },
          }}
          options={_menooOptions}
        />
      ) : null;
    }
  };

  return (
    <Kitten.ListItemv2
      // disabled
      title={() => <Txt.$Title numberOfLines={1}>{titleTx}</Txt.$Title>}
      style={{ paddingLeft: 2, backgroundColor: "transparent" }}
      accessoryRight={ControlActions}
      {...props}
    />
  );
}

const A = {
  ViewControlRow: sstyled(Kitten.ViewH)({
    alignSelf: "flex-start",
    marginLeft: spacing(2),
    // bottom: -scale(9),
    // position: "absolute",
  }),
};
