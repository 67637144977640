//#region [IMPORT]//#region [IMPORT]
import { IconPrimr, ICON_NAME } from "assets";
import { Buttoon, Kitten } from "components";
import { dAccessory, sstyled, Toasty } from "components/atoms";
import { useMailTemplatesFunctions } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { View } from "react-native";
import WebView from "react-native-webview";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";

// import ReactQuill from "react-quill";
// // import "react-quill/dist/quill.snow.css";

/**
 * ### Post skeleton to show when db is being loaded
 *  ----
 *  @example
 *  loading && <PosstSkeleton />
 *  ----
 *  @version 21.03.05
 *  -  *Build a component*
 *  @author  NL
 *
 **/

export function TemplateCreate(props: P) {
  const userProfile = useUserProfile();
  const [value, setValue] = React.useState("");
  // const pwRef = React.useRef<Kitten.Input>();
  const [_subject, setSubject] = React.useState<any>();
  const Navigation = useNavigator();
  const creatorForm = useForm();
  const MenuIcon = (name: ICON_NAME, props: dAccessory) => (
    <IconPrimr
      name={name}
      size={props.style.width}
      color={props.style.tintColor}
    />
  );

  const handleTemplate = useMailTemplatesFunctions();

  //#endregion
  function SendMails(xong) {
    /**
     * We are doing this because postmark doesn't accept links to be passed the email
     */
    var refactorhtml = props.designHtml.replace(
      `<link href="https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap" rel="stylesheet" type="text/css">`,
      ``
    );

    var anotherRefactor = refactorhtml.replace(
      `<link href="https://fonts.googleapis.com/css?family=Playfair+Display:400,700&display=swap" rel="stylesheet" type="text/css">`,
      ``
    );

    const payload = {
      _teamId: userProfile.data?._teamId,
      appId: "primr-exp",
      userId: userProfile.data?._id,
      template_name: _subject,
      // template_alias: subject,
      template_subject: _subject,
      template_html_body: anotherRefactor,
      template_jsonBody: JSON.stringify(props.designJson),
    };
    // console.log("payload ", payload);

    handleTemplate
      .add(payload)
      .then((res) => {
        if (res.status == 200) {
          Toasty.show("Template Created", { type: "success" });
          xong && xong();
          setTimeout(() => {
            Navigation.navigate("mass-mail-home");
          }, 1000);
        }
      })
      .catch(() => {
        // console.log("an error occured");
        xong && xong();
        Toasty.show("Template not created", { type: "danger" });
      });
  }

  // if (handleTemplate.error) return <Txt.Indicator>Error!</Txt.Indicator>;
  // if (!handleTemplate.get()) return <ScreenSkeleton3 />;
  return (
    <>
      <FormProvider {...creatorForm}>
        <A.CtnrBuilder
        // disabled
        // header={M.PosstCreatorHeader}
        // footer={M.PosstCreatorFooter}
        >
          <WebView
            source={{ html: props.chunkBody }}
            style={{ flex: -1, width: "100%", height: 700 }}
          />

          {/* <Txt>{props.chunkBody}</Txt> */}
          <Kitten.Input
            // ref={emailRef}
            placeholder={tr("Template name")}
            autoFocus={true}
            // // disabled={_runningTSKS || !R.isNil(frbsAuthe)}
            autoCapitalize="none"
            value={_subject}
            onChangeText={setSubject}
            // onBlur={() => Keyboard.dismiss()}
            selectTextOnFocus
          />

          <Buttoon
            progress
            onPress={(xong) => SendMails(xong)}
            accessoryLeft={(props: dAccessory) => MenuIcon("send", props)}
          >
            {tr("Create template")}
          </Buttoon>
        </A.CtnrBuilder>
      </FormProvider>
    </>
  );
}

const A = {
  CtnrBuilder: sstyled(View)((p) => ({
    flex: 1,
    backgroundColor: "background",
    width: "100%",
    marginBottom: 10,
    paddingHorizontal: spacing(2),
  })),
  CtnrMedia: sstyled(Kitten.ViewH)({
    // justifyContent: "space-evenly",
    // flexWrap: "wrap",
    // flexDirection: "row",

    flex: 1,
    // flexDirection: "row",
    flexWrap: "wrap",
    // width: "100%",
  }),

  InputBody: sstyled(Kitten.Input)((p) => ({
    // maxWidth: 400,
    width: "100%",
    maxHeight: 800,
    padding: spacing(3),
    // backgroundColor: "back",
  })),
};

interface P {
  designJson: any;
  designHtml: any;
  chunkBody: any;
}
