import { ListItemProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Kitten } from "components";
import { Buttoon, sstyled, Txt } from "components/atoms";
import {
  dActivityEntry,
  fn,
  ShortcutSchema,
  useActivityCollection,
  useAppContext,
  useConfigDocument
} from "engines";
import React from "react";
import { ImageBackground } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { useNavigator } from "screens";
import { scale, spacing, THEME, tr } from "utils";
import {
  dateToStr,
  getNormalDate
} from "../../../engines/firebase/handler/activity-handler";
import { StatsCreatorCenter } from "../../organisms/personal-activity/StatsCreatorCenter";
import { ShortcutEditor } from "./ShortcutEditor";

export function WidgetUserStats(
  props: ListItemProps & {
    isHomeScreen?: boolean;
    page: ShortcutSchema;
    uid?: string;
    todaysProgress?: number;
  }
) {
  const { page, uid, onPress, todaysProgress, isHomeScreen } = props;
  const { C, frbsUser, teamId, theme } = useAppContext();

  let _uid = uid || frbsUser?.uid;
  // const Navigation = useNavigator();

  const configDoc = useConfigDocument("variables-" + teamId);

  const { _getHighestStreak, ...userActivities } = useActivityCollection(_uid);
  const Navigation = useNavigator();
  const [highestStreak, setHighestStreak] = React.useState<number>(0);
  const [todayReport, setTodayReport] = React.useState<dActivityEntry[]>([]);

  React.useEffect(
    function todayReportListener() {
      if (userActivities?.data && configDoc?.data) {
        let _rawData = userActivities.data?.filter(
          (ac) => dateToStr(getNormalDate(ac?.date)) === dateToStr(new Date())
        );
        let _activitiesDB = configDoc?.data?.activities || [];
        let metadataData = _rawData?.map((data) => ({
          ...data,
          date: fn.calendar.dateFormat(data?.date?.toDate()),
          metadata: _activitiesDB?.filter(
            (db) => db.key === data?.activityKey
          )[0],
        }));
        setTodayReport(metadataData);
      }
    },
    [userActivities?.data, configDoc?.data]
  );
  function hasUserReported() {
    return userActivities.data?.find(
      (ac) => dateToStr(getNormalDate(ac?.date)) === dateToStr(new Date())
    );
  }

  const onReportDayPress = () => {
    Navigation.dialogPortal.open({
      headerTitle: "Enter today's stats",
      render: <StatsCreatorCenter />,
    });
  };

  if (isHomeScreen) {
    return (
      <A.ViewWidget {...props}>
        <Kitten.ViewH
          style={{
            justifyContent: "space-between",
            paddingHorizontal: spacing(3),
            backgroundColor: theme === THEME.LIGHT && C.text,
          }}
        >
          <Txt.C2 style={{ color: C.adminGold }}>{tr("Highest Streak")}</Txt.C2>
          <Kitten.ViewH>
            <IconPrimr name="bolt" size={20} color={C.adminGold} />
            <Txt.H5 style={{ color: C.adminGold }}>
              {_getHighestStreak()}
            </Txt.H5>
          </Kitten.ViewH>
        </Kitten.ViewH>
        {/* <Kitten.ListItemv2
    title={tr("streak")}
    disabled
    accessoryRight={() => (
      <Kitten.ViewH>
        <IconPrimr name="bolt" size={20} color={C.adminGold} />
        <Txt.H5 style={{ color: C.adminGold }}>10</Txt.H5>
      </Kitten.ViewH>
    )}
    style={{ backgroundColor: C.surface }}
  /> */}
        <A.ImageBackground
        // source={{
        //   uri: "https://png.pngtree.com/background/20210717/original/pngtree-golden-yellow-gradient-abstract-art-background-picture-image_1445360.jpg",
        // }}
        // blurRadius={5}
        >
          {/* <View style={A.ImageBackground}> */}
          {/* <Txt>{JSON.stringify(todayReport, null, "\t")}</Txt> */}
          {hasUserReported() ? (
            <Txt.S2 style={{ textAlign: "center" }}>
              {tr("🎉 Hooray, you've finished your daily report!")}
            </Txt.S2>
          ) : (
            <Buttoon
              size={"tiny"}
              onPress={onReportDayPress}
              style={{ alignSelf: "center" }}
              icon={{ name: "plus" }}
              status="warning"
            // compact
            >
              {tr("Report my day")}
            </Buttoon>
          )}
          <Buttoon
            size={"tiny"}
            appearance={"ghost"}
            status={"basic"}
            onPress={onPress}
            style={{ alignSelf: "center" }}
            icon={{ name: "link", right: true }}
          >
            {tr("Visit My Stats")}
          </Buttoon>
          <Txt.C2
            style={{ color: C.primary, textAlign: "center" }}
            onPress={() => {
              Navigation.dialogPortal.open({
                headerTitle: tr("Edit shortcut"),
                render: (
                  <ShortcutEditor editingDocId={page?._id} type={"link"} />
                ),
              });
            }}
          >
            {tr("Edit")}
          </Txt.C2>
          {/* </View> */}
        </A.ImageBackground>
      </A.ViewWidget>
    );
  }

  return !todayReport?.length > 0 ? (
    <Buttoon
      // forceTheme={THEME.DARK}
      // size={"small"}
      onPress={onReportDayPress}
      // style={{ alignSelf: "center" }}
      status="control"
      style={{ flex: 1, borderRadius: scale(9), margin: spacing(2) }}
      // icon={{ name: "", right:true }}
      // appearance={todaysProgress === 1 ? "primary" : "outline"}
      appearance={"filled"}
      icon={{ name: "pencil" }}
    // accessoryRight={() =>
    //   todaysProgress > -1 ? (
    //     <AnimatedCircularProgress
    //       style={{ marginHorizontal: spacing(4) }}
    //       size={50}
    //       width={4}
    //       fill={todaysProgress * 100}
    //       rotation={0}
    //       tintColor={C.primary}
    //       backgroundColor={C.primaryDarker}
    //     >
    //       {() => <Txt.S2>{`${Math.round(todaysProgress * 100)}%`}</Txt.S2>}
    //     </AnimatedCircularProgress>
    //   ) : (
    //     <Kitten.Spinner />
    //   )
    // }
    // compact
    >
      {tr("Report my day")}
    </Buttoon>
  ) : (
    <Buttoon
      icon={{ name: "arrow_right", right: true }}
      appearance="outline"
      onPress={() => Navigation.navigate("stats")}
    >
      {"Day complete!\nSee your stats"}
    </Buttoon>
    // <FlatList
    //   data={todayReport}
    //   keyExtractor={(item, index) => item + index}
    //   ItemSeparatorComponent={Kitten.Divider}
    //   renderItem={({ item }) => (
    //     <Kitten.ListItemv2
    //       title={
    //         item?.metadata?.title || tr("This question is no longer existed")
    //       }
    //       description={item?.metadata?.description}
    //       accessoryRight={() => {
    //         switch (item?.metadata?.entryType) {
    //           case "number":
    //             return <Txt.H5 style={{ maxWidth: 300 }}>{item?.value}</Txt.H5>;
    //             break;
    //           case "text":
    //             return <Txt style={{ maxWidth: 300 }}>{item?.value}</Txt>;
    //             break;
    //           case "checkbox":
    //             return (
    //               <Txt.H5 style={{ maxWidth: 300 }}>
    //                 {item?.value ? "✅" : "❌"}
    //               </Txt.H5>
    //             );
    //             break;

    //           default:
    //             return <Txt style={{ maxWidth: 300 }} />;
    //             break;
    //         }
    //       }}
    //       disabled={!item?.metadata?.title} //* If question doesn't existed, it'll be grey out
    //       style={{ backgroundColor: "transparent" }}
    //     />
    //   )}
    // />
  );
  return todayReport?.length > 0 ? (
    <A.ViewWidget {...props}>
      <Kitten.ViewH
        style={{
          justifyContent: "space-between",
          paddingHorizontal: spacing(3),
          backgroundColor: theme === THEME.LIGHT && C.text,
        }}
      >
        <Txt.C2 style={{ color: C.adminGold }}>{tr("Highest Streak")}</Txt.C2>
        <Kitten.ViewH>
          <IconPrimr name="bolt" size={20} color={C.adminGold} />
          <Txt.H5 style={{ color: C.adminGold }}>{_getHighestStreak()}</Txt.H5>
        </Kitten.ViewH>
      </Kitten.ViewH>
      {/* <Kitten.ListItemv2
        title={tr("streak")}
        disabled
        accessoryRight={() => (
          <Kitten.ViewH>
            <IconPrimr name="bolt" size={20} color={C.adminGold} />
            <Txt.H5 style={{ color: C.adminGold }}>10</Txt.H5>
          </Kitten.ViewH>
        )}
        style={{ backgroundColor: C.surface }}
      /> */}
      <A.ImageBackground
      // source={{
      //   uri: "https://png.pngtree.com/background/20210717/original/pngtree-golden-yellow-gradient-abstract-art-background-picture-image_1445360.jpg",
      // }}
      // blurRadius={5}
      >
        {/* <View style={A.ImageBackground}> */}
        <Txt>{JSON.stringify(todayReport, null, "\t")}</Txt>
        {hasUserReported() ? (
          <Txt.S2 style={{ textAlign: "center" }}>
            {tr("🎉 Hooray, you've finished your daily report!")}
          </Txt.S2>
        ) : (
          <Buttoon
            size={"tiny"}
            onPress={onReportDayPress}
            style={{ alignSelf: "center" }}
            icon={{ name: "plus" }}
            status="warning"
          // compact
          >
            {tr("Report my day")}
          </Buttoon>
        )}
        <Buttoon
          size={"tiny"}
          appearance={"ghost"}
          status={"basic"}
          onPress={onPress}
          style={{ alignSelf: "center" }}
          icon={{ name: "link", right: true }}
        >
          {tr("Visit My Stats")}
        </Buttoon>
        <Txt.C2
          style={{ color: C.primary, textAlign: "center" }}
          onPress={() => {
            Navigation.dialogPortal.open({
              headerTitle: tr("Edit shortcut"),
              render: <ShortcutEditor editingDocId={page?._id} type={"link"} />,
            });
          }}
        >
          {tr("Edit")}
        </Txt.C2>
        {/* </View> */}
      </A.ImageBackground>
    </A.ViewWidget>
  ) : (
    <Buttoon
      // size={"tiny"}
      onPress={onReportDayPress}
      // style={{ alignSelf: "center" }}
      style={{ flex: 1, borderRadius: scale(9), margin: spacing(2) }}
      icon={{ name: "plus" }}
      status="warning"
      appearance="outline"
    // compact
    >
      {tr("Report my day")}
    </Buttoon>
  );
}

const A = {
  ViewShortcut: sstyled(Kitten.ListItemv2)(({ hovered }) => ({
    height: scale(68),
    borderWidth: 1,
    borderColor: hovered ? "primary" : "transparent",
  })),
  ViewWidget: sstyled(Kitten.Layout)(({ hovered }) => ({
    flex: 1,
    flexGrow: 1,
    // borderWidth: 1,
    // backgroundColor: "surface",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ImageBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: spacing(2),
  }),
};
