// import React from "react";
import {
  Buttoon,
  Kitten, ListHeader, Skeleton,
  Txt
} from "components/atoms";
import { ShortcutItem } from "components/molecules";
import { ShortcutList } from "components/organisms";
import { useBreakpointIndex } from "dripsy";
import { ShortcutSchema, URL_PREFIX, useAppContext } from "engines";
import { useMoreCollection } from "engines/firebase/handler/more-handler";
import * as R from "ramda";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";
import { WidgetEditor } from "../../molecules/widgets/widget-editor";

interface dShortcutsGrid {
  type: "full" | "compact";
}
export function ShortcutsHomeGrid({ type = "full", ...props }: dShortcutsGrid) {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const breakpoint = useBreakpointIndex();
  const shortcutsHandler = useMoreCollection();
  // const userProfile = useUserProfile();

  const [sectionShown, setSectionShown] = React.useState<boolean>(true);

  let gridLayout = () => {
    switch (type) {
      case "compact":
        return {};
        break;
      default:
        return {};
        break;
    }
  };

  // const categorizedData = shortcutsHandler.data?.map((shortcut) => {
  //   if (shortcut?.name && (shortcut?.contactPhone || shortcut?.contactEmail)) {
  //     return { ...shortcut, _CAT: "contact" };
  //   }
  //   if (
  //     shortcut?.name &&
  //     (shortcut?.link ||
  //       shortcut?.iosLink ||
  //       shortcut?.androidLink ||
  //       shortcut?.otherLink)
  //   ) {
  //     return { ...shortcut, _CAT: "link" };
  //   }
  //   return { ...shortcut, _CAT: "other" };
  // });

  const [_shortcuts, setShortcuts] = React.useState<ShortcutSchema[]>([]); // supply your own state

  React.useEffect(
    function grabItems() {
      if (shortcutsHandler.data) {
        const categorizedData = shortcutsHandler.data?.map((shortcut) => {
          if (
            shortcut?.name &&
            (shortcut?.contactPhone || shortcut?.contactEmail)
          ) {
            return { ...shortcut, _CAT: "contact" };
          }
          if (
            shortcut?.name &&
            (shortcut?.link ||
              shortcut?.iosLink ||
              shortcut?.androidLink ||
              shortcut?.otherLink)
          ) {
            if (String(shortcut?.link).startsWith("inapp://")) {
              return { ...shortcut, _CAT: "inapp" };
            } else if (String(shortcut?.link).startsWith(URL_PREFIX.PAGE)) {
              return { ...shortcut, _CAT: "page" };
            } else return { ...shortcut, _CAT: "link" };
          }
          return { ...shortcut, _CAT: "other" };
        });
        let sortedCatData = R.sortWith<ShortcutSchema>(
          [R.ascend(R.prop("position"))],
          categorizedData
        );
        setShortcuts(sortedCatData);
      }
    },
    [shortcutsHandler.data]
  );

  //#region [functions]
  function onCreateShortcut() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Create a shortcut"),
      render: (
        <WidgetEditor
          onValueCallback={(value) =>
            console.log("widget val: ", JSON.stringify(value, null, "\t"))
          }
        />
      ),
    });
  }
  function onViewAllShortcutsPress() {
    Navigation.overlayPortal.open({
      headerTitle: tr("View all shortcut"),
      render: <ShortcutList />,
    });
  }
  //#endregion

  try {
    if (shortcutsHandler.error) {
      console.warn("error in ShortcutList: ", shortcutsHandler.error);
      return <Txt.Indicator>{tr("Error getting data")}</Txt.Indicator>;
    }
    if (!shortcutsHandler.data) return <Skeleton.Screen.Feed length={2} />;
    return (
      <View>
        {/* <WidgetEditor
          onValueCallback={(value) =>
            console.log("widget val: ", JSON.stringify(value, null, "\t"))
          }
        /> */}
        {/* <ShortcutEditor /> */}
        <ListHeader
          titleTx={tr("Shortcuts")}
          actions={[
            {
              label: "Create a shortcut",
              icon: { name: "plus" },
              onPress: onCreateShortcut,
            },
            {
              label: "Hide",
              icon: { name: sectionShown ? "chevron_up" : "chevron_down" },
              onPress: () => setSectionShown(!sectionShown),
            },
          ]}
        />
        {/* <Buttoon
          onPress={onCreateShortcut}
          icon={{ name: "plus" }}
          appearance={"ghost"}
        >
          {tr("Create new")}
        </Buttoon> */}
        {sectionShown && (
          <View>
            {/* <Txt.S1 style={{ marginTop: spacing(3) }}>Widgets</Txt.S1> */}
            {/* <CalendarGlance /> */}
            <Kitten.List
              data={_shortcuts.filter((item) => {
                return item._CAT === "inapp";
              })}
              style={{ backgroundColor: "transparent" }}
              // data={_shortcuts}
              key={breakpoint <= 2 ? "bi-list" : "tri-list"}
              numColumns={breakpoint <= 2 ? 2 : 3}
              numColumns={2}
              renderItem={({ item, index }) => (
                <ShortcutItem type={item._CAT} page={item} />
              )}
            />
            <Kitten.List
              data={_shortcuts.filter((item) => {
                return item._CAT === "page" && item?.isStarred;
              })}
              style={{ backgroundColor: "transparent" }}
              key={breakpoint <= 2 ? "bi-list" : "tri-list"}
              numColumns={breakpoint <= 2 ? 2 : 3}
              numColumns={1}
              renderItem={({ item, index }) => (
                <ShortcutItem type={item._CAT} page={item} />
              )}
              ItemSeparatorComponent={() => <Kitten.Divider />}
            />
            {/* <Txt.S1 style={{ marginTop: spacing(3) }}>Links, Contacts</Txt.S1> */}
            <Kitten.List
              data={_shortcuts.filter((item) => {
                return (
                  item._CAT !== "inapp" &&
                  item._CAT !== "page" &&
                  item?.isStarred &&
                  item?.name
                );
              })}
              style={{ backgroundColor: "transparent" }}
              key={breakpoint <= 2 ? "bi-list" : "tri-list"}
              numColumns={breakpoint <= 2 ? 2 : 3}
              numColumns={1}
              renderItem={({ item, index }) => (
                <ShortcutItem type={item._CAT} page={item} />
              )}
              ItemSeparatorComponent={() => <Kitten.Divider />}
            />
            <Buttoon
              size={"tiny"}
              appearance={"ghost"}
              status={"basic"}
              onPress={onViewAllShortcutsPress}
              style={{ alignSelf: "center" }}
              icon={{ name: "link", right: true }}
            >
              {tr("View all shortcuts")}
            </Buttoon>
          </View>
        )}
      </View>
    );
  } catch (error) {
    throw Error(`::TWVkaWFIb21lRmVlZA==::${error}`);
  }
}

// import React from "react";
// import { Kitten, Txt, Avt, sstyled } from "components";
// import { fn } from "engines";
// import { scale } from "utils";
// import { useCollectionPresetv2 } from "../../../engines/firebase/config";
// import { Pressable, View } from "react-native";
// import { IconPrimr } from "../../../assets/icons/primr-icon";
// import { useNavigator } from "../../../screens/_navigation";
// import { useBreakpointIndex } from "dripsy";
// import { StackActions } from "@react-navigation/native";
// interface dShortcutsHomeGrid {}

// /**
//  * ### List of Shortcuts
//  *  - Located in HomeScreen
//  *  ----
//  *  @example
//  *  Copy and paste function is the best...
//  *  ----
//  *  @version 23.04.25
//  *  -  *Brief changelog*
//  *  @author  K
//  *
//  **/
// export function ShortcutsHomeGrid(props: dShortcutsHomeGrid) {
//   const {} = props;
//   const Navigation = useNavigator();
//   const breakpoint = useBreakpointIndex();
//   const { data, ...shortcutsHandler } = useCollectionPresetv2<ShortcutsSchema>(
//     "shortcuts",
//     "_id"
//   );
//   return (
//     <>
//       <Txt.$Title
//         marginV
//         onPress={() =>
//           shortcutsHandler.add({
//             _teamId: "",
//             name: "Test 2",
//             uri: "scoreboards",
//             position: 2,
//           })
//         }
//       >
//         Shortcuts
//       </Txt.$Title>
//       <Kitten.List
//         data={data}
//         key={breakpoint <= 2 ? "single-list" : "double-list"}
//         numColumns={breakpoint <= 2 ? 1 : 2}
//         renderItem={({ item, index }) => (
//           <ShortcutItem index={index} item={item} />
//         )}
//         sepItemSeparatorComponent={Kitten.Divider_________}
//         containerStyle={{ borderRadius: scale(9) }}
//       />
//     </>
//   );
// }

// function ShortcutItem({ item, ...props }: dShortcutItem) {
//   const Navigation = useNavigator();

//   const [linkType, setLinkType] = React.useState<
//     "inapp" | "page" | "url" | "link_magnet" | "fin" | "bml"
//   >(null);

//   //#region [section] handle link as a page link
//   const [uriValue, setUriValue] = React.useState<string>(null);
//   //   const handlePage = useDocument<MediaPageSchema>(`${FPATH.PAGES}/${cpid}`);
//   //#endregion
//   React.useEffect(
//     /**
//      * get link type
//      */
//     function getLinkType() {
//       // not including level: here because level-button-item.tsx handles this
//       if (item?.uri?.startsWith("page:")) {
//         setLinkType("page");
//         setUriValue(item?.uri?.split("page:")?.pop());
//       }
//       if (item?.uri?.startsWith("inapp:")) {
//         setLinkType("inapp");
//         setUriValue(item?.uri?.split("inapp:")?.pop());
//       }
//       // if (item?.uri?.startsWith("http")) {
//       else if (item?.uri?.length > 0) {
//         if (fn.js.isLinkMagnetURL(item?.uri)) {
//           setLinkType("link_magnet");
//         } else if (fn.js.isFinURL(item?.uri)) {
//           setLinkType("fin");
//         } else if (fn.js.isBMLButton(item?.uri)) {
//           setLinkType("bml");
//         } else {
//           setLinkType("url");
//         }
//       }
//     },
//     [item?.uri]
//   );
//   const onPress = () => {
//     switch (linkType) {
//       case "inapp":
//         return Navigation.navigate(uriValue);
//         break;
//       case "page":
//         /** NOTE Copy from button-item. Need modify */
//         return Navigation.dispatch(
//           StackActions.push("Page Contents", {
//             cpid: "cpid",
//             collabMode: false,
//             buttonItem: item,
//             previousCpid: "parentPageId",
//           })
//         );
//         break;

//       default:
//         return;
//         break;
//     }
//   };
//   return (
//     <Pressable style={{ flexGrow: 1, flex: 1 }}>
//       {({ hovered }) => (
//         <A.ItemShortcut
//           hovered={hovered}
//           accessoryLeft={() => (
//             <View>
//               <Avt name={item.name} source={{ url: "" }} shape={"square"} />
//               <IconPrimr
//                 name="link"
//                 preset={"default"}
//                 color="white"
//                 size={11}
//                 containerStyle={{
//                   backgroundColor: "black",
//                   position: "absolute",
//                   right: -3,
//                   bottom: -3,
//                 }}
//               />
//             </View>
//           )}
//           title={item.name}
//           description={item.description}
//           onPress={onPress}
//           {...props}
//         />
//       )}
//     </Pressable>
//   );
// }

// interface dShortcutItem extends Kitten.ListItemv2 {
//   item: ShortcutsSchema;
// }
// interface ShortcutsSchema {
//   _id: string;
//   _teamId: string;
//   name: string;
//   description?: string;
//   uri: string;
//   position: number;
// }

// const A = {
//   ItemShortcut: sstyled(Kitten.ListItemv2)(({ hovered }) => ({
//     // width: scale(400),
//     // borderRadius: scale(4),
//     borderWidth: 1,
//     borderColor: hovered ? "primary" : "transparent",
//   })),
// };
