import { IconPrimr } from "assets";
import Color from "color";
import {
  Avt,
  Buttoon,
  Inpuut,
  Kitten,
  Menoo,
  Poppy,
  sstyled,
  Toasty,
  Txt
} from "components";
import { PickyColor } from "components/atoms/others/PickyColor/PickyColor";
import { AssignUserBS } from "components/organisms/bs/AssignUserBS";
import { styled } from "dripsy";
import { fn, FRBS_ROLE, MediaPageSchema, useAppContext } from "engines";
import { dUseDocumentReturns } from "engines/firebase/config";
import {
  UserSchemaWithCustomFunc,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import { usePageDocument } from "engines/firebase/handler/pages-handler";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import { useCollabContext } from "engines/providers/collaboration-provider";
import * as ImagePicker from "expo-image-picker";
import firebase from "firebase";
import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import {
  Image,
  ImageBackground,
  Platform,
  Pressable,
  View
} from "react-native";
import { scale } from "react-native-size-matters";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";
import { S_PageItemAddPassword } from "utils/helpers/PageItemAddPassword";

/**
 * ### BS Home Item
 *  - Displaying BS logo, name
 *  - Include BS funcs
 *  ----
 *  @version 21.11.24
 *  -  *Move this component to ./molecules*
 *  @author  K, Nl
 *
 **/
export function BSHomeItem(props: dBSHomeItem) {
  const {
    index,
    cpid,
    onPress,
    addEntryClick,
    editModeEnabled,
    onEditCallback,
  } = props;
  const { setIsCollabModeOn } = useCollabContext();
  const { C, theme } = useAppContext();
  const Navigation = useNavigator();
  //#region [FRBS] handler
  const userProfile = useUserProfile();
  const pageHandler = usePageDocument(cpid);

  //#endregion

  const [itemIndexSelected, setItemIndexSelected] =
    React.useState<number>(null);

  // #region [ANCHOR2] NOTE To disable collaboration mode if you transition from collab to baseshop
  React.useEffect(() => {
    setIsCollabModeOn(false);
  }, []);
  // #endregion

  React.useEffect(() => {
    editModeEnabled == false && setItemIndexSelected(null);
  }, [editModeEnabled]);

  //#region [functions] more functions
  const [title, setTitle] = React.useState<string>(pageHandler.data?.name);
  const [titleEdit, setTitleEdit] = React.useState<boolean>(false);
  function onRemoveItem() {
    Poppy(
      "Final Check",
      "Are you sure you want to delete this resource?",
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () => pageHandler.deleteDocument(),
      }
    );
  }

  function onMoveFrom() {
    setItemIndexSelected(index);
    console.log("index ", index);

    addEntryClick(pageHandler.data);
    const values = {
      name: pageHandler.data?.name,
      position: pageHandler.data?.position,
      _id: pageHandler.data?.id,
    };
    console.log("from ", values);
    onEditCallback && onEditCallback();
  }
  function onMoveTo() {
    addEntryClick(pageHandler.data);

    const values = {
      name: pageHandler.data?.name,
      position: pageHandler.data?.position,
      _id: pageHandler.data?.id,
    };

    console.log("to ", values);
    onEditCallback && onEditCallback();
  }
  // console.log("editModeEnabled ", editModeEnabled);
  //#endregion

  return React.useMemo(() => {
    try {
      if (pageHandler.error) throw Error();
      if (!pageHandler.data) return null;
      const itemColor = pageHandler.data?.mediaItem?.color
        ? Color(pageHandler.data?.mediaItem?.color).alpha(0.7).toString()
        : C.surface;

      return (
        <Pressable style={{ flex: 1, margin: spacing(3), height: "100%" }}>
          {({ hovered }) => (
            <A.PressableContainer
              style={{
                backgroundColor: itemColor,
              }}
              onPress={() => onPress && onPress()}
            >
              {
                //#region [section] Logo section
                /**
                 * This was implemented this way because of the issue
                 * we had when ever we change a photo, the component
                 * doesn't show us the photo except we refresh the screen.
                 * we might have to look at the AVT
                 * component to see other ways we can solve that later
                 */
                <A.LogoBackground
                  source={{ uri: pageHandler.data?.mediaItem?.logo || "" }}
                  blurRadius={20}
                >
                  {pageHandler.data?.mediaItem?.logo &&
                  pageHandler.data?.mediaItem?.logo ? (
                    <Image
                      source={{ uri: pageHandler.data?.mediaItem?.logo || "" }}
                      style={{
                        width: scale(42),
                        height: scale(42),
                      }}
                    />
                  ) : (
                    <Avt
                      name={pageHandler.data?.name || ""}
                      source={{ uri: pageHandler.data?.mediaItem?.logo || "" }}
                      size="giant"
                      shape="square"
                      style={{
                        width: scale(42),
                        height: scale(42),
                      }}
                    />
                  )}
                </A.LogoBackground>
                //#endregion
              }
              <>
                {
                  //#region [section2] Title/ title editor
                  titleEdit ? (
                    //* Editable title
                    <View
                      style={{
                        alignItems: "center",
                        marginHorizontal: spacing(3),
                      }}
                    >
                      <Inpuut
                        value={title}
                        onChangeText={setTitle}
                        numberOfLines={2}
                        onSavePress={() => {
                          console.log("this button is clicked");
                          setTitleEdit(titleEdit == true ? false : true);
                          pageHandler.update({ name: title });
                        }}
                      />
                      <Txt.Helper marginV>{tr("Enter to save")}</Txt.Helper>
                    </View>
                  ) : (
                    <>
                      <A.TxtItemName hovered={hovered} numberOfLines={2}>
                        {pageHandler.data?.name || "       "}
                      </A.TxtItemName>
                    </>
                  )
                  //#endregion
                }
              </>
              <Kitten.Divider_________ />
              <SectionBottom
                {...props}
                pageHandler={pageHandler}
                userProfile={userProfile}
                titleEdit={titleEdit}
                setTitleEdit={setTitleEdit}
                setTitle={setTitle}
                onRemoveItem={onRemoveItem}
                onMoveFrom={onMoveFrom}
              />
            </A.PressableContainer>
          )}
        </Pressable>
      );
    } catch (error) {
      //
      throw Error(`::TWVkaWFIb21lSXRlbQ==::${error}`);
    }
  }, [
    pageHandler.data,
    editModeEnabled,
    itemIndexSelected,
    addEntryClick,
    userProfile,
    C,
  ]);
}

const SectionBottom = (props: dSectionBottom) => {
  const {
    pageHandler,
    userProfile,
    titleEdit,
    setTitleEdit,
    setTitle,
    onRemoveItem: onRemoveBS,
    onMoveFrom,
  } = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();

  //#region [functions] MEDIA-PICKER
  const [avatar, setAvatar] = React.useState("");
  const [mediaProgress, setMediaProgress] = React.useState<number>(0);
  async function cameraPermission() {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        Toasty.show(
          tr("Sorry, we need camera roll permissions to make this work!"),
          { type: "danger", duration: 10000 }
        );
      }
    }
  }

  async function onPickImage() {
    await cameraPermission();
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    // console.log(result); //* e.g. "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="
    if (!result.cancelled) {
      // let _mediaType = fn.media.extractMediaType(result.uri);
      let _mediaType = fn.media.extractMediaType(result.uri);

      if (_mediaType !== "image" && _mediaType !== "video") {
        Toasty.show("Unknown file type.", { type: "danger" });
        return null;
      } else {
        Poppy(
          "Final Check",
          "Are you sure you want to change this thumbnail?",
          {
            text: "Cancel",
            onPress: () => {},
          },
          {
            text: "Yes",
            onPress: () => uploadMedia(result?.uri, _mediaType),
          }
        );

        // setSaveVisibility(true);
      }
    }
  }

  function uploadMedia(url: string, type: "video" | "image") {
    if (!type) type = fn.media.extractMediaType(url);
    setAvatar(url);

    fn.media
      .uploadMedia(
        url,
        {
          folder: `${CLOUDINARY_FOLDER.CLIENTS}/${pageHandler.data?._teamId}/${pageHandler.data?._id}`,
          title: "basehop_" + pageHandler.data?.id,
          type,
        },
        (progress) => {
          setMediaProgress(progress);
        },
        (response) => {
          pageHandler.update({
            mediaItem: {
              ...pageHandler.data?.mediaItem,
              logo: response.url,
            },
          });

          console.log("item has been updated");
        }
      )
      .catch((error) => {
        console.warn("Error updating avt: ", error);
        setAvatar(null);
        // return null;
      });
  }

  function onRemoveLogo() {
    Poppy(
      "Final Check",
      "Are you sure you want to remove this photo?",
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () =>
          pageHandler.update({
            mediaItem: {
              ...pageHandler.data?.mediaItem,
              logo: "",
            },
          }),
      }
    );
  }
  //#endregion

  //#region [section] BS Item Password handlers
  function onViewPassword() {
    Navigation.dialogPortal.open({
      headerTitle: "Password this team",
      render: (
        <Kitten.Card style={{ flex: 1, flexGrow: 1 }}>
          <Txt.P1 style={{ textAlign: "center" }}>
            Password to this Base Shop is
          </Txt.P1>
          <Txt.S1 style={{ textAlign: "center" }}>
            {tr(pageHandler.data?.password)}
          </Txt.S1>
          <Kitten.Divider_________ />
          <Buttoon
            appearance={"ghost"}
            onPress={() => {
              Poppy(
                "Final Check",
                "Are you sure you want to remove the password?",
                {
                  text: "Cancel",
                  onPress: () => {},
                },
                {
                  text: "Yes",
                  onPress: () => {
                    pageHandler.update({
                      password: "",
                    });
                    Toasty.show(tr("Password removed successfully"), {
                      type: "success",
                    });
                  },
                }
              );
            }}
          >
            {tr("Remove password")}
          </Buttoon>
        </Kitten.Card>
      ),
    });
  }

  function onAddPassword() {
    Navigation.dialogPortal.open({
      headerTitle: "Password this team",
      render: (
        <View style={{ flex: 1, flexGrow: 1 }}>
          <S_PageItemAddPassword pageHandler={pageHandler} />
        </View>
      ),
    });
  }
  //#endregion

  //#region [section2] BS Owner handlers
  function onAssignOwner() {
    Navigation.dialogPortal.open({
      headerTitle: "Assign leader to team",
      render: (
        <Kitten.Card disabled style={{ flex: 1, flexGrow: 1 }}>
          <AssignUserBS pageHandler={pageHandler} />
        </Kitten.Card>
      ),
    });
  }
  //#endregion

  //#region [section] Color handler
  function onChangeColor() {
    Navigation.dialogPortal.open({
      headerTitle: "Set color for this base shop",
      render: (
        <Kitten.Card disabled>
          <PickyColor
            color={pageHandler.data?.mediaItem?.color}
            onSubmit={(color) => {
              Toasty.show(tr("What's a dope color choice!"), {
                type: "success",
              });
              pageHandler
                .update({
                  mediaItem: { ...pageHandler.data?.mediaItem, color: color },
                })
                .then(() => {
                  Navigation.goBack();
                });
            }}
          />
        </Kitten.Card>
      ),
    });
  }
  //#endregion

  //#region [section2] input bs
  React.useEffect(
    function setupEditableRole() {
      pageHandler.data &&
        _.isEmpty(pageHandler.data?.rolesThatCanEdit) &&
        pageHandler.update({
          rolesThatCanEdit: firebase.firestore.FieldValue.arrayUnion(
            `BS:${pageHandler.data?._id}`
          ),
        });
    },
    [pageHandler.data]
  );

  const InputEditableRole = () => (
    <>
      {userProfile._isRolesContain([FRBS_ROLE.DEVELOPER]) && (
        <View style={{ borderWidth: 1, borderColor: C.devPink }}>
          {!_.isEmpty(pageHandler.data?.rolesThatCanEdit) ? (
            <Txt>{`✅ Editable by ${JSON.stringify(
              pageHandler.data?.rolesThatCanEdit
            )}`}</Txt>
          ) : (
            <Txt>Loading</Txt>
          )}
        </View>
      )}
    </>
  );
  //#endregion
  return (
    <A.ViewBottom>
      {"debug" === "nah" && <InputEditableRole />}
      {
        /** If item has password, a lock icon will show up,
         * else, a transparent icon will be there to keep the UI layout
         */
        pageHandler.data?.password && pageHandler.data?.password ? (
          <IconPrimr
            preset="circular"
            name="lock"
            color={C["color-warning-500"]}
            containerStyle={{
              backgroundColor: C["color-warning-transparent-500"],
            }}
          />
        ) : (
          <IconPrimr
            preset="circular"
            name="placeholder"
            color={"transparent"}
          />
        )
      }
      {
        //#region [section] for developer
        userProfile._isDeveloper() ? (
          <>
            {
              titleEdit ? (
                <Buttoon
                  status="danger"
                  size="tiny"
                  onPress={() => {
                    setTitleEdit(false);
                    setTitle(pageHandler.data?.name);
                  }}
                >
                  {tr("Cancel")}
                </Buttoon>
              ) : (
                <Menoo
                  trigger={{
                    name: "dots_horizontal",
                    size: scale(10),
                    preset: "circular",
                    color: C.text,
                  }}
                  options={[
                    pageHandler.data?.password && pageHandler.data?.password
                      ? {
                          text: tr("View Password"),
                          onSelect: onViewPassword,
                        }
                      : {
                          text: tr("Add Password"),
                          onSelect: onAddPassword,
                        },
                    {
                      text: tr("Assign owner to base shop"),
                      onSelect: onAssignOwner,
                    },
                    { text: Menoo.Divider },
                    // {
                    //   text: tr("Move Base Shop"),
                    //   onSelect: onMoveFrom,
                    // },
                    {
                      text: tr("Edit Title"),
                      onSelect: () => {
                        setTitle(pageHandler.data?.name);
                        setTitleEdit(true);
                      },
                    },
                    {
                      text: tr("Edit Logo"),
                      onSelect: onPickImage,
                    },
                    pageHandler.data?.mediaItem?.logo
                      ? {
                          text: tr("Remove Logo"),
                          onSelect: onRemoveLogo,
                        }
                      : null,
                    {
                      text: tr("Edit Color"),
                      onSelect: onChangeColor,
                    },
                    { text: Menoo.Divider },
                    {
                      text: tr("Broadcasting a message"),
                      onSelect: () => {
                        Toasty.show(
                          tr("Broadcasting a message (Coming Soon)"),
                          {
                            type: "success",
                            duration: 10000,
                          }
                        );
                      },
                    },
                    {
                      text: tr("Collaboration"),
                      onSelect: () => {
                        Toasty.show(tr("Collaboration (Coming Soon)"), {
                          type: "success",
                          duration: 10000,
                        });
                      },
                    },
                    { text: Menoo.Divider },
                    {
                      text: tr("Remove Base Shop"),
                      color: C.errorRed,
                      onSelect: onRemoveBS,
                    },
                  ]}
                />
              )
              //#endregion
            }
          </>
        ) : (
          //#region [section2] bs owner
          userProfile._isBaseShopOwner(`BS:${pageHandler.data?.id}`) && (
            <>
              {
                titleEdit ? (
                  <Buttoon
                    status="danger"
                    size="tiny"
                    onPress={() => {
                      setTitleEdit(false);
                      setTitle(pageHandler.data?.name);
                    }}
                  >
                    {tr("Cancel")}
                  </Buttoon>
                ) : (
                  <Menoo
                    trigger={{
                      name: "dots_horizontal",
                      size: scale(10),
                      preset: "circular",
                      color: C.text,
                    }}
                    options={[
                      pageHandler.data?.password && pageHandler.data?.password
                        ? {
                            text: tr("View Password"),
                            onSelect: onViewPassword,
                          }
                        : {
                            text: tr("Add Password"),
                            onSelect: onAddPassword,
                          },
                      {
                        text: tr("Edit Title"),
                        onSelect: () => {
                          setTitle(pageHandler.data?.name);
                          setTitleEdit(true);
                        },
                      },
                      {
                        text: tr("Edit Logo"),
                        onSelect: onPickImage,
                      },
                      pageHandler.data?.mediaItem?.logo
                        ? {
                            text: tr("Remove Logo"),
                            onSelect: onRemoveLogo,
                          }
                        : null,
                      {
                        text: tr("Edit Color"),
                        onSelect: onChangeColor,
                      },
                      { text: Menoo.Divider },
                      {
                        text: tr("Remove Base Shop"),
                        color: C.errorRed,
                        onSelect: onRemoveBS,
                      },
                    ]}
                  />
                )
                //#endregion
              }
            </>
          )
        )
      }
    </A.ViewBottom>
  );
};

const A = {
  DotNeweResource: sstyled(View)({
    width: scale(16),
    height: scale(16),
    borderRadius: 30,
    backgroundColor: "errorRed",
  }),
  PressableContainer: sstyled(Pressable)({
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "surface",
    borderRadius: scale(4),
    overflow: "hidden",
  }),
  LogoBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    padding: spacing(4),
  }),
  TxtItemName: styled(Txt.H5)(({ hovered }) => ({
    height: scale(30),
    textAlign: "center",
    color: hovered ? "primary" : "text",
  })),
  ViewBottom: sstyled(Kitten.ViewH)({
    width: "90%",
    justifyContent: "space-between",
    margin: spacing(3),
  }),
  PressableMoveToPlaceholder: sstyled(Buttoon)({
    width: "100%",
    height: scale(50),
    borderColor: "color-info-700",
    borderWidth: 2,
    borderStyle: "dotted",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: spacing(3),
  }),
  ViewItemPressPrevention: sstyled(View)({
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
  }),
};

interface dBSHomeItem {
  index: number;
  cpid: MediaPageSchema["_id"];
  onPress(): void;
  addEntryClick(item): void;
  editModeEnabled: boolean;
  onEditCallback(): void;
}

interface dSectionBottom extends dBSHomeItem {
  pageHandler: dUseDocumentReturns<MediaPageSchema>;
  userProfile: UserSchemaWithCustomFunc;
  titleEdit: boolean;
  setTitleEdit: Dispatch<SetStateAction<boolean>>;
  setTitle: Dispatch<SetStateAction<string>>;
  onRemoveItem(): void;
  onMoveFrom(): void;
  itemColor: string;
}
