//#region [import]
import { useFocusEffect } from "@react-navigation/native";
import { CustomPageItem, Kitten, sstyled, Txt } from "components";
import { useTwoColumns } from "components/templates";
import { MediaPageItemSchema, MediaPageSchema } from "engines";
import {
  dUseDocumentReturns,
  useMemberDocument,
  usePageContentsCollection
} from "engines/firebase";
import { useAppContext } from "engines/hooks";
import { useCollabContext } from "engines/providers/collaboration-provider";
import React from "react";
import { FlatList, View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";
import { spacing } from "../../../utils/styles/spacing";
//#endregion

/**
 * ### Custom page body
 *  - Containing a list of CustomPageItem
 *  ----
 *  @version 21.09.05
 *  -  *Memoize it*
 *  @author  K
 *
 **/
export function CollabPageBody(props: dCollabPageBody) {
  const { cpid, pageHandler, title } = props;
  const collabMode = true;
  const { column1ContainerStyle } = useTwoColumns();
  const refRenderCount = React.useRef(0);
  const { frbsUser } = useAppContext();
  const Navigation = useNavigator();

  //#region [section] handlers
  const pageContentsHandler = usePageContentsCollection(cpid);
  const memberHandler = useMemberDocument(frbsUser?.uid);
  //#endregion

  const {
    setIsCollabModeOn,
    isCollabModeOn,
    isSelectModeOn,
    selectAll,
    setSelectAll,
    pageContentItems,
    setPageContentItems,
    setIsSelectModeOn,
  } = useCollabContext();

  //#region [section 2] for collaboration
  // React.useEffect(() => {
  //   // console.log("😎 props in CustomPageBody ", collabMode);
  //   setIsCollabModeOn(collabMode ? collabMode : false);
  // }, [collabMode]);
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    // console.log("resource cpid: ", cpid);
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section2] Select all ops
  React.useEffect(
    function setupSelectAll() {
      if (selectAll) {
        if (pageContentItems?.length !== pageContentsHandler.data?.length) {
          const tempPageContentIds: MediaPageItemSchema[] = [];
          pageContentsHandler.data?.forEach((item, index) => {
            if (
              item._id &&
              !tempPageContentIds.find((p) => p._id === item._id)
            ) {
              tempPageContentIds.push(item as MediaPageItemSchema);
            }
          });
          setPageContentItems(tempPageContentIds);
        }
      } else {
        if (
          !(
            pageContentItems?.length < pageContentsHandler.data?.length &&
            pageContentItems?.length > 0
          )
        )
          setPageContentItems([]);
      }
    },
    [selectAll]
  );

  React.useEffect(
    function () {
      if (pageContentItems?.length === pageContentsHandler.data?.length) {
        // setSelectAll(true);
      } else if (
        pageContentItems?.length < pageContentsHandler.data?.length &&
        pageContentItems?.length > 0
      ) {
        setSelectAll(false);
      }
    },
    [pageContentItems]
  );
  //#endregion

  React.useEffect(() => {
    setIsCollabModeOn(true);
    if (props.title) {
      Navigation.setOptions({ title: title });
    }
  }, []);

  const ViewFeedHeader = () => (
    <View>
      <Txt.Debug>{`# of re-render: ${refRenderCount.current++}`}</Txt.Debug>
      {
        <View
          style={[
            { flexDirection: "row", flex: 1, justifyContent: "flex-start" },
          ]}
        >
          {isSelectModeOn && (
            <Kitten.CheckBox
              checked={
                selectAll ||
                (pageContentsHandler.data &&
                  pageContentItems.length === pageContentsHandler.data?.length)
              }
              onChange={() => {
                setSelectAll(!selectAll);
              }}
              style={{ transform: [{ scale: 1.166 }], margin: spacing(4) }}
            >
              {!selectAll ? "Select All" : "Unselect All"}
            </Kitten.CheckBox>
          )}
        </View>
      }
    </View>
  );

  return React.useMemo(() => {
    try {
      return (
        <FlatList
          contentContainerStyle={[
            column1ContainerStyle,
            { margin: 3, minWidth: 300 },
          ]}
          ItemSeparatorComponent={Kitten.Divider}
          ListHeaderComponent={ViewFeedHeader()}
          ListEmptyComponent={() => (
            <Txt.Indicator marginV>
              {pageContentsHandler.loading
                ? tr("Loading...")
                : tr("This page is empty")}
            </Txt.Indicator>
          )}
          data={pageContentsHandler.data}
          renderItem={({ item, index }) => (
            <A.ViewContainer>
              {isSelectModeOn && (
                <Kitten.CheckBox
                  checked={!!pageContentItems.find((p) => p._id === item._id)}
                  onChange={() => {
                    if (!!pageContentItems.find((p) => p._id === item._id)) {
                      setPageContentItems([
                        ...pageContentItems.filter((p) => p._id !== item._id),
                      ]);
                    } else {
                      if (pageContentItems.length === 0) {
                        setPageContentItems([item]);
                      } else {
                        setPageContentItems([...pageContentItems, item]);
                      }
                    }
                  }}
                />
              )}
              <View style={{ flex: 1 }}>
                <CustomPageItem
                  key={item?._id}
                  memberHandler={memberHandler}
                  // configKeysHandler={configKeysHandler}
                  // levelPageProgress={levelPageProgress}
                  // onCompleteLevelItem={completeLevelItem}
                  pageHandler={pageHandler}
                  pageContentsHandler={pageContentsHandler}
                  onReachedPageCompletion={() => {}}
                  cpid={cpid}
                  pcid={item?._id}
                  item={item}
                  index={index}
                  movingModeEnabled={false}
                  onEditCallback={() => {}}
                  collabMode
                  //@ts-expect-error We do not use this in CollaPageBody
                  addEntryClick={() => {}}
                  shouldEditContent={false}
                />
              </View>
            </A.ViewContainer>
          )}
          keyExtractor={(item) => item._id}
        />
      );
    } catch (error) {
      throw Error(`::Q3VzdG9tUGFnZUJvZHk=::${error}`);
    }
  }, [
    pageContentsHandler.data,
    isSelectModeOn,
    selectAll,
    pageContentItems,
    isCollabModeOn,
  ]);
}

const A = {
  ViewContainer: sstyled(Kitten.ViewH)({
    flex: 1,
  }),
  ViewCheckbox: sstyled(View)({
    margin: spacing(3),
    justifyContent: "center",
    transform: [{ scale: 1.166 }],
  }),
};

export interface dCollabPageBody {
  cpid: string;
  pageHandler: dUseDocumentReturns<MediaPageSchema>;
  title: string;
  selectModeOn: boolean;
  collabMode?: boolean;
}
