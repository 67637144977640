import { useFocusEffect } from "@react-navigation/native";
import { Buttoon, Kitten, Skeleton, sstyled, Txt } from "components/atoms";
import { ScorerItem } from "components/molecules";
import { ScoreEditor } from "components/molecules/scoreboard/ScoreEditor";
import { useTwoColumns } from "components/templates";
import {
  FRBS_ROLE,
  getCustomFields,
  ScoreboardSchema,
  useAppContext
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useScoreboardDocument } from "engines/firebase/handler/scoreboard-handler";
import firebase from "firebase";
import * as R from "ramda";
import React from "react";
import { ScrollViewProps, View } from "react-native";
import Animated from "react-native-reanimated";
import { useNavigator } from "screens/_navigation";
import { dColors, scale, spacing, tr } from "utils";
import {
  doesSubmissionHaveDuplicates,
  doesSubmissionMeetRequirements,
  getFormSubmissions,
  GHLFormSubmission
} from "../../../engines/backends/apis/go-high-level/forms";
import { getAccessToken } from "../../../engines/backends/apis/go-high-level/tokenHandler";
import { APIS, stripBrackets } from "../../../engines/backends/schemas";
import { dConfigKeys } from "../../../engines/backends/schemas/configs/configs.schema";
import { dScore } from "../../../engines/backends/schemas/scoreboards/scoreboard.schema";
import { useConfigDocument } from "../../../engines/firebase/handler/config-handler";
import Toasty from "../../atoms/generals/toasty/Toasty";
import { ScoreboardInfo } from "./ScoreboardInfo";
import { ScoreCreatorCenter } from "./ScoreCreatorCenter";

/**
 * ### List of specific scoreboard
 *  - Including some quick actions as an accordion item
 *  ----
 *  @version 21.03.19
 *  -  *Document it*
 *  @author  K
 *
 **/
export function ScoreboardCenter(props: dScoreboardCenter) {
  const { sbid, scrollHandler = () => {}, filterDays } = props;
  const { teamId } = useAppContext();
  const Navigation = useNavigator();
  const {
    column1ContainerStyle,
    breakpointForTwoColumns,
    ViewCol1BottomSpace,
  } = useTwoColumns();
  const scoreboardHandler = useScoreboardDocument(sbid);
  const { data, error } = scoreboardHandler;
  const userProfile = useUserProfile();
  const configHandler = useConfigDocument(`keys-${teamId}`);

  //#region [ANM]

  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  React.useLayoutEffect(
    function setupHeader() {
      Navigation.setOptions({
        title: data?.title + " - Scoreboard" || "Scoreboard",
        headerTitle: data?.title || "Scoreboard",
      });
    },
    [Navigation, data]
  );
  //#endregion

  const refLastFilterDays = React.useRef<number>(filterDays);
  React.useEffect(
    function filterDaysChanged() {
      if (refLastFilterDays.current !== filterDays) {
        refLastFilterDays.current = filterDays;
        loadForms(filterDays);
      }
    },
    [filterDays]
  );

  const [memberID, setMemberID] = React.useState<string>(null);
  const [sortedScorers, setSortedScorers] =
    React.useState<ScoreboardSchema["people"]>(null);

  //#region [section2]  Scoreboard sorting
  // let sortByScore = R.sortWith([R.descend(R.prop("score"))]);
  // if (data?.people) {
  //   let _people = data.people;
  //   let _sortedPeople = sortByScore(_people) as ScoreboardSchema["people"];
  //   setSortedScorers(_sortedPeople);
  // } else {
  //   setSortedScorers([]);
  // }

  const refDidLoadBillSubmissions = React.useRef<boolean>(false);
  const refDidLoadAppointmentsSet = React.useRef<boolean>(false);
  const refDidLoadSales = React.useRef<boolean>(false);

  const sortByScore = R.sortWith([R.descend(R.prop("score"))]);
  React.useEffect(
    function sortScore() {
      // let sortByScore = R.sortWith([R.descend(R.prop("score"))]);
      if (data) {
        // @ts-ignore
        const apiTo: APIS = data.apiTo;
        if (apiTo && apiTo?.length > 0) {
          // SETUP SCORES FROM API HERE!
          if (apiTo === APIS.GO_HIGH_LEVEL_APPOINTMENTS_SET) {
            if (!refDidLoadAppointmentsSet.current) {
              refDidLoadAppointmentsSet.current = true;
              loadForms(filterDays);
            }
            // loadAppointmentsSet();
          } else if (apiTo === APIS.GO_HIGH_LEVEL_POWER_BILLS) {
            if (!refDidLoadBillSubmissions.current) {
              refDidLoadBillSubmissions.current = true;
              loadForms(filterDays);
            }
            // loadPowerBillSubmissions();
          } else if (apiTo === APIS.GO_HIGH_LEVEL_SALES) {
            if (!refDidLoadSales.current) {
              refDidLoadSales.current = true;
              loadContacts(APIS.GO_HIGH_LEVEL_SALES, filterDays);
            }
          }
        }
        let _people = data.people;
        // alert(JSON.stringify(_people));
        let _sortedPeople = _people
          ? (sortByScore(_people) as ScoreboardSchema["people"])
          : ([] as ScoreboardSchema["people"]);
        setSortedScorers(_sortedPeople);
      } else {
        // setSortedScorers([]);
      }
    },
    [data, configHandler.data]
  );

  /**
   * TODOs:
   * Have getAccessToken use a cloud function that only returns a access_token, and make sure the new refresh_token gets saved in firestore before returning the access_token to the user
   * Make loadForms a function that can be called from anywhere to turn forms into scores, this way ScoreboardCards on the home screen can use this as well
   * Use discussed filtering options
   * Show stats in profile
   */

  /**
   * Loads all contacts relevent to calculating scores for this scoreboard and updates the scoreboard accordingly
   * - leave days as 0 to include all contacts
   */
  async function loadContacts(api, days = 0) {
    const scoreToasty = Toasty.show("Updating scores...", {
      type: "loading",
    });

    if (api === APIS.GO_HIGH_LEVEL_SALES) {
      firebase
        .functions()
        .httpsCallable("updateAPIScoresIfNeeded")({
          _sbid: sbid,
          days,
        })
        .then((response) => {
          const potentialScoreboard: ScoreboardSchema | { error: any } =
            response.data;
          Toasty.hide(scoreToasty);
          if (potentialScoreboard.error) {
            Toasty.show(potentialScoreboard.error, { type: "danger" });
          } else {
            setSortedScorers((potentialScoreboard as ScoreboardSchema).people);
            Toasty.show("Scoreboard updated!", { type: "success" });
          }
        });
    } else {
      Toasty.hide(scoreToasty);
      Toasty.show("Unknown api type " + api);
    }
  }

  async function loadForms(days = 0) {
    const scoreToasty = Toasty.show("Updating scores...", {
      type: "loading",
    });

    firebase
      .functions()
      .httpsCallable("updateAPIScoresIfNeeded")({
        _sbid: sbid,
        days,
      })
      .then((response) => {
        // alert(JSON.stringify(response))
        const potentialScoreboard: ScoreboardSchema | { error: any } =
          response.data;
        Toasty.hide(scoreToasty);
        if (potentialScoreboard.error) {
          Toasty.show(potentialScoreboard.error, { type: "danger" });
        } else {
          setSortedScorers((potentialScoreboard as ScoreboardSchema).people);
          Toasty.hide(scoreToasty);
          Toasty.show("Scoreboard updated!", { type: "success" });
        }
      })
      .catch((err) => {
        console.error(err);
        Toasty.hide(scoreToasty);
        Toasty.show(`${err}`, { type: "danger" });
      });
  }

  /**
   * @deprecated handled by cloud functions now
   * Loads all the forms with the provided form type and credits scores to users on the scoreboard accordingly
   * - formType is an enum string from APIS.
   */
  async function _loadForms(formType, days = 0) {
    let dateLimit: Date | null = null;
    if (days > 0) {
      const oneDay = 86400 * 1000;
      dateLimit = new Date();
      dateLimit.setTime(dateLimit.getTime() - days * oneDay);
    }
    try {
      // @ts-ignore
      const configKeys: dConfigKeys = configHandler.data;
      const apis = configKeys?.apis;
      if (!apis || !apis?.goHighLevelAccounts) {
        Toasty.show(`Api info not found.`, { type: "danger" });
        return;
      }
      const scoreToasty = Toasty.show("Updating scores...", {
        type: "loading",
      });
      // let allFormSubmissions: GHLFormSubmission[] = [];
      const scores: dScore[] = [];
      for (let g in apis?.goHighLevelAccounts) {
        const ghlAccount = apis?.goHighLevelAccounts[g];
        // const token = await getAccessToken(ghlAccount, ghlAccount.lastToken);
        const token = await getAccessToken(teamId, ghlAccount);
        if (!token || !token.access_token) {
          // alert("NO TOKEN IN RESPONSE: " + JSON.stringify(token));
          alert("No api token available.");
          return;
        }

        const ghlForms = ghlAccount.forms || [];

        // should this await or keep going for efficiency?
        // await updateToken(teamId, ghlAccount.locationId, token);
        let submissions: GHLFormSubmission[] = [];
        for (let _i in ghlForms[formType]?.formIds) {
          const _formId = ghlForms[formType]?.formIds[_i];
          const _formSubmissions = await getFormSubmissions(
            ghlAccount,
            token,
            _formId,
            dateLimit || undefined
          );
          if (!_formSubmissions) {
            console.warn(`Something went wrong loading form submissions...`);
            continue;
          }
          submissions = submissions.concat(_formSubmissions);
        }
        if (submissions.length < 1) {
          console.warn("0 submissions fetched. Moving on...");
          continue;
        }
        /**
         * Get all the custom fields to determine who gets credit for the submissions
         */
        const customFields = await getCustomFields(ghlAccount);
        // console.log(`CUSTOM FIELDS: \n${JSON.stringify(customFields)}`);
        const emailFieldId =
          customFields.find(
            (f) => f.fieldKey === stripBrackets(ghlForms[formType]?.emailKey)
          )?.id || "email";
        const phoneFieldId =
          customFields.find(
            (f) => f.fieldKey === stripBrackets(ghlForms[formType]?.phoneKey)
          )?.id || "phone";
        const firstNameFieldId =
          customFields.find(
            (f) =>
              f.fieldKey === stripBrackets(ghlForms[formType]?.firstNameKey)
          )?.id || "firstName";
        const lastNameFieldId =
          customFields.find(
            (f) => f.fieldKey === stripBrackets(ghlForms[formType]?.lastNameKey)
          )?.id || "lastName";
        /**
         * Loop through all the form submissions and start updating the scores array
         */
        // submissions that have been counted as a score
        // const countedSubmissions: GHLFormSubmission[] = [];
        let sidx = -1;
        for (let s in submissions) {
          ++sidx;
          const submission = submissions[s];
          if (!submission) {
            console.warn(`Some form submission is null. Skipping.`);
            continue;
          }
          const name =
            submission[firstNameFieldId] + " " + submission[lastNameFieldId]; //+` (${submission[phoneFieldId]})`;identifier
          const submissionIdentifier =
            submission[phoneFieldId]?.length > 0
              ? submission[phoneFieldId]
              : submission[emailFieldId];
          const preExistingScore = scores.find(
            (s) => s.identifier === submissionIdentifier
          );
          // checks to make sure any necessary "requiredFields" (see apis.ts), have data before counting it as a point
          const submissionMeetsRequirements = doesSubmissionMeetRequirements(
            submission,
            formType,
            customFields
          );
          if (preExistingScore && submissionMeetsRequirements) {
            const isDuplicate = doesSubmissionHaveDuplicates(
              submission,
              submissions.filter((_s, idx) => idx < sidx),
              customFields,
              formType
            );
            if (!isDuplicate) preExistingScore.score += 1;
          } else if (submissionMeetsRequirements) {
            const uid =
              sortedScorers?.find((a) => a.identifier === submissionIdentifier)
                ?._id || "nonUser";
            scores.push({
              name,
              uid,
              score: 1,
              identifier: submissionIdentifier,
            });
          }
        }
      }
      //@ts-ignore
      const __sortedScores = sortByScore(scores);
      //@ts-ignore
      setSortedScorers(__sortedScores);
      //@ts-ignore (will only save the first time cuz we only want the default filter to save to firestore)
      !sortedScorers && scoreboardHandler.update({ people: __sortedScores });
      Toasty.update(scoreToasty, "Scores up to date.", { type: "success" });
    } catch (err) {
      console.error(err);
      Toasty.show(err, { type: "danger" });
    }
  }

  // async function loadAppointmentsSet() {
  //   if (refDidLoadAppointmentsSet.current) return;
  //   refDidLoadAppointmentsSet.current = true;
  //   try {
  //     // @ts-ignore
  //     const configKeys: dConfigKeys = configHandler.data;
  //     const apis = configKeys?.apis;
  //     if (!apis || !apis?.goHighLevelAccounts) {
  //       Toasty.show(`Api info not found.`, { type: "danger" });
  //       return;
  //     }
  //     let allAppointments: GHLAppointment[] = [];
  //     for (let g in apis?.goHighLevelAccounts) {
  //       const ghlAccount = apis?.goHighLevelAccounts[g];
  //       let token = await getAccessToken(ghlAccount, ghlAccount.lastToken);
  //       // should this await or keep going for efficiency?
  //       await updateToken(teamId, ghlAccount.locationId, token);
  //       const { calendars } = await getCalendars(ghlAccount, token);
  //       token = await getAccessToken(ghlAccount, token);
  //       await updateToken(teamId, ghlAccount.locationId, token);
  //       const appointments = await getAllAppointmentsSet(
  //         token,
  //         ghlAccount.apiKey,
  //         calendars
  //       );
  //       allAppointments = allAppointments.concat(appointments);
  //     }
  //     console.log(`APPOINTMENTS:\n${JSON.stringify(allAppointments)}`);
  //     const scores: dScore[] = [];
  //     for (let a in allAppointments) {
  //       const appt = allAppointments[a];
  //       const score: dScore = {
  //         name: appt.contact.firstName + " " + appt.contact.lastName,
  //         score: 1,
  //         uid: "nonUser",
  //       };
  //       scores.push(score);
  //     }
  //     setSortedScorers(scores);
  //   } catch (err) {
  //     console.error(err);
  //     Toasty.show(err, { type: "danger" });
  //   }
  // }

  //#endregion
  try {
    if (error || R.isNil(sbid)) {
      console.warn("error in ScoreboardCenter: ", error);
      return (
        <Txt.Indicator>{tr("Error loading this scoreboard")}</Txt.Indicator>
      );
    }
    if (!data) return <Skeleton {...Skeleton.Screen.Members} />;

    const ViewFeedHeader = () => (
      <View>
        {!breakpointForTwoColumns && (
          <>
            <A.TxtDescription>
              {scoreboardHandler.data?.subtitle || tr("No description")}
            </A.TxtDescription>
            {userProfile._isRolesContain([
              FRBS_ROLE.ADMIN,
              FRBS_ROLE.DEVELOPER,
            ]) ? (
              <Buttoon
                size={"tiny"}
                appearance={"ghost"}
                onPress={() => {
                  Navigation.dialogPortal.open({
                    render: (
                      <View style={{ flex: 1 }}>
                        <ScoreboardInfo
                          sbid={sbid}
                          isEditing={true}
                          from={"sboard-scr"}
                          onChangeFilterDays={(days) => {
                            if (data.apiTo === APIS.GO_HIGH_LEVEL_SALES) {
                              loadContacts(APIS.GO_HIGH_LEVEL_SALES, days);
                            } else if (
                              data.apiTo === APIS.GO_HIGH_LEVEL_POWER_BILLS ||
                              data.apiTo === APIS.GO_HIGH_LEVEL_APPOINTMENTS_SET
                            ) {
                              loadForms(days);
                            }
                          }}
                        />
                      </View>
                    ),
                    headerTitle: "Edit " + scoreboardHandler.data?.title,
                  });
                }}
              >
                {tr("Edit")}
              </Buttoon>
            ) : null}
          </>
        )}
      </View>
    );

    return (
      <Animated.FlatList
        scrollEventThrottle={16}
        onScroll={scrollHandler}
        ListHeaderComponent={ViewFeedHeader()}
        ListFooterComponent={ViewCol1BottomSpace}
        data={sortedScorers || []}
        contentContainerStyle={[
          column1ContainerStyle,
          {
            marginTop: spacing(5),
            borderRadius: scale(9),
            overflow: "hidden",
          },
        ]}
        renderItem={({ item: scorer, index }) => (
          <>
            {/* <Txt>{JSON.stringify(scorer)}</Txt> */}
            <ScorerItem.Existing
              index={index}
              key={`${scorer.identifier}${scorer.uid}`}
              sbid={data?._sbid}
              scorer={scorer}
              onPress={() =>
                setMemberID(memberID === scorer.uid ? null : scorer.uid)
              }
              style={{ backgroundColor: "transparent" }}
            />
          </>
        )}
        keyExtractor={(item) => `${item.identifier}${item.uid}`}
        ItemSeparatorComponent={Kitten.Divider}
        ListEmptyComponent={() => (
          <View>
            <Txt.Indicator marginV>
              {scoreboardHandler.loading
                ? tr("Loading...")
                : tr("Scoreboard is empty")}
            </Txt.Indicator>
            {userProfile._isRolesContain([
              FRBS_ROLE.ADMIN,
              FRBS_ROLE.DEVELOPER,
            ]) ? (
              <Buttoon
                appearance={"ghost"}
                icon={{ name: "plus" }}
                onPress={() => {
                  Navigation.overlayPortal.open({
                    render: <ScoreCreatorCenter sbid={sbid} />,
                    headerTitle: tr("Add a score"),
                  });
                }}
              >
                {tr("Add member's score")}
              </Buttoon>
            ) : (
              <Buttoon
                appearance={"ghost"}
                icon={{ name: "plus" }}
                onPress={() => {
                  Navigation.overlayPortal.open({
                    render: (
                      <ScoreEditor
                        personali={userProfile.data?.personali}
                        score={"0"}
                        onSubmitCallback={() => {}}
                        shouldNavigateBack={
                          breakpointForTwoColumns ? false : true
                        }
                      />
                    ),
                    headerTitle: tr("Add your score"),
                  });
                }}
              >
                {tr("Add your score")}
              </Buttoon>
            )}
          </View>
        )}
      />
    );
  } catch (error) {}
}

const A = {
  ViewContainer: sstyled(View)((p) => ({
    backgroundColor: "background",
    flex: 1,
  })),
  Config: (C: dColors) => ({
    List: {
      itemWidth: 160,
      itemHeight: 140,
      separatorSize: 8,
      borderRadius: 10,
      stickyItemWidth: 50,
      stickyItemHeight: 50,
      stickyItemBackgroundColors: [
        C["color-primary-transparent-200"],
        C.primary,
      ],
    },
  }),
  TxtDescription: sstyled(Txt.S1)({
    margin: spacing(3),
    textAlign: "center",
    color: "grey500",
  }),
  TxtAddScore: sstyled(Txt.S2)({
    color: "primary",
    marginVertical: spacing(3),
    textAlign: "center",
    alignSelf: "center",
  }),
};

export interface dScoreboardCenter {
  sbid: string;
  scrollHandler?: ScrollViewProps["onScroll"];
  /**
   * For use with special apis (GHL/Apricot)
   */
  filterDays?: number;
}
