const sampleData = {
  counters: {
    u_column: 6,
    u_row: 4,
    u_content_image: 3,
    u_content_text: 2,
    u_content_button: 1,
  },
  body: {
    rows: [
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px 10px",
                  src: {
                    url: "https://s3.amazonaws.com/unroll-images-production/projects%2F0%2F1627057440983-10808.jpg",
                    width: 7500,
                    height: 5000,
                    autoWidth: false,
                    maxWidth: "60%",
                  },
                  textAlign: "center",
                  altText: "Image",
                  action: {
                    name: "web",
                    values: { href: "", target: "_blank" },
                  },
                  _meta: {
                    htmlID: "u_content_image_1",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "#ffffff",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          hideDesktop: false,
          _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "text",
                values: {
                  containerPadding: "10px",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  _meta: {
                    htmlID: "u_content_text_1",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%;">Hi [Team Member],</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">This is really exciting: Welcome (officially) to the [Team Name] app. We&rsquo;re so lucky to have you.</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">[I/we] are here to help make sure you get the results you expect from [your product or service here], so don&rsquo;t hesitate to reach out with questions. [I&rsquo;d/we&rsquo;d] love to hear from you.</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">To help you get started, [I/we] recommend checking out these resources:</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">[Onboarding resource 1]<br />[Onboarding resource 2]<br />[Onboarding resource 3]<br />&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">If you need support, you can give us a call at [555-555-5555]. [I/we] can talk you through the details and information you need to get started on the right foot.</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">Looking forward to hearing from you,</p>\n<p style="font-size: 14px; line-height: 140%;">[Your company/name]</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>',
                },
              },
            ],
            values: {
              backgroundColor: "#ffffff",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_2", htmlClassNames: "u_column" },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          _meta: { htmlID: "u_row_2", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "10px 10px 0px",
                  src: {
                    url: "https://get-my.app/t/PrimericaTemplate/logo.png",
                    width: 2100,
                    height: 588,
                    autoWidth: false,
                    maxWidth: "32%",
                  },
                  textAlign: "center",
                  altText: "Image",
                  action: {
                    name: "web",
                    values: { href: "", target: "_blank" },
                  },
                  _meta: {
                    htmlID: "u_content_image_3",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
              {
                type: "text",
                values: {
                  containerPadding: "0px 10px 10px",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  _meta: {
                    htmlID: "u_content_text_2",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="color: #00e096; font-size: 14px; line-height: 19.6px;"><a style="color: #00e096;" href="https://apptakeoff.com" target="_blank" rel="noopener" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Imh0dHBzOi8vYXBwdGFrZW9mZi5jb20iLCJ0YXJnZXQiOiJfYmxhbmsifX0=">apptakeoff.com</a></span></p>',
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_6", htmlClassNames: "u_column" },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          _meta: { htmlID: "u_row_4", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      textColor: "#000000",
      backgroundColor: "#e7e7e7",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      contentWidth: "500px",
      contentAlign: "center",
      fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
      preheaderText: "",
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkHoverColor: "#0000ee",
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
    },
  },
  schemaVersion: 6,
};

export default sampleData;


