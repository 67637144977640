import { IconPrimr } from "assets";
import { Buttoon, Kitten, Rush, Txt } from "components/atoms";
import { BroadcastEditor } from "components/organisms/broadcast/BroadcastEditor";
import { useTwoColumns } from "components/templates";
import {
  dNotification,
  fn,
  MediaPageSchema,
  NotificationStatus,
  RECTYPE,
  useAppContext,
  useNotificationCollection,
  useUserProfile
} from "engines";
import { dateAsNumber } from "engines/functions/calendar-functions";
import moment from "moment";
import * as R from "ramda";
import React from "react";
import {
  FlatList,
  ScrollView,
  ScrollViewProps,
  StyleSheet,
  View
} from "react-native";
import Animated from "react-native-reanimated";
import { useNavigator } from "screens";
import { IS_WEB, scale, spacing, tr } from "utils";
import { NotificationComments } from "../notifications/NotificationComments";

// import { NotificationItem } from "../notifications/NotificationFeed";
/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.03.23
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function BroadcastList(props: P) {
  const { bsid, scheduled, scrollHandler = () => {} } = props;

  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();
  const handleNotification = bsid
    ? useNotificationCollection(`${teamId}`, {
        where: [
          ["baseShopId", "==", bsid],
          ["status", "==", scheduled],
        ],
      })
    : useNotificationCollection(`${teamId}`);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  // const shouldLoadComponent = (index) => index === selectedIndex;

  // console.log("data ", bsid);

  function notificationPress(notification: dNotification) {
    Navigation.overlayPortal.open({
      headerTitle: tr("Notification Details"),
      render: <NotificationComments notification={notification} />,
    });
    // Navigation.navigate("notificationComments", { notification });
  }

  if (handleNotification.error) {
    console.log("handleMember.error ", handleNotification.error);
    //* It'll return nothing in prod, only show this text if debug mode is enabled
    return <Txt.Debug>{JSON.stringify(handleNotification.error)}</Txt.Debug>;
  }

  if (!handleNotification.data) {
    // Toasty.show(tr("Error getting your account"), { type: "danger" });
    return <Rush.Bar {...Rush._config.Loading} />;
  }

  let sortedBroadcastList = R.sortWith<dNotification>(
    [
      /** sorting `createdAt` in `desc` */
      R.descend(R.prop("createdAt")),
    ],
    handleNotification?.data
  );

  const SentTab = () => {
    return (
      <Animated.FlatList
        scrollEventThrottle={16}
        onScroll={scrollHandler}
        data={sortedBroadcastList.filter(
          (p) => p.status == "sent" && !p.recurrence
        )}
        renderItem={({ item, index }) => (
          <BroadcastListItem item={item} onPress={notificationPress} />
        )}
        ListEmptyComponent={() => (
          <Txt.Indicator>{tr("Empty Feed")}</Txt.Indicator>
        )}
        ItemSeparatorComponent={() => <Kitten.Divider_________ />}
        keyExtractor={(item) => item?.id}
        contentContainerStyle={bsid ? null : [column1ContainerStyle]}
        ListFooterComponent={ViewCol1BottomSpace}
      />
    );
  };

  const RecurringTab = () => {
    return (
      <FlatList
        data={sortedBroadcastList.filter(
          (p) => p.recurrence && p.status == "scheduled"
        )}
        renderItem={({ item, index }) => (
          <BroadcastListItem item={item} onPress={notificationPress} />
        )}
        ListEmptyComponent={() => (
          <Txt.Indicator>{tr("Empty Feed")}</Txt.Indicator>
        )}
        ItemSeparatorComponent={() => <Kitten.Divider_________ />}
        keyExtractor={(item) => item?.id}
        contentContainerStyle={bsid ? null : [column1ContainerStyle]}
        ListFooterComponent={ViewCol1BottomSpace}
      />
    );
  };

  const UpcomingTab = () => {
    return (
      <FlatList
        data={sortedBroadcastList.filter(
          (p) => !p.recurrence && p.status == "scheduled"
        )}
        renderItem={({ item, index }) => (
          <BroadcastListItem item={item} onPress={notificationPress} />
        )}
        ListEmptyComponent={() => (
          <Txt.Indicator>{tr("Empty Feed")}</Txt.Indicator>
        )}
        ItemSeparatorComponent={() => <Kitten.Divider_________ />}
        keyExtractor={(item) => item?.id}
        contentContainerStyle={bsid ? null : [column1ContainerStyle]}
        ListFooterComponent={ViewCol1BottomSpace}
      />
    );
  };

  try {
    return IS_WEB ? (
      <FlatList
        data={sortedBroadcastList}
        renderItem={({ item, index }) => (
          <BroadcastListItem item={item} onPress={notificationPress} />
        )}
        ListEmptyComponent={() => (
          <Txt.Indicator>
            {handleNotification.loading ? tr("Loading...") : tr("Empty Feed")}
          </Txt.Indicator>
        )}
        ItemSeparatorComponent={() => <Kitten.Divider_________ />}
        keyExtractor={(item) => item?.id}
        contentContainerStyle={bsid ? null : [column1ContainerStyle]}
        ListFooterComponent={ViewCol1BottomSpace}
      />
    ) : (
      <View style={[styles.container, { backgroundColor: C.background }]}>
        <Kitten.TabView
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}
        >
          <Kitten.Tab title={tr("Sent")}>
            <Kitten.Layout>
              <ScrollView
                keyboardShouldPersistTaps="always"
                keyboardDismissMode="none"
              >
                <View style={{ margin: spacing(2) }}>
                  <SentTab />
                </View>
              </ScrollView>
            </Kitten.Layout>
          </Kitten.Tab>
          <Kitten.Tab title={tr("Recurring")}>
            <Kitten.Layout>
              <ScrollView>
                <View style={{ margin: spacing(2) }}>
                  <RecurringTab />
                </View>
              </ScrollView>
            </Kitten.Layout>
          </Kitten.Tab>
          <Kitten.Tab title={tr("Upcoming")}>
            <Kitten.Layout>
              <ScrollView>
                <View style={{ margin: spacing(2) }}>
                  <UpcomingTab />
                </View>
              </ScrollView>
            </Kitten.Layout>
          </Kitten.Tab>
        </Kitten.TabView>
      </View>
    );
  } catch (error) {
    //
    throw Error(`::TWVkaWFIbA67s21672a1lSXRlbQ==::${error}`);
  }
}

interface P {
  bsid?: string;
  scheduled?: NotificationStatus;
  scrollHandler?: ScrollViewProps["onScroll"];
}

export const BroadcastListItem = (props: {
  item: dNotification;
  onPress?: (noti: dNotification) => void;
}) => {
  const { item, onPress } = props;

  const { C, teamId } = useAppContext();

  const [_myTeam, setMyTeam] = React.useState<MediaPageSchema>(null);
  const userProfile = useUserProfile();
  const Navigation = useNavigator();

  var aDay = 24 * 60 * 60 * 1000;
  const today = item.createdAt?.toDate();

  const day = moment(today).fromNow();

  const read =
    dateAsNumber(item?.createdAt) <
    dateAsNumber(userProfile?.data?.notifications?.lastRead);

  const title =
    item?.title && item?.title?.length > 0 ? item?.title : item?.message;
  const description =
    item?.message && item?.message?.length > 0 ? item?.message : null;

  function openEditBroadcast() {
    Navigation.overlayPortal.open({
      headerTitle: `Edit ${item.title}`,
      render: <BroadcastEditor item={item} />,
    });
  }

  /**
   * ###
   *  - Function is used to know if a date is in the future
   *  ----
   *  @example
   *  Copy and paste function is the best...
   *  ----
   *  @version 22.07.25
   *  -  *Brief changelog*
   *  @author  NL
   *
   **/
  function isInTheFuture(date) {
    const today = new Date();

    return date > today;
  }

  return (
    <>
      <Kitten.ListItemv2
        key={item?.id}
        onPress={() => onPress(item)}
        style={{
          marginVertical: spacing(1),
          borderColor: read ? C.grey900 : C.grey600,
          // borderWidth: 1,
          borderRadius: scale(10),
        }}
        title={() => {
          return (
            <View>
              <Txt.H6>{tr(`${title}`)}</Txt.H6>
              <Txt.P2 style={{ fontStyle: "italic" }}>
                {tr(`${item?.recurrence ? " • " + item?.recurrence : ""}`)}
              </Txt.P2>
            </View>
          );
        }}
        description={() => {
          const scheduledAt = (item.timestamp || item.createdAt)?.toDate()
          return (
            <>
              <View style={{ marginVertical: spacing(2) }}>
                <Txt.C2>{description}</Txt.C2>
                {/* <Txt.P2 style={{ fontStyle: "italic" }}>{day}</Txt.P2> */}
                <Txt.P2 style={{ fontStyle: "italic" }}>
                  {fn.calendar.dateFormat(scheduledAt) +
                    " @ " +
                    fn.calendar.timeToAMPMString(scheduledAt)}
                </Txt.P2>
              </View>

              <Kitten.ViewH>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Txt.P1 style={{ color: C.grey500 }}>
                    {tr(item?.comments?.length || "0")}
                  </Txt.P1>
                  <IconPrimr
                    name="chat_bubble"
                    size={scale(14)}
                    color={C.grey500}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {(item.status == NotificationStatus.SCHEDULED &&
                    item.recurrence != RECTYPE.BLANK) ||
                  item.status !== NotificationStatus.SENT ? (
                    <Buttoon
                      icon={{ name: "pencil" }}
                      size={"tiny"}
                      appearance={"ghost"}
                      onPress={() => {
                        openEditBroadcast();
                      }}
                    />
                  ) : null}
                </View>
              </Kitten.ViewH>
            </>
          );
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  kittenCard: { margin: 10, flex: 1 },
  container: {
    flex: 1,
    overflow: "hidden",
  },
  questionValue: {
    overflow: "hidden",
  },
  flatlistButton: {
    overflow: "hidden",
  },
  tabContainer: {
    height: "95%",
    alignItems: "center",
    justifyContent: "center",
  },
});
