import * as Contacts from "expo-contacts";
import { Alert, Platform } from "react-native";
import { LinkURL } from "../web-functions";

// const didShowPolicyPrompt = React.useRef<boolean>(false);
let didShowPolicyPrompt = false;

/**
 * ### Gives android user detailed policy information to please Google
 * - Recommended to use with await
 *
 * @example
 * await androidPrecheckIfNeeded()
 */
export function androidPrecheckIfNeeded(): Promise<void> {
  return new Promise(async (resolve, reject) => {
    const { status: precheck } = await Contacts.getPermissionsAsync();
    // Make Google happy by showing this message to android users before starting the exercise or accessing contact related feature for the first time.
    if (
      precheck !== "granted" &&
      didShowPolicyPrompt !== true &&
      Platform.OS === "android"
    ) {
      didShowPolicyPrompt = true;
      // const playstoreRequiredMessage = `The app stores contacts you select for convenient use in the Contact List feature to help you stay organized and schedule follow up reminders with specific people.`;
      Alert.alert(
        `This feature requires access to your phone contacts to function.`,
        `Permitting access to your contacts will allow you to select contacts from your phone to add into the app.
Your contacts will not be distributed to any third parties but by agreeing you do consent to contacts you select being stored on our secure servers until you choose to delete them.`,
        [
          {
            text: "View Privacy Policy",
            onPress: async () => {
              await LinkURL("https://apptakeoff.com/privacy-policy");
              // resolve();
            },
          },
          {
            text: "Decline",
            onPress: async () => {
              reject();
            },
          },
          {
            text: "Agree",
            onPress: () => {
              resolve();
            },
          },
        ]
      );
    } else {
      didShowPolicyPrompt = true;
      resolve();
    }
  });
}

/**
 * ### Get all contacts in phone
 * - It is recommended you provide a query but the default will help minimize data by only getting the following fields:
 *    "name", "id", "birthday", "emails", "phoneNumbers", "addresses"
 *
 * @example
 * const contacts = await fetchContacts()
 *
 * @example
 * const contacts = await fetchContacts({ fields: ["id","name"] })
 */
export async function fetchContacts(
  contactQuery: Contacts.ContactQuery = {
    fields: ["name", "id", "birthday", "emails", "phoneNumbers", "addresses"],
  }
): Promise<Contacts.Contact[]> {
  await androidPrecheckIfNeeded();
  const { status } = await Contacts.requestPermissionsAsync();
  if (status === "granted") {
    // const { data } = await Contacts.getContactsAsync({
    //   fields: [Contacts.Fields.PhoneNumbers],
    // });
    const { data } = await Contacts.getContactsAsync(contactQuery);

    if (data.length > 0) {
      const formattedData = data
        ?.filter((d) => d?.name)
        ?.sort((c1, c2) => c1?.name?.localeCompare(c2?.name));
      return formattedData;
    }
  }
  return [];
}
