import { Buttoon, Kitten, Toasty, Txt } from "components/atoms";
import {
  ContactGroupActionsList,
  ContactsHomeItem
} from "components/molecules";
import { TwoColumns, useTwoColumns } from "components/templates";
import { MediaPageItemSchema, useAppContext } from "engines";
import { ContactGroupSchemaa } from "engines/backends/schemas/bml";
import { useConfigDocument } from "engines/firebase/handler/config-handler";
import { useContactGroupsCollection } from "engines/firebase/handler/contact-groups-handler";
import { useFollowUpContacts } from "engines/firebase/handler/contact-handler";
import React from "react";
import { ActivityIndicator, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import { useNavigator } from "screens";
import { tr } from "utils";
import { dContactsScrParams } from "../../../screens/_navigation";
import { AddContactCategoryDialog } from "../contact-manager/AddContactCategoryDialog";
import { ListFollowUpContacts } from "../contact-manager/ListFollowUpContacts";

export function ContactsHomeCenter(props: P) {
  const { goDirectlyToFollowUps } = props;
  console.log("props data", props.sharedITems);
  const { C, teamId, frbsUser } = useAppContext();
  // const userProfile = useUserProfile();
  const toolsHandler = useConfigDocument(`variables-${teamId}`);
  // const Navigation = useNavigator();
  // const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);

  const { data, error, add } = useContactGroupsCollection(frbsUser?.uid);
  const followUpContactsHandler = useFollowUpContacts(frbsUser?.uid);

  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  const followUpContacts = followUpContactsHandler.data?.filter(
    (c) => c.followUpDate?.toDate && c.followUpDate?.toDate() <= endOfToday
  );

  // NOTE: apparently not being used anywhere, so commented out for now
  // let exportData = contacts.map((item) => {
  //   const formattedPN = item.phoneNumbers
  //     ? typeof item.phoneNumbers[0] === "string"
  //       ? item.phoneNumbers[0]
  //       : //@ts-expect-error
  //         item.phoneNumbers[0]?.number
  //     : "";

  //   const formattedNotes = item.notes
  //     ? item.notes
  //         ?.map((n) => {
  //           return `Note "${n.noteText}" created at ${n.createdAt
  //             .toDate()
  //             .toLocaleDateString()} by ${n.createdBy.displayName.trim()}`;
  //         })
  //         .join(",\n")
  //     : "";

  //   return {
  //     displayName: item.displayName,
  //     phoneNumber: formattedPN,
  //     email: item.email,
  //     notes: formattedNotes,
  //   };
  // });

  const Navigation = useNavigator();
  const [emailGroups, setEmailGroups] = React.useState<boolean>(false);
  const [list, setList] = React.useState<boolean>(false);
  const { column1ContainerStyle, ViewCol1BottomSpace } = useTwoColumns();

  const [contactGroups, setContactGroups] =
    React.useState<ContactGroupSchemaa[]>(null);

  React.useEffect(
    function loadedContactGroups() {
      data &&
        setContactGroups(
          data.sort(
            (a, b) =>
              (a.createdAt?.toDate()?.getTime() || 0) -
              (b.createdAt?.toDate()?.getTime() || 0)
          )
        );
    },
    [data]
  );

  const refDidOpenFollowUpsImmediately = React.useRef<boolean>(false);
  React.useEffect(
    function openFollowUpsImmediately() {
      if (
        goDirectlyToFollowUps &&
        !refDidOpenFollowUpsImmediately.current &&
        followUpContacts
      ) {
        refDidOpenFollowUpsImmediately.current = true;
        Navigation.navigate<dContactsScrParams>("Contact Group Screen", {
          id: "followups",
          followUpContacts,
        });
      }
    },
    [goDirectlyToFollowUps, followUpContacts]
  );

  return React.useMemo(() => {
    try {
      if (error) return <Txt.Helper>{tr("Cannot load groups")}</Txt.Helper>;
      if (!contactGroups) return <ActivityIndicator />;

      if (toolsHandler.error) throw Error();
      if (!toolsHandler.data) return null;

      const toolsdata = toolsHandler?.data;

      let toolsDoc =
        data && Array.isArray(toolsdata?.tools) ? toolsdata?.tools : null;
      //TODO temporarily, for FIN demo
      // return (
      //   <FlatList
      //     contentContainerStyle={column1ContainerStyle}
      //     data={data}
      //     ListHeaderComponent={() => (
      //       <View>
      //         <FinCenter />
      //         <Kitten.Divider_________ />
      //       </View>
      //     )}
      //     renderItem={({ item, index }) => (
      //       <ContactsHomeItem key={index} item={item} index={index} />
      //     )}
      //     ItemSeparatorComponent={Kitten.Divider_________}
      //     ListEmptyComponent={
      //       <Txt.Indicator marginV>
      //         {tr("No contact group found")}
      //       </Txt.Indicator>
      //     }
      //   />
      // );

      return toolsDoc?.some((value) => value === "Contact Manager") ? (
        <TwoColumns
          disableAnimatedFooter
          bottomInset
          column1Content={(scrollHandler) => (<ScrollView style={{ flex: 1 }}>
            <ContactsHomeItem
              index={0}
              followUpContacts={followUpContacts || []}
            />
            <Kitten.Divider />
            {contactGroups.map((item, index) => <>
              <ContactsHomeItem
                key={item._id}
                item={item}
                index={index + 1}
                sharedITems={props.sharedITems}
              />
              <Kitten.Divider />
            </>)}
            <View style={{ width: "100%", height: 75 }} />
          </ScrollView>)}
          column3Content={(scrollHandler) => (
            <Animated.FlatList
              scrollEventThrottle={16}
              onScroll={scrollHandler}
              showsVerticalScrollIndicator={false}
              // NOTE Future feature
              // ListHeaderComponent={() => (
              //   <View>
              //     <FinCenter />
              //     <Kitten.Divider_________ />
              //   </View>
              // )}
              // @ts-ignore
              data={(followUpContacts ? [{ followUps: true }] : []).concat(
                contactGroups
              )}
              renderItem={({ item, index }) =>
                item.followUps ? (
                  <ContactsHomeItem
                    index={index}
                    followUpContacts={followUpContacts || []}
                  />
                ) : (
                  <ContactsHomeItem
                    item={item}
                    index={index}
                    sharedITems={props.sharedITems}
                  />
                )
              }
              contentContainerStyle={[column1ContainerStyle]}
              ItemSeparatorComponent={Kitten.Divider}
              ListEmptyComponent={
                <Txt.Indicator marginV>
                  {tr("No contact group found")}
                </Txt.Indicator>
              }
              ListFooterComponent={ViewCol1BottomSpace}
            />
          )}
          column2Content={[
            {
              headerTitle: "Follow-Up Contacts",
              icon: "retweet",
              showTitle: false,
              render: () => <ListFollowUpContacts />,
            },
            {
              headerTitle: "Create Contact Group",
              icon: "plus",
              showTitle: false,
              render: () => (
                <ContactGroupCreation
                  onCreate={(group) =>
                    // setContactGroups([group].concat(contactGroups))
                    setTimeout(() => {
                      Navigation.navigate("Contact Group Screen", {
                        id: group?._id
                      })
                    }, 500)
                    // Navigation.navigate<dContactsScrParams>("Contact Group Screen", {
                    //   id: item?._id,
                    //   sharedItems: sharedITems,
                    //   followUpContacts,
                    // });
                  }
                />
              ),
            },
            {
              headerTitle: "More",
              icon: "dots_horizontal",
              showTitle: true,
              // hiddenIf: [!IS_WEB],
              render: () => <ContactGroupActionsList />,
            },
          ]}
        />
      ) : (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Txt.H3>{tr("Contact Groups is disabled by your admin")}</Txt.H3>
        </View>
      );
    } catch (error) {
      throw Error(`::ContactsHomeCenter::${error}`);
    }
  }, [data, list, emailGroups, followUpContacts, C]);
}

const ContactGroupCreation = ({
  onCreate,
}: {
  onCreate?: (group: ContactGroupSchemaa) => void;
}) => {
  const { C, frbsUser } = useAppContext();
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();
  const { ...contactGroupsHandler } = useContactGroupsCollection(frbsUser?.uid);

  const handleCreateGroup = () =>
    Navigation.dialogPortal.open({
      headerTitle: tr("Create contact category"),
      render: (
        <AddContactCategoryDialog
          addFunc={async (group: ContactGroupSchemaa) => {
            Toasty.show("Creating group...", { type: "loading" })
            const groupId = await contactGroupsHandler.add(group);
            onCreate && onCreate({ ...group, _id: groupId });
          }}
        />
      ),
    });

  React.useEffect(function triggering() {
    !breakpointForTwoColumns && (Navigation.goBack(), handleCreateGroup());
  }, []);
  return breakpointForTwoColumns ? (
    <Buttoon size="giant" onPress={handleCreateGroup}>
      {tr("Create Contact Group")}
    </Buttoon>
  ) : (
    <View />
  );
};

interface P {
  sharedITems?: MediaPageItemSchema[];
  /**
   * For when a push notification is tapped and you want the follow ups list to come up immediately
   */
  goDirectlyToFollowUps?: boolean;
}
