import {
  TouchableWeb,
  TouchableWebProps
} from "@ui-kitten/components/devsupport";
import { Kitten } from "components";
import {
  Buttoon,
  Imaggie,
  ListHeader,
  Poppy,
  sstyled,
  Toasty,
  Txt
} from "components/atoms";
import {
  TemplateSchema,
  useAppContext,
  useMailTemplatesFunctions
} from "engines";
import { useEmailTemplateDocument } from "engines/firebase/handler/emails-handler";
import React from "react";
import { ActivityIndicator, ImageBackground, View } from "react-native";
import { WebView } from "react-native-webview";
import { dEditTemplateScrParams, useNavigator } from "screens";
import { IS_WEB, scale, THEME, tr } from "utils";

/**
 * ### List Item for Member
 *  - Including some member's action
 *  ----
 *  @version 21.03.20
 *  -  *Move this component to ./molecules*
 *  @author  Nl
 *
 **/
export function MailTemplateListItem(props: dMailTemplateListItem) {
  const { item } = props;
  const { C, theme } = useAppContext();
  const { data, error } = useEmailTemplateDocument(item.id);
  const Navigation = useNavigator();

  const handleTemplate = useMailTemplatesFunctions();

  const showTemplate = async () => {
    //Navigation.navigate<dOverlayParams>("Overlay", {
    Navigation.overlayPortal.open({
      headerTitle: tr("Preview template"),
      render: (
        <View>
          <ListHeader
            titleTx={tr("Preview")}
            actions={[
              {
                label: "Use this template",
                onPress: () =>
                  Navigation.navigate<dEditTemplateScrParams>("use-template", {
                    docID: item.id,
                  }),
              },
            ]}
          />
          <WebView
            source={{ html: item.HtmlBody }}
            style={{ flex: -1, width: "100%", height: 700 }}
          />
          {/* <Buttoon
        // size="small"
        appearance="ghost"
        status="primary"
          onPress={() =>
            Navigation.navigate<dEditTemplateScrParams>("use-template", {
              docID: item.__snapshot.id,
            })
          }
        >
          {tr("Use template")}
        </Buttoon> */}
        </View>
      ),
    });
  };

  const deleteTemplate = async () => {
    // console.log(item);
    handleTemplate
      .x(item.TemplateId)
      .then((res) => {
        if (res.status == 200) {
          Toasty.show("Delete Successful", { type: "success" });
          // getEmailTemplate();
        }
      })
      .catch(() => {
        Toasty.show("Failed to delete", { type: "danger" });
      });
  };
  const [_borderWidth, setBorderWidth] = React.useState(1);
  try {
    if (error)
      return <Txt.Indicator>{"Error getting page content"}</Txt.Indicator>;
    if (!data) return <ActivityIndicator />;
    let item = data;
    return (
      <TouchableWeb
        onMouseEnter={(e) => {
          setBorderWidth(6);
        }}
        onMouseLeave={(e) => {
          setBorderWidth(1);
        }}
        onPress={() => showTemplate()}
      >
        <A.CtnrGrid
          source={{
            uri:
              _borderWidth == 6
                ? theme == THEME.DARK
                  ? "https://res.cloudinary.com/https-apptakeoff-com/image/upload/q_auto:low/v1622731805/apptakeoff/icons/rocket_vdr9ge.gif"
                  : ""
                : "",
          }}
          blurRadius={80}
          style={{
            borderWidth: _borderWidth,
            backgroundColor:
              theme == THEME.DARK ? C.primaryDarker : C.pitchWhite,
          }}
        >
          <View style={{ alignItems: "center" }}>
            <Txt.H4 style={{ textAlign: "center" }}>
              {item.Name || "       "}
            </Txt.H4>
            <Kitten.ViewH>
              <Buttoon /** Admin stuff */
                size="small"
                appearance="ghost"
                status="primary"
                onPress={() => {
                  Navigation.navigate<dEditTemplateScrParams>("use-template", {
                    docID: item.id,
                  });
                }}
                // icon={{ name: "trash" }}
              >
                Use template
              </Buttoon>
              <Buttoon /** Admin stuff */
                size="small"
                appearance="ghost"
                status="danger"
                onPress={() => {
                  Poppy(
                    tr("Final Check"),
                    tr("Do you want to delete this template?"),
                    {
                      text: "Cancel",
                      onPress: () => {},
                    },
                    {
                      text: "Yes",
                      onPress: async () => {
                        await deleteTemplate();
                      },
                    }
                  );
                }}
                // icon={{ name: "trash" }}
              >
                Delete
              </Buttoon>
            </Kitten.ViewH>
          </View>

          {/* <WebView
            source={{ html: handleTemplate.data.HtmlBody }}
            textZoom={10}
          /> */}
        </A.CtnrGrid>
      </TouchableWeb>
    );
  } catch (error) {
    throw new Error(error);
  }
}

// const A = {
//   Ctnr: sstyled(Kitten.Card)({
//     width: 9,
//     height: 9,
//   }),
//   CtnrGrid: sstyled(ImageBackground)({
//     backgroundColor: "primaryDarker",
//     overflow: "hidden",
//     // borderWidth: _borderWidth,
//     borderColor: "primary",
//     justifyContent: "flex-end",
//     borderRadius: 5,
//     // padding: 10,
//     height: 300,
//   }),
//   TxtItemLabel: sstyled(Txt.S1)({ color: "text01", textAlign: "center" }),
//   TxtItemDescription: sstyled(Txt.P2)({
//     color: "text01",
//     textAlign: "center",
//   }),
// };

const A = {
  CtnrGrid: sstyled(ImageBackground)({
    backgroundColor: "primaryDarker",
    overflow: "hidden",
    // borderWidth: _borderWidth,
    borderColor: "primary",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    padding: 10,
    height: 300,
  }),
  Ctnr: sstyled(ImageBackground)({
    borderRadius: 10,
    overflow: "hidden",
    padding: 4,
    marginBottom: 3,
    alignItems: "flex-start",
    backgroundColor: "color-basic-1100",
  }),
  CtnrMedia: sstyled(View)((p) => ({
    maxWidth: 200,
    borderRadius: 10,
    overflow: "hidden",
    marginBottom: 2,
  })),
  Thumbnail: sstyled(Imaggie)({
    alignSelf: "center",
    // width: p.mediaDimension.width,
    // height: p.mediaDimension.height,
    width: 20,
    height: 20,
    marginRight: IS_WEB ? 0 : scale(5),
    backgroundColor: "dim",
  }),
};

export interface dMailTemplateListItem extends TouchableWebProps {
  item: TemplateSchema;
  docID?: string;
  docID2?: number;
}
