const Config = {
  disableTeamsTab: false,
  disableScheduleTab: false,
  useSnlLogo: false,
  pageExtensions: [".media", ".team", ".home"],
  newPeoplePageKeywords: ["New Recruits", "New People", "New Representatives"],
  enablingKeywordsForProgressFeature: ["Level", "Class", "Section", "Step"],
  dropboxAccessTokens: [
    "4_l-4AYJm6IAAAAAAAB4mRIhvk_1K-_-HUPGZOdMFg4i_wrck5CMjmxMtyXQnhHM", // Dean Francis
  ],
  vimeoAccessTokens: [
    {
      accessToken: "da2b1b1d7ad2febb2b79b5e917ec6b1b",
      userId: "13477675", // Dean Francis
    },
  ],
  // calendarAccessToken: 'DSGu18kQSYlsPAAKKpuXT-RsgLJM4zQV',
  // calendarId: 'cal_XDAY9fQIYSfBHW2A_AILwqmN@lQLNtj2eNmVu3Q', // Dean Francis
  calendarAccessToken: null,
  calendarId: null,
};

export const updateWithKeys = (keys) => {
  //TODO erase duplicates from live refresh?
  Config.dropboxAccessTokens = Config.dropboxAccessTokens.concat(keys.dropbox);
  Config.vimeoAccessTokens = Config.vimeoAccessTokens.concat(keys.vimeo);

  if (keys.calendarId) Config.calendarId = keys.calendarId;
  if (keys.calendarAccessToken)
    Config.calendarAccessToken = keys.calendarAccessToken;
};

export default Config;
