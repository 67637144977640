import { Kitten, Txt } from "components";
import { MediaPageItemSchema } from "engines";
import React from "react";
import { spacing } from "utils";

export function CheckboxItem(p: {
  item: MediaPageItemSchema;
  checked: boolean;
  onCheckItem: (item: MediaPageItemSchema, checked: boolean) => void;
}) {
  const { item, checked, onCheckItem } = p;
  const [isChecked, setIsChecked] = React.useState<boolean>(checked);

  React.useEffect(
    function updateCheckedState() {
      if (isChecked !== checked) setIsChecked(checked);
    },
    [checked]
  );

  return (
    <Kitten.ViewH
      style={{
        justifyContent: "flex-start",
        marginVertical: spacing(3),
        marginHorizontal: spacing(3),
      }}
    >
      <Kitten.CheckBox
        style={{ marginRight: spacing(3) }}
        checked={isChecked}
        onChange={(val) => {
          setIsChecked(val);
          onCheckItem && onCheckItem(item, val);
        }}
      />
      <Txt.P1>{item?.title}</Txt.P1>
    </Kitten.ViewH>
  );
}
