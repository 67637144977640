import { sstyled, Txt } from "components";
import { LinkURL } from "engines/functions/web-functions";
import * as R from "ramda";
import React, { useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  ImageStyle,
  ScrollView,
  View
} from "react-native";
import * as Animatable from "react-native-animatable";
import { useNavigator } from "screens";
import { dFaqScrParams } from "screens/_navigation/faq.navigator";
import { spacing, useDimension } from "utils";
import { ContentCard } from "./c.content-card";

export function FaqScreen(props: P) {
  const { route } = props;
  const { WIDTH } = useDimension();
  const [screenShown, showScreen] = useState(false);

  const {
    faqBody: { color: projectColor, headline },
  } = route.params;
  const [_contents, setContents] = React.useState([""]);
  const [_bodyContents, setBodyContents] = React.useState([""]);
  const [_imgContents, setImgContents] = React.useState([""]);

  React.useEffect(function sortContents() {
    const dbContents = [
      route.params.faqBody.image00,

      route.params.faqBody.image01,

      route.params.faqBody.image02,

      route.params.faqBody.image03,

      route.params.faqBody.image04,

      route.params.faqBody.image05,

      route.params.faqBody.image06,

      route.params.faqBody.image07,

      route.params.faqBody.image08,

      route.params.faqBody.image09,
    ];
    const dbBodyContents = [
      route.params.faqBody.body00,
      route.params.faqBody.body01,
      route.params.faqBody.body02,
      route.params.faqBody.body03,
      route.params.faqBody.body04,
      route.params.faqBody.body05,
      route.params.faqBody.body06,
      route.params.faqBody.body07,
      route.params.faqBody.body08,
      route.params.faqBody.body09,
    ];

    const newContents = R.reject(
      (content) => !content || content == "",
      dbContents
    );
    const newBodyContents = R.reject(
      (content) => !content || content == "",
      dbBodyContents
    );
    const imgContents = R.filter(
      (content: string) => content.includes("http"),
      newContents
    ).reduce((a, c) => [...a, { url: c }], []);
    setContents(newContents);
    setBodyContents(newBodyContents);
    setImgContents(imgContents);
    global.setTimeout(() => {
      showScreen(true);
    }, 1000);
  }, []);

  const Navigation = useNavigator();
  try {
    return screenShown ? (
      <SS.Sctnr
      // contentContainerStyle={{ justifyContent: "center", alignItems: "center" }}
      >
        <SS.Headline {...props}>{headline}</SS.Headline>
        <View
          style={{
            justifyContent: "center",
            alignItems: "flex-start",
            paddingHorizontal: WIDTH > 996 ? spacing(7) : spacing(5),
          }}
        >
          {_bodyContents.map((body) => (
            // <Txt style={{ marginVertical: spacing(3), textAlign: "justify" }}>
            //   {body}
            // </Txt>
            <Txt.Md
              onLinkPress={(url) => {
                if (url) {
                  LinkURL(url, true);
                  return false;
                }
                return true;
              }}
            >
              {body}
            </Txt.Md>
          ))}
        </View>
        <View
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <FlatList
            data={_contents}
            renderItem={({ item: image, index: imageIndex }) => {
              return (
                <ContentCard
                  key={imageIndex}
                  text={image}
                  imageIndex={imageIndex}
                  onImagePress={() => {
                    let imgIndex = R.findIndex(R.propEq("url", image))(
                      _imgContents
                    );
                    // console.log("img index: ", imgIndex);
                    Navigation.navigate("Gallery", {
                      images: _imgContents,
                      imgIndex,
                    });
                  }}
                />
              );
            }}
          />
        </View>
      </SS.Sctnr>
    ) : (
      <SS.CtnrLoading>
        <ActivityIndicator size="large" color={projectColor} />
      </SS.CtnrLoading>
    );
  } catch (error) {
    throw Error(`::RmFxU2NyZWVu::${error}`);
  }
}

const SS = {
  Sctnr: sstyled(ScrollView)((p) => ({
    backgroundColor: "background",
    paddingTop: spacing(5),
  })),
  CtnrImg: sstyled(Animatable.View)((p) => ({
    margin: 10,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "surface",
  })),
  CtnrLabel: sstyled(Animatable.View)(() => ({
    margin: 10,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  })),
  CtnrLoading: sstyled(View)((p) => ({
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    backgroundColor: "background",
  })),
  //*----Txt-SECTION ----------
  Headline: sstyled(Txt.H1)((p) => ({
    // fontSize: 26,
    color: "text",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: spacing(2),
    paddingHorizontal: spacing(6),
  })),
  Label: sstyled(Txt.P2)((p) => ({ width: p.chieuRong, textAlign: "center" })),
  S: {
    IMG_CTNR: {
      margin: 10,
      borderRadius: 10,
      overflow: "hidden",
    } as ImageStyle,
  },
};

/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: dFaqScrParams }; //* unmask if we pass params thru rnav
}
