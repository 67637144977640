import { MediaPageItemSchema } from "engines";
import { PageBodyScreen } from "screens/page-body/PageBodyScreen";

/**
 * ###  A Navigator for Media Resources Screens
 * -  Media Resources contains custom pages of media
 * that users upload for educational purposes
 * ---
 * @version 1.6.25
 * -  *Decentralized from main.navigator*
 * @author K
 */
export const PagesStackCollection = {
  "Page Contents": {
    component: PageBodyScreen,
  },
};

export type PAGES_STK = keyof typeof PagesStackCollection;

export interface dPageContentsScreenParam {
  cpid: string;
  diableBaseShopPrompt?: boolean;
  /**
   * If we are coming from a custom page, we want to know that cpid especially in the case of buttonItem being provided
   */
  previousCpid?: string;
  /**
   * If a button item navigated the user to this page, we want to know about it so we can update that button's logo if needed
   */
  buttonItem?: MediaPageItemSchema;
  /**
   * To open a specific page id right after navigating to this page
   */
  thenGoTo?: string;
  isLevel?: boolean;
}
