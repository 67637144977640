import { MenuIcon } from "assets/icons/MenuIcon";
import { Avt, Buttoon, Kitten, sstyled, Toasty, Txt } from "components";
import {
  FRBS_ROLE,
  useAppContext,
  useConfigDocument,
  usePageDocument,
  UserSchema
} from "engines";
import {
  useCalculateProgressForPage,
  useGetLevelsOnParentPage
} from "engines/firebase/handler/levels-handler";
import {
  useMemberDocument,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import firebase from "firebase";
import { isNaN } from "lodash";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { scale, spacing, tr } from "utils";
import { useMassMailFunctions } from "../../../engines/backends/mass-mail-handler/MassMailHandler";
import { ContactTeamCenter } from "../members/ContactTeamCenter";
// import { ContactTeamCenter } from "./ContactTeamCenter";

export interface dMemberInfo {
  memberId: string;
  type: "admin" | "teammates" | "user";
}

export function LevelsDashboardCenter({
  memberId,
  type = "admin",
}: dMemberInfo) {
  const { C, teamId, frbsUser, setFrbsUser } = useAppContext();
  const Navigation = useNavigator();
  const { data: userD, ...userProfile } = useUserProfile();
  const { data: memberD, ...handleMember } = useMemberDocument(memberId);
  // const { breakpointForTwoColumns } = useTwoColumns();

  let isMemberBanned = memberD?.roles?.includes(FRBS_ROLE.BANNED);
  let isMemberAdmin = memberD?.roles?.includes(FRBS_ROLE.ADMIN);

  let displayName =
    type === "user"
      ? tr("your")
      : `${memberD?.personali?.displayName || "member"}'s`;

  const massMailHandler = useMassMailFunctions();

  //#region [section] user's growth handler
  const configHandler = useConfigDocument(`variables-${teamId}`);
  // const levelPagesHandler = useGetLevelsOnParentPage(
  //   configHandler.data?.startHerePage
  // );
  async function onToggleLevelComplete() {
    const allLevelsCompleted = !memberD?.allLevelsCompleted;
    Toasty.show(
      `${allLevelsCompleted ? "Unl" : "L"}ocking ${displayName} levels...`,
      {
        type: "loading",
      }
    );
    await handleMember.update({
      allLevelsCompleted,
    });
    Toasty.show(
      tr(
        `${displayName} levels are now ${allLevelsCompleted ? "un" : ""}locked!`
      )
    );
  }

  //#endregion

  //#region [section2] roles handler
  function onToggleAdmin() {
    isMemberAdmin
      ? // ? handleMember.update({
      handleMember.update({
        roles: firebase.firestore.FieldValue.arrayRemove(FRBS_ROLE.ADMIN),
      })
      : // : handleMember.update({
      handleMember.update({
        roles: firebase.firestore.FieldValue.arrayUnion(FRBS_ROLE.ADMIN),
      });
  }

  function onToggleBan() {
    isMemberAdmin
      ? // ? handleMember.update({
      handleMember.update({
        roles: firebase.firestore.FieldValue.arrayRemove(FRBS_ROLE.BANNED),
      })
      : // : handleMember.update({
      handleMember.update({
        roles: firebase.firestore.FieldValue.arrayUnion(FRBS_ROLE.BANNED),
      });
  }
  function onKickFromTeam() {
    const currentBaseShopId = memberD?.baseShopId;
    handleMember.update({
      unlockedPages:
        memberD?.unlockedPages?.filter((pid) => pid !== currentBaseShopId) ||
        [],
      baseShopId: null,
    });
  }
  //#endregion

  function toMemberContacts() {
    // console.log(
    //   `Viewing data for ${memberD?.personali?.displayName} and their shareTo is ${memberD._id} to ${userD._id}`
    // );
    //NOTE only show contact groups, if the teammate has shared their profile with you.
    if (
      // user shared with you specifically
      memberD?.growth?.listBuilder?.shareTo?.includes(userD?._id) ||
      // this is your profile
      memberD?._id == userD?._id ||
      // you are a developer
      userProfile?._isRolesContain([FRBS_ROLE.DEVELOPER]) ||
      // access permitted to ADMINs
      (memberD?.growth?.listBuilder?.shareTo?.length > 0 &&
        userProfile?._isRolesContain([FRBS_ROLE.ADMIN])) ||
      // access permitted to their RVP/base shop leader
      (memberD?.growth?.listBuilder?.shareTo?.length > 0 &&
        userProfile?._isRolesContain([`BS:${memberD?.baseShopId}`]))
    ) {
      Navigation.overlayPortal.open({
        headerTitle: memberD.personali?.displayName || memberD.personali?.email,
        render: <ContactTeamCenter userId={memberD._id} />,
      });
    } else {
      Toasty.show(
        `${memberD?.personali?.displayName} has not permitted access to their contacts. Ask them to share it!`,
        { type: "warning" }
      );
    }
  }

  try {
    if (handleMember.error) throw Error("firebase-error");
    return (
      <View style={[{ width: "100%" }]}>
        {
          //#region [section2] Levels
          (userProfile._isRolesContain(FRBS_ROLE.ADMIN, FRBS_ROLE.DEVELOPER, [
            `BS:${memberD?.baseShopId}`,
          ]) ||
            memberD?.growth?.listBuilder?.shareTo?.includes(userD?._id) ||
            type === "user") && (
            <>
              <A.Menu>
                {/** @ts-ignore needs to chill */}
                {memberD?.parentLevelPages ? (
                  memberD?.parentLevelPages?.map((parentPageId) => (
                    <LevelProgressMenu
                      parentPageId={parentPageId}
                      uid={memberId}
                      userD={memberD}
                    />
                  ))
                ) : (
                  <LevelProgressMenu
                    parentPageId={configHandler.data?.startHerePage}
                    uid={memberId}
                    userD={memberD}
                  />
                )}
                {userProfile._isRolesContain[
                  (FRBS_ROLE.ADMIN,
                    FRBS_ROLE.DEVELOPER,
                    `BS:${memberD?.baseShopId}`)
                ] ? (
                  <Kitten.MenuItem
                    title={tr(`Unlock all levels`)}
                    onPress={onToggleLevelComplete}
                    accessoryLeft={() => (
                      <Kitten.CheckBox
                        checked={memberD?.allLevelsCompleted || false}
                        onChange={onToggleLevelComplete}
                      />
                    )}
                    accessoryRight={(p) => MenuIcon("unlock", p)}
                    style={{
                      backgroundColor: memberD?.allLevelsCompleted
                        ? C["color-primary-transparent-500"]
                        : C.grey500,
                    }}
                  />
                ) : null}
              </A.Menu>
            </>
          )
          //#endregion
        }
      </View>
    );
  } catch (error) {
    throw new Error(`::TWVtYmVySW5mbw==:: ${error}`);
  }
}

export function LevelProgressMenu(props: {
  parentPageId: string;
  uid: string;
  userD?: UserSchema;
}) {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const { parentPageId, uid, userD } = props;
  const parentPage = usePageDocument(parentPageId);
  const levelPages = useGetLevelsOnParentPage(parentPageId);

  const isTrainingComplete =
    userD && userD.completedLevelPages?.includes(parentPageId);

  return (
    <>
      <Kitten.ViewH style={{ justifyContent: "space-between" }}>
        <Txt.S2 style={{ margin: spacing(4), color: C.text }}>{`${parentPage?.data?.name || "Level Section"
          }`}</Txt.S2>
        <Buttoon
          appearance="ghost"
          size="small"
          style={{ marginRight: spacing(2) }}
          status={isTrainingComplete ? "info" : "primary"}
          icon={{ name: "arrow_right", right: true }}
          onPress={() => {
            Navigation.navigate("Page Contents", {
              cpid: parentPageId,
            });
          }}
        >
          {isTrainingComplete ? tr("Review Training") : tr("Resume Training")}
        </Buttoon>
      </Kitten.ViewH>
      {levelPages
        ? levelPages.map((levelPageId) => (
          <LevelProgressMenuItem levelPageId={levelPageId} user={userD} />
        ))
        : null}
    </>
  );
}

export function LevelProgressMenuItem(props: {
  levelPageId: string;
  user: UserSchema;
}) {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const { levelPageId, user } = props;
  const levelPageHandler = usePageDocument(levelPageId);
  const levelPageProgress = useCalculateProgressForPage(levelPageId, user, {
    levelPageHandler,
  });
  return (
    <Kitten.ListItemv2
      // title={levelPageHandler.data?.name || "    "}
      onPress={() => {
        // console.log("🚀 Item method in Hierarchyhome feed pressed");
        Navigation.navigate<dPageContentsScreenParam>("Page Contents", {
          cpid: levelPageHandler.data?._id,
          title: levelPageHandler.data?.name || tr("Untitled"),
        });
      }}
      title={levelPageHandler.data?.name}
      accessoryLeft={() => (
        <Avt
          name={levelPageHandler.data?.name || ""}
          source={{ uri: levelPageHandler.data?.mediaItem?.logo }}
          shape={"square"}
        />
      )}
      accessoryRight={() => (
        <View style={{ width: scale(50) }}>
          {isNaN(levelPageProgress) ? (
            <Txt.S2 style={{ textAlign: "right", color: C.errorRed }}>
              {tr("Error")}
            </Txt.S2>
          ) : (
            <Txt.S2 style={{ textAlign: "right" }}>{`${Math.round(
              levelPageProgress * 100
            )}%`}</Txt.S2>
          )}
        </View>
      )}
    />
  );
}

interface P {
  team?: boolean;
}

const A = {
  Menu: sstyled(Kitten.Menu)({
    borderRadius: scale(4),
    borderWidth: 1,
    borderColor: "dim",
  }),
};
