import { FRBS_ROLE, MediaPageItemSchema } from "engines";
import fakerStatic from "faker";
import firebase from "firebase";
import randomColor from "randomcolor";

/**
 * ###  Schema for media pages
 * -  Custom page is set up by users,
 * containing their own media and contents
 * ---
 * @version 1.1.12
 * @author jm_francis
  * @example
  * const MediaPage = {
    id: "",
    zoom: undefined,
    password: undefined,
    mediaItem: {
      imageColor: undefined,
      color: undefined,
      link: undefined,
      logo: undefined,
      presetLogo: undefined,
      team: false,
      visible: false,
    },
    name: "",
    pageItem: {
      description: "",
      imageUrl: undefined,
      uploadImage: undefined,
      visible: false,
      link: undefined,
    },
    position: 0,
    content: [],
  };
  */

/** Requirements for a specific media page to be accessed */
export interface MediaPageRequirement {
  title: string;
  /** The scoreboard this requirement may need to know about */
  _sbid?: string;
  /** The minimum number (score) the user must have to gain access */
  minNumber?: number;
}
export interface MediaPageSchema {
  _teamId: string;
  teamIdSharers?: string;
  _id: string;

  /**
   * Project-id from the firebase, this is used for collaboration feature
   */
  teamID?: string;

  /**
   * Boolean field indicating if this page is shared for collaboration with other apps
   */
  collaboration: boolean;
  key?: string;
  /**
   * If this is a team that has a zoom link or ID it can go here
   */
  zoom?: string;
  /**
   * If the page requires a password to get into
   */
  password?: string | number;
  /** Requirements for a specific media page to be accessed */
  accessRequirements?: MediaPageRequirement[];
  /**
   * The type of page, if special type like (Start Here, Level)
   */
  // pageType?: PageType;
  /**
   * Configuration for how the page might display and function in the "Media" area of the app
   */
  mediaItem: {
    /**
     * @deprecated Image displays as is now
     */
    imageColor?: string;
    /**
     *
     */
    color?: string;
    /**
     * Open a URL instead of a page
     */
    link?: string;
    /**
     * Give the page a custom icon
     */
    logo?: string;
    /**
     * Use an icon that the app supports locally
     */
    presetLogo?: string;
    /**
     * Whether or not this media page represents a team
     */
    team?: boolean;
    /**
     * If true or "true", will be displayed directly on the "Media" area of the app
     */
    visible?: boolean | string;
  };
  /**
   * The name of the page :D
   */
  name: string;
  /**
   * Configuration for how the page might display and function in the home page of the app
   * @deprecated Now using social feature to control content of the home screen
   */
  pageItem?: {
    description?: string;
    imageUrl?: string;
    uploadImage?: string;
    visible: boolean | string;
    link?: string;
  };
  /**
   * Order in which the page might show up on the "Media" area of the app.
   * For example, a media page with position -3 will show up before one with position 1
   */
  position: number;
  /**
   * All of the content items the page contains
   * @deprecated DO NOT USE
   */
  content?: MediaPageItemSchema[];

  trophyId?: string;

  createdAt?: firebase.firestore.FieldValue;

  /**
   * ### What roles, besides from ADMIN and DEV, can edit this item
   * - Use for BS item, where only BS leader (BS:bsid) can edit the item
   */
  rolesThatCanEdit?: FRBS_ROLE[] | string[];
}

/**
 * TBD
 * @author jm_francis
 */
// export enum PageType {
//   StartHere,
//   Level
// }

export const _DEFAULT_MEDIA_PAGE: MediaPageSchema = {
  id: fakerStatic.datatype.uuid(),
  _teamId: "",
  zoom: "",
  password: "",
  mediaItem: {
    // imageColor: "",
    color: randomColor({
      hue: "purple",
      luminosity: "light",
    }),
    link: "",
    logo: "",
    presetLogo: "",
    team: false,
    /**
     * False if it's a sub-page
     */
    visible: true,
  },
  name: fakerStatic.company.catchPhrase(),
  position: 0,
  content: [],
  collaboration: false,
  rolesThatCanEdit: [],
};
