//#region [import]

import { T } from "components";
import {
  HierarchyHomeFeed,
  iHierarchyFeedData
} from "components/organisms/collaboration/HierarchyHomeFeed";
import { useCollabContext } from "engines/providers/collaboration-provider";
import React from "react";
import { useNavigator } from "screens";
//#endregion

/**
 * ### Hierarchy Home Screen
 * -  Display MediaResources for Hierarchy from Collaboration
 *  ----
 *  @version 21.11.16
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export function HierarchyHomeScreen(props: P) {
  const { setIsCollabModeOn } = useCollabContext();
  const teamName = props?.route?.params?.teamName;
  const hierarchyTeamId = props?.route?.params?.hierarchyTeamId;

  const Navigation = useNavigator();
  React.useLayoutEffect(() => {
    Navigation.setOptions({
      headerTitle: `${teamName}`,
    });
  }, [Navigation]);

  //#region [section2] for collaboration mode on
  React.useEffect(() => {
    setIsCollabModeOn(true);
  }, []);
  //#endregion

  // return <T.OneColumn column1Content={(scrollHandler) => <O.MediaHomeFeed />} />;
  try {
    return (
      <T.TwoColumns
        column1Content={(scrollHandler) => (
          <HierarchyHomeFeed
            teamName={teamName}
            hierarchyTeamId={hierarchyTeamId}
          />
        )}
        column2Content={[]}
      />
    );
  } catch (error) {
    throw new Error(`::SGllcmFyY2h5SG9tZVNjcmVlbi50c3g=:: ${error}`);
  }
}

/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: iHierarchyFeedData }; //* unmask if we pass params thru rnav
}
