import firebase from "firebase"
import * as AddCalendarEvent from "react-native-add-calendar-event";
import { IconPrimr } from "assets";
import { Buttoon, Kitten, Poppy, sstyled, Toasty, Txt } from "components";
import { useTwoColumns } from "components/templates";
import { ACTION_TYPE, fn, recordUserAction, useAppContext, useUserProfile } from "engines";
import {
  dCalendarEvent,
  useCalendar,
} from "engines/backends/calendar-handler/CalendarHandler";
import { useUserAttendance } from "engines/firebase/handler/attendance-handler";
import * as EXCalendar from "expo-calendar";
import _ from "lodash";
import React from "react";
import { Platform, View } from "react-native";
import { createAnimatableComponent } from "react-native-animatable";
import { Calendar } from "react-native-calendars";
import { DateData, Theme } from "react-native-calendars/src/types";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { dColors, IS_WEB, scale, spacing, tr } from "utils";
import { useNavigator } from "../_navigation/navigation-utils";
import { EventCard } from "./EventCard";
import { PersonalCalendar } from "./PersonalCalendar";
import { RenderDay } from "./render-day";
import { forsureDate } from "engines/functions/calendar-functions/time";

export function AgendaCenter() {
  const { teamId, C, frbsUser } = useAppContext();
  const {
    data: calEvents,
    loading,
    error,
    fetch,
    getAllPersonalCalendarEvents,
    combineAndSortEvents,
  } = useCalendar();

  const [personalEvents, setPersonalEvents] =
    React.useState<dCalendarEvent[]>(null);
  const [teamEvents, setTeamEvents] = React.useState<dCalendarEvent[]>([]);
  const events = combineAndSortEvents(teamEvents, personalEvents || []);

  const Navigation = useNavigator();

  const userHandler = useUserProfile();
  const userAttendanceHandler = useUserAttendance(frbsUser?.uid);

  React.useEffect(
    function loadCalendarEvents() {
      setTeamEvents(calEvents);
      if (
        userHandler.data &&
        userHandler.data?.personalCalendars &&
        !personalEvents // only load personal calendar once at start
      ) {
        if (!IS_WEB) {
          getAllPersonalCalendarEvents(
            userHandler.data?.personalCalendars
          ).then((__evts) => {
            setPersonalEvents(__evts);
          });
        } else {
          setPersonalEvents([]);
          // cannot access personal calendar from WEB (TODO: save to firebase)
          Toasty.show(
            "Your personal calendar(s) events can only be accessed from your mobile device.",
            { type: "warning" }
          );
        }
      }
    },
    [calEvents, userHandler.data]
  );

  //#region [section] RN-Calendar & Chronofy data setup
  const zoom = { key: "zoom", color: "#2d8cff", selectedDotColor: "blue" };
  const others = { key: "others", color: C["color-warning-600"] };
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [selectedDateStr, setSelectedDateStr] = React.useState(
    fn.calendar.dateFormat(new Date())
  );
  // const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());

  // React.useEffect(
  //   function showEvents() {
  //     // events.length > 0 &&
  //     //   alert(
  //     //     JSON.stringify(events.filter((e) => e && e.date).map((e) => e?.date))
  //     //   );
  //   },
  //   [events]
  // );

  /**
   * Returns object with dates and events like this...
   * {
   * "11/16/22": { events: [...] },
   * "11/17/22": { events: [...] }
   * }
   */
  const eventsByDate = _.groupBy(events, (e) =>
    fn.calendar.dateFormat(e?.date)
  );
  /**
   * ### Marked events, in react-native-calendars format (see example)
   *
   * @example Output will be e.g.
   * {
   *  "2021-12-18":{"dots":[{"key":"zoom","color":"#2d8cff"}]},
   *  "2021-12-20":{"dots":[{"key":"others","color":"#2d8cff"}]},
   * }
   */
  const markedEvents = Object.fromEntries(
    Object.entries(eventsByDate).map(([key, value]) => {
      /** Convert from mm/dd/yyyy to yyyy/mm/dd */
      let dateAsDateString =
        key.split("/")[2] + "-" + key.split("/")[0] + "-" + key.split("/")[1];

      const eventsToDots = Array(...value).map((event) => {
        return String(event?.description)?.includes("https://zoom.us")
          ? zoom
          : others;
      });

      return [dateAsDateString, { dots: eventsToDots }];
    })
  );

  /**
   * ### Set selected date state, with mm/dd/yyyy format
   * -  instead of the original m/d/yyyy format from rn-calendars
   * @param data
   */
  function onDateSelect(data: DateData) {
    const _selectedDate = new Date();
    _selectedDate.setTime(data.timestamp);
    let formattedSelectedDate =
      ("0" + data.month).slice(-2) +
      "/" +
      ("0" + data.day).slice(-2) +
      "/" +
      data.year;
    setSelectedDateStr(formattedSelectedDate);
    setSelectedDate(_selectedDate);
  }

  //#endregion
  const { column1ContainerStyle } = useTwoColumns();
  // React.useEffect(
  //   function loggin() {
  //     // console.log("events data:\n", JSON.stringify(events, null, "\t"));
  //   },
  //   [events]
  // );

  // const { width } = useWindowDimensions();

  return (
    <ScrollView
      horizontal={false}
      style={[
        column1ContainerStyle,
        // { paddingBottom: 0, overflow: "hidden", flex: 1 },
      ]}
    // contentContainerStyle={{ height: Dimensions.get("window").height*2 }}
    >
      <A.Calendar
        renderArrow={(direction) => (
          <IconPrimr
            name={direction == "left" ? "arrow_left" : "arrow_right"}
            color={C.primaryDarker}
            size={scale(14)}
          />
        )}
        theme={A.ThemedCalendar({ C })}
        displayLoadingIndicator={loading}
        firstDay={0}
        // onPressArrowLeft={(subtractMonth) => subtractMonth()}
        // onPressArrowRight={(addMonth) => addMonth()}
        minDate={"2020-01-01"}
        maxDate={"2099-12-31"}
        markingType={"dot"}
        markedDates={markedEvents}
        onDayPress={onDateSelect}
        monthFormat={"MMMM yyyy"}
        hideExtraDays={true}
        dayComponent={(p) =>
          RenderDay({ ...p, onDateSelect, selectedDate: selectedDateStr })
        }
      />

      <A.ViewLegendContainer>
        <A.ViewLegendItem>
          <IconPrimr
            name="dot"
            color={C["color-warning-600"]}
            preset="safe"
            size={16}
          />
          <Txt.S1>{tr("Team")}</Txt.S1>
        </A.ViewLegendItem>
        <A.ViewLegendItem>
          <IconPrimr name="dot" color={"#2d8cff"} preset="safe" size={16} />
          <Txt.S1>{tr("Personal")}</Txt.S1>
        </A.ViewLegendItem>
        {/* <A.ViewLegendItem>
          <IconPrimr name="dot" color={C["color-warning-600"]} preset="safe" />
          <Txt>{tr("Other")}</Txt>
        </A.ViewLegendItem> */}
      </A.ViewLegendContainer>
      <Kitten.Divider_________ />
      <Kitten.ViewH
        style={{
          justifyContent: "space-between",
          paddingHorizontal: spacing(2),
          marginVertical: spacing(3),
        }}
      >
        <A.TxtDate style={{ marginLeft: spacing(2) }}>
          {selectedDateStr}
        </A.TxtDate>
        <Buttoon
          size="small"
          appearance="ghost"
          status="control"
          onPress={() => {
            if (IS_WEB) {
              Toasty.show("Not currently available on web.", {
                type: "warning",
              });
              return;
            }
            const _startDate = new Date(selectedDate);
            _startDate.setHours(12, 0, 0, 0);
            const _endDate = new Date(selectedDate);
            _endDate.setHours(_startDate.getHours() + 1, 0, 0, 0);
            AddCalendarEvent.presentEventCreatingDialog({
              title: "",
              startDate: _startDate.toISOString(),
              endDate: _endDate.toISOString(),
            })
              .then(async (evt) => {
                if (evt.action === "SAVED") {
                  const savedEvent = await EXCalendar.getEventAsync(evt.calendarItemIdentifier || evt.eventIdentifier)

                  const evtDate = forsureDate(savedEvent.startDate)
                  const timezoneAbbr = new Date()
                    .toLocaleTimeString("en-us", { timeZoneName: "short" })
                    .split(" ")[2];
                  recordUserAction(userHandler.data, ACTION_TYPE.SCHEDULED_MEETING, `${userHandler.data?.personali?.displayName} created a meeting \"${savedEvent.title}\" for ${fn.calendar.niceDateFormat(evtDate)} at ${fn.calendar.timeToAMPMString(evtDate)} ${timezoneAbbr}`, { location: savedEvent.id, involvedPartyName: savedEvent.title })

                  if (!(userHandler.data?.personalCalendars || [])?.includes(savedEvent.calendarId)) {
                    // the id of this calendar is not yet integrated
                    Poppy("Sync selected calendar?", "The calendar you selected for this event is not synced with your app. Would you like to sync it now?", { text: "No, thanks" }, {
                      text: "Sync (recommended)", onPress: async () => {
                        userHandler.update({ personalCalendars: firebase.firestore.FieldValue.arrayUnion(savedEvent.calendarId) })
                        Toasty.show("Sync complete", { type: 'success' })
                        getAllPersonalCalendarEvents(
                          userHandler.data?.personalCalendars
                        ).then((evts) => {
                          setPersonalEvents(evts);
                        });
                        Toasty.show("Event added", { type: "success" })
                      }
                    })
                  } else {
                    Toasty.show("Event added", { type: "success" });
                    getAllPersonalCalendarEvents(
                      userHandler.data?.personalCalendars
                    ).then((evts) => {
                      setPersonalEvents(evts);
                    });
                  }
                }
              })
              .catch((error: string) => {
                Toasty.show(error, { type: "danger" });
                console.error(error);
              });
          }}
          icon={{ name: "plus" }}
        >
          {tr("Add Event")}
        </Buttoon>
      </Kitten.ViewH>
      <FlatList
        scrollEnabled={false}
        style={{ marginBottom: spacing(5) }}
        // style={{height: "100%", backgroundColor: "red", padding: 25}}
        data={selectedDateStr ? eventsByDate[selectedDateStr] : []}
        renderItem={({ item, index }) => (
          <EventCard
            event={item}
            onAttended={async (event) => {
              Toasty.show("Thanks for attending!");
              try {
                await userAttendanceHandler.addAttendance(frbsUser?.uid, {
                  _teamId: teamId,
                  //@ts-ignore will auto transform to Timestamp
                  attendedAt: new Date(),
                  guests: [],
                  //@ts-ignore will transform to Timestamp
                  meetingStartTime: event.date,
                  //@ts-ignore will transform to Timestamp
                  meetingEndTime: event.endDate,
                  attendee: {
                    uid: frbsUser?.uid,
                    displayName:
                      userHandler.data?.personali?.displayName || "Unknown",
                    photoURL: userHandler.data?.personali?.photoURL,
                  },
                  meetingId: event.eventId,
                });
                recordUserAction(userHandler.data, ACTION_TYPE.JOINED_MEETING, `${userHandler.data?.personali?.displayName} joined meeting \"${event.title}\".`, { location: event.eventId, involvedPartyName: event.title })
              } catch (err) {
                console.error(err);
                Toasty.show(err, { type: "danger" });
              }
            }}
            userAttendance={userAttendanceHandler.data}
          />
        )}
        ListEmptyComponent={() => (
          <Txt.Indicator marginV>
            {loading ? tr("Loading...") : tr("No event")}
          </Txt.Indicator>
        )}
      />
      {!IS_WEB ? (
        <Buttoon
          style={{
            marginBottom: spacing(6),
            marginTop: spacing(4),
            marginHorizontal: spacing(5),
          }}
          size="small"
          status="control"
          icon={{ name: "calendar" }}
          appearance="filled"
          onPress={async () => {
            try {
              await EXCalendar.requestCalendarPermissionsAsync();
              if (Platform.OS === "ios")
                await EXCalendar.requestRemindersPermissionsAsync();
              const myCals = await EXCalendar.getCalendarsAsync();
              Navigation.dialogPortal.open({
                headerTitle: "Your Calendars",
                render: <PersonalCalendar myCalendars={myCals} />,
              });
            } catch (err) {
              alert(err);
            }
          }}
        >
          Add personal calendar
        </Buttoon>
      ) : null}
    </ScrollView>
  );
}

const A = {
  // CardEvent: sstyled(createAnimatableComponent(Kitten.Card))((p) => ({
  //   // marginVertical: spacing(3),
  // })),
  CardEvent: sstyled(Kitten.Card)((p) => ({
    // marginVertical: spacing(3),
  })),
  TxtDate: sstyled(createAnimatableComponent(Txt.H5))({ textAlign: "center" }),

  //@ts-expect-error sstyled interface accepts only functional comp, but in fact it can also receive class comp
  Calendar: sstyled(Calendar)({
    backgroundColor: "background",
    borderRadius: scale(4),
    // paddingBottom: spacing(4),
  }),
  ViewLegendContainer: sstyled(View)({
    flexDirection: "row",
    justifyContent: "space-evenly",
  }),
  //@ts-expect-error sstyled doesnt like marginHorizontal
  ViewLegendItem: sstyled(Kitten.ViewH)({ marginHorizontal: spacing(2) }),
  ThemedCalendar: ({ C }: { C: dColors }) =>
  ({
    backgroundColor: "transparent",
    calendarBackground: "transparent",
    textSectionTitleColor: C.primaryDarker,
    monthTextColor: C.primaryDarker,
    textMonthFontWeight: "bold",
    textDayHeaderFontWeight: "bold",
    textMonthFontSize: 18,
    textDayHeaderFontSize: 14,
  } as Theme),
};
