//#region [import]
import { TwoColumns } from "components/templates";
import * as React from "react";
import { AgendaCenter } from "./AgendaCenter";
//#endregion

/**
 * ###  Screen of Calendar and its CRUD ops
 * -  The Screen contains specific layout.
 * -  Some screen may use `$_QuickGlance`, which is the core <_> of CalendarScreen
 * @version 1.1.23
 * @author K
 */
export function CalendarScreen() {
  // const { C } = useAppContext();
  try {
    return (
      <TwoColumns
        column1Content={(scrollHandler) => <AgendaCenter />}
        column2Content={[]}
      />
    );
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}
