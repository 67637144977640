import { MediaPageItemSchema } from "engines";

export interface dCollaboration {
  srcPageId?: string;
}

// /**
//  * ### Collaboration Feature for the Web App.
//  *  - Collaboration feature that will allow cross-hierarchy content(a.k.a media resources) sharing across the web.
//  *  ----
//  *  @example
//  *  <Collaboration {...props}>
//  *  ----
//  *  @version 21.11.05
//  *  -  *Creating the Collaboration Feature*
//  *  @author  Saurabh_M
//  *
//  **/
// export function Collaboration(props: P) {
//   const { srcPageId } = props.route.params;

//   const { setIsCollabModeOn, setCopyToPageId } = useCollabContext();

//   React.useEffect(() => {
//     setIsCollabModeOn(true);
//     if (srcPageId) {
//       setCopyToPageId(srcPageId);
//     }
//     // return () => setIsCollabModeOn(false);
//   }, []);

//   return (
//     <TwoColumns
//       bottomInset
//       column1Content={(scrollHandler) => <CollaborationHomeFeed />}
//       column2Content={[]}
//     />
//   );
// }

export interface dSearchResultCollab extends MediaPageItemSchema {
  pageId: string;
  mediaType: string;
  _teamId: string;
  hierarchyLogo: string;
}
export enum FILTERTYPES {
  "ALL" = "ALL",
  "VIDEO" = "VIDEO",
  "AUDIO" = "AUDIO",
  "AUDIO_FOLDER" = "AUDIO FOLDER",
  "WEBSITE" = "WEBSITE",
  "TOPAGE" = "TO PAGE",
  "IMAGE" = "IMAGE",
  "TITLE" = "TITLE",
  "PARAGRAPH" = "PARAGRAPH",
}
// interface P {
//   route?: { params?: dCollaboration }; //* TODO remember to create dResourcesBodyScreenParam
// }
