import { Buttoon, Kitten, ListHeader } from "components";
import { StatsCreatorCenter, UserStatsFeed } from "components/organisms";
import { UserStatsOverview } from "components/organisms/personal-activity/UserStatsOverview";
import { useAppContext } from "engines";
import { ScrollView } from "react-native-gesture-handler";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";

export function StatsScreen(p: { route?: any; uid?: string }) {
  const { route, uid } = p;
  const { C } = useAppContext();
  const altUid = uid || route?.params?.uid;
  const Navigation = useNavigator();

  return (
    <ScrollView style={{ flex: 1, backgroundColor: C.background }}>
      <UserStatsOverview showStreakShortcut uid={altUid} />
      <Kitten.Divider_________ />
      <ListHeader
        titleTx="Activity History"
        style={{ marginTop: spacing(3), backgroundColor: "transparent" }}
        accessoryRight={() => (
          <Buttoon
            onPress={() => {
              Navigation.overlayPortal.open({
                headerTitle: "Daily Progress Report",
                render: <StatsCreatorCenter />,
              });
            }}
            size="tiny"
            appearance="outline"
            status="basic"
            icon={{ name: "pen" }}
          >
            {tr("Report Today")}
          </Buttoon>
        )}
      />
      <UserStatsFeed uid={altUid} />
    </ScrollView>
  );
}
