import { useFocusEffect } from "@react-navigation/native";
import { sstyled } from "components";
import { Kitten, ListHeader, Txt } from "components/atoms";
import { useAppContext } from "engines";
import { useTrophyCollection } from "engines/firebase/handler/config-handler";
import React from "react";
import { ActivityIndicator, FlatList, Image, View } from "react-native";
import * as Animatable from "react-native-animatable";
import { tr } from "utils";
/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.23
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function TrophiesFeed() {
  //   const { addFunc } = props;
  const { teamId } = useAppContext();

  const { data, error, add } = useTrophyCollection(`variables-${teamId}`);

  console.log("trophy data", data);
  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  try {
    if (error) return <Txt.Helper>{tr("Cannot load trophies")}</Txt.Helper>;
    if (!data) return <ActivityIndicator />;

    return (
      <A.CtnrPersonali>
        {/* <ListHeader titleTx={tr("Trophy case")} /> */}
        <FlatList
          data={data}
          numColumns={4}
          renderItem={({ item, index }) => (
            <View style={{ padding: 10 }}>
              <Image
                source={{
                  uri: item.uri,
                }}
                style={{ width: 64, height: 50 }}
              />
              <Txt.$Title
                style={{ fontSize: 10, paddingTop: 5, textAlign: "center" }}
              >
                {tr(item.name)}
              </Txt.$Title>
            </View>
          )}
          ItemSeparatorComponent={Kitten.Divider_________}
          ListEmptyComponent={
            <Txt.Indicator marginV>{tr("No trophy found")}</Txt.Indicator>
          }
        />
      </A.CtnrPersonali>
    );
  } catch (error) {
    throw Error(`::QWRkQ29udGFjdENyZWF0b3I=::${error}`);
  }
}

const A = {
  CtnrPersonali: sstyled(Animatable.View)((p) => ({
    justifyContent: "flex-start",
  })),
};
