/**
 * ###
 *  - Checks throught the array of errors and returns if it exist
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.12
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function checkExistError(errocode, data) {
  for (let index = 0; index < data?.length; index++) {
    const element = data[index]?.fields;
    if (element.customfield_10063 == errocode) {
      return true;
    }
  }
  return null;
}
