//#region [import]
import {
  Buttoon,
  Kitten,
  ListHeader,
  MemberSelect,
  sstyled,
  Toasty
} from "components";
import { useAppContext, __MediaResourcesData } from "engines";
import { useMemberDocument } from "engines/firebase/handler/members-handler";
import { usePagesCollection } from "engines/firebase/handler/pages-handler";
import firebase from "firebase";
import React from "react";
import { ScrollView, TextInput } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
//#endregion

/**
 * ###
 *  - This form is used in creating a base shop
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.10.19
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function BaseShopCreatorCenter() {
  const refBSName = React.useRef<TextInput>();
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  const pagesHandler = usePagesCollection();

  const [title, setTitle] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [bsOwner, setBsOwner] = React.useState<string>("");

  const handleMember = useMemberDocument(bsOwner);
  React.useEffect(() => {
    refBSName.current?.focus();
  }, []);

  function addBaseShop() {
    console.log("bsOwner ", bsOwner);
    pagesHandler
      .add({
        ...__MediaResourcesData({
          teamId,
          pageName: title,
        }),
        mediaItem: { visible: true, team: true },
        password: password ? password : "",
      })
      .then((bsId) => {
        console.log("bsId: ", bsId);
        bsOwner
          ? handleMember.update({
              baseShopId: `${bsId}`,
              roles: firebase.firestore.FieldValue.arrayUnion(`BS:${bsId}`),
            })
          : null;

        Navigation.goBack();

        Toasty.show(tr("Base shop") + " " + title + " " + tr("created!"), {
          type: "success",
        });
      })
      .catch((e) => {
        Toasty.show(tr("Failed to create base shop"), { type: "danger" });
        Navigation.goBack();
      });
  }

  return (
    <A.ViewBuilder>
      <ListHeader title={tr("Create a base shop")} />
      <>
        <A.Input
          ref={refBSName}
          placeholder={tr("Base shop name") + " *"}
          autoCapitalize="none"
          value={title}
          onChangeText={setTitle}
        />
        <A.Input
          placeholder={tr("Base shop password") + " " + tr("(optional)")}
          autoCapitalize="none"
          value={password}
          onChangeText={setPassword}
        />
        <ListHeader title={tr("Select base shop leader")} />
        <MemberSelect
          onChange={(userSelected) => {
            console.log(userSelected);
            setBsOwner(userSelected);
          }}
          flexDirection={"row"}
        />
        <Kitten.Divider_________ />
        <Buttoon disabled={!title} onPress={addBaseShop}>
          {tr("Create")}
        </Buttoon>
      </>
    </A.ViewBuilder>
  );
}

const A = {
  ViewBuilder: sstyled(ScrollView)((p) => ({
    // flex: 1,
    // backgroundColor: "background",
    width: "100%",
    marginBottom: 10,
  })),
  Input: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
};
