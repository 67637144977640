import { Buttoon, Toasty } from "components";
import { useTwoColumns } from "components/templates";
import { fn, MediaPageSchema, useUserProfile } from "engines";
import { TOPICTYPES } from "engines/functions/notifications-functions";
import firebase from "firebase";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { IS_WEB, tr } from "utils";
import { ass, ASS_KEY } from "utils/storage";

/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.05.25
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function LeaveBaseShop(props: P) {
  const { handlerPage } = props;

  const userProfile = useUserProfile();

  const { breakpointForTwoColumns } = useTwoColumns();
  const Navigation = useNavigator();

  React.useEffect(function closePage() {
    !breakpointForTwoColumns && leaveBS();
  }, []);

  async function leaveBS() {
    Navigation.navigate("BaseShops");
    //* Remove Base shop info (BSIDINFO) from aysncstorage
    let removeBSID = ass.remove(ASS_KEY.BSIDINFO);
    if (removeBSID)
      await userProfile.update({
        baseShopId: firebase.firestore.FieldValue.delete(),
      });

    Toasty.show(tr("You have sucessfully left this base shop"), {
      type: "success",
    });
    // Navigation.navigate("BaseShops");

    IS_WEB
      ? null
      : fn.notifications
          .unSubscribeToTopic(
            userProfile?.data?._teamId,
            TOPICTYPES.BASESHOP,
            handlerPage?._id
          )
          .then((res) => {
            console.log("response from registering ", res);
          });
  }
  return breakpointForTwoColumns ? (
    <>
      <Buttoon
        onPress={() => {
          leaveBS();
        }}
        status="danger"
        icon={{ name: "exit" }}
      >
        {tr("Leave") + " " + handlerPage?.name}
      </Buttoon>
    </>
  ) : (
    <View />
  );
}

interface P {
  handlerPage?: MediaPageSchema;
}
