import { firestore } from "firebase-admin";
import { MediaPageItemSchema } from "./schemas/web.schemas";

export interface dLoginInfo {
  email: string;
  password: string;
  accessCode: string;
  subdomain?: string;
  teamId?: string;
}
export interface dCallableValidResponse {
  errorCode: number | null;
  errorMessage: string | null;
  success: boolean;
  userType: FRBS_ROLE | null;
  _teamId: string;
  _parentAppId?: string;
}

export enum ERRMSG {
  USER_NOT_EXISTS = "User doesn't exist.",
  ACC_CODE_INVALID = "Invalid access code",
  ERR = "Error while validating user ",
  BANNED = "Access denied"
}

//#region [rbga(255,255,0,0.1)] Notifications Section
export enum NotificationStatus {
  SENT = "sent",
  SCHEDULED = "scheduled",
}
export interface dNotification {
  channel: string;
  id?: string;
  status: NotificationStatus;
  /**
   * When the notification was sent or when it is scheduled to be sent
   */
  timestamp?: firestore.Timestamp | Date;
  title: string;
  message: string;
  video?: string;
  url?: string;
  comments: dComment[];
  option?: RECTYPE;
}

export interface dComment {
  uid: string;
  timestamp: firestore.Timestamp | Date;
  name: string;
  profileImage?: string;
  message: string;
}
export enum RECTYPE {
  "DAILY" = "Daily",
  "WEEKLY" = "Weekly",
  "MONTHLY" = "Monthly",
  "YEARLY" = "Yearly",
  "BLANK" = "",
}
//#endregion

export enum WEBFPATH {
  CONFIG = "configs",
  MORE = "more",
  PAGES = "pages",
  POSSTS = "possts",
  COMMENTS = "comments",
  SCOREBOARDS = "scoreboards",
  USERS = "users",
  CUSTOM_PAGE_CONTENT = "pageContent",
  CONTACT_GROUPS = "contact-groups",
  CONTACTS = "contacts",
  TROPHIES = "trophies",
}

export enum MOBFPATH {
  CONFIG = "config",
  MORE = "more",
  PAGES = "pages",
  POSSTS = "possts",
  COMMENTS = "comments",
  SCOREBOARDS = "scoreboard",
  USERS = "users",
  CUSTOM_PAGE_CONTENT = "pageContent",
  NOTIFICATIONS = "notifications",
  TROPHIES = "trophies",
}

export enum COLL {
  CONFIG_KEYS = "keys",
  CONFIG_VARIABLES = "variables",
  SB_SCORES = "scores",
}

/**
 * @description DB Action Type enum
 * @author Saurabh_M
 * @version 2021.08.31
 * @example ACTIONTYPE.ADD,
 * ACTIONTYPE.DELETE,
 * ACTIONTYPE.UPDATE
 */
export enum ACTIONTYPE {
  ADD = "add",
  DELETE = "delete",
  UPDATE = "update",
}

export enum FRBS_BUCKET {
  /**
   * Dir stored user-related files (e.g. avatar...)
   */
  USERS = "users",
  /**
   *  Dir stored posst-related files (e.g. posst's image, video...)
   */
  POSSTS = "possts",
}

export enum THEME {
  NULL = "null",
  LIGHT = "themeLight",
  DARK = "themeDark",
}

export enum FRBS_ROLE {
  TRAINER = "TRAINER",
  /**
   * Devs team,
   * those who can access to FRBS admin
   */
  ADMIN = "ADMIN",
  /**
   * Team leader,
   * who lead the big team
   */
  BS = "BS:",
  /**
   * Hierarchy leader,
   * who lead the hierarchy/branch of the team
   */
  HEADER = "HEADER",
  /**
   * New member of the team,
   * who hasn't finished onboarding tasks
   */
  NEWBIE = "NEWBIE",
  BANNED = "BANNED",
  DEVELOPER = "DEVELOPER",
}

export interface dListOfPasswordHash {
  [projectId: string]: dPasswordHash;
}
/**
 * @description This is required for creating users with same password in another firebase project, check README.md inside serviceAccount folder
 */
export interface dPasswordHash {
  algorithm: string;
  base64_signer_key: string;
  base64_salt_separator: string;
  rounds: number;
  mem_cost: number;
}

export interface dUserPWDData {
  uid: string;
  passwordHash: string;
  passwordSalt: string;
}

export enum TOOLCENTER {
  SCOREBOARDS = "Scoreboards",
  CONTACTMANAGER = "Contact Manager",
  MASSMAIL = "Mass Mail",
  NOTIFICATIONS = "Notifications",
  SMSEMUP = "SmsEmUp",
}

/**
 * EXPIREAFTER - Cache will expire after these many hours
 */
export enum CACHE {
  EXPIREAFTER = 12,
}

export interface dSearchCF {
  query: string;
  // allHierarchiesInfo: dAppConfigData[];
}

export interface dSearchResultCollab extends MediaPageItemSchema {
  pageId: string;
  mediaType: string;
}

export interface dWebVersionResp {
  _teamId: string | null;
  success: boolean;
  isWebVersionPaid: boolean;
  errorMessage: string | null;
  errorCode: number | null;
}

export interface dWebVersionSub {
  subdomain: string;
}

export interface ProvidedData {
  fetchUrl: string;
  calendarAccessToken: string;
}
