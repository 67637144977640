import { Avt, Kitten, Toasty, Txt } from "components";
import { MediaPageItemSchema, useAppContext } from "engines";
import {
  ContactGroupSchemaa,
  ContactSchemaa
} from "engines/backends/schemas/bml/contacts/contacts.schema";
import {
  useContactGroupDocument,
  useContactGroupsCollection
} from "engines/firebase/handler/contact-groups-handler";
import { useContactsCollection } from "engines/firebase/handler/contact-handler";
import React from "react";
import { FlatList, View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";

export function ContactGroupSelector(props: P) {
  const { addEntryClick } = props;
  const { data } = useContactGroupsCollection(props?.userId);
  // console.log("contact information data ", data);

  const [emailGroups, setEmailGroups] = React.useState<boolean>(false);
  const [list, setList] = React.useState<boolean>(false);

  return React.useMemo(() => {
    try {
      return (
        <View>
          <FlatList
            data={data}
            renderItem={({ item, index }) => (
              <ContactsHomeItem
                key={index}
                item={item}
                index={index}
                c_uid={props?.userId}
                sharedItems={props?.sharedItems}
                addEntryClick={addEntryClick}
              />
            )}
            ItemSeparatorComponent={Kitten.Divider}
            ListEmptyComponent={
              <Txt.Indicator marginV>
                {tr("No contact group found")}
              </Txt.Indicator>
            }
          />
        </View>
      );
    } catch (error) {
      throw Error(`::ContactsHomeCenter::${error}`);
    }
  }, [data, list, emailGroups]);
}

function ContactsHomeItem(props: dContactsHomeItem) {
  const { item, addEntryClick, c_uid } = props;
  // console.log("item ", item);
  const { C } = useAppContext();
  const Navigation = useNavigator();
  // const refItemSwipeable = React.useRef<Swipeable>();
  const contactGroupHandler = useContactGroupDocument(c_uid, item?._id);
  const contactsHandler = useContactsCollection(c_uid, {
    // where: [["groups", "array-contains", item?._id]],
  });
  const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);

  React.useEffect(
    function setupData() {
      contactsHandler.data &&
        setContacts(
          // contactsHandler.data
          contactsHandler.data?.filter(
            (c) =>
              c?.groups?.includes(item?._id) ||
              item?.contacts?.includes(c?._id) ||
              c?.listId === item?._id
          )
        );
    },
    [contactsHandler.data]
  );

  return (
    <Kitten.ListItemv2
      title={item.name}
      style={[
        contactGroupHandler.data?.name == "*New Contact Group" && {
          backgroundColor: C.errorRed,
        },
        item?.contacts?.length <= 0 && {
          backgroundColor: C["color-basic-disabled"],
        },
        { minHeight: 74 },
      ]}
      // disabled={contacts?.length <= 0 ? true : false}
      onPress={() => {
        if (item?.contacts?.length < 1) {
          Toasty.show("This list appears to have 0 contacts.", {
            type: "info",
          });
        }
        addEntryClick && addEntryClick({ ...item, contactObjects: contacts });
        Navigation.goBack();
      }}
      description={() => (
        <>
          <Avt.Row
            data={contacts?.map((contact) => ({
              name: contact.name || contact.displayName || contact.email,
              source: {
                uri: "",
              },
            }))}
          />
          {contacts?.length <= 0 || !contactsHandler.data ? (
            <Txt style={{ color: C.grey600 }}>
              {!contactsHandler.data
                ? `Loading contacts...`
                : `${item?.contacts?.length} contacts`}
            </Txt>
          ) : null}
        </>
      )}
    />
  );
}

interface dContactsHomeItem {
  index: number;
  item: ContactGroupSchemaa;
  c_uid?: string;
  sharedItems?: MediaPageItemSchema[];
  addEntryClick?(item: ContactGroupSchemaa | ContactSchemaa): Promise<void>;
}

// const A = {
//   ViewBuilder: sstyled(ScrollView)((p) => ({
//     // flex: 1,
//     // backgroundColor: "background",
//     width: "100%",
//     marginBottom: 10,
//     paddingHorizontal: spacing(2),
//   })),

//   Input: sstyled(TextInput)({
//     minWidth: 7,
//     color: "text",
//     fontSize: scale(16),
//     padding: spacing(3),
//     margin: spacing(3),
//     borderBottomWidth: 1,
//     borderColor: "primary",
//   }),
//   CheckBox: sstyled(Kitten.CheckBox)({
//     minWidth: 7,
//     color: "text",
//     fontSize: scale(16),
//     padding: spacing(3),
//     margin: spacing(3),
//     // borderBottomWidth: 1,
//     // borderColor: "primary",
//   }),
//   // Thumbnail: sstyled(Imaggie)({
//   //   alignSelf: "center",
//   //   // width: p.mediaDimension.width,
//   //   // height: p.mediaDimension.height,
//   //   width: 20,
//   //   height: 20,
//   //   marginRight: IS_WEB ? 0 : scale(5),
//   //   backgroundColor: "dim",
//   // }),
// };

interface P {
  userId: string;
  sharedItems?: MediaPageItemSchema[];
  addEntryClick?(item: ContactGroupSchemaa | ContactSchemaa): Promise<void>;
}
