import { IconPrimr } from "assets";
import { AutocompleteTags, Kitten, Skeleton, sstyled, Txt } from "components";
import { AutocompleteTagsProps } from "components/atoms";
import { useTwoColumns } from "components/templates";
import { useAppContext } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml/contacts/contacts.schema";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { FlatList, ImageBackground, ScrollView, View } from "react-native";
import { tr } from "utils";
import { ContactProfile } from "./ContactProfile";
/**
 * @deprecated don't use this
 * ### Input for typing multiple contact's
 *  - Detailed explanation
 *  ----
 *  @example
 *  <M.ContactsMultiInput onContactsCallback={setNewContacts} />
 *  ----
 *  @version 21.08.04
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 * @deprecated DO NOT USE THIS VERSION, IT is only a CONCEPT
 **/
interface dContactsMultiInput extends Partial<AutocompleteTagsProps> {
  title: string;
  onContactsCallback(phoneContacts: ContactSchemaa[]): void;
  screenId?: string;
  handleContactDeletion: Function;
  autoFocus?: boolean;
}

/**
 * @deprecated DO NOT USE
 */
export function ContactsMultiInputV2(props: dContactsMultiInput) {
  const {
    title = "Contacts",
    onContactsCallback,
    handleContactDeletion,
    autoFocus,
  } = props;
  const numCols = 4;

  const { teamId, C, frbsUser } = useAppContext();
  const { contacts } = useBMLMContext();

  const verticalScrollViewRef = useRef<ScrollView>(null);
  const selectedContactsFLRef = useRef<FlatList>(null);

  const [offset, setOffset] = useState(10);
  const [query, setQuery] = useState("");

  const { column1ContainerStyle } = useTwoColumns();
  //#region Remove from here
  /** All Contacts from the phone */
  //const [contacts, setContacts] = useState<Contacts.Contact[]>([]);

  /** Contacts selected by the user while using BML Feature */
  const [screenBmlContacts, setScreenBmlContacts] = useState<ContactSchemaa[]>(
    []
  );
  //#endregion TILL HERE

  /** Auto populate the suggestion while typing */
  const [suggestions, setSuggestions] = React.useState<ContactSchemaa[]>([]);

  React.useEffect(
    function updateSuggestionsData() {
      if (!!contacts && query && query.length >= 3) {
        let _reducedData: ContactSchemaa[] = contacts
          .filter((c) => c.name) //NOTE This filter was added to avoid contacts with no name causing issue on BML (endless "Unnamed")
          .filter((c) => c.phoneNumbers) //NOTE This filter was added to avoid contacts with no phoneNumber
          .reduce(
            (a, c) => [
              ...a,
              {
                _cid: c.id,
                ownerUid: frbsUser?.uid,
                displayName: c.name,
                phoneNumbers: c.phoneNumbers,
                _phoneNumberStrings: (c?.phoneNumbers || [])?.map(
                  (pn) => pn.digits || pn.number?.replace(/\D/g, "")
                ),
                listId: "BML",
                qualifications: [],
                email: c.emails ? c.emails[0].email : "",
              },
            ],
            []
          );

        setSuggestions(
          _reducedData
            .filter((c) =>
              c.displayName.toLowerCase().includes(query.toLowerCase())
            )
            .sort((c1, c2) => c1?.displayName?.localeCompare(c2?.displayName))
            .slice(0, offset)
        );
      } else {
        setSuggestions([]);
      }
    },
    [contacts, offset, query]
  );

  // const [tags, setTags] = useState<dContactSuggestion[]>([]);

  // React.useEffect(
  //   /**
  //    * When u select a contact,
  //    * callback functions (selectedEmailsCallback, selectedIdsCallback)
  //    * will be called
  //    */
  //   function callback() {
  //     onContactsCallback && onContactsCallback(tags);
  //   },
  //   [tags]
  // );

  if (!contacts) return <Skeleton.Screen.Members length={5} />;

  const handleContactSelect = (contact: ContactSchemaa) => {
    const finalContacts = _.unionBy(screenBmlContacts, [contact], "_cid");
    setScreenBmlContacts(finalContacts);
    onContactsCallback(finalContacts);
  };

  // #region [ANCHOR2] Render the contact the user selected and display it's full name when tapped
  const SelectedContacts = ({ contact }: { contact: ContactSchemaa }) => {
    const [showFullName, setShowFullName] = useState(false);
    const [contactName, setContactName] = useState("");

    React.useEffect(() => {
      if (showFullName) {
        setContactName(contact.displayName);
      } else {
        const fNameInitial = contact.displayName?.includes(" ")
          ? contact.displayName
              .replaceAll("  ", " ")
              .split(" ")[0][0]
              .toUpperCase()
          : contact.displayName?.slice(0, 1).toUpperCase();

        const lNameInitial = contact.displayName?.includes(" ")
          ? contact.displayName
              .replaceAll("  ", " ")
              .split(" ")[1][0]
              .toUpperCase()
          : "";
        const display = lNameInitial
          ? fNameInitial + lNameInitial
          : fNameInitial;
        setContactName(display);
      }
    }, [showFullName]);

    // const generateColor = () => {
    //   const randomColor = Math.floor(Math.random() * 16777215)
    //     .toString(16)
    //     .padStart(6, "0");
    //   return `#${randomColor}`;
    // };

    return (
      <>
        <Kitten.ListItemv2
          style={{
            width: 90,
            height: 40,
            borderRadius: 20,
            backgroundColor: "lightblue",
            margin: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => setShowFullName(!showFullName)}
          activeOpacity={0.5}
          title={
            contactName.slice(0, 20) + (contactName.length > 20 ? "..." : "")
          }
          onPressOut={() => setShowFullName(false)}
          accessoryRight={() =>
            showFullName && (
              <IconPrimr
                name={"x"}
                size={12}
                color={C["color-danger-600"]}
                onPress={() => {
                  setScreenBmlContacts(
                    screenBmlContacts.filter((c) => c._cid != contact?._cid)
                  );
                  handleContactDeletion(contact);
                }}
              />
            )
          }
        />
        {/* <Txt.C2 onPress={() => setShowFullName(!showFullName)}></Txt.C2> */}
      </>
    );
  };
  // #endregion

  return (
    <View style={{ width: "100%" }}>
      <Kitten.Input
        placeholder="Type contact name..."
        onChangeText={(value) => setQuery(value)}
      />
      {/* {screenBmlContacts && screenBmlContacts.length > 0 && (
        <FlatList
          ref={selectedContactsFLRef}
          data={screenBmlContacts}
          keyExtractor={(item) => item._cid}
          numColumns={numCols}
          renderItem={({ item, index }) => <SelectedContacts contact={item} />}
          ListFooterComponent={() => <View style={{ marginBottom: 50 }} />}
          onContentSizeChange={() =>
            screenBmlContacts.length > numCols &&
            selectedContactsFLRef.current.scrollToEnd({ animated: true })
          }
        />
      )} */}
      <Kitten.Divider_________ />
      <FlatList
        data={suggestions}
        keyExtractor={(item) => item._cid}
        contentContainerStyle={[column1ContainerStyle, { flexGrow: 1 }]}
        renderItem={({ item, index }) => (
          <CMI.ContactList
            key={item?.id + "-" + index.toString()}
            title={item?.displayName}
            description={
              item?.phoneNumbers?.find((c) => c.number !== "").number
            }
            accessoryLeft={() => <ContactProfile contact={item} />}
            onPress={() => handleContactSelect(item)}
          />
        )}
        onEndReached={() => setOffset(offset + 5)}
        keyboardShouldPersistTaps={"handled"}
        nestedScrollEnabled
      />
      {/* <ScrollView
        ref={verticalScrollViewRef}
        style={{ width: "95%", marginTop: 5 }}
        onContentSizeChange={() =>
          verticalScrollViewRef.current.scrollToEnd({ animated: true })
        }
        showsVerticalScrollIndicator={false}
        scrollEnabled={suggestions.length > 8 ? true : false}
      >
        {!!suggestions &&
          suggestions.map((bmlContact, index) => (
            <CMI.ContactList
              key={bmlContact?.id + "-" + index.toString()}
              title={bmlContact?.displayName}
              description={
                bmlContact?.phoneNumbers?.find((c) => c.number !== "").number
              }
              accessoryLeft={() => <ContactProfile contact={bmlContact} />}
              onPress={() => console.log("Contact selected")}
              // accessoryRight={() => (
              //   <IconPrimr
              //     name={"x"}
              //     size={12}
              //     color={C["color-danger-600"]}
              //     onPress={() => {
              //       setScreenBmlContacts(
              //         screenBmlContacts.filter(
              //           (c) => c._cid != bmlContact?._cid
              //         )
              //       );
              //       setTags(tags.filter((t) => t._cid !== bmlContact?._cid));
              //       handleContactDeletion(bmlContact);
              //     }}
              //   />
              // )}
            />
          ))}
      </ScrollView> */}
    </View>
  );
  return (
    <React.Fragment>
      {/* //@ts-ignore */}
      <AutocompleteTags
        inputProps={{
          autoFocus: autoFocus,
          autoCapitalize: "none",
          autoCorrect: false,
          placeholder: tr("Type here..."),
          placeholderTextColor: C.grey600,
          style: { color: C.text, fontWeight: "bold" },
        }}
        flatListStyle={{ backgroundColor: C.background }}
        {...props}
        tags={tags}
        suggestions={suggestions}
        labelExtractor={(item: ContactSchemaa) =>
          `${item.displayName || "Unnamed"}`
        }
        suggestionExtractor={(item: ContactSchemaa) =>
          `${item.displayName || "Unnamed"}`
        }
        onChangeTags={(incomingTags) => {
          const finalTags = _.unionBy(incomingTags, tags, "_cid");
          setScreenBmlContacts(finalTags);
          setTags(finalTags);
        }}
        allowCustomTags={true}
        renderTag={(tag: ContactSchemaa) => null}
        accessoryLeft={() => <Txt.Helper>{title}</Txt.Helper>}
      />
      <ScrollView
        ref={verticalScrollViewRef}
        style={{ width: "95%", marginTop: 5 }}
        onContentSizeChange={() =>
          verticalScrollViewRef.current.scrollToEnd({ animated: true })
        }
        showsVerticalScrollIndicator={false}
        scrollEnabled={screenBmlContacts.length > 8 ? true : false}
      >
        {!!screenBmlContacts &&
          screenBmlContacts.map((bmlContact, index) => (
            <CMI.ContactList
              key={bmlContact?.id + "-" + index.toString()}
              title={bmlContact?.displayName}
              description={
                bmlContact?.phoneNumbers?.find((c) => c.number !== "").number
              }
              accessoryLeft={() => <ContactProfile contact={bmlContact} />}
              accessoryRight={() => (
                <IconPrimr
                  name={"x"}
                  size={12}
                  color={C["color-danger-600"]}
                  onPress={() => {
                    setScreenBmlContacts(
                      screenBmlContacts.filter(
                        (c) => c._cid != bmlContact?._cid
                      )
                    );
                    setTags(tags.filter((t) => t._cid !== bmlContact?._cid));
                    handleContactDeletion(bmlContact);
                  }}
                />
              )}
            />
          ))}
      </ScrollView>
    </React.Fragment>
  );
}

export const CMI = {
  ContactList: sstyled(Kitten.ListItem)({
    //marginY: 2,
    backgroundColor: "transparent",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
  }),
  ImageBG: sstyled(ImageBackground)({
    width: 40,
    height: 40,
    borderRadius: 20,
  }),
};
