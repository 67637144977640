import { IconPrimr, ICON_NAME } from "assets";
import { dAccessory, Kitten } from "components";
import { sstyled } from "components/atoms";
import { useAppContext, __MediaResourcesData } from "engines";
import { usePagesCollection } from "engines/firebase/handler/pages-handler";
import React from "react";
// import { useNavigator } from "screens";
import { tr } from "utils";

export function MediaHomeActionsList(props: P) {
  // const { sbid } = props;
  const { C, teamId } = useAppContext();

  const pagesHandler = usePagesCollection();

  const MenuIcon = (name: ICON_NAME, props: dAccessory) => (
    <IconPrimr
      name={name}
      size={props.style.width}
      color={props.style.tintColor}
    />
  );

  const [selectedIndex, setSelectedIndex] = React.useState(null);
  // const Navigation = useNavigator();
  try {
    return (
      <A.CtnrMenu
        selectedIndex={selectedIndex}
        onSelect={(index) => {
          switch (index.row) {
            case 0:
              pagesHandler.add(__MediaResourcesData({ teamId }));
              break;
          }
        }}
      >
        <Kitten.MenuItem
          title={tr("New Media Page")}
          // onPress={() => navigation.navigate("ListBuilder")}
          accessoryLeft={(props: dAccessory) => MenuIcon("plus", props)}
        />
      </A.CtnrMenu>
    );
  } catch (error) {
    throw new Error(error);
  }
}

interface P {}

const A = {
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
};
