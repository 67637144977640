//#region [import]
import { StackNavigationOptions } from "@react-navigation/stack";
import {
  Buttoon,
  CollaborationCopyCenter,
  CopyCollaborationContentMenu,
  sstyled,
  T,
  Txt
} from "components";
import { CollabPageBody } from "components/organisms";
import { useAppContext } from "engines";
import { usePageDocument } from "engines/firebase/handler/pages-handler";
import React from "react";
import { Platform, TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, tr } from "utils";
import { useCollabContext } from "../../engines/providers/collaboration-provider";
//#endregion

/**
 * ### A quick screen for a quick start
 *
 * ----
 * @example As is
 * ----
 * @version 1.1.19
 * -  *Add fool-proof try-catch*
 * @author nguyenkhooi
 */
export function CollabRsBodyScreen(props: P) {
  const { route } = props;
  const { C } = useAppContext();
  const {
    isCollabModeOn,
    isSelectModeOn,
    copyFromPageId,
    setCopyFromPageId,
    copyToPageId,
    pageContentItems,
    setIsCollabModeOn,
    setIsSelectModeOn,
    setPageContentItems,
    setCopyToPageId,
    setSelectAll,
    selectAll,
  } = useCollabContext();
  const Navigation = useNavigator();
  // const userProfile = useUserProfile();
  const handlerPage = usePageDocument(route.params?.cpid);

  React.useEffect(function leaveCollab() {
    return () => {
      setIsCollabModeOn(false);
      setIsSelectModeOn(false);
    };
  }, []);

  //#region [section2] Appearance of the Header when select mode is on
  React.useEffect(() => {
    if (isSelectModeOn) {
      Navigation.setOptions({
        headerTitle: () => (
          <Txt.H6 style={{ color: C["text-alternate-color"] }}>
            {`${pageContentItems?.length ? pageContentItems.length : 0} ${
              pageContentItems?.length === 1 ? "item" : "items"
            } selected`}
          </Txt.H6>
        ),
        headerStyle: {
          backgroundColor: C["color-primary-600"],
          opacity: 0.8,
          elevation: 0, // remove shadow on Android
          shadowOpacity: 0, // remove shadow on iOS
          borderBottomWidth: 0, // Just in case.
        },
        headerLeft: () => (
          <Buttoon
            style={{ borderRadius: 69 }}
            size={"large"}
            icon={{ name: "x", color: C["text-alternate-color"] }}
            appearance={"ghost"}
            onPress={() => {
              setIsSelectModeOn(false);
              setPageContentItems([]);
              setSelectAll(false);
              setCopyToPageId("");
              Navigation.setOptions({
                title: "Search the Multiverse 🌎",
              });
            }}
          />
        ),
      } as StackNavigationOptions);
    } else {
      Navigation.setOptions({
        headerTitle: () => <Txt.H6>{"Search the Multiverse 🌎"}</Txt.H6>,
        headerStyle: {
          backgroundColor: C.background,
          borderColor: C.background,
          elevation: 0, // remove shadow on Android
          shadowOpacity: 0, // remove shadow on iOS
          borderBottomWidth: 0, // Just in case.
        },
        headerLeft: () => (
          <Buttoon
            style={{ borderRadius: 69 }}
            size={"large"}
            icon={{ name: "arrow_left" }}
            appearance={"ghost"}
            onPress={() => Navigation.goBack()}
          />
        ),
        headerRight: () => (
          <Buttoon
            size={"small"}
            appearance={"ghost"}
            onPress={() => {
              setIsCollabModeOn(false);
              setIsSelectModeOn(false);
              Navigation.navigate("Home");
            }}
            icon={{ name: "home" }}
          >
            {tr("Home")}
          </Buttoon>
        ),
      } as StackNavigationOptions);
    }
  }, [pageContentItems, isSelectModeOn, selectAll]);

  //#endregion

  // #region [ANCHOR2] Enforcing Collab Mode to stay true since this is collaboration file
  // React.useEffect(() => {
  //   setIsCollabModeOn(true);
  // }, []);
  // #endregion

  try {
    if (!route.params?.cpid) {
      console.warn("No cpid found");
      return <ContentNotFound />;
    }
    return (
      <T.TwoColumns
        bottomInset
        column1Content={(scrollHandler) => (
          <CollabPageBody
            title={route.params?.title}
            pageHandler={handlerPage}
            cpid={route.params?.cpid}
            selectModeOn={isSelectModeOn}
            collabMode
          />
        )}
        column2Content={
          //#region [section] Collaboration Menu
          [
            {
              showTitle: false,
              headerTitle: isSelectModeOn ? tr("Done") : tr("Select Content"),
              render: () => (
                <CopyCollaborationContentMenu cpid={route.params?.cpid} />
              ),
              // render: () => <SectionSelectContents />,
              icon: "check",
            },
            {
              showTitle: false,
              hiddenIf: [!isSelectModeOn, pageContentItems.length === 0],
              headerTitle: tr("Copy page content"),
              render: () => (
                <CollaborationCopyCenter
                  cpid={route.params?.cpid}
                  title={route.params?.title}
                />
              ),
              icon: "copy",
            },
          ]
        }
      />
    );
  } catch (error) {
    throw Error(`::Q29sbGFiUnNCb2R5U2NyZWVuLnRzeA==::${error}`);
  }
}

export const ContentNotFound = () => {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  try {
    return (
      <View
        style={{
          backgroundColor: C.background,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Txt.H6>{tr("Page not found")}</Txt.H6>
        <Buttoon appearance={"ghost"} onPress={() => Navigation.goBack()}>
          {tr("Go back")}
        </Buttoon>
      </View>
    );
  } catch (error) {
    throw new Error(`::Q29udGVudE5vdEZvdW5k:: ${error}`);
  }
};

/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: dCollabPageBodyScreen }; //* TODO remember to create dResourcesBodyScreenParam
}

export interface dCollabPageBodyScreen {
  cpid: string;
  /** @deprecated Get title from cpid */
  title?: string;
}

const A = {
  InputTitle: sstyled(TextInput)({
    color: "text",
    borderRadius: 1, //* To create a "bordered" placeholder when text is being loaded
    fontSize: scale(30),
    // fontFamily: "Inter_800ExtraBold"
    fontWeight: Platform.select({ web: "800", ios: "800", android: "bold" }),
  }),
};
