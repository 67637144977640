import _ from "lodash";
import { dQualification } from "../configs/configs.schema";

/**
 * @author Saurabh_M
 * @description Schema for Build My List Questionnaire
 * @version 21.7.31
 */
export interface BmlQSchema {
  _qid: string;
  _teamId: string;
  /**
   * @example :  "TEXT", "VIDEO", "WELCOME", "ENCOURAGEMENT", "CONFETTI"
   */
  questionType: QuestionType;
  question?: string;
  /**
   * @deprecated Videos won't be used anymore for qualification
   */
  mediaUri?: string;
  points?: "1" | "2" | "3" | "4" | "5";
  createdAt: Date;
}

export enum QuestionType {
  "TEXT" = "text",
  "VIDEO" = "video",
  "WELCOME" = "welcome",
  "ENCOURAGEMENT" = "encouragement",
  "CONFETTI" = "confetti",
  "QUALIFICATION" = "qualification",
}

export enum Pointers {
  "FIVE" = "5",
  "FOUR" = "4",
  "THREE" = "3",
  "TWO" = "2",
  "ONE" = "1",
  "BLANK" = "",
}

export const mergeArrays = (
  array1: any[],
  array2: any[],
  uniqueID: string
): any[] => {
  return _.unionBy(array1, array2, uniqueID);
};

export const defaultQualification: dQualification[] = [
  { _id: "1", name: "Married", countTowardsPointer: true, isDisabled: false },
  { _id: "2", name: "Age", countTowardsPointer: true, isDisabled: false },
  { _id: "3", name: "Children", countTowardsPointer: true, isDisabled: false },
  { _id: "4", name: "Homeowner", countTowardsPointer: true, isDisabled: false },
  {
    _id: "5",
    name: "Occupation",
    countTowardsPointer: true,
    isDisabled: false,
  },
];

export const defaultQuestions: BmlQSchema[] = [
  {
    _qid: "1",
    //FIXME : THIS NEED TO BE UPDATED TO CORRECT HIERARCHY
    _teamId: "app-takeoff-team",
    questionType: QuestionType.WELCOME,
    question: "Let's\nBrainstorm!\n🤔",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "2",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.TEXT,
    question:
      "Imagine you are opening a restaurant or other business. Who are 3 people you want to open it with?",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "3",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.TEXT,
    question: "Who are your closest friends?",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "4",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.ENCOURAGEMENT,
    question: "YOU ARE ON\nA ROLL.\nKEEP GOING!\n🚀🚀🚀",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "5",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.TEXT,
    question: "Who are your favorite family members or in-laws?",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "6",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.TEXT,
    question: "Favorite co-workers? (past or present)",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "7",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.TEXT,
    question:
      "Who are some cool people you know through your church, gym, or other activities?",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "8",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.TEXT,
    question: "Classmates? (past or present)",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "9",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.ENCOURAGEMENT,
    question: "GREAT!!!\nNext Step.",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  //NOTE Video approach is depcrecated
  // {
  //   _qid: "10",
  //   _teamId: "app-takeoff-team",
  //   questionType: QuestionType.VIDEO,
  //   question: null,
  //   mediaUri:
  //     "https://res.cloudinary.com/https-apptakeoff-com/video/upload/v1619918197/apptakeoff/videos/MARRIED_pld06d.mp4",
  //   points: "4",
  //   createdAt: new Date(),
  // },
  // {
  //   _qid: "11",
  //   _teamId: "app-takeoff-team",
  //   questionType: QuestionType.VIDEO,
  //   question: null,
  //   mediaUri:
  //     "https://res.cloudinary.com/https-apptakeoff-com/video/upload/v1619918207/apptakeoff/videos/WORKWITH_qsumbu.mp4",
  //   points: "2",
  //   createdAt: new Date(),
  // },
  // {
  //   _qid: "12",
  //   _teamId: "app-takeoff-team",
  //   questionType: QuestionType.VIDEO,
  //   question: null,
  //   mediaUri:
  //     "https://res.cloudinary.com/https-apptakeoff-com/video/upload/v1619918194/apptakeoff/videos/SUPPORTIVE_bref25.mp4",
  //   points: "1",
  //   createdAt: new Date(),
  // },
  {
    _qid: "10",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.QUALIFICATION,
    question: null,
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "13",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.CONFETTI,
    question:
      "🔥 NICE WORK! 🔥\n\nNow let's share your list with your trainer!",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
  {
    _qid: "14",
    _teamId: "app-takeoff-team",
    questionType: QuestionType.ENCOURAGEMENT,
    question: "GREAT JOB! 👍\n\nSEE YOUR LIST!",
    mediaUri: null,
    points: null,
    createdAt: new Date(),
  },
];

export interface PLProps {
  _id: string;
  points: undefined | 0 | 1 | 2 | 3 | 4 | 5;
  title: string;
}
export const pointersList: PLProps[] = [
  {
    _id: "1",
    points: 1,
    title: "1 Pointers",
  },
  {
    _id: "2",
    points: 2,
    title: "2 Pointers",
  },
  {
    _id: "3",
    points: 3,
    title: "3 Pointers",
  },
  {
    _id: "4",
    points: 4,
    title: "4 Pointers",
  },
  {
    _id: "5",
    points: 5,
    title: "5 Pointers",
  },
  {
    _id: "-1",
    points: 0,
    title: "Everyone Else",
  },
];
