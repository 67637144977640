/**
 * Simple safari detection based on user agent test
 */
// const isSafari = () =>
//   /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isJsons = (array) =>
  Array.isArray(array) &&
  array.every((row) => typeof row === "object" && !(row instanceof Array));
const jsonsHeaders = (array) =>
  Array.from(
    array
      .map((json) => Object.keys(json))
      .reduce((a, b) => new Set([...a, ...b]), [])
  );

const jsons2arrays = (jsons, headers) => {
  headers = headers || jsonsHeaders(jsons);

  // allow headers to have custom labels, defaulting to having the header data key be the label
  let headerLabels = headers;
  let headerKeys = headers;
  if (isJsons(headers)) {
    headerLabels = headers.map((header) => header.label);
    headerKeys = headers.map((header) => header.key);
  }

  const data = jsons.map((object) =>
    headerKeys.map((header) => getHeaderValue(header, object))
  );
  return [headerLabels, ...data];
};

const getHeaderValue = (property, obj) => {
  const foundValue = property
    .replace(/\[([^\]]+)]/g, ".$1")
    .split(".")
    .reduce(function (o, p, i, arr) {
      // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
      if (o[p] === undefined) {
        arr.splice(1);
      } else {
        return o[p];
      }
    }, obj);
  // if at any point the nested keys passed do not exist then looks for key `property` in object obj
  return foundValue === undefined
    ? property in obj
      ? obj[property]
      : ""
    : foundValue;
};

const elementOrEmpty = (element) => {
  return typeof element === "undefined" || element === null
    ? "asdfsd"
    : element;
};

const joiner = (data, separator = ",", enclosingCharacter = '"') => {
  // console.log('join data: ', data);
  return data
    .filter((e) => e)
    .map((row) => {
      //   console.log("rows: ", row);
      return row
        .map((element) => elementOrEmpty(element))
        .map((column) => `${enclosingCharacter}${column}${enclosingCharacter}`)
        .join(separator);
    })
    .join(`\n`);
};

const jsons2csv = (data, headers, separator, enclosingCharacter) =>
  joiner(jsons2arrays(data, headers), separator, enclosingCharacter);

export function toCSV({
  data,
  headers,
  separator,
  enclosingCharacter,
}: {
  data: Object;
  headers?: string[];
  separator?: string;
  enclosingCharacter?: string;
}) {
  if (isJsons(data)) {
    console.log("is json");
    return jsons2csv(data, headers, separator, enclosingCharacter);
  }
  throw new TypeError(`Data should be a "Json" `);
}
