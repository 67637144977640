import { IconPrimr } from "assets";
import {
  Kitten,
  Skeleton,
  SkeletonLayout,
  sstyled,
  Txt
} from "components/atoms";
import {
  dScore,
  fn,
  ScoreboardSchema,
  useAppContext,
  UserSchema
} from "engines";
import {
  useMemberDocument,
  useMembersCollection,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import { useScoreboardDocument } from "engines/firebase/handler/scoreboard-handler";
import * as R from "ramda";
import React from "react";
import { Image, ImageBackground, Pressable, View } from "react-native";
import { IS_WEB, scale, spacing, tr } from "utils";

interface dCardScoreboard {
  scoreboardId: string;
  onPress?(): void;
  onLayout?: () => void;
  index: number;
}
export const NEW_SCOREBOARD_TITLE = "*New scoreboard";

/**
 * ### Used in ScoreboardLists to show overview and top scorer
 *  ----
 *  @version 21.09.08
 *  -  *Memoize*
 *  @author  K
 *
 **/
export function ScoreboardCard(props: dCardScoreboard) {
  const { scoreboardId, onPress, index, onLayout } = props;
  const { C } = useAppContext();
  const userProfile = useUserProfile();
  const scoreboardHandler = useScoreboardDocument(scoreboardId);
  const {
    data: scoreboardD,
    error,
    update,
    deleteDocument,
  } = scoreboardHandler;
  const [topScorerShown, showTopScorer] = React.useState(false);

  //todo This is meant for auto update position of the item, but sometimes it runs wildly
  // React.useEffect(
  //   function updatePosition() {
  //     data && index && update({ position: index });
  //   },
  //   [index]
  // );

  React.useEffect(() => {
    setTimeout(() => {
      // //LayoutAnimation.configureNext(
      //   LayoutAnimation.create(
      //     300,
      //     LayoutAnimation.Types.easeInEaseOut,
      //     LayoutAnimation.Properties.scaleXY
      //   )
      // );
      showTopScorer(true);
    }, 100);
  }, []);

  //#region [section]  Scoreboard sorting
  let _sortedPeople = React.useMemo(() => {
    let sortByScore = R.sortWith([R.descend(R.prop("score"))]);
    return sortByScore(scoreboardD?.people || []) as ScoreboardSchema["people"];
  }, [scoreboardD]);
  //#endregion

  return React.useMemo(() => {
    try {
      if (!scoreboardD) return null;
      if (error) throw Error(error);
      return (
        <Pressable
          onPress={onPress}
          style={({ hovered }) => [
            {
              flex: 1,
              marginLeft: 2,
              // minWidth: scale(200),
              width: scale(200),
              borderRadius: scale(9),
              backgroundColor: C.surface,
            },
            hovered && { borderColor: C.primary, borderWidth: 1 },
          ]}
        >
          <A.CardContainer
            onLayout={onLayout}
            style={
              _sortedPeople &&
              _sortedPeople?.length < 1 &&
              {
                // borderColor: C.errorRed,
                // borderWidth: 2,
              }
            }
          >
            {topScorerShown ? (
              <TopScorerView
                scorer={
                  _sortedPeople && _sortedPeople.length > 0
                    ? _sortedPeople[0]
                    : null
                }
              />
            ) : null}

            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <Kitten.Divider_________ />
              <A.TxtScoreboardTitle numberOfLines={2}>
                {/* {scoreboard.position + " - "}  */}
                {scoreboardD.title}
              </A.TxtScoreboardTitle>
              <Txt.Helper style={{ marginTop: spacing(1), marginBottom: -spacing(3) }}>{`${_sortedPeople?.length || 0} competitors`}</Txt.Helper>
              {/* {userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]) ? (
                <MenooScoreboardEditor sbid={scoreboardD._sbid} />
              ) : (
                <View style={{ height: spacing(4), width: 10 }} />
              )} */}
              <View style={{ height: spacing(4), width: 10 }} />
            </View>
          </A.CardContainer>
        </Pressable>
      );
    } catch (error) { }
  }, [scoreboardD, topScorerShown, C, userProfile]);
}

/**
 * ### Score Item with Top Scorer name, avatar, and score 👑
 * @param p
 */
export const TopScorerView = (p: {
  scorer: dScore | null;
  options?: {
    hideImage?: boolean;
    hideText?: boolean;
    nameTextStyle?: any;
    crownSize?: number;
  };
}) => {
  const {
    scorer,
    options: { hideImage, hideText, nameTextStyle, crownSize } = {
      hideImage: false,
      hideText: false,
    },
  } = p;
  const { C, teamId } = useAppContext();
  // const { data: memberD, ...memberHandler } = useMemberDocument(scorer?.uid);
  const [memberD, setMemberD] = React.useState<UserSchema>(null);
  // following code is also to be used on ScorerItem to determine top scorer info
  // const memberHandler =
  //   scorer && scorer?.uid !== "nonUser" && !scorer.identifier
  //     ? useMemberDocument(scorer?.uid)
  //     : { data: null };
  const memberHandler = useMemberDocument(scorer?.uid);
  const _fieldPath = scorer?.identifier?.includes("@")
    ? "personali.email"
    : "personali.phoneNumber";
  // const potentialMembersHandler = scorer?.identifier
  //   ? useMembersCollection({
  //       where: [
  //         [_fieldPath, "==", scorer?.identifier?.replace("+1", "")],
  //         ["_teamId", "==", teamId],
  //       ],
  //     })
  //   : { data: null };
  const potentialMembersHandler = useMembersCollection({
    where: [
      [
        _fieldPath,
        "==",
        scorer?.identifier?.replace("+1", "") || "nontraceable",
      ],
      ["_teamId", "==", teamId],
    ],
  });

  // const refDidFindPotentialMember = React.useRef<boolean>(false);

  React.useEffect(
    function initMember() {
      memberHandler.data && setMemberD(memberHandler.data); // && !refDidFindPotentialMember.current
    },
    [memberHandler.data]
  );
  React.useEffect(
    function initPotentialMember() {
      if (
        potentialMembersHandler.data &&
        potentialMembersHandler.data?.length > 0
      ) {
        // refDidFindPotentialMember.current = false;
        setMemberD(potentialMembersHandler.data[0]);
      }
    },
    [potentialMembersHandler.data]
  );

  return React.useMemo(() => {
    let CASE = {
      MEMBER: !!memberD?.personali,
      NON_MEMBER: !!(memberHandler.error && scorer?.name && scorer?.score),
    };

    if (!scorer) {
      return (
        <View
          style={{
            flex: IS_WEB ? 1 : 0,
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            width: scale(200),
            height: scale(200),
          }}
        >
          <Txt.Helper marginV>{tr("No Scores")}</Txt.Helper>
        </View>
      );
      // return (
      //   <View
      //     style={{
      //       flex: IS_WEB ? 1 : 0,
      //       flexGrow: 1,
      //       justifyContent: "center",
      //       alignItems: "center",
      //       width: scale(200),
      //       height: scale(200),
      //     }}
      //   >
      //     <Txt.S1>{tr("No Scores")}</Txt.S1>
      //   </View>
      // );
    }

    if (memberHandler.error) {
      if (scorer?.name && scorer?.score) {
        return (
          <View
            style={{
              flex: IS_WEB ? 1 : 0,
              flexGrow: 1,
              width: scale(200),
              height: scale(200),
            }}
          >
            {hideImage ? null : (
              <>
                <A.ImgBackgroundTopAvatar
                  source={{ uri: "" }}
                  resizeMode="cover"
                  style={{
                    backgroundColor: fn.js.stringToColor(scorer?.name || ": ("),
                  }}
                >
                  <Txt.H3 style={{ alignSelf: "center" }}>
                    {fn.js.initialFromName(scorer?.name || ": (")}
                  </Txt.H3>
                </A.ImgBackgroundTopAvatar>
                <A.TxtTopScore>
                  {fn.js.formatNumber(scorer?.score)}
                </A.TxtTopScore>
              </>
            )}

            {hideText ? null : (
              <>
                <A.CtnrScoreItem>
                  <IconPrimr
                    preset="safe"
                    name={"crown"}
                    color={C.hazardYellow}
                    size={crownSize ? crownSize : scale(18)}
                  />
                  <A.TxtTopName numberOfLines={2} style={nameTextStyle}>
                    {scorer?.name || tr("Unknown member")}
                  </A.TxtTopName>
                </A.CtnrScoreItem>
              </>
            )}
          </View>
        );
      } else {
        return null;
      }
    }

    if (!memberD && scorer.uid !== "nonUser")
      return (
        <View style={{ flex: IS_WEB ? 1 : 0, flexGrow: 1 }}>
          <Skeleton layout={SkeletonLayout.ScoreHomeItem} />
        </View>
      );

    return (
      <View style={{ flex: IS_WEB ? 1 : 0, flexGrow: 1 }}>
        {hideImage ? null : (
          <>
            {scorer.uid !== "nonUser" ||
              memberD?.personali?.photoURL ||
              memberD?.personali?.displayName ? (
              <A.ImgBackgroundTopAvatar
                source={{
                  uri: fn.media.smallerSizeFromURL(memberD?.personali?.photoURL, 200 * 3) || "",
                }}
                resizeMode="cover"
                style={{
                  backgroundColor: fn.js.stringToColor(
                    CASE.NON_MEMBER
                      ? scorer?.name
                      : memberD?.personali?.displayName
                  ),
                }}
              >
                {!memberD?.personali?.photoURL ? (
                  <Txt.H3 style={{ alignSelf: "center" }}>
                    {fn.js.initialFromName(
                      CASE.NON_MEMBER
                        ? scorer?.name
                        : memberD?.personali?.displayName
                    )}
                  </Txt.H3>
                ) : null}
              </A.ImgBackgroundTopAvatar>
            ) : (
              <View
                style={{
                  flex: IS_WEB ? 1 : 0,
                  padding: spacing(1),
                  flexGrow: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  source={{
                    uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1664056457/admin/flag_1_w8uul0.png",
                  }}
                  style={{
                    width: scale(65),
                    height: scale(65),
                    tintColor: fn.js.stringToColor(scorer?.name),
                  }}
                />
              </View>
            )}
            <A.TxtTopScore style={{ ...(hideText ? { bottom: 0 } : {}) }}>
              {fn.js.formatNumber(scorer?.score)}
            </A.TxtTopScore>
          </>
        )}
        {hideText ? null : (
          <>
            <A.CtnrScoreItem>
              <IconPrimr
                preset="safe"
                name={"crown"}
                color={C.hazardYellow}
                size={crownSize ? crownSize : scale(18)}
              />
              <A.TxtTopName numberOfLines={2} style={nameTextStyle}>
                {CASE.NON_MEMBER
                  ? scorer?.name
                  : memberD?.personali?.displayName ||
                  memberD?.personali?.email ||
                  scorer?.name ||
                  "Unknown member"}
              </A.TxtTopName>
            </A.CtnrScoreItem>
          </>
        )}
      </View>
    );
  }, [scorer, memberHandler.error, memberD, C]);
};

const A = {
  CardContainer: sstyled(View)({
    // flex: 1,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "dim",
    borderRadius: scale(9),
    width: "100%",
    height: 10,
    overflow: "hidden",
    // justifyContent: "center",
    // alignItems: "center",
  }),
  ImgBackgroundTopAvatar: sstyled(ImageBackground)({
    flex: IS_WEB ? 1 : 0,
    padding: spacing(1),
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    // justifyContent: "flex-end",
  }),
  CtnrScoreItem: sstyled(View)({
    paddingRight: 3,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  TxtScoreboardTitle: sstyled(Txt)({
    fontWeight: "bold",
    borderRadius: 0,
    textAlign: "center",
    maxWidth: [scale(160), "100%"],
    paddingHorizontal: spacing(3),
  }),
  TxtTopScore: sstyled(Txt.H4)({
    position: "absolute",
    bottom: scale(18) + spacing(4),
    backgroundColor: "background",
    textAlign: "center",
    alignSelf: "center",
    paddingHorizontal: spacing(2),
  }),
  TxtTopName: sstyled(Txt)({
    maxWidth: "95%",
    fontWeight: "bold",
    color: "hazardYellow",
    textAlign: "center",
    alignSelf: "center",
  }),
};
