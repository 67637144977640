//#region [import]
import { O, sstyled, Txt } from "components";
import { Buttoon, Kitten, Toasty } from "components/atoms";
import { useAppContext, useMailTemplatesFunctions } from "engines";
import { useEmailTemplateDocument } from "engines/firebase/handler/emails-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
// import "react-quill/dist/quill.snow.css";
import EmailEditor from "react-email-editor";
import { ActivityIndicator, View } from "react-native";
import { useNavigator } from "screens";
import { SaveTemplateOverlayScreen } from "screens/mass-mail/mass-mail-home/SaveTemplateOverlayScreen";
import { THEME, tr } from "utils";
//#endregion

/**
 * ### Post skeleton to show when db is being loaded
 *  ----
 *  @example
 *  loading && <PosstSkeleton />
 *  ----
 *  @version 21.03.05
 *  -  *Build a component*
 *  @author  NL,K
 *
 **/
export function MailCompose(props: P) {
  const { C, theme } = useAppContext();
  //#region [FRBS]
  const userProfile = useUserProfile();
  const templateData = useEmailTemplateDocument(
    props.docID ? props.docID : null
  );
  const handleTemplate = useMailTemplatesFunctions();
  //#endregion

  const Navigation = useNavigator();
  const emailEditorRef = React.useRef<EmailEditor>();

  if (props.docID != "_") {
    // console.log(JSON.parse(templateItem.jsonBody));

    const onDesignLoad = (data) => {
      console.log("onDesignLoad", data);
    };
    var onLoad = () => {
      emailEditorRef.current?.editor.addEventListener(
        "onDesignLoad",
        onDesignLoad
      );
      emailEditorRef.current?.editor.loadDesign(
        JSON.parse(templateData.data.jsonBody)
      );
    };
  }

  function updateTemplate(xong) {
    let templateItem = templateData.data;
    emailEditorRef.current?.editor.exportHtml(
      (data) => {
        const { design, html, chunks } = data;

        // console.log(chunks.body);

        const payload = {
          appId: "primr-exp",
          userId: userProfile.data?._id,
          docID: props.docID,
          template_id: templateItem.TemplateId.toString(),
          template_name: templateItem.Name,
          template_subject: templateItem.Name,
          template_html_body: chunks.body,
          template_jsonBody: JSON.stringify(design),
        };
        // console.log(payload);

        handleTemplate
          .update(payload)
          .then((res) => {
            console.log(res.status);
            if (res.status == 200) {
              Toasty.show("Template Updated", { type: "success" });
              xong && xong();
              setTimeout(() => {
                Navigation.navigate("mass-mail-home");
              }, 1000);
            } else if (res.status == 422) {
              Toasty.show("Template validation error", { type: "danger" });
              xong && xong();
            } else if (res.status == 400) {
              Toasty.show("Template not updated", { type: "danger" });
              xong && xong();
            }
          })
          .catch(() => {
            // console.log("an error occured");
            xong && xong();
            Toasty.show("Template not updated", { type: "danger" });
          });
      },
      {
        cleanup: false,
      }
    );
  }

  const openSendEmailModal = () => {
    emailEditorRef.current.editor.exportHtml(
      (data) => {
        const { design, html, chunks } = data;
        //Navigation.navigate<dOverlayParams>("Overlay", {
        Navigation.dialogPortal.open({
          headerTitle: tr("Send Mail"),
          render: <O.MailSend designJson={design} designHtml={chunks.body} />,
        });
      },
      {
        cleanup: false,
      }
    );
  };

  const openSendandSaveEmailModal = () => {
    let templateItem = templateData.data;
    emailEditorRef.current?.editor.exportHtml(
      (data) => {
        const { design, html } = data;
        //Navigation.navigate<dOverlayParams>("Overlay", {
        Navigation.dialogPortal.open({
          headerTitle: tr("Send Mail"),
          render: (
            <O.MailSend
              docID={props.docID}
              designJson={design}
              designHtml={html}
              sendandsave={props.docID ? false : true}
              updatedSave={props.docID ? true : false}
              templateItem={props.docID ? templateItem : null}
            />
          ),
        });
      },
      {
        cleanup: false,
      }
    );
  };

  const openSaveTemplateModal = () => {
    emailEditorRef.current?.editor?.exportHtml(
      (data) => {
        const { design, html, chunks } = data;

        // console.log(chunks.body.trim());
        //Navigation.navigate<dOverlayParams>("Overlay", {
        Navigation.dialogPortal.open({
          headerTitle: tr("Send Mail"),
          render: (
            <SaveTemplateOverlayScreen
              designJson={design}
              designHtml={chunks.body}
              chunkBody={chunks.body.trim()}
            />
          ),
        });
      },
      {
        cleanup: false,
      }
    );
  };

  React.useLayoutEffect(
    function renderTopButton() {
      Navigation.setOptions({
        headerRight: () => (
          <Kitten.ViewH>
            <Buttoon
              appearance="ghost"
              onPress={() => {
                openSendEmailModal();
              }}
            >
              {tr("Send Only")}
            </Buttoon>

            {props.docID != "_" ? (
              <Buttoon
                progress
                onPress={(xong) => updateTemplate(xong)}
                appearance="ghost"
              >
                {tr("Update Template")}
              </Buttoon>
            ) : (
              <Buttoon
                onPress={() => openSaveTemplateModal()}
                appearance="ghost"
              >
                {tr("Save as Template")}
              </Buttoon>
            )}

            <Buttoon onPress={() => openSendandSaveEmailModal()}>
              {tr("Save & send")}
            </Buttoon>
          </Kitten.ViewH>
        ),
      });
    },
    [Navigation]
  );

  React.useEffect(
    function checking() {
      console.log("this is data", templateData.data);
      templateData.data?.jsonBody &&
        emailEditorRef.current?.editor?.loadDesign(JSON.parse(_jsonBody));
    },
    [templateData.data]
  );

  if (templateData.error) return <Txt.Indicator>Error!</Txt.Indicator>;
  if (!templateData.data) return <ActivityIndicator />;
  let _jsonBody = templateData.data.jsonBody;

  return (
    <A.Ctnr>
      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        options={{
          appearance: { theme: theme === THEME.DARK ? "dark" : "light" },
        }}
      />
    </A.Ctnr>
  );
}

const A = {
  Ctnr: sstyled(View)({ flex: 1, borderRadius: 10, overflow: "hidden" }),
};

/**
 * ###  Screen Props
 */
interface P {
  docID?: string;
}
