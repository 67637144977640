import { sstyled, Txt } from "components";
import { useAppContext } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import React from "react";
import { View } from "react-native";

export interface ContactProfileProps {
  contact: ContactSchemaa;
  color?: "black" | "gray" | "white" | "pink" | "primary" | "danger" | "info";
  style?: any;
  fontStyle?: any;
  size?: number;
}
export const ContactProfile = (props: ContactProfileProps) => {
  const { contact: bmlContact, color = "info", style, fontStyle, size } = props;
  const { C } = useAppContext();
  let formattedColor: string = "primary";

  const fullName = bmlContact.displayName
    ? bmlContact.displayName.split(" ")
    : [];
  const fNameInitial = fullName.length > 0 ? fullName[0][0] : "";
  const lNameInitial = fullName.length > 1 ? fullName[1][0] : "";

  switch (color) {
    case "danger":
      formattedColor = C["color-danger-800"];
      break;
    case "primary":
      formattedColor = C.primary;
      break;

    case "info":
      formattedColor = C.infoBlue;
      break;

    default:
      formattedColor = color;
      break;
  }
  return (
    <CP.Container
      style={[
        size && { width: size, height: size, borderRadius: size / 2 },
        {
          backgroundColor: formattedColor,
          alignSelf: "center",
        },
        style,
      ]}
    >
      <CP.Initials
        style={{
          color: color === "white" || color === "primary" ? "black" : "white",
          ...fontStyle,
        }}
      >
        {fNameInitial ? fNameInitial + lNameInitial : "⭐️"}
      </CP.Initials>
    </CP.Container>
  );
};

const CP = {
  Container: sstyled(View)({
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
  }),
  Initials: sstyled(Txt)({
    fontSize: 20,
    fontWeight: "600",
    padding: 1,
  }),
};
