import axios from "axios";
import { Toasty } from "components";
import { fn } from "engines";
import * as FileSystem from "expo-file-system";
import * as ImagePicker from "expo-image-picker";
import { IS_WEB, tr } from "utils";
import { chunkedUpload, CloudinaryCompletionResponse } from "./upload-chunks";

const GOOGLE_API_KEY = "AIzaSyAdBBn6KG-VqataLdnqRo6pZ6emhwm1gFs";

export async function pickImage() {
  let result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    allowsEditing: true,
    aspect: [4, 3],
    quality: 1,
  });

  console.log(result); //* e.g. "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="

  if (!result.cancelled) {
    //@ts-ignore
    setBlur(true);
    //@ts-ignore
    uploadMedia(result.uri);
  }
}

interface UploadDataResponse {
  uploadURL: string;
  upload_params: any;
}
async function fetchUploadData(folder: string): Promise<UploadDataResponse> {
  const baseURL = "https://us-central1-app-takeoff-dev.cloudfunctions.net/api";
  const res: any = await fetch(
    `${baseURL}/services/fetch-signed-upload-props`,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ folder }),
      headers: {
        "content-type": "application/json",
      },
    }
  ).catch((e) => console.warn("error fetchUploadData: ", e));
  const json = await res.json();
  if (!json) {
    console.log("Failed to fetch upload props!");
    return null;
  }
  return json;
}

const dataURLtoFile = (dataurl: string, filename: string) => {
  console.log("DATA URLLL: ", dataurl);

  // const arr = dataurl.split(",");
  // const mime = arr[0].match(/:(.*?);/)[1];
  // const bstr = atob(arr[1]);
  // let n = bstr.length;
  // const u8arr = new Uint8Array(n);
  // while (n--) {
  //   u8arr[n] = bstr.charCodeAt(n);
  // }
  const dataBlob = new Blob([dataurl]);
  console.log("Data Blob: ", dataBlob);
  return new File([dataBlob], filename, {
    lastModified: new Date().getTime(),
    type: dataBlob.type,
  });
};

const blobToFile = (theBlob: Blob, fileName: string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

/**
 * ### Upload an image or video file
 * - Uploads chunks for web for large files
 * - Mobile does not yet support 100MB+ files
 * 
 * @author jm_francis
 * @version 2.9.30
 * 
 * @example
 * let localUri = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="
 * fn.media
      .uploadMedia(
        localUri,
        { title: "A Presentation", folder: `${CLOUDINARY_FOLDER.CLIENTS}/${teamId}/baseShop._id`, type: "video" },
        (progress) => { console.log(`Uploading... ${progress}%`) },
        (data) => { console.log("Done!") }
      )
 * ---
 * @see https://dev.to/joypalumbo/uploading-images-to-cloudinary-in-react-native-using-cloudinary-s-api-37mo#:~:text=Go%20to%20your%20Cloudinary%20Dashboard,down%20to%20%22upload%20presets%22.
 */
export async function uploadMedia(
  /**
   * For big uploads like on web, just provide the file, don't try to give us a huge data blob string X_X
   */
  localURI: string | File,
  options: {
    /**
     * Desired name of file on Cloudinary
     */
    title: string;
    /**
     * Provide an alternative path to store the file in cloudinary
     */
    folder?: string;
    type: "video" | "image" | "audio";
    extension?: "jpg" | "png" | "mp4";
  },
  onProgress: (progress: number) => void,
  onUploadCompleted: (data: CloudinaryCompletionResponse) => void
): Promise<CloudinaryCompletionResponse> {
  function isValidMediaType(type: string): boolean {
    return (
      !options.type?.includes("audio") &&
      !options.type?.includes("video") &&
      !options.type?.includes("image")
    );
  }
  if (!options?.type || !isValidMediaType(options?.type))
    options.type =
      typeof localURI === "string"
        ? fn.media.extractMediaType(localURI)
        : "image";
  return IS_WEB
    ? await uploadMediaWeb(localURI, options, onProgress, onUploadCompleted)
    : await uploadMediaMobile(
      <string>localURI,
      options,
      onProgress,
      onUploadCompleted
    );
}

async function uploadMediaWeb(
  localURI: string | File,
  options: {
    /**
     * Desired name of file on Cloudinary
     */
    title: string;
    /**
     * Provide an alternative path to store the file in cloudinary
     */
    folder?: string;
    type: "video" | "image" | "audio";
    extension?: "jpg" | "png" | "mp4";
  },
  onProgress: (progress: number) => void,
  onUploadCompleted: (data: CloudinaryCompletionResponse) => void
): Promise<CloudinaryCompletionResponse> {
  const __toasty = Toasty.show(tr("Uploading") + "...", {
    type: "loading",
    duration: 100000,
  });
  return new Promise(async (resolve, reject) => {
    try {
      const { title = "noname", folder = "nofolder", type } = options;

      // console.log("this is a file precious", localURI);
      console.log("[picker.ts] uploading precious file");
      //   const uploadData = await fetchUploadData(folder);
      //   const { uploadURL, upload_params } = uploadData;

      // const file = dataURLtoFile(localURI, title);
      // const file = await (await fetch(localURI)).blob();
      //*We need the media to be base64 in order to be formatted for Cloudinary
      //   const _base64 =
      //     !IS_WEB &&
      //     (await FileSystem.readAsStringAsync(localURI, {
      //       encoding: "base64",
      //     }));
      // const _base64 = IS_WEB
      //   ? localURI
      //   : await FileSystem.readAsStringAsync(localURI, {
      //       encoding: "base64",
      //     });

      const extension = options.extension
        ? options.extension
        : type === "image"
          ? "jpg"
          : "mp4";

      const file =
        typeof localURI === "string"
          ? blobToFile(
            await (
              await fetch(
                `${IS_WEB ? "" : `data:${type}/${extension};base64,`
                }${localURI}`
              )
            ).blob(),
            `${title?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')}-${Date.now()}.${extension}`
          )
          : localURI;

      //   let file: string = IS_WEB
      //     ? localURI
      //     : `data:${type}/${extension};base64,${_base64}`; //* On web, localURI is base64, so no need to convert
      // new File([file], "myfile");

      chunkedUpload(
        file,
        {
          url: "https://api.cloudinary.com/v1_1/https-apptakeoff-com/upload",
          appendToFormData: {
            upload_preset: "mko6difm",
            folder,
            cloud_name: "https-apptakeoff-com",
            public_id: `${title?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')}-${Date.now()}`,
          },
          headers: {},
        },
        async (percent: number) => {
          onProgress && onProgress(percent);
          Toasty.update(__toasty, tr("Uploading... ") + percent + "%", {
            type: "loading",
            duration: 100000,
          });
          if (percent === 100) Toasty.hide(__toasty);
        },
        async (data) => {
          Toasty.show("Upload complete!", { type: "success" });
          onUploadCompleted(data);
          resolve(data);
        }
      );
    } catch (error) {
      !!__toasty &&
        Toasty.update(
          __toasty,
          tr("Upload failed. Please inform the dev team or try again later."),
          {
            type: "danger",
            // icon: Toasty.icon.danger,
          }
        );
      reject(error.message);
    }
  });
}

async function uploadMediaMobile(
  localURI: string,
  options: {
    /**
     * Desired name of file on Cloudinary
     */
    title: string;
    /**
     * Provide an alternative path to store the file in cloudinary
     */
    folder?: string;
  },
  progressCallback?: (progress: number) => void,
  onUploadCompleted?: (data: CloudinaryCompletionResponse) => void
): Promise<CloudinaryCompletionResponse> {
  const __toasty = Toasty.show(tr("Uploading") + "...", {
    type: "loading",
    duration: 999999,
  });
  return new Promise(async (resolve, reject) => {
    try {
      const { title, folder } = options;

      // console.log("this is a file precious", localURI);
      console.log("[picker.ts] uploading precious file");
      const uploadData = await fetchUploadData(folder);
      const { uploadURL, upload_params } = uploadData;

      // const file = dataURLtoFile(localURI, title);
      // const file = await (await fetch(localURI)).blob();
      //*We need the media to be base64 in order to be formatted for Cloudinary
      const _base64 =
        !IS_WEB &&
        (await FileSystem.readAsStringAsync(localURI, {
          encoding: "base64",
        }));

      let file = IS_WEB ? localURI : `data:video/mp4;base64,${_base64}`; //* On web, localURI is base64, so no need to convert

      //#region [section] Cloudinary prep
      const form: FormData = new FormData();
      form.append("file", file);
      form.append("api_key", upload_params.api_key);
      form.append("eager", upload_params.eager);
      form.append("folder", upload_params.folder);
      form.append("signature", upload_params.signature);
      form.append("timestamp", upload_params.timestamp);
      form.append("unique_filename", upload_params.unique_filename);
      form.append("use_filename", upload_params.use_filename);
      //#endregion

      console.log(
        "📹 uploading " +
        upload_params.use_filename +
        ", " +
        title +
        " to folder " +
        upload_params.folder +
        " type: ",
        String(_base64).slice(0, 130) + "..."
      );

      // const proxyUrl = "https://cors-anywhere.herokuapp.com/";
      const uploadResponse: any = await axios(`${uploadURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        data: form,
        onUploadProgress: (p) => {
          let uploadPercent = Math.round((p.loaded / p.total) * 100);
          Toasty.update(__toasty, tr("Uploading...") + uploadPercent + "%", {
            type: "loading",
            duration: 999999,
          });
          progressCallback && progressCallback(uploadPercent);
          // uploadPercent == 1 && __toasty && Toasty.hide(__toasty);
        },
      }).catch((error) => console.warn("📹 Error axios: ", error));

      // let responseData: typeof MOCK_RESPONSE["data"] = uploadResponse?.data;
      let responseData = uploadResponse?.data;

      onUploadCompleted && onUploadCompleted(responseData);
      resolve(responseData);
      //   resolve({
      //     uri: responseData["secure_url"],
      //     type: responseData["resource_type"],
      //     width: responseData["width"],
      //     height: responseData["height"],
      //   });

      // resolve({ photoURL: photoURL });
      !!__toasty && Toasty.hide(__toasty);
      Toasty.show(tr("Upload complete!"), { type: "success" });
    } catch (error) {
      !!__toasty &&
        Toasty.update(__toasty, tr("Upload failed. " + error.message), {
          type: "danger",
        });
      console.log(error.message);
      reject(error.message);
    }
  });
}

export enum dMediaFileType {
  video,
  image,
}

export interface dGoogleDriveData {
  thumbnail: string;
  fileType: string;
  json: any;
}

/**
 * @deprecated
 * -  Returns a direct uri to access the file itself
 * Ex usage: react-native-track-player, react-native-video
 */
export function uriFromSource(source: string) {
  const regex = /\/d\/(.*)\/view/i;
  const _fileId = source.match(regex)[1];
  return `https://www.googleapis.com/drive/v3/files/${_fileId}?alt=media&key=${GOOGLE_API_KEY}`;
}

/**
 * @deprecated
 * Returns a share link with the given file id
 */
export function shareLinkWithFileId(fileId: string) {
  return `https://drive.google.com/file/d/${fileId}/view?usp=sharing`;
}

/**
 * @deprecated
 * (async) Returns { thumbnail: string, fileType: string, json: {} }
 */
export async function getVideoMetadata(
  source: string
): Promise<dGoogleDriveData> {
  const regex = /\/d\/(.*)\/view/i;
  const _fileId = source.match(regex)[1];
  const uri = `https://www.googleapis.com/drive/v3/files/${_fileId}?key=${GOOGLE_API_KEY}&fields=thumbnailLink%2C%20thumbnailVersion%2C%20mimeType`;
  const res = await fetch(uri, { method: "GET" });
  const json = await res.json();
  return {
    thumbnail: json.thumbnailLink,
    fileType: json.mimeType,
    json,
  };
}
