const noBaseShopTeams = [
  "unleash-apptakeoff",
  "app-takeoff-team",
  "fml-apptakeoff",
  "rvp-training-center",
  "rvp-training-factory",
  "fearless-chaffin",
  "tavala-apptakeoff",
  "champions-united"
];
const crmEnabledTeams = [
  "legacy-builders-financial",
  "rvp-training-center",
  "rvp-training-factory",
];

/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import PushNotificationIOS from "@react-native-community/push-notification-ios";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { IconPrimr, ICON_NAME } from "assets";
import { Avt, Buttoon, Kitten } from "components/atoms";
import { NotificationFeed } from "components/molecules/notifications/NotificationFeed";
import { NavigationBar, ViewTeamLogo } from "components/organisms";
import { useBreakpointIndex } from "dripsy";
import {
  fn,
  MediaPageSchema,
  useAppContext,
  usePageDocument,
  useUserProfile
} from "engines";
import * as ExpoNotifications from "expo-notifications";
import * as React from "react";
import { Platform, Pressable, StatusBar, useWindowDimensions, View } from "react-native";
import { BSHomeScreen } from "screens/bs-home-scr/BSHomeScreen";
import { Flowtrack } from "screens/crm/Flowtrack";
import { HomeScreen } from "screens/home/HomeScreen";
import { PageHomeScreen } from "screens/pages-home-scr/PagesHomeScreen";
import { PersonalDashboardScreen } from "screens/personal-activity/PersonalDashboardScreen";
import { IS_WEB, scale, spacing, THEME, tr } from "utils";
import { APP_CONFIG } from "../../../apptakeoff.config";
import { dResourcesBodyScreenParam } from "./media.navigator";
import { useNavigator, useNavigatorPresetConfig } from "./navigation-utils";
const { app_team_id } = require("../../../package.json");

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

PushNotificationIOS.addEventListener("notification", (handler) => {
  // alert("You just received a notification, check your notification center.");
  // alert("noti cam ein " + JSON.stringify(handler));
  // PushNotificationIOS.addNotificationRequest(handler.getMessage());
});
PushNotificationIOS.addEventListener("localNotification", () => {
  // alert("local");
});
ExpoNotifications.setNotificationHandler({
  handleSuccess(notificationId) {
    // alert("notification came in... " + notificationId);
  },
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

const BottomTab = createBottomTabNavigator();

export function MainNavigator(props) {
  const { theme, C, setTheme } = useAppContext();
  const breakpointIndex = useBreakpointIndex();
  const { bottomTabConfig } = useNavigatorPresetConfig();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();
  const handlerPage = usePageDocument(userProfile?.data?.baseShopId);
  //#region [section] base shop setup
  const [bsItem, setBsItem] = React.useState<MediaPageSchema>();
  const [showTopTabBar, setShowTopTabBar] = React.useState<boolean>(true);
  // const [bsLoading, setBsLoading] = React.useState<boolean>(true);
  // const [loading, setLoading] = React.useState<any>();
  // const [initialRoute, setInitialRoute] = React.useState("Home");

  const { width, height } = useWindowDimensions()
  const showMobileTabBar = !IS_WEB || width <= 1242

  const fetchData = async () => {
    // console.log("Function is called when ever we join a bs");
    if (!userProfile?.data?.baseShopId) {
      // setBsLoading(false);
      setBsItem(null);
    } else {
      // setBsLoading(false);
      setBsItem(handlerPage?.data);
    }
  };

  React.useEffect(() => {
    // declare the data fetching function

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // updateBsSync()
  }, [userProfile?.data?.baseShopId]);
  //#endregion

  //#region [section2] Notification Navigation

  /**
   * WIP
   */
  // React.useEffect(() => {
  //   // Assume a message-notification contains a "type" property in the data payload of the screen to open
  //   if (!IS_WEB) {
  //     messaging().onNotificationOpenedApp(remoteMessage => {
  //       console.log(
  //         'Notification caused app to open from background state:',
  //         remoteMessage.notification,
  //       );
  //       Navigation.navigate("Contact Manager");
  //     });

  //     // Check whether an initial notification is available
  //     messaging()
  //       .getInitialNotification()
  //       .then(remoteMessage => {
  //         if (remoteMessage) {
  //           console.log(
  //             'Notification caused app to open from quit state:',
  //             remoteMessage.notification,
  //           );
  //           setInitialRoute(remoteMessage.data.type); // e.g. "Settings"
  //         }
  //         setLoading(false);
  //       });
  //   }

  // }, []);
  //#endregion

  return React.useMemo(
    () => (
      <BottomTab.Navigator
        {...bottomTabConfig}
        // initialRouteName={"Pages"}
        screenOptions={{
          // headerShown: false,
          tabBarShowLabel: false,
          tabBarStyle: { display: !showMobileTabBar ? "none" : "flex" },
          headerStyle: {
            backgroundColor: C.background,
            borderBottomColor: C.dim,
            ...(showTopTabBar === false
              ? {
                height: Platform.OS === "ios" ? StatusBar.currentHeight : 0,
              }
              : {}),
          },
          headerTitleStyle: { color: C.text },
          headerLeft: () => (!showMobileTabBar ? <NavigationBar /> : <View />),
          headerTitleAlign: "center",
          headerTitle: () => <ViewTeamLogo />,
          headerRight: () =>
            showTopTabBar ? (
              <Kitten.ViewH
                style={{
                  marginRight: spacing(4),
                  alignItems: "center",
                  flexDirection: "row",
                  flex: 1,
                }}
              >
                <Buttoon
                  icon={{
                    color: C.text,
                    name: theme == THEME.DARK ? "moon" : "sunny",
                  }}
                  size={breakpointIndex > 2 ? "giant" : "large"}
                  appearance="ghost"
                  style={{ borderRadius: 56 }}
                  onPress={() => {
                    setTheme(theme == THEME.DARK ? THEME.LIGHT : THEME.DARK);
                  }}
                />
                <Buttoon
                  icon={{ color: C.text, name: "bell" }}
                  size={breakpointIndex > 2 ? "giant" : "large"}
                  appearance="ghost"
                  style={{ borderRadius: 56 }}
                  onPress={() =>
                    Navigation.dialogPortal.open({
                      render: <NotificationFeed />,
                      headerTitle: tr("Inbox"),
                    })
                  }
                />
                {/* <ViewTeamLogo /> */}
              </Kitten.ViewH>
            ) : null,
        }}
      >
        {Object.entries(MainStackCollection).map(([name, screen]) => (
          <BottomTab.Screen
            name={name}
            key={name}
            {...screen}
            {...(name === "CRM"
              ? {
                component: (p) => (
                  <Flowtrack
                    {...p}
                    onScreenFocus={(focused) => {
                      const hideTopBar = !IS_WEB && focused;
                      setShowTopTabBar(!hideTopBar);
                    }}
                  />
                ),
              }
              : {})}
            options={{
              tabBarIcon: ({ color }) => (
                <TabBarIcon
                  name={screen.options?._icon}
                  color={color}
                  title={screen.options.title}
                  bsItem={bsItem}
                />
              ),
              ...screen.options,
            }}
          />
        ))}
      </BottomTab.Navigator>
    ),
    []
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: ICON_NAME;
  color: string;
  title: string;
  bsItem: any;
}) {
  // console.log("title of page ", props.title)
  const Navigation = useNavigator();
  const { C } = useAppContext();
  switch (props.title) {
    case "Base Shops":
      return props?.bsItem ? (
        <Pressable
          onPress={() => {
            Navigation.navigate<dResourcesBodyScreenParam>("BaseShops", {
              cpid: props?.bsItem?._id,
              title: props?.bsItem?.name || tr("Untitled"),
            });
          }}
        >
          <Avt
            size="tiny"
            source={{
              uri: props?.bsItem?.mediaItem?.logo,
            }}
            name={props?.bsItem?.name}
          />
        </Pressable>
      ) : (
        <Pressable
          onPress={() => {
            Navigation.navigate<dResourcesBodyScreenParam>("BaseShops");
          }}
        >
          <IconPrimr
            size={21}
            containerStyle={{ marginBottom: -3 }}
            preset={"circular"}
            {...props}
          />
        </Pressable>
      );
      break;

    default:
      return (
        <IconPrimr
          size={scale(21)}
          containerStyle={{ marginBottom: -3 }}
          preset={"circular"}
          {...props}
        />
      );
      break;
  }
}

export const MainStackCollection = {
  Home: {
    component: HomeScreen,
    options: {
      title: "Home",
      _icon: "home",
      headerShown: true,
      tabBarShowLabel: true,
      // tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
    },
  },
  Pages: {
    component: PageHomeScreen,
    options: {
      title: "Resources",
      _icon: "media_resource",
      headerShown: true,
      tabBarShowLabel: true,
      // tabBarIcon: ({ color }) => (
      //   <TabBarIcon name="media_resource" color={color} />
      // ),
    },
  },
  // TeamFeed: {
  //   component: TeamActionFeedScreen,
  //   options: {
  //     title: "Activity",
  //     _icon: "people_friends",
  //     headerShown: true,
  //     tabBarShowLabel: true
  //   }
  // },
  ...(!noBaseShopTeams.includes(APP_CONFIG?.app_id || app_team_id) && APP_CONFIG?.teams?.enabled
    ? {
      BaseShops: {
        component: BSHomeScreen,
        options: {
          title: "Base",
          _icon: "flag",
          headerShown: true,
          tabBarShowLabel: true,
        },
      },
    }
    : null),
  ...(IS_WEB || crmEnabledTeams.includes(APP_CONFIG?.app_id || app_team_id)
    ? {
      CRM: {
        // component: () => <Flowtrack onScreenFocus={() => alert("crm screen tapped")} />,
        options: {
          title: "Flowtrack",
          _icon: "crm",
          headerShown: true,
          tabBarShowLabel: true,
        },
      },
    }
    : {}),
  UserDashboard: {
    component: PersonalDashboardScreen,
    options: {
      title: "You",
      // _icon: "dashboard",
      // _icon: "profile",
      headerShown: true,
      tabBarShowLabel: true,
      // tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
      tabBarIcon: ({ color }) => {
        const { C } = useAppContext();
        const userHandler = useUserProfile();
        return (
          <Avt
            name="You"
            style={{
              borderWidth: 0.82,
              borderColor: C.text,
              width: 24,
              height: 24,
            }}
            source={{
              uri: fn.media.smallerSizeFromURL(userHandler.data?.personali?.photoURL, 24 * 5),
              // uri: "http://res.cloudinary.com/https-apptakeoff-com/image/upload/v1697309810/clients/unleash-apptakeoff/profilePhotos/cpzgj3sf1xpu41uxcvsx.jpg",
            }}
          />
        );
      },
    },
  },
  // Chat: {
  //   component: TelegramChats,
  //   options: {
  //     title: "Chat",
  //     _icon: "chat_bubble",
  //     headerShown: false

  //   },
  // },
};

export type MAIN_STK = keyof typeof MainStackCollection;
