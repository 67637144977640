// import Backend from "backend/";
import { Poppy, Toasty } from "components";
import { FPATH, FRBS_ROLE } from "engines";
import {
  dConfigVariables,
  dListBuilderList
} from "engines/firebase/firebase.props";
import * as Linking from "expo-linking";
import firebase from "firebase";
import * as R from "ramda";
import { THEME, tr } from "utils";
import { fuego } from "../../../../fuego";
import {
  c2EmailRES,
  checkEmailandPassRES,
  GOOGLE_AUTH,
  kqGAuth
} from "../backends.props";
import { UserSchema } from "../schemas";
import { ContactGroupSchemaa, ContactSchemaa } from "../schemas/bml";

let googleProvider: any;

/**
 * Fn to validate given email and password
 * (e.g. its strength, length...)
 * ---
 * @version 0.10.19
 * @author nguyenkhooi
 */
export function checkEmailandPassword(email: string, password: string) {
  return new Promise<checkEmailandPassRES>((resolve, reject) => {
    !R.isNil(password) && password.length < 6
      ? resolve({ code: `PW_SHORT` })
      : resolve({ code: `EMPW_GOOD` });
  });
}

/**
 * Fn to sign in/sign up via email
 *
 * ---
 * @version 1.3.1
 * -  *Fix create user fn(_)*
 * @author nguyenkhooi
 */
export async function connectWithEmail(
  email: string,
  password: string,
  _teamId?: string
) {
  // const __toast = Toasty.show("Loading...", {
  //   type: "loading",
  // });

  // xong();

  return new Promise<c2EmailRES>(async (resolve, reject) => {
    try {
      //*  TODO: add this
      // checkEmailandPassword()
      //#region [section]  check email Match

      const doRegister = await fuego
        .auth()
        .signInWithEmailAndPassword(email, password);
      // Toasty.hide() //* don't hide Toast here

      //#region [section]  check teamId Match
      getUserByID(doRegister.user.uid).then((res) => {
        console.log("checker ", res);

        let CASE = {
          U_HAS_REGISTERED: !!doRegister.user,
          /** 🚩1: If user already verified his email... */
          U_VERIFIED_EMAIL:
            !!doRegister.user && !!doRegister.user.emailVerified,

          /**
           * This checks if the user team id matches the team the user is trying to logon to
           */
          U_TEAM_MISMATCH: !!doRegister.user && _teamId !== res._teamId,

          // U_UNMATCH_TEAM: !!doRegister.user && !!doRegister.user.emailVerified && doRegister.user.,
          /**
           *  🚩2:...if user hasn't verified yet...
           *  @see https://stackoverflow.com/a/58110763
           *  @see https://firebase.google.com/docs/auth/web/email-link-auth#send_an_authentication_link_to_the_users_email_address
           */
          U_NOT_VERIFIED_EMAIL: !(
            !!doRegister.user && !!doRegister.user.emailVerified
          ),
        };

        if (CASE.U_HAS_REGISTERED) {
          // Backend.firestoreHandler.initWithUser(doRegister.user);

          /**
           * Here is where it is resolved and returned to the frontend
           */
          if (CASE.U_TEAM_MISMATCH) {
            resolve({
              code: AUTH_RESULT.U_TEAM_MISMATCH,
            });
          }

          if (CASE.U_VERIFIED_EMAIL) {
            console.log(AUTH_RESULT.EMAIL_EXISTED_VERIFIED);
            resolve({
              code: AUTH_RESULT.EMAIL_EXISTED_VERIFIED,
              uid: doRegister.user.uid,
            });
            // !!__toast &&
            //   Toasty.update(__toast, tr("Login successful as ") + email, {
            //     type: "success",
            //     // icon: Toasty.icon.success,
            //   });
          }
          if (CASE.U_NOT_VERIFIED_EMAIL) {
            const { user } = doRegister;
            console.log("user ", user);
            console.log(`EMAIL_EXISTED_xVERIFIED. Sending verMail...`);
            //@ts-ignore
            // user.sendEmailVerification().catch(error => {
            //   throw new Error(error)
            // })
            resolve({
              code: AUTH_RESULT.EMAIL_EXISTED_xVERIFIED,
              uid: user.uid,
            });
            // !!__toast &&
            // Toasty.update(__toast, tr("Welcome back, ") + email, {
            //   type: "success",
            //   // icon: Toasty.icon.success,
            // });
          }
        }
      });

      // console.log(matchTeam);

      //#region [section]  CASES

      //#endregion
    } catch (error) {
      /**
       * Create new user with Email and Pass if xEXISTED
       * Send veriMail
       */
      if (error.code == "auth/user-not-found") {
        await fuego
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then(async (authUser) => {
            console.log(
              `EMAIL_xEXISTED. Just created an acc with email: `,
              email
            );
            const message = await firebase
              .functions()
              .httpsCallable("addNewbieRole")({
              email: authUser.user.email,
            });

            console.log("Message from Login with Email function ", message);

            // console.log("userProfile ", userProfile);
            // welcomeEmail(authUser.user.email);
            //! We don't verify email
            // created.user
            //   ?.sendEmailVerification()
            //   .then((r) => {})
            //   .catch((error) => {
            //     throw new Error(error);
            //   });
            console.log("`authUser`: ", authUser);
            await addUserToDB(authUser, _teamId).then(() => {
              // fuego.auth().signInWithEmailAndPassword(email, password);

              resolve({
                code: AUTH_RESULT.EMAIL_xEXISTED,
                uid: authUser.user?.uid,
              });
            });
          });
      } else {
        console.warn(AUTH_RESULT.ERR_EMAIL_AUTH, errLabel(error, email));
        /**
         * Return errLabel() for the errToast
         */
        resolve({ code: AUTH_RESULT.ERR_EMAIL_AUTH });
      }
    }
  });
}

/**
 * ###  Fn to reset password and send instruction mail to user
 *
 * ---
 * @version 0.10.19
 * @author nguyenkhooi
 */
export function resetPassword(email: string) {
  const __toast = Toasty.show("Loading...", {
    type: "loading",
  });
  return new Promise(async (resolve, reject) => {
    if (email) {
      await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve({ code: `RESET_EMAIL_SENT` });

          !!__toast &&
            Toasty.update(__toast, "Email reset instruction sent!", {
              type: "success",
              // icon: Toasty.icon.success,
            });
        })
        .catch((error) => {
          !!__toast &&
            Toasty.update(__toast, "Error resetting password", {
              type: "danger",
            });
          console.log("resetPassword error: ", error);
        });
    } else {
      resolve({ code: `EMAIL_NOT_FOUND`, message: `Please type your email` });
      !!__toast &&
        Toasty.update(__toast, "Please type your email", {
          type: "warning",
        });
    }
  });
}

export function sendEmailVerification(email: string) {
  const __toast = Toasty.show("Loading...", {
    type: "loading",
  });
  return new Promise(async (resolve, reject) => {
    const currentUser = fuego.auth().currentUser;
    // console.log("mail verified? ", currentUser.emailVerified)
    !!currentUser && !!currentUser.emailVerified
      ? null
      : currentUser
          .sendEmailVerification()
          .then((result) => console.log("email-verification RES: ", result))
          .catch((error) => {
            throw new Error(error);
          });

    if (email) {
      await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => resolve({ code: `RESET_EMAIL_SENT` }))
        .catch((error) => {
          console.log("resetPassword error: ", error);
        });
    } else {
      resolve({ code: `EMAIL_NOT_FOUND`, message: `Please type your email` });
    }
  });
}

export const errLabel = (error: { code: string }, email?: string) => {
  switch (error.code) {
    case "auth/email-already-in-use":
      return "The email address is already in use by another account."; //* == error.message
      break;
    case "auth/invalid-email":
      return "The email address is badly formatted. Please try again";
      break;
    case "auth/weak-password":
      return tr("Weak password. Please try again");
      break;
    case "auth/wrong-password":
      return "Wrong password. Please try again";
      break;
    case "auth/unknown":
      /**
       * This is when `sendEmailVerification` received multiple requests
       */
      const message = R.includes("We have blocked all requests", error.code)
        ? "Our server is overheated right now. Please try again later"
        : `Login failed. ${error.code}\nPlease try again`;
      return message;
    /** ⚠️ Special case */
    case "auth/user-not-found":
      return "Failed to setup user \n" + email;
      break;
    default:
      console.log("(auth) err: ", error.code);
      return `Login failed. ${error.code}\nPlease try again`;
      break;
  }
};

/**
 * ### Function to check if user exists in \_USER\_ firestore collection
 * #### returns true if exists, false otherwise.
 * ---
 * @version 0.02.21
 * @author SaurabhM
 * @example userExists("JohnSmith_gmail.com")
 */
export const userExists = async (email?: string) => {
  let isUserAdded: boolean;

  await firebase
    .firestore()
    .collection(FPATH.USERS) // .collection(FPATH.USERS)
    .where("personali.email", "==", email)
    .get()
    .then((docSnapshot) => {
      if (docSnapshot.empty) {
        // return false;
        isUserAdded = false;
      } else {
        isUserAdded = true;
      }
    });
  console.log("Query output", isUserAdded);
  return isUserAdded;
};

/**
 * ### Function to find userinfo based on id.
 * #### This will return an object of type UserSchema.
 * ---
 * @version 0.02.21
 * @author SaurabhM
 * @example getUserByID(userId)
 */
export const getUserByID = async (id?: string) => {
  let userInfo: UserSchema = null;

  await firebase
    .firestore()
    .collection(FPATH.USERS)
    .where("_id", "==", id)
    .get()
    .then((docSnapshot) => {
      if (!docSnapshot.empty) {
        docSnapshot.docs.forEach((doc) => {
          userInfo = <UserSchema>doc.data();
        });
      } else {
        throw Error("The user with id: " + id + " was not found.");
      }
    });

  return userInfo;
};

/**
 * ### Function to find userinfo based on id.
 * #### This will return an object of type UserSchema.
 * ---
 * @version 0.02.21
 * @author Nl
 * @example getUserByID(userId)
 */
export const getUserByEmail = async (email?: string) => {
  let userInfo: UserSchema = null;

  await firebase
    .firestore()
    .collection(FPATH.USERS)
    .where("personali.email", "==", email)
    .get()
    .then((docSnapshot) => {
      if (!docSnapshot.empty) {
        docSnapshot.docs.forEach((doc) => {
          userInfo = <UserSchema>doc.data();
        });
      } else {
        throw Error("The user with id: " + email + " was not found.");
      }
    });

  return userInfo;
};

/**
 * ### Function to add the user to \_USER\_ collection in firestore
 * ---
 * @version 0.02.21
 * @author SaurabhM
 * @example addUserToDB(authUser)
 */
export const addUserToDB = async (
  authUser: firebase.auth.UserCredential,
  _teamId?: string
) => {
  const userEmail = authUser.user.email;
  const userId = authUser.user.uid;
  const teamName = _teamId ? _teamId : "";

  //console.log("authUser :>> ", authUser);

  let userData: UserSchema = {
    _id: userId,
    _teamId: _teamId,
    baseShopId: "",
    allLevelsCompleted: false,
    createdAt: firebase.firestore.Timestamp.now().toDate(),
    growth: {
      allLevelsCompleted: false,
      levels: {},
      listBuilder: {
        lists: [],
        shareTo: [],
      },
      team: teamName.substr(teamName.indexOf("/") + 1, teamName.length).trim(),
    },
    imitate: false,
    notifications: {
      lastRead: firebase.firestore.Timestamp.now().toDate(),
    },
    personali: {
      email: userEmail,
      displayName: authUser.user?.displayName,
      phoneNumber: "",
      photoURL: authUser.user?.photoURL
        ? authUser.user?.photoURL?.toString()
        : "",
    },
    //TODO: Assign Role here that is appropriate
    roles: [FRBS_ROLE.NEWBIE],
    theme: THEME.LIGHT,
  };

  //console.log("User is about to be added " + JSON.stringify(userData));
  let status: boolean = false;
  await firebase
    .firestore()
    .collection(FPATH.USERS) // .collection(FPATH.USERS)
    .doc(userId)
    .set(userData)
    .then(() => {
      //setUserProfile(userData);
      status = true;
    })
    .catch((err: Error) => {
      status = false;
      console.log(err.message);
      throw new Error("Not able to add the user : " + err);
    });

  return userData;
};

/**
 * ### Function to handle google signin.
 * It authenticates the user and if its their first time signing in then
 * a NEWBIE role is assigned and a firestore entry is added to _USERS_ collection.
 * ---
 * @version 0.10.19
 * @author nguyenkhooi
 * @see frtb
 */
export async function connectWithGoogleAuth(email?: string) {
  const __toast = Toasty.show("Loading...", {
    type: "loading",
  });
  return new Promise<kqGAuth>(async (resolve, reject) => {
    try {
      const authUser = await fuego.auth().signInWithPopup(googleProvider);

      if (!authUser.user) {
        throw Error("Failed to authenticate");
      }
      if (email?.toLowerCase() !== authUser.user.email?.toLowerCase()) {
        throw Error(`Used account is not ${email}`);
      }

      console.log("Email is : ", authUser.user?.email);
      const isUserinDB = await userExists(authUser.user?.email);
      console.log("User exists in DB? : ", isUserinDB);

      if (!isUserinDB) {
        await addUserToDB(authUser);
      }

      const message = await firebase.functions().httpsCallable("addNewbieRole")(
        {
          email: authUser.user.email,
        }
      );

      console.log("Message from Google Sign in function ", message);

      const result = await authUser.user.getIdTokenResult(true);

      if (result.claims.roles && result.claims.roles.length !== 0) {
        resolve({
          type: GOOGLE_AUTH.SUCCESS,
          payload: { frbsUser: authUser, roles: result.claims.roles },
        });
      } else {
        /** @see https://firebase.google.com/docs/auth/admin/custom-claims#set_and_validate_custom_user_claims_via_the_admin_sdk */
        console.warn(
          "User don't have a role. Set it up thru frbs admin if available"
        );
        // throw Error(
        //   "You must set roles for this user. Click here for more info"
        // );
      }
    } catch (error) {
      Poppy.Error(error, "312-gauth-01", "Signing in with this account");
      if (fuego.auth().currentUser) {
        fuego.auth().signOut();
      }
      //*----CASES---------------------------------------------
      let FRBS_SETUP_NEEDED = error.message.includes(
        "The identity provider configuration is disabled"
      );
      let U_NO_ROLE_YET = error.message.includes(
        "You must set roles for this user"
      );
      let U_IS_NOT_EMAIL = error.message.includes("Used account is not");
      let G_AUTH_FAILED = error.message.includes("Failed to authenticate");

      //*----MAIN-SECTION -----------------------------------------------
      if (FRBS_SETUP_NEEDED) {
        reject({ code: GOOGLE_AUTH.FRBS_SETUP_NEEDED, message: error.message });
        Toasty.show(
          "You must enable Google sign-in for your Firebase project. 👉  Edit settings?",
          {
            duration: 100000,
            type: "danger",
            onPress: () => {
              Linking.openURL(
                `https://console.firebase.google.com/u/0/project/${
                  fuego.auth().app.options["projectId"]
                }/authentication/providers`
              );
            },
          }
        );
      }
      if (U_NO_ROLE_YET) {
        reject({ code: GOOGLE_AUTH.U_NO_ROLE_YET, message: error.message });
        Toasty.show(error.message + " 👉  More info?", {
          duration: 100000,
          type: "danger",
          onPress: () => {
            Linking.openURL(
              "https://firebase.google.com/docs/auth/admin/custom-claims#set_and_validate_custom_user_claims_via_the_admin_sdk"
            );
          },
        });
      }
      if (U_IS_NOT_EMAIL) {
        reject({ code: GOOGLE_AUTH.FAILED, message: error.message });
        Toasty.show(error.message, { type: "danger" });
      }
      if (G_AUTH_FAILED) {
        reject({ code: GOOGLE_AUTH.FAILED, message: error.message });
        Toasty.show(error.message, { type: "danger" });
      }
    }
  });
}

/**
 * ### Fn to sign out from frbs auth
 * Remember to reset local storage
 * and navigate back to login screen
 * after this
 * ---
 * @version 1.1.16
 * -  Add callback fn
 */
export function signOut(c: () => void) {
  if (fuego.auth().currentUser) {
    fuego.auth().signOut();
    c &&
      global.setTimeout(() => {
        c();
      }, 2000);
    Toasty.show(tr("👋   See you soon!"), { type: "normal" });
  }
}

/**
 * @deprecated doesn't seem to be used anywhere in the code
 * ### Fn to check AccessCode
 *
 * -  This checks for the accesscode before moving to login,
 * so if the access code doesn't exist or it is wrong
 * it wouldn't allow the user to login
 *  ----
 *  @example
 *  Backend.authHandler.checkUserAccessCode(_access_code).then((res) => {
      // console.log("res", res[0]);
      res ? ass.saveString(ASS_KEY.TEAMNAME, res._teamId) : "";
      res ? setCodeCorrect(true) : setCodeCorrect(false);
    })
 *  ----
 *  @version 21.05.28
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export async function checkUserAccessCode(_access_code?: string) {
  let accessInfo: any = null;
  await firebase
    .firestore()
    .collection(FPATH.CONFIGS)
    .where("appPassword", "==", _access_code)
    .get()
    .then((docRef) => {
      docRef.docs.forEach((doc) => {
        accessInfo = doc.data();

        // console.log("access info ", accessInfo);
      });
    })
    .catch(() => {
      throw Error(
        "The team access code with accesscode: " +
          _access_code +
          " was not found."
      );
    });

  return accessInfo;

  // return userData;
}

export async function getKeys(_teamId?: string) {
  var accessInfo: any[] = [];

  await firebase
    .firestore()
    .collection(FPATH.CONFIGS)
    .where("_teamId", "==", _teamId)
    .get()
    .then((docRef) => {
      docRef.docs.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data());
        accessInfo.push(doc.data());
        // console.log("access info ", accessInfo);
      });
    })
    .catch(() => {
      throw Error(
        "The team access team with accesscode: " + _teamId + " was not found."
      );
    });

  return accessInfo;
}

/**
 * @description Updates the contact-group sub-collection of the provided userId with provided list
 * @author Saurabh_M
 * @version 2021.08.12
 * @param {string} userId
 * @return {*}  {Promise<ContactGroupSchema[]>}
 */
export async function createBMLContactGroup(
  userId: string,
  lists: dListBuilderList[]
): Promise<ContactGroupSchemaa[]> {
  var results: ContactGroupSchemaa[] = [];
  const contactGroupRef = firebase
    .firestore()
    .collection(`${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}`);

  const contactIds: string[] = [];

  lists.map(async (list) => {
    try {
      list.contacts.forEach((contact) => {
        contactIds.push(contact._cid);
      });
      const data: ContactGroupSchemaa = {
        _id: list.id,
        name: list.title,
        groupType: "list", //CONTACTGROUPTYPE.LIST.valueOf(),
        contacts: contactIds,
        shareTo: [],
      };
      results.push(data);
      await contactGroupRef.doc(list.id).set(data, { merge: true });
    } catch (error) {
      return [];
    }
  });
  return results;
}

/**
 * @description Obtains the list from the contact-groups sub-collection associated with the provided userId
 * @author Saurabh_M
 * @version 2021.08.10
 * @param {string} userId
 * @returns {*}  {Promise<ContactGroupSchema[]>}
 */
export async function getUserContactGroup(
  userId: string
): Promise<ContactGroupSchemaa[]> {
  var results: ContactGroupSchemaa[] = [];

  const contactGroupRef = firebase
    .firestore()
    .collection(`${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}`);

  await contactGroupRef
    .where("groupType", "==", "list")
    .get()
    .then((docSnap) => {
      docSnap.docs.forEach((doc) => {
        const temp: ContactGroupSchemaa = <ContactGroupSchemaa>doc.data();
        results.push(temp);
      });
    })
    .catch(() => {
      throw Error("Error while fetching List Data for userId " + userId);
    });

  return results;
}

/**
 * @description Updates the contact-groups sub-collection of provided userId with provided list
 * @author Saurabh_M
 * @version 2021.08.12
 * @param {string} userId
 * @param {ContactGroupSchemaa} list
 */
export async function updateUserBMLListToDB(
  userId: string,
  list: ContactGroupSchemaa,
  contactsList: ContactSchemaa[]
) {
  const db = firebase.firestore();
  //console.log("Saving list to user Profile :>> ", list.contacts);
  // console.log(
  //   `${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}/${list._id}`
  // );

  await firebase
    .firestore()
    .doc(`${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}/${list._id}`)
    .set(
      {
        ...list,
        contacts: firebase.firestore.FieldValue.arrayUnion(...list.contacts),
      },
      { merge: true }
    );

  const batch = db.batch();
  contactsList.forEach(async (contact) => {
    const contactFields = Object.keys(contact);
    contactFields.forEach((f) => {
      if (f === "uid" || f === "_teamId") {
        delete contact[f];
      }
    });

    //NOTE Add the BML groups to the newly added BML contact
    if (!contactFields.includes("groups")) {
      contact.groups = ["BML"];
    } else {
      if (!contact.groups.includes("BML")) contact.groups.push("BML");
    }

    const docRef = db
      .collection(`${FPATH.USERS}/${userId}/${FPATH.CONTACTS}`)
      .doc(contact._cid);
    batch.set(docRef, contact, { merge: true });
  });
  await batch.commit();
}

export function saveBMLContact(
  contact: ContactSchemaa,
  userId: string,
  changedSection: CONTACT_SECTION
) {
  const db = firebase.firestore();
  const contactRef = db.doc(
    `${FPATH.USERS}/${userId}/${FPATH.CONTACTS}/${contact._cid}`
  );
  if (changedSection === CONTACT_SECTION.NOTES) {
    contactRef.set({ notes: contact.notes }, { merge: true });
  } else if (changedSection === CONTACT_SECTION.QUALIFICATION) {
    contactRef.set({ qualifications: contact.qualifications }, { merge: true });
  }

  // #region [section] NOTE Delete the deprecated fields from the contact document
  const contactFields = Object.keys(contact);
  if (contactFields.includes("pointers")) {
    contactRef.update({ pointers: firebase.firestore.FieldValue.delete() });
  }
  if (contactFields.includes("profileImage")) {
    contactRef.update({ profileImage: firebase.firestore.FieldValue.delete() });
  }
  if (contactFields.includes("points")) {
    contactRef.update({ points: firebase.firestore.FieldValue.delete() });
  }
  if (contactFields.includes("_uid")) {
    contactRef.update({ _uid: firebase.firestore.FieldValue.delete() });
  }
  // #endregion
}

export enum CONTACT_SECTION {
  "NOTES" = "NOTES",
  "QUALIFICATION" = "QUALIFICATION",
}

export function deleteBMLContact(contact: ContactSchemaa, userId: string) {
  const db = firebase.firestore();
  db.doc(
    `${FPATH.USERS}/${userId}/${FPATH.CONTACT_GROUPS}/${contact.listId}`
  ).update({
    contacts: firebase.firestore.FieldValue.arrayRemove(contact._cid),
  });

  db.doc(`${FPATH.USERS}/${userId}/${FPATH.CONTACTS}/${contact._cid}`).delete();
}

export async function getBMLContactsFromDB(
  userId: string
): Promise<ContactSchemaa[]> {
  const results: ContactSchemaa[] = [];
  const db = firebase.firestore();

  const userContactsRef = await db
    .collection(FPATH.USERS)
    .doc(userId)
    .collection(FPATH.CONTACTS)
    .get();

  userContactsRef.docs.forEach((contactRef) => {
    const tempResult = contactRef.data() as ContactSchemaa;
    const fields = Object.keys(tempResult);
    if (!fields.includes("qualifications")) tempResult["qualifications"] = [];
    if (tempResult.listId === "BML" || tempResult.groups?.includes("BML"))
      results.push(tempResult);
  });

  //NOTE OLD CODE DO NOT REMOVE YET (Part of BML)
  // await Promise.all(
  //   contactIds.map(async (contact) => {
  //     const tempResult = await getContactById(userId, contact);
  //     const fields = Object.keys(tempResult);
  //     if (!fields.includes("qualifications")) tempResult["qualifications"] = [];
  //     if (tempResult.listId === "BML") results.push(await tempResult);
  //   })
  // );
  //console.log("results at the end :>> ", results);

  return results;
}

//NOTE OLD CODE DO NOT REMOVE YET (Part of BML)
// const getContactById = async (userId: string, contact: string) => {
//   const db = firebase.firestore();

//   const result = db
//     .doc(`${FPATH.USERS}/${userId}/${FPATH.CONTACTS}/${contact}`)
//     .get();
//   const tempResult = <dContactSuggestion>(await result).data();
//   return tempResult;
// };

export async function updateConfigVariables(
  _teamId: string,
  data: Partial<dConfigVariables>
): Promise<void> {
  if (!data) return;
  return firebase
    .firestore()
    .collection(FPATH.CONFIGS)
    .doc(`variables-${_teamId}`)
    .set(data, { merge: true });
}

export async function addToolsKey(_teamId, value) {
  return await firebase
    .firestore()
    .collection(FPATH.CONFIGS)
    .doc(_teamId)
    .update({
      tools: firebase.firestore.FieldValue.arrayUnion(value),
    });
}

// export const authHandler = {
//   connectWithEmail,
//   checkEmailandPassword,
//   resetPassword,
//   sendEmailVerification,
//   connectWithGoogleAuth,
//   signOut,
// };

export enum AUTH_RESULT {
  /**
   * ### Account with email existed and verified
   * - Set a successful caption to inform user
   * - Should check `userCompletion()`
   */
  EMAIL_EXISTED_VERIFIED = "EMAIL_EXISTED_VERIFIED",
  /**
   * ### Account with email existed but not verified
   *
   * - 2 scenarios:
   *    - #### Log in again but not yet verified:
   *        - Set a `welcome back` caption to inform user
   *        - Show resend btn after some time in case user want to resend
   *    - #### Hit resend button (aka re-run `register()` )
   *        - Set a `Checking your acc` caption to inform user
   */
  EMAIL_EXISTED_xVERIFIED = "EMAIL_EXISTED_xVERIFIED",
  /**
   * ### Account with email does not exist in the db
   *
   * -  Set caption to inform user
   * -  Show resend btn after some time in case user want to resend ( deprecated for now and navigate to ProfileSetupFLO)
   */
  EMAIL_xEXISTED = "EMAIL_xEXISTED",
  /**
   * ### Error with email auth process
   *
   * -  errToast shows internally
   */
  ERR_EMAIL_AUTH = "ERR_EMAIL_AUTH",

  U_TEAM_MISMATCH = "U_TEAM_MISMATCH",
}
