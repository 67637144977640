import { IconPrimr } from "assets";
import { Buttoon, Imaggie, Kitten, sstyled, Txt } from "components/atoms";
import { MediaPageItemSchema, useAppContext } from "engines";
import { useAudioPlayerContext } from "engines/providers/audio-provider";
import React from "react";
import { View } from "react-native";
import { useDimension } from "utils";
import SeekBar from "./audio-seekbar";

interface dAudioItemFS {
  item: MediaPageItemSchema;
}

const speedRate = [0.75, 1, 1.5, 2];
/**
 * ### Brief description
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  <AudioItemFS >
 *  ----
 *  @version 22.03.08
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export const AudioItemFS = (props: dAudioItemFS) => {
  const { item } = props;
  const { C } = useAppContext();
  const {
    track,
    setTrack,
    isTrackPlayerInit: isSoundLoaded,
    isPlaying,
    onButtonPressed: handlePress,
    sliderValue,
    position,
    duration,
    slidingStarted,
    slidingCompleted,
    skipBy,
    rate,
    setRate,
  } = useAudioPlayerContext();
  const [audioSpeed, setAudioSpeed] = React.useState(
    rate ? rate.toString() + "x" : "1x"
  );
  const { WIDTH } = useDimension();

  const handleRateChange = (rate: number) => {
    setAudioSpeed(rate.toString() + "x");
    setRate(rate);
  };

  return (
    <A.ViewContainer>
      <A.CoverArt
        style={{ borderColor: C.grey500 }}
        source={{
          uri:
            track?.artwork && typeof track?.artwork === "string"
              ? track.artwork
              : "https://i.ibb.co/Fhr3mXW/music-icon-2.png",
        }}
      />
      <A.SubViewContainer style={{ marginTop: 20 }}>
        {speedRate.map((rate) => (
          <Buttoon
            key={rate}
            onPress={() => handleRateChange(rate)}
            appearance={
              audioSpeed === rate.toString() + "x" ? "outline" : "ghost"
            }
            status="basic"
          >
            <Txt.S1>{rate.toString() + "x"}</Txt.S1>
          </Buttoon>
        ))}
      </A.SubViewContainer>
      <A.SubViewContainer style={{ marginTop: 10 }}>
        <SeekBar
          durationSeconds={duration}
          disabled={!isSoundLoaded}
          onSlidingStart={slidingStarted}
          onSlidingComplete={slidingCompleted}
          positionSeconds={position}
          style={[{ width: WIDTH * 0.6, height: 40 }]}
          value={sliderValue}
        />
      </A.SubViewContainer>
      <A.SubViewContainer>
        <IconPrimr
          preset="circular"
          size={30}
          name={"skipbackward"}
          color={C.text}
          onPress={() => {
            skipBy(-10);
          }}
        />
        {isSoundLoaded && (
          <IconPrimr
            preset="circular"
            size={80}
            name={!isPlaying ? "play" : "pause"}
            color={C.text}
            onPress={handlePress}
          />
        )}
        {!isSoundLoaded && <Kitten.Spinner status={"basic"} size={"giant"} />}
        <IconPrimr
          preset="circular"
          size={30}
          name={"skipforward"}
          color={C.text}
          onPress={() => {
            skipBy(10);
          }}
        />
      </A.SubViewContainer>
    </A.ViewContainer>
  );
};

const A = {
  ViewContainer: sstyled(View)({
    flex: 1,
    width: "95%",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  }),
  SubViewContainer: sstyled(View)({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  }),
  CoverArt: sstyled(Imaggie)({
    // width: "70%",
    // height: "30%",
    padding: 10,
    borderRadius: 10,
    borderWidth: 2,
    width: 350,
    height: (550 * 3) / 4,
  }),
};
