import { Kitten } from "components";
import { Buttoon, Poppy, sstyled, Toasty, Txt } from "components/atoms";
import { BoxMediaPicker } from "components/atoms/others/BoxMediaPicker/BoxMediaPicker";
import { fn, ShortcutSchema, useAppContext } from "engines";
import { dUseCollectionReturns } from "engines/firebase/config";
import { useMoreDocument } from "engines/firebase/handler/more-handler";
import { DefaultMoreData } from "engines/firebase/initial-values";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import { useMoreCollection } from "engines/firebase/handler/more-handler";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { IconPrimr } from "assets/icons";
import { WidgetEditor } from "./widget-editor";
import * as R from "ramda";
import { FPATH } from "../../../engines/firebase/firebase.props";
import { Avt } from "../../atoms/generals/avt/Avt";

export function ExlinkEditor2(props: {
  shortcutsHandler?: dUseCollectionReturns<ShortcutSchema>;
  editingDocId?: string;
  onBack(): void;
  mode: "default" | "from-home";
  type?: "contact" | "link" | "inapp";
}) {
  const { editingDocId, type, mode = "default", onBack } = props;
  const { teamId, C } = useAppContext();
  const Navigation = useNavigator();
  const { _smallestPositionIndex, ...shortcutsHandler } = useMoreCollection();
  const shortcutHandler = useMoreDocument(editingDocId);
  //#region [section] fields
  const [position, setPosition] = React.useState<string>(
    shortcutHandler.data?.position
  );
  const [label, setLabel] = React.useState<string>(shortcutHandler.data?.name);
  const [url, setUrl] = React.useState<string>(
    shortcutHandler.data?.link ||
      shortcutHandler.data?.iosLink ||
      shortcutHandler.data?.androidLink ||
      shortcutHandler.data?.otherLink
  );

  const [_isStarred, setIsStarred] = React.useState<boolean>(
    mode === "from-home" || shortcutHandler.data?.isStarred
  );
  //#endregion
  //#region [section2] handle reposition
  function onMovingStart(sourceIndex: number, targetIndex: number) {
    let _shortcuts = shortcutsHandler?.data;
    /**
     * Calculate new target position value
     * -  if targetIndex < sourceIndex, position = avg(target-1 ,target);
     * if targetPostion is 0, position is set to -1
     * -  if targetIndex > sourceIndex, position = avg(target ,target + 1);
     * if targetPostion is n, position is set to n+1
     */
    const targetPosition = () => {
      if (targetIndex < sourceIndex) {
        return _shortcuts[targetIndex - 1]
          ? R.median([
              parseInt(_shortcuts[targetIndex - 1]?.position),
              parseInt(_shortcuts[targetIndex]?.position),
            ])
          : parseInt(_shortcuts[targetIndex]?.position) - 1;
      }
      if (targetIndex > sourceIndex) {
        return _shortcuts[targetIndex + 1]
          ? R.median([
              parseInt(_shortcuts[targetIndex]?.position),
              parseInt(_shortcuts[targetIndex + 1]?.position),
            ])
          : parseInt(_shortcuts[targetIndex]?.position) + 1;
      }
    };

    console.log("[move]: ", sourceIndex + " -> " + targetIndex);
    console.log(
      "[move] update position of doc ",
      _shortcuts[sourceIndex]?._id + " to " + targetPosition()
    );
    !R.isNil(targetPosition()) &&
      shortcutHandler
        .update<ShortcutSchema>(
          FPATH.MORE + "/" + _shortcuts[sourceIndex]?._id,
          {
            position: targetPosition(),
          }
        )
        .then(() => {
          Toasty.show(tr("Update position..."), {
            type: "loading",
            duration: 1100,
          });
        });
  }

  //#endregion
  //#region [functions]
  async function onSubmit() {
    shortcutsHandler?.add({
      ...DefaultMoreData({ teamId }),
      name: label,
      link: fn.js.validateWebUrl(url),
      // contactEmail,
      // contactPhone,
      logo: `https://www.google.com/s2/favicons?domain=${url}&sz=180`,
      position: _smallestPositionIndex() - 1,
      isStarred: _isStarred,
    });

    Toasty.show(tr("Successfully create a shortcut!"), {
      type: "success",
    });
    onBack && onBack();
  }

  async function onUpdate() {
    shortcutHandler?.update({
      name: label,
      link: fn.js.validateWebUrl(url),
      // contactEmail,
      // contactPhone,
      logo: `https://www.google.com/s2/favicons?domain=${url}&sz=180`,
      position,
      isStarred: _isStarred,
    });
    Toasty.show(tr("Successfully update a shortcut!"), {
      type: "success",
    }),
      onBack && onBack();
  }

  function onRemove() {
    Poppy(
      tr("Final Check"),
      tr("Do you want to remove this shortcut?"),
      {
        text: "Cancel",
        onPress: () => onBack(),
      },
      {
        text: "Yes",
        onPress: async () => {
          shortcutHandler.deleteDocument();
          Toasty.show(tr("Shortcut removed"));
          onBack && onBack();
        },
      }
    );
  }
  //#endregion
  return (
    <View style={{ width: "100%" }}>
      <Kitten.Avatar
        size="small"
        {...props}
        source={{
          uri: `https://www.google.com/s2/favicons?domain=${url}&sz=180`,
        }}
        style={{ margin: spacing(3) }}
        // onError={() => setIsError(true)}
      />
      <A.Input
        placeholder={tr("Name")}
        autoCapitalize="none"
        value={label}
        onChangeText={setLabel}
      />
      <A.Input
        placeholder={tr("URL")}
        autoCapitalize="none"
        value={url}
        onChangeText={setUrl}
      />
      <Kitten.Divider_________ />
      <Kitten.ListItemv2
        onPress={() => setIsStarred(!_isStarred)}
        title={"Star this shortcut"}
        description={`Shortcut will${
          _isStarred ? "" : " not"
        } show up on home screen`}
        accessoryRight={() => (
          <IconPrimr
            onPress={() => setIsStarred(!_isStarred)}
            name={_isStarred ? "star" : "staro"}
            color={_isStarred ? C.primary : C.grey600}
          />
        )}
        style={{ backgroundColor: "transparent" }}
      />
      <Kitten.Divider_________ />
      {/* {shortcutsHandler && <Buttoon onPress={onSubmit}>{tr("Create")}</Buttoon>} */}
      {1 == 1 && (
        <Kitten.ViewH
          style={{ justifyContent: "space-between", margin: spacing(3) }}
        >
          <Buttoon
            appearance={"ghost"}
            size="tiny"
            onPress={() => onBack && onBack()}
          >
            {tr("Cancel")}
          </Buttoon>
          <Buttoon onPress={onSubmit}>{tr("Create")}</Buttoon>
        </Kitten.ViewH>
      )}
      {/* {editingDocId && type && ( */}
      {1 == 2 && (
        <Kitten.ViewH
          style={{ justifyContent: "space-between", margin: spacing(3) }}
        >
          <Buttoon
            appearance={"ghost"}
            size="tiny"
            onPress={() => onBack && onBack()}
          >
            {tr("Cancel")}
          </Buttoon>
          <Buttoon appearance="ghost" status="danger" onPress={onRemove}>
            {tr("Delete")}
          </Buttoon>
          <Buttoon onPress={onUpdate}>{tr("Update")}</Buttoon>
        </Kitten.ViewH>
      )}
    </View>
  );
}

const A = {
  CtnrMenu: sstyled(Kitten.Menu)({ minWidth: 8 }),
  Input: sstyled(TextInput)({
    minWidth: 3,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
  ViewThumbnail: sstyled(View)({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: spacing(6),
  }),
  ViewMediaUploader: sstyled(View)({
    // transform: [{ scale: 0.5 }],
    width: 6,
    height: 6,
    alignSelf: "flex-start",
  }),
};
