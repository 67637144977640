import { Kitten, sstyled, Txt } from "components";
import { useAppContext } from "engines";
import React from "react";
import { spacing } from "utils";

/**
 * ### FAQ Card for displaying FAQ title and description
 *  ----
 *  @version 21.03.13
 *  -  *Build a card*
 *  @author  K
 *
 **/
export function FAQCard(props: dFAQCard) {
  const { onPress, item, type } = props;
  const { C } = useAppContext();
  const [_borderWidth, setBorderWidth] = React.useState(0);
  try {
    return (
      <SS.CtnrCard
        onMouseEnter={(e) => {
          setBorderWidth(6);
        }}
        onMouseLeave={(e) => {
          setBorderWidth(0);
        }}
        onPress={onPress}
        style={{
          backgroundColor: item.color,
          borderWidth: _borderWidth,
          borderColor: C.primary,
        }}
      >
        <Txt.H6 style={{ color: C.text01 }}>{item?.title}</Txt.H6>
        <Txt.S1 style={{ color: C.text01 }}>{item?.label}</Txt.S1>
      </SS.CtnrCard>
    );
  } catch (error) {
    throw Error(`::RkFRQ2FyZA==::${error}`);
  }
}

interface dFAQCard {
  onPress(): void;
  item?: { thumbnail: string; title: string; color: string; label: string };
  type?: "placeholder";
}

const SS = {
  CtnrCard: sstyled(Kitten.TouchableWeb)({
    // Responsive -> 100% on smaller screens, 50% on larger screens
    width: "100%",
    margin: 3,
    height: 9,
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: spacing(5),
    borderRadius: 10,
  }),
};
