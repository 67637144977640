import { usePortal } from "@gorhom/portal";
import { BottomTabNavigationOptions } from "@react-navigation/bottom-tabs";
import {
  DefaultNavigatorOptions,
  NavigationContainerRef,
  NavigationState,
  PartialState,
  RouteProp,
  StackActions,
  useNavigation
} from "@react-navigation/native";
import {
  HeaderStyleInterpolators,
  StackNavigationOptions,
  TransitionSpecs
} from "@react-navigation/stack";
import {
  StackNavigationConfig,
  TransitionSpec
} from "@react-navigation/stack/lib/typescript/src/types";
import { IconPrimr } from "assets";
import { useSx } from "dripsy";
import { useAppContext } from "engines";
import * as R from "ramda";
import React, { useEffect, useRef, useState } from "react";
import { BackHandler, Platform } from "react-native";
import { BS_STK, dDialogParams, dOverlayParams } from "screens";
import { IS_WEB, spacing } from "utils";
import { COLLABFEED_STK } from "./collaboration.navigator";
import { DRAWER_STK } from "./drawer.navigator";
import { FAQ_STK } from "./faq.navigator";
import { HOME_STK } from "./home.navigator";
import { dLayoverParams } from "./layover.navigator";
import { MAIN_STK } from "./main.navigator";
import { PAGES_STK } from "./pages.navigator";
import { ROOT_STK } from "./root.navigator";
import { TOOLS_STK } from "./tools.navigator";

/**
 * ### Custom useNavigator(_) hook
 *  - Basically a `useNavigation()` bootleg, but it'll be better trust me
 *  - Navigation.navigate("ROUTE_NAME_IS TYPESCRIPTED_OMG😝")
 *  ----
 *  @example
 *  Navigation.navigate("Home")
 *  
 *  Navigation.navigate<dGalleryScrParams>("Gallery", {
      images: [{ url: avatar }],
      imageIndex: 0,
    });
 *  ----
 *  @version 21.03.15
 *  -  *Build the hook*
 *  @author  K
 *
 **/
export function useNavigator() {
  let _navigation = useNavigation();
  //#region [section] overlay
  const overlayPortal = usePortal("overlay");
  function open({ render, ...params }: dOverlayParams) {
    overlayPortal.addPortal("overlay", render);
    overlayPortal.updatePortal("overlay", render);

    // attempt re render if needed on pulling up a overlayPortal immediately after another
    setTimeout(() => {
      overlayPortal.updatePortal("overlay", render);
    }, 100);
    setTimeout(() => {
      overlayPortal.updatePortal("overlay", render);
    }, 1000);

    return _navigation.dispatch(StackActions.push("Overlay", params));
  }
  //#endregion
  //#region [section2] dialog
  const dialogPortal = usePortal("dialog");
  function openDialog({ render, loadingTime = 0, ...params }: dDialogParams) {
    dialogPortal.addPortal("dialog", render);
    return _navigation.dispatch(StackActions.push("Dialog", params));
  }
  function closeDialog() {
    dialogPortal.removePortal("dialog");
    return _navigation.dispatch(StackActions.push("Dialog", {}));
  }
  //#endregion
  //#region [section] layover
  function layover<LayoverP>({ ...params }: dLayoverParams<LayoverP>) {
    return _navigation.dispatch(
      StackActions.push("Layover", {
        ...params,
      })
    );
  }
  //#endregion
  /**
   *  @example
   *  Navigation.navigate("Home")
   *  
   *  Navigation.navigate<dGalleryScrParams>("Gallery", {
        images: [{ url: avatar }],
        imageIndex: 0,
      });
  *  ----
  *  @version 21.03.15
  *  -  *Build the hook*
  *  @author  K
  *
  **/
  function navigate<P>(
    name: NAVIGATION_ROUTE,
    param?: P | { screen?: NAVIGATION_ROUTE; params?: P }
  ) {
    //@ts-expect-error
    return _navigation.navigate(name, param);
  }

  return {
    ..._navigation,
    navigate,
    overlayPortal: { open },
    dialogPortal: { open: openDialog, close: closeDialog },
    layover,
  };
}

export function useNavigatorPresetConfig() {
  let sx = useSx();
  const { C, theme, setTheme } = useAppContext();
  const Navigation = useNavigator();
  //#region [section] drawer config
  const drawerConfig = {
    // drawerType: breakpointIndex > 1 ? "permanent" : "front"} //*
    hideStatusBar: false,
    drawerPosition: "left",
    drawerType: "permanent",
    headerShown: false,
    drawerStyle: {
      borderLeftColor: C.dim,
      backgroundColor: C.background,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
  };
  //#endregion
  //#region [section2] bottom tab config
  const bottomTabConfig = {
    screenOptions: {
      headerStyle: { backgroundColor: C.background },
      headerTitleStyle: { color: C.text },
      tabBarActiveTintColor: C.primary,
      tabBarStyle: {
        backgroundColor: C.background,
        borderTopColor: C.dim,
      },
      tabBarShowLabel: false,
      headerTitleAlign: "center",
      // headerLeft: () => <ViewUserCorner showInfo={false} />,
      // headerRight: () => (
      //   <Kitten.ViewH>
      //     <Txt.C2
      //       onPress={() => {
      //         setTheme(theme == THEME.DARK ? THEME.LIGHT : THEME.DARK);
      //       }}
      //     >
      //       {"v" + version}
      //       {" •"}
      //     </Txt.C2>
      //     <IconPrimr
      //       name={theme == THEME.DARK ? "moon" : "sunny"}
      //       color={C.text}
      //       size={scale(14)}
      //     />
      //   </Kitten.ViewH>
      // ),
      // headerTitle: () => <ViewTeamLogo />,
    } as BottomTabNavigationOptions,
  };
  //#endregion
  //#region [section] main stack config
  const mainStackConfig = {
    screenOptions: {
      headerShown: true,
      headerTintColor: C.primary,
      headerTitleAlign: Platform.select({
        ios: "center",
        android: "left",
        web: "center",
      }),
      headerLeftContainerStyle: sx({
        // backgroundColor: "red",
        paddingLeft: [0, 0, 0, "8%", "11%"],
      }),
      headerRightContainerStyle: sx({
        // backgroundColor: "red",
        paddingRight: [0, 0, 0, "8%", "11%"],
      }),
      headerStyle: {
        backgroundColor: C.background,
        borderBottomWidth: 0,
      },
      headerTitleStyle: { color: C.text },
      animationTypeForReplace: "pop",
      gestureDirection: "horizontal",
      transitionSpec: {
        open: TransitionSpecs.TransitionIOSSpec,
        close: TransitionSpecs.TransitionIOSSpec,
      },
      headerStyleInterpolator: HeaderStyleInterpolators.forSlideUp,
      cardStyleInterpolator: ({ current, next, layouts }) => {
        return {
          cardStyle: {
            opacity: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 1],
            }),
          },
          overlayStyle: {
            opacity: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 0.5],
            }),
          },
        };
      },
    } as StackNavigationOptions,
  };
  //#endregion

  //#region [section2] overlay stack
  const overlayStackConfig = {
    screenOptions: {
      headerShown: true,
      headerStyle: sx({ backgroundColor: "surface" }),
      cardStyle: sx({
        flex: 1,
        marginTop: IS_WEB ? 30 : 0,
        alignContent: "center",
        marginLeft: ["0%", 0, "3%", "15%"],
        marginRight: ["0%", 0, "3%", "15%"],
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: "surface", //* same config with Kitten.Card
        paddingHorizontal: spacing(3),
        borderWidth: 1,
        borderBottomWidth: 0,
        borderColor: "line", //* same config with Kitten.Card
      }),
      // ...TransitionPresets.ModalPresentationIOS,
      animationEnabled: true,
      headerTitleAlign: "center",
      headerStatusBarHeight: 0,
      headerLeft: (props) => (
        <IconPrimr
          preset={"circular"}
          name={"x"}
          color={C.primary}
          containerStyle={{ marginLeft: spacing(2) }}
          onPress={() => Navigation.goBack()}
        />
      ),
    } as StackNavigationOptions,
  };
  //#endregion
  //#region [section] root stack config
  const rootStackConfig: StackNavigationConfig & {
    screenOptions: StackNavigationOptions;
  } = {
    screenOptions: {
      cardOverlayEnabled: true,
      // animationEnabled: true,
      gestureEnabled: true,
      headerStyle: {
        elevation: 0,
        backgroundColor: C.background,
        borderBottomColor: C.dim,
      },
      headerTitleStyle: {
        color: C.text,
        fontWeight: "700",
      },
      // headerTintColor: C.primary,
      // safeAreaInsets: {},
      headerTitleAlign: "left",
      headerBackTitleVisible: false,
      // headerLeft: () => <C_AvatarCorner />,
      headerShown: false,
      cardStyle: { backgroundColor: "transparent" },

      ...(IS_WEB && {
        //* For transparent overlay anm (e.g. posst-creator scr)
        cardStyleInterpolator: ({ current: { progress } }) =>
          IS_WEB && {
            cardStyle: {
              opacity: progress.interpolate({
                inputRange: [0, 0.5, 0.9, 1],
                outputRange: [0, 0.25, 0.7, 1],
              }),
            },
            overlayStyle: {
              opacity: progress.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0.5],
                extrapolate: "clamp",
              }),
            },
          },
      }),
    },
  };
  //#endregion
  //#region [section2] overlay dialog
  const overlayDialogConfig = {
    screenOptions: {
      // ...TransitionPresets.DefaultTransition,
      presentation: "transparentModal",
      cardStyleInterpolator: ({ current, next, layouts }) => {
        return {
          cardStyle: {
            opacity: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 1],
            }),
          },
          overlayStyle: {
            opacity: current.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 0.5],
            }),
          },
        };
      },
      animationEnabled: true,
      headerShown: false,
      cardStyle: sx({
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
      }),
    } as StackNavigationOptions,
  };
  //#endregion
  return {
    rootStackConfig,
    drawerConfig,
    bottomTabConfig,
    mainStackConfig,
    overlayStackConfig,
    overlayDialogConfig,
  };
}

/**
 * Gets the current screen from any navigation state.
 * @deprecated
 */
export function getActiveRouteName(
  state: NavigationState | PartialState<NavigationState>
) {
  const route = state.routes[state.index];

  // Found the active route -- return the name
  if (!route.state) return route.name;

  // Recursive call to deal with nested routers
  return getActiveRouteName(route.state);
}

/**
 * Hook that handles Android back button presses and forwards those on to
 * the navigation or allows exiting the app.
 * @deprecated
 */
export function useBackButtonHandler(
  ref: React.RefObject<NavigationContainerRef>,
  canExit: (routeName: string) => boolean
) {
  const canExitRef = useRef(canExit);

  useEffect(() => {
    canExitRef.current = canExit;
  }, [canExit]);

  useEffect(() => {
    // We'll fire this when the back button is pressed on Android.
    const onBackPress = () => {
      const navigation = ref.current;

      if (navigation == null) {
        return false;
      }

      // grab the current route
      const routeName = getActiveRouteName(navigation.getRootState());

      // are we allowed to exit?
      if (canExitRef.current(routeName)) {
        // let the system know we've not handled this event
        return false;
      }

      // we can't exit, so let's turn this into a back action
      if (navigation.canGoBack()) {
        navigation.goBack();

        return true;
      }

      return false;
    };

    // Subscribe when we come to life
    BackHandler.addEventListener("hardwareBackPress", onBackPress);

    // Unsubscribe when we're done
    return () =>
      BackHandler.removeEventListener("hardwareBackPress", onBackPress);
  }, [ref]);
}

/**
 * Custom hook for persisting navigation state.
 * @deprecated
 */
export function useNavigationPersistence(
  storage?: any,
  persistenceKey?: string
) {
  const [initialNavigationState, setInitialNavigationState] = useState();
  const [isRestoringNavigationState, setIsRestoringNavigationState] =
    useState(true);

  const routeNameRef = useRef();
  const onNavigationStateChange = (state) => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = getActiveRouteName(state);

    if (previousRouteName !== currentRouteName) {
      // track screens.
      console.info("🗺️ Current route: ", currentRouteName);
    }

    // Save the current route name for later comparision
    routeNameRef.current = currentRouteName;

    // Persist state to storage
    // storage.save(persistenceKey, state);
  };

  const restoreState = async () => {
    try {
      // const state = await storage.load(persistenceKey);
      // if (state) setInitialNavigationState(state);
    } finally {
      setIsRestoringNavigationState(false);
    }
  };

  useEffect(() => {
    if (isRestoringNavigationState) restoreState();
  }, [isRestoringNavigationState]);

  return { onNavigationStateChange, restoreState, initialNavigationState };
}

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes: NAVIGATION_ROUTE[] = ["Welcome", "Home"];
export const canExit = (routeName: NAVIGATION_ROUTE) =>
  exitRoutes.includes(routeName);

/**
 * Preset Navigation Config
 *
 * ---
 * @version 0.12.18
 *  - *correct type `durationSpec`*
 */
export const presetNavConfig = {
  durationSpec: {
    config: { duration: 200 },
    animation: "timing",
  } as TransitionSpec,
  noHeader: { headerShown: false },
  /**
   * ###  Set header title based on rnav's param
   * @example ({ route }) => ({
      ...presetNavConfig.headerTitle({ route, param: "sboard", key: "title" }),
    }),
   */
  headerTitle: ({
    route,
    param,
    key,
  }: {
    route: RouteProp<any, any>;
    param: string;
    key?: string | number;
  }) => ({
    title:
      R.isNil(param) || R.isNil(route.params)
        ? ""
        : !!key
          ? route.params[param][key]
          : route.params[param],
  }),
  // backButtonAsX:
  noTitle: {
    headerTitleStyle: {
      fontSize: 0,
    },
  },
};

export type NAVIGATION_ROUTE =
  | ROOT_STK
  | MAIN_STK
  | DRAWER_STK
  | HOME_STK
  | TOOLS_STK
  | FAQ_STK
  | PAGES_STK
  | COLLABFEED_STK
  | BS_STK;

/**
 * ### Type of stacked screen collection
 *
 * @note  Mask it when not use for auto-completion
 * ---
 * @example
 * ```
 * // const SCR_C0: dStackedScreenC0 = { <---NOTE mask when not use
 * const SCR_C0 = {
 *  "Home": {
 *    component: HomeScreen,
 *    options: { title: "" },
 *  },
 * }
 * ```
 * ---
 * @version 0.12.18
 * @author nguyenkhooi
 */
export interface dNavigatorCollection {
  [name: string]: {
    component: JSX.Element | ((p: any) => JSX.Element);
    // options: StackNavigationOptions;
    options?: DefaultNavigatorOptions<StackNavigationOptions>["screenOptions"];
  };
}
