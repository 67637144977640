import { UserSchema } from "engines";

/**
 * ###
 *  - Function used in checking created_date
 *  of user to comfirm if the user if new or not
 * This is been used in Home Screen, line 66, 
 * it is used to check if we can post the 
 * Welcome! Tap here to get started Modal
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.01.30
 *  -  *Brief changelog*
 *  @author  JR,NL
 *
 **/

export function isUserNew(_user?: UserSchema) {
  const user = _user ? _user : this._account;
  if (!user) return false;
  let createdAt: Date = user?.createdAt?.toDate();
  if (!createdAt) createdAt = new Date();
  const rightNow = new Date();
  const secondsSinceSignUp =
    rightNow.getTime() / 1000 - createdAt?.getTime() / 1000;
  console.log("seconds since user signed up: " + secondsSinceSignUp);
  if (secondsSinceSignUp < 60 * 60) {
    return true;
  }
  return false;
}
