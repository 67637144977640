import { StackActions, useNavigation } from "@react-navigation/native";
import {
  Buttoon,
  Kitten,
  PagePicker, Toasty,
  Txt
} from "components/atoms";
import {
  FPATH,
  MediaPageItemSchema,
  MediaPageSchema,
  usePagesCollection
} from "engines";
import firebase from "firebase";
import React from "react";
import { View } from "react-native";
import { spacing } from "utils";
import { dCustomPageItemProps } from "../items/CustomPageItem";

interface d {
  item: MediaPageItemSchema;
  parentPageId: string;
}

/**
 * ### Let the user move a CustomPageItem to a different page
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.10.05
 *  @author  jm_francis
 *
 **/
export function MoveItemScreen(props: d) {
  const { parentPageId, item } = props;

  const Navigation = useNavigation();

  const [shouldCopy, setShouldCopy] = React.useState<boolean>(false);
  const [moveItemCompleted, setMoveItemCompleted] =
    React.useState<boolean>(false);

  const pagesHandler = usePagesCollection();

  const [selectedPage, setSelectedPage] = React.useState<MediaPageSchema>(null);

  async function onMoveItemPressed() {
    const __toasty = Toasty.show(`Moving item to ${selectedPage?.name}...`, {
      type: "loading",
      duration: 999999,
    });
    const pagesRef = firebase.firestore().collection(FPATH.PAGES);
    try {
      const transformedItem = Object.assign({}, { ...item, ...(item.textTemplate ? { textTemplate: { ...item.textTemplate, parentPageId, parentPageName: selectedPage?.name } } : {}) });
      delete transformedItem["__snapshot"];
      // alert(JSON.stringify(transformedItem));
      const newItemId = (
        await pagesRef
          .doc(selectedPage._id)
          .collection(FPATH.CUSTOM_PAGE_CONTENT)
          .add(transformedItem)
      ).id;
      await pagesRef
        .doc(selectedPage._id)
        .collection(FPATH.CUSTOM_PAGE_CONTENT)
        .doc(newItemId)
        .update({ _id: newItemId });
      !shouldCopy &&
        (await pagesRef
          .doc(parentPageId)
          .collection(FPATH.CUSTOM_PAGE_CONTENT)
          .doc(item._id)
          .delete());

      setMoveItemCompleted(true);

      Toasty.hide(__toasty);
      Toasty.show(
        `${item?.title ? item.title : "Item"} successfully moved to ${selectedPage?.name
        }`,
        { type: "success" }
      );
    } catch (err) {
      Toasty.hide(__toasty);
      Toasty.show(`Move failed. ${err}`, {
        type: "danger",
      });
    }
  }

  /**
   * If the item is already moved, you may want to visit it!
   */
  if (moveItemCompleted)
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "space-evenly",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Buttoon
          status="Done"
          icon={{ right: true, name: "check" }}
          onPress={() => Navigation.goBack()}
        >
          Finish
        </Buttoon>
        <Buttoon
          status="info"
          icon={{ right: true, name: "arrow_right_top" }}
          onPress={() => {
            Toasty.show("You may need to scroll to find the item.", {
              type: "normal",
            });
            Navigation.goBack();
            Navigation.dispatch(
              StackActions.push("Page Contents", {
                cpid: selectedPage._id,
                collabMode: false,
              })
            );
          }}
        >
          View item
        </Buttoon>
      </View>
    );

  return (
    <View style={{ flex: 1 }}>
      <Txt.H6 style={{ marginBottom: spacing(4) }}>
        Select a page to move to
      </Txt.H6>

      <PagePicker pages={pagesHandler?.data} onPageSelected={setSelectedPage} />

      <Kitten.CheckBox
        checked={shouldCopy}
        onChange={setShouldCopy}
        style={{ marginTop: spacing(3) }}
      >
        Copy item
      </Kitten.CheckBox>
      <Buttoon
        style={{ marginTop: spacing(4) }}
        status="basic"
        icon={{ right: true, name: "arrow_right" }}
        disabled={!selectedPage}
        onPress={onMoveItemPressed}
      >
        {shouldCopy ? "Copy item" : "Move item"}
      </Buttoon>
    </View>
  );
}
