import { Buttoon, Kitten, sstyled, Toasty, Txt } from "components/atoms";
import { RushBar } from "components/atoms/generals/rush/rush-bar";
import { BoxMediaPicker } from "components/atoms/others/BoxMediaPicker/BoxMediaPicker";
import { dPosstCreatorMedia } from "components/molecules/posst-creator/PosstCreatorMedia";
import {
    dUseDocumentReturns,
    fn,
    ItemType,
    MediaPageItemSchema,
    ServiceType,
    typeOfItem,
    useAppContext,
    __ResourceItemData
} from "engines";
import { usePageContentsCollection } from "engines/firebase/handler/page-contents-handler";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import React from "react";
import { TextInput, View } from "react-native";
import WebView from "react-native-webview";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";

export function DocumentItemEditor({
    cpid,
    onCloseCallback = () => { },
    pageContentDocument,
}: P) {
    const { C, teamId } = useAppContext();
    let editorMode: "CREATE" | "UPDATE" =
        pageContentDocument && pageContentDocument?.data ? "UPDATE" : "CREATE";

    //#region [FRBS] page contents handler
    const pageContentsHandler = usePageContentsCollection(cpid);
    let pageContentD = pageContentDocument?.data;
    //#endregion
    const Navigation = useNavigator();

    const [localMedia, setLocalMedia] =
        React.useState<dPosstCreatorMedia["initialMedia"]>(null);
    const [caption, setCaption] = React.useState<string>("");
    const [uploadPercentage, setUploadPercentage] = React.useState<number>(0);

    // console.log(
    //   "localUri ",
    //   localMedia?.uri ? "exists but is SUPER long so chill" : "does not exist"
    // );
    // console.log("urlFrom3rdParty ", urlFrom3rdParty);
    // console.log("caption ", caption);

    //#region [section] handle input enabler
    function disableSubmission() {
        if (editorMode === "UPDATE") {
            return !(caption !== pageContentD?.title || localMedia?.assets)
        }
        return !((localMedia && (localMedia?.assets || localMedia?.file) && caption));
    }
    //#endregion

    //#region [functions]
    async function onSubmit(xong) {
        // alert(JSON.stringify(localMedia));
        const type = localMedia?.mimeType
            ? localMedia.mimeType
            : fn.media.extractMediaType(localMedia?.url) || localMedia?.type;
        if (
            localMedia &&
            (localMedia?.assets?.length > 0 || localMedia?.file)
            //&& localMedia?.assets?.length !== pageContentD?.media
        ) {
            fn.media.uploadMedia(
                localMedia?.assets?.length > 0 ? localMedia?.assets[0]?.uri : localMedia.file || localMedia.uri,
                {
                    title: `doc-${caption.slice(0, 15)}`,
                    // folder: "pages/" + cpid,
                    folder: `${CLOUDINARY_FOLDER.CLIENTS}/${teamId}`,
                    type,
                },
                async (percentage) => {
                    // Toasty.show(`${percentage}% uploaded...`);
                    setUploadPercentage(percentage);
                },
                async (data) => {
                    switch (editorMode) {
                        case "CREATE":
                            pageContentsHandler.add({
                                ...__ResourceItemData(),
                                url: data?.url,
                                position: pageContentsHandler._largestPositionIndex() + 1,
                                title: caption,
                                _teamId: teamId,
                                _parentPageId: cpid
                            });
                            setTimeout(() => {
                                Toasty.show(tr("Media item added!"));
                                xong && xong();
                                onCloseCallback && onCloseCallback();
                            }, 690);
                            break;
                        case "UPDATE":
                            pageContentDocument.update({
                                url: data?.url,
                                title: caption?.length > 0 ? caption : pageContentD?.title,
                                _teamId: teamId,
                                _parentPageId: cpid,
                            });
                            Toasty.show(tr("Update complete. Refresh the page if needed."));
                            xong && xong();
                            break;
                        default:
                            xong && xong();
                            break;
                    }
                }
            );
        }
    }

    //#endregion

    //#region [section]
    const refInputCaption = React.useRef<TextInput>();

    //#endregion

    React.useEffect(function setupInitialData() {
        if (editorMode === "UPDATE") {
            setCaption(pageContentD?.title);

            const initialMedia: dPosstCreatorMedia["initialMedia"] = {
                uri: pageContentD?.media,
                type: ItemType.DOCUMENT
            };
            setLocalMedia(initialMedia);
        }
    }, []);

    return (
        <View style={{ width: "100%" }}>
            {
                //#region [section] local file input component
                <View style={{ flex: 1 }}>
                    <BoxMediaPicker
                        key={1}
                        onSelect={(media) => {
                            setLocalMedia(media);
                        }}
                        allowedFileTypes={["application/pdf"]}
                        initialMedia={localMedia}
                        type="Images"
                    />
                </View>
                //#endregion
            }
            <A.InputCaption
                ref={refInputCaption}
                placeholder={tr("Media Title")}
                onChangeText={setCaption}
                value={caption}
                style={{ marginBottom: spacing(3) }}
            />
            {uploadPercentage > 0 ? (
                <>
                    <Txt.H5
                        style={{
                            color: C.primary,
                            alignSelf: "center",
                            marginTop: spacing(3),
                        }}
                    >{`${uploadPercentage}%`}</Txt.H5>
                    <RushBar
                        progress={uploadPercentage / 100}
                        style={{ marginVertical: spacing(3) }}
                        color={C.primary}
                    />
                </>
            ) : (
                <Buttoon
                    progress
                    disabled={disableSubmission()}
                    onPress={(xong) => {
                        onSubmit(xong);
                    }}
                >
                    {editorMode === "CREATE" ? tr(`Add`) : tr("Update")}
                </Buttoon>
            )}

            <Buttoon
                appearance={"ghost"}
                onPress={() => {
                    Navigation.goBack();
                }}
            >
                {tr("Cancel")}
            </Buttoon>
        </View>
    );
}

interface P {
    /**
     * the doc id of the current custom page
     */
    cpid: string;
    onCloseCallback(): void;
    pageContentDocument?: dUseDocumentReturns<MediaPageItemSchema>;
}

const A = {
    CtnrWebView: sstyled(WebView)({
        width: "100%",
        height: [250, 300, 350, 420],
        backgroundColor: "black",
        borderRadius: 10,
        overflow: "hidden",
    }),
    InputCaption: sstyled(Kitten.Input)({
        marginTop: 3,
        marginBottom: 3,
    }),
};
