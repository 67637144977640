import { useCollection } from "@nandorojo/swr-firestore";
import {
  Buttoon,
  CardPosst,
  Imaggie,
  Skeleton,
  sstyled,
  Txt
} from "components";
import { fn, FPATH, PosstSchema, POSST_STATUS, useAppContext } from "engines";
import { getThumbnailFromURL } from "engines/functions/media-functions";
import * as React from "react";
import { Dimensions, Pressable, View } from "react-native";
import Swiper from "react-native-web-swiper";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";

export function SpotlightRoll() {
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  //#region [FRBS]
  const { data: posstsD, ...posstsHandler } = useCollection<PosstSchema>(
    FPATH.POSSTS,
    {
      listen: true,
      where: [
        ["_teamId", "==", teamId],
        ["pinned", "==", true],
        ["status", "==", POSST_STATUS.POSTED],
      ],
    }
  );
  //#endregion

  const [galleryList, setGalleryList] = React.useState<dSpotlightItem[]>([]);
  const [isShown, setIsShown] = React.useState<boolean>(true);

  React.useEffect(
    /**
     * Swiper component doesn't auto refresh when new data is loaded,
     * so we have to do the trick of toggling isShown to re-render the component
     */
    function buildGallery() {
      setIsShown(false);
      /**
       * This _galleryList will
       *  * filter only image medias,
       *  * add _pid to the media object to refer to correct posst when user click on it
       *  * flat() to concat all sub-array medias into a single array
       */
      let _galleryList = posstsD
        ?.map((posst, index) => {
          const mediasWithPid = posst.medias
            // .filter((media) => media.type?.includes("image"))
            .map((media) => ({ _pid: posst._pid, ...media, index }));
          return mediasWithPid;
        })
        .flat();
      setGalleryList(_galleryList);
      if (!isShown)
        setTimeout(() => {
          setIsShown(true);
        }, 1000);
    },
    [posstsD]
  );

  try {
    if (posstsHandler.error)
      return <Txt.Indicator>{tr("Error getting spotlight")}</Txt.Indicator>;
    if (!posstsD) return <Skeleton layout={Skeleton.Layout.Item} />;
    return (
      galleryList?.length > 0 && (
        <View>
          <A.ViewSpotlight>
            <View style={{ flex: 1 }}>
              {isShown && (
                <Swiper
                  loop
                  timeout={3}
                  controlsProps={{
                    dotActiveStyle: { backgroundColor: C.primary },
                    nextTitle: "⟶",
                    nextTitleStyle: { fontSize: 30, color: C.primary },
                    prevTitle: "⟵",
                    prevTitleStyle: { fontSize: 30, color: C.primary },
                  }}
                  springConfig={{ speed: 10, bounciness: 0 }}
                // innerContainerStyle={{ height: 100 }}
                >
                  {galleryList?.map((item, index) => (
                    <Pressable
                      key={item?._pid + index}
                      onPress={() => {
                        Navigation.dialogPortal.open({
                          headerTitle: tr("Spotlight post"),
                          render: <CardPosst posst={posstsD[item.index]} posstId={item._pid} />,
                        });
                      }}
                    >
                      <Imaggie
                        key={item?._pid + index}
                        style={{ width: "100%", height: scale(240) }}
                        source={{
                          uri:
                            fn.media.smallerSizeFromURL(`${fn.media
                              .extractMediaType(item?.uri)
                              ?.includes("video") ||
                              item?.type?.includes("video")
                              ? getThumbnailFromURL(item?.uri)
                              : item?.uri}`, Dimensions.get("window").width * 3),
                        }}
                        backgroundBlurRadius={20}
                        expandable={false}
                      />
                      <Buttoon
                        style={{
                          position: "absolute",
                          top: spacing(3),
                          right: spacing(3),
                        }}
                        size={"small"}
                        status={"basic"}
                        compact
                        onPress={() => {
                          // alert(item?._pid);
                          Navigation.dialogPortal.open({
                            headerTitle: tr("Spotlight post"),
                            render: <CardPosst posst={posstsD[item.index]} posstId={item._pid} />,
                          });
                        }}
                      >
                        {tr("Read more")}
                      </Buttoon>
                    </Pressable>
                  ))}
                </Swiper>
              )}
            </View>
          </A.ViewSpotlight>
        </View>
      )
    );
  } catch (error) {
    throw Error(`::U3BvdGxpZ2h0Um9sbA==::${error}`);
  }
}

/**<Buttoon
            progress
            onPress={(xong) => {
              setIsShown(false);
              setTimeout(() => {
                setIsShown(true);
                xong && xong();
              }, 1500);
            }}
            size="tiny"
            appearance="ghost"
            icon={{ name: "refresh" }}
          >
          Refresh spotlight
          </Buttoon>*/

const A = {
  ViewSpotlight: sstyled(View)({
    width: "100%",
    height: scale(240),
    backgroundColor: "surface",
    borderRadius: scale(4),
    overflow: "hidden",
  }),
};

interface dSpotlightItem {
  uri: string;
  type: "image" | "video" | "vimeo" | "youtube";
  _pid: string;
  index: number;
}
