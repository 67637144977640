import { primrDark } from "./primr-dark-v1";
import { primrLight } from "./primr-light-v1";

export const themeLight = {
  /**
   * The colors is available to use, but prefer using the name.
   */
  ...primrLight,
  grey900: primrLight["color-basic-900"],
  grey600: primrLight["color-basic-600"],
  grey500: primrLight["color-basic-500"],
  pitchWhite: primrLight["color-basic-100"],
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The main tinting colors.
   */
  primary: primrLight["color-primary-500"],
  /**
   * The main tinting color, but darker.
   */
  primaryDarker: primrLight["color-primary-700"],
  /**
   * The sceondary tinting colors.
   */
  accent: primrLight["color-info-500"],
  /**
   * The secondary tinting colors, but darker.
   */
  accentDarker: primrLight["color-info-700"],
  /**
   * The screen background.
   */
  background: primrLight["color-basic-200"],
  /**
   * The `dark` screen background.
   */
  background01: primrLight["color-basic-1000"],
  /**
   * The screen surface. Usually used for modal screen
   */
  surface: primrLight["color-basic-400"],
  /**
   * The `dark` screen surface.
   */
  surface01: primrLight["color-basic-800"],
  /**
   * A subtle color used for borders and lines.
   */
  line: primrLight["color-basic-400"],
  /**
   * The default color of text in many components.
   */
  text: primrLight["color-basic-1100"],
  /**
   * The '01' color of text in many dark-background components.
   */
  text01: primrLight["color-basic-300"],
  /**
   * Secondary information.
   */
  dim: primrLight["color-basic-400"],
  /**
   * Error messages and icons.
   */
  errorRed: primrLight["color-danger-500"],
  /**
   * Warning color
   */
  hazardYellow: primrLight["color-warning-500"],
  /**
   * Info color
   */
  infoBlue: primrLight["color-info-500"],
  /**
   * Success color
   */
  awakenVolt: primrLight["color-success-400"],
  /** Gold color reserved for admin section */
  adminGold: "gold",
  /** Pink color reserved for dev section */
  devPink: "deeppink",
  /**
   * Storybook background for Text stories, or any stories where
   * the text color is colors.text, which is white by default, and does not show
   * in Stories against the default white background
   */
  storybookDarkBg: primrLight["color-basic-1000"],

  /**
   * Storybook text color for stories that display Text components against the
   * white background
   */
  storybookTextColor: primrLight["color-basic-1000"],
};

export const themeDark = {
  /**
   * The colors is available to use, but prefer using the name.
   */
  ...primrDark,
  grey900: primrDark["color-basic-900"],
  grey600: primrDark["color-basic-600"],
  grey500: primrDark["color-basic-500"],
  pitchWhite: primrDark["color-basic-100"],
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The main tinting colors.
   */
  primary: primrDark["color-primary-500"],
  /**
   * The main tinting color, but darker.
   */
  primaryDarker: primrDark["color-primary-700"],
  /**
   * The sceondary tinting colors.
   */
  accent: primrDark["color-info-500"],
  /**
   * The secondary tinting colors, but darker.
   */
  accentDarker: primrDark["color-info-700"],
  /**
   * The screen background.
   */
  background: primrDark["color-basic-1100"],
  /**
   * The `dark` screen background.
   */
  background01: primrDark["color-basic-1100"],
  /**
   * The screen surface. Usually used for modal screen
   */
  surface: primrDark["color-basic-800"],
  /**
   * The `dark` screen surface.
   */
  surface01: primrDark["color-basic-900"],
  /**
   * A subtle color used for borders and lines.
   */
  line: primrDark["color-basic-800"],
  /**
   * The default color of text in many components.
   */
  text: primrDark["color-basic-100"],
  /**
   * The '01' color of text in many dark-background components.
   */
  text01: primrDark["color-basic-100"],
  /**
   * Secondary information.
   */
  dim: primrDark["color-basic-700"],
  /**
   * Error messages and icons.
   */
  errorRed: primrDark["color-danger-500"],
  /**
   * Warning color
   */
  hazardYellow: primrDark["color-warning-500"],
  /**
   * Info color
   */
  infoBlue: primrDark["color-info-500"],
  /**
   * Success color
   */
  awakenVolt: primrDark["color-success-400"],
  /** Gold color reserved for admin section */
  adminGold: "gold",
  /** Pink color reserved for dev section */
  devPink: "deeppink",
  /**
   * Storybook background for Text stories, or any stories where
   * the text color is colors.text, which is white by default, and does not show
   * in Stories against the default white background
   */
  storybookDarkBg: primrDark["color-basic-1000"],

  /**
   * Storybook text color for stories that display Text components against the
   * white background
   */
  storybookTextColor: primrDark["color-basic-1000"],
};
/**
 * Roles for colors. Prefer using these over the colors.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner colors.  It makes more sense to
 * put that in the <Spinner /> component.
 */
export const colors = themeLight;
