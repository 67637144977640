import * as EXCalendar from "expo-calendar";
import { ListHeader, Skeleton, sstyled, Txt } from "components";
import { StatsCreatorCenter } from "components/organisms";
import {
  ConfigVariablesSchema,
  dCalendarEvent,
  dTask,
  fn,
  FPATH,
  useActivityCollection,
  useAppContext,
  useCalendar,
  useConfigDocument,
  UserSchema,
  useUserProfile,
  useUserTasks
} from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import firebase from "firebase";
import React from "react";
import {
  ImageBackground,
  Pressable,
  PressableProps,
  TextStyle,
  View,
  ViewStyle
} from "react-native";
import Typewriter from "screens/personal-activity/PersonalDashboardScreen";
import { useNavigator } from "screens/_navigation";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { IS_WEB, scale, spacing, THEME, tr } from "utils";
import Toasty from "../../components/atoms/generals/toasty/Toasty";
import { useFollowUpContacts } from "../../engines/firebase/handler/contact-handler";

export function TodaysAgendaSection(p: {
  todaysEvents?: dCalendarEvent[];
  followUps?: ContactSchemaa[];
  userTasks?: dTask[];
}) {
  const { todaysEvents, followUps, userTasks } = p;

  const Navigation = useNavigator();
  const { frbsUser, teamId, theme } = useAppContext();

  const userHandler = useUserProfile(frbsUser?.uid);
  // const configHandler = useConfigDocument(`variables-${teamId}`)

  const calendarHandler = useCalendar();
  const [_todaysEvents, setTodaysEvents] =
    React.useState<dCalendarEvent[]>(null);

  const refDidShowPermFail = React.useRef<boolean>(false);
  React.useEffect(
    function setupTodaysEvents() {
      if (todaysEvents) {
        setTodaysEvents(todaysEvents);
        return;
      }
      if (!userHandler.data) return;

      EXCalendar.getCalendarPermissionsAsync().then(perm => {
        if (!perm.granted) {
          if (!refDidShowPermFail.current && !IS_WEB) {
            Toasty.show("Your calendar permissions are turned off.", { type: "danger" })
          }
          refDidShowPermFail.current = true
          return;
        }

        // permission is enabled
        if (userHandler.data?.personalCalendars?.length > 0) {
          console.log("Getting personal cal events...")
          calendarHandler
            .getTodaysPersonalCalendarEvents(userHandler.data?.personalCalendars)
            .then((personalEventsToday) => {
              setTodaysEvents(
                calendarHandler.combineAndSortEvents(
                  personalEventsToday,
                  calendarHandler.getTodaysEvents()
                )
              );
            })
            .catch((err) => {
              setTodaysEvents(calendarHandler.getTodaysEvents());
              Toasty.show(err, { type: "danger" });
            });
        } else {
          setTodaysEvents(calendarHandler.getTodaysEvents());
        }
      }).catch(err => {
        console.error(err)
        setTodaysEvents(calendarHandler.getTodaysEvents());
      })
    },
    [calendarHandler.data, userHandler.data]
  );

  // const contactsHandler = useContactsCollection(frbsUser?.uid);
  const followUpContactsHandler = useFollowUpContacts(frbsUser?.uid);
  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  const _followUps = followUpContactsHandler.data?.filter(
    (c) => c.followUpDate?.toDate && c.followUpDate?.toDate() <= endOfToday
  );

  const [needsReportDay, setNeedsReportDay] = React.useState<boolean>(false);
  const [didReportToday, setDidReportToday] = React.useState<boolean>(false);

  const tasksHandler = useUserTasks(frbsUser?.uid);

  const _userTasks = userTasks || tasksHandler.data;

  const today = new Date();
  const lastStartedDayPress =
    userHandler.data && userHandler.data?.lastGetStartedDayPress?.toDate
      ? userHandler.data?.lastGetStartedDayPress?.toDate()
      : null;
  const didPressGetStarted =
    lastStartedDayPress &&
    lastStartedDayPress.getDate() === today.getDate() &&
    lastStartedDayPress.getMonth() === today.getMonth() &&
    lastStartedDayPress.getFullYear() === today.getFullYear();

  const _isNewbie = isNewbie(userHandler.data);
  const hasIncompleteTraining =
    !userHandler?.data?.completedLevelParentPages?.includes(
      userHandler?.data.lastParentLevelPage
    );

  let msg = didReportToday
    ? "Your day is complete!  Great job!  🎉"
    : fn.you.getDailyBriefingText(
      _todaysEvents,
      followUps || _followUps || [],
      _userTasks
    ) +
    (needsReportDay
      ? "\n\nDon't forget to submit your daily progress report!"
      : hasIncompleteTraining
        ? "\n\nYou also need to complete your training."
        : "");

  async function openDefaultTrainingSection() {
    const configVariables = (
      await firebase
        .firestore()
        .doc(`${FPATH.CONFIGS}/variables-${teamId}`)
        .get()
    ).data() as ConfigVariablesSchema;
    Navigation.navigate<dPageContentsScreenParam>("Page Contents", {
      cpid: configVariables?.startHerePage,
    });
  }

  return (
    <A.BlurredBackground style={{ marginBottom: spacing(2), backgroundColor: theme === THEME.DARK ? "#232B43" : "#E5E9F1" }} imageStyle={{ opacity: 0.65 }}
      // source={{
      //   uri: `https://get-my.app/t/${configHandler.data?.appTitle
      //     ?.toLowerCase()
      //     .replace(/ /g, "")}/logo.png`,
      // }}
      blurRadius={17}>
      <ListHeader
        titleTx={tr("Today's Briefing")}
        actions={[
          {
            label: "View My Day",
            icon: { name: "arrow_right", right: true },
            onPress: () => {
              Navigation.navigate("UserDashboard");
            },
          },
        ]}
      />
      {!_isNewbie && userHandler.data ? (
        <Typewriter
          text={msg}
          delay={25}
          style={{
            paddingHorizontal: spacing(4),
            fontSize: 13.9,
            marginBottom: spacing(3),
            ...(theme === THEME.DARK ? { textShadowColor: "black", textShadowRadius: 1.5, shadowOpacity: 0.4 } : {})
          }}

        />
      ) : null}
      {!didPressGetStarted ? (
        <ShinyButton
          bigButton={_isNewbie}
          title={
            _isNewbie
              ? "Start Your Training"
              : hasIncompleteTraining
                ? "Get Started"
                : "Start My Day"
          }
          onPress={async () => {
            if (!_isNewbie) {
              userHandler.update({
                //@ts-ignore
                lastGetStartedDayPress: new Date(),
              });
              Navigation.navigate("UserDashboard");
            } else {
              openDefaultTrainingSection();
            }
          }}
        />
      ) : (
        // : hasIncompleteTraining ? (
        //   <ShinyButton
        //     title="Resume Training"
        //     onPress={() => {
        //       Navigation.navigate<dPageContentsScreenParam>("Page Contents", {
        //         cpid: userHandler.data.lastParentLevelPage,
        //       });
        //     }}
        //   />
        // )
        <ReportMyDayButton
          onNeedsToReportDay={(needsReport) => {
            setNeedsReportDay(needsReport);
          }}
          onDidReportToday={setDidReportToday}
        />
      )}
      <View style={{ width: "100%", height: spacing(3) }} />
    </A.BlurredBackground>
  );
}

function ReportMyDayButton(p: {
  onNeedsToReportDay?: (needsReport: boolean) => void;
  onDidReportToday?: (didReportToday: boolean) => void;
}) {
  const { onNeedsToReportDay, onDidReportToday } = p;
  const Navigation = useNavigator();
  const { frbsUser, teamId, theme } = useAppContext();

  const configHandler = useConfigDocument(`variables-${teamId}`);
  const configVariables: ConfigVariablesSchema = configHandler.data;
  const activityHandler = useActivityCollection(frbsUser?.uid);

  const [needsReportDay, setNeedsReportDay] = React.useState<boolean>(true);

  React.useEffect(
    function loadActivity() {
      if (!activityHandler.data) {
        setNeedsReportDay(true);
        return;
      }
      const _todaysActs = activityHandler.getTodaysActivities(
        activityHandler.data
      );
      if (_todaysActs?.length > 0 && onDidReportToday) onDidReportToday(true);
      else if (onDidReportToday) onDidReportToday(false);
      setNeedsReportDay(_todaysActs.length < 1);
    },
    [activityHandler.data]
  );

  React.useEffect(
    function fireNeedsReportDay() {
      onNeedsToReportDay && onNeedsToReportDay(needsReportDay);
    },
    [needsReportDay]
  );

  return needsReportDay &&
    configVariables?.activities &&
    configVariables?.activities?.length > 0 ? (
    <ShinyButton
      title="Report My Day"
      layoutStyle={{ width: 153 }}
      onPress={() => {
        Navigation.overlayPortal.open({
          headerTitle: "Report Today",
          render: <StatsCreatorCenter showStatsScreenAfterSubmission={true} />,
        });
      }}
    />
  ) : (
    <ShinyButton
      title="View My Day"
      onPress={() => {
        Navigation.navigate("UserDashboard");
      }}
    />
  );
}

const ShinyButton = (
  p: PressableProps & {
    title: string;
    bigButton?: boolean;
    layoutStyle?: ViewStyle;
    textStyle?: TextStyle;
  }
) => {
  const { title, bigButton = false, layoutStyle = {}, textStyle = {} } = p;
  const { C } = useAppContext();
  return (
    <NSW.PressableContainer {...p}>
      <Skeleton
        isLoading
        duration={2400}
        // easing={Easing.circle}
        layout={[
          {
            key: "medal-wrapper",
            width: bigButton ? "85%" : 145,
            height: bigButton ? 60 : 32,
            borderRadius: bigButton ? 7 : 20,
            ...layoutStyle,
          },
        ]}
        highlightColor={C.primary}
        boneColor={C.infoBlue}
      />
      <NSW.ViewLabel>
        <Txt.S1
          style={{
            fontSize: bigButton ? 18 : 15,
            ...(bigButton ? { fontWeight: "800" } : {}),
            ...textStyle,
          }}
        >
          {tr(title)}
        </Txt.S1>
      </NSW.ViewLabel>
    </NSW.PressableContainer>
  );
};

function isNewbie(user?: UserSchema): boolean {
  if (!user) return true;
  const createdAtDate = (user?.createdAt?.toDate ? user?.createdAt?.toDate() : new Date())
  if (!createdAtDate) return false;
  const today = new Date();
  return (
    createdAtDate.getDate() === today.getDate() &&
    createdAtDate.getMonth() === today.getMonth() &&
    createdAtDate.getFullYear() === today.getFullYear()
  );
}

const NSW = {
  PressableContainer: sstyled(Pressable)({
    borderRadius: 16,
    margin: spacing(3),
    overflow: "hidden",
    backgroundColor: "transparent",
  }),
  ViewLabel: sstyled(View)((p) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  })),
};

const A = {
  BlurredBackground: sstyled(ImageBackground)(({ hovered }) => ({
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
}