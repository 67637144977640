import { useCollectionGroup } from "@nandorojo/swr-firestore";
import { Toasty } from "components";
import { dateToStr, FPATH } from "engines";
import firebase from "firebase";
import { dAttendance } from "../../backends/schemas/users/user.schema";
import { useCollectionPresetv2 } from "../config";

/**
 * Mark a user's attendance for a particular meeting
 *
 * @author jm_francis
 *
 * @example
 * await addAttendance(frbsUser?.uid, {
 *
 * })
 */
export function addAttendance(uid: string, attendance: dAttendance) {
  const collectionPath = `${FPATH.USERS}/${uid}/${FPATH.USER_ATTENDANCE}`;
  const startTimeDate: Date = attendance?.meetingStartTime?.toDate
    ? attendance?.meetingStartTime?.toDate()
    : //@ts-ignore
    (attendance?.meetingStartTime as Date);
  return firebase
    .firestore()
    .collection(collectionPath)
    .doc(`${dateToStr(startTimeDate)}-${attendance.meetingId}`)
    .set(attendance);
}

export function updateAttendnace(uid: string, attendance: dAttendance) {
  // alert(JSON.stringify(attendance))
  const collectionPath = `${FPATH.USERS}/${uid}/${FPATH.USER_ATTENDANCE}`;
  const startTimeDate: Date = attendance?.meetingStartTime?.toDate
    ? attendance.meetingStartTime.toDate()
    : //@ts-ignore
    (attendance?.meetingStartTime as Date);
  return firebase
    .firestore()
    .collection(collectionPath)
    .doc(`${dateToStr(startTimeDate)}-${attendance.meetingId}`)
    .set(attendance, { merge: true });
}

/**
 * Used to track attendance at a meeting for a specific user
 */
export function useUserAttendance(userId: string, query?) {
  const collectionPath = `${FPATH.USERS}/${userId}/${FPATH.USER_ATTENDANCE}`;
  const defaultCollectionFunctions = useCollectionPresetv2<dAttendance>(
    collectionPath,
    {
      listen: true,
      ...query,
    }
  );

  return {
    ...defaultCollectionFunctions,
    addAttendance,
    updateAttendnace
  };
}

/**
 * Get the overall attendance of a specific meeting
 */
export function useAttendanceForMeeting(
  _teamId: string,
  meetingId: string,
  query?
) {
  const defaultCollectionFunctions = useCollectionGroup<dAttendance>(
    FPATH.USER_ATTENDANCE,
    {
      where: [["meetingId", "==", meetingId]],
      orderBy: ["attendedAt", "asc"],
    },
    { onError: (err) => console.error(err) }
  );

  return { ...defaultCollectionFunctions };
}
