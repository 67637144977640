import { IconPrimr } from "assets";
import Color from "color";
import {
  Avt,
  Buttoon,
  handleCopyContent,
  Highlighter,
  Inpuut,
  Kitten,
  Menoo,
  Poppy,
  sstyled,
  Toasty,
  Txt
} from "components";
import {
  fn,
  FRBS_ROLE,
  MediaPageSchema,
  URL_PREFIX,
  useAppContext,
  useConfigDocument,
  __MediaResourcesData
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { usePageContentsCollection } from "engines/firebase/handler/page-contents-handler";
import { usePageDocument } from "engines/firebase/handler/pages-handler";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import * as ImagePicker from "expo-image-picker";
import React from "react";
import { Image, Platform, useWindowDimensions, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { S_PageItemAddPassword } from "utils/helpers/PageItemAddPassword";
import { useCollabContext } from "../../../../engines/providers/collaboration-provider";
import { SearchContent } from "./SearchContent";
import { SelectUser } from "./SelectUser";
/**
 * ### List Item for Custom Page
 *  - Including some member's action
 *  ----
 *  @version 21.03.20
 *  -  *Move this component to ./molecules*
 *  @author  K
 *
 **/
export function PagesHomeItem(props: dMediaHomeItem) {
  const {
    index,
    cpid,
    onPress,
    addEntryClick,
    editModeEnabled,
    onEditCallback,
    query,
    pageTitle,
    collabMode,
    pagesHandler,
  } = props;
  const { C, teamId } = useAppContext();
  const {
    isCollabModeOn,
    setIsCollabModeOn,
    setSelectAll,
    setIsSelectModeOn,
    setPageContentItems,
    clearCollabContext,
  } = useCollabContext();
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const Navigation = useNavigator();
  const [searchResult, setSearchResult] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");

  //#region [FRBS] handler
  const { data: userD, ...userProfile } = useUserProfile();
  const configHandler = useConfigDocument(`variables-${teamId}`)
  const { data: pageD, ...pageHandler } = usePageDocument(cpid);

  const pageContentsHandler = usePageContentsCollection(cpid);
  /**
   * NOTE: remove this
   */
  // const pagesHandler = usePagesCollection();
  //#endregion

  // #region [ANCHOR] Avoid eidting of pages of which are not admin
  const [editAllowed, setEditAllowed] = React.useState(false);
  React.useEffect(() => {
    if (pageD && userD) {
      if (
        teamId === pageD?._teamId &&
        userProfile._isRolesContain([FRBS_ROLE.DEVELOPER, FRBS_ROLE.ADMIN]) &&
        !isCollabModeOn
      ) {
        setEditAllowed(true);
      } else {
        setEditAllowed(false);
      }
    }
  }, [pageD, userD]);
  // #endregion

  React.useEffect(
    function turnOffCollabModeIfNeeded() {
      if (teamId === pageD?._teamId) setIsCollabModeOn(false);
    },
    [pageD]
  );

  //#region [section] Handle collaboration mode
  React.useEffect(() => {
    // console.log(
    //   "In MediaHomeItem collab mode is ",
    //   collabMode,
    //   " and context wise collab Mode is ",
    //   isCollabModeOn
    // );
    setIsCollabModeOn(collabMode ? collabMode : false);
  }, [collabMode]);

  //#endregion

  React.useEffect(() => {
    query && query.length >= 3 ? setSearchQuery(query) : setSearchQuery("");
    const tempRes: string[] = [];
    pageContentsHandler.data?.forEach((pc) => {
      const itemType = pc.checkbox
        ? "checkbox"
        : pc.url.includes("http")
          ? "link"
          : pc.url.includes(URL_PREFIX.PAGE)
            ? "page"
            : pc.media
              ? "image"
              : pc.paragraph
                ? "text"
                : "title";

      pc.paragraph && tempRes.push("text^" + pc.paragraph);
      pc.title && tempRes.push(`${itemType}^` + pc.title);
    });

    tempRes.push("pageTitle^" + pageTitle);
    // console.log("searchQuery = ", searchQuery);
    if (query && query.length >= 3) {
      searchQuery && tempRes.length > 0
        ? setSearchResult(
          tempRes.filter((d) =>
            String(d)
              .toLowerCase()
              .includes(String(searchQuery)?.toLowerCase())
          )
        )
        : setSearchResult([]);
    } else {
      setSearchResult([]);
    }
  }, [searchQuery, query]);

  const [itemIndexSelected, setItemIndexSelected] =
    React.useState<number>(null);
  React.useEffect(() => {
    editModeEnabled == false && setItemIndexSelected(null);
  }, [editModeEnabled]);

  // React.useEffect(() => {
  //   editModeEnabled == false && setItemIndexSelected(null);
  // }, [editModeEnabled]);

  // React.useEffect(
  //   function handleMedia() {
  //     doc.data &&
  //       doc.update({
  //         mediaItem: {
  //           ...doc.data?.mediaItem,
  //           color: randomColor({
  //             luminosity: "bright",
  //             format: "hex", // e.g. 'rgb(225,200,20)'
  //           }),
  //         },
  //       });
  //   },
  //   [doc.data]
  // );
  //#region [functions] more functions
  const [title, setTitle] = React.useState<string>(pageD?.name);
  const [titleEdit, setTitleEdit] = React.useState<boolean>(false);
  function onRemove() {
    Poppy(
      "Final Check",
      "Are you sure you want to delete this resource?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => pageHandler.deleteDocument(),
      }
    );
  }

  function unlockForUser() {
    Navigation.dialogPortal.open({
      headerTitle: "Select a User",
      render: <SelectUser page={pageD} />,
    });
  }

  function onMoveFrom() {
    setItemIndexSelected(index);
    // console.log("index ", index);

    addEntryClick(pageD);
    const values = {
      name: pageD?.name,
      position: pageD?.position,
      _id: pageD?.id,
    };
    // console.log("from ", values);
    onEditCallback && onEditCallback();
  }
  function onMoveTo() {
    addEntryClick(pageD);

    // const values = {
    //   name: pageD?.name,
    //   position: pageD?.position,
    //   _id: pageD?.id,
    // };

    // console.log("to ", values);
    onEditCallback && onEditCallback();
  }
  // console.log("editModeEnabled ", editModeEnabled);
  //#endregion

  //#region [functions] MEDIA-PICKER
  const [avatar, setAvatar] = React.useState("");
  const [mediaProgress, setMediaProgress] = React.useState<number>(0);
  async function cameraPermission() {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        Toasty.show(
          tr("Sorry, we need camera roll permissions to make this work!"),
          { type: "danger", duration: 10000 }
        );
      }
    }
  }

  async function pickImage() {
    await cameraPermission();
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    // console.log(result); //* e.g. "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="
    if (!result.cancelled) {
      // let _mediaType = fn.media.extractMediaType(result.uri);
      const _mediaType = fn.media.extractMediaType(result.uri);

      if (_mediaType !== "image" && _mediaType !== "video") {
        Toasty.show("Unknown file type.", { type: "danger" });
        return null;
      } else {
        Poppy(
          "Final Check",
          "Are you sure you want to change this thumbnail?",
          {
            text: "Cancel",
            onPress: () => { },
          },
          {
            text: "Yes",
            onPress: () => uploadMedia(result?.uri, _mediaType),
          }
        );

        // setSaveVisibility(true);
      }
    }
  }

  function uploadMedia(url: string, type: string) {
    setAvatar(url);
    if (!type) type = fn.media.extractMediaType(url);

    fn.media
      .uploadMedia(
        url,
        {
          // folder: "baseshop/" + pageD?.name,
          folder: `${CLOUDINARY_FOLDER.CLIENTS}/${pageD?._teamId}`,
          title: `pid-${pageD?._id}`,
          type,
        },
        (progress) => {
          setMediaProgress(progress);
        },
        (data) => { }
      )
      .then((response) => {
        pageHandler.update({
          mediaItem: {
            ...pageD?.mediaItem,
            logo: response.url,
          },
        });

        // console.log("item has been updated");
        // setSaveVisibility(false);
      })
      .catch((error) => {
        console.warn("Error updating avt: ", error);
        setAvatar(null);
        // return null;
      });
  }

  function removeLogo() {
    Poppy(
      "Final Check",
      "Are you sure you want to remove this photo?",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () =>
          pageHandler.update({
            mediaItem: {
              ...pageD?.mediaItem,
              logo: "",
            },
          }),
      }
    );
  }
  //#endregion

  const [_borderWidth, setBorderWidth] = React.useState(0);

  return React.useMemo(() => {
    try {
      if (pageHandler.error || pageContentsHandler.error) throw Error();
      if (!pageD || !pageContentsHandler.data) return null;

      return (
        <View>
          {/**
            //* Debug text to check NaN value for position
            <Txt.C2 style={{ textAlign: "left" }} numberOfLines={2}>
              {(pageD?.position) || "Nah"}
            </Txt.C2>
           */}
          {
            /** * Show this when:
             * -  editMode is enabled,
             * -  the item is not the selected one,
             * //- and not the one next to the selected one cannot implement it for some reason
             */
            editModeEnabled && index != itemIndexSelected && (
              <A.PressableMoveToPlaceholder
                status="basic"
                size="large"
                appearance="outline"
                onPress={onMoveTo}
              >
                {tr("Move item here")}
              </A.PressableMoveToPlaceholder>
            )
          }
          {query && query.length >= 3 ? (
            searchResult.length > 0 && (
              <Kitten.Card onPress={onPress}>
                <Kitten.ListItemv2
                  disabled
                  title={() => (
                    <Txt.H5>
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: C["color-warning-transparent-500"],
                        }}
                        searchWords={[query]}
                        autoEscape={true}
                        textToHighlight={pageD?.name}
                      />
                    </Txt.H5>
                  )}
                  accessoryLeft={() => (
                    /**
                     * This was implemented this way because of the issue
                     * we had when ever we change a photo, the component
                     * doesn't show us the photo except we refresh the screen.
                     * we might have to look at the AVT component to see other ways we can solve that later
                     */
                    <>
                      {pageD?.mediaItem?.logo && pageD?.mediaItem?.logo ? (
                        <Image
                          source={{
                            uri: fn.media.smallerSizeFromURL(pageD?.mediaItem?.logo, scale(32) * 3),
                          }}
                          style={{
                            width: scale(32),
                            height: scale(32),
                            borderRadius: 2,
                          }}
                        />
                      ) : (
                        <Avt
                          name={pageD?.name || ""}
                          source={{
                            uri: `https://get-my.app/t/${configHandler.data?.appTitle
                              ?.toLowerCase()
                              .replace(/ /g, "")}/logo.png`,
                          }}
                          size="small"
                          shape="square"
                        />
                      )}
                    </>
                  )}
                />
                {query && !searchResult.includes("pageTitle^") ? (
                  <SearchContent data={searchResult} searchPart={searchQuery} />
                ) : null}
              </Kitten.Card>
            )
          ) : (
            <Kitten.ListItemv2
              onPress={titleEdit ? () => { } : onPress}
              // style={_isResourceNew && { backgroundColor: C["color-danger-400"] }}
              style={{ borderRadius: scale(4) }}
              description={() =>
                query ? (
                  <SearchContent data={searchResult} searchPart={searchQuery} />
                ) : null
              }
              title={() => {
                return (
                  <>
                    {titleEdit ? (
                      //* Editable title
                      <Inpuut
                        value={title}
                        onChangeText={setTitle}
                        numberOfLines={2}
                        onSavePress={() => {
                          setTitleEdit(titleEdit == true ? false : true);
                          pageHandler.update({ name: title });
                        }}
                      />
                    ) : (
                      <>
                        <Txt.H5 style={{ textAlign: "left" }} numberOfLines={2}>
                          {!isCollabModeOn
                            ? pageD?.name
                            : pageD?.name.concat(
                              pageD?.mediaItem?.team === true &&
                                pageD?.mediaItem?.visible === true
                                ? " (BASE SHOP)"
                                : ""
                            ) || "       "}
                        </Txt.H5>
                        {pageD?.password &&
                          pageD?.password &&
                          !isCollabModeOn ? (
                          <IconPrimr
                            preset="circular"
                            name="lock"
                            color={C["color-warning-500"]}
                            containerStyle={{
                              backgroundColor:
                                C["color-warning-transparent-500"],
                            }}
                          />
                        ) : null}
                      </>
                    )}
                  </>
                );
              }}
              accessoryLeft={() => (
                /**
                 * This was implemented this way because of the issue
                 * we had when ever we change a photo, the component
                 * doesn't show us the photo except we refresh the screen.
                 * we might have to look at the AVT component to see other ways we can solve that later
                 */
                <>
                  {pageD?.mediaItem?.logo && pageD?.mediaItem?.logo ? (
                    <Image
                      source={{
                        uri: pageD?.mediaItem?.logo || "",
                      }}
                      style={{
                        width: 57,
                        height: 57,
                        borderRadius: 2,
                      }}
                    />
                  ) : (
                    <Avt
                      name={pageD?.name || ""}
                      source={{
                        uri: `https://get-my.app/t/${configHandler.data?.appTitle
                          ?.toLowerCase()
                          .replace(/ /g, "")}/logo.png`,
                      }}
                      size="giant"
                      shape="square"
                    />
                  )}
                  {/* <Avt
                    name={pageD?.name || ""}
                    source={{ uri: pageD?.mediaItem?.logo || "" }}
                    size="giant"
                    shape="square"
                  /> */}
                </>
              )}
              accessoryRight={() =>
                editAllowed ? (
                  <>
                    {titleEdit ? (
                      <Buttoon
                        status="basic"
                        appearance="ghost"
                        size="tiny"
                        onPress={() => {
                          setTitleEdit(false);
                          setTitle(pageD?.name);
                        }}
                      >
                        {tr("Cancel")}
                      </Buttoon>
                    ) : (
                      <Menoo
                        trigger={{ name: "dots_vertical", size: scale(16) }}
                        options={[
                          pageD?.password && pageD?.password
                            ? {
                              text: tr("Remove password"),
                              onSelect: () => {
                                pageHandler.update({ password: "" });
                                Toasty.show(
                                  tr("Password removed successfully"),
                                  {
                                    type: "success",
                                  }
                                );
                              },
                            }
                            : {
                              text: tr("Add Password"),
                              onSelect: () => {
                                Navigation.dialogPortal.open({
                                  headerTitle: "Password this team",
                                  render: (
                                    <View style={{ flex: 1, flexGrow: 1 }}>
                                      <S_PageItemAddPassword
                                        pageHandler={pageHandler}
                                      />
                                    </View>
                                  ),
                                });
                              },
                            },
                          {
                            text: tr("Unlock for user"),
                            onSelect: unlockForUser,
                          },
                          {
                            text: Menoo.Divider,
                          },
                          {
                            text: tr("Move Page"),
                            onSelect: onMoveFrom,
                          },
                          {
                            text: pageD?.collaboration
                              ? tr("Unshare from Multiverse")
                              : tr("Share in Multiverse"),
                            onSelect: () => {
                              Poppy(
                                "Collaborating...",
                                `Are you sure you want to "${pageD?.collaboration ? "Unshare" : "Share"
                                }" this page with the Multiverse?`,
                                {
                                  text: "Cancel",
                                  onPress: () => { },
                                },
                                {
                                  text: "Yes",
                                  onPress: async () => {
                                    await pageHandler.update({
                                      collaboration: pageD?.collaboration
                                        ? false
                                        : true,
                                    });

                                    //NOTE If the page is shared then all the content is public else it's private and won't show up in Content Multiverse search
                                    await pageContentsHandler._updateAllPageContentsData(
                                      {
                                        isContentPrivate: pageD?.collaboration
                                          ? true
                                          : false,
                                      }
                                    );
                                    //copyGoToPageDetails(pageD._id);
                                  },
                                }
                              );
                            },
                          },
                          {
                            text: tr("Edit Title"),
                            onSelect: () => {
                              setTitle(pageD?.name);
                              setTitleEdit(true);
                            },
                          },
                          {
                            text: pageD?.mediaItem?.logo
                              ? tr("Remove Logo")
                              : tr("Edit Logo"),
                            onSelect: pageD?.mediaItem?.logo
                              ? removeLogo
                              : pickImage,
                          },
                          {
                            text: Menoo.Divider,
                          },
                          {
                            text: tr("Delete"),
                            onSelect: onRemove,
                            color: C.errorRed,
                          },
                        ]}
                      />
                    )}
                  </>
                ) : isCollabModeOn &&
                  pageD?.mediaItem?.team !== true &&
                  pageD?.mediaItem?.visible === true &&
                  (userProfile._isUserAdmin() || userProfile._isDeveloper()) &&
                  teamId !== pageD?._teamId ? (
                  <Buttoon
                    onPress={async () => {
                      if (!pagesHandler) {
                        Toasty.show(
                          "Error occurred. (pagesHandler not provided)",
                          { type: "danger" }
                        );
                        return;
                      }
                      Toasty.show(`Creating this page in your app...`, {
                        type: "loading",
                      });
                      const newPageId = await pagesHandler.add({
                        ...__MediaResourcesData({
                          teamId,
                          pageName: pageD.name,
                        }),
                        position: pagesHandler._largestPositionIndex() + 1,
                        mediaItem: pageD.mediaItem,
                      });
                      // Toasty.show(
                      //   `Copying over ${pageContentsHandler.data?.length} items into ${newPageId}`
                      // );
                      handleCopyContent({
                        Navigation,
                        rolesThatCanEdit: userD.roles || [],
                        C,
                        teamId,
                        screenWidth,
                        screenHeight,
                        copyToPageId: newPageId,
                        pageContentItems: pageContentsHandler.data,
                        // pageContentsHandler,
                        navToTitle: pageD.name,
                        onResetCollab: (disableCollab) => {
                          if (disableCollab) {
                            setIsCollabModeOn(false);
                            clearCollabContext();
                          }
                          setIsSelectModeOn(false);
                          setSelectAll(false);
                          setPageContentItems([]);
                        },
                      });
                    }}
                    appearance={"ghost"}
                    icon={{ name: "plus" }}
                  >
                    {tr("Add to my app")}
                  </Buttoon>
                ) : // <Txt>{`${isCollabModeOn} ${editAllowed} ${pageD?.mediaItem?.team} ${pageD?.mediaItem?.visible} `}</Txt>
                  null
              }
            />
          )}
          {
            /**
             * If item is selected,
             * ViewItemPressPrevention appears above the item to prevent from pressing
             */
            editModeEnabled && index == itemIndexSelected && (
              <A.ViewItemPressPrevention
                style={{
                  backgroundColor: Color(C["color-info-700"])
                    .alpha(0.2)
                    .toString(),
                }}
              />
            )
          }
        </View>
      );
    } catch (error) {
      //
      throw Error(`::TWVkaWFIb21lSXRlbQ==::${error}`);
    }
  }, [
    pageD,
    editModeEnabled,
    itemIndexSelected,
    addEntryClick,
    userProfile,
    C,
    searchQuery,
    isCollabModeOn,
    editAllowed,
  ]);
}

const A = {
  DotNeweResource: sstyled(View)({
    width: scale(16),
    height: scale(16),
    borderRadius: 30,
    backgroundColor: "errorRed",
  }),
  PressableMoveToPlaceholder: sstyled(Buttoon)({
    width: "100%",
    height: scale(50),
    borderColor: "color-info-700",
    borderWidth: 2,
    borderStyle: "dotted",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: spacing(3),
  }),
  ViewItemPressPrevention: sstyled(View)({
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
  }),
};

interface dMediaHomeItem {
  index: number;
  cpid: MediaPageSchema["id"];
  onPress(): void;
  addEntryClick(item): void;
  editModeEnabled: boolean;
  onEditCallback(): void;
  query?: string;
  pageTitle?: string;
  collabMode?: boolean;
  /**
   * Provide when using Content Multiverse
   */
  pagesHandler?: any;
  // checkable?: boolean;
  // onCheckChanged?: (status: boolean, pageId: string) => void;
}
