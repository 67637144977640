import { Txt } from "components";
import * as ImagePicker from "expo-image-picker";
import React, { useState } from "react";
import { View } from "react-native";
import { tr } from "utils";
import { AvatarPicker } from "../../components/molecules";

/**
 * A View overlaying home screen
 * requires user to select avatar
 * to be able to use the app
 * @returns
 */
export function ViewAvatarWall() {
  const [image, setImage] = useState(null);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      setImage(result.assets[0].uri);
    }
  };

  return (
    <View style={{ justifyContent: "center", alignItems: "center" }}>
      <Txt.H5>{tr("Profile Picture")}</Txt.H5>
      <Txt.Helper marginV>
        {"Upload a great looking picture of yourself for the world to see!"}
      </Txt.Helper>
      <AvatarPicker />
    </View>
  );
}
