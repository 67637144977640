import { IconPrimr } from "assets";
import { Kitten } from "components/atoms/externals";
import { Buttoon, sstyled, Toasty, Txt } from "components/atoms/generals";
import { dPosstCreatorMedia } from "components/molecules/posst-creator/PosstCreatorMedia";
// import { Buttoon, Kitten, sstyled, Toasty, Txt } from "components/atoms";
import { fn, useAppContext } from "engines";
import { Video } from "expo-av";
let DocumentPicker: any = {};
// import * as DocumentPicker from "expo-document-picker";
if (Platform.OS === "web") {
  DocumentPicker = require("expo-document-picker");
}
import * as ImagePicker from "expo-image-picker";
import React from "react";
import {
  ImageBackground,
  Platform,
  Pressable,
  View,
  ViewStyle,
} from "react-native";
import { dGalleryScrParams, useNavigator } from "screens";
import { IS_WEB, moderateScale, scale, spacing, tr } from "utils";

/**
 * ### BoxMediaPicker<>
 *  - A Pressable to select local media, then send media info thru `onSelect()`
 *  - Display existing media
 *  - Allow to Change media, Reset/Remove media
 *  - Uploading process will be handled outside
 *  ----
 *  @example
* <BoxMediaPicker
    media={{
      uri: memberD?.personali?.photoURL,
      type: "image",
    }}
    onSelect={setMedia}
    key={"personali"}
    showMedia={true}
    type={"Images"}
    containerStyle={{
      borderRadius: 100,
      minHeight: 100,
      width: 100,
    }}
  />
 *  ----
 *  @version 22.05.15
 *  -  *Branch out from PosstCreatorMedia*
 *  @author  K
 *
 **/
export function BoxMediaPicker({
  onSelect,
  type = "All",
  allowedFileTypes,
  disableMobile,
  showMedia = true,
  PlaceholderComponent,
  containerStyle,
  initialMedia,
  pickerOptions,
}: dPosstCreatorMedia) {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  //#region [section]  Media Callback
  const [media, setMedia] =
    React.useState<dPosstCreatorMedia["initialMedia"]>(initialMedia);
  // { uri: "https://reactjs.org/logo-og.png", type: "image" }
  // { uri: "http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4", type: "video" }

  // React.useEffect(
  //   function setupInitialMedia() {
  //     initialMedia && setMedia(initialMedia);
  //   },
  //   [initialMedia]
  // );
  React.useEffect(
    function onSelectCallback() {
      return onSelect && onSelect(media);
    },
    [media]
  );
  //#endregion

  //#region [functions] Media picker
  const pickMedia = async () => {
    Toasty.show("Opening media picker", { type: "loading" });
    if (IS_WEB) {
      DocumentPicker?.getDocumentAsync({
        type: allowedFileTypes ? allowedFileTypes : ["video/*", "audio/*", "image/*"],
        copyToCacheDirectory: false,
      })
        // FYI result has .file, .uri, and .mimeType
        .then(async (result) => {
          //@ts-ignore
          if (!result.cancelled) {
            // if (!result.file) alert("File not found");
            //@ts-ignore
            const mimeType: string = result.assets?.length > 0 ? result.assets[0].mimeType : result?.mimeType;
            // console.log(JSON.stringify(result))
            const uri = (result.file && mimeType?.includes("video")) ? "" : result?.assets?.length > 0 ? result.assets[0].uri : result?.uri
            setMedia({
              // ...result,
              //@ts-ignore
              uri,
              // uri: result.file ? "" : result.uri,
              //@ts-ignore
              file: result.assets?.length > 0 ? result.assets[0].file : result.file,
              mimeType: mimeType?.includes("video")
                ? "video"
                : mimeType?.includes("image")
                  ? "image"
                  : mimeType?.includes("audio")
                    ? "audio"
                    : mimeType,
            });
          }
        })
        .catch((e) => {
          console.log("error pick document: ", e);
          Toasty.show("Error selecting file. " + e, { type: "danger" });
        });
      return;
    }
    if (disableMobile) {
      Toasty.show("File type only supported on web", { type: 'warning' })
      return;
    }
    ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions[type],
      allowsEditing: true,
      quality: 1,
      ...pickerOptions,
    })
      .then(async (result) => {
        // console.log("pickImage 0: ", result);
        if (!result.cancelled) {
          // console.log("pickImage: ", result);
          setMedia({
            type: fn.media.extractMediaType(result.uri) || result.type,
            ...result,
          });
        }
      })
      .catch((e) => {
        console.log("err pickImage: ", e);
        Toasty.show("Error selecting media. " + e, { type: "danger" });
      });
    // console.log(result); //* e.g. "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeEA…qkgiIgAiIgAikO4Hr/x+TgtTh/4t6XwAAAABJRU5ErkJggg=="
  };
  //#endregion

  function WithMediaRender() {
    if (showMedia) {
      // alert(JSON.stringify(media))
      // alert(media.mimeType + " vs " + fn.media.extractMediaType(media?.uri));
      const _mediaType = media?.mimeType
        ? media.mimeType
        : fn.media.extractMediaType(media?.uri) || media.type;
      switch (_mediaType) {
        case "image":
          return (
            <Pressable
              style={{ flex: 1 }}
              onPress={() => {
                Navigation.navigate<dGalleryScrParams>("Gallery", {
                  imageIndex: 0,
                  images: [{ url: media.assets?.length > 0 ? media.assets[0].uri : media?.uri }],
                });
              }}
            >
              {({ hovered }) => (
                <A.ImageThumbnail
                  resizeMode={"cover"}
                  source={{
                    uri: media.uri,
                  }}
                >
                  {(hovered || !IS_WEB) && media && (
                    <IconPrimr
                      name={"expand"}
                      color={C.text01}
                      preset="circular"
                      containerStyle={{
                        backgroundColor: C["color-primary-transparent-500"],
                      }}
                    />
                  )}
                </A.ImageThumbnail>
              )}
            </Pressable>
          );
          break;
        default:
          return (
            // <A.VideoThumbnail
            //   source={{ uri: media?.uri }}
            //   resizeMode="contain"
            //   useNativeControls
            // />
            <View
              style={{
                flex: 1,
                backgroundColor: C.accent,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Txt.H6 style={{ color: C.pitchWhite }}>
                {tr(
                  _mediaType?.includes("video")
                    ? "Video Selected"
                    : _mediaType?.includes("audio")
                      ? "Audio Selected"
                      : "File Selected"
                )}
              </Txt.H6>
              <IconPrimr
                style={{ marginHorizontal: spacing(2) }}
                name="check"
                size={moderateScale(17)}
                color={C.pitchWhite}
              />
            </View>
          );
          break;
      }
    } else {
      return <View />;
    }
  }

  function WithoutMediaRender() {
    return (
      <A.ViewWithoutMedia
        style={[containerStyle && containerStyle]}
        disabled={media && media.uri}
        onPress={pickMedia}
      >
        {PlaceholderComponent || <IconPrimr name="plus" color={C.primary} />}
      </A.ViewWithoutMedia>
    );
  }
  let resetIcon = () => {
    /** If initialMedia is provided, show Undo button when picked media != initial media */
    if (initialMedia?.uri && initialMedia?.uri != media?.uri) {
      return "undo";
    } else if (!initialMedia?.uri && media?.uri) {
      /** If initialMedia is NOT provided, show x button when a media is picked */
      return "x";
    } else {
      return null;
    }
  };
  if (media && (media?.uri || media?.file || media?.assets))
    return (
      <View>
        {/* <Txt>{JSON.stringify(media?.uri)}</Txt> */}
        <A.ViewWithMedia style={[containerStyle && containerStyle]}>
          <WithMediaRender />
        </A.ViewWithMedia>
        <Kitten.ViewH
          style={{
            position: "absolute",
            left: spacing(3),
            bottom: -scale(7),
          }}
        >
          <IconPrimr
            onPress={pickMedia}
            preset="circular"
            name="pen"
            containerStyle={{
              backgroundColor: C.primary,
              alignSelf: "center",
            }}
            color={C.text01}
            size={scale(12)}
          />
          {resetIcon() && (
            <IconPrimr
              onPress={() => {
                setMedia(initialMedia);
              }}
              preset="circular"
              name={resetIcon()}
              containerStyle={{
                backgroundColor: C.errorRed,
                alignSelf: "center",
              }}
              color={C.text01}
              size={scale(12)}
            />
          )}
        </Kitten.ViewH>
      </View>
    );
  return <WithoutMediaRender />;
}

const A = {
  ViewWithMedia: sstyled(View)({
    minHeight: 6,
    flex: 1,
    overflow: "hidden",
    justifyContent: "center",
    borderColor: "primary",
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: "dim",
  }),
  ViewWithoutMedia: sstyled(Kitten.Card)((p) => ({
    minHeight: 6,
    flex: 1,
    borderRadius: 10,
    borderWidth: 3,
    borderStyle: "dotted",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  })),
  ImageThumbnail: sstyled(ImageBackground)({
    flex: 1,

    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "background",
  }),
  VideoThumbnail: sstyled(Video)({
    flex: 1,
    width: "100%",
    height: 8,
    // justifyContent: "center",
    // alignItems: "center",
  }),
  ButtonRemove: sstyled(Buttoon)({
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
};

export interface dBoxMediaPicker {
  onSelect(item: ImagePicker.ImagePickerResult): void;
  type?: "All" | "Images" | "Videos"; //ImagePicker.MediaTypeOptions;
  /** Component to be rendered at the center of picker placeholder */
  PlaceholderComponent?: JSX.Element;
  showMedia?: boolean;
  containerStyle?: ViewStyle;
  initialMedia?:
  | Partial<ImagePicker.ImagePickerResult>
  | Partial<DocumentPicker.DocumentResult>;
  pickerOptions?: ImagePicker.ImagePickerOptions;
}
