import * as Notifications from "expo-notifications";
import { isPermissionAllowed } from "./isPermissionAllowed";
export async function requestNotificationPermissions(): Promise<{
  isNotifcationAllowed?: boolean;
}> {
  const keyword = "notifcation";
  const isNotifcationAllowedResult =
  
    await Notifications.requestPermissionsAsync();
  const isNotifcationAllowed = isPermissionAllowed(
    isNotifcationAllowedResult.granted,
    keyword
  );
  console.log("isNotifcationAllowedResult ", isNotifcationAllowedResult.granted);

  return {
    isNotifcationAllowed,
  };
}
