import { useDocument } from "@nandorojo/swr-firestore";
import {
  dNotification,
  fn,
  FPATH,
  MediaPageSchema,
  useCollectionPresetv2,
  UserSchema
} from "engines";
import { TOPICTYPES } from "engines/functions/notifications-functions";
import firebase from "firebase";
import { Alert } from "react-native";

export function topicIdForTeam(pageId: string): string {
  if (!pageId) return "team_unknown";
  return `team_${pageId
    .replace(/ /g, "")
    .replace(/\'/g, "")
    .replace(/\./g, "")
    .replace(/\&/g, "")
    .replace(/\!/g, "")
    .replace(/\,/g, "")
    .replace(/\:/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/\-/g, "")
    .replace(/\_/g, "")
    .replace(/\</g, "")
    .replace(/\>/g, "")}`;
  // return `/topics/team_${pageId.replace(/ /g, "")}`;
}

export function useNotificationDocument(id: string, teamId: string, query?) {
  const notificationDocument = useDocument<dNotification>(
    `${FPATH.NOTIFICATION}/${teamId}/notis/${id}`,
    {
      listen: true,
      ...query,
    }
  );

  return { ...notificationDocument };
}

export function useNotificationCollection(teamId: string, query?) {
  const notificationCollection = useCollectionPresetv2<dNotification>(
    `${FPATH.NOTIFICATION}/${teamId}/notis`,
    "_id",
    query
  );

  return {
    ...notificationCollection,
    // registerForPushNotificationsAsync,
    sendNotificationToEveryone,
    // sendPushNotification,
    sendNotificationToTeam,
    sendNotificationToUser,
    sendLevelCompletionNotification,
  };
}

/**
 * ### Sends push notification to everyone subscribed to topic "global"
 * - HINT: everyone is subscribed to "global" by default :D
 * - set "disableAddToFirestore" in data param to true to send the notification without adding into firestore/the bell area
 *   (example of this in ExploreHandler.createPosst)
 *
 * @author jm_francis
 * @version 1.2.12
 *
 * @example
 * notificationHandler.sendNotificationToEveryone("BIG DAY", "LESS GO TEAM WE GOT THIS!", {})
 */
export async function sendNotificationToEveryone(
  title: string,
  message: string,
  data?: {
    /** if this is a unleash app inside another app */
    cloudFunctionEndpoint?: string;
    teamId: string;
    disableAddToFirestore?: boolean;
  }
) {
  const { teamId, cloudFunctionEndpoint } = data;
  if (!teamId) {
    Alert.alert("teamId not provided!");
    return;
  }
  let apiVALUE = fn.js.getApiKey(teamId);
  if (!apiVALUE) {
    apiVALUE = fn.js.getApiKey(cloudFunctionEndpoint);
  }
  if (!apiVALUE) {
    Alert.alert("Failed to get api key");
    return;
  }
  // alert(JSON.stringify(apiVALUE));

  // const apiUrl = data?.teamId == "app-takeoff-team" ? "primr-exp" : teamId;

  // if (IS_WEB && data?.teamId) {
  console.log("Cloundfunction.apiKey.keys ", apiVALUE);
  const notificationPayload = {
    title: title,
    message: message,
    data: {
      cloudFunctionEndpoint,
      teamId: teamId,
      disableAddToFirestore: true,
      channel: `${data?.teamId}-global`,
    },
  };
  fetch(
    `https://us-central1-${
      cloudFunctionEndpoint || teamId
    }.cloudfunctions.net/tellEveryone`,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ data: notificationPayload }),
      headers: {
        "content-type": "application/json",
        API_KEY: apiVALUE.apiKey,
      },
    }
  )
    .then((res) => res.json())
    .then((resp) => console.log(`[notification-handler] ` + resp))
    .catch((err) => console.error(err));
}

/**
 * ### Sends a push notification to everyone in the specified team
 *
 * @author jm_francis
 * @version 1.1.30
 *
 * @example
 * notificationHandler.sendNotificationToTeam(team.name, "Hey team!", {})
 */
export async function sendNotificationToTeam(
  teamId: string,
  topicType: TOPICTYPES,
  pageInfo: MediaPageSchema,
  title: string,
  message: string,
  cloudFunctionEndpoint?: string
) {
  const topic = `${teamId}-${topicType}-${pageInfo?._id}`;
  var apiVALUE = fn.js.getApiKey(teamId);
  if (!apiVALUE) {
    apiVALUE = fn.js.getApiKey(cloudFunctionEndpoint);
  }

  console.log(
    "sending notification to team (via teamName and team pageId) " +
      pageInfo.name +
      " with topic ids: " +
      topic
  );

  let teamLogo = pageInfo.mediaItem.logo;
  teamLogo = teamLogo?.length > 1 ? teamLogo : null;
  teamLogo && console.log("using icon for notification: " + teamLogo);

  // if (IS_WEB) {

  // } else {
  // teamLogo =
  // "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1638900068/admin/2021-12-07_13.01.00_hbeork.jpg";
  /**
   * NOTE: Not sure why I ever specified any variables in data: {} when it can just be straight in there but whatever keep it for now so it all works - jmf
   */
  let apiUrl = teamId == "app-takeoff-team" ? "primr-exp" : teamId;
  const notificationPayload = {
    title: `${title} \n ${pageInfo.name}`,
    teamName: pageInfo.name,
    message,
    channel: topic,
    icon: teamLogo,
    data: {
      channel: topic,
    },
  };

  return fetch(`https://us-central1-${apiUrl}.cloudfunctions.net/tellATeam`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ data: notificationPayload }),
    headers: {
      "content-type": "application/json",
      API_KEY: apiVALUE.apiKey,
    },
  })
    .then((res) => res.json())
    .then((resp) => console.log(resp))
    .catch((err) => console.log(err));
}

/**
 * # Send notification to a single user
 *
 * @example
 * await sendNotificationToUser(user, "Hey!", "Guess what!")
 *
 * @author jm_francis
 */
export async function sendNotificationToUser(
  /** Provide the teamId or the cloud function endpoint to use ;) */
  // teamIdOrEndpoint: string | null,
  user: UserSchema,
  title: string,
  message: string,
  icon?: string
) {
  console.log("user?.fcmTokens ", user?.fcmTokens);
  if (!user?.fcmTokens || (user?.fcmTokens as string[])?.length < 1) {
    console.log(
      `User ${user?.personali.displayName} does not have any registered fcm tokens/devices. Cancelling.`
    );
    return;
  }
  // var apiVALUE = fn.js.getApiKey(teamIdOrEndpoint);
  // const notificationPayload = {
  //   title,
  //   message,
  //   icon: icon?.length > 0 ? icon : null,
  //   tokens: user?.fcmTokens,
  // };
  // console.log(notificationPayload.tokens);

  // let apiUrl = teamId == "app-takeoff-team" ? "primr-exp" : teamId;

  // return fetch(`https://us-central1-${teamIdOrEndpoint}.cloudfunctions.net/send`)

  return firebase.functions().httpsCallable("sendNotificationToUser")({
    user,
    title,
    message,
  });

  // return fetch(
  //   `https://us-central1-${teamIdOrEndpoint}.cloudfunctions.net/sendNotificationToTokens`,
  //   {
  //     method: "POST",
  //     mode: "cors",
  //     body: JSON.stringify({ data: notificationPayload }),
  //     headers: {
  //       "content-type": "application/json",
  //       API_KEY: apiVALUE.apiKey,
  //     },
  //   }
  // )
  //   .then((res) => res.json())
  //   .then((resp) => console.log(resp))
  //   .catch((err) => console.log(err));
}

/**
 * # Send notification to field trainers when their trainee completes a level!
 * - function will find the fcm tokens for all the field trainers the trainee has shared their account with and send them each a notification
 *
 * @author jm_francis
 *
 * @example
 * sendLevelCompletionNotification(traineeUser, levelName)
 */
export async function sendLevelCompletionNotification(
  trainee: UserSchema,
  levelName: string,
  fieldTrainers: UserSchema[]
) {
  console.log(
    "sending notification to trainee's trainers for completing " + levelName
  );
  // const fieldTrainers = Backend.adminHandler._users.filter((u) =>
  //   trainee.growth.listBuilder.shareTo.includes(u.uid)
  // );
  let fcmTokens: string[] = [];
  for (let ft in fieldTrainers) {
    console.log(
      "adding tokens for " +
        fieldTrainers[ft]?.personali?.displayName +
        " : " +
        JSON.stringify(fieldTrainers[ft]?.fcmTokens)
    );
    fcmTokens = fcmTokens.concat(fieldTrainers[ft]?.fcmTokens);
  }
  const title = "Your trainee completed a level!";
  const message = `${trainee?.personali?.displayName} just completed ${levelName}!\n\nBe sure to congratulate them!`;
  if (fcmTokens?.length < 0) {
    console.log(
      "No field trainer tokens found, push notification send cancelled."
    );
    return;
  }
  var apiVALUE = fn.js.getApiKey(teamId);
  let apiUrl = teamId == "app-takeoff-team" ? "primr-exp" : teamId;

  const notificationPayload = {
    title,
    message,
    icon: trainee?.personali?.photoURL,
    tokens: fcmTokens,
  };

  return fetch(
    `https://us-central1-${apiUrl}.cloudfunctions.net/sendNotificationToTokens`,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ data: notificationPayload }),
      headers: {
        "content-type": "application/json",
        API_KEY: apiVALUE.apiKey,
      },
    }
  )
    .then((res) => res.json())
    .then((resp) => console.log(resp))
    .catch((err) => console.log(err));
}
