//#region [import]
import { Buttoon, Kitten, Poppy, sstyled, Toasty } from "components/atoms";
import { MediaPageItemSchema, useAppContext } from "engines";
import { dUseDocumentReturns } from "engines/firebase/config";
import React from "react";
import { TextInput, View } from "react-native";
import { scale, spacing, tr } from "utils";

//#endregion

export function TitleItemEditor({
  pageContentHandler,
  onUpdateCallback,
}: {
  pageContentHandler: dUseDocumentReturns<MediaPageItemSchema>;
  onUpdateCallback(): void;
}) {
  const { C } = useAppContext();
  let content = pageContentHandler?.data;
  const refInput = React.useRef<TextInput>();
  const [value, setValue] = React.useState<string>(content?.title);

  React.useEffect(function staging() {
    refInput.current?.focus();
  }, []);

  //#region [functions]
  function onDelete() {
    Poppy(
      "The field is empty",
      "Are you sure you want to delete this item?",
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () => {
          pageContentHandler.deleteDocument();
          Toasty.show(tr("Title deleted"), { type: "success" });
        },
      }
    );
  }

  function onSave() {
    if (value?.length == 0) {
      onDelete();
    } else {
      pageContentHandler.update({ title: value });
      Toasty.show(tr("Title updated"), { type: "success" });
      onUpdateCallback && onUpdateCallback();
    }
  }
  //#endregion

  return (
    <View>
      {/* 
      Nanle removed this based on the instruction for us to make text field multi-line
      <Inpuut
        ref={refInput}
        value={value}
        onChangeText={setValue}
        doneText={tr("Save")}
        onSavePress={onSave}
        isUrl={false}
        textStyle={{ fontSize: scale(20), fontWeight: "bold", color: C.text }}
      /> */}
      <A.InputTitle
        ref={refInput}
        multiline
        value={value}
        onChangeText={setValue}
        selectTextOnFocus
        onSubmitEditing={onSave}
      />
      <Kitten.Divider_________ />
      <Buttoon compact disabled={value === content.title} onPress={onSave}>
        {tr("Save")}
      </Buttoon>
    </View>
  );
}

const A = {
  InputTitle: sstyled(TextInput)({
    minHeight: scale(50),
    color: "text",
    borderWidth: 1,
    borderRadius: scale(4),
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
  }),
};
