import { ReactionProvider } from 'react-native-reactions';
import useCachedResources from "engines/hooks/useCachedResources";
import * as React from "react";
import { Platform, UIManager, View } from "react-native";
import Navigation from "screens/_navigation/root.navigator";
import { IS_WEB } from "utils";
import ErrorBoundary from "./ErrorBoundary";
import { Toasty } from "./src/components";
import { AppProvider } from "./src/engines";
const PushNotification = IS_WEB
  ? null
  : require("react-native-push-notification");

if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

function App() {
  const isLoadingComplete = useCachedResources();

  React.useEffect(function launchApp() {
    // Check if the app was launched from a notification
    !IS_WEB &&
      PushNotification.popInitialNotification((notification) => {
        if (notification && notification?.data) {
          if (notification?.data?.destination) {
            Toasty.show(`Navigating to ${notification?.data?.destination}`);
            navigateFromNotification(notification);
          }
        }
      });
  }, []);

  return !isLoadingComplete ? (
    <View />
  ) : (
    <ReactionProvider>
      <AppProvider>
        <ErrorBoundary>
          <Navigation />
          <Toasty
            ref={(ref) => Toasty.setRef(ref)}
            placement={IS_WEB ? "top" : "bottom"}
          />
        </ErrorBoundary>
      </AppProvider>
    </ReactionProvider>
  );

  // if (!isLoadingComplete) {
  //   return <View />;
  // } else {
  //   return (
  //     <AppProvider>
  //       <ErrorBoundary>
  //         <Navigation />
  //         <Toasty
  //           ref={(ref) => Toasty.setRef(ref)}
  //           placement={IS_WEB ? "top" : "bottom"}
  //         />
  //       </ErrorBoundary>
  //     </AppProvider>
  //   );
  // }
}

// let navigationAttempts = 0;
export const navigateFromNotification = (
  _notification: any,
  attempts = 0,
  goToShortcut?: string
) => {
  const destination = goToShortcut || _notification?.data?.destination;

  const navigationRef =
    require("./src/screens/_navigation/root.navigator").navigationRef;
  if (!navigationRef.current) {
    // navigationRef not ready, try again...
    setTimeout(
      () => navigateFromNotification(_notification, attempts + 1, goToShortcut),
      500
    );
  } else {
    if (_notification && destination) {
      if (!navigationRef.current.navigate) {
        Toasty.show(`Bad navigation ref.`, { type: "danger" });
        return;
      }
      // @ts-ignore
      navigationRef.current.navigate(destination, {
        notification: true,
        notificationData: _notification
      });
      const currentRoute =
        navigationRef.current.getCurrentRoute &&
        navigationRef.current.getCurrentRoute();
      if (
        !currentRoute ||
        (currentRoute?.name !== destination && attempts < 10)
      ) {
        setTimeout(
          () =>
            navigateFromNotification(_notification, attempts + 1, goToShortcut),
          500
        );
      }
    }
  }
};

export default App;
