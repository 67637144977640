import { Buttoon, Poppy } from "components";
import { ACTION_TYPE, recordUnknownUserAction, recordUserAction, useAppContext, UserSchema } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import {
  BmlQSchema,
  QuestionType
} from "engines/backends/schemas/bml/bml.schema";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import _ from "lodash";
import React, { useState } from "react";
import {
  ActivityIndicator,
  Keyboard,
  ScrollView,
  useWindowDimensions
} from "react-native";
import ConfettiCannon from "react-native-confetti-cannon";
import { useNavigator } from "screens/_navigation";
import { tr } from "utils";
import { SSBM } from "./StyledComponents.BML-Mobile";

export interface BMLNavigationProps {
  data: BmlQSchema[];
  scrollRef: React.MutableRefObject<ScrollView>;
  confettiRef?: React.MutableRefObject<ConfettiCannon>;
  setShareDialogue?: React.Dispatch<React.SetStateAction<boolean>>;
  skipped?: boolean;
  revertSkip?: React.Dispatch<React.SetStateAction<boolean>>;
  existingContacts?: ContactSchemaa[];
  onScreenIndexChange?: (index: number) => void;
  user?: UserSchema
}

/**
 * ### BML Navigation Buttons
 *  - These buttons help navigate between different sections of BML
 *  ----
 *  @example 
 *  <BMLNavigation
          data={}
          scrollRef={}
          confettiRef={}
          setShareDialogue={}
          skipped={}
          revertSkip={}
          existingContacts={}
        />
 *  ----
 *  @version 22.04.16
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *  
 **/
export function BMLNavigation(props: BMLNavigationProps) {
  const {
    data: screens,
    scrollRef: scrollViewRef,
    confettiRef,
    setShareDialogue,
    skipped,
    revertSkip,
    existingContacts,
    onScreenIndexChange,
    user
  } = props;
  const { frbsUser } = useAppContext()
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const [screenIndex, setScreenIndex] = useState(1);
  const { C } = useAppContext();

  const { bmlContacts, setBmlContacts } = useBMLMContext();
  const Navigation = useNavigator();
  const [lastQIndex, setLastQIndex] = useState<number>();
  const [disableNavigation, setDisableNavigation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkList, setCheckList] = useState(false);

  // #region [ANCHOR2] //NOTE If the qualification screen has no contacts then take the user out of BML
  React.useEffect(() => {
    if (
      bmlContacts.length === 0 &&
      screens[screenIndex - 1].questionType === QuestionType.QUALIFICATION
    ) {
      Poppy(
        "No contacts to qualify",
        "Please start this process over.",
        {
          text: "Ok",
          onPress: () => {
            Navigation.navigate("Pages");
          },
        },
        {}
      );
    }
  }, [bmlContacts]);
  // #endregion

  React.useEffect(function startAtZero() {
    setTimeout(() => {
      if (scrollViewRef.current !== null) {
        scrollViewRef.current.scrollTo({ x: 0, y: 0 });
      } else {
        setTimeout(() => {
          scrollViewRef.current?.scrollTo({ x: 0, y: 0 });
        }, 500);
      }
    }, 500);
  }, []);

  React.useEffect(
    function screenIndexChanged() {
      const screen = screens[screenIndex];
      // workaround to dismiss keyboard on a text screen
      if (screen && screen.questionType === QuestionType.TEXT) {
        setTimeout(() => Keyboard.dismiss(), 900);
        // Keyboard.dismiss();
      }
    },
    [screenIndex, screens]
  );

  // #region [section] //NOTE Handle the navigation buttons for BML
  const handleNavigation = (option: NAVOPTIONS) => {
    //NOTE If not the end of BML activity then move to next screen else end the activity
    if (option !== NAVOPTIONS.FINISH) {
      if (scrollViewRef.current !== null) {
        scrollViewRef.current.scrollTo({
          x:
            option === NAVOPTIONS.NEXT
              ? screenWidth * screenIndex
              : screenWidth * (screenIndex - 2),
          animated: true,
        });

        const newScreenIndex =
          option === NAVOPTIONS.NEXT ? screenIndex + 1 : screenIndex - 1;

        setScreenIndex(
          newScreenIndex > screens.length ? screenIndex : newScreenIndex
        );
        onScreenIndexChange &&
          onScreenIndexChange(
            newScreenIndex > screens.length ? screenIndex : newScreenIndex
          );

        if (
          screens[newScreenIndex - 1].questionType === QuestionType.CONFETTI
        ) {
          handleConfetti();
          setShareDialogue &&
            setTimeout(() => setShareDialogue(true), 800 + 2870) &&
            setTimeout(() => setShareDialogue(false), 1800 + 2870) &&
            setTimeout(() => setDisableNavigation(false), 2000 + 2870);
        }
      }
    } else {
      Navigation.goBack();
      setBmlContacts(_.uniq([...existingContacts, ...bmlContacts]));
      Navigation.navigate("bml-mobile-list");
      // setTimeout(() => setLoading(false), 500);
      //TODO Clear Local Data after finishing the actvity
      //setBmlContacts([]);
    }
  };
  // #endregion

  // #region [section2] //NOTE Handle confetti when user completes qualification
  const handleConfetti = () => {
    if (screens[screenIndex].questionType === QuestionType.CONFETTI) {
      setDisableNavigation(true);
      setTimeout(() => {
        confettiRef.current.start();
        const actionUserName = user?._id ? user?.personali?.displayName : frbsUser?.uid
        const actionDescription = `${actionUserName} just finished the Build My List exercise with ${bmlContacts.length} contacts.`
        if (user && user._id)
          recordUserAction(user, ACTION_TYPE.BUILD_MY_LIST, actionDescription, { location: "BML" })
        else
          recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.BUILD_MY_LIST, actionDescription, { location: "BML" })
      }, 100);
    } else {
      // console.log("Stopping Confetti");
      confettiRef.current.stop();
    }
  };
  // #endregion

  // #region [ANCHOR] //NOTE Identify the screen for Qualification
  React.useEffect(() => {
    setLastQIndex(
      screens.findIndex((q) => q.questionType === QuestionType.QUALIFICATION) -
      1
    );
  }, []);
  // #endregion

  // #region [section] //NOTE Skip to Qualification screen logic
  React.useEffect(() => {
    if (skipped) {
      const _index =
        screens.findIndex(
          (q) => q.questionType === QuestionType.QUALIFICATION
        ) + 1;
      setScreenIndex(_index);
      onScreenIndexChange && onScreenIndexChange(_index);
      revertSkip(false);
    }
  }, [skipped]);
  // #endregion

  // #region [ANCHOR2] Show the loading button when clicking Check Results button
  React.useEffect(() => {
    if (screenIndex === screens.length && checkList) {
      setLoading(true);
      setCheckList(false);
    } else {
      setLoading(false);
    }
  }, [checkList]);
  // #endregion

  try {
    return (
      <SSBM.Screen>
        {/* NOTE If no contacts select during memory jogger process then do not allow user to move on to next section */}
        {/* <Txt.Helper marginV>
          {screenIndex === lastQIndex && bmlContacts.length === 0
            ? tr("Please add contacts to continue...")
            : ""}
        </Txt.Helper> */}
        <SSBM.NAVBtns>
          {screenIndex === 1 || screenIndex === screens.length ? (
            !loading ? (
              <Buttoon
                style={{
                  width: screenWidth,
                  borderRadius: 0,
                }}
                size="giant"
                onPress={() => {
                  confettiRef.current.stop();
                  handleNavigation(
                    screenIndex === 1 ? NAVOPTIONS.NEXT : NAVOPTIONS.FINISH
                  );
                  setCheckList(screenIndex === 1 ? false : true);
                  setLoading(screenIndex === 1 ? false : true);
                }}
                appearance="filled"
                icon={{ name: "chevron_right", right: true }}
                status="danger"
              >
                {screenIndex === 1 ? "Start" : "See Results"}
              </Buttoon>
            ) : (
              <ActivityIndicator
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: C.errorRed,
                  flex: 1,
                }}
                size="small"
                color={C.text}
              />
            )
          ) : (
            <>
              <Buttoon
                disabled={disableNavigation}
                style={{ width: screenWidth * 0.5, borderRadius: 0 }}
                onPress={() => handleNavigation(NAVOPTIONS.BACK)}
                appearance="filled"
                icon={{ name: "arrow_left" }}
                status="danger"
                size="giant"
              >
                {tr("Back")}
              </Buttoon>
              <Buttoon
                style={{ width: screenWidth * 0.5, borderRadius: 0 }}
                onPress={() => {
                  screenIndex < screens.length &&
                    handleNavigation(NAVOPTIONS.NEXT);
                }}
                appearance="filled"
                icon={{ name: "chevron_right", right: true }}
                status="info"
                size="giant"
                disabled={disableNavigation}
              // disabled={
              //   (screenIndex === lastQIndex && bmlContacts.length === 0) ||
              //   disableNavigation
              // }
              >
                {tr("Next")}
              </Buttoon>
            </>
          )}
        </SSBM.NAVBtns>
      </SSBM.Screen>
    );
  } catch (error) {
    throw Error(`::Qk1MTmF2aWdhdGlvbg==::${error}`);
  }
}

enum NAVOPTIONS {
  "NEXT" = "next",
  "BACK" = "back",
  "FINISH" = "finish",
}
