import { IconPrimr } from "assets";
import { Buttoon, Kitten, Txt } from "components/";
import {
  dNotification,
  fn,
  MediaPageSchema,
  useAppContext,
  useNotificationCollection,
  useUserProfile,
} from "engines";
import { dateAsNumber } from "engines/functions/calendar-functions/time";
import moment from "moment";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
import { NotificationComments } from "./NotificationComments";
// import { C, moderateScale, spacing } from "utilities/";

// let __MOCK_NOTIFICATIONS = [
//   { id: 0, title: "First noti", message: "Sup!" },
//   { id: 0, title: "Second noti", message: "Bro Sup Bro Sup Bro Sup Bro!" },
// ];

export function NotificationFeed(props) {
  const { navigation } = props;
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();
  const [_globalNotifications, setGlobalNotifications] = React.useState<
    dNotification[]
  >([]);
  const [_teamNotifications, setTeamNotifications] = React.useState<
    dNotification[]
  >([]);

  const userProfile = useUserProfile();

  // const allNotifications = fn.notifications.sortNotifications(
  //   _globalNotifications.concat(_teamNotifications)
  // );

  const { data, ...notificationsHandler } = useNotificationCollection(
    `${teamId}`
  );
  const baseshopData = useNotificationCollection(`${teamId}`, {
    where: [["baseShopId", "==", userProfile?.data.baseShopId]],
  });

  console.log(" useNotificationCollection ", userProfile?.data.baseShopId);

  React.useEffect(function fetchNotifications() {
    setGlobalNotifications(data ? data : []);
    setTeamNotifications(baseshopData ? baseshopData.data : []);
    if (
      !userProfile?.data?.notifications?.lastRead ||
      fn.notifications.getUnreadNotifications(
        userProfile?.data && userProfile?.data
      ).length > 0
    ) {
      userProfile.update({
        notifications: { lastRead: new Date() },
      });
    }
  }, []);

  function notificationPress(notification: dNotification) {
    Navigation.overlayPortal.open({
      headerTitle: "Notification comments",
      render: <NotificationComments notification={notification} />,
    });
    // Navigation.navigate("notificationComments", { notification });
  }

  const sortedData = data?.sort(
    (a, b) => b?.createdAt?.seconds - a?.createdAt?.seconds
  );

  return (
    <Kitten.List
      data={sortedData?.length > 75 ? sortedData.slice(0, 75) : sortedData}
      renderItem={({ item }) => (
        <NotificationItem item={item} onPress={notificationPress} />
      )}
      ItemSeparatorComponent={Kitten.Divider}
      keyExtractor={(item, idx) => `${idx}`}
      ListEmptyComponent={() => (
        <Txt.Indicator marginV>
          {notificationsHandler.loading
            ? tr("Loading...")
            : tr("No notification found")}
        </Txt.Indicator>
      )}
    />
  );
}

export const NotificationItem = (props: {
  item: dNotification;
  onPress?: (noti: dNotification) => void;
}) => {
  const { item, onPress } = props;

  const { C, teamId } = useAppContext();

  const [_myTeam, setMyTeam] = React.useState<MediaPageSchema>(null);
  const userProfile = useUserProfile();
  const Navigation = useNavigator();

  var aDay = 24 * 60 * 60 * 1000;
  const today = item.createdAt?.toDate();

  const day = moment(today).fromNow();

  const read =
    dateAsNumber(item?.createdAt) <
    dateAsNumber(userProfile?.data?.notifications?.lastRead);

  const title =
    item?.title && item?.title?.length > 0 ? item?.title : item?.message;
  const description =
    item?.message && item?.message?.length > 0 ? item?.message : null;

  return (
    <Kitten.ListItem
      onPress={() => onPress(item)}
      style={{
        marginVertical: spacing(1),
      }}
      title={title}
      description={description === title ? "" : description}
      accessoryLeft={() =>
        _myTeam &&
        item?.channel?.length > 0 &&
        item?.channel ===
          topicIdForTeam(_myTeam?._id ? _myTeam?._id : _myTeam?._id) ? (
          _myTeam?.mediaItem?.logo ? (
            <Image
              source={{ uri: _myTeam?.mediaItem?.logo }}
              style={{
                width: scale(23),
                height: scale(23),
              }}
            />
          ) : (
            <IconPrimr
              name="flag"
              size={scale(23)}
              color={read ? C.dim : C.errorRed}
            />
          )
        ) : (
          <IconPrimr
            name="dot"
            size={scale(10)}
            color={read ? C.dim : C.errorRed}
          />
        )
      }
      accessoryRight={() => (
        <View style={{ alignItems: "flex-end" }}>
          <Txt.C2>
            {item?.createdAt?.toDate().toLocaleDateString() || "Unknown date"} •{" "}
            {item?.createdAt?.toDate().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }) || "Unknown time"}
          </Txt.C2>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Txt.C2>{tr(item?.comments?.length || "0")}</Txt.C2>
            <IconPrimr name="chat_bubble" size={scale(14)} color={C.dim} />
          </View>
        </View>
      )}
    />
  );
};

const SS = StyleSheet.create({
  CTNR_HEADER: {
    paddingHorizontal: spacing(3),
    alignItems: "center",
  },
  paragraph: {
    margin: 24,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
});
