/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.09.22
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function getTeamId(value) {
  let val = value.split(".");
  return val[0];
}
