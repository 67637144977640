import { Avt, Buttoon, Kitten, Skeleton, sstyled, Txt } from "components";
import { useTwoColumns } from "components/templates";
import {
  ConfigVariablesSchema,
  FRBS_ROLE,
  useAppContext,
  useConfigCollection,
  useConfigDocument
} from "engines";
import {
  useMembersCollection,
  useUserProfile
} from "engines/firebase/handler/members-handler";
import * as R from "ramda";
import React from "react";
import { Pressable, View } from "react-native";
import { useTourGuideController } from "rn-tourguide";
import { useNavigator } from "screens";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { scale, spacing, THEME, tr } from "utils";
import { MembersFeed } from "../members/MembersFeed";

/**
 * ### Profile and Quick Actions Center
 *  - Show avatar, name
 *  - Show quick creator actions for Admin (see `footer`)
 *  ----
 *  @version 21.03.14
 *  -  *Add `<S_CreatorList>`*
 *  @author  K
 *
 **/
export function ProfileAndActionsCenter({
  showNewStartHere = true,
}: {
  showNewStartHere?: boolean;
}) {
  const { C, theme, setTheme, teamId, frbsUser } = useAppContext();

  //#region [FRBS]
  const userProfile = useUserProfile();
  const membersHandler = useMembersCollection({
    where: ["_teamId", "==", teamId],
  });;
  // const configHandler = useConfigCollection();
  const configHandler = useConfigDocument(`variables-${teamId}`)
  // const appConfig = configHandler._getConfigFromSubDomain(teamId);
  // const { data: varD, ...varHandler } = useConfigDocument(
  //   `variables-${teamId}`
  // );
  const appConfig = configHandler.data as ConfigVariablesSchema
  let startHerePageId = appConfig?.startHerePage;
  //#endregion
  const Navigation = useNavigator();

  //#region [section] tourguide
  const { stop: stopTourguide } = useTourGuideController();

  //#endregion
  const { breakpointForTwoColumns } = useTwoColumns();
  function toNewStartHerePage() {
    stopTourguide();
    Navigation.navigate<dPageContentsScreenParam>("Page Contents", {
      cpid: appConfig?.startHerePage,
    });
  }
  React.useEffect(function triggering() {
    !breakpointForTwoColumns && (Navigation.goBack(), toNewStartHerePage()); //* On smaller screen, it'll navigate to New Start Here page
  }, []);

  try {
    return breakpointForTwoColumns ? (
      <Kitten.Card
        onPress={() => {
          setTheme(theme == THEME.DARK ? THEME.LIGHT : THEME.DARK);
        }}
        footer={() =>
          frbsUser && (showNewStartHere || !startHerePageId) ? (
            <PressableNewStartHere toNewStartHerePage={toNewStartHerePage} />
          ) : (
            <View />
          )
        }
      >
        <_2______ />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Skeleton
            isLoading={!appConfig?.appTitle}
            layout={[
              { key: "text-skltn", width: 250, height: 30, marginBottom: 6 },
            ]}
          >
            <Txt.H6
              style={{
                backgroundColor: R.isNil(userProfile) ? C.dim : null,
              }}
            >
              {appConfig?.appTitle}
            </Txt.H6>
          </Skeleton>
        </View>
        <_2______ />
        <Skeleton
          isLoading={!membersHandler.data}
          layout={[
            { key: "text-skltn", width: 220, height: 20, marginBottom: 6 },
          ]}
        >
          <Avt.Row
            data={membersHandler.data?.map((member) => ({
              name: member?.personali?.displayName || member?.personali?.email,
              source: {
                uri: member.personali?.photoURL,
              },
            }))}
          />

          <Buttoon
            appearance="ghost"
            size="tiny"
            icon={{ name: "chevron_right", right: true }}
            onPress={() => {
              userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ])
                ? Navigation.navigate("Admin Portal")
                : Navigation.overlayPortal.open({
                  render: <MembersFeed mode={"alacarte"} />,
                  headerTitle: `People of ${appConfig?.appTitle}`,
                });
            }}
          >
            {userProfile._isRolesContain([FRBS_ROLE.ADMIN, FRBS_ROLE.DEVELOPER])
              ? tr("Go to Admin Portal")
              : `See all people of ${appConfig?.appTitle || "..."}`}
          </Buttoon>
        </Skeleton>

        <_2______ />
      </Kitten.Card>
    ) : (
      <View />
    );
  } catch (error) {
    throw new Error(error);
  }
}

const _2______ = () => <View style={{ height: spacing(2) }} />;

export const PressableNewStartHere = (p) => {
  const { toNewStartHerePage } = p;
  const { C, teamId } = useAppContext();

  return (
    <A.PressableContainer
      onPress={() => toNewStartHerePage && toNewStartHerePage()}
    >
      <Skeleton
        isLoading
        duration={2400}
        // easing={Easing.circle}
        layout={[
          {
            key: "medal-wrapper",
            width: scale(940),
            height: scale(160),
            // justifyContent: "center",
          },
        ]}
        highlightColor={C.primary}
        boneColor={C.infoBlue}
      />
      <A.ViewLabel>
        <Txt.H4>{tr("Start Training")}</Txt.H4>
      </A.ViewLabel>
    </A.PressableContainer>
  );
};

const A = {
  PressableContainer: sstyled(Pressable)({
    borderBottomLeftRadius: scale(4),
    borderBottomRightRadius: scale(4),
    overflow: "hidden",
    backgroundColor: "transparent",
  }),
  ViewLabel: sstyled(View)((p) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  })),
};
