import { ConfigVariablesSchema } from "engines";
import { BmlQSchema, ContactSchemaa } from "engines/backends/schemas/bml";

export enum FPATH {
  USERS = "users",
  CONFIGS = "configs",
  POSSTS = "possts",
  COMMENTS = "comments",
  SCOREBOARDS = "scoreboards",
  PAGES = "pages",
  EMAIL = "emails",
  TEMPLATES = "email-templates",
  SCHEDULES = "email-schedules",
  // CUSTOM_PAGES = "pages",
  CUSTOM_PAGE_CONTENT = "pageContent",
  CONTACT_GROUPS = "contact-groups",
  USER_ACTIVITIES = "activities",
  USER_ACTIONS = "actions",
  USER_VIEW_HISTORY = "viewHistory",
  TASKS = "tasks",
  USER_ATTENDANCE = "attendance",
  NOTIFICATION = "notifications",
  CONTACTS = "contacts",
  PROFILES = "PROFILES",
  MORE = "more",
  TROPHIES = "trophies",
  // for Patriots Club Card
  COMPANIES = "companies",
}

/**
 * @description This enum is related to Data Sync cloud functions
 */
export enum COLL {
  CONFIG_KEYS = "keys",
  CONFIG_VARIABLES = "variables",
}

/**
 * @deprecated not used anymore
 * @description DB Action Type enum. This is related to Data Sync Cloud functions
 * @author Saurabh_M
 * @version 2021.08.31
 * @example ACTIONTYPE.ADD,
 * ACTIONTYPE.DELETE,
 * ACTIONTYPE.UPDATE
 */
export enum ACTIONTYPE {
  ADD = "add",
  DELETE = "delete",
  UPDATE = "update",
  MORE = "more",
}

export enum FRBS_BUCKET {
  /**
   * Dir stored user-related files (e.g. avatar...)
   */
  USERS = "users",
  /**
   *  Dir stored posst-related files (e.g. posst's image, video...)
   */
  POSSTS = "possts",
}

export enum FRBS_ROLE {
  /**
   * Devs team can now move to DEVELOPER INSTEAD,
   * those who can access to FRBS admin
   */
  ADMIN = "ADMIN",
  /**
   * Devs team,
   * those who can access to FRBS admin
   */
  DEVELOPER = "DEVELOPER",
  /**
   * Team leader,
   * who lead the big team
   */
  LEADER = "LEADER",
  /**
   * Field trainer,
   */
  TRAINER = "TRAINER",
  /**
   * Hierarchy leader,
   * who lead the teamId/branch of the team
   */
  HEADER = "HEADER",
  /**
   * New member of the team,
   * who hasn't finished onboarding tasks
   */
  NEWBIE = "NEWBIE",
  BANNED = "BANNED",
  BSID = "BS:", //* "Testing Base Shop" BS: 7np6a8GeD4wy6f3IG0b9
}

export interface Birthday {
  day: number;
  month: number;
  year: number;
}
export interface VimeoToken {
  accessToken: string;
  userId: string;
}

export interface dConfigKeys {
  calendarAccessToken?: string;
  calendarId?: string;
  dropbox?: string[];
  vimeo?: VimeoToken[];
}

/**
 * goes with ListBuilderHandler
 */
export interface dListBuilderList {
  title: string;
  id: string;
  contacts: ContactSchemaa[];
}
export interface dConfigVariables {
  adminPassword: string;
  appPassword: string;
  appTakeoffShareURL?: string;
  appTitle: string;
  disableCalendar: boolean;
  disableContactManager: boolean;
  disableScoreboard: boolean;
  disableMore: boolean;
  enablePushNotifications: boolean;
  googleDrive?: { folderId: string };
  levels?: {
    allowLevelSkipping?: boolean;
    lockMedia?: boolean;
  };
  listBuilder: {
    defaultLists: dListBuilderList[];
  };
  releaseVersion?: string;
  updateURL?: string;
  reviewMode?: boolean;
  videoCalls?: { title: string; meetingId: string }[];
  bmlQuestionnaire?: BmlQSchema[];
  qualifications?: dQualification[];
}

export interface dQualification {
  _id: string;
  /**
   * @description Name of the qualification like Married, Motivated or so on
   */
  name: string;
  /**
   * @description is the qualification disabled, true = yes and false = no
   */
  isDisabled: boolean;
  /**
   * @description Do we need to count this towards the pointers ? false = no and true = yes
   */
  countTowardsPointer: boolean;
}

export interface dConfig {
  keys: dConfigKeys;
  variables: ConfigVariablesSchema;
}

export interface dMediaSlide {
  title?: string;
  description?: string;
  position: Number;
  image: string;
  link?: string;
}

export interface dMoreItem {
  name: string;
  position: number;
  logo?: string;
  iosLink?: string;
  androidLink?: string;
  contactEmail?: string;
  contactPhone?: string;
  otherLink?: string;
}

/**
 * From react-native-contacts
 */
export interface Contact {
  recordID: string;
  backTitle: string;
  company: string;
  emailAddresses: EmailAddress[];
  familyName: string;
  givenName: string;
  middleName: string;
  jobTitle: string;
  phoneNumbers: PhoneNumber[];
  hasThumbnail: boolean;
  thumbnailPath: string;
  postalAddresses: PostalAddress[];
  prefix: string;
  suffix: string;
  department: string;
  birthday: Birthday;
  imAddresses: InstantMessageAddress[];
  note: string;
}

export interface EmailAddress {
  label: string;
  email: string;
}

export interface PhoneNumber {
  label: string;
  number: string;
}

export interface PostalAddress {
  label: string;
  formattedAddress: string;
  street: string;
  pobox: string;
  neighborhood: string;
  city: string;
  region: string;
  state: string;
  postCode: string;
  country: string;
}

export interface InstantMessageAddress {
  username: string;
  service: string;
}
