//#region [import]
import { useDocument } from "@nandorojo/swr-firestore";
import { Buttoon } from "components/atoms";
import { fn, FPATH, MediaPageSchema, PosstSchema, URL_PREFIX } from "engines";
// import { usePosstDocument } from "engines/backends/__quick-handler/QuickHandler";
import { LinkURL } from "engines/functions/web-functions";
import React from "react";
import { useNavigator } from "screens";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { tr } from "utils";

//#endregion

interface dGoToPage {
  // posstHandler: dUseDocumentReturns<PosstSchema>;
  posst: PosstSchema
}

export function GoToPage({ posst }: dGoToPage) {
  const Navigation = useNavigator();
  // const item = posstHandler.data;

  const [linkType, setLinkType] = React.useState<"page" | "url">(null);
  const [linkLabel, setLinkLabel] = React.useState<string>("");

  //#region [section] handle link as a page link
  const [cpid, setCpid] = React.useState<string>(null);
  const handlePage = useDocument<MediaPageSchema>(`${FPATH.PAGES}/${cpid}`);
  //#endregion

  React.useEffect(
    function getLink() {
      //* Check if there's link in the body (logic from old schema)
      const link = fn.js.extractLinkFromBody(posst?.body);
      if (link) {
        // posstHandler.update({ goToPage: link });
      }
    },
    [posst?.body]
  );

  React.useEffect(
    /**
     * get link type and set button label from `goToPage` value
     */
    function getLinkType() {
      if (posst?.goToPage?.startsWith(URL_PREFIX.PAGE)) {
        setLinkType("page");
        setCpid(posst?.goToPage?.split(URL_PREFIX.PAGE).pop());
        setLinkLabel(handlePage.data?.name);
      }
      if (posst?.goToPage?.startsWith("http")) {
        !(posst?.goToPage === "https://") && //todo this is a workaround for existing goToPage with value "https://". Should weed out this using cloud functions
          (setLinkType("url"), setLinkLabel(posst?.goToPage));
      }
    },
    [posst?.goToPage, handlePage.data]
  );

  //#region [functions]
  function onLinkPress() {
    if (linkType == "page") {
      return Navigation.navigate<dPageContentsScreenParam>("Page Contents", {
        cpid: cpid,
      });
    }
    if (linkType == "url") {
      return LinkURL(posst?.goToPage, true);
    }
  }
  //#endregion

  switch (linkType) {
    case "url":
      return (
        <Buttoon
          icon={{ name: "link" }}
          size="small"
          appearance={"ghost"}
          onPress={onLinkPress}
        >
          {tr("Go to") + " " + String(linkLabel)?.substring(0, 50)}
        </Buttoon>
      );
      break;
    case "page":
      return (
        <Buttoon
          icon={{ name: "page" }}
          size="small"
          appearance={"ghost"}
          onPress={onLinkPress}
        >
          {tr("Go to") + " " + String(linkLabel)?.substring(0, 50)}
        </Buttoon>
      );
      break;

    default:
      return null;
      break;
  }
}
