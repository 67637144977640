/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.02.04
 *  -  *Brief changelog*
 *  @author  Your Name
 *
 **/

import { MediaPageItemSchema } from "engines/backends";

/**
 * Takes a list of MediaPageItems and converts them into a pretty string that can be shared out (like in a sms or email)
 *
 * @author jm_francis
 */
export function shareItemsToPrettyMessage(
  shareItems: MediaPageItemSchema[]
): string {
  console.log("shareItems shareItemsToPrettyMessage ", shareItems);
  let renderHTML = false;
  let message = "";
  //   const shareItems = customPageHandler.compileShareItems();
  //   console.log(shareItems);
  let index = 0;
  for (let i in shareItems) {
    const shareItem = shareItems[i];
    const text =
      typeof shareItem?.title === "string" && shareItem?.title?.length > 1
        ? shareItem?.title
        : typeof shareItem?.paragraph === "string" &&
          shareItem?.paragraph?.replace(/ /g, "")?.length > 1
        ? shareItem?.paragraph
        : "";
    const source =
      typeof shareItem?.media === "string" &&
      shareItem?.media?.replace(/ /g, "")?.length > 2
        ? shareItem?.media
        : typeof shareItem?.url === "string" &&
          shareItem?.url?.replace(/ /g, "")?.length > 2
        ? shareItem?.url
        : null;
    // if (!shareItem.title || !shareItem.source) continue;
    if (renderHTML && source) {
      message += `<a href="${source}">${text}</a><br><br>`;
    } else
      message +=
        text +
        (source ? `\n${source}` : "") +
        (index + 1 == shareItems.length ? "" : " \n\n");

    ++index;
  }
  return renderHTML ? "<html>" + message + "</html>" : message;
}
