import { Buttoon, Kitten, Poppy, Toasty, Txt } from "components";
import { ListItemv2 } from "components/atoms/externals/ui-kitten/list-item-v2";
import { TwoColumns } from "components/templates";
import {
  fn,
  FPATH,
  PosstSchema,
  POSST_STATUS,
  useAppContext,
  usePosstsCollection
} from "engines";
import firebase from "firebase";
import React from "react";
import { Image, View } from "react-native";
import Animated from "react-native-reanimated";
import { spacing } from "utils";

interface d {}

/**
 * ### View scheduled announcements/possts
 *
 *  @author jm_francis
 *
 **/
export function ScheduledPossts(props: d) {
  const { teamId } = useAppContext();

  const [scheduledPossts, setScheduledPossts] =
    React.useState<PosstSchema[]>(null);

  const posstsHandler = usePosstsCollection({
    where: [
      // ["status", "==", POSST_STATUS.SCHEDULED],
      ["_teamId", "==", teamId],
    ],
  });

  React.useEffect(
    function loadScheduledPossts() {
      if (!posstsHandler.data) return;
      setScheduledPossts(
        posstsHandler.data
          .filter((_p) => _p.status === POSST_STATUS.SCHEDULED)
          .sort((a, b) => {
            return (
              a.createdAt?.toDate()?.getTime() -
              b.createdAt?.toDate()?.getTime()
            );
          })
      );
    },
    [posstsHandler.data]
  );

  return (
    <>
      <TwoColumns
        disableAnimatedFooter
        bottomInset
        column1Content={(scrollHandler) =>
          scheduledPossts ? (
            <Animated.FlatList
              scrollEventThrottle={16}
              onScroll={scrollHandler}
              data={scheduledPossts}
              renderItem={(data: { item: PosstSchema }) => (
                <ScheduledPosstCard posst={data.item} />
              )}
            />
          ) : (
            <Kitten.Spinner />
          )
        }
        column2Content={[]} // button actions go here
      />
    </>
  );
}

function ScheduledPosstCard(p: { posst: PosstSchema }) {
  const { posst } = p;

  const { C } = useAppContext();

  return (
    <ListItemv2
      style={{ marginVertical: spacing(2) }}
      accessoryLeft={() => (
        <>
          <View style={{ flex: 1 }}>
            <Txt.Indicator
              style={{ marginBottom: spacing(3) }}
            >{`Scheduled for ${fn.calendar.dateFormat(
              posst?.createdAt?.toDate(),
              "mm/dd/yyyy"
            )} @ ${fn.calendar.timeToAMPMString(
              posst?.createdAt?.toDate()
            )}`}</Txt.Indicator>
            <Txt.S1>{posst?.body}</Txt.S1>
          </View>
        </>
      )}
      accessoryRight={() => (
        <>
          {posst.medias?.length > 0 ? (
            <>
              <Image
                source={{ uri: posst.medias[0].uri }}
                style={{ width: 135, height: 135 }}
              />
              {posst.medias?.length > 1 ? (
                <Txt.Helper>{`and ${
                  posst.medias?.length - 1
                } more`}</Txt.Helper>
              ) : null}
            </>
          ) : null}
          <View style={{ width: "100%", justifyContent: "flex-end" }}>
            <Buttoon
              onPress={() => {
                Poppy(
                  "Are you sure you want to delete this announcement?",
                  "You cannot undo this action.",
                  { text: "No" },
                  {
                    text: "Delete",
                    onPress: async () => {
                      Toasty.show("Deleting...", { type: "loading" });
                      await firebase
                        .firestore()
                        .doc(`${FPATH.POSSTS}/${posst?._pid}`)
                        .update({ status: POSST_STATUS.DELETED });
                      Toasty.show("Announcement deleted.", { type: "success" });
                    },
                  }
                );
              }}
              icon={{ name: "trash", color: C.errorRed }}
              appearance="ghost"
              size="small"
            ></Buttoon>
          </View>
        </>
      )}
    />
  );
}
