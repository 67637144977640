import { useCollection } from "@nandorojo/swr-firestore";
import { useFocusEffect } from "@react-navigation/native";
import { M, Poppy, sstyled, Toasty, Txt } from "components";
import { Buttoon, Kitten, Skeleton } from "components/atoms";
import {
  ACTION_TYPE,
  FPATH,
  Handler,
  recordUserAction,
  useAppContext,
  useMemberDocument,
  useMembersCollection,
  UserSchema
} from "engines";
import * as R from "ramda";
import React from "react";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";
import { ShareAccountDialog } from "./ShareAccountDialog";

/**
 * ###
 *  -
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.01.21
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function TrainersTeammates() {
  const { C, teamId, frbsUser } = useAppContext();
  const Navigation = useNavigator()

  const [members, setMembers] = React.useState<UserSchema[]>([]);
  const memberHandler = useMemberDocument(frbsUser?.uid);
  const shareData = memberHandler?.data?.growth?.listBuilder?.shareTo;
  //#region [FRBS]
  const handleMembers = useMembersCollection({where: ["_id","in",shareData]})
  // const handleMembers = useCollection<UserSchema>(`${FPATH.USERS}`, {
  //   // ...Handler.defaultSetup<UserSchema>(teamId),
  //   where: ["_id", "in", shareData]
  // });
  const [_sharedWithUids, setSharedWithUids] = React.useState<string[]>([]);
  React.useEffect(
    function setStates() {
      setSharedWithUids(shareData ? shareData : []);
    },
    [shareData]
  );

  React.useEffect(
    function setupData() {
      let __members = handleMembers.data;
      handleMembers.data &&
        setMembers(
          __members.filter((m) =>
            memberHandler.data?.growth?.listBuilder?.shareTo?.includes(m._id)
          )
        );
    },
    [handleMembers.data]
  );
  const [listOffset, setListOffset] = React.useState(30);
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#endregion
  return React.useMemo(() => {
    try {
      if (handleMembers.error) {
        return (
          <Txt.Indicator>{tr("Error getting members feed")}</Txt.Indicator>
        );
      }
      if (!handleMembers.data) return <Skeleton.Screen.Members length={7} />;

      let sortedMembers = R.sortWith<UserSchema>(
        [
          /** sorting `createdAt` in `desc` */
          R.descend(R.prop("createdAt")),
        ],
        members
      );
      return (
        <View>
          <A.CardContainer disabled>
            {/* <Kitten.Input
              value={query}
              placeholder={tr("Search trainers")}
              onChangeText={(value) => {
                filterHandler(value);
              }}
            /> */}
            {/* <Kitten.Divider_________ /> */}
            <FlatList
              data={sortedMembers}
              renderItem={({ item: member, index }) => (
                <>
                  {_sharedWithUids && _sharedWithUids.includes(member?._id) ? (
                    <M.MemberListItem
                      key={member._id}
                      member={member}
                      team={true}
                      overridePress={() => {
                        Poppy(
                          "Do you want to remove this person as your trainer?",
                          member.personali?.displayName || "",
                          {
                            text: "Yes",
                            onPress: async () => {
                              await memberHandler.update({
                                growth: {
                                  levels: memberHandler.data.growth.levels,
                                  listBuilder: {
                                    lists:
                                      memberHandler.data.growth.listBuilder
                                        .lists || [],
                                    shareTo: _sharedWithUids.filter(
                                      (uu) => uu !== member._id
                                    ),
                                  },
                                },
                              });
                              recordUserAction(memberHandler.data, ACTION_TYPE.UNASSIGNED_TRAINER, `${memberHandler.data?.personali?.displayName} removed ${member?.personali?.displayName} from being their field trainer.`, { location: member?._id, involvedPartyName: member?.personali?.displayName })
                              Toasty.show("Field trainer removed", {
                                type: "success",
                              });
                            },
                          },
                          { text: "No" }
                        );
                      }}
                    />
                  ) : null}
                </>

                /* Type was added in other to reuse the memberListItem component and 
              to know if it was a BS or a member info detail, ask Nanle if you have questions */
              )}
              ListFooterComponent={() =>
                sortedMembers?.length > 10 ? (
                  <Buttoon
                    appearance={"ghost"}
                    progress
                    onPress={(xong) => {
                      setTimeout(() => {
                        setListOffset(listOffset + 5);
                        xong && xong();
                      }, 600);
                    }}
                    disabled={listOffset >= sortedMembers.length}
                  >
                    {listOffset < sortedMembers.length &&
                      sortedMembers.length !== 0
                      ? tr("Load More")
                      : ""}
                  </Buttoon>
                ) : null
              }
              ListEmptyComponent={() => (
                <Txt.Indicator marginV>
                  {handleMembers.loading
                    ? tr("Loading...")
                    : "You need a trainer, assign one now!"}
                </Txt.Indicator>
              )}
              keyExtractor={(item) => item._id}
            />
          </A.CardContainer>
          <Buttoon icon={{ name: "plus" }} size="small" status="control"
            style={{ margin: spacing(4) }}
            onPress={() => {
              Navigation.dialogPortal.open({
                headerTitle: tr("Assign Trainer"),
                render: <ShareAccountDialog />,
              });
            }}>{"Assign New Trainer"}</Buttoon>
        </View>
      );
    } catch (error) {
      throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
    }
  }, [members, listOffset, C, shareData]);
}

const A = {
  CardContainer: sstyled(Kitten.Card)({
    minWidth: 300,
  }),
};

interface P {
  /*
  used to pass query to this components
  the where clause allow us to compare multiple parameters
  we could have something like 
  where teamId, ==, "apptakeoff"
  this then returns all users under the teamId apptakeoff
 * 
 * */
  where?: WhereItem<UserSchema>;
  bsid?: string; //I added this because i was having a type error on the teams page
}

type WhereItem<Doc extends object = {}, key = keyof Doc> = [key, "==", unknown];
