import * as EXCalendar from "expo-calendar"
import { ListItemProps } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import { Buttoon, Kitten, ListHeader, Toasty } from "components";
import { sstyled, Txt } from "components/atoms";
import {
  ACTION_TYPE,
  dCalendarEvent,
  fn,
  recordUserAction,
  useAppContext,
  useCalendar,
  UserSchema
} from "engines";
import React from "react";
import { ImageBackground, View } from "react-native";
import * as AddCalendarEvent from "react-native-add-calendar-event";
import { CalendarScreen, useNavigator } from "screens";
import { scale, spacing, tr } from "utils";

export function CalendarListSection(
  props: ListItemProps & { userData: UserSchema }
) {
  const { userData } = props;
  const { C, frbsUser, teamId, theme } = useAppContext();

  const Navigation = useNavigator();
  const calendarHandler = useCalendar();
  /** just today */
  const [personalCalendarEvents, setPersonalCalendarEvents] = React.useState<
    dCalendarEvent[]
  >([]);

  React.useEffect(
    function _setPersonalCalendarEvents() {
      if (!userData) return;
      if (userData.personalCalendars) {
        calendarHandler
          .getTodaysPersonalCalendarEvents(userData.personalCalendars)
          .then((events) => setPersonalCalendarEvents(events));
      }
    },
    [userData]
  );

  const allTodaysEvents = calendarHandler.combineAndSortEvents(
    calendarHandler.getTodaysEvents() || [],
    personalCalendarEvents || []
  );

  const openCalendarScreen = () => {
    Navigation.overlayPortal.open({
      render: <CalendarScreen />,
      headerTitle: "Calendar",
    });
  };

  try {
    return (
      <>
        <A.ViewSection>
          <ListHeader
            title={tr("Today's Meetings")}
            style={{ paddingBottom: 0, backgroundColor: "transparent" }}
            accessoryRight={() =>
              allTodaysEvents?.length ? (
                <A.ChipIndicator>
                  {allTodaysEvents?.length || "-"}
                </A.ChipIndicator>
              ) : (
                <></>
              )
            }
          />

          {/* {fuContacts?.length !== 0 && (
          <Txt.Helper style={{ color: C.hazardYellow }}>
            You have {fuContacts?.length} contact(s){"\n"}that need to be
            followed up with today
          </Txt.Helper>
        )} */}
          <Kitten.List
            data={allTodaysEvents?.slice(0, 3)}
            keyExtractor={(item, index) => index + "__" + item?.eventId}
            ItemSeparatorComponent={Kitten.Divider}
            style={{ backgroundColor: "transparent" }}
            renderItem={({ item, index }) => (
              <Kitten.ListItemv2
                key={index + "__" + item.eventId}
                style={{ backgroundColor: "transparent" }}
                onPress={() => {
                  Navigation.overlayPortal.open({
                    render: <CalendarScreen />,
                    headerTitle: "Calendar",
                  });
                }}
                accessoryLeft={() =>
                  calendarHandler
                    ?.getUpcomingEvents()
                    ?.find((e) => e.eventId === item?.eventId) ? (
                    <IconPrimr name="calendar" color={C.text} size={19} />
                  ) : (
                    <IconPrimr name="check" color={C.primary} size={19} />
                  )
                }
                title={item?.title}
                description={() => null}
                accessoryRight={() => (
                  <Kitten.ViewH>
                    <Txt.C1>{fn.calendar.timeToAMPMString(item.date)}</Txt.C1>
                  </Kitten.ViewH>
                )}
              />
            )}
            ListEmptyComponent={() => (
              <View style={{ marginVertical: spacing(3) }}>
                <Txt.Helper style={{ textAlign: "center" }}>
                  {"0 upcoming meetings"}
                </Txt.Helper>
                <Buttoon size="small"
                  appearance="ghost"
                  status="basic"
                  icon={{ right: true, name: "chevron_right" }} onPress={() => {
                    Navigation.overlayPortal.open({
                      render: <CalendarScreen />,
                      headerTitle: "Calendar",
                    });
                  }}>{"view events"}</Buttoon>
              </View>
            )}
            ListFooterComponent={() =>
              allTodaysEvents?.length > 3 ? (
                <Buttoon
                  onPress={() => {
                    Navigation.overlayPortal.open({
                      render: <CalendarScreen />,
                      headerTitle: "Calendar",
                    });
                  }}
                  size="small"
                  appearance="ghost"
                  status="basic"
                  icon={{ right: true, name: "chevron_right" }}
                >
                  {allTodaysEvents?.length > 3
                    ? `see ${allTodaysEvents?.length - 3} more events(s)`
                    : `see upcoming events`}
                </Buttoon>
              ) : null
            }
          />
        </A.ViewSection>
        <Kitten.ViewH
          style={{ justifyContent: "flex-end", padding: spacing(3) }}
        >
          <Buttoon
            size="tiny"
            appearance="ghost"
            status="info"
            onPress={() => {
              AddCalendarEvent.presentEventCreatingDialog({ title: "" })
                .then(async (evt) => {
                  if (evt.action === "SAVED") {
                    const savedEvent = await EXCalendar.getEventAsync(evt.eventIdentifier)
                    recordUserAction(userData, ACTION_TYPE.SCHEDULED_MEETING, `${userData.personali?.displayName} scheduled a new meeting.`, { location: evt.eventIdentifier, involvedPartyName: savedEvent.title })
                    Toasty.show("Event added", { type: "success" });
                    calendarHandler
                      .getTodaysPersonalCalendarEvents(
                        userData?.personalCalendars
                      )
                      .then((pEvents) => setPersonalCalendarEvents(pEvents))
                      .catch((err) => console.error(err));
                  }
                })
                .catch((error: string) => {
                  Toasty.show(error, { type: "danger" });
                  console.error(error);
                });
            }}
            icon={{ name: "plus" }}
          >
            {tr("Add Event")}
          </Buttoon>
          <Buttoon
            size="tiny"
            appearance="filled"
            status="info"
            onPress={openCalendarScreen}
            icon={{ name: "chevron_right", right: true }}
          >
            {tr("See full calendar")}
          </Buttoon>
        </Kitten.ViewH>
      </>
    );
  } catch (error) {
    throw Error(`::QlNIb21lU2NyZWVu::${error}`);
  }
}

const A = {
  ViewSection: sstyled(Kitten.Layout)(({ hovered }) => ({
    flex: 1,
    flexGrow: 1,
    backgroundColor: "transparent",
    margin: spacing(2),
    borderRadius: scale(9),
    overflow: "hidden",
  })),
  ImageBackground: sstyled(ImageBackground)({
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingVertical: spacing(2),
  }),
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "errorRed",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};
