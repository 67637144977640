// fuego.ts
// import {
//   API_KEY,
//   APP_ID,
//   AUTH_DOMAIN,
//   MEASUREMENT_ID,
//   MESSAGING_SENDER_ID,
//   PROJECT_ID,
//   STORAGE_BUCKET
// } from "@env";
import firebase from "firebase/app";

let API_KEY = "AIzaSyDnjf5WQXUu7d02BqkqXkvmlRl9HRSpdAw";
let AUTH_DOMAIN = "primr-exp.firebaseapp.com";
let PROJECT_ID = "primr-exp";
let STORAGE_BUCKET = "primr-exp.appspot.com";
let MESSAGING_SENDER_ID = "477292863857";
let APP_ID = "1:477292863857:web:80499a3a7bb1f2941896d0";
let MEASUREMENT_ID = "G-897STKMENH";

type Config = Parameters<typeof firebase.initializeApp>[0];

const firestoreSettings = {
  ignoreUndefinedProperties: true,
};

export class Fuego {
  public db: ReturnType<firebase.app.App["firestore"]>;
  public auth: typeof firebase.auth;
  public functions: typeof firebase.functions;
  public storage: typeof firebase.storage;
  constructor(config: Config) {
    console.log("🚀");
    console.log("firebase apps length ", firebase.apps.length);
    this.db = !firebase.apps.length
      ? (firebase.initializeApp(config),
        firebase
          .firestore()
          .settings({
            experimentalForceLongPolling: true,
            ...firestoreSettings,
          }),
        firebase.app().firestore())
      : (firebase
          .firestore()
          .settings({
            experimentalForceLongPolling: true,
            ...firestoreSettings,
          }),
        firebase.app().firestore());
    this.auth = firebase.auth;
    this.functions = firebase.functions;
    this.storage = firebase.storage;
  }
}
// console.log("API_KEY ", API_KEY);

export const firebaseConfig = {
  apiKey: `${API_KEY}`,
  authDomain: `${AUTH_DOMAIN}`,
  projectId: `${PROJECT_ID}`,
  storageBucket: `${STORAGE_BUCKET}`,
  messagingSenderId: `${MESSAGING_SENDER_ID}`,
  appId: `${APP_ID}`,
  measurementId: `${MEASUREMENT_ID}`,
};

export const fuego = new Fuego(firebaseConfig);
