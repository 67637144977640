import { Buttoon, Kitten, sstyled } from "components";
import { dTask } from "engines";
import React from "react";
import { useNavigator } from "screens";
import { spacing, tr } from "utils";
import { TaskListSection } from "./TaskListSection";
import { TodoCenter } from "./TodoCenter";

interface dTaskSection {
  onIncompleteTasks: (tasks: dTask[]) => void;
}

/**
 * ### Section of Tasks
 *  - COntacts
 *  ----
 *  @version 23.10.04
 *  -  *Brief changelog*
 *  @author  J
 *
 **/
export function TaskSection(props: dTaskSection) {
  const { onIncompleteTasks } = props;
  const Navigation = useNavigator();

  const openTaskScreen = () => {
    Navigation.overlayPortal.open({
      render: <TodoCenter />,
      headerTitle: "Tasks",
    });
  };

  return (
    <A.ViewContainer level="3">
      <TaskListSection onIncompleteTasks={onIncompleteTasks} />
      <Kitten.ViewH style={{ justifyContent: "flex-end", padding: spacing(3) }}>
        <Buttoon
          size="tiny"
          appearance="ghost"
          status="info"
          onPress={() => {
            openTaskScreen();
          }}
          icon={{ name: "plus" }}
        >
          {tr("Add Task")}
        </Buttoon>
        <Buttoon
          size="tiny"
          appearance="filled"
          status="info"
          onPress={openTaskScreen}
          icon={{ name: "chevron_right", right: true }}
        >
          {tr(`See all tasks`)}
        </Buttoon>
      </Kitten.ViewH>
    </A.ViewContainer>
  );
}

const A = {
  ViewContainer: sstyled(Kitten.Layout)(({ hovered }) => ({
    flex: 1,
    flexGrow: 1,
    borderRadius: 9,
  })),
};
