/**
 * Custom apis designed for specific clients to tell the app to function in a specific way for them
 */
export enum APIS {
  /** Apricot Solar power bills submitted */
  GO_HIGH_LEVEL_POWER_BILLS = "GO_HIGH_LEVEL_POWER_BILLS",
  /** Apricot Solar appointments set */
  GO_HIGH_LEVEL_APPOINTMENTS_SET = "GO_HIGH_LEVEL_APPOINTMENTS_SET",
  /** Apricot Solar sales made */
  GO_HIGH_LEVEL_SALES = "GO_HIGH_LEVEL_SALES",
}

/**
 * Api requirements for when the data is pulled and used in features throughout the app like Scoreboards
 */
interface API_CONFIGS_TYPE {
  [key: string]: {
    requiredFields?: string[];
    checkFieldsForDuplicates?: string[];
  };
}
export const API_CONFIGS: API_CONFIGS_TYPE = {
  [APIS.GO_HIGH_LEVEL_POWER_BILLS]: {
    requiredFields: ["{{ contact.electric_bills }}"],
    checkFieldsForDuplicates: ["{{ contact.ho2_email }}"],
  },
  [APIS.GO_HIGH_LEVEL_APPOINTMENTS_SET]: {},
};

API_CONFIGS[APIS.GO_HIGH_LEVEL_POWER_BILLS].requiredFields;

/**
 * Used to remove the {{ brackets }} and spaces in a customField for accurate comparison
 */
export function stripBrackets(str: string): string {
  return str && str.replace(/}/g, "").replace(/{/g, "").replace(/ /g, "");
}
