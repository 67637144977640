import { Toasty } from "components";
import sendErrorEmail from "engines/backends/apis/sendErrorMail";
import { Alert, AlertButton, AlertOptions, Platform } from "react-native";
import { tr } from "utils";

/**
 *  @example 
 *  PoppyCore(
      "Final Check",
      "Are you sure you want this poppy?",
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () => console.log("Poppy popped!"),
      }
    );
 *  ----
 **/
const PoppyCore = (
  title: string,
  desc: string,
  negativeButton: AlertButton = { text: "Cancel", onPress: () => {} },
  positiveButton: AlertButton = { text: "OK", onPress: () => {} },
  options: AlertOptions = {}
) => {
  switch (Platform.OS) {
    case "web":
      const res = window.confirm(`${title}\n${desc}`);
      if (res) positiveButton?.onPress();
      else negativeButton?.onPress();
      break;

    default:
      return Alert.alert(
        title,
        desc,
        [negativeButton, positiveButton],
        options
      );
      break;
  }
};

/**
 * ### Error Poppy
 *  - Recommended to use this to catch error from trycatch
 *  ----
 *  @example
 * const error={ message: "This is a test error", name: "Error" };
   Poppy.Error(error, "ERR-CODE", "Error label shown to user")
 *  ----
 *  @version 21.07.19
 *  -  *Move to Poppy*
 *  @author  K
 *
 **/
const Error = (
  e: Error, // { name: string; message: string; stack?: string; }
  errorCode: string,
  errorLabel: string,
  _token?: string
) => {
  console.log("ERR " + errorCode + ": ", e);

  function mailError() {
    console.log("mail error is running");
    const payload = {
      recipient: "drkhoi16@gmail.com",
      email_from: `Error <admin@apptakeoff.com>`,
      // email_reply_to: _email_reply_to,
      email_subject: "Primr error log",
      email_text_body: `${e.message}`,
      // Cc: "paulnanle611@gmail.com",
    };

    //* sendErrorEmail
    sendErrorEmail(payload, _token)
      .then((res) => {
        console.log("res ", res);
        Toasty.show(
          tr("🙏   Report sent, we'll cover it right away. Thank you")
        );
      })
      .catch((e) => {
        console.log(e);
        Toasty.show(tr("Error sending the report"), { type: "danger" });
      });
    console.log("mail error function");
  }
  return PoppyCore(
    "Error: " + errorLabel,
    "Do you want to report this?",
    {
      text: "Cancel",
      onPress: () => {},
    },
    {
      text: "Yes",
      onPress: () => {
        mailError();
      },
    }
  );
};
/**
 * ### Alert component for cross-platform
 *  - Using RN's Alert for android/ios, `window.confirm` on the web
 *  - On the web, buttons' texts are ALWAYS "Cancel", "OK"
 *  ----
 *  @example 
 *  Poppy(
      "Final Check",
      "Are you sure you want this poppy?",
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () => console.log("Poppy popped!"),
      }
    )
 *  ----
 *  @version 21.07.17
 *  -  *Build cross-platform*
 *  @author  K
 *  
 **/
export function Poppy(
  title: string,
  desc: string,
  negativeButton: AlertButton = { text: "Cancel", onPress: () => {} },
  positiveButton: AlertButton = { text: "OK", onPress: () => {} },
  options: AlertOptions = {}
) {
  return PoppyCore(title, desc, negativeButton, positiveButton, options);
}
Poppy.Error = Error;
