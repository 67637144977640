import { useIsFocused } from "@react-navigation/native";
import { Avt, Buttoon } from "components";
import {
  ConfigVariablesSchema,
  useAppContext,
  useConfigDocument
} from "engines";
import React, { useMemo } from "react";
import {
  Alert,
  Dimensions,
  Linking, View
} from "react-native";
import WebView from "react-native-webview";
import { IS_WEB, spacing } from "utils";

export function Flowtrack(p: { onScreenFocus: (focused: boolean) => void }) {
  const { onScreenFocus } = p;

  const { teamId } = useAppContext();
  const variablesHandler = useConfigDocument(`variables-${teamId}`);
  const variablesConfig = variablesHandler?.data as ConfigVariablesSchema;

  const isFocused = useIsFocused();

  React.useEffect(
    function didFocusScreen() {
      onScreenFocus && onScreenFocus(isFocused);
    },
    [isFocused]
  );

  // const { width, height } = useWindowDimensions();

  return useMemo(() => {
    return variablesConfig.crm?.type === "flowtrack" ? (
      // <View style={{ flex: 1 }}>
      <WebView
        key="flowtrack-webview"
        // onLoad={() => {
        //   setCurrentPageURL(item?.url);
        // }}
        onFileDownload={({ nativeEvent: { downloadUrl } }) => {
          Linking.openURL(downloadUrl);
        }}
        // pullToRefreshEnabled={true}
        allowFileAccessFromFileURLs={true}
        allowsLinkPreview={true}
        sharedCookiesEnabled={true}
        javaScriptEnabled
        allowFileAccess
        domStorageEnabled
        source={{
          uri: `https://app.flowtrack.co/admin/dashboard`,
        }}
        // onNavigationStateChange={(data) => setCurrentPageURL(data.url)}
        style={{
          flex: 1,
          // width,
          // height,
        }}
      />
    ) : (
      // </View>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: spacing(5),
          backgroundColor: "#101010",
        }}
      >
        <Avt
          size={
            IS_WEB
              ? Dimensions.get("window").height * 0.73
              : Dimensions.get("window").width
          }
          name="App Takeoff CRM"
          source={{
            uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1695699373/appcrm_hwijyi.png",
          }}
        />
        {/* <Image
        source={{
          uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1695699373/appcrm_hwijyi.png",
        }}
        style={{ height: 500 }}
      /> */}
        <Buttoon
          appearance="ghost"
          icon={{ right: true, name: "arrow_right" }}
          style={{ marginTop: spacing(5) }}
          onPress={() => {
            IS_WEB
              ? alert(
                  "App Takeoff offers a powerful CRM integration, to enable this feature contact us.\n\napptakeoff.com"
                )
              : Alert.alert(
                  "Contact Us",
                  "App Takeoff offers a powerful CRM integration, to enable this feature contact us.\n\napptakeoff.com"
                );
          }}
        >
          Learn More
        </Buttoon>
        {/* <Txt.S2>
        App Takeoff offers a powerful CRM integration, to enable this feature
        contact us.
      </Txt.S2> */}
      </View>
    );
  }, []);
}
