import firebase from "firebase"
import React from "react"
import { Kitten, ListHeader, Txt } from "components";
import { fn, FPATH, MediaPageItemSchema, useAppContext } from "engines";
import { ScrollView } from "react-native-gesture-handler";
import { BlastTemplateItem } from "components/molecules/custom-media/items/blast-template-item";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { IndexPath } from "@ui-kitten/components";
import { spacing, tr } from "utils";
import { Image, View } from "react-native";
import { ListItemv2 } from "components/atoms/externals/ui-kitten/list-item-v2";
import { IconPrimr } from "assets";

/**
 * Navigation route use "contact" to provide the contact and "onVideoSelect" for a listener when a video is selected
 */
export function TrackableVideosScreen(p: { route }) {
    const { teamId, C } = useAppContext()
    const { route } = p;
    //@ts-ignore when a text template gets selected to send
    const onVideoSelect = route?.params?.onVideoSelect as (item: MediaPageItemSchema) => void;
    const contact = route?.params?.contact as ContactSchemaa
    // const msgHistory = contact && contact.messageHistory ? contact.messageHistory : []
    // const msgedItemIds = msgHistory.map(m => m.itemId)

    // const userHandler = useUserProfile()
    // const textTemplateHandler = useTextTemplates(teamId)

    const [noneFound, setNoneFound] = React.useState<boolean>(false);
    const [trackableVideos, setTrackableVideos] = React.useState<MediaPageItemSchema[]>(null);
    // const [selectedIndex, setSelectedIndex] = React.useState<IndexPath>(new IndexPath(0))

    const refDidAttemptLoad = React.useRef<boolean>(false);
    React.useEffect(function loadVideos() {
        if (refDidAttemptLoad.current === true) return;
        refDidAttemptLoad.current = true
        firebase.firestore().collectionGroup(FPATH.CUSTOM_PAGE_CONTENT).where("_teamId", "==", teamId).where("trackable", "==", true).get().then(query => {
            if (!query.docs || query.docs.length < 1) {
                setNoneFound(true)
                return;
            }
            const _trackableVideoItems: MediaPageItemSchema[] = query?.docs?.map(d => d.data() as MediaPageItemSchema)
            setTrackableVideos(_trackableVideoItems)
        })
    }, []);

    function _onVideoSelect(item: MediaPageItemSchema) {
        onVideoSelect && onVideoSelect(item)
    }

    return <ScrollView style={{ flex: 1 }}>
        <ListHeader titleTx="Trackable Videos" />
        {trackableVideos ? trackableVideos.map(tv => <TrackableVideoListing item={tv} onPress={_onVideoSelect} />) : noneFound ? <Txt.P1 style={{ textAlign: "center", marginVertical: spacing(5) }}>{tr("No trackable videos uploaded")}</Txt.P1> : <Kitten.Spinner />}
    </ScrollView>
}

function TrackableVideoListing(p: { item: MediaPageItemSchema, onPress: (item: MediaPageItemSchema) => void }) {
    const { item, onPress } = p;
    const { C } = useAppContext()
    const height = 83
    return <ListItemv2
        style={{ height, paddingLeft: 0, borderRadius: 8 }}
        onPress={() => onPress(item)}
        title={() => <Txt.S1>{item.title}</Txt.S1>}
        accessoryLeft={() => <Image source={{ uri: item?.thumbnail || fn.media.getThumbnailFromURL(item?.media) }} style={{ width: height, height }} />}
        accessoryRight={() => <IconPrimr name="send" color={C.primary} />}
    />
}