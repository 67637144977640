import { UserSchema } from "engines";
import { FRBS_ROLE } from "engines/firebase";
import { dUseDocumentReturns } from "engines/firebase/config";

/**
 * Generate unique IDs for use as pseudo-private/protected names.
 * @see https://gist.github.com/gordonbrander/2230317
 * @version 0.7.1
 */
export function ID() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
}

/**
 * Assuming Date "zero" (aka ISOString == "1970-01-01T00") is default `null-date`.
 *
 * This function checks if given date is `"null-date"` or not
 * @version 0.7.1
 */
export function isDateZero(date: Date) {
  return date && date.toISOString().includes(`1970-01-01T00`);
}

/**
 * Format Time from Date() to human readable format
 * @version 0.7.1
 */
export function formatTime(date: Date, showSeconds: boolean) {
  const formattedTime = showSeconds
    ? [date.getHours(), date.getMinutes(), date.getSeconds()]
        .map((num) => `0${num}`.slice(-2))
        .join(":")
    : [date.getHours(), date.getMinutes()]
        .map((num) => `0${num}`.slice(-2))
        .join(":");

  return formattedTime;
}

/**
 * Capitalize string
 * @version 1.6.17
 * NOTE: need tested
 */
export const capitalize = (string: string) => {
  return !!string && string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * ### Is U admin, based on userProfile
 *  ----
 *  @example
 *  let isAdmin = userProfile._isUserAdmin()
 *  ----
 *  @version 21.06.21
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export const isAdmin = (userProfile: dUseDocumentReturns<UserSchema>) => {
  return userProfile.data?.roles?.includes(FRBS_ROLE.ADMIN);
};

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * ###  Check if url contains https or not.
 * - If not, add https:// at the beginning of url
 * @param url
 * @returns
 */
export function validateWebUrl(url: string) {
  let validUrl = new RegExp(/^https?:\/\//);
  if (!url) return "";
  if (!validUrl.test(url)) {
    let httpsLink = "https://" + url;
    return httpsLink;
  } else {
    return url;
  }
}

/**
 * Truncate a string if it is longer than the specified number of characters.
 * - Truncated strings will end with a translatable ellipsis sequence ("…") (by default)
 * or specified characters.
 * @param str
 * @param length
 * @param ending
 * @returns
 */
export function textTruncate(
  str: string,
  length: number = 100,
  ending: string = "..."
) {
  if (str.length > length) {
    return str.substring(0, length - String(ending).length) + ending;
  } else {
    return str;
  }
}

export function extractString([beg, end]: [string, string]) {
  const matcher = new RegExp(`${beg}(.*?)${end}`, "gm");
  const normalise = (str: string) =>
    String(str).slice(beg.length, end.length * -1);
  return function (str: string) {
    return String(str).match(matcher).map(normalise);
  };
}

export function isInputNumeric(text: string) {
  return /^\d+$/.test(text);
}

export function isInputPhoneNum(text: string) {
  let validPhoneNum = new RegExp(
    /^\(?([\d]{3})\)?[-. ]?([\d]{3})[-. ]?([\d]{4})+$/
  );
  return validPhoneNum.test(text);
}
export function isValidUrl(urlString: string) {
  try {
    let _urlwHttps = new RegExp(/^https?:\/\//);
    let _urlString = !_urlwHttps.test(urlString)
      ? "https://" + urlString
      : urlString;

    return Boolean(new URL(_urlString));
  } catch (e) {
    return false;
  }
}
