import { StackNavigationOptions } from "@react-navigation/stack";
import { ICON_NAME } from "assets";
import { MenuIcon } from "assets/icons/MenuIcon";
import { Buttoon, dAccessory, Kitten, Txt } from "components";
import { useTwoColumns } from "components/templates";
import { useAppContext } from "engines";
import { useCollabContext } from "engines/providers/collaboration-provider";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";

export enum COPY_CONTENT_TYPE {
  SELECT = "SELECT",
  COPY = "COPY",
}

const creatorOptions: dCreatorOption[] = [
  {
    key: COPY_CONTENT_TYPE.SELECT,
    label: tr("Select Content"),
    icon: "check",
  },
  // {
  //   key: COPY_CONTENT_TYPE.COPY,
  //   label: tr("Copy Content To..."),
  //   icon: "copy",
  // },
];

export function CopyCollaborationContentMenu(props: P) {
  const { cpid, title } = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const {
    isSelectModeOn,
    setIsSelectModeOn,
    pageContentItems,
    setPageContentItems,
    copyToPageId,
    setCopyToPageId,
    copyFromPageId,
    selectAll,
    setSelectAll,
    setIsCollabModeOn,
  } = useCollabContext();

  const { breakpointForTwoColumns } = useTwoColumns();

  React.useEffect(function triggering() {
    !breakpointForTwoColumns && (handleSelectMode(), Navigation.goBack());
  }, []);

  // #region [ANCHOR2] Enforcing Collab Mode to stay true even despite the status of select Mode
  React.useEffect(() => {
    setIsCollabModeOn(true);
  }, []);
  // #endregion

  React.useEffect(() => {
    //NOTE When select Mode is on display the Wide Green Border on the top along
    //     with the indication of number of items selected
    if (isSelectModeOn) {
      Navigation.setOptions({
        headerTitle: () => (
          <Txt.H6
            style={{
              color: C["text-alternate-color"],
              backgroundColor: C.primaryDarker,
            }}
          >
            {`${pageContentItems?.length ? pageContentItems.length : 0} ${
              pageContentItems?.length === 1 ? "item" : "items"
            } selected`}
          </Txt.H6>
        ),
        headerRight: () => null,
      } as StackNavigationOptions);
    } else {
      Navigation.setOptions({
        headerTitle: title,
        headerStyle: {
          backgroundColor: C.background,
          borderColor: C.background,
          elevation: 0, // remove shadow on Android
          shadowOpacity: 0, // remove shadow on iOS
          borderBottomWidth: 0, // Just in case.
        },
        headerLeft: () => (
          <Buttoon
            style={{ borderRadius: 69 }}
            size={"large"}
            icon={{ name: "arrow_left" }}
            appearance={"ghost"}
            onPress={() => Navigation.goBack()}
          />
        ),
        headerRight: () => (
          <Buttoon
            size={"small"}
            appearance={"ghost"}
            onPress={() => Navigation.navigate("Home")}
            icon={{ name: "home" }}
          >
            {tr("Home")}
          </Buttoon>
        ),
      } as StackNavigationOptions);
    }
  }, [pageContentItems, isSelectModeOn, selectAll]);

  React.useEffect(() => {
    // console.log(`Copying to pageId ${copyToPageId}`);
    setCopyToPageId(() => copyToPageId);
  }, [copyToPageId]);

  const handleSelectMode = () => {
    if (isSelectModeOn) {
      setIsSelectModeOn(false);
      setPageContentItems([]);
      setSelectAll(false);
      setCopyToPageId("");
    } else {
      setIsSelectModeOn(true);
      Navigation.setOptions({
        headerTitle: () => (
          <Txt.H6 style={{ color: C["text-alternate-color"] }}>
            {`${
              pageContentItems?.length ? pageContentItems.length : 0
            } items selected`}
          </Txt.H6>
        ),
        headerStyle: {
          backgroundColor: C["color-primary-600"],
          opacity: 0.8,
          elevation: 0, // remove shadow on Android
          shadowOpacity: 0, // remove shadow on iOS
          borderBottomWidth: 0, // Just in case.
        },
        headerLeft: () => (
          <Buttoon
            style={{ borderRadius: 69 }}
            size={"large"}
            icon={{ name: "admin", color: C["text-alternate-color"] }}
            appearance={"ghost"}
            onPress={() => {
              setIsSelectModeOn(false);
              setPageContentItems([]);
              setSelectAll(false);
              setCopyToPageId("");
            }}
          />
        ),
      } as StackNavigationOptions);
    }
  };

  return React.useMemo(() => {
    try {
      if (!cpid) return null;

      return breakpointForTwoColumns ? (
        <Kitten.Menu>
          {creatorOptions.map((option) => (
            // option.key === COPY_CONTENT_TYPE.SELECT ?
            <Kitten.MenuItem
              key={option.key}
              title={
                option.key === COPY_CONTENT_TYPE.SELECT && isSelectModeOn
                  ? "Cancel"
                  : option.label
              }
              accessoryLeft={(props: dAccessory) =>
                MenuIcon(
                  option.key === COPY_CONTENT_TYPE.SELECT && isSelectModeOn
                    ? "x"
                    : option.icon,
                  props
                )
              }
              onPress={handleSelectMode}
            />
          ))}
        </Kitten.Menu>
      ) : (
        <View />
      );
    } catch (error) {
      throw Error("::TGlua1Rv611Axa::" + error);
    }
  }, [isSelectModeOn, pageContentItems, copyToPageId]);
}

interface dCreatorOption {
  key: COPY_CONTENT_TYPE;
  label: string;
  icon: ICON_NAME;
}
interface P {
  itemOption?: dCreatorOption;
  cpid: string;
  title?: string;
}
