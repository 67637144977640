import { IconPrimr } from "assets";
import { Skeleton } from "components/atoms";
import { AudioItemFS } from "components/molecules/custom-media/items/audio-item-fullscreen";
import { useAppContext } from "engines";
import { useAudioPlayerContext } from "engines/providers/audio-provider";
import * as React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import TextTicker from "react-native-text-ticker";
import { spacing } from "utils";
import {
  useNavigator,
  useNavigatorPresetConfig,
} from "../_navigation/navigation-utils";

export function AudioPlayerScreen() {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const { overlayStackConfig } = useNavigatorPresetConfig();
  const inset = useSafeAreaInsets();
  const [ready, setReady] = React.useState<boolean>(false);
  const { audioItem, setShowPlayer, isPlaying, setIsFullScreen } =
    useAudioPlayerContext();

  React.useLayoutEffect(
    function layouting() {
      Navigation.setOptions({
        ...overlayStackConfig.screenOptions,
        headerTitle: () => (
          <TextTicker
            style={[styles.AudioPlayerScreenStyle, { color: C.text }]}
            duration={6000}
            loop
            bounce={false}
            shouldAnimateTreshold={2}
            repeatSpacer={50}
            marqueeDelay={2000}
          >
            {audioItem?.title}
          </TextTicker>
        ),
        headerLeft: (props) => (
          <IconPrimr
            preset={"circular"}
            name={"arrow_down"}
            color={C.primary}
            containerStyle={{ marginLeft: spacing(2) }}
            onPress={() => {
              Navigation.goBack();
              setIsFullScreen(false);
              setShowPlayer(true);
            }}
          />
        ),
      });
      setTimeout(() => {
        setReady(true);
      }, 600);
    },
    [Navigation]
  );

  return !ready ? (
    <View style={{ height: "100%" }}>
      <Skeleton
        layout={[
          { key: "text-skltn", width: 220, height: 20, marginBottom: 6 },
        ]}
      />
    </View>
  ) : (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        borderWidth: 0,
        borderRadius: 0,
        paddingBottom: inset.bottom + spacing(4),
      }}
    >
      {/* <PortalHost name="overlay" key="overlay-portal" /> */}
      <AudioItemFS item={audioItem} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  AudioPlayerScreenStyle: {
    fontSize: 20,
    marginTop: 10,
    fontWeight: "800",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
