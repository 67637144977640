import { Buttoon, Txt } from "components";
import { useAppContext } from "engines";
import * as React from "react";
import { Linking, View } from "react-native";
import WebView from "react-native-webview";
import { useNavigator } from "screens";
import { scale, spacing, verticalScale } from "utils";

/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.02.21
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function Browsers(props: P) {
  console.log("itemurl ", props);
  const { route } = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const [currentPageURL, setCurrentPageURL] = React.useState<any>();
  const [source, setSource] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>();
  //#endregion

  //#region [functions]

  //#endregion
  const headerHeight = verticalScale(65);

  const { itemurl } = route?.params;
  //   console.log("browswer item ", itemurl.);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: C.background01,
      }}
    >
      <View
        style={{
          backgroundColor: C.background01,
          height: headerHeight,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Buttoon
          onPress={() => Linking.openURL(currentPageURL)}
          icon={{ name: "arrow_right", right: true, size: scale(12) }}
          appearance="ghost"
          style={{
            marginTop: -spacing(1),
          }}
        >
          <Txt.P2
            style={{
              color: C.primary,
            }}
          >
            Open in Browser
          </Txt.P2>
        </Buttoon>
      </View>

      <WebView
        // onLoad={() => {
        //   setCurrentPageURL(item?.url);
        // }}
        onFileDownload={({ nativeEvent: { downloadUrl } }) => {
          Linking.openURL(
            `https://drive.google.com/viewerng/viewer?embedded=false&url=${itemurl}`
          );
        }}
        pullToRefreshEnabled={true}
        allowFileAccessFromFileURLs={true}
        allowsLinkPreview={true}
        sharedCookiesEnabled={true}
        javaScriptEnabled
        allowFileAccess
        domStorageEnabled
        source={{
          uri: `https://drive.google.com/viewerng/viewer?embedded=true&url=${itemurl}`,
        }}
        onNavigationStateChange={(data) => setCurrentPageURL(data.url)}
        style={{
          flex: 1,
        }}
      />

      {loading ? (
        <View
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <Txt
            style={{
              left: "-50%",
              fontSize: 22,
              color: "rgb(161, 157, 156)",
            }}
          >
            Loading...
          </Txt>
        </View>
      ) : null}
    </View>
  );
}

interface P {
  route?: { itemurl?: string }; //* unmask if we pass params thru rnav
}
