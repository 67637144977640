import { IconPrimr } from "assets";
import { Kitten, sstyled, Txt } from "components";
import {
  FRBS_ROLE, useAppContext, useUserProfile
} from "engines";
import {
  useFollowUpContacts
} from "engines/firebase/handler/contact-handler";
import React from "react";
import { View } from "react-native";
import { Typewriter, useNavigator } from "screens";
import { scale, spacing } from "utils";
import { ViewUserCorner } from "../drawer/NavigationBar";

interface dProfileCorner {}

/**
 * ### Menu in UserStatsFeed about Profile
 *  ----
 *  @version 23.10.04
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function ProfileCorner(props: dProfileCorner) {
  const {} = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  //#region [section] Contact Manager
  const { frbsUser } = useAppContext();
  const followUpContactsHandler = useFollowUpContacts(frbsUser?.uid);
  const followUpContacts = followUpContactsHandler.data;
  //#endregion
  //#region [section2] Teammates Trainee
  // const trainees = useMembersCollection({
  //   where: ["growth.listBuilder.shareTo", "array-contains", frbsUser?.uid],
  // });
  //#endregion
  return (
    <>
      <Kitten.ViewH style={{ flex: 1 }}>
        <View style={{ flex: 1, alignItems: "flex-start" }}>
          <ViewUserCorner showInfo={true} />
        </View>
        <IconPrimr
          size={scale(19)}
          name="cog"
          preset="circular"
          color={C.text01}
          onPress={() => Navigation.navigate("Profile")}
        />
        {userProfile._isRolesContain([
          FRBS_ROLE.ADMIN,
          FRBS_ROLE.DEVELOPER,
        ]) && (
          <IconPrimr
            size={scale(19)}
            name="admin"
            preset="circular"
            color={C.adminGold}
            onPress={() => Navigation.navigate("Admin Portal")}
          />
        )}
      </Kitten.ViewH>
      <Typewriter
        style={{
          fontStyle: "italic",
          textAlign: "center",
          marginVertical: spacing(3),
        }}
        text={`You have ${
          followUpContacts.length || "-"
        } people to follow up with,\n1 appointment at 5pm,\nand 4 tasks on your todo list for today.`}
        delay={100}
      />
      {/* <Txt.S1
        style={{
          fontStyle: "italic",
          textAlign: "center",
          marginVertical: spacing(3),
        }}
      >
        You have {followUpContacts.length || "-"} people to follow up with, 1
        appointment at 5pm, and 4 tasks on your todo list for today.
      </Txt.S1> */}
      {/* <Kitten.Menu style={{ backgroundColor: "transparent" }}>
        <Kitten.MenuItem
          style={{ backgroundColor: "transparent" }}
          title={tr("Teammates")}
          onPress={() => {
            Navigation.dialogPortal.open({
              headerTitle: tr("Create Announcement"),
              render: <PosstCreatorCenter />,
            });
          }}
          accessoryLeft={(props: dAccessory) => MenuIcon("users", props)}
          accessoryRight={(props: dAccessory) => (
            <A.ChipIndicator style={{ backgroundColor: C.infoBlue }}>
              {trainees?.data?.length || "-"}
              {" " + tr("Trainees")}
            </A.ChipIndicator>
          )}
        />
      </Kitten.Menu> */}
    </>
  );
}

const A = {
  ChipIndicator: sstyled(Txt.C1)({
    color: "text01",
    backgroundColor: "infoBlue",
    borderRadius: 12,
    paddingHorizontal: spacing(3),
    paddingVertical: spacing(1),
  }),
};
