import { IconPrimr } from "assets";
import { Inpuut, ListHeader, Toasty } from "components";
import { MediaPageSchema, useAppContext } from "engines";
import { dUseDocumentReturns } from "engines/firebase/config";
import React from "react";
import { TextInput, View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";

export const S_PageItemAddPassword = ({ pageHandler }: P) => {
  //#region [FRBS] page contents handler

  //#endregion
  const Navigation = useNavigator();
  const { C } = useAppContext();
  const refInput = React.useRef<TextInput>();
  const [passwordInput, setPassword] = React.useState<any>();

  //#region [FRBS] page contents handler
  function addPasswordTeam() {
    pageHandler.update({
      password: passwordInput,
    });

    Toasty.show(tr("Password has been set"), { type: "success" });
    Navigation.goBack();
  }
  //#endregion
  React.useEffect(function inputFocus() {
    refInput.current?.focus();
  }, []);
  return (
    <View>
      <ListHeader
        title={tr("Input password")}
        accessoryLeft={() => <IconPrimr name={"lock"} color={C.text} />}
      />
      <View>
        <Inpuut
          ref={refInput}
          placeholder={tr("Enter password")}
          autoFocus
          value={passwordInput}
          onChangeText={setPassword}
          doneText={tr("Enter")}
          onSavePress={() => {
            addPasswordTeam();
          }}
        />
      </View>
    </View>
  );
};

interface P {
  pageHandler?: dUseDocumentReturns<MediaPageSchema>;
}
