//#region [import]
import { StackActions } from "@react-navigation/native";
import { MenuIcon } from "assets/icons/MenuIcon";
import {
  Avt,
  Buttoon,
  Kitten,
  M,
  O,
  PageItemsCreatorMenu,
  Poppy,
  sstyled,
  Toasty,
  Txt
} from "components";
import { PagePasswordCenter } from "components/molecules/custom-media/PagePasswordCenter";
import { CustomPageBody, ViewTeamLogo } from "components/organisms";
import { TwoColumns, useTwoColumns } from "components/templates";
import {
  FRBS_ROLE,
  MediaPageItemSchema,
  NotificationStatus,
  useAppContext
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { usePageDocument } from "engines/firebase/handler/pages-handler";
import _ from "lodash";
import React from "react";
import { Linking, Platform, TextInput, View } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { useNavigator } from "screens";
import { dPageContentsScreenParam } from "screens/_navigation/pages.navigator";
import { TelegramChats } from "screens/_navigation/tests/TelegramChats";
import { IS_WEB, scale, spacing, tr } from "utils";
import { ass, ASS_KEY } from "utils/storage";
import { PageRequirmentCenter } from "../../components/molecules/custom-media/PageRequirementCenter";
import { useCollabContext } from "../../engines/providers/collaboration-provider";
import { LeaveBaseShop } from "./leave-base-shop";
import { PageInfo } from "./PageInfo";
import { ShareContents } from "./share-contents";
import { ShareContentsPressable } from "./share-contents-pressable";
//#endregion

/**
 * ### A quick screen for a quick start
 *
 * ----
 * @example As is
 * ----
 * @version 1.1.19
 * -  *Add fool-proof try-catch*
 * @author nguyenkhooi
 */
// export function PageBodyScreenBeta(props: P) {
//   const { route } = props;
//   const { data: pageD, ...handlerPage } = usePageDocument(route?.params?.cpid);

//   switch (handlerPage._isBaseShopPage()) {
//     case true:
//       return <PageBSScreen />;
//       break;
//     case false:
//       return <View />;
//     default:
//       return <View />;
//       break;
//   }
// }

export function PageBodyScreen(props: P) {
  const { route } = props;
  const { C } = useAppContext();
  const {
    isCollabModeOn,
    isSelectModeOn,
    copyFromPageId,
    setCopyFromPageId,
    copyToPageId,
    setIsCollabModeOn,
    onlyShowUncompletedContents,
  } = useCollabContext();
  const Navigation = useNavigator();
  const { data: userD, ...userProfile } = useUserProfile();
  const handlerPage = usePageDocument(route?.params?.cpid);

  const isUserInThisTeamId = () => userD?._teamId === handlerPage.data?._teamId || handlerPage.data?.teamIdSharers?.includes(userD?._teamId);

  const [showContents, setshowContents] = React.useState<boolean>(false);
  const [isSelectShareModeOn, setisSelectShareModeOn] =
    React.useState<boolean>(false);
  const [isShareContents, setIsShareContents] = React.useState<
    MediaPageItemSchema[]
  >([]);

  const refDidGoTo = React.useRef<boolean>(false);
  if (route.params?.thenGoTo && !refDidGoTo.current) {
    refDidGoTo.current = true;
    Navigation.dispatch(
      StackActions.push("Page Contents", {
        cpid: route.params?.thenGoTo,
        // collabMode: isCollabModeOn,
        // buttonItem: item,
        previousCpid: route.params?.cpid,
      })
    );
  }

  // prompt user if they want to become a member of this base shop or just visit it
  const refDidAskToJoinBaseShop = React.useRef<boolean>(false);
  async function askToJoinBaseShop() {
    const isInBaseShopAlready =
      userD?.baseShopId && userD?.baseShopId?.length > 0;

    if (refDidAskToJoinBaseShop.current) return;
    refDidAskToJoinBaseShop.current = true;

    Poppy(
      `Request to join ${handlerPage.data?.name ? handlerPage.data?.name : ""}`,
      `Would you like to join this Base Shop?${isInBaseShopAlready
        ? "\n\n(You will no longer be a member of your current team)"
        : ""
      }`,
      {
        text: isInBaseShopAlready ? "Just visiting" : "No, thanks",
        onPress: () => { },
      },
      {
        text: isInBaseShopAlready ? "Switch team" : "Join!",
        onPress: async () => {
          await userProfile.update({ baseShopId: handlerPage.data?._id });
          Toasty.show(`You are now a member of ${handlerPage.data?.name}!`);
        },
      }
    );
  }

  // const refHasBeenAskedToJoin = React.useRef<boolean>(false);
  React.useEffect(
    function joinBaseShopIfNeeded() {
      // if (refHasBeenAskedToJoin.current)
      // || route?.params?.diableBaseShopPrompt
      // return;
      // refHasBeenAskedToJoin.current = true;
      if (
        handlerPage._isBaseShopPage() &&
        handlerPage.data?._id !== userD?.baseShopId &&
        !(
          handlerPage.data?.password &&
          (handlerPage.data?.password as string)?.length > 0
        )
      )
        askToJoinBaseShop();
      // if (
      //   handlerPage._isBaseShopPage() &&
      //   (!userD?.baseShopId || userD?.baseShopId?.length < 1)
      // ) {
      //   askToJoinBaseShop();
      // }
    },
    [userD, handlerPage.data]
  );

  //#region [navigation]
  React.useEffect(
    /** Update header with page title or default value */
    function navigationSetup() {
      !isSelectModeOn &&
        handlerPage.data &&
        Navigation.setOptions({
          title: handlerPage._isBaseShopPage()
            ? handlerPage.data?.name
              ? handlerPage.data?.name
              : "Base Shop"
            : handlerPage.data?.name + " - Page" || "Page Contents",
          headerTitle: handlerPage._isBaseShopPage()
            ? () => <ViewTeamLogo />
            : handlerPage.data?.name || "Page Contents",
        });
    },
    [Navigation, isSelectModeOn]
  );
  //#endregion

  //#region [section] BS-RELATED FUNCTIONS
  const [pageType, setPageType] = React.useState<
    | "passwordScreen"
    | "requirementScreen"
    | "bsScreen"
    | "pages"
    | "inaccessible"
    | null
  >(null);

  React.useEffect(() => {
    if (!copyFromPageId) {
      setCopyFromPageId(route?.params?.cpid);
    }
    // console.log(`🤖 Copying from Page Id ${copyFromPageId}`);
  }, [copyFromPageId]);

  //Below we check if the bs has been joined previously
  function hasAccessToPage() {
    return (
      userD?.baseShopId === route?.params?.cpid ||
      userD?.unlockedPages?.includes(route?.params?.cpid) ||
      userProfile._isRolesContain([
        FRBS_ROLE.DEVELOPER,
        FRBS_ROLE.ADMIN,
        `BS:${route?.params?.cpid}`,
      ])
    );
  }
  React.useEffect(() => {
    // declare the data fetching function
    const checkAccessibility = async () => {
      if (
        hasAccessToPage() ||
        (!handlerPage?.data?.password && !handlerPage.data?.accessRequirements)
      ) {
        closePopup();
      } else {
        showPopup();
      }

      // if (
      //   "debug" === "nah" ||
      //   isUserInThisTeamId() ||
      //   userProfile._isRolesContain([FRBS_ROLE.DEVELOPER])
      // ) {
      //   if (handlerPage._isBaseShopPage()) {
      //     if (
      //       userD?.baseShopId == handlerPage?.data?._id &&
      //       userD?.baseShopId?.length > 0
      //     ) {
      //       // handlerPage?.data?.password ? showPopup() : closePopup();
      //       handlerPage?.data && setshowContents(true);
      //       handlerPage?.data && setPageType("bsScreen");
      //     } else {
      //       handlerPage?.data?.password || handlerPage?.data?.accessRequirements
      //         ? showPopup()
      //         : closePopup();
      //     }
      //   } else {
      //     console.log(
      //       "check if pages has password",
      //       handlerPage?.data?.password
      //     );
      //     if (
      //       handlerPage?.data?.password ||
      //       handlerPage?.data?.accessRequirements
      //     )
      //       showPopup();
      //     else closePopup();
      //   }
      // } else {
      //   // alert(handlerPage.data?._teamId + " vs " + userD._teamId);
      //   // handlerPage?.data?.password || handlerPage?.data?.accessRequirements
      //   //   ? showPopup()
      //   //   : closePopup();
      //   //* If user is NOT in this Team Id, make the page inaccessible for them
      //   // removed because what is this even accomplishing?
      //   // Toasty.show("This is not your team.", { type: "warning" });
      //   // Navigation.goBack();
      //   // setPageType("inaccessible");
      // }
    };

    // call the function
    checkAccessibility()
      // make sure to catch any error
      .catch(console.error);
  }, [route?.params, handlerPage?.data, userD]);
  //#endregion

  //#region [section 2] For Collaboration Mode
  React.useEffect(() => {
    // console.log("route in ResourcesBodyScreen", route);
    // console.log("isCollabModeOn in ResourcesBodyScreen ", isCollabModeOn);
    if (route?.params?.title === "Search the Multiverse 🌎") {
      setIsCollabModeOn(true);
    }
  }, [route?.params]);
  //#endregion

  React.useEffect(
    function () {
      showContents == true ? closePopup() : showPopup();
    },
    [showContents]
  );

  //#region [section2] PASSWORD-RELATED FUNCTIONS
  function showPopup() {
    // console.log(" showContents && setshowContents(false);");
    //todo refactor to showPasswordDialog
    // console.log("userD ", userD);
    if (hasAccessToPage()) {
      console.log("showContents ", showContents);
      setshowContents(true);

      if (handlerPage?._isBaseShopPage()) {
        setPageType("bsScreen");
      } else {
        setPageType("pages");
      }
    } else {
      if (handlerPage?.data?.password) {
        setshowContents(false);
        setPageType("passwordScreen");
      } else if (handlerPage?.data?.accessRequirements) {
        setshowContents(false);
        setPageType("requirementScreen");
      } else if (handlerPage.data) {
        setshowContents(true);
        if (handlerPage?._isBaseShopPage()) {
          setPageType("bsScreen");
        } else {
          setPageType("pages");
        }
      }
    }

    //This checks the role of the user if the user is a developer if yes it allows the user access pages with out password
  }

  /**
   * This function also is used to join the BS
   */
  function closePopup() {
    //todo refactor to closePasswordDialog
    setshowContents(true);

    if (handlerPage._isBaseShopPage()) {
      //todo "true" string case
      //this ensures that the user is joining a baseshop not a custom page
      let storingBSID = ass.save(ASS_KEY.BSIDINFO, handlerPage?.data);
      if (storingBSID && userD?.baseShopId !== handlerPage?.data?._id) {
        askToJoinBaseShop();
      }
      setshowContents(true);
      setPageType("bsScreen");
    } else {
      setPageType("pages");
      setshowContents(true);
      setPageType("pages");
    }
  }
  //#endregion

  const [shouldEditContent, setShouldEditContent] =
    React.useState<boolean>(false);

  try {
    if (!route?.params?.cpid) {
      // console.warn("No cpid found");
      return <ContentNotFound />;
    }

    switch (pageType) {
      case "inaccessible":
        return <ContentNotFound />;
        break;
      case "passwordScreen":
        return (
          <A.Ctnr>
            <PagePasswordCenter
              password={handlerPage?.data?.password}
              item={handlerPage?.data}
              setshowContents={(val) => {
                // if (
                //   val &&
                //   pageType === "passwordScreen" &&
                //   handlerPage?.data?.mediaItem?.team === true
                // ) {
                //   askToJoinBaseShop();
                // }
                setshowContents(val);
              }}
            />
          </A.Ctnr>
        );
        break;
      case "requirementScreen":
        return (
          <A.Ctnr>
            <PageRequirmentCenter
              page={handlerPage.data}
              setshowContents={setshowContents}
            />
          </A.Ctnr>
        );

      default:
        return (
          <TwoColumns
            bottomInset={
              //* DOn't apply if it's a base shop
              !handlerPage._isBaseShopPage()
            }
            column1Content={(scrollHandler) => (
              <CustomPageBody
                isLevel={route.params?.isLevel}
                scrollHandler={scrollHandler}
                shouldEditContent={shouldEditContent}
                pageHandler={handlerPage}
                cpid={route?.params?.cpid}
                highlightItem={route?.params?.itemId}
                buttonItem={route?.params?.buttonItem}
                previousPageId={route?.params?.previousCpid}
                selectModeOn={isSelectModeOn}
                collabMode={
                  route?.params?.title === "Search the Multiverse 🌎" ||
                  route?.params?.collabMode
                }
                isSelectShareModeOn={isSelectShareModeOn}
                setisSelectShareModeOn={setisSelectShareModeOn}
                setIsShareContents={setIsShareContents}
                isShareContents={isShareContents}
              />
              // {!showContents ? null : (
              //   <CustomPageBody
              //     title={route?.params?.title}
              //     pageHandler={handlerPage}
              //     cpid={route?.params?.cpid}
              //     selectModeOn={isSelectModeOn}
              //     collabMode={
              //       route?.params?.title === "Search the Multiverse 🌎" ||
              //       route?.params?.collabMode
              //     }
              //   />
              // )}
            )}
            column2Content={
              //#region [section] Collaboration Menu
              isCollabModeOn && route?.params?.collabMode
                ? [
                  {
                    headerTitle: tr("Select Content"),
                    render: () => (
                      <M.CopyCollaborationContentMenu
                        cpid={route?.params?.cpid}
                        title={route?.params?.title}
                      />
                    ),
                    icon: "check",
                    showTitle: true,
                    hiddenIf: [
                      !userProfile._isRolesContain([
                        FRBS_ROLE.ADMIN,
                        `BS:${copyToPageId}`,
                      ]),
                    ],
                  },
                  (userProfile._isUserAdmin() ||
                    userD?.roles?.includes(`BS:${copyToPageId}`)) && {
                    headerTitle: tr("Copy page content"),
                    render: () => (
                      <M.CollaborationCopyCenter
                        cpid={route?.params?.cpid}
                        title={route?.params?.title}
                      />
                    ),
                    icon: "copy",
                    showTitle: true,
                  },
                ]
                : //#endregion
                //#region [section2] normal menu
                [
                  {
                    icon: (
                      <View style={{ marginHorizontal: spacing(3) }}>
                        <AnimatedCircularProgress
                          size={40}
                          width={5}
                          fill={0.4269 * 100}
                          rotation={0}
                          tintColor={C.primary}
                          backgroundColor={C.primaryDarker}
                        // style={{ padding: spacing(2) }}
                        >
                          {() => (
                            <Avt
                              name={handlerPage.data?.name || ""}
                              source={{
                                uri: handlerPage.data?.mediaItem?.logo || "",
                              }}
                              size="tiny"
                              shape="round"
                            />
                          )}
                        </AnimatedCircularProgress>
                      </View>
                    ),
                    // headerTitle: String(
                    //   handlerPage.data?.name
                    //     ? onlyShowUncompletedContents
                    //       ? handlerPage.data?.name + " (*)"
                    //       : handlerPage.data?.name
                    //     : tr("Untitled")
                    // ).toUpperCase(),
                    headerTitle: onlyShowUncompletedContents ? " (*)" : "",
                    render: () => (
                      <PageInfo
                        cpid={route?.params?.cpid}
                        previousPageId={route?.params?.previousCpid}
                        buttonItem={route?.params?.buttonItem}
                        isUserInThisTeamId={isUserInThisTeamId()}
                      />
                    ),
                    showTitle: false,
                    hiddenIf: [
                      shouldEditContent,
                      isSelectShareModeOn,
                      !userD,
                      // !isUserInThisTeamId,
                    ],
                  },
                  {
                    headerTitle: tr("Team Chat"),
                    showTitle: false,
                    onPress: () => {
                      Navigation.navigate("chat");
                    },
                    render: () =>
                      IS_WEB ? (
                        <Buttoon
                          onPress={() =>
                            Linking.openURL("https://web.telegram.org")
                          }
                          appearance="filled"
                          status="basic"
                          icon={{ name: "chat_bubble" }}
                        >
                          Open Team Chat
                        </Buttoon>
                      ) : (
                        <TelegramChats />
                      ),
                    icon: "chat_bubble",
                    hiddenIf: [!handlerPage.data?.mediaItem?.team],
                  },
                  {
                    headerTitle: shouldEditContent
                      ? tr("Done")
                      : "Edit Content",
                    // headerTitle: shouldEditContent
                    //   ? tr("Done")
                    //   : JSON.stringify(
                    //       _.concat(
                    //         handlerPage.data?.rolesThatCanEdit,
                    //         FRBS_ROLE.ADMIN,
                    //         FRBS_ROLE.DEVELOPER,
                    //         `BS:${route?.params?.cpid}`
                    //       )
                    //     ),
                    // : tr("Edit contents"),
                    showTitle: true,
                    render: () =>
                      isUserInThisTeamId() || userProfile?._isDeveloper ? (
                        <SectionEditContent
                          shouldEditContent={shouldEditContent}
                          onToggleEditContent={() =>
                            setShouldEditContent(!shouldEditContent)
                          }
                        />
                      ) : (
                        <Txt>This is not your team's page.</Txt>
                      ),
                    icon: shouldEditContent ? "check" : "pen",
                    color: shouldEditContent && C.primary,
                    hiddenIf: [
                      !userProfile._isRolesContain(
                        _.concat(
                          handlerPage.data?.rolesThatCanEdit,
                          FRBS_ROLE.ADMIN,
                          FRBS_ROLE.DEVELOPER,
                          `BS:${route?.params?.cpid}`
                        )
                      ),
                      isSelectShareModeOn,
                      !userD,
                    ],
                  },
                  {
                    headerTitle: tr("Add content"),
                    showTitle: true,
                    render: () => (
                      <PageItemsCreatorMenu cpid={route?.params?.cpid} />
                    ),
                    icon: "plus",
                    hiddenIf: [
                      !userProfile._isRolesContain(
                        _.concat(
                          handlerPage.data?.rolesThatCanEdit,
                          FRBS_ROLE.ADMIN,
                          FRBS_ROLE.DEVELOPER,
                          `BS:${route?.params?.cpid}`
                        )
                      ),
                      shouldEditContent,
                      isSelectShareModeOn,
                      !isUserInThisTeamId() && !userProfile?._isDeveloper(),
                    ],
                  },
                  {
                    headerTitle: tr(`Base shop Notification`),
                    icon: "bell",
                    showTitle: true,
                    render: () => (
                      <O.BroadcastCreatorCenter
                        bsid={`${route?.params?.cpid}`}
                        handlerPage={handlerPage?.data}
                        scheduled={NotificationStatus.SCHEDULED}
                      />
                    ),
                    // icon: shouldEditContent ? "check" : "pen",
                    hiddenIf: [
                      !userProfile._isRolesContain(
                        _.concat(
                          handlerPage.data?.rolesThatCanEdit,
                          FRBS_ROLE.DEVELOPER,
                          `BS:${route?.params?.cpid}`
                        )
                      ),
                      shouldEditContent,
                      isSelectShareModeOn,
                      !handlerPage._isBaseShopPage(),
                    ],
                  },
                  // {
                  //   //This allows us to view BS Members if we are the owners of the BS
                  //   headerTitle: tr(
                  //     `Manage ${handlerPage.data?.name} members`
                  //   ),
                  //   showTitle: true,
                  //   render: () => (
                  //     <MembersFeed
                  //       where={["baseShopId", "==", handlerPage.data?.id]}
                  //       bsid={handlerPage.data?.id}
                  //       mode={"alacarte"}
                  //     />
                  //   ),
                  //   icon: "users",
                  //   hiddenIf: [
                  //     !userProfile._isBaseShopOwner(
                  //       `BS:${handlerPage?.data?.id}`
                  //     ),
                  //     isSelectShareModeOn,
                  //   ],
                  // },

                  {
                    headerTitle: isSelectShareModeOn
                      ? tr("Cancel")
                      : tr("Share content"),
                    showTitle: isSelectShareModeOn ? false : true,
                    render: () => (
                      <ShareContentsPressable
                        isSelectShareModeOn={isSelectShareModeOn}
                        setisSelectShareModeOn={setisSelectShareModeOn}
                        setIsShareContents={setIsShareContents}
                      />
                    ),
                    icon: isSelectShareModeOn ? "x" : "share",
                    hiddenIf: [!handlerPage?.data, shouldEditContent],
                  },
                  {
                    headerTitle: tr("Sharing options"),
                    showTitle: true,
                    render: () => (
                      <ShareContents
                        isSelectShareModeOn={isSelectShareModeOn}
                        setisSelectShareModeOn={setisSelectShareModeOn}
                        isShareContents={isShareContents}
                        setIsShareContents={setIsShareContents}
                      />
                    ),
                    icon: "share",
                    color: C.primary,
                    hiddenIf: [
                      !isSelectShareModeOn,
                      shouldEditContent,
                      isShareContents.length <= 0,
                    ],
                  },

                  // This is used to check the type of page, which is if it is either a custom page or a base shop,
                  // if it is a bs it allows them to leave else it doesn't show the leave button at all
                  {
                    hiddenIf: [
                      !handlerPage._isBaseShopPage(),
                      isSelectShareModeOn,
                      shouldEditContent,
                      !userD,
                      // !(
                      //   userProfile._isUserAdmin() ||
                      //   userProfile._isDeveloper() ||
                      //   userProfile._isBaseShopOwner(
                      //     `BS:${route?.params?.cpid}`
                      //   )
                      // ), //* Everyone should be allow to leave BS
                    ], //todo "false" case
                    headerTitle: tr("Leave the base shop"),
                    color: C.errorRed,
                    render: () => (
                      <LeaveBaseShop handlerPage={handlerPage?.data} />
                    ),
                    icon: "exit",
                  },
                ]
              //#endregion
            }
          />
        );
        break;
    }
  } catch (error) {
    throw Error(`::UmVzb3VyY2VCb2R5U2NyZWVuCg==::${error}`);
  }
}

const SectionEditContent = ({ onToggleEditContent, shouldEditContent }) => {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();
  React.useEffect(function closePage() {
    !breakpointForTwoColumns &&
      (onToggleEditContent && onToggleEditContent(), Navigation.goBack());
  }, []);
  return breakpointForTwoColumns ? (
    <View>
      <Kitten.Menu style={{ borderWidth: 1, borderColor: C.adminGold }}>
        <Kitten.MenuItem
          accessoryLeft={(p) =>
            MenuIcon(shouldEditContent ? "check" : "pen", p)
          }
          title={shouldEditContent ? tr("Done") : tr("Edit Content")}
          onPress={() => onToggleEditContent && onToggleEditContent()}
        />
      </Kitten.Menu>
    </View>
  ) : (
    <View />
  );
};

export const ContentNotFound = () => {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  try {
    return (
      <View
        style={{
          backgroundColor: C.background,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Txt.H6>{tr("Page not found")}</Txt.H6>
        <Buttoon appearance={"ghost"} onPress={() => Navigation.goBack()}>
          {tr("Go back")}
        </Buttoon>
      </View>
    );
  } catch (error) {
    throw new Error(`::Q29udGVudE5vdEZvdW5k:: ${error}`);
  }
};

/**
 * ###  Screen Props
 */
interface P {
  route?: { params?: dPageContentsScreenParam }; //* TODO remember to create dResourcesBodyScreenParam
}

const A = {
  InputTitle: sstyled(TextInput)({
    color: "text",
    borderRadius: 1, //* To create a "bordered" placeholder when text is being loaded
    fontSize: scale(30),
    // fontFamily: "Inter_800ExtraBold"
    fontWeight: Platform.select({ web: "800", ios: "800", android: "bold" }),
  }),
  Ctnr: sstyled(View)({
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "background",
  }),
};
