import { Toasty } from "components";
import { fn, PosstSchema } from "engines";
import { tr } from "utils";

/**
 * ### Function to upload one or multiple medias
 *  - Being used in PosstCreatorCenter
 *  ----
 *  @example
 *  uploadMultipleMedias().then((r)=> console.log("Cloudinary medias: ", r))
 *  ----
 *  @version 21.09.15
 *  -  *Build an individual function*
 *  @author  @nguyenkhooi
 *
 **/
export function uploadMultipleMedias(localMedias: PosstSchema["medias"]) {
  if (!Array.isArray(localMedias)) {
    Toasty.show(tr("Error uploading medias"), { type: "danger" });
  }

  return Promise.all<PosstSchema["medias"][0]>(
    localMedias.map((media, i) => toCloudinaryUri(media, i))
  );
}

/**
 * Used for possts so possts can have multiple media items included
 */
const toCloudinaryUri = async (
  media: PosstSchema["medias"][0],
  i: number
): Promise<{ uri: string; type: "image" | "video" | "vimeo" | "youtube" }> => {
  let mediaProgress = 0;
  // let today = new Date().toLocaleString();

  // alert("TO CLOUDINARY URI " + media.uri + (media.file ? "FILE" : "NO FILE"));

  return new Promise((resolve, reject) => {
    try {
      fn.media.uploadMedia(
        media.file ? media.file : media.uri,
        {
          // title: "posst_media_" + i + today,
          title: `posst-${i}`,
          folder: "possts",
          type: "image",
        },
        (progress) => {
          mediaProgress = progress;
        },
        (data) => {
          mediaProgress = 0;
          resolve({ uri: data.url, type: media.type });
        }
      );
    } catch (error) {
      reject(error);
    }
  });
};
