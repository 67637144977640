import { Buttoon, sstyled } from "components/atoms/generals";
import { useBreakpointIndex } from "dripsy";
import { MediaPageItemSchema } from "engines";
import { LinkURL } from "engines/functions/web-functions";
import React from "react";
import { Platform, Pressable, useWindowDimensions, View } from "react-native";
import Sound from "react-native-sound";
import { WebView } from "react-native-webview";
import { useNavigator } from "screens/_navigation/navigation-utils";
import { IS_WEB, scale, spacing, tr } from "utils";
import { VideoPlayImage } from "./video-play-image";
import { ImageVideollyThumbnail } from "./Videolly";
interface dYoutubeVideolly {
  item: MediaPageItemSchema;
  videoId: string;
  /**
   * Will get passed with the onComplete() when called so the database knows what item to complete
   * - originally generated by levels-handler.tsx
   */
  itemString?: string;
  onComplete?: (itemString: string) => void;
}

export function YoutubeVideolly({
  item,
  videoId,
  itemString,
  onComplete,
}: dYoutubeVideolly) {
  // const { C } = useAppContext();
  const Navigation = useNavigator();
  const window = useWindowDimensions();
  const breakpoint = useBreakpointIndex();
  const _videoId = React.useMemo(() => videoId, []);

  const [playInView, setPlayInView] = React.useState<boolean>(false);
  // const [playing, setPlaying] = React.useState(false);

  // const onStateChange = React.useCallback((state) => {
  //   if (state === "ended") {
  //     setPlaying(false);
  //   }
  // }, []);

  // const togglePlaying = React.useCallback(() => {
  //   setPlaying((prev) => !prev);
  // }, []);
  function YoutubePlayer() {
    switch (Platform.OS) {
      case "web":
        return (
          <A.CtnrWebView
            //   onError={onError}
            allowsFullscreenVideo
            scrollEnabled={false}
            // automaticallyAdjustContentInsets
            source={{
              uri: `https://www.youtube.com/embed/${_videoId}?rel=0&autoplay=1&showinfo=0`,
              // html: `
              //   <html>
              //     <body>
              //       <iframe src="https://www.youtube.com/embed/${videoId}" width="100%" height="400"  frameborder="0" webkitallowfullscreen mozallowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              //     </body>
              //   </html>
              // `,
            }}
          />
        );
        break;

      default:
        return (
          <>
            <A.CtnrWebView
              allowsFullscreenVideo
              scrollEnabled={false}
              source={{
                uri: `https://www.youtube.com/embed/${_videoId}?rel=0&autoplay=1&showinfo=0`,
              }}
            />
            <Buttoon icon={{ name: "world" }} status="control" appearance="filled" size="small" style={{ width: "50%", alignSelf: "center", marginTop: spacing(5) }} onPress={() => {
              LinkURL(item.media)
            }}>{tr("View in browser")}</Buttoon>
          </>
        );
        // return (
        //   <YoutubeNativePlayer
        //     height={600}
        //     play={playing}
        //     videoId={_videoId}
        //     onChangeState={onStateChange}
        //     forceAndroidAutoplay={true}
        //   />
        // );
        break;
    }
  }
  //#region [section] on thumbnail press, show dialog of video & auto play it
  function onThumbnailPress() {
    !IS_WEB && Sound.setCategory("Playback");

    onComplete && onComplete(itemString);

    // setPlayInView(true)

    // if (IS_WEB) {
    //   setPlayInView(true);
    //   return;
    // }

    return Navigation.dialogPortal.open({
      headerTitle: item?.title,
      staticVideo: true,
      cardType: "transparent",
      render: (
        <View
          style={[
            { borderRadius: 10 },
            breakpoint <= 2
              ? {
                width: window.width,
                // height: (window.width * 9) / 16,
              }
              : {
                width: scale(800),
                // height: scale(450),
              },
          ]}
        >
          <YoutubePlayer />
        </View>
      ),
    });
  }
  //#endregion

  return React.useMemo(() => {
    return playInView ? (
      <YoutubePlayer />
    ) : (
      <Pressable onPress={onThumbnailPress}>
        <ImageVideollyThumbnail
          source={{ uri: `https://img.youtube.com/vi/${_videoId}/0.jpg` }}
        >
          <VideoPlayImage service={"youtube"} />
        </ImageVideollyThumbnail>
      </Pressable>
    );
  }, [playInView, _videoId]);
}

const A = {
  CtnrWebView: sstyled(WebView)({
    width: "100%",
    height: [250, 300, 350, 420],
    backgroundColor: "black",
    borderRadius: 10,
    overflow: "hidden",
  }),
};
