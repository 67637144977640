/**
 * ###
 *  - This function allow us to be able to format the number and then return a string,
 *  we could change the .toFixed value to any number in other to increase or reduce the number of values after the decimal
 *  ----
 *  @example
 *  formatNumber(2000)
 *  expected result 2,000
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.06.30
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function formatNumber(value) {
  let val = (value / 1).toFixed(0).replace(",", ".");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
