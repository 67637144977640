import { useDocument } from "@nandorojo/swr-firestore";
import { Toasty } from "components";
import {
  FPATH,
  MediaPageItemSchema,
  MediaPageSchema,
  URL_PREFIX
} from "engines";
import firebase from "firebase";
import _ from "lodash";
import * as R from "ramda";
import { useCollectionPresetv2 } from "../config";

export function usePageDocument(id: string, query?) {
  const swrDocument = useDocument<MediaPageSchema>(`${FPATH.PAGES}/${id}`, {
    listen: true,
    ...query,
  });

  function _isBaseShopPage() {
    return (
      swrDocument?.data?.mediaItem?.team == true ||
      swrDocument?.data?.mediaItem?.team == "true"
    );
  }

  return { ...swrDocument, _isBaseShopPage };
}

export function usePagesCollection(query?) {
  const swrCollection = useCollectionPresetv2<MediaPageSchema>(
    FPATH.PAGES,
    "_id",
    { ...query }
  );

  function _largestPositionIndex() {
    let positions = swrCollection.data
      ? R.pluck("position", swrCollection.data)
          .map(Number)
          .filter((p) => !!p === true && p != Number.NEGATIVE_INFINITY) //* Temporary sol to ignore all NaN values
          ?.length == 0
        ? [0]
        : R.pluck("position", swrCollection.data)
            .map(Number)
            .filter((p) => !!p === true && p != Number.NEGATIVE_INFINITY) //* Temporary sol to ignore all NaN values
      : [0];
    // alert(JSON.stringify(positions) + "\n" + _.max(positions));
    // return Math.max(...positions) !== Number.NEGATIVE_INFINITY ? ;
    return _.max(positions);
  }

  function _updateResourcePosition(data: { cpid: string; position: number }) {
    return updateResourcePosition(data);
  }

  return {
    ...swrCollection,
    _updateResourcePosition,
    _largestPositionIndex,
  };
}

/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.09.28
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export const updateResourcePosition = async (data: {
  cpid: string;
  position: number;
}) => {
  // console.log("data request ", data);
  try {
    const updateResourcePosition = await firebase
      .firestore()
      .collection(FPATH.PAGES)
      .doc(data.cpid)
      .update({
        position: data.position,
      });
    return updateResourcePosition;
  } catch (error) {
    return error;
  }
};

/**
 * ###
 *  - Update collaboration field on all pages in a batch
 *  ----
 *  @example
 *  shareAllPages(pageHandler.data,teamId,true);
 *  ----
 *  @version 21.11.23
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export const shareAllPages = async (
  pagesData: MediaPageSchema[],
  teamId: string,
  value: boolean
) => {
  const fs = firebase.firestore();
  const pagesBatch = fs.batch();
  try {
    pagesData.forEach(async (page) => {
      const updatePageDataRef = fs.collection(FPATH.PAGES).doc(page._id);
      pagesBatch.set(
        updatePageDataRef,
        { collaboration: value, teamID: teamId },
        { merge: true }
      );
    });
    await pagesBatch.commit();
  } catch (error) {
    return error;
  }
};

/**
 * ###
 *  - If the pageContent contains a topage pointing to another page, we copy that page as well, it's content, and it's subpages if any.
 *  - Returns the url that should be used with this page, ex: "level:pgxyz123"
 *  ----
 *  @example
 *  newPageUrl = createNewToPage(originalPageId, "fml-experior", ["BS:cyz123"]);
 *  ----
 *  @version 21.11.23
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *  @returns newPageId
 *
 **/
export const createNewToPage = async (
  originalPageId: string,
  teamId: string,
  /** What roles can edit this page if any */
  rolesThatCanEdit = []
): Promise<string> => {
  Toasty.show("Creating new page...", { type: "loading", duration: 6000 });
  console.log("[multiverse] creating new subpage...");
  const fs = firebase.firestore();
  const pageCollRef = fs.collection(FPATH.PAGES);
  try {
    const origPageRef = pageCollRef.doc(originalPageId);
    console.log("[multiverse] getting original page...");
    const origPageData = await origPageRef.get();

    console.log("[multiverse] getting original page content...");
    const origPageContentData = await origPageRef
      .collection(FPATH.CUSTOM_PAGE_CONTENT)
      .get();

    const newPageRef = pageCollRef.doc();
    const newPageId = newPageRef.id;
    const pageData: MediaPageSchema = {
      ...(origPageData.data() as MediaPageSchema),
      _id: newPageId,
      _teamId: teamId,
      teamID: teamId,
      mediaItem: { visible: false },
      // reset roles that can edit for this new page
      rolesThatCanEdit,
    };
    console.log("[multiverse] creating new page...");
    await newPageRef.set(pageData);

    console.log(
      `[multiverse] now setting up page's contents... (${origPageContentData.docs.length} items)`
    );
    origPageContentData.docs.forEach(async (doc) => {
      // const pageContentData = origPageContentData.docs[d];
      const tempPCData: MediaPageItemSchema = doc.data() as MediaPageItemSchema;
      let newToPageId: string = "";

      console.log("[multiverse] copying item with title " + tempPCData?.title);

      if (
        tempPCData.url &&
        (tempPCData.url?.includes(URL_PREFIX.PAGE) ||
          tempPCData.url?.includes(URL_PREFIX.LEVEL))
      ) {
        console.log("[multiverse] if one");
        const urlPrefix = tempPCData.url?.includes(URL_PREFIX.PAGE)
          ? URL_PREFIX.PAGE
          : URL_PREFIX.LEVEL;
        console.log("[multiverse] creating deep subpage...");
        newToPageId =
          urlPrefix +
          (await createNewToPage(
            tempPCData.url
              .replace(URL_PREFIX.PAGE, "")
              .replace(URL_PREFIX.LEVEL, ""),
            teamId,
            rolesThatCanEdit
          ));
        console.log("[multiverse] new topage id = " + newToPageId);
      }
      console.log(`[multiverse] adding in regular item to page ${newToPageId}`);
      await fs
        .collection(FPATH.PAGES)
        .doc(newPageId)
        .collection(FPATH.CUSTOM_PAGE_CONTENT)
        .doc(doc.id)
        .set(tempPCData, { merge: true });
      // if (
      //   newToPageId
      //     .replace(URL_PREFIX.PAGE, "")
      //     .replace(URL_PREFIX.LEVEL, "") !== ""
      // ) {
      //   console.log("[multiverse] adding item to subpage...");
      //   await fs
      //     .collection(FPATH.PAGES)
      //     .doc(newPageId)
      //     .collection(FPATH.CUSTOM_PAGE_CONTENT)
      //     .doc(doc.id)
      //     .set(tempPCData, { merge: true });
      // } else {
      //   console.log("[multiverse] adding in regular item...");
      //   await fs
      //     .collection(FPATH.PAGES)
      //     .doc(newPageId)
      //     .collection(FPATH.CUSTOM_PAGE_CONTENT)
      //     .doc(doc.id)
      //     .set(tempPCData, { merge: true });
      //   return "";
      // }
    });

    // origPageContentData.docs.forEach(async (pageContentData) => {
    //   const tempPCData = pageContentData.data();
    //   let newToPageId: string = "";

    //   if (tempPCData.topage && tempPCData.topage.includes(URL_PREFIX.PAGE)) {
    //     newToPageId =
    //       URL_PREFIX.PAGE +
    //       (await createNewToPage(
    //         tempPCData.topage.replace(URL_PREFIX.PAGE, ""),
    //         teamId
    //       ));
    //   }
    //   if (newToPageId.replace(URL_PREFIX.PAGE, "") !== "") {
    //     await fs
    //       .collection(FPATH.PAGES)
    //       .doc(newPageId)
    //       .collection(FPATH.CUSTOM_PAGE_CONTENT)
    //       .doc(pageContentData.id)
    //       .set(tempPCData, { merge: true });
    //   } else {
    //     return "";
    //   }
    // });

    Toasty.show("New page created!");
    console.log("[multiverse] setup new page with pageid: " + newPageId);

    return newPageId;
  } catch (error) {
    Toasty.show("Failed to setup subpages...", { type: "danger" });
    console.log("[multiverse] " + error);
    return error;
  }
};
