import { IconPrimr } from "assets";
import { AutocompleteTags, Buttoon, Txt } from "components";
import { AutocompleteTagsProps, idFromItem, Toasty } from "components/atoms";
import { ContactGroupSelector } from "components/organisms";
import { useAppContext } from "engines";
import {
  ContactGroupSchemaa,
  ContactSchemaa
} from "engines/backends/schemas/bml";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { fetchContacts } from "engines/functions/contacts-functions";
import * as Contacts from "expo-contacts";
import React, { useState } from "react";
import { Alert, TextInput, View } from "react-native";
import { Popable } from "react-native-popable";
import { useNavigator } from "screens";
import { IS_WEB, spacing } from "utils";
/**
 * ### Input for typing multiple member's emails
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  <M.MembersMultiInput onRecipientsCallback={setNewRecipients} />
 *  ----
 *  @version 21.06.30
 *  -  *Brief changelog*
 *  @author  Your Name
 *
 **/
interface dMembersMultiInput extends Partial<AutocompleteTagsProps> {
  title: string;
  /**
   * Set to true to disable adding of contact groups
   */
  disableGroups?: boolean;
  keepInputAtTop?: boolean;
  hidePlusButton?: boolean;
  onRecipientsCallback(
    recipients: (ContactSchemaa | ContactGroupSchemaa)[]
  ): void;
  renderTag?: (
    tag: ContactSchemaa | ContactGroupSchemaa,
    onPress: (tag: ContactSchemaa | ContactGroupSchemaa) => void
  ) => JSX.Element;
  /** See description in AutocompleteTags */
  // suggestionsYOffset?: number;
  // onDoneInputCallback(): void;
}
export function MembersMultiInput(props: dMembersMultiInput) {
  const {
    title = "To: ",
    onRecipientsCallback,
    // onDoneInputCallback,
    mode = "email",
    disableGroups,
    keepInputAtTop,
    hidePlusButton,
    renderTag,
    // suggestionsYOffset,
  } = props;
  const { teamId, C, frbsUser } = useAppContext();
  const Navigation = useNavigator();
  //#region [FRBS] handler
  const userProfile = useUserProfile();
  // const handleMembers = useMembersCollection();
  // const { data, error: groupDataError } = useContactGroupsCollection(
  //   userProfile.data?._id,
  //   {
  //     where: ["groupType", "==", "email-groups"],
  //   }
  // );
  // let _members = handleMembers.data;
  // const [_members, set_members] = React.useState<any>(handleMembers.data);
  const [_groups, set_groups] = React.useState<ContactGroupSchemaa[]>([]);
  const [tags, setTags] = useState<(ContactSchemaa | ContactGroupSchemaa)[]>(
    []
  );
  // var _groups = [];
  //#endregion
  const addEntryClick = async (item: ContactSchemaa | ContactGroupSchemaa) => {
    console.log("addEntryClick ", item);
    if (
      (item as ContactGroupSchemaa).contacts &&
      !_groups.find((o) => o._id === (item as ContactGroupSchemaa)._id)
    ) {
      set_groups(_groups.concat([item as ContactGroupSchemaa]));
    } else {
      Alert.alert(`${item?.name} already added.`);
    }
  };
  const [phoneContacts, setPhoneContacts] = React.useState<Contacts.Contact[]>(
    []
  );
  const [suggestions, setSuggestions] = React.useState<
    (ContactSchemaa | ContactGroupSchemaa)[]
  >([]);
  const [contactPermission, setContactPermission] =
    React.useState<Contacts.PermissionStatus>(null);
  const [hover, setHover] = useState(false);
  const [focusTagId, setFocusTagId] = useState("");
  React.useEffect(function getNativePhoneContacts() {
    fetchContacts().then((allPhoneContacts) => {
      if (!allPhoneContacts || allPhoneContacts.length < 1) {
        // this may not be needed actually oops but whatever - jm_francis
        setContactPermission(Contacts.PermissionStatus.UNDETERMINED);
      } else {
        setPhoneContacts(allPhoneContacts);
      }
    });
  }, []);
  React.useEffect(
    function updateSuggestionsData() {
      // if (!!_members) {
      // let _reducedData: ContactSchemaa[] = _members
      //   .filter((m) => m?.personali?.displayName)
      //   .reduce(
      //     (a, c) => [
      //       ...a,
      //       {
      //         _id: c._id,
      //         ...c.personali,
      //       },
      //     ],
      //     []
      //   );
      let _reducedNativeContacts: ContactSchemaa[] = phoneContacts
        // .filter((m) => m?.personali?.displayName)
        .reduce(
          (a, c) => [
            ...a,
            ({
              _id: c.id,
              _cid: c.id,
              ownerUid: frbsUser?.uid,
              // ...c.personali,
              email: c.emails?.pop()?.email,
              emails: c?.emails,
              phoneNumber:
                c.phoneNumbers?.length > 0 ? c.phoneNumbers[0].number : "",
              phoneNumbers: c?.phoneNumbers,
              _phoneNumberStrings: (c?.phoneNumbers || [])?.map(pn => pn.digits),
              displayName: `${c.name?.length > 0 ? c.name : "Unnamed"}`,
              name: `${c.name?.length > 0 ? c.name : "Unnamed"}`
            } as Partial<ContactSchemaa>),
          ],
          []
        );
      setSuggestions([..._reducedNativeContacts]);
      // }
      if (!!_groups && _groups.length !== 0) {
        const _reducedData: ContactGroupSchemaa[] = _groups?.reduce(
          (a, c) => [
            ...a,
            {
              _id: c._id,
              name: c.name,
              contacts: c.contacts ? c.contacts : [],
              contactObjects: c.contactObjects ? c.contactObjects : [],
            } as ContactGroupSchemaa,
          ],
          []
        );
        setSuggestions([...suggestions, ..._reducedData]);
        setTags([..._reducedData]);
      }
    },
    [_groups, phoneContacts]
  );
  // Array.from({ length: 50 }).map(() => ({
  //   _id: "000",
  //   displayName: "Fred Hendriks",
  //   email: "fred.hendricks@abc.com",
  // }))
  React.useEffect(
    /**
     * When u select members,
     * callback functions (selectedEmailsCallback, selectedIdsCallback)
     * will be called
     */
    function callback() {
      onRecipientsCallback && onRecipientsCallback(tags);
    },
    [tags]
  );
  const onAddNewTag = (input: string) => {
    // Toasty.show(tr("Email not found"));
    setTags(
      tags.concat([
        {
          id: "000",
          _cid: "000",
          phoneNumbers: [],
          _phoneNumberStrings: [],
          displayName: input,
          name: input,
          email: input,
        },
      ])
    );
  };
  // const renderGroupDetails = (tag: dSuggestionEmail) => {
  //   return Navigation.navigate<dOverlayParams>("Overlay", {
  //     headerTitle: tag.groupName,
  //     render: (
  //       <View style={{ margin: 20 }}>
  //         <Txt.H5 style={{ marginBottom: 10 }}>
  //           {`${tag.groupName} ${
  //             tag.groupContacts.length > 0
  //               ? `(${tag.groupContacts.length} members)`
  //               : ""
  //           }`}
  //         </Txt.H5>
  //         {tag.groupContacts.map((contact) => (
  //           <Txt style={{ marginBottom: 5 }}>
  //             {contact.displayName}{" "}
  //             <Txt
  //               style={{ color: C.hazardYellow }}
  //             >{`(${contact.email})`}</Txt>
  //           </Txt>
  //         ))}
  //       </View>
  //     ),
  //   });
  // };
  const [allowInput, setAllowInput] = React.useState<boolean>(true);
  const refInput = React.useRef<TextInput>(null);
  function toggleInputFocus() {
    setAllowInput(!allowInput);
    // refInput.current?.isFocused()
    //   ? refInput?.current?.focus()
    //   : refInput?.current?.blur();
  }
  React.useEffect(
    function setupForInput() {
      if (allowInput) {
        refInput?.current?.focus();
      } else {
        refInput?.current?.blur();
        // onDoneInputCallback && onDoneInputCallback();
      }
    },
    [allowInput]
  );
  // if (handleMembers.error) {
  //   console.warn("error in MembersFeed: ", handleMembers.error);
  //   return <Txt.Indicator>{tr("Error getting members feed")}</Txt.Indicator>;
  // }
  // if (!handleMembers.data) return <Skeleton.Screen.Members length={7} />;
  /**
   * Label to show on tags, suggestion item
   * @param item
   * @returns
   */
  let _label = (item: ContactSchemaa | ContactGroupSchemaa) => {
    if ((item as ContactGroupSchemaa)?.contacts) {
      return (item as ContactGroupSchemaa)?.name
        ? `${item?.name} (${item?.contacts?.length})`
        : "";
    } else {
      switch (mode) {
        case "sms":
          return `${(item as ContactSchemaa)?.displayName || item?.name || "Unnamed"
            } • ${((item as ContactSchemaa)?.phoneNumbers?.length > 0 &&
              (item as ContactSchemaa).phoneNumbers[0].number) ||
            "(No number)"
            }`;
          break;
        case "email":
          return `${(item as ContactSchemaa)?.email ||
            (item as ContactSchemaa)?.emails?.pop()?.email ||
            "(No email)"
            } • ${(item as ContactSchemaa)?.displayName ||
            (item as ContactSchemaa)?.name ||
            "Unnamed"
            }`;
          break;
        default:
          return "";
          break;
      }
    }
  };

  function onTagPress(tag: ContactSchemaa | ContactGroupSchemaa) {
    setTags(tags.filter((t) => idFromItem(t) !== idFromItem(tag)));
    if (tag?.contacts)
      set_groups(_groups.filter((g) => idFromItem(g) !== idFromItem(tag)));
  }

  return (
    <AutocompleteTags
      ref={refInput}
      keepInputAtTop={keepInputAtTop}
      // suggestionsYOffset={suggestionsYOffset}
      contactPermissionStatus={contactPermission}
      inputProps={{
        autoCapitalize: "none",
        autoCorrect: false,
        placeholder: tags?.length < 1 ? "Type a name or group..." : "",
        autoFocus: true,
        onFocus: () => setAllowInput(true),
        ...props.inputProps,
      }}
      {...props}
      tags={tags}
      suggestions={suggestions}
      showSuggestions={allowInput}
      labelExtractor={(tag) =>
        (tag as ContactSchemaa)?.displayName ||
        (tag as ContactSchemaa)?.name ||
        (tag as ContactGroupSchemaa)?.name
      }
      suggestionExtractor={_label}
      onChangeTags={(t: ContactGroupSchemaa | ContactGroupSchemaa) => {
        setTags(t);
      }}
      onAddNewTag={onAddNewTag}
      allowCustomTags={false}
      renderTag={(tag) =>
        renderTag ? (
          renderTag(tag, () => onTagPress(tag))
        ) : (
          <Popable
            key={`${tag.name}`}
            visible={
              hover &&
              tag._id === focusTagId &&
              tag._cid !== focusTagId &&
              !!tag.name
            }
            position={"bottom"}
            style={{ width: 165, zIndex: 99999 }}
            content={
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  backgroundColor: C.storybookDarkBg,
                  padding: 10,
                }}
              >
                {(tag as ContactGroupSchemaa)?.contactObjects &&
                  (tag as ContactGroupSchemaa).contactObjects
                    .sort((a, b) => {
                      const nameA = a?.displayName || a?.name;
                      const nameB = b?.displayName || b?.name;
                      return nameA?.localeCompare(nameB);
                    })
                    .map((contact) => (
                      <>
                        <Txt.S2>{`${contact.displayName || contact.name
                          }`}</Txt.S2>
                        <Txt.C2 style={{ color: C.hazardYellow }}>
                          {mode === "sms"
                            ? `${contact?.phoneNumbers?.length < 1 || "No number"
                            }`
                            : `${contact?.email ||
                            contact?.emails?.pop()?.email ||
                            "No email"
                            }`}
                        </Txt.C2>
                      </>
                    ))}
              </View>
            }
          >
            <Buttoon
              size="tiny"
              status={tag.name ? "warning" : "primary"}
              appearance="outline"
              compact
              style={{ margin: spacing(2), borderRadius: 69 }}
              icon={
                IS_WEB
                  ? hover && (tag._id === focusTagId || tag._cid === focusTagId)
                    ? { name: "x", right: true }
                    : null
                  : { name: "x", right: true }
              }
              // onLongPress={() => {
              //   tag.groupName ? renderGroupDetails(tag) : null;
              // }}
              onPress={() => onTagPress(tag)}
              onMouseEnter={() => {
                setHover(true);
                setFocusTagId(tag._id || tag._cid);
              }}
              onMouseLeave={() => {
                setHover(false);
                setFocusTagId("");
              }}
            >
              {String(_label(tag)).split("•")[0]}
            </Buttoon>
          </Popable>
        )
      }
      accessoryRight={() =>
        hidePlusButton ? null : (
          <IconPrimr
            color={C.primary}
            name={"plus"}
            preset="circular"
            containerStyle={[
              allowInput && {
                backgroundColor: C["color-primary-transparent-500"],
                margin: spacing(2),
              },
            ]}
            onPress={() => {
              if (!disableGroups)
                Navigation.dialogPortal.open({
                  headerTitle: "Select group",
                  render: (
                    <ContactGroupSelector
                      userId={userProfile.data?._id}
                      addEntryClick={addEntryClick}
                    // sharedItems={isShareContents}
                    />
                  ),
                });
              else {
                Toasty.show("Type in the name of a person in your phone!", {
                  type: "normal",
                });
              }
            }}
          // onPress={toggleInputFocus}
          />
        )
      }
    />
  );
}
/**
 * Tag Suggestion
 */
