//#region [import]
import { createNavigationContainerRef } from "@react-navigation/native";
import { Buttoon, sstyled, Txt } from "components";
import { useAppContext } from "engines";
import React from "react";
import { Image, ImageBackground, View } from "react-native";
import { IS_WEB, tr } from "utils";

//#endregion

export const navigationRef = createNavigationContainerRef();
/**
 * ### A 404 screen whenever the app throw an error
 *  - Give user the error code so they can send it to us if needed
 *  ----
 *  @version 1.09.29
 *  -  *real errorcode*
 *  @author  NL, nguyenkhooi
 *
 **/
export function PageNotFound(props: E) {
  const { C } = useAppContext();
  // const Navigation = useNavigator();
  const { errorcode } = props;
  // function updateTemplate(payload: TemplateSchema) {
  //   console.log("got to the main function");
  //   handleTemplate.update(payload);
  // }
  // console.log(props.route.params.item)
  try {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#0b0e10",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Txt.H1
          style={{
            color: C.text01,
            fontSize: 150,
            fontWeight: "bold",
          }}
        >
          {tr("404")}
        </Txt.H1>

        <Txt.H5 style={{ color: C.text01 }}>
          {tr("Houston, we have a problem ")}
        </Txt.H5>
        <Txt.S1 style={{ color: C.text01 }}>
          {tr("Error-code: ") + errorcode}
        </Txt.S1>
        <Txt.Helper marginV style={{ textAlign: "center" }}>
          {tr(
            "Sorry for the inconvenience, an automatic report has been sent to us.\nYou can also send us this error code too!"
          )}
        </Txt.Helper>
        <Image
          source={{
            uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/v1629839418/404-1_cdihiu.gif",
          }}
          style={{ width: 200, height: 200, alignSelf: "center", margin: 50 }}
        />
        <Buttoon
          appearance="outline"
          status="basic"
          onPress={() => {
            // 👀 yo nanle can you handle for mobile?
            if (IS_WEB) window.location.href = window.location.origin;
            else navigationRef.navigate("Main");
          }}
        >
          {tr("Go Home")}
        </Buttoon>
      </View>
    );
  } catch (error) {
    throw Error(`::UGFnZU5vdEZvdW5k::${error}`);
  }
}

const A = {
  Ctnr: sstyled(View)({
    // flex: 1,
    // backgroundColor: "background",
    // alignItems: "center",
  }),
  Notfound: sstyled(ImageBackground)({
    // flex: 1,
    // backgroundColor: "background",
  }),
};

interface E {
  errorcode: string;
}
