import { IconPrimr } from "assets";
import { MenuIcon } from "assets/icons/MenuIcon";
import { Kitten, ListHeader } from "components/atoms";
import { TwoColumns, useTwoColumns } from "components/templates";
import { useAppContext } from "engines";
import * as React from "react";
import { View } from "react-native";
import { dLayoverStack, useNavigator } from "screens/_navigation";
import { LAYOVER } from "screens/_navigation/layover.navigator";
import { scale, spacing, THEME } from "utils";
import { FuegoSection } from "./fuego-scr.devs";
export function DevsHomeScreen() {
  const { C, theme, setTheme } = useAppContext();
  const Navigation = useNavigator();
  const { column1ContainerStyle } = useTwoColumns();
  let EXP_LIST = [
    {
      title: "WelcomeScreen EXP",
      onPress: () =>
        Navigation.navigate<dLayoverStack<{}>>("Layover", {
          _key: LAYOVER.WelcomeScreen,
        }),
    },
    {
      title: "Contact Permission EXP",
      onPress: () =>
        Navigation.dialogPortal.open({
          headerTitle: "AgendaCenter EXP",
          render: <PhotosPermissionPage  />,
          hideClose: true
        }),

    },
    {
      title: "Layover EXP",
      onPress: () =>
        Navigation.navigate<dLayoverStack<{}>>("Layover", {
          _key: LAYOVER.AgendaCenter,
        }),
    },
  ];
  return (
    <TwoColumns
      column1Content={(scrollHandler) => (
        <Kitten.List
          data={EXP_LIST}
          ItemSeparatorComponent={Kitten.Divider}
          ListHeaderComponent={
            <View>
              <Kitten.ViewH style={{ paddingRight: spacing(3) }}>
                <IconPrimr
                  onPress={() => {
                    setTheme(theme == THEME.DARK ? THEME.LIGHT : THEME.DARK);
                  }}
                  name={theme == THEME.DARK ? "moon" : "sunny"}
                  color={C.text}
                  size={scale(20)}
                />
              </Kitten.ViewH>
              <FuegoSection />
              <ListHeader
                titleTx="Playground"
                description={
                  "This is where devs put their experiment to test and discuss about functionalities of specific components"
                }
              />
            </View>
          }
          renderItem={({ item }) => (
            <Kitten.ListItemv2
              title={item?.title}
              onPress={item?.onPress}
              accessoryRight={() => MenuIcon("chevron_right")}
            />
          )}
        />
      )}
    />
  );
}
export const SelectSimpleUsageShowcase = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(
    new Kitten.IndexPath(0)
  );
  return (
    <Kitten.Select
      selectedIndex={selectedIndex}
      onSelect={(index) => setSelectedIndex(index)}
    >
      <Kitten.SelectItem title="Option 1" />
      <Kitten.SelectItem title="Option 2" />
      <Kitten.SelectItem title="Option 3" />
    </Kitten.Select>
  );
};
