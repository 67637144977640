import * as EXCalendar from "expo-calendar"

export function parseISOString(s) {
  return new Date(Date.parse(s));
  // var b = s.split(/\D+/);
  // return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

const today = new Date()

export const niceDateFormat = (date: Date): string => (date?.toLocaleDateString("en-us", {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
}).replace(`, ${today.getFullYear()}`, ""))

/** get a link to add this event to your calendar */
export function generateEventUrl(event: Partial<EXCalendar.Event>, service = "stream"): string {
  const _startDate = typeof (event.startDate) === "string" ? new Date(event.startDate) : event.startDate
  const _endDate = typeof (event.endDate) === "string" ? new Date(event.endDate) : event.endDate
  return encodeURI(`https://calndr.link/d/event/?service=${service}&start=${_startDate.toISOString()}&end=${_endDate.toISOString()}&title=${event.title}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&description=${event.notes || event.description || " "}&location=${event.location || " "}`)
}
export function generateEventInviteMessage(event: Partial<EXCalendar.Event>): string {
  const _startDate = typeof (event.startDate) === "string" ? new Date(event.startDate) : event.startDate
  const _endDate = typeof (event.endDate) === "string" ? new Date(event.endDate) : event.endDate
  const dateTimeFormat = `${niceDateFormat(_startDate)}\n${timeToAMPMString(_startDate)} - ${timeToAMPMString(_endDate)} ${event.timeZone || ""}`
  const prefix = `${event.title}\n\n${dateTimeFormat}\n\nClick the link below to add it to your calendar:\n\n`
  return prefix + generateEventUrl(event)
  // return prefix + encodeURI(`https://my.calendarlink.com/link?collection=be503145-4aae-4aae-a22c-d9bdef27f280&title=${event.title}&start=${event.startDate}&end=${event.endDate}&timezone=${event.timeZone}&description=${event.notes || event.description || " "}&location=${event.location || " "}`)
}

export const forsureDate = (dateObj: any): Date => typeof (dateObj) === "string" ? new Date(dateObj) : (dateObj?.toDate ? dateObj?.toDate() : dateObj)

export function socialFriendlyTime(givenDate: Date) {
  const today: Date = new Date();
  const oneday = 24 * 60 * 60 * 1000;
  const yesterday: Date = new Date(today.getTime());
  yesterday.setTime(yesterday.getTime() - oneday);
  // const timestamp: Date = item.timestamp.toDate();
  let time: string = givenDate.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });
  if (
    givenDate.getDate() !== today.getDate() ||
    givenDate.getMonth() !== today.getMonth()
  ) {
    if (
      givenDate.getDate() === yesterday.getDate() &&
      givenDate.getMonth() === yesterday.getMonth()
    ) {
      time = "Yesterday";
    } else {
      const days = Math.floor((today.getTime() - givenDate.getTime()) / oneday);
      const weeks = Math.floor(days / 7);
      time =
        weeks > 0
          ? `${weeks} week${weeks > 1 ? "s" : ""} ago`
          : `${days} day${days > 1 ? "s" : ""} ago`;
    }
  } else {
    time = `Today at ${time}`
  }
  return `${time}`;
}

export function timeToAMPMString(date: string | number | Date) {
  if (!date) return null;
  let _date = new Date(date);
  let hours = _date.getHours();
  let minutes = _date.getMinutes().toString();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = Number(minutes) < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

// formats date with just the month year and date
export function dateFormat(
  date: string | number | Date,
  format?: "mm/dd/yyyy" | "yyyy-mm-dd"
) {
  if (!date) return null;
  let _date = new Date(date);
  // if (date?.toDate) _date = date.toDate();
  const month = _date.getMonth() + 1;
  const monthStr = month < 10 ? "0" + month : `${month}`;
  const dateStr =
    _date.getDate() < 10 ? "0" + _date.getDate() : "" + _date.getDate();
  switch (format) {
    case "mm/dd/yyyy":
      return `${monthStr}/${dateStr}/${_date.getFullYear()}`;
      break;
    case "yyyy-mm-dd":
      return `${_date.getFullYear()}-${monthStr}-${dateStr}`;
    default:
      return `${monthStr}/${dateStr}/${_date.getFullYear()}`;
      break;
  }

  // return date.toLocaleDateString("en-GB", {
  //   // you can use undefined as first argument
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // });
}

/**
 * Returns date as number
 * whether it is a JavaScript Date or Firestore Timestamp
 */
export function dateAsNumber(date: string | number | Date) {
  let _date = new Date(date);
  // if (_date.toDate) date = _date.toDate();
  return _date.getTime();
}
