/**
 * ###
 *  - Function is used in checking if a url is a linkmagnet link
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.02.09
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export const isLinkMagnetURL = (url: string): boolean => {
  return (
    url &&
    (url.includes("followthislink.com") ||
      url.includes("freedomforfamilies.com"))
  );
};
