import { IMessage } from "react-native-gifted-chat";

/**
 * ### Cmt Doc Schema
 *
 * ---
 * @version 1.1.22
 * @author  nguyenkhooi
 * ---
 * @example see _MOCK_COMMENT
 */
export interface CommentSchema extends Partial<IMessage> {
  //* These fields below already stated in IMessage
  // _id: string;
  // user: {
  //   _id: string;
  //   name: string;
  //   avatar: string;
  // }; //* = rn-gifted-chat User
  // text: string;
  // createdAt: Date | Timestamp; //* = firebase-js firebase.firestore.FieldValue
  _pid: string;
  likes?: { [uid: string]: { name: string } };
  status: CMT_STATUS;
}

export enum CMT_STATUS {
  POSTED = "POSTED",
  PENDING = "PENDING",
  DELETED = "DELETED",
  REPORTED = "REPORTED",
}

/**
 * NOTE: Recommended database structure
 */
//@ts-ignore
export const _MOCK_COMMENT: CommentSchema = {
  _cid: "p000",
  user: {
    _id: "id_000",
    name: "Rice Cooker",
    avatar: "https://i.redd.it/iibrptucse951.png",
  },
  text:
    "This is a mock comment. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ",
  createdAt: new Date(),
  likes: {
    id_111: { name: "Khoi Trannn" },
    id_222: { name: "Jeremy Francis" },
    id_333: { name: "Doodle dude" },
  },
  status: "POSTED",
};
