import { IconPrimr, ICON_NAME } from "assets";
import { Buttoon, Kitten, Poppy } from "components";
import { useAppContext } from "engines";
import { QuestionType } from "engines/backends/schemas/bml";
import React from "react";
import { View } from "react-native";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { tr } from "utils";

export interface BMLSettingsMenuProps {
  type: SETTINGS;
  filterFunction?: Function;
  addFunction?: Function;
}

export enum SETTINGS {
  "FILTER" = "Filter",
  "ADDQUALIFICATION" = "AddQualification",
}

const BMLSettingsMenu = (props: BMLSettingsMenuProps) => {
  const { filterFunction: setFilterType, type, addFunction } = props;

  const { C } = useAppContext();

  const FilterMenu = () => {
    return (
      <View>
        <Menu onBackdropPress={() => setFilterType(null)}>
          <MenuTrigger>
            <Kitten.ViewH style={{ alignItems: "center" }}>
              <IconPrimr
                preset={"circular"}
                name={"filtermenu"}
                color={C.primary}
              />
            </Kitten.ViewH>
          </MenuTrigger>

          <MenuOptions
            customStyles={{
              optionText: { color: C.text },
              optionsContainer: { backgroundColor: C.surface },
            }}
          >
            <MenooOption
              title={tr("All")}
              iconName={"dots_horizontal"}
              onSelect={() => {
                setFilterType(null);
              }}
            />
            <MenooOption
              title={tr("Questions")}
              iconName={"text"}
              onSelect={() => {
                setFilterType(QuestionType.TEXT);
              }}
            />
            {/* <MenooOption
              title={tr("Videos")}
              iconName={"videocam"}
              onSelect={() => {
                setFilterType(QuestionType.VIDEO);
              }}
            /> */}
            <MenooOption
              title={tr("Messages")}
              iconName={"text"}
              onSelect={() => {
                setFilterType(QuestionType.ENCOURAGEMENT);
              }}
            />
          </MenuOptions>
        </Menu>
      </View>
    );
  };

  const AddQualification = () => {
    return (
      <Buttoon
        onPress={() => Poppy("Feature coming soon!!", "")}
        appearance="ghost"
        icon={{ name: "plus" }}
        status="primary"
      />
    );
  };

  try {
    switch (type) {
      case SETTINGS.FILTER:
        return <FilterMenu />;
        break;
      case SETTINGS.ADDQUALIFICATION:
        return <AddQualification />;
    }
  } catch (error) {
    throw Error(`::Qk1MU2V0dGluZ3NNZW51::${error}`);
  }
};

const MenooOption = ({
  onSelect,
  title,
  iconName,
}: {
  onSelect(): void;
  title: string;
  iconName: ICON_NAME;
}) => {
  const { C } = useAppContext();
  return (
    <MenuOption onSelect={() => onSelect && onSelect()}>
      <Kitten.ListItemv2
        disabled
        accessoryLeft={() => <IconPrimr name={iconName} color={C.text} />}
        title={title}
      />
    </MenuOption>
  );
};

export default BMLSettingsMenu;
