import firebase from "firebase"
import { Kitten, Poppy, Toasty, Txt } from "components/atoms";
import { ContactListItemDetails } from "components/molecules/members/ContactListItemDetails";
import {
  MyDaySection,
  MyJourneySection,
  ProfileSummarySection,
  StatsCreatorSection,
  TodoCenter,
  WidgetCalendar
} from "components/organisms";
import { AddContactCreator } from "components/organisms/contact-manager/AddContactCreatorDialog";
import { ContactCreatorCenter } from "components/organisms/members/ContactCreatorCenter";
import { TwoColumns, useTwoColumns } from "components/templates";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import React from "react";
import { ScrollView, View } from "react-native";
import * as AddCalendarEvent from "react-native-add-calendar-event";
import { IS_WEB, tr } from "utils";
import { useAppContext } from "../../engines/hooks/useAppContext";
import { useNavigator } from "../_navigation/navigation-utils";
import { ActionButton } from "./ActionButton";
import { ACTION_TYPE, FPATH, recordUnknownUserAction, recordUserAction, UserSchema, useUserActions, useUserProfile } from "engines";
interface dUserStatsScreen {
  // navigation: any;
  route: any;
}

/**
 * ### Shows your personal activity
 *
 *  @version 23.02.14
 *  @author  jm_francis
 **/
export function PersonalDashboardScreen(props: dUserStatsScreen) {
  const { route } = props;

  const { frbsUser } = useAppContext();
  const { notification, notificationData, uid, title } = route?.params || {};
  const Navigation = useNavigator();
  const { breakpointForTwoColumns, column1ContainerStyle } = useTwoColumns();

  const userHandler = useUserProfile()
  const [user, setUser] = React.useState<UserSchema>();
  React.useEffect(function updateUser() {
    userHandler.data && setUser(userHandler.data)
  }, [userHandler.data]);

  const refDidOpenStatsEntry = React.useRef<boolean>(false);
  React.useEffect(
    function shouldOpenStatsEntryImmediately() {
      if (notification && !refDidOpenStatsEntry.current) {
        refDidOpenStatsEntry.current = true;

        // video tracking
        if (notificationData?.title?.includes("just watched")) {
          // video tracking
          const contactName = notificationData?.title?.substring(0, notificationData?.title?.indexOf("just watched") - 1)
          // if (userD.roles?.includes(FRBS_ROLE.DEVELOPER)) alert(`Identified contact name as \"${contactName}\"`)
          firebase.firestore().collection(`${FPATH.USERS}/${frbsUser?.uid}/${FPATH.CONTACTS}`).where("name", "==", contactName).get().then(query => {
            if (query.docs?.length < 1) {
              Poppy("No contact found", "Could not find " + contactName + " in your account.")
              // if (userD?.roles?.includes(FRBS_ROLE.DEVELOPER)) alert("No contacts found for contactName")
              return;
            }
            const possibleContacts = query.docs.map(d => d.data() as ContactSchemaa)
            let contact = possibleContacts.find(c => c.videoWatches?.length > 0)
            if (!contact) contact = possibleContacts[0]
            Navigation.overlayPortal.open({
              headerTitle: "Contact Details",
              render: <ContactListItemDetails contact={contact} userId={frbsUser?.uid} />
            })
          })
        }
        else {
          // pull up daily stats
          setTimeout(() => {
            // having a short timeout here makes sure it all loads properly when coming from a push notification tap
            Navigation.dialogPortal.open({
              headerTitle: "Enter today's stats",
              render: <StatsCreatorSection />,
            });
          }, 200);
        }
      }
    },
    [notification]
  );

  const column2Content = [
    // {
    //   headerTitle: tr("Profile"),
    //   icon: "user",
    //   showTitle: false,
    //   render: () => <ProfileCorner />,
    //   hiddenIf: [!breakpointForTwoColumns],
    // },
    // //#region [section2] Contact Manager
    // {
    //   headerTitle: tr("Contact Manager"),
    //   showTitle: false,
    //   render: ({ badgeValueCallback }) => <FollowUpSection />,
    //   icon: "contacts",
    //   // hiddenIf: [breakpointForTwoColumns],
    // },
    // //#endregion
    {
      headerTitle: "New Contact",
      showTitle: true,
      render: ({ badgeValueCallback }) => <ContactCreatorCenter id="" />,
      icon: (
        <ActionButton
          title="New Contact"
          iconName="add_person"
          onPress={() => {
            return Navigation.overlayPortal.open({
              headerTitle: "Add contact",
              render: <AddContactCreator id={"id"} />,
            });
          }}
        />
      ),
      // icon: "person_add",
      hiddenIf: [breakpointForTwoColumns],
    },
    {
      headerTitle: "Add Event",
      showTitle: true,
      render: ({ badgeValueCallback }) => <ContactCreatorCenter id="" />,
      icon: (
        <ActionButton
          title="Add Event"
          iconName="event"
          onPress={() => {
            if (IS_WEB) {
              Toasty.show("Not currently available on web.", {
                type: "warning",
              });
              return;
            }
            const today = new Date();
            today.setHours(12);
            today.setMinutes(0);
            const oneHourLater = new Date();
            oneHourLater.setHours(today.getHours() + 1);
            oneHourLater.setMinutes(0);
            AddCalendarEvent.presentEventCreatingDialog({
              title: "",
              startDate: today.toISOString(),
              endDate: oneHourLater.toISOString(),
            })
              .then((evt) => {
                if (evt.action === "SAVED") {
                  Toasty.show("Event added", { type: "success" });
                  recordUnknownUserAction(frbsUser?.uid, ACTION_TYPE.SCHEDULED_MEETING, `${frbsUser?.uid} created a new meeting.`, { location: evt.calendarItemIdentifier })
                }
              })
              .catch((error: string) => {
                Toasty.show(error, { type: "danger" });
                console.error(error);
              });
          }}
        />
      ),
      // icon: "person_add",
      hiddenIf: [breakpointForTwoColumns],
    },
    {
      headerTitle: "Add Task",
      showTitle: true,
      render: ({ badgeValueCallback }) => <ContactCreatorCenter id="" />,
      icon: (
        <ActionButton
          title="Add Task"
          iconName="add_to_list"
          onPress={() => {
            return Navigation.overlayPortal.open({
              headerTitle: "Add Task",
              render: <TodoCenter />,
            });
          }}
        />
      ),
      // icon: "person_add",
      hiddenIf: [breakpointForTwoColumns],
    },
    //#region [section] Calendar
    {
      /** On bigger screen, it'll show Today's event on 2nd column */
      headerTitle: tr("Today's events"),
      showTitle: false,
      render: ({ badgeValueCallback }) => <WidgetCalendar />,
      icon: "calendar",
      hiddenIf: [!breakpointForTwoColumns, !frbsUser],
    },
    // {
    //   /** On smaller screen, it'll navigate to CalendarScreen */
    //   headerTitle: tr("Calendar"),
    //   showTitle: true,
    //   render: ({ badgeValueCallback }) => <CalendarScreen />,
    //   icon: "calendar",
    //   hiddenIf: [breakpointForTwoColumns],
    // },
    //#endregion
    //#region [section2] Todo Center
    // {
    //   headerTitle: tr("My Todo"),
    //   showTitle: false,
    //   render: ({ badgeValueCallback }) => <TodoCenter />,
    //   icon: "todolist",
    //   // hiddenIf: [breakpointForTwoColumns],
    // },
    //#endregion

    // {
    //   headerTitle: tr("Levels"),
    //   icon: "level",
    //   showTitle: true,
    //   render: () => <LevelsDashboardCenter memberId={uid} type="admin" />,
    // },
  ];
  //* Move this to StreaksCenter
  // if (!uid || uid === frbsUser?.uid)
  //   column2Content.push({
  //     headerTitle: tr("Enter today's stats"),
  //     icon: "plus",
  //     showTitle: false,
  //     render: () => <StatsCreatorSection />,
  //   });

  // consider making shortcut to edit daily stats activity types (if ADMIN)
  /**
 * {
    headerTitle: tr("Edit activity types"),
    icon: "pen",
    showTitle: false,
    render: () => <EditDailyStats />,
  }
 */

  return (
    <TwoColumns
      bottomInset
      column1Content={(scrollHandler) => (
        <ScrollView contentContainerStyle={column1ContainerStyle}>
          <View>
            {user ? <ProfileSummarySection scrollHandler={scrollHandler} userD={user} /> : <Kitten.Spinner />}
            {/* <TodoCenter /> */}
            {/* <DashboardCardGrid /> */}
            {/* <LevelsDashboardCenter memberId={frbsUser?.uid} type="user" /> */}
          </View>
          <MyDaySection />
          <MyJourneySection />
        </ScrollView>
      )}
      column2Content={column2Content}
    />
  );
}

{
  /* <UserStatsFeed uid={uid} title={title} /> */
}

export const Typewriter = ({ text, delay, style, children }) => {
  const [currentText, setCurrentText] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState(0);

  let timeout;

  React.useEffect(
    function textChanges() {
      if (!text.startsWith(currentText)) {
        clearTimeout(timeout);
        setCurrentText("");
        setCurrentIndex(0);
      }
    },
    [text]
  );

  React.useEffect(() => {
    if (currentIndex < text.length) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);
  // Typing logic goes here
  // return <Txt style={style}>{children}</Txt>;
  return <Txt.Indicator style={style}>{currentText}</Txt.Indicator>;
};

export default Typewriter;
