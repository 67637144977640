import { useAppContext } from "engines";
import * as React from "react";
import { ISkeletonContentProps } from "./constants";
import { ScreenSkeleton, SkeletonLayout } from "./presets/ScreenSkeleton";
// import SkeletonContent from "react-native-skeleton-content";
import SkeletonContent from "./skeleton-core";
// import { ISkeletonContentProps } from "react-native-skeleton-content/lib/Constants";

interface dSkeleton extends Partial<ISkeletonContentProps> {}

/**
 * ### Skeleton Wrapper with dynamic theme
 *  - Skeleton is the "loading" layout when component is getting data
 *  - Think of it as a fancy "LoadingActivity"
 *  - Docs: https://github.com/alexZajac/react-native-skeleton-content
 *  ----
 *  @example
 *  <Skeleton 
 *    isLoading
 *    layout={[
        { key: 'text-skltn', width: 220, height: 20, marginBottom: 6 },
      ]}>
 *    <Txt>This is the main content</Txt>
 *  </Skeleton>
 *  ----
 *  @version 21.03.12
 *  -  *Build a component*
 *  @author  K
 *
 **/
export function Skeleton(props: dSkeleton) {
  const { children } = props;
  const { C } = useAppContext();

  return (
    <SkeletonContent
      isLoading={true}
      boneColor={C.dim}
      highlightColor={C.surface}
      {...props}
    >
      {children}
    </SkeletonContent>
  );
}

Skeleton.Layout = SkeletonLayout;
Skeleton.Screen = ScreenSkeleton;
