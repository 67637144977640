import { IconPrimr } from "assets";
import { Avt, Kitten, MembersMultiInput } from "components";
import { useAppContext } from "engines";
import {
  ContactGroupSchemaa,
  ContactSchemaa
} from "engines/backends/schemas/bml";
import { BmlQSchema } from "engines/backends/schemas/bml/bml.schema";
import React from "react";
import { View } from "react-native";
import { SSBM } from "./StyledComponents.BML-Mobile";

export interface QuestionScreenProps {
  data: BmlQSchema;
  /** current screen */
  qualificationScreenIndex: number;
  /** index of this screen */
  thisIndex: number;
  /** when a contact gets added or removed let the higher level know */
  onContactsUpdate: (screenIndex: number, contacts: ContactSchemaa[]) => void;
}

export function QuestionScreen(props: QuestionScreenProps) {
  const { data, qualificationScreenIndex, thisIndex, onContactsUpdate } = props;
  const { C } = useAppContext();

  async function handleBMLContactsList(
    _contacts: (ContactSchemaa | ContactGroupSchemaa)[]
  ) {
    onContactsUpdate(thisIndex, _contacts as ContactSchemaa[]);
    /** no longer saving here it slows the app A LOT, saving in BMLMobile */
    // refBmlContacts.current = _contacts as ContactSchemaa[];
    // const contacts = _contacts as ContactSchemaa[];
    // const incomingContacts: ContactSchemaa[] = contacts.map(
    //   (c) =>
    //     ({
    //       ...c,
    //       _cid: c._cid || c.id,
    //       ownerUid: frbsUser?.uid,
    //       displayName: c.displayName || c.name,
    //       phoneNumbers: c.phoneNumbers,
    //       emails: c.emails,
    //     } as ContactSchemaa)
    // );
    // const existingContacts = bmlContacts ? bmlContacts : [];
    // const finalContacts = mergeArrays(
    //   incomingContacts,
    //   existingContacts,
    //   "_cid"
    // );
    // setBmlContacts(finalContacts);
  }

  try {
    return (
      <SSBM.Screen>
        <SSBM.QScreen key={data._qid}>{data.question}</SSBM.QScreen>
        {/* <Txt.Helper marginV>
          {"*Only contacts with name and phone # will be shown."}
        </Txt.Helper> */}
        <MembersMultiInput
          keepInputAtTop
          hidePlusButton
          // suggestionsYOffset={Platform.OS === "ios" ? 0 : 0}
          title=""
          containerStyle={
            {
              // width: "95%",
            }
          }
          tagContainerStyle={{ width: "100%" }}
          disableGroups
          inputProps={{ autoFocus: false, placeholder: "Type a name..." }}
          mode="sms"
          onRecipientsCallback={(recips) => handleBMLContactsList(recips)}
          renderTag={(tag, onPress) => {
            const tagName =
              (tag as ContactSchemaa)?.name ||
              (tag as ContactSchemaa)?.displayName ||
              "";
            return (
              <View style={{ width: "100%" }}>
                <Kitten.ListItemv2
                  title={tagName}
                  accessoryLeft={() => (
                    <Avt name={tagName} source={{ uri: "" }} />
                  )}
                  accessoryRight={() => (
                    <IconPrimr
                      name={"x"}
                      preset={"circular"}
                      onPress={() => onPress(tag)}
                      color={C.primary}
                    />
                  )}
                />
                <Kitten.Divider />
              </View>
            );
          }}
        />
      </SSBM.Screen>
    );
  } catch (error) {
    throw Error(`::UXVlc3Rpb25TY3JlZW4=::${error}`);
  }
}
