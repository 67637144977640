import { useRoute } from "@react-navigation/native";
import { IconPrimr, ICON_NAME } from "assets";
import { Buttoon, Kitten, sstyled, Txt } from "components";
import { Avt, dButtoon, Skeleton } from "components/atoms";
import { HierarchyList } from "components/molecules";
import { useTwoColumns } from "components/templates";
import {
  ConfigVariablesSchema,
  FRBS_ROLE,
  MediaPageSchema,
  useAppContext,
  usePageDocument
} from "engines";
import { getAppLogoFromTitle, useConfigCollection } from "engines/firebase/handler/config-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import firebase from "firebase";
import * as React from "react";
import { Pressable, ScrollView, TextInput, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  MainStackCollection,
  MAIN_STK,
  NAVIGATION_ROUTE,
  useNavigator
} from "screens";
import { dResourcesBodyScreenParam } from "screens/_navigation/media.navigator";
import { IS_WEB, scale, spacing, tr } from "utils";
import { ass, ASS_KEY } from "utils/storage";
import { useActivityCollection } from "../../../engines/firebase/handler/activity-handler";
import { useConfigDocument } from "../../../engines/firebase/handler/config-handler";
import { ProfileAndActionsCenter } from "./ProfileAndActionsCenter";
interface dNavigationBar {
  key: string;
  title: string;
  route: NAVIGATION_ROUTE;
  icon: ICON_NAME;
  color: string;
}
/**
 * ### Top Navigation Bar for StackNavigator (🌐)
 *  - If we use StackNavigator for main.navigator,
 *  - this bar will be used to navigate between main screens
 *  ----
 *  @version 22.01.24
 *  -  *Build a component*
 *  @author  K
 *
 **/
export function NavigationBar() {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const userProfile = useUserProfile();
  const { breakpointForTwoColumns } = useTwoColumns();
  const route = useRoute();
  const handlerPage = usePageDocument(userProfile?.data?.baseShopId);
  //#region [section] base shop setup
  const [bsItem, setBsItem] = React.useState<MediaPageSchema>();
  const [bsLoading, setBsLoading] = React.useState<boolean>(true);

  const fetchData = async () => {
    // console.log("Function is called when ever we join a bs");
    if (!userProfile?.data?.baseShopId) {
      setBsLoading(false);
      setBsItem(null);
    } else {
      setBsLoading(false);
      setBsItem(handlerPage?.data);
    }
  };

  React.useEffect(() => {
    // declare the data fetching function

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [userProfile?.data]);
  //#endregion

  //#region [section2] skeleton component

  let SkeletonNaviItem = ({ children, ...rest }) => (
    <Skeleton
      isLoading={userProfile.loading}
      boneColor={C.grey500}
      highlightColor={C.grey600}
      layout={[
        {
          key: "text-skltn",
          width: scale(24),
          height: scale(24),
          margin: spacing(4),
        },
      ]}
      {...rest}
    >
      {children}
    </Skeleton>
  );
  //#endregion

  return (
    <ScrollView horizontal bounces={false}>
      <Kitten.ViewH style={{ paddingRight: spacing(3) }}>
        {Object.entries(MainStackCollection).map(
          ([name, _route]: [
            MAIN_STK,
            { options: { title: string; _icon: ICON_NAME } }
          ]) => {
            const enabled = route.name == name;

            //#region [section] sytling for navigation items
            let buttoonProps: dButtoon & { label: string } = {
              label: _route.options.title || name,
              icon: { name: _route.options._icon },
              // size: breakpointIndex > 2 ? "giant" : "medium",
              size: "medium",
              appearance: "ghost",
              style: { borderRadius: 56 },
              status: enabled ? "primary" : "basic",
              onPress: () => {
                Navigation.navigate(name);
                // Navigation.dispatch(StackActions.replace(_route.route));
              },
            };
            //#endregion

            switch (name) {
              case "BaseShops":
                //* This checks if the user has joined a BS and updates the navigation bar
                return (
                  <SkeletonNaviItem isLoading={bsLoading} key={name}>
                    {bsItem ? (
                      <Kitten.Button
                        // {...buttoonProps}
                        key={name}
                        style={{ borderRadius: 56 }}
                        // size={breakpointIndex > 2 ? "giant" : "medium"}
                        size={"medium"}
                        appearance={"ghost"}
                        // label={{ name }}
                        // icon={{ name: _route.options._icon }}
                        status={enabled ? "primary" : "basic"}
                        onPress={() => {
                          Navigation.navigate<dResourcesBodyScreenParam>(
                            "BaseShops",
                            {
                              cpid: bsItem?._id,
                              title: bsItem?.name || tr("Untitled"),
                            }
                          );
                        }}
                        accessoryLeft={() => (
                          <Avt
                            size="tiny"
                            source={{
                              uri: bsItem?.mediaItem?.logo,
                            }}
                            name={bsItem?.name}
                          />
                        )}
                      />
                    ) : (
                      <Buttoon
                        // icon={{ name: _route.icon }}
                        // size={breakpointIndex > 2 ? "giant" : "medium"}
                        // appearance={"ghost"}
                        // style={{ borderRadius: 56 }}
                        // status={enabled ? "primary" : "basic"}
                        // key={name}
                        // onPress={() => {
                        //   _route.route == "coming-soon"
                        //     ? Toasty.show(tr("Base shop area coming soon"))
                        //     : Navigation.navigate(_route.route);
                        //   // Navigation.dispatch(StackActions.replace(_route.route));
                        // }}
                        {...buttoonProps}
                        key={name}
                      >
                        {
                          /** Try no-show label */
                          breakpointForTwoColumns && buttoonProps.label
                        }
                      </Buttoon>
                    )}
                  </SkeletonNaviItem>
                );
                break;
              case "Home":
                return (
                  <Kitten.ViewH>
                    {/* <ViewTeamLogo /> */}
                    <Kitten.Button
                      {...buttoonProps}
                      key={name}
                      accessoryLeft={() => (
                        <ViewTeamLogo onPress={buttoonProps.onPress} />
                      )}
                      appearance="ghost"
                    >
                      {breakpointForTwoColumns && tr("Home")}
                    </Kitten.Button>
                  </Kitten.ViewH>
                );
                break;
              case "UserDashboard":
                return (
                  <Kitten.Button
                    {...buttoonProps}
                    key={name}
                    accessoryLeft={() => (
                      <Kitten.ViewH>
                        <IconPrimr
                          name="bars"
                          color={C.text01}
                          containerStyle={{ marginRight: spacing(4) }}
                        />
                        <View style={{ position: "absolute", left: -1 }}>
                          <ViewUserCorner showInfo={false} />
                        </View>
                      </Kitten.ViewH>
                    )}
                    accessoryLeft={() => <ViewUserCorner showInfo={false} />}
                    appearance="ghost"
                  >
                    {breakpointForTwoColumns && tr("You")}
                  </Kitten.Button>
                );
                break;
              // case "Tools":
              //   /**
              //    *  This checks if the user is admin or not.
              //    *  - If yes, show Admin Controls screen,
              //    *  - else show Members screen
              //    */
              //   let roleProps: dButtoon & { label: string } = {
              //     icon: { name: userProfile?._isUserAdmin() ? "admin" : "users" },
              //     label: userProfile?._isUserAdmin()
              //       ? tr("Admin Controls")
              //       : tr("Members"),
              //   };

              //   return (
              //     <SkeletonNaviItem isLoading={userProfile.loading} key={name}>
              //       <Buttoon {...buttoonProps} {...roleProps}>
              //         {
              //           /** Try no-show label */
              //           1 == 2 ? roleProps.label : ""
              //         }
              //       </Buttoon>
              //     </SkeletonNaviItem>
              //   );
              //   break;
              default:
                return (
                  <Buttoon {...buttoonProps} key={name}>
                    {
                      /** Try no-show label */
                      breakpointForTwoColumns && buttoonProps.label
                    }
                  </Buttoon>
                );
                break;
            }
          }
        )}
      </Kitten.ViewH>
    </ScrollView>
  );
}

/**
 * ###  User Avatar on the top left corner of the screen
 * -  Tap to toggle "personal drawer"
 * @returns
 */
export function ViewUserCorner(props: { showInfo: boolean }) {
  const { showInfo = true } = props;
  const { C, frbsUser, teamId } = useAppContext();
  const { data: userD, ...userProfile } = useUserProfile();
  // const { data: pagesD } = usePagesCollection();
  // const { data: posstsD } = usePosstsCollection();
  // const { data: configsD } = useConfigCollection();
  // const [appConfig, setAppConfig] = React.useState<ConfigVariablesSchema>(null);
  const configHandler = useConfigDocument(`variables-${teamId}`);
  const activityHandler = useActivityCollection(frbsUser?.uid);
  // const Navigation = useNavigator();
  const [nameInput, setNameInput] = React.useState<string>("");
  const [highestStreak, setHighestStreak] = React.useState<number>(0);

  // React.useEffect(
  //   function _setAppConfig() {
  //     configsD && setAppConfig(configsD.find((a) => a._teamId === teamId));
  //   },
  //   [configsD]
  // );

  React.useEffect(
    function getHighestStreak() {
      if (!configHandler.data?.activities || !activityHandler.data || !userD)
        return;
      setHighestStreak(
        activityHandler._getHighestStreak(
          activityHandler.data,
          configHandler.data?.activities,
          userD
        )
      );
    },
    [activityHandler.data, configHandler.data, userD]
  );

  return (
    <Skeleton
      isLoading={userProfile.loading}
      boneColor={C.grey500}
      highlightColor={C.grey600}
      layout={[
        {
          key: "text-skltn",
          width: scale(39),
          height: scale(39),
          // marginLeft: spacing(3),
          borderRadius: 40,
        },
      ]}
    >
      {/* <Pressable
        onPress={() => Navigation.dispatch(DrawerActions.toggleDrawer())}
      > */}
      <Kitten.ViewH>
        <View>
          <Avt
            style={{
              borderWidth: 1.1,
              borderColor: C.text,
              width: 32,
              height: 32,
            }}
            // size={36}
            source={{ uri: userD?.personali?.photoURL }}
            name={userD?.personali?.displayName || "# #"}
          />
          {/* <View
              style={{
                position: "absolute",
                bottom: -11,
                left: -10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ViewTeamLogo />
            </View> */}

          {highestStreak > 0 ? (
            <View
              style={{
                position: "absolute",
                bottom: -11,
                right: -10,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconPrimr name="bolt" size={18} color={C.adminGold} />
              <Txt.P2
                style={{
                  color: C.adminGold,
                  fontWeight: "bold",
                  marginLeft: -3,
                }}
              >
                {highestStreak}
              </Txt.P2>
            </View>
          ) : null}
        </View>
        {/* {(!pagesD || !posstsD || !configsD) && <Txt></Txt>} */}
        {showInfo ? (
          <View style={{ marginLeft: spacing(4) }}>
            {userD?.personali?.displayName ? (
              <Txt.H6>{userD?.personali?.displayName || "           "}</Txt.H6>
            ) : (
              <Kitten.Input
                size={"small"}
                placeholder="Insert your name"
                value={nameInput}
                onChangeText={setNameInput}
                onSubmitEditing={() =>
                  userProfile.update({
                    personali: {
                      ...userD?.personali,
                      displayName: nameInput,
                    },
                  })
                }
              />
            )}
            <Txt style={{ color: C.grey500 }}>{userD?.personali?.email}</Txt>
          </View>
        ) : null}
      </Kitten.ViewH>
      {/* </Pressable> */}
    </Skeleton>
  );
}

export const DevRolesBar = () => {
  const { C } = useAppContext();
  const { data: userD, ...userProfile } = useUserProfile();

  function onRoleToggle(role: FRBS_ROLE) {
    Array.isArray(userD?.roles) && userD?.roles?.includes(role)
      ? userProfile.update({
        roles: firebase.firestore.FieldValue.arrayRemove(role),
      })
      : userProfile.update({
        roles: firebase.firestore.FieldValue.arrayUnion(role),
      });
  }

  const [bsid, setBsid] = React.useState<string>("");
  const [isBSOwner, setIsBSOwner] = React.useState<boolean>(false);

  React.useEffect(
    function checkBSOwnerRole() {
      let bsRole =
        Array.isArray(userD?.roles) &&
        userD?.roles?.filter((role) => role.includes("BS:"))[0];

      if (bsRole) {
        setIsBSOwner(true);
        setBsid(bsRole.replace("BS:", ""));
      } else {
        setBsid("");
        setIsBSOwner(false);
      }
    },
    [userD?.roles]
  );
  const insets = useSafeAreaInsets();
  return (
    <Kitten.ViewH
      style={[
        {
          justifyContent: "space-between",
          backgroundColor: C["color-danger-300"],
          paddingBottom: insets.bottom,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          alignSelf: "center",
          backgroundColor: userD?.roles?.includes(FRBS_ROLE.ADMIN)
            ? C.adminGold
            : "transparent",
        }}
      >
        <A.TxtDevRoles onPress={() => onRoleToggle(FRBS_ROLE.ADMIN)}>
          A
        </A.TxtDevRoles>
      </View>
      <Kitten.ViewH
        style={{
          flex: 2,
          justifyContent: "center",
          backgroundColor: isBSOwner ? C.infoBlue : "transparent",
        }}
      >
        <A.TxtDevRoles>BS:</A.TxtDevRoles>
        {isBSOwner ? (
          <A.TxtDevRoles>
            {bsid}.{" "}
            <A.TxtDevRoles onPress={() => onRoleToggle("BS:" + bsid)}>
              Remove?
            </A.TxtDevRoles>
          </A.TxtDevRoles>
        ) : (
          <TextInput
            value={bsid}
            onChangeText={setBsid}
            onSubmitEditing={() => onRoleToggle("BS:" + bsid)}
            placeholder={"bsid"}
            style={{
              margin: spacing(2),
              flex: 0.5,
              flexShrink: 1,
              backgroundColor: "hotpink",
            }}
          />
        )}
      </Kitten.ViewH>
      <View
        style={{
          flex: 1,
          alignSelf: "center",
          backgroundColor: userD?.roles?.includes(FRBS_ROLE.DEVELOPER)
            ? C.devPink
            : "transparent",
        }}
      >
        <A.TxtDevRoles onPress={() => onRoleToggle(FRBS_ROLE.DEVELOPER)}>
          D
        </A.TxtDevRoles>
      </View>
    </Kitten.ViewH>
  );
};

/**
 * ###  Team Logo, displaying at the center of navigation bar
 * -  Tap to show Hierarchy Info
 * -  For dev, it shows option to switch hierarchy
 * @returns
 */
export function ViewTeamLogo(p: { onPress?: () => void }) {
  const { onPress } = p;
  const { C, teamId, setTeamId, theme, setTheme } = useAppContext();
  // const configHandler = useConfigDocument(`variables-${teamId}`);
  const userProfile = useUserProfile();
  // const configHandler = useConfigCollection();
  const configHandler = useConfigDocument(`variables-${teamId}`)
  const appConfig = configHandler.data as ConfigVariablesSchema
  // const appConfig = configHandler._getConfigFromSubDomain(teamId); //_getConfigFromSubDomain(teamId)
  const Navigation = useNavigator();

  //#region [functions]

  function onHierarchySelect(item: ConfigVariablesSchema) {
    //* Remove Base shop info (BSIDINFO) from aysncstorage
    let removeBSID = ass.remove(ASS_KEY.BSIDINFO);
    removeBSID && userProfile.update({ baseShopId: "" }); //* Leave any joined BS
    setTeamId(item._teamId);
    setTimeout(() => {
      Navigation.goBack();
      Navigation.goBack();
    }, 2000);
  }

  function onHierarchySwitch() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Select hierarchy to switch to"),
      render: (
        <View>
          {/* <ProfileAndActionsCenter showNewStartHere={false} /> */}
          <Txt.Helper marginV>{tr("Select hierarchy to switch to")}</Txt.Helper>
          <HierarchyList
            onHierarchySelect={onHierarchySelect}
            hierarchiesToBeExcluded={[teamId]}
            showAllTeams
          />
        </View>
      ),
    });
  }

  function onHierarchyInfo() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Select hierarchy to switch to"),
      render: (
        <View>
          <ProfileAndActionsCenter showNewStartHere={false} />
        </View>
      ),
    });
  }

  //#endregion

  return (
    <Skeleton
      isLoading={configHandler.loading}
      boneColor={C.primary}
      highlightColor={C.primaryDarker}
      layout={[
        {
          key: "text-skltn",
          width: scale(29),
          height: scale(29),
          borderRadius: scale(4),
        },
      ]}
      containerStyle={[
        !IS_WEB && { paddingBottom: spacing(4) },
        { alignItems: "center" },
      ]}
    >
      <Pressable
        onPress={onPress}
        onLongPress={() =>
          userProfile._isDeveloper() ? onHierarchySwitch() : onHierarchyInfo()
        }
      >
        <Avt
          size={29}
          shape="rounded"
          name={appConfig?.appTitle || "N K"}
          source={
            { uri: getAppLogoFromTitle(appConfig?.appTitle) }
            // teamId == "app-takeoff-team"
            //   ? img.appLogo
            //   : {
            //       uri: appConfig?.appLogo,
            //     }
          }
        />
        {/* <Txt.C2 style={{ textAlign: "center" }}>
          {version ? version : "No version found"}
        </Txt.C2> */}
      </Pressable>
    </Skeleton>
  );
}

const A = {
  TxtDevRoles: sstyled(Txt.Indicator)({
    color: "black",
  }),
};
