import { Txt } from "components";
import { Browsers } from "components/molecules/custom-media/Browser/Browser";
import { NotificationComments } from "components/molecules/notifications/NotificationComments";
import { ContactsHomeCenter } from "components/organisms";
import { MemberScreen } from "components/organisms/members/MemberScreen";
import { MemberView } from "components/organisms/members/MemberView";
import { MediaPageItemSchema, ScoreboardSchema } from "engines";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { View } from "react-native";
import { BroadcastScreen } from "screens/broadcast/BroadcastScreen";
import { CalendarScreen } from "screens/calendar/CalendarScreen";
import { ContactsScreen } from "screens/contacts-scr/ContactsScreen";
import { ContactSearch } from "screens/contacts-scr/contact_search/ContactSearch";
import { NotificationsPermissionPage } from "screens/permissions/NotificationsPermissionPage";
import { PublicContent } from "screens/public-content/PublicContent";
import { ScheduledPossts } from "screens/scheduled-possts/ScheduledPossts";
import { ScoreboardScreen } from "screens/scoreboard/ScoreboardScreen";
import { ViewAllScoreboards } from "screens/scoreboard/ViewAllScoreboards";
import { StatsScreen } from "screens/stats-scr/StatsScreen";
import { ActionsGroupScreen } from "screens/team-mates/ActionsGroupScreen";
import { ActionsFeed, TeamMatesScreen } from "screens/team-mates/TeamMatesSceen";
import { TextTemplatesScreen } from "screens/text-templates/TextTemplatesScreen";
import { ToolsHomeScreen } from "screens/tools-home-scr/ToolsHomeScreen";
import { UserScreen } from "screens/user/UserScreen";
import { TelegramChats } from "./tests/TelegramChats";

/**
 * ###  A Navigator for Home's Utilities Screens
 * -  That is, we don't have "Home" here
 * (it belongs to MainNavigator)
 * -  Instead, this is the stack of Home's related utilities
 * (create a board, create a post)
 * -  Locating in root-navigator,
 * it'll animate ABOVE MainNavigator's bottom tab,
 * making more sense for UX
 * ---
 * @version 1.1.17
 * -  *Remove Home*
 * -  *Add sboard-creator-scr*
 * @author K
 */
export const HomeStackCollection = {
  "content-item": {
    component: PublicContent,
    options: {
      title: "",
    },
  },
  "sboard-scr": {
    component: ScoreboardScreen,
    options: {
      title: "Scoreboard",
    },
  },
  ViewAllScoreboards: {
    component: ViewAllScoreboards,
    options: {
      title: "Scoreboards",
    },
  },
  Teammates: {
    component: TeamMatesScreen,
    options: {
      title: "Teammates",
    },
  },
  MemberScreen: {
    component: MemberScreen,
    options: {
      title: "Member"
    }
  },
  ActionsGroup: {
    component: ActionsGroupScreen,
    options: {
      title: "Activity Breakdown"
    }
  },
  TextTemplates: {
    component: TextTemplatesScreen,
    options: {
      title: "Text Templates"
    }
  },
  "Contact Manager": {
    component: ContactManagerScreen,
    options: {
      title: "Contacts",
    },
  },
  "calendar-home-scr": {
    component: CalendarScreen,
    options: { title: "Calendar" },
  },
  "scheduled-possts": {
    component: ScheduledPossts,
    options: { title: "Announcements" },
  },
  "Contact Group Screen": {
    component: ContactsScreen,
    options: {
      title: "Contact Manager",
    },
  },
  "Future Follow Ups": {
    component: ContactsScreen,
    options: {
      title: "Upcoming Follow Ups",
    },
  },
  "contacts-search": {
    component: ContactSearch,
    options: {
      title: "Phone Contacts",
    },
  },
  chat: {
    component: TelegramChats,
    options: {
      title: "Chats",
    },
  },
  "Admin Portal": {
    component: ToolsHomeScreen,
    options: {
      title: "Admin"
    },
  },
  "Team Activity": {
    component: ActionsFeed,
    options: {
      title: "Team Activity"
    }
  },
  stats: {
    component: StatsScreen,
    options: {
      title: "Activity Stats",
    },
  },
  "profile-settings": {
    component: UserScreen,
    title: "Profile Settings",
  },
  // "user-stats": {
  //   component: UserStatsFeed,
  // },
  DisabledApp: {
    component: () => (
      <View style={{ flex: 1 }}>
        <Txt>
          App disabled, visit apptakeoff.com to learn how to resume this app or
          start your own.
        </Txt>
      </View>
    ),
    options: { headerShown: false },
  },
  broadcast: {
    component: BroadcastScreen,
    options: {
      title: "Broadcast a Message",
    },
  },
  browser: {
    component: Browsers,
    options: {
      title: "In app browser",
    },
  },
  "notification-permission": {
    component: NotificationsPermissionPage,
    options: {
      title: "Notification Permission",
    },
  },
  "notification-comments": {
    component: NotificationComments,
    options: {
      title: "Notification Comments",
    },
  },
};

interface P {
  route?: { params?: { listData: any; notification: any } };
}
function ContactManagerScreen(props: P) {
  const { params } = props.route;
  // console.log("props?.route?.params ", params);
  return (
    <ContactsHomeCenter
      sharedITems={params?.listData}
      goDirectlyToFollowUps={params?.notification}
    />
  );
}

export type HOME_STK = keyof typeof HomeStackCollection;

export interface dSboardScrParams {
  sbid: ScoreboardSchema["_sbid"];
}

export interface dEditTemplateScrParams {
  docID: string; //* Should be `TemplateSchema["TemplateID"]` , but since Chuks uses different one so this is deprecated right now
  // item: TemplateSchema;
}

export interface dContactsScrParams {
  id: string;
  team?: boolean;
  c_uid?: string;
  sharedItems?: MediaPageItemSchema[];
  /** If this is for the follow up contacts list */
  followUpContacts?: ContactSchemaa[];
  futureFollowUpsOnly?: boolean;
}
