import { Buttoon, Kitten, Rush, Txt } from "components/atoms";
import {
  dMemberInfo,
  dPersonaliEditCenter,
  dScoreboardCenter,
  MemberInfo,
  PersonaliEditCenter,
  ScoreboardCenter
} from "components/organisms";
import { useAppContext } from "engines";
import * as React from "react";
import { Platform, ScrollView, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { AgendaCenter } from "screens/calendar/AgendaCenter";
import { WelcomeScreen } from "screens/welcome/WelcomeScreen";
import { spacing, tr } from "utils";
import { useNavigator, useNavigatorPresetConfig } from "./navigation-utils";

/**
 * List of keys for screens used in Layover
 * ---
 * - URL friendly
 */
export enum LAYOVER {
  "WelcomeScreen",
  "AgendaCenter",
  "ScoreboardCenter",
  "MemberInfo",
  "PersonaliEditCenter",
}

const LAYOVER_CONTENTS = ({ _key, ...params }) => {
  return {
    [LAYOVER.WelcomeScreen]: { render: <WelcomeScreen {...params} /> },
    [LAYOVER.AgendaCenter]: { render: <AgendaCenter {...params} /> },
    [LAYOVER.ScoreboardCenter]: {
      render: <ScoreboardCenter {...(params as dScoreboardCenter)} />,
    },
    [LAYOVER.MemberInfo]: {
      render: <MemberInfo {...(params as dMemberInfo)} />,
    },
    [LAYOVER.PersonaliEditCenter]: {
      render: <PersonaliEditCenter {...(params as dPersonaliEditCenter)} />,
    },
  };
};

/** (WIP) */
export function LayoverNavigator({ route }: dLayoverStack<{}>) {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const _params = route?.params;

  const { overlayStackConfig } = useNavigatorPresetConfig();
  const inset = useSafeAreaInsets();
  const [ready, setReady] = React.useState<boolean>(false);
  React.useLayoutEffect(
    function layouting() {
      Navigation.setOptions(overlayStackConfig.screenOptions);
      setTimeout(() => {
        setReady(true);
      }, 600);
    },
    [Navigation]
  );
  return !ready ? (
    <View
      style={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Rush.Bar indeterminate style={{ width: 200, height: 20 }} />
      <Txt style={{ textAlign: "center" }}>{tr("Loading...")}</Txt>
    </View>
  ) : (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        borderWidth: 0,
        borderRadius: 0,
        paddingBottom: inset.bottom + spacing(4),
      }}
      contentContainerStyle={{ width: "100%" }}
    >
      {"debug" === "nah" && (
        <Kitten.Card
          // disabled
          style={{ borderWidth: 1, borderColor: C.devPink }}
        >
          <Txt
            style={{
              fontFamily: Platform.OS == "ios" ? "Courier" : "monospace",
            }}
          >
            {JSON.stringify(_params, null, "\t")}
          </Txt>
          <ScrollView horizontal>
            <Kitten.ButtonGroup status={"basic"} size={"small"}>
              <Buttoon
                style={{
                  backgroundColor:
                    _params?._key === LAYOVER.AgendaCenter && C.primary,
                }}
                onPress={() => {
                  Navigation.layover({
                    _key: LAYOVER.AgendaCenter,
                  });
                }}
              >
                {tr("TO agenda center")}
              </Buttoon>
              <Buttoon
                style={{
                  backgroundColor:
                    _params?._key === LAYOVER.ScoreboardCenter && C.primary,
                }}
                onPress={() => {
                  Navigation.layover<{ sbid: string }>({
                    _key: LAYOVER.ScoreboardCenter,
                    sbid: "1wO2Lf6cggstcWyljVhS",
                  });
                }}
              >
                {tr("To scoreboard center")}
              </Buttoon>
              <Buttoon
                style={{
                  backgroundColor:
                    _params?._key === LAYOVER.MemberInfo && C.primary,
                }}
                onPress={() => {
                  Navigation.layover<dMemberInfo>({
                    _key: LAYOVER.MemberInfo,
                    memberId: "S6MqP8RD7UPlaG7dfecj7kq9c7e2",
                    type: "admin",
                  });
                }}
              >
                {tr("To Member Info")}
              </Buttoon>
              <Buttoon
                style={{
                  backgroundColor:
                    _params?._key === LAYOVER.PersonaliEditCenter && C.primary,
                }}
                onPress={() => {
                  Navigation.layover<dPersonaliEditCenter>({
                    _key: LAYOVER.PersonaliEditCenter,
                    memberId: "",
                  });
                }}
              >
                {tr("To Personali Edit Center")}
              </Buttoon>
            </Kitten.ButtonGroup>
          </ScrollView>
        </Kitten.Card>
      )}
      {_params?._key in LAYOVER_CONTENTS(_params) ? (
        LAYOVER_CONTENTS(_params)[_params?._key].render
      ) : (
        <Txt.Indicator marginV>
          {`_key "${_params?._key}" nor found in LAYOVER_CONTENTS`}
        </Txt.Indicator>
      )}
    </ScrollView>
  );
}

export type dLayoverParams<P> = {
  _key: LAYOVER;
} & P;

interface dLayoverStack<P> {
  route: { params: dLayoverParams<P> };
}
