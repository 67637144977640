/**
 * ### Extract style config string from paragraph string of `pageContent` doc
 *  - Page's buttoon item offers multiple stylings,
 * indicated by codes stored in paragraph field of `pageContent` doc
 *  - This fn will extract those styling so it'll help render the right style
 *  @param paragraph  paragraph string
 *  @param column   0 for buttoon's color; 1 for buttoon's appearance
 *  ----
 *  @example
 *  let _colorCode = fn.js.styleSpilt(updates ? updates.paragraph : null, 0);
 *  let _appearanceCode = fn.js.styleSpilt(updates ? updates.paragraph : null, 1);
 *  ----
 *  @version 21.08.27
 *  -  *Documenting*
 *  @author  @nguyenkhooi
 *
 **/
export function styleSpilt(paragraph: string, column: 0 | 1) {
  let _splitedData =
    paragraph && paragraph.includes("stylecode:")
      ? paragraph.split(":").pop().split("-")[column] //* e.g stylecode:1-4 --> returns 0
      : "0";

  return _splitedData;
}
