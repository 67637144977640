/**
 * ### 
 *  - Detailed explanation (if any)
 *  ----
 *  @example 
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.10.15
 *  -  *Brief changelog*
 *  @author  Nl
 *  
 **/

 export function getBaseshopId(value) {
    let val = value.split(":");
    return val[1];
  }
  