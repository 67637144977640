import { IconPrimr } from "assets";
import { Avt, ItemSwipeable, Kitten, Poppy, Toasty, Txt } from "components";
import {
  MediaPageItemSchema,
  useAppContext,
  useContactsCollection
} from "engines";
import {
  ContactGroupSchemaa,
  ContactSchemaa
} from "engines/backends/schemas/bml";
import { useContactGroupDocument } from "engines/firebase/handler/contact-groups-handler";
import React from "react";
import { Pressable } from "react-native";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { dContactsScrParams, useNavigator } from "screens";
import { scale, tr } from "utils";

// const today = new Date();

export function ContactsHomeItem(props: dContactsHomeItem) {
  const { item, sharedITems, followUpContacts } = props;
  const { C } = useAppContext();
  const { frbsUser } = useAppContext();
  const Navigation = useNavigator();
  const refItemSwipeable = React.useRef<Swipeable>();

  const contactGroupHandler = useContactGroupDocument(frbsUser?.uid, item?._id);

  const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);
  // const [futureFollowUpContacts, setFutureFollowUpContacts] = React.useState<
  //   ContactSchemaa[]
  // >([]);
  const contactHandler = followUpContacts
    ? { data: null }
    : useContactsCollection(frbsUser?.uid, item?._id);

  // const [contacts, setContacts] = React.useState<ContactSchemaa[]>([]);

  React.useEffect(
    function setupData() {
      // console.log("contactHandler.data? ", contactHandler.data);
      if (followUpContacts) {
        const endOfToday = new Date();
        endOfToday.setUTCHours(23, 59, 59, 999);
        setContacts(
          followUpContacts.filter(
            (c) =>
              c.followUpDate?.toDate && c.followUpDate?.toDate() <= endOfToday
          )
        );
      } else {
        // NOTE: filtering here probably is no longer necessary cuz hook takes care of this
        contactHandler.data &&
          setContacts(
            contactHandler.data?.filter(
              (c) =>
                c?.groups?.some((group) => group == item?._id) ||
                c?.listId === item?._id
            )
          );
      }
      // console.log("contactGroupHandler ", contactGroupHandler.data);
    },
    [contactHandler.data, contactGroupHandler.data, followUpContacts]
  );

  //#region [functions]
  function onShare() {
    Toasty.show(tr("Coming to you soon"));
  }
  function onDelete() {
    Poppy(
      "Final Check",
      "Are you sure you want to delete this group?",
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () => contactGroupHandler.deleteDocument(),
      }
    );
  }

  //#endregion

  return (
    <ItemSwipeable
      ref={refItemSwipeable}
      rightActions={
        followUpContacts
          ? []
          : [
              {
                icon: { name: "share" },
                status: "info",
                onPress: onShare,
              },
              {
                icon: { name: "trash" },
                status: "danger",
                onPress: onDelete,
              },
              {
                label: tr("Close"),
                size: "tiny",
                appearance: "ghost",
                onPress: () => refItemSwipeable.current?.close(),
              },
            ]
      }
    >
      <Kitten.ListItemv2
        title={
          followUpContacts
            ? () => (
                <Txt.S2
                  style={{ color: C.adminGold, fontWeight: "bold" }}
                >{`You have ${contacts.length} contact${
                  contacts.length > 1 ? "s" : ""
                } that need${
                  contacts.length > 1 ? "" : "s"
                } to be followed up with today.`}</Txt.S2>
              )
            : item?.name
        }
        style={
          contactGroupHandler.data?.name == "*New Contact Group" && {
            backgroundColor: C.errorRed,
          }
        }
        onPress={() => {
          Navigation.navigate<dContactsScrParams>("Contact Group Screen", {
            id: item?._id,
            sharedItems: sharedITems,
            followUpContacts,
          });
        }}
        description={() =>
          contacts.length == 0 ? (
            <Txt.Helper marginV style={{ textAlign: "left" }}>
              {tr("No contacts")}
            </Txt.Helper>
          ) : (
            <Avt.Row
              data={contacts.map((contact) => ({
                name: contact?.displayName || contact?.email,
                source: {
                  uri: "",
                },
              }))}
            />
          )
        }
        accessoryRight={() => (
          <Pressable
            onPress={() => refItemSwipeable.current?.openRight()}
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconPrimr
              name="dots_vertical"
              color={C.text}
              size={scale(18)}
              preset="safe"
            />
          </Pressable>
        )}
      />
    </ItemSwipeable>
  );
}

interface dContactsHomeItem {
  index: number;
  /** Should always be provided unless followUps is provided instead */
  item?: ContactGroupSchemaa;
  sharedITems?: MediaPageItemSchema[];
  /** If this is for the follow ups list, which is unique */
  followUpContacts?: ContactSchemaa[];
}
