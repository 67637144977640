//#region [import]

import { Buttoon, Toasty } from "components";
import {
  DocumentCreatorWithTitle,
  PagesHomeFeed,
  ShortcutList,
} from "components/organisms";
import { TwoColumns, useTwoColumns } from "components/templates";
import { FRBS_ROLE, useAppContext, __MediaResourcesData } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { usePagesCollection } from "engines/firebase/handler/pages-handler";
import React from "react";
import { View } from "react-native";
import { PageBodyScreen, useNavigator } from "screens";
import { spacing, tr } from "utils";
//#endregion

/**
 * ### Resources Home Screen
 * -  Display MediaHomeFeed & ShortcutList
 *  ----
 *  @version 21.03.01
 *  -  *Brief changelog*
 *  @author  K
 *
 **/
export function PageHomeScreen(props: P) {
  const { C } = useAppContext();
  const userProfile = useUserProfile();
  const Navigation = useNavigator();

  const [locationUpdate, setLocationUpdate] = React.useState("");

  React.useEffect(() => {
    // console.log("window.location.href ", window?.location?.href);
    if (window?.location?.href.includes("/resources")) {
      setLocationUpdate(locationUpdate + "a");
    }
  }, [window?.location?.href]);
  // return <T.OneColumn column1Content={(scrollHandler) => <O.MediaHomeFeed />} />;
  try {
    return (
      <TwoColumns
        column1Content={(scrollHandler) => (
          <PagesHomeFeed scrollHandler={scrollHandler} />
        )}
        // column1Content={(scrollHandler) => <ShortcutList />}
        column2Content={[
          {
            headerTitle: tr("Create a new page"),
            showTitle: false,
            icon: "plus",
            hiddenIf: [
              !userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]),
            ],
            render: () => <PageCreation />,
          },
          {
            headerTitle: tr("Content Multiverse"),
            showTitle: false,
            icon: "world",
            hiddenIf: [
              !userProfile._isRolesContain([
                FRBS_ROLE.ADMIN,
                FRBS_ROLE.DEVELOPER,
              ]),
            ],
            render: () => <ContentMultiverseHome />,
          },
          {
            headerTitle: tr("Shortcuts"),
            showTitle: false,
            icon: "link",
            render: () => <ShortcutList />,
          },
        ]}
      />
    );
    return (
      <PageBodyScreen
        route={{ params: { cpid: "iiLjHh3Xg4Yv6tsNWwiN", collabMode: false } }}
      />
    );
  } catch (error) {
    throw new Error(`::UmVzb3VyY2VzSG9tZVNjcmVlbg==:: ${error}`);
  }
}

function PageCreation() {
  const { teamId } = useAppContext();
  const pagesHandler = usePagesCollection();
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();

  function RenderPageCreator() {
    return (
      <DocumentCreatorWithTitle
        onCreate={(pageName) => {
          Toasty.show(
            tr("Your page will be created shortly at the end of the feed..."),
            { type: "loading" }
          );
          pagesHandler.add({
            ...__MediaResourcesData({
              teamId,
              pageName,
            }),
            position: pagesHandler._largestPositionIndex() + 1,
          });
        }}
      />
    );
  }

  function handleCreatePage() {
    Navigation.dialogPortal.open({
      headerTitle: tr("Enter Page Name"),
      render: <RenderPageCreator />,
    });
  }
  React.useEffect(function triggering() {
    !breakpointForTwoColumns && (Navigation.goBack(), handleCreatePage());
  }, []);

  return breakpointForTwoColumns ? (
    <Buttoon
      appearance={"outline"}
      size="giant"
      icon={{ name: "plus" }}
      onPress={handleCreatePage}
    >
      {tr("Create a new page")}
    </Buttoon>
  ) : (
    <View />
  );
}

function ContentMultiverseHome() {
  const Navigation = useNavigator();
  const { breakpointForTwoColumns } = useTwoColumns();

  function handleOpenMultiverse() {
    Navigation.navigate("multiverse");
  }
  React.useEffect(function triggering() {
    !breakpointForTwoColumns && (Navigation.goBack(), handleOpenMultiverse());
  }, []);

  return breakpointForTwoColumns ? (
    <Buttoon
      size="giant"
      appearance={"outline"}
      status="info"
      style={{ marginTop: spacing(3) }}
      onPress={handleOpenMultiverse}
      icon={{ name: "world" }}
    >
      {tr("Content Multiverse")}
    </Buttoon>
  ) : (
    <View />
  );
}

/**
 * ###  Screen Props
 */
interface P {
  //   route?: { params?: dSboardScrParams }; //* unmask if we pass params thru rnav
}
