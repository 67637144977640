import { Buttoon, Kitten, sstyled, Toasty } from "components";
import { Backend, useAppContext } from "engines";
import React from "react";
import { Keyboard, View } from "react-native";
import { useNavigator } from "screens";
import { tr } from "utils";

/**
 * ### Authentication Center
 *  - Available: Email Auth, Google Auth
 *  ----
 *  @version 21.03.15
 *  -  *Rename*
 *  @author  K
 *
 **/
export function ResetCenter({ passwordResetVisibilityCallback }) {
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const [_email, setEmail] = React.useState("");

  //@ts-ignore
  const emailRef = React.useRef<Kitten.Input>();

  React.useEffect(() => emailRef.current?.focus(), []);

  async function onResetPassword(xong?) {
    // // startTSKS(true);
    try {
      Backend.authHandler.resetPassword(_email).then((result) => {
        xong && xong();
        passwordResetVisibilityCallback && passwordResetVisibilityCallback();
      });
    } catch (error) {
      xong && xong();
      Toasty.show(tr("Error connecting to server"), {
        type: "danger",
      });
      console.warn("err registering: ", error);
    }
  }
  return (
    <A.Ctnr>
      <A.Input
        ref={emailRef}
        placeholder={tr("Email")}
        // autoFocus={true}
        // // disabled={_runningTSKS || !R.isNil(frbsAuthe)}
        autoCapitalize="none"
        keyboardType="email-address"
        value={_email}
        onChangeText={setEmail}
        onBlur={() => Keyboard.dismiss()}
        onSubmitEditing={() => onResetPassword()}
        selectTextOnFocus
      />
      <Kitten.Divider_________ />
      <Buttoon
        disabled={!_email}
        progress={true}
        onPress={(xong) => {
          onResetPassword(xong);
        }}
      >
        {tr("Request Password Reset")}
      </Buttoon>
    </A.Ctnr>
  );
}

const A = {
  Ctnr: sstyled(View)({
    // flex: Platform.select({ web: 1, ios: 0, android: 0 }),
    // width: Platform.select({ web: undefined, ios: "90%", android: "90%" }),
    paddingVertical: 10,
    minWidth: 6,
  }),
  Input: sstyled(Kitten.Input)({ minWidth: 10, marginBottom: 3 }),
};
