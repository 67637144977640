import { Avt, Buttoon, Kitten, Poppy, Toasty } from "components";
import { MailSchema, useAppContext } from "engines";
import { useEmailTemplateDocument } from "engines/firebase/handler/emails-handler";
import React from "react";
import WebView from "react-native-webview";
import { useNavigator } from "screens";
import { tr } from "utils";

/**
 * ### List Item for Member
 *  - Including some member's action
 *  ----
 *  @version 21.03.20
 *  -  *Move this component to ./molecules*
 *  @author  K
 *
 **/
export function MailListItem(props: dMailListItem) {
  const { item } = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();
  const handleEmail = useEmailTemplateDocument(item?.id, {
    ignoreFirestoreDocumentSnapshotField: false,
  });

  const openScheduleMail = async () => {
    //Navigation.navigate<dOverlayParams>("Overlay", {
    Navigation.overlayPortal.open({
      headerTitle: "Reschedule Mail",
      render: (
        <>
          <WebView
            source={{ html: item.body }}
            style={{ flex: -1, width: "100%", height: 700 }}
          />
        </>
      ),
    });
  };

  const deleteSchedule = async () => {
    handleEmail
      .deleteDocument()
      .then(() => {
        Toasty.show("Delete Successful", { type: "success" });
        // getEmailTemplate();
      })
      .catch(() => {
        Toasty.show("Failed to delete", { type: "danger" });
      });
  };

  return (
    <>
      <Kitten.ListItem
        title={item.subject}
        description={`${
          item.textbody
            ? String(item.textbody)?.substring(0, 100) + "..."
            : "Empty"
        }`}
        onPress={() => openScheduleMail()}
        // accessoryRight={() => (
        //  <Txt.P1>{item.SubmittedAt}</Txt.P1>
        // )}
        accessoryLeft={() => (
          <Avt
            name={
              item.recipient
                ? item.recipient.charAt(0).toUpperCase()
                : item.recipients[0].charAt(0).toUpperCase()
            }
            size={"small"}
            style={{ borderRadius: 3 }}
            source={{
              uri: "",
            }}
          />
        )}
        accessoryRight={() => (
          <Buttoon
            icon={{ name: "trash" }}
            status={"danger"}
            appearance={"ghost"}
            style={{ alignSelf: "flex-end" }}
            onPress={() => {
              Poppy(
                tr("Final Check"),
                tr("Do you want to delete this email schedule?"),
                {
                  text: "Cancel",
                  onPress: () => {},
                },
                {
                  text: "Yes",
                  onPress: async () => {
                    await deleteSchedule();
                  },
                }
              );
            }}
          />
        )}
      ></Kitten.ListItem>
    </>
  );
}

export interface dMailListItem {
  item: MailSchema;
}
