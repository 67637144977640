import { Input } from "@ui-kitten/components";
import { IconPrimr } from "assets";
import {
  Avt,
  Buttoon,
  Inpuut,
  Kitten,
  ListHeader,
  Poppy,
  Toasty,
  Txt
} from "components";
import { useUserProfile } from "engines";
import {
  CONTACT_SECTION,
  saveBMLContact
} from "engines/backends/auth/AuthHandler";
import { ContactSchemaa, dContactNote } from "engines/backends/schemas/bml";
import { useAppContext } from "engines/providers";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import * as Clipboard from "expo-clipboard";
import * as Contacts from "expo-contacts";
import firebase from "firebase";
import { isString } from "lodash";
import React from "react";
import { Linking, StyleSheet, View } from "react-native";
import { ContactNotesEdit } from "screens/bml/bml-mobile/ContactNotesEdit";
import { IS_WEB, tr } from "utils";

export interface dBMLContactDetails {
  contact: ContactSchemaa;
}
/**
 * ### Details of the BML Contact and a form to edit their current qualifications
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.03.28
 *  -  *Brief changelog*
 *  @author  Saurabh_M
 *
 **/
export const BMLContactDetails = (props: dBMLContactDetails) => {
  const { contact } = props;
  const { C } = useAppContext();
  const user = useUserProfile();
  const { qualificationData, setRefreshList } = useBMLMContext();

  const [updatedQualifications, setUpdatedQualifications] = React.useState<
    string[]
  >(contact?.qualifications || []);

  const [updatedNotes, setUpdatedNotes] = React.useState<dContactNote[]>(
    contact?.notes || []
  );
  const [contactId, setContactId] = React.useState("");
  // const [currentNote, setCurrentNote] = React.useState<dContactNote>(null);
  const [currentNoteText, setCurrentNoteText] = React.useState("");
  const [updateType, setUpdateType] = React.useState("");

  // #region [ANCHOR] Update Qualifications
  React.useEffect(() => {
    contact.qualifications = [];
    contact.qualifications = updatedQualifications;
    saveBMLContact(contact, user.data?._id, CONTACT_SECTION.QUALIFICATION);
    setRefreshList(true);
  }, [updatedQualifications]);
  // #endregion

  // #region [ANCHOR2] Update Notes
  React.useEffect(() => {
    contact.notes = [];
    contact.notes = updatedNotes;
    saveBMLContact(contact, user.data?._id, CONTACT_SECTION.NOTES);
    updateType &&
      Toasty.show(`Successfully ${updateType} the note.`, { type: "success" });
    setCurrentNoteText("");
    setUpdateType("");
  }, [updatedNotes]);
  // #endregion

  React.useEffect(() => {
    const recordId = contact?._cid && contact?._cid?.split(":")[0]?.trim();
    if (!IS_WEB) {
      recordId &&
        Contacts.getContactByIdAsync(recordId).then((data) => {
          data && setContactId(data?.id);
        });
    }
  }, []);

  /**
   * @description Header for Contact's information containing
   * - Contact Profile Image
   * - Contact Name
   * - Contact Pointers
   * @param props optional
   * @author Saurabh_M
   * @returns HeaderView
   */
  const HeaderView = (props?: any) => {
    // const badgeSize = IS_WEB ? 50 : 30;
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Avt
          style={{ marginTop: 5 }}
          name={`${contact.displayName?.replace("undefined", "")}` || ""}
          size="giant" //* or size={420}
          source={{ uri: "" }}
        />
        <Buttoon
          style={{
            marginHorizontal: 15,
            alignSelf: "center",
          }}
          appearance="ghost"
          disabled={contactId ? false : true}
          icon={contactId ? { name: "pencil", right: true } : null}
          status={contactId ? "primary" : "control"}
          size="giant"
          onPress={async () => {
            if (IS_WEB) {
              Toasty.show("Phone contacts cannot be viewed on Web.", {
                type: "warning",
              });
            } else if (contactId) {
              await Contacts.presentFormAsync(contactId);
            }
          }}
        >
          {contact.displayName?.replace("undefined", "")?.trim()}
        </Buttoon>
      </View>
    );
  };

  const handleNoteSaveBtn = () => {
    if (currentNoteText) {
      const tempNote: dContactNote = {
        _id: (contact.notes ? contact.notes.length + 1 : 1).toString(),
        createdAt: firebase.firestore.Timestamp.now(),
        isDeleted: false,
        noteText: currentNoteText,
        createdBy: {
          _id: user.data?._id,
          displayName: user.data?.personali?.displayName,
        },
      };
      setUpdatedNotes([...updatedNotes, tempNote]);
      setUpdateType("Added");
      setCurrentNoteText("");
    }
  };

  const handleNoteEdit = (noteId: string, editedNote: string) => {
    // Find the index of the note and then replace with the new text and save it
    const noteIndex = updatedNotes.findIndex((n) => n._id === noteId);
    const newNote = updatedNotes[noteIndex];
    newNote.noteText = editedNote;
    newNote.createdBy._id = user.data?._id;
    newNote.createdBy.displayName = user.data?.personali?.displayName;

    // console.log("Saving contact ", contactUpdatedInfo.notes);
    setUpdatedNotes([
      ...updatedNotes.filter((n) => n._id !== newNote._id),
      newNote,
    ]);
    setUpdateType("Updated");
    // Navigator.goBack();
  };

  const handleUpdate = (qualificationId: string) => {
    if (updatedQualifications) {
      if (updatedQualifications.includes(qualificationId)) {
        setUpdatedQualifications(
          updatedQualifications.filter((q) => q !== qualificationId)
        );
      } else {
        setUpdatedQualifications([...updatedQualifications, qualificationId]);
      }
    } else {
      setUpdatedQualifications([...updatedQualifications, qualificationId]);
    }
    contact.qualifications = [];
    contact.qualifications = updatedQualifications;
  };

  /**
   * @description Content of the contact's detail containing
   * - Contact Phone info
   * - Contact email info
   * - Contact Pointer info (5,4,3,2,1,None)
   * - Contact Notes
   * @param props optional
   * @author Saurabh_M
   * @returns Content
   */
  const Content = () => {
    const handleNumberCopy = async (pn: string) => {
      Clipboard.setString(pn);
      Toasty.show(`Copied ${pn} to clipboard`, { type: "normal" });
    };
    return (
      <View>
        <ListHeader titleTx="Contact Info" />

        <View
          style={{
            flexWrap: "wrap",
            // alignItems: "center",
            // justifyContent: "center",
            flexDirection: "row",
          }}
        >
          {qualificationData.map((qualification) => (
            <Buttoon
              // disabled={userId !== user?.data?._id}
              key={qualification._id}
              size={"tiny"}
              style={{
                margin: 2.5,
                backgroundColor: updatedQualifications?.includes(
                  qualification._id
                )
                  ? C.primary
                  : C.dim,
                borderColor: updatedQualifications?.includes(qualification._id)
                  ? C.grey600
                  : C.grey500,
              }}
              onPress={() => handleUpdate(qualification._id)}
              children={() => (
                <Txt.C2
                  style={{ fontWeight: "800", fontSize: 10, color: C.text }}
                >
                  {qualification.name}
                </Txt.C2>
              )}
            />
          ))}
        </View>

        {isString(
          contact?.phoneNumbers?.length > 0
            ? contact?.phoneNumbers[0]?.number
            : ""
        ) ? (
          contact.phoneNumbers &&
          contact.phoneNumbers?.map((pn) => {
            return pn.number ? (
              <Kitten.ListItemv2
                disabled
                key={pn.id}
                accessoryLeft={() => (
                  <Kitten.ViewH>
                    <Txt.Helper>
                      {pn.label ? pn.label.toUpperCase() : "Number"}
                    </Txt.Helper>
                    <Txt.Indicator
                      style={{ color: C.infoBlue }}
                      onPress={() => handleNumberCopy(pn.number)}
                    >
                      {" " + pn.number}
                    </Txt.Indicator>
                  </Kitten.ViewH>
                )}
                accessoryRight={() => (
                  <Kitten.ViewH>
                    <IconPrimr
                      onPress={() => Linking.openURL(`sms:${pn.number}`)}
                      preset={"safe"}
                      name={"sms"}
                      size={20}
                      color={"dodgerblue"}
                    />
                    <IconPrimr
                      onPress={() => Linking.openURL(`tel:${pn.number}`)}
                      preset={"safe"}
                      name={"phone"}
                      size={20}
                      color={"dodgerblue"}
                    />
                  </Kitten.ViewH>
                )}
              />
            ) : null;
          })
        ) : (
          <Kitten.ListItemv2
            accessoryLeft={() => <Txt.Indicator>{"Number"}</Txt.Indicator>}
            accessoryRight={() => <Txt.Indicator>{"Invalid"}</Txt.Indicator>}
            onPress={() => Linking.openURL(`tel:${contact.phoneNumbers[0]}`)}
          />
        )}
        {contact?.email || contact?.emails?.length > 0 ? (
          <Kitten.ListItemv2
            accessoryLeft={() => <Txt.Indicator>{"EMAIL"}</Txt.Indicator>}
            accessoryRight={() => (
              <Txt.Indicator style={{ color: C.infoBlue }}>
                {contact?.emails?.pop()?.email || contact?.email}
              </Txt.Indicator>
            )}
            onPress={() => Linking.openURL(`mailto:${contact.email}`)}
          />
        ) : (
          <Kitten.ListItemv2
            accessoryLeft={() => (
              <Txt.Helper>{tr("No Email found")}</Txt.Helper>
            )}
            disabled
          />
        )}
      </View>
    );
  };

  const handleNoteDeletion = (note: dContactNote) => {
    Poppy(
      tr("Final Check"),
      tr(`Do you want to remove this note from the contact ?`),
      {
        text: "Cancel",
        onPress: () => {},
      },
      {
        text: "Yes",
        onPress: () => {
          setUpdatedNotes(updatedNotes.filter((n) => n._id !== note._id));
          setUpdateType("Deleted");
        },
      }
    );
  };
  const contactInputRef = React.useRef<Input>(null);

  return contact ? (
    <Kitten.Layout collapsable level={"1"}>
      <Kitten.Card style={styles.card} header={HeaderView} disabled>
        <Content />
        <Kitten.Divider_________ />
        <ListHeader titleTx={"Contact Notes"} />
        <Kitten.Divider_________ />
        <Inpuut
          ref={contactInputRef}
          textStyle={{ minHeight: 74 }}
          multiline
          // autoFocus={IS_WEB ? true : false}
          autoFocus={false}
          // returnKeyType="done"
          doneText="Post"
          alwaysShowSave
          keyboardType="default"
          autoCorrect={false}
          autoCapitalize="none"
          onSavePress={handleNoteSaveBtn}
          // placeholder={`Enter notes for ${contact.displayName?.replace(
          //   "undefined",
          //   ""
          // )}`}
          placeholder="Type note here..."
          onChangeText={(text) => setCurrentNoteText(text)}
          value={currentNoteText}
        />
        {updatedNotes?.filter((n) => n.isDeleted !== true).length > 0 ? (
          <>
            <Kitten.Divider_________ />
            <ListHeader titleTx={"Previous Notes"} />
            <Kitten.Divider_________ />
            {/* Only display non-deleted notes */}
            {updatedNotes
              .filter((n) => n.isDeleted !== true)
              .sort(
                (n1, n2) => n2.createdAt.toMillis() - n1.createdAt.toMillis()
              )
              .map((n, index) => {
                return (
                  <ContactNotesEdit
                    key={n?._id || index}
                    note={n}
                    handleNoteDeletion={handleNoteDeletion}
                    handleNoteEdit={handleNoteEdit}
                  />
                );
              })}
          </>
        ) : null}
      </Kitten.Card>
    </Kitten.Layout>
  ) : null;
};

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    flex: 1,
    margin: 2,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerControl: {
    marginHorizontal: 2,
  },
});
