import { IndexPath } from "@ui-kitten/components";
import { Kitten, Txt } from "components";
import { Buttoon, Toasty } from "components/atoms";
import { FPATH, MediaPageSchema, UserSchema } from "engines";
import firebase from "firebase";
import { useState } from "react";
import { View } from "react-native";
import { useMembersCollection } from "../../../../engines/firebase/handler/members-handler";
import { useNavigator } from "../../../../screens/_navigation/navigation-utils";
import { spacing } from "../../../../utils/styles/spacing";

/**
 * ### Select a user from a dropdown list
 * - TODO: Make this component ready for general user, rather than just specific to user page unlocking
 * - TODO: put in correct directory
 *
 * @author jm_frnacis
 *
 * @example
 * <SelectUser page={page} />
 */
export function SelectUser(p: { page: MediaPageSchema }) {
  const { page } = p;
  const Navigation = useNavigator();
  const membersHandler = useMembersCollection();

  const [selectedIndex, setSelectedIndex] = useState<IndexPath>(
    new IndexPath(0)
  );

  const [selectedUser, setSelectedUser] = useState<UserSchema | null>(null);
  const [searchText, setSearchText] = useState<string>("");

  function getFilteredUsers(): UserSchema[] {
    return (
      (membersHandler.data &&
        membersHandler.data.filter(
          (_u) =>
            searchText.length < 1 ||
            (_u.personali.displayName || _u.personali.email)
              ?.toLowerCase()
              ?.includes(searchText.toLowerCase())
        )) ||
      []
    );
  }

  return (
    <View>
      <Txt.$Title style={{ marginBottom: spacing(3) }}>
        Select a User
      </Txt.$Title>
      <Kitten.Select
        onAccessibilityEscape={() => {
          setSearchText("");
        }}
        style={{ flexGrow: 1 }}
        placeholder={"Select a user"}
        value={
          selectedUser
            ? selectedUser?.personali?.displayName ||
              selectedUser?.personali?.email
            : "None selected"
        }
        selectedIndex={selectedIndex}
        onSelect={(index) => {
          setSelectedIndex(index as IndexPath);
        }}
      >
        {[
          <Kitten.Input
            placeholder="Search a user"
            onChangeText={setSearchText}
          />,
          <Kitten.ListItemv2 title="No selection" disabled key="noselection" />,
        ].concat(
          getFilteredUsers().map((_user, _idx) => (
            <Kitten.ListItemv2
              onPress={() => {
                setSelectedIndex(new IndexPath(_idx));
                setSelectedUser(getFilteredUsers()[_idx]);
              }}
              title={_user.personali.displayName || _user.personali.email}
            />
          ))
        )}
      </Kitten.Select>
      <Buttoon
        style={{ marginTop: spacing(3) }}
        disabled={setSelectedUser ? false : true}
        onPress={async () => {
          try {
            const __toasty = Toasty.show(`Unlocking ${page.name} for user...`);
            if (selectedUser?.unlockedPages?.includes(page._id)) {
              Toasty.show("User already has access to this page.", {
                type: "info",
              });
              return;
            }
            await firebase
              .firestore()
              .doc(`${FPATH.USERS}/${selectedUser._id}`)
              .update({
                unlockedPages: firebase.firestore.FieldValue.arrayUnion(
                  page._id
                ),
              });
            Toasty.hide(__toasty);
            Toasty.show(
              `${page.name} unlocked for ${
                selectedUser?.personali?.displayName ||
                selectedUser?.personali?.email
              }`,
              { type: "success" }
            );
            Navigation.goBack();
          } catch (err) {
            console.error(err);
            Toasty.show(err, { type: "danger" });
          }
        }}
      >{`Unlock for user`}</Buttoon>
    </View>
  );
}
