import { BMLContactDetails } from "screens/bml/bml-mobile/BMLContactDetails";
import { BMLList } from "screens/bml/bml-mobile/BMLList";
import { BMLMobile } from "screens/bml/bml-mobile/BMLMobile";
import { BMLSettings } from "screens/bml/bml-mobile/BMLSettings";
import { BMLWeb } from "screens/bml/bml-web/BMLWeb";
import { MassMailComposeScreen } from "screens/mass-mail/mass-mail-home/MassMailComposeScreen";
import { MassMailHomeScreen } from "screens/mass-mail/mass-mail-home/MassMailHomeScreen";
import { MassMailUseTemplateScreen } from "screens/mass-mail/mass-mail-home/MassMailUseTemplateScreen";

const BMLStackCollection = {
  "bml-web": {
    component: BMLWeb,
    options: ({ route }) => ({
      title: "Build My List",
      //
    }),
  },
  "bml-mobile": {
    component: BMLMobile,
    options: ({ route }) => ({
      title: "Build My List",
      //
    }),
  },
  "bml-mobile-list": {
    component: BMLList,
    options: ({ route }) => ({
      title: "Build My List",
    }),
  },
  "bml-mobile-settings": {
    component: BMLSettings,
    options: ({ route }) => ({
      title: "BML Settings",
    }),
  },
  "bml-contact-details": {
    component: BMLContactDetails,
    options: {
      title: "Contact Details",
    },
  },
};

const MassMailCollection = {
  "mass-mail-home": {
    component: MassMailHomeScreen,
    options: {
      title: "Mass Email",
    },
  },

  "mass-mail-compose": {
    component: MassMailComposeScreen,
    options: {
      title: "Compose Email",
    },
  },

  "use-template": {
    component: MassMailUseTemplateScreen,
    options: {
      title: "Use Template",
    },
  },
};

export const ToolsStackCollection = {
  ...BMLStackCollection,
  ...MassMailCollection,
  // ...CollabStackCollection,
};

export type TOOLS_STK =
  | keyof typeof MassMailCollection
  | keyof typeof BMLStackCollection; //* 👈 Add type here;
