import {
  Autocomplete as UIKAutocomplete,
  AutocompleteItem,
  AutocompleteProps
} from "@ui-kitten/components";
import { useAppContext } from "engines";
import React from "react";
import { tr } from "utils";

const _MOCK_LIST: dAutocomplete["data"] = [
  { id: "00", title: "Star Wars" },
  { id: "01", title: "Back to the Future" },
  { id: "02", title: "The Matrix" },
  { id: "03", title: "Inception" },
  { id: "04", title: "Interstellar" },
];

const filter = (item, query) =>
  item.title.toLowerCase().includes(query.toLowerCase());

// const StarIcon = (props) => <Icon {...props} name="star" />;

export function Autocompletev2(props: dAutocomplete) {
  const { data = _MOCK_LIST, onSelectCallback } = props;
  const { C } = useAppContext(); // Luu Trong An Ha
  const [_index, setIndex] = React.useState<number>(null);
  const [_value, setValue] = React.useState(null);
  const [_data, setData] = React.useState<dAutocomplete["data"]>(data);

  const onSelect = (index: number) => {
    setValue(_data[index].title);
    setIndex(index);
    // onSelectCallback && onSelectCallback(index);
  };
  React.useEffect(
    function onCallback() {
      onSelectCallback && _index && onSelectCallback(_data[_index].id);
    },
    [_value]
  );

  const onChangeText = (query) => {
    setValue(query);
    setData(data.filter((item) => filter(item, query)));
  };

  const clearInput = () => {
    setValue("");
    setData(data);
  };

  const renderOption = (item, index) => (
    <AutocompleteItem key={index} title={item.title} />
  );

  return (
    <UIKAutocomplete
      placeholder={tr("Place your text")}
      value={_value}
      {...props}
      onChangeText={onChangeText}
      onSelect={onSelect}
    >
      {_data.map(renderOption)}
    </UIKAutocomplete>
  );
}

interface dAutocomplete extends AutocompleteProps {
  data?: { id: string; title: string }[];
  onSelectCallback?(index: number | string): void;
}
