import { Buttoon, DatteLatte, ListHeader, Toasty, Txt } from "components";
import { useAppContext } from "engines";
import { useScheduleDocument } from "engines/firebase/handler/emails-handler";
import React from "react";
import { tr } from "utils";

/**
 * ### List Item for Member
 *  - Including some member's action
 *  ----
 *  @version 21.03.20
 *  -  *Move this component to ./molecules*
 *  @author  K
 *
 **/
export function RescheduleMail(props: dScheduleDocId) {
  //   const { item } = props;
  const { C } = useAppContext();

  const { docID } = props;

  const [toggleDate, setToggleDate] = React.useState<boolean>(false);
  const toggleShowDate = async () => {
    setToggleDate(!toggleDate);
  };
  const handleScheduleItem = useScheduleDocument(docID);

  console.log("docID ", docID);

  const [scheduleAt, setScheduleAt] = React.useState<string>("");

  /*G-E-A-U-X */

  function updateSchedule() {
    handleScheduleItem
      .update({ scheduleDate: scheduleAt })
      .then(() => {
        Toasty.show("Update Successful", { type: "success" });
      })
      .catch((e) => {
        console.log(e);
        Toasty.show("Failed to update schedule", { type: "danger" });
      });
  }
  try {
    if (!handleScheduleItem.data) return null;

    console.log(handleScheduleItem.data.id);

    return (
      <>
        <ListHeader titleTx={tr("Reschedule this email")} />
        <DatteLatte
          onSelectCallback={(datte) => {
            setScheduleAt(datte);
          }}
        />
        <Txt.Indicator marginV>
          {tr("Mail will be rescheduled to send at ") + scheduleAt}
        </Txt.Indicator>{" "}
        <Buttoon
          status={"primary"}
          appearance={"ghost"}
          // style={{ alignSelf: "flex-end" }}
          onPress={() => {
            updateSchedule();
          }}
        >
          {tr("Update schedule")}
        </Buttoon>
      </>
    );
  } catch (error) {
    throw new Error(error);
  }
}

export interface dScheduleDocId {
  docID: string;
}
