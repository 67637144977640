import { IconPrimr } from "assets";
import { Buttoon, ListHeader, Poppy, Txt } from "components";
import ListItemDeleteAction from "components/atoms/lists/SwipeyList/ListItemDeleteAction";
import { ContactProfile } from "components/molecules/contact-item/ContactProfile";
import { CMI } from "components/molecules/contact-item/ContactsMultiInput-v2";
import { ContactListItemDetails } from "components/molecules/members/ContactListItemDetails";
import { useTwoColumns } from "components/templates";
import { useAppContext, useUserProfile } from "engines";
import { deleteBMLContact } from "engines/backends/auth/AuthHandler";
import { ContactSchemaa } from "engines/backends/schemas/bml";
import { useBMLMContext } from "engines/providers/bml-mobile-provider";
import { isString } from "lodash";
import React, { useState } from "react";
import { ActivityIndicator, SectionList, View } from "react-native";
import { Swipeable } from "react-native-gesture-handler";
import { useNavigator } from "screens/_navigation";
import { tr } from "utils";
import { SLData } from "./BMLList";

interface dBMLFeed {
  data: SLData[];
}

/**
 * ### Display the BML List Data based upon the results
 *  ----
 *  @example
 *  <BMLFeed />
 *  ----
 *  @version 22.03.28
 *  -  **
 *  @author  Saurabh_M
 *
 **/
export function BMLFeed(props: dBMLFeed) {
  const { data } = props;
  const { C } = useAppContext();
  const { column1ContainerStyle } = useTwoColumns();
  const feedRef = React.useRef<SectionList>(null);
  const [ready, setReady] = useState(false);

  const [filteredSlData, setFilteredSlData] = useState<SLData[]>(data);

  React.useEffect(() => {
    setReady(false);
    setFilteredSlData(data);
    if (data && data.length > 0) {
      setReady(true);
      // feedRef.current?.scrollToLocation({
      //   itemIndex: 0,
      //   sectionIndex: 0,
      //   animated: false,
      // });
      // setTimeout(() => {
      //   feedRef.current?.scrollToLocation({
      //     itemIndex: 0,
      //     sectionIndex: 0,
      //     animated: false,
      //   });
      // }, 560);
    }
  }, [data]);

  // return !ready && filteredSlData.length === 0 ? (
  //   <ActivityIndicator
  //     style={{
  //       justifyContent: "center",
  //       alignItems: "center",
  //       backgroundColor: C.background,
  //       flex: 1,
  //     }}
  //     size="large"
  //     color={C.primary}
  //   />
  // ) :
  return (
    <SectionList
      // style={{ backgroundColor: "background" }}
      collapsable
      showsVerticalScrollIndicator={false}
      ref={feedRef}
      contentContainerStyle={column1ContainerStyle}
      stickyHeaderIndices={[0]}
      ListEmptyComponent={
        <ActivityIndicator
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: C.background,
            flex: 1,
          }}
          size="large"
          color={C.primary}
        />
      }
      ListFooterComponent={() => (
        <Buttoon
          compact
          onPress={() => {
            feedRef.current?.scrollToLocation({
              itemIndex: 0,
              sectionIndex: 0,
              animated: true,
            });
          }}
          appearance="filled"
          status="warning"
        >
          {"Scroll to top"}
        </Buttoon>
      )}
      sections={filteredSlData}
      keyExtractor={(item: ContactSchemaa) => item._cid}
      renderSectionHeader={({ section: { title } }) => (
        <ListHeader
          style={{ backgroundColor: C.surface }}
          titleTx={
            title.includes("5") || title.includes("4")
              ? `${title} 🔥🔥`
              : `${title}`
          }
        />
      )}
      renderItem={({
        item,
        index,
      }: {
        item: ContactSchemaa;
        index: number;
      }) => (
        <ListItem
          bmlContact={item}
          index={index}
          data={filteredSlData}
          helper={setFilteredSlData}
        />
      )}
    />
  );
}

const ListItem = ({
  bmlContact,
  index,
  data,
  helper: setData,
}: {
  bmlContact: ContactSchemaa;
  index: number;
  data: SLData[];
  helper: any;
}) => {
  const { C, frbsUser } = useAppContext();
  const user = useUserProfile();
  const Navigator = useNavigator();
  const { getPointers, getQualificationNames } = useBMLMContext();

  const ContactTitle = () => {
    return (
      <View style={{ marginHorizontal: 10 }}>
        <Txt.P1>{bmlContact.displayName}</Txt.P1>
        {bmlContact.appointments && bmlContact.appointments.length > 0 ? <Txt.C1 style={{ color: C.primary }}>{"Scheduled"}</Txt.C1> : <Txt.C1 style={{ color: C.hazardYellow, fontWeight: "bold" }}>No appointment set</Txt.C1>}
        {/* {getQualificationNames(bmlContact.qualifications).join(", ").trim()} */}
      </View>
    );
  };

  const ContactDescription = () => {
    return null;
    return <Txt.C2>{isString(
      bmlContact?.phoneNumbers?.length > 0 && bmlContact.phoneNumbers[0]
    )
      ? bmlContact.phoneNumbers
      : bmlContact.phoneNumbers?.find((c) => c.number !== "")?.number}</Txt.C2>
  }

  return (
    <Swipeable
      // containerStyle={{ flexDirection: "row", width: "100%" }}
      renderRightActions={() => (
        <ListItemDeleteAction
          onPress={() => {
            Poppy(
              tr("Final Check"),
              `Do you want to remove "${bmlContact.displayName}" from the list?`,
              {
                text: "Cancel",
                onPress: () => { },
              },
              {
                text: "Yes",
                onPress: () => {
                  const dataIndex = data?.findIndex(
                    (l) => l.points === bmlContact.qualifications?.length
                  );
                  const tempSlData = [...data];
                  tempSlData[dataIndex].data = tempSlData[
                    dataIndex
                  ].data.filter((c) => c._cid !== bmlContact._cid);
                  setData(tempSlData);
                  deleteBMLContact(bmlContact, user?.data?._id);
                },
              }
            );
          }}
        />
      )}
    >
      <CMI.ContactList
        key={bmlContact.id + "-" + index.toString()}
        onPress={() => {
          Navigator.overlayPortal.open({
            render: (
              <ContactListItemDetails
                contact={bmlContact}
                userId={frbsUser?.uid}
                listName={"Build My List"}
              />
            ),
            headerTitle: `Contact Details`,
          });
        }}
        title={ContactTitle}
        description={ContactDescription}
        // description={
        //   isString(
        //     bmlContact?.phoneNumbers?.length > 0 && bmlContact.phoneNumbers[0]
        //   )
        //     ? bmlContact.phoneNumbers
        //     : bmlContact.phoneNumbers?.find((c) => c.number !== "")?.number
        // }
        accessoryLeft={() => (
          <ContactProfile contact={bmlContact} color={"info"} />
        )}
        accessoryRight={() => (
          <IconPrimr
            //@ts-ignore
            name={`num${getPointers(bmlContact.qualifications) > 0
              ? getPointers(bmlContact.qualifications)
              : ""
              }`}
            size={40}
            color={
              bmlContact.qualifications
                ? bmlContact.qualifications.length > 3
                  ? C["color-danger-600"]
                  : bmlContact.qualifications?.length === 3
                    ? C["color-warning-600"]
                    : C["color-basic-600"]
                : C["color-basic-600"]
            }
          />
        )}
      />
    </Swipeable>
  );
};
