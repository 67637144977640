import {
  ACTION_TYPE,
  MediaPageItemSchema,
  MediaPageSchema,
  recordUserAction,
  URL_PREFIX,
  useAppContext,
  UserSchema
} from "engines";
import { isCompletableItem } from "engines/functions/level-functions/level-page-utils";
import firebase from "firebase";
import { dUseDocumentReturns } from "../config";
import { useMemberDocument } from "./members-handler";
import {
  usePageContentsCollection,
  UsePageContentsCollectionType
} from "./page-contents-handler";
import { usePageDocument } from "./pages-handler";

export interface dHandlers {
  memberHandler?: dUseDocumentReturns<UserSchema>;
  parentPageHandler?: dUseDocumentReturns<MediaPageSchema>;
  parentPageContentsHandler?: UsePageContentsCollectionType;
  levelPageHandler?: dUseDocumentReturns<MediaPageSchema>;
  levelPageContentsHandler?: UsePageContentsCollectionType;
}

let recentlyCompletedPageIds: string[] = []

/**
 * Returns the overall levels progress for the specified user. (the average of all existing levels)
 * Ex: calculateTotalLevelProgressForUser(user)
 */
//  export function useCalculateTotalLevelProgressForUser(uid: string): number {
//   const user = _user ? _user : Backend.firestoreHandler._account;
//   let fullLevelProgress = 0;
//   for (let l in user.levels) {
//     let levelProgress = calculateProgressForLevel(
//       l,
//       user
//     );
//     fullLevelProgress = (fullLevelProgress + levelProgress) / 2;
//   }
//   fullLevelProgress = fullLevelProgress ? fullLevelProgress : 0;
//   return fullLevelProgress;
// }

/**
 * ### Returns percentage progress of specified page
 * - gets from currently logged in account or if an altAccount is specified it calculates their progress on that level
 *
 * @author jm_francis
 * @version 1.9.8
 *
 * @example
 * const progress = useCalculateProgressForPage(pageId, uid)
 */
export function useCalculateProgressForPage(
  pageId: string,
  user: UserSchema,
  /**
   * Provide memberHandler here if you already have a memberHandler in use for the current user
   * - for example: the currently logged in user, there is already a memberHandler active
   * - this will help avoid double reads
   */
  handlers?: dHandlers
): number {
  const { frbsUser } = useAppContext();
  const memberHandler = handlers?.memberHandler
    ? handlers?.memberHandler
    : useMemberDocument(user?._id || frbsUser?.uid);
  // if (memberHandler.data?.completedLevelPages?.includes(pageId)) return 1;
  const pageHandler = handlers?.levelPageHandler
    ? handlers?.levelPageHandler
    : usePageDocument(pageId);
  // const pagesHandler = usePagesCollection();
  const pageContentsHandler = handlers?.levelPageContentsHandler
    ? handlers?.levelPageContentsHandler
    : usePageContentsCollection(pageId, {
      ignoreFirestoreDocumentSnapshotField: false,
    });
  // TODO make sure _id is added on each pageContent document when migrating from mobile database
  const completableItems = pageContentsHandler.data?.filter((item) =>
    isCompletableItem(item)
  );
  const account = memberHandler.data;
  const completedLevelItems = account?.completedLevelItems?.map((li) =>
    li && li?.includes(":")
      ? String(li)?.substring(String(li)?.indexOf(":") + 1, String(li)?.length)
      : li || ""
  );
  /**
   * If user has completed this page before, new content might have been added, so give them credit regardless!
   */
  if (memberHandler.data?.completedLevelPages?.includes(pageId)) return 1;

  if (!pageId) return 1;
  // const account = Backend.firestoreHandler._account;

  //#region [section] function to get the last level page (the level page with the highest number)
  /**
   * Returns the last levels page. For example, if there are a total of 6 levels, the page with a name like "New Reps - Level 6" would get returned.
   */
  // function getLastLevelPage(): MediaPageSchema {
  //   if (pageHandler.data) {
  //     const levelPages = pagesHandler?.data?.filter((_page) =>
  //       isLevelPage(_page.name)
  //     );
  //     let lastPage: MediaPageSchema;
  //     let lastPageIndex = 0;
  //     for (let l in levelPages) {
  //       const page = levelPages[l];
  //       const pageIndex = indexFromName(page.name);
  //       if (pageIndex > lastPageIndex) {
  //         lastPage = page;
  //         lastPageIndex = pageIndex;
  //       }
  //     }
  //     return lastPage;
  //   }
  // }
  //#endregion

  if (
    !pageHandler.data ||
    !completedLevelItems
    //|| !isLevelPage(pageHandler?.data?.name)
  ) {
    return 0;
  } else {
    // const levels = altAccount ? altAccount.levels : account.levels;
    // if (!levels) {
    //   return 0;
    // }
    // const level = levels[pageId] ? levels[pageId] : null;
    // const page = Backend.firebasePages.getPageWithName(pageId);
    let completeCount = 0;
    let incompleteCount = 0;
    for (let i in completableItems) {
      const item = completableItems[i];
      // console.log("[levels] snap id: " + item.__snapshot?.id);
      // if (completedLevelItems.includes(item.__snapshot?.id)) completeCount++;
      if (completedLevelItems?.includes(item?._id)) completeCount++;
      else incompleteCount++;
    }
    const result = completeCount / (completeCount + incompleteCount);
    //#region [section] if this is the last level page and the progress is 100% complete AND it is the currently logged in user, then we will set the user's "allLevelsCompleted" to true
    // const lastLevelPage = getLastLevelPage();
    // if (
    //   uid === frbsUser.uid &&
    //   lastLevelPage &&
    //   lastLevelPage.name === pageHandler.data?.name &&
    //   result === 1
    // ) {
    //   memberHandler.update({ allLevelsCompleted: true });
    // }
    //#endregion
    /**
     * If progress is 1 then mark the page as complete for the user
     */
    if (user._id === frbsUser.uid && result >= 1 && !memberHandler.data?.completedLevelPages?.includes(pageId)) {
      memberHandler.update({
        completedLevelPages: firebase.firestore.FieldValue.arrayUnion(pageId),
      });
      if (!memberHandler.data?.completedLevelPages?.includes(pageId) && !recentlyCompletedPageIds.includes(pageId))
        recordUserAction(user, ACTION_TYPE.LEVEL_COMPLETION, `${user.personali?.displayName} just completed all training in ${pageHandler.data?.name}.`, { location: `${URL_PREFIX}${pageId}`, involvedPartyName: pageHandler.data?.name, thumbnail: pageHandler?.data?.mediaItem?.logo })
      recentlyCompletedPageIds.push(pageId)
    }
    return result;
  }
}

/**
 * ### Returns the progress percentage of the level that comes before the specified level
 *
 * @author jm_francis
 * TODO: include parent page setting
 *
 * @example
 * const previousLevelProgress = useProgressForPreviousLevel(levelPageId, frbsUser?.uid)
 */
export function useProgressForPreviousLevel(
  parentPageId: string,
  levelPageId: string,
  uid: string,
  handlers?: dHandlers
): number {
  const parentPageContentsHandler = handlers?.parentPageContentsHandler
    ? handlers.parentPageContentsHandler
    : usePageContentsCollection(parentPageId);

  const levelPageHandler = handlers?.levelPageHandler
    ? handlers.levelPageHandler
    : usePageDocument(levelPageId);

  const levelPageName = String(levelPageHandler.data?.name);

  if (!levelPageName) {
    console.log(
      "[levels] levels-handler.tsx - level page name is null, very strange 0.o"
    );
  }

  const potentialLevelPages = useGetLevelsOnParentPage(parentPageId, {
    ...handlers,
    levelPageHandler,
    parentPageContentsHandler,
  });
  const previousLevelPageIndex = potentialLevelPages.indexOf(levelPageId) - 1;
  if (previousLevelPageIndex < 0) {
    //* no previous level page, therefore we will give 100% completion so unlock this level :D
    // console.log(
    //   "[level] no previous level page to " + levelPageName + " " + levelPageId
    // );
    // by making first value null, 100% (1) progress will be returned but also maintain the hook count for React
    return useCalculateProgressForPage(null, uid, handlers);
  }
  // console.log("[levels] next step");
  const previousLevelPageId = potentialLevelPages[previousLevelPageIndex];
  // console.log(
  //   "[level] previous level page determined as " +
  //     previousLevelPageId +
  //     " in relation to " +
  //     levelPageName
  // );
  // console.log(
  //   "[level] this is " +
  //     levelPageName +
  //     " and previous level is " +
  //     previousLevelPageId +
  //     " and its progress is as follows in next log..."
  // );
  return useCalculateProgressForPage(previousLevelPageId, uid, handlers);
}

/**
 * # Gets the buttons that go to a URL_PREFIX.LEVEL page and puts them into an array
 *
 * @author jm_francis
 *
 * @example
 * const levelPages = getLevelsOnParentPage(newRepPageId)
 */
export function useGetLevelsOnParentPage(
  parentPageId: string,
  handlers?: dHandlers
): string[] {
  // console.log(
  //   "[levels-handler] getting levels on parent page: " + parentPageId
  // );
  const parentPageContentsHandler = handlers?.parentPageContentsHandler
    ? handlers.parentPageContentsHandler
    : usePageContentsCollection(parentPageId);
  const toPageButtons = parentPageContentsHandler.data
    ?.filter((item) => item?.url?.startsWith(URL_PREFIX.LEVEL))
    .sort((a, b) => a.position - b.position);
  // console.log(
  //   "[levels-handler] potential to page buttons: " +
  //     JSON.stringify(toPageButtons)
  // );
  if (!toPageButtons) return [];
  return toPageButtons?.map((item) => item?.url?.replace(URL_PREFIX.LEVEL, ""));
}

// memberHandler.update({
//   completedLevelItems: memberHandler.data?.completedLevelItems.concat([
//     itemId,
//   ])

/**
 * # Quickly calculate the provided user's progress for the provided page contents
 * - This function is assuming you already have the user's data and the contents of the page you want to get a progress number for
 *
 * @author jm_francis
 *
 * @example
 * const progress = calculatePageProgress(user, pageContents.data)
 */
export function calculatePageProgress(
  user: UserSchema,
  pageContents: MediaPageItemSchema[]
): number {
  const pageContentsThatCanBeCompleted = pageContents
    ? pageContents?.filter((i) => isCompletableItem(i))
    : [];
  let completedItems = 0;
  function strippedId(_id: string): string {
    return _id?.substring(_id?.indexOf(":") + 1, _id?.length);
  }
  const completedLevelItemIds = [];
  for (let i in user?.completedLevelItems)
    completedLevelItemIds?.push(strippedId(user?.completedLevelItems[i]));
  for (let p in pageContentsThatCanBeCompleted) {
    const pageItem = pageContentsThatCanBeCompleted[p];
    const itemId = strippedId(pageItem?._id);
    if (completedLevelItemIds?.includes(itemId)) completedItems++;
  }
  if (completedItems < 1 || !pageContents) return 0;
  return completedItems / pageContentsThatCanBeCompleted?.length;
}
