import { Poppy } from "components";

async function post(
  url: string,
  body: {},
  requiredItem?: string,
  _tokens?: string[]
): Promise<{
  json: { link?: string; path_lower?: string; entries?: any[]; id?: string };
  usedToken: string;
}> {
  const tokens = [
    "Qd28tsRSFOMAAAAAAAAAYRl1usosYsqxnE04gplXJeZEkNTzkj7u6hm00Z9gHS6E", //This token doesn't work with some audio files
    "mFMMs4ybRnIAAAAAAAAAAa0w8nauWQeRavuWFSLht6eVma7KhWfLOLfyUejo5J36", // This works with all audio files
  ];
  if (_tokens)
    for (let t in _tokens)
      if (!tokens.includes(_tokens[t])) tokens.push(_tokens[t]);
  let tokenIndex = 0;

  async function attemptFetch() {
    if (tokenIndex + 1 > tokens.length) {
      console.log("ALL TOKENS FAILED");
      return null;
    }

    const token = tokens[tokenIndex++];

    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    //console.log("Response is ", JSON.stringify(res.ok));

    if (res.ok == false) {
      // failed with token
      return await attemptFetch();
    }
    const json = res && res.json && (await res.json());
    if (json && requiredItem && !json[requiredItem]) {
      // failed with token according to requiredItem
      return await attemptFetch();
    }
    // console.log("[dropbox] Token #" + token + " worked ");
    return { json, usedToken: token };
  }

  const json = await attemptFetch();
  return json;
}

export default class DropboxAPI {
  // ANCHOR: Files

  /**
   * Gets the proper file or folder metadata
   */
  static async getMetadata(shareLink: string, tokens?: string[]) {
    const res1 = await post(
      `https://api.dropboxapi.com/2/sharing/get_shared_link_metadata`,
      {
        url: shareLink,
      },
      null,
      tokens
    );
    const metadata1 = res1?.json;
    //console.log("metadata 1 is ", metadata1);

    if (!metadata1) return null;
    const { id } = metadata1;
    //console.log("id in metadata 1 is ", id);

    const res2 = await post(
      `https://api.dropboxapi.com/2/sharing/get_file_metadata`,
      {
        file: id,
      },
      "path_lower",
      tokens
    );
    const metadata2 = res2?.json;
    //console.log("MetaData 2 is ", metadata2);
    return metadata2;
  }
  /**
   * Get a direct uri to the audio file for streaming
   */
  static async getStreamingURI(
    shareLink: string,
    tokens?: string[]
  ): Promise<{ uri?: string; usedToken?: string; error?: string }> {
    const metadata = await DropboxAPI.getMetadata(shareLink);
    if (!metadata) {
      Poppy("Failed to load audio.", "Please inform an administrator.");
      return { error: "Failed to load audio, please inform an administrator." };
    }
    const path = metadata.path_lower;
    const res = await post(
      `https://api.dropboxapi.com/2/files/get_temporary_link`,
      {
        path: path,
      },
      null,
      tokens
    );
    return { uri: res?.json?.link, usedToken: res?.usedToken };
  }

  // ANCHOR: Folders

  /**
   * @deprecated
   * Get content of a folder (returns entries[] array)
   * Each entry contains { name, uri, id }
   */
  static async folder_getContent(shareLink: string, tokens?: string[]) {
    const res1 = await post(
      `https://api.dropboxapi.com/2/sharing/get_shared_link_metadata`,
      {
        url: shareLink,
      },
      "path_lower",
      tokens
    );
    const metadata = res1?.json;
    if (!metadata) return null;
    const res2 = await post(
      `https://api.dropboxapi.com/2/files/list_folder`,
      {
        path: metadata.path_lower,
      },
      null,
      tokens
    );
    const content = res2?.json;
    const entries = [];
    for (let e in content.entries) {
      const entry = content.entries[e];
      entries.push({
        name: entry.name,
        path: entry.path_lower,
        id: entry.id,
      });
    }
    return entries;
  }
  /**
   * @deprecated
   * Gets streaming uri for a file that was fetched with getFolderContent(shareLink: string)
   */
  static async folder_getStreamingURI(filePath: string, tokens?: string[]) {
    const res = await post(
      `https://api.dropboxapi.com/2/files/get_temporary_link`,
      {
        path: filePath,
      },
      null,
      tokens
    );
    return res?.json?.link;
  }
}
