//#region [import]
import { O } from "components";
import React from "react";
import { View } from "react-native";
//#endregion

/**
 * ### A page to create posst
 * -  Using `react-hook-form` for ease of use.
 *  - This hook returns a `rawForm` thru `getValues(_)`
 *  - See enum FORM for the form's keys
 *  - `rawForm` is then convert to `doneForm` with `PosstSchema`
 * and ready to upload to frbs
 *
 * ---
 * @example As is
 * ---
 * @version 1.1.22
 * @author Nl
 */
export function SaveTemplateOverlayScreen(props: P) {
  // const { teamId } = useAppContext();
  // const Navigation = useNavigator();
  //   const payload = {
  //     designJson: props.designJson,
  //     designHtml: props.designHtml,
  //   };

  //   console.log("payload1", props.designHtml);
  try {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <O.TemplateCreate
          designJson={props.designJson}
          designHtml={props.designHtml}
          chunkBody={props.chunkBody}
        />
      </View>
    );
  } catch (error) {
    throw new Error(`::U2F2ZVRlbXBsYXRlT3ZlcmxheVNjcmVlbg==:: ${error}`);
  }
  
}

interface P {
  designJson: any;
  designHtml: any;
  chunkBody: any;
}
