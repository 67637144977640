//#region [import]
import { FPATH } from "engines";
import { useConfigCollection } from "engines/firebase/handler/config-handler";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { useAppContext } from "engines/providers";
import { fuego } from "../../../../fuego";
import {
  TemplateSchema,
  _DEFAULT_TEMPLATE
} from "../schemas/mail/templates.schema";
//#endregion

export function useMailTemplatesFunctions<
  Schema extends TemplateSchema /**👈 Replace Schema here*/
>() {
  //#region [section] DATA VALIDATION
  //* Data being type-checked and safe to pass to component.

  //#endregion

  const { setIsFirstTimeUser } = useAppContext();
  const userProfile = useUserProfile();
  const configHandler = useConfigCollection();
  const appConfig = configHandler._getConfigFromSubDomain();
  const { frbsUser } = useAppContext();

  /**
   * ### "Update" the Possts Collection with more possts (pagination)
   *  ----
   *  @example
   *  onEndReached={() => handlePossts.update()}
   *  ----
   *  @version 21.03.15
   *  -  *build it*
   *  @author  NL
   *
   **/
  async function update(payload: Schema) {
    let projectId = "primr-exp";
    const _token = await frbsUser.getIdToken();

    try {
      return (
        _token &&
        fetch(
          `https://us-central1-${projectId}.cloudfunctions.net/api/services/emails/update-template`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(payload),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + _token,
            },
          }
        )
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  async function add(data) {
    // console.log("this is from mail component");

    // console.log(data);
    // console.log("token: ", userProfile._token);
    let projectId = "primr-exp";
    const _token = await frbsUser.getIdToken();
    try {
      return (
        _token &&
        fetch(
          `https://us-central1-${projectId}.cloudfunctions.net/api/services/emails/create-template`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(data),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + _token,
            },
          }
        )
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  async function addDefaultTemplate() {
    /**
     * 1st Step is to check if the welcome template exist else create one
     */

    // console.log("userProfile", userProfile);
    let projectId = "primr-exp";
    const _token = await frbsUser.getIdToken();
    try {
      _token &&
        checkWelcomeEmailExist()
          .then((res) => {
            console.log("res ", res);
            if (res == null) {
              let projectId = "primr-exp";

              const payload = {
                _teamId: userProfile.data?._teamId,
                appId: "primr-exp",
                userId: userProfile.data?._id,
                template_name: _DEFAULT_TEMPLATE.Name,
                // template_alias: subject,
                template_subject: _DEFAULT_TEMPLATE.Name,
                template_html_body: _DEFAULT_TEMPLATE.HtmlBody,
                template_jsonBody: _DEFAULT_TEMPLATE.jsonBody,
              };

              return fetch(
                `https://us-central1-${projectId}.cloudfunctions.net/api/services/emails/create-template`,
                {
                  method: "POST",
                  mode: "cors",
                  body: JSON.stringify(payload),
                  headers: {
                    "content-type": "application/json",
                    Authorization: "Bearer " + _token,
                  },
                }
              );
            } else {
              const payload = {
                _teamId: userProfile.data?._teamId,
                recipient: userProfile.data?.personali.email,
                email_from: `${appConfig?.appTitle} <admin@apptakeoff.com>`,
                // email_reply_to: _email_reply_to,
                email_subject: `Welcome Email`,
                email_html_body: res.HtmlBody,

                // Cc: _ccopy ? _ccopy : "",
              };

              fetch(
                `https://us-central1-${projectId}.cloudfunctions.net/api/services/signup/welcome-email`,
                {
                  method: "POST",
                  mode: "cors",
                  body: JSON.stringify(payload),
                  headers: {
                    "content-type": "application/json",
                  },
                }
              ).then(() => {
                setIsFirstTimeUser(false);
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
    } catch (error) {
      throw new Error(error);
    }
  }

  async function checkWelcomeEmailExist() {
    let welcomeInfo: any = null;
    await fuego.db
      .collection(FPATH.TEMPLATES)
      .where("Name", "==", "Welcome Template")
      .where("_teamId", "==", userProfile.data?._teamId)
      .get()
      .then((docRef) => {
        docRef.docs.forEach((doc) => {
          welcomeInfo = doc.data();

          // console.log("welcome info ", accessInfo);
        });
      })
      .catch(() => {
        throw Error(
          "Welcome template associated with: " +
            userProfile.data?._teamId +
            " was not found."
        );
      });

    return welcomeInfo;
  }

  async function welcomeEmail() {
    let projectId = "primr-exp";
    checkWelcomeEmailExist().then((res: TemplateSchema) => {
      console.log(res);
      const payload = {
        _teamId: userProfile.data?._teamId,
        recipient: userProfile.data?.personali.email,
        email_from: `${appConfig?.appTitle} <admin@apptakeoff.com>`,
        // email_reply_to: _email_reply_to,
        email_subject: `Welcome Email`,
        email_html_body: res.HtmlBody,

        // Cc: _ccopy ? _ccopy : "",
      };

      fetch(
        `https://us-central1-${projectId}.cloudfunctions.net/api/services/signup/welcome-email`,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(payload),
          headers: {
            "content-type": "application/json",
          },
        }
      ).then(() => {
        setIsFirstTimeUser(false);
      });
    });
  }

  async function x(
    pid: Schema["TemplateId"] /**👈 Replace with appropiate id */
  ) {
    //   if (typeof pid !== "string")
    //     return Error("pid is not a string. Please correct the input");

    let projectId = "primr-exp";
    const payload = {
      template_id: pid.toString(),
    };
    const _token = await frbsUser.getIdToken();

    try {
      return (
        _token &&
        fetch(
          `https://us-central1-${projectId}.cloudfunctions.net/api/services/emails/delete-template`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(payload),
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + _token,
            },
          }
        )
      );
    } catch (error) {
      throw new Error(error);
    }

    //   return deleteDocument<Schema>(`${FPATH.TEMPLATES}/${pid}`);
  }

  return {
    add,
    update,
    x,
    addDefaultTemplate,
    welcomeEmail,
  };
}

/**
 * ### Posst Document Hook
 *  - standard GEAUX ops
 *  ----
 *  @example
 *  const handlePosst = usePosstDocument("0z399110dk")
 *  ----
 *  @version 21.03.07
 *  -  *Build a hook*
 *  @author  NL
 *
 **/

interface dPayload {
  appId: string;
  userId: string;
  docID: string;
  template_id: string;
  template_name: string;
  template_subject: string;
  template_short_description: string;
  template_html_body: string;
}
