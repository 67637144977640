import firebase from "firebase"
import { IconPrimr, ICON_NAME } from "assets";
import { Poppy, Txt } from "components";
import { Buttoon, Kitten, Menoo, Toasty } from "components/atoms";
import { HitEmUpEmail } from "components/molecules/HitEmUp/HitEmUpEmail";
import { BlastSmsComposer } from "components/molecules/HitEmUp/hitemupSms";
import { FPATH, MediaPageItemSchema, useAppContext } from "engines";
import * as Clipboard from "expo-clipboard";
import * as React from "react";
import { Platform, Share, View } from "react-native";
import { useNavigator } from "screens";
import { IS_WEB, spacing, tr } from "utils";

interface dTextTemplateItem {
  item: MediaPageItemSchema;
  blastText?: string;
  complete?: boolean;
  /** ghosted and only showing 3 lines */
  minimized?: boolean;
  icon?: ICON_NAME,
  iconRight?: boolean,
  showGoToPageShortcut?: boolean;
  /**
   * When the box itself gets pressed
   */
  onPress?: () => void;
  onOverridePress?: (item: MediaPageItemSchema) => void;
  /**
   * User must tap Blast and selection an option for this to be marked as completed.
   */
  onBlastInitiated?: () => void;
  /**
   * quick way to remove as suggested text script
   */
  allowRemoveFromSuggested?: boolean;
}

// export const isLinkMagnetURL = (url: string): boolean => {
//   return (
//     url &&
//     (url.includes("followthislink.com") ||
//       url.includes("freedomforfamilies.com"))
//   );
// };

// export const isFinURL = (url: string): boolean => {
//   return url && url.includes("discoverfin.io");
// };

/**
 * ### Custom Page's button item
 *  - Custom style based on `stylecode` value in "paragraph"
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.07.06
 *  -  *Separate the component*
 *  @author  K, Nl
 *
 **/
export function BlastTemplateItem(props: dTextTemplateItem) {
  const { item, complete, minimized, onPress, onOverridePress, onBlastInitiated, blastText = "Blast", icon = "bullhorn", iconRight = false, showGoToPageShortcut, allowRemoveFromSuggested } = props;
  const { C, teamId } = useAppContext();
  const Navigation = useNavigator();

  const copyToClipboard = async () => {
    await Clipboard.setString(item?.title || item?.paragraph);

    Toasty.show("Copied to clipboard", { type: "success" });
  };

  const [_forceClose, setForceClose] = React.useState<boolean>(false);

  const FooterActions = () => {
    return (
      <Kitten.ViewH style={{ justifyContent: "space-between" }}>
        {/* {IS_WEB && (
          <Txt.Helper>{tr("Blast is available on mobile only")}</Txt.Helper>
        )} */}

        {item.textTemplate?.enabledForTeamId === teamId ?
          <View style={{ marginLeft: spacing(3) }}><IconPrimr name="check_circle" size={14.5} color={C.primary} /></View> : <View />}

        <Kitten.ViewH>
          <Menoo
            onOpen={() => {
              if (onOverridePress) {
                onOverridePress(item)
                setForceClose(true)
              }
            }}
            onClose={() => {
              setForceClose(false)
            }}
            {...(_forceClose ? { opened: !_forceClose } : {})}
            trigger={{
              text: blastText,
              icon,
              iconRight,
              style: {
                color: minimized ? C.primaryDarker : C.primary,
                alignSelf: "center",
                marginRight: spacing(4),
                marginLeft: spacing(3),
                fontWeight: "bold",
                ...(iconRight ? { marginRight: spacing(3) } : {})
                // paddingVertical: spacing(2),
              },
            }}
            options={[
              {
                text: tr("Blast SMS", ["SMS"]),
                // disabled: IS_WEB,
                onSelect: () => {
                  if (onOverridePress) {
                    onOverridePress(item)
                    return;
                  }
                  onBlastInitiated && onBlastInitiated();
                  Navigation.overlayPortal.open({
                    headerTitle: "Blast SMS",
                    render: (
                      <BlastSmsComposer
                        listData={[item]}
                        shareMode={true}
                        contacts={[]}
                      />
                    ),
                  });
                },
              },
              {
                text: tr("Blast email"),
                onSelect: () => {
                  onBlastInitiated && onBlastInitiated();
                  Navigation.dialogPortal.open({
                    headerTitle: "Blast email",
                    render: (
                      <HitEmUpEmail
                        listData={[item]}
                        shareMode={true}
                        contacts={[]}
                      />
                    ),
                  });
                },
              },
              {
                text: tr("More apps..."),
                onSelect: async () => {
                  try {
                    onBlastInitiated && onBlastInitiated();
                    const result = await Share.share({
                      message: (item?.title || item?.paragraph)?.replace(
                        /🌀/g,
                        ""
                      ),
                    });
                    if (result.action === Share.sharedAction) {
                      if (result.activityType) {
                        // shared with activity type of result.activityType
                      } else {
                        // shared
                      }
                    } else if (result.action === Share.dismissedAction) {
                      // dismissed
                    }
                  } catch (error) {
                    alert(error.message);
                  }
                },
              },
            ]}
          />
          {
            IS_WEB ? (
              <Buttoon
                appearance={"ghost"}
                icon={{ name: "copy" }}
                onPress={() => {
                  copyToClipboard();
                }}
              >
                {tr("Copy")}
              </Buttoon>
            ) : null
            // <Buttoon appearance={"ghost"} icon={{ name: "send" }} onPress={
            //   async () => {
            //     try {
            //       const result = await Share.share({
            //         message: item?.title || item?.paragraph,
            //       });
            //       if (result.action === Share.sharedAction) {
            //         if (result.activityType) {
            //           // shared with activity type of result.activityType
            //         } else {
            //           // shared
            //         }
            //       } else if (result.action === Share.dismissedAction) {
            //         // dismissed
            //       }
            //     } catch (error) {
            //       alert(error.message);
            //     }
            //   }}>
            //   {tr("Share Blast")}
            // </Buttoon>
          }
          {/* {complete ? <Txt.Indicator>Sent</Txt.Indicator> : null} */}
        </Kitten.ViewH>
      </Kitten.ViewH>
    );
  };
  return (
    <View style={{ marginVertical: spacing(1) }}>
      <Kitten.Card
        onPress={() => {
          if (onOverridePress) {
            onOverridePress(item)
            return;
          }
          copyToClipboard();
          onPress && onPress();

          // Navigation.navigate("Contact Manager", { listData: [item] });
          // Navigation.dialogPortal.open({
          //   headerTitle: "Blast SMS",
          //   render: (
          //     <HitEmUpSms listData={[item]} shareMode={true} contacts={[]} />
          //   ),
          // });
        }}
        onLongPress={() => {
          copyToClipboard();
        }}
        // header={() => item.textTemplate?.description || item.textTemplate?.enabledForTeamId === teamId ?
        //   <Kitten.ViewH style={{ justifyContent: "space-between" }}>
        //     <Txt.P2>{item.textTemplate?.description || ""}</Txt.P2>
        //     <Kitten.ViewH>
        // {item.textTemplate?.enabledForTeamId === teamId ?
        //   <IconPrimr name="check_circle" size={14} color={C.primary} /> : null}
        //     </Kitten.ViewH>
        //   </Kitten.ViewH> : null}
        footer={() => <FooterActions />}
      >
        {item?.textTemplate?.description ? <><Txt.Indicator style={{ marginBottom: spacing(2), fontWeight: "bold" }}>{item?.textTemplate?.description}</Txt.Indicator>
          <Kitten.Divider_________ style={{ opacity: 0.7 }} /></> : null}
        <Txt
          style={{ fontFamily: Platform.OS == "ios" ? "Courier" : "monospace", ...(minimized ? { opacity: 0.6 } : {}) }}
          numberOfLines={minimized ? 3 : 0}
        >
          {item?.title || item?.paragraph}
        </Txt>
      </Kitten.Card>
      <Kitten.ViewH style={{ justifyContent: "space-between" }}>
        {allowRemoveFromSuggested ? <Buttoon size="small" appearance="ghost" status="danger" icon={{ right: true, name: "trash" }} onPress={() => {
          Poppy("Are you sure?", "This will not delete this text script but will remove it as a suggested text here.", { text: "Cancel" }, {
            text: "Remove", onPress: async () => {
              try {
                console.log(`removing script ${item?.textTemplate?.parentPageId}/${item._id}`)
                await firebase.firestore().doc(`${FPATH.PAGES}/${item?.textTemplate?.parentPageId}/${FPATH.CUSTOM_PAGE_CONTENT}/${item?._id}`).update({
                  textTemplate: {
                    ...item?.textTemplate,
                    enabledForTeamId: null,
                  }
                })
                Toasty.show("Removed. Refresh your app to see the change.", { type: "normal" })
              } catch (err) {
                Toasty.show(err, { type: "danger" })
              }
            }
          })
        }}></Buttoon> : null}
        {showGoToPageShortcut ? <Buttoon size="small" appearance="ghost" status="basic" icon={{ right: true, name: "arrow_right" }} onPress={() => {
          if (!item?.textTemplate?.parentPageId) {
            Poppy("Page not found.", "No page found corresponding to this item.")
            return;
          }
          Navigation.goBack()
          setTimeout(() => {
            Navigation.navigate("Page Contents", {
              cpid: item?.textTemplate?.parentPageId,
            });
          }, 50)
        }}>View page</Buttoon> : null}
      </Kitten.ViewH>
    </View>
  );
}
