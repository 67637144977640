import { Buttoon, sstyled, Toasty } from "components";
import { useAppContext, __ResourceItemData } from "engines";
import { usePageContentsCollection } from "engines/firebase/handler/page-contents-handler";
import * as R from "ramda";
import React from "react";
import { TextInput, View } from "react-native";
import { scale, spacing, tr } from "utils";

export function TitleItemCreator({ cpid, onCloseCallback = () => {} }: P) {
  const { C } = useAppContext();

  //#region [FRBS] page contents handler
  const pageContentsHandler = usePageContentsCollection(cpid);
  //#endregion

  const [title, setTitle] = React.useState<string>("");
  const refInput = React.useRef<TextInput>();
  React.useEffect(function inputFocus() {
    setTimeout(() => {
      refInput.current?.focus();
    }, 420);
  }, []);

  function onSavePress(xong?) {
    pageContentsHandler.add({
      ...__ResourceItemData(),
      title: title,
      position: pageContentsHandler._largestPositionIndex() + 1, //* Latest position on the ContentBody
    });
    setTimeout(() => {
      Toasty.show(tr("Title added!"));
      xong && xong();
      onCloseCallback && onCloseCallback();
    }, 690);
  }
  return (
    <View>
      <A.InputTitle
        ref={refInput}
        placeholder={tr("Write a catchy title...")}
        value={title}
        onChangeText={setTitle}
        multiline
        style={{
          minWidth: scale(200),
          minHeight: scale(108),
          fontWeight: "bold",
          fontSize: scale(24),
        }}
      />

      <Buttoon
        size={"small"}
        progress
        compact
        disabled={R.isEmpty(title)}
        onPress={onSavePress}
      >
        {tr("Add")}
      </Buttoon>
    </View>
  );
}

interface P {
  /**
   * the doc id of the current custom page
   */
  cpid: string;
  onCloseCallback(): void;
}

const A = {
  InputTitle: sstyled(TextInput)({
    minHeight: scale(50),
    color: "text",
    borderWidth: 1,
    borderRadius: scale(4),
    borderColor: "primary",
    padding: spacing(3),
    margin: spacing(3),
  }),
};
