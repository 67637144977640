import { IndexPath } from "@ui-kitten/components";
import { Avt, Kitten, sstyled, Txt } from "components";
import { Toasty } from "components/atoms";
import { useBreakpointIndex } from "dripsy";
import {
  MediaPageSchema,
  URL_PREFIX,
  useAppContext,
  __MediaResourcesData
} from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import { usePageContentsCollection } from "engines/firebase/handler/page-contents-handler";
import { usePagesCollection } from "engines/firebase/handler/pages-handler";
import { useCollabContext } from "engines/providers/collaboration-provider";
import * as R from "ramda";
import React from "react";
import { View } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import { scale, spacing, tr } from "utils";

/**
 * ### Set a link to go to specific url (webpage, custom page...)
 *  - Used in `button-item-creator`, `posst-creator`
 *
 *  ----
 *  @version 21.09.08
 *  -  *Build a Component*
 *  @nguyenkhooi
 *
 **/
export function CopyTo({ onChange, flexDirection }: dCopyTo) {
  const { C, teamId } = useAppContext();
  const breakpoints = useBreakpointIndex();
  const { setCopyToPageId, copyToPageId, isCollabModeOn } = useCollabContext();
  const user = useUserProfile();
  const { _getSubPagesId } = usePageContentsCollection(copyToPageId);

  /**
   * Use offset 2 if allowing user to create a page as an option
   */
  const indexOffset = 1;

  //#region [FRBS]
  const pagesHandler = usePagesCollection();

  const [pages, setPages] = React.useState<MediaPageSchema[]>([]);
  const [autoSelectPageId, setAutoSelectPageId] = React.useState<string>(
    copyToPageId ? copyToPageId : null
  );
  const autoSelectedPage =
    autoSelectPageId &&
    pagesHandler.data?.filter((_pg) => _pg._id === autoSelectPageId).pop();

  React.useEffect(
    function setupData() {
      pagesHandler.data && setPages(pagesHandler.data);
    },
    [pagesHandler.data]
  );

  React.useEffect(() => {
    setCopyToPageId(copyToPageId);
    // console.log("Copy to page Id is ", copyToPageId);
  }, [copyToPageId]);
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    setQuery(value);
    const newData = pagesHandler.data?.filter((page) => {
      return page.name?.toLowerCase().includes(value.toLowerCase());
    });
    // console.log("valuee: ", newData);
    setPages([...newData]);
  }
  //#endregion

  //#region [section2] Create new page
  async function onCreateNewPage() {
    // if (user._isBaseShopOwner(`BS:${copyToPageId}`) && !user._isUserAdmin()) {
    //   Toasty.show(tr("Base shop owner's cannot create new subpage..."), {
    //     type: "danger",
    //   });
    //   return;
    // }
    const __toast = Toasty.show(tr("Creating new subpage..."), {
      type: "loading",
    });
    console.log("creating new subpage with name: " + query);
    pagesHandler
      .add({
        ...__MediaResourcesData({
          teamId,
          pageName: query,
        }),
        mediaItem: { visible: true },
      })
      .then((id) => {
        setQuery("");
        setAutoSelectPageId(id);
        setCopyToPageId(id);
        onChange && onChange(URL_PREFIX.PAGE + id, query);
        // kittenSelect.current.props.onSelect(new IndexPath(indexOffset))
        Toasty.update(__toast, tr("New subpage created!"), {
          type: "success",
        });
      });
  }
  //#endregion

  const [selectedTypeIndex, setSelectedTypeIndex] = React.useState(0);
  const [_caption, setCaption] = React.useState<string>(
    'Add a page name, or an url link starting with "www."'
  );

  // const kittenSelect = React.useRef<Select>(null);

  // const [sortedPages, setSortedPages] = React.useState<MediaPageSchema[]>([]);

  let sortedPages = R.sortWith<MediaPageSchema>(
    [
      /** sorting `name` in `asc` */
      R.ascend(R.prop("name")),
    ],
    /** If the user is admin then return all pages, but if he is baseshop owner then only return the base shop page for selection */
    pages.filter((p) => {
      return user._isUserAdmin()
        ? true
        : _getSubPagesId()?.includes(p._id) ||
            user._isBaseShopOwner(`BS:${p._id}`);
    })
  );

  // FIXME @SM -> @K This is what we worked on together
  // React.useEffect(() => {
  //   if (pages) {
  //     const data = R.sortWith<MediaPageSchema>(
  //       [
  //         /** sorting `name` in `asc` */
  //         R.ascend(R.prop("name")),
  //       ],
  //       /** If the user is admin then return all pages, but if he is baseshop owner then only return the base shop page for selection */
  //       pages.filter((p) => {
  //         return user._isUserAdmin()
  //           ? true
  //           : user._isBaseShopOwner(`BS:${p._id}`);
  //       })
  //     );
  //     setSortedPages(data);
  //   }
  // }, [pages]);

  const [_selectedPageIndex, setSelectedPageIndex] =
    React.useState<IndexPath>(null);

  let selectedPageIndex = autoSelectedPage
    ? new IndexPath(sortedPages.indexOf(autoSelectedPage) + indexOffset)
    : _selectedPageIndex;

  // React.useEffect(() => {
  //   if (sortedPages) {
  //     const index = sortedPages.findIndex((p) => p.name.includes(query));
  //     selectedPageIndex = new IndexPath(index + indexOffset);
  //     console.log(selectedPageIndex);
  //   }
  // }, [sortedPages]);

  // const [newPageName, setNewPageName] =
  //   React.useState<string>("Add a new page");

  return React.useMemo(() => {
    try {
      if (pagesHandler.error)
        return <Txt.Indicator>{"Error: " + pagesHandler.error}</Txt.Indicator>;

      if (!pagesHandler.data)
        return <Txt.Indicator>{tr("Loading...")}</Txt.Indicator>;

      /**
       * We have to subtract 2 to get the correct item, this takes into account the search for pages input and the create new page button
       */
      const displayValue = selectedPageIndex
        ? sortedPages[selectedPageIndex.row - indexOffset]?.name
        : null;

      // let _radioGroupDirection = () => {
      //   if (flexDirection) {
      //     return flexDirection;
      //   } else {
      //     return breakpoints < 3 ? "column" : "row";
      //   }
      // };
      return (
        <View>
          <Txt.Helper marginV>
            {tr("Select a page to copy the selected items to")}
          </Txt.Helper>
          <Kitten.ViewH style={{ width: "100%" }}>
            <Kitten.Select
              // ref={kittenSelect}
              style={{ width: "95%" }}
              placeholder={tr("Select a page")}
              value={displayValue}
              selectedIndex={selectedPageIndex}
              onSelect={(index) => {
                console.log("[page_select] selected index: " + index);

                /**
                 * For creating a new page
                 */
                // if (index?.row === 1) {
                //   onCreateNewPage();
                // }

                // index.row !== 1 && setSelectedPageIndex(index);
                setSelectedPageIndex(index);
                // setCaption("Nice!");

                // console.log(
                //   "[page_select] autoselect pageid1: " + autoSelectPageId
                // );
                // if (autoSelectPageId) {
                //   /**
                //    * After a new page gets created, auto select it
                //    */
                //   onChange && onChange(URL_PREFIX.PAGE + autoSelectPageId);
                // } else {
                onChange && isCollabModeOn
                  ? onChange(
                      sortedPages[index.row - indexOffset]?._id,
                      sortedPages[index.row - indexOffset]?.name
                    )
                  : onChange(sortedPages[index.row - indexOffset]?._id);
                // }

                setAutoSelectPageId(null);
              }}
              // caption={_caption}
              // style={{ maxWidth: 293 - 60 }}
            >
              <A.InputSearch
                autoFocus={true}
                value={query}
                placeholder={tr("Search page by name")}
                onChangeText={(value) => {
                  setQuery(query);
                  setSelectedPageIndex(null);
                  filterHandler(value);
                }}
              />
              {/* <Kitten.SelectItem
                disabled={!query}
                title={
                  query ? tr("Create a page named") + ' "' + query + '"' : ""
                }
                accessoryLeft={() =>
                  query ? (
                    <IconPrimr
                      name={"plus"}
                      preset={"circular"}
                      color={C.text}
                    />
                  ) : null
                }
              /> */}
              {sortedPages?.map((page, index) => (
                <Kitten.SelectItem
                  key={page._id}
                  title={page.name}
                  accessoryLeft={() => (
                    <Avt
                      name={page.name || "K"}
                      source={{ uri: page.mediaItem?.logo || "" }}
                    />
                  )}
                />
              ))}
            </Kitten.Select>
          </Kitten.ViewH>
        </View>
      );
    } catch (error) {
      throw Error("::TGlua1Rv::" + error);
    }
  }, [
    query,
    selectedPageIndex,
    selectedTypeIndex,
    pages,
    copyToPageId,
    sortedPages,
  ]);
}

interface dCopyTo {
  /**
   * `link` will be either:
   *  - a url (start with http), or
   *  - a page id (start with page:PAGE_ID)
   * it'll be ALL store on url field of PageItemSchema,
   * since `toPage` field may contain page's name according to the old schema
   */
  onChange(link: string, navTitle?: string): void;
  flexDirection?: "row" | "column";
}

const A = {
  InputSearch: sstyled(TextInput)({
    minWidth: 7,
    color: "text",
    fontSize: scale(16),
    padding: spacing(3),
    margin: spacing(3),
    borderBottomWidth: 1,
    borderColor: "primary",
  }),
};
