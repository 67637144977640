import { GHLAccount } from "../../schemas/configs/configs.schema";

export interface GHLCustomField {
  id: string;
  name: string;
  fieldKey: string;
  placeholder: string;
  position: number;
  dataType: string;
  picklistOptions: string[];
}

/**
 * Returns all the custom fields for the provided ghlAccount
 *
 * @author jm_francis
 *
 * @example
 * const customFields = await getCustomFields(ghlAccount)
 */
export async function getCustomFields(
  ghlAccount: GHLAccount
): Promise<GHLCustomField[]> {
  return fetch(`https://rest.gohighlevel.com/v1/custom-fields/`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${ghlAccount.apiKey}`,
    },
  })
    .then((res) => res.json())
    .then((json) => {
      const { customFields } = json;
      if (customFields) {
        return customFields;
      } else {
        const errMsg = `Failed to get customFields with response: ${JSON.stringify(
          json
        )}`;
        console.log(errMsg);
        throw errMsg;
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}
