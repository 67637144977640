import { Buttoon, sstyled, Txt, Videolly } from "components";
import { useAppContext } from "engines";
import { Video } from "expo-av";
import * as React from "react";
import { ScrollView, useWindowDimensions, View } from "react-native";
import * as Animatable from "react-native-animatable";
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue
} from "react-native-reanimated";
import { useNavigator } from "screens";
import { spacing, THEME, tr } from "utils";

/**
 * ### A splash screen that
 * -  Navigate to HomeStack if !!currentUser
 * -  Show Sign in button if !currentUser
 *  ----
 *  @version 21.03.15
 *  -  *Web+mobile in one*
 *  @author  K
 *
 **/
export function OnboardingScreen() {
  const { C, theme, setTheme, isReady } = useAppContext();
  const { height } = useWindowDimensions();
  const Navigation = useNavigator();
  const refPromoVideo = React.useRef<Video>();

  //#region [ANM]
  const refRocket = React.useRef<Animatable.View>();
  const refMoon = React.useRef<Animatable.View>();

  React.useEffect(
    function darkModeCartoon() {
      return () =>
        theme == THEME.DARK
          ? refMoon.current?.slideInDown()
          : refMoon.current?.fadeOutUp();
    },
    [theme]
  );

  let scrollY = useSharedValue(0);
  let posYAuthCtnr = useSharedValue(0);
  const reAuthCtnrStyle = useAnimatedStyle(() => ({
    justifyContent: "center",
    alignItems: "center",
    transform: [
      {
        scale: interpolate(scrollY.value, [0, height], [1, 0]),
      },
      { translateY: posYAuthCtnr.value },
    ],
  }));
  const rScrollViewStyle = useAnimatedStyle(() => ({
    paddingHorizontal: 10,
    // flex: 1,
    // backgroundColor: "primaryDarker",
    backgroundColor: interpolateColor(
      scrollY.value,
      [0, height, height * 2],
      [C.background, C.primary, C.errorRed] //"#0b0e10"
    ),
  }));
  //#endregion
  const refList = React.useRef<ScrollView>(null);
  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollY.value = event.contentOffset.y;
  });

  const scrollToSection = (section: "top" | "video" | "footer") => {
    switch (section) {
      case "top":
        refList.current?.scrollTo({ y: 0, animated: true });
        break;
      case "video":
        refList.current?.scrollTo({ y: height, animated: true });
        break;
    }
  };
  try {
    return (
      <Animated.ScrollView
        // contentContainerStyle={{ height: height, width: "100%" }}
        ref={refList}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        style={rScrollViewStyle}
      >
        <View
          style={{
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Videolly
            ref={refPromoVideo}
            type="thumbnail"
            // ref={video}
            // style={{
            //   alignSelf: "center",
            //   width: "100%",
            //   height: height,
            //   // width: "80%",
            //   // height: "auto",
            // }}
            posterSource={{
              uri: "https://res.cloudinary.com/https-apptakeoff-com/image/upload/q_auto:low/v1621931630/promo/promovid2_fbefip.gif",
            }}
            source={{
              uri: "https://res.cloudinary.com/https-apptakeoff-com/video/upload/v1621522031/promo/Hierarchy_App_Commercial_bhaybo.mp4",
            }}
          />
        </View>
        <A.ViewOverlayPromo height={height}>
          <A.Headline style={{ color: C.text01 }}>
            {tr("Our Mission")}
          </A.Headline>
          <A.Paragraph style={{ color: C.text01 }}>
            {tr(
              `We strive for innovation and growth in everything we do, to provide you with the best and most important features you need. We want to empower you to spend less time switching between multiple platforms, by putting everything you need in one place.`
            )}
          </A.Paragraph>
          <Buttoon
            icon={{ name: "play" }}
            onPress={() => {
              refPromoVideo.current?.presentFullscreenPlayer() &&
                refPromoVideo.current?.playAsync();
            }}
          >
            {tr("See what we offer") + ` • 1:18`}
          </Buttoon>
        </A.ViewOverlayPromo>
      </Animated.ScrollView>
    );
  } catch (error) {
    throw Error(`::V2VsY29tZVNjcmVlbg==::${error}`);
  }
}

const A = {
  ViewOverlayPromo: sstyled(View)((p) => ({
    position: "absolute",
    width: "100%",
    paddingHorizontal: spacing(7),
    bottom: p.height * 0.2,
    justifyContent: "center",
    alignItems: "center",
  })),
  TxtTitle: sstyled(Txt.H1)((p) => ({
    fontSize: [22, 38],
    textAlign: "center",
    color: "text",
  })),
  Headline: sstyled(Txt.H6)({
    // color: "grey500",
    color: "text",
    fontSize: [18, 28],
    textAlign: "center",
    marginVertical: spacing(5),
    paddingHorizontal: spacing(3),
  }),
  Paragraph: sstyled(Txt.S1)((p) => ({
    // color: "grey500",
    color: "text",
    fontSize: [14, 24],
    textAlign: "center",
    marginBottom: spacing(5),
    paddingHorizontal: spacing(3),
  })),
};
