import React, { useState, useEffect, forwardRef } from "react";

import { StyleSheet, Platform, ViewPropTypes, ViewProps } from "react-native";

// import PropTypes from "prop-types";

import { WebView } from "react-native-webview";
import { IS_ANDROID } from "utils";

import { reduceData, getWidth, isSizeChanged, shouldUpdate } from "./utils";

export const AutoHeightWebView = React.memo(
  forwardRef((props, ref) => {
    const {
      style,
      onMessage,
      onSizeUpdated,
      scrollEnabledWithZoomedin,
      scrollEnabled,
      showsVerticalScrollIndicator = false,
      showsHorizontalScrollIndicator = false,
      originWhitelist = ["*"],
      scalesPageToFit = IS_ANDROID ? false : true,
      viewportContent = Platform.OS === "ios" && "width=device-width",
    } = props;

    const [size, setSize] = useState({
      height: style && style.height ? style.height : 0,
      width: getWidth(style),
    });
    const [scrollable, setScrollable] = useState(false);
    const handleMessage = (event) => {
      onMessage && onMessage(event);
      if (!event.nativeEvent) {
        return;
      }
      let data = {};
      // Sometimes the message is invalid JSON, so we ignore that case
      try {
        data = JSON.parse(event.nativeEvent.data);
      } catch (error) {
        console.error(error);
        return;
      }
      const { height, width, zoomedin } = data;
      !scrollEnabled && scrollEnabledWithZoomedin && setScrollable(!!zoomedin);
      const { height: previousHeight, width: previousWidth } = size;
      isSizeChanged({ height, previousHeight, width, previousWidth }) &&
        setSize({
          height,
          width,
        });
    };

    const currentScrollEnabled =
      scrollEnabled === false && scrollEnabledWithZoomedin
        ? scrollable
        : scrollEnabled;

    const { currentSource, script } = reduceData(props);

    const { width, height } = size;
    useEffect(
      () =>
        onSizeUpdated &&
        onSizeUpdated({
          height,
          width,
        }),
      [width, height, onSizeUpdated]
    );

    return React.createElement(WebView, {
      ...props,
      ref,
      onMessage: handleMessage,
      style: [
        { backgroundColor: "transparent" },
        {
          width,
          height,
        },
        style,
      ],
      injectedJavaScript: script,
      source: currentSource,
      scrollEnabled: currentScrollEnabled,
    });
  }),
  (prevProps, nextProps) => !shouldUpdate({ prevProps, nextProps })
);

interface dAutoHeightWebView {
  onSizeUpdated(): void;
  files: { href: string; type: string; rel: string }[];
  style: ViewProps["style"];
  customScript: string;
  customStyle: string;
  viewportContent: string;
  scrollEnabledWithZoomedin: boolean;
  originWhitelist: string[];
  onMessage(): void;
  scalesPageToFit: boolean;
  source: object;
}
