import * as Contacts from "expo-contacts";
import firebase from "firebase";
import { dContactNote } from "../bml";

/**
 * @deprecated use ContactGroupSchema from contacts.schema.ts
 */
export interface ContactGroupSchema {
  _id?: string;
  name?: string;
  groupType?: "email-groups" | "list";
  contacts?: DetailContact[];
  description?: string;
  createdAt?: firebase.firestore.FieldValue;
}

/**
 * @depreacted use ContactSchemaa
 */
export interface ContactSchema {
  _cid: string;
  displayName: string;
  email?: string;
  groups?: string[] | firebase.firestore.FieldValue;
  groupType?: "email-groups" | "list";
  phoneNumbers?: string[];
  createdAt?: firebase.firestore.FieldValue;
  /**
   * @deprecated DO NOT USE THIS, use the qualification field instead
   */
  pointer?: number; //todo newly added
  qualifications?: string[];
  /**
   * @description Related to BML
   */
  listId?: string;
  notes?: dContactNote[];
}

export type DetailContact = {
  displayName?: string;
  phoneNumbers: Partial<Contacts.PhoneNumber[]>;
  email?: string;
  /**
   * Required for member contact & non-member contact
   * - if it's member contact, the syntax is: `uid:[MEMBER_UID]`
   * - if it's non-member contact, the syntax is ramdom uid without `uid:` at the beginning
   * todo: change to _id
   */
  uid: string;
  priority?: number; //todo newly added
  pointer?: number; //todo newly added
  /** @description For BML  */
  qualifications?: string[];
};

export interface dContactCSV {
  displayName: string;
  phoneNumber: any;
  email: string;
  qualifications: string[];
  notes: string;
}

export const __DEFAULT_CONTACTGROUP: ContactGroupSchema = {
  _id: Math.random().toString(),
  name: "Awesome Contact Group",
  groupType: "list",
  contacts: [],
  createdAt: null,
};
