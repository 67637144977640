import firebase from "firebase";
import { FPATH } from "../../../firebase/firebase.props";
import { GHLAccount } from "../../schemas";
import { GHL_Token } from "../../schemas/configs/configs.schema";

export async function getAccessToken(
  teamId: string,
  ghlAccount: GHLAccount
): Promise<GHL_Token> {
  return fetch(
    `https://us-central1-primr-exp.cloudfunctions.net/getGHLAccessToken`,
    {
      method: "POST",
      body: JSON.stringify({
        teamId,
        ghlAccount,
      }),
    }
  )
    .then((res) => res.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

/**
 * @deprecated use server instead
 * Will return an access token, refresh token, etc.
 * - You can optionally provide the a previous tokens set in which case the refresh token will be used to generate a new access token
 * - An access token only gets one use and expires within like a day
 */
export async function _getAccessToken(
  ghlAccount: GHLAccount,
  tokens?: GHL_Token
): Promise<GHL_Token> {
  //   console.log(
  //     `Generating new access token using ${
  //       tokens
  //         ? `provided tokens... refresh_token: ${tokens.refresh_token}`
  //         : "code."
  //     }`
  //   );
  const { clientId, clientSecret, code } = ghlAccount;

  const refresh_token =
    tokens && tokens.refresh_token?.length > 0 ? tokens.refresh_token : null;

  const grantType: "refresh_token" | "authorization_code" = refresh_token
    ? "refresh_token"
    : "authorization_code"; // authorization_code | refresh_token
  grantType === "authorization_code" &&
    console.warn(
      `refresh_token failed, attempting to use authorization_code grantType`
    );
  const details = {
    client_id: clientId,
    client_secret: clientSecret,
    // same as authorization_code ?
    ...(refresh_token ? {} : { code }),
    grant_type: grantType,
    refresh_token: refresh_token || "",
  };

  let formBody = [];
  for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    // @ts-ignore it works
    formBody.push(encodedKey + "=" + encodedValue);
  }
  // @ts-ignore it works
  formBody = formBody.join("&");

  return fetch(`https://services.leadconnectorhq.com/oauth/token`, {
    method: "POST",
    // @ts-ignore take it
    body: formBody,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(
        `[tokeHandler] JSON response for token: ${JSON.stringify(json)}`
      );
      //   console.log(JSON.stringify(json));
      if (json.access_token) {
        console.log("Just generated access token " + json.access_token);
      } else {
        if (json.error === "invalid_grant" && grantType === "refresh_token") {
          console.warn(
            "[tokenHandler] Attempting to use code grant type since refresh_token type did not work..."
          );
          return _getAccessToken(ghlAccount);
        }
        const errMsg =
          "[tokeHandler] Failed to get a token with json response...\n " +
          JSON.stringify(json);
        console.error(errMsg);
        throw errMsg;
      }
      return json;
    })
    .catch((err) => {
      console.log("ERR: " + err);
      throw err;
    });
}

/**
 * @deprecated handled on server-side cloud functions now
 * When getting a new access_token and refresh_token from GHL, you'll want to save it right away!
 * - This function will save the new token to the provided locationId
 *
 * @author jm_francis
 *
 * @example
 * await updateToken("apricot-apptakeoff", "fj23f8j2f8j23f", { access_token, refresh_token })
 */
export async function updateToken(
  teamId: string,
  locationId: string,
  token: GHL_Token
): Promise<void> {
  try {
    const keysDoc = firebase
      .firestore()
      .collection(FPATH.CONFIGS)
      .doc(`keys-${teamId}`);

    const goHighLevelAccounts =
      (await keysDoc.get()).data()?.apis?.goHighLevelAccounts || [];

    console.log(
      "updating token for " + teamId + " at GHL location " + locationId
    );

    let didFindAccountToUpdate = false;
    for (let g in goHighLevelAccounts) {
      const _acc = goHighLevelAccounts[g];
      if (_acc.locationId === locationId) {
        didFindAccountToUpdate = true;
        goHighLevelAccounts[g].lastToken = token;
        goHighLevelAccounts[g].lastUpdated = new Date();
        break;
      }
    }

    if (!didFindAccountToUpdate) {
      const errMsg = `(updateToken) Failed to find goHighLevelAccount for locationId: ${locationId}`;
      console.log(errMsg);
      throw errMsg;
    }

    return await keysDoc.update({
      apis: {
        goHighLevelAccounts,
      },
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
}
/**
 * Returns all the goHighLevelAccounts from firestore in the keys config
 * - Use the .locationId field to identify an account
 *
 * @author jm_francis
 *
 * @schema
 * keys: {
 * apis: {
 *   goHighLevelAccounts: [{
 *     name: string,
 *     locationId: string,
 *     apiKey: string,
 *     clientId: string,
 *     clientSecret: string,
 *     code: string,
 *     lastToken: { access_token: string, refresh_token: string }
 *   }]
 * }
 * }
 *
 * @example
 * const ghlAccounts = await getGoHighLevelAccounts("apricot-apptakeoff")
 */
async function getGoHighLevelAccounts(teamId: string): Promise<GHLAccount[]> {
  try {
    const keysDoc = firebase
      .firestore()
      .collection(FPATH.CONFIGS)
      .doc(`keys-${teamId}`);
    const goHighLevelAccounts =
      (await keysDoc.get()).data()?.apis?.goHighLevelAccounts || [];
    return goHighLevelAccounts;
  } catch (err) {
    console.log(err);
    throw err;
  }
}
