import { IconPrimr } from "assets";
import { useAppContext } from "engines";
import React from "react";
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native";

function ListItemDeleteAction({ onPress }: { onPress: any }) {
  const { C } = useAppContext();
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View
        style={[styles.container, { backgroundColor: C["color-danger-900"] }]}
      >
        <IconPrimr name="trash" color={C.pitchWhite} />
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 50,
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginBottom: 4,
  },
});
export default ListItemDeleteAction;
