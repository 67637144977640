import firebase from "firebase";
import { NAVIGATION_ROUTE } from "screens";
export enum RECTYPE {
  "DAILY" = "Daily",
  "WEEKLY" = "Weekly",
  "MONTHLY" = "Monthly",
  "YEARLY" = "Yearly",
  "BLANK" = "",
}
export interface dComment {
  uid: string;
  //   timestamp: Timestamp | Date;
  timestamp: Date;
  name: string;
  profileImage?: string;
  message: string;
}

export enum NotificationStatus {
  SENT = "sent",
  SCHEDULED = "scheduled",
}
export interface dNotification {
  id?: string;
  status: NotificationStatus;
  baseShopId?: string;
  teamName?:string;
  _cloudFunctionEndpoint?: string;
  _teamId: string;
  channel:string;
  icon?:string;
  /**
   * When the notification was sent or when it is scheduled to be sent
   */
  // timestamp?: Timestamp | Date;
  createdAt?: firebase.firestore.FieldValue;
  timestamp?: firebase.firestore.Timestamp;
  title: string;
  message: string;
  video?: string;
  url?: string; 
  
  comments?: dComment[];
  recurrence?: RECTYPE;

  goToScreen?: dgoToScreen; //WIP

}

/**
 * WIP
 */
interface dgoToScreen {
  name: NAVIGATION_ROUTE,
  params: any
}