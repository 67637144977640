export const appKeys = {
    "keys":[
       { "name": "synergy-pro-apptakeoff", "apiKey": "AIzaSyASitHnYJEIPz2CsxBuQBmXpbp8YNZrdhM" },
       { "name": "dreamchasers-apptakeoff", "apiKey": "AIzaSyDtOAaGYLaogUOWuOuqRuT3CEAo0KMwf-g" },
       { "name": "tavala-apptakeoff", "apiKey": "AIzaSyB1O2HeFLrlShYPSNS8oOxk1odDmyd5y-c" },
       { "name": "mens-alliance", "apiKey": "AIzaSyAsdowbc9lpoPMdjGqJK3RUElTPzOBDd8I" },
       { "name": "jh-erc", "apiKey": "AIzaSyCCLmt7s5FAHhm8iAeCX0Qu0VOMWbyT-78" },
       { "name": "team-roar-apptakeoff", "apiKey": "AIzaSyDLslyKa9YdPteT4JzCRPewj4VWBvh6eBs" },
       { "name": "arising-leaders", "apiKey": "AIzaSyDBejZZb5Oha2KbWR3q5cx3VsM35PdwOp0" },
       { "name": "comedy-hypno", "apiKey": "AIzaSyAbNGh3uxWqTd4SzwRu6hZ9Bx0hAc7LTT8" },
       { "name": "unleash-apptakeoff", "apiKey": "AIzaSyBVRHwemHMjswzKboiqJJyhlY_B-IiYMXY" },
       { "name": "fearless-chaffin", "apiKey": "AIzaSyALhFw3Dl6eQTgt-iQFNjVgAirskY5k0Zs" },
       { "name": "rocky-mountain-impact", "apiKey": "AIzaSyDwYracfmo6jX39NBKrJkRR0A-NYs-GhFA" },
       { "name": "fml-experior", "apiKey": "AIzaSyCHLW2SC8HH4Hu3D3dkYJrehXccoZnkPM0" },
       { "name": "iron-life-apptakeoff", "apiKey": "AIzaSyBVlNHl6UZBS2jnKOFZSuBYQwZ4T_hHW2U" },
       { "name": "landrumlegends", "apiKey": "AIzaSyAiDVzLx4dU6MaQL7ieNGxnaw1xVamJjZ0" },
       { "name": "team-unstoppable-apptakeoff", "apiKey": "AIzaSyB5KeDiZ5uvhO56QADA6He_-rh_rlb-HjU" },
       { "name": "apricot-apptakeoff", "apiKey": "AIzaSyCtPZ_7vVIkzJVXGAkkuGALKVbAQe_Atjc" },
       { "name": "team-erc", "apiKey": "AIzaSyBWlZSXHYVJxGG6qslVfnvGDJAkNZe9FjY" },
       { "name": "experior-apptakeoff", "apiKey": "AIzaSyCZ-S5dPVmKj12S-lj71r3LxszNknRbIXc" },
       { "name": "maniacs-omt", "apiKey": "AIzaSyAFbdn2bcxtOol48wrgbiwDSs0RLQRr-EU" },
       { "name": "legacy-builders-financial", "apiKey": "AIzaSyDcwRke64GzIanroIhAC_X4ezBmbA4BoYQ" },
       { "name": "champions-united", "apiKey": "AIzaSyDFPNQE7JERDmPS7bIr-EqHTbOIuDjePF0" },
       { "name": "vinciamo", "apiKey": "AIzaSyBDa6RzD4_Nab5lmW71BRSMkE9ghiOI-No" },
       { "name": "rat-pack-apptakeoff", "apiKey": "AIzaSyAhH1qEsAQcKi6eBoY9V9W_PTtwnEQtKig" },
       { "name": "dogpound-empire", "apiKey": "AIzaSyD4z8NwCi25CGURZOFOzi_4beglzRShIxo" },
       { "name": "team-xplosion-apptakeoff", "apiKey": "AIzaSyD4bSHKusSqd4bi5Te_0vLO-bkDmU3pzrs" },
       {"name":"crusaders-app-apptakeoff",  "apiKey":"AIzaSyDjNgI8U_7v6c91CUmtlmC0-Ie8rmqTW5s"},
       {"name":"big-apptakeoff","apiKey":"AIzaSyCy1DNJ__kfaP6B9qndnqPDrU-IBRfHbQs"},
       {"name":"one-team-one-dream","apiKey":"AIzaSyDqXVHiABuzSeF3Qk8IxFPjmCUXZ5AXgKE"},
       {"name":"app-takeoff","apiKey":"AIzaSyBTKYvU_pFqVDfg0Q3x5bKZMnPmpv09xR4"},
       {"name":"app-takeoff-team","apiKey":"AIzaSyBoae3ydBqRxAzc4dLkr5csqiiD-aLvK7o"},
       {"name":"arrizon-hierarchy","apiKey":"AIzaSyA7eFAPV79kS-Wz1mMRSxmzz51sNitRSKU"},
       {"name":"benitez-rising-stars","apiKey":"AIzaSyCDry69FM1jpT01xUv9Gzz1xpg-I83gTXM"},
       {"name":"billionaire-business-builders","apiKey":"AIzaSyBhaTqMmkbyL5EgtU2bE8vgBoLaba4cfLM"},
       {"name":"carreon-hierarchy-mb2","apiKey":"AIzaSyAnyPps14uHaPlzJPbpQwim8-eAdhTZATU"},
       {"name":"casper-apptakeoff","apiKey":"AIzaSyCPk_YZiD27lPu3ZIsRHvKzsJHVdH4ziRw"},
       {"name":"champnation-apptakeoff","apiKey":"AIzaSyAcqvTPmL8P2QtqAmBGhgi89OLQrRnOTso"},
       {"name":"conant-financial-group","apiKey":"AIzaSyCXI7S5Dz1p2KrotC7jiG3sNXPWVBHw9O8"},
       {"name":"david-and-associates","apiKey":"AIzaSyBx41i80kRdrOeWi8BzCjo6MYxMtxNEp9E"},
       {"name":"dominators-mier-hierarchy","apiKey":"AIzaSyCpayM1ZquhO6jVkIVGZuGIhmD9seXz5BQ"},
       {"name":"dream-team-drake","apiKey":"AIzaSyDSY-vOWTt2sIEAQ6aZUotDgIWhZtLxNSc"},
       {"name":"dream-team-hierarchy","apiKey":"AIzaSyBAaDIXjOqrY_kzYUPReQ28_j41p1fWnyk"},
       {"name":"driving-force-apptakeoff","apiKey":"AIzaSyD76bmnJRxwq1MjYUGUaYPHWsAslzlmMkU"},
       {"name":"fearless-apptakeoff","apiKey":"AIzaSyCdnFd5vip2xmPDmMvIkY1E7icYK1l2lP0"},
       {"name":"fletcher-team-apptakeoff","apiKey":"AIzaSyCQKuT9VGzlkWVjkW6TCwJTUH0JLQ1Ze0g"},
       {"name":"flight-apptakeoff","apiKey":"AIzaSyCShtdU-yRidFTyTTJaccs3tHGQsd4AI9g"},
       {"name":"flores-fanatics","apiKey":"AIzaSyBwshKFHHUTzsw1aI7ensgLU8DgxDRUYlc"},
       {"name":"freedom-fighters-divita","apiKey":"AIzaSyBXLZdBI9Blo4K_RKTsO6KjioK1sFjb_Ss"},
       {"name":"freedom-fighters-hierarchy","apiKey":"AIzaSyD4bLWmCxX4gpj_8F25DRwHRCOzosDCffE"},
       {"name":"freedom-force-apptakeoff","apiKey":"AIzaSyDpURF2uv5xSruiYz0xHz6Hr1ca0bql75o"},
       {"name":"gladiators-apptakeoff","apiKey":"AIzaSyCFEFSudKwMl1IqGwbmIKVYFltEvR-q6cs"},
       {"name":"gonzalez-hierarchy-apptakeoff","apiKey":"AIzaSyBpeo49bgox-nlUMGEvhKYC677GSpGVzbo"},
       {"name":"huffmans-heroes","apiKey":"AIzaSyAm6Ml-tPScbJQ6hiP_yY08eNIGpJnzpp4"},
       {"name":"huge-apptakeoff","apiKey":"AIzaSyDGmeAgGC6HVW0RtdTLiAONf0WxjSOY4ng"},
       {"name":"icf-apptakeoff","apiKey":"AIzaSyC3i8O4-vfVCuK1fEMTy5WT0cd1HT2b33A"},
       {"name":"kingsqueens-apptakeoff","apiKey":"AIzaSyDtnV8vj_iQ39uX82Jo6M8Oxsjgitfqr6I"},
       {"name":"landrum-international","apiKey":"AIzaSyDmgQHfz1mkQlzMUcZAyh8ANVrtHi2m_CQ"},
       {"name":"lashner-lightning","apiKey":"AIzaSyCRk2NRN6YTGQpf-zsEVYVhI-k77cD-yWQ"},
       {"name":"legacy-legends-apptakeoff","apiKey":"AIzaSyDH4XJ-yjhyDPWK8I8fXaH7avoiAFLUodY"},
       {"name":"lion-chasers-apptakeoff","apiKey":"AIzaSyAMviBcEtsVUYs4oPKbosU7_f_8klEgyuc"},
       {"name":"limitless-apptakeoff","apiKey":"AIzaSyCzkY_HvjrObUM1fFPjSGxb8cZbzlQOBjU"},
       {"name":"mana-hierarchy","apiKey":"AIzaSyC3SJEhiOy5lEbG9SNPJ52_iqcc4AakYhk"},
       {"name":"never-tolerate-average","apiKey":"AIzaSyBP8sNkkCsj7HlSDERk2DJRafDrbtzuIig"},
       {"name":"powerbuilders-apptakeoff","apiKey":"AIzaSyBHXEdXmOeGFkLLilD3RRypap-kX9doNFM"},
       {"name":"peak-achievers","apiKey":"AIzaSyAWjiC2JHNAZsQ2s_ulb6KV7T_GyhkMe5A"},
       {"name":"persistence-apptakeoff","apiKey":"AIzaSyAVuKTz7nUAMtGwaDCYYbHDmdEkpXsW5jE"},
       {"name":"protectors-apptakeoff","apiKey":"AIzaSyBpjhmIrAC_GHarWkjAtBiTqxGaVqIKmQI"},
       {"name":"quiet-storm-apptakeoff","apiKey":"AIzaSyCiSQnm9nQiVfe6yYLZh0ZkM_MFZ91IHZU"},
       {"name":"rise-apptakeoff","apiKey":"AIzaSyCt6EqRqan29_iJ6uGxdGyHc0XLLPVqN0g"},
       {"name":"run-fields","apiKey":"AIzaSyDl2KR6FqKryfmDnrUzyq_QtQcrBoOowMQ"},
       {"name":"rvp-training-factory","apiKey":"AIzaSyCwyA5BVpNL8c0wsGaKbaACI5_DpGMTF_c"},
       {"name":"rufino-republic-apptakeoff","apiKey":"AIzaSyBXmu5PXFtZbVzvg5V3TCYVN-HknsHVthw"},
       {"name":"rumble-apptakeof","apiKey":"AIzaSyC0fmU4XTAsqKAlvNG1lk4WIIzu88ILU6U"},
       {"name":"sabia-apptakeoff","apiKey":"AIzaSyDau0Hwh0l80PFMq_GNRsRKe9egUW9gq_s"},
       {"name":"summit-apptakeoff","apiKey":"AIzaSyBLYqRvXK5ZZEbSzU5-4TiVr5UcP8_UeVs"},
       {"name":"super-saints","apiKey":"AIzaSyC7iaPx6Dd23Mx6b2G1lxyH1aB3xOUTfmk"},
       {"name":"superbase-cd4c1","apiKey":"AIzaSyCLjIwHl4BDH163hZI9QbW2we0yXdMcg3k"},
       {"name":"team-hughes-all-in","apiKey":"AIzaSyBbWQEZu2dEOvBj3pRFWd5YwNEE-UoNghk"},
       {"name":"tu-empire-builders","apiKey":"AIzaSyD1uWm7GUyCwOeNqz9IXozpUjZ6t69YIeM"},
       {"name":"team-action-crusaders","apiKey":"AIzaSyBV0hpPmLo6D5TfOTJGYXgfke4kFxlAbww"},
       {"name":"team-lavination-apptakeoff","apiKey":"AIzaSyAT80KuQ-53r5IOVl1syQXGV14TYkvi2tc"},
       {"name":"team-legacy-apptakeoff","apiKey":"AIzaSyA_9gGKsede5t6lTMIP_D02gH_vUX0qF6E"},
       {"name":"team-mvp-apptakeoff","apiKey":"AIzaSyD4O5IpuedJYb77HUTwL7njczbAMOpcCLM"},
       {"name":"team-rhino-apptakeoff","apiKey":"AIzaSyBAsSc-cTJi9ckWQDV_MynHJybRUObggvs"},
       {"name":"team-vip-apptakeoff","apiKey":"AIzaSyBljB3B-ZEdlv0FZ1Nq2usP6ShcpISSRw8"},
       {"name":"teamofdestiny-apptakeoff","apiKey":"AIzaSyB0oGHgdpjMRuy6Fpm8uFNEhnofsd5w0XU"},
       {"name":"the-global-alliance-apptakeoff","apiKey":"AIzaSyCAGWIg0zjbgehquyK2W7vFwphhgaIcEl4"},
       {"name":"thestandard-apptakeoff","apiKey":"AIzaSyDq7AHi329-uKKZj4DgBhNwLIWassP8lYQ"},
       {"name":"thetroopnation-apptakeoff","apiKey":"AIzaSyBiL0RevcoxnWrSmW3YFmtYfAN2VLTUOKw"},
       {"name":"trendsetters-apptakeoff","apiKey":"AIzaSyC0cEBuWFMYB6h5VQhtaPy1N2HAX0c9o7A"},
       {"name":"vangoor-apptakeoff","apiKey":"AIzaSyDJ3Df9mjP9JgQmcF3O2hncONxZAT3gqlQ"},
       {"name":"wyd1-apptakeoff","apiKey":"AIzaSyBYDt7dHHEym49ICGff16lGNOmc-PayeB8"},
       {"name":"warriors-super-hierarchy","apiKey":"AIzaSyD_ihg8lUH756l85ZhtGBthhvv9pPTUTfM"},
       {"name":"wealth-builders-apptakeoff","apiKey":"AIzaSyBbi7EbSPYKDEXDDpXYueZBu041U4cvhSw"},
       {"name":"world-changers-apptakeoff","apiKey":"AIzaSyBIH53OImQGFpzALD2fhib7OKo2R_dJXSU"},
       {"name":"world-changers-west-apptakeoff","apiKey":"AIzaSyBIH53OImQGFpzALD2fhib7OKo2R_dJXSU"},
    ]
} 