import { FRBS_ROLE } from "engines";
import firebase from "firebase";
import { fuego } from "../../../fuego";

export enum CLOUD_FUNCTIONS {
  ImpersonatorAuth = "callable-ImpersonatorAuth",
  ValidateLogin = "validateLogin",
}

export async function cloudFunction<
  I extends Object,
  R extends firebase.functions.HttpsCallableResult
>(name: CLOUD_FUNCTIONS, input: I) {
  const callables: R = await firebase.functions().httpsCallable(name)(input);
  return callables;
}

export const ImpersonatorAuth = (email: string) =>
  fuego.functions().httpsCallable(CLOUD_FUNCTIONS.ImpersonatorAuth)({
    email,
  });

export interface ValidateLoginInput {
  email: string;
  password: string;
  accessCode: string;
  subdomain?: string;
  teamId: string;
}
export interface ValidateLoginResult {
  data: dCallableValidResponse;
}

export interface dCallableValidResponse {
  errorCode: number | null;
  errorMessage: string | null;
  success: boolean;
  /** @deprecated */
  userType: FRBS_ROLE | null;
  _teamId: string;
  _parentAppId?: string;
  uid: string;
}
