import { useFocusEffect } from "@react-navigation/native";
import { Buttoon, Kitten, ListHeader, Toasty } from "components/atoms";
import {
  NotificationStatus,
  useAppContext,
  useNotificationCollection,
  usePageDocument
} from "engines";
import { TOPICTYPES } from "engines/functions/notifications-functions";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens/_navigation";
import { tr } from "utils";

/**
 * @deprecated not being used?
 * ### List of members of the app (aka teamId's members)
 *  - Including some quick actions as an swipeable item
 *  ----
 *  @version 21.09.07
 *  -  *Memoize it*
 *  @author  K, Nl
 *
 **/
export function BroadcastMessageBs(props: P) {
  const { C, teamId, frbsUser } = useAppContext();
  const { bsid } = props;

  const Navigation = useNavigator();
  // const userProfile = useUserProfile();

  const { data } = usePageDocument(bsid);
  const topic = `${teamId}-${TOPICTYPES.BASESHOP}-${data?._id}`;
  const { add, sendNotificationToTeam } = useNotificationCollection(`${topic}`);
  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section]

  const [message, setMessage] = React.useState<any>();
  // const [toggleDate, setToggleDate] = React.useState<boolean>(false);
  // const [recurring, setRecurring] = React.useState<boolean>(false);

  //#endregion

  //#region [functions] send broadcast Message to BS
  function onBroadcastMessagePress(xong) {
    console.log("message ", message);
    sendNotificationToTeam(topic, data, message)
      .then(() => {
        add({
          channel: topic,
          title: data?.name,
          message: message,
          status: NotificationStatus.SENT,
        }).then(() => {
          Toasty.show("Message sent to everyone!", {
            type: "success",
          });
          xong && xong();
        });
      })
      .catch((e) => {
        Toasty.show("Message failed to send to everyone!", {
          type: "danger",
        });
        xong && xong();
      });
  }
  //#endregion

  try {
    return (
      <>
        <ListHeader titleTx={"Type your message"} />
        <View>
          <Kitten.Input
            textStyle={{ minHeight: 60 }}
            multiline={true}
            placeholder={"Type Here"}
            value={message}
            onChangeText={setMessage}
          />
          <Kitten.Divider_________ />
          <Buttoon
            icon={{ name: "check" }}
            status="info"
            appearance={"filled"}
            size={"small"}
            progress
            onPress={(xong) => onBroadcastMessagePress(xong)}
          >
            {tr("Send to Everyone!")}
          </Buttoon>
          <Kitten.Divider_________ />
          <Buttoon
            // icon={{ name: "send" }}
            size={"small"}
            status="basic"
            appearance={"ghost"}
            onPress={() => {
              Navigation.goBack();
            }}
          >
            {tr("Cancel")}
          </Buttoon>
        </View>
      </>
    );
  } catch (error) {
    throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
  }
}

interface P {
  bsid: any;
}
