import { Kitten, Txt } from "components";
import { useAppContext } from "engines";
import React from "react";
import { View } from "react-native";
import { useNavigator } from "screens/_navigation";

/**
 * ### Loading Screen while setting up Collaboration Search Data
 *  ----
 *  @version 21.09.08
 *  @author  Saurabh_M
 *
 **/
export function CollaborationLoadScreen(props: dCollabLoadScreen) {
  const { C, teamId } = useAppContext();
  const { searchTerm } = props;
  const Navigation = useNavigator();

  searchTerm && Navigation.setOptions({ title: "Please Wait..." });

  return (
    <View
      style={{
        backgroundColor: C.background,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Txt.S1 style={{ marginBottom: 50 }}>
        {searchTerm
          ? `Searching for contents with keyword "${searchTerm}"`
          : "Loading Content..."}
      </Txt.S1>
      <Kitten.Spinner size={"giant"} />
    </View>
  );
}

interface dCollabLoadScreen {
  searchTerm?: string;
}
