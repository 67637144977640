import { Avt, Kitten, Txt } from "components";
import { useAppContext } from "engines";
import { iHierarchyList } from "engines/backends/schemas/collaboration/hierarchyList.schema";
import React from "react";
import { View } from "react-native";
import { scale } from "react-native-size-matters";
import { useNavigator } from "screens";
/**
 * ### List Hierarchy ListItem participating in Collaboration
 *  ----
 *  @version 21.11.07
 *  @author  Saurabh_M
 *
 **/
export function CollaborationHierarchyListItem(props: dCollabListItem) {
  const { index, inputData, onPress, editModeEnabled } = props;
  const { C } = useAppContext();
  const Navigation = useNavigator();

  const [itemIndexSelected, setItemIndexSelected] =
    React.useState<number>(null);

  React.useEffect(() => {
    editModeEnabled == false && setItemIndexSelected(null);
  }, [editModeEnabled]);

  return React.useMemo(() => {
    try {
      return (
        <View>
          <Kitten.ListItemv2
            // style={_isResourceNew && { backgroundColor: C["color-danger-400"] }}
            key={index}
            style={{ borderRadius: scale(4) }}
            onPress={onPress}
            title={() => {
              return (
                <>
                  <Txt.H5 style={{ textAlign: "left" }} numberOfLines={2}>
                    {inputData.teamName || "       "}
                  </Txt.H5>
                </>
              );
            }}
            accessoryLeft={() => (
              <Avt
                name={inputData.teamName || ""}
                source={{
                  uri:
                    (!inputData.teamLogo.includes("undefined") &&
                      inputData.teamLogo) ||
                    "",
                }}
                size="giant"
                shape="square"
              />
            )}
          />
        </View>
      );
    } catch (error) {
      //
      throw Error(`::TWVkaWFIbA67s21672a1lSXRlbQ==::${error}`);
    }
  }, [editModeEnabled, itemIndexSelected, C]);
}

interface dCollabListItem {
  index: number;
  inputData: iHierarchyList;
  onPress(): void;
  editModeEnabled: boolean;
}
