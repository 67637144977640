import {
  useItemAbortListener,
  useItemErrorListener,
  useItemProgressListener,
} from "@rpldy/uploady";
import { IconPrimr } from "assets";
import {
  Imaggie,
  Kitten,
  M,
  sstyled,
  Videolly,
  VimeoVideolly,
  YoutubeVideolly,
} from "components";
import { fn, MediaPageItemSchema, useAppContext } from "engines";
import VimeoAPI from "engines/backends/apis/VimeoAPI";
import { CLOUDINARY_FOLDER } from "engines/functions/media-functions";
import React, { memo } from "react";
import { ImageBackground, View } from "react-native";
import { dGalleryScrParams, useNavigator } from "screens";
/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.08.25
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
const STATES = {
  PROGRESS: "PROGRESS",
  DONE: "DONE",
  ABORTED: "ABORTED",
  ERROR: "ERROR",
};

const STATE_COLORS = {
  [STATES.PROGRESS]: "#f4e4a4",
  [STATES.DONE]: "#a5f7b3",
  [STATES.ABORTED]: "#f7cdcd",
  [STATES.ERROR]: "#ee4c4c",
};

export const QueueItem = memo((props: dQueueItem) => {
  const { cpid, pcid } = props;
  let media = props;
  const [progress, setProgress] = React.useState<any>(0);
  const [itemState, setItemState] = React.useState<any>(0);

  const Navigation = useNavigator();
  const { C } = useAppContext();

  // console.log(props);

  useItemProgressListener((item) => {
    if (item.completed > progress) {
      setProgress(() => item.completed);
      setItemState(() =>
        item.completed === 100 ? STATES.DONE : STATES.PROGRESS
      );
    }
  }, props.id);

  useItemAbortListener((item) => {
    setItemState(STATES.ABORTED);
  }, props.id);

  useItemErrorListener((item) => {
    setItemState(STATES.ERROR);
  }, props.id);
  
  // return <Txt>{JSON.stringify(media)}</Txt>;
  switch (media?.type) {
    case "image":
      return <Imaggie source={{ uri: media?.url }} />;
      break;
    case "vimeo":
      let vimeoId = VimeoAPI.getVideoIdFromUri(media.url);
      // console.log("vimeoId ", vimeoId);
      return <VimeoVideolly videoId={vimeoId} />;
      break;
    case "youtube":
      let YoutubeId = fn.media.getYoutubeId(media.url);
      // console.log("YoutubeId ", YoutubeId);
      return <YoutubeVideolly videoId={YoutubeId} />;
      break;

    case "video":
      return (
        <A.VideoThumbnail
          type="component"
          source={{ uri: media?.url }}
          resizeMode="cover"
          useNativeControls
          editModeEnabled
          cpid={cpid}
          pcid={pcid}
        />
      );
      break;
    case "dropbox":
      let customMedia: MediaPageItemSchema = {
        url: media?.url,
      };
      console.log("DROPBOX ", media?.url);
      return <M.AudioItem item={customMedia} serviceType={"DROPBOX"} />;
      break;

    default:
      return <View style={{ backgroundColor: "blue" }} />;
      break;
  }

  return (
    <>
      <A.CtnrWithMedia>
        {media?.type == "image" ? (
          <>
            {vimeoId.length != "303205674".length &&
            YoutubeId.length != "4meo1tkyJak".length ? (
              <A.ImageThumbnail
                resizeMode={"cover"}
                source={{ uri: media?.url }}
              >
                {media && (
                  <IconPrimr
                    name={"expand"}
                    color={C.text01}
                    preset="circular"
                    containerStyle={{
                      backgroundColor: C["color-primary-transparent-500"],
                    }}
                    onPress={() => {
                      Navigation.navigate<dGalleryScrParams>("Gallery", {
                        imageIndex: 0,
                        images: [{ url: media?.url }],
                      });
                    }}
                  />
                )}
              </A.ImageThumbnail>
            ) : vimeoId.length == "303205674".length ? (
              <VimeoVideolly videoId={vimeoId} />
            ) : (
              <YoutubeVideolly videoId={YoutubeId} />
            )}
          </>
        ) : (
          <A.VideoThumbnail
            type="component"
            source={{ uri: media?.url }}
            resizeMode="cover"
            useNativeControls
          />
        )}
      </A.CtnrWithMedia>
    </>
  );
});

const A = {
  CtnrWithMedia: sstyled(View)({
    minHeight: 6,
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    justifyContent: "center",
    backgroundColor: "dim",
  }),
  CtnrWithoutMedia: sstyled(Kitten.Card)((p) => ({
    minHeight: 6,
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  })),
  ImageThumbnail: sstyled(ImageBackground)({
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "background",
  }),
  VideoThumbnail: sstyled(Videolly)({
    maxWidth: 500,
    // width: "100%",
    height: "auto",
    // justifyContent: "center",
    alignSelf: "center",
  }),
};

export interface dQueueItem {
  id: string;
  url: string;
  type: string;
  name: string;
  cpid?: string;
  pcid?: string;
}
