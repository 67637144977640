import { useDocument } from "@nandorojo/swr-firestore";
import { IconPrimr, ICON_NAME } from "assets";
import { ItemSwipeable, Kitten, Rush, Txt } from "components";
import { Avt, Poppy, Toasty } from "components/atoms";
import { MemberInfo } from "components/organisms";
import { MemberViewNavigationParams } from "components/organisms/members/MemberView";
import { differenceInDays } from "date-fns";
import { FRBS_ROLE, useAppContext, UserSchema } from "engines";
import { useUserProfile } from "engines/firebase/handler/members-handler";
import React from "react";
import { View } from "react-native";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { useNavigator } from "screens";
import { scale, spacing, tr } from "utils";
/**
 * ### List Item for Member
 *  - Including some member's action
 *  ----
 *  @version 21.03.20
 *  -  *Move this component to ./molecules*
 *  @author  NL
 *
 **/
export function MemberListItem(props: dMemberListItem) {
  const { member, disabled, overridePress, selectMode, customIcon } = props;
  const userProfile = useUserProfile();
  const { C } = useAppContext();

  const Navigation = useNavigator();
  const handleMember = useDocument<UserSchema>(`users/${member._id}`, {
    listen: true,
  });
  const refItemSwipeable = React.useRef<Swipeable>();

  const [highestStreak, setHighestStreak] = React.useState<number>(0);

  React.useEffect(
    function _setupHighestStreak() {
      if (!handleMember.data) return;
      setHighestStreak(
        handleMember.data?.activityStreaks
          ? Math.max(
            ...handleMember.data?.activityStreaks?.map((_s) => _s.streak)
          )
          : 0
      );
    },
    [handleMember.data]
  );

  if (handleMember.error) {
    console.log("handleMember.error ", handleMember.error);
    //* It'll return nothing in prod, only show this text if debug mode is enabled
    return <Txt.Debug>{JSON.stringify(handleMember.error)}</Txt.Debug>;
  }

  if (!handleMember.data) {
    // Toasty.show(tr("Error getting your account"), { type: "danger" });
    return <Rush.Bar {...Rush._config.Loading} />;
  }

  let isMemberBanned = React.useMemo(
    () => handleMember.data.roles?.includes(FRBS_ROLE.BANNED),
    [handleMember.data.roles]
  );
  let isMemberAdmin = React.useMemo(
    () => handleMember.data.roles?.includes(FRBS_ROLE.ADMIN),
    [handleMember.data.roles]
  );
  let isMemberDev = React.useMemo(
    () => handleMember.data.roles?.includes(FRBS_ROLE.DEVELOPER),
    [handleMember.data.roles]
  );

  /**
   * NB: isBsOwner checks if that specific user is assigned owner of the BS
   */
  let isBsOwner = React.useMemo(
    () => handleMember.data.roles?.includes(`BS:${props.bsid}`),
    [handleMember.data.roles]
  );

  let memberData = React.useMemo(() => handleMember.data, [handleMember.data]);

  //#region [functions]
  function toMemberInfo() {
    //Navigation.navigate<dOverlayParams>("Overlay", {
    /* 
      In this function we checked to know if the screen we were handling was a member screen or a BS
      so if we are coming from a BS screen it takes us to the BS dialog 
      else it takes us to the member dialog
    */
    if (props.bsid) {
      return refItemSwipeable.current?.openRight();
    } else {
      Navigation.navigate("MemberScreen", { uid: memberData._id, userName: memberData.personali?.displayName } as MemberViewNavigationParams)
      // Navigation.dialogPortal.open({
      //   headerTitle:
      //     memberData.personali?.displayName || memberData.personali?.email,
      //   render: (
      //     <MemberInfo
      //       memberId={memberData._id}
      //       type={props.team ? "teammates" : "admin"}
      //     />
      //   ),
      //   // render: !props.team ? (
      //   //   <MemberInfo memberId={memberData._id} />
      //   // ) : (
      //   //   <MemberInfoTeam memberId={memberData._id} />
      //   // ),
      // });
    }
  }
  //#endregion

  //#region [functions] REMOVEUSER
  /**
   * This functions is used to remove a user from a BS
   * @param xong
   */
  function removeUser(xong) {
    Poppy(
      "Final Check",
      "Are you sure you want to remove user from base shop",
      {
        text: "Cancel",
        onPress: () => { },
      },
      {
        text: "Yes",
        onPress: () => {
          /**
           *This checks if we are trying to remove the owner of the BS, so if it true it doesn't allow us to do that else it removes that specific user
           */
          if (userProfile.data?._id === handleMember.data._id) {
            Toasty.show("Sorry you can not remove the owner of the BS", {
              type: "warning",
            });
          } else {
            handleMember
              .update({
                baseShopId: "",
              })
              .then(() => {
                xong && xong();
                Toasty.show("User removed from base shop", { type: "success" });
              })
              .catch((error) => {
                xong && xong();
                Poppy.Error(
                  error,
                  "base shop",
                  "Failed to remove user from base shop"
                );
                Navigation.goBack();
              });
          }
        },
      }
    );
    xong && xong();
  }
  //#endregion

  const CASE = {
    BS_MEMBER: props.bsid,
    MEMBER: !props.bsid,
  };

  if (CASE.BS_MEMBER) {
    return (
      <>
        <ItemSwipeable
          ref={refItemSwipeable}
          rightActions={[
            {
              icon: { name: "exit" },
              size: "medium",
              appearance: "filled",
              status: "danger",
              onPress: (xong) => removeUser(xong),
            },
            {
              label: tr("Close"),
              size: "tiny",
              appearance: "ghost",
              onPress: () => refItemSwipeable.current?.close(),
            },
          ]}
        >
          <Kitten.ListItemv2
            style={{
              borderWidth: isMemberBanned ? 2 : 0,
              borderColor: isMemberBanned && C.errorRed,
            }}
            title={() => (
              <Kitten.ViewH>
                <Txt.S2
                  style={
                    !memberData.personali?.displayName && {
                      borderWidth: 2,
                      borderRadius: scale(4),
                      borderColor: C.hazardYellow,
                    }
                  }
                >
                  {memberData.personali?.displayName || "                     "}
                </Txt.S2>

                {isMemberAdmin ? (
                  <Txt.S2
                    style={{
                      color: C.adminGold,
                    }}
                  >
                    {" "}
                    {tr("Admin")}
                  </Txt.S2>
                ) : null}
                {isMemberDev ? (
                  <Txt.S2
                    style={{
                      color: C.devPink,
                    }}
                  >
                    {" "}
                    {"Dev"}
                  </Txt.S2>
                ) : null}
                {isMemberBanned ? (
                  <Txt.S2
                    style={{
                      color: C.errorRed,
                    }}
                  >
                    {" "}
                    {"Banned"}
                  </Txt.S2>
                ) : null}
              </Kitten.ViewH>
            )} //*{fakerStatic.name.findName()}
            description={`${memberData?._id} • ${isBsOwner ? "BS Owner" : ""}`} //*{fakerStatic.company.catchPhrase()} NB: isBsOwner checks if that specific user is assigned owner of the BS
            onPress={toMemberInfo}
            accessoryLeft={() => (
              <View
                style={{
                  marginRight: highestStreak > 0 ? spacing(4) : spacing(3),
                }}
              >
                <Avt
                  name={
                    memberData.personali?.displayName ||
                    memberData.personali?.email
                  }
                  source={{ uri: memberData.personali?.photoURL }}
                />
                {highestStreak > 0 ? (
                  <View
                    style={{
                      position: "absolute",
                      bottom: -11,
                      right: -10,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <IconPrimr name="bolt" size={18} color={C.adminGold} />
                    <Txt.P2
                      style={{
                        color: C.adminGold,
                        fontWeight: "bold",
                        marginLeft: -3,
                      }}
                    >
                      {highestStreak}
                    </Txt.P2>
                  </View>
                ) : null}
              </View>
            )}
            accessoryRight={() => (
              <>
                {/*  */}
                <IconPrimr
                  name="dots_vertical"
                  color={C.grey500}
                  size={scale(16)}
                  preset="safe"
                />
              </>
            )}
          />
        </ItemSwipeable>
      </>
    );
  }

  if (CASE.MEMBER) {
    return (
      <>
        <Kitten.ListItemv2
          style={{
            borderWidth: isMemberBanned ? 2 : 0,
            borderColor: isMemberBanned && C.errorRed,
          }}
          title={() => (
            <Kitten.ViewH>
              <Txt.S2
                style={
                  !memberData.personali?.displayName && {
                    borderWidth: 2,
                    borderRadius: scale(4),
                    borderColor: C.hazardYellow,
                  }
                }
              >
                {memberData.personali?.displayName || "                     "}
              </Txt.S2>

              {!props.team && isMemberAdmin ? (
                <Txt.S2
                  style={{
                    color: C.adminGold,
                  }}
                >
                  {" "}
                  {tr("Admin")}
                </Txt.S2>
              ) : null}
              {!props.team && isMemberDev ? (
                <Txt.S2
                  style={{
                    color: C.devPink,
                  }}
                >
                  {" "}
                  {"Dev"}
                </Txt.S2>
              ) : null}
              {!props.team && isMemberBanned ? (
                <Txt.S2
                  style={{
                    color: C.errorRed,
                  }}
                >
                  {" "}
                  {"Banned"}
                </Txt.S2>
              ) : null}
              {Math.abs(
                differenceInDays(
                  member.createdAt?.toDate(),
                  new Date(Date.now())
                )
              ) <= 7 ? (
                <Txt.Helper
                  style={{ color: C.primary, marginLeft: 5, fontWeight: "800" }}
                >
                  {"New"}
                </Txt.Helper>
              ) : null}
            </Kitten.ViewH>
          )} //*{fakerStatic.name.findName()}
          description={selectMode ? memberData?.personali?.phoneNumber || memberData.personali?.email : memberData.personali?.email || ""} //*{fakerStatic.company.catchPhrase()}
          onPress={overridePress || toMemberInfo}
          disabled={disabled}
          accessoryLeft={() => (
            <View
              style={{
                marginRight: highestStreak > 0 ? spacing(4) : spacing(3),
              }}
            >
              <Avt
                name={
                  memberData.personali?.displayName ||
                  memberData.personali?.email
                }
                source={{ uri: memberData.personali?.photoURL }}
              />
              {highestStreak > 0 ? (
                <View
                  style={{
                    position: "absolute",
                    bottom: -11,
                    right: -10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IconPrimr name="bolt" size={18} color={C.adminGold} />
                  <Txt.P2
                    style={{
                      color: C.adminGold,
                      fontWeight: "bold",
                      marginLeft: -3,
                    }}
                  >
                    {highestStreak}
                  </Txt.P2>
                </View>
              ) : null}
            </View>
          )}
          accessoryRight={() => (
            <IconPrimr
              name={customIcon ? customIcon : selectMode ? "plus" : "chevron_right"}
              color={C.grey500}
              size={scale(16)}
              preset="safe"
            />
          )}
        />
      </>
    );
  }
}

export interface dMemberListItem {
  member: UserSchema;
  bsid?: string;
  team?: boolean;
  disabled?: boolean;
  overridePress?: () => void;
  /** for selecting the field trainer for another purpose */
  selectMode?: boolean;
  customIcon?: ICON_NAME
}
