import { useFocusEffect } from "@react-navigation/native";
import { M, sstyled, Txt } from "components";
import { Buttoon, Kitten, Skeleton } from "components/atoms";
import { useAppContext, useMembersCollection, UserSchema } from "engines";
import * as R from "ramda";
import React from "react";
import { View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { tr } from "utils";

/**
 * ###
 *  -
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.01.21
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function TraineesTeammates(p: {
  onLoadTrainees?: (trainees: UserSchema[]) => void;
  hide?: boolean;
}) {
  const { onLoadTrainees, hide } = p;
  const { C, teamId, frbsUser } = useAppContext();

  const [members, setMembers] = React.useState<UserSchema[]>([]);

  //#region [FRBS]
  // const handleMembers = useCollection<UserSchema>(`${FPATH.USERS}`, {
  //   ...Handler.defaultSetup<UserSchema>(teamId),
  // });

  const handleMembers = useMembersCollection({
    where: ["growth.listBuilder.shareTo", "array-contains", frbsUser?.uid],
  });

  React.useEffect(
    function setupData() {
      // let members = handleMembers.data?.filter((member) => {
      //   return member.growth?.listBuilder?.shareTo?.includes(frbsUser.uid);
      // });
      let members = handleMembers.data;
      handleMembers.data && setMembers(members);
      onLoadTrainees && onLoadTrainees(members);
    },
    [handleMembers.data]
  );
  const [listOffset, setListOffset] = React.useState(30);
  //#endregion

  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  });
  //#endregion

  //#region [section] search ops
  const [query, setQuery] = React.useState<string>();

  function filterHandler(value: string) {
    let membersD = handleMembers.data?.filter((member) => {
      return member.growth?.listBuilder?.shareTo?.includes(frbsUser.uid);
    });
    setQuery(value);
    const newData = membersD?.filter((member) => {
      return member.personali?.displayName
        ?.toLowerCase()
        .includes(value.toLowerCase());
    });
    setMembers([...newData]);
  }
  //#endregion
  return React.useMemo(() => {
    try {
      if (handleMembers.error) {
        return (
          <Txt.Indicator>{tr("Error getting members feed")}</Txt.Indicator>
        );
      }
      if (!handleMembers.data) return <Skeleton.Screen.Members length={7} />;

      let sortedMembers = R.sortWith<UserSchema>(
        [
          /** sorting `createdAt` in `desc` */
          R.descend(R.prop("createdAt")),
        ],
        members
      );
      return hide ? null : (
        <View>
          <A.CardContainer>
            <Kitten.Input
              value={query}
              placeholder={tr("Search trainees")}
              onChangeText={(value) => {
                filterHandler(value);
              }}
            />
            <Kitten.Divider_________ />
            <FlatList
              data={sortedMembers
                /** pagination */
                .slice(0, listOffset)}
              renderItem={({ item: member, index }) => (
                <M.MemberListItem
                  key={member._id}
                  member={member}
                  team={true}
                />
                /* Type was added in other to reuse the memberListItem component and 
              to know if it was a BS or a member info detail, ask Nanle if you have questions */
              )}
              ListFooterComponent={() =>
                sortedMembers?.length > 10 && (
                  <Buttoon
                    appearance={"ghost"}
                    progress
                    onPress={(xong) => {
                      setTimeout(() => {
                        setListOffset(listOffset + 5);
                        xong && xong();
                      }, 600);
                    }}
                    disabled={listOffset >= sortedMembers.length}
                  >
                    {listOffset < sortedMembers.length ? tr("Load More") : ""}
                  </Buttoon>
                )
              }
              ListEmptyComponent={() => (
                <Txt.Indicator marginV>
                  {handleMembers.loading
                    ? tr("Loading...")
                    : "0 trainees, have them assign you as their trainer first!"}
                </Txt.Indicator>
              )}
              keyExtractor={(item) => item._id}
            />
          </A.CardContainer>
        </View>
      );
    } catch (error) {
      throw Error(`::TWVtYmVyc0ZlZWQ=::${error}`);
    }
  }, [members, listOffset, C]);
}

const A = {
  CardContainer: sstyled(Kitten.Card)({
    minWidth: 300,
  }),
};

interface P {
  /*
  used to pass query to this components
  the where clause allow us to compare multiple parameters
  we could have something like 
  where teamId, ==, "apptakeoff"
  this then returns all users under the teamId apptakeoff
 * 
 * */
  where?: WhereItem<UserSchema>;
  bsid?: string; //I added this because i was having a type error on the teams page
}

type WhereItem<Doc extends object = {}, key = keyof Doc> = [key, "==", unknown];
