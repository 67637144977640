import { ButtonGroup, ButtonGroupProps } from "@ui-kitten/components";
import * as React from "react";
import { ScrollView } from "react-native";
/**
 * ### Modded Button Group
 *  - Allow scrolling as the group grows
 *  ----
 *  @example
 *  <View style={{ alignItems: "center" }}>
        <Kitten.ButtonGroupv2>
          {Array.from({ length: 4 }).map((_, i) => (
            <Buttoon>{i}</Buttoon>
          ))}
        </Kitten.ButtonGroupv2>
      </View>
 *  ----
 *
 **/
export function ButtonGroupv2(props: ButtonGroupProps) {
  return (
    <ScrollView horizontal bounces={false}>
      <ButtonGroup {...props}>{props?.children}</ButtonGroup>
    </ScrollView>
  );
}
