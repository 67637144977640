import * as R from "ramda";

/**
 * ###  Fn to rename key(s) in given object
 * -  Usually use when i want to convert old schema to new one 
 * without interfering the values
 * -  e.g. `OldSchema: {id: string} -> NewSchema: {_id: string}`
 * ---
 * @version 1.1.23
 * @author K
 * @param obj given obj
 * @param newKeys Map of {[old_key_name]: new_key_name}
 * 
 * @example
 *  const obj = { id: '1', name: 'Khoi' };
    const newKeys = { id: '_id', sex: 'gender' };
    const renamedObj = renameKeys(obj, newKeys);
    console.log(renamedObj);
    //*-> { _id:'1', name: 'Khoi' }
  * @example with TS

    interface OldSchema {id: string, name: string }  
    interface NewSchema {_id: string, name: string }

    const obj: OldSchema = { id: '1', name: 'Khoi' };
    const newKeys = { id: '_id', sex: 'gender' };
    const renamedObj = renameKeys<OldSchema, NewSchema>(obj, newKeys );
    console.log(renamedObj);
    //*-> { _id:'1', name: 'Khoi' }; renamedObj has type NewSchema 

  * @example  
    //* Real life: Update the schema in FRBS at the beginning of render
    
    db.collection(FPATH.POSSTS).onSnapshot((snapshot) => {
      snapshot.forEach((r) => {
        const rawData = r.data() as OldSchema;
        let DatawNewSchema = fn.js.renameKeys<OldSchema, NewSchema>(
          { ...rawData },
          { id: "_pid", sex: "gender", name: "displayName" }
        );
        r.ref.set(DatawNewSchema); //*-> now this doc has `DatawNewSchema`
      });
    });
    




    ---
  */
export function renameKeys<O, N>(
  obj: O,
  newKeys: Partial<Record<keyof O, keyof N>>
): N {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

/**
 * ###  Returns a partial copy of an object omitting the keys specified.
 * -    Useful when we want to omit some field(s) in the data,
 * especially the one not fitting in the new schema
 * ---
 * @param obj   given object
 * @param keys  list of to-be-omitted keys
 * @version 1.1.23
 * -    *TSfy everything*
 * @author K
 * ---
 * @example
 * let givenObj = {id: "1", name: "Khoi", uselessField: "Haha"}
    const newObj = omitKeys(givenObj,["uselessField"])
    console.log(newObj)
    //*->   {id: "1", name: "Khoi"}



    ---
 */
export function omitKeys<O extends Object>(obj: O, keys: Array<keyof O>) {
  //@ts-ignore  R.omit wants only string[], while keys = number|string[], which is still ok
  return R.omit(keys, obj);
}

export function schemaCheck<S>(obj: Object, requiredKeys: Array<keyof S>) {
  //@ts-ignore  R.props wants only string[], while keys = number|string[], which is still ok
  const values = R.props(requiredKeys, obj);
  let nilKeys = [];
  for (var i = 0; i < values.length; i++) {
    // nilKeys[i] = !values[i];
    if (!values[i]) {
      nilKeys.push(requiredKeys[i]);
    }
  }
  // return values;
  return nilKeys;
}
