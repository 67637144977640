import { MenuIcon } from "assets/icons/MenuIcon";
import { Kitten } from "components";
import { HitEmUpEmail } from "components/molecules/HitEmUp/HitEmUpEmail";
import { useTwoColumns } from "components/templates";
import { fn, MediaPageItemSchema, useAppContext } from "engines";
import { useCollabContext } from "engines/providers/collaboration-provider";
import React from "react";
import { Share } from "react-native";
import { useNavigator } from "screens/_navigation";
import { IS_WEB, tr } from "utils";
/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.03.09
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function ShareContents(props: P) {
  const { C, frbsUser } = useAppContext();
  const { setIsSelectModeOn, isSelectModeOn } = useCollabContext();
  const {
    isSelectShareModeOn,
    setisSelectShareModeOn,
    isShareContents,
    setIsShareContents,
  } = props;
  const { breakpointForTwoColumns } = useTwoColumns();
  const Navigation = useNavigator();

  React.useEffect(function closePage() {
    isShareContents.length <= 0
      ? !breakpointForTwoColumns &&
        (setisSelectShareModeOn(!isSelectShareModeOn), Navigation.goBack())
      : null;
  }, []);

  const BLAST_OPTIONS = [
    {
      text: tr("Blast text"),
      onSelect: () => {
        console.log("isShareContents ", isShareContents);
        Navigation.navigate("Contact Manager", { listData: isShareContents });
        // Navigation.dialogPortal.open({
        //   headerTitle: "Blast",
        //   render: (
        //     <HitEmUpSms
        //       listData={isShareContents}
        //       shareMode={true}
        //       contacts={[]}
        //     />
        //   ),
        // });
      },
      icon: "sms",
      disableOnWeb: true,
    },
    {
      text: tr("Blast Email"),
      onSelect: () => {
        Navigation.dialogPortal.open({
          headerTitle: "Blast",
          render: (
            <HitEmUpEmail
              listData={isShareContents}
              shareMode={true}
              contacts={[]}
            />
          ),
        });
      },
      icon: "email",
      disableOnWeb: false,
    },

    {
      text: tr("More apps..."),
      onSelect: async () => {
        try {
          const result = await Share.share({
            message: fn.js.shareItemsToPrettyMessage(isShareContents),
          });
          if (result.action === Share.sharedAction) {
            if (result.activityType) {
              // shared with activity type of result.activityType
            } else {
              // shared
            }
          } else if (result.action === Share.dismissedAction) {
            // dismissed
          }
        } catch (error) {
          alert(error.message);
        }
      },
      icon: "share",
      disableOnWeb: true,
    },
    // {
    //   text: tr("Cancel"),
    //   onSelect: () => {
    //     // console.log("checker");
    //   },
    // },
  ];
  return (
    <>
      {/* {isShareContents.length <= 0 ? (
        <Buttoon
          appearance={"filled"}
          disabled={true}
          onPress={() => {
            setisSelectShareModeOn(false);
          }}
        // style={{color:""}}
        // appearance={"ghost"}
        >
          {tr("Share selected items")}
        </Buttoon>
      ) : (
        <Menoo
          renderer={renderers.SlideInMenu}
          trigger={{
            text: tr("Share selected items"),
            style: {
              // color: C.pitchWhite,
              textAlign: "center",
              padding: spacing(4),
              backgroundColor: C.primary,
            },
          }}
          options={[
            {
              text: tr("Existing List"),
              onSelect: () => {
                Navigation.dialogPortal.open({
                  headerTitle: "Blast",
                  render: (
                    <HitEmUpSelector
                      userId={frbsUser?.uid}
                      sharedITems={isShareContents}
                    />
                  ),
                });
              },
            },
            {
              text: "__________",
            },
            {
              text: tr("Text & Share"),
              onSelect: async () => {
                try {
                  const result = await Share.share({
                    message: fn.js.shareItemsToPrettyMessage(isShareContents),
                  });
                  if (result.action === Share.sharedAction) {
                    if (result.activityType) {
                      // shared with activity type of result.activityType
                    } else {
                      // shared
                    }
                  } else if (result.action === Share.dismissedAction) {
                    // dismissed
                  }
                } catch (error) {
                  alert(error.message);
                }
              },
            },
            {
              text: "__________",
            },
            {
              text: tr("Cancel"),
              onSelect: () => {
                // console.log("checker");
              },
            },
          ]}
        />
      )} */}
      <Kitten.Menu>
        {BLAST_OPTIONS.map((item, index) => {
          return (
            <Kitten.MenuItem
              title={item?.text}
              onPress={() => {
                item?.onSelect();
              }}
              accessoryLeft={(p) => MenuIcon(item?.icon, p)}
              disabled={IS_WEB && item?.disableOnWeb}
            />
          );
        })}
      </Kitten.Menu>
      {/* <Menoo
        renderer={renderers.SlideInMenu}
        trigger={{
          text: tr("Share selected items"),
          style: {
            // color: C.pitchWhite,
            textAlign: "center",
            padding: spacing(4),
            backgroundColor: C.primary,
          },
        }}
        options={[
          {
            text: tr("Existing List"),
            onSelect: () => {
              Navigation.dialogPortal.open({
                headerTitle: "Blast",
                render: (
                  <HitEmUpSelector
                    userId={frbsUser?.uid}
                    sharedITems={isShareContents}
                  />
                ),
              });
            },
          },
          {
            text: "__________",
          },
          {
            text: tr("Text & Share"),
            onSelect: async () => {
              try {
                const result = await Share.share({
                  message: fn.js.shareItemsToPrettyMessage(isShareContents),
                });
                if (result.action === Share.sharedAction) {
                  if (result.activityType) {
                    // shared with activity type of result.activityType
                  } else {
                    // shared
                  }
                } else if (result.action === Share.dismissedAction) {
                  // dismissed
                }
              } catch (error) {
                alert(error.message);
              }
            },
          },
          {
            text: "__________",
          },
          {
            text: tr("Cancel"),
            onSelect: () => {
              // console.log("checker");
            },
          },
        ]}
      /> */}
    </>
  );
}

interface P {
  isSelectShareModeOn?: boolean;
  setisSelectShareModeOn?: (value: boolean) => void;
  isShareContents?: MediaPageItemSchema[];
  setIsShareContents?: (item) => void;
}
