import { useFocusEffect } from "@react-navigation/native";
import { sstyled } from "components";
import { Buttoon, Txt } from "components/atoms";
import { MediaPageSchema, useAppContext } from "engines";
import { useTrophyDocument } from "engines/firebase/handler/config-handler";
import React from "react";
import { ActivityIndicator, Animated, Image, View } from "react-native";
import * as Animatable from "react-native-animatable";
import ConfettiCannon from "react-native-confetti-cannon";
import { useNavigator } from "screens";
import { tr } from "utils";

/**
 * ###
 *  - Detailed explanation (if any)
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 21.12.23
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/
export function LevelCompletionTrophy(props: P) {
  const { pageHandler } = props;
  const { teamId } = useAppContext();
  const { data, error, deleteDocument } = useTrophyDocument(
    `variables-${teamId}`,
    pageHandler.trophyId
  );

  const Navigation = useNavigator();

  // React.useEffect(function setUpData() {
  //   const newData = data?.filter((trophy) => {
  //     return trophy.id == pageHandler.trophyId;
  //   });

  //   setTrophyDetails(newData && newData[0]);
  // }, []);

  // console.log("trophy data", trophyDetails);
  //#region [ANM]
  useFocusEffect(function layouting() {
    //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    setTimeout(() => {
      fadeIn();
    }, 3000);
  });
  //#endregion

  // fadeAnim will be used as the value for opacity. Initial Value: 0
  const fadeAnim = React.useRef(new Animated.Value(0)).current;

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 5000,
    }).start();
  };

  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 3000,
    }).start();
  };

  try {
    if (error) return <Txt.Helper>{tr("Cannot load trophies")}</Txt.Helper>;
    if (!data) return <ActivityIndicator />;

    return (
      <A.CtnrPersonali>
        <ConfettiCannon
          count={500}
          origin={{ x: -10, y: 0 }}
          autoStartDelay={2000}
        />

        <View style={{ padding: 20, alignItems: "center" }}>
          <Image
            source={{
              uri: data?.uri,
            }}
            style={{ width: 200, height: 200 }}
          />

          <Animated.View
            style={[
              {
                opacity: fadeAnim,
                padding: 10,
              },
            ]}
          >
            <Txt.H3 style={{ paddingTop: 5, textAlign: "center" }}>
              {tr(`Congratulations \n You have unlocked ${tr(data?.name)}!`)}
            </Txt.H3>
          </Animated.View>

          <Buttoon
            status={"success"}
            onPress={() => {
              fadeOut();

              setTimeout(() => {
                Navigation.goBack();
              }, 2000);
            }}
          >
            {tr("Awesome")}
          </Buttoon>
        </View>
      </A.CtnrPersonali>
    );
  } catch (error) {
    throw Error(`::QWRkQ29udGFjdENyZWF0b3I=::${error}`);
  }
}

const A = {
  CtnrPersonali: sstyled(Animatable.View)((p) => ({
    justifyContent: "flex-start",
  })),
};

interface P {
  pageHandler?: MediaPageSchema;
}
