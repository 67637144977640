import { IconPrimr } from "assets";
import { Avt, Skeleton, sstyled, Txt } from "components";
import { Buttoon } from "components/atoms";
import { useAppContext, UserSchema } from "engines";
import { useMemberDocument } from "engines/firebase/handler/members-handler";
import React from "react";
import { Linking, View } from "react-native";
import * as Animatable from "react-native-animatable";
import { scale, spacing, tr } from "utils";

/**
 * ###
 *  - This component is used for minimized viewing
 * - profile details: and it no other functionality:
 *   eg Edit or Delete or Create functionality
 *  ----
 *  @example
 *  Copy and paste function is the best...
 *  ----
 *  @version 22.01.21
 *  -  *Brief changelog*
 *  @author  Nl
 *
 **/

export function PersonaliViewCenter({
  uid,
  showAdminTools,
}: {
  uid: UserSchema["_id"];
  showAdminTools: boolean;
}) {
  const { C } = useAppContext();

  const { data: memberD, ...memberHandler } = useMemberDocument(uid);

  const [highestStreak, setHighestStreak] = React.useState<number>(0);

  React.useEffect(
    function _setupHighestStreak() {
      if (!memberD) return;
      setHighestStreak(
        memberD?.activityStreaks
          ? Math.max(...memberD?.activityStreaks?.map((_s) => _s.streak))
          : 0
      );
    },
    [memberD]
  );

  return React.useMemo(() => {
    if (memberHandler.error)
      return (
        <Txt.Indicator marginV>{tr("Error getting user info")}</Txt.Indicator>
      );

    if (!memberD) {
      // Toasty.show(tr("Error getting your account"), { type: "danger" });
      return <Skeleton layout={Skeleton.Layout.ProfileItem} />;
    }
    return (
      <A.CtnrPersonali>
        <View style={{ justifyContent: "flex-start", flexDirection: "row" }}>
          <View style={{ alignSelf: "flex-start" }}>
            <View style={{ justifyContent: "center" }}>
              <Avt
                name={
                  memberD?.personali?.displayName || memberD?.personali?.email
                }
                source={{ uri: memberD?.personali?.photoURL }}
                size={scale(69)}
              />
              {showAdminTools ? (
                <Buttoon
                  disabled={!memberD?.personali?.photoURL}
                  size="tiny"
                  appearance="ghost"
                  status="info"
                  onPress={() => Linking.openURL(memberD?.personali?.photoURL)}
                >
                  Download
                </Buttoon>
              ) : null}
            </View>
            {highestStreak > 0 ? (
              <View
                style={{
                  position: "absolute",
                  bottom: -11,
                  right: -10,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconPrimr name="bolt" size={24} color={C.adminGold} />
                <Txt.S1
                  style={{
                    fontSize: 18,
                    color: C.adminGold,
                    fontWeight: "bold",
                    marginLeft: -3,
                  }}
                >
                  {highestStreak}
                </Txt.S1>
              </View>
            ) : null}
          </View>
        </View>
        <A.Ctnr$>
          <Txt.H6>
            {memberD?.personali?.displayName || "⚠️ No display name"}
          </Txt.H6>
          <Txt.S1>
            {memberD?.personali?.phoneNumber || "⚠️ No phone number"}
          </Txt.S1>
          <Txt>{memberD?.personali?.email || "⚠️ No email"}</Txt>
        </A.Ctnr$>
      </A.CtnrPersonali>
    );
  }, [memberD, C, highestStreak]);
}

const A = {
  CtnrPersonali: sstyled(Animatable.View)((p) => ({
    justifyContent: "flex-start",
  })),
  CtnrAvatar: sstyled(View)({
    // flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  Ctnr$: sstyled(View)({
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    // width: p.WIDTH * 0.2,
  }),
};
