import { IconPrimr } from "assets";
import Color from "color";
import { Txt } from "components";
import { useAppContext } from "engines";
import React from "react";
import { Pressable, View } from "react-native";
import { DayProps } from "react-native-calendars/src/calendar/day";
import { DateData } from "react-native-calendars/src/types";
import { scale, spacing } from "utils";

export function RenderDay({
  date,
  state,
  marking,
  onDateSelect,
  selectedDate,
}: DayProps & {
  date?: DateData;
  onDateSelect?(date: DateData): void;
  selectedDate: string;
}) {
  const { C } = useAppContext();
  let formattedSelectedDate =
    ("0" + date.month).slice(-2) +
    "/" +
    ("0" + date.day).slice(-2) +
    "/" +
    date.year;

  let isSelected = selectedDate === formattedSelectedDate;
  let _textColor = () => {
    if (isSelected) {
      return C.primaryDarker;
    } else {
      switch (state) {
        case "disabled":
          return C.dim;
          break;
        case "today":
          return "#000";
          break;
        default:
          return C.text;
          break;
      }
    }
  };
  let _backgroundColor = () => {
    if (isSelected) {
      return Color(C.primaryDarker).alpha(0.1).toString();
    } else {
      switch (state) {
        case "disabled":
          return "transparent";
          break;
        case "today":
          return C["color-success-500"];
          break;
        default:
          return "transparent";
          break;
      }
    }
  };
  return (
    <Pressable
      onPress={() => onDateSelect && onDateSelect(date)}
      style={{
        backgroundColor: _backgroundColor(),
        borderRadius: scale(3),
        borderWidth: 2,
        borderColor: isSelected ? C.primaryDarker : "transparent",
        padding: spacing(3),
        width: scale(40),
        height: scale(40),
        alignItems: "center",
      }}
    >
      <Txt
        onPress={() => onDateSelect && onDateSelect(date)}
        style={{
          textAlign: "center",
          color: _textColor(),
          fontWeight: "bold",
        }}
      >
        {date.day}
      </Txt>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        {
          /** Only show 1 dot for each type of events, regarding quantities */
          marking?.dots
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t?.color === value?.color)
            )
            .map((dot) => (
              <IconPrimr color={dot?.color} name="dot" size={scale(7)} />
            ))
        }
      </View>
    </Pressable>
  );
}
